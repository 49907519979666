import React, { useState, useContext, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import GroupsOutlinedIcon from '@mui/icons-material/GroupsOutlined';
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined';
import { useSnackbar } from 'notistack';
import styles from './BillingPage.module.css';
import { PageConstants } from '../../constants';
import { GlobalContext, initialState } from '../../contexts/GlobalStateProvider';
import { actionTypes } from '../../reducers/reducer';
import { generateRoutesAndTitle } from '../../actions/BreadCrumbActions';
import { BillingActions, CompanyActions, SearchJumpToActions } from '../../actions';
import Title from '../../components/Title/Title';
import GeneralSubNavBar from '../../components/GeneralSubNavBar/GeneralSubNavBar';
import { LayoutMain } from '../../components/UI_V2/Layout';
import { SurfacePaperMain } from '../../components/UI_V2/Surface/Paper';
import { FeedbackLoadingMain } from '../../components/UI_V2/Feedback/Loading';
import BillingPageSkeleton from './Skeleton/Skeleton';
import BillingPageListCompanies from './ListCompanies/ListCompanies';
import BillingSubscriptionStatus from './SubscriptionStatus/SubscriptionStatus';
import BillingInvoicesSection from './InvoicesSection/InvoicesSection';
import BillingModalPlanSelection from './ModalPlanSelection/ModalPlanSelection';
import handleLoadings from '../../utilities/handleLoadings';
import handleStatusMsg from '../../utilities/handleStatusMsg';
import BillingModalOrderSummary from './ModalOrderSummary/ModalOrderSummary';
import LinkNoDecor from '../../components/LinkNoDecor/LinkNoDecor';
import GlobalActionButton from '../../components/GeneralSubNavBar/BottomNavBar/GlobalActionButton/GlobalActionButton';
import { setCurrentInvoicesUnpaidCounter } from '../../actions/BillingActions';
import { useInitiateRoleUser } from '../../hooks/RoleHooks';
import { hasPermission } from '../../actions/RoleActions';
import { roles } from '../../constants/RoleConstants';
import IntroEmptyBillingPage from './IntroEmptyBillingPage/IntroEmptyBillingPage';
import { useMarkReadNotificationOnPageOpen } from '../../hooks/NotificationHooks';

const containerWithSidebarStyles = {
  true: `${styles.container} ${styles.sidebarOpen}`,
  false: styles.container,
};

const listCompaniesSectionWithSidebarStyles = {
  true: `${styles.listCompaniesSection} ${styles.listCompaniesSectionSidebarOpen}`,
  false: styles.listCompaniesSection,
};

const billingSectionWithSidebarStyles = {
  true: `${styles.billingSection} ${styles.billingSectionSidebarOpen}`,
  false: styles.billingSection,
};

const BillingPage = () => {
  const [
    {
      user, currentRoleUser, currentCompanies, currentCompany,
      isSidebarOpen,
    },
    dispatch,
  ] = useContext(GlobalContext);

  const [loadings, setLoadings] = useState([]);
  const [showPlanSelection, setShowPlanSelection] = useState(false);
  const [showOrderSummary, setShowOrderSummary] = useState(false);
  const [showEmptySection, setShowEmptySection] = useState(true);
  const [subscription, setSubscription] = useState({});
  const [tabSelected, setTabSelected] = useState('all');
  const { enqueueSnackbar } = useSnackbar();

  useInitiateRoleUser({ });

  // read notif automatically if there is readNotifId
  useMarkReadNotificationOnPageOpen();

  const history = useHistory();
  const location = useLocation();
  const params = useParams();

  const { companyId } = params;

  const handleOpenPlanSelection = () => {
    setShowPlanSelection(true);
  };

  const handleClosePlanSelection = () => {
    setShowPlanSelection(false);
  };

  const handleOpenOrderSummary = () => {
    setShowOrderSummary(true);
  };

  const handleCloseOrderSummary = () => {
    setShowOrderSummary(false);
  };

  const getSubscriptionApi = async () => {
    try {
      const startLoadings = handleLoadings('billingSubscriptionStatus', [...loadings], 'start');
      setLoadings([...startLoadings]);

      const result = await BillingActions.getSubscriptionStatus({
        companyId,
      });

      setSubscription(result.data);

      // const status = handleStatusMsg(result, 'success');

      // enqueueSnackbar(status.message, {
      //   variant: 'success',
      // });
    } catch (err) {
      const status = handleStatusMsg(err, 'error');

      enqueueSnackbar(status.message, {
        variant: 'error',
      });
    } finally {
      const endLoadings = handleLoadings('billingSubscriptionStatus', [...loadings], 'end');
      setLoadings([...endLoadings]);
    }
  };

  const switchAndSaveNewCompany = async () => {
    try {
      const startLoadings = handleLoadings('billingSubscriptionStatus', [...loadings], 'start');
      setLoadings([...startLoadings]);
      await CompanyActions.getAndSaveCompanyAndLastOpenedCompany(
        { companyId, companies: currentCompanies, userId: user?._id }, dispatch,
      );
    } catch (err) {
      const status = handleStatusMsg(err, 'error');

      enqueueSnackbar(status.message, {
        variant: 'error',
      });
    }
  };

  const checkIfUserHaveAccessToBillingPage = () => {
    if (currentRoleUser.role.length < 1) return false;
    const isUserSpvOrAbove = hasPermission(currentRoleUser, roles.spvOrAbove);

    if (!isUserSpvOrAbove) {
      setShowEmptySection(true);
      return false;
    }

    if (isUserSpvOrAbove) {
      setShowEmptySection(false);
    }

    return true;
  };

  useEffect(() => {
    if (user._id === undefined) {
      history.push(`/check-login?previousPath=${location.pathname}`);
      return;
    }
    switchAndSaveNewCompany();
  }, [location]);

  useEffect(() => {
    const isPermitted = checkIfUserHaveAccessToBillingPage();
    if (isPermitted) {
      getSubscriptionApi();
    }
  }, [currentRoleUser]);

  const forceReinitiateInvoicesList = async (value) => {
    const initiateInvoicesApi = async () => {
      try {
        const result = await BillingActions.initiateInvoices({
          companyId,
          status: value,
        },
        dispatch);
      } catch (err) {
        const status = handleStatusMsg(err, 'error');
        enqueueSnackbar(status.message, {
          variant: 'error',
        });
      }
    };

    const getInvoicesCounterUnpaidApi = async () => {
      try {
        const result = await BillingActions.getInvoicesCounterUnpaid({
          companyId,
        });

        setCurrentInvoicesUnpaidCounter(result?.total, dispatch);
      } catch (err) {
        const status = handleStatusMsg(err, 'error');

        enqueueSnackbar(status.message, {
          variant: 'error',
        });
      }
    };

    await initiateInvoicesApi();
    await getInvoicesCounterUnpaidApi();
  };

  const pageType = PageConstants.pageType.billings;
  const breadcrumb = generateRoutesAndTitle({ type: pageType, data: { currentCompany } });
  SearchJumpToActions.saveRecentPage({
    company: currentCompany,
    userId: user?._id,
    itemId: `${companyId}-billings`,
    url: breadcrumb.url,
    routes: breadcrumb.routes,
    name: breadcrumb.title,
    type: pageType,
  });

  return (
    <>
      <Title title="Halaman Tagihan" />
      <GeneralSubNavBar
        breadcrumbRoutes={breadcrumb.routes}
        pageTitle={breadcrumb.title}
        bottomBarOverviewOptionId={null}
        withMiddleNavBar={false}
        bottomBarGlobalActions={(
          <>
            <LinkNoDecor to={`/companies/${params.companyId}/settings/edit`}>
              <GlobalActionButton
                buttonMode="passive"
                text="Pengaturan"
                icon={<SettingsOutlinedIcon />}
              />
            </LinkNoDecor>
            <LinkNoDecor to={`/companies/${params.companyId}/settings/members`}>
              <GlobalActionButton
                buttonMode="passive"
                text="Anggota"
                icon={<GroupsOutlinedIcon />}
              />
            </LinkNoDecor>
            <LinkNoDecor to={`/companies/${params.companyId}/billings`}>
              <GlobalActionButton
                buttonMode="active"
                text="Tagihan"
                icon={PageConstants.pageProperties.billings.icon}
              />
            </LinkNoDecor>
          </>
        )}
      />
      <div className={containerWithSidebarStyles[isSidebarOpen]}>
        {/* <FeedbackLoadingMain
              wait="blastPage"
              loadings={loadings}
              loadingComponent={<BillingPageSkeleton />}
            > */}
        <div className={listCompaniesSectionWithSidebarStyles[isSidebarOpen]}>
          <BillingPageListCompanies
            companies={currentCompanies}
            currentCompany={currentCompany}
          />
        </div>
        <div className={billingSectionWithSidebarStyles[isSidebarOpen]}>
          {showEmptySection
          && (
            <IntroEmptyBillingPage company={currentCompany} />
          )}
          {!showEmptySection && (
          <>
            <div className={styles.subscriptionStatusSection}>
              <BillingSubscriptionStatus
                subscription={subscription}
                handleOpenPlanSelection={handleOpenPlanSelection}
                handleOpenOrderSummary={handleOpenOrderSummary}
                loadings={loadings}
              />
            </div>
            <div className={styles.invoicesSection}>
              <BillingInvoicesSection
                tabSelected={tabSelected}
                setTabSelected={setTabSelected}
                showPlanSelection={showPlanSelection}
                showOrderSummary={showOrderSummary}
              />
            </div>
          </>
          )}
        </div>
        {/* </FeedbackLoadingMain> */}
      </div>

      {!showEmptySection && (
      <>
        <BillingModalPlanSelection
          open={showPlanSelection}
          onClose={handleClosePlanSelection}
          subscription={subscription}
          user={user}
          currentCompany={currentCompany}
          tabSelected={tabSelected}
          forceReinitiateInvoicesList={forceReinitiateInvoicesList}
        />
        <BillingModalOrderSummary
          open={showOrderSummary}
          onClose={handleCloseOrderSummary}
          subscription={subscription}
          user={user}
          currentCompany={currentCompany}
          tabSelected={tabSelected}
          forceReinitiateInvoicesList={forceReinitiateInvoicesList}
        />
      </>
      )}
    </>
  );
};

BillingPage.propTypes = {

};

export default BillingPage;
