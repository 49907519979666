import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import StyledEngineProvider from '@mui/material/StyledEngineProvider';
import { Waiter } from 'react-wait';
import { SnackbarProvider, closeSnackbar } from 'notistack';
import GlobalStateProvider from './contexts/GlobalStateProvider';

import App from './App';
import { InputButtonMain } from './components/UI_V2/Input/Button';

ReactDOM.render(
  <BrowserRouter>
    <GlobalStateProvider>
      <Waiter>
        <SnackbarProvider
          maxSnack={3}
          autoHideDuration={1300}
          action={(snackbarId) => (
            <InputButtonMain border="semiRounded" size="sm" handleClick={() => closeSnackbar(snackbarId)}>
              Tutup
            </InputButtonMain>
          )}
        >
          <StyledEngineProvider injectFirst>
            <App />
          </StyledEngineProvider>
        </SnackbarProvider>
      </Waiter>
    </GlobalStateProvider>
  </BrowserRouter>,
  document.getElementById('root'),
);
