import React, { useState, useContext } from 'react';
import AddIcon from '@mui/icons-material/Add';
import PropTypes from 'prop-types';
import { useMediaQuery } from '@mui/material';
import styles from './Members.module.css';
import ImageMember from '../../../components/UI/ImageMember/ImageMember';
import ManageMultipleMembersContainer from '../../ManageMultipleMembersContainer/ManageMultipleMembersContainer';
import { checkIfUserAuthorizedToEditData } from '../../../actions/UserActions';
import { GlobalContext } from '../../../contexts/GlobalStateProvider';
import { DisplayAvatarMemberWithOnline } from '../../../components/UI_V2/Display/Avatar';
import { useDelayShowHideHandler } from '../../../hooks/HelperHooks';
import ShowLimitMembers from '../../../components/ShowLimitMembers/ShowLimitMembers';

const Members = ({ card }) => {
  const isMobile = useMediaQuery('(max-width:720px)');
  const [{ user, currentRoleUser, currentTeamBelowRole }] = useContext(GlobalContext);
  const [showManageMembers,
    handleShowManageMembers, handleHideManageMembers] = useDelayShowHideHandler();

  const handleClicked = () => {
    handleShowManageMembers();
  };

  const handleCancel = () => {
    handleHideManageMembers();
  };

  const limitMember = isMobile ? 6 : 13;

  return (
    <>
      {card.members !== undefined && card.members.length > 0
        ? <p className={styles.Members__smallTitle}>ANGGOTA</p>
        : null}

      <div className={styles.container}>
        <ShowLimitMembers
          members={card.members ? card.members : []}
          limit={limitMember}
          noPlusButton
        />
        {/* {card.members !== undefined ? card.members.map((member) => (

          <div className={styles.Members__relative}>
            <DisplayAvatarMemberWithOnline
              // clicked={checkIfUserAuthorizedToEditData(card, user,
              //   currentRoleUser, currentTeamBelowRole)
              //   ? () => handleClicked()
              //   : () => null}
              src={member.photoUrl}
              userId={member._id}
              positionPopUp="topLeft"
            />

          </div>

        )) : null} */}
        {card.members !== undefined && card.members.length > 0
        && checkIfUserAuthorizedToEditData(card, user,
          currentRoleUser, currentTeamBelowRole) ? (
            <div onClick={handleClicked} className={card.members?.length > limitMember ? `${styles.plusButton} ${styles.marginLeft}` : styles.plusButton}>
              <AddIcon />
            </div>
          ) : null}
      </div>
      {showManageMembers ? <ManageMultipleMembersContainer type="card" card={card} cancel={handleCancel} left /> : null}
    </>
  );
};

Members.propTypes = {
  card: PropTypes.object.isRequired,
};

export default Members;
