import React, {
  memo, useState, useContext, useEffect, useCallback,
} from 'react';
import { useParams } from 'react-router-dom';
import PropTypes from 'prop-types';
import { differenceWith, isEqual } from 'lodash';
import Spinner from 'react-bootstrap/Spinner';
import { useSnackbar } from 'notistack';
import styles from './FetchMessages.module.css';
import Message from './Message/Message';
import { GlobalContext, initialState } from '../../../../contexts/GlobalStateProvider';
import { ChatActions } from '../../../../actions';
import handleStatusMsg from '../../../../utilities/handleStatusMsg';
import { handleCompareDate, handleCheckToday } from '../../../../utilities/checkSameDay';
import DateSeparator from '../../../CommentsSectionContainer/DateSeparator/DateSeparator';
import { usePrivateMessageSocket } from '../../../../hooks/PrivateMessageHooks';
import UnreadMessagesSeparator from './UnreadMessagesSeparator/UnreadMessagesSeparator';
import { useSearchAutoScrollMessage } from '../../../../hooks/GroupMessageHooks';
import InfinityScroll from '../../../../components/UI/InfinityScroll/InfinityScroll';
import { FeedbackLoadingBlock } from '../../../../components/UI_V2/Feedback/Loading';
import { reverseArray } from '../../../../utilities/arrayUtil';
import Color from '../../../../themes/colors';
import { useFileViewer } from '../../../../hooks/FileHooks';
import FileViewerDialog from '../../../../components/FileViewerDialog/FileViewerDialog';

const FetchMessages = ({
  isNeedCheckLoadMore, checkLengthChatToLimit,
  loadingUpload, progressUpload, listFilesBeingUploaded,
}) => {
  const [{
    user, currentChat,
    currentChatUnreadMessagesCounter, previousChat,
  }, dispatch] = useContext(GlobalContext);
  const [messagesFiles, setMessagesFiles] = useState([]);
  const { enqueueSnackbar } = useSnackbar();
  const [isErrorLoadMore, setStatusErrorLoadMore] = useState(false);
  const params = useParams();
  const { companyId, chatId } = params;

  const [messageRef, lastMessageRef, searchMessageId, goToMessage] = useSearchAutoScrollMessage({
    list: messagesFiles,
    keyQueryProperty: 'messageId',
  });

  usePrivateMessageSocket({
    chatId: params.chatId,
    userId: user?._id,
  }, dispatch);

  const {
    openFileViewer,
    fileViewerObj,
    handleOpenAndSetFileViewer,
    handleCloseFileViewer,
  } = useFileViewer();

  const sortingCurrentChat = useCallback(
    () => ChatActions.sortingChats(currentChat?.chats), [currentChat],
  );
  const checkIfChatNeedToLoad = useCallback(() => {
    if (!isNeedCheckLoadMore || isErrorLoadMore) return false;

    return checkLengthChatToLimit(previousChat);
  }, [isNeedCheckLoadMore, previousChat, isErrorLoadMore]);

  const resetPrevChat = useCallback(() => {
    ChatActions.setPreviousChat({ previousChat: initialState.previousChat }, dispatch);
    ChatActions.setCurrentChat({ currentChat: initialState.currentChat }, dispatch);
  }, []);

  useEffect(() => () => resetPrevChat(), []);

  useEffect(() => {
    if (currentChat.chats === undefined) return;

    const joinedData = sortingCurrentChat();

    const prevArray = [...messagesFiles];
    const updatedItems = differenceWith(joinedData, prevArray, isEqual);

    if (updatedItems.length > 0) {
      setMessagesFiles([...joinedData]);
    }
  }, [currentChat]);

  const handleLoadMoreMessages = async () => {
    try {
      const result = await ChatActions.loadMoreChats(
        { chatId, currentChat, companyId },
        dispatch,
      );
    } catch (error) {
      const status = handleStatusMsg(error, 'error');

      if (status?.message) {
        enqueueSnackbar(status.message, {
          variant: 'error',
        });
      }

      setStatusErrorLoadMore(true);
    }
  };

  return (
    <div id="list-messages-section" className={styles.container}>
      <InfinityScroll
        scrollableTarget="list-messages-section"
        inverse
        dataLength={messagesFiles?.length}
        next={handleLoadMoreMessages}
        hasMore={checkIfChatNeedToLoad()}
        initialScrollY={0}
        loader={<div className={styles.infiniteScroll}><Spinner animation="border" size="lg" variant="warning" /></div>}
        emptyMessage={(
          <>
            <div className={styles.infiniteScroll}>
              {messagesFiles.length === 0
                ? <p>No messages here yet...</p>
                : <p>That&apos;s all, folks! 🥳</p>}
            </div>
          </>
            )}
        style={{ display: 'flex', flexDirection: 'column-reverse' }}
      >
        <FeedbackLoadingBlock
          loadingUpload={loadingUpload}
          progressUpload={progressUpload}
          listFilesBeingUploaded={reverseArray(listFilesBeingUploaded)}
          backgroundColorItem={Color.white}
          heightItem="50px"
        />
        {messagesFiles.map((messageFile, index) => {
          const isSameDate = handleCompareDate(messagesFiles, messageFile, (index + 1), 'createdAt');
          const isToday = handleCheckToday(messageFile);

          const isLastIndex = index === (messagesFiles.length - 1);
          const isMessageMatchQuery = searchMessageId === messageFile._id;

          let usedRef;

          if (isLastIndex) {
            usedRef = lastMessageRef;
          }

          if (isMessageMatchQuery) {
            usedRef = messageRef;
            goToMessage();
          }
          if (!isSameDate) {
            return (
              <>
                <Message
                  messageFile={messageFile}
                  owner={messageFile?.creator?._id === user?._id ? 'self' : 'other'}
                  isToday={isToday}
                  ref={usedRef}
                  handleOpenAndSetFileViewer={handleOpenAndSetFileViewer}
                />
                <DateSeparator date={messageFile.createdAt} />
                {index === currentChatUnreadMessagesCounter - 1
                  ? <UnreadMessagesSeparator /> : null}
              </>
            );
          }
          return (
            <>
              <Message
                messageFile={messageFile}
                owner={messageFile?.creator?._id === user?._id ? 'self' : 'other'}
                isToday={isToday}
                ref={usedRef}
                handleOpenAndSetFileViewer={handleOpenAndSetFileViewer}
              />
              {index === currentChatUnreadMessagesCounter - 1
                ? <UnreadMessagesSeparator /> : null}
            </>
          );
        })}
      </InfinityScroll>
      <FileViewerDialog
        open={openFileViewer}
        onClose={handleCloseFileViewer}
        file={fileViewerObj}
      />
    </div>
  );
};

FetchMessages.propTypes = {
  isNeedCheckLoadMore: PropTypes.bool.isRequired,
  checkLengthChatToLimit: PropTypes.func.isRequired,
  loadingUpload: PropTypes.bool.isRequired,
  listFilesBeingUploaded: PropTypes.array.isRequired,
  progressUpload: PropTypes.array.isRequired,
};

export default memo(FetchMessages);
