import React, {
  useState, useContext, useEffect, useCallback,
} from 'react';
import Form from 'react-bootstrap/Form';
import { useParams } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import PropTypes from 'prop-types';
import Switch from '@mui/material/Switch';
import Button from '../../../../components/UI/Button/Button';
import styles from './CreateFolderContainer.module.css';
import { GlobalContext } from '../../../../contexts/GlobalStateProvider';
import handleStatusMsg from '../../../../utilities/handleStatusMsg';
import handleLoadings from '../../../../utilities/handleLoadings';
import { BucketActions } from '../../../../actions';

const CreateFolderContainer = ({ cancel }) => {
  const [{ currentTeam }, dispatch] = useContext(GlobalContext);
  const [newFolder, setNewFolder] = useState();
  const [postNewFolder, setPostNewFolder] = useState();
  const [loadings, setLoadings] = useState([]);
  const { enqueueSnackbar } = useSnackbar();
  const [isAccessPrivate, setAccessPrivate] = useState(false);

  const params = useParams();
  const { companyId, teamId, bucketId } = params;
  useEffect(() => {
    if (postNewFolder === undefined) {
      return;
    }

    const postApiFolder = async () => {
      const data = {
        selector: {
          teamId: currentTeam._id,
          localParentBucketId: params.bucketId,
        },
        data: {
          title: postNewFolder.value,
          isPublic: !isAccessPrivate,
        },
      };

      try {
        const startLoadings = handleLoadings('createBucket', [...loadings], 'start');
        setLoadings([...startLoadings]);

        const result = await BucketActions.createBucket({ body: data, teamId, companyId });
        const status = handleStatusMsg(result, 'success');

        enqueueSnackbar(status.message, {
          variant: 'success',
        });

        // close this container after finished
        cancel();
      } catch (err) {
        const status = handleStatusMsg(err, 'error');

        enqueueSnackbar(status.message, {
          variant: 'error',
        });
      } finally {
        const endLoadings = handleLoadings('createBucket', [...loadings], 'end');
        setLoadings([...endLoadings]);
      }
    };
    postApiFolder();
  }, [postNewFolder, isAccessPrivate]);

  const handleChanged = (event) => {
    const { value } = event.target;

    setNewFolder((prevValue) => ({
      ...prevValue,
      value,
    }));
  };

  const handlePostNewFolder = () => {
    if (newFolder === undefined || newFolder.value === undefined || newFolder.value.length < 1) {
      enqueueSnackbar('Folder name cannot be empty', {
        variant: 'error',
      });
      return;
    }

    setPostNewFolder(newFolder);
  };

  const handleChangeAccess = useCallback((event) => {
    // event.preventDefault();
    setAccessPrivate(event?.target?.checked);
  }, []);

  return (
    <div>
      <div className={styles.sectionSmallTitle}>
        <Form onSubmit={(event) => {
          event.preventDefault();
          handlePostNewFolder();
        }}
        >
          <Form.Control autoFocus onChange={(event) => handleChanged(event)} as="input" placeholder="Masukkan nama folder..." />
        </Form>
      </div>

      <div className={styles.togglePrivateCard}>
        <Switch
          color="primary"
          size="small"
          onChange={handleChangeAccess}
        />
        <h1>Folder Rahasia</h1>
      </div>

      <div className={styles.sectionFooter}>
        <Button handleClick={handlePostNewFolder} variant="success" size="sm" wait="createBucket" loadings={loadings}>Buat</Button>
      </div>
    </div>
  );
};

CreateFolderContainer.propTypes = {
  cancel: PropTypes.func.isRequired,
};

export default CreateFolderContainer;
