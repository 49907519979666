import React, { useState } from 'react';
import CloseIcon from '@mui/icons-material/Close';
import { useHistory, useLocation } from 'react-router-dom';
import EditIcon from '@mui/icons-material/Edit';
import PropTypes from 'prop-types';
import BoxPopOver from '../../../components/UI/BoxPopOver/BoxPopOver';
import SeparatorLine from '../../../components/UI/SeparatorLine/SeparatorLine';
import styles from './OccurrenceMenu.module.css';
import OverlayButton from '../../../components/UI/Button/OverlayButton/OverlayButton';

const OccurrenceMenu = ({ occurrence, cancel }) => {
  const [loadings, setLoadings] = useState([]);

  const history = useHistory();
  const location = useLocation();

  // useEffect(() => {
  //   if (archiveOccurrence === undefined) return;

  //   const archivedApiEvent = async () => {
  //     const occurrenceId = archiveOccurrence;
  //     const eventId = occurrence.event;

  //     let url = `${process.env.REACT_APP_PRIMARY_API_URL} + "/api/v1/events/"
  // + eventId + '/archived'`

  //     try {
  //       const startLoadings = handleLoadings('occurrenceMenu', [...loadings] , 'start');
  //       setLoadings([...startLoadings]);

  //       const result = await axios.patch(,{},{withCredentials: true,
  // headers: {Authorization: "jwt " + localStorage.getItem('token')},});

  //       dispatch({
  //         type: actionTypes.SET_CURRENT_OCCURRENCE,
  //         currentOccurrence: _.cloneDeep(result.data.occurrence)
  //       });

  //       const status = handleStatusMsg(result, 'success');

  //       enqueueSnackbar(status.message, {
  //         variant: 'success',
  //       });

  //       cancel();
  //     } catch (err) {
  //       const status = handleStatusMsg(err, 'error');

  //       enqueueSnackbar(status.message, {
  //         variant: 'error',
  //       });
  //     } finally {
  //       const endLoadings = handleLoadings('occurrenceMenu', [...loadings] , 'end');
  //       setLoadings([...endLoadings]);

  //     }
  //   };
  //   archivedApiEvent();

  // }, [archiveOccurrence])

  // const handleArchivedOccurrence = occurrenceId => {
  //   setArchiveOccurrence(occurrenceId)
  // }

  const handleEditOccurrence = (occurrenceId) => {
    history.push(`${location.pathname}/edit`);
  };

  return (
    <BoxPopOver>
      <div className={styles.headerSection}>
        <h1>
          Menu
        </h1>
        <CloseIcon onClick={cancel} />
      </div>
      <SeparatorLine />
      <div className={styles.bodySection}>
        <OverlayButton wait="occurrenceMenu" loadings={loadings}>
          <div onClick={() => handleEditOccurrence(occurrence._id)} className={styles.menu}>
            <EditIcon />
            <p>Ubah</p>
          </div>
          {/* {occurrence.archived ? <>{occurrence.archived.status ? <div className={styles.menu}>
          <p>This occurrence is already archived</p>
        </div> : <div
        onClick={() => handleArchivedOccurrence(occurrence._id)} className={styles.menu}>
          <p>Archive This Event</p>
        </div>}</> : null} */}
        </OverlayButton>
      </div>
      {/* <div className={styles.actionSection}>
        <Button variant="danger" size="sm" block>Delete</Button>
      </div> */}
    </BoxPopOver>
  );
};

OccurrenceMenu.propTypes = {
  occurrence: PropTypes.object.isRequired,
  cancel: PropTypes.func.isRequired,
};

export default OccurrenceMenu;
