/* eslint-disable consistent-return */
import { useCallback, useEffect, useState } from 'react';
import { UserTypingActions } from '../actions';
import {
  connectSocketUserTypingChat,
  connectSocketUserTypingGroupChat,
  connectSocketUserTypingCard,
  connectSocketUserTypingPost,
  connectSocketUserTypingDoc,
  connectSocketUserTypingFile,
  connectSocketUserTypingEvent,
  connectSocketUserTypingOccurrence,
  connectSocketUserTypingQuestion,
  connectSocketUserTypingDiscussion,
} from '../services/socket';
import { useSocketHooks } from './SocketHooks';
import { useDebounce } from './HelperHooks';
import { emitUpdateTypingStatus } from '../actions/UserTypingActions';
import { socketTypingStatus } from '../constants/UserTypingConstants';

const useIsTyping = ({
  debounceDelay = 1500,
}) => {
  const [isTyping, setIsTyping] = useState(false);
  const [keyword, setKeyword] = useState('');
  const debouncedValue = useDebounce(keyword, debounceDelay);
  const handleTyping = useCallback((value) => {
    setKeyword(value);
  }, []);

  const handleStatusTyping = useCallback((value) => {
    if (value === isTyping) return;
    setIsTyping(value);
  }, [isTyping]);

  useEffect(() => {
    if (keyword !== debouncedValue) {
      if (!isTyping) handleStatusTyping(true);
    } else if (isTyping) handleStatusTyping(false);
  }, [keyword, debouncedValue]);

  return [isTyping, handleTyping];
};

const useUpdateUserTypingStatus = ({ isTyping, socket, userId }) => {
  const isTypingDebounced = useDebounce(isTyping, 1500);

  useEffect(() => {
    if (!socket) return;
    if (!userId) return;
    if (isTypingDebounced) {
      emitUpdateTypingStatus({
        socket,
        userId,
        typingStatus: socketTypingStatus.START,
      });
    } else {
      emitUpdateTypingStatus({
        socket,
        userId,
        typingStatus: socketTypingStatus.STOP,
      });
    }
  }, [isTypingDebounced]);
};

function useUserTypingChatSocket({ companyId, chatId, userId }, dispatch) {
  const callbackInitiate = useCallback((allUserTypingChat) => {
    UserTypingActions.incomingInitiateAllUserTypingChat({
      allUserTypingChat,
    }, dispatch);
  }, []);

  const callbackUpdateStatus = useCallback((userTypingChat) => {
    UserTypingActions.incomingUserTypingChatUpdate({
      userTypingChat,
    }, dispatch);
  }, []);

  const listenSocket = (socket, params) => {
    socket
      .on('initiate', callbackInitiate)
      .on('update-status', callbackUpdateStatus);
  };
  const removeListener = (socket, params) => {
    socket
      .off('initiate')
      .off('update-status');
    socket.disconnect();
    UserTypingActions.resetAllUserTypingChat(dispatch);
  };

  const { socket } = useSocketHooks({
    params: { companyId, chatId, userId },
    connectSocket: connectSocketUserTypingChat,
    listenSocket,
    removeListener,
  });

  return {
    socket,
  };
}

function useUserTypingGroupChatSocket({
  companyId, groupChatId, userId, teamId,
}, dispatch) {
  const callbackInitiate = useCallback((allUserTypingGroupChat) => {
    UserTypingActions.incomingInitiateAllUserTypingGroupChat({
      allUserTypingGroupChat,
    }, dispatch);
  }, []);

  const callbackUpdateStatus = useCallback((userTypingGroupChat) => {
    UserTypingActions.incomingUserTypingGroupChatUpdate({
      userTypingGroupChat,
    }, dispatch);
  }, []);

  const listenSocket = (socket, params) => {
    socket
      .on('initiate', callbackInitiate)
      .on('update-status', callbackUpdateStatus);
  };
  const removeListener = (socket, params) => {
    socket
      .off('initiate')
      .off('update-status');
    socket.disconnect();
    UserTypingActions.resetAllUserTypingGroupChat(dispatch);
  };

  const { socket } = useSocketHooks({
    params: {
      companyId, groupChatId, teamId, userId,
    },
    connectSocket: connectSocketUserTypingGroupChat,
    listenSocket,
    removeListener,
  });

  return {
    socket,
  };
}

function useUserTypingCardSocket({
  companyId, cardId, userId, teamId,
}, dispatch) {
  const callbackInitiate = useCallback((allUserTypingCard) => {
    UserTypingActions.incomingInitiateAllUserTypingCard({
      allUserTypingCard,
    }, dispatch);
  }, []);

  const callbackUpdateStatus = useCallback((userTypingCard) => {
    UserTypingActions.incomingUserTypingCardUpdate({
      userTypingCard,
    }, dispatch);
  }, []);

  const listenSocket = (socket, params) => {
    socket
      .on('initiate', callbackInitiate)
      .on('update-status', callbackUpdateStatus);
  };
  const removeListener = (socket, params) => {
    socket
      .off('initiate')
      .off('update-status');
    socket.disconnect();
    UserTypingActions.resetAllUserTypingCard(dispatch);
  };

  const { socket } = useSocketHooks({
    params: {
      companyId, cardId, teamId, userId,
    },
    connectSocket: connectSocketUserTypingCard,
    listenSocket,
    removeListener,
  });

  return {
    socket,
  };
}

function useUserTypingPostSocket({
  companyId, postId, userId, teamId,
}, dispatch) {
  const callbackInitiate = useCallback((allUserTypingPost) => {
    UserTypingActions.incomingInitiateAllUserTypingPost({
      allUserTypingPost,
    }, dispatch);
  }, []);

  const callbackUpdateStatus = useCallback((userTypingPost) => {
    UserTypingActions.incomingUserTypingPostUpdate({
      userTypingPost,
    }, dispatch);
  }, []);

  const listenSocket = (socket, params) => {
    socket
      .on('initiate', callbackInitiate)
      .on('update-status', callbackUpdateStatus);
  };
  const removeListener = (socket, params) => {
    socket
      .off('initiate')
      .off('update-status');
    socket.disconnect();
    UserTypingActions.resetAllUserTypingPost(dispatch);
  };

  const { socket } = useSocketHooks({
    params: {
      companyId, postId, teamId, userId,
    },
    connectSocket: connectSocketUserTypingPost,
    listenSocket,
    removeListener,
  });

  return {
    socket,
  };
}

function useUserTypingDocSocket({
  companyId, docId, userId, teamId,
}, dispatch) {
  const callbackInitiate = useCallback((allUserTypingDoc) => {
    UserTypingActions.incomingInitiateAllUserTypingDoc({
      allUserTypingDoc,
    }, dispatch);
  }, []);

  const callbackUpdateStatus = useCallback((userTypingDoc) => {
    UserTypingActions.incomingUserTypingDocUpdate({
      userTypingDoc,
    }, dispatch);
  }, []);

  const listenSocket = (socket, params) => {
    socket
      .on('initiate', callbackInitiate)
      .on('update-status', callbackUpdateStatus);
  };
  const removeListener = (socket, params) => {
    socket
      .off('initiate')
      .off('update-status');
    socket.disconnect();
    UserTypingActions.resetAllUserTypingDoc(dispatch);
  };

  const { socket } = useSocketHooks({
    params: {
      companyId, docId, teamId, userId,
    },
    connectSocket: connectSocketUserTypingDoc,
    listenSocket,
    removeListener,
  });

  return {
    socket,
  };
}

function useUserTypingFileSocket({
  companyId, fileId, userId, teamId,
}, dispatch) {
  const callbackInitiate = useCallback((allUserTypingFile) => {
    UserTypingActions.incomingInitiateAllUserTypingFile({
      allUserTypingFile,
    }, dispatch);
  }, []);

  const callbackUpdateStatus = useCallback((userTypingFile) => {
    UserTypingActions.incomingUserTypingFileUpdate({
      userTypingFile,
    }, dispatch);
  }, []);

  const listenSocket = (socket, params) => {
    socket
      .on('initiate', callbackInitiate)
      .on('update-status', callbackUpdateStatus);
  };
  const removeListener = (socket, params) => {
    socket
      .off('initiate')
      .off('update-status');
    socket.disconnect();
    UserTypingActions.resetAllUserTypingFile(dispatch);
  };

  const { socket } = useSocketHooks({
    params: {
      companyId, fileId, teamId, userId,
    },
    connectSocket: connectSocketUserTypingFile,
    listenSocket,
    removeListener,
  });

  return {
    socket,
  };
}

function useUserTypingEventSocket({
  companyId, eventId, userId, teamId,
}, dispatch) {
  const callbackInitiate = useCallback((allUserTypingEvent) => {
    UserTypingActions.incomingInitiateAllUserTypingEvent({
      allUserTypingEvent,
    }, dispatch);
  }, []);

  const callbackUpdateStatus = useCallback((userTypingEvent) => {
    UserTypingActions.incomingUserTypingEventUpdate({
      userTypingEvent,
    }, dispatch);
  }, []);

  const listenSocket = (socket, params) => {
    socket
      .on('initiate', callbackInitiate)
      .on('update-status', callbackUpdateStatus);
  };
  const removeListener = (socket, params) => {
    socket
      .off('initiate')
      .off('update-status');
    socket.disconnect();
    UserTypingActions.resetAllUserTypingEvent(dispatch);
  };

  const { socket } = useSocketHooks({
    params: {
      companyId, eventId, teamId, userId,
    },
    connectSocket: connectSocketUserTypingEvent,
    listenSocket,
    removeListener,
  });

  return {
    socket,
  };
}

function useUserTypingOccurrenceSocket({
  companyId, eventId, occurrenceId, userId, teamId,
}, dispatch) {
  const callbackInitiate = useCallback((allUserTypingOccurrence) => {
    UserTypingActions.incomingInitiateAllUserTypingOccurrence({
      allUserTypingOccurrence,
    }, dispatch);
  }, []);

  const callbackUpdateStatus = useCallback((userTypingOccurrence) => {
    UserTypingActions.incomingUserTypingOccurrenceUpdate({
      userTypingOccurrence,
    }, dispatch);
  }, []);

  const listenSocket = (socket, params) => {
    socket
      .on('initiate', callbackInitiate)
      .on('update-status', callbackUpdateStatus);
  };
  const removeListener = (socket, params) => {
    socket
      .off('initiate')
      .off('update-status');
    socket.disconnect();
    UserTypingActions.resetAllUserTypingOccurrence(dispatch);
  };

  const { socket } = useSocketHooks({
    params: {
      companyId, eventId, occurrenceId, teamId, userId,
    },
    connectSocket: connectSocketUserTypingOccurrence,
    listenSocket,
    removeListener,
  });

  return {
    socket,
  };
}

function useUserTypingQuestionSocket({
  companyId, questionId, userId, teamId,
}, dispatch) {
  const callbackInitiate = useCallback((allUserTypingQuestion) => {
    UserTypingActions.incomingInitiateAllUserTypingQuestion({
      allUserTypingQuestion,
    }, dispatch);
  }, []);

  const callbackUpdateStatus = useCallback((userTypingQuestion) => {
    UserTypingActions.incomingUserTypingQuestionUpdate({
      userTypingQuestion,
    }, dispatch);
  }, []);

  const listenSocket = (socket, params) => {
    socket
      .on('initiate', callbackInitiate)
      .on('update-status', callbackUpdateStatus);
  };
  const removeListener = (socket, params) => {
    socket
      .off('initiate')
      .off('update-status');
    socket.disconnect();
    UserTypingActions.resetAllUserTypingQuestion(dispatch);
  };

  const { socket } = useSocketHooks({
    params: {
      companyId, questionId, teamId, userId,
    },
    connectSocket: connectSocketUserTypingQuestion,
    listenSocket,
    removeListener,
  });

  return {
    socket,
  };
}

function useUserTypingDiscussionSocket({
  companyId, commentId, userId, teamId,
}, dispatch) {
  const callbackInitiate = useCallback((allUserTypingDiscussion) => {
    UserTypingActions.incomingInitiateAllUserTypingDiscussion({
      allUserTypingDiscussion,
    }, dispatch);
  }, []);

  const callbackUpdateStatus = useCallback((userTypingDiscussion) => {
    UserTypingActions.incomingUserTypingDiscussionUpdate({
      userTypingDiscussion,
    }, dispatch);
  }, []);

  const listenSocket = (socket, params) => {
    socket
      .on('initiate', callbackInitiate)
      .on('update-status', callbackUpdateStatus);
  };
  const removeListener = (socket, params) => {
    socket
      .off('initiate')
      .off('update-status');
    socket.disconnect();
    UserTypingActions.resetAllUserTypingDiscussion(dispatch);
  };

  const { socket } = useSocketHooks({
    params: {
      companyId, commentId, teamId, userId,
    },
    connectSocket: connectSocketUserTypingDiscussion,
    listenSocket,
    removeListener,
  });

  return {
    socket,
  };
}

export {
  useUserTypingChatSocket,
  useUserTypingGroupChatSocket,
  useUserTypingCardSocket,
  useUserTypingPostSocket,
  useUserTypingDocSocket,
  useUserTypingFileSocket,
  useUserTypingEventSocket,
  useUserTypingOccurrenceSocket,
  useUserTypingQuestionSocket,
  useUserTypingDiscussionSocket,
  useIsTyping,
  useUpdateUserTypingStatus,
};
