import React, {
  memo, useContext, useEffect, useState,
} from 'react';
import PropTypes from 'prop-types';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { useSnackbar } from 'notistack';
import { useParams, useLocation } from 'react-router-dom';
import styles from './BottleneckUserReport.module.css';
import Color from '../../../../themes/colors';
import TitleChart from '../ChartUserReport/TitleChart/TitleChart';
import BottleneckItem from './BottleneckItem/BottleneckItem';
import handleLoadings from '../../../../utilities/handleLoadings';
import handleStatusMsg from '../../../../utilities/handleStatusMsg';
import { GlobalContext } from '../../../../contexts/GlobalStateProvider';
import { initiateReportListBottleneckBacklogCard, initiateReportListBottleneckOngoingCard } from '../../../../actions/ReportActions';
import { FeedbackLoadingMain } from '../../../../components/UI_V2/Feedback/Loading';
import SideUserReportSkeleton from '../../SidebarUserReport/SideUserReportSkeleton/SideUserReportSkeleton';

const BottleneckUserReport = ({
  allCount,
  allPercentage,
  fromDate,
  untilDate,
}) => {
  const [
    {
      currentTeam,
    },
    dispatch,
  ] = useContext(GlobalContext);
  const [loadings, setLoadings] = useState([]);
  const [members, setMembers] = useState([]);
  const { enqueueSnackbar } = useSnackbar();

  const params = useParams();
  const location = useLocation();

  const { teamId, memberId, companyId } = params;

  const [currentReportListBottleneckBacklogCard,
    setCurrentReportListBottleneckBacklogCard] = useState();
  const [currentReportListBottleneckOngoingCard,
    setCurrentReportListBottleneckOngoingCard] = useState();

  useEffect(() => {
    const startLoadings = handleLoadings('initiateBottleneck', [...loadings], 'start');
    setLoadings([...startLoadings]);

    if (!currentTeam || currentTeam?._id !== teamId) {
      return;
    }

    const initiateBottleneckBacklogApi = async () => {
      try {
        const result = await initiateReportListBottleneckBacklogCard({
          teamId,
          memberId,
          companyId,
          fromDate,
          untilDate,
          setCurrentReportListBottleneckBacklogCard,
        });
      } catch (error) {
        const status = handleStatusMsg(error, 'error');

        enqueueSnackbar(status.message, {
          variant: 'error',
        });
      } finally {
        const endLoadings = handleLoadings('initiateBottleneck', [...loadings], 'end');
        setLoadings([...endLoadings]);
      }
    };

    initiateBottleneckBacklogApi();
  }, [location, fromDate, untilDate]);

  useEffect(() => {
    const startLoadings = handleLoadings('initiateBottleneck', [...loadings], 'start');
    setLoadings([...startLoadings]);

    if (!currentTeam || currentTeam?._id !== teamId) {
      return;
    }

    const initiateBottleneckOngoingApi = async () => {
      try {
        const result = await initiateReportListBottleneckOngoingCard({
          teamId,
          memberId,
          companyId,
          fromDate,
          untilDate,
          setCurrentReportListBottleneckOngoingCard,
        });
      } catch (error) {
        const status = handleStatusMsg(error, 'error');

        enqueueSnackbar(status.message, {
          variant: 'error',
        });
      } finally {
        const endLoadings = handleLoadings('initiateBottleneck', [...loadings], 'end');
        setLoadings([...endLoadings]);
      }
    };

    initiateBottleneckOngoingApi();
  }, [location, fromDate, untilDate]);

  const bottleneckBacklogCount = allCount?.[4];
  const bottleneckOngoingCount = allCount?.[5];
  const backlogCount = allCount?.[0];
  const ongoingCount = allCount?.[1];

  return (
    <div className={styles.container}>
      <div className={styles.title}>
        <TitleChart>
          Kemungkinan Penghambat
        </TitleChart>
      </div>
      <div className={styles.body}>
        <FeedbackLoadingMain
          wait="initiateBottleneck"
          loadings={loadings}
          loadingComponent={<SideUserReportSkeleton />}
        >
          <div className={styles.item}>
            <BottleneckItem
              contentRatio={`${bottleneckBacklogCount} dari ${backlogCount} tugas yg belum`}
              titleRatio="masih belum dikerjakan juga padahal udah >40 hari"
              titleCard="Top 1 tugas terlama belum dikerjakan"
              card={currentReportListBottleneckBacklogCard}
            />
          </div>
        </FeedbackLoadingMain>
        <FeedbackLoadingMain
          wait="initiateBottleneck"
          loadings={loadings}
          loadingComponent={<SideUserReportSkeleton />}
        >
          <div className={styles.item}>
            <BottleneckItem
              contentRatio={`${bottleneckOngoingCount} dari ${ongoingCount} tugas yg dikerjakan`}
              titleRatio="masih belum selesai juga padahal udah >40 hari"
              titleCard="Top 1 tugas terlama sedang dikerjakan"
              card={currentReportListBottleneckOngoingCard}
            />
          </div>
        </FeedbackLoadingMain>
      </div>
    </div>
  );
};

BottleneckUserReport.propTypes = {
  allCount: PropTypes.array.isRequired,
  allPercentage: PropTypes.array.isRequired,
  fromDate: PropTypes.string.isRequired,
  untilDate: PropTypes.string.isRequired,
};

BottleneckUserReport.defaultProps = {};

export default memo(BottleneckUserReport);
