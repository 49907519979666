import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { Box } from '@mui/material';
import styles from './TeamFilterSection.module.css';
import { useDelayShowHideHandler, useSearch, useSelectItemsOnList } from '../../../../hooks/HelperHooks';
import { InputSearchGeneral } from '../../../UI_V2/Input/Search';
import { DisplayTextBody } from '../../../UI_V2/Display/Text';
import ButtonTextWithCheck from '../ButtonTextWithCheck/ButtonTextWithCheck';
import ButtonDiv from '../../../UI/Button/ButtonDiv/ButtonDiv';
import { limitChar } from '../../../../utilities/stringUtil';
import ButtonTextWithCheckBox from '../ButtonTextWithCheckBox/ButtonTextWithCheckBox';
import { DisplayDividerMain } from '../../../UI_V2/Display/Divider';
import Color from '../../../../themes/colors';

const TeamFilterSection = ({
  searchHook,
  showAllTeams,
  handleShowAllTeams,
  handleHideAllTeams,
  checkTeams,
  handleClickTeam,
  handleClickAllTeams,
  isAllTeamsSelected
}) => {
  const {
    searchResult,
    keyword,
    keywordArray,
    setKeyword,
    isTyping,
    onChange,
    resetSearch,
  } = searchHook;

  return (
    <Box className={styles.container}>
      <Box className={styles.titleBox}>
        <DisplayTextBody>
          Filter Tim
        </DisplayTextBody>
      </Box>
      <Box className={styles.searchBox}>
        <InputSearchGeneral
          onChange={onChange}
          value={keyword}
          placeholder="Cari tim..."
          onClickCloseIcon={resetSearch}
          size="sm"
          autoFocus={false}
        />
      </Box>
      <DisplayDividerMain borderColor={Color.gray6} marginTop="0.4rem" marginBottom="0.4rem" />
      <div className={styles.selectAll}>
        <ButtonTextWithCheckBox
          text="Pilih Semua"
          textDecoration="bold"
          textMode="12"
          onClick={handleClickAllTeams}
          checked={isAllTeamsSelected}
        />
      </div>
      <DisplayDividerMain borderColor={Color.gray6} marginTop="0.4rem" marginBottom="0px" />
      <Box className={styles.body}>
        {searchResult.length < 1
          && <span>Tim ga ketemu</span>}
        {/* {searchResult.length > 0
          && (
          <ButtonTextWithCheck
            text="All"
            onClick={() => handleClickAll(teams)}
            checked={isAllTeamsSelected}
          />
          )} */}
        {searchResult.map((team, index) => (
          <ButtonTextWithCheckBox
            text={limitChar(team?.name, 30)}
            onClick={() => handleClickTeam(team._id)}
            checked={checkTeams.some((checkTeam) => checkTeam === team._id)}
          />
        ))}
        {/* {showAllTeams
          ? (
            <>
              {searchResult.map((team, index) => {
                if (index === (searchResult.length - 1)) {
                  return (
                    <>
                      <ButtonTextWithCheck
                        text={limitChar(team?.name, 30)}
                        onClick={() => handleClickTeam(team._id)}
                        checked={checkTeams.some((checkTeam) => checkTeam === team._id)}
                      />
                      {index >= 4 && (
                      <ButtonDiv onClick={handleHideAllTeams} className={styles.menu}>
                        <p>Hide some teams</p>
                      </ButtonDiv>
                      )}
                    </>
                  );
                }
                return (
                  <ButtonTextWithCheck
                    text={limitChar(team?.name, 30)}
                    onClick={() => handleClickTeam(team._id)}
                    checked={checkTeams.some((checkTeam) => checkTeam === team._id)}
                  />
                );
              })}
            </>
          )
          : (
            <>
              {searchResult.map((team, index) => {
                if (index < 3) {
                  return (
                    <ButtonTextWithCheck
                      text={limitChar(team?.name, 30)}
                      onClick={() => handleClickTeam(team._id)}
                      checked={checkTeams.some((checkTeam) => checkTeam === team._id)}
                    />
                  );
                }
                if (index === 3) {
                  return (
                    <>
                      <ButtonTextWithCheck
                        text={limitChar(team?.name, 30)}
                        onClick={() => handleClickTeam(team._id)}
                        checked={checkTeams.some((checkTeam) => checkTeam === team._id)}
                      />
                      {index !== searchResult.length - 1 && (
                      <ButtonDiv onClick={handleShowAllTeams} className={styles.menu}>
                        <p>
                          Show more (
                          {`${searchResult.length - 4} hidden`}
                          )
                        </p>
                      </ButtonDiv>
                      )}
                    </>
                  );
                }
                return null;
              })}
            </>
          )} */}
      </Box>
    </Box>
  );
};

TeamFilterSection.propTypes = {
  // teams: PropTypes.array,
  searchHook: PropTypes.object.isRequired,
  showAllTeams: PropTypes.bool.isRequired,
  handleShowAllTeams: PropTypes.func.isRequired,
  handleHideAllTeams: PropTypes.func.isRequired,
  checkTeams: PropTypes.array.isRequired,
  handleClickTeam: PropTypes.func.isRequired,
  handleClickAllTeams: PropTypes.func.isRequired,
  isAllTeamsSelected: PropTypes.bool.isRequired,
};

// TeamFilterSection.defaultProps = {
//   teams: [],
// };

export default memo(TeamFilterSection);
