import { isNil, isObject } from 'lodash';

function getSessionLastOpenedCompany({ userId }) {
  const lastOpenedCompany = window.sessionStorage.getItem(`lastOpenedCompany-${userId}`);

  if (lastOpenedCompany) return JSON.parse(lastOpenedCompany);

  return null;
}

function setSessionLastOpenedCompany({ company, userId }) {
  if (company === undefined || userId === undefined) return null;

  let valueSession = company;

  if (isObject(company)) {
    valueSession = JSON.stringify(company);
  }

  return window.sessionStorage.setItem(`lastOpenedCompany-${userId}`, valueSession);
}

function getSessionBoardCardView({ companyId, userId }) {
  const boardCardView = window.sessionStorage.getItem(`boardCardView-${userId}-${companyId}`);

  if (boardCardView) return JSON.parse(boardCardView);

  return null;
}

function setSessionBoardCardView({ companyId, userId, view }) {
  if (view === undefined) return null;

  let valueStore = view;

  if (isObject(view)) {
    valueStore = JSON.stringify(view);
  }

  return window.sessionStorage.setItem(`boardCardView-${userId}-${companyId}`, valueStore);
}

function getSessionMyTaskCardView({ companyId, userId }) {
  const myTaskCardView = window.sessionStorage.getItem(`myTaskCardView-${userId}-${companyId}`);

  if (myTaskCardView) return JSON.parse(myTaskCardView);

  return null;
}

function setSessionMyTaskCardView({ companyId, userId, view }) {
  if (view === undefined) return null;

  let valueStore = view;

  if (isObject(view)) {
    valueStore = JSON.stringify(view);
  }

  return window.sessionStorage.setItem(`myTaskCardView-${userId}-${companyId}`, valueStore);
}

function setSessionWelcomeUserPopUp({ userId, companyId, value }) {
  if (companyId === undefined || userId === undefined
  || !value?.welcomeTutorial
  ) return null;

  let valueStore;

  if (isObject(value)) {
    valueStore = JSON.stringify(value);
  }

  return window.sessionStorage.setItem(`welcomeUserPopUp-${userId}-${companyId}`, valueStore);
}

function getSessionWelcomeUserPopUp({ userId, companyId }) {
  const welcomeUserPopUp = window.sessionStorage.getItem(`welcomeUserPopUp-${userId}-${companyId}`);

  if (welcomeUserPopUp) return JSON.parse(welcomeUserPopUp);

  return null;
}

function getSessionCurrentTeam() {
  const currentTeam = window.sessionStorage.getItem('currentTeam');

  if (currentTeam) return JSON.parse(currentTeam);

  return null;
}

function setSessionCurrentTeam(value) {
  if (value === undefined) return null;

  let valueSession = value;

  if (isObject(value)) {
    valueSession = JSON.stringify(value);
  }

  return window.sessionStorage.setItem('currentTeam', valueSession);
}

export {
  getSessionLastOpenedCompany,
  setSessionLastOpenedCompany,
  getSessionCurrentTeam,
  setSessionCurrentTeam,
  getSessionWelcomeUserPopUp,
  setSessionWelcomeUserPopUp,
  getSessionBoardCardView,
  setSessionBoardCardView,
  getSessionMyTaskCardView,
  setSessionMyTaskCardView,
};
