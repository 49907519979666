import TagManager from 'react-gtm-module';

const baseGTMArguments = {
  gtmId: process.env.REACT_APP_GTM_ID,
};

const connectGTMBase = () => {
  TagManager.initialize(baseGTMArguments);

  return TagManager;
};

export {
  connectGTMBase,
};
