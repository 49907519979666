import React, { memo } from 'react';
import CheckIcon from '@mui/icons-material/Check';
import PropTypes from 'prop-types';
import styles from './ColorBox.module.css';

function ColorBox({ color, clicked, checked }) {
  return (
    <div style={{ backgroundColor: color }} className={styles.ColorBox} onClick={clicked}>
      {checked ? <CheckIcon style={{ fill: 'white', width: '15px' }} /> : null}
    </div>
  );
}

ColorBox.propTypes = {
  color: PropTypes.string.isRequired,
  clicked: PropTypes.func.isRequired,
  checked: PropTypes.bool.isRequired,
};

const isComponentDataEqual = (prevProps, nextProps) => {
  const { color, checked } = nextProps;
  return prevProps.color === color
  && prevProps.checked === checked;
};

export default memo(ColorBox, isComponentDataEqual);
