import React, { memo, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Alert, useMediaQuery } from '@mui/material';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import { useParams } from 'react-router-dom';
import styles from './ResetCreateNewPasswordSection.module.css';
import Color from '../../../themes/colors';
import { InputButtonMain } from '../../../components/UI_V2/Input/Button';
import { InputTextFieldMain } from '../../../components/UI_V2/Input/TextField';
import { DisplayTextBody } from '../../../components/UI_V2/Display/Text';
import { validatePassword } from '../../../utilities/inputValidatorUtil';
import LinkNoDecor from '../../../components/LinkNoDecor/LinkNoDecor';
import { AuthActions } from '../../../actions';
import handleLoadings from '../../../utilities/handleLoadings';
import handleStatusMsg from '../../../utilities/handleStatusMsg';

const ResetCreateNewPasswordSection = () => {
  const params = useParams();

  const [newUser, setNewUser] = useState({
    password: '',
    passwordConfirmation: '',
  });
  const [loadings, setLoadings] = useState([]);

  const [showPassword, setShowPassword] = useState(false);
  const [isValidPassword, setIsValidPassword] = useState(true);
  const [isPasswordEmpty, setIsPasswordEmpty] = useState(true);
  const [isPasswordConfirmationEmpty, setIsPasswordConfirmationEmpty] = useState(true);
  const [isValidPasswordConfirmation, setIsValidPasswordConfirmation] = useState(true);
  const [errorMsgPassword, setErrorMsgPassword] = useState('');
  const [errorMsgPasswordConfirmation, setErrorMsgPasswordConfirmation] = useState('');
  const [errorMsgEmpty, setErrorMsgEmpty] = useState('');
  const [showErrorEmpty, setShowErrorEmpty] = useState(false);
  const [errorMsgClickStillError, setErrorMsgClickStillError] = useState('');
  const [showErrorClickStillError, setShowErrorClickStillError] = useState(false);

  const [showSuccessResetPassword, setShowSuccessResetPassword] = useState(false);
  const [successResetPasswordMsg, setSuccessResetPasswordMsg] = useState('');

  const [showErrorResetPassword, setShowErrorResetPassword] = useState(false);
  const [errorResetPasswordMsg, setErrorResetPasswordMsg] = useState('');

  const [isProcessing, setIsProcessing] = useState(false);

  const [showLoginButton, setShowLoginButton] = useState(false);

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const handleChanged = (event) => {
    const { name, value } = event.target;
    setNewUser((prevValue) => ({
      ...prevValue,
      [name]: value,
    }));

    if (showErrorEmpty) {
      setShowErrorEmpty(false);
      setErrorMsgEmpty('');
    }

    if (showErrorClickStillError) {
      setShowErrorClickStillError(false);
      setErrorMsgClickStillError('');
    }

    if (showSuccessResetPassword) {
      setShowSuccessResetPassword(false);
      setSuccessResetPasswordMsg('');
    }

    if (showErrorResetPassword) {
      setShowErrorResetPassword(false);
      setErrorResetPasswordMsg('');
    }

    if (name === 'password') {
      const isEmpty = value.length < 1;

      if (isEmpty) {
        setIsPasswordEmpty(true);
        setIsValidPassword(true);
        setErrorMsgPassword('');
        return;
      }

      setIsPasswordEmpty(false);

      const { isValid, errorMsg } = validatePassword(value);

      if (isValid) {
        setIsValidPassword(true);
        setErrorMsgPassword('');
      } else {
        setIsValidPassword(false);
        setErrorMsgPassword(errorMsg);
      }

      const isEmptyPasswordConfirmation = newUser.passwordConfirmation.length < 1;

      if (isEmptyPasswordConfirmation) {
        setIsPasswordConfirmationEmpty(true);
        setIsValidPasswordConfirmation(true);
        setErrorMsgPasswordConfirmation('');
        return;
      }

      setIsPasswordConfirmationEmpty(false);

      const isTheSameWithPassword = value === newUser.passwordConfirmation;
      if (isTheSameWithPassword) {
        setIsValidPasswordConfirmation(true);
        setErrorMsgPasswordConfirmation('');
      } else {
        setIsValidPasswordConfirmation(false);
        setErrorMsgPasswordConfirmation('Konfirmasi password belum sama. ');
      }
    }

    if (name === 'passwordConfirmation') {
      const isEmpty = value.length < 1;

      if (isEmpty) {
        setIsPasswordConfirmationEmpty(true);
        setIsValidPasswordConfirmation(true);
        setErrorMsgPasswordConfirmation('');
        return;
      }

      setIsPasswordConfirmationEmpty(false);

      const isTheSameWithPassword = value === newUser.password;
      if (isTheSameWithPassword) {
        setIsValidPasswordConfirmation(true);
        setErrorMsgPasswordConfirmation('');
      } else {
        setIsValidPasswordConfirmation(false);
        setErrorMsgPasswordConfirmation('Konfirmasi password belum sama. ');
      }
    }
  };

  const isThereAnyEmpty = isPasswordEmpty
  || isPasswordConfirmationEmpty;

  const isThereAnyError = !isValidPassword || !isValidPasswordConfirmation;

  const handleClickWhenEmpty = (event) => {
    if (event) {
      event.preventDefault();
    }

    let message = '';

    if (isPasswordEmpty) {
      message = `${message}Password baru masih kosong. `;
    }

    if (isPasswordConfirmationEmpty) {
      message = `${message}Konfirmasi Password baru masih kosong. `;
    }

    setErrorMsgEmpty(message);
    setShowErrorEmpty(true);
  };

  const handleClickWhenStillError = () => {
    setErrorMsgClickStillError('Belum bisa melanjutkan, perbaiki dulu error diatas.');
    setShowErrorClickStillError(true);
  };

  const processResetPasswordApi = async ({
    tokenKey,
    password,
  }) => {
    try {
      const startLoadings = handleLoadings('resetThenCreateNewPassword', [...loadings], 'start');
      setLoadings([...startLoadings]);

      const result = await AuthActions.resetThenCreateNewPassword({
        password,
        tokenKey,
      });

      // const status = handleStatusMsg(result, 'success');

      setShowSuccessResetPassword(true);
      setShowErrorResetPassword(false);
      setSuccessResetPasswordMsg('Reset password berhasil. Silahkan login ulang.');
      setErrorResetPasswordMsg('');
      setShowLoginButton(true);
    } catch (err) {
      const status = handleStatusMsg(err, 'error');

      setShowErrorResetPassword(true);
      setShowSuccessResetPassword(false);
      setErrorResetPasswordMsg(status.message);
      setSuccessResetPasswordMsg('');
    } finally {
      const endLoadings = handleLoadings('resetThenCreateNewPassword', [...loadings], 'end');
      setLoadings([...endLoadings]);
      setIsProcessing(false);
    }
  };

  const handleClickResetPassword = (event) => {
    if (event) {
      event.preventDefault();
    }

    if (isThereAnyEmpty) {
      handleClickWhenEmpty();
      return;
    }

    if (isThereAnyError) {
      handleClickWhenStillError();
      return;
    }

    if (isProcessing) return;

    setIsProcessing(true);

    processResetPasswordApi({
      tokenKey: params?.tokenKey,
      password: newUser.password,
    });
  };

  return (
    <div className={styles.container}>
      <form onSubmit={isThereAnyEmpty ? handleClickWhenEmpty : handleClickResetPassword}>
        <div className={styles.form}>
          <div className={styles.formText}>
            <DisplayTextBody
              decoration="bold"
            >
              Password Baru
            </DisplayTextBody>
          </div>
          <div className={styles.formArea}>
            <InputTextFieldMain
              placeholder="**********"
              name="password"
              onChange={(event) => handleChanged(event)}
              value={newUser.password}
              border="rounded"
              type={showPassword ? 'text' : 'password'}
              size="lg"
              showHelperIcon
              helperIcon={showPassword ? <VisibilityOffIcon /> : <VisibilityIcon />}
              onClickHelperIcon={togglePasswordVisibility}
            />
          </div>
        </div>
        <div className={styles.form}>
          <div className={styles.formText}>
            <DisplayTextBody
              decoration="bold"
            >
              Konfirmasi Password Baru
            </DisplayTextBody>
          </div>
          <div className={styles.formArea}>
            <InputTextFieldMain
              placeholder="**********"
              name="passwordConfirmation"
              onChange={(event) => handleChanged(event)}
              value={newUser.passwordConfirmation}
              border="rounded"
              type={showPassword ? 'text' : 'password'}
              size="lg"
              showHelperIcon
              helperIcon={showPassword ? <VisibilityOffIcon /> : <VisibilityIcon />}
              onClickHelperIcon={togglePasswordVisibility}
            />
          </div>
        </div>
        {!isValidPassword && !isValidPasswordConfirmation && <Alert severity="error">{errorMsgPassword}</Alert>}
        {isValidPassword
                    && !isValidPasswordConfirmation && <Alert severity="error">{errorMsgPasswordConfirmation}</Alert>}
        {!isValidPassword && isValidPasswordConfirmation && <Alert severity="error">{errorMsgPassword}</Alert>}

        {isThereAnyEmpty && showErrorEmpty && <div className={styles.alertEmptySection}><Alert severity="error">{errorMsgEmpty}</Alert></div>}
        {isThereAnyError && showErrorClickStillError && <div className={styles.alertEmptySection}><Alert severity="error">{errorMsgClickStillError}</Alert></div>}
        {showSuccessResetPassword && (
        <div className={styles.alertClickResetPasswordSection}>
          <Alert severity="success">{successResetPasswordMsg}</Alert>
        </div>
        )}
        {showErrorResetPassword && (
        <div className={styles.alertClickResetPasswordSection}>
          <Alert severity="error">{errorResetPasswordMsg}</Alert>
        </div>
        )}
        <div className={styles.action}>
          <InputButtonMain
            border="rounded"
            size="lg"
            wide="block"
            handleClick={isThereAnyEmpty ? handleClickWhenEmpty : handleClickResetPassword}
            loadings={loadings}
            wait="resetThenCreateNewPassword"
            type="submit"
          >
            Reset Password
          </InputButtonMain>
        </div>
      </form>
      {showLoginButton && (
      <div className={styles.loginNowSection}>
        <div className={styles.loginNowSection__text}>
          <DisplayTextBody>
            Silahkan login kembali
          </DisplayTextBody>
        </div>
        <div className={styles.loginNowSection__button}>
          <LinkNoDecor to="/signin">
            <DisplayTextBody color={Color.brightBlue}>
              Disini
            </DisplayTextBody>
          </LinkNoDecor>
        </div>
      </div>
      )}
    </div>
  );
};

ResetCreateNewPasswordSection.propTypes = {};

ResetCreateNewPasswordSection.defaultProps = {};

export default memo(ResetCreateNewPasswordSection);
