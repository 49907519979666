import React, {
  useContext, useEffect,
} from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { actionTypes } from '../../reducers/reducer';
import MainLayout from '../../components/Layout/MainLayout/MainLayout';
import styles from './RedirectUrlPage.module.css';
import { GlobalContext } from '../../contexts/GlobalStateProvider';
import handleStatusMsg from '../../utilities/handleStatusMsg';
import {
  RedirectUrlActions, CheckLoginActions,
} from '../../actions';
import LoadingWithAnimation from '../../components/UI/LoadingWithAnimation/LoadingWithAnimation';

function RedirectUrlPage() {
  const [{ user, currentCompanies, currentCompany }, dispatch] = useContext(GlobalContext);

  const history = useHistory();
  const location = useLocation();

  useEffect(() => {
    if (user._id === undefined) {
      history.push(`/check-login?previousPath=${location.pathname}`);
      return;
    }
    const checkUrlApi = async () => {
      try {
        const url = await RedirectUrlActions.getUrl(
          { locationUrl: location.pathname, currentCompany },
        );
        await CheckLoginActions.initiateCurrentCompany(
          { previousPath: url, companies: currentCompanies, userId: user?._id },
          dispatch,
        );
        history.push(url);
      } catch (error) {
        const status = handleStatusMsg(error, 'error');
        let message = status?.message;
        if (status?.message === 'Something went wrong!'
        || status?.message === 'Something went wrong.'
        ) message = 'Link is not valid';
        dispatch({
          type: actionTypes.SET_ERROR_RESPONSE,
          errorResponse: { message },
        });

        history.push(`/errors?previousPath=${location.pathname}`);
      }
    };
    checkUrlApi();
  }, []);

  return (
    <>
      <MainLayout>
        <LoadingWithAnimation>
          <h1>Please Wait</h1>
          <h1>Checking your link...</h1>
        </LoadingWithAnimation>
      </MainLayout>
    </>
  );
}

export default RedirectUrlPage;
