import React, { memo, useState } from 'react';
import PropTypes from 'prop-types';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import styles from './MemberInvalidSection.module.css';
import Color from '../../../themes/colors';
import FeedbackIntroMain from '../../../components/UI_V2/Feedback/Intro/Main/Main';
import noAccessImage from '../../../assets/BillingPage/no-access.png';
import notMemberImage from '../../../assets/ReportPage/not-member.png';

const typeContent = {
  memberNotInThisTeam: {
    headline: 'Anggota ga ada di Tim ini',
    description: 'Silahkan pilih anggota yang lain ya!',
    illustrationImage: notMemberImage,
  },
  userHaveNoAccess: {
    headline: 'Kamu ga boleh liat anggota ini',
    description: 'Kamu bukan Manager di Tim ini, hanya boleh liat laporan kamu sendiri.',
    illustrationImage: noAccessImage,
  },
};

const MemberInvalidSection = ({ invalidType }) => (
  <div className={styles.container}>
    <FeedbackIntroMain
      headline={typeContent[invalidType].headline}
      description={typeContent[invalidType].description}
      illustrationImage={typeContent[invalidType].illustrationImage}
    />
  </div>
);

MemberInvalidSection.propTypes = {
  invalidType: PropTypes.oneOf(['memberNotInThisTeam', 'userHaveNoAccess']),
};

MemberInvalidSection.defaultProps = {
  invalidType: 'memberNotInThisTeam',
};

export default memo(MemberInvalidSection);
