import React, { memo, useContext } from 'react';
import PropTypes from 'prop-types';
import styles from './Header.module.css';
import { DisplayTextBody } from '../../../../components/UI_V2/Display/Text';
import { GlobalContext, initialState } from '../../../../contexts/GlobalStateProvider';
import BillingPageListCompaniesItem from '../Item/Item';
import Color from '../../../../themes/colors';
import { getSingleOrPlural } from '../../../../utilities/stringUtil';

const BillingPageListCompaniesHeader = ({
  currentCompany,
  companies,
}) => {
  const [
    {
      user,
    },
    dispatch,
  ] = useContext(GlobalContext);
  const finalCompanies = companies.filter(
    (company) => company.admins.some((admin) => (admin._id || admin) === user?._id),
  );

  const totalCompanies = finalCompanies.length;
  return (
    <div className={`${styles.container} `}>
      <div className={styles.companiesCountSection}>
        <DisplayTextBody
          mode="12"
          decoration="bold"
          color={Color.gray3}
          fontFamily="openSans"
        >
          {/* {`${totalCompanies} company ${getSingleOrPlural('billing', totalCompanies)}`} */}
          {`${totalCompanies} tagihan perusahaan`}
        </DisplayTextBody>
      </div>
      {/* <div className={styles.selectedCompanySection}>
        <BillingPageListCompaniesItem isSelected company={currentCompany} />
      </div> */}
    </div>
  );
};

BillingPageListCompaniesHeader.propTypes = {
  currentCompany: PropTypes.object.isRequired,
  companies: PropTypes.array.isRequired,
};

export default memo(BillingPageListCompaniesHeader);
