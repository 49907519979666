import React, { memo } from 'react';
import PropTypes from 'prop-types';
import styles from './ErrorButton.module.css';

const ErrorButton = ({ handleClick, children }) => (
  <div onClick={handleClick} className={styles.ErrorButton}>
    {children}
  </div>
);

ErrorButton.propTypes = {
  handleClick: PropTypes.func.isRequired,
  children: PropTypes.node.isRequired,
};

export default memo(ErrorButton);
