import React, { useState, useEffect } from 'react';
import Card from 'react-bootstrap/Card';
import Image from 'react-bootstrap/Image';
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import PropTypes from 'prop-types';
import styles from './ScheduleSnapshot.module.css';
import EventSnapshot from './EventSnapshot/EventSnapshot';
import { handleCompareDate } from '../../../utilities/checkSameDay';
import SeparatorLine from '../../../components/UI/SeparatorLine/SeparatorLine';

const ScheduleSnapshot = ({ events }) => {
  const [filteredEvents, setFilteredEvents] = useState();
  useEffect(() => {
    if (events === undefined) return;

    let greaterThanTodayEvents = events.filter((scheduleEvent) => {
      let eventDate = new Date(scheduleEvent.startDate);
      // resetting hour, so we can compare
      eventDate = eventDate.setHours(0, 0, 0, 0);
      const compareDate = new Date().setHours(0, 0, 0, 0);
      return eventDate >= compareDate;
    });

    greaterThanTodayEvents = greaterThanTodayEvents.sort((a, b) => {
      const c = new Date(a.startDate);
      const d = new Date(b.startDate);
      return c - d;
    });

    setFilteredEvents(greaterThanTodayEvents);
  }, [events]);

  return (
    <div className={styles.eventsSection__items}>
      {filteredEvents !== undefined
        ? (
          <>
            {' '}
            {filteredEvents.length < 1 ? (
              <>
                <Image src={`${process.env.REACT_APP_PRIMARY_API_URL}/public/images/calendar.png`} className="w-50" />
                <Card.Subtitle className="mb-2 text-muted">A centralized calendar for your team</Card.Subtitle>
              </>
            )

              : (
                <>
                  {' '}
                  {filteredEvents.map((event, index) => {
                    let startDateString = new Date(event?.startDate);
                    startDateString = startDateString.toString();
                    startDateString = startDateString.split(' ').slice(0, 3).join(' ');
                    const isSameDate = handleCompareDate(filteredEvents, event, (index - 1), 'startDate');

                    if (!isSameDate) {
                      return (
                        <>
                          {index === 0 ? null : <SeparatorLine />}
                          <div className={styles.showEvents}>
                            <div className={styles.showEvents__header}>
                              <div className={styles.boxDate}>
                                <CalendarTodayIcon />
                              </div>
                              <h1>{startDateString}</h1>
                            </div>

                            <EventSnapshot event={event} />
                          </div>
                        </>
                      );
                    }
                    return (
                      <EventSnapshot event={event} />
                    );
                  })}
                  {' '}

                </>
              )}

          </>
        ) : null}
    </div>
  );
};

ScheduleSnapshot.propTypes = {
  events: PropTypes.array.isRequired,
};

export default ScheduleSnapshot;
