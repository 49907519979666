import React, { memo } from 'react';
import PropTypes from 'prop-types';
import Radio from '@mui/material/Radio';
import { styled } from '@mui/material/styles';
import Color from '../../../../themes/colors';

const StyledRadioButton = styled(Radio)({
//   '& label': {
//     color: Color.gray5,
//   },
//   '& label.Mui-focused': {
//     color: Color.gray5,
//   },
//   '& .MuiInput-underline:after': {
//     borderBottomColor: Color.yellowCicle4,
//   },
//   '& .MuiOutlinedInput-root': {
//     background: Color.white,
//     '& fieldset': {
//       borderColor: Color.gray5,
//     },
//     '&:hover fieldset': {
//       borderColor: Color.gray5,
//     },
//     '&.Mui-focused fieldset': {
//       borderColor: Color.yellowCicle4,
//     },
//   },
});

const InputRadioButtonMain = ({
  onChange,
  value,
  name,
  checked,
}) => (
  <StyledRadioButton
    onChange={onChange}
    name={name}
    value={value}
    checked={checked}
  />
);

InputRadioButtonMain.propTypes = {
  onChange: PropTypes.func.isRequired,
  value: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  checked: PropTypes.bool.isRequired,
};

export default memo(InputRadioButtonMain);
