/* eslint-disable jsx-a11y/media-has-caption */
import React, { memo, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import DownloadIcon from '@mui/icons-material/Download';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { useMediaQuery } from '@mui/material';
import styles from './FileViewerDialog.module.css';
import Color from '../../themes/colors';
import { FeedbackDialogGeneral } from '../UI_V2/Feedback/Dialog';
import { AttachmentActions } from '../../actions';

const FileViewerDialog = ({
  open,
  onClose,
  file,
}) => {
  const [title, setTitle] = useState();
  const [isImage, setIsImage] = useState();
  const [isVideo, setIsVideo] = useState();
  const [isPdf, setIsPdf] = useState();
  const [fileUrl, setFileUrl] = useState();

  const fileName = file.title || file.name;

  useEffect(() => {
    if (fileName === undefined) return;

    let tempTitle;
    if (fileName.length < 40) {
      tempTitle = fileName;
    } else {
      tempTitle = `${fileName.slice(0, 40)}...`;
    }

    setTitle(tempTitle);

    const urlFile = file.url?.charAt(0) === '/'
      ? process.env.REACT_APP_PRIMARY_API_URL + file.url
      : file.url;

    setFileUrl(urlFile);

    const result = AttachmentActions.getMimeTypeUrl({ mimeType: file.mimeType, fileUrl: urlFile });

    setIsImage(result?.isImage);
    setIsVideo(result?.isVideo);
    setIsPdf(result?.isPdf);
  }, [file]);

  return (
    <FeedbackDialogGeneral
      open={open}
      onClose={onClose}
      maxWidth={isPdf ? 'xl' : 'lg'}
    >
      <div className={styles.container}>
        {(!isVideo && !isPdf) && (<img src={fileUrl} style={{ maxHeight: '500px', maxWidth: '1200px' }} />)}
        {isVideo
        && (
        <video controls autoPlay height={550}>
          <source
            src={fileUrl}
          />
        </video>
        )}
        {isPdf && (
          <object className={styles.objectPdf} data={fileUrl} type="application/pdf" height={550} width={1200} />
        )}
      </div>
    </FeedbackDialogGeneral>
  );
};

FileViewerDialog.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
  file: PropTypes.object,
};

FileViewerDialog.defaultProps = {
  open: false,
  onClose: () => null,
  file: {},
};

export default memo(FileViewerDialog);
