import React, { memo } from 'react';
import PropTypes from 'prop-types';
import styles from './ListCompanies.module.css';
import BillingPageListCompaniesHeader from './Header/Header';
import BillingPageListCompaniesBody from './Body/Body';
import BillingPageListCompaniesSeparatorActiveCompany from './SeparatorActiveCompany/SeparatorActiveCompany';

const BillingPageListCompanies = ({
  companies,
  currentCompany,
}) => (
  <div className={styles.container}>
    <div className={styles.header}>
      <BillingPageListCompaniesHeader
        companies={companies}
        currentCompany={currentCompany}
      />
    </div>
    {/* <div className={styles.separator}>
      <BillingPageListCompaniesSeparatorActiveCompany />
    </div> */}
    <div className={styles.body}>
      <BillingPageListCompaniesBody
        currentCompany={currentCompany}
      />
    </div>
  </div>
);

BillingPageListCompanies.propTypes = {
  companies: PropTypes.array.isRequired,
  currentCompany: PropTypes.object.isRequired,
};

export default memo(BillingPageListCompanies);
