import { useCallback } from 'react';
import { MyTaskCardActions } from '../actions';
import { MyTaskCardConstants } from '../constants';
import { connectSocketMyTaskCard } from '../services/socket';
import { useSocketHooks } from './SocketHooks';

function useMyTaskCardSocket({ companyId, userId, view }, dispatch) {
  const callbackMyTaskCardUpdate = useCallback(({ currentCard }) => {
    switch (view) {
      case 'listAll':
        MyTaskCardActions.incomingCardUpdateOnMyTaskCardListAll({
          currentCard,
          typeAction: MyTaskCardConstants.typeCallback.EDIT,
        }, dispatch);
        break;

      case 'calendarAll':
        MyTaskCardActions.incomingCardUpdateOnMyTaskCardCalendarAll({
          currentCard,
          typeAction: MyTaskCardConstants.typeCallback.EDIT,
        }, dispatch);
        break;

      default:
        break;
    }
  }, []);

  const callbackMyTaskCardNew = useCallback(({ currentCard }) => {
    switch (view) {
      case 'listAll':
        MyTaskCardActions.incomingCardUpdateOnMyTaskCardListAll({
          currentCard,
          typeAction: MyTaskCardConstants.typeCallback.NEW,
        }, dispatch);
        break;

      case 'calendarAll':
        MyTaskCardActions.incomingCardUpdateOnMyTaskCardCalendarAll({
          currentCard,
          typeAction: MyTaskCardConstants.typeCallback.NEW,
        }, dispatch);
        break;

      default:
        break;
    }
  }, []);

  const callbackMyTaskCardArchive = useCallback(({ currentCard }) => {
    switch (view) {
      case 'listAll':
        MyTaskCardActions.incomingCardUpdateOnMyTaskCardListAll({
          currentCard,
          typeAction: MyTaskCardConstants.typeCallback.DELETE,
        }, dispatch);
        break;

      case 'calendarAll':
        MyTaskCardActions.incomingCardUpdateOnMyTaskCardCalendarAll({
          currentCard,
          typeAction: MyTaskCardConstants.typeCallback.DELETE,
        }, dispatch);
        break;

      default:
        break;
    }
  }, []);

  const callbackMyTaskCardUnarchive = useCallback(({ currentCard }) => {
    switch (view) {
      case 'listAll':
        MyTaskCardActions.incomingCardUpdateOnMyTaskCardListAll({
          currentCard,
          typeAction: MyTaskCardConstants.typeCallback.NEW,
        }, dispatch);
        break;

      case 'calendarAll':
        MyTaskCardActions.incomingCardUpdateOnMyTaskCardCalendarAll({
          currentCard,
          typeAction: MyTaskCardConstants.typeCallback.NEW,
        }, dispatch);
        break;

      default:
        break;
    }
  }, []);

  const listenSocket = (socket, params) => {
    socket
      .on('cardUpdate', callbackMyTaskCardUpdate)
      .on('cardNew', callbackMyTaskCardNew)
      .on('cardArchive', callbackMyTaskCardArchive)
      .on('cardUnarchive', callbackMyTaskCardUnarchive);
  };
  const removeListener = (socket, params) => {
    socket
      .off('cardUpdate')
      .off('cardNew')
      .off('cardArchive')
      .off('cardUnarchive');
    socket.disconnect();
  };

  useSocketHooks({
    params: { companyId, userId },
    connectSocket: connectSocketMyTaskCard,
    listenSocket,
    removeListener,
  });
}

export {
  useMyTaskCardSocket,
};
