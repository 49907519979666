import React, { memo, useContext, useState } from 'react';
import PropTypes from 'prop-types';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import styles from './IntroChatSection.module.css';
import { DisplayTextBody } from '../../../components/UI_V2/Display/Text';
import Color from '../../../themes/colors';
import introImage from '../../../assets/PrivateChatPage/intro-chat-section.png';
import { GlobalContext } from '../../../contexts/GlobalStateProvider';

const containerWithSidebarStyles = {
  true: `${styles.container} ${styles.sidebarOpen}`,
  false: styles.container,
};

const IntroChatSection = () => {
  const [{ isSidebarOpen }] = useContext(GlobalContext);
  return (
    <div className={containerWithSidebarStyles[isSidebarOpen]}>
      <div className={styles.image}>
        <LazyLoadImage src={introImage} />
      </div>
      <div className={styles.text}>
        <DisplayTextBody
          mode="12"
          color={Color.gray3}
          position="center"
        >
          Yuk chatting realtime dengan rekan kerjamu!
        </DisplayTextBody>
      </div>
    </div>
  );
};

IntroChatSection.propTypes = {};

IntroChatSection.defaultProps = {};

export default memo(IntroChatSection);
