import React, { useState, useContext, useEffect } from 'react';
// import CloseIcon from '@mui/icons-material/Close';
// import { useSnackbar } from 'notistack';
import PropTypes from 'prop-types';
import { LazyLoadImage } from 'react-lazy-load-image-component';
// import { InputTextFieldMain } from '../../../components/UI_V2/Input/TextField';
import { GlobalContext } from '../../../contexts/GlobalStateProvider';
// import { actionTypes } from '../../../reducers/reducer';
// import SimplePopOver from '../../../components/UI/SimplePopOver/SimplePopOver';
// import { InputButtonMain } from '../../../components/UI_V2/Input/Button';
import styles from './CreateTeamButton.module.css';
// import SeparatorLine from '../../../components/UI/SeparatorLine/SeparatorLine';
// import handleStatusMsg from '../../../utilities/handleStatusMsg';
// import handleLoadings from '../../../utilities/handleLoadings';
import Line from '../../../components/UI/Line/Line';
// import { CompanyActions, TeamActions } from '../../../actions';
import createTeamImage from '../../../assets/DashboardPage/create-team-button.png';
import ModalCreateTeam from '../EmptyTeamsSectionForAdmins/ModalCreateTeam/ModalCreateTeam';

const CreateTeamButton = ({ isCanCreateTeam, type, params }) => {
  const [{
    user,
    teams,
    currentCompany,
  }, dispatch] = useContext(GlobalContext);
  // const { enqueueSnackbar } = useSnackbar();
  // const [loadings, setLoadings] = useState([]);
  const [isCreate, setIsCreate] = useState(false);
  // const [newTeam, setNewTeam] = useState({
  //   name: '',
  //   desc: '',
  // });
  // const [postTeam, setPostTeam] = useState();
  const openCreateTeam = () => {
    setIsCreate(true);
  };

  // const handleChanged = (event) => {
  //   const { name, value } = event.target;
  //   setNewTeam((prevValue) => ({
  //     ...prevValue,
  //     [name]: value,
  //   }));
  // };

  const handleCancel = () => {
    setIsCreate(false);
  };

  // useEffect(() => {
  //   const postNewTeam = async () => {
  //     if (postTeam !== undefined) {
  //       try {
  //         const startLoadings = handleLoadings('createTeam', [...loadings], 'start');
  //         setLoadings([...startLoadings]);
  //         const payload = {
  //           name: postTeam.name,
  //           desc: postTeam.desc,
  //           type,
  //         };
  //         const result = await TeamActions.createTeamAndUpdateTeamsThenUpdateCompanyAndCompanies(
  //           {
  //             companyId, payload, user, teams, currentCompany,
  //           }, dispatch,
  //         );

  //         const status = handleStatusMsg(result, 'success');

  //         enqueueSnackbar(status.message, {
  //           variant: 'success',
  //         });

  //         handleCancel();
  //       } catch (err) {
  //         const status = handleStatusMsg(err, 'error');

  //         enqueueSnackbar(status.message, {
  //           variant: 'error',
  //         });
  //       } finally {
  //         const endLoadings = handleLoadings('createTeam', [...loadings], 'end');
  //         setLoadings([...endLoadings]);
  //       }
  //     }
  //   };
  //   postNewTeam();
  // }, [postTeam]);

  // const handleClicked = () => {
  //   // setPostTeam jadi newTeam, harusnya ini ngetrigger useEffect
  //   setPostTeam(newTeam);
  // };

  return (
    <>
      <div className={styles.container}>
        <div className={styles.line}>
          <Line />
        </div>
        <div className={styles.buttonContainer}>
          {isCanCreateTeam && (
          <div className={styles.button} onClick={openCreateTeam}>
            <LazyLoadImage
              alt=""
              src={createTeamImage}
            />
          </div>
          )}
          { isCreate
            && (
              <ModalCreateTeam
                open={isCreate}
                onClose={handleCancel}
                initialType={type}
                params={params}
              />
              // <SimplePopOver>
              //   <span className={styles.title}>
              //     Create
              //     {' '}
              //     {type}
              //     ?
              //   </span>
              //   <SeparatorLine />
              //   <div className={styles.formInput}>
              //     <InputTextFieldMain
              //       placeholder="Input name..."
              //       autoFocus
              //       onChange={(event) => handleChanged(event)}
              //       name="name"
              //     />
              //   </div>
              //   <div className={styles.formInput}>
              //     <InputTextFieldMain
              //       placeholder="Input description..."
              //       minRows={3}
              //       maxRows={3}
              //       onChange={(event) => handleChanged(event)}
              //       name="desc"
              //       multiline
              //     />
              //   </div>
              //   <CloseIcon className={styles.closeIcon} onClick={handleCancel} />
              //   <InputButtonMain
              //     handleClick={handleClicked}
              //     variant="success"
              //     size="sm"
              //     wait="createTeam"
              //     loadings={loadings}
              //   >
              //     Create
              //   </InputButtonMain>
              // </SimplePopOver>
            )}
        </div>
      </div>
    </>
  );
};

CreateTeamButton.propTypes = {
  type: PropTypes.string.isRequired,
  isCanCreateTeam: PropTypes.bool.isRequired,
  params: PropTypes.object.isRequired,
};

export default CreateTeamButton;
