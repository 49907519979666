import React, { useState, useContext, useEffect } from 'react';
import PropTypes from 'prop-types';
import { GlobalContext } from '../../../../contexts/GlobalStateProvider';
import styles from './ListPositionOption.module.css';
import ButtonDiv from '../../../../components/UI/Button/ButtonDiv/ButtonDiv';

const ListPositionOption = ({
  cancel, handleUpdateDestListIndex, handleUpdateViewListPosition, destData, list,
}) => {
  const [{ currentBoardLists }, dispatch] = useContext(GlobalContext);
  const [listCards, setListCards] = useState([]);

  const handleSelectPos = (listIndex) => {
    handleUpdateDestListIndex({
      index: listIndex,
    });

    handleUpdateViewListPosition({
      listPosition: listIndex + 1,
    });

    cancel();
  };

  useEffect(() => {
    const newList = currentBoardLists.find(
      (boardList) => boardList._id === destData.destination.droppableId,
    );
    let newListCards = [];
    if (newList) {
      newListCards = newList?.cards;
    }
    setListCards([...newListCards]);
  }, [list, destData]);

  return (
    <div className={styles.mainSection}>
      {listCards.length > 0 ? listCards.map((card, index) => (
        <ButtonDiv
          onClick={() => {
            handleSelectPos(index);
          }}
          className={styles.listSection}
        >
          <a>{index + 1}</a>
        </ButtonDiv>

      ))
        : (
          <ButtonDiv
            onClick={() => {
              handleSelectPos(0);
            }}
            className={styles.listSection}
          >
            <a>1</a>
          </ButtonDiv>
        )}
    </div>
  );
};

ListPositionOption.propTypes = {
  list: PropTypes.object.isRequired,
  destData: PropTypes.object.isRequired,
  cancel: PropTypes.func.isRequired,
  handleUpdateDestListIndex: PropTypes.func.isRequired,
  handleUpdateViewListPosition: PropTypes.func.isRequired,
};

export default ListPositionOption;
