import React, {
  useState, useContext, useEffect, useCallback,
} from 'react';
import { useHistory, useParams, useLocation } from 'react-router-dom';
import _ from 'lodash';
import '../../../node_modules/react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import { useSnackbar } from 'notistack';
import AddIcon from '@mui/icons-material/Add';
import TextareaAutosize from 'react-textarea-autosize';
import EmojiPicker from 'emoji-picker-react';
import MoodIcon from '@mui/icons-material/Mood';
import Button from '../../components/UI/Button/Button';
import styles from './EditBucketPage.module.css';
import Paper from '../../components/UI/Paper/Paper';
import SeparatorLine from '../../components/UI/SeparatorLine/SeparatorLine';
import MainLayout from '../../components/Layout/MainLayout/MainLayout';
import { actionTypes } from '../../reducers/reducer';
import { GlobalContext } from '../../contexts/GlobalStateProvider';
import handleLoadings from '../../utilities/handleLoadings';
import PageLoading from '../../components/UI/Button/PageLoading/PageLoading';
import handleStatusMsg from '../../utilities/handleStatusMsg';
import { BucketActions, SearchJumpToActions, TeamActions } from '../../actions';
import ManageSubscribersContainer from '../ManageSubscribersContainer/ManageSubscribersContainer';
import Title from '../../components/Title/Title';
import GeneralSubNavBar from '../../components/GeneralSubNavBar/GeneralSubNavBar';
import GlobalActionButton from '../../components/GeneralSubNavBar/BottomNavBar/GlobalActionButton/GlobalActionButton';
import { generateRoutesAndTitle } from '../../actions/BreadCrumbActions';
import { PageConstants } from '../../constants';
import { InputSwitchMain } from '../../components/UI_V2/Input/Switch';
import { LayoutMain } from '../../components/UI_V2/Layout';
import { SurfacePaperMain } from '../../components/UI_V2/Surface/Paper';

function EditBucketPage() {
  const [{
    user, currentBucket, currentTeam, bucketDocPath, currentCompany,
  }, dispatch] = useContext(GlobalContext);
  const [newBucket, setNewBucket] = useState({ value: '' });
  const [showAddSubscriber, setShowAddSubscriber] = useState(false);
  const [shouldFetchSubscribers, setShouldFetchSubscribers] = useState(false);
  const [subscribers, setSubscribers] = useState([]);
  const [showEmojiPicker, setShowEmojiPicker] = useState(false);
  const [postBucket, setPostBucket] = useState();
  const [loadings, setLoadings] = useState([]);
  const { enqueueSnackbar } = useSnackbar();
  const [isAccessPrivate, setAccessPrivate] = useState(false);

  const history = useHistory();
  const location = useLocation();
  const params = useParams();
  const { companyId, teamId, bucketId } = params;

  useEffect(() => {
    if (user._id === undefined) {
      history.push(`/check-login?previousPath=${location.pathname}`);
      return;
    }

    if (!_.isEmpty(currentTeam)) return;

    const startLoadings = handleLoadings('editBucketPage', [...loadings], 'start');
    setLoadings([...startLoadings]);

    const fetchApiBucketDetail = async () => {
      try {
        await BucketActions.initiateBucket({ bucketId, teamId, companyId }, dispatch);
        await TeamActions.initiateTeam({
          teamId,
          companyId,
          currentTeam,
        }, dispatch);
      } catch (err) {
        const status = handleStatusMsg(err, 'error');

        dispatch({
          type: actionTypes.SET_ERROR_RESPONSE,
          errorResponse: { message: status.message },
        });

        history.push(`/errors?previousPath=${location.pathname}`);
      } finally {
        const endLoadings = handleLoadings('editBucketPage', [...loadings], 'end');
        setLoadings([...endLoadings]);
      }
    };
    fetchApiBucketDetail();
  }, []);

  const fetchBucketSubscribersApi = async () => {
    try {
      const result = await BucketActions.initiateBucketSubscribers({
        bucketId,
        companyId,
        teamId,
      }, dispatch);

      const resultSubscribers = result?.data?.subscribers;
      const tempSubscribers = [];
      if (resultSubscribers) {
        resultSubscribers.forEach((subscriber) => {
          tempSubscribers.push(subscriber);
        });
      }

      setSubscribers([...tempSubscribers]);
    } catch (err) {
      const status = handleStatusMsg(err, 'error');

      dispatch({
        type: actionTypes.SET_ERROR_RESPONSE,
        errorResponse: { message: status.message },
      });

      history.push(`/errors?previousPath=${location.pathname}`);
    }
  };

  const handleCancel = () => {
    history.push(`/companies/${companyId}/teams/${teamId}/buckets/${bucketId}`);
  };

  useEffect(() => {
    if (user._id === undefined) {
      history.push(`/check-login?previousPath=${location.pathname}`);
      return;
    }

    if (postBucket === undefined) {
      return;
    }

    const postApiBucket = async () => {
      try {
        const startLoadings = handleLoadings('editBucket', [...loadings], 'start');
        setLoadings([...startLoadings]);

        const result = await BucketActions.editBucket(
          {
            body: postBucket, bucketId, companyId, teamId,
          },
        );
        const status = handleStatusMsg(result, 'success');

        enqueueSnackbar(status.message, {
          variant: 'success',
        });

        handleCancel();
      } catch (err) {
        const status = handleStatusMsg(err, 'error');

        enqueueSnackbar(status.message, {
          variant: 'error',
        });
      } finally {
        const endLoadings = handleLoadings('editBucket', [...loadings], 'end');
        setLoadings([...endLoadings]);
      }
    };
    postApiBucket();
  }, [postBucket]);

  useEffect(() => {
    if (_.isEmpty(currentBucket)) {
      return;
    }

    setNewBucket({ value: currentBucket.title });

    if (currentBucket?.isPublic !== undefined) {
      setAccessPrivate(!currentBucket?.isPublic);
    }

    setShouldFetchSubscribers(true);
  }, [currentBucket]);

  useEffect(() => {
    if (!shouldFetchSubscribers) {
      return;
    }
    fetchBucketSubscribersApi();
  }, [shouldFetchSubscribers]);

  const onEmojiClick = (code, emoji) => {
    setNewBucket((prevValue) => {
      let newValue;
      if (prevValue.value !== '') {
        newValue = prevValue.value + emoji.emoji;
      } else {
        newValue = emoji.emoji;
      }
      return ({
        ...prevValue,
        value: newValue,
      });
    });
  };

  const handleChanged = (event) => {
    const { value } = event.target;

    setNewBucket((prevValue) => ({
      ...prevValue,
      value,
    }));
  };

  const handleShowEmojiPicker = () => {
    setShowEmojiPicker(!showEmojiPicker);
  };

  const disableShowEmojiPicker = () => {
    setShowEmojiPicker(false);
  };

  const handlePostBucket = () => {
    if (newBucket === undefined || newBucket.value === undefined || newBucket.value.length < 1) {
      enqueueSnackbar('Bucket name cannot be empty', {
        variant: 'error',
      });
      return;
    }
    // send subscriber ids
    const subscriberIds = [];

    subscribers.forEach((subscriber) => {
      subscriberIds.push(subscriber._id);
    });

    setPostBucket({
      title: newBucket.value,
      subscribers: [...subscriberIds],
      isPublic: !isAccessPrivate,
    });
  };

  const handleSetSubscribers = (incomingSubscribers) => {
    setSubscribers([...incomingSubscribers]);
  };

  const handleChangeAccess = useCallback((access) => {
    setAccessPrivate(access);
  }, []);
  const pageType = PageConstants.pageType.bucketsEdit;
  const breadcrumb = generateRoutesAndTitle({
    type: pageType,
    data: {
      companyId, currentTeam, currentBucket, bucketDocPath,
    },
  });
  SearchJumpToActions.saveRecentPage({
    company: currentCompany,
    team: currentTeam,
    userId: user?._id,
    itemId: `${bucketId}-edit`,
    url: breadcrumb.url,
    routes: breadcrumb.routes,
    name: breadcrumb.title,
    type: pageType,
  });

  return (
    <>
      <Title title={currentBucket?.title === undefined ? 'Ubah Folder' : `Ubah ${currentBucket?.title}`} />
      <GeneralSubNavBar
        breadcrumbRoutes={breadcrumb.routes}
        pageTitle={breadcrumb.title}
        bottomBarOverviewOptionId="buckets"
        withBottomNavBar={false}
      />
      <LayoutMain>
        <PageLoading wait="editBucketPage" loadings={loadings}>
          <SurfacePaperMain>
            <div className={styles.container}>
              <div className={styles.inputTitleSection}>
                <TextareaAutosize autoFocus placeholder="Ketik nama folder..." onClick={disableShowEmojiPicker} onChange={(event) => handleChanged(event)} minRows={1} maxRows={2} value={newBucket.value} />
                <MoodIcon onClick={handleShowEmojiPicker} />
                {showEmojiPicker
                  ? (
                    <div className={styles.emojiPicker}>
                      <EmojiPicker onEmojiClick={onEmojiClick} />
                    </div>
                  ) : null}
              </div>
              <SeparatorLine />

              <div className={styles.manageSubscribers}>
                <ManageSubscribersContainer
                  type="bucket"
                  initialSubscribers={subscribers}
                  handleSetSubscribers={handleSetSubscribers}
                  companyId={companyId}
                />
              </div>

              <InputSwitchMain
                title="Apakah folder ini Rahasia untuk Penerima aja?"
                labelSwitch="Rahasia"
                handleChange={handleChangeAccess}
                defaultValue={isAccessPrivate}
              />

              <div className={styles.actionSection}>
                <div>
                  <Button handleClick={handlePostBucket} variant="success" wait="editBucket" spinnerSize="sm" loadings={loadings}>Simpan Perubahan</Button>
                  <Button handleClick={handleCancel} variant="danger">Batal</Button>
                </div>
              </div>
            </div>
          </SurfacePaperMain>
        </PageLoading>
      </LayoutMain>
    </>
  );
}

export default EditBucketPage;
