import React, { memo, useState } from 'react';
import PropTypes from 'prop-types';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import styles from './ListMemberOnline.module.css';
import Color from '../../../../themes/colors';
import PersonItem from './PersonItem/PersonItem';

const ListMemberOnline = ({ members, positionPopUpOnlineOnPhoto }) => (
  <div className={styles.container}>
    {members.map((member) => (
      <PersonItem member={member} positionPopUpOnlineOnPhoto={positionPopUpOnlineOnPhoto} />
    ))}
  </div>
);

ListMemberOnline.propTypes = {
  members: PropTypes.array.isRequired,
  positionPopUpOnlineOnPhoto: PropTypes.oneOf(['topRight', 'topLeft', 'bottomRight', 'bottomLeft']),
};

ListMemberOnline.defaultProps = {
  positionPopUpOnlineOnPhoto: 'topLeft',
};

export default memo(ListMemberOnline);
