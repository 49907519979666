import React, { memo, useState } from 'react';
import PropTypes from 'prop-types';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { Skeleton } from '@mui/material';
import styles from './Body.module.css';
import Color from '../../../../../themes/colors';

const UserRerportPageSkeletonBody = () => (
  <div className={styles.container}>
    <div className={styles.box}>
      <div className={styles.skeleton}>
        <Skeleton
          animation="wave"
          variant="rounded"
          height={30}
        />
      </div>
      <div className={styles.skeleton}>
        <Skeleton
          animation="wave"
          variant="rounded"
          height={85}
        />
      </div>
      <div className={styles.skeleton}>
        <Skeleton
          animation="wave"
          variant="rounded"
          height={30}
        />
      </div>
      <div className={styles.skeleton}>
        <Skeleton
          animation="wave"
          variant="rounded"
          height={85}
        />
      </div>
    </div>
    <div className={styles.box}>
      <div className={styles.skeleton}>
        <Skeleton
          animation="wave"
          variant="rounded"
          height={30}
        />
      </div>
      <div className={styles.skeleton}>
        <Skeleton
          animation="wave"
          variant="rounded"
          height={85}
        />
      </div>
      <div className={styles.skeleton}>
        <Skeleton
          animation="wave"
          variant="rounded"
          height={30}
        />
      </div>
      <div className={styles.skeleton}>
        <Skeleton
          animation="wave"
          variant="rounded"
          height={85}
        />
      </div>
    </div>
  </div>
);

UserRerportPageSkeletonBody.propTypes = {};

UserRerportPageSkeletonBody.defaultProps = {};

export default memo(UserRerportPageSkeletonBody);
