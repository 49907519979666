import React, {
  useState, useContext, useEffect, useCallback,
} from 'react';
import { useHistory, useParams, useLocation } from 'react-router-dom';
import AddIcon from '@mui/icons-material/Add';
import { useSnackbar } from 'notistack';
import queryString from 'query-string';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import FormatListBulletedIcon from '@mui/icons-material/FormatListBulleted';
import FilterAltOutlinedIcon from '@mui/icons-material/FilterAltOutlined';
import { Tooltip } from '@mui/material';
import { GlobalContext } from '../../contexts/GlobalStateProvider';
import styles from './MyTaskCardCalendarPage.module.css';
import Title from '../../components/Title/Title';
import {
  BoardActions,
  SearchJumpToActions,
  TeamActions,
} from '../../actions';
import { PageConstants } from '../../constants';
import GeneralSubNavBar from '../../components/GeneralSubNavBar/GeneralSubNavBar';
import GlobalActionButton from '../../components/GeneralSubNavBar/BottomNavBar/GlobalActionButton/GlobalActionButton';
import { generateRoutesAndTitle } from '../../actions/BreadCrumbActions';
import CalendarListCards from './CalendarListCards/CalendarListCards';
import { useDelayShowHideHandler } from '../../hooks/HelperHooks';
import ModalCreateCardWithTeamListSelection from '../../components/Container/ModalCreateCardWithTeamListSelection/ModalCreateCardWithTeamListSelection';
import VerticalSeparator from '../../components/GeneralSubNavBar/BottomNavBar/VerticalSeparator/VerticalSeparator';
import { getStoreMyTaskCardView, setStoreFilterMyTaskCard, setStoreMyTaskCardView } from '../../utilities/localStorage';
import { getSessionMyTaskCardView, setSessionMyTaskCardView } from '../../utilities/sessionStorage';
import LinkNoDecor from '../../components/LinkNoDecor/LinkNoDecor';

const containerWithSidebarStyles = {
  true: `${styles.container} ${styles.sidebarOpen}`,
  false: styles.container,
};

function MyTaskCardCalendarPage() {
  const [{ user, currentCompany, isSidebarOpen }, dispatch] = useContext(GlobalContext);
  /* Show/Hide Filters */
  const [showFilters, handleShowFilters, handleHideFilters] = useDelayShowHideHandler();
  const [isFilterActive,
    handleShowFilterActive, handleHideFilterActive] = useDelayShowHideHandler();
  const [showCreateMyTaskCard,
    handleShowCreateMyTaskCard, handleHideCreateMyTaskCard] = useDelayShowHideHandler();
  const [selectedDueDate,
    setSelectedDueDate] = useState(new Date());

  const location = useLocation();
  const query = queryString.parse(location.search);
  const { initialCalendarView } = query;
  const history = useHistory();
  const params = useParams();
  const { companyId } = params;

  const isLocationMyTaskOpenCard = location.pathname.includes('/cards');

  let initialMyTaskCardView;

  initialMyTaskCardView = getSessionMyTaskCardView(
    { companyId, userId: user?._id },
  );

  if (!initialMyTaskCardView?.mainValue) {
    initialMyTaskCardView = getStoreMyTaskCardView(
      { companyId, userId: user?._id },
    );
  }

  const myTaskCardViewInitialCalendarViewValue = initialMyTaskCardView?.initialCalendarViewValue || 'dayGridMonth';

  useEffect(() => {
    if (user?._id) {
      setStoreMyTaskCardView({
        companyId,
        userId: user._id,
        view: {
          initialCalendarViewValue: myTaskCardViewInitialCalendarViewValue,
          mainValue: 'calendarAll',
        },
      });
      setSessionMyTaskCardView({
        companyId,
        userId: user._id,
        view: {
          initialCalendarViewValue: myTaskCardViewInitialCalendarViewValue,
          mainValue: 'calendarAll',
        },
      });
      if (!initialCalendarView && !isLocationMyTaskOpenCard) history.push(`/companies/${companyId}/my-tasks/calendar/all?initialCalendarView=${myTaskCardViewInitialCalendarViewValue}`);
    }

    return () => {
    //   // on exit page, do these :
    // // clear current board, boardLists, board labels
    //   BoardActions.resetBoardAndLists({}, dispatch);
    //   // clear team and team members
    //   TeamActions.resetTeamAndTeamMembers({}, dispatch);
    };
  }, [user]);

  const pageType = PageConstants.pageType.myTasksCalendar;
  const breadcrumb = generateRoutesAndTitle(
    {
      type: pageType,
      data: {
        companyId,
        initialMyTaskCalendarView: myTaskCardViewInitialCalendarViewValue,
      },
    },
  );
  SearchJumpToActions.saveRecentPage({
    company: currentCompany,
    userId: user?._id,
    itemId: `${companyId}-myTasksCalendar`,
    url: breadcrumb.url,
    routes: breadcrumb.routes,
    name: breadcrumb.title,
    type: pageType,
  });

  return (
    <>
      <Title title="Semua Tugas : Kalender" />
      <GeneralSubNavBar
        breadcrumbRoutes={breadcrumb.routes}
        pageTitle={breadcrumb.title}
        withMiddleNavBar={false}
        withMyTaskCardMiddleNavBar
        bottomBarOverviewOptionId="myTasksCalendar"
        // bottomBarGlobalActions={(
        //   <>
        //     <LinkNoDecor to={`/companies/${companyId}/my-tasks/list/all`}>
        //       <GlobalActionButton text="View List" icon={<FormatListBulletedIcon />} />
        //     </LinkNoDecor>
        //     <GlobalActionButton
        // buttonMode="active" text="View Kalender" icon={<CalendarMonthIcon />} />
        //   </>
        //   )}
        bottomBarGlobalActions={(
          <>
            <GlobalActionButton
              handleClick={handleShowFilters}
              buttonMode="outlined"
              text="Filter"
              icon={<FilterAltOutlinedIcon />}
            >
              {isFilterActive ? (
                <Tooltip
                  title="Filter-mu aktif. Atur atau hapus filter kalo kamu ga menemukan yang kamu cari."
                >
                  <div className={styles.activeFilterIcon}><CheckCircleIcon /></div>
                </Tooltip>
              ) : null}
            </GlobalActionButton>
          </>
          )}
      />
      <div className={containerWithSidebarStyles[isSidebarOpen]}>
        <CalendarListCards
          showFilters={showFilters}
          handleHideFilters={handleHideFilters}
          handleShowFilterActive={handleShowFilterActive}
          handleHideFilterActive={handleHideFilterActive}
          handleShowCreateMyTaskCard={handleShowCreateMyTaskCard}
          handleSetDueDate={setSelectedDueDate}
          initialCalendarView={myTaskCardViewInitialCalendarViewValue}
        />
        <ModalCreateCardWithTeamListSelection
          open={showCreateMyTaskCard}
          onClose={handleHideCreateMyTaskCard}
          initialDueDate={selectedDueDate}
        />
      </div>
    </>
  );
}

export default MyTaskCardCalendarPage;
