import React, {
  useState, useContext, useEffect, useCallback,
} from 'react';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import Spinner from 'react-bootstrap/Spinner';
import { useSnackbar } from 'notistack';
import InfiniteScroll from 'react-infinite-scroll-component';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { GlobalContext, initialState } from '../../contexts/GlobalStateProvider';
import { actionTypes } from '../../reducers/reducer';
import styles from './CheersPage.module.css';
import Title from '../../components/Title/Title';
import handleStatusMsg from '../../utilities/handleStatusMsg';
import handleLoadings from '../../utilities/handleLoadings';
import PageLoading from '../../components/UI/Button/PageLoading/PageLoading';
import OverlayButton from '../../components/UI/Button/OverlayButton/OverlayButton';
import ShowNotifAndCheers from './ShowNotifAndCheers/ShowNotifAndCheers';
import { useMarkReadNotificationOnPageOpen, useNotificationSocket } from '../../hooks/NotificationHooks';
import { CheerActions, SearchJumpToActions } from '../../actions';
import { useInfinityScrollNotificationHooks } from '../../hooks/InfinityScrollHooks';
import GeneralSubNavBar from '../../components/GeneralSubNavBar/GeneralSubNavBar';
import { generateRoutesAndTitle } from '../../actions/BreadCrumbActions';
import emptyCheersImage from '../../assets/CheersPage/empty-cheers-illustration.png';
import cheersWelcomeImage from '../../assets/CheersPage/cheers-welcome.png';
import { PageConstants } from '../../constants';

const containerWithSidebarStyles = {
  true: `${styles.container} ${styles.sidebarOpen}`,
  false: styles.container,
};

function CheersPage() {
  const [
    {
      user, cheersNotifications, currentCompany,
      isSidebarOpen,
    },
    dispatch,
  ] = useContext(GlobalContext);

  const [loadings, setLoadings] = useState([]);
  const { enqueueSnackbar } = useSnackbar();

  const history = useHistory();
  const location = useLocation();
  const params = useParams();

  const { companyId } = params;

  useNotificationSocket({
    userId: user?._id,
    companyId,
    typeNotif: 'cheerNotif',
    filter: 'all',
  }, dispatch);

  // read notif automatically if there is readNotifId
  useMarkReadNotificationOnPageOpen();

  const getMoreLists = async () => {
    const result = await CheerActions.loadMoreCheers({
      companyId,
      cheersNotifications,
    }, dispatch);

    return result;
  };

  const resetPreviousLists = useCallback(() => {
    CheerActions.setCheersNotif({
      data: initialState.cheersNotifications.data,
    }, dispatch);
    CheerActions.setTotalCheersNotif({
      total: initialState.cheersNotifications.total,
    }, dispatch);
  }, []);

  const {
    lists,
    handleLoadMoreLists,
    checkIfListNeedToLoad,
  } = useInfinityScrollNotificationHooks({
    currentObjectWithKeyProperty: cheersNotifications,
    resetPreviousLists,
    getMoreLists,
    keyProperty: 'data',
  });

  useEffect(() => {
    if (!user?._id) {
      history.push(`/check-login?previousPath=${location.pathname}`);
      return;
    }
    if (!companyId) {
      enqueueSnackbar('Please select a company first', {
        variant: 'info',
      });
      return;
    }

    const fetchApiCheers = async () => {
      const startLoadings = handleLoadings('cheersPageFirst', [...loadings], 'start');
      setLoadings([...startLoadings]);

      try {
        const result = await CheerActions.initiateCheers({
          companyId,
        }, dispatch);
      } catch (err) {
        const status = handleStatusMsg(err, 'error');

        dispatch({
          type: actionTypes.SET_ERROR_RESPONSE,
          errorResponse: { message: status.message },
        });

        history.push(`/errors?previousPath=${location.pathname}`);
      } finally {
        const endLoadings = handleLoadings('cheersPageFirst', [...loadings], 'end');
        setLoadings([...endLoadings]);
      }
    };
    fetchApiCheers();
  }, []);
  const pageType = PageConstants.pageType.cheers;
  const breadcrumb = generateRoutesAndTitle({ type: pageType, data: { companyId } });
  SearchJumpToActions.saveRecentPage({
    company: currentCompany,
    userId: user?._id,
    itemId: `${companyId}-cheers`,
    url: breadcrumb.url,
    routes: breadcrumb.routes,
    name: breadcrumb.title,
    type: pageType,
  });

  return (
    <>
      <Title title="Ringkasan Cheers" />
      <GeneralSubNavBar
        breadcrumbRoutes={breadcrumb.routes}
        pageTitle={breadcrumb.title}
        withBottomNavBar={false}
        withMiddleNavBar={false}
      />
      <PageLoading wait="cheersPageFirst" loadings={loadings}>
        <div className={containerWithSidebarStyles[isSidebarOpen]}>
          <div className={styles.headerSection}>
            <LazyLoadImage width="100%" src={cheersWelcomeImage} />
          </div>
          <div className={styles.bodySection}>
            {lists
              ? (
                <InfiniteScroll
                  dataLength={lists?.length}
                  next={handleLoadMoreLists}
                  hasMore={checkIfListNeedToLoad()}
                  loader={<div className={styles.infiniteScroll}><Spinner animation="border" size="sm" variant="warning" /></div>}
                  endMessage={(
                    <>
                      {lists.length < 1
                        ? (
                          <OverlayButton wait="cheersPage" loadings={loadings}>
                            <div className={styles.emptyNotif}>
                              <LazyLoadImage src={emptyCheersImage} />
                              <h1>No cheers found</h1>
                              <p>Start cheering others so you can have it too! 😀</p>
                            </div>
                            {' '}

                          </OverlayButton>
                        ) : (
                          <div className={styles.infiniteScroll}>
                            <p>That&apos;s all, folks! 🥳</p>
                          </div>
                        )}
                    </>
                    )}
                >
                  <OverlayButton wait={lists.length > 0 ? 'cheersPage' : null} loadings={loadings}>
                    <ShowNotifAndCheers notifCheers={lists} />
                  </OverlayButton>
                </InfiniteScroll>
              )
              : null}
          </div>
        </div>
      </PageLoading>
    </>
  );
}

export default CheersPage;
