const delayUpdateCard = 2500;
const limitCard = 10;
const limitComment = 10;
const typeCallback = {
  NEW: 'new',
  DELETE: 'delete',
  EDIT: 'edit',
};

export {
  delayUpdateCard,
  limitCard,
  limitComment,
  typeCallback,
};
