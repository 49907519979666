import React, { memo } from 'react';
import PropTypes from 'prop-types';
import MoreVertOutlinedIcon from '@mui/icons-material/MoreVertOutlined';
import MoreHorizOutlinedIcon from '@mui/icons-material/MoreHorizOutlined';
import styles from './More.module.css';
import Color from '../../../../../themes/colors';

const InputButtonMore = ({ onClick, variant, colorVariant }) => {
  const icon = {
    vertical: <MoreVertOutlinedIcon />,
    horizontal: <MoreHorizOutlinedIcon />,
  };

  const colorVariantStyles = {
    light: styles.colorVariantLight,
    dark: styles.colorVariantDark,
  };

  return (
    <div
      onClick={onClick}
      className={`${styles.container} ${colorVariantStyles[colorVariant]}`}
    >
      {icon[variant]}
    </div>
  );
};

InputButtonMore.propTypes = {
  onClick: PropTypes.func,
  variant: PropTypes.oneOf(['vertical', 'horizontal']),
  colorVariant: PropTypes.oneOf(['light', 'dark']),
};

InputButtonMore.defaultProps = {
  onClick: () => null,
  variant: 'vertical',
  colorVariant: 'dark',
};

export default memo(InputButtonMore);
