import React, { memo } from 'react';
import PropTypes from 'prop-types';
import Badge from '@mui/material/Badge';
import { styled } from '@mui/material/styles';
import { propTypes } from 'react-time-ago';
import styles from './Counter.module.css';
import Color from '../../../../../themes/colors';

const borderRadiusStyles = {
  dot: {
    borderRadius: '100%',
  },
  standard: {},
};

const DisplayBadgeCounter = ({
  customStyle,
  badgeContent,
  handleClick,
  children,
  color,
  backgroundColor,
  fontSize,
  height,
  minWidth,
  variant,
}) => {
  const StyledBadge = styled(Badge)(({ style }) => ({
    '& .MuiBadge-badge': {
      backgroundColor,
      color,
      fontSize,
      height,
      minWidth,
      ...style,
      ...borderRadiusStyles[variant],
    },
  }));

  return (
    <StyledBadge
      style={customStyle}
      variant={variant}
      badgeContent={badgeContent}
      onClick={handleClick}
    >
      {children}
    </StyledBadge>
  );
};

DisplayBadgeCounter.propTypes = {
  customStyle: PropTypes.object,
  badgeContent: PropTypes.number.isRequired,
  handleClick: PropTypes.func,
  children: PropTypes.node,
  backgroundColor: PropTypes.string,
  color: PropTypes.string,
  fontSize: PropTypes.string,
  height: PropTypes.string,
  minWidth: PropTypes.string,
  variant: PropTypes.oneOf(['dot', 'standard']),
};

DisplayBadgeCounter.defaultProps = {
  customStyle: {},
  handleClick: () => null,
  children: null,
  backgroundColor: Color.yellowAccentCicle,
  color: Color.white,
  fontSize: '0.6rem',
  height: '17px',
  minWidth: '17px',
  variant: 'standard',
};

export default memo(DisplayBadgeCounter);
