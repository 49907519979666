import React, {
  memo, useState, useCallback, useEffect,
} from 'react';
import PropTypes from 'prop-types';
import { Box } from '@mui/material';
import Color from '../../../../../themes/colors';
import { DisplayTextSubHeadline } from '../../../../../components/UI_V2/Display/Text';
import { InputRadioButtonMain } from '../../../../../components/UI_V2/Input/RadioButton';
import { DisplayBadgeCounter } from '../../../../../components/UI_V2/Display/Badge';
import limitChar from '../../../../../utilities/limitChar';

const styles = {
  background: Color.white,
  boxSizing: 'border-box',
  borderRadius: '10px',
  height: '38px',
  padding: '3px 10px 3px 20px',
  cursor: 'pointer',
  '&:hover': {
    opacity: 0.8,
  },
};

const flexStyles = {
  display: 'flex',
  alignItems: 'center',
};

const fontColorStyles = {
  true: Color.blueNavy3,
  false: Color.fontColor,
};

const decorationStyles = {
  true: 'bold',
  false: 'regular',
};

const tabStyles = {
  true: {
    // background: Color.yellowCicle2,
    border: `1px solid ${Color.lighterBlue}`,
  },
  false: {
    border: `1px solid ${Color.gray6}`,
  },
};

const TeamItem = ({
  _id,
  name,
  counter,
  selected,
  onClick,
}) => (
  <Box
    sx={{
      ...styles,
      ...flexStyles,
      ...tabStyles[selected],
      justifyContent: 'space-between',
    }}
    onClick={onClick}
  >
    <Box
      sx={{
        ...flexStyles,
      }}
    >
      <Box
        sx={{
          marginRight: '10px',
        }}
      >
        <DisplayTextSubHeadline
          mode="14"
          decoration={decorationStyles[selected]}
          color={fontColorStyles[selected]}
        >
          {limitChar(name, 50)}
        </DisplayTextSubHeadline>
      </Box>
      <DisplayBadgeCounter
        badgeContent={counter}
        backgroundColor={Color.deleteColor}
        customStyle={{
          right: -10,
        }}
      />
    </Box>
    <Box>
      <InputRadioButtonMain
        value={_id}
        checked={selected}
        onChange={onClick}
      />
    </Box>
  </Box>
);

TeamItem.propTypes = {
  name: PropTypes.string.isRequired,
  counter: PropTypes.number.isRequired,
  selected: PropTypes.bool.isRequired,
  _id: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
};

export default memo(TeamItem);
