import React, { memo, useState } from 'react';
import PropTypes from 'prop-types';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import styles from './StatText.module.css';
import Color from '../../../../../themes/colors';
import { DisplayChartDoughnut } from '../../../../../components/UI_V2/Display/Chart';
import TitleChart from '../TitleChart/TitleChart';
import { DisplayTextBody, DisplayTextSubHeadline } from '../../../../../components/UI_V2/Display/Text';
import { typeRef, categoryRef } from '../../../../../constants/ReportConstants';

const StatText = ({
  completeRatio,
  overdueRatio,
  ongoingRatio,
}) => {
  const completedCategory = completeRatio?.category;
  const overdueCategory = overdueRatio?.category;
  const ongoingCategory = ongoingRatio?.category;

  const isAllGood = completedCategory === categoryRef.good
    && overdueCategory === categoryRef.good
    && ongoingCategory === categoryRef.good;
  const isAllBad = completedCategory === categoryRef.bad
    && overdueCategory === categoryRef.bad
    && ongoingCategory === categoryRef.bad;
  const isAllNormal = completedCategory === categoryRef.normal
    && overdueCategory === categoryRef.normal
    && ongoingCategory === categoryRef.normal;

  const isOverdueBad = overdueCategory === categoryRef.bad;
  const isOngoingBad = ongoingCategory === categoryRef.bad;
  const isCompletedBad = completedCategory === categoryRef.bad;
  const isOverdueNormal = overdueCategory === categoryRef.normal;
  const isOngoingNormal = ongoingCategory === categoryRef.normal;
  const isCompletedNormal = completedCategory === categoryRef.normal;
  const isOverdueGood = overdueCategory === categoryRef.good;
  const isOngoingGood = ongoingCategory === categoryRef.good;
  const isCompletedGood = completedCategory === categoryRef.good;

  let descRecommendText = 'Sejauh ini sudah cukup.';

  // bad & good || normal
  if (isOverdueBad && !isOngoingBad && !isCompletedBad) {
    descRecommendText = 'Perlu selesaikan tugas-tugas yang terlambat.';
    if (isOngoingGood) {
      //
    }

    if (isOngoingNormal) {
      //
    }

    if (isCompletedGood) {
      descRecommendText = `${descRecommendText} Sudah bagus, banyak tugas yang selesai.`;
    }

    if (isCompletedNormal) {
      descRecommendText = `${descRecommendText} Sudah lumayan, tugas yang diselesaikan sudah cukup.`;
    }
  }

  if (!isOverdueBad && isOngoingBad && !isCompletedBad) {
    descRecommendText = 'Tinggal percepat lagi penyelesaian tugas-tugas yang sedang dikerjakan. Atau tambahkan lagi tugas dari yang belum dikerjakan.';
    if (isOverdueGood) {
      //
    }

    if (isOverdueNormal) {
      //
    }

    if (isCompletedGood) {
      descRecommendText = `${descRecommendText} Sudah bagus, banyak tugas yang selesai.`;
    }

    if (isCompletedNormal) {
      descRecommendText = `${descRecommendText} Sudah lumayan, tugas yang diselesaikan sudah cukup.`;
    }
  }

  if (!isOverdueBad && !isOngoingBad && isCompletedBad) {
    if (isOngoingGood) {
      descRecommendText = 'Jumlah tugas yang dikerjakan sudah bagus';
    }

    if (isOngoingNormal) {
      descRecommendText = 'Jumlah tugas yang dikerjakan cukup';
    }

    descRecommendText = `${descRecommendText} dan sedikit (atau ga ada) tugas yang terlambat. Tinggal selesaikan saja tugas-tugas yang sedang dikerjakan. Karena harus lebih banyak lagi tugas yang harus diselesaikan.`;
  }

  if (isOverdueBad && !isOngoingBad && isCompletedBad) {
    descRecommendText = 'Walau sudah mengerjakan jumlah tugas yang cukup, tapi perlu selesaikan tugas yang terlambat dulu, dan perlu menyelesaikan lebih banyak tugas. Masih kurang banyak tugas yang perlu diselesaikan.';
  }

  if (isOverdueBad && isOngoingBad && !isCompletedBad) {
    descRecommendText = 'Tinggal selesaikan tugas yang masih terlambat dan yang sedang dikerjakan (atau perlu tambahkan lagi tugas menumpuk dari yang belum dikerjakan).';
    if (isCompletedGood) {
      descRecommendText = `${descRecommendText} Sudah bagus, banyak tugas yang selesai.`;
    }

    if (isCompletedNormal) {
      descRecommendText = `${descRecommendText} Sudah lumayan, tugas yang diselesaikan sudah cukup.`;
    }
  }

  if (!isOverdueBad && isOngoingBad && isCompletedBad) {
    descRecommendText = 'Walau tugas terlambatnya sedikit (atau ga ada), tapi perlu selesaikan lebih banyak tugas lagi. Caranya dengan mempercepat pengerjaan tugas yang saat ini masih dikerjakan, atau tambahkan lagi dari tugas menumpuk yang belum dikerjakan.';
  }

  //

  if (isAllGood) {
    descRecommendText = 'Semua bagus. Good job!';
  }

  if (isAllNormal) {
    descRecommendText = 'Semua sudah cukup. Kalau mau lebih bagus, selesaikan lebih banyak tugas lagi.';
  }

  if (isAllBad) {
    descRecommendText = 'Selesaikan yang terlambat dulu, lalu selesaikan tugas lebih banyak lagi. Baik dari yang sedang dikerjakan atau bisa tambahkan lagi dari tugas yang masih menumpuk yang belum dikerjakan. Karena masih kurang banget.';
  }

  return (
    <div className={styles.container}>
      <div className={styles.subTitle}>
        <DisplayTextBody
          mode="12"
          color={Color.gray3}
        >
          {descRecommendText}
        </DisplayTextBody>
      </div>
    </div>
  );
};

StatText.propTypes = {
  completeRatio: PropTypes.object.isRequired,
  ongoingRatio: PropTypes.object.isRequired,
  overdueRatio: PropTypes.object.isRequired,
};

StatText.defaultProps = {};

export default memo(StatText);
