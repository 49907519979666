import React from 'react';
import PropTypes from 'prop-types';
import styles from './FolderSnapshot.module.css';
import PrivateIcon from '../../../../components/UI/PrivateIcon/PrivateIcon';

const FolderSnapshot = ({ folder, clicked }) => {
  // only counting is there any item or not
  const joinedDatas = [...folder?.docs, ...folder?.buckets, ...folder?.files];

  return (
    <div onClick={clicked} className={styles.Folder}>
      <div className={styles.border}>
        <div className={styles.insideBorder} />
      </div>
      <div className={styles.main}>
        <div className={styles.main__title}>
          <h1>
            <PrivateIcon data={folder} size="small" />
            {' '}
            {folder.title}
          </h1>
        </div>
        <div className={styles.main__content}>
          {joinedDatas.length > 0
            ? (
              <p>
                {joinedDatas.length}
                {' '}
                more datas
              </p>
            )
            : null }
        </div>
      </div>
    </div>
  );
};

FolderSnapshot.propTypes = {
  folder: PropTypes.object.isRequired,
  clicked: PropTypes.func.isRequired,
};

export default FolderSnapshot;
