/* eslint-disable import/no-extraneous-dependencies */
import React, { useState, useEffect, memo } from 'react';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import TimeAgo from 'javascript-time-ago';
import en from 'javascript-time-ago/locale/en';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CancelIcon from '@mui/icons-material/Cancel';
import PropTypes from 'prop-types';
import styles from './DateBox.module.css';
import CustomTimeAgo from '../TimeAgo/TimeAgo';

TimeAgo.addDefaultLocale(en);

const DateBox = (
  {
    date,
    clicked,
    expand,
    icon,
    detail,
    relativeTime,
    dateStyle, // only can be used with relativeTime
    exactTime,
    changeColorDate,
    complete,
    isOngoingButOverdue,
  },
) => {
  const [dateFinal, setDateFinal] = useState();
  const [statusDate, setStatusDate] = useState('');

  // function utk mendapatkan kode warna,
  // sesuai status date apakah overdue atau soon
  const statusDateChecker = (inputDate) => {
    const compareDate = new Date(inputDate);
    compareDate.setHours(0, 0, 0, 0);

    const todayDate = new Date();
    todayDate.setHours(0, 0, 0, 0); // set today last midnight

    let soonDate = new Date();
    soonDate.setHours(0, 0, 0, 0);
    soonDate = soonDate.setHours(soonDate.getHours() + 72); // set next 3 days

    // jika item complete done kirim warna hijau + icon putih check
    if (complete && complete.status && complete.type === 'done') {
      return '#28a745';
    }

    if (complete && complete.status && complete.type === 'blocked') {
      return '#28a745';
    }
    // jika item complete blocked kirim warna hijau + icon merah cancel

    // jika soon maka kirim warna kuning
    if (compareDate >= todayDate && compareDate <= soonDate) {
      return '#fff76a';
    // jika overdue maka kirim warna merah
    }

    if (compareDate < todayDate) {
      if (isOngoingButOverdue) {
        return '#ffbf00';
      }
      return '#ff4646';
    }
    return '';
  };

  useEffect(() => {
    if (date === undefined) {
      return;
    }
    let dateString;
    dateString = new Date(date);
    dateString = dateString.toString();

    if (exactTime) {
      const baseDate = new Date(date);
      const minute = baseDate.getMinutes();
      let hour = baseDate.getHours();
      const ampm = hour >= 12 ? 'PM' : 'AM';
      // eslint-disable-next-line no-mixed-operators
      hour = ((hour + 11) % 12 + 1);
      const prefixHour = hour >= 10 ? '' : '0';
      const prefixMinute = minute >= 10 ? '' : '0';

      dateString = `${prefixHour + hour}:${prefixMinute}${minute} ${ampm}`;
    } else if (detail) {
      dateString = dateString.split(' ').slice(0, 5).join(' ');
    } else {
      dateString = dateString.split(' ').slice(1, 3).join(' ');
    }

    setDateFinal(dateString);

    // jika ada props utk ganti warna berdasarkan status overdue/soon, maka eksekusi function ini,
    // agar mendapatkan kode warna yang sesuai
    if (changeColorDate) setStatusDate(statusDateChecker(date));
  }, [date, complete]);

  return (
    <>
      {dateFinal === undefined ? null
        : (
          <>
            {changeColorDate
              ? (
                <div onClick={clicked} style={{ backgroundColor: statusDate }} className={expand ? styles.DateBox : `${styles.DateBox} ${styles.noClick}`}>
                  {icon ? (
                    <>
                      {complete
                && complete.status
                && complete.type === 'done'
                        ? <CheckCircleIcon className={styles.whiteStyle} />
                        : (
                          <>
                            {complete
                            && complete.status
                            && complete.type === 'blocked' ? <CancelIcon className={styles.redStyle} /> : <AccessTimeIcon className={statusDate === '#ff4646' || statusDate === '#28a745' ? styles.whiteStyle : null} /> }
                          </>
                        ) }
                    </>
                  ) : null}
                  {relativeTime ? <p><CustomTimeAgo date={date} dateStyle={dateStyle} withoutOn /></p> : <p className={statusDate === '#ff4646' || statusDate === '#28a745' ? styles.whiteStyle : null}>{dateFinal}</p>}
                  {expand ? <ExpandMoreIcon className={statusDate === '#ff4646' || statusDate === '#28a745' ? `${styles.expandIcon} ${styles.whiteStyle}` : styles.expandIcon} /> : null}
                </div>
              )
              : (
                <div onClick={clicked} className={expand ? styles.DateBox : `${styles.DateBox} ${styles.noClick}`}>
                  {icon ? <AccessTimeIcon /> : null}
                  {relativeTime
                    ? <p><CustomTimeAgo date={date} dateStyle={dateStyle} withoutOn /></p>
                    : <p>{dateFinal}</p>}
                  {expand ? <ExpandMoreIcon className={styles.expandIcon} /> : null}
                </div>
              )}
          </>
        )}
    </>
  );
};

DateBox.propTypes = {
  date: PropTypes.instanceOf(Date).isRequired,
  clicked: PropTypes.func.isRequired,
  expand: PropTypes.bool.isRequired,
  icon: PropTypes.bool.isRequired,
  detail: PropTypes.string.isRequired,
  relativeTime: PropTypes.bool.isRequired,
  dateStyle: PropTypes.string.isRequired, // only can be used with relativeTime
  exactTime: PropTypes.bool.isRequired,
  changeColorDate: PropTypes.bool.isRequired,
  complete: PropTypes.object.isRequired,
  isOngoingButOverdue: PropTypes.bool,
};

DateBox.defaultProps = {
  isOngoingButOverdue: false,
};

export default memo(DateBox);
