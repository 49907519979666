import React, { memo } from 'react';
import PropTypes from 'prop-types';
import styles from './Line.module.css';

const Line = ({ customStyle }) => (
  <div className={styles.Line} style={customStyle} />
);

Line.propTypes = {
  customStyle: PropTypes.object,
};

Line.defaultProps = {
  customStyle: {},
};

export default memo(Line);
