import React, { memo, useState } from 'react';
import PropTypes from 'prop-types';
import { Alert, useMediaQuery } from '@mui/material';
import styles from './ChangeEmailContainer.module.css';
import Color from '../../../../themes/colors';
import { InputButtonMain } from '../../../../components/UI_V2/Input/Button';
import { validateEmail } from '../../../../utilities/inputValidatorUtil';
import handleLoadings from '../../../../utilities/handleLoadings';
import { AuthActions } from '../../../../actions';
import { DisplayTextBody } from '../../../../components/UI_V2/Display/Text';
import { InputTextFieldMain } from '../../../../components/UI_V2/Input/TextField';
import handleStatusMsg from '../../../../utilities/handleStatusMsg';

const ChangeEmailContainer = ({
  handleHideChangeEmail,
  emailUserBefore,
}) => {
  const [newUser, setNewUser] = useState({
    email: '',
  });

  const [loadings, setLoadings] = useState([]);

  const [isEmailEmpty, setIsEmailEmpty] = useState(true);
  const [isValidEmail, setIsValidEmail] = useState(true);
  const [errorMsgEmail, setErrorMsgEmail] = useState('');
  const [errorMsgEmpty, setErrorMsgEmpty] = useState('');
  const [showErrorEmpty, setShowErrorEmpty] = useState(false);
  const [errorMsgClickStillError, setErrorMsgClickStillError] = useState('');
  const [showErrorClickStillError, setShowErrorClickStillError] = useState(false);

  const [showSuccessChangeEmail, setShowSuccessChangeEmail] = useState(false);
  const [successChangeEmailMsg, setSuccessChangeEmailMsg] = useState('');

  const [showErrorChangeEmail, setShowErrorChangeEmail] = useState(false);
  const [errorChangeEmailMsg, setErrorChangeEmailMsg] = useState('');

  const [isProcessing, setIsProcessing] = useState(false);

  const handleChanged = (event) => {
    const { name, value } = event.target;
    setNewUser((prevValue) => ({
      ...prevValue,
      [name]: value,
    }));

    if (showErrorEmpty) {
      setShowErrorEmpty(false);
      setErrorMsgEmpty('');
    }

    if (showErrorClickStillError) {
      setShowErrorClickStillError(false);
      setErrorMsgClickStillError('');
    }

    if (showSuccessChangeEmail) {
      setShowSuccessChangeEmail(false);
      setSuccessChangeEmailMsg('');
    }

    if (showErrorChangeEmail) {
      setShowErrorChangeEmail(false);
      setErrorChangeEmailMsg('');
    }

    if (name === 'email') {
      const isEmpty = value.length < 1;

      if (isEmpty) {
        setIsEmailEmpty(true);
        setIsValidEmail(true);
        setErrorMsgEmail('');
        return;
      }

      setIsEmailEmpty(false);

      const validEmail = validateEmail(value);

      if (validEmail) {
        setIsValidEmail(true);
        setErrorMsgEmail('');
      } else {
        setIsValidEmail(false);
        setErrorMsgEmail('Email harus dengan format nama@domain.com (cth: john@doe.com)');
      }
    }
  };

  const isThereAnyEmpty = isEmailEmpty;

  const isThereAnyError = !isValidEmail;

  const handleClickWhenEmpty = (event) => {
    if (event) {
      event.preventDefault();
    }

    let message = '';
    if (isEmailEmpty) {
      message = `${message}Email masih kosong. `;
    }

    setErrorMsgEmpty(message);
    setShowErrorEmpty(true);
  };

  const handleClickWhenStillError = () => {
    setErrorMsgClickStillError('Belum bisa melanjutkan, perbaiki dulu error diatas.');
    setShowErrorClickStillError(true);
  };

  const processChangeEmailApi = async ({
    email,
    emailBefore,
  }) => {
    try {
      const startLoadings = handleLoadings('requestChangeEmail', [...loadings], 'start');
      setLoadings([...startLoadings]);

      const result = await AuthActions.requestChangeEmail({
        email,
        emailBefore,
      });

      // const status = handleStatusMsg(result, 'success');

      setShowSuccessChangeEmail(true);
      setShowErrorChangeEmail(false);
      setSuccessChangeEmailMsg('Permintaan ubah email berhasil. Klik link di email kamu untuk melanjutkan.');
      setErrorChangeEmailMsg('');
    } catch (err) {
      const status = handleStatusMsg(err, 'error');

      setShowErrorChangeEmail(true);
      setShowSuccessChangeEmail(false);
      setErrorChangeEmailMsg(status.message);
      setSuccessChangeEmailMsg('');
    } finally {
      const endLoadings = handleLoadings('requestChangeEmail', [...loadings], 'end');
      setLoadings([...endLoadings]);
      setIsProcessing(false);
    }
  };

  const handleClickRequestChangeEmail = (event) => {
    if (event) {
      event.preventDefault();
    }

    if (isThereAnyEmpty) {
      handleClickWhenEmpty();
      return;
    }

    if (isThereAnyError) {
      handleClickWhenStillError();
      return;
    }

    if (isProcessing) return;

    setIsProcessing(true);

    processChangeEmailApi({
      email: newUser.email,
      emailBefore: emailUserBefore,
    });
  };
  return (
    <div className={styles.container}>
      <form onSubmit={isThereAnyEmpty ? handleClickWhenEmpty : handleClickRequestChangeEmail}>
        <div className={styles.form}>
          <div className={styles.formText}>
            <DisplayTextBody
              decoration="bold"
            >
              Email
            </DisplayTextBody>
          </div>
          <div className={styles.formArea}>
            <InputTextFieldMain
              placeholder="emailkamu@gmail.com"
              name="email"
              onChange={(event) => handleChanged(event)}
              value={newUser.email}
              border="rounded"
              type="email"
              size="lg"
            />
          </div>
        </div>
        {!isValidEmail && <Alert severity="error">{errorMsgEmail}</Alert>}

        {isThereAnyEmpty && showErrorEmpty && <div className={styles.alertEmptySection}><Alert severity="error">{errorMsgEmpty}</Alert></div>}
        {isThereAnyError && showErrorClickStillError && <div className={styles.alertEmptySection}><Alert severity="error">{errorMsgClickStillError}</Alert></div>}

        {showSuccessChangeEmail && (
        <div className={styles.alertClickSection}>
          <Alert severity="success">{successChangeEmailMsg}</Alert>
        </div>
        )}
        {showErrorChangeEmail && (
        <div className={styles.alertClickSection}>
          <Alert severity="error">{errorChangeEmailMsg}</Alert>
        </div>
        )}
        <div className={styles.action}>
          <div className={styles.button}>
            <InputButtonMain
              border="rounded"
              handleClick={handleHideChangeEmail}
              variant="light"
            >
              Kembali
            </InputButtonMain>
          </div>
          <div className={styles.button}>
            <InputButtonMain
              border="rounded"
              handleClick={isThereAnyEmpty ? handleClickWhenEmpty : handleClickRequestChangeEmail}
              loadings={loadings}
              wait="requestChangeEmail"
              type="submit"
            >
              Ubah Email
            </InputButtonMain>
          </div>
        </div>
      </form>
    </div>
  );
};

ChangeEmailContainer.propTypes = {
  handleHideChangeEmail: PropTypes.func.isRequired,
  emailUserBefore: PropTypes.string.isRequired,
};

ChangeEmailContainer.defaultProps = {};

export default memo(ChangeEmailContainer);
