import React, { memo } from 'react';
import ChatBubbleOutlineIcon from '@mui/icons-material/ChatBubbleOutline';
import PropTypes from 'prop-types';
import { isEqual, isNumber } from 'lodash';
import styles from './CommentIcon.module.css';

const CommentIcon = ({ comments, clicked }) => (
  <>
    {!isNumber(comments?.length) || comments?.length === 0 ? null : (
      <div onClick={clicked} className={styles.CommentIcon}>
        <ChatBubbleOutlineIcon />
        <p>{comments.length}</p>
      </div>
    ) }
  </>
);

CommentIcon.propTypes = {
  comments: PropTypes.array.isRequired,
  clicked: PropTypes.func.isRequired,
};

const isComponentDataEqual = (prevProps, nextProps) => {
  const { comments } = nextProps;
  return isEqual(prevProps.comments, comments);
};

export default memo(CommentIcon, isComponentDataEqual);
