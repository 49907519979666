import React, { memo, useState } from 'react';
import PropTypes from 'prop-types';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import styles from './Referral.module.css';
import { DisplayTextBody, DisplayTextSubHeadline } from '../../../../../components/UI_V2/Display/Text';
import Color from '../../../../../themes/colors';
import arrowIllustration from '../../../../../assets/DashboardAdminPage/PirateMetricPage/arrow-referral-illustration.png';
import referralIllustration from '../../../../../assets/DashboardAdminPage/PirateMetricPage/5-referral-illustration.png';
import { getSingleOrPlural } from '../../../../../utilities/stringUtil';
import BoxText from '../BoxText/BoxText';
import PercentageText from '../PercentageText/PercentageText';

const Referral = ({
  value,
  percentage,
  url,
}) => (
  <div className={styles.container}>
    <div className={styles.content}>
      <div className={styles.title}>
        <DisplayTextSubHeadline
          decoration="bold"
        >
          Referral
        </DisplayTextSubHeadline>
      </div>
      <div className={styles.percentage}>
        <PercentageText percentage={percentage} />
      </div>
    </div>
    <div className={styles.box}>
      <BoxText desc="Trial" value={value} url={url} />
    </div>
    <div className={styles.arrowIllustration}>
      <LazyLoadImage src={arrowIllustration} />
    </div>
    <div className={styles.referralIllustration}>
      <LazyLoadImage src={referralIllustration} />
    </div>
  </div>
);

Referral.propTypes = {
  value: PropTypes.number,
  percentage: PropTypes.number,
  url: PropTypes.string.isRequired,
};

Referral.defaultProps = {
  value: 0,
  percentage: 0,
};

export default memo(Referral);
