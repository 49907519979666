import React, { useState, useEffect, useCallback } from 'react';
import { useHistory, useParams, useLocation } from 'react-router-dom';
import CloseIcon from '@mui/icons-material/Close';
import { useSnackbar } from 'notistack';
import axios from 'axios';
import PropTypes from 'prop-types';
import Modal from 'react-bootstrap/Modal';
import styles from './ListSeen.module.css';
import handleStatusMsg from '../../../../utilities/handleStatusMsg';
import handleLoadings from '../../../../utilities/handleLoadings';
import { SeenConstants } from '../../../../constants';
import InfinityScroll from '../../../UI/InfinityScroll/InfinityScroll';
import SeenItem from './SeenItem/SeenItem';
import { SeenActions } from '../../../../actions';
import { useInfinityScrollHooks } from '../../../../hooks/InfinityScrollHooks';
import { handleCheckToday, handleCompareDate } from '../../../../utilities/dateUtil';
import DateSeparator from '../../../../pages/CommentsSectionContainer/DateSeparator/DateSeparator';
import ListSeenSkeleton from './ListSeenSkeleton/ListSeenSkeleton';
import FeedbackLoadingMain from '../../../UI_V2/Feedback/Loading/Main/Main';

const { typeModule } = SeenConstants;

const ListSeen = ({
  type,
  itemId,
  forceParentIds,
  forceTeamId,
}) => {
  /* Initiate list data */
  const initialStatePreviousSeen = {
    data: new Array(SeenConstants.limitSeen),
  };
  const initialStateCurrentSeen = {
    data: [],
  };
  const [previousSeen, setPreviousSeen] = useState(initialStatePreviousSeen);
  const [currentSeen, setCurrentSeen] = useState(initialStateCurrentSeen);
  const [isFirstLoad, setIsFirstLoad] = useState(true);
  const [loadings, setLoadings] = useState([]);

  const params = useParams();
  const history = useHistory();
  const location = useLocation();
  const {
    companyId,
    chatId,
    cardId,
    commentId,
    groupChatId,
    postId,
    docId,
    fileId,
    eventId,
    occurrenceId,
    questionId,
  } = params;

  const teamId = params.teamId || forceTeamId;

  const { enqueueSnackbar } = useSnackbar();

  let initiateListFunc;
  let loadMoreListFunc;
  switch (type) {
    case typeModule.chatMessage:
      initiateListFunc = () => SeenActions.initiateSeenChatMessage({
        companyId,
        messageId: itemId,
        chatId,
        setCurrentSeen,
        setPreviousSeen,
        initialStatePreviousSeen,
      });
      loadMoreListFunc = () => SeenActions.loadMoreSeenChatMessage({
        companyId,
        messageId: itemId,
        chatId,
        setCurrentSeen,
        setPreviousSeen,
        currentSeen,
      });
      break;
    case typeModule.chatAttachment:
      initiateListFunc = () => SeenActions.initiateSeenChatAttachment({
        companyId,
        attachmentId: itemId,
        chatId,
        setCurrentSeen,
        setPreviousSeen,
        initialStatePreviousSeen,
      });
      loadMoreListFunc = () => SeenActions.loadMoreSeenChatAttachment({
        companyId,
        attachmentId: itemId,
        chatId,
        setCurrentSeen,
        setPreviousSeen,
        currentSeen,
      });
      break;
    case typeModule.groupChatMessage:
      initiateListFunc = () => SeenActions.initiateSeenGroupChatMessage({
        companyId,
        teamId,
        groupMessageId: itemId,
        groupChatId,
        setCurrentSeen,
        setPreviousSeen,
        initialStatePreviousSeen,
      });
      loadMoreListFunc = () => SeenActions.loadMoreSeenGroupChatMessage({
        companyId,
        teamId,
        groupMessageId: itemId,
        groupChatId,
        setCurrentSeen,
        setPreviousSeen,
        currentSeen,
      });
      break;
    case typeModule.groupChatAttachment:
      initiateListFunc = () => SeenActions.initiateSeenGroupChatAttachment({
        companyId,
        teamId,
        attachmentId: itemId,
        groupChatId,
        setCurrentSeen,
        setPreviousSeen,
        initialStatePreviousSeen,
      });
      loadMoreListFunc = () => SeenActions.loadMoreSeenGroupChatAttachment({
        companyId,
        teamId,
        attachmentId: itemId,
        groupChatId,
        setCurrentSeen,
        setPreviousSeen,
        currentSeen,
      });
      break;
    case typeModule.card:
      initiateListFunc = () => SeenActions.initiateSeenCard({
        companyId,
        teamId,
        cardId: itemId,
        setCurrentSeen,
        setPreviousSeen,
        initialStatePreviousSeen,
      });
      loadMoreListFunc = () => SeenActions.loadMoreSeenCard({
        companyId,
        teamId,
        cardId: itemId,
        setCurrentSeen,
        setPreviousSeen,
        currentSeen,
      });
      break;
    case typeModule.cardComment:
      initiateListFunc = () => SeenActions.initiateSeenCardComment({
        companyId,
        teamId,
        cardId: cardId || forceParentIds[0],
        commentId: itemId,
        setCurrentSeen,
        setPreviousSeen,
        initialStatePreviousSeen,
      });
      loadMoreListFunc = () => SeenActions.loadMoreSeenCardComment({
        companyId,
        teamId,
        cardId,
        commentId: itemId,
        setCurrentSeen,
        setPreviousSeen,
        currentSeen,
      });
      break;
    case typeModule.cardDiscussion:
      initiateListFunc = () => SeenActions.initiateSeenCardDiscussion({
        companyId,
        teamId,
        cardId,
        commentId,
        discussionId: itemId,
        setCurrentSeen,
        setPreviousSeen,
        initialStatePreviousSeen,
      });
      loadMoreListFunc = () => SeenActions.loadMoreSeenCardDiscussion({
        companyId,
        teamId,
        cardId,
        commentId,
        discussionId: itemId,
        setCurrentSeen,
        setPreviousSeen,
        currentSeen,
      });
      break;
    case typeModule.post:
      initiateListFunc = () => SeenActions.initiateSeenPost({
        companyId,
        teamId,
        postId: itemId,
        setCurrentSeen,
        setPreviousSeen,
        initialStatePreviousSeen,
      });
      loadMoreListFunc = () => SeenActions.loadMoreSeenPost({
        companyId,
        teamId,
        postId: itemId,
        setCurrentSeen,
        setPreviousSeen,
        currentSeen,
      });
      break;
    case typeModule.postComment:
      initiateListFunc = () => SeenActions.initiateSeenPostComment({
        companyId,
        teamId,
        postId: postId || forceParentIds[0],
        commentId: itemId,
        setCurrentSeen,
        setPreviousSeen,
        initialStatePreviousSeen,
      });
      loadMoreListFunc = () => SeenActions.loadMoreSeenPostComment({
        companyId,
        teamId,
        postId,
        commentId: itemId,
        setCurrentSeen,
        setPreviousSeen,
        currentSeen,
      });
      break;
    case typeModule.postDiscussion:
      initiateListFunc = () => SeenActions.initiateSeenPostDiscussion({
        companyId,
        teamId,
        postId,
        commentId,
        discussionId: itemId,
        setCurrentSeen,
        setPreviousSeen,
        initialStatePreviousSeen,
      });
      loadMoreListFunc = () => SeenActions.loadMoreSeenPostDiscussion({
        companyId,
        teamId,
        postId,
        commentId,
        discussionId: itemId,
        setCurrentSeen,
        setPreviousSeen,
        currentSeen,
      });
      break;
    case typeModule.doc:
      initiateListFunc = () => SeenActions.initiateSeenDoc({
        companyId,
        teamId,
        docId: itemId,
        setCurrentSeen,
        setPreviousSeen,
        initialStatePreviousSeen,
      });
      loadMoreListFunc = () => SeenActions.loadMoreSeenDoc({
        companyId,
        teamId,
        docId: itemId,
        setCurrentSeen,
        setPreviousSeen,
        currentSeen,
      });
      break;
    case typeModule.docComment:
      initiateListFunc = () => SeenActions.initiateSeenDocComment({
        companyId,
        teamId,
        docId: docId || forceParentIds[0],
        commentId: itemId,
        setCurrentSeen,
        setPreviousSeen,
        initialStatePreviousSeen,
      });
      loadMoreListFunc = () => SeenActions.loadMoreSeenDocComment({
        companyId,
        teamId,
        docId,
        commentId: itemId,
        setCurrentSeen,
        setPreviousSeen,
        currentSeen,
      });
      break;
    case typeModule.docDiscussion:
      initiateListFunc = () => SeenActions.initiateSeenDocDiscussion({
        companyId,
        teamId,
        docId,
        commentId,
        discussionId: itemId,
        setCurrentSeen,
        setPreviousSeen,
        initialStatePreviousSeen,
      });
      loadMoreListFunc = () => SeenActions.loadMoreSeenDocDiscussion({
        companyId,
        teamId,
        docId,
        commentId,
        discussionId: itemId,
        setCurrentSeen,
        setPreviousSeen,
        currentSeen,
      });
      break;
    case typeModule.file:
      initiateListFunc = () => SeenActions.initiateSeenFile({
        companyId,
        teamId,
        fileId: itemId,
        setCurrentSeen,
        setPreviousSeen,
        initialStatePreviousSeen,
      });
      loadMoreListFunc = () => SeenActions.loadMoreSeenFile({
        companyId,
        teamId,
        fileId: itemId,
        setCurrentSeen,
        setPreviousSeen,
        currentSeen,
      });
      break;
    case typeModule.fileComment:
      initiateListFunc = () => SeenActions.initiateSeenFileComment({
        companyId,
        teamId,
        fileId: fileId || forceParentIds[0],
        commentId: itemId,
        setCurrentSeen,
        setPreviousSeen,
        initialStatePreviousSeen,
      });
      loadMoreListFunc = () => SeenActions.loadMoreSeenFileComment({
        companyId,
        teamId,
        fileId,
        commentId: itemId,
        setCurrentSeen,
        setPreviousSeen,
        currentSeen,
      });
      break;
    case typeModule.fileDiscussion:
      initiateListFunc = () => SeenActions.initiateSeenFileDiscussion({
        companyId,
        teamId,
        fileId,
        commentId,
        discussionId: itemId,
        setCurrentSeen,
        setPreviousSeen,
        initialStatePreviousSeen,
      });
      loadMoreListFunc = () => SeenActions.loadMoreSeenFileDiscussion({
        companyId,
        teamId,
        fileId,
        commentId,
        discussionId: itemId,
        setCurrentSeen,
        setPreviousSeen,
        currentSeen,
      });
      break;
    case typeModule.questionComment:
      initiateListFunc = () => SeenActions.initiateSeenQuestionComment({
        companyId,
        teamId,
        questionId: questionId || forceParentIds[0],
        commentId: itemId,
        setCurrentSeen,
        setPreviousSeen,
        initialStatePreviousSeen,
      });
      loadMoreListFunc = () => SeenActions.loadMoreSeenQuestionComment({
        companyId,
        teamId,
        questionId,
        commentId: itemId,
        setCurrentSeen,
        setPreviousSeen,
        currentSeen,
      });
      break;
    case typeModule.questionDiscussion:
      initiateListFunc = () => SeenActions.initiateSeenQuestionDiscussion({
        companyId,
        teamId,
        questionId,
        commentId,
        discussionId: itemId,
        setCurrentSeen,
        setPreviousSeen,
        initialStatePreviousSeen,
      });
      loadMoreListFunc = () => SeenActions.loadMoreSeenQuestionDiscussion({
        companyId,
        teamId,
        questionId,
        commentId,
        discussionId: itemId,
        setCurrentSeen,
        setPreviousSeen,
        currentSeen,
      });
      break;
    case typeModule.event:
      initiateListFunc = () => SeenActions.initiateSeenEvent({
        companyId,
        teamId,
        eventId: itemId,
        setCurrentSeen,
        setPreviousSeen,
        initialStatePreviousSeen,
      });
      loadMoreListFunc = () => SeenActions.loadMoreSeenEvent({
        companyId,
        teamId,
        eventId: itemId,
        setCurrentSeen,
        setPreviousSeen,
        currentSeen,
      });
      break;
    case typeModule.eventComment:
      initiateListFunc = () => SeenActions.initiateSeenEventComment({
        companyId,
        teamId,
        eventId: eventId || forceParentIds[0],
        commentId: itemId,
        setCurrentSeen,
        setPreviousSeen,
        initialStatePreviousSeen,
      });
      loadMoreListFunc = () => SeenActions.loadMoreSeenEventComment({
        companyId,
        teamId,
        eventId,
        commentId: itemId,
        setCurrentSeen,
        setPreviousSeen,
        currentSeen,
      });
      break;
    case typeModule.eventDiscussion:
      initiateListFunc = () => SeenActions.initiateSeenEventDiscussion({
        companyId,
        teamId,
        eventId,
        commentId,
        discussionId: itemId,
        setCurrentSeen,
        setPreviousSeen,
        initialStatePreviousSeen,
      });
      loadMoreListFunc = () => SeenActions.loadMoreSeenEventDiscussion({
        companyId,
        teamId,
        eventId,
        commentId,
        discussionId: itemId,
        setCurrentSeen,
        setPreviousSeen,
        currentSeen,
      });
      break;
    case typeModule.occurrence:
      initiateListFunc = () => SeenActions.initiateSeenOccurrence({
        companyId,
        teamId,
        eventId: itemId,
        setCurrentSeen,
        setPreviousSeen,
        initialStatePreviousSeen,
      });
      loadMoreListFunc = () => SeenActions.loadMoreSeenOccurrence({
        companyId,
        teamId,
        eventId: itemId,
        setCurrentSeen,
        setPreviousSeen,
        currentSeen,
      });
      break;
    case typeModule.occurrenceComment:
      initiateListFunc = () => SeenActions.initiateSeenOccurrenceComment({
        companyId,
        teamId,
        eventId: eventId || forceParentIds[0],
        occurrenceId,
        commentId: itemId,
        setCurrentSeen,
        setPreviousSeen,
        initialStatePreviousSeen,
      });
      loadMoreListFunc = () => SeenActions.loadMoreSeenOccurrenceComment({
        companyId,
        teamId,
        eventId,
        occurrenceId,
        commentId: itemId,
        setCurrentSeen,
        setPreviousSeen,
        currentSeen,
      });
      break;
    case typeModule.occurrenceDiscussion:
      initiateListFunc = () => SeenActions.initiateSeenOccurrenceDiscussion({
        companyId,
        teamId,
        eventId,
        occurrenceId,
        commentId,
        discussionId: itemId,
        setCurrentSeen,
        setPreviousSeen,
        initialStatePreviousSeen,
      });
      loadMoreListFunc = () => SeenActions.loadMoreSeenOccurrenceDiscussion({
        companyId,
        teamId,
        eventId,
        occurrenceId,
        commentId,
        discussionId: itemId,
        setCurrentSeen,
        setPreviousSeen,
        currentSeen,
      });
      break;
    default:
      break;
  }

  const getMoreLists = async () => {
    const result = await loadMoreListFunc();

    return result;
  };

  const resetPreviousLists = useCallback(() => {
    setPreviousSeen(initialStatePreviousSeen);
    setCurrentSeen(initialStateCurrentSeen);
  }, []);

  const {
    lists,
    handleLoadMoreLists,
    checkIfListNeedToLoad,
    setCheckLoadMore,
    isListsLengthAtTheLimitOrAbove,
  } = useInfinityScrollHooks({
    currentObjectWithKeyProperty: currentSeen,
    previousLists: previousSeen?.data,
    resetPreviousLists,
    getMoreLists,
    keyProperty: 'data',
    limitList: SeenConstants.limitSeen,
  });

  const initiateSeenApi = async () => {
    try {
      const startLoadings = handleLoadings('initiateSeen', [...loadings], 'start');
      setLoadings([...startLoadings]);

      const result = await initiateListFunc();

      if (isListsLengthAtTheLimitOrAbove(result?.data?.data)) {
        setCheckLoadMore(true);
      } else {
        setCheckLoadMore(false);
      }

      if (isFirstLoad) {
        setIsFirstLoad(false);
      }
    } catch (err) {
      const status = handleStatusMsg(err, 'error');

      enqueueSnackbar(status.message, {
        variant: 'error',
      });
    } finally {
      const endLoadings = handleLoadings('initiateSeen', [...loadings], 'end');
      setLoadings([...endLoadings]);
    }
  };

  useEffect(() => {
    initiateSeenApi();
  }, [location]);

  return (
    <div className={styles.container}>
      <FeedbackLoadingMain
        loadingComponent={<ListSeenSkeleton />}
        wait="initiateSeen"
        loadings={loadings}
      >
        <InfinityScroll
          scrollableTarget="listSeen"
          dataLength={lists?.length || 0}
          hasMore={checkIfListNeedToLoad()}
          next={handleLoadMoreLists}
          emptyMessage="No people seen here yet..."
        >
          {lists.map((seenItem, index) => {
            const isSameDate = handleCompareDate(lists, seenItem, (index - 1), 'readAt');

            if (!isSameDate) {
              return (
                <>
                  <DateSeparator date={seenItem.readAt} />
                  <div className={styles.seenItem}>
                    <SeenItem
                      seenItem={seenItem}
                    />
                  </div>
                </>
              );
            }
            return (
              <div className={styles.seenItem}>
                <SeenItem
                  seenItem={seenItem}
                />
              </div>
            );
          })}
        </InfinityScroll>
      </FeedbackLoadingMain>
    </div>
  );
};

ListSeen.propTypes = {
  type: PropTypes.string.isRequired,
  itemId: PropTypes.string.isRequired,
  forceParentIds: PropTypes.array,
  forceTeamId: PropTypes.string,
};

ListSeen.defaultProps = {
  forceParentIds: [],
  forceTeamId: undefined,
};

export default ListSeen;
