import React, { memo, useState } from 'react';
import PropTypes from 'prop-types';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import styles from './CompleteRatioInterpretation.module.css';
import Color from '../../../../../themes/colors';
import { DisplayTextBody, DisplayTextHeadline, DisplayTextSubHeadline } from '../../../../../components/UI_V2/Display/Text';
import { sliceNumber } from '../../../../../utilities/stringUtil';

const titleColorStyles = {
  good: Color.tosca,
  normal: Color.blueNavy2,
  bad: Color.deleteColor,
};

const CompleteRatioInterpretation = ({
  completeRatioAverage,
}) => (
  <div className={styles.container}>
    <div className={styles.title}>
      <DisplayTextHeadline color={titleColorStyles[completeRatioAverage.category]} decoration="bold">
        {`${sliceNumber(completeRatioAverage.percentage, 4)}%`}
      </DisplayTextHeadline>
    </div>
    <div className={styles.subTitle}>
      <DisplayTextBody position="center" color={Color.gray3}>
        tingkat penyelesaian tugas
      </DisplayTextBody>
    </div>
  </div>
);

CompleteRatioInterpretation.propTypes = {
  completeRatioAverage: PropTypes.object,
};

CompleteRatioInterpretation.defaultProps = {
  completeRatioAverage: {
    percentage: 0,
    category: 'bad',
  },
};

export default memo(CompleteRatioInterpretation);
