import React, { memo, useState } from 'react';
import PropTypes from 'prop-types';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import styles from './OverdueRatioInterpretation.module.css';
import Color from '../../../../../themes/colors';
import { DisplayTextBody, DisplayTextHeadline, DisplayTextSubHeadline } from '../../../../../components/UI_V2/Display/Text';
import { sliceNumber } from '../../../../../utilities/stringUtil';

const titleColorStyles = {
  good: Color.tosca,
  normal: Color.blueNavy2,
  bad: Color.deleteColor,
};

const OverdueRatioInterpretation = ({
  overdueRatioAverage,
}) => (
  <div className={styles.container}>
    <div className={styles.title}>
      <DisplayTextHeadline color={titleColorStyles[overdueRatioAverage.category]} decoration="bold">
        {`${sliceNumber(overdueRatioAverage.percentage, 4)}%`}
      </DisplayTextHeadline>
    </div>
    <div className={styles.subTitle}>
      <DisplayTextBody position="center" color={Color.gray3}>
        tingkat tugas-tugas yang telat
      </DisplayTextBody>
    </div>
  </div>
);

OverdueRatioInterpretation.propTypes = {
  overdueRatioAverage: PropTypes.object,
};

OverdueRatioInterpretation.defaultProps = {
  overdueRatioAverage: {
    percentage: 0,
    category: 'bad',
  },
};

export default memo(OverdueRatioInterpretation);
