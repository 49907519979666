import React, { memo } from 'react';
import PropTypes from 'prop-types';
import styles from './Item.module.css';
import { DisplayTextBody, DisplayTextSubBody } from '../../../Display/Text';
import Color from '../../../../../themes/colors';
import LinkNoDecor from '../../../../LinkNoDecor/LinkNoDecor';
import { DisplayDividerMain } from '../../../Display/Divider';

const fontColorStyles = {
  default: Color.blueNavy4,
  success: Color.darkerTosca,
  danger: Color.deleteColor,
  blueAquaMarine: Color.blueAquaMarine,
  light: Color.gray4,
  blueLight: Color.lighterBlue2,
  warning: Color.yellowAccentCicle,
};

const iconStyles = {
  default: styles.iconDefault,
  success: styles.iconSuccess,
  danger: styles.iconDanger,
  blueAquaMarine: styles.iconBlueAquamarine,
  light: styles.iconLight,
  blueLight: styles.iconBlueLight,
  warning: styles.iconWarning,
};

const NavigationMenuItem = ({
  onClick, text, icon, disableDivider, variant,
}) => (
  <>
    <div className={styles.container} onClick={onClick}>
      <div className={`${styles.icon} ${iconStyles[variant]}`}>
        {icon}
      </div>
      <div className={styles.text}>
        <DisplayTextBody
          color={fontColorStyles[variant]}
          mode="11"
        >
          {text}
        </DisplayTextBody>
      </div>
    </div>
    {!disableDivider && (
    <DisplayDividerMain
      marginTop="0px"
      marginBottom="0px"
      borderColor={Color.lighterBlue3}
    />
    )}
  </>
);

NavigationMenuItem.propTypes = {
  onClick: PropTypes.func,
  text: PropTypes.string.isRequired,
  icon: PropTypes.node.isRequired,
  disableDivider: PropTypes.bool,
  variant: PropTypes.oneOf(['default', 'success', 'danger', 'light', 'blueLight', 'blueAquaMarine', 'warning']),
};

NavigationMenuItem.defaultProps = {
  onClick: () => null,
  disableDivider: false,
  variant: 'default',
};

export default memo(NavigationMenuItem);
