import { cloneDeep } from 'lodash';
import apiUtil from '../utilities/apiUtil';
import { actionTypes } from '../reducers/reducer';
import { ErrorException } from '../utilities/handleError';
import { setSessionLastOpenedCompany, getSessionLastOpenedCompany } from '../utilities/sessionStorage';
import { setStoreLastOpenedCompany, getStoreLastOpenedCompany } from '../utilities/localStorage';
import { ApiConstants, CheckLoginConstants } from '../constants';
import { CompanyActions, TeamActions } from '.';

/*
  Dispatcher
*/

function dispatchUser({ user }, dispatch) {
  dispatch({
    type: actionTypes.SET_USER,
    user,
  });
}

/*
  SetterDispatcher
*/

function setUser({ user }, dispatch) {
  if (!user) return;

  dispatchUser({ user: cloneDeep(user) }, dispatch);
}

/*
  Method
*/

// const checkExceptionUrls = ({ previousPath }) => {
//   if (!previousPath) return false;

//   const [firstUrl, mainUrl, id] = previousPath.split('/');

//   const isException = CheckLoginConstants.exceptionUrls.some(
//     (exceptionUrl) => mainUrl.includes(exceptionUrl),
//   );

//   return isException;
// };

const extractCompanyIdFromPath = ({ previousPath }) => {
  if (!previousPath) return null;
  const [emptyString, companiesUrl, companyId] = previousPath.split('/');
  if (companiesUrl === 'companies' && companyId) {
    return companyId;
  }
  return null;
};

const extractCompanyIdFromSessionStorage = ({ userId }) => {
  const lastOpenedCompany = getSessionLastOpenedCompany({ userId });
  const companyId = lastOpenedCompany?._id;
  return companyId;
};

const extractCompanyIdFromLocalStorage = ({ userId }) => {
  const lastOpenedCompany = getStoreLastOpenedCompany({ userId });
  const companyId = lastOpenedCompany?._id;
  return companyId;
};

const getCurrentCompanyId = ({ previousPath, userId }) => {
  let companyId = extractCompanyIdFromPath({ previousPath });
  if (!companyId) {
    companyId = extractCompanyIdFromSessionStorage({ userId });
    if (!companyId) {
      companyId = extractCompanyIdFromLocalStorage({ userId });
    }
  }
  return companyId;
};

const initiateCurrentCompany = async ({ previousPath, companies, userId }, dispatch) => {
  try {
    let companyId = extractCompanyIdFromPath({ previousPath });

    if (previousPath === '/'
    || previousPath === '/notifications'
    || previousPath === '/cheers'
    || previousPath.includes('/profiles')) {
      companyId = extractCompanyIdFromSessionStorage({ userId });

      if (!companyId) {
        companyId = extractCompanyIdFromLocalStorage({ userId });
      }
    }

    if (!companyId) return null;

    const result = await CompanyActions.getAndSaveCompanyAndLastOpenedCompany(
      { companyId, companies, userId }, dispatch,
    );
    return result;
  } catch (error) {
    throw new ErrorException(error);
  }
};

async function getTokenAndUserWithTempToken(
  { tempToken, userId }, dispatch,
  // setStoreCurrentCompany,
) {
  try {
    const result = await apiUtil.post(ApiConstants.URL_V1.SIGN_IN_CHECK(), { tempToken, userId });

    localStorage.setItem('token', result?.data?.token);
    setUser({ user: result?.data?.user }, dispatch);
    // const companiesAndDefaultCompany = saveCompanyIdAndCheckSubscription(
    //   { defaultCompany: result?.data?.user?.defaultCompany },
    //   setStoreCurrentCompany,
    // );

    // return companiesAndDefaultCompany;
    return result?.data?.user;
  } catch (error) {
    throw new ErrorException(error);
  }
}

async function getUserWithToken(
  {}, dispatch,
  // setStoreCurrentCompany,
) {
  try {
    const result = await apiUtil.get('/api/v1/auth/google/signin/request-user-only');

    setUser({ user: result?.data?.user }, dispatch);
    // const companiesAndDefaultCompany = saveCompanyIdAndCheckSubscription(
    //   { defaultCompany: result?.data?.user?.defaultCompany },
    //   setStoreCurrentCompany,
    // );

    // return companiesAndDefaultCompany;
    return result?.data?.user;
  } catch (error) {
    throw new ErrorException(error);
  }
}

const setUserAndToken = ({
  user,
  token,
}, dispatch) => {
  try {
    setUser({ user }, dispatch);
    localStorage.setItem('token', token);
  } catch (error) {
    throw new ErrorException(error);
  }
};

const refreshTokenAndSetNewToken = async () => {
  try {
    // refresh access token by using refreshToken in cookie.
    // no need explicitly specified the refreshToken in cookie,
    // it already has been set by server in our client browser/device.
    const result = await apiUtil.post(ApiConstants.URL_V1.REFRESH_TOKEN(), {});

    // set new access token
    localStorage.setItem('token', result?.data?.token);

    // for new refresh token, it will set automatically by server on cookie.
    // no need explicitly set the cookie here.

    return result?.data?.token;
  } catch (error) {
    throw new ErrorException(error);
  }
};

const handleAxiosDeleteRefreshToken = async (err) => {
  try {
    let newAuthToken;
    if (err?.response?.status === 401) {
      newAuthToken = await refreshTokenAndSetNewToken();
    }

    return newAuthToken;
  } catch (error) {
    return undefined;
  }
};

export {
  getTokenAndUserWithTempToken,
  getUserWithToken,
  initiateCurrentCompany,
  extractCompanyIdFromPath,
  getCurrentCompanyId,
  refreshTokenAndSetNewToken,
  handleAxiosDeleteRefreshToken,
  setUserAndToken,
};
