import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { Box } from '@mui/material';
import CheckIcon from '@mui/icons-material/Check';
import styles from './MemberFilterSection.module.css';
import { useDelayShowHideHandler, useSearch, useSelectItemsOnList } from '../../../../hooks/HelperHooks';
import { InputSearchGeneral } from '../../../UI_V2/Input/Search';
import ButtonDiv from '../../../UI/Button/ButtonDiv/ButtonDiv';
import { DisplayAvatarMember } from '../../../UI_V2/Display/Avatar';
import { DisplayTextBody } from '../../../UI_V2/Display/Text';

const MemberFilterSection = ({
  searchHook,
  showAllMembers,
  handleShowAllMembers,
  handleHideAllMembers,
  checkMembers,
  handleClickMember,
}) => {
  const {
    searchResult,
    keyword,
    keywordArray,
    setKeyword,
    isTyping,
    onChange,
    resetSearch,
  } = searchHook;

  return (
    <Box className={styles.container}>
      <Box className={styles.titleBox}>
        <DisplayTextBody>
          Filter Anggota
        </DisplayTextBody>
      </Box>
      <Box className={styles.searchBox}>
        <InputSearchGeneral
          onChange={onChange}
          value={keyword}
          placeholder="Cari anggota..."
          onClickCloseIcon={resetSearch}
          size="sm"
          autoFocus={false}
        />
      </Box>
      <Box className={styles.members}>
        {searchResult.length < 1
          && <span>Anggota ga ketemu</span>}
        {showAllMembers
          ? (
            <>
              {searchResult.map((member, index) => {
                if (index === (searchResult.length - 1)) {
                  return (
                    <>
                      <ButtonDiv
                        onClick={() => handleClickMember(member?._id)}
                        className={styles.menu}
                      >
                        <div className={styles.icon}>
                          <DisplayAvatarMember src={member?.photoUrl} />
                        </div>
                        <div className={styles.text}>
                          <p>{member?.fullName}</p>
                          {' '}
                          {checkMembers.some((checkMember) => checkMember === member?._id)
                            && <CheckIcon />}
                        </div>
                      </ButtonDiv>
                      {index >= 4 && (
                      <ButtonDiv onClick={handleHideAllMembers} className={styles.menu}>
                        <p>Hide some members</p>
                      </ButtonDiv>
                      )}
                    </>
                  );
                }
                return (
                  <ButtonDiv
                    onClick={() => handleClickMember(member?._id)}
                    className={styles.menu}
                  >
                    <div className={styles.icon}>
                      <DisplayAvatarMember src={member?.photoUrl} />
                    </div>
                    <div className={styles.text}>
                      <p>{member?.fullName}</p>
                      {' '}
                      {checkMembers.some((checkMember) => checkMember === member?._id)
                        && <CheckIcon />}
                    </div>
                  </ButtonDiv>
                );
              })}
            </>
          )
          : (
            <>
              {searchResult.map((member, index) => {
                if (index < 3) {
                  return (
                    <ButtonDiv
                      onClick={() => handleClickMember(member?._id)}
                      className={styles.menu}
                    >
                      <div className={styles.icon}>
                        <DisplayAvatarMember src={member?.photoUrl} />
                      </div>
                      <div className={styles.text}>
                        <p>{member?.fullName}</p>
                        {' '}
                        {checkMembers.some((checkMember) => checkMember === member?._id)
                          && <CheckIcon />}
                      </div>
                    </ButtonDiv>
                  );
                } if (index === 3) {
                  return (
                    <>
                      <ButtonDiv
                        onClick={() => handleClickMember(member?._id)}
                        className={styles.menu}
                      >
                        <div className={styles.icon}>
                          <DisplayAvatarMember src={member?.photoUrl} />
                        </div>
                        <div className={styles.text}>
                          <p>{member?.fullName}</p>
                          {' '}
                          {checkMembers.some((checkMember) => checkMember === member?._id)
                            && <CheckIcon />}
                        </div>
                      </ButtonDiv>
                      {index !== searchResult.length - 1 && (
                        <ButtonDiv onClick={handleShowAllMembers} className={styles.menu}>
                          <p>
                            Tampilkan lagi (
                            {`${searchResult.length - 4} lagi`}
                            )
                          </p>
                        </ButtonDiv>
                      )}
                    </>
                  );
                }
                return null;
              })}
            </>
          )}
      </Box>
    </Box>
  );
};

MemberFilterSection.propTypes = {
  searchHook: PropTypes.object.isRequired,
  showAllMembers: PropTypes.bool.isRequired,
  handleShowAllMembers: PropTypes.func.isRequired,
  handleHideAllMembers: PropTypes.func.isRequired,
  checkMembers: PropTypes.array.isRequired,
  handleClickMember: PropTypes.func.isRequired,
};

export default memo(MemberFilterSection);
