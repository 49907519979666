import React, { memo, useState } from 'react';
import PropTypes from 'prop-types';
import styles from './PersonChatWith.module.css';
import ImageMember from '../../../../components/UI/ImageMember/ImageMember';
import { DisplayTextBody, DisplayTextSubBody } from '../../../../components/UI_V2/Display/Text';
import Color from '../../../../themes/colors';
import { DisplayAvatarMemberWithOnline } from '../../../../components/UI_V2/Display/Avatar';
import UserTyping from '../../../../components/Container/UserTyping/UserTyping';

const PersonChatWith = ({ user, allUserTypingChat, companyMembers }) => (
  <div className={styles.container}>
    <div className={styles.photo}>
      {user ? (
        <DisplayAvatarMemberWithOnline
          src={user?.photoUrl}
          userId={user?._id}
          marginRight="10px"
          positionPopUp="topLeft"
          size="lg"
        />
      ) : null}
    </div>
    <div className={styles.text}>
      <div className={styles.fullName}>
        <DisplayTextBody
          mode="12"
          color={Color.fontColor}
          decoration="bold"
        >
          {user?.fullName}
        </DisplayTextBody>
      </div>
      {/* <div className={styles.bio}>
        <DisplayTextSubBody
          mode="9"
          color={Color.fontColor}
          fontFamily="openSans"
        >
          {user?.status}
          {' '}
          {user?.bio ? '-' : null}
          {' '}
          {user?.bio}
        </DisplayTextSubBody>
      </div> */}
      <div className={styles.typingSection}>
        <UserTyping
          allUserTyping={allUserTypingChat}
          companyMembers={companyMembers}
          userId={user?._id}
        />
      </div>
    </div>
  </div>
);

PersonChatWith.propTypes = {
  user: PropTypes.object.isRequired,
  allUserTypingChat: PropTypes.array.isRequired,
  companyMembers: PropTypes.array.isRequired,
};

export default memo(PersonChatWith);
