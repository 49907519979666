import React, { memo, useState } from 'react';
import PropTypes from 'prop-types';
import DownloadIcon from '@mui/icons-material/Download';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { useMediaQuery } from '@mui/material';
import styles from './Tab.module.css';
import Color from '../../../../../themes/colors';
import { InputButtonMain } from '../../../../UI_V2/Input/Button';
import { DisplayTextBody } from '../../../../UI_V2/Display/Text';

const Tab = ({
  isActive,
  icon,
  text,
}) => {
  const isMobile = useMediaQuery('(max-width:720px)');
  return (
    <div className={styles.container}>
      <div className={styles.icon}>
        {icon}
      </div>
      <div className={styles.text}>
        <DisplayTextBody decoration={isActive ? 'bold' : 'regular'} mode="14">
          {text}
        </DisplayTextBody>
      </div>
      {/* {isActive && (
      <div className={styles.menu}>
        <MenuTab />
      </div>
      )} */}
      {isActive && <div className={styles.bottomLine} />}
    </div>
  );
};

Tab.propTypes = {
  isActive: PropTypes.bool,
  icon: PropTypes.node,
  text: PropTypes.string,
};

Tab.defaultProps = {
  isActive: false,
  icon: <></>,
  text: '',
};

export default memo(Tab);
