import { cloneDeep, isNumber } from 'lodash';
import { CheerConstants } from '../constants';
import { actionTypes } from '../reducers/reducer';
import apiUtil, { getNextPage } from '../utilities/apiUtil';
import {
  getIndexItemByIDFromList,
  mergeObjectListAndRemoveDuplicate,
  updateListProperty,
} from '../utilities/arrayUtil';
import { ErrorException } from '../utilities/handleError';

/*
  Dispatcher
*/

function dispatchCheersNotif({ data }, dispatch) {
  dispatch({
    type: actionTypes.SET_CHEERS_NOTIFICATIONS,
    data,
  });
}

function dispatchUpdateTotalCheersNotif({ total }, dispatch) {
  dispatch({
    type: actionTypes.UPDATE_TOTAL_CHEERS_NOTIFICATIONS,
    total,
  });
}

function dispatchUpdateCheersNotif({ updateCheersNotification }, dispatch) {
  dispatch({
    type: actionTypes.UPDATE_CHEERS_NOTIFICATIONS,
    updateCheersNotification,
  });
}

/*
  SetterDispatcher
*/

function setCheersNotif({ data }, dispatch) {
  if (!data) return;

  dispatchCheersNotif({ data }, dispatch);
}

function setTotalCheersNotif({ total }, dispatch) {
  if (!isNumber(total)) return;

  dispatchUpdateTotalCheersNotif({ total }, dispatch);
}

/*
  Method
*/

function incomingCheerFromSecondaryParent({
  cheer,
  typeAction,
  secondaryParentAction,
  typeActionEditSecondaryParent,
  keyProperty,
}, dispatch) {
  if (!cheer) return;

  const comment = {
    // ini id nya ngga pake underscore
    _id: cheer?.primaryParent?.id,
  };

  const updateCommentCheer = (previousComment) => {
    const listData = previousComment?.cheers;
    const indexNewCheer = getIndexItemByIDFromList(listData, cheer);

    switch (typeAction) {
      case CheerConstants.typeCallback.NEW:
        if (indexNewCheer >= 0) {
          listData[indexNewCheer] = cheer;
        } else {
          // place to the last item
          listData.splice(previousComment?.cheers?.length, 0, cheer);
        }
        break;
      case CheerConstants.typeCallback.DELETE:
        if (indexNewCheer >= 0) {
          listData.splice(indexNewCheer, 1);
        }
        break;
      default:
        break;
    }

    let newComment = { ...previousComment, cheers: listData };
    newComment = cloneDeep(newComment);

    return newComment;
  };

  secondaryParentAction({
    comment,
    typeAction: typeActionEditSecondaryParent,
    updateCommentCheer,
    keyProperty,
  }, dispatch);
}

function incomingCheerFromSecondaryParentV2({
  cheer,
  typeAction,
  secondaryParentAction,
  typeActionEditSecondaryParent,
  keyProperty,
}, dispatch) {
  if (!cheer) return;

  const currentComment = {
    // ini id nya ngga pake underscore
    _id: cheer?.primaryParent?.id,
  };

  const updateCommentCheer = (previousComment) => {
    const listData = previousComment?.cheers;
    const indexNewCheer = getIndexItemByIDFromList(listData, cheer);

    switch (typeAction) {
      case CheerConstants.typeCallback.NEW:
        if (indexNewCheer >= 0) {
          listData[indexNewCheer] = cheer;
        } else {
          // place to the last item
          listData.splice(previousComment?.cheers?.length, 0, cheer);
        }
        break;
      case CheerConstants.typeCallback.DELETE:
        if (indexNewCheer >= 0) {
          listData.splice(indexNewCheer, 1);
        }
        break;
      default:
        break;
    }

    let newComment = { ...previousComment, cheers: listData };
    newComment = cloneDeep(newComment);

    return newComment;
  };

  secondaryParentAction({
    currentComment,
    typeAction: typeActionEditSecondaryParent,
    updateCommentCheer,
    keyProperty,
  }, dispatch);
}

function incomingCheerFromCommentDiscussion({
  cheer,
  typeAction,
  secondaryParentAction,
  typeActionEditSecondaryParent,
  keyProperty,
}, dispatch) {
  if (!cheer) return;

  const discussion = {
    // ini id nya ngga pake underscore
    _id: cheer?.primaryParent?.id,
  };

  const updateCommentCheer = (previousComment) => {
    const listData = previousComment?.cheers;
    const indexNewCheer = getIndexItemByIDFromList(listData, cheer);

    switch (typeAction) {
      case CheerConstants.typeCallback.NEW:
        if (indexNewCheer >= 0) {
          listData[indexNewCheer] = cheer;
        } else {
          // place to the last item
          listData.splice(previousComment?.cheers?.length, 0, cheer);
        }
        break;
      case CheerConstants.typeCallback.DELETE:
        if (indexNewCheer >= 0) {
          listData.splice(indexNewCheer, 1);
        }
        break;
      default:
        break;
    }

    let newComment = { ...previousComment, cheers: listData };
    newComment = cloneDeep(newComment);

    return newComment;
  };

  secondaryParentAction({
    discussion,
    typeAction: typeActionEditSecondaryParent,
    updateCommentCheer,
    keyProperty,
  }, dispatch);
}

/*
  @params
  cheer => new data cheers (object)
  typeAction => typeAction cheers, new or delete (string)
  primaryParentAction => action to dispatch reducer (function)
  keyProperty => property name for cheers (string)
*/

function incomingCheerFromPrimaryParent({
  cheer,
  typeAction,
  primaryParentAction,
  keyProperty,
}, dispatch) {
  if (!cheer) return;

  const updateStateParent = (currentList) => updateListProperty({
    keyProperty,
    newData: cheer,
    currentList,
    typeAction,
    reverse: false,
  });

  primaryParentAction({ updateStateParent }, dispatch);
}

async function initiateCheers({ companyId }, dispatch) {
  try {
    const result = await apiUtil.get(
      '/api/v1/cheers?',
      {
        params: {
          limit: CheerConstants.limitListCheer,
          page: 1,
          sortBy: 'updatedAt',
          orderBy: 'desc',
          companyId,
        },
      },
    );

    const { data } = result?.data;
    setCheersNotif({ data }, dispatch);

    return result;
  } catch (error) {
    throw new ErrorException(error);
  }
}

async function loadMoreCheers({
  companyId,
  cheersNotifications,
}, dispatch) {
  try {
    const page = getNextPage({
      data: cheersNotifications.data,
      limitPerPage: CheerConstants.limitListCheer,
    });
    const result = await apiUtil.get(
      '/api/v1/cheers?',
      {
        params: {
          limit: CheerConstants.limitListCheer,
          page,
          sortBy: 'updatedAt',
          orderBy: 'desc',
          companyId,
        },
      },
    );

    const total = page * CheerConstants.limitListCheer;
    const mergedDataNotif = mergeObjectListAndRemoveDuplicate({
      currentObjectList: cheersNotifications,
      nextObjectList: result?.data,
      keyObject: 'data',
    });

    setCheersNotif({ data: mergedDataNotif?.data }, dispatch);
    setTotalCheersNotif({ total }, dispatch);

    return result;
  } catch (error) {
    throw new ErrorException(error);
  }
}

export {
  incomingCheerFromSecondaryParent,
  incomingCheerFromSecondaryParentV2,
  incomingCheerFromPrimaryParent,
  incomingCheerFromCommentDiscussion,
  initiateCheers,
  loadMoreCheers,
  setCheersNotif,
  setTotalCheersNotif,
};
