import React, { memo, useState } from 'react';
import PropTypes from 'prop-types';
import { Box } from '@mui/material';
import { DisplayTextBody, DisplayTextSubHeadline } from '../../../../../components/UI_V2/Display/Text';
import Color from '../../../../../themes/colors';

const TitleJumpTo = ({ children }) => (
  <Box
    sx={{
      marginBottom: '5px',
      marginTop: '15px',
    }}
  >
    <DisplayTextBody
      color={Color.gray3}
      mode="14"
      decoration="bold"
    >
      {children}
    </DisplayTextBody>
  </Box>
);

TitleJumpTo.propTypes = {
  children: PropTypes.node.isRequired,
};

export default memo(TitleJumpTo);
