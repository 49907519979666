import { cloneDeep, isNumber } from 'lodash';
import axios from 'axios';
import apiUtil, { getNextPage } from '../utilities/apiUtil';
import { actionTypes } from '../reducers/reducer';
import { ErrorException } from '../utilities/handleError';
import { ApiConstants, BillingConstants } from '../constants';
import { mergeObjectListAndRemoveDuplicate, updateListProperty } from '../utilities/arrayUtil';
import { initialState } from '../contexts/GlobalStateProvider';
import { handleAxiosDeleteRefreshToken } from './CheckLoginActions';

/*
  Dispatcher
*/

function dispatchPreviousInvoices({ previousInvoices }, dispatch) {
  dispatch({
    type: actionTypes.SET_PREVIOUS_INVOICES,
    previousInvoices,
  });
}

function dispatchCurrentInvoices({ currentInvoices }, dispatch) {
  dispatch({
    type: actionTypes.SET_CURRENT_INVOICES,
    currentInvoices,
  });
}

function dispatchUpdateInvoices({ updateInvoices }, dispatch) {
  dispatch({
    type: actionTypes.UPDATE_CURRENT_INVOICES,
    updateInvoices,
  });
}

function dispatchCurrentInvoicesUnpaidCounter(currentInvoicesUnpaidCounter, dispatch) {
  dispatch({
    type: actionTypes.SET_CURRENT_INVOICES_UNPAID_COUNTER,
    currentInvoicesUnpaidCounter,
  });
}

function dispatchPreviousSubscriptions({ previousSubscriptions }, dispatch) {
  dispatch({
    type: actionTypes.SET_PREVIOUS_SUBSCRIPTIONS,
    previousSubscriptions,
  });
}

function dispatchCurrentSubscriptions({ currentSubscriptions }, dispatch) {
  dispatch({
    type: actionTypes.SET_CURRENT_SUBSCRIPTIONS,
    currentSubscriptions,
  });
}

function dispatchUpdateSubscriptions({ updateSubscriptions }, dispatch) {
  dispatch({
    type: actionTypes.UPDATE_CURRENT_SUBSCRIPTIONS,
    updateSubscriptions,
  });
}

/*
  SetterDispatcher
*/

function setPreviousInvoices({ previousInvoices }, dispatch) {
  if (!previousInvoices) return;

  dispatchPreviousInvoices(
    { previousInvoices: cloneDeep(previousInvoices) }, dispatch,
  );
}

function setCurrentInvoices({ currentInvoices }, dispatch) {
  if (!currentInvoices) return;
  dispatchCurrentInvoices(
    { currentInvoices: cloneDeep(currentInvoices) }, dispatch,
  );
}

function setCurrentInvoicesUnpaidCounter(currentInvoicesUnpaidCounter, dispatch) {
  if (!isNumber(currentInvoicesUnpaidCounter)) return;

  dispatchCurrentInvoicesUnpaidCounter(currentInvoicesUnpaidCounter, dispatch);
}

function setPreviousSubscriptions({ previousSubscriptions }, dispatch) {
  if (!previousSubscriptions) return;

  dispatchPreviousSubscriptions(
    { previousSubscriptions: cloneDeep(previousSubscriptions) }, dispatch,
  );
}

function setCurrentSubscriptions({ currentSubscriptions }, dispatch) {
  if (!currentSubscriptions) return;
  dispatchCurrentSubscriptions(
    { currentSubscriptions: cloneDeep(currentSubscriptions) }, dispatch,
  );
}

/*
  Method
*/

async function getPlans({ planDurationCode, companyId }) {
  try {
    let durationStart = 30;
    let durationEnd = 30;
    switch (planDurationCode) {
      case 'monthly':
        durationStart = 30;
        durationEnd = 30;
        break;
      case 'quarterly':
        durationStart = 90;
        durationEnd = 90;
        break;
      case 'biyearly':
        durationStart = 180;
        durationEnd = 180;
        break;
      case 'yearly':
        durationStart = 365;
        durationEnd = 365;
        break;
      default:
        break;
    }
    const result = await apiUtil.get(ApiConstants.URL_V2.PLAN(), {
      params: {
        durationStart,
        durationEnd,
        companyId,
      },
    });

    return result?.data;
  } catch (error) {
    throw new ErrorException(error);
  }
}

async function getSubscriptionStatus({ companyId }) {
  try {
    const result = await apiUtil.get(ApiConstants.URL_V2.SUBSCRIPTION({ companyId }), {
      params: {},
    });

    return result?.data;
  } catch (error) {
    throw new ErrorException(error);
  }
}

async function expireInvoice({ companyId, invoiceId }) {
  try {
    const result = await apiUtil.post(ApiConstants.URL_V2.INVOICE_EXPIRE({ invoiceId }), {}, {
      params: {
        companyId,
      },
    });

    return result?.data;
  } catch (error) {
    throw new ErrorException(error);
  }
}

async function getAddonsWithQty({ companyId, orderId, planType = 'PLAN_ADDON_USER' }) {
  try {
    const result = await apiUtil.get(ApiConstants.URL_V2.ORDER_LIST_ADDONS(), {
      params: {
        companyId,
        orderId,
        planType,
      },
    });

    return result?.data;
  } catch (error) {
    throw new ErrorException(error);
  }
}

async function createOrderSummary({ companyId, items }) {
  try {
    const result = await apiUtil.post(ApiConstants.URL_V2.ORDERS(), {
      items,
    }, {
      params: {
        companyId,
      },
    });

    return result?.data;
  } catch (error) {
    throw new ErrorException(error);
  }
}

async function updateOrderSummary({
  companyId, orderId, items,
}) {
  try {
    const result = await apiUtil.patch(ApiConstants.URL_V2.ORDER({ orderId }), {
      items,
    }, {
      params: {
        companyId,
      },
    });

    return result?.data;
  } catch (error) {
    throw new ErrorException(error);
  }
}

async function addCouponOrderSummary({
  companyId, orderId, couponCode,
}) {
  try {
    const result = await apiUtil.post(ApiConstants.URL_V2.ORDER_COUPON({ orderId }), {
      couponCode,
    }, {
      params: {
        companyId,
      },
    });

    return result?.data;
  } catch (error) {
    throw new ErrorException(error);
  }
}

async function removeCouponOrderSummary({
  companyId, orderId,
}) {
  try {
    const result = await axios.delete(
      `${process.env.REACT_APP_PRIMARY_API_URL}${ApiConstants.URL_V2.ORDER_COUPON({ orderId })}`, {
        withCredentials: true,
        headers: {
          Authorization: `jwt ${localStorage.getItem('token')}`,
        },
        params: {
          companyId,
        },
      },
    );

    return result?.data;
  } catch (error) {
    const newAuthToken = await handleAxiosDeleteRefreshToken(error);
    if (newAuthToken) {
      try {
        const result = await axios.delete(
          `${process.env.REACT_APP_PRIMARY_API_URL}${ApiConstants.URL_V2.ORDER_COUPON({ orderId })}`, {
            withCredentials: true,
            headers: {
              Authorization: `jwt ${localStorage.getItem('token')}`,
            },
            params: {
              companyId,
            },
          },
        );

        return result?.data;
      } catch (err) {
        throw new ErrorException(err);
      }
    }
    throw new ErrorException(error);
  }
}

async function createInvoice({
  companyId,
  orderId,
}) {
  try {
    const result = await apiUtil.post(ApiConstants.URL_V2.INVOICES(), {
      orderId,
    }, {
      params: {
        companyId,
      },
    });

    return result?.data;
  } catch (error) {
    throw new ErrorException(error);
  }
}

async function getInvoicesCounterUnpaid({
  companyId,
}) {
  try {
    const result = await apiUtil.get(ApiConstants.URL_V2.INVOICES_COUNTER_UNPAID(), {
      params: {
        companyId,
      },
    });

    return result?.data;
  } catch (error) {
    throw new ErrorException(error);
  }
}

async function initiateInvoices({
  companyId, status, page = 1, limit = BillingConstants.limitInvoices,
}, dispatch) {
  try {
    const result = await apiUtil.get(ApiConstants.URL_V2.INVOICES(), {
      params: {
        companyId,
        status,
        page,
        limit,
      },
    });

    setCurrentInvoices({ currentInvoices: result?.data }, dispatch);
    setPreviousInvoices({ previousInvoices: initialState.previousInvoices }, dispatch);

    return result;
  } catch (error) {
    throw new ErrorException(error);
  }
}

async function loadMoreInvoices({
  companyId,
  currentInvoices,
  status,
  limit = BillingConstants.limitInvoices,
}, dispatch) {
  try {
    const page = getNextPage({
      data: currentInvoices.data,
      limitPerPage: limit,
    });

    const result = await apiUtil.get(ApiConstants.URL_V2.INVOICES(), {
      params: {
        companyId,
        status,
        page,
        limit,
      },
    });

    const mergedDataInvoices = mergeObjectListAndRemoveDuplicate({
      currentObjectList: currentInvoices,
      nextObjectList: result?.data,
      keyObject: 'data',
    });

    const mergedInvoices = {
      ...result?.data,
      data: mergedDataInvoices?.data,
    };

    setPreviousInvoices({ previousInvoices: result?.data }, dispatch);
    setCurrentInvoices({ currentInvoices: mergedInvoices }, dispatch);

    return result;
  } catch (error) {
    throw new ErrorException(error);
  }
}

async function initiateSubscriptions({
  page = 1, limit = BillingConstants.limitSubscriptions,
}, dispatch) {
  try {
    const result = await apiUtil.get(ApiConstants.URL_V2.SUBSCRIPTIONS(), {
      params: {
        page,
        limit,
      },
    });

    setCurrentSubscriptions({ currentSubscriptions: result?.data }, dispatch);
    setPreviousSubscriptions({
      previousSubscriptions: initialState.previousSubscriptions,
    }, dispatch);

    return result;
  } catch (error) {
    throw new ErrorException(error);
  }
}

async function loadMoreSubscriptions({
  currentSubscriptions,
  limit = BillingConstants.limitSubscriptions,
}, dispatch) {
  try {
    const page = getNextPage({
      data: currentSubscriptions.data,
      limitPerPage: limit,
    });

    const result = await apiUtil.get(ApiConstants.URL_V2.SUBSCRIPTIONS(), {
      params: {
        page,
        limit,
      },
    });

    const mergedDataSubscriptions = mergeObjectListAndRemoveDuplicate({
      currentObjectList: currentSubscriptions,
      nextObjectList: result?.data,
      keyObject: 'data',
    });

    const mergedSubscriptions = {
      ...result?.data,
      data: mergedDataSubscriptions?.data,
    };

    setPreviousSubscriptions({ previousSubscriptions: result?.data }, dispatch);
    setCurrentSubscriptions({ currentSubscriptions: mergedSubscriptions }, dispatch);

    return result;
  } catch (error) {
    throw new ErrorException(error);
  }
}

function incomingInvoices({
  invoice, typeAction, keyProperty = 'data',
}, dispatch) {
  if (!invoice) return;

  const updateInvoices = (currentInvoices) => updateListProperty({
    keyProperty,
    newData: invoice,
    currentList: currentInvoices,
    typeAction,
  });

  dispatchUpdateInvoices({ updateInvoices }, dispatch);
}

export {
  getPlans,
  getSubscriptionStatus,
  getInvoicesCounterUnpaid,
  initiateInvoices,
  loadMoreInvoices,
  setPreviousInvoices,
  setCurrentInvoices,
  createOrderSummary,
  createInvoice,
  getAddonsWithQty,
  updateOrderSummary,
  expireInvoice,
  incomingInvoices,
  setCurrentInvoicesUnpaidCounter,
  initiateSubscriptions,
  loadMoreSubscriptions,
  setPreviousSubscriptions,
  setCurrentSubscriptions,
  addCouponOrderSummary,
  removeCouponOrderSummary,
};
