import { cloneDeep } from 'lodash';
import { actionTypes } from '../reducers/reducer';
import { updateListSocket } from '../utilities/arrayUtil';
import { UserTypingConstants } from '../constants';
import {
  limitUserTyping, limitUserTypingMobile, limitUserTypingNameChar, socketEvent,
} from '../constants/UserTypingConstants';
import { limitChar } from '../utilities/stringUtil';

/*
  Dispatcher
*/

function dispatchAllUserTypingChat({ allUserTypingChat }, dispatch) {
  dispatch({
    type: actionTypes.SET_ALL_USER_TYPING_CHAT,
    allUserTypingChat,
  });
}

function dispatchUpdateAllUserTypingChat({ updateAllUserTypingChat }, dispatch) {
  dispatch({
    type: actionTypes.UPDATE_ALL_USER_TYPING_CHAT,
    updateAllUserTypingChat,
  });
}

function dispatchAllUserTypingGroupChat({ allUserTypingGroupChat }, dispatch) {
  dispatch({
    type: actionTypes.SET_ALL_USER_TYPING_GROUP_CHAT,
    allUserTypingGroupChat,
  });
}

function dispatchUpdateAllUserTypingGroupChat({ updateAllUserTypingGroupChat }, dispatch) {
  dispatch({
    type: actionTypes.UPDATE_ALL_USER_TYPING_GROUP_CHAT,
    updateAllUserTypingGroupChat,
  });
}

function dispatchAllUserTypingCard({ allUserTypingCard }, dispatch) {
  dispatch({
    type: actionTypes.SET_ALL_USER_TYPING_CARD,
    allUserTypingCard,
  });
}

function dispatchUpdateAllUserTypingCard({ updateAllUserTypingCard }, dispatch) {
  dispatch({
    type: actionTypes.UPDATE_ALL_USER_TYPING_CARD,
    updateAllUserTypingCard,
  });
}

function dispatchAllUserTypingPost({ allUserTypingPost }, dispatch) {
  dispatch({
    type: actionTypes.SET_ALL_USER_TYPING_POST,
    allUserTypingPost,
  });
}

function dispatchUpdateAllUserTypingPost({ updateAllUserTypingPost }, dispatch) {
  dispatch({
    type: actionTypes.UPDATE_ALL_USER_TYPING_POST,
    updateAllUserTypingPost,
  });
}

function dispatchAllUserTypingDoc({ allUserTypingDoc }, dispatch) {
  dispatch({
    type: actionTypes.SET_ALL_USER_TYPING_DOC,
    allUserTypingDoc,
  });
}

function dispatchUpdateAllUserTypingDoc({ updateAllUserTypingDoc }, dispatch) {
  dispatch({
    type: actionTypes.UPDATE_ALL_USER_TYPING_DOC,
    updateAllUserTypingDoc,
  });
}

function dispatchAllUserTypingFile({ allUserTypingFile }, dispatch) {
  dispatch({
    type: actionTypes.SET_ALL_USER_TYPING_FILE,
    allUserTypingFile,
  });
}

function dispatchUpdateAllUserTypingFile({ updateAllUserTypingFile }, dispatch) {
  dispatch({
    type: actionTypes.UPDATE_ALL_USER_TYPING_FILE,
    updateAllUserTypingFile,
  });
}

function dispatchAllUserTypingEvent({ allUserTypingEvent }, dispatch) {
  dispatch({
    type: actionTypes.SET_ALL_USER_TYPING_EVENT,
    allUserTypingEvent,
  });
}

function dispatchUpdateAllUserTypingEvent({ updateAllUserTypingEvent }, dispatch) {
  dispatch({
    type: actionTypes.UPDATE_ALL_USER_TYPING_EVENT,
    updateAllUserTypingEvent,
  });
}

function dispatchAllUserTypingOccurrence({ allUserTypingOccurrence }, dispatch) {
  dispatch({
    type: actionTypes.SET_ALL_USER_TYPING_OCCURRENCE,
    allUserTypingOccurrence,
  });
}

function dispatchUpdateAllUserTypingOccurrence({ updateAllUserTypingOccurrence }, dispatch) {
  dispatch({
    type: actionTypes.UPDATE_ALL_USER_TYPING_OCCURRENCE,
    updateAllUserTypingOccurrence,
  });
}

function dispatchAllUserTypingQuestion({ allUserTypingQuestion }, dispatch) {
  dispatch({
    type: actionTypes.SET_ALL_USER_TYPING_QUESTION,
    allUserTypingQuestion,
  });
}

function dispatchUpdateAllUserTypingQuestion({ updateAllUserTypingQuestion }, dispatch) {
  dispatch({
    type: actionTypes.UPDATE_ALL_USER_TYPING_QUESTION,
    updateAllUserTypingQuestion,
  });
}

function dispatchAllUserTypingDiscussion({ allUserTypingDiscussion }, dispatch) {
  dispatch({
    type: actionTypes.SET_ALL_USER_TYPING_DISCUSSION,
    allUserTypingDiscussion,
  });
}

function dispatchUpdateAllUserTypingDiscussion({ updateAllUserTypingDiscussion }, dispatch) {
  dispatch({
    type: actionTypes.UPDATE_ALL_USER_TYPING_DISCUSSION,
    updateAllUserTypingDiscussion,
  });
}

/*
    SetterDispatcher
  */

const setAllUserTypingChat = ({ allUserTypingChat }, dispatch) => {
  if (!allUserTypingChat) return;

  dispatchAllUserTypingChat({ allUserTypingChat: cloneDeep(allUserTypingChat) }, dispatch);
};

const setAllUserTypingGroupChat = ({ allUserTypingGroupChat }, dispatch) => {
  if (!allUserTypingGroupChat) return;

  dispatchAllUserTypingGroupChat(
    { allUserTypingGroupChat: cloneDeep(allUserTypingGroupChat) }, dispatch,
  );
};

const setAllUserTypingCard = ({ allUserTypingCard }, dispatch) => {
  if (!allUserTypingCard) return;

  dispatchAllUserTypingCard({ allUserTypingCard: cloneDeep(allUserTypingCard) }, dispatch);
};

const setAllUserTypingPost = ({ allUserTypingPost }, dispatch) => {
  if (!allUserTypingPost) return;

  dispatchAllUserTypingPost({ allUserTypingPost: cloneDeep(allUserTypingPost) }, dispatch);
};

const setAllUserTypingDoc = ({ allUserTypingDoc }, dispatch) => {
  if (!allUserTypingDoc) return;

  dispatchAllUserTypingDoc({ allUserTypingDoc: cloneDeep(allUserTypingDoc) }, dispatch);
};

const setAllUserTypingFile = ({ allUserTypingFile }, dispatch) => {
  if (!allUserTypingFile) return;

  dispatchAllUserTypingFile({ allUserTypingFile: cloneDeep(allUserTypingFile) }, dispatch);
};

const setAllUserTypingEvent = ({ allUserTypingEvent }, dispatch) => {
  if (!allUserTypingEvent) return;

  dispatchAllUserTypingEvent({ allUserTypingEvent: cloneDeep(allUserTypingEvent) }, dispatch);
};

const setAllUserTypingOccurrence = ({ allUserTypingOccurrence }, dispatch) => {
  if (!allUserTypingOccurrence) return;

  dispatchAllUserTypingOccurrence(
    { allUserTypingOccurrence: cloneDeep(allUserTypingOccurrence) }, dispatch,
  );
};

const setAllUserTypingQuestion = ({ allUserTypingQuestion }, dispatch) => {
  if (!allUserTypingQuestion) return;

  dispatchAllUserTypingQuestion(
    { allUserTypingQuestion: cloneDeep(allUserTypingQuestion) }, dispatch,
  );
};

const setAllUserTypingDiscussion = ({ allUserTypingDiscussion }, dispatch) => {
  if (!allUserTypingDiscussion) return;

  dispatchAllUserTypingDiscussion(
    { allUserTypingDiscussion: cloneDeep(allUserTypingDiscussion) }, dispatch,
  );
};

/*
  Reset Dispatcher
*/

const resetAllUserTypingChat = (dispatch) => {
  setAllUserTypingChat({
    allUserTypingChat: [],
  }, dispatch);
};

const resetAllUserTypingGroupChat = (dispatch) => {
  setAllUserTypingGroupChat({
    allUserTypingGroupChat: [],
  }, dispatch);
};

const resetAllUserTypingCard = (dispatch) => {
  setAllUserTypingCard({
    allUserTypingCard: [],
  }, dispatch);
};

const resetAllUserTypingPost = (dispatch) => {
  setAllUserTypingPost({
    allUserTypingPost: [],
  }, dispatch);
};

const resetAllUserTypingDoc = (dispatch) => {
  setAllUserTypingDoc({
    allUserTypingDoc: [],
  }, dispatch);
};

const resetAllUserTypingFile = (dispatch) => {
  setAllUserTypingFile({
    allUserTypingFile: [],
  }, dispatch);
};

const resetAllUserTypingEvent = (dispatch) => {
  setAllUserTypingEvent({
    allUserTypingEvent: [],
  }, dispatch);
};

const resetAllUserTypingOccurrence = (dispatch) => {
  setAllUserTypingOccurrence({
    allUserTypingOccurrence: [],
  }, dispatch);
};

const resetAllUserTypingQuestion = (dispatch) => {
  setAllUserTypingQuestion({
    allUserTypingQuestion: [],
  }, dispatch);
};

const resetAllUserTypingDiscussion = (dispatch) => {
  setAllUserTypingDiscussion({
    allUserTypingDiscussion: [],
  }, dispatch);
};

/*
  Helper
*/

const getListStartUserTyping = (allUserTyping) => {
  if (!allUserTyping || allUserTyping.length < 1) return [];
  const result = allUserTyping.filter((userTyping) => userTyping.typingStatus === 'start');
  return result;
};

function getListUserFromUserIds(userIds, userObjectArray) {
  const result = userIds.map((userId) => userObjectArray.find((user) => user._id === userId));

  return result.filter((user) => user !== undefined);
}

function createListFullNameWithCommas(listUser, limit = limitUserTyping) {
  if (listUser.length === 0) {
    return ''; // Return empty string if there are no users
  }

  if (listUser.length === 1 || limit === 1) {
    return limitChar(listUser[0].fullName, limitUserTypingNameChar, undefined, true);
  }

  const names = listUser.slice(0, limit).map(
    (user) => limitChar(user.fullName, limitUserTypingNameChar, undefined, true),
  );
  return names.join(', ');
}

const getListFullNameStartTypingFromCompanyMembers = (
  allUserTyping, companyMembers, userId, isMobile,
) => {
  if (!userId) return '';
  if (!allUserTyping || allUserTyping.length < 1) return '';
  if (!companyMembers || companyMembers.length < 1) return '';

  const allUserTypingStart = getListStartUserTyping(allUserTyping);

  if (!allUserTypingStart || allUserTypingStart.length < 1) return '';

  const userIds = allUserTypingStart.map((userTypingStart) => userTypingStart.user)
    .filter((id) => id !== userId);

  const listUser = getListUserFromUserIds(userIds, companyMembers);

  const limitTyping = isMobile ? limitUserTypingMobile : limitUserTyping;
  return createListFullNameWithCommas(listUser, limitTyping);
};

const getRemainingStartUserTypingLength = (allUserTypingStart, userId, isMobile) => {
  if (!allUserTypingStart || allUserTypingStart.length < 1) return 0;

  const userIds = allUserTypingStart.map((userTypingStart) => userTypingStart.user)
    .filter((id) => id !== userId);

  const limitTyping = isMobile ? limitUserTypingMobile : limitUserTyping;

  const allLength = userIds.length;
  return allLength - limitTyping;
};

/*
  Socket Emitter
*/

const emitUpdateTypingStatus = ({ socket, userId, typingStatus }) => {
  const payload = {
    userId,
    typingStatus,
  };

  socket.emit(socketEvent.UPDATE_STATUS, payload);
};

/*
  Socket Callback Stuffs
*/

const incomingInitiateAllUserTypingChat = ({ allUserTypingChat }, dispatch) => {
  if (!allUserTypingChat) return;
  setAllUserTypingChat({ allUserTypingChat }, dispatch);
};

const incomingUserTypingChatUpdate = ({ userTypingChat }, dispatch) => {
  if (!userTypingChat) return;
  const updateAllUserTypingChat = (currentAllUserTypingChat) => {
    const isUserTypingChatExist = currentAllUserTypingChat.find(
      (elem) => elem.user === userTypingChat.user,
    );

    const typeAction = isUserTypingChatExist ? UserTypingConstants.typeCallback.EDIT
      : UserTypingConstants.typeCallback.NEW;

    const isSameStatus = currentAllUserTypingChat.find(
      (elem) => elem.user === userTypingChat.user
      && elem.typingStatus === userTypingChat.typingStatus,
    );

    if (isSameStatus) return currentAllUserTypingChat;

    const newAllUserTypingChat = updateListSocket({
      newData: userTypingChat,
      currentList: currentAllUserTypingChat,
      typeAction,
      reverse: false,
    });

    return cloneDeep(newAllUserTypingChat);
  };

  dispatchUpdateAllUserTypingChat({ updateAllUserTypingChat }, dispatch);
};

const incomingInitiateAllUserTypingGroupChat = ({ allUserTypingGroupChat }, dispatch) => {
  if (!allUserTypingGroupChat) return;
  setAllUserTypingGroupChat({ allUserTypingGroupChat }, dispatch);
};

const incomingUserTypingGroupChatUpdate = ({ userTypingGroupChat }, dispatch) => {
  if (!userTypingGroupChat) return;
  const updateAllUserTypingGroupChat = (currentAllUserTypingGroupChat) => {
    const isUserTypingGroupChatExist = currentAllUserTypingGroupChat.find(
      (elem) => elem.user === userTypingGroupChat.user,
    );

    const typeAction = isUserTypingGroupChatExist ? UserTypingConstants.typeCallback.EDIT
      : UserTypingConstants.typeCallback.NEW;

    const isSameStatus = currentAllUserTypingGroupChat.find(
      (elem) => elem.user === userTypingGroupChat.user
        && elem.typingStatus === userTypingGroupChat.typingStatus,
    );

    if (isSameStatus) return currentAllUserTypingGroupChat;

    const newAllUserTypingGroupChat = updateListSocket({
      newData: userTypingGroupChat,
      currentList: currentAllUserTypingGroupChat,
      typeAction,
      reverse: false,
    });

    return cloneDeep(newAllUserTypingGroupChat);
  };

  dispatchUpdateAllUserTypingGroupChat({ updateAllUserTypingGroupChat }, dispatch);
};

const incomingInitiateAllUserTypingCard = ({ allUserTypingCard }, dispatch) => {
  if (!allUserTypingCard) return;
  setAllUserTypingCard({ allUserTypingCard }, dispatch);
};

const incomingUserTypingCardUpdate = ({ userTypingCard }, dispatch) => {
  if (!userTypingCard) return;
  const updateAllUserTypingCard = (currentAllUserTypingCard) => {
    const isUserTypingCardExist = currentAllUserTypingCard.find(
      (elem) => elem.user === userTypingCard.user,
    );

    const typeAction = isUserTypingCardExist ? UserTypingConstants.typeCallback.EDIT
      : UserTypingConstants.typeCallback.NEW;

    const isSameStatus = currentAllUserTypingCard.find(
      (elem) => elem.user === userTypingCard.user
          && elem.typingStatus === userTypingCard.typingStatus,
    );

    if (isSameStatus) return currentAllUserTypingCard;

    const newAllUserTypingCard = updateListSocket({
      newData: userTypingCard,
      currentList: currentAllUserTypingCard,
      typeAction,
      reverse: false,
    });

    return cloneDeep(newAllUserTypingCard);
  };

  dispatchUpdateAllUserTypingCard({ updateAllUserTypingCard }, dispatch);
};

const incomingInitiateAllUserTypingPost = ({ allUserTypingPost }, dispatch) => {
  if (!allUserTypingPost) return;
  setAllUserTypingPost({ allUserTypingPost }, dispatch);
};

const incomingUserTypingPostUpdate = ({ userTypingPost }, dispatch) => {
  if (!userTypingPost) return;
  const updateAllUserTypingPost = (currentAllUserTypingPost) => {
    const isUserTypingPostExist = currentAllUserTypingPost.find(
      (elem) => elem.user === userTypingPost.user,
    );

    const typeAction = isUserTypingPostExist ? UserTypingConstants.typeCallback.EDIT
      : UserTypingConstants.typeCallback.NEW;

    const isSameStatus = currentAllUserTypingPost.find(
      (elem) => elem.user === userTypingPost.user
        && elem.typingStatus === userTypingPost.typingStatus,
    );

    if (isSameStatus) return currentAllUserTypingPost;

    const newAllUserTypingPost = updateListSocket({
      newData: userTypingPost,
      currentList: currentAllUserTypingPost,
      typeAction,
      reverse: false,
    });

    return cloneDeep(newAllUserTypingPost);
  };

  dispatchUpdateAllUserTypingPost({ updateAllUserTypingPost }, dispatch);
};

const incomingInitiateAllUserTypingDoc = ({ allUserTypingDoc }, dispatch) => {
  if (!allUserTypingDoc) return;
  setAllUserTypingDoc({ allUserTypingDoc }, dispatch);
};

const incomingUserTypingDocUpdate = ({ userTypingDoc }, dispatch) => {
  if (!userTypingDoc) return;
  const updateAllUserTypingDoc = (currentAllUserTypingDoc) => {
    const isUserTypingDocExist = currentAllUserTypingDoc.find(
      (elem) => elem.user === userTypingDoc.user,
    );

    const typeAction = isUserTypingDocExist ? UserTypingConstants.typeCallback.EDIT
      : UserTypingConstants.typeCallback.NEW;

    const isSameStatus = currentAllUserTypingDoc.find(
      (elem) => elem.user === userTypingDoc.user
          && elem.typingStatus === userTypingDoc.typingStatus,
    );

    if (isSameStatus) return currentAllUserTypingDoc;

    const newAllUserTypingDoc = updateListSocket({
      newData: userTypingDoc,
      currentList: currentAllUserTypingDoc,
      typeAction,
      reverse: false,
    });

    return cloneDeep(newAllUserTypingDoc);
  };

  dispatchUpdateAllUserTypingDoc({ updateAllUserTypingDoc }, dispatch);
};

const incomingInitiateAllUserTypingFile = ({ allUserTypingFile }, dispatch) => {
  if (!allUserTypingFile) return;
  setAllUserTypingFile({ allUserTypingFile }, dispatch);
};

const incomingUserTypingFileUpdate = ({ userTypingFile }, dispatch) => {
  if (!userTypingFile) return;
  const updateAllUserTypingFile = (currentAllUserTypingFile) => {
    const isUserTypingFileExist = currentAllUserTypingFile.find(
      (elem) => elem.user === userTypingFile.user,
    );

    const typeAction = isUserTypingFileExist ? UserTypingConstants.typeCallback.EDIT
      : UserTypingConstants.typeCallback.NEW;

    const isSameStatus = currentAllUserTypingFile.find(
      (elem) => elem.user === userTypingFile.user
            && elem.typingStatus === userTypingFile.typingStatus,
    );

    if (isSameStatus) return currentAllUserTypingFile;

    const newAllUserTypingFile = updateListSocket({
      newData: userTypingFile,
      currentList: currentAllUserTypingFile,
      typeAction,
      reverse: false,
    });

    return cloneDeep(newAllUserTypingFile);
  };

  dispatchUpdateAllUserTypingFile({ updateAllUserTypingFile }, dispatch);
};

const incomingInitiateAllUserTypingEvent = ({ allUserTypingEvent }, dispatch) => {
  if (!allUserTypingEvent) return;
  setAllUserTypingEvent({ allUserTypingEvent }, dispatch);
};

const incomingUserTypingEventUpdate = ({ userTypingEvent }, dispatch) => {
  if (!userTypingEvent) return;
  const updateAllUserTypingEvent = (currentAllUserTypingEvent) => {
    const isUserTypingEventExist = currentAllUserTypingEvent.find(
      (elem) => elem.user === userTypingEvent.user,
    );

    const typeAction = isUserTypingEventExist ? UserTypingConstants.typeCallback.EDIT
      : UserTypingConstants.typeCallback.NEW;

    const isSameStatus = currentAllUserTypingEvent.find(
      (elem) => elem.user === userTypingEvent.user
            && elem.typingStatus === userTypingEvent.typingStatus,
    );

    if (isSameStatus) return currentAllUserTypingEvent;

    const newAllUserTypingEvent = updateListSocket({
      newData: userTypingEvent,
      currentList: currentAllUserTypingEvent,
      typeAction,
      reverse: false,
    });

    return cloneDeep(newAllUserTypingEvent);
  };

  dispatchUpdateAllUserTypingEvent({ updateAllUserTypingEvent }, dispatch);
};

const incomingInitiateAllUserTypingOccurrence = ({ allUserTypingOccurrence }, dispatch) => {
  if (!allUserTypingOccurrence) return;
  setAllUserTypingOccurrence({ allUserTypingOccurrence }, dispatch);
};

const incomingUserTypingOccurrenceUpdate = ({ userTypingOccurrence }, dispatch) => {
  if (!userTypingOccurrence) return;
  const updateAllUserTypingOccurrence = (currentAllUserTypingOccurrence) => {
    const isUserTypingOccurrenceExist = currentAllUserTypingOccurrence.find(
      (elem) => elem.user === userTypingOccurrence.user,
    );

    const typeAction = isUserTypingOccurrenceExist ? UserTypingConstants.typeCallback.EDIT
      : UserTypingConstants.typeCallback.NEW;

    const isSameStatus = currentAllUserTypingOccurrence.find(
      (elem) => elem.user === userTypingOccurrence.user
            && elem.typingStatus === userTypingOccurrence.typingStatus,
    );

    if (isSameStatus) return currentAllUserTypingOccurrence;

    const newAllUserTypingOccurrence = updateListSocket({
      newData: userTypingOccurrence,
      currentList: currentAllUserTypingOccurrence,
      typeAction,
      reverse: false,
    });

    return cloneDeep(newAllUserTypingOccurrence);
  };

  dispatchUpdateAllUserTypingOccurrence({ updateAllUserTypingOccurrence }, dispatch);
};

const incomingInitiateAllUserTypingQuestion = ({ allUserTypingQuestion }, dispatch) => {
  if (!allUserTypingQuestion) return;
  setAllUserTypingQuestion({ allUserTypingQuestion }, dispatch);
};

const incomingUserTypingQuestionUpdate = ({ userTypingQuestion }, dispatch) => {
  if (!userTypingQuestion) return;
  const updateAllUserTypingQuestion = (currentAllUserTypingQuestion) => {
    const isUserTypingQuestionExist = currentAllUserTypingQuestion.find(
      (elem) => elem.user === userTypingQuestion.user,
    );

    const typeAction = isUserTypingQuestionExist ? UserTypingConstants.typeCallback.EDIT
      : UserTypingConstants.typeCallback.NEW;

    const isSameStatus = currentAllUserTypingQuestion.find(
      (elem) => elem.user === userTypingQuestion.user
            && elem.typingStatus === userTypingQuestion.typingStatus,
    );

    if (isSameStatus) return currentAllUserTypingQuestion;

    const newAllUserTypingQuestion = updateListSocket({
      newData: userTypingQuestion,
      currentList: currentAllUserTypingQuestion,
      typeAction,
      reverse: false,
    });

    return cloneDeep(newAllUserTypingQuestion);
  };

  dispatchUpdateAllUserTypingQuestion({ updateAllUserTypingQuestion }, dispatch);
};

const incomingInitiateAllUserTypingDiscussion = ({ allUserTypingDiscussion }, dispatch) => {
  if (!allUserTypingDiscussion) return;
  setAllUserTypingDiscussion({ allUserTypingDiscussion }, dispatch);
};

const incomingUserTypingDiscussionUpdate = ({ userTypingDiscussion }, dispatch) => {
  if (!userTypingDiscussion) return;
  const updateAllUserTypingDiscussion = (currentAllUserTypingDiscussion) => {
    const isUserTypingDiscussionExist = currentAllUserTypingDiscussion.find(
      (elem) => elem.user === userTypingDiscussion.user,
    );

    const typeAction = isUserTypingDiscussionExist ? UserTypingConstants.typeCallback.EDIT
      : UserTypingConstants.typeCallback.NEW;

    const isSameStatus = currentAllUserTypingDiscussion.find(
      (elem) => elem.user === userTypingDiscussion.user
            && elem.typingStatus === userTypingDiscussion.typingStatus,
    );

    if (isSameStatus) return currentAllUserTypingDiscussion;

    const newAllUserTypingDiscussion = updateListSocket({
      newData: userTypingDiscussion,
      currentList: currentAllUserTypingDiscussion,
      typeAction,
      reverse: false,
    });

    return cloneDeep(newAllUserTypingDiscussion);
  };

  dispatchUpdateAllUserTypingDiscussion({ updateAllUserTypingDiscussion }, dispatch);
};

export {
  incomingInitiateAllUserTypingChat,
  incomingUserTypingChatUpdate,
  incomingInitiateAllUserTypingGroupChat,
  incomingUserTypingGroupChatUpdate,
  incomingInitiateAllUserTypingCard,
  incomingUserTypingCardUpdate,
  incomingInitiateAllUserTypingPost,
  incomingUserTypingPostUpdate,
  incomingInitiateAllUserTypingDoc,
  incomingUserTypingDocUpdate,
  incomingInitiateAllUserTypingFile,
  incomingUserTypingFileUpdate,
  incomingInitiateAllUserTypingEvent,
  incomingUserTypingEventUpdate,
  incomingInitiateAllUserTypingOccurrence,
  incomingUserTypingOccurrenceUpdate,
  incomingInitiateAllUserTypingQuestion,
  incomingUserTypingQuestionUpdate,
  incomingInitiateAllUserTypingDiscussion,
  incomingUserTypingDiscussionUpdate,
  getListFullNameStartTypingFromCompanyMembers,
  getRemainingStartUserTypingLength,
  emitUpdateTypingStatus,
  resetAllUserTypingChat,
  resetAllUserTypingGroupChat,
  resetAllUserTypingCard,
  resetAllUserTypingPost,
  resetAllUserTypingDoc,
  resetAllUserTypingFile,
  resetAllUserTypingEvent,
  resetAllUserTypingOccurrence,
  resetAllUserTypingQuestion,
  resetAllUserTypingDiscussion,
};
