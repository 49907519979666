import React, {
  useState, useContext, useEffect, memo,
} from 'react';
import { useSnackbar } from 'notistack';
import { useParams } from 'react-router-dom';
import EditIcon from '@mui/icons-material/Edit';
import PropTypes from 'prop-types';
import { cloneDeep, isEqual } from 'lodash';
import { Add } from '@mui/icons-material';
import { InputButtonMain } from '../../../components/UI_V2/Input/Button';
import Label from '../../../components/UI/Label/Label';
import SearchLabels from '../SearchLabels/SearchLabels';
import styles from './ToggleLabels.module.css';
import { GlobalContext } from '../../../contexts/GlobalStateProvider';
import OverlayButton from '../../../components/UI/Button/OverlayButton/OverlayButton';
import handleStatusMsg from '../../../utilities/handleStatusMsg';
import handleLoadings from '../../../utilities/handleLoadings';
import apiUtil from '../../../utilities/apiUtil';
import { getIndexItemByIDFromList } from '../../../utilities/arrayUtil';

const ToggleLabels = ({
  card, handleShowCreateLabel, handleShowEditLabel,
}) => {
  const [{ currentBoardLabels }] = useContext(GlobalContext);
  const [checkLabels, setCheckLabels] = useState([]);
  const [postLabels, setPostLabels] = useState();
  const [loadings, setLoadings] = useState([]);
  const { enqueueSnackbar } = useSnackbar();
  const [filteredLabels, setFilteredLabels] = useState([...currentBoardLabels]);

  const params = useParams();
  const { companyId } = params;

  useEffect(() => {
    if (postLabels === undefined) {
      return;
    }

    const patchApiPostLabels = async () => {
      const cardId = params.cardId || card._id;
      const teamId = params.teamId || card.team?._id || card.team;
      const labels = postLabels;
      const url = `${process.env.REACT_APP_PRIMARY_API_URL}/v2/cards/${cardId}/labels`;

      try {
        const startLoadings = handleLoadings('toggleLabels', [...loadings], 'start');
        setLoadings([...startLoadings]);

        const result = await apiUtil.post(url, { labels }, {
          params: {
            companyId,
            teamId,
          },
        });

        const status = handleStatusMsg(result, 'success');

        enqueueSnackbar(status.message, {
          variant: 'success',
        });

        setPostLabels();
      } catch (err) {
        const status = handleStatusMsg(err, 'error');

        enqueueSnackbar(status.message, {
          variant: 'error',
        });
      } finally {
        const endLoadings = handleLoadings('toggleLabels', [...loadings], 'end');
        setLoadings([...endLoadings]);
      }
    };
    patchApiPostLabels();
  }, [postLabels]);

  useEffect(() => {
    setCheckLabels([...card.labels]);
  }, [card]);

  useEffect(() => {
    if (!currentBoardLabels) return;
    setFilteredLabels((prevStateFilteredLabels) => {
      const updatedFilteredLabels = prevStateFilteredLabels.map((label) => {
        const indexPrevLabel = getIndexItemByIDFromList(
          currentBoardLabels,
          label,
        );

        if (indexPrevLabel < 0) return null;

        return currentBoardLabels[indexPrevLabel];
      });

      return updatedFilteredLabels.filter((label) => label);
    });
  }, [currentBoardLabels]);

  const handleFilterLabels = (filteredResults) => {
    setFilteredLabels([...filteredResults]);
  };

  const handleToggleListLabels = (clickedLabel) => {
    const labelExist = checkLabels.find((label) => String(label._id) === String(clickedLabel._id));
    if (labelExist) {
      setCheckLabels((prevValue) => prevValue.filter((value) => value._id !== clickedLabel._id));
    } else {
      setCheckLabels((prevValue) => [
        ...prevValue,
        clickedLabel,
      ]);
    }
  };

  const handlePostLabels = () => {
    const labelIds = checkLabels.map((label) => label._id);
    setPostLabels([...labelIds]);
  };

  return (
    <>
      <div>
        <SearchLabels labels={currentBoardLabels} handleFilterLabels={handleFilterLabels} />
      </div>
      <div className={styles.sectionSmallTitle}>
        <p className={styles.ToggleLabelsContainer__smallTitle}>LABEL</p>
        <div onClick={handleShowCreateLabel} className={styles.plusButton}>
          <Add />
        </div>
      </div>
      <div className={styles.ToggleLabelsContainer__body}>
        {currentBoardLabels.length < 1
          ? <span>Lokasi tugas ini belum punya label</span>
          : null}
        {filteredLabels.length < 1 && currentBoardLabels.length > 0
          ? <span>Label ga ketemu</span>
          : null}

        {filteredLabels.map((label) => (
          <div className={styles.sectionLabel}>
            <div className={styles.sectionLabelBody}>
              <Label
                clicked={() => handleToggleListLabels(label)}
                color={label.color.name}
                name={label.name}
                block
                checked={checkLabels.some((checkLabel) => checkLabel._id === label._id)}
              />
            </div>
            <EditIcon
              onClick={() => handleShowEditLabel(label._id)}
              className={styles.ToggleLabelsContainer__editIcon}
            />
          </div>
        ))}

      </div>
      {filteredLabels?.length > 0 ? (
        <div className={styles.sectionFooter}>
          <InputButtonMain
            handleClick={handlePostLabels}
            wait="toggleLabels"
            loadings={loadings}
            variant="success"
            wide="block"
          >
            Terapkan Label
          </InputButtonMain>
        </div>
) : null}
    </>
  );
};

ToggleLabels.propTypes = {
  card: PropTypes.object.isRequired,
  handleShowCreateLabel: PropTypes.func.isRequired,
  handleShowEditLabel: PropTypes.func.isRequired,
};

const isComponentDataEqual = (prevProps, nextProps) => {
  const { card } = nextProps;
  return isEqual(prevProps.card, card);
};

export default memo(ToggleLabels, isComponentDataEqual);
