import React, {
  memo,
} from 'react';
import PropTypes from 'prop-types';
import ScrollContainer from 'react-indiana-drag-scroll';
import styles from './PopUpMoreAction.module.css';
import { DisplayTextBody } from '../../../UI_V2/Display/Text';
import Color from '../../../../themes/colors';

const PopUpMoreAction = ({ children }) => (
  <div className={styles.container}>
    <div className={styles.header}>
      <DisplayTextBody
        mode="11"
        decoration="bold"
        color={Color.white}
        fontFamily="openSans"
      >
        Aksi Lainnya
      </DisplayTextBody>
    </div>
    <ScrollContainer className={styles.body}>
      {children}
    </ScrollContainer>
  </div>
);

PopUpMoreAction.propTypes = {
  children: PropTypes.node.isRequired,
};

export default memo(PopUpMoreAction);
