import React, { useState, useEffect } from 'react';
import CloseIcon from '@mui/icons-material/Close';
import { useSnackbar } from 'notistack';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import PropTypes from 'prop-types';
import Modal from 'react-bootstrap/Modal';
import styles from './SeenModal.module.css';
import Button from '../../UI/Button/Button';
import handleStatusMsg from '../../../utilities/handleStatusMsg';
import handleLoadings from '../../../utilities/handleLoadings';
import { FeedbackDialogGeneral } from '../../UI_V2/Feedback/Dialog';
import { SeenConstants } from '../../../constants';
import TitleSeen from './TitleSeen/TitleSeen';
import SearchSeen from './SearchSeen/SearchSeen';
import ListSeen from './ListSeen/ListSeen';

const { typeModule } = SeenConstants;

const SeenModal = ({
  open,
  onClose,
  type,
  itemId,
  forceParentIds,
  forceTeamId,
}) => (
  <FeedbackDialogGeneral
    open={open}
    onClose={onClose}
    maxWidth="sm"
  >
    <div className={styles.container}>
      <div className={styles.title}>
        <TitleSeen type={type} />
      </div>
      <div className={styles.search}>
        <SearchSeen />
      </div>
      <div id="listSeen" className={styles.list}>
        <ListSeen
          type={type}
          itemId={itemId}
          forceParentIds={forceParentIds}
          forceTeamId={forceTeamId}
        />
      </div>
    </div>
  </FeedbackDialogGeneral>
);

SeenModal.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  type: PropTypes.oneOf([
    typeModule.chatMessage,
    typeModule.chatAttachment,
    typeModule.groupChatMessage,
    typeModule.groupChatAttachment,
    typeModule.card,
    typeModule.cardComment,
    typeModule.cardDiscussion,
    typeModule.post,
    typeModule.postComment,
    typeModule.postDiscussion,
    typeModule.doc,
    typeModule.docComment,
    typeModule.docDiscussion,
    typeModule.file,
    typeModule.fileComment,
    typeModule.fileDiscussion,
    typeModule.event,
    typeModule.eventComment,
    typeModule.eventDiscussion,
    typeModule.occurrence,
    typeModule.occurrenceComment,
    typeModule.occurrenceDiscussion,
    typeModule.questionComment,
    typeModule.questionDiscussion,
  ]),
  itemId: PropTypes.string.isRequired,
  forceParentIds: PropTypes.array,
  forceTeamId: PropTypes.string,
};

SeenModal.defaultProps = {
  type: typeModule.chatMessage,
  forceParentIds: [],
  forceTeamId: undefined,
};

export default SeenModal;
