import React, { useEffect, memo } from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import _ from 'lodash';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import styles from './ActionBox.module.css';
import { DisplayTextHeadline, DisplayTextSubHeadline } from '../../../components/UI_V2/Display/Text';
import Color from '../../../themes/colors';

const ActionBox = ({ imageUrl, title }) => (
  <div className={styles.container}>
    <div className={styles.title}>
      <DisplayTextSubHeadline
        position="center"
        color={Color.gray3}
        mode="24"
        decoration="semi_bold"
      >
        {title}
      </DisplayTextSubHeadline>
    </div>
    <div className={styles.imageSection}>
      <LazyLoadImage className={styles.image} src={imageUrl} />
    </div>
  </div>
);

ActionBox.propTypes = {
  imageUrl: PropTypes.node.isRequired,
  title: PropTypes.string.isRequired,
};

export default memo(ActionBox);
