import { cloneDeep } from 'lodash';
import { actionTypes } from '../reducers/reducer';
import { ApiConstants, CommentConstants } from '../constants';
import apiUtil from '../utilities/apiUtil';
import {
  getLastListCreatedAt,
  mergeObjectListAndRemoveDuplicate,
  updateListProperty,
} from '../utilities/arrayUtil';
import { ErrorException } from '../utilities/handleError';
import {
  TeamActions, BucketActions, CommentActions, DocActions,
} from '.';
import { mergeObjectWithKeyProperty } from '../utilities/objectUtil';

/*
  Dispatcher
*/

function dispatchCurrentBucketFile({ currentBucketFile }, dispatch) {
  dispatch({
    type: actionTypes.SET_CURRENT_BUCKET_FILE,
    currentBucketFile,
  });
}

function dispatchPreviousBucketFileComment({ previousFileComment }, dispatch) {
  dispatch({
    type: actionTypes.SET_PREVIOUS_FILE_COMMENT,
    previousFileComment,
  });
}

function dispatchUpdateBucketFile({ updateBucketFile }, dispatch) {
  dispatch({
    type: actionTypes.UPDATE_CURRENT_BUCKET_FILE,
    updateBucketFile,
  });
}

/*
  SetterDispatcher
*/

function setCurrentBucketFile({ currentBucketFile }, dispatch) {
  if (!currentBucketFile) return;

  dispatchCurrentBucketFile(
    { currentBucketFile: cloneDeep(currentBucketFile) }, dispatch,
  );
}

function setPreviousBucketFileComment({ previousFileComment }, dispatch) {
  if (!previousFileComment) return;

  dispatchPreviousBucketFileComment(
    { previousFileComment: cloneDeep(previousFileComment) }, dispatch,
  );
}

/*
  Method
*/

function incomingFile({ file }, dispatch) {
  if (!file) return;

  const updateBucketFile = (currentBucketFile) => {
    let newCurrentBucketFile = {
      ...file,
      comments: currentBucketFile.comments,
      cheers: currentBucketFile.cheers,
    };
    newCurrentBucketFile = cloneDeep(newCurrentBucketFile);

    return newCurrentBucketFile;
  };

  dispatchUpdateBucketFile({ updateBucketFile }, dispatch);
}

function incomingFileComment({
  comment, typeAction, updateCommentCheer, keyProperty = 'comments',
}, dispatch) {
  if (!comment) return;

  const updateBucketFile = (currentBucketFile) => updateListProperty({
    keyProperty,
    newData: comment,
    currentList: currentBucketFile,
    typeAction,
    actionEdit: updateCommentCheer,
  });

  dispatchUpdateBucketFile({ updateBucketFile }, dispatch);
}

async function initiateFile({
  fileId, limit, companyId, teamId,
}, dispatch) {
  try {
    const result = await apiUtil.get(ApiConstants.URL_V2.FILE({ fileId }), {
      params: {
        companyId,
        teamId,
      },
    });

    const resultComment = await apiUtil.get(ApiConstants.URL_V2.FILE_COMMENT({ fileId }), {
      params: {
        limit,
        companyId,
        teamId,
      },
    });

    const modifiedResult = mergeObjectWithKeyProperty({
      obj: result?.data?.file,
      keyProperty: 'comments',
      newPropertyValue: resultComment?.data?.comments,
    });

    // BucketActions.setCurrentBucket({ currentBucket: result?.data?.bucket }, dispatch);
    setCurrentBucketFile({ currentBucketFile: modifiedResult }, dispatch);
    DocActions.setCurrentBucketDocPath({ bucketDocPath: result?.data?.bucketDocPath }, dispatch);
    // TeamActions.saveCurrentTeam({ currentTeam: result?.data?.currentTeam }, dispatch);

    const objComments = CommentActions.sliceResultDataforLastCommentNearLimitComment(resultComment);
    const payload = {
      data: {
        file: result?.data?.file,
        comments: objComments?.data?.comments,
      },
    };

    return payload;
  } catch (error) {
    throw new ErrorException(error);
  }
}

async function loadMoreFileComment({
  fileId, currentBucketFile, companyId, teamId,
}, dispatch) {
  try {
    const result = await apiUtil.get(ApiConstants.URL_V2.FILE_COMMENT({ fileId }), {
      params: {
        limit: CommentConstants.limitComment,
        createdAt: getLastListCreatedAt(currentBucketFile.comments),
        companyId,
        teamId,
      },
    });

    const modifiedResult = mergeObjectWithKeyProperty({
      obj: currentBucketFile,
      keyProperty: 'comments',
      newPropertyValue: result?.data?.comments,
    });

    const mergedFile = mergeObjectListAndRemoveDuplicate({
      currentObjectList: currentBucketFile,
      nextObjectList: modifiedResult,
      keyObject: 'comments',
    });

    setPreviousBucketFileComment({ previousFileComment: modifiedResult?.comments }, dispatch);
    setCurrentBucketFile({ currentBucketFile: mergedFile }, dispatch);

    return result;
  } catch (error) {
    throw new ErrorException(error);
  }
}

async function changeAccessFile({
  fileId, isPublic, companyId, teamId,
}) {
  try {
    const result = await apiUtil.patch(
      ApiConstants.URL_V1.FILE({ fileId }),
      { isPublic }, {
        params: {
          companyId,
          teamId,
        },
      },
    );

    return result;
  } catch (error) {
    throw new ErrorException(error);
  }
}

async function archiveFile({ fileId, companyId, teamId }) {
  try {
    const result = await apiUtil.patch(
      ApiConstants.URL_V1.FILE_ARCHIVE({ fileId }),
      {}, {
        params: {
          companyId,
          teamId,
        },
      },
    );

    return result;
  } catch (error) {
    throw new ErrorException(error);
  }
}

async function editFile({
  fileId, body, companyId, teamId,
}) {
  try {
    const result = await apiUtil.patch(
      ApiConstants.URL_V1.FILE({ fileId }),
      body, {
        params: {
          companyId,
          teamId,
        },
      },
    );

    return result;
  } catch (error) {
    throw new ErrorException(error);
  }
}

async function unarchiveFile({
  teamId,
  companyId,
  fileId,
}, dispatch) {
  try {
    const result = await apiUtil.patch(ApiConstants.URL_V1.FILE_UNARCHIVE({ fileId }), {}, {
      params: {
        companyId,
        teamId,
      },
    });

    return result;
  } catch (error) {
    throw new ErrorException(error);
  }
}

export {
  incomingFile,
  incomingFileComment,
  initiateFile,
  loadMoreFileComment,
  dispatchUpdateBucketFile,
  setPreviousBucketFileComment,
  changeAccessFile,
  editFile,
  archiveFile,
  unarchiveFile,
};
