import React, {
  useState, useContext, useEffect, useCallback,
} from 'react';
import {
  useHistory, useParams, useLocation,
} from 'react-router-dom';
import _ from 'lodash';
import { useSnackbar } from 'notistack';
import AddIcon from '@mui/icons-material/Add';
import MainLayout from '../../components/Layout/MainLayout/MainLayout';
import Title from '../../components/Title/Title';
import ManageSubscribersContainer from '../ManageSubscribersContainer/ManageSubscribersContainer';
import ImageMember from '../../components/UI/ImageMember/ImageMember';
import Button from '../../components/UI/Button/Button';
import { GlobalContext } from '../../contexts/GlobalStateProvider';
import styles from './EditFilePage.module.css';
import Paper from '../../components/UI/Paper/Paper';
import { actionTypes } from '../../reducers/reducer';
import handleLoadings from '../../utilities/handleLoadings';
import PageLoading from '../../components/UI/Button/PageLoading/PageLoading';
import handleStatusMsg from '../../utilities/handleStatusMsg';
import SwitchComponent from '../../components/UI/Switch/SwitchComponent';
import { FileActions, SearchJumpToActions, TeamActions } from '../../actions';
import { CommentConstants, PageConstants } from '../../constants';
import GeneralSubNavBar from '../../components/GeneralSubNavBar/GeneralSubNavBar';
import GlobalActionButton from '../../components/GeneralSubNavBar/BottomNavBar/GlobalActionButton/GlobalActionButton';
import { generateRoutesAndTitle } from '../../actions/BreadCrumbActions';
import { InputSwitchMain } from '../../components/UI_V2/Input/Switch';
import { LayoutMain } from '../../components/UI_V2/Layout';
import { SurfacePaperMain } from '../../components/UI_V2/Surface/Paper';

function EditFilePage() {
  const [{
    user, currentBucketFile,
    currentTeam, bucketDocPath, currentCompany,
  }, dispatch] = useContext(GlobalContext);
  const [newFile, setNewFile] = useState({ value: '' });
  const [showAddSubscriber, setShowAddSubscriber] = useState(false);
  const [subscribers, setSubscribers] = useState([]);
  const [showEmojiPicker, setShowEmojiPicker] = useState(false);
  const [postFile, setPostFile] = useState();
  const [loadings, setLoadings] = useState([]);
  const { enqueueSnackbar } = useSnackbar();
  const [isAccessPrivate, setAccessPrivate] = useState(false);

  const history = useHistory();
  const location = useLocation();
  const params = useParams();
  const { companyId, teamId, fileId } = params;

  useEffect(() => {
    if (user._id === undefined) {
      history.push(`/check-login?previousPath=${location.pathname}`);
      return;
    }

    if (!_.isEmpty(currentTeam)) return;

    const startLoadings = handleLoadings('editFilePage', [...loadings], 'start');
    setLoadings([...startLoadings]);

    const fetchApiFileDetail = async () => {
      try {
        const result = await FileActions.initiateFile({
          fileId,
          companyId,
          teamId,
          limit: CommentConstants.limitComment,
        }, dispatch);
        await TeamActions.initiateTeam({
          teamId,
          companyId,
          currentTeam,
        }, dispatch);
      } catch (err) {
        const status = handleStatusMsg(err, 'error');

        dispatch({
          type: actionTypes.SET_ERROR_RESPONSE,
          errorResponse: { message: status.message },
        });

        history.push(`/errors?previousPath=${location.pathname}`);
      } finally {
        const endLoadings = handleLoadings('editFilePage', [...loadings], 'end');
        setLoadings([...endLoadings]);
      }
    };
    fetchApiFileDetail();
  }, [location]);

  const handleCancel = () => {
    history.push(`/companies/${companyId}/teams/${teamId}/files/${fileId}`);
  };

  useEffect(() => {
    if (user._id === undefined) {
      history.push(`/check-login?previousPath=${location.pathname}`);
      return;
    }

    if (postFile === undefined) {
      return;
    }

    const postApiFile = async () => {
      try {
        const startLoadings = handleLoadings('editFile', [...loadings], 'start');
        setLoadings([...startLoadings]);

        const result = await FileActions.editFile({
          fileId, body: postFile, companyId, teamId,
        });

        const status = handleStatusMsg(result, 'success');

        enqueueSnackbar(status.message, {
          variant: 'success',
        });

        handleCancel();
      } catch (err) {
        const status = handleStatusMsg(err, 'error');

        enqueueSnackbar(status.message, {
          variant: 'error',
        });
      } finally {
        const endLoadings = handleLoadings('editFile', [...loadings], 'end');
        setLoadings([...endLoadings]);
      }
    };
    postApiFile();
  }, [postFile]);

  useEffect(() => {
    if (_.isEmpty(currentBucketFile)) {
      return;
    }

    setNewFile({ value: currentBucketFile.title });

    if (currentBucketFile?.isPublic !== undefined) {
      setAccessPrivate(!currentBucketFile?.isPublic);
    }

    // set subscribers
    const tempSubscribers = [];
    currentBucketFile.subscribers.forEach((subscriber) => {
      tempSubscribers.push(subscriber);
    });

    setSubscribers([...tempSubscribers]);
  }, [currentBucketFile]);

  const handlePostFile = () => {
    // send subscriber ids
    const subscriberIds = [];

    subscribers.forEach((subscriber) => {
      subscriberIds.push(subscriber._id);
    });

    setPostFile({
      // title: newFile.value,
      subscribers: [...subscriberIds],
      isPublic: !isAccessPrivate,
    });
  };

  const handleCancelShowAddSubscriber = () => {
    setShowAddSubscriber(false);
  };

  const handleShowAddSubscriber = () => {
    setShowAddSubscriber(true);
  };

  const handleSetSubscribers = (incomingSubscribers) => {
    setSubscribers([...incomingSubscribers]);
  };

  const handleChangeAccess = useCallback((access) => {
    setAccessPrivate(access);
  }, []);
  const pageType = PageConstants.pageType.filesEdit;
  const breadcrumb = generateRoutesAndTitle({
    type: pageType,
    data: {
      companyId, currentTeam, currentBucketFile, bucketDocPath,
    },
  });
  SearchJumpToActions.saveRecentPage({
    company: currentCompany,
    team: currentTeam,
    userId: user?._id,
    itemId: `${fileId}-edit`,
    url: breadcrumb.url,
    routes: breadcrumb.routes,
    name: breadcrumb.title,
    type: pageType,
  });
  return (
    <>
      <Title title={currentBucketFile.title === undefined ? 'Ubah File' : `Ubah ${currentBucketFile.title}`} />
      <GeneralSubNavBar
        breadcrumbRoutes={breadcrumb.routes}
        pageTitle={breadcrumb.title}
        bottomBarOverviewOptionId="buckets"
        withBottomNavBar={false}
      />
      <LayoutMain>
        <PageLoading wait="editFilePage" loadings={loadings}>
          <SurfacePaperMain>
            <div className={styles.container}>
              {/* <div className={styles.headerSection}>
              <div className={styles.inputTitleSection}>
                <TextareaAutosize placeholder="Type a title..."
                onClick={disableShowEmojiPicker}
                onChange={(event) => handleChanged(event)}
                minRows={1} maxRows={2} value={newFile.value} />
                <MoodIcon onClick={handleShowEmojiPicker} />
                {showEmojiPicker
                  ? (
                    <div className={styles.emojiPicker}>
                      <EmojiPicker onEmojiClick={onEmojiClick} />
                    </div>
                  ) : null}
              </div>
            </div> */}
              {/* <SeparatorLine /> */}
              <div className={styles.manageSubscribers}>
                <ManageSubscribersContainer
                  type="file"
                  initialSubscribers={subscribers}
                  handleSetSubscribers={handleSetSubscribers}
                  companyId={companyId}
                />
              </div>

              <InputSwitchMain
                title="Apakah file ini Rahasia untuk Penerima aja?"
                labelSwitch="Rahasia"
                handleChange={handleChangeAccess}
                defaultValue={isAccessPrivate}
              />

              <div className={styles.actionSection}>
                <div>
                  <Button handleClick={handlePostFile} variant="success" wait="editFile" spinnerSize="sm" loadings={loadings}>Simpan Perubahan</Button>
                  <Button handleClick={handleCancel} variant="danger">Batal</Button>
                </div>

              </div>
            </div>
          </SurfacePaperMain>
        </PageLoading>

      </LayoutMain>
    </>
  );
}

export default EditFilePage;
