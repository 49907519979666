import { Box } from '@mui/material';
import CheckIcon from '@mui/icons-material/Check';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import React, { memo } from 'react';
import PropTypes from 'prop-types';
import styles from './ButtonTextWithCheckBox.module.css';
import ButtonDiv from '../../../UI/Button/ButtonDiv/ButtonDiv';
import { DisplayTextBody } from '../../../UI_V2/Display/Text';
import Color from '../../../../themes/colors';

const ButtonTextWithCheckBox = ({
  onClick, checked, text, textDecoration, textMode,
}) => (
  <ButtonDiv onClick={onClick} className={styles.menu}>
    <Box className={styles.text}>
      <DisplayTextBody
        decoration={textDecoration}
        fontFamily="openSans"
        color={Color.grayIconEditDocs}
        mode={textMode}
      >
        {text}
      </DisplayTextBody>
      {' '}
      {checked ? <CheckBoxIcon className={styles.checkBoxRead} />
        : <CheckBoxOutlineBlankIcon className={styles.checkBoxUnread} />}
    </Box>
  </ButtonDiv>
);

ButtonTextWithCheckBox.propTypes = {
  onClick: PropTypes.func,
  checked: PropTypes.bool,
  text: PropTypes.string.isRequired,
  textDecoration: PropTypes.oneOf(['bold', 'regular']),
  textMode: PropTypes.oneOf(['11', '12']),
};

ButtonTextWithCheckBox.defaultProps = {
  onClick: () => null,
  checked: false,
  textDecoration: 'regular',
  textMode: '11',
};

export default memo(ButtonTextWithCheckBox);
