import React, { memo, useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import styles from './CompanyListPage.module.css';
import { DisplayTextBody, DisplayTextHeadline } from '../../../components/UI_V2/Display/Text';
import Color from '../../../themes/colors';
import introImage from '../../../assets/PrivateChatPage/intro-chat-section.png';
import { SurfacePaperMain } from '../../../components/UI_V2/Surface/Paper';
import { LayoutMain } from '../../../components/UI_V2/Layout';
import Header from '../Header/Header';
import CompanyList from './CompanyList/CompanyList';
import { dateBeautifyFormatter } from '../../../utilities/dateUtil';

const CompanyListPage = () => {
  const [headerText, setHeaderText] = useState({
    title: 'Company List',
    desc: 'List of company from each category',
  });

  const handleSetHeaderText = useCallback((values) => {
    const { category, fromDate, untilDate } = values;

    let finalCategory = 'All Categories';
    if (category) {
      finalCategory = category;
    }
    setHeaderText({
      title: `List ${finalCategory}`,
      desc: `Period : ${dateBeautifyFormatter(fromDate, true)} - ${dateBeautifyFormatter(untilDate, true)}`,
    });
  }, []);

  return (
    <LayoutMain>
      <SurfacePaperMain>
        <div className={styles.container}>
          <div className={styles.header}>
            <Header
              title={headerText.title}
              desc={headerText.desc}
            />
          </div>
          <div className={styles.body}>
            <CompanyList
              handleSetHeaderText={handleSetHeaderText}
            />
          </div>
        </div>
      </SurfacePaperMain>
    </LayoutMain>
  );
};

export default memo(CompanyListPage);
