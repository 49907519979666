import React from 'react';
import PropTypes from 'prop-types';
import styles from './DateSeparator.module.css';
import { dateBeautifyFormatter } from '../../../utilities/dateUtil';
import { DisplayTextBody } from '../../../components/UI_V2/Display/Text';
import Color from '../../../themes/colors';

const DateSeparator = ({ date }) => {
  const beautyDate = dateBeautifyFormatter(date);

  return (
    <div className={styles.box}>
      <div className={styles.container}>
        <DisplayTextBody
          color={Color.gray4}
        >
          {beautyDate}
        </DisplayTextBody>
      </div>
    </div>
  );
};

DateSeparator.propTypes = {
  date: PropTypes.instanceOf(Date).isRequired,
};

export default DateSeparator;
