import React, {
  memo, useState, useEffect, useContext, useCallback,
} from 'react';
import { useLocation, useHistory, useParams } from 'react-router-dom';
import PropTypes from 'prop-types';
import { useSnackbar } from 'notistack';
import { find } from 'lodash';
import OverlayButton from '../../../../components/UI/Button/OverlayButton/OverlayButton';
import { GlobalContext } from '../../../../contexts/GlobalStateProvider';
import { actionTypes } from '../../../../reducers/reducer';
import styles from './ListNewChatsModeContainer.module.css';
import handleStatusMsg from '../../../../utilities/handleStatusMsg';
import handleLoadings from '../../../../utilities/handleLoadings';
import ChatPersonItem from '../ChatPersonItem/ChatPersonItem';
import apiUtil from '../../../../utilities/apiUtil';

const ListNewChatsModeContainer = ({ filteredMembers, cancel }) => {
  const [{
    user, currentChatNotifications, recentChats,
  }, dispatch] = useContext(GlobalContext);
  const [checkMemberChat, setCheckMemberChat] = useState();
  const [loadings, setLoadings] = useState([]);
  const [updatedNotif, setUpdatedNotif] = useState();
  const { enqueueSnackbar } = useSnackbar();
  const params = useParams();

  const { companyId } = params;

  const history = useHistory();
  const location = useLocation();

  const goToChat = (chatId) => {
    history.push(`/companies/${companyId}/chats/${chatId}`);
  };
  useEffect(() => {
    if (user._id === undefined) {
      history.push({
        pathname: '/check-login',
        state: {
          from: location.pathname,
        },
      });
    } else if (checkMemberChat !== undefined) {
      const checkApiChat = async () => {
        const memberId = checkMemberChat;

        const startLoadings = handleLoadings(`newPrivateMessage-${memberId}`, [...loadings], 'start');
        setLoadings([...startLoadings]);

        try {
          const result = await apiUtil.post('/api/v1/chats/', { memberId }, {
            params: {
              companyId,
            },
          });
          if (result?.data?.chatId !== undefined) {
            const { chatId } = result.data;
            goToChat(chatId);
          }
        } catch (err) {
          const status = handleStatusMsg(err, 'error');

          enqueueSnackbar(status.message, {
            variant: 'error',
          });
        } finally {
          const endLoadings = handleLoadings(`newPrivateMessage-${memberId}`, [...loadings], 'end');
          setLoadings([...endLoadings]);
          cancel();
        }
      };
      checkApiChat();
    }
  }, [checkMemberChat]);

  useEffect(() => {
    if (!updatedNotif) return;

    const updateApiNotification = () => {
      try {
        const { notificationId, chatId } = updatedNotif;
        const result = apiUtil.patch(`/api/v1/notifications/${notificationId}`, {}, {
          params: {
            companyId,
          },
        });
        goToChat(chatId);
        cancel();
      } catch (err) {
        const status = handleStatusMsg(err, 'error');

        enqueueSnackbar(status.message, {
          variant: 'error',
        });
      }
    };
    updateApiNotification();
  }, [updatedNotif]);

  const getChatIdFromRecentChats = (listRecentChats, memberId) => {
    const result = listRecentChats.find(
      (chat) => {
        const foundMember = chat.members.find((member) => String(member._id) === String(memberId));
        if (foundMember) {
          return chat;
        }
        return undefined;
      },
    );
    return result;
  };

  const handleClickChat = ({ memberId, notificationId, counter }) => {
    const recentChat = getChatIdFromRecentChats(recentChats, memberId);
    const chatId = recentChat?._id;

    if (chatId) {
      if (counter < 1) {
        if (chatId !== params.chatId) {
          goToChat(chatId);
        }
        cancel();
      } else {
        setUpdatedNotif({ notificationId, chatId });
      }
    } else {
      setCheckMemberChat(memberId);
    }
  };

  const countChatCounters = (notification) => {
    if (!notification) return 0;

    let checkId = [];
    let filteredActivities = [];
    if (notification !== undefined) {
      checkId = [user._id];
      filteredActivities = notification.activities.filter(
        (activity) => !activity.readBy.some((readBy) => checkId.includes(readBy.reader)),
      );
    }

    return filteredActivities?.length;
  };

  return (
    <div className={styles.container}>
      {filteredMembers.length < 1 ? <span>Anggota ga ketemu</span> : (
        <>
          {filteredMembers.map((member) => {
            const notif = currentChatNotifications.find(
              (notification) => (
                notification.subscribers.includes(member._id)
                    && notification.subscribers.includes(user._id)
              ),
            );
            const counter = countChatCounters(notif);
            return (
              <OverlayButton wait={`newPrivateMessage-${member._id}`} loadings={loadings}>
                <ChatPersonItem
                  photoUrl={member?.photoUrl}
                  fullName={member?.fullName}
                  clicked={handleClickChat}
                  subContent={member.bio || member.status}
                  onClickId={{ memberId: member?._id, notificationId: notif?._id, counter }}
                />
              </OverlayButton>
            );
          })}
        </>
      )}
    </div>
  );
};

ListNewChatsModeContainer.propTypes = {
  filteredMembers: PropTypes.array.isRequired,
  cancel: PropTypes.func.isRequired,
};

export default memo(ListNewChatsModeContainer);
