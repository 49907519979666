import React, { memo, useState } from 'react';
import PropTypes from 'prop-types';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import styles from './SuggestionText.module.css';
import Color from '../../../../../themes/colors';
import { DisplayTextBody } from '../../../../../components/UI_V2/Display/Text';

const SuggestionText = ({
  suggestionText
}) => (
  <div className={styles.container}>
    <div className={styles.subTitle}>
      <DisplayTextBody
        mode="12"
        color={Color.gray3}
      >
        {suggestionText}
      </DisplayTextBody>
    </div>
  </div>
);

SuggestionText.propTypes = {
  suggestionText: PropTypes.string.isRequired,
};

SuggestionText.defaultProps = {};

export default memo(SuggestionText);
