import React, { memo, useState } from 'react';
import PropTypes from 'prop-types';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { Rating } from '@mui/material';
import styles from './PerformanceBadgeUserReport.module.css';
import Color from '../../../../../../themes/colors';
import { DisplayTextBody } from '../../../../../../components/UI_V2/Display/Text';
import { DisplayDividerMain } from '../../../../../../components/UI_V2/Display/Divider';

const titleText = {
  good: 'Bagus',
  normal: 'Cukup',
  bad: 'Kurang',
};

const dividerColorStyles = {
  good: Color.tosca,
  normal: Color.gray4,
  bad: Color.deleteColor,
};

const textColorStyles = {
  good: Color.tosca,
  normal: Color.gray4,
  bad: Color.deleteColor,
};

const boxColorStyles = {
  good: styles.good,
  normal: styles.normal,
  bad: styles.bad,
};

const boxEmptyColorStyles = {
  true: styles.normal,
  false: '',
};

const PerformanceBadgeUserReport = ({
  rating,
  isEmptyData,
}) => (
  <div className={`${styles.container} ${boxColorStyles[rating?.category]} ${boxEmptyColorStyles[isEmptyData]}`}>
    {isEmptyData && (
    <div className={styles.emptyText}>
      <DisplayTextBody>Belum ada data</DisplayTextBody>
    </div>
    )}
    {!isEmptyData && (
    <>
      <div className={styles.text}>
        <DisplayTextBody>
          {titleText[rating?.category]}
        </DisplayTextBody>
      </div>
      <div clasName={styles.divider}>
        <DisplayDividerMain
          marginTop="0.5rem"
          marginBottom="0.5rem"
          borderColor={dividerColorStyles[rating?.category]}
        />
      </div>
      <div className={styles.percentage}>
        <div className={styles.ratingStars}>
          <Rating
            name="half-rating-read"
            value={Number(rating?.value)}
            precision={0.1}
            readOnly
            size="small"
          />
        </div>
        {/* <DisplayTextBody>
      {`${rating?.value}`}
    </DisplayTextBody> */}
      </div>
    </>
    )}
  </div>
);

PerformanceBadgeUserReport.propTypes = {
  rating: PropTypes.object.isRequired,
  isEmptyData: PropTypes.bool.isRequired,
};

PerformanceBadgeUserReport.defaultProps = {};

export default memo(PerformanceBadgeUserReport);
