import React, {
  memo, useContext, useCallback, useEffect, useState,
} from 'react';
import PropTypes from 'prop-types';
import { VisibilityContext } from 'react-horizontal-scrolling-menu';
import { DisplayTextBody } from '../../../../components/UI_V2/Display/Text';
import Color from '../../../../themes/colors';
import styles from './CategoryTabNotif.module.css';
import { DisplayBadgeCounter } from '../../../../components/UI_V2/Display/Badge';
import limitChar from '../../../../utilities/limitChar';

const firstItemStyles = {
  true: styles.firstItem,
  false: '',
};

const tabSelectedStyles = {
  true: styles.selected,
  false: styles.unselected,
};

const fontColorSelectedStyles = {
  true: Color.white,
  false: Color.gray4,
};

const boldSelectedStyles = {
  true: 'bold',
  false: 'regular',
};

const CategoryTabNotif = ({
  onClick, selected, name, counter, isFirstItem, itemId,
}) => {
  const visibility = useContext(VisibilityContext);
  const { scrollToItem, getItemById } = visibility;

  useEffect(() => {
    if (selected) {
      if (typeof scrollToItem === 'function' && typeof getItemById === 'function') {
        setTimeout(() => {
          scrollToItem(getItemById(itemId), 'smooth', 'center');
        }, [200]);
      }
    }
  }, [selected]);
  return (
    <div className={styles.container} onClick={onClick}>
      <DisplayBadgeCounter
        backgroundColor={Color.yellowAccentCicle}
        badgeContent={counter}
        customStyle={{ top: 0, right: 9 }}
      >
        <div className={`${styles.tab} ${tabSelectedStyles[selected]} ${firstItemStyles[isFirstItem]}`}>
          <DisplayTextBody
            mode="12"
            decoration={boldSelectedStyles[selected]}
            fontFamily="openSans"
            color={fontColorSelectedStyles[selected]}
            position="center"
          >
            {limitChar(name, 20)}
          </DisplayTextBody>
        </div>
      </DisplayBadgeCounter>
    </div>
  );
};

CategoryTabNotif.propTypes = {
  onClick: PropTypes.func.isRequired,
  selected: PropTypes.bool.isRequired,
  name: PropTypes.string.isRequired,
  counter: PropTypes.number.isRequired,
  isFirstItem: PropTypes.bool.isRequired,
  itemId: PropTypes.string.isRequired,
};

export default memo(CategoryTabNotif);
