const delayUpdateBoard = 3000;
const boardLimit = 8;
const boardCardListLimit = 20;
const maximumLimitList = 9999;
const minimumLimitCard = 0;
const initialLastIndex = 0;
const limitList = 10;
const limitCard = 10;
const typeCallback = {
  NEW: 'new',
  DELETE: 'delete',
  EDIT: 'edit',
};
const typeMove = {
  CARD: 'card',
  LIST: 'list',
};
const socketEvent = {
  CARD_MOVE: 'cardMove',
  LIST_MOVE: 'listMove',
};

const listCompleteTypeTitle = {
  done: 'Selesai',
  blocked: 'Block',
};

export {
  delayUpdateBoard,
  boardLimit,
  typeCallback,
  limitList,
  limitCard,
  initialLastIndex,
  minimumLimitCard,
  maximumLimitList,
  typeMove,
  socketEvent,
  listCompleteTypeTitle,
  boardCardListLimit,
};
