import React, {
  useState, useContext, useEffect,
} from 'react';
import { useParams } from 'react-router-dom';
import PropTypes from 'prop-types';
import CloseIcon from '@mui/icons-material/Close';
import { useSnackbar } from 'notistack';
import { TextField } from '@mui/material';
import { GlobalContext } from '../../../../contexts/GlobalStateProvider';
import BoxPopOver from '../../../../components/UI/BoxPopOver/BoxPopOver';
import SeparatorLine from '../../../../components/UI/SeparatorLine/SeparatorLine';
import { InputButtonMain } from '../../../../components/UI_V2/Input/Button';
import styles from './InviteMemberContainer.module.css';
import handleStatusMsg from '../../../../utilities/handleStatusMsg';
import handleLoadings from '../../../../utilities/handleLoadings';
import { convertEmailMultilineStringIntoArray } from '../../../../utilities/arrayUtil';
import apiUtil from '../../../../utilities/apiUtil';
import { ApiConstants } from '../../../../constants';
import { elementId } from '../../../../constants/ElementIdConstants';

const InviteMemberContainer = ({ handleCancel, left }) => {
  const [{ user }] = useContext(GlobalContext);
  const { enqueueSnackbar } = useSnackbar();
  const [loadings, setLoadings] = useState([]);
  const [memberInvited, setMemberInvited] = useState({});
  const [sendMemberInvited, setSendMemberInvited] = useState();
  const params = useParams();

  const { companyId } = params;

  useEffect(() => {
    if (sendMemberInvited === undefined) {
      return;
    }

    const postMemberInvited = async () => {
      const emailReceivers = sendMemberInvited;
      const senderId = user._id;

      if (emailReceivers !== undefined && emailReceivers.length > 0) {
        try {
          const startLoadings = handleLoadings('inviteMember', [...loadings], 'start');
          setLoadings([...startLoadings]);
          const result = await apiUtil.post(ApiConstants.URL_V2.INVITE_COMPANY_MEMBERS(
            { companyId },
          ),
          {
            emails: emailReceivers,
            senderId,
          });
          const status = handleStatusMsg(result, 'success');

          enqueueSnackbar(status.message, {
            variant: 'success',
          });

          handleCancel();
        } catch (err) {
          const status = handleStatusMsg(err, 'error');

          enqueueSnackbar(status.message, {
            variant: 'error',
          });
        } finally {
          const endLoadings = handleLoadings('inviteMember', [...loadings], 'end');
          setLoadings([...endLoadings]);
        }
      } else {
        enqueueSnackbar('Email is not valid or empty', {
          variant: 'error',
        });
      }
    };
    postMemberInvited();
  }, [sendMemberInvited]);

  const handleChanged = (event) => {
    const { value } = event.target;
    setMemberInvited((prevValue) => ({
      ...prevValue,
      value,
    }
    ));
  };

  const handleClicked = () => {
    const validEmails = convertEmailMultilineStringIntoArray(memberInvited.value);
    setSendMemberInvited(validEmails);
  };

  return (
    <BoxPopOver customStyle={{ width: '260px' }} left={left}>
      <div className={styles.headerSection}>
        <h1>
          Undang Orang ke Perusahaan
        </h1>
        <CloseIcon onClick={handleCancel} />
      </div>
      <SeparatorLine />
      <div className={styles.bodySection}>
        <div className={styles.form}>
          <TextField
            id={elementId.companyInviteEmailTextField}
            fullWidth
            label="Email"
            autoFocus
            name="emails"
            onChange={handleChanged}
            placeholder="Bisa undang sekaligus banyak email. Maksimal 15 email per request. 1 baris per email."
            rows={4}
            multiline
          />
        </div>
        {/* <p>These members will have full access to this company.</p> */}
      </div>
      <div className={styles.actionSection}>
        <InputButtonMain id={elementId.companyInviteEmailInviteButton} handleClick={handleClicked} variant="success" wait="inviteMember" loadings={loadings}>Undang</InputButtonMain>
      </div>
    </BoxPopOver>
  );
};

InviteMemberContainer.propTypes = {
  handleCancel: PropTypes.func.isRequired,
  left: PropTypes.bool,
};

InviteMemberContainer.defaultProps = {
  left: false,
};

export default InviteMemberContainer;
