import { actionTypes } from '../reducers/reducer';
import { setStoreSidebarConfig } from '../utilities/localStorage';
/*
  Dispatcher
*/

function dispatchIsSidebarOpen({ isSidebarOpen }, dispatch) {
  dispatch({
    type: actionTypes.SET_IS_SIDEBAR_OPEN,
    isSidebarOpen,
  });
}

/*
    Setter Dispatcher
*/

function setIsSidebarOpen({ isSidebarOpen }, dispatch) {
  dispatchIsSidebarOpen(
    { isSidebarOpen }, dispatch,
  );
}

/*
    Method
*/

const handleOpenSidebar = ({}, dispatch) => {
  setIsSidebarOpen({ isSidebarOpen: true }, dispatch);
  setStoreSidebarConfig(true);
};

const handleCloseSidebar = ({}, dispatch) => {
  setIsSidebarOpen({ isSidebarOpen: false }, dispatch);
  setStoreSidebarConfig(false);
};

export {
  handleCloseSidebar,
  handleOpenSidebar,
};
