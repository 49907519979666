import React, { useState, useEffect } from 'react';
import Spinner from 'react-bootstrap/Spinner';
import Backdrop from '@mui/material/Backdrop';
import PropTypes from 'prop-types';

const PageLoading = ({
  wait, loadings, children, spinnerSize, spinnerVariant, spinnerAnimation,
}) => {
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    if (loadings === undefined) return;
    const checkWait = loadings.filter((loading) => loading === wait);

    if (checkWait.length > 0) {
      setIsLoading(true);
      return;
    }

    setIsLoading(false);
  }, [loadings]);

  return (
    <>
      {isLoading
        ? (
          <Backdrop open style={{ backgroundColor: 'rgba(0, 0, 0, 0)' }}>
            <Spinner variant={spinnerVariant || 'warning'} animation={spinnerAnimation || 'border'} size={spinnerSize || 'sm'} />
          </Backdrop>
        )
        : <>{children}</>}
    </>
  );
};

PageLoading.propTypes = {
  loadings: PropTypes.array.isRequired,
  children: PropTypes.node.isRequired,
  wait: PropTypes.string.isRequired,
  spinnerSize: PropTypes.string.isRequired,
  spinnerVariant: PropTypes.string.isRequired,
  spinnerAnimation: PropTypes.string.isRequired,
};

export default PageLoading;
