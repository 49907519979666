import React, { memo, useState } from 'react';
import PropTypes from 'prop-types';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import styles from './TractionInfo.module.css';
import { DisplayTextBody, DisplayTextSubHeadline } from '../../../../components/UI_V2/Display/Text';
import Color from '../../../../themes/colors';
import TextInfo from '../../CompanyDetailPage/CompanyInfo/DetailInfoBox/TextInfo/TextInfo';
import milestoneImage from '../../../../assets/DashboardAdminPage/TrationPage/milestone-illustration.png';

const TractionInfo = () => (
  <div className={styles.container}>
    <div className={styles.title}>
      <DisplayTextSubHeadline>
        Milestone
      </DisplayTextSubHeadline>
    </div>
    <div className={styles.content}>
      <div className={styles.milestone}>
        <LazyLoadImage src={milestoneImage} />
      </div>
    </div>
  </div>
);

TractionInfo.propTypes = {};

TractionInfo.defaultProps = {};

export default memo(TractionInfo);
