import React, { memo, useState } from 'react';
import PropTypes from 'prop-types';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import styles from './SelectPeriodRecapMonthWeekly.module.css';
import Color from '../../../../../../themes/colors';
import ButtonTextWithCheck from '../../../../../../components/Container/FilterBar/ButtonTextWithCheck/ButtonTextWithCheck';
import { getMonthName } from '../../../../../../utilities/dateUtil';

const SelectPeriodRecapMonthWeekly = ({
  listMonthsYear,
  onClickMonthYear,
  selectedMonth,
  selectedYear,
  hideChecked,
}) => (
  <div className={styles.container}>
    {listMonthsYear.map((obj, index) => {
      const isThisMonth = index === 0;
      const isLastMonth = index === 1;

      let text = `${getMonthName(obj.month)} ${obj.year}`;

      if (isThisMonth) text = `${text} (Bulan ini)`;
      if (isLastMonth) text = `${text} (Bulan lalu)`;
      return (
        <ButtonTextWithCheck
          text={text}
          onClick={() => onClickMonthYear(obj.month, obj.year, index)}
          checked={selectedMonth === obj.month && selectedYear === obj.year && !hideChecked}
        />
      );
    })}
  </div>
);

SelectPeriodRecapMonthWeekly.propTypes = {
  listMonthsYear: PropTypes.array,
  onClickMonthYear: PropTypes.func,
  selectedMonth: PropTypes.string,
  selectedYear: PropTypes.string,
  hideChecked: PropTypes.bool.isRequired,
};

SelectPeriodRecapMonthWeekly.defaultProps = {
  listMonthsYear: [],
  onClickMonthYear: () => null,
  selectedMonth: null,
  selectedYear: null,
};

export default memo(SelectPeriodRecapMonthWeekly);
