import React, {
  memo,
  useCallback,
  useEffect, useState,
} from 'react';
import { useParams, useHistory, useLocation } from 'react-router-dom';
import queryString from 'query-string';
import PropTypes from 'prop-types';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { useSnackbar } from 'notistack';
import styles from './ChartList.module.css';
import { DisplayTextBody, DisplayTextSubHeadline } from '../../../../components/UI_V2/Display/Text';
import Color from '../../../../themes/colors';
import ChartBox from './ChartBox/ChartBox';
import CardActivitiesChart from './CardActivitiesChart/CardActivitiesChart';
import handleLoadings from '../../../../utilities/handleLoadings';
import handleStatusMsg from '../../../../utilities/handleStatusMsg';
import { AdministratorActions } from '../../../../actions';
import { calculateMonthday, dateBeautifyFormatter } from '../../../../utilities/dateUtil';
import { FeedbackLoadingMain } from '../../../../components/UI_V2/Feedback/Loading';
import FilterDropdown from '../../../../components/Container/FilterDropdown/FilterDropdown';
import PostActivitiesChart from './PostActivitiesChart/PostActivitiesChart';
import DocActivitiesChart from './DocActivitiesChart/DocActivitiesChart';
import EventActivitiesChart from './EventActivitiesChart/EventActivitiesChart';
import FileActivitiesChart from './FileActivitiesChart/FileActivitiesChart';
import BucketActivitiesChart from './BucketActivitiesChart/BucketActivitiesChart';
import QuestionActivitiesChart from './QuestionActivitiesChart/QuestionActivitiesChart';
import ChatActivitiesChart from './ChatActivitiesChart/ChatActivitiesChart';
import GroupChatActivitiesChart from './GroupChatActivitiesChart/GroupChatActivitiesChart';
import UserActivitiesChart from './UserActivitiesChart/UserActivitiesChart';
import TeamActivitiesChart from './TeamActivitiesChart/TeamActivitiesChart';

const ChartList = ({
  handleSetCompany,
  handleSetHeaderText,
}) => {
  const params = useParams();
  const history = useHistory();
  const location = useLocation();

  const { companyId } = params;

  const query = queryString.parse(location.search);

  const {
    fromDate,
    untilDate,
  } = query;

  let initialFromDate = calculateMonthday(new Date(), 14, 'subtract');
  if (fromDate) {
    initialFromDate = new Date(fromDate);
  }

  let initialUntilDate = new Date();
  if (untilDate) {
    initialUntilDate = new Date(untilDate);
  }

  const [filters, setFilters] = useState({
    filterFromUntilDate: {
      fromDate: initialFromDate,
      untilDate: initialUntilDate,
    },
  });
  const [loadings, setLoadings] = useState([]);
  const { enqueueSnackbar } = useSnackbar();
  const [currentMetricCompanyActivities, setCurrentMetricCompanyActivities] = useState({
    data: [],
  });

  const initiateMetricCompanyActivitiesApi = async (filterValues) => {
    try {
      const startLoadings = handleLoadings('initiateMetricCompanyActivities', [...loadings], 'start');
      setLoadings([...startLoadings]);
      const result = await AdministratorActions.initiateMetricCompanyActivities({
        setCurrentMetricCompanyActivities,
        filters: filterValues,
        companyId,
      });
    } catch (error) {
      const status = handleStatusMsg(error, 'error');

      enqueueSnackbar(status.message, {
        variant: 'error',
      });
    } finally {
      const endLoadings = handleLoadings('initiateMetricCompanyActivities', [...loadings], 'end');
      setLoadings([...endLoadings]);
    }
  };

  useEffect(() => {
    initiateMetricCompanyActivitiesApi(filters);
  }, [location]);

  useEffect(() => {
    if (currentMetricCompanyActivities?.company) {
      handleSetCompany(currentMetricCompanyActivities.company);
      handleSetHeaderText({
        companyName: currentMetricCompanyActivities.company.name,
        fromDate: filters.filterFromUntilDate.fromDate,
        untilDate: filters.filterFromUntilDate.untilDate,
      });
    }
  }, [currentMetricCompanyActivities]);

  const onApplyFilters = useCallback((values) => {
    initiateMetricCompanyActivitiesApi(values);
    setFilters(values);
  }, []);

  const labels = currentMetricCompanyActivities.data.map((elem) => (
    dateBeautifyFormatter(elem.date, true, 'MonthDate')
  ));

  const activities = currentMetricCompanyActivities.data.map((elem) => (
    elem.activities
  ));
  return (
    <div className={styles.container}>
      <div className={styles.filter}>
        <FilterDropdown
          showFeatureTypeSection={false}
          showArchivedSection={false}
          showTeamSection={false}
          showFromUntilDateSection
          onApplyFilters={onApplyFilters}
          loadingsOnApplyFilters={loadings}
          waitOnApplyFilters="initiateMetricCompanyActivities"
          initialFromDate={filters.filterFromUntilDate.fromDate}
          initialUntilDate={filters.filterFromUntilDate.untilDate}
        />
      </div>
      <FeedbackLoadingMain
        wait="initiateMetricCompanyActivities"
        loadings={loadings}
        useSpinner
      >
        <div className={styles.chartSectionAndTitle}>
          <div className={styles.charttitle}>
            <DisplayTextSubHeadline
              decoration="bold"
            >
              Cards
            </DisplayTextSubHeadline>
          </div>
          <div className={styles.chartSection}>
            <div className={styles.chartBox}>
              <ChartBox
                title="Cards & Comments"
              >
                <CardActivitiesChart
                  labels={labels}
                  activities={activities}
                />
              </ChartBox>
            </div>
          </div>
        </div>

        <div className={styles.chartSectionAndTitle}>
          <div className={styles.charttitle}>
            <DisplayTextSubHeadline
              decoration="bold"
            >
              Blasts
            </DisplayTextSubHeadline>
          </div>
          <div className={styles.chartSection}>
            <div className={styles.chartBox}>
              <ChartBox
                title="Posts & Comments"
              >
                <PostActivitiesChart
                  labels={labels}
                  activities={activities}
                />
              </ChartBox>
            </div>
          </div>
        </div>

        <div className={styles.chartSectionAndTitle}>
          <div className={styles.charttitle}>
            <DisplayTextSubHeadline
              decoration="bold"
            >
              Docs & Files
            </DisplayTextSubHeadline>
          </div>
          <div className={styles.chartSection}>
            <div className={styles.chartBox}>
              <ChartBox
                title="Docs & Comments"
              >
                <DocActivitiesChart
                  labels={labels}
                  activities={activities}
                />
              </ChartBox>
            </div>
            <div className={styles.chartBox}>
              <ChartBox
                title="Files & Comments"
              >
                <FileActivitiesChart
                  labels={labels}
                  activities={activities}
                />
              </ChartBox>
            </div>
            <div className={styles.chartBox}>
              <ChartBox
                title="Buckets & Comments"
              >
                <BucketActivitiesChart
                  labels={labels}
                  activities={activities}
                />
              </ChartBox>
            </div>
          </div>
        </div>

        <div className={styles.chartSectionAndTitle}>
          <div className={styles.charttitle}>
            <DisplayTextSubHeadline
              decoration="bold"
            >
              Schedules
            </DisplayTextSubHeadline>
          </div>
          <div className={styles.chartSection}>
            <div className={styles.chartBox}>
              <ChartBox
                title="Events & Comments"
              >
                <EventActivitiesChart
                  labels={labels}
                  activities={activities}
                />
              </ChartBox>
            </div>
          </div>
        </div>

        <div className={styles.chartSectionAndTitle}>
          <div className={styles.charttitle}>
            <DisplayTextSubHeadline
              decoration="bold"
            >
              Chats & Group Chats
            </DisplayTextSubHeadline>
          </div>
          <div className={styles.chartSection}>
            <div className={styles.chartBox}>
              <ChartBox
                title="Chats"
              >
                <ChatActivitiesChart
                  labels={labels}
                  activities={activities}
                />
              </ChartBox>
            </div>
            <div className={styles.chartBox}>
              <ChartBox
                title="Group Chats"
              >
                <GroupChatActivitiesChart
                  labels={labels}
                  activities={activities}
                />
              </ChartBox>
            </div>
          </div>
        </div>

        <div className={styles.chartSectionAndTitle}>
          <div className={styles.charttitle}>
            <DisplayTextSubHeadline
              decoration="bold"
            >
              CheckIns
            </DisplayTextSubHeadline>
          </div>
          <div className={styles.chartSection}>
            <div className={styles.chartBox}>
              <ChartBox
                title="Questions & Comments"
              >
                <QuestionActivitiesChart
                  labels={labels}
                  activities={activities}
                />
              </ChartBox>
            </div>
          </div>
        </div>

        <div className={styles.chartSectionAndTitle}>
          <div className={styles.charttitle}>
            <DisplayTextSubHeadline
              decoration="bold"
            >
              General Activities
            </DisplayTextSubHeadline>
          </div>
          <div className={styles.chartSection}>
            <div className={styles.chartBox}>
              <ChartBox
                title="Users"
              >
                <UserActivitiesChart
                  labels={labels}
                  activities={activities}
                />
              </ChartBox>
            </div>
            <div className={styles.chartBox}>
              <ChartBox
                title="Teams"
              >
                <TeamActivitiesChart
                  labels={labels}
                  activities={activities}
                />
              </ChartBox>
            </div>
          </div>
        </div>
      </FeedbackLoadingMain>
    </div>
  );
};

ChartList.propTypes = {
  handleSetCompany: PropTypes.func.isRequired,
  handleSetHeaderText: PropTypes.func.isRequired,
};

ChartList.defaultProps = {};

export default memo(ChartList);
