import React, { memo } from 'react';
import PropTypes from 'prop-types';
import styles from './SeparatorLine.module.css';

function SeparatorLine({ dark }) {
  return (
    <div className={dark ? `${styles.SeparatorLineDark} ${styles.SeparatorLine}` : styles.SeparatorLine} />
  );
}

SeparatorLine.propTypes = {
  dark: PropTypes.bool.isRequired,
};

const isComponentDataEqual = (prevProps, nextProps) => {
  const { dark } = nextProps;
  return prevProps.dark === dark;
};

export default memo(SeparatorLine, isComponentDataEqual);
