import React, {
  memo,
} from 'react';
import { isEqual, isNil } from 'lodash';
import PropTypes from 'prop-types';
import { handleCompareDate } from '../../../utilities/checkSameDay';
import SeparatorLine from '../../../components/UI/SeparatorLine/SeparatorLine';
import EventDateSeparator from '../EventDateSeparator/EventDateSeparator';
import Event from '../Event/Event';
import styles from './EventList.module.css';
import LinkNoDecor from '../../../components/LinkNoDecor/LinkNoDecor';
import { DisplayTextBody } from '../../../components/UI_V2/Display/Text';
import { checkIfUserHaveAccessToDataV3 } from '../../../actions/UserActions';

function EventList({
  events, companyId, teamId, user, currentCompany, currentRoleUser,
}) {
  if (isNil(events)) return null;
  return (
    <>
      {!events || events.length < 1
        ? (
          <DisplayTextBody position="center">Belum ada jadwal dalam rentang tanggal ini</DisplayTextBody>
        )
        : (
          <>
            {events.map((event, index) => {
              if (!checkIfUserHaveAccessToDataV3(event, user, currentRoleUser)) {
                return null;
              }
              const isSameDate = handleCompareDate(events, event, (index - 1), 'startDate');
              if (!isSameDate) {
                return (
                  <>
                    <SeparatorLine />
                    <div className={styles.showEvents}>
                      <div className={styles.showEvents__date}>
                        <EventDateSeparator date={event.startDate} />
                      </div>
                      <div className={styles.showEvents__item}>
                        <LinkNoDecor to={event.isOccurrence ? `/companies/${companyId}/teams/${teamId}/events/${event.event}/occurrences/${event._id}` : `/companies/${companyId}/teams/${teamId}/events/${event._id}`}>
                          <Event event={event} />
                        </LinkNoDecor>
                      </div>
                    </div>
                  </>
                );
              }
              return (
                <div className={styles.showEvents}>
                  <div className={styles.showEvents__date} />
                  <div className={styles.showEvents__item}>
                    <LinkNoDecor
                      to={event.isOccurrence ? `/companies/${companyId}/teams/${teamId}/events/${event.event}/occurrences/${event._id}` : `/companies/${companyId}/teams/${teamId}/events/${event._id}`}
                    >
                      <Event event={event} />
                    </LinkNoDecor>
                  </div>
                </div>
              );
            })}
          </>
        )}
    </>
  );
}

EventList.propTypes = {
  events: PropTypes.array.isRequired,
  user: PropTypes.object.isRequired,
  companyId: PropTypes.string.isRequired,
  teamId: PropTypes.string.isRequired,
  currentCompany: PropTypes.object.isRequired,
  currentRoleUser: PropTypes.object.isRequired,
};

const isComponentDataEqual = (prevProps, nextProps) => {
  const { events, user } = nextProps;
  return isEqual(prevProps.events, events)
    && isEqual(prevProps.user, user);
};

export default memo(EventList, isComponentDataEqual);
