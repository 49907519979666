const handleLoadings = (payload, currentLoadings, type) => {
  let newLoadings = [...currentLoadings];

  if (currentLoadings === undefined) return [];

  switch (type) {
    case 'start': {
      const filterLoadings = newLoadings.filter((loading) => loading !== payload);
      newLoadings = [...filterLoadings, payload];
      break;
    }

    case 'end': {
      const removeLoadings = newLoadings.filter((loading) => loading !== payload);
      newLoadings = [...removeLoadings];
      break;
    }

    default:
      break;
  }

  return [...newLoadings];
};

export default handleLoadings;
