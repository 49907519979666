import React, { memo, useState } from 'react';
import PropTypes from 'prop-types';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { useParams } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import styles from './SelectCouponSection.module.css';
import { DisplayTextBody, DisplayTextHeadline, DisplayTextSubBody } from '../../../../components/UI_V2/Display/Text';
import Color from '../../../../themes/colors';
import CouponItem from './CouponItem/CouponItem';
import ListCoupon from './ListCoupon/ListCoupon';
import { InputButtonMain } from '../../../../components/UI_V2/Input/Button';
import { InputTextFieldMain } from '../../../../components/UI_V2/Input/TextField';
import InputManualCoupon from './InputManualCoupon/InputManualCoupon';
import { BillingActions } from '../../../../actions';
import handleStatusMsg from '../../../../utilities/handleStatusMsg';
import handleLoadings from '../../../../utilities/handleLoadings';

const SelectCouponSection = ({
  order,
  handleSetOrder,
  handleHideCouponSection,
}) => {
  const [loadings, setLoadings] = useState([]);
  const params = useParams();
  const { companyId } = params;
  const { enqueueSnackbar } = useSnackbar();

  const handleClickApplyCoupon = async (couponCode) => {
    try {
      const startLoadings = handleLoadings(`coupon-add-${couponCode}`, [...loadings], 'start');
      setLoadings([...startLoadings]);
      const result = await BillingActions.addCouponOrderSummary({
        companyId,
        couponCode,
        orderId: order?._id,
      });

      const status = handleStatusMsg(result, 'success');

      enqueueSnackbar(status.message, {
        variant: 'success',
      });

      handleSetOrder(result.data);
      handleHideCouponSection();
    } catch (err) {
      const status = handleStatusMsg(err, 'error');

      enqueueSnackbar(status.message, {
        variant: 'error',
      });
    } finally {
      const endLoadings = handleLoadings(`coupon-add-${couponCode}`, [...loadings], 'end');
      setLoadings([...endLoadings]);
    }
  };

  return (
    <div className={styles.container}>
      <div className={styles.title}>
        <DisplayTextHeadline
          color={Color.blueNavy2}
          mode="24"
          decoration="bold"
          position="center"
        >
          Pilih Kupon
        </DisplayTextHeadline>
      </div>
      <div id="couponList" className={styles.list}>
        <ListCoupon
          handleClickApplyCoupon={handleClickApplyCoupon}
          loadings={loadings}
        />
      </div>
      <div className={styles.action}>
        <InputManualCoupon
          handleClickApplyCoupon={handleClickApplyCoupon}
          loadings={loadings}
        />
      </div>
    </div>
  );
};

SelectCouponSection.propTypes = {
  handleSetOrder: PropTypes.func.isRequired,
  order: PropTypes.object.isRequired,
  handleHideCouponSection: PropTypes.func.isRequired,
};

export default memo(SelectCouponSection);
