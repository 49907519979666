import React, { memo, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useSnackbar } from 'notistack';
import { useParams } from 'react-router-dom';
import styles from './ModalPlanSelection.module.css';
import { FeedbackDialogGeneral } from '../../../components/UI_V2/Feedback/Dialog';
import { DisplayTextBody, DisplayTextHeadline } from '../../../components/UI_V2/Display/Text';
import Color from '../../../themes/colors';
import handleStatusMsg from '../../../utilities/handleStatusMsg';
import handleLoadings from '../../../utilities/handleLoadings';
import { InputButtonMain } from '../../../components/UI_V2/Input/Button';
import ModalPlanSelectionPlanCard from './PlanCard/PlanCard';
import { BillingActions } from '../../../actions';
import { splitString } from '../../../utilities/stringUtil';
import BillingOrderSummary from '../OrderSummary/OrderSummary';
import ModalPlanSelectionSkeleton from './Skeleton/Skeleton';

const BillingModalPlanSelection = ({
  open,
  onClose,
  subscription,
  user,
  currentCompany,
  forceReinitiateInvoicesList,
  tabSelected,
}) => {
  const { enqueueSnackbar } = useSnackbar();
  const [loadings, setLoadings] = useState([]);
  const [showOrderSummary, setShowOrderSummary] = useState(false);
  const [initialOrderItems, setInitialOrderItems] = useState([]);
  const [planDurationCode, setPlanDurationCode] = useState();
  const [plans, setPlans] = useState([]);

  const params = useParams();

  const { companyId } = params;

  const getPlansApi = async (value) => {
    if (!open) return;
    try {
      const startLoadings = handleLoadings('billingModalPlanSelection', [...loadings], 'start');
      setLoadings([...startLoadings]);

      const result = await BillingActions.getPlans({
        planDurationCode: value,
        companyId,
      });

      setPlans(result.data);

      // const status = handleStatusMsg(result, 'success');

      // enqueueSnackbar(status.message, {
      //   variant: 'success',
      // });
    } catch (err) {
      const status = handleStatusMsg(err, 'error');

      enqueueSnackbar(status.message, {
        variant: 'error',
      });
    } finally {
      const endLoadings = handleLoadings('billingModalPlanSelection', [...loadings], 'end');
      setLoadings([...endLoadings]);
    }
  };

  const setInitialPlanDurationCode = (value) => {
    const result = splitString(value, '_');
    let code = result[1];

    if (code === 'monthly'
    || code === 'quarterly'
    || code === 'biyearly'
    || code === 'yearly'
    ) {
      //
    } else {
      code = 'monthly';
    }

    setPlanDurationCode(code);
    return code;
  };

  const handleShowOrderSummary = (value) => {
    setShowOrderSummary(value);
  };

  const handleCloseOrderSummary = () => {
    handleShowOrderSummary(false);
    getPlansApi(planDurationCode);
  };

  const handleClickPlan = ({ planId, qty }) => {
    const items = [
      {
        _id: planId,
        qty,
      },
    ];

    setInitialOrderItems(items);
    handleShowOrderSummary(true);
  };

  useEffect(() => {
    const code = setInitialPlanDurationCode(subscription?.plan?.name);
    getPlansApi(code);
  }, [open]);

  const handleSwitchPlanDuration = (value) => {
    if (value === planDurationCode) return;
    setPlanDurationCode(value);
    getPlansApi(value);
  };

  const handleCloseAllModalPlanSelection = () => {
    handleShowOrderSummary(false);
    onClose();
  };

  const isMonthlySelected = planDurationCode === 'monthly';
  const isQuarterlySelected = planDurationCode === 'quarterly';
  const isBiyearlySelected = planDurationCode === 'biyearly';
  const isYearlySelected = planDurationCode === 'yearly';

  const isPlansEmpty = plans.length === 0;

  return (
    <FeedbackDialogGeneral
      open={open}
      onClose={handleCloseAllModalPlanSelection}
    >
      <div className={styles.container}>
        {showOrderSummary && (
        <BillingOrderSummary
          handleClose={() => handleCloseOrderSummary()}
          initialOrderItems={initialOrderItems}
          user={user}
          currentCompany={currentCompany}
          isOpen={showOrderSummary}
          tabSelected={tabSelected}
          forceReinitiateInvoicesList={forceReinitiateInvoicesList}
        />
        )}
        {!showOrderSummary && (
        <>
          <div className={styles.title}>
            <DisplayTextHeadline
              color={Color.blueNavy2}
              mode="24"
              decoration="bold"
              position="center"
            >
              Pilih Paket
            </DisplayTextHeadline>
          </div>
          <div className={styles.durationSection}>
            <div className={styles.durationButton}>
              <InputButtonMain
                size="xs"
                rounded="default"
                variant={isMonthlySelected ? 'blueLight' : 'light'}
                handleClick={() => handleSwitchPlanDuration('monthly')}
              >
                Bulanan
              </InputButtonMain>
            </div>
            <div className={styles.durationButton}>
              <InputButtonMain
                size="xs"
                rounded="default"
                variant={isQuarterlySelected ? 'blueLight' : 'light'}
                handleClick={() => handleSwitchPlanDuration('quarterly')}
              >
                Kuartalan
              </InputButtonMain>
            </div>
            <div className={styles.durationButton}>
              <InputButtonMain
                size="xs"
                rounded="default"
                variant={isBiyearlySelected ? 'blueLight' : 'light'}
                handleClick={() => handleSwitchPlanDuration('biyearly')}
              >
                Semesteran
              </InputButtonMain>
            </div>
            <div className={styles.durationButton}>
              <InputButtonMain
                size="xs"
                rounded="default"
                variant={isYearlySelected ? 'blueLight' : 'light'}
                handleClick={() => handleSwitchPlanDuration('yearly')}
              >
                Tahunan
              </InputButtonMain>
            </div>
          </div>
          <div className={styles.scrollPlanCards}>
            <div className={styles.planCardSection}>
              {isPlansEmpty && <ModalPlanSelectionSkeleton />}
              {plans.map((plan) => (
                <div className={styles.planCard}>
                  <ModalPlanSelectionPlanCard
                    plan={plan}
                    planDurationCode={planDurationCode}
                    subscription={subscription}
                    handleClickPlan={handleClickPlan}
                    loadings={loadings}
                  />
                </div>
              ))}
            </div>
          </div>
          <div className={styles.footer}>
            <DisplayTextBody
              color={Color.gray3}
              mode="11"
              position="center"
            >
              * harga untuk per 1 perusahaan
            </DisplayTextBody>
            <DisplayTextBody
              color={Color.gray3}
              mode="11"
              position="center"
            >
              * Penambahan pengguna diluar paket 24.900 / pengguna / bulan
            </DisplayTextBody>
          </div>
        </>
        )}
      </div>
    </FeedbackDialogGeneral>
  );
};

BillingModalPlanSelection.propTypes = {
  open: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  subscription: PropTypes.object.isRequired,
  user: PropTypes.object.isRequired,
  currentCompany: PropTypes.object.isRequired,
  forceReinitiateInvoicesList: PropTypes.func.isRequired,
  tabSelected: PropTypes.string.isRequired,
};

export default memo(BillingModalPlanSelection);
