import React, {
  memo, useState,
} from 'react';
import PropTypes from 'prop-types';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import styles from './MoreButton.module.css';
import { DisplayTextBody } from '../../../UI_V2/Display/Text';
import Color from '../../../../themes/colors';
import PopUpMoreAction from '../PopUpMoreAction/PopUpMoreAction';

const MoreButton = ({ text, children }) => {
  const [showMore, setShowMore] = useState(false);

  const handleClick = () => {
    setShowMore(!showMore);
  };

  return (
    <>
      <div className={styles.container} onClick={handleClick}>
        <div className={styles.text}>
          <DisplayTextBody
            mode="11"
            decoration="bold"
            color={Color.yellowNavyCicle}
          >
            {text}
          </DisplayTextBody>
        </div>
        <div className={styles.icon}>
          <KeyboardArrowDownIcon />
        </div>
      </div>
      {showMore
        ? (
          <PopUpMoreAction>
            {children}
          </PopUpMoreAction>
        )
        : null}
    </>
  );
};

MoreButton.propTypes = {
  children: PropTypes.node.isRequired,
  text: PropTypes.string.isRequired,
};

export default memo(MoreButton);
