import React, { memo, useState } from 'react';
import PropTypes from 'prop-types';
import { Box } from '@mui/material';
import CheckIcon from '@mui/icons-material/Check';
import { isEmpty } from 'lodash';
import styles from './SingleListFilterSection.module.css';
import ButtonDiv from '../../../UI/Button/ButtonDiv/ButtonDiv';
import ButtonTextWithCheck from '../ButtonTextWithCheck/ButtonTextWithCheck';
import { limitChar } from '../../../../utilities/stringUtil';
import ButtonTextWithCheckBox from '../ButtonTextWithCheckBox/ButtonTextWithCheckBox';
import { DisplayDividerMain } from '../../../UI_V2/Display/Divider';
import { InputSearchGeneral } from '../../../UI_V2/Input/Search';
import { DisplayTextBody } from '../../../UI_V2/Display/Text';
import Color from '../../../../themes/colors';

const SingleListFilterSection = ({
  searchHook,
  checkSingleList,
  handleClickSingleList,
}) => {
  const {
    searchResult,
    keyword,
    keywordArray,
    setKeyword,
    isTyping,
    onChange,
    resetSearch,
  } = searchHook;
  return (
    <Box className={styles.container}>
      <Box className={styles.titleBox}>
        <DisplayTextBody>
          Cari List
        </DisplayTextBody>
      </Box>
      <Box className={styles.searchBox}>
        <InputSearchGeneral
          onChange={onChange}
          value={keyword}
          placeholder="Cari list..."
          onClickCloseIcon={resetSearch}
          size="sm"
          autoFocus={false}
        />
      </Box>
      <DisplayDividerMain borderColor={Color.gray6} marginTop="0.4rem" marginBottom="0px" />
      <Box className={styles.body}>
        {searchResult.length < 1
          && <span>List ga ketemu</span>}
        {searchResult.map((list, index) => (
          <ButtonTextWithCheck
            text={limitChar(list?.name, 30)}
            onClick={() => handleClickSingleList(list._id)}
            checked={checkSingleList === list._id}
          />
        ))}
      </Box>
    </Box>
  );
};

SingleListFilterSection.propTypes = {
  searchHook: PropTypes.object.isRequired,
  checkSingleList: PropTypes.string.isRequired,
  handleClickSingleList: PropTypes.func.isRequired,
};

export default memo(SingleListFilterSection);
