import { cloneDeep } from 'lodash';
import {
  CommentActions, ScheduleActions, TeamActions,
} from '.';
import { ApiConstants, EventConstants } from '../constants';
import { actionTypes } from '../reducers/reducer';
import apiUtil from '../utilities/apiUtil';
import { getLastListCreatedAt, mergeObjectListAndRemoveDuplicate, updateListProperty } from '../utilities/arrayUtil';
import { ErrorException } from '../utilities/handleError';

/*
  Dispatcher
*/

function dispatchCurrentScheduleEvent({ currentScheduleEvent }, dispatch) {
  dispatch({
    type: actionTypes.SET_CURRENT_SCHEDULE_EVENT,
    currentScheduleEvent,
  });
}

function dispatchPreviousEventComment({ previousEventComment }, dispatch) {
  dispatch({
    type: actionTypes.SET_PREVIOUS_EVENT_COMMENT,
    previousEventComment,
  });
}

function dispatchUpdateScheduleEvent({ updateScheduleEvent }, dispatch) {
  dispatch({
    type: actionTypes.UPDATE_CURRENT_SCHEDULE_EVENT,
    updateScheduleEvent,
  });
}

/*
  SetterDispatcher
*/

function setCurrentScheduleEvent({ currentScheduleEvent }, dispatch) {
  if (!currentScheduleEvent) return;

  dispatchCurrentScheduleEvent(
    { currentScheduleEvent: cloneDeep(currentScheduleEvent) },
    dispatch,
  );
}

function setPreviousEventComment({ previousEventComment }, dispatch) {
  if (!previousEventComment) return;

  dispatchPreviousEventComment(
    { previousEventComment: cloneDeep(previousEventComment) },
    dispatch,
  );
}

/*
  Helpers
*/

const modifyEventAndEventComments = (events, comments) => ({
  ...events,
  comments,
});

/*
  Method
*/

function incomingEvent({ event }, dispatch) {
  if (!event) return;

  const updateScheduleEvent = (currentScheduleEvent) => {
    let newCurrentScheduleEvent = {
      ...event,
      comments: currentScheduleEvent.comments,
      cheers: currentScheduleEvent.cheers,
    };
    newCurrentScheduleEvent = cloneDeep(newCurrentScheduleEvent);

    return newCurrentScheduleEvent;
  };

  dispatchUpdateScheduleEvent({ updateScheduleEvent }, dispatch);
}

function incomingEventComment({
  comment, typeAction, updateCommentCheer, keyProperty = 'comments',
}, dispatch) {
  if (!comment) return;

  const updateScheduleEvent = (currentScheduleEvent) => updateListProperty({
    keyProperty,
    newData: comment,
    currentList: currentScheduleEvent,
    typeAction,
    actionEdit: updateCommentCheer,
  });

  dispatchUpdateScheduleEvent({ updateScheduleEvent }, dispatch);
}

async function initiateEventV2({
  eventId, limit, companyId, teamId,
}, dispatch) {
  try {
    const result = await apiUtil.get(ApiConstants.URL_V2.EVENT_NEW({ eventId }), {
      params: {
        companyId,
        teamId,
      },
    });
    const resultComment = await apiUtil.get(ApiConstants.URL_V2.EVENT_COMMENT({ eventId }), {
      params: {
        limit,
        companyId,
        teamId,
      },
    });

    const modifiedResult = modifyEventAndEventComments(
      result?.data?.event,
      resultComment?.data?.comments,
    );

    setCurrentScheduleEvent({ currentScheduleEvent: modifiedResult }, dispatch);
    ScheduleActions.setCurrentSchedule(
      { currentSchedule: { _id: result?.data?.event?.schedule } }, dispatch,
    );
    // TeamActions.saveCurrentTeam({ currentTeam: result?.data?.currentTeam }, dispatch);

    const objComments = CommentActions.sliceResultDataforLastCommentNearLimitComment(resultComment);
    const payload = {
      data: {
        event: result?.data?.event,
        comments: objComments?.data?.comments,
        occurrenceId: result?.data?.occurrenceId,
      },
    };

    return payload;
  } catch (error) {
    throw new ErrorException(error);
  }
}

async function loadMoreEventComment({
  eventId, currentScheduleEvent, companyId, teamId,
}, dispatch) {
  try {
    const result = await apiUtil.get(ApiConstants.URL_V2.EVENT_COMMENT({ eventId }), {
      params: {
        limit: EventConstants.limitComment,
        createdAt: getLastListCreatedAt(currentScheduleEvent.comments),
        companyId,
        teamId,
      },
    });

    const modifiedResult = modifyEventAndEventComments(
      currentScheduleEvent,
      result?.data?.comments,
    );
    const mergedEvent = mergeObjectListAndRemoveDuplicate({
      currentObjectList: currentScheduleEvent,
      nextObjectList: modifiedResult,
      keyObject: 'comments',
    });

    setPreviousEventComment({ previousEventComment: modifiedResult?.comments }, dispatch);
    setCurrentScheduleEvent({ currentScheduleEvent: mergedEvent }, dispatch);

    return result;
  } catch (error) {
    throw new ErrorException(error);
  }
}

export {
  initiateEventV2,
  loadMoreEventComment,
  setCurrentScheduleEvent,
  setPreviousEventComment,
  dispatchUpdateScheduleEvent,
  incomingEventComment,
  incomingEvent,
};
