import React, { memo, useContext, useState } from 'react';
import PropTypes from 'prop-types';
import DownloadIcon from '@mui/icons-material/Download';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { useMediaQuery } from '@mui/material';
import { useParams } from 'react-router-dom';
import styles from './WidgetRecentlyVisit.module.css';
import Color from '../../../../themes/colors';
import ListRecentViewPages from '../../../SearchJumpToContainer/ShowResults/JumpTo/ListRecentViewPages/ListRecentViewPages';
import { getStoreRecentViewPages } from '../../../../utilities/localStorage';
import { GlobalContext } from '../../../../contexts/GlobalStateProvider';
import { DisplayTextSubHeadline } from '../../../../components/UI_V2/Display/Text';
import { DisplayDividerMain } from '../../../../components/UI_V2/Display/Divider';

const WidgetRecentlyVisit = () => {
  const [{ user }] = useContext(GlobalContext);
  const isMobile = useMediaQuery('(max-width:720px)');
  const params = useParams();
  const { companyId } = params;
  const listRecentViewPages = getStoreRecentViewPages({ companyId, userId: user?._id }) || [];
  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <DisplayTextSubHeadline color={Color.blueNavyCicle} decoration="bold">
          Terakhir Dibuka
        </DisplayTextSubHeadline>
      </div>
      <DisplayDividerMain
        borderColor={Color.gray6}
        marginTop="0.5rem"
        marginBottom="0.5rem"
      />
      <div className={styles.body}>
        <ListRecentViewPages
          params={params}
          list={listRecentViewPages}
        //   onClose={onClose}
        />
      </div>
    </div>
  );
};

WidgetRecentlyVisit.propTypes = {};

WidgetRecentlyVisit.defaultProps = {};

export default memo(WidgetRecentlyVisit);
