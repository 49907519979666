import React, { memo, useState } from 'react';
import PropTypes from 'prop-types';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import styles from './PercentageText.module.css';
import { DisplayTextBody } from '../../../../../components/UI_V2/Display/Text';
import Color from '../../../../../themes/colors';
import { getSingleOrPlural } from '../../../../../utilities/stringUtil';

const PercentageText = ({
  percentage,
}) => (
  <div className={styles.container}>
    <DisplayTextBody
      color={Color.blueAquaMarine}
      decoration="bold"
    >
      {`${percentage}%`}
    </DisplayTextBody>
  </div>
);

PercentageText.propTypes = {
  percentage: PropTypes.number.isRequired,
};

export default memo(PercentageText);
