import React, { memo, useState } from 'react';
import PropTypes from 'prop-types';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { useMediaQuery } from '@mui/material';
import styles from './EmptyTeamsSectionForAdmins.module.css';
import { DisplayTextHeadline, DisplayTextSubHeadline } from '../../../components/UI_V2/Display/Text';
import Color from '../../../themes/colors';
import { InputButtonMain } from '../../../components/UI_V2/Input/Button';
import ModalCreateTeam from './ModalCreateTeam/ModalCreateTeam';
import emptyIllustrationImage from '../../../assets/DashboardPage/empty-dashboard-admin.png';
import { elementId } from '../../../constants/ElementIdConstants';

function EmptyTeamsSectionForAdmins({ clicked, children, params }) {
  const [showCreateTeam, setShowCreateTeam] = useState(false);
  const handleCloseCreateTeam = () => {
    setShowCreateTeam(false);
  };

  const handleOpenCreateTeam = () => {
    setShowCreateTeam(true);
  };

  const isTablet = useMediaQuery('(max-width:1024px)');
  const isMobile = useMediaQuery('(max-width:720px)');
  return (
    <div className={styles.container}>
      <div className={styles.illustration}>
        <LazyLoadImage src={emptyIllustrationImage} />
      </div>
      <div className={styles.content}>
        <div className={styles.title}>
          <DisplayTextHeadline
            color={Color.blueNavyCicle}
            decoration="bold"
            position={isMobile && 'center'}
          >
            Hal Besar, Dimulai dari Langkah Kecil
          </DisplayTextHeadline>
        </div>
        <div className={styles.description}>
          <DisplayTextSubHeadline
            mode="20"
            position={isMobile && 'center'}
          >
            Langkah pertama, saatnya buat Tim!
            Kemudian kamu bisa mengundang orang-orang mu dan
            menugaskannya ke Tim yang udah kamu buat.
            {/* First time first, let&#39;s create a Team!
            So you can later invite your people and assign them
            to Team you&#39;ve created. */}
          </DisplayTextSubHeadline>
        </div>
        <div className={styles.cta}>
          <InputButtonMain
            handleClick={handleOpenCreateTeam}
            id={elementId.home_intro_createTeam_button}
          >
            Buat Tim
          </InputButtonMain>
        </div>
      </div>
      <ModalCreateTeam
        open={showCreateTeam}
        onClose={handleCloseCreateTeam}
        initialType="team"
        params={params}
      />
    </div>
  );
}

EmptyTeamsSectionForAdmins.propTypes = {
  clicked: PropTypes.func.isRequired,
  children: PropTypes.node.isRequired,
  params: PropTypes.object.isRequired,
};

export default memo(EmptyTeamsSectionForAdmins);
