import React, {
  useState, useContext, useEffect, memo,
} from 'react';
import PropTypes from 'prop-types';
import PersonAddAlt1OutlinedIcon from '@mui/icons-material/PersonAddAlt1Outlined';
import _ from 'lodash';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import VerifiedUserIcon from '@mui/icons-material/VerifiedUser';
import { InputButtonMain } from '../../../components/UI_V2/Input/Button';
import styles from './TabInviteMemberContainer.module.css';
import RemoveMemberButton from './RemoveMemberButton/RemoveMemberButton';
import InviteMemberContainer from './InviteMemberContainer/InviteMemberContainer';
import { DisplayTextBody, DisplayTextSubHeadline } from '../../../components/UI_V2/Display/Text';
import { DisplayAvatarMember } from '../../../components/UI_V2/Display/Avatar';
import { hasPermission } from '../../../actions/RoleActions';
import { GlobalContext } from '../../../contexts/GlobalStateProvider';
import { roles } from '../../../constants/RoleConstants';
import ListRoleUsers from './ListRoleUsers/ListRoleUsers';
import { elementId } from '../../../constants/ElementIdConstants';

const containerWithSidebarStyles = {
  true: `${styles.container} ${styles.sidebarOpen}`,
  false: styles.container,
};

const TabInviteMemberContainer = ({ }) => {
  const [{
    user, currentRoleUser, currentCompany,
    isSidebarOpen,
  }, dispatch] = useContext(GlobalContext);
  const [isInvite, setIsInvite] = useState(false);
  const [showModalRoleUser, setShowModalRoleUser] = useState(false);

  const handleShowModalRoleUser = () => {
    setShowModalRoleUser(true);
  };

  const handleHideModalRoleUser = () => {
    setShowModalRoleUser(false);
  };

  const handleCancel = () => {
    setIsInvite(false);
  };

  const openInviteMember = () => {
    setIsInvite(true);
  };

  const isUserAdminOrAbove = hasPermission(currentRoleUser, roles.adminOrAbove);
  const isUserSpvOrAbove = hasPermission(currentRoleUser, roles.spvOrAbove);

  return (
    <div className={containerWithSidebarStyles[isSidebarOpen]}>
      <div className={styles.header}>
        <div className={styles.text}>
          <div className={styles.title}>
            <DisplayTextSubHeadline
              mode="18"
              decoration="bold"
            >
              Anggota perusahaan
            </DisplayTextSubHeadline>
          </div>
          {/* <div className={styles.desc}>
            <DisplayTextSubHeadline
              mode="18"
            >
              You can invite new members by using their email.
            </DisplayTextSubHeadline>
          </div> */}
        </div>
        <div className={styles.inviteButton}>
          {isInvite
            ? (
              <InviteMemberContainer handleCancel={handleCancel} />
            )
            : null}
          {/* {isUserAdminOrAbove && (
          <div className={styles.manageRole}>
            <InputButtonMain
              variant="default"
              border="rounded"
              icon={<VerifiedUserIcon />}
              handleClick={handleShowModalRoleUser}
              iconPosition="start"
            >
              Roles
            </InputButtonMain>
          </div>
          )} */}
          {isUserSpvOrAbove && (
          <InputButtonMain
            variant="success"
            border="rounded"
            icon={<PersonAddAlt1OutlinedIcon />}
            handleClick={openInviteMember}
            iconPosition="start"
            id={elementId.companyInviteButton}
          >
            Undang
          </InputButtonMain>
          )}
        </div>
      </div>
      <div className={styles.search} />
      <div className={styles.members}>
        <ListRoleUsers />
        {/* {currentCompany?.members?.map((member) => (
          <div className={styles.memberContainer}>
            <div className={styles.member}>
              <div className={styles.member__photo}>
                <DisplayAvatarMember src={member.photoUrl} />
              </div>
              <div className={styles.member__status}>
                <DisplayTextSubHeadline
                  mode="16"
                  decoration="bold"
                >
                  {member.fullName}
                </DisplayTextSubHeadline>
                <DisplayTextBody>
                  {member.status}
                </DisplayTextBody>
              </div>
            </div>
            {(isUserAdminOrAbove
            || member._id === user._id) && (
            <div className={styles.removeButton}>
              <RemoveMemberButton member={member} />
            </div>
            )}
          </div>
        ))} */}
      </div>
      {/* <ModalChangeRoleUser
        open={showModalRoleUser}
        onClose={handleHideModalRoleUser}
      /> */}
    </div>
  );
};

TabInviteMemberContainer.propTypes = {};

export default memo(TabInviteMemberContainer);
