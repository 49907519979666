import React, { memo, useState } from 'react';
import PropTypes from 'prop-types';
import { Box, Skeleton } from '@mui/material';

const textStyles = {
  marginBottom: '5px',
};

const rectangularStyles = {
  marginBottom: '5px',
};

const sectionStyles = {
  marginBottom: '10px',
};

const SearchJumpToResultsSkeleton = () => (
  <Box>
    <Box
      sx={{
        ...sectionStyles,
      }}
    >
      <Skeleton sx={{ ...textStyles }} variant="text" animation="wave" width={100} />
      <Skeleton sx={{ ...rectangularStyles }} variant="rectangular" height={40} animation="wave" />
      <Skeleton sx={{ ...rectangularStyles }} variant="rectangular" height={40} animation="wave" />
    </Box>
    <Box
      sx={{
        ...sectionStyles,
      }}
    >
      <Skeleton sx={{ ...textStyles }} variant="text" animation="wave" width={100} />
      <Skeleton sx={{ ...rectangularStyles }} variant="rectangular" height={40} animation="wave" />
    </Box>
    <Box>
      <Skeleton sx={{ ...textStyles }} variant="text" animation="wave" width={100} />
      <Skeleton sx={{ ...rectangularStyles }} variant="rectangular" height={40} animation="wave" />
      <Skeleton sx={{ ...rectangularStyles }} variant="rectangular" height={40} animation="wave" />
      <Skeleton sx={{ ...rectangularStyles }} variant="rectangular" height={40} animation="wave" />
    </Box>
  </Box>
);

export default memo(SearchJumpToResultsSkeleton);
