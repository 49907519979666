import React, { memo, useState } from 'react';
import PropTypes from 'prop-types';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import styles from './AdditionalInfoRevenue.module.css';
import { DisplayTextBody } from '../../../../../../components/UI_V2/Display/Text';
import Color from '../../../../../../themes/colors';
import TextInfo from '../../../../CompanyDetailPage/CompanyInfo/DetailInfoBox/TextInfo/TextInfo';
import { dateBeautifyFormatter } from '../../../../../../utilities/dateUtil';
import { getSingleOrPlural } from '../../../../../../utilities/stringUtil';
import { formatToBasicThousandSeparator } from '../../../../../../utilities/numberUtil';

const AdditionalInfoRevenue = ({
  item,
}) => (
  <div className={styles.container}>
    <TextInfo
      title="Paid Date"
      text={dateBeautifyFormatter(item?.additionalInfo.paidDate)}
    />
    {item?.additionalInfo?.order?.items.map(((orderItem, index) => (
      <TextInfo
        title={`Order Item [${index + 1}]`}
        text={orderItem.desc}
      />
    )))}
    <TextInfo
      title="Total Before Discount"
      text={formatToBasicThousandSeparator(item?.additionalInfo?.order?.totalBeforeDiscount || 0)}
    />
    <TextInfo
      title="Discount Amount"
      text={formatToBasicThousandSeparator(item?.additionalInfo?.order?.discountAmount || 0)}
    />
    <TextInfo
      title="Total"
      text={formatToBasicThousandSeparator(item?.additionalInfo?.order?.total || 0)}
    />
    <TextInfo
      title="Buy After How Long Trial?"
      text={`${item?.additionalInfo?.buyAfterDaysTrial} ${getSingleOrPlural('Day', item?.additionalInfo?.buyAfterDaysTrial)}`}
    />
  </div>
);

AdditionalInfoRevenue.propTypes = {
  item: PropTypes.object.isRequired,
};

AdditionalInfoRevenue.defaultProps = {};

export default memo(AdditionalInfoRevenue);
