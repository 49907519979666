import React, { memo } from 'react';
import PropTypes from 'prop-types';
import styles from './RoundActionMenu.module.css';

const RoundActionMenu = ({
  clicked, children, id, medium, style,
}) => (
  <div
    onClick={() => clicked(id)}
    className={medium ? `${styles.container} ${styles.medium}` : styles.container}
    style={style}
  >
    {children}
  </div>
);

RoundActionMenu.propTypes = {
  children: PropTypes.node.isRequired,
  id: PropTypes.string.isRequired,
  clicked: PropTypes.func.isRequired,
  medium: PropTypes.bool.isRequired,
  style: PropTypes.object,
};

RoundActionMenu.defaultProps = {
  style: {},
};

export default memo(RoundActionMenu);
