import React, { useContext, useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import {
  Switch, Route, useHistory, useLocation,
} from 'react-router-dom';
import { useMediaQuery } from '@mui/material';
import TeamPage from './pages/TeamPage/TeamPage';
import DashboardPage from './pages/DashboardPage/DashboardPage';
import InvitePage from './pages/InvitePage/InvitePage';
import CheckFromExternalPage from './pages/CheckFromExternalPage/CheckFromExternalPage';
import CompanyPage from './pages/CompanyPage/CompanyPage';
import BoardPage from './pages/BoardPage/BoardPage';
import PrivateChatPage from './pages/PrivateChatPage/PrivateChatPage';
import GroupChatPage from './pages/GroupChatPage/GroupChatPage';
import BlastPage from './pages/BlastPage/BlastPage';
import CreateBlastPage from './pages/CreateBlastPage/CreateBlastPage';
import PostBlastPage from './pages/PostBlastPage/PostBlastPage';
import { GlobalContext, initialState } from './contexts/GlobalStateProvider';
import NotificationPage from './pages/NotificationPage/NotificationPage';
import CheckInPage from './pages/CheckInPage/CheckInPage';
import CreateQuestionPage from './pages/CreateQuestionPage/CreateQuestionPage';
import QuestionPage from './pages/QuestionPage/QuestionPage';
import SchedulePage from './pages/SchedulePage/SchedulePage';
import CreateEventPage from './pages/CreateEventPage/CreateEventPage';
import EventPage from './pages/EventPage/EventPage';
import OccurrencePage from './pages/OccurrencePage/OccurrencePage';
import BucketPage from './pages/BucketPage/BucketPage';
import CreateDocPage from './pages/CreateDocPage/CreateDocPage';
import DocPage from './pages/DocPage/DocPage';
import FilePage from './pages/FilePage/FilePage';
import EditBlastPostPage from './pages/EditBlastPostPage/EditBlastPostPage';
import EditQuestionPage from './pages/EditQuestionPage/EditQuestionPage';
import EditEventPage from './pages/EditEventPage/EditEventPage';
import EditOccurrencePage from './pages/EditOccurrencePage/EditOccurrencePage';
import EditDocPage from './pages/EditDocPage/EditDocPage';
import EditBucketPage from './pages/EditBucketPage/EditBucketPage';
import EditFilePage from './pages/EditFilePage/EditFilePage';
import ErrorPage from './pages/ErrorPage/ErrorPage';
import ProfilePopUpContainer from './pages/ProfilePopUpContainer/ProfilePopUpContainer';
import CommentDiscussPage from './pages/CommentDiscussPage/CommentDiscussPage';
import CheckLoginPage from './pages/CheckLoginPage/CheckLoginPage';
import CheersPage from './pages/CheersPage/CheersPage';
import BillingPage from './pages/BillingPage/BillingPage';
import SetPublicCalendarPage from './pages/SchedulePage/SetPublicCalendarPage/SetPublicCalendarPage';
import SuccessRedirectionPage from './pages/SuccessRedirectionPage/SuccessRedirectionPage';
import WelcomePage from './pages/WelcomePage/WelcomePage';
import WelcomeCreateCompanyPage from './pages/WelcomeCreateCompanyPage/WelcomeCreateCompanyPage';
import WelcomeLinkInvitationPage from './pages/WelcomeLinkInvitationPage/WelcomeLinkInvitationPage';
import RedirectUrlPage from './pages/RedirectUrlPage/RedirectUrlPage';
import DashboardAdminPage from './pages/DashboardAdminPage/DashboardAdminPage';
import RegisterPage from './pages/RegisterPage/RegisterPage';
import LoginPage from './pages/LoginPage/LoginPage';
import TeamSettingsContainer from './pages/TeamPage/TeamSettingsContainer/TeamSettingsContainer';
import NavHeader from './components/NavHeader/NavHeader';
import UserReportPageV2 from './pages/UserReportPageV2/UserReportPageV2';
import { useParamsCompanyIdTeamId } from './hooks/HelperHooks';
import {
  useNotificationCounterCompaniesSocket,
  useNotificationCounterSocket,
  useNotificationCounterTeamsSocket,
} from './hooks/NotificationHooks';
import { useUserOnlineSocket } from './hooks/UserOnlineHooks';
import { useTrackGTM } from './hooks/WebTrackingHooks';
import { useOneSignalPushNotif } from './hooks/OneSignalPushNotifHooks';
import ForgotPasswordPage from './pages/ForgotPasswordPage/ForgotPasswordPage';
import EmailRegistrationVerificationPage from './pages/EmailRegistrationVerificationPage/EmailRegistrationVerificationPage';
import ResetThenCreateNewPasswordPage from './pages/ResetThenCreateNewPasswordPage/ResetThenCreateNewPasswordPage';
import MyTaskCardListAllPage from './pages/MyTaskCardListAllPage/MyTaskCardListAllPage';
import MyTaskCardCalendarPage from './pages/MyTaskCardCalendarPage/MyTaskCardCalendarPage';
import BoardCardListAllPage from './pages/BoardCardListAllPage/BoardCardListAllPage';
import BoardRedirectionPage from './pages/BoardRedirectionPage/BoardRedirectionPage';
import CardRedirectionPage from './pages/CardRedirectionPage/CardRedirectionPage';
import BoardCardCalendarPage from './pages/BoardCardCalendarPage/BoardCardCalendarPage';
import Sidebar from './components/Sidebar/Sidebar';
import { NotificationActions } from './actions';
import { actionTypes } from './reducers/reducer';
import UserMenuContainer from './pages/UserMenuContainer/UserMenuContainer';
import TeamCardMenu from './pages/DashboardPage/FetchTeamsCards/Card/TeamCardMenu/TeamCardMenu';
import CompaniesMenuContainer from './pages/CompaniesMenuContainer/CompaniesMenuContainer';
import NotificationContainer from './pages/NotificationContainer/NotificationContainer';
import ActivityContainer from './pages/ActivityContainer/ActivityContainer';
import { handleCloseSidebar, handleOpenSidebar } from './actions/SidebarActions';
import EmailChangeVerificationPage from './pages/EmailChangeVerificationPage/EmailChangeVerificationPage';

const listHideNavHeader = [
  '/boards',
  '/cards',
  '/invitation',
  '/errors',
  '/success',
  '/welcome',
  '/admin',
  '/signin',
  '/signup',
  '/auth/email/password/reset',
  '/auth/email/register/verification',
];

function App() {
  const [{
    user,
    isDelayShowActivityMenu,
    isDelayShowCompaniesMenu,
    isDelayShowUserMenu,
    isDelayShowNotifMenu,
    isDelayShowEditTeamMenu,
    isDelayShowEditTeamFavoriteMenu,
    showUserMenu,
    showNotification,
    showActivityMenu,
    showCompaniesSelect,
    showEditTeamMenu,
    showEditTeamFavoriteMenu,
    selectedEditTeam,
    selectedEditTeamFavorite,
    isSidebarOpen,
  }, dispatch] = useContext(GlobalContext);
  const history = useHistory();
  const location = useLocation();
  const params = useParamsCompanyIdTeamId({ pathname: location.pathname });
  const shouldHideNav = listHideNavHeader.some((url) => location.pathname.includes(url));
  const isCardCommentUrl = location.pathname.includes('/cards') && location.pathname.includes('/comments');
  const isMyTaskCardListViewUrl = location.pathname.includes('/cards') && location.pathname.includes('/list') && location.pathname.includes('/my-tasks');
  const isMyTaskCardCalendarViewUrl = location.pathname.includes('/cards') && location.pathname.includes('/calendar') && location.pathname.includes('/my-tasks');
  const isCardCalendarViewUrl = location.pathname.includes('/cards') && location.pathname.includes('/calendar');
  const isBoardListViewUrl = location.pathname.includes('/boards') && location.pathname.includes('/list');
  const isBoardCalendarViewUrl = location.pathname.includes('/boards') && location.pathname.includes('/calendar') && !location.pathname.includes('/cards');
  const isCardRedirectUrl = location.pathname.includes('/cards') && !location.pathname.includes('/list') && !location.pathname.includes('/kanban') && !location.pathname.includes('/calendar') && !location.pathname.includes('/comments') && !location.pathname.includes('/boards') && !location.pathname.includes('/my-tasks');
  const isContainBoardView = location.pathname.includes('/boards');
  const isContainCardView = location.pathname.includes('/cards');
  const isContainCheckLogin = location.pathname.includes('/check-login');
  const isMobile = useMediaQuery('(max-width:720px)');

  const isStagingOrMySubdomain = window.location.hostname.includes('staging') || window.location.hostname.includes('my');

  useEffect(() => {
    if (location.pathname.includes('/invitation/')) return;
    if (location.pathname.includes('/external/')) return;
    if (location.pathname.includes('/check-login')) return;
    if (location.pathname.includes('/auth/email/register/verification')) return;
    if (location.pathname.includes('/auth/email/password/reset')) return;
    if (location.pathname.includes('/signup')) return;
    if (location.pathname.includes('/signin')) return;
    if (user._id === undefined) {
      history.push(`/check-login?previousPath=${location.pathname}${location.search}`);
    }
  }, []);

  useOneSignalPushNotif({
    companyId: params.companyId,
    userId: user?._id,
  });
  useNotificationCounterSocket({ userId: user?._id, companyId: params.companyId }, dispatch);
  useNotificationCounterCompaniesSocket(
    { userId: user?._id, companyId: params.companyId }, dispatch,
  );
  useNotificationCounterTeamsSocket({ companyId: params.companyId, userId: user?._id }, dispatch);
  useUserOnlineSocket({ companyId: params.companyId, userId: user?._id }, dispatch);
  useTrackGTM({ enableExecuteWithoutUser: true });

  // start - Related to open closing pop up navheader and sidebar
  const handleClearNotif = () => {
    NotificationActions.setAllNotif({
      data: initialState.allNotifications.data,
    }, dispatch);

    NotificationActions.setTotalAllNotif({
      total: initialState.allNotifications.total,
    }, dispatch);

    NotificationActions.setCurrentNotif({
      data: initialState.currentNotifications.data,
    }, dispatch);

    NotificationActions.setTotalNotif({
      total: initialState.currentNotifications.total,
    }, dispatch);

    dispatch({
      type: actionTypes.SET_CURRENT_CHAT_NOTIFICATIONS,
      currentChatNotifications: [],
    });
  };

  useEffect(() => {
    if (user?._id === undefined) return;

    handleClearNotif();
  }, [user]);

  const handleShowCompaniesSelect = () => {
    if (isDelayShowCompaniesMenu) return;
    dispatch({
      type: actionTypes.SET_SHOW_COMPANIES_SELECT,
      showCompaniesSelect: true,
    });
    dispatch({
      type: actionTypes.SET_SHOW_NOTIFICATION,
      showNotification: false,
    });
    dispatch({
      type: actionTypes.SET_SHOW_USER_MENU,
      showUserMenu: false,
    });
    dispatch({
      type: actionTypes.SET_SHOW_EDIT_TEAM_MENU,
      showEditTeamMenu: false,
    });
    dispatch({
      type: actionTypes.SET_SHOW_EDIT_TEAM_FAVORITE_MENU,
      showEditTeamFavoriteMenu: false,
    });
    if (isMobile) {
      handleCloseSidebar({}, dispatch);
    }
  };

  const handleShowActivityMenu = () => {
    if (isDelayShowActivityMenu) return;
    dispatch({
      type: actionTypes.SET_SHOW_ACTIVITY_MENU,
      showActivityMenu: true,
    });
    dispatch({
      type: actionTypes.SET_SHOW_NOTIFICATION,
      showNotification: false,
    });
    dispatch({
      type: actionTypes.SET_SHOW_USER_MENU,
      showUserMenu: false,
    });
    dispatch({
      type: actionTypes.SET_SHOW_COMPANIES_SELECT,
      showCompaniesSelect: false,
    });
    dispatch({
      type: actionTypes.SET_SHOW_EDIT_TEAM_MENU,
      showEditTeamMenu: false,
    });
    dispatch({
      type: actionTypes.SET_SHOW_EDIT_TEAM_FAVORITE_MENU,
      showEditTeamFavoriteMenu: false,
    });
    if (isMobile) {
      handleCloseSidebar({}, dispatch);
    }
  };

  const handleShowNotification = () => {
    if (isDelayShowNotifMenu) return;
    dispatch({
      type: actionTypes.SET_SHOW_NOTIFICATION,
      showNotification: true,
    });
    dispatch({
      type: actionTypes.SET_SHOW_USER_MENU,
      showUserMenu: false,
    });
    dispatch({
      type: actionTypes.SET_SHOW_COMPANIES_SELECT,
      showCompaniesSelect: false,
    });
    dispatch({
      type: actionTypes.SET_SHOW_ACTIVITY_MENU,
      showActivityMenu: false,
    });
    dispatch({
      type: actionTypes.SET_SHOW_EDIT_TEAM_MENU,
      showEditTeamMenu: false,
    });
    dispatch({
      type: actionTypes.SET_SHOW_EDIT_TEAM_FAVORITE_MENU,
      showEditTeamFavoriteMenu: false,
    });
    if (isMobile) {
      handleCloseSidebar({}, dispatch);
    }
  };

  const handleShowEditTeamMenu = () => {
    if (isDelayShowEditTeamMenu) return;
    dispatch({
      type: actionTypes.SET_SHOW_EDIT_TEAM_MENU,
      showEditTeamMenu: true,
    });
    dispatch({
      type: actionTypes.SET_SHOW_NOTIFICATION,
      showNotification: false,
    });
    dispatch({
      type: actionTypes.SET_SHOW_USER_MENU,
      showUserMenu: false,
    });
    dispatch({
      type: actionTypes.SET_SHOW_COMPANIES_SELECT,
      showCompaniesSelect: false,
    });
    dispatch({
      type: actionTypes.SET_SHOW_ACTIVITY_MENU,
      showActivityMenu: false,
    });
    dispatch({
      type: actionTypes.SET_SHOW_EDIT_TEAM_FAVORITE_MENU,
      showEditTeamFavoriteMenu: false,
    });
  };

  const handleShowEditTeamFavoriteMenu = () => {
    if (isDelayShowEditTeamFavoriteMenu) return;
    dispatch({
      type: actionTypes.SET_SHOW_EDIT_TEAM_FAVORITE_MENU,
      showEditTeamFavoriteMenu: true,
    });
    dispatch({
      type: actionTypes.SET_SHOW_EDIT_TEAM_MENU,
      showEditTeamMenu: false,
    });
    dispatch({
      type: actionTypes.SET_SHOW_NOTIFICATION,
      showNotification: false,
    });
    dispatch({
      type: actionTypes.SET_SHOW_USER_MENU,
      showUserMenu: false,
    });
    dispatch({
      type: actionTypes.SET_SHOW_COMPANIES_SELECT,
      showCompaniesSelect: false,
    });
    dispatch({
      type: actionTypes.SET_SHOW_ACTIVITY_MENU,
      showActivityMenu: false,
    });
  };

  const handleCancelShow = (from) => {
    dispatch({
      type: actionTypes.SET_SHOW_NOTIFICATION,
      showNotification: false,
    });
    dispatch({
      type: actionTypes.SET_SHOW_USER_MENU,
      showUserMenu: false,
    });
    dispatch({
      type: actionTypes.SET_SHOW_COMPANIES_SELECT,
      showCompaniesSelect: false,
    });
    dispatch({
      type: actionTypes.SET_SHOW_ACTIVITY_MENU,
      showActivityMenu: false,
    });
    dispatch({
      type: actionTypes.SET_SHOW_EDIT_TEAM_MENU,
      showEditTeamMenu: false,
    });
    dispatch({
      type: actionTypes.SET_SHOW_EDIT_TEAM_FAVORITE_MENU,
      showEditTeamFavoriteMenu: false,
    });
    if (from === 'companiesMenu') {
      dispatch({
        type: actionTypes.SET_IS_DELAY_SHOW_COMPANIES_MENU,
        isDelayShowCompaniesMenu: true,
      });
    }
    if (from === 'userMenu') {
      dispatch({
        type: actionTypes.SET_IS_DELAY_SHOW_USER_MENU,
        isDelayShowUserMenu: true,
      });
    }
    if (from === 'notifMenu') {
      dispatch({
        type: actionTypes.SET_IS_DELAY_SHOW_NOTIF_MENU,
        isDelayShowNotifMenu: true,
      });
    }
    if (from === 'activityMenu') {
      dispatch({
        type: actionTypes.SET_IS_DELAY_SHOW_ACTIVITY_MENU,
        isDelayShowActivityMenu: true,
      });
    }
    if (from === 'editTeamMenu') {
      dispatch({
        type: actionTypes.SET_IS_DELAY_SHOW_EDIT_TEAM_MENU,
        isDelayShowEditTeamMenu: true,
      });
    }
    if (from === 'editTeamFavoriteMenu') {
      dispatch({
        type: actionTypes.SET_IS_DELAY_SHOW_EDIT_TEAM_FAVORITE_MENU,
        isDelayShowEditTeamFavoriteMenu: true,
      });
    }
  };

  const handleShowUserMenu = () => {
    if (isDelayShowUserMenu) return;
    dispatch({
      type: actionTypes.SET_SHOW_USER_MENU,
      showUserMenu: true,
    });
    dispatch({
      type: actionTypes.SET_SHOW_COMPANIES_SELECT,
      showCompaniesSelect: false,
    });
    dispatch({
      type: actionTypes.SET_SHOW_ACTIVITY_MENU,
      showActivityMenu: false,
    });
    dispatch({
      type: actionTypes.SET_SHOW_NOTIFICATION,
      showNotification: false,
    });
    dispatch({
      type: actionTypes.SET_SHOW_EDIT_TEAM_MENU,
      showEditTeamMenu: false,
    });
    dispatch({
      type: actionTypes.SET_SHOW_EDIT_TEAM_FAVORITE_MENU,
      showEditTeamFavoriteMenu: false,
    });
    if (isMobile) {
      handleCloseSidebar({}, dispatch);
    }
  };

  useEffect(() => {
    if (!isDelayShowUserMenu) return;
    setTimeout(() => {
      dispatch({
        type: actionTypes.SET_IS_DELAY_SHOW_USER_MENU,
        isDelayShowUserMenu: false,
      });
    }, 100);
  }, [isDelayShowUserMenu]);

  useEffect(() => {
    if (!isDelayShowCompaniesMenu) return;
    setTimeout(() => {
      dispatch({
        type: actionTypes.SET_IS_DELAY_SHOW_COMPANIES_MENU,
        isDelayShowCompaniesMenu: false,
      });
    }, 100);
  }, [isDelayShowCompaniesMenu]);

  useEffect(() => {
    if (!isDelayShowActivityMenu) return;
    setTimeout(() => {
      dispatch({
        type: actionTypes.SET_IS_DELAY_SHOW_ACTIVITY_MENU,
        isDelayShowActivityMenu: false,
      });
    }, 100);
  }, [isDelayShowActivityMenu]);

  useEffect(() => {
    if (!isDelayShowNotifMenu) return;
    setTimeout(() => {
      dispatch({
        type: actionTypes.SET_IS_DELAY_SHOW_NOTIF_MENU,
        isDelayShowNotifMenu: false,
      });
    }, 100);
  }, [isDelayShowNotifMenu]);

  useEffect(() => {
    if (!isDelayShowEditTeamMenu) return;
    setTimeout(() => {
      dispatch({
        type: actionTypes.SET_IS_DELAY_SHOW_EDIT_TEAM_MENU,
        isDelayShowEditTeamMenu: false,
      });
    }, 100);
  }, [isDelayShowEditTeamMenu]);

  useEffect(() => {
    if (!isDelayShowEditTeamFavoriteMenu) return;
    setTimeout(() => {
      dispatch({
        type: actionTypes.SET_IS_DELAY_SHOW_EDIT_TEAM_FAVORITE_MENU,
        isDelayShowEditTeamFavoriteMenu: false,
      });
    }, 100);
  }, [isDelayShowEditTeamFavoriteMenu]);

  // reset counter notif to zero when have no companyId
  useEffect(() => {
    if (user?._id && !params?.companyId) {
      NotificationActions.setCounterNotif({
        nonChat: 0,
        chat: 0,
      }, dispatch);
      NotificationActions.setCounterCompaniesNotif({
        totalCounter: 0,
        companies: [],
      }, dispatch);
    }
  }, [params?.companyId]);

  // close all menu when changing location
  useEffect(() => {
    handleCancelShow();
  }, [location]);

  // let showEditTeamMenuTop = '25rem';

  // if (currentTeamFavorites.data.length <= 1) {
  //   showEditTeamMenuTop = '18rem';
  // }

  // if (currentTeamFavorites.data.length === 2) {
  //   showEditTeamMenuTop = '20rem';
  // }

  // if (currentTeamFavorites.data.length === 3) {
  //   showEditTeamMenuTop = '22rem';
  // }

  // if (currentTeamFavorites.data.length === 4) {
  //   showEditTeamMenuTop = '24rem';
  // }
  // end - Related to open closing pop up navheader and sidebar

  return (
    <>
      {isStagingOrMySubdomain && (
        <Helmet>
          <meta name="robots" content="noindex, nofollow" />
        </Helmet>
      )}
      {/* {!shouldHideNav && (
      <NavHeader
        params={params}
        handleCancelShow={handleCancelShow}
        handleShowUserMenu={handleShowUserMenu}
      />
      )}
      {isCardCommentUrl
      && !isMyTaskCardCalendarViewUrl
      && !isMyTaskCardListViewUrl && (
      <NavHeader
        params={params}
        handleCancelShow={handleCancelShow}
        handleShowUserMenu={handleShowUserMenu}
      />
      )}
      {isMyTaskCardListViewUrl && (
      <NavHeader
        params={params}
        handleCancelShow={handleCancelShow}
        handleShowUserMenu={handleShowUserMenu}
      />
      )}
      {isCardCalendarViewUrl && (
      <NavHeader
        params={params}
        handleCancelShow={handleCancelShow}
        handleShowUserMenu={handleShowUserMenu}
      />
      )}
      {isBoardListViewUrl && (
      <NavHeader
        params={params}
        handleCancelShow={handleCancelShow}
        handleShowUserMenu={handleShowUserMenu}
      />
      )}
      {isBoardCalendarViewUrl && (
      <NavHeader
        params={params}
        handleCancelShow={handleCancelShow}
        handleShowUserMenu={handleShowUserMenu}
      />
      )}
      {isCardRedirectUrl && (
      <NavHeader
        params={params}
        handleCancelShow={handleCancelShow}
        handleShowUserMenu={handleShowUserMenu}
      />
      )} */}
      {((!shouldHideNav || isContainBoardView || (isContainCardView && !isCardRedirectUrl))
      && !isContainCheckLogin) && (
      <Sidebar
        params={params}
        handleCancelShow={handleCancelShow}
        handleShowActivityMenu={handleShowActivityMenu}
        handleShowNotification={handleShowNotification}
        handleShowCompaniesSelect={handleShowCompaniesSelect}
        handleShowEditTeamMenu={handleShowEditTeamMenu}
        handleShowEditTeamFavoriteMenu={handleShowEditTeamFavoriteMenu}
        handleShowUserMenu={handleShowUserMenu}
      />
      )}
      <div style={{
        width: '0px',
        position: 'fixed',
        top: '-20px',
        left: isSidebarOpen ? '240px' : '5px',
        zIndex: '4',
      }}
      >
        {showActivityMenu && (
          <ActivityContainer
            params={params}
            cancel={() => handleCancelShow('activityMenu')}
          />
        )}
        {showNotification && (
          <NotificationContainer
            params={params}
            cancel={() => handleCancelShow('notifMenu')}
          />
        )}
        {showCompaniesSelect && (
          <CompaniesMenuContainer
            cancel={() => handleCancelShow('companiesMenu')}
          />
        )}
        {showEditTeamMenu && (
          <TeamCardMenu
            team={selectedEditTeam}
            cancel={() => handleCancelShow('editTeamMenu')}
            isCanCreateTeam
            customParams={params}
            customStyle={{
              position: 'absolute',
              top: '14rem',
            }}
          />
        )}
        {showEditTeamFavoriteMenu && (
          <TeamCardMenu
            team={selectedEditTeamFavorite}
            cancel={() => handleCancelShow('editTeamFavoriteMenu')}
            isCanCreateTeam
            customParams={params}
            customStyle={{
              position: 'absolute',
              top: '14rem',
            }}
            isFromTeamFavorite
          />
        )}
        {showUserMenu && (
          <UserMenuContainer params={params} cancel={() => handleCancelShow('userMenu')} />
        )}
      </div>
      <Switch>
        <Route exact path="/"><DashboardPage /></Route>
        <Route exact path="/companies/:companyId"><DashboardPage /></Route>
        <Route exact path="/teams/:teamId"><RedirectUrlPage /></Route>
        <Route exact path="/companies/:companyId/teams/:teamId"><TeamPage /></Route>
        <Route exact path="/companies/:companyId/teams/:teamId/settings"><TeamSettingsContainer /></Route>
        <Route exact path="/companies/:companyId/teams/:teamId/reports/members/:memberId"><UserReportPageV2 /></Route>
        <Route exact path="/companies/:companyId/settings/:tabId"><CompanyPage /></Route>
        <Route exact path="/companies/:companyId/billings"><BillingPage /></Route>
        <Route exact path="/signin"><LoginPage /></Route>
        <Route exact path="/signup"><RegisterPage /></Route>
        <Route exact path="/auth/email/password/reset/:tokenKey"><ResetThenCreateNewPasswordPage /></Route>
        <Route exact path="/auth/email/password/reset"><ForgotPasswordPage /></Route>
        <Route exact path="/auth/email/register/verification/:tokenKey"><EmailRegistrationVerificationPage /></Route>
        <Route exact path="/auth/email/change/:tokenKey"><EmailChangeVerificationPage /></Route>
        <Route exact path="/check-login"><CheckLoginPage /></Route>
        <Route exact path="/boards/:boardId"><RedirectUrlPage /></Route>
        <Route exact path="/companies/:companyId/teams/:teamId/boards/:boardId"><BoardRedirectionPage /></Route>
        <Route exact path="/companies/:companyId/teams/:teamId/boards/:boardId/kanban/all">
          <BoardPage />
        </Route>
        <Route exact path="/cards/:cardId"><RedirectUrlPage /></Route>
        <Route exact path="/companies/:companyId/teams/:teamId/cards/:cardId"><CardRedirectionPage /></Route>
        <Route exact path="/companies/:companyId/teams/:teamId/boards/:boardId/kanban/all/cards/:cardId">
          <BoardPage />
        </Route>
        <Route exact path="/invitation/:token"><InvitePage /></Route>
        <Route exact path="/external/:sourcePage"><CheckFromExternalPage /></Route>
        <Route exact path="/chats/:chatId"><RedirectUrlPage /></Route>
        <Route exact path="/companies/:companyId/chats"><PrivateChatPage /></Route>
        <Route exact path="/companies/:companyId/chats/:chatId"><PrivateChatPage /></Route>
        <Route exact path="/group-chats/:groupChatId"><RedirectUrlPage /></Route>
        <Route exact path="/companies/:companyId/teams/:teamId/group-chats/:groupChatId"><GroupChatPage /></Route>
        <Route exact path="/blasts/:blastId"><RedirectUrlPage /></Route>
        <Route exact path="/companies/:companyId/teams/:teamId/blasts/:blastId"><BlastPage /></Route>
        <Route exact path="/blasts/:blastId/create"><RedirectUrlPage /></Route>
        <Route exact path="/companies/:companyId/teams/:teamId/blasts/:blastId/create"><CreateBlastPage /></Route>
        <Route exact path="/posts/:postId"><RedirectUrlPage /></Route>
        <Route exact path="/companies/:companyId/teams/:teamId/posts/:postId"><PostBlastPage /></Route>
        <Route exact path="/posts/:postId/edit"><RedirectUrlPage /></Route>
        <Route exact path="/companies/:companyId/teams/:teamId/posts/:postId/edit"><EditBlastPostPage /></Route>
        <Route exact path="/notifications"><RedirectUrlPage /></Route>
        <Route exact path="/companies/:companyId/notifications"><NotificationPage /></Route>
        <Route exact path="/check-ins/:checkInId"><RedirectUrlPage /></Route>
        <Route exact path="/companies/:companyId/teams/:teamId/check-ins/:checkInId"><CheckInPage /></Route>
        <Route exact path="/check-ins/:checkInId/create"><RedirectUrlPage /></Route>
        <Route exact path="/companies/:companyId/teams/:teamId/check-ins/:checkInId/create"><CreateQuestionPage /></Route>
        <Route exact path="/questions/:questionId"><RedirectUrlPage /></Route>
        <Route exact path="/companies/:companyId/teams/:teamId/questions/:questionId"><QuestionPage /></Route>
        <Route exact path="/questions/:questionId/edit"><RedirectUrlPage /></Route>
        <Route exact path="/companies/:companyId/teams/:teamId/questions/:questionId/edit"><EditQuestionPage /></Route>
        <Route exact path="/schedules/:scheduleId"><RedirectUrlPage /></Route>
        <Route exact path="/companies/:companyId/teams/:teamId/schedules/:scheduleId"><SchedulePage /></Route>
        <Route exact path="/schedules/:scheduleId/create"><RedirectUrlPage /></Route>
        <Route exact path="/companies/:companyId/teams/:teamId/schedules/:scheduleId/create"><CreateEventPage /></Route>
        <Route exact path="/schedules/:scheduleId/public-calendar"><RedirectUrlPage /></Route>
        <Route exact path="/companies/:companyId/teams/:teamId/schedules/:scheduleId/public-calendar"><SetPublicCalendarPage /></Route>
        <Route exact path="/events/:eventId"><RedirectUrlPage /></Route>
        <Route exact path="/companies/:companyId/teams/:teamId/events/:eventId"><EventPage /></Route>
        <Route exact path="/events/:eventId/occurrences/:occurrenceId"><RedirectUrlPage /></Route>
        <Route exact path="/companies/:companyId/teams/:teamId/events/:eventId/occurrences/:occurrenceId"><OccurrencePage /></Route>
        <Route exact path="/events/:eventId/edit"><RedirectUrlPage /></Route>
        <Route exact path="/companies/:companyId/teams/:teamId/events/:eventId/edit"><EditEventPage /></Route>
        <Route exact path="/events/:eventId/occurrences/:occurrenceId/edit"><RedirectUrlPage /></Route>
        <Route exact path="/companies/:companyId/teams/:teamId/events/:eventId/occurrences/:occurrenceId/edit"><EditOccurrencePage /></Route>
        <Route exact path="/buckets/:bucketId"><RedirectUrlPage /></Route>
        <Route exact path="/companies/:companyId/teams/:teamId/buckets/:bucketId"><BucketPage /></Route>
        <Route exact path="/buckets/:bucketId/edit"><RedirectUrlPage /></Route>
        <Route exact path="/companies/:companyId/teams/:teamId/buckets/:bucketId/edit"><EditBucketPage /></Route>
        <Route exact path="/buckets/:bucketId/create"><RedirectUrlPage /></Route>
        <Route exact path="/companies/:companyId/teams/:teamId/buckets/:bucketId/create"><CreateDocPage /></Route>
        <Route exact path="/docs/:docId"><RedirectUrlPage /></Route>
        <Route exact path="/companies/:companyId/teams/:teamId/docs/:docId"><DocPage /></Route>
        <Route exact path="/docs/:docId/edit"><RedirectUrlPage /></Route>
        <Route exact path="/companies/:companyId/teams/:teamId/docs/:docId/edit"><EditDocPage /></Route>
        <Route exact path="/files/:fileId"><RedirectUrlPage /></Route>
        <Route exact path="/companies/:companyId/teams/:teamId/files/:fileId"><FilePage /></Route>
        <Route exact path="/files/:fileId/edit"><RedirectUrlPage /></Route>
        <Route exact path="/companies/:companyId/teams/:teamId/files/:fileId/edit"><EditFilePage /></Route>
        <Route exact path="/profiles/:profileId"><RedirectUrlPage /></Route>
        <Route exact path="/companies/:companyId/profiles/:profileId"><ProfilePopUpContainer /></Route>
        <Route exact path="/questions/:questionId/comments/:commentId"><RedirectUrlPage /></Route>
        <Route exact path="/companies/:companyId/teams/:teamId/questions/:questionId/comments/:commentId"><CommentDiscussPage /></Route>
        <Route exact path="/posts/:postId/comments/:commentId"><RedirectUrlPage /></Route>
        <Route exact path="/companies/:companyId/teams/:teamId/posts/:postId/comments/:commentId"><CommentDiscussPage /></Route>
        <Route exact path="/cards/:cardId/comments/:commentId"><RedirectUrlPage /></Route>
        <Route exact path="/companies/:companyId/teams/:teamId/cards/:cardId/comments/:commentId"><CommentDiscussPage /></Route>
        <Route exact path="/companies/:companyId/my-tasks/calendar/all/teams/:teamId/cards/:cardId/comments/:commentId"><CommentDiscussPage /></Route>
        <Route exact path="/companies/:companyId/my-tasks/list/all/teams/:teamId/cards/:cardId/comments/:commentId"><CommentDiscussPage /></Route>
        <Route exact path="/events/:eventId/occurrences/:occurrenceId/comments/:commentId"><RedirectUrlPage /></Route>
        <Route exact path="/companies/:companyId/teams/:teamId/events/:eventId/occurrences/:occurrenceId/comments/:commentId"><CommentDiscussPage /></Route>
        <Route exact path="/events/:eventId/comments/:commentId"><RedirectUrlPage /></Route>
        <Route exact path="/companies/:companyId/teams/:teamId/events/:eventId/comments/:commentId"><CommentDiscussPage /></Route>
        <Route exact path="/docs/:docId/comments/:commentId"><RedirectUrlPage /></Route>
        <Route exact path="/companies/:companyId/teams/:teamId/docs/:docId/comments/:commentId"><CommentDiscussPage /></Route>
        <Route exact path="/files/:fileId/comments/:commentId"><RedirectUrlPage /></Route>
        <Route exact path="/companies/:companyId/teams/:teamId/files/:fileId/comments/:commentId"><CommentDiscussPage /></Route>
        <Route exact path="/administrators"><DashboardAdminPage /></Route>
        <Route exact path="/administrators/reports/tractions"><DashboardAdminPage /></Route>
        <Route exact path="/administrators/reports/metrics"><DashboardAdminPage /></Route>
        <Route exact path="/administrators/reports/companies"><DashboardAdminPage /></Route>
        <Route exact path="/administrators/reports/companies/:companyId"><DashboardAdminPage /></Route>
        <Route exact path="/administrators/reports/insights"><DashboardAdminPage /></Route>
        <Route exact path="/welcome"><WelcomePage /></Route>
        <Route exact path="/welcome/create-company"><WelcomeCreateCompanyPage /></Route>
        <Route exact path="/welcome/link-invitation"><WelcomeLinkInvitationPage /></Route>
        <Route exact path="/cheers"><RedirectUrlPage /></Route>
        <Route exact path="/companies/:companyId/my-tasks/list/all"><MyTaskCardListAllPage /></Route>
        <Route exact path="/companies/:companyId/my-tasks/list/all/teams/:teamId/cards/:cardId"><MyTaskCardListAllPage /></Route>
        <Route exact path="/companies/:companyId/my-tasks/calendar/all"><MyTaskCardCalendarPage /></Route>
        <Route exact path="/companies/:companyId/my-tasks/calendar/all/teams/:teamId/cards/:cardId"><MyTaskCardCalendarPage /></Route>
        <Route exact path="/companies/:companyId/teams/:teamId/boards/:boardId/list/all"><BoardCardListAllPage /></Route>
        <Route exact path="/companies/:companyId/teams/:teamId/boards/:boardId/list/all/cards/:cardId"><BoardCardListAllPage /></Route>
        <Route exact path="/companies/:companyId/teams/:teamId/boards/:boardId/calendar/all"><BoardCardCalendarPage /></Route>
        <Route exact path="/companies/:companyId/teams/:teamId/boards/:boardId/calendar/all/cards/:cardId"><BoardCardCalendarPage /></Route>
        <Route exact path="/companies/:companyId/cheers"><CheersPage /></Route>
        <Route exact path="/success"><SuccessRedirectionPage /></Route>
        <Route exact path="/errors"><ErrorPage /></Route>
        <Route><ErrorPage /></Route>
      </Switch>
    </>
  );
}

export default App;
