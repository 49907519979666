import React, { memo, useState } from 'react';
import PropTypes from 'prop-types';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import styles from './HeaderActivity.module.css';
import Color from '../../../themes/colors';
import { DisplayTextSubHeadline } from '../../../components/UI_V2/Display/Text';
import { InputButtonMain } from '../../../components/UI_V2/Input/Button';

const HeaderActivity = ({
  handleHideActivities,
}) => (
  <div className={styles.container}>
    <DisplayTextSubHeadline
      color={Color.gray3}
      decoration="semibold"
      mode="16"
    >
      Komentar & Aktifitas
    </DisplayTextSubHeadline>

    <div className={styles.activityButton}>
      <InputButtonMain variant="light" handleClick={handleHideActivities} size="sm">
        Kembali ke Komentar
      </InputButtonMain>
    </div>
  </div>
);

HeaderActivity.propTypes = {
  handleHideActivities: PropTypes.func.isRequired,
};

HeaderActivity.defaultProps = {};

export default memo(HeaderActivity);
