import React, {
  useState, useContext, useEffect, useCallback, createRef,
} from 'react';
import { useHistory, useParams, useLocation } from 'react-router-dom';
import {
  cloneDeep, differenceWith, isArray, isEqual, isNumber,
} from 'lodash';
import { useSnackbar } from 'notistack';
import queryString from 'query-string';
import AddIcon from '@mui/icons-material/Add';
import { GlobalContext } from '../../contexts/GlobalStateProvider';
import { actionTypes } from '../../reducers/reducer';
import '../../../node_modules/react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import styles from './CommentDiscussPage.module.css';
import CommentsSectionContainer from '../CommentsSectionContainer/CommentsSectionContainer';
import handleStatusMsg from '../../utilities/handleStatusMsg';
import handleLoadings from '../../utilities/handleLoadings';
import Title from '../../components/Title/Title';
import { CommentConstants, PageConstants } from '../../constants';
import {
  CommentActions,
  TeamActions,
  SearchJumpToActions,
  PostActions,
  QuestionActions,
  DocActions,
  FileActions,
  EventActions,
  OccurrenceActions,
  CardActions,
} from '../../actions';
import InfinityScroll from '../../components/UI/InfinityScroll/InfinityScroll';
import { sortingListByCreatedAt } from '../../utilities/arrayUtil';
import apiUtil from '../../utilities/apiUtil';
import { useDiscussionCheerNCommentSocket, useDiscussionSocket } from '../../hooks/DiscussionHooks';
import {
  connectSocketCard, connectSocketDoc, connectSocketEvent, connectSocketFile,
  connectSocketOccurrenceNoTypo, connectSocketPost, connectSocketQuestion,
} from '../../services/socket';
import { setStore } from '../../utilities/localStorage';
import GeneralSubNavBar from '../../components/GeneralSubNavBar/GeneralSubNavBar';
import { generateRoutesAndTitle } from '../../actions/BreadCrumbActions';
import GlobalActionButton from '../../components/GeneralSubNavBar/BottomNavBar/GlobalActionButton/GlobalActionButton';
import CommentDiscussPageHeaderPost from './HeaderPost/HeaderPost';
import { DisplayDividerMain } from '../../components/UI_V2/Display/Divider';
import { LayoutMain } from '../../components/UI_V2/Layout';
import { SurfacePaperMain } from '../../components/UI_V2/Surface/Paper';
import { FeedbackLoadingMain } from '../../components/UI_V2/Feedback/Loading';
import CommentDiscussPageSkeleton from './Skeleton/Skeleton';
import CommentDiscussPageIntroText from './IntroText/IntroText';
import { useUserTypingDiscussionSocket } from '../../hooks/UserTypingHooks';
import UserTyping from '../../components/Container/UserTyping/UserTyping';
import { useMarkReadNotificationOnPageOpen } from '../../hooks/NotificationHooks';

const CommentDiscussPage = () => {
  const [{
    user,
    currentTeam,
    currentComment,
    currentCommentDiscussions,
    previousDiscussionComment,
    currentBlast,
    currentBlastPost,
    currentSchedule,
    currentScheduleEvent,
    currentOccurrence,
    currentCheckIn,
    currentCheckInQuestion,
    currentBoard,
    currentBoardLists,
    currentCard,
    currentBucket,
    currentBucketDoc,
    currentBucketFile,
    bucketDocPath,
    currentCompany,
    allUserTypingDiscussion,
  }, dispatch] = useContext(GlobalContext);
  const [loadings, setLoadings] = useState([]);
  const [type, setType] = useState();
  const [commentParentData, setCommentParentData] = useState({
    title: '',
    creator: {
      photoUrl: '',
      fullName: '',
    },
    createdAt: null,
  });
  const [introTextUrl, setIntroTextUrl] = useState({
    teamUrl: null,
    parentUrl: null,
  });
  const [openCreateCommentForm, setOpenCreateCommentForm] = useState(false);
  const [navbarType, setNavbarType] = useState({ recentViewPageId: '', breadcrumb: '', bottomBar: '' });
  const [isNeedCheckLoadMore, setCheckLoadMore] = useState(false);
  const [isErrorLoadMore, setStatusErrorLoadMore] = useState(false);
  const [commentsFiles, setCommentsFiles] = useState([]);
  const { enqueueSnackbar } = useSnackbar();

  const history = useHistory();
  const location = useLocation();

  const isLocationMyTaskListAll = location.pathname.includes('/my-tasks/list/all');
  const isLocationMyTaskCalendarAll = location.pathname.includes('/my-tasks/calendar/all');
  const isLocationBoard = !isLocationMyTaskCalendarAll && !isLocationMyTaskListAll;

  const params = useParams();
  const {
    companyId,
    commentId,
    teamId,
  } = params;

  const { socket } = useUserTypingDiscussionSocket({
    companyId,
    commentId,
    teamId,
    userId: user?._id,
  }, dispatch);

  // read notif automatically if there is readNotifId
  useMarkReadNotificationOnPageOpen();

  // for open create comment
  const handleOpenCreateCommentForm = useCallback((value) => {
    setOpenCreateCommentForm(value);
  }, []);
  const createCommentRef = createRef();
  const scrollAndOpenCreateCommentForm = useCallback(() => {
    if (createCommentRef && createCommentRef.current) {
      createCommentRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
    }
    setTimeout(() => {
      handleOpenCreateCommentForm(true);
    }, 800);
  }, [createCommentRef]);

  useEffect(() => () => {
    if (history.action === 'POP') {
      const query = queryString.parse(location.search);
      const previousCommentDiscussion = {
        commentId: params?.commentId,
        commentIndex: query?.commentIndex,
      };
      setStore('previousCommentDiscussion', previousCommentDiscussion);
    }
  });

  const setTypeDiscussion = useCallback(() => {
    let actionSocket;
    let paramsSocket;
    let eventSocket;

    if (params.questionId) {
      actionSocket = connectSocketQuestion;
      paramsSocket = { questionId: params.questionId, userId: user?._id };
      eventSocket = `questionComment-update-${params.questionId}`;
    } else if (params.cardId) {
      actionSocket = connectSocketCard;
      paramsSocket = { cardId: params.cardId, userId: user?._id };
      eventSocket = `cardsComment-update-${params.cardId}`;
    } else if (params.postId) {
      actionSocket = connectSocketPost;
      paramsSocket = { postId: params.postId, userId: user?._id };
      eventSocket = `postsComment-update-${params.postId}`;
    } else if (params.eventId && params.occurrenceId) {
      actionSocket = connectSocketOccurrenceNoTypo;
      paramsSocket = {
        eventId: params.eventId, occurrenceId: params.occurrenceId, userId: user?._id,
      };
      eventSocket = `occurrencesComment-update-${params.occurrenceId}`;
    } else if (params.eventId && !params.occurrenceId) {
      actionSocket = connectSocketEvent;
      paramsSocket = { eventId: params.eventId, userId: user?._id };
      eventSocket = `eventsComment-update-${params.eventId}`;
    } else if (params.docId) {
      actionSocket = connectSocketDoc;
      paramsSocket = { docId: params.docId, userId: user?._id };
      eventSocket = `docsComment-update-${params.docId}`;
    } else if (params.fileId) {
      actionSocket = connectSocketFile;
      paramsSocket = { fileId: params.fileId, userId: user?._id };
      eventSocket = `filesComment-update-${params.fileId}`;
    } else {
      //
    }

    return {
      actionSocket,
      paramsSocket,
      eventSocket,
    };
  }, []);

  const typeDiscussion = setTypeDiscussion();

  /*
    socket
    useDiscussionSocket => main comment socket (include cheer)
    useDiscussionCheerNCommentSocket => child cheer and comment socket
  */
  useDiscussionSocket({
    typeDiscussion,
    commentId: params?.commentId,
  }, dispatch);
  useDiscussionCheerNCommentSocket({
    commentId: params?.commentId,
    userId: user?._id,
  }, dispatch);

  const sortingCurrentQuestionComment = useCallback(
    () => sortingListByCreatedAt(currentCommentDiscussions),
    [currentCommentDiscussions],
  );
  const isCommentsLengthAtTheLimitOrAbove = useCallback((comments) => isNumber(comments?.length)
  && comments?.length >= CommentConstants.limitComment, []);
  const checkIfDiscussionNeedToLoad = useCallback(() => {
    if (!isNeedCheckLoadMore || isErrorLoadMore) return false;

    return isCommentsLengthAtTheLimitOrAbove(previousDiscussionComment);
  }, [previousDiscussionComment, isErrorLoadMore, isNeedCheckLoadMore]);

  useEffect(() => {
    if (!isArray(currentCommentDiscussions)) return;

    const joinedData = sortingCurrentQuestionComment();

    const prevArray = [...commentsFiles];
    const updatedItems = differenceWith(joinedData, prevArray, isEqual);
    const isItemRemoved = isArray(prevArray)
    && isArray(joinedData) && prevArray.length > joinedData.length;

    if (updatedItems.length > 0 || isItemRemoved) {
      setCommentsFiles([...joinedData]);
    }
  }, [currentCommentDiscussions]);

  useEffect(() => () => CommentActions.resetDiscussionComment(dispatch), []);

  useEffect(() => {
    if (user._id === undefined) {
      history.push(`/check-login?previousPath=${location.pathname}`);
      return;
    }
    const startLoadings = handleLoadings('commentPage', [...loadings], 'start');
    setLoadings([...startLoadings]);

    let id;
    let ids;

    if (params.questionId) {
      id = params.questionId;
      // setUrl(
      // `${process.env.REACT_APP_PRIMARY_API_URL}/api/v1/questions/${id}/comments/${commentId}`);
      setIntroTextUrl({
        teamUrl: `/companies/${companyId}/teams/${teamId}`,
        parentUrl: `/companies/${companyId}/teams/${teamId}/questions/${id}`,
      });
      setType({ comment: 'question', discussion: 'questionDiscussion' });
      setNavbarType({
        recentViewPageId: `${id}-${commentId}`,
        breadcrumb: PageConstants.pageType.questionsComment,
        bottomBar: 'check-ins',
      });
    } else if (params.cardId) {
      id = params.cardId;
      // setUrl(
      // `${process.env.REACT_APP_PRIMARY_API_URL}/api/v1/cards/${id}/comments/${commentId}`);

      if (isLocationMyTaskCalendarAll) {
        setIntroTextUrl({
          teamUrl: `/companies/${companyId}/teams/${teamId}`,
          parentUrl: `/companies/${companyId}/my-tasks/calendar/all/teams/${teamId}/cards/${id}`,
          myTaskUrl: `/companies/${companyId}/my-tasks/calendar/all`,
        });
        setNavbarType({
          recentViewPageId: `${id}-${commentId}`,
          breadcrumb: PageConstants.pageType.myTasksCalendarCardsComment,
          bottomBar: 'boards',
        });
      }
      if (isLocationMyTaskListAll) {
        setIntroTextUrl({
          teamUrl: `/companies/${companyId}/teams/${teamId}`,
          parentUrl: `/companies/${companyId}/my-tasks/list/all/teams/${teamId}/cards/${id}`,
          myTaskUrl: `/companies/${companyId}/my-tasks/list/all`,
        });
        setNavbarType({
          recentViewPageId: `${id}-${commentId}`,
          breadcrumb: PageConstants.pageType.myTasksListCardsComment,
          bottomBar: 'boards',
        });
      }

      if (isLocationBoard) {
        setIntroTextUrl({
          teamUrl: `/companies/${companyId}/teams/${teamId}`,
          parentUrl: `/companies/${companyId}/teams/${teamId}/cards/${id}`,
        });
        setNavbarType({
          recentViewPageId: `${id}-${commentId}`,
          breadcrumb: PageConstants.pageType.cardsComment,
          bottomBar: 'boards',
        });
      }

      setType({ comment: 'card', discussion: 'cardDiscussion' });
    } else if (params.postId) {
      id = params.postId;
      // setUrl(
      // `${process.env.REACT_APP_PRIMARY_API_URL}/api/v1/posts/${id}/comments/${commentId}`);
      setIntroTextUrl({
        teamUrl: `/companies/${companyId}/teams/${teamId}`,
        parentUrl: `/companies/${companyId}/teams/${teamId}/posts/${id}`,
      });
      setType({ comment: 'blast', discussion: 'blastDiscussion' });
      setNavbarType({
        recentViewPageId: `${id}-${commentId}`,
        breadcrumb: PageConstants.pageType.postsComment,
        bottomBar: 'blasts',
      });
    } else if (params.eventId && params.occurrenceId) {
      ids = [params.eventId, params.occurrenceId];
      // setUrl(
      // `${process.env.REACT_APP_PRIMARY_API_URL}
      // /api/v1/events/${ids[0]}/occurrences/${ids[1]}/comments/${commentId}`);
      setIntroTextUrl({
        teamUrl: `/companies/${companyId}/teams/${teamId}`,
        parentUrl: `/companies/${companyId}/teams/${teamId}/events/${ids[0]}/occurrences/${ids[1]}`,
      });
      setType({ comment: 'occurrence', discussion: 'occurrenceDiscussion' });
      setNavbarType({
        recentViewPageId: `${id}-${commentId}`,
        breadcrumb: PageConstants.pageType.occurrencesComment,
        bottomBar: 'schedules',
      });
    } else if (params.eventId && !params.occurrenceId) {
      id = params.eventId;
      // setUrl(
      // `${process.env.REACT_APP_PRIMARY_API_URL}/api/v1/events/${id}/comments/${commentId}`);
      setIntroTextUrl({
        teamUrl: `/companies/${companyId}/teams/${teamId}`,
        parentUrl: `/companies/${companyId}/teams/${teamId}/events/${id}`,
      });
      setType({ comment: 'event', discussion: 'eventDiscussion' });
      setNavbarType({
        recentViewPageId: `${id}-${commentId}`,
        breadcrumb: PageConstants.pageType.eventsComment,
        bottomBar: 'schedules',
      });
    } else if (params.docId) {
      id = params.docId;
      // setUrl(`${process.env.REACT_APP_PRIMARY_API_URL}/api/v1/docs/${id}/comments/${commentId}`);
      setIntroTextUrl({
        teamUrl: `/companies/${companyId}/teams/${teamId}`,
        parentUrl: `/companies/${companyId}/teams/${teamId}/docs/${id}`,
      });
      setType({ comment: 'doc', discussion: 'docDiscussion' });
      setNavbarType({
        recentViewPageId: `${id}-${commentId}`,
        breadcrumb: PageConstants.pageType.docsComment,
        bottomBar: 'buckets',
      });
    } else if (params.fileId) {
      id = params.fileId;
      // setUrl(
      // `${process.env.REACT_APP_PRIMARY_API_URL}/api/v1/files/${id}/comments/${commentId}`);
      setIntroTextUrl({
        teamUrl: `/companies/${companyId}/teams/${teamId}`,
        parentUrl: `/companies/${companyId}/teams/${teamId}/files/${id}`,
      });
      setType({ comment: 'file', discussion: 'fileDiscussion' });
      setNavbarType({
        recentViewPageId: `${id}-${commentId}`,
        breadcrumb: PageConstants.pageType.filesComment,
        bottomBar: 'buckets',
      });
    } else {
      //
    }
  }, [location]);

  useEffect(() => {
    if (type === undefined || type === null) return;

    const fetchApiCommentDetail = async () => {
      try {
        // need to get intial data according to feature, for breadrcumb
        // const result = await apiUtil.get(url, {
        //   params: {
        //     companyId,
        //     teamId,
        //   },
        // });
        let result;
        const resultV2 = await CommentActions.initiateDiscussion(
          { commentId, companyId, teamId }, dispatch,
        );
        if (isCommentsLengthAtTheLimitOrAbove(resultV2?.discussions)) setCheckLoadMore(true);

        switch (type.discussion) {
          case 'questionDiscussion':
            if (!params.questionId) return;
            result = await QuestionActions.initiateQuestion(
              {
                questionId: params.questionId,
                checkInId: currentCheckIn?._id,
                teamId,
                companyId,
              }, dispatch,
            );
            // dispatch({
            //   type: actionTypes.SET_CURRENT_CHECK_IN_QUESTION,
            //   currentCheckInQuestion: cloneDeep(result.data.question),
            // });
            // dispatch({
            //   type: actionTypes.SET_CURRENT_CHECK_IN,
            //   currentCheckIn: cloneDeep(result.data.checkIn),
            // });

            setCommentParentData({
              title: result.data.question.title,
              creator: result.data.question.creator,
              createdAt: result.data.question.createdAt,
            });
            break;
          case 'occurrenceDiscussion':
            if (!params.eventId || !params.occurrenceId) return;
            result = await OccurrenceActions.initiateOccurrenceV2(
              {
                eventId: params.eventId,
                occurrenceId: params.occurrenceId,
                companyId,
                teamId,
                limit: CommentActions.getLimitCommentByPreviousCommentDiscussion(),
              },
              dispatch,
            );
            // dispatch({
            //   type: actionTypes.SET_CURRENT_OCCURRENCE,
            //   currentOccurrence: result.data.occurrence,
            // });

            // dispatch({
            //   type: actionTypes.SET_CURRENT_SCHEDULE,
            //   currentSchedule: result.data.schedule,
            // });

            setCommentParentData({
              title: result.data.occurrence.title,
              creator: result.data.occurrence.creator,
              createdAt: result.data.occurrence.createdAt,
            });
            break;
          case 'eventDiscussion':
            if (!params.eventId) return;
            result = await EventActions.initiateEventV2({
              eventId: params.eventId,
              companyId,
              teamId,
              limit: CommentActions.getLimitCommentByPreviousCommentDiscussion(),
            }, dispatch);
            // dispatch({
            //   type: actionTypes.SET_CURRENT_SCHEDULE_EVENT,
            //   currentScheduleEvent: result.data.event,
            // });

            // dispatch({
            //   type: actionTypes.SET_CURRENT_SCHEDULE,
            //   currentSchedule: result.data.schedule,
            // });

            setCommentParentData({
              title: result.data.event.title,
              creator: result.data.event.creator,
              createdAt: result.data.event.createdAt,
            });
            break;
          case 'blastDiscussion':
            if (!params.postId) return;
            result = await PostActions.initiatePost({
              postId: params.postId,
              companyId,
              blastId: currentBlast?._id,
              teamId,
            }, dispatch);
            // dispatch({
            //   type: actionTypes.SET_CURRENT_BLAST_POST,
            //   currentBlastPost: cloneDeep(result.data.post),
            // });
            // dispatch({
            //   type: actionTypes.SET_CURRENT_BLAST,
            //   currentBlast: cloneDeep(result.data.blast),
            // });

            setCommentParentData({
              title: result.data.blast.title,
              creator: result.data.blast.creator,
              createdAt: result.data.blast.createdAt,
            });
            break;
          case 'cardDiscussion':
            if (!params.cardId) return;
            result = await CardActions.initiateCardV2(
              {
                cardId: params.cardId,
                currentBoardId: currentBoard?._id,
                companyId,
                teamId,
                currentBoard,
                currentBoardLists,
                currentTeam,
              },
              dispatch,
            );
            // dispatch({
            //   type: actionTypes.SET_CURRENT_CARD,
            //   currentCard: cloneDeep(result.data.card),
            // });

            // const fetchedBoard = cloneDeep(result.data.board);
            // const fetchedBoardLists = cloneDeep(fetchedBoard.lists);

            // dispatch({
            //   type: actionTypes.SET_CURRENT_BOARD,
            //   currentBoard: cloneDeep(fetchedBoard),
            // });

            // dispatch({
            //   type: actionTypes.SET_CURRENT_BOARD_LISTS,
            //   currentBoardLists: cloneDeep(fetchedBoardLists),
            // });

            setCommentParentData({
              title: result.data.card.name,
              creator: result.data.card.creator,
              createdAt: result.data.card.createdAt,
            });
            break;

          case 'docDiscussion':
            if (!params.docId) return;
            result = await DocActions.initiateDoc({
              docId: params.docId,
              companyId,
              teamId,
              limit: CommentActions.getLimitCommentByPreviousCommentDiscussion(),
            }, dispatch);
            // dispatch({
            //   type: actionTypes.SET_CURRENT_BUCKET_DOC,
            //   currentBucketDoc: cloneDeep(result.data.doc),
            // });
            // dispatch({
            //   type: actionTypes.SET_CURRENT_BUCKET,
            //   currentBucket: cloneDeep(result.data.bucket),
            // });

            // dispatch({
            //   type: actionTypes.SET_BUCKET_DOC_PATH,
            //   bucketDocPath: cloneDeep(result.data.bucketDocPath),
            // });

            setCommentParentData({
              title: result.data.doc.title,
              creator: result.data.doc.creator,
              createdAt: result.data.doc.createdAt,
            });
            break;
          case 'fileDiscussion':
            if (!params.fileId) return;
            result = await FileActions.initiateFile({
              fileId: params.fileId,
              companyId,
              teamId,
              limit: CommentActions.getLimitCommentByPreviousCommentDiscussion(),
            }, dispatch);
            // dispatch({
            //   type: actionTypes.SET_CURRENT_BUCKET_FILE,
            //   currentBucketFile: cloneDeep(result.data.file),
            // });

            // dispatch({
            //   type: actionTypes.SET_CURRENT_BUCKET,
            //   currentBucket: cloneDeep(result.data.bucket),
            // });

            // dispatch({
            //   type: actionTypes.SET_BUCKET_DOC_PATH,
            //   bucketDocPath: cloneDeep(result.data.bucketDocPath),
            // });

            setCommentParentData({
              title: result.data.file.title,
              creator: result.data.file.creator,
              createdAt: result.data.file.createdAt,
            });
            break;

          default:
            break;
        }
        // TeamActions.saveCurrentTeam({ currentTeam: resultV2?.team }, dispatch);
        const resultTeam = await TeamActions.initiateTeam({
          teamId,
          companyId,
          currentTeam,
        }, dispatch);
        await TeamActions.initiateTeamMembers({
          teamId,
          companyId,
          currentTeam: resultTeam?.data?.team || resultTeam,
        }, dispatch);
      } catch (err) {
        const status = handleStatusMsg(err, 'error');

        dispatch({
          type: actionTypes.SET_ERROR_RESPONSE,
          errorResponse: { message: status.message },
        });

        history.push(`/errors?previousPath=${location.pathname}`);
      } finally {
        const endLoadings = handleLoadings('commentPage', [...loadings], 'end');
        setLoadings([...endLoadings]);
      }
    };
    fetchApiCommentDetail();
  }, [type]);

  useEffect(() => {
    if (currentComment !== null) return;

    if (type) {
      switch (type.discussion) {
        case 'questionDiscussion':
          history.push(`/companies/${companyId}/teams/${teamId}/questions/${params.questionId}`);
          break;
        case 'blastDiscussion':
          history.push(`/companies/${companyId}/teams/${teamId}/posts/${params.postId}`);
          break;
        case 'cardDiscussion':
          history.push(`/companies/${companyId}/teams/${teamId}/cards/${params.cardId}`);
          break;
        case 'occurrenceDiscussion':
          history.push(`/companies/${companyId}/teams/${teamId}/events/${params.eventId}/occurrences/${params.occurrenceId}`);
          break;
        case 'eventDiscussion':
          history.push(`/companies/${companyId}/teams/${teamId}/events/${params.eventId}`);
          break;
        case 'docDiscussion':
          history.push(`/companies/${companyId}/teams/${teamId}/docs/${params.docId}`);
          break;
        case 'fileDiscussion':
          history.push(`/companies/${companyId}/teams/${teamId}/files/${params.fileId}`);
          break;
        default:
          break;
      }
    }
  }, [currentComment]);

  const handleLoadMoreComments = async () => {
    try {
      const result = await CommentActions.loadMoreDiscussionComment({
        companyId,
        commentId,
        currentCommentDiscussions,
        teamId,
      }, dispatch);
    } catch (error) {
      const status = handleStatusMsg(error, 'error');

      if (status?.message) {
        enqueueSnackbar(status.message, {
          variant: 'error',
        });
      }

      setStatusErrorLoadMore(true);
    }
  };

  const breadcrumb = generateRoutesAndTitle({
    type: navbarType.breadcrumb,
    data: {
      companyId,
      currentTeam,
      currentBlast,
      currentBlastPost,
      currentSchedule,
      currentScheduleEvent,
      currentOccurrence,
      currentCheckIn,
      currentCheckInQuestion,
      currentBoard,
      currentCard,
      currentBucket,
      currentBucketDoc,
      currentBucketFile,
      bucketDocPath,
      currentComment,
    },
  });

  SearchJumpToActions.saveRecentPage({
    company: currentCompany,
    userId: user?._id,
    itemId: navbarType.recentViewPageId,
    url: breadcrumb.url,
    routes: breadcrumb.routes,
    name: breadcrumb.title,
    type: navbarType.breadcrumb,
  });

  return (
    <>
      {currentComment ? <Title title={currentComment.creator ? `${currentComment.creator?.fullName} berkomentar ke : ${commentParentData.title}` : 'Diskusi'} /> : null}
      <GeneralSubNavBar
        breadcrumbRoutes={breadcrumb.routes}
        pageTitle={breadcrumb.title}
        bottomBarOverviewOptionId={navbarType.bottomBar}
        bottomBarGlobalActions={(
          <>
            <GlobalActionButton text="Tambah Balasan" handleClick={scrollAndOpenCreateCommentForm} icon={<AddIcon />} />
          </>
        )}
      />
      <LayoutMain>
        <SurfacePaperMain>
          <div className={styles.container}>
            <FeedbackLoadingMain
              wait="commentPage"
              loadings={loadings}
              loadingComponent={<CommentDiscussPageSkeleton />}
            >
              <div className={styles.headerSection}>
                <div className={styles.titleSection}>
                  {currentComment && type ? (
                    <div className={styles.titleSection__title}>
                      <div className={styles.introText}>
                        <CommentDiscussPageIntroText
                          currentComment={currentComment}
                          currentTeam={currentTeam}
                          type={type}
                          introTextUrl={introTextUrl}
                        />
                      </div>
                      <CommentDiscussPageHeaderPost commentParentData={commentParentData} />
                    </div>
                  ) : null}
                </div>
                <div className={styles.menuSection} />
              </div>
              <DisplayDividerMain />
              {currentComment && type
                ? (
                  <div>
                    {currentComment.creator ? (
                      <CommentsSectionContainer
                        comments={[currentComment]}
                        type={type.comment}
                        hideReply
                        hideAddComment
                        disableAutoScrollHook
                        socket={socket}
                      />
                    ) : null}

                  </div>
                ) : null}
              <DisplayDividerMain />
              <div className={styles.typingSection}>
                <UserTyping
                  allUserTyping={allUserTypingDiscussion}
                  companyMembers={currentCompany?.members}
                />
              </div>
              {currentCommentDiscussions && type ? (
                <InfinityScroll
                  dataLength={commentsFiles?.length}
                  hasMore={checkIfDiscussionNeedToLoad()}
                  next={handleLoadMoreComments}
                  emptyMessage="Belum ada komentar disini..."
                  style={{
                    overflow: undefined,
                  }}
                >
                  <CommentsSectionContainer
                    ref={createCommentRef}
                    openForm={openCreateCommentForm}
                    onOpenForm={handleOpenCreateCommentForm}
                    comments={commentsFiles}
                    type={type.discussion}
                    hideShowActivitiesButton
                    socket={socket}
                  />
                </InfinityScroll>
              ) : null}
            </FeedbackLoadingMain>
          </div>
        </SurfacePaperMain>
      </LayoutMain>
    </>
  );
};

export default CommentDiscussPage;
