import React, { memo, useContext } from 'react';
import { useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined';
import ArchiveOutlinedIcon from '@mui/icons-material/ArchiveOutlined';
import useMediaQuery from '@mui/material/useMediaQuery';
import VerifiedUserIcon from '@mui/icons-material/VerifiedUser';
import styles from './HomeSubNavHeader.module.css';
import HeadlineSubNavBar from '../../../components/GeneralSubNavBar/HeadlineSubNavBar/HeadlineSubNavBar';
import ShowLimitMembers from '../../../components/ShowLimitMembers/ShowLimitMembers';
import { InputButtonRounded } from '../../../components/UI_V2/Input/Button';
import LinkNoDecor from '../../../components/LinkNoDecor/LinkNoDecor';
import InviteMemberContainer from '../../CompanyPage/TabInviteMemberContainer/InviteMemberContainer/InviteMemberContainer';
import GlobalActionButton from '../../../components/GeneralSubNavBar/BottomNavBar/GlobalActionButton/GlobalActionButton';
import { hasPermission } from '../../../actions/RoleActions';
import { roleTypeRef, roles, typeRef } from '../../../constants/RoleConstants';
import { GlobalContext } from '../../../contexts/GlobalStateProvider';
import { useDelayShowHideHandler } from '../../../hooks/HelperHooks';
import MyRoleUser from '../../../components/Container/MyRoleUser/MyRoleUser';
import MemberOnlineModal from '../../../components/Container/MemberOnlineModal/MemberOnlineModal';
import { elementId } from '../../../constants/ElementIdConstants';

const containerWithSidebarStyles = {
  true: `${styles.container} ${styles.sidebarOpen}`,
  false: styles.container,
};

const HomeSubNavHeader = ({
  company,
  handleShowArchivedItems,
}) => {
  const [{ currentRoleUser, isSidebarOpen }] = useContext(GlobalContext);
  const [
    showModalRoleUser,
    handleShowModalRoleUser,
    handleHideModalRoleUser,
  ] = useDelayShowHideHandler();

  const [
    showMemberOnlineModal,
    handleShowMemberOnlineModal,
    handleHideMemberOnlineModal,
  ] = useDelayShowHideHandler();
  const isUserAdminOrAbove = hasPermission(currentRoleUser, roles.adminOrAbove);
  const isMobile = useMediaQuery('(max-width:720px)');
  const { name, members } = company;
  const limit = isMobile ? 1 : 7;
  const history = useHistory();

  const handleClickButtonMembers = () => {
    history.push(`/companies/${company._id}/settings/members`);
  };

  return (
    <div className={containerWithSidebarStyles[isSidebarOpen]}>
      <div className={styles.memberAndRole}>
        <ShowLimitMembers
          members={members || []}
          limit={limit}
          additionalPlusButton={members?.length >= limit}
          handleClickButton={handleClickButtonMembers}
          companyId={company._id}
          positionPopUpOnlineOnPhoto="topLeft"
          enableDiffClickOnExceededMember
          handleClickExceededMember={handleShowMemberOnlineModal}
          idAddButton={elementId.companyMemberToggleAddButton}
        />
        <div className={styles.role}>
          {
             company?._id
             && (
             <GlobalActionButton
               handleClick={handleShowModalRoleUser}
              //  text={isMobile ? '' : 'My Default Role'}
               text="Akses Default Saya"
               icon={<VerifiedUserIcon />}
             />
             )
          }
        </div>
        {isUserAdminOrAbove && (
        <div className={styles.title__archivedItems}>
          <GlobalActionButton
            handleClick={handleShowArchivedItems}
            buttonMode="outlined"
            // text="Archived Teams"
            text="Arsip Tim"
            icon={<ArchiveOutlinedIcon />}
          />
        </div>
        )}
        <LinkNoDecor to={`/companies/${company._id}/settings/edit`}>
          <GlobalActionButton
            // handleClick={handleShowArchivedItems}
            buttonMode="outlined"
            // text="Archived Teams"
            text="Pengaturan Perusahaan"
            icon={<SettingsOutlinedIcon />}
          />
        </LinkNoDecor>
      </div>
      <div className={styles.title}>
        {/* {isUserAdminOrAbove && (
        <div className={styles.title__archivedItems}>
          <GlobalActionButton
            handleClick={handleShowArchivedItems}
            buttonMode="outlined"
            // text="Archived Teams"
            text="Arsip Tim"
            icon={<ArchiveOutlinedIcon />}
          />
        </div>
        )}
        <LinkNoDecor to={`/companies/${company._id}/settings/edit`}>
          <div className={styles.title__settings}>
            <SettingsOutlinedIcon />
          </div>
        </LinkNoDecor> */}
        {/* <div className={styles.title__text}>
          <HeadlineSubNavBar text="Beranda" />
        </div> */}
        {/* <div className={styles.title__rounded}>
          <InputButtonRounded />
        </div> */}
      </div>
      <MyRoleUser
        open={showModalRoleUser}
        onClose={handleHideModalRoleUser}
        featureType={typeRef.company}
        // featureId={company?._id}
        featureTitle={company?.name}
        roleType={roleTypeRef.company}
      />
      {members && showMemberOnlineModal
            && (
            <MemberOnlineModal
              open={showMemberOnlineModal}
              onClose={handleHideMemberOnlineModal}
              members={members}
              title={name}
            />
            )}
    </div>
  );
};

HomeSubNavHeader.propTypes = {
  company: PropTypes.object.isRequired,
  handleShowArchivedItems: PropTypes.func.isRequired,
};

export default memo(HomeSubNavHeader);
