import React, { memo, useState } from 'react';
import PropTypes from 'prop-types';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import styles from './Visitor.module.css';
import { DisplayTextBody, DisplayTextSubHeadline } from '../../../../../components/UI_V2/Display/Text';
import Color from '../../../../../themes/colors';
import visitIllustration from '../../../../../assets/DashboardAdminPage/PirateMetricPage/1-visit-illustration.png';
import { getSingleOrPlural } from '../../../../../utilities/stringUtil';
import BoxText from '../BoxText/BoxText';
import PercentageText from '../PercentageText/PercentageText';

const Visitor = ({
  value,
  percentage,
  url,
}) => (
  <div className={styles.container}>
    <div className={styles.box}>
      <BoxText
        value={value}
        desc="Visitor"
        url={url}
      />
    </div>
    <div className={styles.visitIllustration}>
      <LazyLoadImage src={visitIllustration} />
    </div>
  </div>
);

Visitor.propTypes = {
  value: PropTypes.number,
  percentage: PropTypes.number,
  url: PropTypes.string.isRequired,
};

Visitor.defaultProps = {
  value: 0,
  percentage: 0,
};

export default memo(Visitor);
