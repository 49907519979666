import React, {
  memo, useCallback, useEffect, useState,
} from 'react';
import PropTypes from 'prop-types';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { useParams, useHistory, useLocation } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import styles from './ListActivity.module.css';
import Color from '../../../themes/colors';
import { ActivityConstants } from '../../../constants';
import { ActivityActions } from '../../../actions';
import { useInfinityScrollHooks } from '../../../hooks/InfinityScrollHooks';
import handleLoadings from '../../../utilities/handleLoadings';
import handleStatusMsg from '../../../utilities/handleStatusMsg';
import InfinityScroll from '../../../components/UI/InfinityScroll/InfinityScroll';
import { handleCompareDate } from '../../../utilities/dateUtil';
import DateSeparator from '../../CommentsSectionContainer/DateSeparator/DateSeparator';
import ActivityItem from './ActivityItem/ActivityItem';
import ListActivitySkeleton from './ListActivitySkeleton/ListActivitySkeleton';
import FeedbackLoadingMain from '../../../components/UI_V2/Feedback/Loading/Main/Main';

const ListActivity = ({
  filters,
  variant,
  params,
  company,
}) => {
  /* Initiate list data */
  const initialStatePreviousActivities = {
    data: new Array(ActivityConstants.limitActivity),
  };
  const initialStateCurrentActivities = {
    data: [],
  };
  const [previousActivities, setPreviousActivities] = useState(initialStatePreviousActivities);
  const [currentActivities, setCurrentActivities] = useState(initialStateCurrentActivities);
  const [isFirstLoad, setIsFirstLoad] = useState(true);
  const [loadings, setLoadings] = useState([]);

  // const params = useParams();
  const history = useHistory();
  const location = useLocation();
  const {
    companyId, teamId, chatId, cardId, commentId, groupChatId,
  } = params;

  const { enqueueSnackbar } = useSnackbar();

  const getMoreLists = async (filtersValue) => {
    const result = await ActivityActions.loadMoreActivities({
      filters: filtersValue,
      companyId,
      setCurrentActivities,
      setPreviousActivities,
      currentActivities,
    });

    return result;
  };

  const resetPreviousLists = useCallback(() => {
    setPreviousActivities(initialStatePreviousActivities);
    setCurrentActivities(initialStateCurrentActivities);
  }, []);

  const {
    lists,
    handleLoadMoreLists,
    checkIfListNeedToLoad,
    setCheckLoadMore,
    isListsLengthAtTheLimitOrAbove,
  } = useInfinityScrollHooks({
    currentObjectWithKeyProperty: currentActivities,
    previousLists: previousActivities?.data,
    resetPreviousLists,
    getMoreLists: () => getMoreLists(filters),
    keyProperty: 'data',
    limitList: ActivityConstants.limitActivity,
  });

  const initiateActivitiesApi = async (filtersValue) => {
    try {
      const startLoadings = handleLoadings('initiateActivities', [...loadings], 'start');
      setLoadings([...startLoadings]);

      const result = await ActivityActions.initiateActivities({
        companyId,
        filters: filtersValue,
        setCurrentActivities,
        setPreviousActivities,
        initialStatePreviousActivities,
      });

      if (isListsLengthAtTheLimitOrAbove(result?.data?.data)) {
        setCheckLoadMore(true);
      } else {
        setCheckLoadMore(false);
      }

      if (isFirstLoad) {
        setIsFirstLoad(false);
      }
    } catch (err) {
      const status = handleStatusMsg(err, 'error');

      enqueueSnackbar(status.message, {
        variant: 'error',
      });
    } finally {
      const endLoadings = handleLoadings('initiateActivities', [...loadings], 'end');
      setLoadings([...endLoadings]);
    }
  };

  useEffect(() => {
    initiateActivitiesApi(filters);
  }, [filters]);

  return (
    <div className={styles.container}>
      <FeedbackLoadingMain
        loadingComponent={<ListActivitySkeleton />}
        wait="initiateActivities"
        loadings={loadings}
      >
        <InfinityScroll
          scrollableTarget="listActivities"
          dataLength={lists?.length || 0}
          hasMore={checkIfListNeedToLoad()}
          next={handleLoadMoreLists}
          emptyMessage="Belum ada aktifitas apapun disini..."
        >
          {lists.map((item, index) => {
            const isSameDate = handleCompareDate(lists, item, (index - 1), 'createdAt');

            if (!isSameDate) {
              return (
                <>
                  <DateSeparator date={item.createdAt} />
                  <div className={styles.item}>
                    <ActivityItem
                      item={item}
                      company={company}
                      variant="regular"
                    />
                  </div>
                </>
              );
            }
            return (
              <div className={styles.item}>
                <ActivityItem
                  item={item}
                  company={company}
                  variant="regular"
                />
              </div>
            );
          })}
        </InfinityScroll>
      </FeedbackLoadingMain>
    </div>
  );
};

ListActivity.propTypes = {
  filters: PropTypes.object,
  variant: PropTypes.oneOf(['byModuleId', 'allModules']),
  params: PropTypes.object.isRequired,
  company: PropTypes.object.isRequired,
};

ListActivity.defaultProps = {
  filters: {},
  variant: 'allModules',
};

export default memo(ListActivity);
