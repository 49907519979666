const handleStatusMsg = (payload, type) => {
  switch (type) {
    case 'success':
      if (payload?.data?.message) {
        return {
          message: payload.data.message,
          isFromAPI: true,
        };
      }

      if (payload?.message) {
        return {
          message: payload.message,
          isFromAPI: true,
        };
      }

      return {
        message: 'Success!',
        isFromAPI: false,
      };

    case 'error':
      if (payload?.message === 'Request failed with status code 429') {
        return {
          message: 'Too many same action from this IP, please try again after an hour',
          isFromAPI: true,
        };
      }
      if (payload?.response?.data?.message) {
        return {
          message: payload.response.data.message,
          isFromAPI: true,
        };
      }

      if (payload?.message) {
        return {
          message: payload.message,
          isFromAPI: false,
        };
      }

      return {
        message: 'Something went wrong!',
        isFromAPI: false,
      };

    default:
      return {};
  }
};

export default handleStatusMsg;
