import React, { useState, useEffect } from 'react';
import CloseIcon from '@mui/icons-material/Close';
import { useSnackbar } from 'notistack';
import { useParams } from 'react-router-dom';
import Form from 'react-bootstrap/Form';
import PropTypes from 'prop-types';
import BoxPopOver from '../../../../components/UI/BoxPopOver/BoxPopOver';
import SeparatorLine from '../../../../components/UI/SeparatorLine/SeparatorLine';
import styles from './EditPopUp.module.css';
import Button from '../../../../components/UI/Button/Button';
import handleStatusMsg from '../../../../utilities/handleStatusMsg';
import handleLoadings from '../../../../utilities/handleLoadings';
import apiUtil from '../../../../utilities/apiUtil';

const EditPopUp = ({ attachment, handleShowEditPopUp }) => {
  const [newAttachment, setNewAttachment] = useState({ value: attachment.name });
  const [editedAttachment, setEditedAttachment] = useState();
  const { enqueueSnackbar } = useSnackbar();
  const [loadings, setLoadings] = useState([]);

  const params = useParams();
  const { companyId, teamId, cardId } = params;

  const handleCancel = () => {
    handleShowEditPopUp('');
  };

  const handleEdit = () => {
    if (newAttachment === undefined
      || newAttachment?.value === undefined
      || newAttachment?.value.length < 1) {
      enqueueSnackbar('Attachment name cannot be empty!', {
        variant: 'error',
      });
      return;
    }

    setEditedAttachment(newAttachment);

    if (newAttachment.value === attachment.name) {
      handleCancel();
    }
  };

  const handleChanged = (event) => {
    const { value } = event.target;
    setNewAttachment((prevValue) => ({
      ...prevValue,
      value,
    }));
  };

  useEffect(() => {
    if (editedAttachment === undefined) {
      return;
    }

    const editApiAttachment = async () => {
      const attachmentId = attachment._id;

      try {
        const startLoadings = handleLoadings('editAttachment', [...loadings], 'start');
        setLoadings([...startLoadings]);

        const result = await apiUtil.patch(`/api/v1/cards/${cardId}/attachments/${attachmentId}`, { name: newAttachment.value }, {
          params: {
            companyId,
            teamId,
          },
        });

        const status = handleStatusMsg(result, 'success');

        enqueueSnackbar(status.message, {
          variant: 'success',
        });

        // close this container after finished
        handleCancel();
      } catch (err) {
        const status = handleStatusMsg(err, 'error');

        enqueueSnackbar(status.message, {
          variant: 'error',
        });
      } finally {
        const endLoadings = handleLoadings('editAttachment', [...loadings], 'end');
        setLoadings([...endLoadings]);
      }
    };
    editApiAttachment();
  }, [editedAttachment]);

  return (
    <BoxPopOver>
      <div className={styles.headerSection}>
        <h1>
          Ubah Lampiran
        </h1>
        <CloseIcon onClick={handleCancel} />
      </div>
      <SeparatorLine />
      <div className={styles.bodySection}>
        <p>Nama Lampiran</p>
      </div>
      <div className={styles.formSection}>
        <Form>
          <Form.Control onChange={(event) => handleChanged(event)} as="input" placeholder="Ketik nama lampiran..." value={newAttachment.value} />
        </Form>
      </div>
      <div className={styles.actionSection}>
        <Button handleClick={handleEdit} variant="success" size="sm" wait="editAttachment" loadings={loadings}>Simpan</Button>
      </div>
    </BoxPopOver>
  );
};

EditPopUp.propTypes = {
  attachment: PropTypes.object.isRequired,
  handleShowEditPopUp: PropTypes.func.isRequired,
};

export default EditPopUp;
