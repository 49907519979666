import React, { memo, useState } from 'react';
import PropTypes from 'prop-types';
import { Box } from '@mui/material';
import CheckIcon from '@mui/icons-material/Check';
import { isEmpty } from 'lodash';
import styles from './DueDateFilterSection.module.css';
import ButtonDiv from '../../../UI/Button/ButtonDiv/ButtonDiv';
import ButtonTextWithCheck from '../ButtonTextWithCheck/ButtonTextWithCheck';
import { FilterBarConstants } from '../../../../constants';
import { DisplayTextBody } from '../../../UI_V2/Display/Text';

const DueDateFilterSection = ({ checkDueDate, handleClickDueDate }) => {
  const isBacklog = checkDueDate === FilterBarConstants.subCategoryType.BACKLOG;
  const isOngoing = checkDueDate === FilterBarConstants.subCategoryType.ONGOING;
  const isOverdue = checkDueDate === FilterBarConstants.subCategoryType.OVERDUE;
  const isCompleted = checkDueDate === FilterBarConstants.subCategoryType.COMPLETED;
  const isAll = checkDueDate === FilterBarConstants.subCategoryType.ALL;
  return (
    <Box className={styles.container}>
      <Box className={styles.titleBox}>
        <DisplayTextBody>
          Filter Berdasarkan Status
        </DisplayTextBody>
      </Box>
      <ButtonTextWithCheck
        text={FilterBarConstants.subCategoryTitle.BACKLOG}
        onClick={() => handleClickDueDate(
          isBacklog ? undefined : FilterBarConstants.subCategoryType.BACKLOG,
        )}
        checked={isBacklog}
      />
      <ButtonTextWithCheck
        text={FilterBarConstants.subCategoryTitle.ONGOING}
        onClick={() => handleClickDueDate(
          isOngoing ? undefined : FilterBarConstants.subCategoryType.ONGOING,
        )}
        checked={isOngoing}
      />
      <ButtonTextWithCheck
        text={FilterBarConstants.subCategoryTitle.OVERDUE}
        onClick={() => handleClickDueDate(
          isOverdue ? undefined : FilterBarConstants.subCategoryType.OVERDUE,
        )}
        checked={isOverdue}
      />
      <ButtonTextWithCheck
        text={FilterBarConstants.subCategoryTitle.COMPLETED}
        onClick={() => handleClickDueDate(
          isCompleted ? undefined : FilterBarConstants.subCategoryType.COMPLETED,
        )}
        checked={isCompleted}
      />
      <ButtonTextWithCheck
        text={FilterBarConstants.subCategoryTitle.ALL}
        onClick={() => handleClickDueDate(
          isAll ? undefined : FilterBarConstants.subCategoryType.ALL,
        )}
        checked={isAll}
      />
    </Box>
  );
};

DueDateFilterSection.propTypes = {
  checkDueDate: PropTypes.object.isRequired,
  handleClickDueDate: PropTypes.func.isRequired,
};

export default memo(DueDateFilterSection);
