import { cloneDeep } from 'lodash';
import { actionTypes } from '../reducers/reducer';
import { ApiConstants, CommentConstants } from '../constants';
import apiUtil from '../utilities/apiUtil';
import {
  getLastListCreatedAt,
  mergeObjectListAndRemoveDuplicate,
  updateListProperty,
} from '../utilities/arrayUtil';
import { BlastActions, TeamActions, CommentActions } from '.';
import { ErrorException } from '../utilities/handleError';

/*
  Dispatcher
*/

function dispatchCurrentBlastPost({ currentBlastPost }, dispatch) {
  dispatch({
    type: actionTypes.SET_CURRENT_BLAST_POST,
    currentBlastPost,
  });
}

function dispatchCurrentPostComment({ currentPostComment }, dispatch) {
  dispatch({
    type: actionTypes.SET_CURRENT_POST_COMMENT,
    currentPostComment,
  });
}

function dispatchPreviousBlastComment({ previousBlastComment }, dispatch) {
  dispatch({
    type: actionTypes.SET_PREVIOUS_BLAST_COMMENT,
    previousBlastComment,
  });
}

function dispatchUpdateCurrentBlastPost({ updateCurrentBlastPost }, dispatch) {
  dispatch({
    type: actionTypes.UPDATE_CURRENT_BLAST_POST,
    updateCurrentBlastPost,
  });
}

/*
  SetterDispatcher
*/

function setCurrentBlastPost({ currentBlastPost }, dispatch) {
  if (!currentBlastPost || currentBlastPost?.comments === undefined) return;

  dispatchCurrentBlastPost(
    { currentBlastPost: cloneDeep(currentBlastPost) }, dispatch,
  );
}

function setCurrentPostComment({ currentPostComment }, dispatch) {
  if (!currentPostComment) return;

  dispatchCurrentPostComment(
    { currentPostComment: cloneDeep(currentPostComment) }, dispatch,
  );
}

function setPreviousBlastComment({ previousBlastComment }, dispatch) {
  if (!previousBlastComment) return;

  dispatchPreviousBlastComment(
    { previousBlastComment: cloneDeep(previousBlastComment) }, dispatch,
  );
}

/*
  Helpers
*/

function modifyAndSetCurrentBlastPost({ result, blastId }, dispatch) {
  setCurrentBlastPost({
    currentBlastPost: {
      ...result?.data?.blast,
      comments: null,
    },
  }, dispatch);

  BlastActions.modifyAndSetCurrentBlast({ result, blastId: result?.data?.blast.blast }, dispatch);
}

function modifyResponseLoadComment({ result, previousPost }) {
  return {
    ...previousPost,
    comments: result?.data?.comments,
  };
}

/*
  Method
*/

function incomingPost({ post }, dispatch) {
  if (!post) return;

  const updateCurrentBlastPost = (currentBlastPost) => {
    let newCurrentBlastPost = {
      ...post,
      comments: currentBlastPost.comments,
      cheers: currentBlastPost.cheers,
    };
    newCurrentBlastPost = cloneDeep(newCurrentBlastPost);

    return newCurrentBlastPost;
  };

  dispatchUpdateCurrentBlastPost({ updateCurrentBlastPost }, dispatch);
}

function incomingPostComment({
  comment, typeAction, updateCommentCheer, keyProperty = 'comments',
}, dispatch) {
  if (!comment) return;

  const updateCurrentBlastPost = (currentBlastPost) => updateListProperty({
    keyProperty,
    newData: comment,
    currentList: currentBlastPost,
    typeAction,
    actionEdit: updateCommentCheer,
  });

  dispatchUpdateCurrentBlastPost({ updateCurrentBlastPost }, dispatch);
}

async function initiatePost({
  blastId, postId, companyId, teamId,
}, dispatch) {
  try {
    const result = await apiUtil.get(ApiConstants.URL_V2.POST_DETAIL({ blastId, postId }), {
      params: {
        companyId,
        teamId,
      },
    });

    modifyAndSetCurrentBlastPost({ result }, dispatch);
    // TeamActions.saveCurrentTeam({ currentTeam: result?.data?.currentTeam }, dispatch);

    return result;
  } catch (error) {
    throw new ErrorException(error);
  }
}

async function initiatePostComment({
  postId, teamId, companyId, limit,
}, dispatch) {
  try {
    const result = await apiUtil.get(ApiConstants.URL_V2.POST_COMMENT({ postId }), {
      params: {
        limit,
        companyId,
        teamId,
      },
    });

    setCurrentPostComment({ currentPostComment: result?.data?.comments }, dispatch);

    return CommentActions.sliceResultDataforLastCommentNearLimitComment(result);
  } catch (error) {
    throw new ErrorException(error);
  }
}

async function loadMorePostComment({
  teamId, companyId, postId, currentBlastPost,
}, dispatch) {
  try {
    const result = await apiUtil.get(ApiConstants.URL_V2.POST_COMMENT({ postId }), {
      params: {
        limit: CommentConstants.limitComment,
        createdAt: getLastListCreatedAt(currentBlastPost.comments),
        companyId,
        teamId,
      },
    });

    const modifiedResult = modifyResponseLoadComment(
      { result, previousPost: currentBlastPost },
    );
    const mergedPost = mergeObjectListAndRemoveDuplicate({
      currentObjectList: currentBlastPost,
      nextObjectList: modifiedResult,
      keyObject: 'comments',
    });

    setPreviousBlastComment({ previousBlastComment: modifiedResult?.comments }, dispatch);
    setCurrentBlastPost({ currentBlastPost: mergedPost }, dispatch);

    return result;
  } catch (error) {
    throw new ErrorException(error);
  }
}

async function toggleCompletePost({
  postId, isComplete, companyId, teamId,
}) {
  try {
    const result = await apiUtil.patch(
      `${ApiConstants.URL_V1.POST_DETAIL({ postId })}/complete`,
      {
        isComplete,
      },
      {
        params: {
          companyId,
          teamId,
        },
      },
    );

    return result;
  } catch (error) {
    throw new ErrorException(error);
  }
}

export {
  initiatePost,
  initiatePostComment,
  loadMorePostComment,
  incomingPost,
  incomingPostComment,
  setPreviousBlastComment,
  setCurrentBlastPost,
  modifyAndSetCurrentBlastPost,
  dispatchUpdateCurrentBlastPost,
  toggleCompletePost,
};
