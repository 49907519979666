import React, { memo, useState } from 'react';
import PropTypes from 'prop-types';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import styles from './BottleneckItem.module.css';
import Color from '../../../../../themes/colors';
import RatioBottleneck from './RatioBottleneck/RatioBottleneck';
import CardBottleneck from './CardBottleneck/CardBottleneck';

const BottleneckItem = ({
  titleRatio,
  contentRatio,
  titleCard,
  card,
}) => (
  <div className={styles.container}>
    <div className={styles.ratio}>
      <RatioBottleneck
        content={contentRatio}
        title={titleRatio}
      />
    </div>
    <div className={styles.card}>
      <CardBottleneck
        title={titleCard}
        card={card}
      />
    </div>
  </div>
);

BottleneckItem.propTypes = {
  titleRatio: PropTypes.string.isRequired,
  contentRatio: PropTypes.string.isRequired,
  titleCard: PropTypes.string.isRequired,
  card: PropTypes.object.isRequired,
};

BottleneckItem.defaultProps = {};

export default memo(BottleneckItem);
