import React, { memo, useState } from 'react';
import PropTypes from 'prop-types';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import PendingIcon from '@mui/icons-material/Pending';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { useMediaQuery } from '@mui/material';
import styles from './LoadingItem.module.css';
import { limitChar } from '../../../../../../utilities/stringUtil';
import { DisplayTextBody } from '../../../../Display/Text';
import Color from '../../../../../../themes/colors';
import { FeedbackSpinnerGeneral } from '../../../Spinner';

const LoadingItem = ({
  isProgressUploadFinish,
  isNotYetUploaded,
  progressUpload,
  file,
  backgroundColor,
  height,
}) => (
  <div
    className={styles.loadingContainer}
    style={{
      backgroundColor,
      height,
    }}
  >
    <div className={styles.fileName}>
      <DisplayTextBody>
        {limitChar(file.name, 47)}
      </DisplayTextBody>
    </div>
    {!isNotYetUploaded && !isProgressUploadFinish && (
      <div className={styles.spinner}>
        <FeedbackSpinnerGeneral
          value={progressUpload[file._id]}
          size={30}
          color={Color.yellowAccentCicle}
        />
      </div>
    )}
    {isProgressUploadFinish && (
      <div className={styles.iconSuccess}>
        <CheckCircleOutlineIcon />
      </div>
    )}
    {isNotYetUploaded && (
      <div className={styles.iconPending}>
        <PendingIcon />
      </div>
    )}
  </div>
);

LoadingItem.propTypes = {
  isProgressUploadFinish: PropTypes.bool,
  isNotYetUploaded: PropTypes.bool,
  file: PropTypes.object,
  progressUpload: PropTypes.object,
  backgroundColor: PropTypes.string,
  height: PropTypes.string,
};

LoadingItem.defaultProps = {
  file: {},
  progressUpload: {},
  isNotYetUploaded: false,
  isProgressUploadFinish: false,
  backgroundColor: Color.gray6,
  height: '40px',
};

export default memo(LoadingItem);
