import axios from 'axios';
import { cloneDeep, isEmpty } from 'lodash';
import { actionTypes } from '../reducers/reducer';
import { CompanyActions, TeamActions, UserActions } from '.';
import handleStatusMsg from '../utilities/handleStatusMsg';
import apiUtil from '../utilities/apiUtil';
import { ErrorException } from '../utilities/handleError';
import { DEFAULT_OPTION } from '../constants/ApiConstants';
import { ApiConstants, TeamConstants } from '../constants';
import { getSessionCurrentTeam, setSessionCurrentTeam } from '../utilities/sessionStorage';
import { getStoreCurrentTeam, setStoreCurrentTeam } from '../utilities/localStorage';
import { updateListSocket } from '../utilities/arrayUtil';

/*
  Dispatcher
*/

function dispatchCurrentTeam({ currentTeam }, dispatch) {
  dispatch({
    type: actionTypes.SET_CURRENT_TEAM,
    currentTeam,
  });
}

function dispatchCurrentTeamBelowRole({ currentTeamBelowRole }, dispatch) {
  dispatch({
    type: actionTypes.SET_CURRENT_TEAM_BELOW_ROLE,
    currentTeamBelowRole,
  });
}

function dispatchCurrentTeamMembers({ currentTeamMembers }, dispatch) {
  dispatch({
    type: actionTypes.SET_CURRENT_TEAM_MEMBERS,
    currentTeamMembers,
  });
}

function dispatchCurrentTeamBoards({ currentTeamBoards }, dispatch) {
  dispatch({
    type: actionTypes.SET_CURRENT_TEAM_BOARDS,
    currentTeamBoards,
  });
}

function dispatchTeams({ teams }, dispatch) {
  dispatch({
    type: actionTypes.SET_TEAMS,
    teams,
  });
}

function dispatchSortedTeams({ sortedTeams }, dispatch) {
  dispatch({
    type: actionTypes.SET_SORTED_TEAMS,
    sortedTeams,
  });
}

function dispatchUpdateTeams({ updateTeams }, dispatch) {
  dispatch({
    type: actionTypes.UPDATE_TEAMS,
    updateTeams,
  });
}

function dispatchUpdateCurrentTeam({ updateCurrentTeam }, dispatch) {
  dispatch({
    type: actionTypes.UPDATE_CURRENT_TEAM,
    updateCurrentTeam,
  });
}

function dispatchUpdateCurrentTeamBelowRole({ updateCurrentTeamBelowRole }, dispatch) {
  dispatch({
    type: actionTypes.UPDATE_CURRENT_TEAM_BELOW_ROLE,
    updateCurrentTeamBelowRole,
  });
}

/*
  SetterDispatcher
*/

function setCurrentTeam({ currentTeam }, dispatch) {
  if (!currentTeam) return;

  dispatchCurrentTeam({ currentTeam: cloneDeep(currentTeam) }, dispatch);
}

function setCurrentTeamBelowRole({ currentTeamBelowRole }, dispatch) {
  if (!currentTeamBelowRole) return;

  dispatchCurrentTeamBelowRole({ currentTeamBelowRole: cloneDeep(currentTeamBelowRole) }, dispatch);
}

function setCurrentTeamMembers({ currentTeamMembers }, dispatch) {
  if (!currentTeamMembers) return;

  dispatchCurrentTeamMembers({ currentTeamMembers: cloneDeep(currentTeamMembers) }, dispatch);
}

function setCurrentTeamBoards({ currentTeamBoards }, dispatch) {
  if (!currentTeamBoards) return;

  dispatchCurrentTeamBoards({ currentTeamBoards: cloneDeep(currentTeamBoards) }, dispatch);
}

const setTeams = ({ teams }, dispatch) => {
  if (!teams) return;

  dispatchTeams({ teams: cloneDeep(teams) }, dispatch);
};

const setSortedTeams = ({ sortedTeams }, dispatch) => {
  if (!sortedTeams) return;

  dispatchSortedTeams({ sortedTeams: cloneDeep(sortedTeams) }, dispatch);
};

/*
  Method
*/

const initiateTeams = ({ currentCompany, userId }, dispatch) => {
  try {
    let teams = [];

    if (!currentCompany || !userId) return null;
    const userAdmin = currentCompany?.admins?.find(
      (user) => user?._id === userId || user === userId,
    );
    if (userAdmin) {
      teams = [...currentCompany?.teams];
    } else {
      teams = currentCompany?.teams?.filter(
        (team) => team?.members?.some((member) => member._id === userId),
      );
    }

    setTeams({ teams }, dispatch);
    return teams;
  } catch (error) {
    throw new ErrorException(error);
  }
};

const saveCurrentTeam = ({ currentTeam, members }, dispatch) => {
  try {
    if (members && currentTeam && !currentTeam?.members) {
      const newTeam = {
        ...currentTeam,
        members,
      };
      setCurrentTeam({ currentTeam: newTeam }, dispatch);
      setSessionCurrentTeam(newTeam);
      setStoreCurrentTeam(newTeam);
    }

    if (currentTeam && currentTeam?.members && !members) {
      setCurrentTeam({ currentTeam }, dispatch);
      setSessionCurrentTeam(currentTeam);
      setStoreCurrentTeam(currentTeam);
    }

    if (currentTeam && !currentTeam?.members && !members) {
      setCurrentTeam({ currentTeam }, dispatch);
      setSessionCurrentTeam(currentTeam);
      setStoreCurrentTeam(currentTeam);
    }
  } catch (error) {
    throw new ErrorException(error);
  }
};

async function initiateCurrentTeam({ teamId, companyId }, dispatch) {
  try {
    // VERSION WITH LOCAL AND SESSION, BUT NEED ADDITIONAL SOCKET
    // let currentTeam = getSessionCurrentTeam();
    // if (!currentTeam) currentTeam = getStoreCurrentTeam();
    // let result;
    // if (!currentTeam || currentTeam?._id !== teamId) {
    //   result = await apiUtil.get(ApiConstants.URL_V1.TEAM({ teamId }), {
    //     params: {
    //       withOccurrences: 1,
    //     },
    //   });
    //   currentTeam = result?.data?.currentTeam;
    // }
    const result = await apiUtil.get(ApiConstants.URL_V1.TEAM({ teamId }), {
      params: {
        withOccurrences: 1,
        companyId,
      },
    });
    const currentTeam = result?.data?.currentTeam;

    saveCurrentTeam({ currentTeam }, dispatch);

    return result;
  } catch (error) {
    throw new ErrorException(error);
  }
}

async function getCurrentTeamMembers({ teamId, companyId }) {
  try {
    const result = await apiUtil.get(ApiConstants.URL_V2.TEAM_MEMBERS({ teamId }), {
      params: {
        companyId,
      },
    });

    return result?.data?.members;
  } catch (error) {
    throw new ErrorException(error);
  }
}

async function getCurrentTeamMembersBelowRole({ teamId, companyId, minimumRole }) {
  try {
    const result = await apiUtil.get(ApiConstants.URL_V2.TEAM_MEMBERS_ROLE({ teamId }), {
      params: {
        companyId,
        minimumRole,
      },
    });

    return result?.data?.members;
  } catch (error) {
    throw new ErrorException(error);
  }
}

const initiateTeamBelowRole = async ({
  teamId,
  companyId,
  currentTeamBelowRole,
  minimumRole,
  currentTeam,
}, dispatch) => {
  try {
    if (currentTeamBelowRole._id === teamId) return currentTeamBelowRole;
    const members = await getCurrentTeamMembersBelowRole({ teamId, companyId, minimumRole });
    const result = {
      ...currentTeam,
      members,
    };
    setCurrentTeamBelowRole({ currentTeamBelowRole: result }, dispatch);

    return result;
  } catch (error) {
    throw new ErrorException(error);
  }
};

async function initiateTeam({ teamId, companyId, currentTeam }, dispatch) {
  try {
    if (currentTeam._id === teamId) return currentTeam;
    const result = await apiUtil.get(ApiConstants.URL_V2.TEAM_NAME({ teamId }), {
      params: {
        companyId,
      },
    });
    const team = result?.data?.team;

    saveCurrentTeam({ currentTeam: team }, dispatch);

    return result;
  } catch (error) {
    throw new ErrorException(error);
  }
}

async function initiateTeamMembers({ teamId, companyId, currentTeam }, dispatch) {
  try {
    if (!currentTeam?._id) return currentTeam;
    if (currentTeam._id === teamId
      && currentTeam.members && currentTeam.members?.length > 0) return currentTeam;

    const members = await getCurrentTeamMembers({ teamId, companyId });
    saveCurrentTeam({ currentTeam, members }, dispatch);

    return members;
  } catch (error) {
    throw new ErrorException(error);
  }
}

async function getCurrentTeamOverviewId({ teamId, companyId }) {
  try {
    const result = await apiUtil.get(ApiConstants.URL_V2.TEAM_OVERVIEW_ID({ teamId }), {
      params: {
        companyId,
      },
    });

    return result?.data?.id;
  } catch (error) {
    throw new ErrorException(error);
  }
}

const createTeamAndUpdateTeamsThenUpdateCompanyAndCompanies = async ({
  payload, companyId, user, teams, currentCompany,
}, dispatch) => {
  try {
    const result = await apiUtil.post(
      ApiConstants.URL_V1.TEAMS(),
      payload,
      {
        params: {
          companyId,
        },
      },
    );

    const newDataTeam = { ...result.data.newTeam, members: [user] };
    const newTeams = updateListSocket({
      newData: newDataTeam,
      currentList: teams,
      typeAction: TeamConstants.typeCallback.NEW,
      reverse: false,
    }, dispatch);

    setTeams({ teams: newTeams }, dispatch);

    CompanyActions.updateCompanyByKeyObjectThenUpdateCompanies({
      data: newTeams,
      keyObjectProperty: 'teams',
      currentCompany,
      userId: user?._id,
    }, dispatch);
  } catch (error) {
    throw new ErrorException(error);
  }
};

async function updateTeamThenUpdateCompanyAndCompanies({
  teamId,
  body,
  teams,
  currentCompany,
  userId,
}, dispatch) {
  try {
    const result = await apiUtil.patch(`/api/v1/teams/${teamId}`, body, {
      params: {
        companyId: currentCompany._id,
      },
    });

    saveCurrentTeam({ currentTeam: result?.data?.currentTeam }, dispatch);

    const newTeams = updateListSocket({
      newData: result?.data?.currentTeam,
      currentList: teams,
      typeAction: TeamConstants.typeCallback.EDIT,
    }, dispatch);
    CompanyActions.updateCompanyByKeyObjectThenUpdateCompanies({
      data: newTeams,
      keyObjectProperty: 'teams',
      currentCompany,
      userId,
    }, dispatch);
    return result;
  } catch (error) {
    throw new ErrorException(error);
  }
}

async function archiveTeamThenUpdateCompanyAndCompanies({
  teamId,
  teams,
  userId,
  currentCompany,
  currentTeam,
}, dispatch) {
  try {
    const result = await apiUtil.patch(`/api/v1/teams/${teamId}/archived`, {}, {
      params: {
        companyId: currentCompany._id,
      },
    });

    let team;

    if (currentTeam) {
      team = {
        ...currentTeam,
        archived: result?.data?.currentTeam?.archived,
      };
    } else {
      team = result?.data?.currentTeam;
    }

    saveCurrentTeam({ currentTeam: team }, dispatch);

    const newTeams = updateListSocket({
      newData: team,
      currentList: teams,
      typeAction: TeamConstants.typeCallback.DELETE,
    }, dispatch);

    CompanyActions.updateCompanyByKeyObjectThenUpdateCompanies({
      data: newTeams,
      keyObjectProperty: 'teams',
      currentCompany,
      userId,
    }, dispatch);
  } catch (error) {
    throw new ErrorException(error);
  }
}

async function getListTeams({
  page = 1,
  limit = TeamConstants.limitTeams,
  companyId,
  filters,
}) {
  try {
    let params = {
      page,
      limit,
      companyId,
      'filter[archived]': true,
    };

    if (filters?.filterTitle) {
      params = {
        ...params,
        'filter[name]': filters.filterTitle,
      };
    }

    const result = await apiUtil.get(ApiConstants.URL_V2.TEAMS(), {
      params,
    });

    return result;
  } catch (error) {
    throw new ErrorException(error);
  }
}

async function unarchiveTeamAndUpdateCompany({
  teamId,
  companyId,
  teams,
  currentCompany,
  userId,
}, dispatch) {
  try {
    const result = await apiUtil.patch(ApiConstants.URL_V1.TEAM_UNARCHIVE({ teamId }), {}, {
      params: {
        companyId,
      },
    });

    const newTeams = updateListSocket({
      newData: result?.data?.team,
      currentList: teams,
      typeAction: TeamConstants.typeCallback.NEW,
    }, dispatch);

    CompanyActions.updateCompanyByKeyObjectThenUpdateCompanies({
      data: newTeams,
      keyObjectProperty: 'teams',
      currentCompany,
      userId,
    }, dispatch);
  } catch (error) {
    throw new ErrorException(error);
  }
}

/*
  Socket Callback Stuffs
*/

const incomingMemberUpdate = ({ user, typeAction }, dispatch) => {
  if (!user) return;
  const updateCurrentTeam = (currentTeam) => {
    const newMembers = updateListSocket({
      newData: user,
      currentList: currentTeam?.members,
      typeAction,
      reverse: false,
    });
    const newTeam = {
      ...currentTeam,
      members: newMembers,
    };

    return cloneDeep(newTeam);
  };

  dispatchUpdateCurrentTeam({ updateCurrentTeam }, dispatch);
};

const incomingOverviewUpdate = ({ item, typeAction, typeOverview }, dispatch) => {
  // not handled yet;
  // if (!item) return;
  // const updateCurrentTeam = (currentTeam) => {
  //   const newMembers = updateListSocket({
  //     newData: user,
  //     currentList: currentTeam?.members,
  //     typeAction,
  //     reverse: false,
  //   });
  //   const newTeam = {
  //     ...currentTeam,
  //     members: newMembers,
  //   };

  //   return cloneDeep(newTeam);
  // };

  // dispatchUpdateCurrentTeam({ updateCurrentTeam }, dispatch);
};

const resetTeamAndTeamMembers = ({}, dispatch) => {
  setCurrentTeam({ currentTeam: {} }, dispatch);
  setCurrentTeamMembers({ currentTeamMembers: [] }, dispatch);
};

export {
  setCurrentTeam,
  setCurrentTeamMembers,
  setCurrentTeamBoards,
  initiateCurrentTeam,
  initiateTeams,
  createTeamAndUpdateTeamsThenUpdateCompanyAndCompanies,
  setTeams,
  updateTeamThenUpdateCompanyAndCompanies,
  archiveTeamThenUpdateCompanyAndCompanies,
  unarchiveTeamAndUpdateCompany,
  saveCurrentTeam,
  getCurrentTeamMembers,
  incomingMemberUpdate,
  incomingOverviewUpdate,
  getCurrentTeamOverviewId,
  getListTeams,
  initiateTeam,
  initiateTeamMembers,
  getCurrentTeamMembersBelowRole,
  initiateTeamBelowRole,
  resetTeamAndTeamMembers,
  dispatchUpdateTeams,
  setSortedTeams,
};
