import React, { memo } from 'react';
import { ModalLink } from 'react-router-modal-gallery';
import PropTypes from 'prop-types';
import styles from './ModalLinkNoDecor.module.css';

const ModalLinkNoDecor = ({ to, children, overlay }) => (
  <>
    {overlay
      ? (
        <ModalLink className={`${styles.ModalLink} ${styles.overlay}`} to={to}>
          {children}
        </ModalLink>
      )
      : (
        <ModalLink className={styles.ModalLink} to={to}>
          {children}
        </ModalLink>
      )}
  </>
);

ModalLinkNoDecor.propTypes = {
  children: PropTypes.node.isRequired,
  to: PropTypes.string.isRequired,
  overlay: PropTypes.bool,
};

ModalLinkNoDecor.defaultProps = {
  overlay: false,
};

const isComponentDataEqual = (prevProps, nextProps) => {
  const { to, overlay } = nextProps;
  return prevProps.to === to
    && prevProps.overlay === overlay;
};

export default memo(ModalLinkNoDecor, isComponentDataEqual);
