import React, {
  useEffect,
  useState, memo, useContext,
} from 'react';
import { useParams } from 'react-router-dom';
import PropTypes from 'prop-types';
import { useSnackbar } from 'notistack';
import OutsideClickHandler from 'react-outside-click-handler';
import styles from './OverviewOptionPopUp.module.css';
import { NavHeaderConstants } from '../../../../../constants';
import Color from '../../../../../themes/colors';
import handleStatusMsg from '../../../../../utilities/handleStatusMsg';
import handleLoadings from '../../../../../utilities/handleLoadings';
import { DisplayTextBody } from '../../../../UI_V2/Display/Text';
import LinkNoDecor from '../../../../LinkNoDecor/LinkNoDecor';
import { TeamActions } from '../../../../../actions';
import OverlayButton from '../../../../UI/Button/OverlayButton/OverlayButton';
import { GlobalContext } from '../../../../../contexts/GlobalStateProvider';

const OverviewOptionPopUp = ({
  defaultMenuId, handleCancel, currentTeam,
}) => {
  const [{ user }] = useContext(GlobalContext);
  const [id, setId] = useState({});
  const [menus, setMenus] = useState([]);
  const { enqueueSnackbar } = useSnackbar();
  const [loadings, setLoadings] = useState([]);
  const params = useParams();
  const { companyId, teamId } = params;

  useEffect(() => {
    if (currentTeam?.blast
      && currentTeam?.boards?.[0]
      && currentTeam?.checkIn
      && currentTeam?.schedule
      && currentTeam?.groupChat
      && currentTeam?.bucket
    ) return;

    const fetchOverviewIds = async () => {
      try {
        const startLoadings = handleLoadings('overviewOption', [...loadings], 'start');
        setLoadings([...startLoadings]);
        const result = await TeamActions.getCurrentTeamOverviewId({ teamId, companyId });
        setId(result);
      } catch (error) {
        const status = handleStatusMsg(error, 'error');

        enqueueSnackbar(status.message, {
          variant: 'error',
        });
      } finally {
        const endLoadings = handleLoadings('overviewOption', [...loadings], 'end');
        setLoadings([...endLoadings]);
      }
    };
    fetchOverviewIds();
  }, []);

  useEffect(() => {
    const newMenus = NavHeaderConstants.menus.map((menu) => {
      let urlId = '';
      let firstUrl = `/companies/${companyId}/teams/${teamId}`;
      let customMenuUrl;
      switch (menu._id) {
        case 'teams':
          urlId = currentTeam?._id;
          firstUrl = `/companies/${companyId}`;
          break;
        case 'blasts':
          urlId = currentTeam?.blast?._id || currentTeam?.blast || id?.blast;
          break;
        case 'boards':
          urlId = `${currentTeam?.boards?.[0]?._id || currentTeam?.boards?.[0] || id?.boards?.[0]}/kanban/all`;
          break;
        case 'boardsList':
          customMenuUrl = 'boards';
          urlId = `${currentTeam?.boards?.[0]?._id || currentTeam?.boards?.[0] || id?.boards?.[0]}/list/all`;
          break;
        case 'boardsCalendar':
          customMenuUrl = 'boards';
          urlId = `${currentTeam?.boards?.[0]?._id || currentTeam?.boards?.[0] || id?.boards?.[0]}/calendar/all`;
          break;
        case 'check-ins':
          urlId = currentTeam?.checkIn?._id || currentTeam?.checkIn || id?.checkIn;
          break;
        case 'schedules':
          urlId = currentTeam?.schedule?._id || currentTeam?.schedule || id?.schedule;
          break;
        case 'group-chats':
          urlId = currentTeam?.groupChat?._id || currentTeam?.groupChat || id?.groupChat;
          break;
        case 'buckets':
          urlId = currentTeam?.bucket?._id || currentTeam?.bucket || id?.bucket;
          break;
        case 'reports':
          urlId = `members/${user?._id}`;
          break;
        default:
          break;
      }

      const url = `${firstUrl}/${customMenuUrl || menu._id}/${urlId}`;
      const newMenu = {
        ...menu,
        url,
      };

      return newMenu;
    });

    setMenus(newMenus);
  }, [currentTeam, id]);

  return (
    <OutsideClickHandler
      onOutsideClick={handleCancel}
    >
      <div className={styles.container}>
        <OverlayButton wait="overviewOption" loadings={loadings}>
          {menus.map((menu) => (
            <LinkNoDecor to={menu.url}>
              <div className={styles.text}>
                <DisplayTextBody
                  mode="11"
                  decoration={menu._id === defaultMenuId ? 'bold' : ''}
                  color={Color.yellowNavyCicle}
                  fontFamily="openSans"
                  className={styles.title}
                >
                  {menu.title}
                </DisplayTextBody>
              </div>
            </LinkNoDecor>
          ))}
        </OverlayButton>
      </div>
    </OutsideClickHandler>

  );
};

OverviewOptionPopUp.propTypes = {
  defaultMenuId: PropTypes.string.isRequired,
  handleCancel: PropTypes.func.isRequired,
  currentTeam: PropTypes.object.isRequired,
};

export default memo(OverviewOptionPopUp);
