import React, { memo, useState } from 'react';
import PropTypes from 'prop-types';
import { useParams } from 'react-router-dom';
import { Box } from '@mui/material';
import GroupsOutlinedIcon from '@mui/icons-material/GroupsOutlined';
import MapsHomeWorkOutlinedIcon from '@mui/icons-material/MapsHomeWorkOutlined';
import AssignmentOutlinedIcon from '@mui/icons-material/AssignmentOutlined';
import { DisplayTextBody, DisplayTextSubHeadline } from '../../../../../../components/UI_V2/Display/Text';
import Color from '../../../../../../themes/colors';
import LinkNoDecor from '../../../../../../components/LinkNoDecor/LinkNoDecor';
import { limitChar } from '../../../../../../utilities/stringUtil';
import { getUrlItem } from '../../../../../../actions/SearchJumpToActions';

const containerStyles = {
  display: 'flex',
  alignItems: 'center',
  height: '35px',
  paddingTop: '1px',
  paddingBottom: '1px',
  paddingLeft: '6px',
  paddingRight: '6px',
  width: '95%',
  cursor: 'pointer',
  '&:hover': {
    backgroundColor: Color.backgroundBrokenWhite,
    borderRadius: '5px',
  },
};

const iconBoxStyles = {
  marginRight: '15px',
};

const iconStyles = {
  color: Color.gray3,
};

const ItemJumpTo = ({
  item, isSelected, onClick, params,
}) => {
  const { companyId } = params;
  const url = getUrlItem({ companyId, item });

  return (
    <LinkNoDecor to={url}>
      <Box
        onClick={onClick}
        aria-pressed={isSelected}
        sx={{
          ...containerStyles,
        }}
      >

        <Box
          sx={{
            ...iconBoxStyles,
          }}
        >
          <GroupsOutlinedIcon sx={{
            ...iconStyles,
          }}
          />
          {/* {item.type === 'hq' && (
          <MapsHomeWorkOutlinedIcon sx={{
            ...iconStyles,
          }}
          />
          )}
          {item.type === 'team' && (
          <GroupsOutlinedIcon sx={{
            ...iconStyles,
          }}
          />
          )}
          {item.type === 'project' && (
          <AssignmentOutlinedIcon sx={{
            ...iconStyles,
          }}
          />
          )} */}
        </Box>
        <Box>
          <DisplayTextBody
            mode="14"
            color={Color.gray3}
          >
            {limitChar(item.name, 40)}
          </DisplayTextBody>
        </Box>
      </Box>
    </LinkNoDecor>
  );
};

ItemJumpTo.propTypes = {
  item: PropTypes.object.isRequired,
  isSelected: PropTypes.bool.isRequired,
  onClick: PropTypes.func.isRequired,
  params: PropTypes.object,
};

ItemJumpTo.defaultProps = {
  params: { companyId: null, teamId: null },
};

export default memo(ItemJumpTo);
