import React, { memo, useState } from 'react';
import PropTypes from 'prop-types';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import styles from './SeenItem.module.css';
import Color from '../../../../../themes/colors';
import { DisplayTextBody } from '../../../../UI_V2/Display/Text';
import { DisplayAvatarMember } from '../../../../UI_V2/Display/Avatar';
import { DisplayDateBox } from '../../../../UI_V2/Display/Date';
import { handleCheckToday } from '../../../../../utilities/dateUtil';
import DateBox from '../../../../UI/DateBox/DateBox';

const SeenItem = ({
  seenItem,
}) => {
  const { reader } = seenItem;
  const { readAt } = seenItem;
  const isToday = handleCheckToday(seenItem, 'readAt');
  return (
    <div className={styles.container}>
      <div className={styles.user}>
        <div className={styles.photo}>
          <DisplayAvatarMember src={reader?.photoUrl} />
        </div>
        <div className={styles.name}>
          <DisplayTextBody>
            {reader.fullName}
          </DisplayTextBody>
        </div>
      </div>
      <div className={styles.time}>
        <DisplayDateBox
          date={readAt}
          relativeTime={!!isToday}
          exactTime={!isToday}
          customBackgroundColor={Color.lighterBlue2}
          customTextColor={Color.blueNavy4}
        />
      </div>
    </div>
  );
};

SeenItem.propTypes = {
  seenItem: PropTypes.object.isRequired,
};

SeenItem.defaultProps = {};

export default memo(SeenItem);
