import React, { memo, useState } from 'react';
import PropTypes from 'prop-types';
import { Box, Skeleton } from '@mui/material';
import styles from './Card.module.css';

const ModalPlanSelectionSkeletonCard = () => (
  <div className={styles.container}>
    <Skeleton
      variant="rounded"
      height={403}
      width={240}
    />
  </div>
);

export default memo(ModalPlanSelectionSkeletonCard);
