import React, { memo, useContext } from 'react';
import PropTypes from 'prop-types';
import ScrollContainer from 'react-indiana-drag-scroll';
import ViewSidebarOutlinedIcon from '@mui/icons-material/ViewSidebarOutlined';
import { useParams } from 'react-router-dom';
import MenuIcon from '@mui/icons-material/Menu';
import styles from './GeneralSubNavBar.module.css';
import { handleCloseSidebar, handleOpenSidebar } from '../../actions/SidebarActions';
import { InputButtonRounded } from '../UI_V2/Input/Button';
import BreadcrumbContainer from './BreadcrumbContainer/BreadcrumbContainer';
import BottomNavBar from './BottomNavBar/BottomNavBar';
import { GlobalContext } from '../../contexts/GlobalStateProvider';
import MiddleNavBar from './MiddleNavBar/MiddleNavBar';
import MyTaskCardMiddleNavBar from './MyTaskCardMiddleNavBar/MyTaskCardMiddleNavBar';
import { DisplayBadgeCounter } from '../UI_V2/Display/Badge';

const containerWithSidebarStyles = {
  sidebarOpenKanban: `${styles.container} ${styles.sidebarOpenKanban}`,
  sidebarOpenNotKanban: `${styles.container} ${styles.sidebarOpenNotKanban}`,
  sidebarClosed: styles.container,
};

const topNavBarBottomBarStyles = {
  true: `${styles.topNavBar} ${styles.disableBottomBorder}`,
  false: styles.topNavBar,
};

const GeneralSubNavBar = ({
  breadcrumbRoutes,
  pageTitle,
  withBottomNavBar,
  withMiddleNavBar,
  withMyTaskCardMiddleNavBar,
  bottomBarOverviewOptionId,
  bottomBarGlobalActions,
  bottomBarOtherActions,
}) => {
  const [{
    isSidebarOpen,
    counterNotif,
    counterCompaniesNotif,
  }, dispatch] = useContext(GlobalContext);

  const params = useParams();
  const { commentId } = params;

  const isKanbanView = bottomBarOverviewOptionId === 'boards' && !commentId;

  let sidebarStyles;

  if (isSidebarOpen) {
    if (isKanbanView) {
      sidebarStyles = 'sidebarOpenKanban';
    } else {
      sidebarStyles = 'sidebarOpenNotKanban';
    }
  } else {
    sidebarStyles = 'sidebarClosed';
  }

  return (
    <div className={containerWithSidebarStyles[sidebarStyles]}>
      <div className={topNavBarBottomBarStyles[withMiddleNavBar]}>
        {!isSidebarOpen && (
          <div
            className={styles.drawerIcon}
            onClick={isSidebarOpen
              ? () => handleCloseSidebar({}, dispatch)
              : () => handleOpenSidebar({}, dispatch)}
          >
            <MenuIcon />
            <DisplayBadgeCounter
              badgeContent={counterNotif.nonChat
                 + counterNotif.chat + counterCompaniesNotif.totalCounter}
              variant="dot"
              height="10px"
              minWidth="10px"
              customStyle={{ top: -3, right: 2 }}
            />
          </div>
        )}
        <ScrollContainer className={styles.breadcrumbs}>
          <BreadcrumbContainer routes={breadcrumbRoutes} />
        </ScrollContainer>
        <div className={styles.title} />
      </div>
      {withMyTaskCardMiddleNavBar && (
        <div className={styles.middleNavBar}>
          <MyTaskCardMiddleNavBar overviewOptionId={bottomBarOverviewOptionId} />
        </div>
      )}
      {withMiddleNavBar && (
        <div className={styles.middleNavBar}>
          <MiddleNavBar overviewOptionId={bottomBarOverviewOptionId} />
        </div>
      )}
      {withBottomNavBar && (
        <div className={styles.bottomNavBar}>
          <BottomNavBar
            overviewOptionId={bottomBarOverviewOptionId}
            globalActions={bottomBarGlobalActions}
            otherActions={bottomBarOtherActions}
          />
        </div>
      )}
    </div>
  );
};

GeneralSubNavBar.propTypes = {
  breadcrumbRoutes: PropTypes.array.isRequired,
  pageTitle: PropTypes.string.isRequired,
  withBottomNavBar: PropTypes.bool,
  withMiddleNavBar: PropTypes.bool,
  withMyTaskCardMiddleNavBar: PropTypes.bool,
  bottomBarOverviewOptionId: PropTypes.string,
  bottomBarGlobalActions: PropTypes.node,
  bottomBarOtherActions: PropTypes.node,
};

GeneralSubNavBar.defaultProps = {
  withBottomNavBar: true,
  withMiddleNavBar: true,
  withMyTaskCardMiddleNavBar: false,
  bottomBarOverviewOptionId: null,
  bottomBarGlobalActions: <></>,
  bottomBarOtherActions: <></>,
};

export default memo(GeneralSubNavBar);
