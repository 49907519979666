const typeCallback = {
  NEW: 'new',
  DELETE: 'delete',
  EDIT: 'edit',
};

const completeDueDate = {
  status: true,
  type: 'done',
};

export {
  typeCallback,
  completeDueDate,
};
