import React, { memo, useContext, useState } from 'react';
import PropTypes from 'prop-types';
import SortIcon from '@mui/icons-material/Sort';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { useMediaQuery } from '@mui/material';
import styles from './CardListItemTeam.module.css';
import Color from '../../../../themes/colors';
import { DisplayTextBody } from '../../../UI_V2/Display/Text';
import CardListColumnBox from '../../CardListColumnBox/CardListColumnBox';
import { limitChar } from '../../../../utilities/stringUtil';
import { GlobalContext } from '../../../../contexts/GlobalStateProvider';

const CardListItemTeam = ({
  item,
  widthPercentage,
  variantBorder,
  setSelected,
  triggerScrollIntoView,
}) => {
  const [{ isSidebarOpen }] = useContext(GlobalContext);
  const isMobile = useMediaQuery('(max-width:720px)');
  const getNameLimit = () => {
    if (isMobile) return 7;
    if (isSidebarOpen) return 17;
    return 20;
  };
  return (
    <CardListColumnBox
      variantBorder={variantBorder}
      widthPercentage={widthPercentage}
    >
      <div className={styles.container}>
        <DisplayTextBody>
          {limitChar(item.team.name, getNameLimit())}
        </DisplayTextBody>
      </div>
    </CardListColumnBox>
  );
};

CardListItemTeam.propTypes = {
  item: PropTypes.object,
  widthPercentage: PropTypes.number,
  variantBorder: PropTypes.string.isRequired,
  setSelected: PropTypes.func,
  triggerScrollIntoView: PropTypes.func,
};

CardListItemTeam.defaultProps = {
  item: {},
  widthPercentage: 100,
  setSelected: () => null,
  triggerScrollIntoView: () => null,
};

export default memo(CardListItemTeam);
