import { cloneDeep } from 'lodash';
import apiUtil from '../utilities/apiUtil';
import { splitString } from '../utilities/stringUtil';
import { actionTypes } from '../reducers/reducer';
import { ErrorException } from '../utilities/handleError';
import { setSessionLastOpenedCompany, getSessionLastOpenedCompany } from '../utilities/sessionStorage';
import { setStoreLastOpenedCompany, getStoreLastOpenedCompany } from '../utilities/localStorage';
import { ApiConstants, CheckLoginConstants } from '../constants';
import { CompanyActions, TeamActions } from '.';

const getCompanyIdAndTeamId = async ({ pathname, id }) => {
  try {
    const result = await apiUtil.get(
      `${process.env.REACT_APP_PRIMARY_API_URL}/v2/${pathname}/${id}/company`,
    );
    return result;
  } catch (error) {
    throw new ErrorException(error);
  }
};

const getUrl = async ({ locationUrl, currentCompany }) => {
  const arrayUrl = splitString(locationUrl, '/');
  const [
    separator,
    pathname,
    id,
  ] = arrayUrl;
  let url;
  if (currentCompany?._id) {
    url = `/errors?previousPath=/companies/${currentCompany?._id}`;
  } else {
    url = '/errors?previousPath=/';
  }

  if (pathname === 'cheers'
  || pathname === 'notifications'
  || pathname === 'profiles'
  ) {
    if (currentCompany?._id) {
      return `/companies/${currentCompany?._id}${locationUrl}`;
    }
    return '/';
  }

  let result;
  if (id) {
    result = await getCompanyIdAndTeamId({ pathname, id });
  }
  const { companyId, teamId } = result?.data;

  url = `/companies/${companyId}/teams/${teamId}${locationUrl}`;

  if (pathname === 'chats'
  || pathname === 'teams'
  ) {
    url = `/companies/${companyId}${locationUrl}`;
  }

  return url;
};

export {
  getUrl,
};
