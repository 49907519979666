import { cloneDeep, findIndex } from 'lodash';
import { actionTypes } from '../reducers/reducer';
import { insertItemArrayImmutable, updateListProperty } from '../utilities/arrayUtil';
import apiUtil from '../utilities/apiUtil';
import { ApiConstants } from '../constants';
import { TeamActions } from '.';
import { ErrorException } from '../utilities/handleError';

/*
  Dispatcher
*/

function dispatchCurrentSchedule({ currentSchedule }, dispatch) {
  dispatch({
    type: actionTypes.SET_CURRENT_SCHEDULE,
    currentSchedule,
  });
}

function dispatchUpdateSchedule({ updateSchedule }, dispatch) {
  dispatch({
    type: actionTypes.UPDATE_CURRENT_SCHEDULE,
    updateSchedule,
  });
}

/*
  SetterDispatcher
*/

function setCurrentSchedule({ currentSchedule }, dispatch) {
  if (!currentSchedule) return;

  dispatchCurrentSchedule(
    { currentSchedule: cloneDeep(currentSchedule) },
    dispatch,
  );
}

/*
  Method
*/

function incomingSchedule({
  event, typeAction, keyProperty = 'events',
}, dispatch) {
  if (!event) return;

  const actionAdd = (previousLists) => {
    const lastIndexEvent = findIndex(previousLists, (itemList) => {
      const eventDate = new Date(itemList.startDate);
      const newEventDate = new Date(event.startDate);
      return eventDate > newEventDate;
    });

    const isItemNotLast = lastIndexEvent >= 0;
    const lengthPreviousList = previousLists.length;
    const newLists = isItemNotLast
      ? insertItemArrayImmutable(previousLists, lastIndexEvent, event)
      : insertItemArrayImmutable(previousLists, lengthPreviousList, event);

    return newLists;
  };

  const updateSchedule = (currentSchedule) => updateListProperty({
    keyProperty,
    newData: event,
    currentList: currentSchedule,
    typeAction,
    actionAdd,
  });

  dispatchUpdateSchedule({ updateSchedule }, dispatch);
}

async function initiateSchedule({
  scheduleId, companyId, fromDate, untilDate,
  teamId,
}, dispatch) {
  try {
    const result = await apiUtil.get(ApiConstants.URL_V1.SCHEDULE_OCCURRENCES({ scheduleId }), {
      params: {
        fromDate,
        untilDate,
        companyId,
        teamId,
      },
    });
    setCurrentSchedule({ currentSchedule: result?.data?.schedule }, dispatch);
    // TeamActions.saveCurrentTeam({ currentTeam: result?.data?.currentTeam }, dispatch);

    return result;
  } catch (error) {
    throw new ErrorException(error);
  }
}

async function initiateScheduleV2({
  scheduleId, companyId, fromDate, untilDate,
  teamId,
}, dispatch) {
  try {
    const result = await apiUtil.get(ApiConstants.URL_V2.SCHEDULE({ scheduleId }), {
      params: {
        fromDate,
        untilDate,
        companyId,
        teamId,
        createdAt: new Date(),
      },
    });
    setCurrentSchedule({ currentSchedule: result?.data?.schedule }, dispatch);
    // TeamActions.saveCurrentTeam({ currentTeam: result?.data?.currentTeam }, dispatch);

    return result;
  } catch (error) {
    throw new ErrorException(error);
  }
}

export {
  incomingSchedule,
  setCurrentSchedule,
  initiateSchedule,
  initiateScheduleV2,
};
