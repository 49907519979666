/* eslint-disable consistent-return */
import { useCallback } from 'react';
import { UserOnlineActions } from '../actions';
import { UserOnlineConstants } from '../constants';
import { connectSocketUserOnline } from '../services/socket';
import { useSocketHooks } from './SocketHooks';

function useUserOnlineSocket({ companyId, userId }, dispatch) {
  const callbackInitiate = useCallback((allUserOnline) => {
    UserOnlineActions.incomingInitiateAllUserOnline({
      allUserOnline,
    }, dispatch);
  }, []);

  const callbackUpdateStatus = useCallback((userOnline) => {
    UserOnlineActions.incomingUserOnlineUpdate({
      userOnline,
    }, dispatch);
  }, []);

  const listenSocket = (socket, params) => {
    socket
      .on(`${params.companyId}-initiate`, callbackInitiate)
      .on(`${params.companyId}-update-status`, callbackUpdateStatus);
  };
  const removeListener = (socket, params) => {
    socket
      .off(`${params.companyId}-initiate`)
      .off(`${params.companyId}-update-status`);
    socket.disconnect();
  };

  useSocketHooks({
    params: { companyId, userId },
    connectSocket: connectSocketUserOnline,
    listenSocket,
    removeListener,
  });
}

export {
  useUserOnlineSocket,
};
