import React, {
  memo,
} from 'react';
import PropTypes from 'prop-types';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import CelebrationIcon from '@mui/icons-material/Celebration';
import { DisplayTextBody } from '../../../components/UI_V2/Display/Text';
import Color from '../../../themes/colors';
import styles from './CheersButton.module.css';
import cheersIcon from '../../../assets/CheersPage/cheers-icon.png';

const CheersButton = () => (
  <div className={styles.container}>
    <div className={styles.icon}>
      <CelebrationIcon />
    </div>
    <DisplayTextBody
      color={Color.gray3}
    >
      Cheers
    </DisplayTextBody>
  </div>
);

export default memo(CheersButton);
