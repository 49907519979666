import React, { memo, useState } from 'react';
import PropTypes from 'prop-types';
import DownloadIcon from '@mui/icons-material/Download';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { useMediaQuery } from '@mui/material';
import { enqueueSnackbar } from 'notistack';
import styles from './EditChecklistItemName.module.css';
import Color from '../../../../../../../themes/colors';
import { InputButtonMain } from '../../../../../../../components/UI_V2/Input/Button';
import { InputTextFieldMain } from '../../../../../../../components/UI_V2/Input/TextField';
import handleLoadings from '../../../../../../../utilities/handleLoadings';
import { validateTitle } from '../../../../../../../utilities/inputValidatorUtil';
import handleStatusMsg from '../../../../../../../utilities/handleStatusMsg';
import { editChecklistItemNameApi } from '../../../../../../../actions/ChecklistItemActions';
import { useEnterKeyForExecutingFunction } from '../../../../../../../hooks/HelperHooks';

const EditChecklistItemName = ({
  cardId,
  checklistId,
  checklistItemId,
  teamId,
  companyId,
  onClose,
  initialChecklistItemName,
}) => {
  const isMobile = useMediaQuery('(max-width:720px)');

  const [newChecklistItemName, setNewChecklistItemName] = useState(initialChecklistItemName);
  const [loadings, setLoadings] = useState([]);

  const clearValue = () => {
    setNewChecklistItemName('');
  };

  const handleChanged = (event) => {
    const { value } = event.target;

    setNewChecklistItemName(() => (value));
  };

  const handleEditChecklistItemNameApi = async () => {
    try {
      const startLoadings = handleLoadings('editChecklistItemName', [...loadings], 'start');
      setLoadings([...startLoadings]);

      const { isValid, errorMsg } = validateTitle(newChecklistItemName);

      if (!isValid) {
        enqueueSnackbar(errorMsg, {
          variant: 'error',
        });
        return;
      }

      const result = await editChecklistItemNameApi({
        cardId,
        checklistId,
        checklistItemId,
        companyId,
        teamId,
        checklistItemName: newChecklistItemName,
      });

      const status = handleStatusMsg(result, 'success');

      enqueueSnackbar(status.message, {
        variant: 'success',
      });

      clearValue();
      onClose();
    } catch (error) {
      const status = handleStatusMsg(error, 'error');
      if (status?.message) {
        enqueueSnackbar(status.message, {
          variant: 'error',
        });
      }
    } finally {
      const endLoadings = handleLoadings('editChecklistItemName', [...loadings], 'end');
      setLoadings([...endLoadings]);
    }
  };

  // hook for press enter executing function;
  useEnterKeyForExecutingFunction(handleEditChecklistItemNameApi);

  return (
    <div className={styles.container}>
      <div className={styles.body}>
        <div className={styles.form}>
          <div className={styles.formArea}>
            <InputTextFieldMain
              placeholder="Judul ceklis item"
              name="name"
              onChange={(event) => handleChanged(event)}
              value={newChecklistItemName}
              autoFocus
              size="md"
            />
          </div>
        </div>
        <div className={styles.action}>
          <div className={styles.actionButton}>
            <InputButtonMain
              handleClick={handleEditChecklistItemNameApi}
              wait="editChecklistItemName"
              loadings={loadings}
              size="sm"
              variant="success"
            >
              Simpan
            </InputButtonMain>
          </div>
          <div className={styles.actionButton}>
            <InputButtonMain
              variant="light"
              handleClick={onClose}
              size="sm"
            >
              Batal
            </InputButtonMain>
          </div>
        </div>
      </div>
    </div>
  );
};

EditChecklistItemName.propTypes = {
  onClose: PropTypes.func.isRequired,
  cardId: PropTypes.string.isRequired,
  teamId: PropTypes.string.isRequired,
  companyId: PropTypes.string.isRequired,
  checklistId: PropTypes.string.isRequired,
  initialChecklistItemName: PropTypes.string.isRequired,
  checklistItemId: PropTypes.string.isRequired,
};

EditChecklistItemName.defaultProps = {};

export default memo(EditChecklistItemName);
