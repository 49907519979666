import React, { memo, useState } from 'react';
import PropTypes from 'prop-types';
import DownloadIcon from '@mui/icons-material/Download';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { useMediaQuery } from '@mui/material';
import styles from './BoxTextSingle.module.css';
import Color from '../../../themes/colors';
import { DisplayTextBody, DisplayTextSubHeadline } from '../../../components/UI_V2/Display/Text';
import { limitChar } from '../../../utilities/stringUtil';

const BoxTextSingle = ({ text, variant }) => {
  let component = <></>;
  switch (variant) {
    case 'subheadline':
      component = (
        <DisplayTextSubHeadline
          position="center"
          decoration="bold"
        >
          {limitChar(text, 30)}
        </DisplayTextSubHeadline>
      );
      break;

    case 'body':
      component = (
        <DisplayTextBody
          position="center"
        >
          {limitChar(text, 50)}
        </DisplayTextBody>
      );
      break;

    default:
      break;
  }
  return (
    <div className={styles.container}>
      {component}
    </div>
  );
};

BoxTextSingle.propTypes = {
  text: PropTypes.string.isRequired,
  variant: PropTypes.oneOf(['subheadline', 'body']),
};

BoxTextSingle.defaultProps = {
  variant: 'body',
};

export default memo(BoxTextSingle);
