import React, {
  useState, useContext, useEffect, useCallback,
} from 'react';
import { useHistory, useParams, useLocation } from 'react-router-dom';
import AddIcon from '@mui/icons-material/Add';
import { enqueueSnackbar, useSnackbar } from 'notistack';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import ViewKanbanIcon from '@mui/icons-material/ViewKanban';
import FormatListBulletedIcon from '@mui/icons-material/FormatListBulleted';
import ArchiveOutlinedIcon from '@mui/icons-material/ArchiveOutlined';
import FilterAltOutlinedIcon from '@mui/icons-material/FilterAltOutlined';
import { Fab, Tooltip } from '@mui/material';
import ScrollContainer from 'react-indiana-drag-scroll';
import { GlobalContext } from '../../contexts/GlobalStateProvider';
import styles from './BoardCardListAllPage.module.css';
import Title from '../../components/Title/Title';
import {
  BoardActions,
  SearchJumpToActions,
  TeamActions,
} from '../../actions';
import { PageConstants } from '../../constants';
import GeneralSubNavBar from '../../components/GeneralSubNavBar/GeneralSubNavBar';
import GlobalActionButton from '../../components/GeneralSubNavBar/BottomNavBar/GlobalActionButton/GlobalActionButton';
import { generateRoutesAndTitle } from '../../actions/BreadCrumbActions';
import CardListHeader from '../../components/CardList/CardListHeader/CardListHeader';
import ListCards from './ListCards/ListCards';
import { useDelayShowHideHandler } from '../../hooks/HelperHooks';
import ModalCreateCardWithTeamListSelection from '../../components/Container/ModalCreateCardWithTeamListSelection/ModalCreateCardWithTeamListSelection';
import ArchivedItemsBar from '../../components/Container/ArchivedItemsBar/ArchivedItemsBar';
import {
  useArchivedBoardCardsHooks,
  useArchivedBoardListsHooks,
  useGetBoardCardViewData, useSetBoardCardView,
} from '../../hooks/BoardHooks';
import LinkNoDecor from '../../components/LinkNoDecor/LinkNoDecor';

const containerWithSidebarStyles = {
  true: `${styles.container} ${styles.sidebarOpen}`,
  false: styles.container,
};

function BoardCardListAllPage() {
  const [{
    user, currentBoard, currentTeam, currentCompany,
    isSidebarOpen,
  }, dispatch] = useContext(GlobalContext);
  /* Show/Hide Filters */
  const [showFilters, handleShowFilters, handleHideFilters] = useDelayShowHideHandler();
  const [isFilterActive,
    handleShowFilterActive, handleHideFilterActive] = useDelayShowHideHandler();
  const [showCreateBoardCard,
    handleShowCreateBoardCard, handleHideCreateBoardCard] = useDelayShowHideHandler();
  const [showArchivedCards, setShowArchivedCards] = useState(false);
  const [showArchivedLists, setShowArchivedLists] = useState(false);
  const [isOverflow, setIsOverflow] = useState(false);

  const location = useLocation();
  const history = useHistory();
  const params = useParams();
  const { companyId, teamId, boardId } = params;

  const [
    initiateListArchivedCards,
    loadMoreListArchivedCards,
    unarchiveArchivedCard,
  ] = useArchivedBoardCardsHooks();
  const [
    initiateListArchivedLists,
    loadMoreListArchivedLists,
    unarchiveArchivedList,
  ] = useArchivedBoardListsHooks();

  // const initialBoardCardView = getStoreBoardCardView(
  //   { boardId, userId: user?._id },
  // );

  // const boardCardViewInitialCalendarViewValue =
  // initialBoardCardView?.initialCalendarViewValue || 'dayGridMonth';
  const [boardCardViewMainValue,
    boardCardViewUrl,
    boardCardViewInitialCalendarViewValue] = useGetBoardCardViewData({
    boardId,
    userId: user?._id,
    companyId,
    teamId,
  });

  useSetBoardCardView({
    user,
    boardId,
    companyId,
    initialCalendarViewValue: boardCardViewInitialCalendarViewValue,
    mainValue: 'listAll',
  });

  const handleShowArchivedCards = () => {
    setShowArchivedCards(!showArchivedCards);
    setShowArchivedLists(false);
  };

  const handleShowArchivedLists = () => {
    setShowArchivedLists(!showArchivedLists);
    setShowArchivedCards(false);
  };

  const pageType = PageConstants.pageType.boardsList;
  const breadcrumb = generateRoutesAndTitle({
    type: pageType,
    data: {
      currentTeam, currentBoard, boardId, companyId,
    },
  });
  SearchJumpToActions.saveRecentPage({
    company: currentCompany,
    team: currentTeam,
    userId: user?._id,
    itemId: `${boardId || currentBoard?._id}-list`,
    url: breadcrumb.url,
    routes: breadcrumb.routes,
    name: breadcrumb.title,
    type: pageType,
  });

  return (
    <>
      <Title title={currentTeam.name === undefined ? 'List Tugas' : `List Tugas - ${currentTeam.name} - List`} />
      <GeneralSubNavBar
        breadcrumbRoutes={breadcrumb.routes}
        pageTitle={breadcrumb.title}
        bottomBarOverviewOptionId="boardsList"
        bottomBarGlobalActions={(
          <>
            <GlobalActionButton handleClick={handleShowCreateBoardCard} text="Buat Tugas" icon={<AddIcon />} />
            <GlobalActionButton
              handleClick={handleShowFilters}
              buttonMode="outlined"
              text="Filter"
              icon={<FilterAltOutlinedIcon />}
            >
              {isFilterActive ? (
                <Tooltip
                  title="Filter-mu aktif. Atur atau hapus filter kalo kamu ga menemukan yang kamu cari."
                >
                  <div className={styles.activeFilterIcon}><CheckCircleIcon /></div>
                </Tooltip>
              ) : null}
            </GlobalActionButton>
            <GlobalActionButton
              handleClick={handleShowArchivedCards}
              buttonMode="outlined"
              text="Arsip Tugas"
              icon={<ArchiveOutlinedIcon />}
            />
            <GlobalActionButton
              handleClick={handleShowArchivedLists}
              buttonMode="outlined"
              text="Arsip List"
              icon={<ArchiveOutlinedIcon />}
            />
          </>
        )}
        // bottomBarGlobalActions={(
        //   <>
        //     {currentTeam?._id === teamId
        //     && (
        //       <LinkNoDecor
        // to={`/companies/${companyId}/teams/${teamId}/reports/members/${user._id}`}>
        //         <GlobalActionButton
        //           text="Kinerja Saya"
        //           icon={PageConstants.pageProperties.reports.icon}
        //         />
        //       </LinkNoDecor>
        //   )}
        //     <LinkNoDecor
        // to={`/companies/${companyId}/teams/${teamId}/boards/${boardId}/kanban/all`}>
        //       <GlobalActionButton text="View Kanban" icon={<ViewKanbanIcon />} />
        //     </LinkNoDecor>
        //     <GlobalActionButton
        // buttonMode="active" text="View List" icon={<FormatListBulletedIcon />} />
        //     <LinkNoDecor
        // to={`/companies/${companyId}/teams/${teamId}/boards/${boardId}/
        // calendar/all?initialCalendarView=${boardCardViewInitialCalendarViewValue}`}>
        //       <GlobalActionButton text="View Kalender" icon={<CalendarMonthIcon />} />
        //     </LinkNoDecor>
        //   </>
        // )}
        // bottomBarOtherActions={(
        //   <>
        //     <GlobalActionButton
        //       handleClick={handleShowFilters}
        //       buttonMode="outlined"
        //       text="Filter"
        //       icon={<FilterAltOutlinedIcon />}
        //     >
        //       {isFilterActive ? (
        //         <Tooltip
        //           title="Filter-mu aktif.
        // Atur atau hapus filter kalo kamu ga menemukan yang kamu cari."
        //         >
        //           <div className={styles.activeFilterIcon}><CheckCircleIcon /></div>
        //         </Tooltip>
        //       ) : null}
        //     </GlobalActionButton>
        //     <GlobalActionButton
        //       handleClick={handleShowArchivedCards}
        //       buttonMode="outlined"
        //       text="Arsip Tugas"
        //       icon={<ArchiveOutlinedIcon />}
        //     />
        //     <GlobalActionButton
        //       handleClick={handleShowArchivedLists}
        //       buttonMode="outlined"
        //       text="Arsip List"
        //       icon={<ArchiveOutlinedIcon />}
        //     />
        //   </>
        // )}
      />
      <ScrollContainer className={styles.scrollList}>
        <div className={containerWithSidebarStyles[isSidebarOpen]}>
          <div
            className={styles.header}
            style={{
              paddingRight: isOverflow ? '6px' : '0px',
            }}
          >
            <CardListHeader />
          </div>
          <div className={styles.body} id="listBoardCardList">
            <ListCards
              showFilters={showFilters}
              handleHideFilters={handleHideFilters}
              handleShowFilterActive={handleShowFilterActive}
              handleHideFilterActive={handleHideFilterActive}
              handleSetIsOverflow={setIsOverflow}
            />
          </div>
          <ModalCreateCardWithTeamListSelection
            open={showCreateBoardCard}
            onClose={handleHideCreateBoardCard}
            disableSelectTeam
          />
        </div>
      </ScrollContainer>
      {/* <div className={styles.fab}>
        <Fab onClick={handleShowCreateBoardCard} color="primary" aria-label="add">
          <AddIcon />
        </Fab>
      </div> */}
      {showArchivedCards
              && (
              <ArchivedItemsBar
                topPositionVariant="withBottomBar"
                initiateListDataFunc={initiateListArchivedCards}
                loadMoreListDataFunc={loadMoreListArchivedCards}
                unarchiveDataFunc={unarchiveArchivedCard}
                onClose={handleShowArchivedCards}
                featureType="card"
                barTitle="Arsip Tugas"
              />
              )}
      {showArchivedLists
              && (
              <ArchivedItemsBar
                topPositionVariant="withBottomBar"
                initiateListDataFunc={initiateListArchivedLists}
                loadMoreListDataFunc={loadMoreListArchivedLists}
                unarchiveDataFunc={unarchiveArchivedList}
                onClose={handleShowArchivedLists}
                featureType="list"
                barTitle="Arsip List"
              />
              )}
    </>
  );
}

export default BoardCardListAllPage;
