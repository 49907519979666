import React, {
  memo, useContext,
  useEffect, useState,
} from 'react';
import PropTypes from 'prop-types';
import DownloadIcon from '@mui/icons-material/Download';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { useMediaQuery } from '@mui/material';
import { InputSearchGeneral } from '../../../UI_V2/Input/Search';
import styles from './ListTeams.module.css';
import Color from '../../../../themes/colors';
import { InputButtonMain } from '../../../UI_V2/Input/Button';
import { GlobalContext } from '../../../../contexts/GlobalStateProvider';
import TeamItem from './TeamItem/TeamItem';
import { useSearch } from '../../../../hooks/HelperHooks';
import { DisplayTextBody } from '../../../UI_V2/Display/Text';
import { actionTypes } from '../../../../reducers/reducer';
import { getStoreTeamsColor } from '../../../../utilities/localStorage';
import { TeamConstants } from '../../../../constants';

const ListTeams = ({
  handleSelectAndShowTeamMenu,
  handleCancelShowTeamMenu,
  params,
  teamType,
  listTeams,
}) => {
  const [{
    selectedEditTeam,
    // teams,
    user,
    isOpenTeamFromFavorite,
    shouldCheckTeamsColor
  }, dispatch] = useContext(GlobalContext);
  const isMobile = useMediaQuery('(max-width:720px)');
  const [teamsColor, setTeamsColor] = useState([]);

  const usedTeams = listTeams.filter((team) => team.type === teamType);

  // const {
  //   searchResult,
  //   keyword,
  //   keywordArray,
  //   setKeyword,
  //   isTyping,
  //   onChange,
  //   resetSearch,
  // } = useSearch({ originalList: usedTeams });

  const isTeamsEmpty = usedTeams.length < 1;

  const onClickTeamItem = () => {
    dispatch({
      type: actionTypes.SET_IS_OPEN_TEAM_FROM_FAVORITE,
      isOpenTeamFromFavorite: false,
    });
  };

  useEffect(() => {
    if (!user?._id) return;
    if (!params?.companyId) return;

    setTeamsColor(getStoreTeamsColor({
      companyId: params?.companyId, userId: user?._id,
    }) || []);
  }, [user, params.companyId, shouldCheckTeamsColor]);

  return (
    <div className={styles.container}>
      {/* {!isTeamsEmpty && (
      <div className={styles.search}>
        <InputSearchGeneral
          onChange={onChange}
          value={keyword}
          placeholder={`Cari ${TeamConstants.teamType[teamType]}`}
          onClickCloseIcon={resetSearch}
          size="sm"
          autoFocus={false}
        />
      </div>
      )} */}
      <div className={styles.list}>
        {isTeamsEmpty && (
        <DisplayTextBody>
          Belum ada tim
        </DisplayTextBody>
        )}
        {!isTeamsEmpty && usedTeams.map((team) => (
          <div className={styles.team}>
            <TeamItem
              team={team}
              teamsColor={teamsColor}
              handleSelectAndShowTeamMenu={handleSelectAndShowTeamMenu}
              handleCancelShowTeamMenu={handleCancelShowTeamMenu}
              isTeamSelected={selectedEditTeam._id === team._id}
              isTeamOpen={team._id === params.teamId && !isOpenTeamFromFavorite}
              onOpenTeam={onClickTeamItem}
            />
          </div>
        ))}
      </div>
    </div>
  );
};

ListTeams.propTypes = {
  handleCancelShowTeamMenu: PropTypes.func,
  handleSelectAndShowTeamMenu: PropTypes.func,
  params: PropTypes.object,
  teamType: PropTypes.oneOf(['hq', 'team', 'project']),
  listTeams: PropTypes.array,
};

ListTeams.defaultProps = {
  handleSelectAndShowTeamMenu: () => null,
  handleCancelShowTeamMenu: () => null,
  params: { companyId: null, teamId: null },
  teamType: 'hq',
  listTeams: [],
};

export default memo(ListTeams);
