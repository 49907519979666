import React, { useState, useEffect } from 'react';
import Form from 'react-bootstrap/Form';
import PropTypes from 'prop-types';

const SearchCards = ({ handleSearchName, boardId }) => {
  let initialCheckSearchName = '';
  const checkSearchNameLs = localStorage.getItem(`confKanbanCheckSearchName-${boardId}`);
  if (checkSearchNameLs && checkSearchNameLs.length > 0) initialCheckSearchName = checkSearchNameLs;
  const [search, setSearch] = useState(initialCheckSearchName);

  const handleChanged = (event) => {
    const { value } = event.target;
    setSearch(value);
  };

  useEffect(() => {
    const delayDebounce = setTimeout(() => {
      handleSearchName(search);
    }, 300);

    return () => clearTimeout(delayDebounce);
  }, [search]);

  return (
    <Form onSubmit={(event) => {
      event.preventDefault();
    }}
    >
      <Form.Control autoFocus onChange={(event) => handleChanged(event)} placeholder="Cari Tugas" value={search} />
    </Form>
  );
};

SearchCards.propTypes = {
  handleSearchName: PropTypes.func.isRequired,
  boardId: PropTypes.string.isRequired,
};

export default SearchCards;
