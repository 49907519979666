import React, {
  memo, useState, useEffect, useContext,
} from 'react';
import PropTypes from 'prop-types';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { useMediaQuery } from '@mui/material';
import styles from './OverviewOptionButton.module.css';
import { DisplayTextBody, DisplayTextSubBody } from '../../../UI_V2/Display/Text';
import Color from '../../../../themes/colors';
import OverviewOptionPopUp from './OverviewOptionPopUp/OverviewOptionPopUp';
import { NavHeaderConstants } from '../../../../constants';
import { GlobalContext } from '../../../../contexts/GlobalStateProvider';
import { limitChar } from '../../../../utilities/stringUtil';

const OverviewOptionButton = ({ defaultMenuId }) => {
  const [{
    currentTeam,
  }, dispatch] = useContext(GlobalContext);
  const [showOption, setShowOption] = useState(false);
  const [isDelayShowOption, setIsDelayShowOption] = useState(false);

  const handleShowOption = () => {
    if (isDelayShowOption) return;
    setShowOption(true);
  };

  const handleCancelShowOption = () => {
    setShowOption(false);
    setIsDelayShowOption(true);
  };

  let title = '';

  const defaultMenu = NavHeaderConstants.menus.find((menu) => menu._id === defaultMenuId);

  if (defaultMenu) title = defaultMenu.title;

  useEffect(() => {
    if (!isDelayShowOption) return;
    setTimeout(() => {
      setIsDelayShowOption(false);
    }, 100);
  }, [isDelayShowOption]);

  const isMobile = useMediaQuery('(max-width:720px)');

  return (
    <>
      <div className={styles.container} onClick={handleShowOption}>
        <div className={styles.text}>
          <DisplayTextSubBody
            mode="11"
            decoration="bold"
            color={Color.white}
            fontFamily="openSans"
          >
            {title}
          </DisplayTextSubBody>
        </div>
        <div className={styles.icon}>
          {showOption ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
        </div>
      </div>
      {showOption
        ? (
          <OverviewOptionPopUp
            defaultMenuId={defaultMenuId}
            currentTeam={currentTeam}
            handleCancel={handleCancelShowOption}
          />
        )
        : null}
    </>
  );
};

OverviewOptionButton.propTypes = {
  defaultMenuId: PropTypes.string.isRequired,
};

export default memo(OverviewOptionButton);
