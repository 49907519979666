import React from 'react';
import CloseIcon from '@mui/icons-material/Close';
import PropTypes from 'prop-types';
import BoxPopOver from '../../../../components/UI/BoxPopOver/BoxPopOver';
import SeparatorLine from '../../../../components/UI/SeparatorLine/SeparatorLine';
import styles from './DeleteCompanyPopUp.module.css';
import { InputButtonMain } from '../../../../components/UI_V2/Input/Button';

const DeleteCompanyPopUp = ({
  handleDelete, wait, loadings, cancel,
}) => (
  <BoxPopOver>
    <div className={styles.headerSection}>
      <h1>
        Hapus Perusahaan?
      </h1>
      <CloseIcon onClick={cancel} />
    </div>
    <SeparatorLine />
    <div className={styles.bodySection}>
      <p>Perusahaan ini berikut semua datanya akan hilang selamanya. Tidak bisa dikembalikan.</p>
    </div>
    <div className={styles.actionSection}>
      <InputButtonMain handleClick={handleDelete} variant="danger" wide="block" wait={wait} loadings={loadings}>Hapus</InputButtonMain>
    </div>
  </BoxPopOver>
);

DeleteCompanyPopUp.propTypes = {
  handleDelete: PropTypes.func.isRequired,
  wait: PropTypes.string.isRequired,
  loadings: PropTypes.array.isRequired,
  cancel: PropTypes.func.isRequired,
};

export default DeleteCompanyPopUp;
