import { Tooltip } from '@mui/material';
import PropTypes from 'prop-types';
import React, { memo } from 'react';
import { ExitToApp } from '@mui/icons-material';
import IconItem from '../../../../../pages/UserMenuContainer/IconItem/IconItem';
import { FeedbackSpinnerGeneral } from '../../../Feedback/Spinner';
import styles from './Logout.module.css';
import Color from '../../../../../themes/colors';

const InputButtonLogout = ({
  handleLogout,
  isLoadingLogout,
}) => (
  // <Tooltip title="Logout">
  <div className={isLoadingLogout ? `${styles.iconSection__item} ${styles.iconLogoutDisableClick}` : `${styles.iconSection__item} ${styles.iconLogout}`}>
    <IconItem
      icon={<ExitToApp />}
      clicked={
                isLoadingLogout ? () => null : handleLogout
}
    />
    {isLoadingLogout && (
      <>
        <div className={styles.logoutLoadingOverlay} />
        <div className={styles.logoutLoadingSpinner}>
          <FeedbackSpinnerGeneral color={Color.yellowAccentCicle} />
        </div>
      </>
    )}
  </div>
  // </Tooltip>
);

InputButtonLogout.propTypes = {
  handleLogout: PropTypes.func.isRequired,
  isLoadingLogout: PropTypes.bool.isRequired,
};

export default memo(InputButtonLogout);
