import React, { memo, useState } from 'react';
import PropTypes from 'prop-types';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import styles from './DropdownMenu.module.css';
import { DisplayTextBody } from '../../../UI_V2/Display/Text';
import Color from '../../../../themes/colors';
import introImage from '../../../../assets/PrivateChatPage/intro-chat-section.png';
import { NavigationMenuBasic } from '../../../UI_V2/Navigation/Menu';

const DropdownMenu = ({
  children,
  onClose,
  disabled,
  position,
}) => (
  <NavigationMenuBasic
    size="md"
    onClose={onClose}
    disabled={disabled}
    position={position}
  >
    <div className={styles.container}>
      {children}
    </div>
  </NavigationMenuBasic>
);

DropdownMenu.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  position: PropTypes.string,
};

DropdownMenu.defaultProps = {
  children: null,
  disabled: false,
  position: null,
};

export default memo(DropdownMenu);
