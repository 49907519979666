import React, { memo, useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { useMediaQuery } from '@mui/material';
import styles from './ChartUserReport.module.css';
import Color from '../../../../themes/colors';
import ChartCardUserReport from './ChartCardUserReport/ChartCardUserReport';
import ChartMemberUserReport from './ChartMemberUserReport/ChartMemberUserReport';
import ChartStatUserReport from './ChartStatUserReport/ChartStatUserReport';

const ChartUserReport = ({
  member,
  fromDate,
  untilDate,
  handleSetAllCount,
  handleSetAllPercentage,
}) => {
  const [rating, setRating] = useState();
  const [ratio, setRatio] = useState();
  const [isEmptyData, setIsEmptyData] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  const handleSetIsLoading = useCallback((value) => {
    setIsLoading(value);
  }, []);

  const handleSetRating = useCallback((value) => {
    setRating(value);
  }, []);

  const handleSetRatio = useCallback((value) => {
    setRatio(value);
  }, []);

  const handleSetIsEmptyData = useCallback((value) => {
    setIsEmptyData(value);
  }, []);

  const isTablet = useMediaQuery('(max-width:1200px)');
  const isMobile = useMediaQuery('(max-width:720px)');
  return (
    <>
      <div className={styles.container}>
        <div className={styles.chart}>
          <ChartMemberUserReport
            member={member}
            fromDate={fromDate}
            untilDate={untilDate}
            rating={rating}
            isEmptyData={isEmptyData}
            isLoading={isLoading}
          />
        </div>
        <div className={isMobile ? `${styles.chart} ${styles.block}` : styles.chart}>
          <ChartCardUserReport
            fromDate={fromDate}
            untilDate={untilDate}
            handleSetRating={handleSetRating}
            handleSetRatio={handleSetRatio}
            handleSetIsEmptyData={handleSetIsEmptyData}
            handleSetAllCount={handleSetAllCount}
            handleSetAllPercentage={handleSetAllPercentage}
            handleSetIsLoading={handleSetIsLoading}
          />
        </div>
        {(!isTablet && !isMobile) && (
        <div className={styles.chart}>
          <ChartStatUserReport
            ratio={ratio}
            isEmptyData={isEmptyData}
            isLoading={isLoading}
          />
        </div>
        )}
      </div>
      {isTablet && (
      <div className={`${styles.chart} ${styles.block}`}>
        <ChartStatUserReport
          ratio={ratio}
          isEmptyData={isEmptyData}
          isLoading={isLoading}
        />
      </div>
      )}

    </>
  );
};

ChartUserReport.propTypes = {
  member: PropTypes.object.isRequired,
  fromDate: PropTypes.string.isRequired,
  untilDate: PropTypes.string.isRequired,
  handleSetAllCount: PropTypes.func.isRequired,
  handleSetAllPercentage: PropTypes.func.isRequired,
};

ChartUserReport.defaultProps = {};

export default memo(ChartUserReport);
