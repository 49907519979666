import React, { memo, useState } from 'react';
import PropTypes from 'prop-types';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import styles from './ActivityItem.module.css';
import Color from '../../../../themes/colors';
import { DisplayTextBody, DisplayTextSubBody } from '../../../../components/UI_V2/Display/Text';
import { ActivityConstants, PageConstants } from '../../../../constants';
import { DisplayDateBox, DisplayDateText } from '../../../../components/UI_V2/Display/Date';
import { DisplayAvatarMember } from '../../../../components/UI_V2/Display/Avatar';
import { getActionTextByServiceType } from '../../../../actions/ActivityActions';
import { handleCheckToday } from '../../../../utilities/dateUtil';
import PrivateIcon from '../../../../components/UI/PrivateIcon/PrivateIcon';
import LittleIcon from '../../../../components/UI/LittleIcon/LittleIcon';
import LinkNoDecor from '../../../../components/LinkNoDecor/LinkNoDecor';

const variantStyles = {
  regular: '',
  fullWidth: styles.fullWidth,
};

const ActivityItem = ({
  item, company, disableClick, variant,
}) => {
  const serviceType = item?.service.serviceType;
  const teamName = item?.team?.name;
  const senderName = item?.sender?.fullName;
  const senderPhoto = item?.sender?.photoUrl;
  const [
    actionText,
    locationText,
    activityTitleText,
    contentText,
    clickUrl,
    isForbidden,
  ] = getActionTextByServiceType({ serviceType, item, company });

  let icon;
  let iconBackgroundColor = Color.yellowAccentCicle;

  if (ActivityConstants.serviceTypeProperty[serviceType]) {
    icon = ActivityConstants.serviceTypeProperty[serviceType].icon;
    iconBackgroundColor = ActivityConstants.serviceTypeProperty[serviceType].iconBackgroundColor;
  }

  const isToday = handleCheckToday(item);
  return (
    <>
      {!isForbidden && disableClick && (
        <div className={`${styles.container} ${styles.disableClick}`}>
          {isForbidden && <div className={styles.blur} />}
          {isForbidden && (
          <div className={styles.blurText}>
            <div className={styles.blurIconPrivate}>
              <PrivateIcon data={{ isPublic: false }} size="small" />
            </div>
            <DisplayTextBody
              decoration="bold"
            >
              {`Aktifitas dari ${activityTitleText} rahasia`}
            </DisplayTextBody>
          </div>
          )}
          <div className={styles.image}>
            <div className={styles.photo}>
              <DisplayAvatarMember size="lg" src={senderPhoto} />
            </div>
            <LittleIcon color={iconBackgroundColor}>
              {icon}
            </LittleIcon>
          </div>
          <div className={`${styles.text} ${variantStyles[variant]}`}>
            <div className={styles.content}>
              <DisplayTextBody>
                <strong>{senderName}</strong>
                {' '}
                {actionText}
                {' '}
                <strong>{locationText}</strong>
              </DisplayTextBody>
            </div>
            {contentText && (
            <div className={styles.content}>
              <DisplayTextBody color={Color.gray3}>
                {`"${contentText}"`}
              </DisplayTextBody>
            </div>
            )}
            <div className={styles.info}>
              <div className={styles.teamName}>
                <DisplayTextBody
                  mode="11"
                  color={Color.gray3}
                >
                  {teamName && (
                  <>
                    {teamName}
                    {' - '}
                    {activityTitleText}
                  </>
                  )}

                  {!teamName && (
                  <>
                    {activityTitleText}
                  </>
                  )}
                </DisplayTextBody>
              </div>
              <div className={styles.date}>
                <DisplayDateBox
                  mode="11"
                  date={item?.createdAt}
                  relativeTime={!!isToday}
                  exactTime={!isToday}
                  customBackgroundColor={Color.lighterBlue2}
                  customTextColor={Color.gray3}
                />
              </div>
            </div>
          </div>
        </div>
      )}
      {!isForbidden && !disableClick && (
      <LinkNoDecor to={clickUrl}>
        <div className={styles.container}>
          {isForbidden && <div className={styles.blur} />}
          {isForbidden && (
          <div className={styles.blurText}>
            <div className={styles.blurIconPrivate}>
              <PrivateIcon data={{ isPublic: false }} size="small" />
            </div>
            <DisplayTextBody
              decoration="bold"
            >
              {`Aktifitas dari ${activityTitleText} rahasia`}
            </DisplayTextBody>
          </div>
          )}
          <div className={styles.image}>
            <div className={styles.photo}>
              <DisplayAvatarMember size="lg" src={senderPhoto} />
            </div>
            <LittleIcon color={iconBackgroundColor}>
              {icon}
            </LittleIcon>
          </div>
          <div className={`${styles.text} ${variantStyles[variant]}`}>
            <div className={styles.content}>
              <DisplayTextBody>
                <strong>{senderName}</strong>
                {' '}
                {actionText}
                {' '}
                <strong>{locationText}</strong>
              </DisplayTextBody>
            </div>
            {contentText && (
            <div className={styles.content}>
              <DisplayTextBody color={Color.gray3}>
                {`"${contentText}"`}
              </DisplayTextBody>
            </div>
            )}
            <div className={styles.info}>
              <div className={styles.teamName}>
                <DisplayTextBody
                  mode="11"
                  color={Color.gray3}
                >
                  {teamName && (
                  <>
                    {teamName}
                    {' - '}
                    {activityTitleText}
                  </>
                  )}

                  {!teamName && (
                  <>
                    {activityTitleText}
                  </>
                  )}
                </DisplayTextBody>
              </div>
              <div className={styles.date}>
                <DisplayDateBox
                  mode="11"
                  date={item?.createdAt}
                  relativeTime={!!isToday}
                  exactTime={!isToday}
                  customBackgroundColor={Color.lighterBlue2}
                  customTextColor={Color.gray3}
                />
              </div>
            </div>
          </div>
        </div>
      </LinkNoDecor>
      )}
    </>
  );
};

ActivityItem.propTypes = {
  item: PropTypes.object.isRequired,
  company: PropTypes.object.isRequired,
  disableClick: PropTypes.bool,
  variant: PropTypes.oneOf(['fullWidth', 'regular']),
};

ActivityItem.defaultProps = {
  disableClick: false,
  variant: 'fullWidth',
};

export default memo(ActivityItem);
