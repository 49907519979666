import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { useMediaQuery } from '@mui/material';
import styles from './Item.module.css';
import { DisplayTextBody } from '../../../../components/UI_V2/Display/Text';
import Color from '../../../../themes/colors';
import { InputButtonMain } from '../../../../components/UI_V2/Input/Button';
import { dateBeautifyFormatter, timeBeautifyFormatter } from '../../../../utilities/dateUtil';
import { formatToBasicThousandSeparator } from '../../../../utilities/numberUtil';
import LinkNoDecorV2 from '../../../../components/LinkNoDecor/LinkNoDecorV2/LinkNoDecorV2';

const statusStyles = {
  PAID: 'blueLight',
  SETTLED: 'blueLight',
  PENDING: 'default',
  EXPIRED: 'outlinedLight',
};

const statusTitleStyles = {
  PAID: 'LUNAS',
  SETTLED: 'LUNAS',
  PENDING: 'PENDING',
  EXPIRED: 'BATAL',
};

const InvoicesSectionItem = ({
  invoice,
  handleClickExpireInvoice,
}) => {
  const {
    status,
    expDate,
    paidDate,
    desc,
    total,
    invoiceUrl,
    invoiceNumber,
  } = invoice;
  const isTablet = useMediaQuery('(max-width:960px)');

  const isStatusUnpaid = status === 'PENDING';
  const isStatusExpired = status === 'EXPIRED';
  const isStatusPaid = status === 'PAID' || status === 'SETTLED';

  const finalAmount = formatToBasicThousandSeparator(total);
  const isDiscAmountExist = invoice.order.discountAmount > 0;
  const finalDiscAmount = isDiscAmountExist
  && formatToBasicThousandSeparator(invoice.order.discountAmount);

  const getEstSubscriptionDateFromItems = (items) => {
    if (!items || items.length < 1) return null;
    const index = items.findIndex((elem) => elem.estSubscriptionStartDate);

    let result;
    if (index !== -1) {
      const item = items[index];
      result = {
        estSubscriptionStartDate: dateBeautifyFormatter(item.estSubscriptionStartDate, true, 'MonthDateYear'),
        estSubscriptionEndDate: dateBeautifyFormatter(item.estSubscriptionEndDate, true, 'MonthDateYear'),
      };
    }

    return result;
  };

  const subscriptionDate = getEstSubscriptionDateFromItems(invoice?.order?.items);

  return (
    <div className={styles.columnInvoice}>
      <div className={styles.columnInvoice__invNumber}>
        <DisplayTextBody
          color={Color.black}
          mode="12"
          fontFamily="openSans"
        >
          {invoiceNumber}
        </DisplayTextBody>
      </div>
      <div className={styles.columnInvoice__status}>
        <InputButtonMain handleClick={() => null} border="default" wide="block" variant={statusStyles[status]} size="xs">
          {statusTitleStyles[status]}
        </InputButtonMain>
      </div>
      <div className={styles.columnInvoice__expDate}>
        <DisplayTextBody
          color={Color.black}
          mode="12"
          fontFamily="openSans"
        >
          {isStatusExpired && `Batal pada : ${dateBeautifyFormatter(expDate, true, 'MonthDateYear')} ${timeBeautifyFormatter(expDate)}`}
          {isStatusPaid && `Lunas pada : ${dateBeautifyFormatter(paidDate, true, 'MonthDateYear')} ${timeBeautifyFormatter(paidDate)}`}
          {isStatusUnpaid && `Bayar sebelum : ${dateBeautifyFormatter(expDate, true, 'MonthDateYear')} ${timeBeautifyFormatter(expDate)}`}
        </DisplayTextBody>
      </div>
      <div className={styles.columnInvoice__description}>
        <div className={styles.columnInvoice__description__name}>
          <DisplayTextBody
            color={Color.black}
            mode="12"
            fontFamily="openSans"
          >
            {desc}
          </DisplayTextBody>
        </div>
        {subscriptionDate?.estSubscriptionStartDate && (
        <div className={styles.columnInvoice__description__duration}>
          <DisplayTextBody
            color={Color.gray5}
            mode="12"
            fontFamily="openSans"
          >
            {`${subscriptionDate?.estSubscriptionStartDate} - ${subscriptionDate?.estSubscriptionEndDate}`}
          </DisplayTextBody>
        </div>
        )}
      </div>
      {/* <div className={styles.columnInvoice__subscriptionDate}>
        <DisplayTextBody
          color={Color.black}
          mode="12"
          fontFamily="openSans"
        >
          {`${dateBeautifyFormatter(estSubscriptionStartDate, true, 'MonthDateYear')} -
          ${dateBeautifyFormatter(estSubscriptionEndDate, true, 'MonthDateYear')}`}
        </DisplayTextBody>
      </div> */}
      <div className={styles.columnInvoice__discAmount}>
        <DisplayTextBody
          color={Color.black}
          mode="12"
          fontFamily="openSans"
        >
          {isDiscAmountExist && `Rp. -${finalDiscAmount}`}
          {!isDiscAmountExist && 'Rp. 0'}
        </DisplayTextBody>
      </div>
      <div className={styles.columnInvoice__amount}>
        <DisplayTextBody
          color={Color.black}
          mode="12"
          fontFamily="openSans"
        >
          {`Rp. ${finalAmount}`}
        </DisplayTextBody>
      </div>
      <div className={styles.columnInvoice__LinkAndExpire}>
        <div className={styles.columnInvoice__invoiceUrl}>
          <LinkNoDecorV2 isOpenNewTab to={invoiceUrl}>
            <InputButtonMain border="rounded" variant="outlinedSuccess" size="sm">
              Buka Link
            </InputButtonMain>
          </LinkNoDecorV2>
        </div>
        {isStatusUnpaid && (
        <div className={styles.columnInvoice__action}>
          <InputButtonMain
            handleClick={() => handleClickExpireInvoice(invoice._id)}
            border="rounded"
            variant="outlinedDanger"
            size="xs"
          >
            Batalkan
          </InputButtonMain>
        </div>
        )}
      </div>
    </div>
  );
};

InvoicesSectionItem.propTypes = {
  invoice: PropTypes.object.isRequired,
  handleClickExpireInvoice: PropTypes.func.isRequired,
};

export default memo(InvoicesSectionItem);
