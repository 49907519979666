const limitMyTaskCardList = 20;
const limitMyTaskCardCalendar = 100;

const typeCallback = {
  NEW: 'new',
  DELETE: 'delete',
  EDIT: 'edit',
};

export {
  limitMyTaskCardList,
  limitMyTaskCardCalendar,
  typeCallback,
};
