import React, {
  useState, useContext, useEffect, memo, useCallback,
} from 'react';
import PropTypes from 'prop-types';
import PersonAddAlt1OutlinedIcon from '@mui/icons-material/PersonAddAlt1Outlined';
import _ from 'lodash';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { useSnackbar } from 'notistack';
import { useParams, useHistory, useLocation } from 'react-router-dom';
import { InputButtonMain } from '../../UI_V2/Input/Button';
import styles from './MyRoleUser.module.css';
import ImageMember from '../../UI/ImageMember/ImageMember';
import {
  DisplayTextBody, DisplayTextHeadline, DisplayTextSubBody, DisplayTextSubHeadline,
} from '../../UI_V2/Display/Text';
import UserItem from '../../../pages/CompanyPage/TabInviteMemberContainer/UserItem/UserItem';
import { RoleActions } from '../../../actions';
import { GlobalContext, initialState } from '../../../contexts/GlobalStateProvider';
import { useInfinityScrollHooks } from '../../../hooks/InfinityScrollHooks';
import { RoleConstants } from '../../../constants';
import handleStatusMsg from '../../../utilities/handleStatusMsg';
import InfinityScroll from '../../UI/InfinityScroll/InfinityScroll';
import { FeedbackDialogGeneral } from '../../UI_V2/Feedback/Dialog';
import Color from '../../../themes/colors';
import handleLoadings from '../../../utilities/handleLoadings';
import { hasPermission } from '../../../actions/RoleActions';
import { roleTypeRef, roles, typeRef } from '../../../constants/RoleConstants';
import FeedbackLoadingMain from '../../UI_V2/Feedback/Loading/Main/Main';
import { useDelayShowHideHandler } from '../../../hooks/HelperHooks';
import MyRoleSection from './MyRoleSection/MyRoleSection';
import ListRoleBadgeSection from './ListRoleBadgeSection/ListRoleBadgeSection';
import ManageRoleSection from './ManageRoleSection/ManageRoleSection';

const MyRoleUser = ({
  open,
  onClose,
  featureType,
  featureId,
  featureTitle,
  roleType,
  featureData,
}) => {
  const [{
    user, currentRoleUsers, previousRoleUsers, currentCompany, currentRoleUser,
  }, dispatch] = useContext(GlobalContext);
  const [loadings, setLoadings] = useState([]);
  const [changedRoleUsers, setChangedRoleUsers] = useState([]);
  const [showMyRoleSection, setShowMyRoleSection] = useState(true);
  const [showListRoleBadgeSection, setShowListRoleBadgeSection] = useState(false);
  const [showManageRoleSection, setShowManageRoleSection] = useState(false);

  const handleShowMyRoleSection = useCallback(() => {
    setShowMyRoleSection(true);
  }, []);

  const handleHideMyRoleSection = useCallback(() => {
    setShowMyRoleSection(false);
  }, []);

  const handleShowListRoleBadgeSection = useCallback(() => {
    setShowListRoleBadgeSection(true);
  }, []);

  const handleHideListRoleBadgeSection = useCallback(() => {
    setShowListRoleBadgeSection(false);
  }, []);

  const handleShowManageRoleSection = useCallback(() => {
    setShowManageRoleSection(true);
  }, []);

  const handleHideManageRoleSection = useCallback(() => {
    setShowManageRoleSection(false);
  }, []);

  const handleCloseListRoleBadgeSection = useCallback(() => {
    handleHideListRoleBadgeSection();
    handleHideManageRoleSection();
    handleShowMyRoleSection();
  }, []);

  const handleCloseManageRoleSection = useCallback(() => {
    handleHideManageRoleSection();
    handleHideListRoleBadgeSection();
    handleShowMyRoleSection();
  }, []);

  useEffect(() => {
    handleShowMyRoleSection();
    handleHideManageRoleSection();
    handleHideListRoleBadgeSection();
  }, [open]);

  const params = useParams();
  const history = useHistory();
  const location = useLocation();
  const { companyId, teamId } = params;

  const { enqueueSnackbar } = useSnackbar();

  const isUserManagerOrAbove = hasPermission(currentRoleUser, roles.managerOrAbove);
  const isUserSpvOrBelow = hasPermission(currentRoleUser, roles.spvOrBelow);

  const isRoleTypeCompany = roleType === roleTypeRef.company;
  return (
    <FeedbackDialogGeneral
      open={open}
      onClose={onClose}
    >
      <div className={styles.container}>
        {showMyRoleSection
          && (
          <MyRoleSection
            featureType={featureType}
            featureId={featureId}
            featureTitle={featureTitle}
            user={user}
            currentRoleUser={currentRoleUser}
            roleType={roleType}
            handleShowListRoleBadgeSection={handleShowListRoleBadgeSection}
            handleHideMyRoleSection={handleHideMyRoleSection}
            handleShowManageRoleSection={handleShowManageRoleSection}
          />
          )}
        {showListRoleBadgeSection
          && (
          <ListRoleBadgeSection
            featureType={featureType}
            featureTitle={featureTitle}
            onBack={handleCloseListRoleBadgeSection}
            isRoleTypeCompany={isRoleTypeCompany}
          />
          )}

        {showManageRoleSection && (
          <ManageRoleSection
            roleType={roleType}
            featureType={featureType}
            featureId={featureId}
            featureTitle={featureTitle}
            onBack={handleCloseManageRoleSection}
            featureData={featureData}
          />
        )}
      </div>
    </FeedbackDialogGeneral>
  );
};

MyRoleUser.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  featureType: PropTypes.oneOf([
    typeRef.team,
    typeRef.board,
    typeRef.card,
  ]),
  featureId: PropTypes.string.isRequired,
  featureTitle: PropTypes.string.isRequired,
  roleType: PropTypes.oneOf([
    roleTypeRef.company,
    roleTypeRef.team,
    roleTypeRef.feature,
  ]),
  featureData: PropTypes.object.isRequired,
};

MyRoleUser.defaultProps = {
  featureType: typeRef.team,
  roleType: roleTypeRef.team,
};

export default memo(MyRoleUser);
