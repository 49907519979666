/* eslint-disable consistent-return */
import { cloneDeep } from 'lodash';
import { useCallback, useContext } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { BucketActions, DocActions, FileActions } from '../actions';
import { BucketConstants, DocConstants, FileConstants } from '../constants';
import { connectSocketBucket } from '../services/socket';
import { useSocketHooks } from './SocketHooks';
import { GlobalContext } from '../contexts/GlobalStateProvider';
import { ErrorException } from '../utilities/handleError';

function useFolderSocket({ bucketId, userId }, dispatch) {
  const callbackNewBucket = useCallback((bucket) => {
    BucketActions.incomingFolder({
      bucket,
      bucketId,
      typeAction: BucketConstants.typeCallback.NEW,
    }, dispatch);
  }, [bucketId]);
  const callbackUpdateBucket = useCallback((bucket) => {
    BucketActions.incomingFolder({
      bucket,
      typeAction: BucketConstants.typeCallback.EDIT,
      bucketId,
    }, dispatch);
  }, [bucketId]);
  const callbackArchiveBucket = useCallback((bucket) => {
    BucketActions.incomingFolder({
      bucket,
      bucketId,
      typeAction: BucketConstants.typeCallback.DELETE,
    }, dispatch);
  }, [bucketId]);

  // doc callback
  const callbackNewDoc = useCallback((bucket) => {
    BucketActions.incomingFolder({
      bucket,
      typeAction: DocConstants.typeCallback.NEW,
      keyProperty: 'docs',
      bucketId,
    }, dispatch);
  }, [bucketId]);
  const callbackUpdateDoc = useCallback((bucket) => {
    BucketActions.incomingFolder({
      bucket,
      typeAction: DocConstants.typeCallback.EDIT,
      keyProperty: 'docs',
      bucketId,
    }, dispatch);
  }, [bucketId]);
  const callbackArchiveDoc = useCallback((bucket) => {
    BucketActions.incomingFolder({
      bucket,
      typeAction: DocConstants.typeCallback.DELETE,
      keyProperty: 'docs',
      bucketId,
    }, dispatch);
  }, [bucketId]);

  // file callback
  const callbackNewFile = useCallback((bucket) => {
    BucketActions.incomingFolder({
      bucket,
      typeAction: FileConstants.typeCallback.NEW,
      keyProperty: 'files',
      bucketId,
    }, dispatch);
  }, [bucketId]);
  const callbackUpdateFile = useCallback((bucket) => {
    BucketActions.incomingFolder({
      bucket,
      typeAction: FileConstants.typeCallback.EDIT,
      keyProperty: 'files',
      bucketId,
    }, dispatch);
  }, [bucketId]);
  const callbackArchiveFile = useCallback((bucket) => {
    BucketActions.incomingFolder({
      bucket,
      typeAction: FileConstants.typeCallback.DELETE,
      keyProperty: 'files',
      bucketId,
    }, dispatch);
  }, [bucketId]);

  const listenSocket = (socket, params) => {
    socket
      .on(`bucket-new-${params?.bucketId}`, callbackNewBucket)
      .on(`bucket-update-${params?.bucketId}`, callbackUpdateBucket)
      .on(`bucket-archive-${params?.bucketId}`, callbackArchiveBucket)
      .on(`doc-new-${params?.bucketId}`, callbackNewDoc)
      .on(`doc-update-${params?.bucketId}`, callbackUpdateDoc)
      .on(`doc-archive-${params?.bucketId}`, callbackArchiveDoc)
      .on(`file-new-${params?.bucketId}`, callbackNewFile)
      .on(`file-update-${params?.bucketId}`, callbackUpdateFile)
      .on(`file-archive-${params?.bucketId}`, callbackArchiveFile);
  };
  const removeListener = (socket, params) => {
    socket
      .off(`bucket-new-${params?.bucketId}`)
      .off(`bucket-update-${params?.bucketId}`)
      .off(`bucket-archive-${params?.bucketId}`)
      .off(`doc-new-${params?.bucketId}`)
      .off(`doc-update-${params?.bucketId}`)
      .off(`doc-archive-${params?.bucketId}`)
      .off(`file-new-${params?.bucketId}`)
      .off(`file-update-${params?.bucketId}`)
      .off(`file-archive-${params?.bucketId}`);
    socket.disconnect();
  };

  useSocketHooks({
    params: { bucketId, userId },
    connectSocket: connectSocketBucket,
    listenSocket,
    removeListener,
  });
}

function useBucketSocket({ bucketId, userId }, dispatch) {
  // bucket callback
  const callbackNewBucket = useCallback((bucket) => {
    BucketActions.incomingBucket({
      bucket,
      typeAction: BucketConstants.typeCallback.NEW,
      bucketId,
    }, dispatch);
  }, [bucketId]);
  const callbackBucket = useCallback((bucket) => {
    BucketActions.incomingBucketItSelf({
      bucket,
      bucketId,
      typeAction: BucketConstants.typeCallback.EDIT,
    }, dispatch);
  }, [bucketId]);
  const callbackArchiveBucket = useCallback((bucket) => {
    BucketActions.incomingBucketItSelf({
      bucket,
      bucketId,
      typeAction: BucketConstants.typeCallback.DELETE,
    }, dispatch);
  }, [bucketId]);

  // doc callback
  const callbackNewDoc = useCallback((bucket) => {
    BucketActions.incomingBucket({
      bucket,
      typeAction: DocConstants.typeCallback.NEW,
      keyProperty: 'docs',
      bucketId,
    }, dispatch);
  }, [bucketId]);
  const callbackUpdateDoc = useCallback((bucket) => {
    BucketActions.incomingBucket({
      bucket,
      typeAction: DocConstants.typeCallback.EDIT,
      keyProperty: 'docs',
      bucketId,
    }, dispatch);
  }, [bucketId]);
  const callbackArchiveDoc = useCallback((bucket) => {
    BucketActions.incomingBucket({
      bucket,
      typeAction: DocConstants.typeCallback.DELETE,
      keyProperty: 'docs',
      bucketId,
    }, dispatch);
  }, [bucketId]);

  // file callback
  const callbackNewFile = useCallback((bucket) => {
    BucketActions.incomingBucket({
      bucket,
      typeAction: FileConstants.typeCallback.NEW,
      keyProperty: 'files',
      bucketId,
    }, dispatch);
  }, [bucketId]);
  const callbackUpdateFile = useCallback((bucket) => {
    BucketActions.incomingBucket({
      bucket,
      typeAction: FileConstants.typeCallback.EDIT,
      keyProperty: 'files',
      bucketId,
    }, dispatch);
  }, [bucketId]);
  const callbackArchiveFile = useCallback((bucket) => {
    BucketActions.incomingBucket({
      bucket,
      typeAction: FileConstants.typeCallback.DELETE,
      keyProperty: 'files',
      bucketId,
    }, dispatch);
  }, [bucketId]);

  const listenSocket = (socket, params) => {
    socket
      .on(`bucket-new-${params?.bucketId}`, callbackNewBucket)
      .on(`bucket-update-${params?.bucketId}`, callbackBucket)
      .on(`bucket-archive-${params?.bucketId}`, callbackArchiveBucket)
      .on(`doc-new-${params?.bucketId}`, callbackNewDoc)
      .on(`doc-update-${params?.bucketId}`, callbackUpdateDoc)
      .on(`doc-archive-${params?.bucketId}`, callbackArchiveDoc)
      .on(`file-new-${params?.bucketId}`, callbackNewFile)
      .on(`file-update-${params?.bucketId}`, callbackUpdateFile)
      .on(`file-archive-${params?.bucketId}`, callbackArchiveFile);
  };
  const removeListener = (socket, params) => {
    socket
      .off(`bucket-new-${params?.bucketId}`)
      .off(`bucket-update-${params?.bucketId}`)
      .off(`bucket-archive-${params?.bucketId}`)
      .off(`doc-new-${params?.bucketId}`)
      .off(`doc-update-${params?.bucketId}`)
      .off(`doc-archive-${params?.bucketId}`)
      .off(`file-new-${params?.bucketId}`)
      .off(`file-update-${params?.bucketId}`)
      .off(`file-archive-${params?.bucketId}`);
    socket.disconnect();
  };

  useSocketHooks({
    params: { bucketId, userId },
    connectSocket: connectSocketBucket,
    listenSocket,
    removeListener,
  });
}

function useArchivedBucketItemsHooks() {
  const [{ user, currentBucketItems }, dispatch] = useContext(GlobalContext);
  const params = useParams();
  const history = useHistory();
  const { companyId, teamId, bucketId } = params;

  const initiateListData = async ({
    filters, page, limit,
  }) => {
    try {
      const filtersModified = {
        ...filters,
        filterArchived: true,
      };
      const result = await BucketActions.initiateBucketItems({
        filters: filtersModified,
        companyId,
        teamId,
        bucketId,
        limit,
      });
      return result;
    } catch (error) {
      throw new ErrorException(error);
    }
  };

  const loadMoreListData = async ({
    filters, page, limit, currentListData,
  }) => {
    try {
      const filtersModified = {
        ...filters,
        filterArchived: true,
      };
      const result = await BucketActions.loadMoreBucketItems({
        filters: filtersModified,
        currentBucketItems: currentListData,
        companyId,
        teamId,
        bucketId,
        limit,
      });
      return result;
    } catch (error) {
      throw new ErrorException(error);
    }
  };

  const unarchiveData = async ({
    item,
  }) => {
    try {
      switch (item.type) {
        case 'doc':
          await DocActions.unarchiveDoc({
            companyId,
            teamId,
            docId: item._id,
          });
          break;
        case 'file':
          await FileActions.unarchiveFile({
            companyId,
            teamId,
            fileId: item._id,
          });
          break;
        case 'bucket':
          await BucketActions.unarchiveBucket({
            companyId,
            teamId,
            bucketId: item._id,
          });
          break;
        default:
          break;
      }
      history.push(`/companies/${companyId}/teams/${teamId}/buckets/${bucketId}`);
    } catch (error) {
      throw new ErrorException(error);
    }
  };

  return [initiateListData, loadMoreListData, unarchiveData];
}

export {
  useFolderSocket,
  useBucketSocket,
  useArchivedBucketItemsHooks,
};
