import React, { memo, useContext, useState } from 'react';
import PropTypes from 'prop-types';
import DownloadIcon from '@mui/icons-material/Download';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { Tooltip, useMediaQuery } from '@mui/material';
import MapsHomeWorkOutlinedIcon from '@mui/icons-material/MapsHomeWorkOutlined';
import { useLocation } from 'react-router-dom';
import styles from './CompanySection.module.css';
import Color from '../../../themes/colors';
import { InputButtonMain } from '../../UI_V2/Input/Button';
import { DisplayTextBody } from '../../UI_V2/Display/Text';
import NavIcon from '../../UI/NavIcon/NavIcon';
import { GlobalContext } from '../../../contexts/GlobalStateProvider';
import { DisplayBadgeCounter } from '../../UI_V2/Display/Badge';
import { limitChar } from '../../../utilities/stringUtil';

const CompanySection = ({ handleShowCompaniesSelect }) => {
  const [{
    currentCompany,
    counterCompaniesNotif,
  }] = useContext(GlobalContext);
  const isMobile = useMediaQuery('(max-width:720px)');

  return (
    <div className={styles.container}>
      <DisplayTextBody
        mode="14"
        decoration="bold"
      >
        {limitChar(currentCompany?.name, isMobile ? 8 : 35)}
      </DisplayTextBody>
      <div className={styles.navIconSection} onClick={handleShowCompaniesSelect}>
        <NavIcon>
          <Tooltip title="Ganti Perusahaan">
            <MapsHomeWorkOutlinedIcon />
          </Tooltip>
          <DisplayBadgeCounter
            badgeContent={counterCompaniesNotif.totalCounter}
            variant="dot"
            height="10px"
            minWidth="10px"
            customStyle={{ top: -6, right: -2 }}
          />
        </NavIcon>
      </div>
    </div>
  );
};

CompanySection.propTypes = {
  handleShowCompaniesSelect: PropTypes.func,
};

CompanySection.defaultProps = {
  handleShowCompaniesSelect: () => null,
};

export default memo(CompanySection);
