import React, {
  memo, forwardRef,
} from 'react';
import PropTypes from 'prop-types';
import SearchOutlinedIcon from '@mui/icons-material/SearchOutlined';
import FilterAltOutlinedIcon from '@mui/icons-material/FilterAltOutlined';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { Box } from '@mui/material';
import { isEqual } from 'lodash';
import Color from '../../../../../themes/colors';
import styles from './General.module.css';

const boxStyles = {
  background: Color.white,
  border: `1px solid ${Color.gray5}`,
  boxSizing: 'border-box',
  width: '100%',
  display: 'flex',
  alignItems: 'center',
  padding: '2px 6px',
  position: 'relative',
};

const borderStyles = {
  default: {
    borderRadius: '5px',
  },
  semiRounded: {
    borderRadius: '10px',
  },
  rounded: {
    borderRadius: '15px',
  },
};

const boxSizeStyles = {
  sm: {
    height: '26px',
  },
  md: {
    height: '32px',
  },
  lg: {
    height: '38px',
  },
};

const inputSizeStyles = {
  sm: {
    fontSize: '10px',
  },
  md: {
    fontSize: '12px',
  },
  lg: {
    fontSize: '14px',
  },
};

const helperIconStyles = {
  cursor: 'pointer',
};

const closeIconBoxStyles = {
  marginRight: '3px',
};

const closeIconStyles = {
  cursor: 'pointer',
};

const boxIconVariantStyles = {
  outlined: {
    border: `1px solid ${Color.gray4}`,
    borderRadius: '4px',
    padding: '1px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  regular: {},
};

const iconSizeStyles = {
  sm: {
    width: '16px',
    height: '16px',
  },
  md: {
    width: '20px',
    height: '20px',
  },
  lg: {
    width: '24px',
    height: '24px',
  },
};

const InputSearchGeneral = forwardRef(({
  autoFocus,
  value,
  placeholder,
  border,
  onClick,
  onClose,
  onChange,
  size,
  searchIcon,
  searchIconVariant,
  searchIconColor,
  onClickSearchIcon,
  disableSearchIcon,
  helperIcon,
  helperIconVariant,
  helperIconColor,
  onClickHelperIcon,
  disableHelperIcon,
  closeIcon,
  closeIconVariant,
  closeIconColor,
  onClickCloseIcon,
  disableCloseIcon,
}, ref) => {
  const showSearchIcon = value.length < 1 && !disableSearchIcon;
  const showCloseIcon = value.length > 0 && !disableCloseIcon;
  const showHelperIcon = !disableHelperIcon;
  return (
    <div
      style={{
        ...boxStyles,
        ...borderStyles[border],
        ...boxSizeStyles[size],
      }}
    >
      {showSearchIcon ? (
        <Box
          sx={{
            ...closeIconBoxStyles,
            ...boxIconVariantStyles[searchIconVariant],
            '& svg': {
              ...closeIconStyles,
              ...iconSizeStyles[size],
              color: searchIconColor,
            },
          }}
          onClick={onClickSearchIcon}
        >
          {searchIcon ? <>{searchIcon}</> : <SearchOutlinedIcon />}
        </Box>
      ) : null}
      {showCloseIcon ? (
        <Box
          sx={{
            ...closeIconBoxStyles,
            ...boxIconVariantStyles[closeIconVariant],
            '& svg': {
              ...closeIconStyles,
              ...iconSizeStyles[size],
              color: closeIconColor,
            },
          }}
          onClick={onClickCloseIcon}
        >
          {closeIcon ? <>{closeIcon}</> : <ArrowBackIcon />}
        </Box>
      ) : null}
      <input
        ref={ref}
        onClick={onClick}
        style={{
          ...inputSizeStyles[size],
        }}
        className={styles.input}
          // eslint-disable-next-line jsx-a11y/no-autofocus
        autoFocus={autoFocus}
        onChange={(event) => onChange(event.target.value)}
        placeholder={placeholder}
        value={value}
      />
      {showHelperIcon ? (
        <Box
          sx={{
            ...boxIconVariantStyles[helperIconVariant],
            '& svg': {
              ...helperIconStyles,
              ...iconSizeStyles[size],
              color: helperIconColor,
            },
          }}
          onClick={onClickHelperIcon}
        >
          {helperIcon ? <>{helperIcon}</> : <FilterAltOutlinedIcon />}
        </Box>
      ) : null}
    </div>
  );
});

InputSearchGeneral.propTypes = {
  disableSearchIcon: PropTypes.bool,
  disableHelperIcon: PropTypes.bool,
  disableCloseIcon: PropTypes.bool,
  autoFocus: PropTypes.bool,
  value: PropTypes.string.isRequired,
  placeholder: PropTypes.string,
  border: PropTypes.oneOf(['default', 'semiRounded', 'rounded']),
  searchIcon: PropTypes.node,
  helperIcon: PropTypes.node,
  closeIcon: PropTypes.node,
  onClickHelperIcon: PropTypes.func,
  onClickSearchIcon: PropTypes.func,
  onChange: PropTypes.func.isRequired,
  size: PropTypes.oneOf(['sm', 'md', 'lg']),
  onClickCloseIcon: PropTypes.func,
  onClick: PropTypes.func,
  onClose: PropTypes.func,
  searchIconVariant: PropTypes.oneOf(['default', 'outlined']),
  helperIconVariant: PropTypes.oneOf(['default', 'outlined']),
  closeIconVariant: PropTypes.oneOf(['default', 'outlined']),
  searchIconColor: PropTypes.string,
  helperIconColor: PropTypes.string,
  closeIconColor: PropTypes.string,
};

InputSearchGeneral.defaultProps = {
  disableSearchIcon: false,
  disableHelperIcon: true,
  disableCloseIcon: false,
  autoFocus: true,
  placeholder: 'Search items...',
  border: 'default',
  searchIcon: null,
  helperIcon: null,
  closeIcon: null,
  onClick: () => null,
  onClose: () => null,
  onClickCloseIcon: () => null,
  onClickHelperIcon: () => null,
  onClickSearchIcon: () => null,
  size: 'md',
  helperIconVariant: 'default',
  closeIconVariant: 'default',
  searchIconVariant: 'default',
  searchIconColor: Color.yellowAccentCicle,
  helperIconColor: Color.gray5,
  closeIconColor: Color.yellowAccentCicle,
};

const isComponentDataEqual = (prevProps, nextProps) => {
  const {
    value,
    onClick,
    onClose,
  } = nextProps;
  return isEqual(prevProps.value, value)
  && isEqual(prevProps.onClick, onClick)
  && isEqual(prevProps.onClose, onClose);
};

export default memo(InputSearchGeneral, isComponentDataEqual);
