import React, {
  memo, useState, useEffect, useReducer, useCallback,
} from 'react';
import PropTypes from 'prop-types';
import { Box } from '@mui/material';
import MoreHorizOutlinedIcon from '@mui/icons-material/MoreHorizOutlined';
import { useSnackbar } from 'notistack';
import { DisplayTextBody, DisplayTextSubHeadline } from '../../../../../components/UI_V2/Display/Text';
import ResultItemDoc from './ResultItemDoc/ResultItemDoc';
import { limitChar } from '../../../../../utilities/stringUtil';
import { SearchJumpToConstants } from '../../../../../constants';
import Color from '../../../../../themes/colors';
import LinkNoDecor from '../../../../../components/LinkNoDecor/LinkNoDecor';
import TitleJumpTo from '../../JumpTo/TitleJumpTo/TitleJumpTo';
import { SearchJumpToActions } from '../../../../../actions';
import { useInfinityScrollHooks } from '../../../../../hooks/InfinityScrollHooks';
import handleLoadings from '../../../../../utilities/handleLoadings';
import handleStatusMsg from '../../../../../utilities/handleStatusMsg';
import OverlayButton from '../../../../../components/UI/Button/OverlayButton/OverlayButton';
import { FeedbackLoadingMain } from '../../../../../components/UI_V2/Feedback/Loading';
import SkeletonListResult from '../SkeletonListResult/SkeletonListResult';

const showMoreTextStyles = {
  display: 'flex',
  alignItems: 'center',
  height: '20px',
  paddingTop: '1px',
  paddingBottom: '1px',
  paddingLeft: '8px',
  paddingRight: '8px',
  width: '95%',
  cursor: 'pointer',
  marginTop: '5px',
  '&:hover': {
    opacity: '0.8',
  },
};

const iconBoxStyles = {
  marginRight: '13px',
};

const iconStyles = {
  color: Color.blueNavy4,
};

const ListResultDoc = ({
  selectedIndex, onClose, title, keywordArray, debouncedKeyword,
  params, handleSetTotal, filters,
}) => {
  const initialStatePreviousSearchDoc = {
    data: new Array(SearchJumpToConstants.limitSearch),
  };
  const initialStateCurrentSearchDoc = {
    data: [],
  };
  const [previousSearchDoc,
    setPreviousSearchDoc] = useState(initialStatePreviousSearchDoc);
  const [currentSearchDoc,
    setCurrentSearchDoc] = useState(initialStateCurrentSearchDoc);
  const [shouldFetch, setShouldFetch] = useState(true);
  const [loadings, setLoadings] = useState([]);

  const { companyId, teamId } = params;

  const { enqueueSnackbar } = useSnackbar();
  const getMoreLists = async () => {
    try {
      const startLoadings = handleLoadings('loadMoreSearchDoc', [...loadings], 'start');
      setLoadings([...startLoadings]);
      const result = await SearchJumpToActions.loadMoreSearchDoc({
        currentSearchDoc,
        setCurrentSearchDoc,
        setPreviousSearchDoc,
        companyId,
        keyword: debouncedKeyword,
        filters,
      });
      return result;
    } catch (error) {
      const status = handleStatusMsg(error, 'error');

      enqueueSnackbar(status.message, {
        variant: 'error',
      });
      return error;
    } finally {
      const endLoadings = handleLoadings('loadMoreSearchDoc', [...loadings], 'end');
      setLoadings([...endLoadings]);
    }
  };

  const resetPreviousLists = useCallback(() => {
    setPreviousSearchDoc(initialStatePreviousSearchDoc);
    setCurrentSearchDoc(initialStateCurrentSearchDoc);
  }, []);

  const {
    lists,
    handleLoadMoreLists,
    checkIfListNeedToLoad,
    setCheckLoadMore,
    isListsLengthAtTheLimitOrAbove,
  } = useInfinityScrollHooks({
    currentObjectWithKeyProperty: currentSearchDoc,
    previousLists: previousSearchDoc?.data,
    resetPreviousLists,
    getMoreLists,
    keyProperty: 'data',
    limitList: SearchJumpToConstants.limitSearch,
  });

  const initiateSearchDocApi = async () => {
    try {
      // if (debouncedKeyword === anchorKeyword) return;
      const startLoadings = handleLoadings('initiateSearchDoc', [...loadings], 'start');
      setLoadings([...startLoadings]);

      const result = await SearchJumpToActions.initiateSearchDoc({
        setCurrentSearchDoc,
        setPreviousSearchDoc,
        initialStatePreviousSearchDoc,
        companyId,
        keyword: debouncedKeyword,
        filters,
      });

      if (isListsLengthAtTheLimitOrAbove(result?.data?.data)) {
        setCheckLoadMore(true);
      } else {
        setCheckLoadMore(false);
      }

      const isNoList = result?.data?.data.length < 1;

      if (isNoList) {
        setShouldFetch(false);
      } else {
        setShouldFetch(true);
      }

      // setAnchorKeyword(debouncedKeyword);
    } catch (err) {
      const status = handleStatusMsg(err, 'error');

      enqueueSnackbar(status.message, {
        variant: 'error',
      });
    } finally {
      const endLoadings = handleLoadings('initiateSearchDoc', [...loadings], 'end');
      setLoadings([...endLoadings]);
    }
  };

  useEffect(() => {
    initiateSearchDocApi();
  }, []);

  const isOneMorePage = currentSearchDoc.lastPage - currentSearchDoc.currentPage === 1;
  const moreLength = SearchJumpToConstants.limitSearch;
  const allDataLeftLength = currentSearchDoc.total
  - (SearchJumpToConstants.limitSearch * currentSearchDoc.currentPage);
  const lastMoreLength = allDataLeftLength;
  const hasMoreLength = checkIfListNeedToLoad() && allDataLeftLength > 0;

  useEffect(() => {
    handleSetTotal(currentSearchDoc.total || 0);
    return () => {
      handleSetTotal(0);
    };
  }, [currentSearchDoc]);
  return (
    <>
      {shouldFetch && (
        <Box
          sx={{
            marginTop: '10px',
            marginBottom: '10px',
          }}
        >
          <TitleJumpTo>
            {title}
          </TitleJumpTo>
          <FeedbackLoadingMain
            wait="initiateSearchDoc"
            loadings={loadings}
            loadingComponent={<SkeletonListResult />}
          >
            {lists.map((item, index) => (
              <ResultItemDoc
                params={params}
                key={item._id}
                keywordArray={keywordArray}
                item={item}
                isSelected={index === selectedIndex}
                onClick={onClose}
              />
            ))}
          </FeedbackLoadingMain>
          {hasMoreLength
           && (
           <OverlayButton wait="loadMoreSearchDoc" loadings={loadings}>
             <Box
               sx={{
                 ...showMoreTextStyles,
               }}
               onClick={handleLoadMoreLists}
             >
               <Box
                 sx={{
                   ...iconBoxStyles,
                 }}
               >
                 <MoreHorizOutlinedIcon sx={{
                   ...iconStyles,
                 }}
                 />
               </Box>
               <DisplayTextBody
                 mode="14"
                 color={Color.blueNavy4}
               >
                 Tampilkan
                 {' '}
                 {!isOneMorePage && moreLength}
                 {isOneMorePage && lastMoreLength}
                 {' '}
                 lagi
                 {' '}
                 {`(${allDataLeftLength} lagi)`}
               </DisplayTextBody>
             </Box>
           </OverlayButton>
           )}
        </Box>
      )}
    </>
  );
};

ListResultDoc.propTypes = {
  selectedIndex: PropTypes.number.isRequired,
  onClose: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
  keywordArray: PropTypes.array.isRequired,
  debouncedKeyword: PropTypes.string.isRequired,
  params: PropTypes.object,
  handleSetTotal: PropTypes.func.isRequired,
  filters: PropTypes.object,
};

ListResultDoc.defaultProps = {
  params: { companyId: null, teamId: null },
  filters: null,
};

export default memo(ListResultDoc);
