import React, {
  useState, useContext, useEffect,
} from 'react';
import Form from 'react-bootstrap/Form';
import _ from 'lodash';
import axios from 'axios';
import { useParams } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import CloseIcon from '@mui/icons-material/Close';
import draftToHtml from 'draftjs-to-html';
import PropTypes from 'prop-types';
import checkIsJson from '../../../utilities/checkIsJson';
import EditorFull from '../../FroalaEditor/EditorFull/EditorFull';
import Button from '../../../components/UI/Button/Button';
import { actionTypes } from '../../../reducers/reducer';
import { GlobalContext } from '../../../contexts/GlobalStateProvider';
import styles from './EditCommentForm.module.css';
import extractElementBySelectorFromString from '../../../utilities/extractElementBySelectorFromString';
import handleStatusMsg from '../../../utilities/handleStatusMsg';
import handleLoadings from '../../../utilities/handleLoadings';
import apiUtil from '../../../utilities/apiUtil';
import { InputButtonMain } from '../../../components/UI_V2/Input/Button';
import { useIsTyping, useUpdateUserTypingStatus } from '../../../hooks/UserTypingHooks';

const EditCommentForm = ({
  comment, parentIds, handleShowEditForm, type, socket, parentTeamId,
}) => {
  const [{ user, currentTeam }, dispatch] = useContext(GlobalContext);
  const [postComment, setPostComment] = useState();
  const { enqueueSnackbar } = useSnackbar();
  const [loadings, setLoadings] = useState([]);
  const [editorModel, setEditorModel] = useState();
  const [isInitialContentReady, setIsInitialContentReady] = useState(false);
  const [trigger, setTrigger] = useState();

  const params = useParams();
  const { companyId } = params;
  const teamId = params.teamId || parentTeamId;

  const [isTyping, handleTyping] = useIsTyping({});
  useUpdateUserTypingStatus({ isTyping, socket, userId: user?._id });

  const handleCancel = () => {
    handleShowEditForm('');
  };

  useEffect(() => {
    if (postComment === undefined) {
      return;
    }

    const postApiComment = async () => {
      let id;
      let ids = [];
      let commentId;
      let url;
      let discussionId;

      switch (type) {
        case 'card':
          ids[0] = params.cardId || parentIds[0];
          commentId = comment._id;
          url = `${process.env.REACT_APP_PRIMARY_API_URL}/api/v1/cards/${ids[0]}/comments/${commentId}`;
          break;
        case 'cardDiscussion':
          ids[0] = params.cardId || parentIds[0];
          commentId = params.commentId || parentIds[1];
          // below is discussion id
          discussionId = comment._id;
          url = `${process.env.REACT_APP_PRIMARY_API_URL}/api/v1/cards/${ids[0]}/comments/${commentId}/discussions/${discussionId}`;
          break;
        case 'blast':
          id = params.postId;
          commentId = comment._id;
          url = `${process.env.REACT_APP_PRIMARY_API_URL}/api/v1/posts/${id}/comments/${commentId}`;
          break;
        case 'blastDiscussion':
          id = params.postId;
          commentId = params.commentId;
          // below is discussion id
          discussionId = comment._id;
          url = `${process.env.REACT_APP_PRIMARY_API_URL}/api/v1/posts/${id}/comments/${commentId}/discussions/${discussionId}`;
          break;
        case 'question':
          id = params.questionId;
          commentId = comment._id;
          url = `${process.env.REACT_APP_PRIMARY_API_URL}/api/v1/questions/${id}/comments/${commentId}`;
          break;
        case 'questionDiscussion':
          id = params.questionId;
          commentId = params.commentId;
          // below is discussion id
          discussionId = comment._id;
          url = `${process.env.REACT_APP_PRIMARY_API_URL}/api/v1/questions/${id}/comments/${commentId}/discussions/${discussionId}`;
          break;
        case 'event':
          id = params.eventId;
          commentId = comment._id;
          url = `${process.env.REACT_APP_PRIMARY_API_URL}/api/v1/events/${id}/comments/${commentId}`;
          break;
        case 'eventDiscussion':
          id = params.eventId;
          commentId = params.commentId;
          // below is discussion id
          discussionId = comment._id;
          url = `${process.env.REACT_APP_PRIMARY_API_URL}/api/v1/events/${id}/comments/${commentId}/discussions/${discussionId}`;
          break;
        case 'occurrence':
          ids = [params.eventId, params.occurrenceId];
          commentId = comment._id;
          url = `${process.env.REACT_APP_PRIMARY_API_URL}/api/v1/events/${ids[0]}/occurrences/${ids[1]}/comments/${commentId}`;
          break;
        case 'occurrenceDiscussion':
          ids = [params.eventId, params.occurrenceId];
          commentId = params.commentId;
          // below is discussion id
          discussionId = comment._id;
          url = `${process.env.REACT_APP_PRIMARY_API_URL}/api/v1/events/${ids[0]}/occurrences/${ids[1]}/comments/${commentId}/discussions/${discussionId}`;
          break;
        case 'doc':
          id = params.docId;
          commentId = comment._id;
          url = `${process.env.REACT_APP_PRIMARY_API_URL}/api/v1/docs/${id}/comments/${commentId}`;
          break;
        case 'docDiscussion':
          id = params.docId;
          commentId = params.commentId;
          // below is discussion id
          discussionId = comment._id;
          url = `${process.env.REACT_APP_PRIMARY_API_URL}/api/v1/docs/${id}/comments/${commentId}/discussions/${discussionId}`;
          break;
        case 'file':
          id = params.fileId;
          commentId = comment._id;
          url = `${process.env.REACT_APP_PRIMARY_API_URL}/api/v1/files/${id}/comments/${commentId}`;
          break;
        case 'fileDiscussion':
          id = params.fileId;
          commentId = params.commentId;
          // below is discussion id
          discussionId = comment._id;
          url = `${process.env.REACT_APP_PRIMARY_API_URL}/api/v1/files/${id}/comments/${commentId}/discussions/${discussionId}`;
          break;
        default:
          //
      }

      try {
        // setIsInitialContentReady(false)
        const startLoadings = handleLoadings('editComment', [...loadings], 'start');
        setLoadings([...startLoadings]);

        const result = await apiUtil.patch(url, postComment, {
          params: {
            companyId,
            teamId,
          },
        });

        switch (type) {
          case 'card':
          // {
          //   dispatch({
          //     type: actionTypes.SET_CURRENT_CARD,
          //     currentCard: _.cloneDeep(result.data.card),
          //   });

            //   const fetchedBoard = _.cloneDeep(result.data.board);
            //   const fetchedBoardLists = _.cloneDeep(fetchedBoard.lists);

            //   dispatch({
            //     type: actionTypes.SET_CURRENT_BOARD,
            //     currentBoard: _.cloneDeep(fetchedBoard),
            //   });

            //   dispatch({
            //     type: actionTypes.SET_CURRENT_BOARD_LISTS,
            //     currentBoardLists: _.cloneDeep(fetchedBoardLists),
            //   });
            // }
            break;
          case 'occurrenceDiscussion':
          case 'eventDiscussion':
          case 'docDiscussion':
          case 'questionDiscussion':
          case 'blastDiscussion':
          case 'fileDiscussion':
          case 'cardDiscussion':
            // dispatch({
            //   type: actionTypes.SET_CURRENT_COMMENT,
            //   currentComment: result.data.comment,
            // });
            // dispatch({
            //   type: actionTypes.SET_CURRENT_COMMENT_DISCUSSIONS,
            //   currentCommentDiscussions: result.data.comment.discussions,
            // });
            break;
          case 'blast':
            // dispatch({
            //   type: actionTypes.SET_CURRENT_BLAST_POST,
            //   currentBlastPost: result.data.post,
            // });

            break;
          case 'question':
            // dispatch({
            //   type: actionTypes.SET_CURRENT_CHECK_IN_QUESTION,
            //   currentCheckInQuestion: result.data.question
            // });
            break;
          case 'event':
            // dispatch({
            //   type: actionTypes.SET_CURRENT_SCHEDULE_EVENT,
            //   currentScheduleEvent: result.data.event,
            // });
            break;
          case 'occurrence':
            // dispatch({
            //   type: actionTypes.SET_CURRENT_OCCURRENCE,
            //   currentOccurrence: result.data.occurrence,
            // });
            break;
          case 'doc':
            // dispatch({
            //   type: actionTypes.SET_CURRENT_BUCKET_DOC,
            //   currentBucketDoc: result.data.doc,
            // });
            break;
          case 'file':
            // dispatch({
            //   type: actionTypes.SET_CURRENT_BUCKET_FILE,
            //   currentBucketFile: result.data.file,
            // });
            break;
          default:
            //
        }

        // dispatch({
        //   type: actionTypes.SET_CURRENT_COMMENT,
        //   currentComment: result.data.comment
        // });
        // dispatch({
        //   type: actionTypes.SET_CURRENT_COMMENT_DISCUSSIONS,
        //   currentCommentDiscussions: result.data.comment.discussions,
        // });
        // setIsInitialContentReady(true)
        const status = handleStatusMsg(result, 'success');

        enqueueSnackbar(status.message, {
          variant: 'success',
        });
        handleCancel();
      } catch (err) {
        const status = handleStatusMsg(err, 'error');

        enqueueSnackbar(status.message, {
          variant: 'error',
        });
      } finally {
        const endLoadings = handleLoadings('editComment', [...loadings], 'end');
        setLoadings([...endLoadings]);
      }
      // close this container after finished
    };
    postApiComment();
  }, [postComment]);

  const handlePostComment = () => {
    // prevent double post
    const checkWait = loadings.filter((loading) => loading === 'editComment');
    if (checkWait.length > 0) return;

    // prevent empty post
    if (!editorModel) {
      handleCancel();
      return;
    }

    const mentionedUsers = extractElementBySelectorFromString(editorModel, '#mentioned-user');

    setPostComment({ content: editorModel, mentionedUsers, type });

    // setEditorModel();
  };

  useEffect(() => {
    if (trigger === undefined) return;
    handlePostComment();
  }, [trigger]);

  const handleTriggerPostComment = () => {
    if (trigger) {
      setTrigger(false);
    } else {
      setTrigger(true);
    }
  };

  useEffect(() => {
    if (_.isEmpty(comment)) {
      return;
    }

    let initialContent;
    if (editorModel) {
      initialContent = editorModel;
    } else if (checkIsJson(comment.content)) {
      initialContent = draftToHtml(JSON.parse(comment.content));
    } else {
      initialContent = comment.content;
    }

    setEditorModel(initialContent);
    setIsInitialContentReady(true);
  }, [comment]);

  const handleEditorModelChange = (model) => {
    setEditorModel(model);
  };

  const customConfig = {
    zIndex: 15000,
    toolbarSticky: false,
    heightMax: 400,
  };

  return (
    <div className={styles.AddCommentContainer}>
      <div className={styles.inputComment}>
        {isInitialContentReady
          ? (
            <>
              <div className={styles.editorSection}>
                <div className={styles.borderEditor}>
                  <EditorFull
                    companyId={companyId}
                    model={editorModel}
                    onModelChange={handleEditorModelChange}
                    triggerLists={currentTeam.members}
                    type="comment"
                    handleAction={handleTriggerPostComment}
                    customConfig={type === 'card' ? customConfig : null}
                    handleTyping={handleTyping}
                  />
                </div>
              </div>
              <div className={styles.actionSection}>
                <InputButtonMain handleClick={handleCancel} variant="light">Batal</InputButtonMain>
                <InputButtonMain handleClick={handlePostComment} wait="editComment" loadings={loadings}>Kirim</InputButtonMain>
              </div>
            </>
          )

          : <p>Please wait...</p>}

      </div>
    </div>
  );
};

EditCommentForm.propTypes = {
  comment: PropTypes.object.isRequired,
  parentIds: PropTypes.array,
  handleShowEditForm: PropTypes.func.isRequired,
  type: PropTypes.string.isRequired,
  socket: PropTypes.func.isRequired,
  parentTeamId: PropTypes.string,
};

EditCommentForm.defaultProps = {
  parentIds: [],
  parentTeamId: undefined,
};

export default EditCommentForm;
