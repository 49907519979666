import React, {
  memo, useContext, useState, useCallback, useEffect,
} from 'react';
import { useParams } from 'react-router-dom';
import PropTypes from 'prop-types';
import ChatOutlinedIcon from '@mui/icons-material/ChatOutlined';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import { useSnackbar } from 'notistack';
import styles from './SideBarChatSection.module.css';
import { DisplayTextHeadline, DisplayTextSubHeadline } from '../../../components/UI_V2/Display/Text';
import SearchComponent from './SearchComponent/SearchComponent';
import { GlobalContext } from '../../../contexts/GlobalStateProvider';
import Color from '../../../themes/colors';
import SideBarChatActionIconContainer from './SideBarChatActionIconContainer/SideBarChatActionIconContainer';
import ListRecentChatsContainer from './ListRecentChatsContainer/ListRecentChatsContainer';
import ListNewChatsModeContainer from './ListNewChatsModeContainer/ListNewChatsModeContainer';
import { getStoreCurrentCompany } from '../../../utilities/localStorage';
import { useNotificationChatSocket } from '../../../hooks/NotificationHooks';
import { useRecentChatsSocket } from '../../../hooks/PrivateMessageHooks';
import { ChatActions } from '../../../actions';
import handleStatusMsg from '../../../utilities/handleStatusMsg';
import handleLoadings from '../../../utilities/handleLoadings';
import OverlayButton from '../../../components/UI/Button/OverlayButton/OverlayButton';
import { elementId } from '../../../constants/ElementIdConstants';

const SideBarChatSection = () => {
  const [{
    user,
    recentChats,
    currentCompany,
    currentChat,
  }, dispatch] = useContext(GlobalContext);
  const [filteredRecentChats, setFilteredRecentChats] = useState([]);
  const [filteredMembers, setFilteredMembers] = useState([]);
  const [showNewChat, setShowNewChat] = useState(false);
  const [limitSocket, setLimitSocket] = useState(0);
  const [loadings, setLoadings] = useState([]);
  const { enqueueSnackbar } = useSnackbar();
  const params = useParams();
  // const getCurrentCompany = useCallback(() => getStoreCurrentCompany(), []);
  // const currentCompany = getCurrentCompany();
  // const companyId = getStoreCurrentCompany()?._id;

  const { companyId } = params;

  useRecentChatsSocket({
    companyId,
    userId: user?._id,
  }, dispatch);

  const { socket } = useNotificationChatSocket(
    { userId: user?._id, companyId },
    dispatch,
  );

  const getMoreListViaSocket = ({ limit }) => {
    socket.emit(`${companyId}-${user?._id}`, { limit });
  };

  useEffect(() => {
    if (!socket || limitSocket === 0) return;
    getMoreListViaSocket({ limit: limitSocket });
  }, [socket, limitSocket]);

  const removeSelfFromCompanyMembers = (members, userId) => {
    if (!members || !userId) return [];

    const membersWithoutSelf = members.filter(
      (member) => member._id !== userId,
    );

    return membersWithoutSelf;
  };

  useEffect(() => {
    const fetchApiRecentChats = async () => {
      if (!currentCompany?._id) return;
      const startLoadings = handleLoadings('listRecentPrivateChats', [...loadings], 'start');
      setLoadings([...startLoadings]);
      try {
        const result = await ChatActions.initiateListRecentChats({
          currentCompany,
        }, dispatch);
        setLimitSocket(result?.limit);
        if (currentCompany && currentCompany?.members) {
          const companyMembersWithoutSelf = removeSelfFromCompanyMembers(
            currentCompany.members, user._id,
          );
          setFilteredMembers([...companyMembersWithoutSelf]);
        }
      } catch (err) {
        const status = handleStatusMsg(err, 'error');

        enqueueSnackbar(status.message, {
          variant: 'error',
        });
      } finally {
        const endLoadings = handleLoadings('listRecentPrivateChats', [...loadings], 'end');
        setLoadings([...endLoadings]);
      }
    };
    fetchApiRecentChats();
  }, [showNewChat]);

  useEffect(() => {
    setFilteredRecentChats([...recentChats]);
  }, [recentChats]);

  const handleSetFilteredRecentChats = (filteredChats) => {
    setFilteredRecentChats([...filteredChats]);
  };

  const handleSetFilteredMembers = (filteredCurrentMembers) => {
    setFilteredMembers([...filteredCurrentMembers]);
  };

  const handleShowNewChat = () => {
    setShowNewChat(true);
  };

  const handleCancelShowNewChat = () => {
    setShowNewChat(false);
  };

  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <div className={styles.header__title}>
          <div className={styles.header__title__text}>
            {showNewChat ? (
              <div className={styles.header__title__back} onClick={() => handleCancelShowNewChat()}>
                <ArrowBackIosIcon />
              </div>
            ) : null}
            <DisplayTextSubHeadline
              mode="18"
              decoration="extra_bold"
              color={Color.gray3}
              fontFamily="openSans"
            >
              {showNewChat ? 'Kontak' : 'Chat Terbaru'}
            </DisplayTextSubHeadline>
          </div>
          {showNewChat ? null : (
            <div id={elementId.chatNewChatIcon} className={styles.header__title__icons}>
              <SideBarChatActionIconContainer handleClick={handleShowNewChat}>
                <ChatOutlinedIcon />
              </SideBarChatActionIconContainer>
            </div>
          )}
        </div>
        <div className={styles.header__search}>
          <SearchComponent
            placeholder="Cari orang"
            handleSetFilteredDatas={
              showNewChat ? handleSetFilteredMembers : handleSetFilteredRecentChats
            }
            originalDatas={showNewChat ? removeSelfFromCompanyMembers(
              currentCompany?.members, user._id,
            ) : recentChats}
          />
        </div>
      </div>
      <div className={styles.body}>
        { showNewChat
          ? (
            <ListNewChatsModeContainer
              filteredMembers={filteredMembers}
              cancel={handleCancelShowNewChat}
            />
          )
          : (
            <OverlayButton
              spinnerSize="lg"
              wait="listRecentPrivateChats"
              loadings={loadings}
              style={
                {
                  height: 'calc(100vh - (102px + 33px + 34px))',
                }
              }
            >
              <ListRecentChatsContainer
                filteredRecentChats={filteredRecentChats}
              />
            </OverlayButton>
          )}
      </div>
    </div>
  );
};

export default memo(SideBarChatSection);
