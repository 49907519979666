import React from 'react';
import FroalaEditorView from 'react-froala-wysiwyg/FroalaEditorView';
import PropTypes from 'prop-types';

const EditorView = ({ model }) => (
  <FroalaEditorView model={model} />
);

EditorView.propTypes = {
  model: PropTypes.string.isRequired,
};

export default EditorView;
