import React, { memo, useState } from 'react';
import PropTypes from 'prop-types';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { useMediaQuery } from '@mui/material';
import styles from './EmptyTeamsSectionForUsers.module.css';
import { DisplayTextHeadline, DisplayTextSubHeadline } from '../../../components/UI_V2/Display/Text';
import Color from '../../../themes/colors';
import { InputButtonMain } from '../../../components/UI_V2/Input/Button';
import emptyIllustrationImage from '../../../assets/DashboardPage/empty-dashboard-user.png';

function EmptyTeamsSectionForUsers({ clicked, children }) {
  const [showCreateTeam, setShowCreateTeam] = useState(false);
  const handleCloseCreateTeam = () => {
    setShowCreateTeam(false);
  };

  const handleOpenCreateTeam = () => {
    setShowCreateTeam(true);
  };

  const isTablet = useMediaQuery('(max-width:1024px)');
  const isMobile = useMediaQuery('(max-width:720px)');
  return (
    <div className={styles.container}>
      <div className={styles.illustration}>
        <LazyLoadImage src={emptyIllustrationImage} />
      </div>
      <div className={styles.content}>
        <div className={styles.title}>
          <DisplayTextHeadline
            color={Color.blueNavyCicle}
            decoration="bold"
            position={isMobile && 'center'}
          >
            {/* Now you can relax... */}
            Rileks dulu ya...
          </DisplayTextHeadline>
        </div>
        <div className={styles.description}>
          <DisplayTextSubHeadline
            mode="20"
            position={isMobile && 'center'}
          >
            Sebelum kamu bisa pake Cicle, mintalah ke Manager atau Admin
            untuk menambahkanmu jadi anggota Tim/Proyek.
            Selagi menunggu, chill dan dengerin aja dulu musik favoritmu, ya girls!
            {/* Before you can use Cicle, please ask your Manager or Admin for
            inviting you to the Team.
            Meanwhile, just chill and enjoy your favorite music, girls! */}
          </DisplayTextSubHeadline>
        </div>
        {/* <div className={styles.cta}>
          <InputButtonMain
            handleClick={handleOpenCreateTeam}
          >
            Create Team
          </InputButtonMain>
        </div> */}
      </div>
    </div>
  );
}

EmptyTeamsSectionForUsers.propTypes = {
  clicked: PropTypes.func.isRequired,
  children: PropTypes.node.isRequired,
};

export default memo(EmptyTeamsSectionForUsers);
