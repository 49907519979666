import React, { memo, useState } from 'react';
import PropTypes from 'prop-types';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import styles from './StatusBoxOnline.module.css';
import Color from '../../../../../../themes/colors';
import { DisplayTextBody } from '../../../Text';

const statusStyles = {
  online: styles.online,
  idle: styles.idle,
  offline: styles.offline,
};

const textColorStyles = {
  online: Color.white,
  idle: Color.gray3,
  offline: Color.white,
};

const statusText = {
  online: 'Aktif',
  idle: 'Diem Aja',
  offline: 'Ga Aktif',
};

const StatusBoxOnline = ({ status }) => (
  <div className={`${styles.container} ${statusStyles[status]}`}>
    <DisplayTextBody
      color={textColorStyles[status]}
    >
      {statusText[status]}
    </DisplayTextBody>
  </div>
);

StatusBoxOnline.propTypes = {
  status: PropTypes.oneOf(['online', 'idle', 'offline']),
};

StatusBoxOnline.defaultProps = {
  status: 'offline',
};

export default memo(StatusBoxOnline);
