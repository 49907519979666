/* eslint-disable jsx-a11y/media-has-caption */
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import PlayCircleIcon from '@mui/icons-material/PlayCircle';
import { useMediaQuery } from '@mui/material';
import styles from './File.module.css';
import PrivateIcon from '../../../components/UI/PrivateIcon/PrivateIcon';
import { AttachmentActions } from '../../../actions';
import PdfThumbnail from '../../../components/PdfThumbnail/PdfThumbnail';
import VideoPlayerThumbnail from '../../../components/VideoPlayerThumbnail/VideoPlayerThumbnail';

const File = ({
  file, clicked, big,
  handleOpenAndSetFileViewer,
}) => {
  const isMobile = useMediaQuery('(max-width:720px)');
  const [title, setTitle] = useState();
  const [imageUrl, setImageUrl] = useState();
  const [isImage, setIsImage] = useState();
  const [isVideo, setIsVideo] = useState();
  const [isPdf, setIsPdf] = useState();
  const [fileUrl, setFileUrl] = useState();

  useEffect(() => {
    if (file.title === undefined) return;

    let tempTitle;
    if (file.title.length < 40) {
      tempTitle = file.title;
    } else {
      tempTitle = `${file.title.slice(0, 40)}...`;
    }

    setTitle(tempTitle);

    const urlFile = file.url?.charAt(0) === '/'
      ? process.env.REACT_APP_PRIMARY_API_URL + file.url
      : file.url;

    setFileUrl(urlFile);

    const result = AttachmentActions.getMimeTypeUrl({ mimeType: file.mimeType, fileUrl: urlFile });

    setImageUrl(result?.imageUrl);
    setIsImage(result?.isImage);
    setIsVideo(result?.isVideo);
    setIsPdf(result?.isPdf);
  }, [file]);

  return (
    <>
      {big ? (
        <div className={styles.mainBig} onClick={clicked}>
          <div className={styles.main__icon} onClick={() => handleOpenAndSetFileViewer(file)}>
            {(!isVideo && !isPdf) && (<img src={imageUrl} style={!isImage ? { maxWidth: '100px', maxHeight: '70px' } : {}} />)}
            {isVideo
        && (
          <VideoPlayerThumbnail
            videoUrl={imageUrl}
            variant="file"
          />
        )}
            {isPdf && (
              <PdfThumbnail
                fileUrl={imageUrl}
                height={230}
              />
            )}
          </div>
          <div className={styles.main__title}>
            <h1>
              <PrivateIcon data={file} />
              {' '}
              {file.title}
            </h1>
            <a href={fileUrl} target="_blank" rel="noreferrer">
              Unduh
            </a>
          </div>
        </div>
      ) : (
        <div className={styles.main} onClick={clicked}>
          <div className={styles.main__icon}>
            {(!isVideo && !isPdf) && (<img src={imageUrl} style={!isImage ? { maxWidth: '100px', maxHeight: '70px' } : { maxWidth: '160px', maxHeight: '130px' }} />)}
            {isVideo
        && (
          <VideoPlayerThumbnail
            videoUrl={imageUrl}
            variant="bucketFile"
          />
        )}
            {isPdf && (
              <PdfThumbnail fileUrl={imageUrl} height={150} />
            )}
          </div>
          <div className={styles.main__title}>
            <h1>
              <PrivateIcon data={file} size="small" />
              {' '}
              <h1>{title}</h1>
            </h1>
          </div>
        </div>
      )}
    </>
  );
};

File.propTypes = {
  file: PropTypes.object.isRequired,
  clicked: PropTypes.func.isRequired,
  big: PropTypes.bool.isRequired,
  handleOpenAndSetFileViewer: PropTypes.func.isRequired,
};

export default File;
