import React, { memo, useState } from 'react';
import PropTypes from 'prop-types';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import styles from './TitleChart.module.css';
import Color from '../../../../../themes/colors';
import DisplayTextBody from '../../../../../components/UI_V2/Display/Text/Body';

const TitleChart = ({
  children
}) => (
  <div className={styles.container}>
    <DisplayTextBody
      decoration="bold"
    >
      {children}
    </DisplayTextBody>
  </div>
);

TitleChart.propTypes = {
  children: PropTypes.node,
};

TitleChart.defaultProps = {
  children: <></>,
};

export default memo(TitleChart);
