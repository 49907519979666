import React, {
  memo, useCallback, useContext, useEffect, useState,
} from 'react';
import PropTypes from 'prop-types';
import { useParams, useHistory, useLocation } from 'react-router-dom';
import { Tooltip } from '@mui/material';
import { useSnackbar } from 'notistack';
import styles from './ListRoleUsers.module.css';
import { DisplayTextBody } from '../../../../components/UI_V2/Display/Text';
import { roleTitle, role, roles } from '../../../../constants/RoleConstants';
import Color from '../../../../themes/colors';
import { RoleActions } from '../../../../actions';
import { GlobalContext, initialState } from '../../../../contexts/GlobalStateProvider';
import { hasPermission } from '../../../../actions/RoleActions';
import { useInfinityScrollHooks } from '../../../../hooks/InfinityScrollHooks';
import { RoleConstants } from '../../../../constants';
import handleStatusMsg from '../../../../utilities/handleStatusMsg';
import InfinityScroll from '../../../../components/UI/InfinityScroll/InfinityScroll';
import UserItem from '../UserItem/UserItem';
import FeedbackLoadingMain from '../../../../components/UI_V2/Feedback/Loading/Main/Main';
import ListRoleUsersSkeleton from './ListRoleUsersSkeleton/ListRoleUsersSkeleton';
import handleLoadings from '../../../../utilities/handleLoadings';

const ListRoleUsers = () => {
  const [{
    user, currentRoleUsers, previousRoleUsers, currentCompany, currentRoleUser,
  }, dispatch] = useContext(GlobalContext);
  const [loadings, setLoadings] = useState([]);
  const [changedRoleUsers, setChangedRoleUsers] = useState([]);

  const params = useParams();
  const history = useHistory();
  const location = useLocation();
  const { companyId, tabId } = params;

  const { enqueueSnackbar } = useSnackbar();

  const isUserManagerOrAbove = hasPermission(currentRoleUser, roles.managerOrAbove);

  const getMoreLists = async () => {
    const result = await RoleActions.loadMoreRoleUsers({
      currentRoleUsers,
      companyId,
    }, dispatch);

    return result;
  };

  const resetPreviousLists = useCallback(() => {
    RoleActions.setPreviousRoleUsers({
      previousRoleUsers: initialState.previousRoleUsers,
    }, dispatch);
    RoleActions.setCurrentRoleUsers({
      currentRoleUsers: initialState.currentRoleUsers,
    }, dispatch);
  }, []);

  const {
    lists,
    handleLoadMoreLists,
    checkIfListNeedToLoad,
    setCheckLoadMore,
    isListsLengthAtTheLimitOrAbove,
  } = useInfinityScrollHooks({
    currentObjectWithKeyProperty: currentRoleUsers,
    previousLists: previousRoleUsers?.data,
    resetPreviousLists,
    getMoreLists,
    keyProperty: 'data',
    limitList: RoleConstants.limitRoleUsers,
  });

  const initiateRoleUsersApi = async () => {
    try {
      const startLoadings = handleLoadings('initiateRoleUsersApi', [...loadings], 'start');
      setLoadings([...startLoadings]);
      const result = await RoleActions.initiateRoleUsers({ companyId }, dispatch);

      if (isListsLengthAtTheLimitOrAbove(result?.data?.data)) {
        setCheckLoadMore(true);
      } else {
        setCheckLoadMore(false);
      }
    } catch (err) {
      const status = handleStatusMsg(err, 'error');

      enqueueSnackbar(status.message, {
        variant: 'error',
      });
    } finally {
      const endLoadings = handleLoadings('initiateRoleUsersApi', [...loadings], 'end');
      setLoadings([...endLoadings]);
    }
  };

  useEffect(() => {
    if (user._id === undefined) {
      history.push(`/check-login?previousPath=${location.pathname}`);
      return;
    }
    // if (!isUserAdminOrAbove) return;

    initiateRoleUsersApi();
  }, [location, currentCompany]);
  return (
    <div className={styles.container}>
      <FeedbackLoadingMain
        wait="initiateRoleUsersApi"
        loadings={loadings}
        loadingComponent={<ListRoleUsersSkeleton />}
      >
        <InfinityScroll
          scrollableTarget="roleUserList"
          dataLength={lists?.length || 0}
          hasMore={checkIfListNeedToLoad()}
          next={handleLoadMoreLists}
          emptyMessage="No users here yet..."
        >
          {lists.map((roleUser) => {
            if (!roleUser?.user?.fullName) return null;
            return (
              <UserItem
                user={user}
                roleUser={roleUser}
                currentRoleUser={currentRoleUser}
                enableDeleteMember
                disableSelectRole
              />
            );
          })}
        </InfinityScroll>
      </FeedbackLoadingMain>
    </div>
  );
};

ListRoleUsers.propTypes = {};

export default memo(ListRoleUsers);
