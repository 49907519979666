import React, { memo, useState } from 'react';
import PropTypes from 'prop-types';
import DownloadIcon from '@mui/icons-material/Download';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { useMediaQuery } from '@mui/material';
import styles from './PasswordSection.module.css';
import Color from '../../../../themes/colors';
import BoxTextSingle from '../../BoxTextSingle/BoxTextSingle';
import { DisplayTextBody } from '../../../../components/UI_V2/Display/Text';

const PasswordSection = ({
  hasPassword,
  onClick,
}) => (
  <div className={styles.container}>
    <div className={styles.box}>
      <BoxTextSingle text={hasPassword ? '********' : 'Belum punya password'} />
    </div>
    <div className={styles.action} onClick={onClick}>
      <DisplayTextBody color={Color.brightBlue}>
        {hasPassword ? 'Ubah Password' : 'Tambah Password'}
      </DisplayTextBody>
    </div>
  </div>
);

PasswordSection.propTypes = {
  hasPassword: PropTypes.bool.isRequired,
  onClick: PropTypes.func.isRequired,
};

PasswordSection.defaultProps = {};

export default memo(PasswordSection);
