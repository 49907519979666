import React, {
  memo, useState, useCallback, useEffect,
} from 'react';
import PropTypes from 'prop-types';
import FilterAltOutlinedIcon from '@mui/icons-material/FilterAltOutlined';
import Box from '@mui/material/Box';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import OneSignal from 'react-onesignal';
import ExitToApp from '@mui/icons-material/ExitToApp';
import { Tooltip } from '@mui/material';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { useHistory } from 'react-router-dom';
import styles from './ModalSelectCompany.module.css';
import Color from '../../../themes/colors';
import { FeedbackDialogGeneral } from '../../../components/UI_V2/Feedback/Dialog';
import { DisplayTextBody, DisplayTextHeadline, DisplayTextSubHeadline } from '../../../components/UI_V2/Display/Text';
import ListSelectCompany from './ListSelectCompany/ListSelectCompany';
import emptyCompanyImage from '../../../assets/DashboardPage/empty-company-welcome.png';
import IconItem from '../../UserMenuContainer/IconItem/IconItem';
import { UserActions } from '../../../actions';
import { useLogoutUser } from '../../../hooks/UserHooks';
import { InputButtonLogout } from '../../../components/UI_V2/Input/Button';

const ModalSelectCompany = ({
  open, onClose, companies, userId,
}) => {
  const { handleLogout, isLoadingLogout } = useLogoutUser();
  return (
    <FeedbackDialogGeneral
      open={open}
      onClose={onClose}
      disableCloseIcon
      disableEscapeKeyDown
      disableBackdropClick
    >
      <Box className={styles.container}>
        <Box
          className={styles.leftSection}
        >
          <LazyLoadImage src={emptyCompanyImage} />
        </Box>
        <Box className={styles.rightSection}>
          <Box>
            <div className={styles.logoutIcon}>
              <InputButtonLogout handleLogout={handleLogout} isLoadingLogout={isLoadingLogout} />
            </div>
          </Box>
          <Box className={styles.textSection}>
            <Box sx={{ marginBottom: '5px' }}>
              <DisplayTextHeadline
                mode="32"
                decoration="bold"
                color={Color.blueNavyCicle}
              >
                Pilih perusahaan
              </DisplayTextHeadline>
            </Box>
            <Box>
              <DisplayTextBody
                color={Color.gray3}
              >
                Kamu mau masuk ke Perusahaan yang mana?
                {' '}
                Ga perlu kuatir, kamu bisa berganti Perusahaan lagi nanti.
              </DisplayTextBody>
            </Box>
          </Box>
          <Box className={styles.listCompanies}>
            <ListSelectCompany list={companies} />
          </Box>
        </Box>
      </Box>
    </FeedbackDialogGeneral>
  );
};

ModalSelectCompany.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  companies: PropTypes.array.isRequired,
  userId: PropTypes.string.isRequired,
};

export default memo(ModalSelectCompany);
