import React, { memo, useState } from 'react';
import PropTypes from 'prop-types';
import { Skeleton } from '@mui/material';
import styles from './SkeletonListResult.module.css';

const SkeletonListResult = () => (
  <div className={styles.container}>
    <div className={styles.skeleton}>
      <Skeleton
        animation="wave"
        variant="text"
        width={100}
      />
    </div>
    <div className={styles.skeleton}>
      <Skeleton
        animation="wave"
        variant="rounded"
        height={40}
      />
    </div>
    <div className={styles.skeleton}>
      <Skeleton
        animation="wave"
        variant="rounded"
        height={40}
      />
    </div>
    <div className={styles.skeleton}>
      <Skeleton
        animation="wave"
        variant="text"
        width={100}
      />
    </div>
    <div className={styles.skeleton}>
      <Skeleton
        animation="wave"
        variant="rounded"
        height={40}
      />
    </div>
    <div className={styles.skeleton}>
      <Skeleton
        animation="wave"
        variant="rounded"
        height={40}
      />
    </div>
    <div className={styles.skeleton}>
      <Skeleton
        animation="wave"
        variant="text"
        width={100}
      />
    </div>
    <div className={styles.skeleton}>
      <Skeleton
        animation="wave"
        variant="rounded"
        height={40}
      />
    </div>
  </div>
);

export default memo(SkeletonListResult);
