import { ApiConstants, ReportConstants } from '../constants';
import { limitReport } from '../constants/ReportConstants';
import { limitSeen } from '../constants/SeenConstants';
import apiUtil from '../utilities/apiUtil';
import { getLastListDate, mergeObjectListAndRemoveDuplicate } from '../utilities/arrayUtil';
import {
  calculateMonthday, getPreviousMonthDate,
  getPreviousQuarterDate, getPreviousTwoWeekDate, getPreviousWeekDate,
  getPreviousYearDate, getThisMonthDate, getThisQuarterDate, getThisTwoWeekDate,
  getThisWeekDate, getThisYearDate, getTodayDate, getYesterdayDate,
} from '../utilities/dateUtil';
import { ErrorException } from '../utilities/handleError';

const initiateReportCardMemberCount = async ({
  memberId,
  teamId,
  companyId,
  fromDate,
  untilDate,
  setCurrentReportCardMemberCount,
  setCurrentReportCardMemberCountPercentage,
}) => {
  try {
    const result = await apiUtil.get(ApiConstants.URL_V1.REPORTS_CARDS_TEAM_MEMBERS_COUNT(
      {
        memberId,
        teamId,
      },
    ), {
      params: {
        companyId,
        fromDate,
        untilDate,
      },
    });

    const count = [
      result.data.data.count.backlog.value,
      result.data.data.count.ongoing.value,
      result.data.data.count.overdue.value,
      result.data.data.count.completed.value,
      result.data.data.count.bottleneckBacklog.value,
      result.data.data.count.bottleneckOngoing.value,
    ];

    const percentage = [
      result.data.data.count.backlog.percentage,
      result.data.data.count.ongoing.percentage,
      result.data.data.count.overdue.percentage,
      result.data.data.count.completed.percentage,
      result.data.data.count.bottleneckBacklog.percentage,
      result.data.data.count.bottleneckOngoing.percentage,
    ];

    setCurrentReportCardMemberCount(count);
    setCurrentReportCardMemberCountPercentage(percentage);

    return result;
  } catch (error) {
    throw new ErrorException(error);
  }
};

const initiateUserCardRatingFromResultReportCount = ({
  result,
  handleSetRating,
}) => {
  try {
    const rating = result?.data?.data?.rating;
    handleSetRating(rating);

    return rating;
  } catch (error) {
    throw new ErrorException(error);
  }
};

const initiateUserCardStatRatioFromResultReportCount = ({
  result,
  handleSetRatio,
}) => {
  try {
    const ratio = result?.data?.data?.ratio;
    handleSetRatio(ratio);

    return ratio;
  } catch (error) {
    throw new ErrorException(error);
  }
};

const initiateReportListCard = async ({
  memberId,
  teamId,
  companyId,
  fromDate,
  untilDate,
  setCurrentReportListCard,
  setPreviousReportListCard,
  initialStatePreviousReportListCard,
  itemType,
  limit = limitReport,
}) => {
  try {
    const result = await apiUtil.get(ApiConstants.URL_V1.REPORTS_CARDS_TEAM_MEMBERS(
      {
        memberId,
        teamId,
      },
    ), {
      params: {
        companyId,
        fromDate,
        untilDate,
        limit,
        'filter[status]': itemType,
      },
    });

    setCurrentReportListCard(result?.data);
    setPreviousReportListCard(initialStatePreviousReportListCard);

    return result;
  } catch (error) {
    throw new ErrorException(error);
  }
};

const initiateReportListBottleneckBacklogCard = async ({
  memberId,
  teamId,
  companyId,
  fromDate,
  untilDate,
  setCurrentReportListBottleneckBacklogCard,
  // setPreviousReportListBottleneckBacklogCard,
  // initialStatePreviousReportListBottleneckBacklogCard,
  itemType = 'bottleneckBacklog',
  limit = 1,
}) => {
  try {
    const result = await apiUtil.get(ApiConstants.URL_V1.REPORTS_CARDS_TEAM_MEMBERS(
      {
        memberId,
        teamId,
      },
    ), {
      params: {
        companyId,
        fromDate,
        untilDate,
        limit,
        'filter[status]': itemType,
      },
    });

    setCurrentReportListBottleneckBacklogCard(result?.data?.data?.[0]);
    // setPreviousReportListBottleneckBacklogCard(
    // initialStatePreviousReportListBottleneckBacklogCard);

    return result;
  } catch (error) {
    throw new ErrorException(error);
  }
};

const initiateReportListBottleneckOngoingCard = async ({
  memberId,
  teamId,
  companyId,
  fromDate,
  untilDate,
  setCurrentReportListBottleneckOngoingCard,
  // setPreviousReportListBottleneckOngoingCard,
  // initialStatePreviousReportListBottleneckOngoingCard,
  itemType = 'bottleneckOngoing',
  limit = 1,
}) => {
  try {
    const result = await apiUtil.get(ApiConstants.URL_V1.REPORTS_CARDS_TEAM_MEMBERS(
      {
        memberId,
        teamId,
      },
    ), {
      params: {
        companyId,
        fromDate,
        untilDate,
        limit,
        'filter[status]': itemType,
      },
    });

    setCurrentReportListBottleneckOngoingCard(result?.data?.data?.[0]);
    // setPreviousReportListBottleneckOngoingCard(
    // initialStatePreviousReportListBottleneckOngoingCard);

    return result;
  } catch (error) {
    throw new ErrorException(error);
  }
};

const loadMoreReportListCard = async ({
  memberId,
  teamId,
  companyId,
  fromDate,
  untilDate,
  setCurrentReportListCard,
  setPreviousReportListCard,
  currentReportListCard,
  itemType,
  limit = limitReport,
}) => {
  try {
    const params = {
      companyId,
      fromDate,
      untilDate,
      limit,
      'filter[status]': itemType,
    };
    if (itemType === ReportConstants.typeRef.completed) {
      const completedAt = getLastListDate(currentReportListCard.data, 'setAt', 'complete');
      params.completedAt = completedAt;
    } else if (itemType === ReportConstants.typeRef.ongoing
      || itemType === ReportConstants.typeRef.overdue) {
      const dueDateAt = getLastListDate(currentReportListCard.data, 'dueDate');
      params.dueDateAt = dueDateAt;
    } else {
      const createdAt = getLastListDate(currentReportListCard.data, 'createdAt');
      params.createdAt = createdAt;
    }

    const result = await apiUtil.get(ApiConstants.URL_V1.REPORTS_CARDS_TEAM_MEMBERS(
      {
        teamId,
        memberId,
      },
    ), {
      params,
    });

    const mergedDataListData = mergeObjectListAndRemoveDuplicate({
      currentObjectList: currentReportListCard,
      nextObjectList: result?.data,
      keyObject: 'data',
    });

    const mergedListData = {
      ...result?.data,
      data: mergedDataListData?.data,
    };

    setPreviousReportListCard(result?.data);
    setCurrentReportListCard(mergedListData);

    return result;
  } catch (error) {
    throw new ErrorException(error);
  }
};

const modifyInitialDateFilterReport = (selectedQuickDate, initFromDate, initUntilDate) => {
  try {
    let newFromDate = new Date();
    let newUntilDate = new Date();

    switch (selectedQuickDate) {
      case 'today':
        [newFromDate, newUntilDate] = getTodayDate();
        break;
      case 'yesterday':
        [newFromDate, newUntilDate] = getYesterdayDate();
        break;
      case 'thisWeek':
        [newFromDate, newUntilDate] = getThisWeekDate();
        break;
      case 'previousWeek':
        [newFromDate, newUntilDate] = getPreviousWeekDate();
        break;
      case 'thisTwoWeek':
        [newFromDate, newUntilDate] = getThisTwoWeekDate();
        break;
      case 'previousTwoWeek':
        [newFromDate, newUntilDate] = getPreviousTwoWeekDate();
        break;
      case 'thisMonth':
        [newFromDate, newUntilDate] = getThisMonthDate();
        break;
      case 'previousMonth':
        [newFromDate, newUntilDate] = getPreviousMonthDate();
        break;
      case 'thisQuarter':
        [newFromDate, newUntilDate] = getThisQuarterDate();
        break;
      case 'previousQuarter':
        [newFromDate, newUntilDate] = getPreviousQuarterDate();
        break;
      case 'thisYear':
        [newFromDate, newUntilDate] = getThisYearDate();
        break;
      case 'previousYear':
        [newFromDate, newUntilDate] = getPreviousYearDate();
        break;
      case 'custom':
        newFromDate = new Date(initFromDate);
        newUntilDate = new Date(initUntilDate);
        break;
      default:
        break;
    }

    if (selectedQuickDate !== 'custom') {
      newUntilDate = calculateMonthday(newUntilDate, 1, 'add');
    }

    return [
      newFromDate,
      newUntilDate,
    ];
  } catch (error) {
    throw new ErrorException(error);
  }
};

export {
  initiateReportCardMemberCount,
  initiateReportListCard,
  loadMoreReportListCard,
  initiateUserCardRatingFromResultReportCount,
  initiateUserCardStatRatioFromResultReportCount,
  initiateReportListBottleneckBacklogCard,
  initiateReportListBottleneckOngoingCard,
  modifyInitialDateFilterReport,
};
