import React, {
  memo,
} from 'react';
import PropTypes from 'prop-types';
import FilterAltOutlinedIcon from '@mui/icons-material/FilterAltOutlined';
import Color from '../../../themes/colors';
import styles from './FilterNotifButton.module.css';

const FilterNotifButton = ({ onClick }) => (
  <div onClick={onClick} className={styles.container}>
    <FilterAltOutlinedIcon />
  </div>
);

FilterNotifButton.propTypes = {
  onClick: PropTypes.func.isRequired,
};

export default memo(FilterNotifButton);
