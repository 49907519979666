import React, {
  memo, useContext, useEffect, useState,
} from 'react';
import PropTypes from 'prop-types';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { useSnackbar } from 'notistack';
import { useParams, useLocation } from 'react-router-dom';
import styles from './CardBottleneckRecapSection.module.css';
import Color from '../../../../themes/colors';
import handleLoadings from '../../../../utilities/handleLoadings';
import handleStatusMsg from '../../../../utilities/handleStatusMsg';
import { GlobalContext } from '../../../../contexts/GlobalStateProvider';
import { initiateReportListBottleneckBacklogCard, initiateReportListBottleneckOngoingCard } from '../../../../actions/ReportActions';
import { FeedbackLoadingMain } from '../../../../components/UI_V2/Feedback/Loading';
import TitleChart from '../../DataRealtimeReport/ChartUserReport/TitleChart/TitleChart';
import BottleneckItem from '../../DataRealtimeReport/BottleneckUserReport/BottleneckItem/BottleneckItem';
import SideUserReportSkeleton from '../../SidebarUserReport/SideUserReportSkeleton/SideUserReportSkeleton';
import { initiateReportListBottleneckBacklogCardSnapshot, initiateReportListBottleneckOngoingCardSnapshot } from '../../../../actions/ReportRecapActions';

const CardBottleneckRecapSection = ({
  week,
  year,
  listReport,
}) => {
  const [
    {
      currentTeam,
    },
    dispatch,
  ] = useContext(GlobalContext);
  const [loadings, setLoadings] = useState([]);
  const [members, setMembers] = useState([]);
  const { enqueueSnackbar } = useSnackbar();
  const [backlogCount, setBacklogCount] = useState(0);
  const [bottleneckBacklogCount, setBottleneckBacklogCount] = useState(0);
  const [ongoingCount, setOngoingCount] = useState(0);
  const [bottleneckOngoingCount, setBottleneckOngoingCount] = useState(0);

  const params = useParams();
  const location = useLocation();

  const { teamId, memberId, companyId } = params;

  const [currentReportListBottleneckBacklogCardSnapshot,
    setCurrentReportListBottleneckBacklogCardSnapshot] = useState();
  const [currentReportListBottleneckOngoingCardSnapshot,
    setCurrentReportListBottleneckOngoingCardSnapshot] = useState();

  useEffect(() => {
    const startLoadings = handleLoadings('initiateBottleneckSnapshot', [...loadings], 'start');
    setLoadings([...startLoadings]);

    if (!currentTeam || currentTeam?._id !== teamId) {
      return;
    }

    if (!week || !year) return;

    const initiateBottleneckSnapshotBacklogApi = async () => {
      try {
        const result = await initiateReportListBottleneckBacklogCardSnapshot({
          teamId,
          memberId,
          companyId,
          week,
          year,
          setCurrentReportListBottleneckBacklogCardSnapshot,
        });
      } catch (error) {
        const status = handleStatusMsg(error, 'error');

        enqueueSnackbar(status.message, {
          variant: 'error',
        });
      } finally {
        const endLoadings = handleLoadings('initiateBottleneckSnapshot', [...loadings], 'end');
        setLoadings([...endLoadings]);
      }
    };

    initiateBottleneckSnapshotBacklogApi();
  }, [location, week, year]);

  useEffect(() => {
    const startLoadings = handleLoadings('initiateBottleneckSnapshot', [...loadings], 'start');
    setLoadings([...startLoadings]);

    if (!currentTeam || currentTeam?._id !== teamId) {
      return;
    }

    if (!week || !year) return;

    const initiateBottleneckSnapshotOngoingApi = async () => {
      try {
        const result = await initiateReportListBottleneckOngoingCardSnapshot({
          teamId,
          memberId,
          companyId,
          week,
          year,
          setCurrentReportListBottleneckOngoingCardSnapshot,
        });
      } catch (error) {
        const status = handleStatusMsg(error, 'error');

        enqueueSnackbar(status.message, {
          variant: 'error',
        });
      } finally {
        const endLoadings = handleLoadings('initiateBottleneckSnapshot', [...loadings], 'end');
        setLoadings([...endLoadings]);
      }
    };

    initiateBottleneckSnapshotOngoingApi();
  }, [location, week, year]);

  useEffect(() => {
    if (!listReport || listReport.length < 1) return;
    const selectCurrentWeekReport = (list) => {
      const selectedReport = list.find((elem) => elem.week === week);
      return selectedReport;
    };

    const report = selectCurrentWeekReport(listReport);

    setBacklogCount(report?.count?.backlog?.value || 0);
    setBottleneckBacklogCount(report?.count?.bottleneckBacklog?.value || 0);
    setOngoingCount(report?.count?.ongoing?.value || 0);
    setBottleneckOngoingCount(report?.count?.bottleneckOngoing?.value || 0);
  }, [listReport, week, year]);

  return (
    <div className={styles.container}>
      <div className={styles.title}>
        <TitleChart>
          Kemungkinan Penghambat
        </TitleChart>
      </div>
      <div className={styles.body}>
        <FeedbackLoadingMain
          wait="initiateBottleneckSnapshot"
          loadings={loadings}
          loadingComponent={<SideUserReportSkeleton />}
        >
          <div className={styles.item}>
            <BottleneckItem
              contentRatio={`${bottleneckBacklogCount} dari ${backlogCount} tugas yg belum`}
              titleRatio="masih belum dikerjakan juga padahal udah >40 hari"
              titleCard="Top 1 tugas terlama belum dikerjakan"
              card={currentReportListBottleneckBacklogCardSnapshot}
            />
          </div>
        </FeedbackLoadingMain>
        <FeedbackLoadingMain
          wait="initiateBottleneckSnapshot"
          loadings={loadings}
          loadingComponent={<SideUserReportSkeleton />}
        >
          <div className={styles.item}>
            <BottleneckItem
              contentRatio={`${bottleneckOngoingCount} dari ${ongoingCount} tugas yg dikerjakan`}
              titleRatio="masih belum selesai juga padahal udah >40 hari"
              titleCard="Top 1 tugas terlama sedang dikerjakan"
              card={currentReportListBottleneckOngoingCardSnapshot}
            />
          </div>
        </FeedbackLoadingMain>
      </div>
    </div>
  );
};

CardBottleneckRecapSection.propTypes = {
  listReport: PropTypes.array.isRequired,
  week: PropTypes.string.isRequired,
  year: PropTypes.string.isRequired,
};

CardBottleneckRecapSection.defaultProps = {};

export default memo(CardBottleneckRecapSection);
