import React, { useState, useEffect, memo } from 'react';
import PropTypes from 'prop-types';
import ReactTimeAgo from 'react-time-ago';
import { DisplayTextBody } from '../Text';
import { dateBeautifyFormatter, timeBeautifyFormatter } from '../../../../utilities/dateUtil';

const DisplayDateText = ({
  date,
  mode,
  decoration,
  fontStyle,
  fontFamily,
  color,
  position,
  underline,
}) => {
  const newDate = new Date(date);
  const newTime = timeBeautifyFormatter(newDate);
  const dateString = dateBeautifyFormatter(newDate);
  const isToday = dateString === 'Hari Ini';
  const isYesterday = dateString === 'Kemarin';

  return (
    <DisplayTextBody
      mode={mode}
      decoration={decoration}
      fontStyle={fontStyle}
      fontFamily={fontFamily}
      color={color}
      position={position}
      underline={underline}
    >
      {isToday
        ? (
          <ReactTimeAgo date={newDate} locale="en-US" />
        )
        : null}
      {isYesterday
        ? (
          <>
            {`${dateString}, ${newTime}`}
          </>

        )
        : null}
      {!isYesterday && !isToday
        ? (
          <>
            {`${dateString}`}
          </>
        )
        : null}
    </DisplayTextBody>
  );
};

DisplayDateText.propTypes = {
  decoration: PropTypes.oneOf(['bold', 'semibold', 'regular']),
  position: PropTypes.oneOf(['left', 'center', 'right']),
  fontFamily: PropTypes.oneOf(['nunito', 'openSans']),
  fontStyle: PropTypes.oneOf(['normal', 'italic']),
  mode: PropTypes.string,
  color: PropTypes.string,
  underline: PropTypes.bool,
  date: PropTypes.instanceOf(Date).isRequired,
};

DisplayDateText.defaultProps = {
  decoration: 'regular',
  position: 'left',
  fontFamily: 'nunito',
  mode: '12',
  color: '',
  underline: false,
  fontStyle: 'normal',
};

export default memo(DisplayDateText);
