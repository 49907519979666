import React from 'react';
import PropTypes from 'prop-types';
import styles from './CheckInSnapshot.module.css';
import QuestionSnapshot from './QuestionSnapshot/QuestionSnapshot';

const CheckInSnapshot = ({ questions }) => (
  <div className={styles.mainContainer}>
    {questions !== undefined
      ? (
        <>
          {questions.map((question) => (
            <QuestionSnapshot question={question} />
          ))}
        </>
      ) : null}
  </div>
);

CheckInSnapshot.propTypes = {
  questions: PropTypes.array.isRequired,
};

export default CheckInSnapshot;
