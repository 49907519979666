import React, { memo, useState } from 'react';
import PropTypes from 'prop-types';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import styles from './SelectPeriodRealtime.module.css';
import Color from '../../../../../../themes/colors';
import ButtonTextWithCheck from '../../../../../../components/Container/FilterBar/ButtonTextWithCheck/ButtonTextWithCheck';

const SelectPeriodRealtime = ({
  selectedPeriodRealtime,
  onClickPeriodRealtime,
  hideChecked,
}) => (
  <div className={styles.container}>
    <ButtonTextWithCheck
      text="Minggu ini"
      onClick={() => onClickPeriodRealtime('thisWeek')}
      checked={selectedPeriodRealtime === 'thisWeek' && !hideChecked}
    />
    {/* <ButtonTextWithCheck
      text="2 Minggu ini"
      onClick={() => onClickPeriodRealtime('thisTwoWeek')}
      checked={selectedPeriodRealtime === 'thisTwoWeek' && !hideChecked}
    />
    <ButtonTextWithCheck
      text="Bulan ini"
      onClick={() => onClickPeriodRealtime('thisMonth')}
      checked={selectedPeriodRealtime === 'thisMonth' && !hideChecked}
    /> */}
  </div>
);

SelectPeriodRealtime.propTypes = {
  onClickPeriodRealtime: PropTypes.func,
  selectedPeriodRealtime: PropTypes.string,
  hideChecked: PropTypes.bool.isRequired,
};

SelectPeriodRealtime.defaultProps = {
  onClickPeriodRealtime: () => null,
  selectedPeriodRealtime: null,
};

export default memo(SelectPeriodRealtime);
