import React, { memo, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useParams } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import styles from './CompanyInfoBox.module.css';
import { DisplayTextBody } from '../../../../../components/UI_V2/Display/Text';
import Color from '../../../../../themes/colors';
import handleLoadings from '../../../../../utilities/handleLoadings';
import { BillingActions } from '../../../../../actions';
import handleStatusMsg from '../../../../../utilities/handleStatusMsg';
import DetailInfoBox from '../DetailInfoBox/DetailInfoBox';
import TextInfo from '../DetailInfoBox/TextInfo/TextInfo';
import { getSingleOrPlural } from '../../../../../utilities/stringUtil';

const CompanyInfoBox = ({
  company,
}) => (
  <div className={styles.container}>
    <DetailInfoBox
      title="Company Info"
    >
      <TextInfo
        title="Name"
        text={company?.name}
      />
      <TextInfo
        title="Desc"
        text={company?.desc}
      />
      <TextInfo
        title="Members"
        text={`${company?.members?.length} ${getSingleOrPlural('Member', company?.members?.length)}`}
      />
      <TextInfo
        title="Remote/Hybrid/Offline?"
        text={company?.creator?.companyRemoteOrOfflineStatus}
      />
    </DetailInfoBox>
  </div>
);

CompanyInfoBox.propTypes = {
  company: PropTypes.object.isRequired,
};

CompanyInfoBox.defaultProps = {};

export default memo(CompanyInfoBox);
