import React, { memo, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { lowerCase } from 'lodash';
import SearchOutlinedIcon from '@mui/icons-material/SearchOutlined';
import styles from './SearchComponent.module.css';

const SearchComponent = ({ handleSetFilteredDatas, originalDatas, placeholder }) => {
  const [search, setSearch] = useState({ value: undefined });

  useEffect(() => {
    if (search.value === undefined) return;

    const filteredResults = originalDatas.filter((data) => {
      let chatWith = '';
      if (data?.members) {
        chatWith = data?.members[0];
      } else {
        chatWith = data;
      }
      const lowName = lowerCase(chatWith.fullName);
      const lowSearch = lowerCase(search.value);
      return lowName.includes(lowSearch);
    });

    handleSetFilteredDatas([...filteredResults]);
  }, [search]);

  const handleChangedSearch = (event) => {
    const { value } = event.target;

    setSearch((prevValue) => ({
      ...prevValue,
      value,
    }));
  };

  return (
    <div className={styles.container}>
      <div className={styles.icon}>
        <SearchOutlinedIcon />
      </div>
      <div className={styles.text}>
        <input
          onChange={(event) => handleChangedSearch(event)}
          placeholder={placeholder}
        />
      </div>
    </div>
  );
};

SearchComponent.propTypes = {
  handleSetFilteredDatas: PropTypes.func.isRequired,
  originalDatas: PropTypes.array.isRequired,
  placeholder: PropTypes.string,
};

SearchComponent.defaultProps = {
  placeholder: '',
};

export default memo(SearchComponent);
