import React, { useState, useEffect } from 'react';
import CloseIcon from '@mui/icons-material/Close';
import { useSnackbar } from 'notistack';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import EditIcon from '@mui/icons-material/Edit';
import ModeEditOutlineOutlinedIcon from '@mui/icons-material/ModeEditOutlineOutlined';
import ArchiveOutlinedIcon from '@mui/icons-material/ArchiveOutlined';
import PropTypes from 'prop-types';
import styles from './PostMenu.module.css';
import OverlayButton from '../../../components/UI/Button/OverlayButton/OverlayButton';
import handleStatusMsg from '../../../utilities/handleStatusMsg';
import handleLoadings from '../../../utilities/handleLoadings';
import ButtonDiv from '../../../components/UI/Button/ButtonDiv/ButtonDiv';
import apiUtil from '../../../utilities/apiUtil';
import { NavigationMenuBasic, NavigationMenuItem } from '../../../components/UI_V2/Navigation/Menu';
import { FeedbackDialogAlert } from '../../../components/UI_V2/Feedback/Dialog';

const PostMenu = ({ post, cancel }) => {
  const { enqueueSnackbar } = useSnackbar();
  const [loadings, setLoadings] = useState([]);
  const [showArchivePopUp, setShowArchivePopUp] = useState(false);
  const history = useHistory();
  const location = useLocation();
  const params = useParams();
  const {
    companyId, teamId, blastId,
  } = params;

  const handleArchivedPost = async (postId) => {
    if (!postId) return;
    try {
      const startLoadings = handleLoadings('blastPostMenu', [...loadings], 'start');
      setLoadings([...startLoadings]);

      const result = await apiUtil.patch(`/api/v1/posts/${postId}/archived`, {}, {
        params: {
          companyId,
          teamId,
        },
      });

      const status = handleStatusMsg(result, 'success');

      enqueueSnackbar(status.message, {
        variant: 'success',
      });

      cancel();
    } catch (err) {
      const status = handleStatusMsg(err, 'error');

      enqueueSnackbar(status.message, {
        variant: 'error',
      });
    } finally {
      const endLoadings = handleLoadings('blastPostMenu', [...loadings], 'end');
      setLoadings([...endLoadings]);
    }
  };

  const handleEditPost = () => {
    history.push(`${location.pathname}/edit`);
  };

  const handleShowArchivePopUp = () => {
    setShowArchivePopUp(true);
  };

  const handleHideArchivePopUp = () => {
    setShowArchivePopUp(false);
  };

  return (
    <NavigationMenuBasic
      onClose={cancel}
      position="topRight"
      disabled={showArchivePopUp}
    >
      <NavigationMenuItem
        icon={<ModeEditOutlineOutlinedIcon />}
        text="Ubah"
        onClick={handleEditPost}
        disableDivider={!!post?.archived?.status}
      />
      {!post?.archived?.status && (
      <NavigationMenuItem
        onClick={handleShowArchivePopUp}
        icon={<ArchiveOutlinedIcon />}
        text="Arsipkan"
        disableDivider
      />
      )}
      <FeedbackDialogAlert
        open={showArchivePopUp}
        onClose={handleHideArchivePopUp}
        handleConfirmNo={handleHideArchivePopUp}
        handleConfirmYes={() => handleArchivedPost(post._id)}
        waitYes="blastPostMenu"
        loadings={loadings}
        labelYes="Arsipkan"
        labelNo="Batal"
        title="Arsipkan Pengumuman Ini?"
        content="Kamu perlu menghubungi CS kami untuk mengembalikan Pengumuman ini."
      />
    </NavigationMenuBasic>
  );
};

PostMenu.propTypes = {
  post: PropTypes.object.isRequired,
  cancel: PropTypes.func.isRequired,
};

export default PostMenu;
