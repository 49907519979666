import React, { memo, useState } from 'react';
import PropTypes from 'prop-types';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import styles from './DataRealtimeReport.module.css';
import Color from '../../../themes/colors';
import ChartUserReport from './ChartUserReport/ChartUserReport';
import ItemUserReport from './ItemUserReport/ItemUserReport';
import BottleneckUserReport from './BottleneckUserReport/BottleneckUserReport';

const DataRealtimeReport = ({
  allCount,
  allPercentage,
  fromDate,
  untilDate,
  handleSetAllCount,
  handleSetAllPercentage,
  member,
}) => (
  <div className={styles.container}>
    <div className={styles.chart}>
      <div className={styles.chartBody}>
        <ChartUserReport
          member={member}
          handleSetAllCount={handleSetAllCount}
          handleSetAllPercentage={handleSetAllPercentage}
          fromDate={fromDate}
          untilDate={untilDate}
        />
      </div>
    </div>
    <div className={styles.item}>
      <div className={styles.itemBody}>
        <ItemUserReport
          fromDate={fromDate}
          untilDate={untilDate}
        />
      </div>
      <div className={styles.itemBody}>
        <BottleneckUserReport
          allCount={allCount}
          allPercentage={allPercentage}
          fromDate={fromDate}
          untilDate={untilDate}
        />
      </div>
    </div>
  </div>
);

DataRealtimeReport.propTypes = {
  allCount: PropTypes.number.isRequired,
  allPercentage: PropTypes.number.isRequired,
  fromDate: PropTypes.object.isRequired,
  untilDate: PropTypes.object.isRequired,
  handleSetAllCount: PropTypes.func.isRequired,
  handleSetAllPercentage: PropTypes.func.isRequired,
  member: PropTypes.object.isRequired,
};

DataRealtimeReport.defaultProps = {};

export default memo(DataRealtimeReport);
