import { ApiConstants } from '../constants';
import { limitSeen } from '../constants/SeenConstants';
import apiUtil from '../utilities/apiUtil';
import { ErrorException } from '../utilities/handleError';
import { getLastListCreatedAt, getLastListDate, mergeObjectListAndRemoveDuplicate } from '../utilities/arrayUtil';
import { REALTIME_API_URL } from '../constants/ApiConstants';

const initiateSeenChatMessage = async ({
  companyId,
  messageId,
  chatId,
  setCurrentSeen,
  setPreviousSeen,
  initialStatePreviousSeen,
  limit = limitSeen,
}) => {
  try {
    const result = await apiUtil.get(ApiConstants.URL_V1.SEEN_CHAT_MESSAGE({ chatId, messageId }), {
      params: {
        companyId,
        limit,
      },
    },
    {},
    REALTIME_API_URL);

    setCurrentSeen(result?.data);
    setPreviousSeen(initialStatePreviousSeen);

    return result;
  } catch (error) {
    throw new ErrorException(error);
  }
};

const loadMoreSeenChatMessage = async ({
  companyId,
  messageId,
  chatId,
  setCurrentSeen,
  setPreviousSeen,
  currentSeen,
  limit = limitSeen,
}) => {
  try {
    const readAt = getLastListDate(currentSeen.data, 'readAt');

    const result = await apiUtil.get(ApiConstants.URL_V1.SEEN_CHAT_MESSAGE({ chatId, messageId }), {
      params: {
        companyId,
        limit,
        readAt,
      },
    },
    {},
    REALTIME_API_URL);

    const mergedDataListData = mergeObjectListAndRemoveDuplicate({
      currentObjectList: currentSeen,
      nextObjectList: result?.data,
      keyObject: 'data',
    });

    const mergedListData = {
      ...result?.data,
      data: mergedDataListData?.data,
    };

    setPreviousSeen(result?.data);
    setCurrentSeen(mergedListData);

    return result;
  } catch (error) {
    throw new ErrorException(error);
  }
};

const initiateSeenChatAttachment = async ({
  companyId,
  attachmentId,
  chatId,
  setCurrentSeen,
  setPreviousSeen,
  initialStatePreviousSeen,
  limit = limitSeen,
}) => {
  try {
    const result = await apiUtil.get(ApiConstants.URL_V1.SEEN_CHAT_ATTACHMENT(
      { chatId, attachmentId },
    ), {
      params: {
        companyId,
        limit,
      },
    },
    {},
    REALTIME_API_URL);

    setCurrentSeen(result?.data);
    setPreviousSeen(initialStatePreviousSeen);

    return result;
  } catch (error) {
    throw new ErrorException(error);
  }
};

const loadMoreSeenChatAttachment = async ({
  companyId,
  attachmentId,
  chatId,
  setCurrentSeen,
  setPreviousSeen,
  currentSeen,
  limit = limitSeen,
}) => {
  try {
    const readAt = getLastListDate(currentSeen.data, 'readAt');

    const result = await apiUtil.get(ApiConstants.URL_V1.SEEN_CHAT_ATTACHMENT(
      { chatId, attachmentId },
    ), {
      params: {
        companyId,
        limit,
        readAt,
      },
    }, {}, REALTIME_API_URL);

    const mergedDataListData = mergeObjectListAndRemoveDuplicate({
      currentObjectList: currentSeen,
      nextObjectList: result?.data,
      keyObject: 'data',
    });

    const mergedListData = {
      ...result?.data,
      data: mergedDataListData?.data,
    };

    setPreviousSeen(result?.data);
    setCurrentSeen(mergedListData);

    return result;
  } catch (error) {
    throw new ErrorException(error);
  }
};

const initiateSeenGroupChatMessage = async ({
  companyId,
  teamId,
  groupMessageId,
  groupChatId,
  setCurrentSeen,
  setPreviousSeen,
  initialStatePreviousSeen,
  limit = limitSeen,
}) => {
  try {
    const result = await apiUtil.get(ApiConstants.URL_V1.SEEN_GROUP_CHAT_MESSAGE(
      { groupChatId, groupMessageId },
    ), {
      params: {
        companyId,
        teamId,
        limit,
      },
    }, {}, REALTIME_API_URL);

    setCurrentSeen(result?.data);
    setPreviousSeen(initialStatePreviousSeen);

    return result;
  } catch (error) {
    throw new ErrorException(error);
  }
};

const loadMoreSeenGroupChatMessage = async ({
  companyId,
  teamId,
  groupMessageId,
  groupChatId,
  setCurrentSeen,
  setPreviousSeen,
  currentSeen,
  limit = limitSeen,
}) => {
  try {
    const readAt = getLastListDate(currentSeen.data, 'readAt');

    const result = await apiUtil.get(ApiConstants.URL_V1.SEEN_GROUP_CHAT_MESSAGE(
      { groupChatId, groupMessageId },
    ), {
      params: {
        companyId,
        teamId,
        limit,
        readAt,
      },
    }, {}, REALTIME_API_URL);

    const mergedDataListData = mergeObjectListAndRemoveDuplicate({
      currentObjectList: currentSeen,
      nextObjectList: result?.data,
      keyObject: 'data',
    });

    const mergedListData = {
      ...result?.data,
      data: mergedDataListData?.data,
    };

    setPreviousSeen(result?.data);
    setCurrentSeen(mergedListData);

    return result;
  } catch (error) {
    throw new ErrorException(error);
  }
};

const initiateSeenGroupChatAttachment = async ({
  companyId,
  teamId,
  attachmentId,
  groupChatId,
  setCurrentSeen,
  setPreviousSeen,
  initialStatePreviousSeen,
  limit = limitSeen,
}) => {
  try {
    const result = await apiUtil.get(ApiConstants.URL_V1.SEEN_GROUP_CHAT_ATTACHMENT(
      { groupChatId, attachmentId },
    ), {
      params: {
        companyId,
        teamId,
        limit,
      },
    }, {}, REALTIME_API_URL);

    setCurrentSeen(result?.data);
    setPreviousSeen(initialStatePreviousSeen);

    return result;
  } catch (error) {
    throw new ErrorException(error);
  }
};

const loadMoreSeenGroupChatAttachment = async ({
  companyId,
  teamId,
  attachmentId,
  groupChatId,
  setCurrentSeen,
  setPreviousSeen,
  currentSeen,
  limit = limitSeen,
}) => {
  try {
    const readAt = getLastListDate(currentSeen.data, 'readAt');

    const result = await apiUtil.get(ApiConstants.URL_V1.SEEN_GROUP_CHAT_ATTACHMENT(
      { groupChatId, attachmentId },
    ), {
      params: {
        companyId,
        teamId,
        limit,
        readAt,
      },
    }, {}, REALTIME_API_URL);

    const mergedDataListData = mergeObjectListAndRemoveDuplicate({
      currentObjectList: currentSeen,
      nextObjectList: result?.data,
      keyObject: 'data',
    });

    const mergedListData = {
      ...result?.data,
      data: mergedDataListData?.data,
    };

    setPreviousSeen(result?.data);
    setCurrentSeen(mergedListData);

    return result;
  } catch (error) {
    throw new ErrorException(error);
  }
};

const initiateSeenCard = async ({
  companyId,
  teamId,
  cardId,
  setCurrentSeen,
  setPreviousSeen,
  initialStatePreviousSeen,
  limit = limitSeen,
}) => {
  try {
    const result = await apiUtil.get(ApiConstants.URL_V1.SEEN_CARD({ cardId }), {
      params: {
        companyId,
        teamId,
        limit,
      },
    }, {}, REALTIME_API_URL);

    setCurrentSeen(result?.data);
    setPreviousSeen(initialStatePreviousSeen);

    return result;
  } catch (error) {
    throw new ErrorException(error);
  }
};

const loadMoreSeenCard = async ({
  companyId,
  teamId,
  cardId,
  setCurrentSeen,
  setPreviousSeen,
  currentSeen,
  limit = limitSeen,
}) => {
  try {
    const readAt = getLastListDate(currentSeen.data, 'readAt');

    const result = await apiUtil.get(ApiConstants.URL_V1.SEEN_CARD({ cardId }), {
      params: {
        companyId,
        teamId,
        limit,
        readAt,
      },
    }, {}, REALTIME_API_URL);

    const mergedDataListData = mergeObjectListAndRemoveDuplicate({
      currentObjectList: currentSeen,
      nextObjectList: result?.data,
      keyObject: 'data',
    });

    const mergedListData = {
      ...result?.data,
      data: mergedDataListData?.data,
    };

    setPreviousSeen(result?.data);
    setCurrentSeen(mergedListData);

    return result;
  } catch (error) {
    throw new ErrorException(error);
  }
};

const initiateSeenCardComment = async ({
  companyId,
  teamId,
  cardId,
  commentId,
  setCurrentSeen,
  setPreviousSeen,
  initialStatePreviousSeen,
  limit = limitSeen,
}) => {
  try {
    const result = await apiUtil.get(ApiConstants.URL_V1.SEEN_CARD_COMMENT({ cardId, commentId }), {
      params: {
        companyId,
        teamId,
        limit,
      },
    }, {}, REALTIME_API_URL);

    setCurrentSeen(result?.data);
    setPreviousSeen(initialStatePreviousSeen);

    return result;
  } catch (error) {
    throw new ErrorException(error);
  }
};

const loadMoreSeenCardComment = async ({
  companyId,
  teamId,
  cardId,
  commentId,
  setCurrentSeen,
  setPreviousSeen,
  currentSeen,
  limit = limitSeen,
}) => {
  try {
    const readAt = getLastListDate(currentSeen.data, 'readAt');

    const result = await apiUtil.get(ApiConstants.URL_V1.SEEN_CARD_COMMENT({ cardId, commentId }), {
      params: {
        companyId,
        teamId,
        limit,
        readAt,
      },
    }, {}, REALTIME_API_URL);

    const mergedDataListData = mergeObjectListAndRemoveDuplicate({
      currentObjectList: currentSeen,
      nextObjectList: result?.data,
      keyObject: 'data',
    });

    const mergedListData = {
      ...result?.data,
      data: mergedDataListData?.data,
    };

    setPreviousSeen(result?.data);
    setCurrentSeen(mergedListData);

    return result;
  } catch (error) {
    throw new ErrorException(error);
  }
};

const initiateSeenCardDiscussion = async ({
  companyId,
  teamId,
  cardId,
  commentId,
  discussionId,
  setCurrentSeen,
  setPreviousSeen,
  initialStatePreviousSeen,
  limit = limitSeen,
}) => {
  try {
    const result = await apiUtil.get(ApiConstants.URL_V1.SEEN_CARD_DISCUSSION(
      { cardId, commentId, discussionId },
    ), {
      params: {
        companyId,
        teamId,
        limit,
      },
    }, {}, REALTIME_API_URL);

    setCurrentSeen(result?.data);
    setPreviousSeen(initialStatePreviousSeen);

    return result;
  } catch (error) {
    throw new ErrorException(error);
  }
};

const loadMoreSeenCardDiscussion = async ({
  companyId,
  teamId,
  cardId,
  commentId,
  discussionId,
  setCurrentSeen,
  setPreviousSeen,
  currentSeen,
  limit = limitSeen,
}) => {
  try {
    const readAt = getLastListDate(currentSeen.data, 'readAt');

    const result = await apiUtil.get(ApiConstants.URL_V1.SEEN_CARD_DISCUSSION(
      { cardId, commentId, discussionId },
    ), {
      params: {
        companyId,
        teamId,
        limit,
        readAt,
      },
    }, {}, REALTIME_API_URL);

    const mergedDataListData = mergeObjectListAndRemoveDuplicate({
      currentObjectList: currentSeen,
      nextObjectList: result?.data,
      keyObject: 'data',
    });

    const mergedListData = {
      ...result?.data,
      data: mergedDataListData?.data,
    };

    setPreviousSeen(result?.data);
    setCurrentSeen(mergedListData);

    return result;
  } catch (error) {
    throw new ErrorException(error);
  }
};

const initiateSeenPost = async ({
  companyId,
  teamId,
  postId,
  setCurrentSeen,
  setPreviousSeen,
  initialStatePreviousSeen,
  limit = limitSeen,
}) => {
  try {
    const result = await apiUtil.get(ApiConstants.URL_V1.SEEN_POST({ postId }), {
      params: {
        companyId,
        teamId,
        limit,
      },
    }, {}, REALTIME_API_URL);

    setCurrentSeen(result?.data);
    setPreviousSeen(initialStatePreviousSeen);

    return result;
  } catch (error) {
    throw new ErrorException(error);
  }
};

const loadMoreSeenPost = async ({
  companyId,
  teamId,
  postId,
  setCurrentSeen,
  setPreviousSeen,
  currentSeen,
  limit = limitSeen,
}) => {
  try {
    const readAt = getLastListDate(currentSeen.data, 'readAt');

    const result = await apiUtil.get(ApiConstants.URL_V1.SEEN_POST({ postId }), {
      params: {
        companyId,
        teamId,
        limit,
        readAt,
      },
    }, {}, REALTIME_API_URL);

    const mergedDataListData = mergeObjectListAndRemoveDuplicate({
      currentObjectList: currentSeen,
      nextObjectList: result?.data,
      keyObject: 'data',
    });

    const mergedListData = {
      ...result?.data,
      data: mergedDataListData?.data,
    };

    setPreviousSeen(result?.data);
    setCurrentSeen(mergedListData);

    return result;
  } catch (error) {
    throw new ErrorException(error);
  }
};

const initiateSeenPostComment = async ({
  companyId,
  teamId,
  postId,
  commentId,
  setCurrentSeen,
  setPreviousSeen,
  initialStatePreviousSeen,
  limit = limitSeen,
}) => {
  try {
    const result = await apiUtil.get(ApiConstants.URL_V1.SEEN_POST_COMMENT({ postId, commentId }), {
      params: {
        companyId,
        teamId,
        limit,
      },
    }, {}, REALTIME_API_URL);

    setCurrentSeen(result?.data);
    setPreviousSeen(initialStatePreviousSeen);

    return result;
  } catch (error) {
    throw new ErrorException(error);
  }
};

const loadMoreSeenPostComment = async ({
  companyId,
  teamId,
  postId,
  commentId,
  setCurrentSeen,
  setPreviousSeen,
  currentSeen,
  limit = limitSeen,
}) => {
  try {
    const readAt = getLastListDate(currentSeen.data, 'readAt');

    const result = await apiUtil.get(ApiConstants.URL_V1.SEEN_POST_COMMENT({ postId, commentId }), {
      params: {
        companyId,
        teamId,
        limit,
        readAt,
      },
    }, {}, REALTIME_API_URL);

    const mergedDataListData = mergeObjectListAndRemoveDuplicate({
      currentObjectList: currentSeen,
      nextObjectList: result?.data,
      keyObject: 'data',
    });

    const mergedListData = {
      ...result?.data,
      data: mergedDataListData?.data,
    };

    setPreviousSeen(result?.data);
    setCurrentSeen(mergedListData);

    return result;
  } catch (error) {
    throw new ErrorException(error);
  }
};

const initiateSeenPostDiscussion = async ({
  companyId,
  teamId,
  postId,
  commentId,
  discussionId,
  setCurrentSeen,
  setPreviousSeen,
  initialStatePreviousSeen,
  limit = limitSeen,
}) => {
  try {
    const result = await apiUtil.get(ApiConstants.URL_V1.SEEN_POST_DISCUSSION(
      { postId, commentId, discussionId },
    ), {
      params: {
        companyId,
        teamId,
        limit,
      },
    }, {}, REALTIME_API_URL);

    setCurrentSeen(result?.data);
    setPreviousSeen(initialStatePreviousSeen);

    return result;
  } catch (error) {
    throw new ErrorException(error);
  }
};

const loadMoreSeenPostDiscussion = async ({
  companyId,
  teamId,
  postId,
  commentId,
  discussionId,
  setCurrentSeen,
  setPreviousSeen,
  currentSeen,
  limit = limitSeen,
}) => {
  try {
    const readAt = getLastListDate(currentSeen.data, 'readAt');

    const result = await apiUtil.get(ApiConstants.URL_V1.SEEN_POST_DISCUSSION(
      { postId, commentId, discussionId },
    ), {
      params: {
        companyId,
        teamId,
        limit,
        readAt,
      },
    }, {}, REALTIME_API_URL);

    const mergedDataListData = mergeObjectListAndRemoveDuplicate({
      currentObjectList: currentSeen,
      nextObjectList: result?.data,
      keyObject: 'data',
    });

    const mergedListData = {
      ...result?.data,
      data: mergedDataListData?.data,
    };

    setPreviousSeen(result?.data);
    setCurrentSeen(mergedListData);

    return result;
  } catch (error) {
    throw new ErrorException(error);
  }
};

const initiateSeenDoc = async ({
  companyId,
  teamId,
  docId,
  setCurrentSeen,
  setPreviousSeen,
  initialStatePreviousSeen,
  limit = limitSeen,
}) => {
  try {
    const result = await apiUtil.get(ApiConstants.URL_V1.SEEN_DOC({ docId }), {
      params: {
        companyId,
        teamId,
        limit,
      },
    }, {}, REALTIME_API_URL);

    setCurrentSeen(result?.data);
    setPreviousSeen(initialStatePreviousSeen);

    return result;
  } catch (error) {
    throw new ErrorException(error);
  }
};

const loadMoreSeenDoc = async ({
  companyId,
  teamId,
  docId,
  setCurrentSeen,
  setPreviousSeen,
  currentSeen,
  limit = limitSeen,
}) => {
  try {
    const readAt = getLastListDate(currentSeen.data, 'readAt');

    const result = await apiUtil.get(ApiConstants.URL_V1.SEEN_DOC({ docId }), {
      params: {
        companyId,
        teamId,
        limit,
        readAt,
      },
    }, {}, REALTIME_API_URL);

    const mergedDataListData = mergeObjectListAndRemoveDuplicate({
      currentObjectList: currentSeen,
      nextObjectList: result?.data,
      keyObject: 'data',
    });

    const mergedListData = {
      ...result?.data,
      data: mergedDataListData?.data,
    };

    setPreviousSeen(result?.data);
    setCurrentSeen(mergedListData);

    return result;
  } catch (error) {
    throw new ErrorException(error);
  }
};

const initiateSeenDocComment = async ({
  companyId,
  teamId,
  docId,
  commentId,
  setCurrentSeen,
  setPreviousSeen,
  initialStatePreviousSeen,
  limit = limitSeen,
}) => {
  try {
    const result = await apiUtil.get(ApiConstants.URL_V1.SEEN_DOC_COMMENT({ docId, commentId }), {
      params: {
        companyId,
        teamId,
        limit,
      },
    }, {}, REALTIME_API_URL);

    setCurrentSeen(result?.data);
    setPreviousSeen(initialStatePreviousSeen);

    return result;
  } catch (error) {
    throw new ErrorException(error);
  }
};

const loadMoreSeenDocComment = async ({
  companyId,
  teamId,
  docId,
  commentId,
  setCurrentSeen,
  setPreviousSeen,
  currentSeen,
  limit = limitSeen,
}) => {
  try {
    const readAt = getLastListDate(currentSeen.data, 'readAt');

    const result = await apiUtil.get(ApiConstants.URL_V1.SEEN_DOC_COMMENT({ docId, commentId }), {
      params: {
        companyId,
        teamId,
        limit,
        readAt,
      },
    }, {}, REALTIME_API_URL);

    const mergedDataListData = mergeObjectListAndRemoveDuplicate({
      currentObjectList: currentSeen,
      nextObjectList: result?.data,
      keyObject: 'data',
    });

    const mergedListData = {
      ...result?.data,
      data: mergedDataListData?.data,
    };

    setPreviousSeen(result?.data);
    setCurrentSeen(mergedListData);

    return result;
  } catch (error) {
    throw new ErrorException(error);
  }
};

const initiateSeenDocDiscussion = async ({
  companyId,
  teamId,
  docId,
  commentId,
  discussionId,
  setCurrentSeen,
  setPreviousSeen,
  initialStatePreviousSeen,
  limit = limitSeen,
}) => {
  try {
    const result = await apiUtil.get(ApiConstants.URL_V1.SEEN_DOC_DISCUSSION(
      { docId, commentId, discussionId },
    ), {
      params: {
        companyId,
        teamId,
        limit,
      },
    }, {}, REALTIME_API_URL);

    setCurrentSeen(result?.data);
    setPreviousSeen(initialStatePreviousSeen);

    return result;
  } catch (error) {
    throw new ErrorException(error);
  }
};

const loadMoreSeenDocDiscussion = async ({
  companyId,
  teamId,
  docId,
  commentId,
  discussionId,
  setCurrentSeen,
  setPreviousSeen,
  currentSeen,
  limit = limitSeen,
}) => {
  try {
    const readAt = getLastListDate(currentSeen.data, 'readAt');

    const result = await apiUtil.get(ApiConstants.URL_V1.SEEN_DOC_DISCUSSION(
      { docId, commentId, discussionId },
    ), {
      params: {
        companyId,
        teamId,
        limit,
        readAt,
      },
    }, {}, REALTIME_API_URL);

    const mergedDataListData = mergeObjectListAndRemoveDuplicate({
      currentObjectList: currentSeen,
      nextObjectList: result?.data,
      keyObject: 'data',
    });

    const mergedListData = {
      ...result?.data,
      data: mergedDataListData?.data,
    };

    setPreviousSeen(result?.data);
    setCurrentSeen(mergedListData);

    return result;
  } catch (error) {
    throw new ErrorException(error);
  }
};

const initiateSeenFile = async ({
  companyId,
  teamId,
  fileId,
  setCurrentSeen,
  setPreviousSeen,
  initialStatePreviousSeen,
  limit = limitSeen,
}) => {
  try {
    const result = await apiUtil.get(ApiConstants.URL_V1.SEEN_FILE({ fileId }), {
      params: {
        companyId,
        teamId,
        limit,
      },
    }, {}, REALTIME_API_URL);

    setCurrentSeen(result?.data);
    setPreviousSeen(initialStatePreviousSeen);

    return result;
  } catch (error) {
    throw new ErrorException(error);
  }
};

const loadMoreSeenFile = async ({
  companyId,
  teamId,
  fileId,
  setCurrentSeen,
  setPreviousSeen,
  currentSeen,
  limit = limitSeen,
}) => {
  try {
    const readAt = getLastListDate(currentSeen.data, 'readAt');

    const result = await apiUtil.get(ApiConstants.URL_V1.SEEN_FILE({ fileId }), {
      params: {
        companyId,
        teamId,
        limit,
        readAt,
      },
    }, {}, REALTIME_API_URL);

    const mergedDataListData = mergeObjectListAndRemoveDuplicate({
      currentObjectList: currentSeen,
      nextObjectList: result?.data,
      keyObject: 'data',
    });

    const mergedListData = {
      ...result?.data,
      data: mergedDataListData?.data,
    };

    setPreviousSeen(result?.data);
    setCurrentSeen(mergedListData);

    return result;
  } catch (error) {
    throw new ErrorException(error);
  }
};

const initiateSeenFileComment = async ({
  companyId,
  teamId,
  fileId,
  commentId,
  setCurrentSeen,
  setPreviousSeen,
  initialStatePreviousSeen,
  limit = limitSeen,
}) => {
  try {
    const result = await apiUtil.get(ApiConstants.URL_V1.SEEN_FILE_COMMENT({ fileId, commentId }), {
      params: {
        companyId,
        teamId,
        limit,
      },
    }, {}, REALTIME_API_URL);

    setCurrentSeen(result?.data);
    setPreviousSeen(initialStatePreviousSeen);

    return result;
  } catch (error) {
    throw new ErrorException(error);
  }
};

const loadMoreSeenFileComment = async ({
  companyId,
  teamId,
  fileId,
  commentId,
  setCurrentSeen,
  setPreviousSeen,
  currentSeen,
  limit = limitSeen,
}) => {
  try {
    const readAt = getLastListDate(currentSeen.data, 'readAt');

    const result = await apiUtil.get(ApiConstants.URL_V1.SEEN_FILE_COMMENT({ fileId, commentId }), {
      params: {
        companyId,
        teamId,
        limit,
        readAt,
      },
    }, {}, REALTIME_API_URL);

    const mergedDataListData = mergeObjectListAndRemoveDuplicate({
      currentObjectList: currentSeen,
      nextObjectList: result?.data,
      keyObject: 'data',
    });

    const mergedListData = {
      ...result?.data,
      data: mergedDataListData?.data,
    };

    setPreviousSeen(result?.data);
    setCurrentSeen(mergedListData);

    return result;
  } catch (error) {
    throw new ErrorException(error);
  }
};

const initiateSeenFileDiscussion = async ({
  companyId,
  teamId,
  fileId,
  commentId,
  discussionId,
  setCurrentSeen,
  setPreviousSeen,
  initialStatePreviousSeen,
  limit = limitSeen,
}) => {
  try {
    const result = await apiUtil.get(ApiConstants.URL_V1.SEEN_FILE_DISCUSSION(
      { fileId, commentId, discussionId },
    ), {
      params: {
        companyId,
        teamId,
        limit,
      },
    }, {}, REALTIME_API_URL);

    setCurrentSeen(result?.data);
    setPreviousSeen(initialStatePreviousSeen);

    return result;
  } catch (error) {
    throw new ErrorException(error);
  }
};

const loadMoreSeenFileDiscussion = async ({
  companyId,
  teamId,
  fileId,
  commentId,
  discussionId,
  setCurrentSeen,
  setPreviousSeen,
  currentSeen,
  limit = limitSeen,
}) => {
  try {
    const readAt = getLastListDate(currentSeen.data, 'readAt');

    const result = await apiUtil.get(ApiConstants.URL_V1.SEEN_FILE_DISCUSSION(
      { fileId, commentId, discussionId },
    ), {
      params: {
        companyId,
        teamId,
        limit,
        readAt,
      },
    }, {}, REALTIME_API_URL);

    const mergedDataListData = mergeObjectListAndRemoveDuplicate({
      currentObjectList: currentSeen,
      nextObjectList: result?.data,
      keyObject: 'data',
    });

    const mergedListData = {
      ...result?.data,
      data: mergedDataListData?.data,
    };

    setPreviousSeen(result?.data);
    setCurrentSeen(mergedListData);

    return result;
  } catch (error) {
    throw new ErrorException(error);
  }
};

const initiateSeenQuestionComment = async ({
  companyId,
  teamId,
  questionId,
  commentId,
  setCurrentSeen,
  setPreviousSeen,
  initialStatePreviousSeen,
  limit = limitSeen,
}) => {
  try {
    const result = await apiUtil.get(ApiConstants.URL_V1.SEEN_QUESTION_COMMENT(
      { questionId, commentId },
    ), {
      params: {
        companyId,
        teamId,
        limit,
      },
    }, {}, REALTIME_API_URL);

    setCurrentSeen(result?.data);
    setPreviousSeen(initialStatePreviousSeen);

    return result;
  } catch (error) {
    throw new ErrorException(error);
  }
};

const loadMoreSeenQuestionComment = async ({
  companyId,
  teamId,
  questionId,
  commentId,
  setCurrentSeen,
  setPreviousSeen,
  currentSeen,
  limit = limitSeen,
}) => {
  try {
    const readAt = getLastListDate(currentSeen.data, 'readAt');

    const result = await apiUtil.get(ApiConstants.URL_V1.SEEN_QUESTION_COMMENT(
      { questionId, commentId },
    ), {
      params: {
        companyId,
        teamId,
        limit,
        readAt,
      },
    }, {}, REALTIME_API_URL);

    const mergedDataListData = mergeObjectListAndRemoveDuplicate({
      currentObjectList: currentSeen,
      nextObjectList: result?.data,
      keyObject: 'data',
    });

    const mergedListData = {
      ...result?.data,
      data: mergedDataListData?.data,
    };

    setPreviousSeen(result?.data);
    setCurrentSeen(mergedListData);

    return result;
  } catch (error) {
    throw new ErrorException(error);
  }
};

const initiateSeenQuestionDiscussion = async ({
  companyId,
  teamId,
  questionId,
  commentId,
  discussionId,
  setCurrentSeen,
  setPreviousSeen,
  initialStatePreviousSeen,
  limit = limitSeen,
}) => {
  try {
    const result = await apiUtil.get(ApiConstants.URL_V1.SEEN_QUESTION_DISCUSSION(
      { questionId, commentId, discussionId },
    ), {
      params: {
        companyId,
        teamId,
        limit,
      },
    }, {}, REALTIME_API_URL);

    setCurrentSeen(result?.data);
    setPreviousSeen(initialStatePreviousSeen);

    return result;
  } catch (error) {
    throw new ErrorException(error);
  }
};

const loadMoreSeenQuestionDiscussion = async ({
  companyId,
  teamId,
  questionId,
  commentId,
  discussionId,
  setCurrentSeen,
  setPreviousSeen,
  currentSeen,
  limit = limitSeen,
}) => {
  try {
    const readAt = getLastListDate(currentSeen.data, 'readAt');

    const result = await apiUtil.get(ApiConstants.URL_V1.SEEN_QUESTION_DISCUSSION(
      { questionId, commentId, discussionId },
    ), {
      params: {
        companyId,
        teamId,
        limit,
        readAt,
      },
    }, {}, REALTIME_API_URL);

    const mergedDataListData = mergeObjectListAndRemoveDuplicate({
      currentObjectList: currentSeen,
      nextObjectList: result?.data,
      keyObject: 'data',
    });

    const mergedListData = {
      ...result?.data,
      data: mergedDataListData?.data,
    };

    setPreviousSeen(result?.data);
    setCurrentSeen(mergedListData);

    return result;
  } catch (error) {
    throw new ErrorException(error);
  }
};

const initiateSeenEvent = async ({
  companyId,
  teamId,
  eventId,
  setCurrentSeen,
  setPreviousSeen,
  initialStatePreviousSeen,
  limit = limitSeen,
}) => {
  try {
    const result = await apiUtil.get(ApiConstants.URL_V1.SEEN_EVENT({ eventId }), {
      params: {
        companyId,
        teamId,
        limit,
      },
    }, {}, REALTIME_API_URL);

    setCurrentSeen(result?.data);
    setPreviousSeen(initialStatePreviousSeen);

    return result;
  } catch (error) {
    throw new ErrorException(error);
  }
};

const loadMoreSeenEvent = async ({
  companyId,
  teamId,
  eventId,
  setCurrentSeen,
  setPreviousSeen,
  currentSeen,
  limit = limitSeen,
}) => {
  try {
    const readAt = getLastListDate(currentSeen.data, 'readAt');

    const result = await apiUtil.get(ApiConstants.URL_V1.SEEN_EVENT({ eventId }), {
      params: {
        companyId,
        teamId,
        limit,
        readAt,
      },
    }, {}, REALTIME_API_URL);

    const mergedDataListData = mergeObjectListAndRemoveDuplicate({
      currentObjectList: currentSeen,
      nextObjectList: result?.data,
      keyObject: 'data',
    });

    const mergedListData = {
      ...result?.data,
      data: mergedDataListData?.data,
    };

    setPreviousSeen(result?.data);
    setCurrentSeen(mergedListData);

    return result;
  } catch (error) {
    throw new ErrorException(error);
  }
};

const initiateSeenEventComment = async ({
  companyId,
  teamId,
  eventId,
  commentId,
  setCurrentSeen,
  setPreviousSeen,
  initialStatePreviousSeen,
  limit = limitSeen,
}) => {
  try {
    const result = await apiUtil.get(ApiConstants.URL_V1.SEEN_EVENT_COMMENT(
      { eventId, commentId },
    ), {
      params: {
        companyId,
        teamId,
        limit,
      },
    }, {}, REALTIME_API_URL);

    setCurrentSeen(result?.data);
    setPreviousSeen(initialStatePreviousSeen);

    return result;
  } catch (error) {
    throw new ErrorException(error);
  }
};

const loadMoreSeenEventComment = async ({
  companyId,
  teamId,
  eventId,
  commentId,
  setCurrentSeen,
  setPreviousSeen,
  currentSeen,
  limit = limitSeen,
}) => {
  try {
    const readAt = getLastListDate(currentSeen.data, 'readAt');

    const result = await apiUtil.get(ApiConstants.URL_V1.SEEN_EVENT_COMMENT(
      { eventId, commentId },
    ), {
      params: {
        companyId,
        teamId,
        limit,
        readAt,
      },
    }, {}, REALTIME_API_URL);

    const mergedDataListData = mergeObjectListAndRemoveDuplicate({
      currentObjectList: currentSeen,
      nextObjectList: result?.data,
      keyObject: 'data',
    });

    const mergedListData = {
      ...result?.data,
      data: mergedDataListData?.data,
    };

    setPreviousSeen(result?.data);
    setCurrentSeen(mergedListData);

    return result;
  } catch (error) {
    throw new ErrorException(error);
  }
};

const initiateSeenEventDiscussion = async ({
  companyId,
  teamId,
  eventId,
  commentId,
  discussionId,
  setCurrentSeen,
  setPreviousSeen,
  initialStatePreviousSeen,
  limit = limitSeen,
}) => {
  try {
    const result = await apiUtil.get(ApiConstants.URL_V1.SEEN_EVENT_DISCUSSION(
      { eventId, commentId, discussionId },
    ), {
      params: {
        companyId,
        teamId,
        limit,
      },
    }, {}, REALTIME_API_URL);

    setCurrentSeen(result?.data);
    setPreviousSeen(initialStatePreviousSeen);

    return result;
  } catch (error) {
    throw new ErrorException(error);
  }
};

const loadMoreSeenEventDiscussion = async ({
  companyId,
  teamId,
  eventId,
  commentId,
  discussionId,
  setCurrentSeen,
  setPreviousSeen,
  currentSeen,
  limit = limitSeen,
}) => {
  try {
    const readAt = getLastListDate(currentSeen.data, 'readAt');

    const result = await apiUtil.get(ApiConstants.URL_V1.SEEN_EVENT_DISCUSSION(
      { eventId, commentId, discussionId },
    ), {
      params: {
        companyId,
        teamId,
        limit,
        readAt,
      },
    }, {}, REALTIME_API_URL);

    const mergedDataListData = mergeObjectListAndRemoveDuplicate({
      currentObjectList: currentSeen,
      nextObjectList: result?.data,
      keyObject: 'data',
    });

    const mergedListData = {
      ...result?.data,
      data: mergedDataListData?.data,
    };

    setPreviousSeen(result?.data);
    setCurrentSeen(mergedListData);

    return result;
  } catch (error) {
    throw new ErrorException(error);
  }
};

const initiateSeenOccurrence = async ({
  companyId,
  teamId,
  eventId,
  setCurrentSeen,
  setPreviousSeen,
  initialStatePreviousSeen,
  limit = limitSeen,
}) => {
  try {
    const result = await apiUtil.get(ApiConstants.URL_V1.SEEN_OCCURRENCE({ eventId }), {
      params: {
        companyId,
        teamId,
        limit,
      },
    }, {}, REALTIME_API_URL);

    setCurrentSeen(result?.data);
    setPreviousSeen(initialStatePreviousSeen);

    return result;
  } catch (error) {
    throw new ErrorException(error);
  }
};

const loadMoreSeenOccurrence = async ({
  companyId,
  teamId,
  eventId,
  setCurrentSeen,
  setPreviousSeen,
  currentSeen,
  limit = limitSeen,
}) => {
  try {
    const readAt = getLastListDate(currentSeen.data, 'readAt');

    const result = await apiUtil.get(ApiConstants.URL_V1.SEEN_OCCURRENCE({ eventId }), {
      params: {
        companyId,
        teamId,
        limit,
        readAt,
      },
    }, {}, REALTIME_API_URL);

    const mergedDataListData = mergeObjectListAndRemoveDuplicate({
      currentObjectList: currentSeen,
      nextObjectList: result?.data,
      keyObject: 'data',
    });

    const mergedListData = {
      ...result?.data,
      data: mergedDataListData?.data,
    };

    setPreviousSeen(result?.data);
    setCurrentSeen(mergedListData);

    return result;
  } catch (error) {
    throw new ErrorException(error);
  }
};

const initiateSeenOccurrenceComment = async ({
  companyId,
  teamId,
  eventId,
  occurrenceId,
  commentId,
  setCurrentSeen,
  setPreviousSeen,
  initialStatePreviousSeen,
  limit = limitSeen,
}) => {
  try {
    const result = await apiUtil.get(ApiConstants.URL_V1.SEEN_OCCURRENCE_COMMENT(
      { eventId, occurrenceId, commentId },
    ), {
      params: {
        companyId,
        teamId,
        limit,
      },
    }, {}, REALTIME_API_URL);

    setCurrentSeen(result?.data);
    setPreviousSeen(initialStatePreviousSeen);

    return result;
  } catch (error) {
    throw new ErrorException(error);
  }
};

const loadMoreSeenOccurrenceComment = async ({
  companyId,
  teamId,
  eventId,
  occurrenceId,
  commentId,
  setCurrentSeen,
  setPreviousSeen,
  currentSeen,
  limit = limitSeen,
}) => {
  try {
    const readAt = getLastListDate(currentSeen.data, 'readAt');

    const result = await apiUtil.get(ApiConstants.URL_V1.SEEN_OCCURRENCE_COMMENT(
      { eventId, occurrenceId, commentId },
    ), {
      params: {
        companyId,
        teamId,
        limit,
        readAt,
      },
    }, {}, REALTIME_API_URL);

    const mergedDataListData = mergeObjectListAndRemoveDuplicate({
      currentObjectList: currentSeen,
      nextObjectList: result?.data,
      keyObject: 'data',
    });

    const mergedListData = {
      ...result?.data,
      data: mergedDataListData?.data,
    };

    setPreviousSeen(result?.data);
    setCurrentSeen(mergedListData);

    return result;
  } catch (error) {
    throw new ErrorException(error);
  }
};

const initiateSeenOccurrenceDiscussion = async ({
  companyId,
  teamId,
  eventId,
  occurrenceId,
  commentId,
  discussionId,
  setCurrentSeen,
  setPreviousSeen,
  initialStatePreviousSeen,
  limit = limitSeen,
}) => {
  try {
    const result = await apiUtil.get(ApiConstants.URL_V1.SEEN_OCCURRENCE_DISCUSSION(
      {
        eventId, occurrenceId, commentId, discussionId,
      },
    ), {
      params: {
        companyId,
        teamId,
        limit,
      },
    }, {}, REALTIME_API_URL);

    setCurrentSeen(result?.data);
    setPreviousSeen(initialStatePreviousSeen);

    return result;
  } catch (error) {
    throw new ErrorException(error);
  }
};

const loadMoreSeenOccurrenceDiscussion = async ({
  companyId,
  teamId,
  eventId,
  occurrenceId,
  commentId,
  discussionId,
  setCurrentSeen,
  setPreviousSeen,
  currentSeen,
  limit = limitSeen,
}) => {
  try {
    const readAt = getLastListDate(currentSeen.data, 'readAt');

    const result = await apiUtil.get(ApiConstants.URL_V1.SEEN_OCCURRENCE_DISCUSSION(
      {
        eventId, occurrenceId, commentId, discussionId,
      },
    ), {
      params: {
        companyId,
        teamId,
        limit,
        readAt,
      },
    }, {}, REALTIME_API_URL);

    const mergedDataListData = mergeObjectListAndRemoveDuplicate({
      currentObjectList: currentSeen,
      nextObjectList: result?.data,
      keyObject: 'data',
    });

    const mergedListData = {
      ...result?.data,
      data: mergedDataListData?.data,
    };

    setPreviousSeen(result?.data);
    setCurrentSeen(mergedListData);

    return result;
  } catch (error) {
    throw new ErrorException(error);
  }
};

export {
  initiateSeenChatMessage,
  loadMoreSeenChatMessage,
  initiateSeenChatAttachment,
  loadMoreSeenChatAttachment,
  initiateSeenGroupChatMessage,
  loadMoreSeenGroupChatMessage,
  initiateSeenGroupChatAttachment,
  loadMoreSeenGroupChatAttachment,
  initiateSeenCard,
  loadMoreSeenCard,
  initiateSeenCardComment,
  loadMoreSeenCardComment,
  initiateSeenCardDiscussion,
  loadMoreSeenCardDiscussion,
  initiateSeenPost,
  loadMoreSeenPost,
  initiateSeenPostComment,
  loadMoreSeenPostComment,
  initiateSeenPostDiscussion,
  loadMoreSeenPostDiscussion,
  initiateSeenDoc,
  loadMoreSeenDoc,
  initiateSeenDocComment,
  loadMoreSeenDocComment,
  initiateSeenDocDiscussion,
  loadMoreSeenDocDiscussion,
  initiateSeenFile,
  loadMoreSeenFile,
  initiateSeenFileComment,
  loadMoreSeenFileComment,
  initiateSeenFileDiscussion,
  loadMoreSeenFileDiscussion,
  initiateSeenEvent,
  loadMoreSeenEvent,
  initiateSeenEventComment,
  loadMoreSeenEventComment,
  initiateSeenEventDiscussion,
  loadMoreSeenEventDiscussion,
  initiateSeenOccurrence,
  loadMoreSeenOccurrence,
  initiateSeenOccurrenceComment,
  loadMoreSeenOccurrenceComment,
  initiateSeenOccurrenceDiscussion,
  loadMoreSeenOccurrenceDiscussion,
  initiateSeenQuestionComment,
  loadMoreSeenQuestionComment,
  initiateSeenQuestionDiscussion,
  loadMoreSeenQuestionDiscussion,
};
