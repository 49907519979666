import React, {
  memo, useContext,
  useEffect,
  useState,
} from 'react';
import PropTypes from 'prop-types';
import { Box } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { isEmpty } from 'lodash';
import styles from './FilterBar.module.css';
import { DisplayTextBody, DisplayTextSubHeadline } from '../../UI_V2/Display/Text';
import Color from '../../../themes/colors';
import SearchFilterSection from './SearchFilterSection/SearchFilterSection';
import LabelFilterSection from './LabelFilterSection/LabelFilterSection';
import MemberFilterSection from './MemberFilterSection/MemberFilterSection';
import DueDateFilterSection from './DueDateFilterSection/DueDateFilterSection';
import { DisplayDividerMain } from '../../UI_V2/Display/Divider';
import HeaderFilterSection from './HeaderFilterSection/HeaderFilterSection';
import TeamFilterSection from './TeamFilterSection/TeamFilterSection';
import { useDelayShowHideHandler, useSearch, useSelectItemsOnList } from '../../../hooks/HelperHooks';
import { InputButtonMain } from '../../UI_V2/Input/Button';
import { GlobalContext } from '../../../contexts/GlobalStateProvider';

const topPositionVariantStyles = {
  navBarOnly: styles.topPositionNavbarOnly,
  withSubNavBar: styles.topPositionWithSubNavBar,
  withBottomBar: styles.topPositionWithBottomBar,
};

const bodyOverflowStyles = {
  navBarOnly: styles.bodyOverflowNavbarOnly,
  withSubNavBar: styles.bodyOverflowWithSubNavBar,
  withBottomBar: styles.bodyOverflowWithBottomBar,
};

const containerSidebarStyles = {
  true: `${styles.container} ${styles.sidebarOpen}`,
  false: styles.container,
};

const ContainerFilterBar = ({
  topPositionVariant,
  listLabels,
  initialSelectedLabels,
  showLabelSection,
  listMembers,
  initialSelectedMembers,
  showMemberSection,
  initialDueDate,
  showDueDateSection,
  listData,
  initialSearch,
  showSearchSection,
  listTeams,
  initialSelectedTeams,
  showTeamSection,
  barTitle,
  onClose,
  onApplyFilters,
  waitOnApplyFilters,
  loadingsOnApplyFilters,
  disableResetSearch,
  disableResetTeam,
  disableResetLabel,
  disableResetMember,
  disableResetDueDate,
  showBackIconOnClose,
  disableCloseIcon,
  invertResetTeamCondition,
}) => {
  const [{ isSidebarOpen }] = useContext(GlobalContext);
  /*
    Search name hook section
  */
  const searchFilterSectionSearchHook = useSearch(
    { originalList: listData, initialKeyword: initialSearch },
  );

  /*
    Team filter hook section
  */
  const teamFilterSectionSearchHook = useSearch({ originalList: listTeams });

  const [
    showAllTeams,
    handleShowAllTeams,
    handleHideAllTeams,
  ] = useDelayShowHideHandler();

  const [
    checkTeams,
    handleClickTeam,
    isAllTeamsSelected,
    handleClickAllTeams,
    isNoneTeamsSelected,
    handleResetTeams,
  ] = useSelectItemsOnList({
    list: listTeams,
    initialSelectedItems: initialSelectedTeams,
    invertResetCondition: invertResetTeamCondition,
  });

  /*
    Labels filter hook section
  */
  const labelFilterSectionSearchHook = useSearch({ originalList: listLabels });

  const [
    showAllLabels,
    handleShowAllLabels,
    handleHideAllLabels,
  ] = useDelayShowHideHandler();

  const [
    checkLabels,
    handleClickLabel,
    isAllLabelsSelected,
    handleClickAllLabels,
    isNoneLabelsSelected,
    handleResetLabels,
  ] = useSelectItemsOnList({ list: listLabels, initialSelectedItems: initialSelectedLabels });

  /*
    Members filter hook section
  */
  const memberFilterSectionSearchHook = useSearch({ originalList: listMembers });

  const [
    showAllMembers,
    handleShowAllMembers,
    handleHideAllMembers,
  ] = useDelayShowHideHandler();

  const [
    checkMembers,
    handleClickMember,
    isAllMembersSelected,
    handleClickAllMembers,
    isNoneMembersSelected,
    handleResetMembers,
  ] = useSelectItemsOnList({ list: listMembers, initialSelectedItems: initialSelectedMembers });

  /*
    Due Date filter section
  */
  const [checkDueDate, setCheckDueDate] = useState(initialDueDate);
  const handleClickDueDate = (type) => {
    setCheckDueDate(type);
  };
  const handleResetDueDate = () => {
    setCheckDueDate();
  };

  useEffect(() => {
    setCheckDueDate(initialDueDate);
  }, [initialDueDate]);

  /*
    Filter active count
  */
  const [filterActiveCount, setFilterActiveCount] = useState(0);
  useEffect(() => {
    const isSearchFilterActive = searchFilterSectionSearchHook.debouncedKeyword.length > 0
    && showSearchSection;
    let isTeamsFilterActive = checkTeams.length > 0 && showTeamSection;
    if (invertResetTeamCondition) {
      isTeamsFilterActive = checkTeams.length < listTeams.length && showTeamSection;
    }
    const isLabelsFilterActive = checkLabels.length > 0 && showLabelSection;
    const isMembersFilterActive = checkMembers.length > 0 && showMemberSection;
    const isDueDateFilterActive = checkDueDate && showDueDateSection;

    let count = 0;
    if (isSearchFilterActive) count += 1;
    if (isTeamsFilterActive) count += 1;
    if (isLabelsFilterActive) count += 1;
    if (isMembersFilterActive) count += 1;
    if (isDueDateFilterActive) count += 1;
    setFilterActiveCount(count);
  }, [
    searchFilterSectionSearchHook.debouncedKeyword,
    checkTeams,
    checkLabels,
    checkMembers,
    checkDueDate,
    barTitle,
  ]);
  /*
    Reset Filter
  */

  const handleResetFilter = () => {
    // reset search name
    if (!disableResetSearch) {
      searchFilterSectionSearchHook.resetSearch();
    }
    // reset teams
    if (!disableResetTeam) {
      teamFilterSectionSearchHook.resetSearch();
      handleResetTeams();
    }
    // resetlabels
    if (!disableResetLabel) {
      labelFilterSectionSearchHook.resetSearch();
      handleResetLabels();
    }
    // reset members
    if (!disableResetMember) {
      memberFilterSectionSearchHook.resetSearch();
      handleResetMembers();
    }
    // reset due date
    if (!disableResetDueDate) {
      handleResetDueDate();
    }
  };

  /*
    Apply Filter
  */

  const handleClickApply = () => {
    onApplyFilters({
      filterTeams: checkTeams,
      filterSubscribers: checkMembers,
      filterLabels: checkLabels,
      filterTitle: searchFilterSectionSearchHook.debouncedKeyword,
      filterDueDate: checkDueDate,
    });
  };

  return (
    <Box className={`${containerSidebarStyles[isSidebarOpen]} ${topPositionVariantStyles[topPositionVariant]}`}>
      <Box className={styles.header}>
        <HeaderFilterSection
          filterActiveCount={filterActiveCount}
          title={barTitle}
          onClose={onClose}
          onResetFilter={handleResetFilter}
          handleClickApplyFilters={handleClickApply}
          waitOnApplyFilters={waitOnApplyFilters}
          loadingsOnApplyFilters={loadingsOnApplyFilters}
          showBackIconOnClose={showBackIconOnClose}
          disableCloseIcon={disableCloseIcon}
        />
      </Box>
      <Box className={`${styles.body} ${bodyOverflowStyles[topPositionVariant]}`}>
        {showSearchSection && (
        <>
          <DisplayDividerMain borderColor={Color.gray4} />
          <Box className={`${styles.searchSection} ${styles.bodySection}`}>
            <SearchFilterSection
              searchHook={searchFilterSectionSearchHook}
            />
          </Box>
        </>
        )}
        {showTeamSection && (
        <>
          <DisplayDividerMain borderColor={Color.gray4} />
          <Box className={`${styles.teamSection} ${styles.bodySection}`}>
            <TeamFilterSection
              searchHook={teamFilterSectionSearchHook}
              showAllTeams={showAllTeams}
              handleShowAllTeams={handleShowAllTeams}
              handleHideAllTeams={handleHideAllTeams}
              checkTeams={checkTeams}
              handleClickTeam={handleClickTeam}
              handleClickAllTeams={handleClickAllTeams}
              isAllTeamsSelected={isAllTeamsSelected}
            />
          </Box>
        </>
        )}
        {showLabelSection && (
        <>
          <DisplayDividerMain borderColor={Color.gray4} />
          <Box className={`${styles.labelSection} ${styles.bodySection}`}>
            <LabelFilterSection
              searchHook={labelFilterSectionSearchHook}
              showAllLabels={showAllLabels}
              handleShowAllLabels={handleShowAllLabels}
              handleHideAllLabels={handleHideAllLabels}
              checkLabels={checkLabels}
              handleClickLabel={handleClickLabel}
            />
          </Box>
        </>
        )}
        {showMemberSection && (
        <>
          <DisplayDividerMain borderColor={Color.gray4} />
          <Box className={`${styles.memberSection} ${styles.bodySection}`}>
            <MemberFilterSection
              searchHook={memberFilterSectionSearchHook}
              showAllMembers={showAllMembers}
              handleShowAllMembers={handleShowAllMembers}
              handleHideAllMembers={handleHideAllMembers}
              checkMembers={checkMembers}
              handleClickMember={handleClickMember}
            />
          </Box>
        </>
        )}
        {showDueDateSection && (
        <>
          <DisplayDividerMain borderColor={Color.gray4} />
          <Box className={`${styles.dueDateSection} ${styles.bodySection}`}>
            <DueDateFilterSection
              checkDueDate={checkDueDate}
              handleClickDueDate={handleClickDueDate}
            />
          </Box>
        </>
        )}
      </Box>
    </Box>
  );
};

ContainerFilterBar.propTypes = {
  topPositionVariant: PropTypes.oneOf(['navbarOnly', 'withSubNavBar', 'withBottomBar']),
  listData: PropTypes.array.isRequired,
  listLabels: PropTypes.array,
  initialSelectedLabels: PropTypes.array,
  showLabelSection: PropTypes.bool,
  listMembers: PropTypes.array,
  initialSelectedMembers: PropTypes.array,
  showMemberSection: PropTypes.bool,
  initialDueDate: PropTypes.string,
  showDueDateSection: PropTypes.bool,
  initialSearch: PropTypes.string,
  showSearchSection: PropTypes.bool,
  listTeams: PropTypes.array,
  initialSelectedTeams: PropTypes.array,
  showTeamSection: PropTypes.bool,
  barTitle: PropTypes.string,
  onClose: PropTypes.func.isRequired,
  onApplyFilters: PropTypes.func.isRequired,
  waitOnApplyFilters: PropTypes.string.isRequired,
  loadingsOnApplyFilters: PropTypes.array.isRequired,
  disableResetDueDate: PropTypes.bool,
  disableResetLabel: PropTypes.bool,
  disableResetMember: PropTypes.bool,
  disableResetTeam: PropTypes.bool,
  disableResetSearch: PropTypes.bool,
  showBackIconOnClose: PropTypes.bool,
  disableCloseIcon: PropTypes.bool,
  invertResetTeamCondition: PropTypes.bool,
};

ContainerFilterBar.defaultProps = {
  topPositionVariant: 'withSubNavBar',
  listLabels: [],
  initialSelectedLabels: [],
  showLabelSection: true,
  listMembers: [],
  initialSelectedMembers: [],
  showMemberSection: true,
  showDueDateSection: true,
  showSearchSection: true,
  listTeams: [],
  initialSelectedTeams: [],
  initialSearch: '',
  initialDueDate: undefined,
  showTeamSection: true,
  barTitle: 'Filter',
  disableResetDueDate: false,
  disableResetSearch: false,
  disableResetMember: false,
  disableResetTeam: false,
  disableResetLabel: false,
  showBackIconOnClose: false,
  disableCloseIcon: false,
  invertResetTeamCondition: false,
};

export default memo(ContainerFilterBar);
