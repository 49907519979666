import React, { memo, useState } from 'react';
import PropTypes from 'prop-types';
import { Box } from '@mui/material';
import styles from './Skeleton.module.css';
import ModalPlanSelectionSkeletonCard from './Card/Card';

const ModalPlanSelectionSkeleton = () => (
  <div className={styles.container}>
    <div className={styles.card}>
      <ModalPlanSelectionSkeletonCard />
    </div>
    <div className={styles.card}>
      <ModalPlanSelectionSkeletonCard />
    </div>
    <div className={styles.card}>
      <ModalPlanSelectionSkeletonCard />
    </div>
  </div>
);

export default memo(ModalPlanSelectionSkeleton);
