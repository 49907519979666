import React, {
  useState, useContext, useCallback,
} from 'react';
import { useParams } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Element } from 'react-scroll';
import axios from 'axios';
import { useSnackbar } from 'notistack';
import CommentItem from './CommentItem/CommentItem';
import DateSeparator from '../DateSeparator/DateSeparator';
import { GlobalContext } from '../../../contexts/GlobalStateProvider';
import { dateBeautifyFormatter } from '../../../utilities/dateUtil';
import { FeedbackDialogAlert } from '../../../components/UI_V2/Feedback/Dialog';
import handleStatusMsg from '../../../utilities/handleStatusMsg';
import handleLoadings from '../../../utilities/handleLoadings';
import { CommentActions } from '../../../actions';
import { useSearchAutoScrollMessage } from '../../../hooks/GroupMessageHooks';
import { useDelayShowHideHandler } from '../../../hooks/HelperHooks';
import SeenModal from '../../../components/Container/SeenModal/SeenModal';
import { SeenConstants } from '../../../constants';
import { handleAxiosDeleteRefreshToken } from '../../../actions/CheckLoginActions';

const ShowComments = ({
  comments,
  parentIds,
  type,
  hideReply,
  disableAutoScrollHook,
  socket,
  parentTeamId,
}) => {
  const [{}, dispatch] = useContext(GlobalContext);
  const [loadings, setLoadings] = useState([]);
  const [showAlertDeleteComment, setShowAlertDeleteComment] = useState(false);
  const [deletedCommentId, setDeletedCommentId] = useState();
  const [seenCommentId, setSeenCommentId] = useState();
  const [
    showSeenModal,
    handleShowSeenModal,
    handleHideSeenModal,
  ] = useDelayShowHideHandler();
  const { enqueueSnackbar } = useSnackbar();
  const params = useParams();
  const { companyId } = params;
  const teamId = params.teamId || parentTeamId;

  const [commentRef, lastCommentRef, searchCommentId, goToComment] = useSearchAutoScrollMessage({
    list: comments,
    keyQueryProperty: 'commentId',
    scrollDirection: 'toBottom',
    isDisabled: disableAutoScrollHook,
  });

  const handleShowAlertDeleteComment = () => {
    setShowAlertDeleteComment(true);
  };

  const handleHideAlertDeleteComment = () => {
    setShowAlertDeleteComment(false);
  };

  const handleClickDeleteComment = (commentId) => {
    setDeletedCommentId(commentId);
    handleShowAlertDeleteComment();
  };

  const handleApiDeleteComment = async () => {
    try {
      const startLoadings = handleLoadings('deleteComment', [...loadings], 'start');
      setLoadings([...startLoadings]);

      const comment = {
        _id: deletedCommentId,
      };

      const url = CommentActions.getUrlApiDeleteCommentByType({
        comment,
        parentIds,
        params,
        type,
      });
      const result = await axios.delete(url, {
        withCredentials: true,
        headers: {
          Authorization: `jwt ${localStorage.getItem('token')}`,
        },
        params: {
          companyId,
          teamId,
        },
      });

      CommentActions.dispatchDeleteApiCommentByType({ type }, dispatch);

      const status = handleStatusMsg(result, 'success');

      enqueueSnackbar(status.message, {
        variant: 'success',
      });
      handleHideAlertDeleteComment();
    } catch (err) {
      const newAuthToken = await handleAxiosDeleteRefreshToken(err);

      if (newAuthToken) {
        try {
          const comment = {
            _id: deletedCommentId,
          };

          const url = CommentActions.getUrlApiDeleteCommentByType({
            comment,
            parentIds,
            params,
            type,
          });
          const result = await axios.delete(url, {
            withCredentials: true,
            headers: {
              Authorization: `jwt ${newAuthToken}`,
            },
            params: {
              companyId,
              teamId,
            },
          });

          CommentActions.dispatchDeleteApiCommentByType({ type }, dispatch);

          const status = handleStatusMsg(result, 'success');

          enqueueSnackbar(status.message, {
            variant: 'success',
          });
          handleHideAlertDeleteComment();
        } catch (error) {
          const status = handleStatusMsg(error, 'error');

          enqueueSnackbar(status.message, {
            variant: 'error',
          });
        }
      } else {
        const status = handleStatusMsg(err, 'error');

        enqueueSnackbar(status.message, {
          variant: 'error',
        });
      }
    } finally {
      const endLoadings = handleLoadings('deleteComment', [...loadings], 'end');
      setLoadings([...endLoadings]);
    }
  };

  const handleCompareDate = (comment, prevCommentIndex) => {
    // DISABLING COMMENT LOCAL VERSION.
    // const prevComment = currentComments[prevCommentIndex];
    const prevComment = comments[prevCommentIndex];

    if (prevComment === undefined) return false;

    const dateStringComment = dateBeautifyFormatter(comment.createdAt);
    const dateStringPrevComment = dateBeautifyFormatter(prevComment.createdAt);

    if (dateStringComment === dateStringPrevComment) {
      return true;
    }
    return false;
  };

  const handleCheckToday = (comment) => {
    const dateStringComment = dateBeautifyFormatter(comment.createdAt);

    if (dateStringComment === 'Hari Ini') {
      return true;
    }
    return false;
  };

  const handleClickShowSeenModal = useCallback((commentId) => {
    setSeenCommentId(commentId);
    handleShowSeenModal();
  }, []);

  let seenModuleType;

  switch (type) {
    case 'card':
      seenModuleType = SeenConstants.typeModule.cardComment;
      break;
    case 'cardDiscussion':
      seenModuleType = SeenConstants.typeModule.cardDiscussion;
      break;
    case 'question':
      seenModuleType = SeenConstants.typeModule.questionComment;
      break;
    case 'questionDiscussion':
      seenModuleType = SeenConstants.typeModule.questionDiscussion;
      break;
    case 'blast':
      seenModuleType = SeenConstants.typeModule.postComment;
      break;
    case 'blastDiscussion':
      seenModuleType = SeenConstants.typeModule.postDiscussion;
      break;
    case 'doc':
      seenModuleType = SeenConstants.typeModule.docComment;
      break;
    case 'docDiscussion':
      seenModuleType = SeenConstants.typeModule.docDiscussion;
      break;
    case 'file':
      seenModuleType = SeenConstants.typeModule.fileComment;
      break;
    case 'fileDiscussion':
      seenModuleType = SeenConstants.typeModule.fileDiscussion;
      break;
    case 'event':
      seenModuleType = SeenConstants.typeModule.eventComment;
      break;
    case 'eventDiscussion':
      seenModuleType = SeenConstants.typeModule.eventDiscussion;
      break;
    case 'occurrence':
      seenModuleType = SeenConstants.typeModule.occurrenceComment;
      break;
    case 'occurrenceDiscussion':
      seenModuleType = SeenConstants.typeModule.occurrenceDiscussion;
      break;
    default:
      break;
  }

  return (
    <>
      {comments !== undefined ? (
        <>
          {
        comments.map((comment, index) => {
          const isSameDate = handleCompareDate(comment, (index - 1));
          const isToday = handleCheckToday(comment);

          const isLastIndex = index === (comments.length - 1);
          const isCommentMatchQuery = searchCommentId === comment._id;

          let usedRef;

          if (isLastIndex) {
            usedRef = lastCommentRef;
          }

          if (isCommentMatchQuery) {
            usedRef = commentRef;
            goToComment();
          }
          return (
            <Element key={comment._id} name={comment?._id} id={comment?._id}>
              {!isSameDate && <DateSeparator date={comment.createdAt} />}
              <CommentItem
                commentIndex={index}
                parentIds={parentIds}
                comment={comment}
                type={type}
                isToday={isToday}
                hideReply={hideReply}
                disabledOutsideClickHandlerMenu={showAlertDeleteComment}
                handleClickDeleteComment={handleClickDeleteComment}
                handleApiDeleteComment={handleApiDeleteComment}
                handleClickShowSeenModal={handleClickShowSeenModal}
                ref={usedRef}
                socket={socket}
                parentTeamId={teamId}
              />
            </Element>
          );
        })

      }
        </>
      ) : null}

      <FeedbackDialogAlert
        open={showAlertDeleteComment}
        onClose={handleHideAlertDeleteComment}
        handleConfirmNo={handleHideAlertDeleteComment}
        handleConfirmYes={handleApiDeleteComment}
        waitYes="deleteComment"
        loadings={loadings}
        labelYes="Hapus"
        labelNo="Batal"
        variantButtonYes="danger"
        title="Hapus Komentar?"
        content="Komentar ini akan hilang selamanya. Tidak bisa dikembalikan."
      />
      <SeenModal
        open={showSeenModal}
        onClose={handleHideSeenModal}
        type={seenModuleType}
        itemId={seenCommentId}
        forceParentIds={parentIds}
        forceTeamId={teamId}
      />
    </>
  );
};

ShowComments.propTypes = {
  type: PropTypes.string.isRequired,
  parentIds: PropTypes.array.isRequired,
  comments: PropTypes.array.isRequired,
  hideReply: PropTypes.bool.isRequired,
  disableAutoScrollHook: PropTypes.bool,
  socket: PropTypes.object.isRequired,
  parentTeamId: PropTypes.string,
};

ShowComments.defaultProps = {
  disableAutoScrollHook: false,
  parentTeamId: undefined,
};

export default ShowComments;
