import React, { memo, useState } from 'react';
import PropTypes from 'prop-types';
import { useParams } from 'react-router-dom';
import { Box } from '@mui/material';
import GroupsOutlinedIcon from '@mui/icons-material/GroupsOutlined';
import MapsHomeWorkOutlinedIcon from '@mui/icons-material/MapsHomeWorkOutlined';
import AssignmentOutlinedIcon from '@mui/icons-material/AssignmentOutlined';
import Highlighter from 'react-highlight-words';
import { DisplayTextBody, DisplayTextSubHeadline } from '../../../../../../components/UI_V2/Display/Text';
import Color from '../../../../../../themes/colors';
import LinkNoDecor from '../../../../../../components/LinkNoDecor/LinkNoDecor';
import { limitChar } from '../../../../../../utilities/stringUtil';
import { getUrlItem } from '../../../../../../actions/SearchJumpToActions';
import { PageConstants } from '../../../../../../constants';
import styles from './ResultItem.module.css';
import Breadcrumb from '../../Breadcrumb/Breadcrumb';

const ResultItem = ({
  item, isSelected, onClick, keywordArray, limitTitleChar, params,
}) => {
  const { companyId } = params;
  const url = getUrlItem({ companyId, item });
  const isExceptionType = item?.type === 'hq' || item?.type === 'team' || item?.type === 'project' || item?.type === 'teams';

  return (
    <LinkNoDecor to={url}>
      <div
        onClick={onClick}
        aria-pressed={isSelected}
        className={styles.container}
      >
        <div
          className={styles.content}
        >
          <div
            className={styles.iconBox}
          >
            {isExceptionType && (item?.type === 'hq' || (item?.type === 'teams' && item.teamType === 'hq')) && (
              <MapsHomeWorkOutlinedIcon />
            )}
            {isExceptionType && (item?.type === 'team' || (item?.type === 'teams' && item.teamType === 'team')) && (
              <GroupsOutlinedIcon />
            )}
            {isExceptionType && (item?.type === 'project' || (item?.type === 'teams' && item.teamType === 'project')) && (
              <AssignmentOutlinedIcon />
            )}
            {!isExceptionType && PageConstants?.pageProperties[item?.type]?.icon}
          </div>
          <Box>
            <DisplayTextBody
              mode="14"
              color={Color.gray3}
            >
              <Highlighter
                highlightStyle={{
                  color: Color.yellowAccentCicle,
                  padding: '0px',
                  backgroundColor: 'transparent',
                }}
                searchWords={keywordArray}
                autoEscape
                textToHighlight={limitChar(item.name, limitTitleChar)}
              />
            </DisplayTextBody>
          </Box>
        </div>
        <div
          className={styles.breadcrumb}
        >
          <Breadcrumb routes={item?.routes} />
        </div>
      </div>
    </LinkNoDecor>
  );
};

ResultItem.propTypes = {
  item: PropTypes.object.isRequired,
  isSelected: PropTypes.bool.isRequired,
  onClick: PropTypes.func.isRequired,
  keywordArray: PropTypes.array,
  limitTitleChar: PropTypes.number,
  params: PropTypes.object,
};

ResultItem.defaultProps = {
  keywordArray: [],
  limitTitleChar: 80,
  params: { companyId: null, teamId: null },
};

export default memo(ResultItem);
