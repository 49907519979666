import React, { useContext } from 'react';
import { useHistory } from 'react-router-dom';
import _ from 'lodash';
import useMediaQuery from '@mui/material/useMediaQuery';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { GlobalContext } from '../../contexts/GlobalStateProvider';
import styles from './ResetThenCreateNewPasswordPage.module.css';
import LogoHeader from '../../components/UI/LogoHeader/LogoHeader';
import mobileViewImage from '../../assets/AuthPage/login-illustration-mobileview.png';
import webViewImage from '../../assets/AuthPage/login-illustration-webview.png';
import Title from '../../components/Title/Title';
import ResetCreateNewPasswordSection from './ResetCreateNewPasswordSection/ResetCreateNewPasswordSection';

function ResetThenCreateNewPasswordPage() {
  const [{ user }, dispatch] = useContext(GlobalContext);

  const history = useHistory();
  const isMobile = useMediaQuery('(max-width:1024px)');

  const titleDescText = {
    title: 'Mereset Password',
    desc: 'Silahkan buat password baru kamu dibawah ini',
  };

  return (
    <>
      <Title title="Cicle - Kelola tugas karyawan, laporan kinerja, dan komunikasi realtime dalam 1 aplikasi" />
      <LogoHeader />
      {user._id === undefined ? (
        <div className={styles.ResetThenCreateNewPasswordPage__container}>
          <div className={styles.ResetThenCreateNewPasswordPage__leftSection}>
            <LazyLoadImage
              alt=""
              className={styles.ResetThenCreateNewPasswordPage__leftSection__illustration}
              src={isMobile
                ? mobileViewImage
                : webViewImage}
            />
          </div>
          <div className={styles.ResetThenCreateNewPasswordPage__rightSection}>
            <div className={styles.ResetThenCreateNewPasswordPage__rightSection__text}>
              <div className={styles.ResetThenCreateNewPasswordPage__textSection}>
                <h1>{titleDescText.title}</h1>
                {titleDescText.subTitle
                && <p className={styles.subtitle}>{titleDescText.subTitle}</p>}
                <p>{titleDescText.desc}</p>
              </div>
            </div>
            <div className={styles.ResetThenCreateNewPasswordPage__rightSection__loginButton}>
              <ResetCreateNewPasswordSection />
            </div>
          </div>
        </div>
      ) : history.push('/')}
    </>
  );
}

export default ResetThenCreateNewPasswordPage;
