import React, { memo, useState } from 'react';
import PropTypes from 'prop-types';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ScrollContainer from 'react-indiana-drag-scroll';
import styles from './ListRoleBadgeSection.module.css';
import { DisplayTextBody, DisplayTextHeadline } from '../../../UI_V2/Display/Text';
import Color from '../../../../themes/colors';
import RoleBadgeExplanationItem from './RoleBadgeExplanationItem/RoleBadgeExplanationItem';
import { role, moduleTitleByRoleTypeRef } from '../../../../constants/RoleConstants';
import ColumnTitleListRoleBadge from './ColumnTitleListRoleBadge/ColumnTitleListRoleBadge';
import { limitChar } from '../../../../utilities/stringUtil';

const ListRoleBadgeSection = ({
  featureType,
  onBack,
  featureTitle,
  isRoleTypeCompany,
}) => (
  <div className={styles.container}>
    <div
      onClick={onBack}
      className={styles.backButton}
    >
      <ArrowBackIcon />
    </div>
    <div className={styles.title}>
      <DisplayTextHeadline
        color={Color.blueNavyCicle}
        decoration="bold"
      >
        Apa sih Akses itu?
      </DisplayTextHeadline>
    </div>
    <div className={styles.description}>
      <DisplayTextBody>
        Setiap pengguna di Cicle memiliki Akses sebagai acuan apa yang boleh dan ga boleh dilakukan
        di tiap-tiap menu di Cicle.
        Di tingkat Perusahaan, setiap pengguna diberi Akses default. Saat pengguna menelusuri Tim
        hingga Tugas/Dokumen, Akses mereka akan selalu mengacu ke Akses default yang ditetapkan
        di tingkat Perusahaan, sampai ketika yang berwenang mengubah aksesnya di menu tertentu.
      </DisplayTextBody>
    </div>
    <div className={styles.description}>
      <div className={styles.descriptionItem}>
        <DisplayTextBody>
          Acuan izin ini hanya spesifik untuk :
        </DisplayTextBody>
      </div>
      <div className={styles.descriptionItem}>
        <DisplayTextBody
          decoration="bold"
        >
          {`${moduleTitleByRoleTypeRef[featureType]}: ${limitChar(featureTitle, 30)}`}
        </DisplayTextBody>
      </div>
    </div>
    <ScrollContainer className={styles.scrollListItems}>
      <div className={styles.columnTitle}>
        <ColumnTitleListRoleBadge />
      </div>
      <div className={styles.listRole}>
        <div className={styles.item}>
          <RoleBadgeExplanationItem
            featureType={featureType}
            roleUser={{ role: role.super }}
          />
        </div>
        <div className={styles.item}>
          <RoleBadgeExplanationItem
            featureType={featureType}
            roleUser={{ role: role.admin }}
          />
        </div>
        {!isRoleTypeCompany && (
        <div className={styles.item}>
          <RoleBadgeExplanationItem
            featureType={featureType}
            roleUser={{ role: role.creator }}
          />
        </div>
        )}
        <div className={styles.item}>
          <RoleBadgeExplanationItem
            featureType={featureType}
            roleUser={{ role: role.manager }}
          />
        </div>
        <div className={styles.item}>
          <RoleBadgeExplanationItem
            featureType={featureType}
            roleUser={{ role: role.spv }}
          />
        </div>
        <div className={styles.item}>
          <RoleBadgeExplanationItem
            featureType={featureType}
            roleUser={{ role: role.member }}
          />
        </div>
      </div>
    </ScrollContainer>
  </div>
);

ListRoleBadgeSection.propTypes = {
  featureType: PropTypes.string.isRequired,
  featureTitle: PropTypes.string.isRequired,
  onBack: PropTypes.func.isRequired,
  isRoleTypeCompany: PropTypes.bool.isRequired,
};

ListRoleBadgeSection.defaultProps = {};

export default memo(ListRoleBadgeSection);
