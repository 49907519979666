import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { Box, Tooltip } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import FilterAltOutlinedIcon from '@mui/icons-material/FilterAltOutlined';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import styles from './HeaderArchivedItemsSection.module.css';
import { DisplayTextBody, DisplayTextSubHeadline } from '../../../UI_V2/Display/Text';
import Color from '../../../../themes/colors';
import { InputButtonMain } from '../../../UI_V2/Input/Button';
import { getSingleOrPlural } from '../../../../utilities/stringUtil';
import GlobalActionButton from '../../../GeneralSubNavBar/BottomNavBar/GlobalActionButton/GlobalActionButton';

const HeaderArchivedItems = ({
  title,
  onClose,
  disableCloseIcon,
  handleClickShowFilters,
  isFilterActive,
}) => (
  <>
    <Box className={styles.container}>
      <Box className={styles.contentSection}>
        <Box className={styles.title}>
          <DisplayTextSubHeadline
            mode="18"
            decoration="bold"
          >
            {title}
          </DisplayTextSubHeadline>
        </Box>
        <Box className={styles.closeAndFilterCount}>
          <Box>
            <GlobalActionButton
              handleClick={handleClickShowFilters}
              buttonMode="outlined"
              text="Filter"
              icon={<FilterAltOutlinedIcon />}
            >
              {isFilterActive ? (
                <Tooltip
                  title="Filter-mu aktif. Atur atau hapus filter kalo kamu ga menemukan yang kamu cari."
                >
                  <div className={styles.activeFilterIcon}><CheckCircleIcon /></div>
                </Tooltip>
              ) : null}
            </GlobalActionButton>
          </Box>
          {!disableCloseIcon && (
          <Box onClick={onClose} className={styles.closeIcon}>
            <CloseIcon />
          </Box>
          )}
        </Box>
      </Box>
    </Box>
  </>
);

HeaderArchivedItems.propTypes = {
  title: PropTypes.string,
  onClose: PropTypes.func,
  disableCloseIcon: PropTypes.bool,
  handleClickShowFilters: PropTypes.func.isRequired,
  isFilterActive: PropTypes.bool.isRequired,
};

HeaderArchivedItems.defaultProps = {
  title: 'Archived Items',
  onClose: () => null,
  disableCloseIcon: false,
};

export default memo(HeaderArchivedItems);
