import React, {
  useState, useEffect, memo,
} from 'react';
import { useParams } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import PropTypes from 'prop-types';
import { isEqual } from 'lodash';
import handleStatusMsg from '../../../utilities/handleStatusMsg';
import ButtonDiv from '../../../components/UI/Button/ButtonDiv/ButtonDiv';
import './ArchiveButtonContainer.module.css';
import apiUtil from '../../../utilities/apiUtil';
import { BoardActions, CardActions } from '../../../actions';

const ArchiveButtonContainer = ({
  card, children, handleLoading, loadings,
}) => {
  const [archivedCard, setArchivedCard] = useState();
  const { enqueueSnackbar } = useSnackbar();

  const params = useParams();
  const { companyId } = params;
  const teamId = params.teamId || card.team?._id || card.team;
  useEffect(() => {
    if (archivedCard === undefined) return;

    const archivedApiCard = async () => {
      const cardId = params.cardId || card._id;

      try {
        handleLoading(true);
        const result = await CardActions.archiveCard({ cardId, teamId, companyId });

        const status = handleStatusMsg(result, 'success');

        enqueueSnackbar(status.message, {
          variant: 'success',
        });
      } catch (err) {
        const status = handleStatusMsg(err, 'error');

        enqueueSnackbar(status.message, {
          variant: 'error',
        });
      } finally {
        handleLoading(false);
      }
    };
    archivedApiCard();
  }, [archivedCard]);

  const handleArchived = (cardId) => {
    setArchivedCard(cardId);
  };

  return (
    <ButtonDiv
      onClick={() => handleArchived(card._id)}
      // className='container'
    >
      {children}
    </ButtonDiv>
  );
};

ArchiveButtonContainer.propTypes = {
  card: PropTypes.object.isRequired,
  children: PropTypes.node.isRequired,
  handleLoading: PropTypes.func.isRequired,
  loadings: PropTypes.array.isRequired,
};

const isComponentDataEqual = (prevProps, nextProps) => {
  const { card, loadings } = nextProps;
  return isEqual(prevProps.card, card)
    && isEqual(prevProps.loadings, loadings);
};

export default memo(ArchiveButtonContainer, isComponentDataEqual);
