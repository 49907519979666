import { cloneDeep } from 'lodash';
import { ApiConstants, MyTaskCardConstants } from '../constants';
import { limitMyTaskCardList } from '../constants/MyTaskCardConstants';
import apiUtil from '../utilities/apiUtil';
import { ErrorException } from '../utilities/handleError';
import { getLastListDate, mergeObjectListAndRemoveDuplicate, updateListProperty } from '../utilities/arrayUtil';
import { actionTypes } from '../reducers/reducer';
import { initialState } from '../contexts/GlobalStateProvider';

/*
  Dispatcher
*/

function dispatchPreviousMyTaskCardListAll({ previousMyTaskCardListAll }, dispatch) {
  dispatch({
    type: actionTypes.SET_PREVIOUS_MY_TASK_CARD_LIST_ALL,
    previousMyTaskCardListAll,
  });
}

function dispatchCurrentMyTaskCardListAll({ currentMyTaskCardListAll }, dispatch) {
  dispatch({
    type: actionTypes.SET_CURRENT_MY_TASK_CARD_LIST_ALL,
    currentMyTaskCardListAll,
  });
}

function dispatchUpdatCurrentMyTaskCardListAll(
  { updateCurrentMyTaskCardListAll }, dispatch,
) {
  dispatch({
    type: actionTypes.UPDATE_CURRENT_MY_TASK_CARD_LIST_ALL,
    updateCurrentMyTaskCardListAll,
  });
}

function dispatchPreviousMyTaskCardCalendarAll({ previousMyTaskCardCalendarAll }, dispatch) {
  dispatch({
    type: actionTypes.SET_PREVIOUS_MY_TASK_CARD_CALENDAR_ALL,
    previousMyTaskCardCalendarAll,
  });
}

function dispatchCurrentMyTaskCardCalendarAll({ currentMyTaskCardCalendarAll }, dispatch) {
  dispatch({
    type: actionTypes.SET_CURRENT_MY_TASK_CARD_CALENDAR_ALL,
    currentMyTaskCardCalendarAll,
  });
}

function dispatchUpdatCurrentMyTaskCardCalendarAll(
  { updateCurrentMyTaskCardCalendarAll }, dispatch,
) {
  dispatch({
    type: actionTypes.UPDATE_CURRENT_MY_TASK_CARD_CALENDAR_ALL,
    updateCurrentMyTaskCardCalendarAll,
  });
}

/*
  SetterDispatcher
*/

function setPreviousMyTaskCardListAll({ previousMyTaskCardListAll }, dispatch) {
  if (!previousMyTaskCardListAll) return;

  dispatchPreviousMyTaskCardListAll(
    { previousMyTaskCardListAll: cloneDeep(previousMyTaskCardListAll) }, dispatch,
  );
}

function setCurrentMyTaskCardListAll({ currentMyTaskCardListAll }, dispatch) {
  if (!currentMyTaskCardListAll) return;
  dispatchCurrentMyTaskCardListAll(
    { currentMyTaskCardListAll: cloneDeep(currentMyTaskCardListAll) }, dispatch,
  );
}

function setPreviousMyTaskCardCalendarAll({ previousMyTaskCardCalendarAll }, dispatch) {
  if (!previousMyTaskCardCalendarAll) return;

  dispatchPreviousMyTaskCardCalendarAll(
    { previousMyTaskCardCalendarAll: cloneDeep(previousMyTaskCardCalendarAll) }, dispatch,
  );
}

function setCurrentMyTaskCardCalendarAll({ currentMyTaskCardCalendarAll }, dispatch) {
  if (!currentMyTaskCardCalendarAll) return;
  dispatchCurrentMyTaskCardCalendarAll(
    { currentMyTaskCardCalendarAll: cloneDeep(currentMyTaskCardCalendarAll) }, dispatch,
  );
}

/* Method */

const initiateMyTaskCardListAll = async ({
  companyId,
  filters,
  limit = limitMyTaskCardList,
}, dispatch) => {
  try {
    let params = {
      companyId,
      limit,
    };

    if (filters) {
      if (filters.filterTitle) {
        params = {
          ...params,
          'filter[name]': filters.filterTitle,
        };
      }

      if (filters.filterTeams) {
        params = {
          ...params,
          'filter[teams]': filters.filterTeams,
        };
      }

      if (filters.filterLabels) {
        params = {
          ...params,
          'filter[labels]': filters.filterLabels,
        };
      }

      if (filters.filterSubscribers) {
        params = {
          ...params,
          'filter[members]': filters.filterSubscribers,
        };
      }

      if (filters.filterDueDate) {
        params = {
          ...params,
          'filter[dueDate]': filters.filterDueDate,
        };
      }
    }

    const result = await apiUtil.get(ApiConstants.URL_V1.MY_TASK_CARD_LIST_ALL(), {
      params,
    },
    {});

    setCurrentMyTaskCardListAll({ currentMyTaskCardListAll: result?.data }, dispatch);
    setPreviousMyTaskCardListAll({
      previousMyTaskCardListAll:
      initialState.previousMyTaskCardListAll,
    }, dispatch);

    return result;
  } catch (error) {
    throw new ErrorException(error);
  }
};

const loadMoreMyTaskCardListAll = async ({
  companyId,
  filters,
  currentMyTaskCardListAll,
  limit = limitMyTaskCardList,
}, dispatch) => {
  try {
    const dueDateAt = getLastListDate(currentMyTaskCardListAll.data, 'dueDate');

    let params = {
      companyId,
      limit,
    };

    if (dueDateAt && filters?.filterDueDate !== 'completed') {
      params = {
        ...params,
        dueDateAt,
      };
    } else if (filters?.filterDueDate === 'completed') {
      const completedAt = getLastListDate(currentMyTaskCardListAll.data, 'setAt', 'complete');
      params = {
        ...params,
        completedAt,
      };
    } else {
      const createdAt = getLastListDate(currentMyTaskCardListAll.data, 'createdAt');
      if (createdAt) {
        params = {
          ...params,
          createdAt,
        };
      }
    }

    if (filters) {
      if (filters.filterTitle) {
        params = {
          ...params,
          'filter[name]': filters.filterTitle,
        };
      }

      if (filters.filterTeams) {
        params = {
          ...params,
          'filter[teams]': filters.filterTeams,
        };
      }

      if (filters.filterLabels) {
        params = {
          ...params,
          'filter[labels]': filters.filterLabels,
        };
      }

      if (filters.filterSubscribers) {
        params = {
          ...params,
          'filter[members]': filters.filterSubscribers,
        };
      }

      if (filters.filterDueDate) {
        params = {
          ...params,
          'filter[dueDate]': filters.filterDueDate,
        };
      }
    }

    const result = await apiUtil.get(ApiConstants.URL_V1.MY_TASK_CARD_LIST_ALL(), {
      params,
    },
    {});

    const mergedDataListData = mergeObjectListAndRemoveDuplicate({
      currentObjectList: currentMyTaskCardListAll,
      nextObjectList: result?.data,
      keyObject: 'data',
    });

    const mergedListData = {
      ...result?.data,
      data: mergedDataListData?.data,
    };

    setPreviousMyTaskCardListAll({
      previousMyTaskCardListAll:
      result?.data,
    }, dispatch);
    setCurrentMyTaskCardListAll({
      currentMyTaskCardListAll:
      mergedListData,
    }, dispatch);

    return result;
  } catch (error) {
    throw new ErrorException(error);
  }
};

const initiateMyTaskCardCalendarAll = async ({
  companyId,
  filters,
  fromDate,
  untilDate,
}, dispatch) => {
  try {
    let params = {
      companyId,
      fromDate,
      untilDate,
    };

    if (filters) {
      if (filters.filterTitle) {
        params = {
          ...params,
          'filter[name]': filters.filterTitle,
        };
      }

      if (filters.filterTeams) {
        params = {
          ...params,
          'filter[teams]': filters.filterTeams,
        };
      }

      if (filters.filterLabels) {
        params = {
          ...params,
          'filter[labels]': filters.filterLabels,
        };
      }

      if (filters.filterSubscribers) {
        params = {
          ...params,
          'filter[members]': filters.filterSubscribers,
        };
      }

      if (filters.filterDueDate) {
        params = {
          ...params,
          'filter[dueDate]': filters.filterDueDate,
        };
      }
    }

    const result = await apiUtil.get(ApiConstants.URL_V1.MY_TASK_CARD_CALENDAR_ALL(), {
      params,
    },
    {});

    setCurrentMyTaskCardCalendarAll({ currentMyTaskCardCalendarAll: result?.data }, dispatch);
    setPreviousMyTaskCardCalendarAll({
      previousMyTaskCardCalendarAll:
      initialState.previousMyTaskCardCalendarAll,
    }, dispatch);

    return result;
  } catch (error) {
    throw new ErrorException(error);
  }
};

const loadMoreMyTaskCardCalendarAll = async ({
  companyId,
  filters,
  currentMyTaskCardCalendarAll,
  fromDate,
  untilDate,
}, dispatch) => {
  try {
    const dueDateAt = getLastListDate(currentMyTaskCardCalendarAll.data, 'dueDate');

    let params = {
      companyId,
      fromDate,
      untilDate,
    };

    if (dueDateAt && filters?.filterDueDate !== 'completed') {
      params = {
        ...params,
        dueDateAt,
      };
    } else if (filters?.filterDueDate === 'completed') {
      const completedAt = getLastListDate(currentMyTaskCardCalendarAll.data, 'setAt', 'complete');
      params = {
        ...params,
        completedAt,
      };
    } else {
      const createdAt = getLastListDate(currentMyTaskCardCalendarAll.data, 'createdAt');
      if (createdAt) {
        params = {
          ...params,
          createdAt,
        };
      }
    }

    if (filters) {
      if (filters.filterTitle) {
        params = {
          ...params,
          'filter[name]': filters.filterTitle,
        };
      }

      if (filters.filterTeams) {
        params = {
          ...params,
          'filter[teams]': filters.filterTeams,
        };
      }

      if (filters.filterLabels) {
        params = {
          ...params,
          'filter[labels]': filters.filterLabels,
        };
      }

      if (filters.filterMembers) {
        params = {
          ...params,
          'filter[members]': filters.filterMembers,
        };
      }

      if (filters.filterDueDate) {
        params = {
          ...params,
          'filter[dueDate]': filters.filterDueDate,
        };
      }
    }

    const result = await apiUtil.get(ApiConstants.URL_V1.MY_TASK_CARD_CALENDAR_ALL(), {
      params,
    },
    {});

    const mergedDataListData = mergeObjectListAndRemoveDuplicate({
      currentObjectList: currentMyTaskCardCalendarAll,
      nextObjectList: result?.data,
      keyObject: 'data',
    });

    const mergedListData = {
      ...result?.data,
      data: mergedDataListData?.data,
    };

    setPreviousMyTaskCardCalendarAll({
      previousMyTaskCardCalendarAll:
      result?.data,
    }, dispatch);
    setCurrentMyTaskCardCalendarAll({
      currentMyTaskCardCalendarAll:
      mergedListData,
    }, dispatch);

    return result;
  } catch (error) {
    throw new ErrorException(error);
  }
};

/* Socket */

const incomingCardUpdateOnMyTaskCardListAll = ({
  currentCard,
  typeAction = MyTaskCardConstants.typeCallback.EDIT,
}, dispatch) => {
  if (!currentCard) return;
  const updateCurrentMyTaskCardListAll = (currentMyTaskCardListAll) => {
    const newCurrentMyTaskCardListAll = updateListProperty({
      keyProperty: 'data',
      newData: currentCard,
      currentList: currentMyTaskCardListAll,
      typeAction,
      reverse: true,
    });

    return cloneDeep(newCurrentMyTaskCardListAll);
  };

  dispatchUpdatCurrentMyTaskCardListAll({ updateCurrentMyTaskCardListAll }, dispatch);
};

const incomingCardUpdateOnMyTaskCardCalendarAll = ({
  currentCard,
  typeAction = MyTaskCardConstants.typeCallback.EDIT,
}, dispatch) => {
  if (!currentCard) return;
  const updateCurrentMyTaskCardCalendarAll = (currentMyTaskCardCalendarAll) => {
    const newCurrentMyTaskCardCalendarAll = updateListProperty({
      keyProperty: 'data',
      newData: currentCard,
      currentList: currentMyTaskCardCalendarAll,
      typeAction,
      reverse: true,
    });

    return cloneDeep(newCurrentMyTaskCardCalendarAll);
  };

  dispatchUpdatCurrentMyTaskCardCalendarAll({ updateCurrentMyTaskCardCalendarAll }, dispatch);
};

export {
  initiateMyTaskCardListAll,
  loadMoreMyTaskCardListAll,
  setPreviousMyTaskCardListAll,
  setCurrentMyTaskCardListAll,
  incomingCardUpdateOnMyTaskCardListAll,
  initiateMyTaskCardCalendarAll,
  loadMoreMyTaskCardCalendarAll,
  setPreviousMyTaskCardCalendarAll,
  setCurrentMyTaskCardCalendarAll,
  incomingCardUpdateOnMyTaskCardCalendarAll,
};
