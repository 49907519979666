import React, {
  memo, useContext, useEffect, useState,
} from 'react';
import PropTypes from 'prop-types';
import DownloadIcon from '@mui/icons-material/Download';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { enqueueSnackbar } from 'notistack';
import { useParams } from 'react-router-dom';
import styles from './SelectList.module.css';
import Color from '../../../../themes/colors';
import { InputButtonDropdown, InputButtonMain } from '../../../UI_V2/Input/Button';
import { NavigationMenuBasic } from '../../../UI_V2/Navigation/Menu';
import SingleTeamFilterSection from '../../FilterBar/SingleTeamFilterSection/SingleTeamFilterSection';
import { useDelayShowHideHandler, useSearch } from '../../../../hooks/HelperHooks';
import { GlobalContext } from '../../../../contexts/GlobalStateProvider';
import SingleListFilterSection from '../../FilterBar/SingleListFilterSection/SingleListFilterSection';
import { BoardActions } from '../../../../actions';
import handleStatusMsg from '../../../../utilities/handleStatusMsg';

const SelectList = ({
  handleSelectListId,
  handleSelectBoardId,
}) => {
  const [{
    teams,
    currentBoard,
    currentBoardLists, currentTeam,
  }, dispatch] = useContext(GlobalContext);
  const singleListFilterSectionSearchHook = useSearch({ originalList: currentBoardLists });
  const [isLoadingLists, setIsLoadingLists] = useState(false);

  const firstListName = currentBoardLists.length > 0 ? currentBoardLists[0].name : 'Belum ada list';

  const [selectedListName, setSelectedListName] = useState(firstListName);

  const params = useParams();
  const { companyId } = params;

  const [
    showSingleListSectionMenu,
    handleShowSingleListSectionMenu,
    handleHideSingleListSectionMenu,
  ] = useDelayShowHideHandler();

  const [checkSingleList, setCheckSingleList] = useState();

  const setListNameFromListId = (listId, lists) => {
    const list = lists.find((elem) => elem._id === listId);

    setSelectedListName(list?.name ? list.name : 'Belum ada list');
  };

  const getBoardIdFromTeams = (teamId, listTeams) => {
    const team = listTeams.find((elem) => elem._id === teamId);
    const boardId = team?.boards?.[0];
    return boardId;
  };

  const handleClickSingleList = (valueListId) => {
    if (valueListId === checkSingleList) {
      handleHideSingleListSectionMenu();
      return;
    }
    setCheckSingleList(valueListId);
    handleSelectListId(valueListId);
    handleSelectBoardId(getBoardIdFromTeams(currentTeam._id, teams));
    setListNameFromListId(valueListId, currentBoardLists);
    handleHideSingleListSectionMenu();
  };
  const handleResetSingleList = () => {
    setCheckSingleList(undefined);
  };

  const initiateLists = async () => {
    try {
      const boardId = getBoardIdFromTeams(currentTeam._id, teams);
      if (!boardId) return;
      await BoardActions.initiateBoardV2(
        {
          boardId,
          companyId,
          teamId: currentTeam._id,
          currentBoard,
          currentBoardLists,
        },
        dispatch,
      );
    } catch (error) {
      const status = handleStatusMsg(error, 'error');

      enqueueSnackbar(status.message, {
        variant: 'error',
      });
    } finally {
      setIsLoadingLists(false);
    }
  };

  useEffect(() => {
    if (!currentTeam?.name) return;
    setIsLoadingLists(true);
    initiateLists();
  }, [currentTeam]);

  useEffect(() => {
    if (!currentTeam?.name) return;
    const listId = currentBoardLists.length > 0 ? currentBoardLists[0]._id : undefined;
    handleClickSingleList(listId, currentBoardLists);
  }, [currentBoardLists]);

  return (
    <div className={styles.container}>
      <InputButtonDropdown
        onClick={handleShowSingleListSectionMenu}
        isLoading={isLoadingLists}
        initialDataString={selectedListName}
        text={selectedListName}
      />
      {showSingleListSectionMenu && (
        <NavigationMenuBasic
          size=""
          position="centerRight"
          onClose={handleHideSingleListSectionMenu}
        >
          <div className={styles.selectList}>
            <SingleListFilterSection
              checkSingleList={checkSingleList}
              handleClickSingleList={handleClickSingleList}
              searchHook={singleListFilterSectionSearchHook}
            />
          </div>

        </NavigationMenuBasic>
      )}
    </div>
  );
};

SelectList.propTypes = {
  handleSelectBoardId: PropTypes.func.isRequired,
  handleSelectListId: PropTypes.func.isRequired,
};

SelectList.defaultProps = {};

export default memo(SelectList);
