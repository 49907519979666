import React, { memo, useState } from 'react';
import PropTypes from 'prop-types';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { Skeleton } from '@mui/material';
import styles from './RecapDataSkeleton.module.css';
import Color from '../../../../themes/colors';

const RecapDataSkeleton = () => (
  <>
    <div className={styles.skeleton}>
      <Skeleton
        animation="wave"
        variant="rounded"
        height={160}
      />
    </div>
    <div className={styles.skeleton}>
      <Skeleton
        animation="wave"
        variant="rounded"
        height={100}
      />
    </div>
  </>
);

RecapDataSkeleton.propTypes = {};

RecapDataSkeleton.defaultProps = {};

export default memo(RecapDataSkeleton);
