/* eslint-disable no-shadow */
/* eslint-disable consistent-return */
import { debounce, cloneDeep } from 'lodash';
import { useCallback, useContext, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import { BoardActions, CardActions, ListActions } from '../actions';
import { BoardConstants } from '../constants';
import { connectSocketBoard, connectSocketBoardPopulatedCard } from '../services/socket';
import { mergeList } from '../utilities/arrayUtil';
import { useSocketHooks } from './SocketHooks';
import { ErrorException } from '../utilities/handleError';
import { GlobalContext } from '../contexts/GlobalStateProvider';
import { getListIdByCardId } from '../actions/ListActions';
import handleStatusMsg from '../utilities/handleStatusMsg';
import { getStoreBoardCardView, setStoreBoardCardView } from '../utilities/localStorage';
import { getSessionBoardCardView, setSessionBoardCardView } from '../utilities/sessionStorage';

function useBoardV2Socket({ boardId, userId }, dispatch) {
  // Queue function
  // const QueueFn = (function () {
  //   function Queue() {}

  //   Queue.prototype.running = false;

  //   Queue.prototype.queue = [];

  //   Queue.prototype.add_function = function (callback) {
  //     const _this = this;
  //     // add callback to the queue
  //     this.queue.push(() => {
  //       const finished = callback();
  //       if (typeof finished === 'undefined' || finished) {
  //       //  if callback returns `false`, then you have to
  //       //  call `next` somewhere in the callback
  //         _this.next();
  //       }
  //     });

  //     if (!this.running) {
  //     // if nothing is running, then start the engines!
  //       this.next();
  //     }

  //     return this; // for chaining fun!
  //   };

  //   Queue.prototype.next = function () {
  //     this.running = false;
  //     // get the first element off the queue
  //     const shift = this.queue.shift();
  //     if (shift) {
  //       this.running = true;
  //       shift();
  //     }
  //   };

  //   return Queue;
  // }());

  // const queue = new QueueFn();

  /*
    Callback board
  */

  // const callbackBoardUpdate = useCallback(({ currentBoard, userId }) => {
  //   // queue.add_function(() => {
  //   //   BoardActions.incomingBoardUpdate({ currentBoard }, dispatch);
  //   // });
  //   BoardActions.incomingBoardUpdate({ currentBoard }, dispatch);
  // }, []);

  /*
    Callback list
  */

  const callbackNewList = useCallback((currentList) => {
    BoardActions.incomingListUpdate({
      currentList,
      typeAction: BoardConstants.typeCallback.NEW,
    }, dispatch);
  }, []);

  const callbackUnarchiveList = useCallback(({ currentList, userId }) => {
    BoardActions.incomingListUpdate({
      currentList,
      typeAction: BoardConstants.typeCallback.NEW,
    }, dispatch);
  }, []);

  const callbackUpdateList = useCallback(({
    currentList, listSource, listDestination, userId,
  }) => {
    if (listDestination && listSource) {
      BoardActions.incomingListUpdate({
        lists: mergeList({
          currentList: [listDestination],
          nextList: [listSource],
        }),
        withActionEdit: true,
        typeAction: BoardConstants.typeCallback.EDIT,
        replaceCard: true,
      }, dispatch);
    } else {
      BoardActions.incomingListUpdate({
        currentList,
        typeAction: BoardConstants.typeCallback.EDIT,
      }, dispatch);
    }
  }, []);
  const callbackArchiveList = useCallback(({ archivedList, userId }) => {
    const currentList = cloneDeep(archivedList);
    BoardActions.incomingListUpdate({
      currentList,
      typeAction: BoardConstants.typeCallback.DELETE,
    }, dispatch);
  }, []);

  const callbackListMove = useCallback(({ data, selector }) => {
    BoardActions.incomingItemMove({
      data,
      selector,
      type: BoardConstants.typeMove.LIST,
    }, dispatch);
  }, []);

  /*
    Callback label
  */

  const callbackNewLabel = useCallback(({ currentLabel, userId }) => {
    BoardActions.incomingLabelsUpdate({
      currentLabel,
      typeAction: BoardConstants.typeCallback.NEW,
    }, dispatch);
  }, []);
  const callbackUpdateLabel = useCallback(({ currentLabel, userId }) => {
    BoardActions.incomingLabelsUpdate({
      currentLabel,
      typeAction: BoardConstants.typeCallback.EDIT,
    }, dispatch);
  }, []);
  const callbackDeleteLabel = useCallback(({ currentLabel, userId }) => {
    BoardActions.incomingLabelsUpdate({
      currentLabel,
      typeAction: BoardConstants.typeCallback.DELETE,
    }, dispatch);
  }, []);

  /*
    Callback card
  */

  const callbackNewCard = useCallback((payload) => {
    const { card, listId } = payload;
    BoardActions.incomingBoardCardsUpdate({
      card,
      listId,
      typeAction: BoardConstants.typeCallback.NEW,
    }, dispatch);
  }, []);
  const callbackUpdateCard = useCallback(({ card, currentCard, listId }) => {
    // bug from BE not sending same object name
    // sometimes sending card, sometimes sending currentCard
    BoardActions.incomingBoardCardsUpdate({
      card: card || currentCard,
      listId,
      typeAction: BoardConstants.typeCallback.EDIT,
    }, dispatch);
  }, []);
  const callbackArchiveCard = useCallback(({ card, listId }) => {
    BoardActions.incomingBoardCardsUpdate({
      card,
      listId,
      typeAction: BoardConstants.typeCallback.DELETE,
    }, dispatch);
  }, []);

  const callbackCardMove = useCallback(({ data, selector }) => {
    BoardActions.incomingItemMove({
      data,
      selector,
      type: BoardConstants.typeMove.CARD,
    }, dispatch);
  }, []);

  /*
    Callback cards
  */

  const callbackUpdateCards = useCallback(({ currentList, userId }) => {
    BoardActions.incomingListUpdate({
      currentList,
      typeAction: BoardConstants.typeCallback.EDIT,
      replaceCard: true,
    }, dispatch);
  }, []);

  const listenSocket = (socket, params) => {
    socket
      .on('listNew', callbackNewList)
      .on('listArchive', callbackArchiveList)
      .on('listUnarchive', callbackUnarchiveList)
      .on('listUpdate', callbackUpdateList)
      .on('listMove', callbackListMove)
      // .on('boardUpdate', callbackBoardUpdate) // deprecated
      .on('labelNew', callbackNewLabel)
      .on('labelUpdate', callbackUpdateLabel)
      .on('labelDelete', callbackDeleteLabel)
      .on('cardArchive', callbackArchiveCard)
      .on('cardsArchive', callbackUpdateCards)
      .on('cardUnarchive', callbackNewCard)
      .on('cardNew', callbackNewCard)
      .on('cardUpdate', callbackUpdateCard)
      .on('cardMove', callbackCardMove);
  };
  const removeListener = (socket, params) => {
    socket
      .off('listNew')
      .off('listArchive')
      .off('listUnarchive')
      .off('listUpdate')
      .off('listMove')
      // .off('boardUpdate') // deprecated
      .off('labelNew')
      .off('labelUpdate')
      .off('labelDelete')
      .off('cardArchive')
      .off('cardsArchive')
      .off('cardUnarchive')
      .off('cardNew')
      .off('cardUpdate')
      .off('cardMove');
    socket.disconnect();
  };

  const { socket } = useSocketHooks({
    params: { boardId, userId },
    connectSocket: connectSocketBoard,
    listenSocket,
    removeListener,
  });

  return { socket, removeListener };
}

function useBoardPopulatedCardSocket({
  boardId, userId, view,
}, dispatch) {
  const callbackBoardCardUpdate = useCallback(({ currentCard }) => {
    switch (view) {
      case 'listAll':
        BoardActions.incomingCardUpdateOnBoardCardListAll({
          currentCard,
          typeAction: BoardConstants.typeCallback.EDIT,
        }, dispatch);
        break;

      case 'calendarAll':
        BoardActions.incomingCardUpdateOnBoardCardCalendarAll({
          currentCard,
          typeAction: BoardConstants.typeCallback.EDIT,
        }, dispatch);
        break;

      default:
        break;
    }
  }, []);

  const callbackBoardCardNew = useCallback(({ currentCard }) => {
    switch (view) {
      case 'listAll':
        BoardActions.incomingCardUpdateOnBoardCardListAll({
          currentCard,
          typeAction: BoardConstants.typeCallback.NEW,
        }, dispatch);
        break;

      case 'calendarAll':
        BoardActions.incomingCardUpdateOnBoardCardCalendarAll({
          currentCard,
          typeAction: BoardConstants.typeCallback.NEW,
        }, dispatch);
        break;

      default:
        break;
    }
  }, []);

  const callbackBoardCardArchive = useCallback(({ currentCard }) => {
    switch (view) {
      case 'listAll':
        BoardActions.incomingCardUpdateOnBoardCardListAll({
          currentCard,
          typeAction: BoardConstants.typeCallback.DELETE,
        }, dispatch);
        break;

      case 'calendarAll':
        BoardActions.incomingCardUpdateOnBoardCardCalendarAll({
          currentCard,
          typeAction: BoardConstants.typeCallback.DELETE,
        }, dispatch);
        break;

      default:
        break;
    }
  }, []);

  const callbackBoardCardUnarchive = useCallback(({ currentCard }) => {
    switch (view) {
      case 'listAll':
        BoardActions.incomingCardUpdateOnBoardCardListAll({
          currentCard,
          typeAction: BoardConstants.typeCallback.NEW,
        }, dispatch);
        break;

      case 'calendarAll':
        BoardActions.incomingCardUpdateOnBoardCardCalendarAll({
          currentCard,
          typeAction: BoardConstants.typeCallback.NEW,
        }, dispatch);
        break;

      default:
        break;
    }
  }, []);

  const listenSocket = (socket, params) => {
    socket
      .on('cardUpdate', callbackBoardCardUpdate)
      .on('cardNew', callbackBoardCardNew)
      .on('cardArchive', callbackBoardCardArchive)
      .on('cardUnarchive', callbackBoardCardUnarchive);
  };
  const removeListener = (socket, params) => {
    socket
      .off('cardUpdate')
      .off('cardNew')
      .off('cardArchive')
      .off('cardUnarchive');
    socket.disconnect();
  };

  useSocketHooks({
    params: { boardId, userId },
    connectSocket: connectSocketBoardPopulatedCard,
    listenSocket,
    removeListener,
  });
}

function useBoardV2SocketForListUpdateOnly({ boardId, userId }, dispatch) {
  /*
    Callback list
  */

  const callbackNewList = useCallback((currentList) => {
    BoardActions.incomingListUpdate({
      currentList,
      typeAction: BoardConstants.typeCallback.NEW,
    }, dispatch);
  }, []);

  const callbackUnarchiveList = useCallback(({ currentList, userId }) => {
    BoardActions.incomingListUpdate({
      currentList,
      typeAction: BoardConstants.typeCallback.NEW,
    }, dispatch);
  }, []);

  const callbackUpdateList = useCallback(({
    currentList, listSource, listDestination, userId,
  }) => {
    if (listDestination && listSource) {
      BoardActions.incomingListUpdate({
        lists: mergeList({
          currentList: [listDestination],
          nextList: [listSource],
        }),
        withActionEdit: true,
        typeAction: BoardConstants.typeCallback.EDIT,
        replaceCard: true,
      }, dispatch);
    } else {
      BoardActions.incomingListUpdate({
        currentList,
        typeAction: BoardConstants.typeCallback.EDIT,
      }, dispatch);
    }
  }, []);
  const callbackArchiveList = useCallback(({ archivedList, userId }) => {
    const currentList = cloneDeep(archivedList);
    BoardActions.incomingListUpdate({
      currentList,
      typeAction: BoardConstants.typeCallback.DELETE,
    }, dispatch);
  }, []);

  const callbackListMove = useCallback(({ data, selector }) => {
    BoardActions.incomingItemMove({
      data,
      selector,
      type: BoardConstants.typeMove.LIST,
    }, dispatch);
  }, []);

  /*
    Callback label
  */

  const callbackNewLabel = useCallback(({ currentLabel, userId }) => {
    BoardActions.incomingLabelsUpdate({
      currentLabel,
      typeAction: BoardConstants.typeCallback.NEW,
    }, dispatch);
  }, []);
  const callbackUpdateLabel = useCallback(({ currentLabel, userId }) => {
    BoardActions.incomingLabelsUpdate({
      currentLabel,
      typeAction: BoardConstants.typeCallback.EDIT,
    }, dispatch);
  }, []);
  const callbackDeleteLabel = useCallback(({ currentLabel, userId }) => {
    BoardActions.incomingLabelsUpdate({
      currentLabel,
      typeAction: BoardConstants.typeCallback.DELETE,
    }, dispatch);
  }, []);

  const listenSocket = (socket, params) => {
    socket
      .on('listNew', callbackNewList)
      .on('listArchive', callbackArchiveList)
      .on('listUnarchive', callbackUnarchiveList)
      .on('listUpdate', callbackUpdateList)
      .on('listMove', callbackListMove)
      // .on('boardUpdate', callbackBoardUpdate) // deprecated
      .on('labelNew', callbackNewLabel)
      .on('labelUpdate', callbackUpdateLabel)
      .on('labelDelete', callbackDeleteLabel);
  };
  const removeListener = (socket, params) => {
    socket
      .off('listNew')
      .off('listArchive')
      .off('listUnarchive')
      .off('listUpdate')
      .off('listMove')
      // .off('boardUpdate') // deprecated
      .off('labelNew')
      .off('labelUpdate')
      .off('labelDelete');
    socket.disconnect();
  };

  const { socket } = useSocketHooks({
    params: { boardId, userId },
    connectSocket: connectSocketBoard,
    listenSocket,
    removeListener,
  });

  return { socket, removeListener };
}

function useArchivedBoardCardsHooks() {
  const [{ currentBoardLists, currentBoard }, dispatch] = useContext(GlobalContext);
  const { enqueueSnackbar } = useSnackbar();
  const params = useParams();
  const { companyId, teamId, boardId } = params;

  const initiateListData = async ({
    filters, page, limit,
  }) => {
    try {
      const result = await BoardActions.getListArchivedCards({
        filters,
        limit,
        companyId,
        teamId,
        page,
        boardId: boardId || currentBoard?._id,
      });
      return result;
    } catch (error) {
      throw new ErrorException(error);
    }
  };

  const loadMoreListData = async ({
    filters, page, limit,
  }) => {
    try {
      const result = await BoardActions.getListArchivedCards({
        filters,
        limit,
        companyId,
        teamId,
        page,
        boardId: boardId || currentBoard?._id,
      });
      return result;
    } catch (error) {
      throw new ErrorException(error);
    }
  };

  const handleSetBodyPayloadRestoredCard = async (card) => {
    try {
      const initiateLists = async () => {
        try {
          const result = await BoardActions.initiateBoardV2(
            {
              boardId,
              companyId,
              teamId,
              currentBoard,
              currentBoardLists,
            },
            dispatch,
          );

          const lists = result?.data?.board?.lists;
          return lists;
        } catch (error) {
          const status = handleStatusMsg(error, 'error');

          enqueueSnackbar(status.message, {
            variant: 'error',
          });
        }
      };

      let checkListReady = [];
      if (currentBoardLists.length < 1 && currentBoard._id !== boardId) {
        const lists = await initiateLists();
        checkListReady = lists.filter((list) => list.archived.status === false);
      } else {
        checkListReady = currentBoardLists.filter((list) => list.archived.status === false);
      }

      if (checkListReady.length < 1) {
        enqueueSnackbar('There is no list left for restoring this card', {
          variant: 'error',
        });
        return;
      }
      const cardDestListId = checkListReady?.[0]?._id;
      // const cardSourceListId = await getListIdByCardId({
      //   boardId, cardId, companyId, teamId,
      // });
      const data = {
        cardId: card._id,
        boardId,
        listSourceId: card.list,
        listDestId: cardDestListId,
        listDestPos: 0,
      };
      return data;
    } catch (error) {
      throw new ErrorException(error);
    }
  };

  const unarchiveData = async ({
    item,
  }) => {
    try {
      const body = await handleSetBodyPayloadRestoredCard(item);
      await CardActions.unArchiveCard({
        body,
        cardId: item._id,
        companyId,
        teamId,
      }, dispatch);
    } catch (error) {
      throw new ErrorException(error);
    }
  };

  return [initiateListData, loadMoreListData, unarchiveData];
}

function useArchivedBoardListsHooks() {
  const [{ currentBoardLists }, dispatch] = useContext(GlobalContext);
  const { enqueueSnackbar } = useSnackbar();
  const params = useParams();
  const { companyId, teamId, boardId } = params;

  const initiateListData = async ({
    filters, page, limit,
  }) => {
    try {
      const result = await BoardActions.getListArchivedLists({
        filters,
        limit,
        companyId,
        teamId,
        page,
        boardId,
      });
      return result;
    } catch (error) {
      throw new ErrorException(error);
    }
  };

  const loadMoreListData = async ({
    filters, page, limit,
  }) => {
    try {
      const result = await BoardActions.getListArchivedLists({
        filters,
        limit,
        companyId,
        teamId,
        page,
        boardId,
      });
      return result;
    } catch (error) {
      throw new ErrorException(error);
    }
  };

  const unarchiveData = async ({
    item,
  }) => {
    try {
      const body = { boardId };
      await ListActions.unarchiveList({
        body,
        listId: item._id,
        companyId,
        teamId,
        boardId,
      }, dispatch);
    } catch (error) {
      throw new ErrorException(error);
    }
  };

  return [initiateListData, loadMoreListData, unarchiveData];
}

const useGetBoardCardViewData = ({
  boardId, userId, companyId, teamId,
}) => {
  let initialBoardCardView;

  initialBoardCardView = getSessionBoardCardView(
    { companyId, userId },
  );

  if (!initialBoardCardView?.mainValue) {
    initialBoardCardView = getStoreBoardCardView(
      { companyId, userId },
    );
  }

  const boardCardViewInitialCalendarViewValue = initialBoardCardView?.initialCalendarViewValue || 'dayGridMonth';
  const boardCardViewMainValue = initialBoardCardView?.mainValue || 'kanbanAll';

  let boardCardViewUrl;

  switch (boardCardViewMainValue) {
    case 'listAll':
      boardCardViewUrl = `/companies/${companyId}/teams/${teamId}/boards/${boardId}/list/all`;
      break;
    case 'calendarAll':
      boardCardViewUrl = `/companies/${companyId}/teams/${teamId}/boards/${boardId}/calendar/all?initalCalendarView=${boardCardViewInitialCalendarViewValue}`;
      break;
    case 'kanbanAll':
      boardCardViewUrl = `/companies/${companyId}/teams/${teamId}/boards/${boardId}/kanban/all`;
      break;
    default:
      boardCardViewUrl = `/companies/${companyId}/teams/${teamId}/boards/${boardId}/kanban/all`;
      break;
  }

  return [
    boardCardViewMainValue,
    boardCardViewUrl,
    boardCardViewInitialCalendarViewValue,
  ];
};

const useSetBoardCardView = ({
  boardId, companyId, user, initialCalendarViewValue = 'dayGridMonth', mainValue = 'kanbanAll',
}) => {
  useEffect(() => {
    if (!boardId || !user?._id) return;
    if (user?._id) {
      setStoreBoardCardView({
        companyId,
        userId: user._id,
        view: {
          initialCalendarViewValue,
          mainValue,
        },
      });
      setSessionBoardCardView({
        companyId,
        userId: user._id,
        view: {
          initialCalendarViewValue,
          mainValue,
        },
      });
    }
  }, [user]);
};

export {
  useBoardV2Socket,
  useArchivedBoardCardsHooks,
  useArchivedBoardListsHooks,
  useBoardPopulatedCardSocket,
  useGetBoardCardViewData,
  useSetBoardCardView,
  useBoardV2SocketForListUpdateOnly,
};
