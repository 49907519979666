const regexRemoveTagHTML = /(<([^>]+)>)/ig;

function isValidIDPhoneNumber(input) {
  const phoneNumberRegex = /^\+62\d{8,15}$/;
  return phoneNumberRegex.test(input);
}

function isOldEditorFormat(str) {
  const regex = /^\{.*"blocks".*\}$/; // Regular expression to match the format and "blocks" substring

  if (regex.test(str)) {
    return 'Format matched'; // String matches the JSON-like format and contains "blocks" substring
  } if (str.includes('{"blocks"')) {
    return 'Partial format matched'; // String contains "blocks" substring but doesn't match the complete format
  }
  return 'Regular string'; // String is a regular string
}

function extractTextFromOldEditor(data) {
  const regex = /"text"\s*:\s*"([^"]+)"/;
  const match = data.match(regex);

  if (match) {
    return match[1];
  }

  return data;
}

export {
  regexRemoveTagHTML,
  isValidIDPhoneNumber,
  isOldEditorFormat,
  extractTextFromOldEditor,
};
