import React, {
  memo, useState, useCallback, useEffect,
} from 'react';
import PropTypes from 'prop-types';
import { Box } from '@mui/material';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import Color from '../../../../../themes/colors';
import { DisplayTextSubHeadline, DisplayTextBody } from '../../../../../components/UI_V2/Display/Text';
import limitChar from '../../../../../utilities/limitChar';
import { adjustServerUrl } from '../../../../../utilities/stringUtil';

const styles = {
  background: Color.white,
  border: `1px solid ${Color.lighterBlue}`,
  boxSizing: 'border-box',
  borderRadius: '10px',
  height: 'auto',
  minHeight: '58px',
  padding: '3px 10px 3px 20px',
  cursor: 'pointer',
  '&:hover': {
    backgroundColor: Color.yellowCicle3,
    border: `1px solid ${Color.yellowCicle3}`,
  },
};

const flexStyles = {
  display: 'flex',
  alignItems: 'center',
};

const logoStyles = {
  width: '30px',
  maxHeight: '30px',
  objectFit: 'cover',
};

const CompanyItem = ({
  name,
  desc,
  logo,
  onClick,
}) => (
  <Box
    sx={{
      ...styles,
      ...flexStyles,
    }}
    onClick={onClick}
  >
    <Box sx={{
      marginRight: '12px',
    }}
    >
      <LazyLoadImage style={{ ...logoStyles }} src={adjustServerUrl(logo)} />
    </Box>
    <Box>
      <DisplayTextBody
        decoration="bold"
        color={Color.blueNavy3}
      >
        {limitChar(name, 50)}
      </DisplayTextBody>
      <Box>
        <DisplayTextBody>
          {limitChar(desc, 60)}
        </DisplayTextBody>
      </Box>
    </Box>
  </Box>
);

CompanyItem.propTypes = {
  name: PropTypes.string.isRequired,
  desc: PropTypes.string.isRequired,
  logo: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
};

export default memo(CompanyItem);
