import React, { memo } from 'react';
import styles from './SeparatorLoginButton.module.css';
import Line from '../../../components/UI/Line/Line';

const SeparatorLoginButton = () => (
  <div className={styles.SeparatorLoginButton}>
    <div className={styles.SeparatorLoginButton__leftSection}>
      <Line />
    </div>
    <div className={styles.SeparatorLoginButton__midSection}>
      <p>atau</p>
    </div>
    <div className={styles.SeparatorLoginButton__rightSection}>
      <Line />
    </div>
  </div>
);

export default memo(SeparatorLoginButton);
