import React from 'react';
import PropTypes from 'prop-types';
import {
  Chart as ChartJS,
  ArcElement, Tooltip, Legend,
} from 'chart.js';
import { Doughnut } from 'react-chartjs-2';
import Color from '../../../../../themes/colors';

ChartJS.register(
  ArcElement, Tooltip, Legend,
);
const DisplayChartDoughnut = ({ labels, datasets, colors }) => {
  const datasetsData = datasets.map((elem) => ({
    label: elem.label,
    data: elem.data,
    backgroundColor: [...colors],
  }));

  const chartData = {
    labels,
    datasets: datasetsData,
  };

  const chartOptions = {
    // layout: {
    //   padding: {
    //     top: 0, // Adjust this value to move the chart and legend vertically
    //   },
    // },
    responsive: true,
    plugins: {
      legend: {
        display: false,
      },
    },
  };

  return <Doughnut data={chartData} options={chartOptions} />;
};

DisplayChartDoughnut.propTypes = {
  labels: PropTypes.array,
  datasets: PropTypes.array,
  colors: PropTypes.array,
};

DisplayChartDoughnut.defaultProps = {
  labels: [],
  datasets: [],
  colors: [],
};

export default DisplayChartDoughnut;
