/* eslint-disable jsx-a11y/media-has-caption */
/* eslint-disable react/void-dom-elements-no-children */
import React, { memo, useEffect, useState } from 'react';
import { useMediaQuery } from '@mui/material';
import PlayCircleIcon from '@mui/icons-material/PlayCircle';
import PropTypes from 'prop-types';
import styles from './VideoPlayerThumbnail.module.css';

const variantStyles = {
  fileSnapshot: {
    containerStyles: styles.fileSnapshotContainer,
    iconStyles: styles.fileSnapshotIcon,
    videoWidth: 60,
  },
  chatSnapshot: {
    containerStyles: styles.chatSnapshotContainer,
    iconStyles: styles.chatSnapshotIcon,
    videoWidth: 60,
  },
  bucketFile: {
    containerStyles: styles.bucketFileContainer,
    iconStyles: styles.bucketFileIcon,
    videoWidth: 190,
  },
  file: {
    containerStyles: styles.fileContainer,
    iconStyles: styles.fileIcon,
    videoWidth: 350,
  },
  chat: {
    containerStyles: styles.chatContainer,
    iconStyles: styles.chatIcon,
    videoWidth: 400,
  },
  cardAttachment: {
    containerStyles: styles.cardAttachmentContainer,
    iconStyles: styles.cardAttachmentIcon,
    videoWidth: 120,
  },
};

const VideoPlayerThumbnail = ({
  videoUrl,
  variant,
}) => {
  const isMobile = useMediaQuery('(max-width:720px)');

  return (
    <div className={`${styles.container} ${variantStyles[variant].containerStyles}`}>
      <video
        src={videoUrl}
        width={variantStyles[variant].videoWidth}
      />
      <div className={`${styles.icon} ${variantStyles[variant].iconStyles}`}>
        <PlayCircleIcon />
      </div>
    </div>
  );
};

VideoPlayerThumbnail.propTypes = {
  videoUrl: PropTypes.string,
  variant: PropTypes.oneOf(['fileSnapshot', 'chatSnapshot', 'file', 'chat', 'cardAttachment']),
};

VideoPlayerThumbnail.defaultProps = {
  videoUrl: undefined,
  variant: 'cardAttachment',
};

export default memo(VideoPlayerThumbnail);
