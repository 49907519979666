import React, {
  memo,
} from 'react';
import PropTypes from 'prop-types';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import { DisplayTextBody } from '../../../components/UI_V2/Display/Text';
import Color from '../../../themes/colors';
import styles from './ActivityButton.module.css';
import cheersIcon from '../../../assets/CheersPage/cheers-icon.png';

const activeStyles = {
  true: styles.active,
  false: '',
};

const ActivityButton = ({ onClick, isActive }) => (
  <div onClick={onClick} className={`${styles.container} ${activeStyles[isActive]}`}>
    <div className={styles.icon}>
      <AccessTimeIcon />
    </div>
    <DisplayTextBody
      color={Color.yellowNavyCicle}
    >
      Aktifitas
    </DisplayTextBody>
  </div>
);

ActivityButton.propTypes = {
  onClick: PropTypes.func.isRequired,
  isActive: PropTypes.bool.isRequired,
};

export default memo(ActivityButton);
