import React, {
  memo, useContext, useEffect, useCallback, useState,
} from 'react';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import PropTypes from 'prop-types';
import { useSnackbar } from 'notistack';
import { GlobalContext, initialState } from '../../../../contexts/GlobalStateProvider';
import styles from './Body.module.css';
import BillingPageListCompaniesItem from '../Item/Item';
import LinkNoDecor from '../../../../components/LinkNoDecor/LinkNoDecor';
import { BillingActions } from '../../../../actions';
import { useInfinityScrollHooks } from '../../../../hooks/InfinityScrollHooks';
import { BillingConstants } from '../../../../constants';
import handleStatusMsg from '../../../../utilities/handleStatusMsg';
import InfinityScroll from '../../../../components/UI/InfinityScroll/InfinityScroll';

const BillingPageListCompaniesBody = ({
  currentCompany,
}) => {
  const [
    {
      user,
      currentSubscriptions,
      previousSubscriptions,
    },
    dispatch,
  ] = useContext(GlobalContext);
  const [isFirstLoad, setIsFirstLoad] = useState(true);
  const history = useHistory();
  const location = useLocation();
  const params = useParams();

  const { companyId } = params;

  const { enqueueSnackbar } = useSnackbar();

  const getMoreLists = async () => {
    const result = await BillingActions.loadMoreSubscriptions({
      currentSubscriptions,
    }, dispatch);

    return result;
  };

  const resetPreviousLists = useCallback(() => {
    BillingActions.setPreviousSubscriptions({
      previousSubscriptions: initialState.previousSubscriptions,
    }, dispatch);
    BillingActions.setCurrentSubscriptions({
      currentSubscriptions: initialState.currentSubscriptions,
    }, dispatch);
  }, []);

  const {
    lists,
    handleLoadMoreLists,
    checkIfListNeedToLoad,
    setCheckLoadMore,
    isListsLengthAtTheLimitOrAbove,
  } = useInfinityScrollHooks({
    currentObjectWithKeyProperty: currentSubscriptions,
    previousLists: previousSubscriptions?.data,
    resetPreviousLists,
    getMoreLists,
    keyProperty: 'data',
    limitList: BillingConstants.limitSubscriptions,
  });

  const initiateSubscriptionsApi = async () => {
    try {
      const result = await BillingActions.initiateSubscriptions({}, dispatch);

      if (isListsLengthAtTheLimitOrAbove(result?.data?.data)) {
        setCheckLoadMore(true);
      } else {
        setCheckLoadMore(false);
      }
    } catch (err) {
      const status = handleStatusMsg(err, 'error');

      enqueueSnackbar(status.message, {
        variant: 'error',
      });
    }
  };

  const checkIfNeedToInitialize = () => {
    if (isFirstLoad) {
      setIsFirstLoad(false);
      return true;
    }
    const result = lists.filter((subscription) => subscription.company._id === companyId);

    if (result.length > 0) {
      return false;
    }

    return true;
  };

  useEffect(() => {
    if (user._id === undefined) {
      history.push(`/check-login?previousPath=${location.pathname}`);
      return;
    }

    if (checkIfNeedToInitialize()) {
      initiateSubscriptionsApi();
    }
  }, [location]);

  return (
    <div id="listSubscriptions" className={styles.container}>
      <InfinityScroll
        scrollableTarget="listSubscriptions"
        dataLength={lists?.length || 0}
        hasMore={checkIfListNeedToLoad()}
        next={handleLoadMoreLists}
        emptyMessage="No subscription here yet..."
      >
        {lists.map((subscription) => (
          <LinkNoDecor to={`/companies/${subscription.company._id}/billings`}>
            <div className={styles.item}>
              <BillingPageListCompaniesItem
                company={subscription.company}
                isSelected={
                  subscription.company._id === currentCompany._id
              }
                subscription={subscription}
              />
            </div>
          </LinkNoDecor>
        ))}
      </InfinityScroll>

    </div>
  );
};

BillingPageListCompaniesBody.propTypes = {
  currentCompany: PropTypes.object.isRequired,
};

export default memo(BillingPageListCompaniesBody);
