import React from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import PropTypes from 'prop-types';
import styles from './MainLayout.module.css';

const MainLayout = React.memo(({ children }) => (
  <Container className={styles.MainLayout}>
    <Row>
      <Col xs={1} />
      <Col className={styles.mainCol} md={10}>{children}</Col>
      <Col xs={1} />
    </Row>
  </Container>
));

MainLayout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default MainLayout;
