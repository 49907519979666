import React, {
  useCallback, useEffect, useRef, useState, memo, useContext,
} from 'react';
import _, { isEqual, isNil, slice } from 'lodash';
import PropTypes from 'prop-types';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import styles from './BucketList.module.css';
import InfinityScroll from '../../../components/UI/InfinityScroll/InfinityScroll';
import File from '../File/File';
import Folder from '../Folder/Folder';
import Doc from '../Doc/Doc';
import { BucketConstants } from '../../../constants';
import LinkNoDecor from '../../../components/LinkNoDecor/LinkNoDecor';
import { BucketActions } from '../../../actions';
import { GlobalContext, initialState } from '../../../contexts/GlobalStateProvider';
import { useInfinityScrollHooks } from '../../../hooks/InfinityScrollHooks';
import handleStatusMsg from '../../../utilities/handleStatusMsg';
import FilterBar from '../../../components/Container/FilterBar/FilterBar';
import handleLoadings from '../../../utilities/handleLoadings';
import FeedbackLoadingMain from '../../../components/UI_V2/Feedback/Loading/Main/Main';
import FeedbackIntroMain from '../../../components/UI_V2/Feedback/Intro/Main/Main';
import BucketSkeleton from '../BucketSkeleton/BucketSkeleton';
import emptyImage from '../../../assets/BucketPage/empty-image.png';
import { InputButtonMain } from '../../../components/UI_V2/Input/Button';
import { checkIfUserHaveAccessToDataV3 } from '../../../actions/UserActions';

function BucketList({
  handleSetIsFilterActive,
  showFilters,
  handleHideFilters,
}) {
  const [{
    user,
    currentBucket,
    currentBucketItems,
    previousBucketItems,
    currentRoleUser,
  }, dispatch] = useContext(GlobalContext);
  const [loadings, setLoadings] = useState([]);
  const [filters, setFilters] = useState({
    filterTeams: [],
    filterSubscribers: [],
    filterLabels: [],
    filterTitle: '',
    filterDueDate: null,
  });

  const history = useHistory();
  const params = useParams();
  const location = useLocation();
  const { companyId, teamId, bucketId } = params;
  const { enqueueSnackbar } = useSnackbar();

  const getMoreLists = async () => {
    const result = await BucketActions.loadMoreBucketItems({
      currentBucketItems,
      companyId,
      teamId,
      bucketId,
      filters,
    }, dispatch);

    return result;
  };

  const resetPreviousLists = useCallback(() => {
    BucketActions.setPreviousBucketItems({
      previousBucketItems: initialState.previousBucketItems,
    }, dispatch);
    BucketActions.setCurrentBucketItems({
      currentBucketItems: initialState.currentBucketItems,
    }, dispatch);
  }, []);

  const {
    lists,
    handleLoadMoreLists,
    checkIfListNeedToLoad,
    setCheckLoadMore,
    isListsLengthAtTheLimitOrAbove,
  } = useInfinityScrollHooks({
    currentObjectWithKeyProperty: currentBucketItems,
    previousLists: previousBucketItems?.data,
    resetPreviousLists,
    getMoreLists,
    keyProperty: 'data',
    limitList: BucketConstants.limitBucket,
  });

  const initiateBucketItemsApi = async (filtersValue) => {
    try {
      const startLoadings = handleLoadings('initiateBucketItems', [...loadings], 'start');
      setLoadings([...startLoadings]);
      const result = await BucketActions.initiateBucketItems({
        companyId,
        teamId,
        bucketId,
        filters: filtersValue,
      }, dispatch);

      if (isListsLengthAtTheLimitOrAbove(result?.data?.data)) {
        setCheckLoadMore(true);
      } else {
        setCheckLoadMore(false);
      }
    } catch (err) {
      const status = handleStatusMsg(err, 'error');

      enqueueSnackbar(status.message, {
        variant: 'error',
      });
    } finally {
      const endLoadings = handleLoadings('initiateBucketItems', [...loadings], 'end');
      setLoadings([...endLoadings]);
    }
  };

  useEffect(() => {
    if (user._id === undefined) {
      history.push(`/check-login?previousPath=${location.pathname}`);
      return;
    }

    if (_.isEmpty(currentBucket)) {
      return;
    }

    if (currentBucket._id !== bucketId) return;

    initiateBucketItemsApi();
  }, [location]);

  const styleInfinityScrollComponent = {
    display: 'flex',
    position: 'relative',
    flexWrap: 'wrap',
    justifyContent: 'center',
  };

  // if (isNil(docsFiles)) return null;

  const onApplyFilters = (filtersValue) => {
    setFilters(filtersValue);
    initiateBucketItemsApi(filtersValue);
  };

  useEffect(() => {
    const {
      filterDueDate,
      filterLabels,
      filterSubscribers,
      filterTeams,
      filterTitle,
    } = filters;
    const isSearchFilterActive = filterTitle.length > 0;
    const isTeamsFilterActive = filterTeams.length > 0;
    const isLabelsFilterActive = filterLabels.length > 0;
    const isMembersFilterActive = filterSubscribers.length > 0;
    const isDueDateFilterActive = filterDueDate;

    let isActive = false;
    if (isSearchFilterActive) isActive = true;
    if (isTeamsFilterActive) isActive = true;
    if (isLabelsFilterActive) isActive = true;
    if (isMembersFilterActive) isActive = true;
    if (isDueDateFilterActive) isActive = true;

    handleSetIsFilterActive(isActive);
  }, [filters]);

  const isListEmpty = lists.length < 1;

  const handleCreateDocument = useCallback(() => {
    history.push(`/companies/${companyId}/teams/${teamId}/buckets/${bucketId}/create`);
  }, []);

  return (
    <>
      <FeedbackLoadingMain
        wait="initiateBucketItems"
        loadings={loadings}
        loadingComponent={<BucketSkeleton />}
      >
        <InfinityScroll
          style={styleInfinityScrollComponent}
          dataLength={lists?.length || 0}
          hasMore={checkIfListNeedToLoad()}
          next={handleLoadMoreLists}
          emptyMessage="No items here yet..."
          hideEndMessage
        >
          {isListEmpty && (
            <div className={styles.introEmpty}>
              <FeedbackIntroMain
                illustrationImage={emptyImage}
                headline="Folder kosong"
                description="Kamu bisa buat dokumen baru atau unggah file ke sini"
                primaryCtaFunc={handleCreateDocument}
                primaryCtaText="Buat Dokumen"
              />
            </div>
          )}
          {lists.map((docFile) => {
            if (!checkIfUserHaveAccessToDataV3(docFile, user, currentRoleUser)) {
              return null;
            }
            if (docFile.type === 'doc') {
              return (
                <LinkNoDecor to={`/companies/${companyId}/teams/${teamId}/docs/${docFile._id}`}>
                  <Doc
                    doc={docFile}
                  />
                </LinkNoDecor>
              );
            } if (docFile.type === 'bucket') {
              return (
                <LinkNoDecor to={`/companies/${companyId}/teams/${teamId}/buckets/${docFile._id}`}>
                  <Folder
                    folder={docFile}
                  />
                </LinkNoDecor>
              );
            } if (docFile.type === 'file') {
              return (
                <LinkNoDecor to={`/companies/${companyId}/teams/${teamId}/files/${docFile._id}`}>
                  <File
                    file={docFile}
                  />
                </LinkNoDecor>
              );
            }
            return null;
          })}
        </InfinityScroll>
      </FeedbackLoadingMain>
      {showFilters
      && (
      <FilterBar
        showLabelSection={false}
        showMemberSection={false}
        showDueDateSection={false}
        showTeamSection={false}
        onClose={handleHideFilters}
        onApplyFilters={onApplyFilters}
        loadingsOnApplyFilters={loadings}
        waitOnApplyFilters="initiateBucketItems"
        initialSearch={filters.filterTitle}
        barTitle="Filter File"
        topPositionVariant="withBottomBar"
      />
      )}
    </>
  );
}

BucketList.propTypes = {
  showFilters: PropTypes.bool.isRequired,
  handleHideFilters: PropTypes.func.isRequired,
  handleSetIsFilterActive: PropTypes.func.isRequired,
};

// const isComponentDataEqual = (prevProps, nextProps) => {
//   const { docsFiles, user } = nextProps;
//   return isEqual(prevProps.docsFiles, docsFiles)
//   && isEqual(prevProps.user, user);
// };

export default memo(BucketList);
