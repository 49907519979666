import React, {
  memo, useCallback, useContext, useEffect, useState,
} from 'react';
import PropTypes from 'prop-types';
import DownloadIcon from '@mui/icons-material/Download';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { useMediaQuery } from '@mui/material';
import { useSnackbar } from 'notistack';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import { useInView } from 'react-intersection-observer';
import { InputSearchGeneral } from '../../../UI_V2/Input/Search';
import styles from './ListFavorites.module.css';
import Color from '../../../../themes/colors';
import { InputButtonMain } from '../../../UI_V2/Input/Button';
import { GlobalContext, initialState } from '../../../../contexts/GlobalStateProvider';
import TeamItem from '../../TeamSection/ListTeams/TeamItem/TeamItem';
import { useSearch } from '../../../../hooks/HelperHooks';
import { DisplayTextBody } from '../../../UI_V2/Display/Text';
import { actionTypes } from '../../../../reducers/reducer';
import handleStatusMsg from '../../../../utilities/handleStatusMsg';
import { TeamFavoriteActions } from '../../../../actions';
import { TeamFavoriteConstants } from '../../../../constants';
import { useInfinityScrollHooks } from '../../../../hooks/InfinityScrollHooks';
import { useTeamFavoriteHooks } from '../../../../hooks/TeamFavoriteHooks';
import handleLoadings from '../../../../utilities/handleLoadings';
import { getStoreTeamsColor } from '../../../../utilities/localStorage';
import InfinityScroll from '../../../UI/InfinityScroll/InfinityScroll';
import { FeedbackSpinnerGeneral } from '../../../UI_V2/Feedback/Spinner';
import { setIsFavoriteListEmpty } from '../../../../actions/TeamFavoriteActions';

const ListFavorites = ({
  handleSelectAndShowTeamFavoriteMenu,
  handleCancelShowTeamFavoriteMenu,
  params,
}) => {
  const [{
    user,
    teams,
    selectedEditTeamFavorite,
    isOpenTeamFromFavorite,
    currentTeamFavorites, previousTeamFavorites,
    shouldCheckTeamsColor,
  }, dispatch] = useContext(GlobalContext);
  const isMobile = useMediaQuery('(max-width:720px)');
  const [loadings, setLoadings] = useState([]);
  const [teamsColor, setTeamsColor] = useState([]);

  const onClickTeamItem = () => {
    dispatch({
      type: actionTypes.SET_IS_OPEN_TEAM_FROM_FAVORITE,
      isOpenTeamFromFavorite: true,
    });
  };

  const { companyId } = params;

  const { enqueueSnackbar } = useSnackbar();

  useTeamFavoriteHooks({
    companyId,
    userId: user?._id,
    teams,
  }, dispatch);

  //   const isUserManagerOrAbove = hasPermission(currentRoleUser, roles.managerOrAbove);

  const getMoreLists = async () => {
    const result = await TeamFavoriteActions.loadMoreTeamFavorites({
      currentTeamFavorites,
      companyId,
      userId: user?._id,
    }, dispatch);

    return result;
  };

  const resetPreviousLists = useCallback(() => {
    TeamFavoriteActions.setPreviousTeamFavorites({
      previousTeamFavorites: initialState.previousTeamFavorites,
    }, dispatch);
    TeamFavoriteActions.setCurrentTeamFavorites({
      currentTeamFavorites: initialState.currentTeamFavorites,
    }, dispatch);
  }, []);

  const {
    lists,
    handleLoadMoreLists,
    checkIfListNeedToLoad,
    setCheckLoadMore,
    isListsLengthAtTheLimitOrAbove,
  } = useInfinityScrollHooks({
    currentObjectWithKeyProperty: currentTeamFavorites,
    previousLists: previousTeamFavorites?.data,
    resetPreviousLists,
    getMoreLists,
    keyProperty: 'data',
    limitList: TeamFavoriteConstants.limitTeamFavorites,
    dontSorting: true,
    forceTriggerUpdateOnDataIndexOrderChange: true,
  });

  const isTeamsEmpty = lists.length < 1;

  const initiateTeamFavoritesApi = async () => {
    try {
      const result = await TeamFavoriteActions.initiateTeamFavorites({
        userId: user?._id,
        companyId,
      }, dispatch);
      const isFavoriteListEmpty = result?.data?.data?.length < 1;
      setIsFavoriteListEmpty({ isFavoriteListEmpty }, dispatch);

      if (isListsLengthAtTheLimitOrAbove(result?.data?.data)) {
        setCheckLoadMore(true);
      } else {
        setCheckLoadMore(false);
      }
    } catch (err) {
      const status = handleStatusMsg(err, 'error');

      enqueueSnackbar(status.message, {
        variant: 'error',
      });
    }
  };

  useEffect(() => {
    if (!user._id || !companyId) {
      return;
    }

    initiateTeamFavoritesApi();
  }, [user, companyId]);

  const reorderTeamFavoriteApi = async ({
    movedTeamId,
    destPosition,
  }) => {
    try {
      const startLoadings = handleLoadings('reorderFavoriteTeam', [...loadings], 'start');
      setLoadings([...startLoadings]);
      const result = await TeamFavoriteActions.reorderTeamFavoritePosition({
        teamId: movedTeamId,
        position: destPosition,
        companyId,
        userId: user?._id,
      }, dispatch);

      const status = handleStatusMsg(result, 'success');

      enqueueSnackbar(status.message, {
        variant: 'success',
      });
    } catch (error) {
      const status = handleStatusMsg(error, 'error');

      enqueueSnackbar(status.message, {
        variant: 'error',
      });
    } finally {
      const endLoadings = handleLoadings('reorderFavoriteTeam', [...loadings], 'end');
      setLoadings([...endLoadings]);
    }
  };

  const onDragEnd = (result) => {
    const {
      destination, source, draggableId,
    } = result;
    if (destination === null) {
      return;
    }

    if (destination.index === source.index && destination.droppableId === source.droppableId) {
      return;
    }

    reorderTeamFavoriteApi({
      movedTeamId: draggableId,
      destPosition: destination.index,
    });
  };

  useEffect(() => {
    if (!user?._id) return;
    if (!params?.companyId) return;

    setTeamsColor(getStoreTeamsColor({
      companyId: params?.companyId, userId: user?._id,
    }) || []);
  }, [user, params.companyId, shouldCheckTeamsColor]);

  // need using observerintersection
  // for infinite scroll comment inside modal dialog
  const { ref, inView, entry } = useInView();
  useEffect(() => {
    if (inView) {
      if (checkIfListNeedToLoad()) {
        handleLoadMoreLists();
      }
    }
  }, [inView]);
  return (
    <div
      className={styles.container}
    >
      <DragDropContext
      // onBeforeDragStart={onBeforeDragStart}
      // onDragUpdate={onDragUpdate}
      // onDragStart={onDragStart}
        onDragEnd={onDragEnd}
      >
        <Droppable key="list-team-favorite" droppableId="list-team-favorite" direction="vertical">
          {(providedDroppable) => (
            <div
              className={styles.list}
              ref={providedDroppable.innerRef}
              {...providedDroppable.droppableProps}
            >
              {/* {isTeamsEmpty && (
                <DisplayTextBody>
                  Belum ada tim
                </DisplayTextBody>
              )} */}
              <InfinityScroll
                // scrollableTarget="listSidebar"
                dataLength={lists?.length}
                hasMore={checkIfListNeedToLoad()}
                next={handleLoadMoreLists}
                hideEndMessage
                loader={(<></>)}
              >
                {!isTeamsEmpty && lists.map((team, index) => (
                  <Draggable key={team._id} draggableId={team._id} index={index}>
                    {(provided) => (
                      <div
                        className={styles.team}
                        ref={provided.innerRef}
                        {...provided?.draggableProps}
                        {...provided?.dragHandleProps}
                      >
                        <TeamItem
                          team={team}
                          teamsColor={teamsColor}
                          handleSelectAndShowTeamMenu={handleSelectAndShowTeamFavoriteMenu}
                          handleCancelShowTeamMenu={handleCancelShowTeamFavoriteMenu}
                          isTeamSelected={selectedEditTeamFavorite._id === team._id}
                          isTeamOpen={team._id === params.teamId && isOpenTeamFromFavorite}
                          onOpenTeam={onClickTeamItem}
                        />
                      </div>
                    )}
                  </Draggable>
                ))}
                {checkIfListNeedToLoad() && (
                <div ref={ref} className={styles.infiniteScroll}>
                  <FeedbackSpinnerGeneral size={30} color={Color.yellowAccentCicle} />
                </div>
                )}
              </InfinityScroll>
            </div>
          )}
        </Droppable>
      </DragDropContext>
    </div>
  );
};

ListFavorites.propTypes = {
  handleCancelShowTeamFavoriteMenu: PropTypes.func,
  handleSelectAndShowTeamFavoriteMenu: PropTypes.func,
  params: PropTypes.object,
};

ListFavorites.defaultProps = {
  handleSelectAndShowTeamFavoriteMenu: () => null,
  handleCancelShowTeamFavoriteMenu: () => null,
  params: { companyId: null, teamId: null },
};

export default memo(ListFavorites);
