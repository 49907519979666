import React, { useState, useEffect, memo } from 'react';
import ButtonBootstrap from 'react-bootstrap/Button';
import Spinner from 'react-bootstrap/Spinner';
import PropTypes from 'prop-types';
import styles from './Button.module.css';

const Button = ({
  variant, size, wait, flex, loadings, anchorId, handleClick, spinnerSize, spinnerAnimation,
  spinnerVariant, children, block,
}) => {
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (loadings === undefined) return;
    const checkWait = loadings.filter((loading) => loading === wait);

    if (anchorId === undefined) {
      if (checkWait.length > 0) {
        setIsLoading(true);
        return;
      }

      setIsLoading(false);
    }

    // anchorId only used to prevent loading unknownly activated
    if (checkWait.length > 0 && wait === anchorId) {
      setIsLoading(true);
      return;
    }

    if (isLoading && checkWait.length > 0) {
      setIsLoading(true);
      return;
    }

    setIsLoading(false);
  }, [loadings]);

  let cssStyle;

  switch (flex) {
    case 'flexStart':
      cssStyle = `${styles.container} ${styles.flexStart}`;
      break;

    default:
      cssStyle = styles.container;
      break;
  }

  return (
    <ButtonBootstrap
      onClick={handleClick}
      variant={variant}
      size={size}
      disabled={isLoading}
      block={block}
    >
      <div className={cssStyle}>
        {children}
        {isLoading
          ? (
            <div className={styles.spinner}>
              <Spinner variant={spinnerVariant || 'warning'} animation={spinnerAnimation || 'border'} size={spinnerSize || 'sm'} />
            </div>
          )
          : null}
      </div>
    </ButtonBootstrap>
  );
};

Button.propTypes = {
  children: PropTypes.node.isRequired,
  variant: PropTypes.string.isRequired,
  size: PropTypes.string.isRequired,
  wait: PropTypes.string.isRequired,
  flex: PropTypes.string.isRequired,
  loadings: PropTypes.array.isRequired,
  anchorId: PropTypes.string.isRequired,
  handleClick: PropTypes.func.isRequired,
  spinnerSize: PropTypes.string.isRequired,
  spinnerAnimation: PropTypes.string.isRequired,
  spinnerVariant: PropTypes.string.isRequired,
  block: PropTypes.string.isRequired,
};

export default memo(Button);
