import React, { memo, useState } from 'react';
import PropTypes from 'prop-types';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import styles from './ColumnList.module.css';
import { DisplayTextBody } from '../../../../../components/UI_V2/Display/Text';
import Color from '../../../../../themes/colors';

const ColumnList = () => (
  <div className={styles.container}>
    <div className={styles.col_companyName}>
      <DisplayTextBody
        decoration="bold"
      >
        Company Name
      </DisplayTextBody>
    </div>
    <div className={styles.col_picName}>
      <DisplayTextBody
        decoration="bold"
      >
        PIC
      </DisplayTextBody>
    </div>
    <div className={styles.col_picEmail}>
      <DisplayTextBody
        decoration="bold"
      >
        Email
      </DisplayTextBody>
    </div>
    <div className={styles.col_picPhone}>
      <DisplayTextBody
        decoration="bold"
      >
        Phone
      </DisplayTextBody>
    </div>
    <div className={styles.col_category}>
      <DisplayTextBody
        decoration="bold"
      >
        Category
      </DisplayTextBody>
    </div>
  </div>
);

export default memo(ColumnList);
