import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { Box } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import styles from './HeaderFilterSection.module.css';
import { DisplayTextBody, DisplayTextSubHeadline } from '../../../UI_V2/Display/Text';
import Color from '../../../../themes/colors';
import { InputButtonMain } from '../../../UI_V2/Input/Button';
import { getSingleOrPlural } from '../../../../utilities/stringUtil';

const applySectionCloseIconStyles = {
  true: styles.applyDisableCloseIconSection,
  false: styles.applySection,
};

const HeaderFilterSection = ({
  title,
  filterActiveCount,
  onClose,
  disableCloseIcon,
  onResetFilter,
  handleClickApplyFilters,
  waitOnApplyFilters,
  loadingsOnApplyFilters,
  showBackIconOnClose,
}) => (
  <>
    <Box className={styles.container}>
      <Box className={styles.contentSection}>
        <Box className={styles.title}>
          <DisplayTextSubHeadline
            mode="18"
            decoration="bold"
          >
            {title}
          </DisplayTextSubHeadline>
        </Box>
        <Box className={styles.closeAndFilterCount}>
          {filterActiveCount < 1 && (
          <Box>
            <InputButtonMain handleClick={handleClickApplyFilters} wait={waitOnApplyFilters} size="sm" loadings={loadingsOnApplyFilters}>Terapkan Filter</InputButtonMain>
          </Box>
          )}
          {filterActiveCount > 0 && (
          <Box className={styles.filterCount}>
            <DisplayTextBody
              mode="12"
              color={Color.blueAquaMarine}
              decoration="regular"
            >
              {`${filterActiveCount} filter diterapkan`}
            </DisplayTextBody>
          </Box>
          )}
          {filterActiveCount > 0 && (
          <Box onClick={onResetFilter} className={styles.reset}>
            <DisplayTextBody
              mode="12"
              color={Color.deleteColor}
              decoration="bold"
            >
              Reset
            </DisplayTextBody>
          </Box>
          )}
          {!disableCloseIcon && !showBackIconOnClose && (
          <Box onClick={onClose} className={styles.closeIcon}>
            <CloseIcon />
          </Box>
          )}
          {!disableCloseIcon && showBackIconOnClose && (
          <Box onClick={onClose} className={styles.closeIcon}>
            <ArrowBackIcon />
          </Box>
          )}
        </Box>
      </Box>
      {filterActiveCount > 0 && (
      <Box className={applySectionCloseIconStyles[disableCloseIcon]}>
        <InputButtonMain handleClick={handleClickApplyFilters} wait={waitOnApplyFilters} size="sm" loadings={loadingsOnApplyFilters}>Terapkan Filter</InputButtonMain>
      </Box>
      )}
    </Box>
  </>
);

HeaderFilterSection.propTypes = {
  title: PropTypes.string,
  filterActiveCount: PropTypes.number,
  onClose: PropTypes.func,
  onResetFilter: PropTypes.func,
  disableCloseIcon: PropTypes.bool,
  handleClickApplyFilters: PropTypes.func.isRequired,
  waitOnApplyFilters: PropTypes.string.isRequired,
  loadingsOnApplyFilters: PropTypes.array.isRequired,
  showBackIconOnClose: PropTypes.bool,
};

HeaderFilterSection.defaultProps = {
  title: 'Filters',
  filterActiveCount: 0,
  onClose: () => null,
  onResetFilter: () => null,
  disableCloseIcon: false,
  showBackIconOnClose: false,
};

export default memo(HeaderFilterSection);
