import React, {
  memo, useContext, useEffect, useState,
} from 'react';
import PropTypes from 'prop-types';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { useParams } from 'react-router-dom';
import { Skeleton } from '@mui/material';
import styles from './ChartMemberUserReport.module.css';
import Color from '../../../../../themes/colors';
import { GlobalContext } from '../../../../../contexts/GlobalStateProvider';
import { DisplayChartDoughnut } from '../../../../../components/UI_V2/Display/Chart';
import BackgroundChart from '../BackgroundChart/BackgroundChart';
import TitleChart from '../TitleChart/TitleChart';
import ProfileUserReport from './ProfileUserReport/ProfileUserReport';
import TeamLocationUserReport from './TeamLocationUserReport/TeamLocationUserReport';
import PerformanceBadgeUserReport from './PerformanceBadgeUserReport/PerformanceBadgeUserReport';
import handleLoadings from '../../../../../utilities/handleLoadings';
import { FeedbackLoadingMain } from '../../../../../components/UI_V2/Feedback/Loading';
import { limitChar } from '../../../../../utilities/stringUtil';
import { getFromUntilDateText } from '../../../../../utilities/dateUtil';
import ChartUserSkeleton from '../ChartUserSkeleton/ChartUserSkeleton';

const ChartMemberUserReport = ({
  member,
  fromDate,
  untilDate,
  rating,
  isEmptyData,
  isLoading,
  title,
}) => {
  const [{ currentTeam }] = useContext(GlobalContext);
  const [loadings, setLoadings] = useState([]);

  const params = useParams();

  const { teamId, memberId } = params;

  return (
    <div className={styles.container}>
      <BackgroundChart>
        <div className={styles.title}>
          <TitleChart>
            {title}
          </TitleChart>
        </div>
        {isLoading && (
        <div className={styles.skeleton}>
          <ChartUserSkeleton />
        </div>
        )}
        {!isLoading && (
        <div className={styles.body}>
          <div className={styles.userAndBadge}>
            <div className={styles.user}>
              <ProfileUserReport member={member} />
            </div>
            <div className={styles.badge}>
              <PerformanceBadgeUserReport isEmptyData={isEmptyData} rating={rating} />
            </div>
          </div>
          <div className={styles.teamAndPeriod}>
            <div className={styles.team}>
              <TeamLocationUserReport
                title="Tim :"
                subTitle={limitChar(currentTeam?.name, 15)}
              />
            </div>
            <div className={styles.period}>
              <TeamLocationUserReport
                title="Periode :"
                subTitle={getFromUntilDateText(fromDate, untilDate)}
              />
            </div>
          </div>
        </div>
        )}
      </BackgroundChart>
    </div>
  );
};

ChartMemberUserReport.propTypes = {
  member: PropTypes.object.isRequired,
  fromDate: PropTypes.string.isRequired,
  untilDate: PropTypes.string.isRequired,
  rating: PropTypes.object.isRequired,
  isEmptyData: PropTypes.bool.isRequired,
  isLoading: PropTypes.bool,
  title: PropTypes.string,
};

ChartMemberUserReport.defaultProps = {
  isLoading: true,
  title: 'Laporan Kinerja Realtime :',
};

export default memo(ChartMemberUserReport);
