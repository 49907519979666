import React, { memo, useState } from 'react';
import PropTypes from 'prop-types';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import styles from './ColumnTitleListRoleBadge.module.css';
import { DisplayTextBody } from '../../../../UI_V2/Display/Text';
import Color from '../../../../../themes/colors';

const ColumnTitleListRoleBadge = () => (
  <div className={styles.container}>
    <div className={styles.columnTitle_badge}>
      <DisplayTextBody
        color={Color.black}
        decoration="bold"
        mode="12"
        fontFamily="openSans"
      >
        Akses
      </DisplayTextBody>
    </div>
    <div className={styles.columnTitle_permissionInfo}>
      <DisplayTextBody
        color={Color.black}
        decoration="bold"
        mode="12"
        fontFamily="openSans"
      >
        Izin
      </DisplayTextBody>
    </div>
  </div>
);

ColumnTitleListRoleBadge.propTypes = {};

ColumnTitleListRoleBadge.defaultProps = {};

export default memo(ColumnTitleListRoleBadge);
