const limitNotif = 10;
const typeNotifications = {
  POST: ['post', 'postMention', 'postSubscription', 'postSystem'],
  POST_COMMENT: ['comment', 'postComment', 'postCommentMention'],
  POST_DISCUSSION: ['commentDiscussionPost', 'commentDiscussionMentionPost'],
  QUESTION: ['question', 'questionSystem', 'questionSubscription'],
  QUESTION_COMMENT: ['questionComment', 'questionCommentMention'],
  QUESTION_DISCUSSION: ['commentDiscussionQuestion', 'commentDiscussionMentionQuestion'],
  DOC: ['doc', 'docMention', 'docSubscription'],
  DOC_COMMENT: ['docComment', 'docCommentMention'],
  DOC_DISCUSSION: ['commentDiscussionDoc', 'commentDiscussionMentionDoc'],
  FILE: ['file', 'fileSubscription'],
  FILE_COMMENT: ['fileComment', 'fileCommentMention'],
  FILE_DISCUSSION: ['commentDiscussionFile', 'commentDiscussionMentionFile'],
  BUCKET: ['bucketSubscription'],
  EVENT: ['event', 'eventMention', 'eventSystem', 'eventSubscription'],
  EVENT_COMMENT: ['eventComment', 'eventCommentMention'],
  EVENT_DISCUSSION: ['commentDiscussionEvent', 'commentDiscussionMentionEvent'],
  RECURRING_OCCURRENCE: ['recurringOccurrence', 'recurringOccurrenceMention', 'recurringOccurrenceSystem', 'recurringOccurrenceSubscription'],
  RECURRING_OCCURRENCE_COMMENT: ['recurringOccurrenceComment', 'recurringOccurrenceCommentMention'],
  RECURRING_OCCURRENCE_DISCUSSION: ['commentDiscussionRecurringOccurrence', 'commentDiscussionMentionRecurringOccurrence'],
  CARD: ['card', 'cardMember', 'cardMoved', 'cardArchived', 'cardMention', 'cardSystem'],
  CARD_COMMENT: ['cardComment', 'cardCommentMention'],
  CARD_DISCUSSION: ['commentDiscussionCard', 'commentDiscussionMentionCard'],
  CHAT: ['chat', 'chatMention'],
  GROUP_CHAT: ['groupChat', 'groupChatMention'],
  SUBSCRIPTION_SYSTEM: ['subscriptionSystem'],
  CHEER: ['cheersSystem'],
  TASK: ['task', 'taskReminder', 'taskSystem'],
};

export {
  limitNotif,
  typeNotifications,
};
