import React, { memo } from 'react';
import SubjectIcon from '@mui/icons-material/Subject';
import PropTypes from 'prop-types';
import styles from './DescIcon.module.css';

const DescIcon = ({ desc, clicked }) => (
  <>
    {desc === undefined ? null : (
      <div onClick={clicked} className={styles.DescIcon}>
        <SubjectIcon />
      </div>
    ) }
  </>
);

DescIcon.propTypes = {
  desc: PropTypes.string.isRequired,
  clicked: PropTypes.func.isRequired,
};

const isComponentDataEqual = (prevProps, nextProps) => {
  const { desc } = nextProps;
  return prevProps.desc === desc;
};

export default memo(DescIcon, isComponentDataEqual);
