/* eslint-disable consistent-return */
import { useCallback, useState } from 'react';
import { AttachmentActions, CheerActions, FileActions } from '../actions';
import { CheerConstants, FileConstants } from '../constants';
import {
  connectSocketBucket, connectSocketFile,
} from '../services/socket';
import { useSocketHooks } from './SocketHooks';

function useFileSocket({ bucketId, fileId, userId }, dispatch) {
  const callbackFile = useCallback((file) => {
    if (file?._id !== fileId) return;
    FileActions.incomingFile({
      file,
    }, dispatch);
  }, []);

  const listenSocket = (socket, params) => {
    socket
      .on(`file-update-${params?.bucketId}`, callbackFile)
      .on(`file-archive-${params?.bucketId}`, callbackFile);
  };
  const removeListener = (socket, params) => {
    socket
      .off(`file-update-${params?.bucketId}`)
      .off(`file-archive-${params?.bucketId}`);
    socket.disconnect();
  };

  useSocketHooks({
    params: { bucketId, userId },
    connectSocket: connectSocketBucket,
    listenSocket,
    removeListener,
  });
}

function useFileCheerNCommentSocket({ fileId, userId }, dispatch) {
  // callback cheer content file
  const callbackNewCheer = useCallback((cheer) => {
    CheerActions.incomingCheerFromPrimaryParent({
      cheer,
      typeAction: CheerConstants.typeCallback.NEW,
      primaryParentAction: ({ updateStateParent }) => FileActions.dispatchUpdateBucketFile(
        { updateBucketFile: updateStateParent },
        dispatch,
      ),
      keyProperty: 'cheers',
    });
  }, []);
  const callbackDeleteCheer = useCallback((cheer) => {
    CheerActions.incomingCheerFromPrimaryParent({
      cheer,
      typeAction: CheerConstants.typeCallback.DELETE,
      primaryParentAction: ({ updateStateParent }) => FileActions.dispatchUpdateBucketFile(
        { updateBucketFile: updateStateParent },
        dispatch,
      ),
      keyProperty: 'cheers',
    });
  }, []);

  // callback comment
  const callbackNewComment = useCallback((comment) => {
    FileActions.incomingFileComment({
      comment,
      typeAction: FileConstants.typeCallback.NEW,
    }, dispatch);
  }, []);
  const callbackUpdateComment = useCallback((comment) => {
    FileActions.incomingFileComment({
      comment,
      typeAction: FileConstants.typeCallback.EDIT,
    }, dispatch);
  }, []);
  const callbackDeleteComment = useCallback((comment) => {
    FileActions.incomingFileComment({
      comment,
      typeAction: FileConstants.typeCallback.DELETE,
    }, dispatch);
  }, []);

  // callback cheer comment
  const callbackNewCheerComment = useCallback((cheer) => {
    CheerActions.incomingCheerFromSecondaryParent({
      cheer,
      typeAction: CheerConstants.typeCallback.NEW,
      secondaryParentAction: FileActions.incomingFileComment,
      typeActionEditSecondaryParent: FileConstants.typeCallback.EDIT,
    }, dispatch);
  }, []);
  const callbackDeleteCheerComment = useCallback((cheer) => {
    CheerActions.incomingCheerFromSecondaryParent({
      cheer,
      typeAction: CheerConstants.typeCallback.DELETE,
      secondaryParentAction: FileActions.incomingFileComment,
      typeActionEditSecondaryParent: FileConstants.typeCallback.EDIT,
    }, dispatch);
  }, []);

  const listenSocket = (socket, params) => {
    socket
      .on('cheer-new', callbackNewCheer)
      .on('cheer-delete', callbackDeleteCheer)
      .on(`filesComment-new-${params?.fileId}`, callbackNewComment)
      .on(`filesComment-update-${params?.fileId}`, callbackUpdateComment)
      .on(`filesComment-delete-${params?.fileId}`, callbackDeleteComment)
      .on('cheerComment-new', callbackNewCheerComment)
      .on('cheerComment-delete', callbackDeleteCheerComment);
  };
  const removeListener = (socket, params) => {
    socket
      .off('cheer-new')
      .off('cheer-delete')
      .off(`filesComment-new-${params?.fileId}`)
      .off(`filesComment-update-${params?.fileId}`)
      .off(`filesComment-delete-${params?.fileId}`)
      .off('cheerComment-new')
      .off('cheerComment-delete');
    socket.disconnect();
  };

  useSocketHooks({
    params: { fileId, userId },
    connectSocket: connectSocketFile,
    listenSocket,
    removeListener,
  });
}

const useFileViewer = () => {
  const [openFileViewer, setOpenFileViewer] = useState(false);
  const [fileViewerObj, setFileViewerObj] = useState({});

  const handleOpenAndSetFileViewer = (file) => {
    const urlFile = file.url?.charAt(0) === '/'
      ? process.env.REACT_APP_PRIMARY_API_URL + file.url
      : file.url;

    const result = AttachmentActions.getMimeTypeUrl({ mimeType: file.mimeType, fileUrl: urlFile });

    if (!result?.isImage && !result?.isVideo && !result?.isPdf) {
      window.open(urlFile);
      return;
    }

    setFileViewerObj(file);
    setOpenFileViewer(true);
  };

  const handleCloseFileViewer = () => {
    setOpenFileViewer(false);
  };
  return {
    openFileViewer, fileViewerObj, handleOpenAndSetFileViewer, handleCloseFileViewer,
  };
};

export {
  useFileSocket,
  useFileCheerNCommentSocket,
  useFileViewer,
};
