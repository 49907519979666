import React, { memo } from 'react';
import ReactTimeAgo from 'react-time-ago';
import PropTypes from 'prop-types';
import { timeBeautifyFormatter, dateBeautifyFormatter } from '../../../utilities/dateUtil';

const TimeAgo = (
  {
    date,
    dateStyle,
    withoutOn,
    enableAlsoExactTimeWhenYesterday,
  },
) => {
  const newDate = new Date(date);

  const dateString = dateBeautifyFormatter(newDate, null, dateStyle);

  return (
    <>
      {dateString === 'Hari Ini' || dateString === 'Kemarin' ? (
        <>
          {dateString === 'Hari Ini'
            ? <span><ReactTimeAgo date={newDate} locale="id-ID" /></span>
            : (
              <>
                {enableAlsoExactTimeWhenYesterday && (
                <span>
                  Yesterday,
                  {' '}
                  {timeBeautifyFormatter(newDate)}
                </span>
                )}
                {!enableAlsoExactTimeWhenYesterday && <span>Yesterday</span>}
              </>
            )}
        </>
      )
        : (
          <>
            {withoutOn ? <span>{dateString}</span> : (
              <span>
                on
                {' '}
                {dateString}
              </span>
            )}
          </>
        )}
    </>
  );
};

TimeAgo.propTypes = {
  date: PropTypes.instanceOf(Date).isRequired,
  dateStyle: PropTypes.string.isRequired,
  withoutOn: PropTypes.bool.isRequired,
  enableAlsoExactTimeWhenYesterday: PropTypes.bool,
};

TimeAgo.defaultProps = {
  enableAlsoExactTimeWhenYesterday: false,
};

export default memo(TimeAgo);
