import React, { memo } from 'react';
import PropTypes from 'prop-types';
import FormControl from '@mui/material/FormControl';
import { Select } from '@mui/material';
import styles from './Main.module.css';

const InputSelectMain = ({
  value, onChange, label, children, name, id
}) => (
  <div className={styles.container}>
    <FormControl
      variant="standard"
    >
      <Select
        id={id || 'inputSelectMain'}
        value={value}
        name={name}
        onChange={onChange}
        label={label}
      >
        {children}
      </Select>
    </FormControl>
  </div>
);

InputSelectMain.propTypes = {
  value: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  label: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
  name: PropTypes.string.isRequired,
  id: PropTypes.string,
};

InputSelectMain.defaultProps = {
  value: '',
  id: undefined,
};
export default memo(InputSelectMain);
