import React from 'react';
import PropTypes from 'prop-types';
import styles from './Paper.module.css';

const Paper = React.memo(({ children }) => (
  <div className={styles.Paper}>
    {children}
  </div>
));

Paper.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Paper;
