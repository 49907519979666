import React, { useState, memo, useContext } from 'react';
import PropTypes from 'prop-types';
import { useMediaQuery } from '@mui/material';
import styles from './Card.module.css';
import ShowLimitMembers from '../../../../components/ShowLimitMembers/ShowLimitMembers';
import TeamCardMenu from './TeamCardMenu/TeamCardMenu';
import { InputButtonMore } from '../../../../components/UI_V2/Input/Button';
import LinkNoDecor from '../../../../components/LinkNoDecor/LinkNoDecor';
import { DisplayTextBody, DisplayTextSubHeadline } from '../../../../components/UI_V2/Display/Text';
import Color from '../../../../themes/colors';
import { cardColors } from '../../../../constants/TeamConstants';

function Card({
  team, companyId, isCanCreateTeam, index,
  teamsColor,
}) {
  const [showTeamMenu, setShowTeamMenu] = useState(false);
  const [showButtonMore, setShowButtonMore] = useState(false);
  const [hoverColor, setHoverColor] = useState('');

  function getColor(teamType, cardIndex) {
    if (!teamType) return '';

    const teamColor = teamsColor.find((elem) => elem.teamId === team._id);
    const color = teamColor?.color || '#7a7a7a';
    return color;
    // if (color) return color;

    // const colors = cardColors[teamType];
    // const colorIndex = cardIndex % colors.length;
    // return colors[colorIndex];
  }

  const color = getColor(team?.type, index);

  const handleCancelShowTeamMenu = () => {
    setShowTeamMenu(false);
  };

  const handleShowTeamMenu = () => {
    setShowTeamMenu(true);
  };

  const handleCancelShowButtonMore = () => {
    setShowButtonMore(false);
  };

  const onMouseOverAction = () => {
    setShowButtonMore(true);
    setHoverColor(color);
  };
  let { name, desc } = team;

  const isTablet = useMediaQuery('(max-width:1024px)');
  const isMobile = useMediaQuery('(max-width:720px)');

  if (name?.length >= 25 && !isMobile) {
    name = `${name.slice(0, 25)}...`;
  }

  if (desc?.length >= 45 && !isMobile) {
    desc = `${desc.slice(0, 45)}...`;
  }

  if (name?.length >= 20 && isMobile) {
    name = `${name.slice(0, 20)}...`;
  }

  if (desc?.length >= 25 && isMobile) {
    desc = `${desc.slice(0, 25)}...`;
  }

  const onMouseLeaveAction = () => {
    handleCancelShowButtonMore();
    handleCancelShowTeamMenu();
    setHoverColor('');
  };

  return (
    // eslint-disable-next-line jsx-a11y/mouse-events-have-key-events
    <div
      style={{
        backgroundColor: hoverColor,
      }}
      className={styles.Card}
      onMouseOver={onMouseOverAction}
      onMouseLeave={onMouseLeaveAction}
    >
      <LinkNoDecor to={`/companies/${companyId}/teams/${team._id}`}><div className={styles.link} /></LinkNoDecor>
      {showButtonMore && isCanCreateTeam && (
      <div className={styles.action}>
        <InputButtonMore colorVariant="dark" onClick={handleShowTeamMenu} />
        {showTeamMenu && (
        <TeamCardMenu
          team={team}
          cancel={handleCancelShowTeamMenu}
          isCanCreateTeam={isCanCreateTeam}
          customParams={{ companyId }}
        />
        )}
      </div>
      )}
      <div className={styles.text}>
        <div className={styles.cardTitle}>
          <DisplayTextSubHeadline
            color={hoverColor === '' ? Color.fontColor : Color.white}
            mode="20"
            decoration="extra_bold"
          >
            {name}
          </DisplayTextSubHeadline>
        </div>
        <DisplayTextBody
          color={hoverColor === '' ? Color.fontColor : Color.white}
        >
          {desc}

        </DisplayTextBody>
      </div>
      <div className={styles.members}>
        <ShowLimitMembers
          members={team.members}
          limit={isMobile ? 2 : 4}
          noPlusButton
          companyId={companyId}
          disableMemberOnlinePhoto
        />
      </div>
      <div className={styles.teamColorBar} style={{ background: color }} />
    </div>
  );
}

Card.propTypes = {
  team: PropTypes.object.isRequired,
  companyId: PropTypes.string.isRequired,
  isCanCreateTeam: PropTypes.bool.isRequired,
  index: PropTypes.number.isRequired,
  teamsColor: PropTypes.array,
};

Card.defaultProps = {
  teamsColor: [],
};

export default memo(Card);
