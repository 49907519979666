import React from 'react';
import PropTypes from 'prop-types';
import styles from './IconItem.module.css';

const IconItem = ({ clicked, icon }) => (
  <div className={styles.container} onClick={clicked}>
    {icon}
  </div>
);

IconItem.propTypes = {
  clicked: PropTypes.func,
  icon: PropTypes.node.isRequired,
};

IconItem.defaultProps = {
  clicked: () => null,
};

export default IconItem;
