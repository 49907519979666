import React, {
  memo, useCallback, useContext, useEffect, useState,
} from 'react';
import { useParams } from 'react-router-dom';
import PropTypes from 'prop-types';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { useSnackbar } from 'notistack';
import styles from './SelectMemberUserReport.module.css';
import Color from '../../../../themes/colors';
import { InputSearchGeneral } from '../../../../components/UI_V2/Input/Search';
import MemberItemUserReport from './MemberItemUserReport/MemberItemUserReport';
import { DisplayTextBody } from '../../../../components/UI_V2/Display/Text';
import { GlobalContext } from '../../../../contexts/GlobalStateProvider';
import handleLoadings from '../../../../utilities/handleLoadings';
import { TeamActions } from '../../../../actions';
import handleStatusMsg from '../../../../utilities/handleStatusMsg';
import { FeedbackLoadingMain } from '../../../../components/UI_V2/Feedback/Loading';
import SideUserReportSkeleton from '../SideUserReportSkeleton/SideUserReportSkeleton';
import { useSearch } from '../../../../hooks/HelperHooks';
import LinkNoDecor from '../../../../components/LinkNoDecor/LinkNoDecor';
import { role } from '../../../../constants/RoleConstants';

const SelectMemberUserReport = ({
  handleSetFilteredRoleMembers,
  variantUrlMember,
}) => {
  const [
    {
      currentTeam,
    },
    dispatch,
  ] = useContext(GlobalContext);
  const [loadings, setLoadings] = useState([]);
  const [isFirstLoad, setIsFirstLoad] = useState(true);
  const [members, setMembers] = useState([]);
  const { enqueueSnackbar } = useSnackbar();

  const {
    searchResult,
    keyword,
    keywordArray,
    setKeyword,
    isTyping,
    onChange,
    resetSearch,
  } = useSearch({ originalList: members });

  const params = useParams();

  const { companyId, teamId, memberId } = params;

  useEffect(() => {
    const startLoadings = handleLoadings('initiateTeamMembers', [...loadings], 'start');
    setLoadings([...startLoadings]);

    if (!currentTeam || currentTeam?._id !== teamId) {
      setIsFirstLoad(false);
      return;
    }

    if (isFirstLoad) {
      setIsFirstLoad(false);
      return;
    }

    const fetchTeamMembers = async () => {
      try {
        const resultMembers = await TeamActions.getCurrentTeamMembersBelowRole(
          {
            teamId,
            companyId,
            minimumRole: role.manager,
          },
        );

        setMembers(resultMembers);
        handleSetFilteredRoleMembers(resultMembers);
      } catch (error) {
        const status = handleStatusMsg(error, 'error');

        enqueueSnackbar(status.message, {
          variant: 'error',
        });
      } finally {
        const endLoadings = handleLoadings('initiateTeamMembers', [...loadings], 'end');
        setLoadings([...endLoadings]);
      }
    };

    fetchTeamMembers();
  }, [currentTeam]);

  const isMemberSelected = (member) => member._id === memberId;

  const getUrlClickMemberByVariant = useCallback(
    (companyIdValue, teamIdValue, memberIdValue, variantValue) => {
      let url;
      switch (variantValue) {
        case 'realtime':
          url = `/companies/${companyIdValue}/teams/${teamIdValue}/reports/members/${memberIdValue}?variantUrl=realtime`;
          break;
        case 'recapMonthWeekly':
          url = `/companies/${companyIdValue}/teams/${teamIdValue}/reports/members/${memberIdValue}?variantUrl=recapMonthWeekly`;
          break;
        default:
          break;
      }

      return url;
    }, [],
  );

  return (
    <div className={styles.container}>
      <div className={styles.title}>
        <DisplayTextBody
          decoration="bold"
        >
          Pilih Anggota :
        </DisplayTextBody>
      </div>
      <div className={styles.body}>
        <div className={styles.search}>
          <InputSearchGeneral
            onChange={onChange}
            value={keyword}
            placeholder="Cari anggota tim ini..."
            onClickCloseIcon={resetSearch}
            size="sm"
            autoFocus={false}
          />
        </div>
        <div className={styles.list}>
          <FeedbackLoadingMain
            loadings={loadings}
            wait="initiateTeamMembers"
            loadingComponent={<SideUserReportSkeleton />}
          >
            <>
              {searchResult?.length > 0 && searchResult?.map((member) => (
                <LinkNoDecor
                  to={getUrlClickMemberByVariant(
                    companyId,
                    teamId,
                    member._id,
                    variantUrlMember,
                  )}
                >
                  <MemberItemUserReport member={member} isSelected={isMemberSelected(member)} />
                </LinkNoDecor>
              ))}
              {searchResult < 1 && (
              <DisplayTextBody>
                Anggota ga ketemu
              </DisplayTextBody>
              )}
            </>
          </FeedbackLoadingMain>
        </div>
      </div>
    </div>
  );
};

SelectMemberUserReport.propTypes = {
  handleSetFilteredRoleMembers: PropTypes.func.isRequired,
  variantUrlMember: PropTypes.oneOf(['recapMonthWeekly', 'realtime']),
};

SelectMemberUserReport.defaultProps = {
  variantUrlMember: 'realtime',
};

export default memo(SelectMemberUserReport);
