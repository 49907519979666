const { differenceInDays, isBefore, isToday } = require('date-fns');

function getGreeting(date) {
  const hour = date.getHours();

  if (hour >= 5 && hour < 11) {
    return 'Selamat Pagi'; // Morning (5:00 AM to 10:59 AM)
  } if (hour >= 11 && hour < 15) {
    return 'Selamat Siang'; // Noon (11:00 AM to 2:59 PM)
  } if (hour >= 15 && hour < 18) {
    return 'Selamat Sore'; // Afternoon (3:00 PM to 5:59 PM)
  }
  return 'Selamat Malam'; // Evening/Night (6:00 PM onwards)
}

const getTodayDate = () => {
  const today = new Date();
  return [today, today];
};

const getYesterdayDate = () => {
  const today = new Date(); // Get today's date
  const yesterday = new Date(today); // Create a new Date object with today's date
  yesterday.setDate(today.getDate() - 1); // Subtract 1 day to get yesterday's date

  return [yesterday, yesterday];
};

const getThisWeekDate = () => {
  const today = new Date(); // Get today's date
  const startOfWeek = new Date(today); // Create a new Date object with today's date
  // Get the current day of the week (0 for Sunday, 1 for Monday, etc.)
  const dayOfWeek = today.getDay(1);

  // Calculate the difference between the current day and the first day of the week
  // (typically Sunday or Monday)
  // and subtract it from the current date to get the first day of the week
  startOfWeek.setDate(today.getDate() - dayOfWeek);

  return [startOfWeek, today];
};

const getPreviousWeekDate = () => {
  const today = new Date(); // Get today's date
  const startOfWeek = new Date(today); // Create a new Date object with today's date
  const endOfWeek = new Date(today);
  // Get the current day of the week (0 for Sunday, 1 for Monday, etc.)
  const dayOfWeek = today.getDay(1);

  // Calculate the first day of the previous week by subtracting the current day of the week
  // and then subtracting 6 days to go back to the start of the week
  startOfWeek.setDate(today.getDate() - dayOfWeek - 6);

  // Calculate the last day of the previous week by subtracting the current day of the week
  // without subtracting any days since we want to end at today's date
  endOfWeek.setDate(today.getDate() - dayOfWeek);

  return [startOfWeek, endOfWeek];
};

const getThisTwoWeekDate = () => {
  const today = new Date(); // Get today's date
  const endOfWeek = new Date(today); // Create a new Date object with today's date
  const startOfWeek = new Date(today);
  // Get the current day of the week (0 for Sunday, 1 for Monday, etc.)
  const dayOfWeek = today.getDay(1);

  // Calculate the last day of the two-week period by adding 13 days to today
  // endOfWeek.setDate(today.getDate() + 13);

  // Calculate the first day of the two-week period by subtracting the current day of the week
  // and then subtracting 13 days to match the end date
  startOfWeek.setDate(today.getDate() - dayOfWeek - 6);

  return [startOfWeek, endOfWeek];
};

const getPreviousTwoWeekDate = () => {
  const today = new Date(); // Get today's date
  const endOfWeek = new Date(today); // Create a new Date object with today's date
  const startOfWeek = new Date(today);
  // Get the current day of the week (0 for Sunday, 1 for Monday, etc.)
  const dayOfWeek = today.getDay(1);

  startOfWeek.setDate(today.getDate() - dayOfWeek - 20);
  endOfWeek.setDate(today.getDate() - dayOfWeek - 6);

  return [startOfWeek, endOfWeek];
};

const getThisMonthDate = () => {
  const today = new Date(); // Get today's date
  // Get the first date of the current month
  const fromDate = new Date(today.getFullYear(), today.getMonth(), 1);

  return [fromDate, today];
};

const getPreviousMonthDate = () => {
  const today = new Date(); // Get today's date
  // Get the first date of the previous month
  const fromDate = new Date(today.getFullYear(), today.getMonth() - 1, 1);
  // Get the last date of the previous month
  const lastDate = new Date(today.getFullYear(), today.getMonth(), 0);

  return [fromDate, lastDate];
};

const getThisQuarterDate = () => {
  const today = new Date(); // Get today's date
  // Calculate the start month of the current quarter
  const quarterStartMonth = Math.floor(today.getMonth() / 3) * 3;
  // Get the first date of the current quarter
  const fromDate = new Date(today.getFullYear(), quarterStartMonth, 1);

  return [fromDate, today];
};

const getPreviousQuarterDate = () => {
  const today = new Date(); // Get today's date
  const currentMonth = today.getMonth();
  // Calculate the current quarter (0, 1, 2, or 3)
  const currentQuarter = Math.floor(currentMonth / 3);
  // Calculate the start month of the previous quarter
  const prevQuarterStartMonth = (currentQuarter - 1) * 3;
  // Calculate the end month of the previous quarter
  const prevQuarterEndMonth = currentQuarter * 3 - 1;
  // Calculate the last day of the previous quarter
  const lastDayOfPrevQuarter = new Date(today.getFullYear(), prevQuarterEndMonth + 1, 0).getDate();

  // Get the first date of the previous quarter
  const fromDate = new Date(today.getFullYear(), prevQuarterStartMonth, 1);
  // Get the last date of the previous quarter
  const lastDate = new Date(today.getFullYear(), prevQuarterEndMonth, lastDayOfPrevQuarter);

  return [fromDate, lastDate];
};

const getThisYearDate = () => {
  const today = new Date(); // Get today's date
  const fromDate = new Date(today.getFullYear(), 0, 1); // Get the first date of the current year

  return [fromDate, today];
};

const getPreviousYearDate = () => {
  const today = new Date(); // Get today's date
  const lastYear = today.getFullYear() - 1; // Calculate the previous year
  const fromDate = new Date(lastYear, 0, 1); // Get the first date of the previous year
  const lastDate = new Date(lastYear, 11, 31); // Get the last date of the previous year

  return [fromDate, lastDate];
};

const convertDateStyle = (dateString, type) => {
  let result;
  switch (type) {
    case 'DayMonthDateYearTime':
      result = dateString.split(' ').slice(0, 5).join(' ');
      break;
    case 'DayMonthDateYear':
      result = dateString.split(' ').slice(0, 4).join(' ');
      break;

    case 'MonthDateYear':
      result = dateString.split(' ').slice(1, 4).join(' ');
      break;

    case 'MonthDate':
      result = dateString.split(' ').slice(1, 3).join(' ');
      break;

    default:
      result = dateString.split(' ').slice(0, 4).join(' ');
      break;
  }

  return result;
};

const isDateBeforeNow = (date, dateToCompareWith) => isBefore(
  new Date(date), new Date(dateToCompareWith),
);

const dateBeautifyFormatter = (date, dateOnly, dateStyle) => {
  let today = new Date();
  today = today.setHours(0, 0, 0, 0);

  let yesterday = new Date();
  yesterday.setUTCDate(yesterday.getUTCDate() - 1);
  yesterday = yesterday.setHours(0, 0, 0, 0);

  let tomorrow = new Date();
  tomorrow.setUTCDate(tomorrow.getUTCDate() + 1);
  tomorrow = tomorrow.setHours(0, 0, 0, 0);

  let compareDate = new Date(date);
  compareDate = compareDate.setHours(0, 0, 0, 0);

  let result;

  const options = {
    weekday: 'long', // Full weekday name (Senin)
    year: 'numeric', // Numeric year (2024)
    month: 'short', // Abbreviated month name (Feb)
    day: 'numeric', // Day of the month (7)
  };
  if (dateOnly) {
    result = new Date(date);
    result = result.toLocaleString('id-ID', options);
    result = convertDateStyle(result, dateStyle);
  } else if (compareDate === today) {
    result = 'Hari Ini';
  } else if (compareDate === yesterday) {
    result = 'Kemarin';
  } else if (compareDate === tomorrow) {
    result = 'Besok';
  } else {
    result = new Date(date);
    result = result.toLocaleString('id-ID', options);
    result = convertDateStyle(result, dateStyle);
  }

  return result;
};

const getFromUntilDateText = (fromDateValue, untilDateValue) => {
  const fromDateText = dateBeautifyFormatter(fromDateValue, true, 'MonthDate');
  const untilDateText = dateBeautifyFormatter(untilDateValue, true, 'MonthDate');
  const text = `${fromDateText} - ${untilDateText}`;
  return text;
};

const timeBeautifyFormatter = (date) => {
  let dateString;
  dateString = new Date(date);
  dateString = dateString.toString();

  const baseDate = new Date(date);
  const minute = baseDate.getMinutes();
  let hour = baseDate.getHours();
  const ampm = hour >= 12 ? 'PM' : 'AM';

  hour = (((hour + 11) % 12) + 1);
  const prefixHour = hour >= 10 ? '' : '0';
  const prefixMinute = minute >= 10 ? '' : '0';

  dateString = `${prefixHour + hour}:${prefixMinute}${minute} ${ampm}`;

  return dateString;
};

const daysBeautifyFormatterFromSchedule = (scheduleDays) => {
  const unsortedDays = [];

  scheduleDays.forEach((schedule) => {
    let dayId;

    switch (schedule) {
      case 'Sun':
        dayId = 0;
        break;
      case 'Mon':
        dayId = 1;
        break;
      case 'Tue':
        dayId = 2;
        break;
      case 'Wed':
        dayId = 3;
        break;
      case 'Thu':
        dayId = 4;
        break;
      case 'Fri':
        dayId = 5;
        break;
      case 'Sat':
        dayId = 6;
        break;
      default:
        //
    }

    unsortedDays.push(dayId);
  });

  const sortedDays = unsortedDays.sort();

  let tempDays = '';

  sortedDays.forEach((schedule) => {
    let dayName;

    switch (schedule) {
      case 0:
        dayName = 'Sun';
        break;
      case 1:
        dayName = 'Mon';
        break;
      case 2:
        dayName = 'Tue';
        break;
      case 3:
        dayName = 'Wed';
        break;
      case 4:
        dayName = 'Thu';
        break;
      case 5:
        dayName = 'Fri';
        break;
      case 6:
        dayName = 'Sat';
        break;
      default:
        //
    }

    tempDays = `${tempDays + dayName} `;
  });

  return tempDays;
};

const timeBeautifyFormatterFromSchedule = (scheduleHour, scheduleMinute) => {
  const UTCHour = scheduleHour;
  const UTCMinute = scheduleMinute;
  const today = new Date();
  const baseDate = new Date(Date.UTC(
    today.getUTCFullYear(),
    today.getUTCMonth(),
    today.getUTCDate(),
    UTCHour,
    UTCMinute,
    0,
  ));

  // turn into local time
  const minute = baseDate.getMinutes();
  let hour = baseDate.getHours();
  const ampm = hour >= 12 ? 'PM' : 'AM';
  hour = (((hour + 11) % 12) + 1);
  const prefixHour = hour >= 10 ? '' : '0';
  const prefixMinute = minute >= 10 ? '' : '0';

  const tempTime = `${prefixHour + hour}:${prefixMinute}${minute} ${ampm}`;

  return tempTime;
};

const handleCompareDate = (items, item, prevItemIndex, keyDateProperty = 'createdAt', keyObjDate) => {
  const prevItem = items[prevItemIndex];

  if (prevItem === undefined) return false;

  const dateStringItem = dateBeautifyFormatter(item?.[keyDateProperty]
    || item?.[keyObjDate]?.[keyDateProperty]);
  const dateStringPrevItem = dateBeautifyFormatter(prevItem?.[keyDateProperty]
        || prevItem?.[keyObjDate]?.[keyDateProperty]);

  // switch (compareBy) {
  //   case 'updatedAt':
  //     dateStringItem = dateBeautifyFormatter(item?.updatedAt || item[keyObjDate]?.updatedAt);
  //     dateStringPrevItem = dateBeautifyFormatter(prevItem?.updatedAt
  //       || prevItem[keyObjDate]?.updatedAt);
  //     break;
  //   case 'startDate':
  //     dateStringItem = dateBeautifyFormatter(item?.startDate || item[keyObjDate]?.startDate);
  //     dateStringPrevItem = dateBeautifyFormatter(prevItem?.startDate
  //       || prevItem[keyObjDate]?.startDate);
  //     break;

  //   default:
  //     dateStringItem = dateBeautifyFormatter(item?.createdAt || item[keyObjDate]?.createdAt);
  //     dateStringPrevItem = dateBeautifyFormatter(prevItem?.createdAt
  //       || prevItem[keyObjDate]?.createdAt);
  //     break;
  // }

  if (dateStringItem === dateStringPrevItem) {
    return true;
  }
  return false;
};

const handleCheckToday = (item, keyDateProperty = 'createdAt') => {
  const dateStringItem = dateBeautifyFormatter(item[keyDateProperty]);

  if (dateStringItem === 'Hari Ini') {
    return true;
  }
  return false;
};

const dateDifferenceInDays = (dateLeft, dateRight) => {
  if (!dateLeft || !dateRight) return null;

  return differenceInDays(new Date(dateLeft), new Date(dateRight));
};

const dateDiffInDays = (date1, date2) => {
  const dt1 = new Date(date1);
  const dt2 = new Date(date2);
  return Math.floor(
    (
      Date.UTC(
        dt2.getFullYear(),
        dt2.getMonth(),
        dt2.getDate(),
      ) - Date.UTC(
        dt1.getFullYear(),
        dt1.getMonth(),
        dt1.getDate(),
      )
    ) / (1000 * 60 * 60 * 24),
  );
};

const getISOWeekInMonth = (date) => {
  // Copy date so don't affect original
  const d = new Date(+date);
  // eslint-disable-next-line no-restricted-globals
  if (isNaN(d)) return;
  // Move to previous Monday
  d.setDate(d.getDate() - d.getDay() + 1);
  // Week number is ceil date/7
  // eslint-disable-next-line consistent-return
  return {
    month: +d.getMonth() + 1,
    week: Math.ceil(d.getDate() / 7),
  };
};

const isDayToday = (date) => isToday(new Date(date));

const calculateMonthday = (date, value, sign) => {
  // eslint-disable-next-line no-useless-catch
  try {
    const newDate = new Date(date);

    let result;
    switch (sign) {
      case 'add':
        result = new Date(newDate.setUTCDate(newDate.getUTCDate() + value));
        break;
      case 'subtract':
        result = new Date(newDate.setUTCDate(newDate.getUTCDate() - value));
        break;

      default:
        break;
    }

    return result;
  } catch (err) {
    throw err;
  }
};

function getFirstAndLastDateByMonth(monthNumber, yearNumber) {
  // Check if the monthNumber is valid (1 to 12)
  if (monthNumber < 0 || monthNumber > 11) {
    return 'Invalid month number. Please enter a number between 0 and 11.';
  }

  // Create a new Date object for the specified month and year
  const firstDate = new Date(yearNumber, monthNumber, 1); // Note: Month is zero-based
  // eslint-disable-next-line max-len
  const lastDate = new Date(yearNumber, monthNumber + 1, 0); // Setting the day to 0 gives the last day of the previous month

  return [
    firstDate,
    lastDate,
  ];
}

function getMonthName(monthNumber) {
  const months = [
    'Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun',
    'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec',
  ];

  // Check if the monthNumber is valid (0 to 11)
  if (monthNumber < 0 || monthNumber > 11) {
    return 'Invalid month number. Please enter a number between 1 and 12.';
  }

  return months[monthNumber];
}

function getMonthNumber(date) {
  return date.getMonth();
}

function getYearNumber(date) {
  return date.getFullYear();
}

function getBackwardMonths(monthNumber, yearNumber, iteration = 4) {
  const monthsArray = [];

  // eslint-disable-next-line no-plusplus
  for (let i = 0; i < iteration; i++) {
    if (monthNumber - i >= 0) {
      monthsArray.push({ month: monthNumber - i, year: yearNumber });
    } else {
      monthsArray.push({ month: 12 - (i - monthNumber), year: yearNumber - 1 });
    }
  }

  return monthsArray;
}

function getFirstAndLastDateWithSurplusThisMonth() {
  // Get current date
  const currentDate = new Date();

  // First day of the current month
  const firstDayOfMonth = new Date(currentDate.getFullYear(), currentDate.getMonth(), 1);

  // Last day of the current month
  const lastDayOfMonth = new Date(currentDate.getFullYear(), currentDate.getMonth() + 1, 0);

  // Subtracting 5 days from the first day of the current month
  const firstDateMinusFiveDays = new Date(firstDayOfMonth);
  firstDateMinusFiveDays.setDate(firstDateMinusFiveDays.getDate() - 5);

  // Adding 5 days to the last day of the current month
  const lastDatePlusFiveDays = new Date(lastDayOfMonth);
  lastDatePlusFiveDays.setDate(lastDatePlusFiveDays.getDate() + 5);

  return [
    firstDateMinusFiveDays,
    lastDatePlusFiveDays,
  ];
}

function determineDueStatus(dueDate, completeStatus) {
  // Convert dueDate to a Date object
  const newDate = new Date(dueDate);

  // Get today's date
  const today = new Date();
  const daysDiff = differenceInDays(newDate, today);

  // Determine status based on days difference
  if (completeStatus) return 'completed';

  if (daysDiff < 0) {
    return 'overdue';
  } if (daysDiff <= 3 && daysDiff >= 0) {
    return 'dueSoon';
  }
  return 'dueLater';
}

export {
  dateDifferenceInDays,
  dateDiffInDays,
  getISOWeekInMonth,
  handleCompareDate,
  handleCheckToday,
  dateBeautifyFormatter,
  timeBeautifyFormatter,
  timeBeautifyFormatterFromSchedule,
  daysBeautifyFormatterFromSchedule,
  isDateBeforeNow,
  isDayToday,
  calculateMonthday,
  getFromUntilDateText,
  getPreviousMonthDate,
  getPreviousQuarterDate,
  getPreviousWeekDate,
  getPreviousYearDate,
  getThisMonthDate,
  getThisQuarterDate,
  getThisWeekDate,
  getThisYearDate,
  getThisTwoWeekDate,
  getPreviousTwoWeekDate,
  getTodayDate,
  getYesterdayDate,
  getFirstAndLastDateByMonth,
  getMonthName,
  getMonthNumber,
  getBackwardMonths,
  getYearNumber,
  getFirstAndLastDateWithSurplusThisMonth,
  determineDueStatus,
  getGreeting,
};
