import React, {
  useState, useContext, useEffect, forwardRef,
} from 'react';
import _ from 'lodash';
import { useParams, useLocation } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import CloseIcon from '@mui/icons-material/Close';
import PropTypes from 'prop-types';
import { InputButtonMain } from '../../../components/UI_V2/Input/Button';
import { actionTypes } from '../../../reducers/reducer';
import { GlobalContext } from '../../../contexts/GlobalStateProvider';
import ImageMember from '../../../components/UI/ImageMember/ImageMember';
import styles from './CreateCommentForm.module.css';
import EditorFull from '../../FroalaEditor/EditorFull/EditorFull';
import extractElementBySelectorFromString from '../../../utilities/extractElementBySelectorFromString';
import handleStatusMsg from '../../../utilities/handleStatusMsg';
import handleLoadings from '../../../utilities/handleLoadings';
import apiUtil from '../../../utilities/apiUtil';
import { DisplayAvatarMember, DisplayAvatarMemberWithOnline } from '../../../components/UI_V2/Display/Avatar';
import { InputTextFieldMain } from '../../../components/UI_V2/Input/TextField';
import { TeamActions } from '../../../actions';
import { useIsTyping, useUpdateUserTypingStatus } from '../../../hooks/UserTypingHooks';

const CreateCommentForm = forwardRef(({
  type, parentIds, openForm, onOpenForm, socket, parentTeamId,
}, ref) => {
  const [{ user, currentTeam }, dispatch] = useContext(GlobalContext);
  const { enqueueSnackbar } = useSnackbar();
  const [loadings, setLoadings] = useState([]);
  const [postComment, setPostComment] = useState();
  const [editorModel, setEditorModel] = useState();
  const [trigger, setTrigger] = useState();

  const params = useParams();
  const location = useLocation();
  const { companyId } = params;
  const teamId = params.teamId || parentTeamId;

  const [isTyping, handleTyping] = useIsTyping({});
  useUpdateUserTypingStatus({ isTyping, socket, userId: user?._id });

  const handleCancel = () => {
    onOpenForm(false);
    setEditorModel();
  };

  useEffect(() => {
    if (postComment === undefined) {
      return;
    }

    const postApiComment = async () => {
      let id;
      let ids;
      let url;
      let commentId;

      switch (type) {
        case 'card':
          id = params.cardId || parentIds[0];
          url = `${process.env.REACT_APP_PRIMARY_API_URL}/api/v1/cards/${id}/comments`;
          break;
        case 'cardDiscussion':
          id = params.cardId || parentIds[0];
          commentId = params.commentId || parentIds[1];
          url = `${process.env.REACT_APP_PRIMARY_API_URL}/api/v1/cards/${id}/comments/${commentId}/discussions`;
          break;
        case 'blast':
          id = params.postId;
          url = `${process.env.REACT_APP_PRIMARY_API_URL}/api/v1/posts/${id}/comments`;
          break;
        case 'blastDiscussion':
          id = params.postId;
          commentId = params.commentId;
          url = `${process.env.REACT_APP_PRIMARY_API_URL}/api/v1/posts/${id}/comments/${commentId}/discussions`;
          break;
        case 'question':
          id = params.questionId;
          url = `${process.env.REACT_APP_PRIMARY_API_URL}/api/v1/questions/${id}/comments`;
          break;
        case 'questionDiscussion':
          id = params.questionId;
          commentId = params.commentId;
          url = `${process.env.REACT_APP_PRIMARY_API_URL}/api/v1/questions/${id}/comments/${commentId}/discussions`;
          break;
        case 'occurrence':
          ids = [params.eventId, params.occurrenceId];
          url = `${process.env.REACT_APP_PRIMARY_API_URL}/api/v1/events/${ids[0]}/occurrences/${ids[1]}/comments`;
          break;
        case 'occurrenceDiscussion':
          ids = [params.eventId, params.occurrenceId];
          commentId = params.commentId;
          url = `${process.env.REACT_APP_PRIMARY_API_URL}/api/v1/events/${ids[0]}/occurrences/${ids[1]}/comments/${commentId}/discussions`;
          break;
        case 'event':
          id = params.eventId;
          url = `${process.env.REACT_APP_PRIMARY_API_URL}/api/v1/events/${id}/comments/`;
          break;
        case 'eventDiscussion':
          id = params.eventId;
          commentId = params.commentId;
          url = `${process.env.REACT_APP_PRIMARY_API_URL}/api/v1/events/${id}/comments/${commentId}/discussions`;
          break;
        case 'doc':
          id = params.docId;
          url = `${process.env.REACT_APP_PRIMARY_API_URL}/api/v1/docs/${id}/comments/`;
          break;
        case 'docDiscussion':
          id = params.docId;
          commentId = params.commentId;
          url = `${process.env.REACT_APP_PRIMARY_API_URL}/api/v1/docs/${id}/comments/${commentId}/discussions`;
          break;
        case 'file':
          id = params.fileId;
          url = `${process.env.REACT_APP_PRIMARY_API_URL}/api/v1/files/${id}/comments/`;
          break;
        case 'fileDiscussion':
          id = params.fileId;
          commentId = params.commentId;
          url = `${process.env.REACT_APP_PRIMARY_API_URL}/api/v1/files/${id}/comments/${commentId}/discussions`;
          break;
        default:
          //
      }

      try {
        const startLoadings = handleLoadings('createComment', [...loadings], 'start');
        setLoadings([...startLoadings]);

        const result = await apiUtil.post(url, postComment, {
          params: {
            companyId,
            teamId,
          },
        });

        switch (type) {
          case 'card':
          // {
            // dispatch({
            //   type: actionTypes.SET_CURRENT_CARD,
            //   currentCard: result.data.card,
            // });
            // const fetchedBoard = _.cloneDeep(result.data.board);
            // const fetchedBoardLists = _.cloneDeep(fetchedBoard.lists);

            // dispatch({
            //   type: actionTypes.SET_CURRENT_BOARD,
            //   currentBoard: _.cloneDeep(fetchedBoard),
            // });

            // dispatch({
            //   type: actionTypes.SET_CURRENT_BOARD_LISTS,
            //   currentBoardLists: _.cloneDeep(fetchedBoardLists),
            // });
            // }
            break;
          case 'occurrenceDiscussion':
          case 'eventDiscussion':
          case 'docDiscussion':
          case 'questionDiscussion':
          case 'blastDiscussion':
          case 'fileDiscussion':
          case 'cardDiscussion':
            // dispatch({
            //   type: actionTypes.SET_CURRENT_COMMENT_DISCUSSIONS,
            //   currentCommentDiscussions: result.data.comment.discussions,
            // });
            break;
          case 'blast':
            // dispatch({
            //   type: actionTypes.SET_CURRENT_BLAST_POST,
            //   currentBlastPost: result.data.post,
            // });
            break;
          case 'question':
            // dispatch({
            //   type: actionTypes.SET_CURRENT_CHECK_IN_QUESTION,
            //   currentCheckInQuestion: result.data.question
            // });
            break;
          case 'occurrence':
            // dispatch({
            //   type: actionTypes.SET_CURRENT_OCCURRENCE,
            //   currentOccurrence: result.data.occurrence,
            // });
            break;
          case 'event':
            // dispatch({
            //   type: actionTypes.SET_CURRENT_SCHEDULE_EVENT,
            //   currentScheduleEvent: result.data.event,
            // });
            break;
          case 'doc':
            // dispatch({
            //   type: actionTypes.SET_CURRENT_BUCKET_DOC,
            //   currentBucketDoc: result.data.doc,
            // });
            break;
          case 'file':
            // dispatch({
            //   type: actionTypes.SET_CURRENT_BUCKET_FILE,
            //   currentBucketFile: result.data.file,
            // });
            break;

          default:
            //
        }

        const status = handleStatusMsg(result, 'success');

        enqueueSnackbar(status.message, {
          variant: 'success',
        });

        handleCancel();
      } catch (err) {
        const status = handleStatusMsg(err, 'error');

        enqueueSnackbar(status.message, {
          variant: 'error',
        });
      } finally {
        const endLoadings = handleLoadings('createComment', [...loadings], 'end');
        setLoadings([...endLoadings]);
      }
    };
    postApiComment();
  }, [postComment]);

  const handlePostComment = () => {
    // prevent double post
    const checkWait = loadings.filter((loading) => loading === 'createComment');
    if (checkWait.length > 0) return;

    // prevent empty post
    if (!editorModel) {
      handleCancel();
      return;
    }

    const mentionedUsers = extractElementBySelectorFromString(editorModel, '#mentioned-user');

    setPostComment({ content: editorModel, mentionedUsers, type });
  };

  useEffect(() => {
    if (trigger === undefined) return;
    handlePostComment();
  }, [trigger]);

  useEffect(() => {
    if (currentTeam.members && currentTeam?._id === teamId) return;
    const fetchApiTeamMembers = async () => {
      try {
        await TeamActions.initiateTeamMembers({
          teamId,
          companyId,
          currentTeam,
        }, dispatch);
      } catch (error) {
        const status = handleStatusMsg(error, 'error');

        enqueueSnackbar(status.message, {
          variant: 'error',
        });
      }
    };
    fetchApiTeamMembers();
  }, []);

  const handleTriggerPostComment = () => {
    setTrigger(Math.random());
  };

  const handleShowFormInput = () => {
    onOpenForm(true);
  };

  const handleEditorModelChange = (model) => {
    setEditorModel(model);
  };

  const customConfig = {
    zIndex: 15000,
    toolbarSticky: false,
    heightMax: 400,
    iframeStyleFiles: [styles],
  };

  return (
    <div ref={ref} className={styles.AddCommentContainer}>
      <div>
        <DisplayAvatarMember
          src={user?.photoUrl}
          userId={user._id}
          // prevLoc={location.pathname}
          size="md"
          marginRight="10px"
        />
      </div>
      <div className={styles.inputComment}>
        {!openForm ? (
          <InputTextFieldMain
            cursor="pointer"
            onClick={handleShowFormInput}
            className={styles.formInitial}
            placeholder="Tambahkan komentar baru..."
            variant="secondary"
            border="semiRounded"
          />
        ) : null}
        {openForm
          ? (
            <>
              <div className={styles.editorSection}>
                <div className={styles.borderEditor}>
                  <EditorFull
                    companyId={companyId}
                    model={editorModel}
                    onModelChange={handleEditorModelChange}
                    triggerLists={currentTeam.members}
                    type="comment"
                    handleAction={handleTriggerPostComment}
                    customConfig={type === 'card' ? customConfig : null}
                    handleTyping={handleTyping}
                  />
                </div>
              </div>
              <div className={styles.actionSection}>
                <InputButtonMain handleClick={handleCancel} variant="light">Batal</InputButtonMain>
                <InputButtonMain handleClick={handlePostComment} wait="createComment" loadings={loadings}>Kirim</InputButtonMain>
              </div>
            </>
          )
          : null}
      </div>
    </div>
  );
});

CreateCommentForm.propTypes = {
  type: PropTypes.string.isRequired,
  parentIds: PropTypes.array,
  openForm: PropTypes.bool.isRequired,
  onOpenForm: PropTypes.func.isRequired,
  socket: PropTypes.object.isRequired,
  parentTeamId: PropTypes.string,
};

CreateCommentForm.defaultProps = {
  parentIds: [],
  parentTeamId: undefined,
};

export default CreateCommentForm;
