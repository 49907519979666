import React, { memo } from 'react';
import AttachFileIcon from '@mui/icons-material/AttachFile';
import PropTypes from 'prop-types';
import { isEqual } from 'lodash';
import styles from './AttachmentIcon.module.css';

const AttachmentIcon = ({ attachments, clicked }) => (
  <>
    {attachments === undefined || attachments.length < 1 ? null : (
      <div onClick={clicked} className={styles.AttachmentIcon}>
        <AttachFileIcon />
        <p>{attachments.length}</p>
      </div>
    ) }
  </>
);

AttachmentIcon.propTypes = {
  attachments: PropTypes.array.isRequired,
  clicked: PropTypes.func.isRequired,
};

const isComponentDataEqual = (prevProps, nextProps) => {
  const { attachments } = nextProps;
  return isEqual(prevProps.attachments, attachments);
};

export default memo(AttachmentIcon, isComponentDataEqual);
