import React, {
  memo, useState, useCallback, useEffect,
} from 'react';
import PropTypes from 'prop-types';
import { Box } from '@mui/material';
import { useHistory } from 'react-router-dom';
import Color from '../../../../themes/colors';
import CompanyItem from './CompanyItem/CompanyItem';

const ListSelectCompany = ({
  list,
}) => {
  const history = useHistory();
  const goToCompany = (companyId) => {
    history.push(`/companies/${companyId}`);
  };
  return (
    <>
      {list?.map((company) => (
        <Box
          sx={{
            marginBottom: '5px',
          }}
        >
          <CompanyItem
            name={company.name}
            key={company._id}
            desc={company.desc}
            logo={company.logo}
            onClick={() => goToCompany(company._id)}
          />
        </Box>
      ))}
    </>
  );
};

ListSelectCompany.propTypes = {
  list: PropTypes.array.isRequired,
};

export default memo(ListSelectCompany);
