/* eslint-disable consistent-return */
import {
  useCallback,
  useState,
  createRef,
  useEffect,
} from 'react';
import queryString from 'query-string';
import { useLocation } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import { ChatActions } from '../actions';
import { ChatConstants } from '../constants';
import { connectSocketGroupChat } from '../services/socket';
import { useSocketHooks } from './SocketHooks';

function useGroupMessageSocket({ groupChatId, userId }, dispatch) {
  const callbackNewMessage = useCallback((message) => {
    ChatActions.incomingChat({
      message,
      categoryChat: ChatConstants.categoryChat.GROUP,
      typeAction: ChatConstants.typeCallback.NEW,
    }, dispatch);
  }, []);
  const callbackDeleteMessage = useCallback((message) => {
    ChatActions.incomingChat({
      message,
      categoryChat: ChatConstants.categoryChat.GROUP,
      typeAction: ChatConstants.typeCallback.DELETE,
    }, dispatch);
  }, []);
  const callbackUpdateMessage = useCallback((message) => {
    ChatActions.incomingChat({
      message,
      categoryChat: ChatConstants.categoryChat.GROUP,
      typeAction: ChatConstants.typeCallback.EDIT,
    }, dispatch);
  }, []);

  const listenSocket = (socket, params) => {
    socket
      .on(`groupChat-new-${params.groupChatId}`, callbackNewMessage)
      .on(`groupChat-delete-${params.groupChatId}`, callbackDeleteMessage)
      .on(`groupChat-update-${params.groupChatId}`, callbackUpdateMessage);
  };
  const removeListener = (socket, params) => {
    socket
      .off(`groupChat-new-${params.groupChatId}`)
      .off(`groupChat-delete-${params.groupChatId}`)
      .off(`groupChat-update-${params.groupChatId}`);
    socket.disconnect();
  };

  useSocketHooks({
    params: { groupChatId, userId },
    connectSocket: connectSocketGroupChat,
    listenSocket,
    removeListener,
  });
}

const useSearchAutoScrollMessage = ({
  list,
  keyQueryProperty,
  scrollDirection = 'toTop',
  isDisabled = false,
}) => {
  const [isMatch, setIsMatch] = useState(false);
  const [hasScrollToElement, setHasScrollToElement] = useState(false);
  const [hasScrollToLastIndex, setHasScrollToLastIndex] = useState(false);
  const location = useLocation();
  const query = queryString.parse(location.search);
  const searchId = query[keyQueryProperty];
  const { enqueueSnackbar } = useSnackbar();

  const lastIndexRef = createRef();
  const elementRef = createRef();

  const scrollDirectionVariant = {
    toTop: {
      // lastIndex: { behavior: 'smooth', block: 'end' },
      lastIndexWhenTooMuchHeight: { behavior: 'smooth', block: 'start' },
      element: { behavior: 'smooth', block: 'center' },
    },
    toBottom: {
      // lastIndex: { behavior: 'smooth', block: 'start' },
      lastIndexWhenTooMuchHeight: { behavior: 'smooth', block: 'end' },
      element: { behavior: 'smooth', block: 'center' },
    },
  };

  const scrollToLastIndex = () => {
    if (lastIndexRef && lastIndexRef.current) {
      const loaderElement = lastIndexRef.current.closest('.bodyInfinityScroll');
      if (loaderElement) {
        loaderElement.scrollIntoView(
          scrollDirectionVariant[scrollDirection].lastIndexWhenTooMuchHeight,
        );
      }
    } else {
      setHasScrollToLastIndex(!hasScrollToLastIndex);
    }
  };

  const scrollToElement = () => {
    if (elementRef && elementRef.current) {
      elementRef.current.scrollIntoView(scrollDirectionVariant[scrollDirection].element);
    } else {
      setHasScrollToElement(!hasScrollToElement);
    }
  };

  const executeScrollToElement = () => {
    if (isDisabled) return;
    if (isMatch) {
      setTimeout(() => {
        scrollToElement();
      }, [400]);
      setTimeout(() => {
        enqueueSnackbar('We found the message!', {
          variant: 'success',
          maxSnack: 1,
          preventDuplicate: true,
        });
      }, [800]);
    }
  };

  useEffect(() => {
    executeScrollToElement();
  }, [isMatch, hasScrollToElement]);

  const executeScrollToLastIndex = () => {
    if (isDisabled) return;
    if (!searchId) return;
    if (isMatch) return;
    if (searchId) {
      setTimeout(() => {
        enqueueSnackbar('Please wait, we\'are still finding the message', {
          variant: 'info',
          maxSnack: 1,
          preventDuplicate: true,
        });
      }, [800]);
    }
    setTimeout(() => {
      scrollToLastIndex();
    }, [400]);
  };

  useEffect(() => {
    executeScrollToLastIndex();
  }, [list, hasScrollToLastIndex]);

  const handleFoundMatch = () => {
    if (isMatch) return;
    setIsMatch(true);
  };

  return [elementRef, lastIndexRef, searchId, handleFoundMatch];
};

export {
  useGroupMessageSocket,
  useSearchAutoScrollMessage,
};
