import React, {
  memo,
} from 'react';
import { Skeleton } from '@mui/material';
import styles from './Post.module.css';

const BlastPageSkeletonPost = () => (
  // eslint-disable-next-line jsx-a11y/mouse-events-have-key-events
  <div
    className={styles.container}
  >
    <div className={styles.photoAndUser}>
      <div className={styles.photo}>
        <Skeleton
          animation="wave"
          variant="circular"
          width={30}
          height={30}
        />
      </div>
      <Skeleton
        animation="wave"
        variant="text"
        width={150}
      />
    </div>
    <div className={styles.contentSection}>
      <Skeleton
        animation="wave"
        variant="text"
      />
      <Skeleton
        animation="wave"
        variant="text"
      />
      <Skeleton
        animation="wave"
        variant="text"
      />
      <Skeleton
        animation="wave"
        variant="rectangular"
        height={40}
      />
    </div>
  </div>
);

export default memo(BlastPageSkeletonPost);
