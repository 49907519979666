import React from 'react';
import PropTypes from 'prop-types';
import { Add, KeyboardArrowDown, KeyboardArrowUp } from '@mui/icons-material';
import { ModalLink } from 'react-router-modal-gallery';
import styles from './HeaderSwitchCompany.module.css';
import { DisplayTextBody } from '../../../../components/UI_V2/Display/Text';
import Colors from '../../../../themes/colors';
import LinkNoDecor from '../../../../components/LinkNoDecor/LinkNoDecor';

const HeaderSwitchCompany = ({ handleClickCreate, company }) => (
  <div className={styles.container}>
    <div className={styles.dropdown}>
      {/* <div className={styles.dropdown__arrowDown}>
        {!isCollapsed ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
      </div> */}
      <div className={styles.dropdown__text}>
        <DisplayTextBody
          color={Colors.blueNavy3}
          decoration="bold"
          mode="11"
        >
          {company?.name}
        </DisplayTextBody>
      </div>
    </div>
    <LinkNoDecor to="/welcome/create-company">
      <div className={styles.plusButton} onClick={handleClickCreate}>
        <Add />
      </div>
    </LinkNoDecor>
  </div>
);

HeaderSwitchCompany.propTypes = {
  handleClickCreate: PropTypes.func.isRequired,
  company: PropTypes.object.isRequired,
};

export default HeaderSwitchCompany;
