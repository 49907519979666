import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { Skeleton } from '@mui/material';
import styles from './Box.module.css';
import { DisplayTextBody, DisplayTextHeadline, DisplayTextSubHeadline } from '../../../../components/UI_V2/Display/Text';
import Color from '../../../../themes/colors';
import { FeedbackLoadingMain } from '../../../../components/UI_V2/Feedback/Loading';
import { InputButtonMain } from '../../../../components/UI_V2/Input/Button';

const variantStyles = {
  leftBox: styles.leftBox,
  rightBox: styles.rightBox,
};

const SubscriptionStatusBox = ({
  title,
  mainText,
  children,
  variant,
  loadings,
  enableSubscriptionIndicator,
  isExpired,
  isSoon,
}) => (
  <div className={`${styles.container} ${variantStyles[variant]}`}>
    {enableSubscriptionIndicator && isExpired && (
    <div className={styles.statusIndicator}>
      <InputButtonMain
        variant="danger"
        disableHover
      >
        Berakhir
      </InputButtonMain>
    </div>
    )}

    {enableSubscriptionIndicator && !isExpired && !isSoon && (
    <div className={styles.statusIndicator}>
      <InputButtonMain
        variant="greenLight"
        disableHover
      >
        Aktif
      </InputButtonMain>
    </div>
    )}

    {enableSubscriptionIndicator && isSoon && (
    <div className={styles.statusIndicator}>
      <InputButtonMain
        variant="default"
        disableHover
      >
        Hampir Habis
      </InputButtonMain>
    </div>
    )}
    <div className={styles.title}>
      <DisplayTextBody
        mode="12"
        decoration="bold"
        color={Color.gray3}
        fontFamily="openSans"
      >
        {title}
      </DisplayTextBody>
    </div>
    <div className={styles.mainText}>
      <FeedbackLoadingMain
        wait="billingSubscriptionStatus"
        loadings={loadings}
        loadingComponent={<Skeleton variant="rounded" height={19} width={200} />}
      >
        <DisplayTextSubHeadline
          mode="28"
          decoration="bold"
          color={Color.blueNavy3}
        >
          {mainText}
        </DisplayTextSubHeadline>
      </FeedbackLoadingMain>
    </div>
    <div className={styles.customSection}>
      {children}
    </div>
  </div>
);

SubscriptionStatusBox.propTypes = {
  title: PropTypes.string.isRequired,
  mainText: PropTypes.string.isRequired,
  children: PropTypes.node,
  variant: PropTypes.oneOf(['leftBox', 'rightBox']),
  loadings: PropTypes.array.isRequired,
  isExpired: PropTypes.bool.isRequired,
  isSoon: PropTypes.bool.isRequired,
  enableSubscriptionIndicator: PropTypes.bool.isRequired,
};

SubscriptionStatusBox.defaultProps = {
  children: <></>,
  variant: 'leftBox',
};

export default memo(SubscriptionStatusBox);
