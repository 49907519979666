import React, {
  memo, useContext,
} from 'react';
import PropTypes from 'prop-types';
import { ScrollMenu } from 'react-horizontal-scrolling-menu';
import { LeftArrow, RightArrow } from './Arrow/Arrow';
import { GlobalContext } from '../../../contexts/GlobalStateProvider';
import styles from './NotificationsCategoryContainer.module.css';
import CategoryTabNotif from './CategoryTabNotif/CategoryTabNotif';

const wrapperStyles = {
  all: styles.containerAll,
  popUp: styles.container,
};

const NotificationsCategoryContainer = ({
  handleSelectTab,
  selectedTab,
  listCounterTeams,
  type,
}) => {
  const [{ user, counterNotif }, dispatch] = useContext(GlobalContext);
  const defaultTab = {
    team: {
      _id: 'all',
      name: 'Semua',
    },
    unreadNotification: counterNotif.nonChat,
  };
  const isItemSelected = (id) => !!selectedTab.includes(id);
  // // eslint-disable-next-line no-restricted-syntax
  // for (const value of listCounterTeams) {
  //   // eslint-disable-next-line react-hooks/rules-of-hooks
  //   useNotificationCounterTeamSocket({ userId: user?._id, teamId: value?.team?._id }, dispatch);
  // }

  return (
    <ScrollMenu
      wrapperClassName={wrapperStyles[type]}
      scrollContainerClassName={styles.disableScrollbar}
      LeftArrow={<LeftArrow typeNotif={type} />}
      RightArrow={<RightArrow typeNotif={type} />}
    >
      <CategoryTabNotif
        name={defaultTab?.team?.name}
        counter={defaultTab?.unreadNotification}
        key={defaultTab?.team?._id}
        itemId={defaultTab?.team?._id}
        isFirstItem
        onClick={() => handleSelectTab(defaultTab?.team?._id)}
        selected={isItemSelected(defaultTab?.team?._id)}
      />
      {listCounterTeams.map((value) => {
        if (value.unreadNotification < 1 && !isItemSelected(value?.team?._id)) return null;
        return (
          <CategoryTabNotif
            name={value?.team?.name}
            counter={value?.unreadNotification}
            key={value?.team?._id}
            itemId={value?.team?._id}
            isFirstItem={false}
            onClick={() => handleSelectTab(value?.team?._id)}
            selected={isItemSelected(value?.team?._id)}
          />
        );
      })}
    </ScrollMenu>
  );
};

NotificationsCategoryContainer.propTypes = {
  type: PropTypes.oneOf(['popup', 'all']),
  listCounterTeams: PropTypes.array.isRequired,
  handleSelectTab: PropTypes.func.isRequired,
  selectedTab: PropTypes.string.isRequired,
};

NotificationsCategoryContainer.defaultProps = {
  type: 'popup',
};

export default memo(NotificationsCategoryContainer);
