import React from 'react';
import PropTypes from 'prop-types';
import styles from './DayBox.module.css';
import ButtonDiv from '../Button/ButtonDiv/ButtonDiv';

const DayBox = ({ name, clicked, selected }) => (
  <ButtonDiv
    onClick={clicked}
    className={selected ? `${styles.container} ${styles.color}` : styles.container}
  >
    {name}
  </ButtonDiv>
);

DayBox.propTypes = {
  name: PropTypes.string.isRequired,
  clicked: PropTypes.func.isRequired,
  selected: PropTypes.bool.isRequired,
};

export default DayBox;
