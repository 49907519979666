import React, { memo, useState, useCallback } from 'react';
import { useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import styles from './MenuAdmin.module.css';
import { DisplayTextBody, DisplayTextSubHeadline } from '../../../components/UI_V2/Display/Text';
import Color from '../../../themes/colors';
import introImage from '../../../assets/PrivateChatPage/intro-chat-section.png';
import { InputButtonMain } from '../../../components/UI_V2/Input/Button';
import logoImage from '../../../assets/Logo/logo-new.png';
import LinkNoDecor from '../../../components/LinkNoDecor/LinkNoDecor';

const MenuAdmin = ({
  activePage,
}) => {
  const isMetricPage = activePage === 'metricPage';
  const isCompanyListPage = activePage === 'companyListPage';
  const isTractionPage = activePage === 'tractionPage';
  const isInsightPage = activePage === 'insightPage';
  const isCompanyDetailPage = activePage === 'companyDetailPage';

  return (
    <div className={styles.container}>
      <div className={styles.brand}>
        <div className={styles.logo}>
          <LazyLoadImage
            alt="logo"
            src={logoImage}
          />
        </div>
        <div className={styles.text}>
          <DisplayTextBody decoration="bold">
            Administrator
          </DisplayTextBody>
        </div>
      </div>
      <div className={styles.menu}>
        <div className={styles.menuItem}>
          <LinkNoDecor to="/administrators/reports/tractions">
            <InputButtonMain
              variant={isTractionPage ? 'default' : 'outlinedDefault'}
              size="sm"
            >
              Traction
            </InputButtonMain>
          </LinkNoDecor>
        </div>
        <div className={styles.menuItem}>
          <LinkNoDecor to="/administrators/reports/metrics">
            <InputButtonMain
              variant={isMetricPage ? 'default' : 'outlinedDefault'}
              size="sm"
            >
              Metrics
            </InputButtonMain>
          </LinkNoDecor>
        </div>
        <div className={styles.menuItem}>
          <LinkNoDecor to="/administrators/reports/companies">
            <InputButtonMain
              variant={isCompanyListPage || isCompanyDetailPage ? 'default' : 'outlinedDefault'}
              size="sm"
            >
              Company
            </InputButtonMain>
          </LinkNoDecor>
        </div>
        {/* <div className={styles.menuItem}>
          <LinkNoDecor to="/administrators/reports/insights">
            <InputButtonMain
              variant={isInsightPage ? 'default' : 'outlinedDefault'}
              size="sm"
            >
              Insight
            </InputButtonMain>
          </LinkNoDecor>
        </div> */}
      </div>
    </div>
  );
};

MenuAdmin.propTypes = {
  activePage: PropTypes.string.isRequired,
};

export default memo(MenuAdmin);
