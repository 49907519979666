import React, { useState } from 'react';
import PropTypes from 'prop-types';
import OutsideClickHandler from 'react-outside-click-handler';
import { useMediaQuery } from '@mui/material';
import styles from './CompaniesMenuContainer.module.css';
import BoxPopOver from '../../components/UI/BoxPopOver/BoxPopOver';
import SwitchCreateCompany from './SwitchCreateCompany/SwitchCreateCompany';
import { SurfacePaperFloating } from '../../components/UI_V2/Surface/Paper';

const CompaniesMenuContainer = ({ cancel, left }) => {
  const [isOutsideClickDisabled, setIsOutsideClickDisabled] = useState(false);

  const handleSetOutsideClickDisabled = (value) => {
    setIsOutsideClickDisabled(value);
  };

  const boxCustomStyle = {
    width: '226px',
    padding: '0px',
  };

  const isMobile = useMediaQuery('(max-width:720px)');

  // if (isMobile) {
  //   boxCustomStyle.right = '-4rem';
  // }

  return (
    <SurfacePaperFloating
      customStyle={boxCustomStyle}
      onOutsideClick={cancel}
      disabled={isOutsideClickDisabled}
      size="sm"
      position="topLeft"
    >
      <div className={styles.companySection}>
        <SwitchCreateCompany
          cancel={cancel}
          handleDisableOutsideClick={handleSetOutsideClickDisabled}
        />
      </div>
    </SurfacePaperFloating>
  );
};

CompaniesMenuContainer.propTypes = {
  left: PropTypes.bool.isRequired,
  cancel: PropTypes.func.isRequired,
};

export default CompaniesMenuContainer;
