import React, {
  memo, useContext, useEffect, useState,
} from 'react';
import PropTypes from 'prop-types';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { useMediaQuery } from '@mui/material';
import { useParams } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import styles from './ListTabs.module.css';
import Color from '../../../../themes/colors';
import { pageProperties, pageType } from '../../../../constants/PageConstants';
import Tab from '../../MiddleNavBar/ListTabs/Tab/Tab';
import { NavHeaderConstants } from '../../../../constants';
import { GlobalContext } from '../../../../contexts/GlobalStateProvider';
import handleStatusMsg from '../../../../utilities/handleStatusMsg';
import handleLoadings from '../../../../utilities/handleLoadings';
import { TeamActions } from '../../../../actions';
import LinkNoDecor from '../../../LinkNoDecor/LinkNoDecor';

const ListTabs = ({
  overviewOptionId,
}) => {
  const [menus, setMenus] = useState([]);
  const params = useParams();
  const { companyId } = params;

  useEffect(() => {
    const newMenus = NavHeaderConstants.menusMyTaskCard.map((menu) => {
      let urlId = '';
      const firstUrl = `/companies/${companyId}`;
      switch (menu._id) {
        case 'myTasksList':
          urlId = 'my-tasks/list/all';
          break;
        case 'myTasksCalendar':
          urlId = 'my-tasks/calendar/all';
          break;
        default:
          break;
      }

      const url = `${firstUrl}/${urlId}`;
      const newMenu = {
        ...menu,
        url,
      };

      return newMenu;
    });

    setMenus(newMenus);
  }, []);
  return (
    <div className={styles.container}>
      {menus.map((menu) => (
        <LinkNoDecor to={menu.url}>
          <div className={styles.tab}>
            <Tab isActive={overviewOptionId === menu._id} text={menu.title} icon={menu.icon} />
          </div>
        </LinkNoDecor>
      ))}
    </div>
  );
};

ListTabs.propTypes = {
  overviewOptionId: PropTypes.string,
};

ListTabs.defaultProps = {
  overviewOptionId: undefined,
};

export default memo(ListTabs);
