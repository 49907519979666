import React, {
  useState, memo, useContext,
} from 'react';
import PropTypes from 'prop-types';
import { useHistory, useParams, useLocation } from 'react-router-dom';
import _ from 'lodash';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { InputButtonMain } from '../../../components/UI_V2/Input/Button';
import styles from './TabEditCompanyContainer.module.css';
import EditCompanyContainer from './EditCompanyContainer/EditCompanyContainer';
import LogoCompanyContainer from './LogoCompanyContainer/LogoCompanyContainer';
import { DisplayTextHeadline, DisplayTextSubHeadline } from '../../../components/UI_V2/Display/Text';
import { adjustServerUrl } from '../../../utilities/stringUtil';
import { GlobalContext } from '../../../contexts/GlobalStateProvider';

const containerWithSidebarStyles = {
  true: `${styles.container} ${styles.sidebarOpen}`,
  false: styles.container,
};

const sectionCompanyLogoWithSidebarStyles = {
  true: `${styles.sectionCompanyLogo} ${styles.sectionCompanyLogoSidebarOpen}`,
  false: styles.sectionCompanyLogo,
};

const sectionCompanyInfoWithSidebarStyles = {
  true: `${styles.sectionCompanyInfo} ${styles.sectionCompanyInfoSidebarOpen}`,
  false: styles.sectionCompanyInfo,
};

const TabEditCompanyContainer = ({ isUserAdminOrAbove, isUserSuper, company }) => {
  const [{ isSidebarOpen }] = useContext(GlobalContext);
  const [showEditCompany, setShowEditCompany] = useState(false);

  const params = useParams();
  const history = useHistory();
  const location = useLocation();

  const handleShowEditCompany = () => {
    setShowEditCompany(true);
  };

  const handleCancelShowEditCompany = () => {
    setShowEditCompany(false);
  };

  let logoSource;
  if (company?.logo) {
    logoSource = adjustServerUrl(company.logo);
  }

  return (
    <div className={containerWithSidebarStyles[isSidebarOpen]}>
      <div className={styles.sectionTitleLogo}>
        <div className={sectionCompanyLogoWithSidebarStyles[isSidebarOpen]}>
          <div className={styles.logo}>
            {isUserAdminOrAbove && <LogoCompanyContainer company={company} />}
            {!isUserAdminOrAbove && <LazyLoadImage src={logoSource} className={styles.logo} />}
          </div>
        </div>
        <div className={sectionCompanyInfoWithSidebarStyles[isSidebarOpen]}>
          {showEditCompany
            ? (
              <EditCompanyContainer
                company={company}
                cancel={handleCancelShowEditCompany}
                isUserSuper={isUserSuper}
              />
            )
            : (
              <>
                <div className={styles.companyTitle}>
                  <DisplayTextHeadline
                    decoration="bold"
                  >
                    {company.name}
                  </DisplayTextHeadline>
                </div>
                <div>
                  <DisplayTextSubHeadline
                    mode="14"
                  >
                    {company.desc}
                  </DisplayTextSubHeadline>
                </div>
                {isUserAdminOrAbove && (
                <div className={styles.buttonSection}>
                  <InputButtonMain handleClick={handleShowEditCompany} border="rounded" wide="wider">Ubah Perusahaan</InputButtonMain>
                </div>
                )}
              </>
            )}
        </div>
      </div>
    </div>
  );
};

TabEditCompanyContainer.propTypes = {
  company: PropTypes.object.isRequired,
  isUserAdminOrAbove: PropTypes.bool.isRequired,
  isUserSuper: PropTypes.bool.isRequired,
};

export default memo(TabEditCompanyContainer);
