import React, { memo, useState } from 'react';
import PropTypes from 'prop-types';
import DownloadIcon from '@mui/icons-material/Download';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { useMediaQuery } from '@mui/material';
import styles from './BoxTextMultiline.module.css';
import Color from '../../../themes/colors';
import { DisplayTextBody } from '../../../components/UI_V2/Display/Text';
import { limitChar } from '../../../utilities/stringUtil';

const BoxTextMultiline = ({ text }) => (
  <div className={styles.container}>
    <DisplayTextBody
      position="center"
      color={Color.gray3}
    >
      {limitChar(text, 150)}
    </DisplayTextBody>
  </div>
);

BoxTextMultiline.propTypes = {
  text: PropTypes.string.isRequired,
};

BoxTextMultiline.defaultProps = {};

export default memo(BoxTextMultiline);
