/* eslint-disable no-return-await */
import React, { useState, useContext } from 'react';
import CloseIcon from '@mui/icons-material/Close';
import { useSnackbar } from 'notistack';
import { useParams, useHistory, useLocation } from 'react-router-dom';
import EditIcon from '@mui/icons-material/Edit';
import PropTypes from 'prop-types';
import BoxPopOver from '../../../components/UI/BoxPopOver/BoxPopOver';
import SeparatorLine from '../../../components/UI/SeparatorLine/SeparatorLine';
import styles from './QuestionMenu.module.css';
import OverlayButton from '../../../components/UI/Button/OverlayButton/OverlayButton';
import { GlobalContext } from '../../../contexts/GlobalStateProvider';
import handleStatusMsg from '../../../utilities/handleStatusMsg';
import handleLoadings from '../../../utilities/handleLoadings';
import { QuestionActions } from '../../../actions';

const QuestionMenu = ({ question, cancel }) => {
  const [{ }, dispatch] = useContext(GlobalContext);
  const { enqueueSnackbar } = useSnackbar();
  const [loadings, setLoadings] = useState([]);

  const history = useHistory();
  const location = useLocation();
  const params = useParams();
  const { companyId, teamId } = params;

  const archivedApiQuestion = async (questionId) => {
    try {
      const startLoadings = handleLoadings('questionMenu', [...loadings], 'start');
      setLoadings([...startLoadings]);

      const result = await QuestionActions.archiveQuestion(
        { questionId, teamId, companyId }, dispatch,
      );

      const status = handleStatusMsg(result, 'success');

      enqueueSnackbar(status.message, {
        variant: 'success',
      });

      cancel();
    } catch (err) {
      const status = handleStatusMsg(err, 'error');

      enqueueSnackbar(status.message, {
        variant: 'error',
      });
    } finally {
      const endLoadings = handleLoadings('questionMenu', [...loadings], 'end');
      setLoadings([...endLoadings]);
    }
  };

  const handleArchivedQuestion = async (questionId) => {
    await archivedApiQuestion(questionId);
  };

  const handleEditQuestion = (questionId) => {
    history.push(`${location.pathname}/edit`);
  };

  return (
    <BoxPopOver>
      <div className={styles.headerSection}>
        <h1>
          Menu
        </h1>
        <CloseIcon onClick={cancel} />
      </div>
      <SeparatorLine />
      <div className={styles.bodySection}>
        <OverlayButton wait="questionMenu" loadings={loadings}>
          <div onClick={() => handleEditQuestion(question._id)} className={styles.menu}>
            <EditIcon />
            <p>Ubah</p>
          </div>
          {question.archived ? (
            <>
              {question.archived.status ? (
                <div className={styles.menu}>
                  <p>Pertanyaan ini udah terarsip</p>
                </div>
              ) : (
                <div
                  onClick={async () => await handleArchivedQuestion(question._id)}
                  className={styles.menu}
                >
                  <p>Arsipkan pertanyaan ini</p>
                </div>
              )}
            </>
          ) : null}
        </OverlayButton>
      </div>
      {/* <div className={styles.actionSection}>
        <Button variant="danger" size="sm" block>Delete</Button>
      </div> */}
    </BoxPopOver>
  );
};

QuestionMenu.propTypes = {
  question: PropTypes.object.isRequired,
  cancel: PropTypes.func.isRequired,
};

export default QuestionMenu;
