import React, { memo } from 'react';
import PropTypes from 'prop-types';
import styles from './LoadingWithAnimation.module.css';
import loadingGif from '../../../assets/CheckLoginPage/loading-login.gif';

const LoadingWithAnimation = ({ children }) => (
  <div className={styles.LoadingWithAnimation}>
    <div className={styles.LoadingWithAnimation__container}>
      <div className={styles.LoadingWithAnimation__text}>
        {children}
      </div>
      <img src={loadingGif} />
    </div>
  </div>
);

LoadingWithAnimation.propTypes = {
  children: PropTypes.node.isRequired,
};

export default memo(LoadingWithAnimation);
