import { enqueueSnackbar } from 'notistack';
import { useContext, useState } from 'react';
import OneSignal from 'react-onesignal';
import { UserActions } from '../actions';
import { GlobalContext } from '../contexts/GlobalStateProvider';

const useLogoutUser = () => {
  const [{
    user,
  }, dispatch] = useContext(GlobalContext);
  const [isLoadingLogout, setIsLoadingLogout] = useState(false);
  const handleLogout = async () => {
    enqueueSnackbar(
      'Tunggu ya, sedang memproses logout...', {
        variant: 'info',
        autoHideDuration: null,
      },
    );
    setIsLoadingLogout(true);
    await UserActions.handleLogout({ OneSignal, userId: user?._id });
  };

  return {
    handleLogout,
    isLoadingLogout,
  };
};

export {
  useLogoutUser,
};
