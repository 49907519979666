import React, { memo, useState } from 'react';
import PropTypes from 'prop-types';
import { Box } from '@mui/material';
import EditBlastPostPageSkeletonHeader from './Header/Header';
import EditBlastPostPageSkeletonBody from './Body/Body';
import { DisplayDividerMain } from '../../../components/UI_V2/Display/Divider';
import Color from '../../../themes/colors';

const EditBlastPostPageSkeleton = () => (
  <Box>
    <EditBlastPostPageSkeletonHeader />
    <DisplayDividerMain borderColor={Color.gray4} />
    <EditBlastPostPageSkeletonBody />
  </Box>
);

export default memo(EditBlastPostPageSkeleton);
