import React, {
  useContext, memo,
} from 'react';
import VerifiedUserIcon from '@mui/icons-material/VerifiedUser';
import { useParams } from 'react-router-dom';
import { useMediaQuery } from '@mui/material';
import { GlobalContext } from '../../contexts/GlobalStateProvider';
import styles from './GroupChatPage.module.css';
import Title from '../../components/Title/Title';
import { generateRoutesAndTitle } from '../../actions/BreadCrumbActions';
import GeneralSubNavBar from '../../components/GeneralSubNavBar/GeneralSubNavBar';
import MainGroupChatSection from './MainGroupChatSection/MainGroupChatSection';
import SideBarGroupChatSection from './SideBarGroupChatSection/SideBarGroupChatSection';
import { SearchJumpToActions } from '../../actions';
import { PageConstants } from '../../constants';
import ShowLimitMembers from '../../components/ShowLimitMembers/ShowLimitMembers';
import { useDelayShowHideHandler } from '../../hooks/HelperHooks';
import { useInitiateRoleUser } from '../../hooks/RoleHooks';
import { roleTypeRef, typeRef } from '../../constants/RoleConstants';
import GlobalActionButton from '../../components/GeneralSubNavBar/BottomNavBar/GlobalActionButton/GlobalActionButton';
import MyRoleUser from '../../components/Container/MyRoleUser/MyRoleUser';
import MemberOnlineModal from '../../components/Container/MemberOnlineModal/MemberOnlineModal';

const containerWithSidebarStyles = {
  true: `${styles.container} ${styles.sidebarOpen}`,
  false: styles.container,
};

function GroupChatPage() {
  const [{
    user, currentTeam,
    currentCompany,
    isSidebarOpen,
  }, dispatch] = useContext(GlobalContext);
  const params = useParams();
  // const [
  //   showModalRoleUser,
  //   handleShowModalRoleUser,
  //   handleHideModalRoleUser,
  // ] = useDelayShowHideHandler();

  const { companyId, teamId, groupChatId } = params;

  useInitiateRoleUser({
    payload: {
      teamId,
      featureType: typeRef.groupChat,
      featureId: groupChatId,
    },
  });

  const isTablet = useMediaQuery('(max-width:1024px)');
  const isMobile = useMediaQuery('(max-width:720px)');

  const [
    showMemberOnlineModal,
    handleShowMemberOnlineModal,
    handleHideMemberOnlineModal,
  ] = useDelayShowHideHandler();

  const pageType = PageConstants.pageType.groupChats;
  const breadcrumb = generateRoutesAndTitle({ type: pageType, data: { currentTeam, companyId } });
  SearchJumpToActions.saveRecentPage({
    company: currentCompany,
    team: currentTeam,
    userId: user?._id,
    itemId: groupChatId,
    url: breadcrumb.url,
    routes: breadcrumb.routes,
    name: breadcrumb.title,
    type: pageType,
  });
  return (
    <>
      <Title title={currentTeam.name === undefined ? 'Chat Grup' : `Chat Grup ${currentTeam.name}`} />
      <GeneralSubNavBar
        breadcrumbRoutes={breadcrumb.routes}
        pageTitle={breadcrumb.title}
        withBottomNavBar={false}
        bottomBarOverviewOptionId="group-chats"
//         bottomBarGlobalActions={(
//           <>
//             {
//             currentTeam?._id === teamId
//             && (
//             <GlobalActionButton
//               handleClick={handleShowModalRoleUser}
//               text="My Role Here"
//               icon={<VerifiedUserIcon />}
//             />
//           )
// }
//           </>
//         )}
        bottomBarOtherActions={(
          <>
            {currentTeam.members && isMobile
              && (
                <ShowLimitMembers
                  members={currentTeam?.members}
                  limit={2}
                  companyId={companyId}
                  noPlusButton
                  positionPopUpOnlineOnPhoto="topRight"
                  handleClickButton={handleShowMemberOnlineModal}
                />
              )}
            {currentTeam.members && isMobile && showMemberOnlineModal
            && (
            <MemberOnlineModal
              open={showMemberOnlineModal}
              onClose={handleHideMemberOnlineModal}
              members={currentTeam.members}
              title={currentTeam.name}
            />
            )}
          </>
        )}
      />
      <div className={containerWithSidebarStyles[isSidebarOpen]}>
        <MainGroupChatSection />
        {!isMobile && <SideBarGroupChatSection />}
        {/* <MyRoleUser
          open={showModalRoleUser}
          onClose={handleHideModalRoleUser}
          featureTitle={currentTeam?.name}
          featureType={typeRef.groupChat}
          featureId={groupChatId}
          roleType={roleTypeRef.team}
        /> */}
      </div>
    </>
  );
}

export default memo(GroupChatPage);
