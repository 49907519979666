const keyFields = [
  'blast',
  'checkIn',
  'groupChat',
  'boards',
  'schedule',
  'bucket',
];

const teamType = {
  team: 'Tim',
  hq: 'HQ',
  project: 'Proyek',
};

const typeCallback = {
  NEW: 'new',
  DELETE: 'delete',
  EDIT: 'edit',
};

const typeOverview = {
  BLAST: 'blast',
  BOARD: 'boards',
  SCHEDULE: 'schedule',
  CHECK_IN: 'checkIn',
  DOCS_FILES: 'bucket',
  GROUP_CHAT: 'groupChat',
};

const limitTeams = 10;

const cardColors = {
  hq: [
    '#0880AE', // repeat
    '#E2007C',
    '#93D144',
    '#F0B418',
    '#FA78CD',
    '#32B4FD',
    '#535c68',
    '#FD6332',
    '#03C4A1',
    '#0880AE', // repeat
    '#E2007C',
    '#93D144',
    '#F0B418',
    '#FA78CD',
    '#32B4FD',
    '#535c68',
    '#FD6332',
    '#03C4A1',
    '#0880AE', // repeat
    '#E2007C',
    '#93D144',
    '#F0B418',
    '#FA78CD',
    '#32B4FD',
    '#535c68',
    '#FD6332',
    '#03C4A1',
    '#0880AE', // repeat
    '#E2007C',
    '#93D144',
    '#F0B418',
    '#FA78CD',
    '#32B4FD',
    '#535c68',
    '#FD6332',
    '#03C4A1',
    '#0880AE', // repeat
    '#E2007C',
    '#93D144',
    '#F0B418',
    '#FA78CD',
    '#32B4FD',
    '#535c68',
    '#FD6332',
    '#03C4A1',
    '#0880AE', // repeat
    '#E2007C',
    '#93D144',
    '#F0B418',
    '#FA78CD',
    '#32B4FD',
    '#535c68',
    '#FD6332',
    '#03C4A1',
    '#0880AE', // repeat
    '#E2007C',
    '#93D144',
    '#F0B418',
    '#FA78CD',
    '#32B4FD',
    '#535c68',
    '#FD6332',
    '#03C4A1',
  ],
  team: [
    '#32B4FD', // repeat
    '#03C4A1',
    '#FD6332',
    '#0880AE',
    '#E2007C',
    '#93D144',
    '#535c68',
    '#F0B418',
    '#FA78CD',
    '#32B4FD', // repeat
    '#03C4A1',
    '#FD6332',
    '#0880AE',
    '#E2007C',
    '#93D144',
    '#535c68',
    '#F0B418',
    '#FA78CD',
    '#32B4FD', // repeat
    '#03C4A1',
    '#FD6332',
    '#0880AE',
    '#E2007C',
    '#93D144',
    '#535c68',
    '#F0B418',
    '#FA78CD',
    '#32B4FD', // repeat
    '#03C4A1',
    '#FD6332',
    '#0880AE',
    '#E2007C',
    '#93D144',
    '#535c68',
    '#F0B418',
    '#FA78CD',
    '#32B4FD', // repeat
    '#03C4A1',
    '#FD6332',
    '#0880AE',
    '#E2007C',
    '#93D144',
    '#535c68',
    '#F0B418',
    '#FA78CD',
    '#32B4FD', // repeat
    '#03C4A1',
    '#FD6332',
    '#0880AE',
    '#E2007C',
    '#93D144',
    '#535c68',
    '#F0B418',
    '#FA78CD',
    '#32B4FD', // repeat
    '#03C4A1',
    '#FD6332',
    '#0880AE',
    '#E2007C',
    '#93D144',
    '#535c68',
    '#F0B418',
    '#FA78CD',
  ],
  project: [
    '#F0B418', // repeat
    '#FA78CD',
    '#535c68',
    '#0880AE',
    '#E2007C',
    '#93D144',
    '#FD6332',
    '#03C4A1',
    '#32B4FD',
    '#F0B418', // repeat
    '#FA78CD',
    '#535c68',
    '#0880AE',
    '#E2007C',
    '#93D144',
    '#FD6332',
    '#03C4A1',
    '#32B4FD',
    '#F0B418', // repeat
    '#FA78CD',
    '#535c68',
    '#0880AE',
    '#E2007C',
    '#93D144',
    '#FD6332',
    '#03C4A1',
    '#32B4FD',
    '#F0B418', // repeat
    '#FA78CD',
    '#535c68',
    '#0880AE',
    '#E2007C',
    '#93D144',
    '#FD6332',
    '#03C4A1',
    '#32B4FD',
    '#F0B418', // repeat
    '#FA78CD',
    '#535c68',
    '#0880AE',
    '#E2007C',
    '#93D144',
    '#FD6332',
    '#03C4A1',
    '#32B4FD',
    '#F0B418', // repeat
    '#FA78CD',
    '#535c68',
    '#0880AE',
    '#E2007C',
    '#93D144',
    '#FD6332',
    '#03C4A1',
    '#32B4FD',
    '#F0B418', // repeat
    '#FA78CD',
    '#535c68',
    '#0880AE',
    '#E2007C',
    '#93D144',
    '#FD6332',
    '#03C4A1',
    '#32B4FD',
  ],
};

export {
  keyFields,
  typeCallback,
  typeOverview,
  cardColors,
  limitTeams,
  teamType,
};
