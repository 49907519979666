import React, {
  memo, useState, useEffect, useContext, useCallback,
} from 'react';
import PropTypes from 'prop-types';
import { useParams, useLocation, useHistory } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import { useMediaQuery } from '@mui/material';
import ScrollContainer from 'react-indiana-drag-scroll';
import styles from './InvoicesSection.module.css';
import CategoryTabNotif from '../../NotificationContainer/NotificationsCategoryContainer/CategoryTabNotif/CategoryTabNotif';
import { DisplayTextBody } from '../../../components/UI_V2/Display/Text';
import { GlobalContext, initialState } from '../../../contexts/GlobalStateProvider';
import Color from '../../../themes/colors';
import InvoicesSectionItem from './Item/Item';
import handleLoadings from '../../../utilities/handleLoadings';
import { BillingActions } from '../../../actions';
import handleStatusMsg from '../../../utilities/handleStatusMsg';
import { useInfinityScrollHooks } from '../../../hooks/InfinityScrollHooks';
import { BillingConstants } from '../../../constants';
import InfinityScroll from '../../../components/UI/InfinityScroll/InfinityScroll';
import { incomingInvoices, setCurrentInvoicesUnpaidCounter } from '../../../actions/BillingActions';
import { FeedbackDialogAlert } from '../../../components/UI_V2/Feedback/Dialog';

const BillingInvoicesSection = ({
  tabSelected,
  setTabSelected,
}) => {
  const [{
    user,
    currentInvoices,
    previousInvoices,
    currentInvoicesUnpaidCounter,
  }, dispatch] = useContext(GlobalContext);
  const isTablet = useMediaQuery('(max-width:960px)');
  const { enqueueSnackbar } = useSnackbar();
  // const [tabSelected, setTabSelected] = useState('all');
  const [loadings, setLoadings] = useState([]);
  // const [invoices, setInvoices] = useState([]);
  // const [counterUnpaid, setCounterUnpaid] = useState(0);
  const [expireInvoiceId, setExpireInvoiceId] = useState();
  const [showAlertExpireInvoice, setShowAlertExpireInvoice] = useState(false);

  const location = useLocation();
  const history = useHistory();
  const params = useParams();

  const { companyId } = params;

  const getMoreLists = async () => {
    const result = await BillingActions.loadMoreInvoices({
      companyId,
      currentInvoices,
      status: tabSelected,
    }, dispatch);

    return result;
  };

  const resetPreviousLists = useCallback(() => {
    BillingActions.setPreviousInvoices({
      previousInvoices: initialState.previousInvoices,
    }, dispatch);
    BillingActions.setCurrentInvoices({
      currentInvoices: initialState.currentInvoices,
    }, dispatch);
  }, []);

  const {
    lists,
    handleLoadMoreLists,
    checkIfListNeedToLoad,
    setCheckLoadMore,
    isListsLengthAtTheLimitOrAbove,
  } = useInfinityScrollHooks({
    currentObjectWithKeyProperty: currentInvoices,
    previousLists: previousInvoices?.data,
    resetPreviousLists,
    getMoreLists,
    keyProperty: 'data',
    limitList: BillingConstants.limitInvoices,
  });

  const initiateInvoicesApi = async (value) => {
    try {
      // const startLoadings = handleLoadings('blastPostMenu', [...loadings], 'start');
      // setLoadings([...startLoadings]);

      const result = await BillingActions.initiateInvoices({
        companyId,
        status: value,
      },
      dispatch);

      if (isListsLengthAtTheLimitOrAbove(result?.data?.data)) {
        setCheckLoadMore(true);
      } else {
        setCheckLoadMore(false);
      }

      // const result = await BillingActions.getInvoices({
      //   companyId,
      //   status: value,
      // });

      // setInvoices(result?.data);

      // const status = handleStatusMsg(result, 'success');

      // enqueueSnackbar(status.message, {
      //   variant: 'success',
      // });
    } catch (err) {
      const status = handleStatusMsg(err, 'error');

      enqueueSnackbar(status.message, {
        variant: 'error',
      });
    } finally {
      // const endLoadings = handleLoadings('blastPostMenu', [...loadings], 'end');
      // setLoadings([...endLoadings]);
    }
  };

  const handleShowAlertExpireInvoice = () => {
    setShowAlertExpireInvoice(true);
  };

  const handleHideAlertExpireInvoice = () => {
    setShowAlertExpireInvoice(false);
  };

  const handleClickExpireInvoice = (invoiceId) => {
    setExpireInvoiceId(invoiceId);
    handleShowAlertExpireInvoice();
  };

  const expireInvoiceApi = async () => {
    try {
      const startLoadings = handleLoadings(`billingExpireInvoice-${expireInvoiceId}`, [...loadings], 'start');
      setLoadings([...startLoadings]);

      const result = await BillingActions.expireInvoice({
        companyId,
        invoiceId: expireInvoiceId,
      },
      dispatch);

      incomingInvoices({
        invoice: result?.data,
        typeAction: BillingConstants.typeCallback.EDIT,
      }, dispatch);

      handleHideAlertExpireInvoice();
    } catch (err) {
      const status = handleStatusMsg(err, 'error');

      enqueueSnackbar(status.message, {
        variant: 'error',
      });
    }
  };
  const getInvoicesCounterUnpaidApi = async () => {
    try {
      // const startLoadings = handleLoadings('blastPostMenu', [...loadings], 'start');
      // setLoadings([...startLoadings]);

      const result = await BillingActions.getInvoicesCounterUnpaid({
        companyId,
      });

      setCurrentInvoicesUnpaidCounter(result?.total, dispatch);

      // const status = handleStatusMsg(result, 'success');

      // enqueueSnackbar(status.message, {
      //   variant: 'success',
      // });
    } catch (err) {
      const status = handleStatusMsg(err, 'error');

      enqueueSnackbar(status.message, {
        variant: 'error',
      });
    } finally {
      // const endLoadings = handleLoadings('blastPostMenu', [...loadings], 'end');
      // setLoadings([...endLoadings]);
    }
  };

  useEffect(() => {
    if (user._id === undefined) {
      history.push(`/check-login?previousPath=${location.pathname}`);
      return;
    }

    getInvoicesCounterUnpaidApi();
    initiateInvoicesApi(tabSelected);
  }, [location]);

  const handleSelectTab = (value) => {
    if (value === tabSelected) return;
    setTabSelected(value);
    initiateInvoicesApi(value);
  };

  return (
    <>
      <div className={styles.container}>
        <ScrollContainer className={styles.scrollTabs}>
          <div className={styles.tabs}>
            <CategoryTabNotif
              name="Semua"
              counter={0}
              key="all"
              itemId="all"
              isFirstItem={false}
              onClick={() => handleSelectTab('all')}
              selected={tabSelected === 'all'}
            />
            <CategoryTabNotif
              name="Pending"
              counter={currentInvoicesUnpaidCounter}
              key="unpaid"
              itemId="unpaid"
              isFirstItem={false}
              onClick={() => handleSelectTab('unpaid')}
              selected={tabSelected === 'unpaid'}
            />
            <CategoryTabNotif
              name="Lunas"
              counter={0}
              key="paid"
              itemId="paid"
              isFirstItem={false}
              onClick={() => handleSelectTab('paid')}
              selected={tabSelected === 'paid'}
            />
            <CategoryTabNotif
              name="Batal"
              counter={0}
              key="expired"
              itemId="expired"
              isFirstItem={false}
              onClick={() => handleSelectTab('expired')}
              selected={tabSelected === 'expired'}
            />
          </div>
        </ScrollContainer>
        {/* <div className={styles.wrapperInvoices}> */}
        <ScrollContainer className={styles.scrollListInvoice}>
          <div className={styles.listInvoicesSection}>
            <div className={styles.columnInvoice}>
              <div className={styles.columnInvoice__invNumber}>
                <DisplayTextBody
                  color={Color.black}
                  decoration="bold"
                  mode="12"
                  fontFamily="openSans"
                >
                  Faktur
                </DisplayTextBody>
              </div>
              <div className={styles.columnInvoice__status}>
                <DisplayTextBody
                  color={Color.black}
                  decoration="bold"
                  mode="12"
                  fontFamily="openSans"
                >
                  Status
                </DisplayTextBody>
              </div>
              <div className={styles.columnInvoice__expDate}>
                <DisplayTextBody
                  color={Color.black}
                  decoration="bold"
                  mode="12"
                  fontFamily="openSans"
                >
                  Tanggal
                </DisplayTextBody>
              </div>
              <div className={styles.columnInvoice__description}>
                <DisplayTextBody
                  color={Color.black}
                  decoration="bold"
                  mode="12"
                  fontFamily="openSans"
                >
                  Deskripsi
                </DisplayTextBody>
              </div>
              {/* <div className={styles.columnInvoice__subscriptionDate}>
              <DisplayTextBody
                color={Color.black}
                decoration="bold"
                mode="12"
                fontFamily="openSans"
              >
                Subscription Date
              </DisplayTextBody>
            </div> */}
              <div className={styles.columnInvoice__discAmount}>
                <DisplayTextBody
                  color={Color.black}
                  decoration="bold"
                  mode="12"
                  fontFamily="openSans"
                >
                  Kupon
                </DisplayTextBody>
              </div>
              <div className={styles.columnInvoice__amount}>
                <DisplayTextBody
                  color={Color.black}
                  decoration="bold"
                  mode="12"
                  fontFamily="openSans"
                >
                  Total
                </DisplayTextBody>
              </div>
              <div className={styles.columnInvoice__invoiceUrl}>
                <DisplayTextBody
                  color={Color.black}
                  decoration="bold"
                  mode="12"
                  fontFamily="openSans"
                >
                  Link Bayar
                </DisplayTextBody>
              </div>
              <div className={styles.columnInvoice__invoiceUrl}>
                {/* <DisplayTextBody
                  color={Color.black}
                  decoration="bold"
                  mode="12"
                  fontFamily="openSans"
                >
                  Payment Link
                </DisplayTextBody> */}
              </div>
            </div>
            <div id="listInvoices" className={styles.listInvoices}>
              <InfinityScroll
                scrollableTarget="listInvoices"
                dataLength={lists?.length || 0}
                hasMore={checkIfListNeedToLoad()}
                next={handleLoadMoreLists}
                emptyMessage="Belum ada tagihan..."
              >
                {lists.map((invoice) => (
                  <InvoicesSectionItem
                    invoice={invoice}
                    handleClickExpireInvoice={handleClickExpireInvoice}
                  />
                ))}
              </InfinityScroll>
            </div>
          </div>
        </ScrollContainer>
      </div>
      {/* </div> */}
      <FeedbackDialogAlert
        open={showAlertExpireInvoice}
        onClose={handleHideAlertExpireInvoice}
        handleConfirmNo={handleHideAlertExpireInvoice}
        handleConfirmYes={expireInvoiceApi}
        waitYes={`billingExpireInvoice-${expireInvoiceId}`}
        loadings={loadings}
        labelYes="Batalkan Tagihan"
        labelNo="Kembali"
        title="Batalkan Tagihan ini?"
        content="Kamu bisa membatalkan tagihan kalo kamu salah pilih paket atau duplikasi pemesanan. Jadi kamu ga akan salah bayar ke tagihan yang ga kamu mau."
      />
    </>
  );
};

BillingInvoicesSection.propTypes = {
  tabSelected: PropTypes.string.isRequired,
  setTabSelected: PropTypes.func.isRequired,
};

export default memo(BillingInvoicesSection);
