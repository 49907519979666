import React, { memo, useState } from 'react';
import PropTypes from 'prop-types';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import { Tooltip } from '@mui/material';
import styles from './HeaderActivity.module.css';
import Color from '../../../themes/colors';
import ActivityButton from '../../NotificationContainer/ActivityButton/ActivityButton';
import LinkNoDecor from '../../../components/LinkNoDecor/LinkNoDecor';
import CheersButton from '../../NotificationContainer/CheersButton/CheersButton';
import { DisplayTextBody, DisplayTextSubHeadline } from '../../../components/UI_V2/Display/Text';

const HeaderActivity = ({
  title,
}) => (
  <div className={styles.container}>
    <DisplayTextSubHeadline color={Color.blueNavyCicle} decoration="bold">
      {title}
    </DisplayTextSubHeadline>
    {/* <div className={styles.icon}>
      <Tooltip title="Lihat aktifitas apa aja yang dilakukan rekan setim,
      untuk meningkatkan kepercayaan satu sama lain.">
        <HelpOutlineIcon />
      </Tooltip>
    </div> */}
  </div>
);

HeaderActivity.propTypes = {
  title: PropTypes.string,
};

HeaderActivity.defaultProps = {
  title: 'List Aktifitas',
};

export default memo(HeaderActivity);
