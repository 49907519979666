import React, { useEffect, useContext } from 'react';
import { useParams, useHistory, useLocation } from 'react-router-dom';
import axios from 'axios';
import LoadingWithAnimation from '../../components/UI/LoadingWithAnimation/LoadingWithAnimation';
import MainLayout from '../../components/Layout/MainLayout/MainLayout';
import styles from './InvitePage.module.css';
import Title from '../../components/Title/Title';
import LogoHeader from '../../components/UI/LogoHeader/LogoHeader';
import handleStatusMsg from '../../utilities/handleStatusMsg';
import { GlobalContext } from '../../contexts/GlobalStateProvider';
import { actionTypes } from '../../reducers/reducer';
import { CheckLoginActions, CompanyActions } from '../../actions';

function InvitePage() {
  const [{ user }, dispatch] = useContext(GlobalContext);
  const params = useParams();
  const history = useHistory();
  const location = useLocation();

  useEffect(() => {
    const fetchInvitation = async () => {
      const { token } = params;
      try {
        const result = await axios.get(`${process.env.REACT_APP_PRIMARY_API_URL}/api/v1/invites/${token}`, { withCredentials: true, headers: { Authorization: `jwt ${localStorage.getItem('token')}` } });
        const resultCompanies = await CompanyActions.initiateCompanies({}, dispatch);
        await CompanyActions.getAndSaveCompanyAndLastOpenedCompany(
          {
            companyId: result?.data?.companyId,
            companies: resultCompanies,
            userId: user?._id,
          }, dispatch,
        );

        let currentUserId = user?._id;
        if (!currentUserId) {
          const resultUser = await CheckLoginActions.getUserWithToken(
            {},
            dispatch,
          );

          currentUserId = resultUser?._id;
        }

        CompanyActions.modifyShowWelcomeTutorialAndTrialMessage({
          userId: currentUserId,
          companyId: result?.data?.companyId,
        });

        // success all, then delete emailInvitationToken
        localStorage.removeItem('emailInvitationToken');

        history.push('/success', '_self');
      } catch (err) {
        if (err?.response?.data?.message) {
          localStorage.removeItem('emailInvitationToken');

          const status = handleStatusMsg(err, 'error');

          dispatch({
            type: actionTypes.SET_ERROR_RESPONSE,
            errorResponse: { message: status.message },
          });

          history.push(`/errors?previousPath=${location.pathname}`);
          return;
        }

        if (token) {
          localStorage.setItem('emailInvitationToken', token);
          history.push('/signup?sourcePage=emailInvitation');
        }
      }
    };
    fetchInvitation();
  }, []);

  return (
    <>
      <Title title="Undangan Gabung Perusahaan" />
      <LogoHeader />
      <MainLayout>
        <LoadingWithAnimation>
          <h1>Tunggu ya...</h1>
          <h1>Mengecek tautan undanganmu</h1>
        </LoadingWithAnimation>
      </MainLayout>
    </>
  );
}

export default InvitePage;
