import React, { memo } from 'react';
import CheckIcon from '@mui/icons-material/Check';
import PropTypes from 'prop-types';
import styles from './Label.module.css';

const Label = ({
  color, name, clicked, block, checked,
}) => (
  <div
    onClick={clicked}
    className={block ? styles.label__block : styles.label}
    style={{ backgroundColor: color }}
  >
    <p>{name}</p>
    {checked ? <CheckIcon style={{ fill: 'white', width: '15px' }} /> : null}
  </div>
);

Label.propTypes = {
  color: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  clicked: PropTypes.func.isRequired,
  block: PropTypes.bool.isRequired,
  checked: PropTypes.bool.isRequired,
};

// const isComponentDataEqual = (prevProps, nextProps) => {
//   const { name, color, checked } = nextProps;
//   return prevProps.name === name
//     && prevProps.color === color
//     && prevProps.checked === checked;
// };

export default memo(Label);
