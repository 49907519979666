import React, {
  memo, useContext, useEffect, useRef, useState,
} from 'react';
import PropTypes from 'prop-types';
import SortIcon from '@mui/icons-material/Sort';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { Skeleton, useMediaQuery } from '@mui/material';
import { enqueueSnackbar } from 'notistack';
import styles from './CardListItemList.module.css';
import Color from '../../../../themes/colors';
import { DisplayTextBody } from '../../../UI_V2/Display/Text';
import CardListColumnBox from '../../CardListColumnBox/CardListColumnBox';
import { limitChar } from '../../../../utilities/stringUtil';
import handleStatusMsg from '../../../../utilities/handleStatusMsg';
import { useDelayShowHideHandler } from '../../../../hooks/HelperHooks';
import { GlobalContext } from '../../../../contexts/GlobalStateProvider';
import ManageMoveCard from '../../../../pages/CardDetailContainer/ManageMoveCard/ManageMoveCard';
import { BoardActions } from '../../../../actions';

const CardListItemList = ({
  item,
  widthPercentage,
  variantBorder,
  setSelected,
  triggerScrollIntoView,
  enableCreateList,
}) => {
  const isMobile = useMediaQuery('(max-width:720px)');
  const [{ currentBoard, currentBoardLists, isSidebarOpen }, dispatch] = useContext(GlobalContext);
  const [isLoadingLists, setIsLoadingLists] = useState(false);

  const [
    showManageCardMove,
    handleShowManageCardMove,
    handleHideManageCardMove,
  ] = useDelayShowHideHandler();

  const initiateLists = async () => {
    try {
      await BoardActions.initiateBoardV2(
        {
          boardId: item.board,
          companyId: item.company,
          teamId: item.team._id,
          currentBoard,
          currentBoardLists,
        },
        dispatch,
      );
    } catch (error) {
      const status = handleStatusMsg(error, 'error');

      enqueueSnackbar(status.message, {
        variant: 'error',
      });
    } finally {
      setIsLoadingLists(false);
      handleShowManageCardMove();
    }
  };

  const checkShouldGetListsOrNot = () => {
    setIsLoadingLists(true);
  };

  useEffect(() => {
    if (!isLoadingLists) return;
    setSelected(item._id);
    initiateLists();
  }, [isLoadingLists]);

  useEffect(() => {
    if (!showManageCardMove) return;

    triggerScrollIntoView();
  }, [showManageCardMove]);

  const getNameLimit = () => {
    if (isMobile) return 7;
    if (isSidebarOpen) return 17;
    return 20;
  };

  return (
    <CardListColumnBox
      variantBorder={variantBorder}
      widthPercentage={widthPercentage}
      variantCursor="pointer"
      onClick={showManageCardMove ? () => null : checkShouldGetListsOrNot}
    >
      <div className={styles.container}>
        {!isLoadingLists && (
        <DisplayTextBody>
          {limitChar(item.list.name, getNameLimit())}
        </DisplayTextBody>
        )}
        {isLoadingLists && <Skeleton width={80} />}
        {showManageCardMove && (
        <ManageMoveCard
          list={item.list}
          card={item}
          cancel={handleHideManageCardMove}
          enableCreateList={enableCreateList}
        />
        )}
      </div>
    </CardListColumnBox>
  );
};

CardListItemList.propTypes = {
  item: PropTypes.object,
  widthPercentage: PropTypes.number,
  variantBorder: PropTypes.string.isRequired,
  setSelected: PropTypes.func,
  triggerScrollIntoView: PropTypes.func,
  enableCreateList: PropTypes.bool,
};

CardListItemList.defaultProps = {
  item: {},
  widthPercentage: 100,
  setSelected: () => null,
  triggerScrollIntoView: () => null,
  enableCreateList: true,
};

export default memo(CardListItemList);
