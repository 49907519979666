import { cloneDeep, isArray, isObject } from 'lodash';
import { actionTypes } from '../reducers/reducer';
import { ApiConstants, BucketConstants } from '../constants';
import apiUtil from '../utilities/apiUtil';
import { getLastListCreatedAt, mergeObjectListAndRemoveDuplicate, updateListProperty } from '../utilities/arrayUtil';
import { ErrorException } from '../utilities/handleError';
import { TeamActions, DocActions } from '.';
import { initialState } from '../contexts/GlobalStateProvider';

/*
  Dispatcher
*/

function dispatchPreviousBucketItems({ previousBucketItems }, dispatch) {
  dispatch({
    type: actionTypes.SET_PREVIOUS_BUCKET_ITEMS,
    previousBucketItems,
  });
}

function dispatchCurrentBucketItems({ currentBucketItems }, dispatch) {
  dispatch({
    type: actionTypes.SET_CURRENT_BUCKET_ITEMS,
    currentBucketItems,
  });
}

function dispatchUpdateBucketItems({ updateBucketItems }, dispatch) {
  dispatch({
    type: actionTypes.UPDATE_CURRENT_BUCKET_ITEMS,
    updateBucketItems,
  });
}

function dispatchCurrentBucket({ currentBucket }, dispatch) {
  dispatch({
    type: actionTypes.SET_CURRENT_BUCKET,
    currentBucket,
  });
}

function dispatchUpdateBucket({ updateBucket }, dispatch) {
  dispatch({
    type: actionTypes.UPDATE_CURRENT_BUCKET,
    updateBucket,
  });
}

/*
  SetterDispatcher
*/

function setPreviousBucketItems({ previousBucketItems }, dispatch) {
  if (!previousBucketItems) return;

  dispatchPreviousBucketItems(
    { previousBucketItems: cloneDeep(previousBucketItems) }, dispatch,
  );
}

function setCurrentBucketItems({ currentBucketItems }, dispatch) {
  if (!currentBucketItems) return;
  dispatchCurrentBucketItems(
    { currentBucketItems: cloneDeep(currentBucketItems) }, dispatch,
  );
}

function setCurrentBucket({ currentBucket }, dispatch) {
  if (!currentBucket) return;

  dispatchCurrentBucket(
    { currentBucket: cloneDeep(currentBucket) }, dispatch,
  );
}

/*
  Helper
*/

const extractItemsBasedOnType = (array, type) => {
  try {
    const result = array.filter((elem) => elem.type === type);

    return result;
  } catch (error) {
    throw new ErrorException(error);
  }
};

const sortItems = (docs, files, buckets) => {
  try {
    // join 2 array
    let joinedDatas = [...docs, ...files, ...buckets];
    // sort by created date
    joinedDatas = joinedDatas.sort((a, b) => {
      const c = new Date(a.createdAt);
      const d = new Date(b.createdAt);
      return d - c;
    });

    return joinedDatas;
  } catch (error) {
    throw new ErrorException(error);
  }
};

const modifyItemType = (object, type) => {
  try {
    if (!isObject(object)) return null;

    const result = {
      ...object,
      type,
    };

    return result;
  } catch (error) {
    throw new ErrorException(error);
  }
};

const modifyItemsType = (array, type) => {
  try {
    if (!isArray(array)) return [];

    const results = array.map((elem) => ({
      ...elem,
      type,
    }));

    return results;
  } catch (error) {
    throw new ErrorException(error);
  }
};

/*
  Method
*/
async function initiateBucketItems({
  companyId,
  bucketId,
  teamId,
  limit = BucketConstants.limitBucket,
  filters,
}, dispatch) {
  try {
    let params = {
      companyId,
      teamId,
      limit,
    };

    if (filters) {
      if (filters.filterTitle) {
        params = {
          ...params,
          'filter[title]': filters.filterTitle,
        };
      }

      if (filters.filterArchived) {
        params = {
          ...params,
          'filter[archived]': true,
        };
      }
    }

    const resultDocs = await apiUtil.get(ApiConstants.URL_V2.BUCKET_DOCS({
      bucketId,
    }), {
      params,
    });
    const docs = modifyItemsType(resultDocs?.data?.data, 'doc');

    const resultFiles = await apiUtil.get(ApiConstants.URL_V2.BUCKET_FILES({
      bucketId,
    }), {
      params,
    });
    const files = modifyItemsType(resultFiles?.data?.data, 'file');

    const resultBuckets = await apiUtil.get(ApiConstants.URL_V2.BUCKET_BUCKETS({
      bucketId,
    }), {
      params,
    });

    const buckets = modifyItemsType(resultBuckets?.data?.data, 'bucket');

    const data = sortItems(docs, files, buckets);

    if (dispatch) {
      setCurrentBucketItems({ currentBucketItems: { data } }, dispatch);
      setPreviousBucketItems({ previousBucketItems: initialState.previousBucketItems }, dispatch);
    }

    return {
      data: {
        data,
      },
    };
  } catch (error) {
    throw new ErrorException(error);
  }
}

async function loadMoreBucketItems({
  companyId,
  currentBucketItems,
  teamId,
  bucketId,
  limit = BucketConstants.limitBucket,
  filters,
}, dispatch) {
  try {
    let params = {
      companyId,
      teamId,
      limit,
    };

    if (filters) {
      if (filters.filterTitle) {
        params = {
          ...params,
          'filter[title]': filters.filterTitle,
        };
      }

      if (filters.filterArchived) {
        params = {
          ...params,
          'filter[archived]': true,
        };
      }
    }
    const currentDocs = extractItemsBasedOnType(currentBucketItems.data, 'doc');
    const createdAtDocs = getLastListCreatedAt(currentDocs);

    const resultDocs = await apiUtil.get(ApiConstants.URL_V2.BUCKET_DOCS({
      bucketId,
    }), {
      params: {
        ...params,
        createdAt: createdAtDocs,
      },
    });
    const docs = modifyItemsType(resultDocs?.data?.data, 'doc');

    const currentFiles = extractItemsBasedOnType(currentBucketItems.data, 'file');
    const createdAtFiles = getLastListCreatedAt(currentFiles);
    const resultFiles = await apiUtil.get(ApiConstants.URL_V2.BUCKET_FILES({
      bucketId,
    }), {
      params: {
        ...params,
        createdAt: createdAtFiles,
      },
    });
    const files = modifyItemsType(resultFiles?.data?.data, 'file');

    const currentBuckets = extractItemsBasedOnType(currentBucketItems.data, 'bucket');
    const createdAtBuckets = getLastListCreatedAt(currentBuckets);
    const resultBuckets = await apiUtil.get(ApiConstants.URL_V2.BUCKET_BUCKETS({
      bucketId,
    }), {
      params: {
        ...params,
        createdAt: createdAtBuckets,
      },
    });

    const buckets = modifyItemsType(resultBuckets?.data?.data, 'bucket');

    const data = sortItems(docs, files, buckets);

    const mergedDataBucketItems = mergeObjectListAndRemoveDuplicate({
      currentObjectList: currentBucketItems,
      nextObjectList: { data },
      keyObject: 'data',
    });

    const mergedBucketItems = {
      ...data,
      data: mergedDataBucketItems?.data,
    };

    if (dispatch) {
      setPreviousBucketItems({ previousBucketItems: { data } }, dispatch);
      setCurrentBucketItems({ currentBucketItems: mergedBucketItems }, dispatch);
    }

    return {
      data: {
        data,
      },
    };
  } catch (error) {
    throw new ErrorException(error);
  }
}

async function initiateBucket({ bucketId, companyId, teamId }, dispatch) {
  try {
    const result = await apiUtil.get(ApiConstants.URL_V2.BUCKET_NEW({ bucketId }), {
      params: {
        companyId,
        teamId,
      },
    });

    setCurrentBucket({ currentBucket: result?.data?.bucket }, dispatch);
    DocActions.setCurrentBucketDocPath({ bucketDocPath: result?.data?.bucketDocPath }, dispatch);

    return result;
  } catch (error) {
    throw new ErrorException(error);
  }
}

async function initiateBucketSubscribers({ bucketId, companyId, teamId }, dispatch) {
  try {
    const result = await apiUtil.get(ApiConstants.URL_V2.BUCKET_SUBSCRIBERS({ bucketId }), {
      params: {
        companyId,
        teamId,
      },
    });

    const updateBucket = (previousBucket) => {
      const newBucket = {
        ...previousBucket,
        subscribers: result?.data?.subscribers,
        creator: result?.data?.bucket?.creator,
      };
      return newBucket;
    };
    dispatchUpdateBucket({ updateBucket }, dispatch);

    return result;
  } catch (error) {
    throw new ErrorException(error);
  }
}

async function changeAccessBucket({
  bucketId, isPublic, companyId, teamId,
}) {
  try {
    const result = await apiUtil.patch(
      ApiConstants.URL_V1.BUCKET({ bucketId }),
      { isPublic }, {
        params: {
          companyId,
          teamId,
        },
      },
    );

    return result;
  } catch (error) {
    throw new ErrorException(error);
  }
}

async function createBucket({ body, companyId, teamId }) {
  try {
    const result = await apiUtil.post(
      ApiConstants.URL_V1.BUCKETS(),
      body,
      {
        params: {
          companyId,
          teamId,
        },
      },
    );

    return result;
  } catch (error) {
    throw new ErrorException(error);
  }
}

async function editBucket({
  body, bucketId, companyId, teamId,
}) {
  try {
    const result = await apiUtil.patch(
      ApiConstants.URL_V1.BUCKET({ bucketId }),
      body,
      {
        params: {
          companyId,
          teamId,
        },
      },
    );

    return result;
  } catch (error) {
    throw new ErrorException(error);
  }
}

async function archiveBucket({ bucketId, companyId, teamId }) {
  try {
    const result = await apiUtil.patch(
      ApiConstants.URL_V1.BUCKET_ARCHIVE({ bucketId }),
      {},
      {
        params: {
          companyId,
          teamId,
        },
      },
    );

    return result;
  } catch (error) {
    throw new ErrorException(error);
  }
}

async function unarchiveBucket({
  teamId,
  companyId,
  bucketId,
}, dispatch) {
  try {
    const result = await apiUtil.patch(ApiConstants.URL_V1.BUCKET_UNARCHIVE({ bucketId }), {}, {
      params: {
        companyId,
        teamId,
      },
    });

    return result;
  } catch (error) {
    throw new ErrorException(error);
  }
}

/* Socket Callback */

function incomingBucketItSelf({ bucket, bucketId, typeAction }, dispatch) {
  if (!bucket) return;
  if (bucket._id !== bucketId) {
    const updateBucketItems = (currentBucketItems) => {
      const type = 'bucket';
      const newItem = modifyItemType(bucket, type);
      return updateListProperty({
        keyProperty: 'data',
        newData: newItem,
        currentList: currentBucketItems,
        typeAction,
      });
    };
    dispatchUpdateBucketItems({ updateBucketItems }, dispatch);
  }

  if (bucket._id === bucketId) {
    const updateBucket = () => bucket;
    dispatchUpdateBucket({ updateBucket }, dispatch);
  }
}

function incomingBucket({
  bucket, typeAction, actionEdit, keyProperty = 'buckets', bucketId,
}, dispatch) {
  if (!bucket) return;
  if (bucket.localParentBucketId !== bucketId) return;
  const updateBucketItems = (currentBucketItems) => {
    const type = keyProperty.substring(0, keyProperty.length - 1);
    const newItem = modifyItemType(bucket, type);

    return updateListProperty({
      keyProperty: 'data',
      newData: newItem,
      currentList: currentBucketItems,
      typeAction,
      // actionEdit,
    });
  };

  dispatchUpdateBucketItems({ updateBucketItems }, dispatch);
}

function incomingFolder({
  bucket, typeAction, keyProperty = 'buckets', bucketId,
}, dispatch) {
  if (!bucket) return;

  const updateBucketItems = (currentBucketItems) => {
    const type = keyProperty.substring(0, keyProperty.length - 1);
    const newItem = modifyItemType(bucket, type);

    const targetBucket = currentBucketItems.data.find((elem) => elem._id === bucketId);
    const updatedBucket = updateListProperty({
      keyProperty,
      newData: newItem,
      currentList: targetBucket,
      typeAction,
    });

    return updateListProperty({
      keyProperty: 'data',
      newData: updatedBucket,
      currentList: currentBucketItems,
      // typeAction,
    });
  };

  dispatchUpdateBucketItems({ updateBucketItems }, dispatch);
}

export {
  setCurrentBucket,
  initiateBucket,
  incomingBucketItSelf,
  incomingBucket,
  incomingFolder,
  changeAccessBucket,
  createBucket,
  editBucket,
  archiveBucket,
  initiateBucketItems,
  loadMoreBucketItems,
  setCurrentBucketItems,
  setPreviousBucketItems,
  initiateBucketSubscribers,
  unarchiveBucket,
};
