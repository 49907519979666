import React, { memo, useState } from 'react';
import PropTypes from 'prop-types';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import styles from './ChartTractionRevenue.module.css';
import { DisplayTextBody } from '../../../../../components/UI_V2/Display/Text';
import Color from '../../../../../themes/colors';
import LineChart from '../../../LineChart/LineChart';
import { dateBeautifyFormatter } from '../../../../../utilities/dateUtil';

const ChartTractionRevenue = ({
  labels,
  metricCounts,
  tractionModels,
}) => {
  const firstData = metricCounts.map((elem) => (elem.revenue.value));
  const secondData = tractionModels.map((elem) => (elem.revenue.value));

  const firstDataset = {
    label: 'Buy',
    data: firstData,
  };

  const secondDataset = {
    label: 'Target Buy',
    data: secondData,
  };

  const datasets = [
    firstDataset,
    secondDataset,
  ];

  return (
    <div className={styles.container}>
      <LineChart
        labels={labels}
        datasets={datasets}
      />
    </div>
  );
};

ChartTractionRevenue.propTypes = {
  labels: PropTypes.array,
  metricCounts: PropTypes.array,
  tractionModels: PropTypes.array,
};

ChartTractionRevenue.defaultProps = {
  labels: [],
  metricCounts: [],
  tractionModels: [],
};

export default memo(ChartTractionRevenue);
