import React, { memo, useState } from 'react';
import PropTypes from 'prop-types';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import styles from './RatioBottleneck.module.css';
import Color from '../../../../../../themes/colors';
import { DisplayTextBody, DisplayTextSubHeadline } from '../../../../../../components/UI_V2/Display/Text';

const RatioBottleneck = ({
  content,
  title,
}) => (
  <div className={styles.container}>
    <div className={styles.text}>
      <DisplayTextSubHeadline
        mode="16"
        decoration="bold"
        color={Color.blueNavy3}
      >
        {content}
      </DisplayTextSubHeadline>
    </div>
    <div className={styles.title}>
      <DisplayTextBody
        mode="12"
        color={Color.gray3}
      >
        {title}
      </DisplayTextBody>
    </div>
  </div>
);

RatioBottleneck.propTypes = {
  content: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
};

RatioBottleneck.defaultProps = {};

export default memo(RatioBottleneck);
