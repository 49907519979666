import React, { memo, useState } from 'react';
import PropTypes from 'prop-types';
import { useParams } from 'react-router-dom';
import { Box } from '@mui/material';
import GroupsOutlinedIcon from '@mui/icons-material/GroupsOutlined';
import MapsHomeWorkOutlinedIcon from '@mui/icons-material/MapsHomeWorkOutlined';
import AssignmentOutlinedIcon from '@mui/icons-material/AssignmentOutlined';
import Highlighter from 'react-highlight-words';
import { DisplayTextBody, DisplayTextSubHeadline } from '../../../../../../components/UI_V2/Display/Text';
import Color from '../../../../../../themes/colors';
import LinkNoDecor from '../../../../../../components/LinkNoDecor/LinkNoDecor';
import { limitChar } from '../../../../../../utilities/stringUtil';
import { getUrlItem } from '../../../../../../actions/SearchJumpToActions';
import { PageConstants } from '../../../../../../constants';
import styles from './ResultItemQuestion.module.css';
import Breadcrumb from '../../Breadcrumb/Breadcrumb';

const ResultItemQuestion = ({
  item, isSelected, onClick, keywordArray, limitTitleChar, params,
}) => {
  const { companyId } = params;

  const routes = [
    {
      name: item.team.name,
    },
    {
      name: 'Pertanyaan',
    },
    {
      name: item.question.title,
    },
  ];

  const newItem = {
    _id: item.question._id,
    name: item.question.title,
    type: 'questions',
    teamId: item.team._id,
    companyId,
    routes,
  };

  const url = getUrlItem({ companyId, item: newItem });
  newItem.url = url;

  return (
    <LinkNoDecor to={url}>
      <div
        onClick={onClick}
        aria-pressed={isSelected}
        className={styles.container}
      >
        <div
          className={styles.content}
        >
          <div
            className={styles.iconBox}
          >
            {PageConstants.pageProperties.questions.icon}
          </div>
          <Box>
            <DisplayTextBody
              mode="14"
              color={Color.gray3}
            >
              <Highlighter
                highlightStyle={{
                  color: Color.yellowAccentCicle,
                  padding: '0px',
                  backgroundColor: 'transparent',
                }}
                searchWords={keywordArray}
                autoEscape
                textToHighlight={limitChar(newItem?.name, limitTitleChar)}
              />
            </DisplayTextBody>
          </Box>
        </div>
        <div
          className={styles.breadcrumb}
        >
          <Breadcrumb routes={newItem?.routes} />
        </div>
      </div>
    </LinkNoDecor>
  );
};

ResultItemQuestion.propTypes = {
  item: PropTypes.object.isRequired,
  isSelected: PropTypes.bool.isRequired,
  onClick: PropTypes.func.isRequired,
  keywordArray: PropTypes.array,
  limitTitleChar: PropTypes.number,
  params: PropTypes.object,
};

ResultItemQuestion.defaultProps = {
  keywordArray: [],
  limitTitleChar: 80,
  params: { companyId: null, teamId: null },
};

export default memo(ResultItemQuestion);
