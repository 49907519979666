import React, {
  useState, useContext, useEffect, useCallback,
} from 'react';
import {
  useHistory, useParams, useLocation,
} from 'react-router-dom';
import _ from 'lodash';
import '../../../node_modules/react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import { useSnackbar } from 'notistack';
import TextareaAutosize from 'react-textarea-autosize';
import EmojiPicker from 'emoji-picker-react';
import MoodIcon from '@mui/icons-material/Mood';
import AddIcon from '@mui/icons-material/Add';
import MainLayout from '../../components/Layout/MainLayout/MainLayout';
import ManageSubscribersContainer from '../ManageSubscribersContainer/ManageSubscribersContainer';
import ImageMember from '../../components/UI/ImageMember/ImageMember';
import Title from '../../components/Title/Title';
import Button from '../../components/UI/Button/Button';
import styles from './CreateDocPage.module.css';
import Paper from '../../components/UI/Paper/Paper';
import SeparatorLine from '../../components/UI/SeparatorLine/SeparatorLine';
import { actionTypes } from '../../reducers/reducer';
import { GlobalContext } from '../../contexts/GlobalStateProvider';
import EditorFull from '../FroalaEditor/EditorFull/EditorFull';
import extractElementBySelectorFromString from '../../utilities/extractElementBySelectorFromString';
import handleLoadings from '../../utilities/handleLoadings';
import PageLoading from '../../components/UI/Button/PageLoading/PageLoading';
import handleStatusMsg from '../../utilities/handleStatusMsg';
import SwitchComponent from '../../components/UI/Switch/SwitchComponent';
import GeneralSubNavBar from '../../components/GeneralSubNavBar/GeneralSubNavBar';
import GlobalActionButton from '../../components/GeneralSubNavBar/BottomNavBar/GlobalActionButton/GlobalActionButton';
import { generateRoutesAndTitle } from '../../actions/BreadCrumbActions';
import {
  BucketActions, DocActions,
  SearchJumpToActions, TeamActions,
} from '../../actions';
import { PageConstants } from '../../constants';
import { InputSwitchMain } from '../../components/UI_V2/Input/Switch';
import { LayoutMain } from '../../components/UI_V2/Layout';
import { SurfacePaperMain } from '../../components/UI_V2/Surface/Paper';

function CreateDocPage() {
  const [{
    user, currentTeam, currentBucket, currentBucketDoc, bucketDocPath, currentCompany,
  }, dispatch] = useContext(GlobalContext);
  const [newDoc, setNewDoc] = useState({ value: '' });
  const [subscribers, setSubscribers] = useState([]);
  const [showAddSubscriber, setShowAddSubscriber] = useState(false);
  const [showEmojiPicker, setShowEmojiPicker] = useState(false);
  const [postDoc, setPostDoc] = useState();
  const [loadings, setLoadings] = useState([]);
  const { enqueueSnackbar } = useSnackbar();
  const [editorModel, setEditorModel] = useState();
  const [trigger, setTrigger] = useState();
  const [isAccessPrivate, setAccessPrivate] = useState(false);

  const history = useHistory();
  const location = useLocation();
  const params = useParams();
  const { companyId, teamId, bucketId } = params;

  useEffect(() => {
    if (user._id === undefined) {
      history.push(`/check-login?previousPath=${location.pathname}`);
      return;
    }

    if (!_.isEmpty(currentTeam)) return;

    const startLoadings = handleLoadings('createDocPage', [...loadings], 'start');
    setLoadings([...startLoadings]);

    const fetchApiBucketDetail = async () => {
      try {
        await BucketActions.initiateBucket(
          { bucketId, companyId, teamId }, dispatch,
        );
        await TeamActions.initiateTeam({
          teamId,
          companyId,
          currentTeam,
        }, dispatch);
      } catch (err) {
        const status = handleStatusMsg(err, 'error');

        dispatch({
          type: actionTypes.SET_ERROR_RESPONSE,
          errorResponse: { message: status.message },
        });

        history.push(`/errors?previousPath=${location.pathname}`);
      } finally {
        const endLoadings = handleLoadings('createDocPage', [...loadings], 'end');
        setLoadings([...endLoadings]);
      }
    };
    fetchApiBucketDetail();
  }, []);

  const handleCancel = () => {
    history.push(`/companies/${companyId}/teams/${teamId}/buckets/${bucketId}`);
  };

  useEffect(() => {
    if (user._id === undefined) {
      history.push(`/check-login?previousPath=${location.pathname}`);
      return;
    }

    if (postDoc === undefined) {
      return;
    }

    const postApiDoc = async () => {
      try {
        const startLoadings = handleLoadings('createDoc', [...loadings], 'start');
        setLoadings([...startLoadings]);

        const result = await DocActions.createDoc({
          bucketId, companyId, teamId, body: postDoc,
        });
        const status = handleStatusMsg(result, 'success');

        enqueueSnackbar(status.message, {
          variant: 'success',
        });

        handleCancel();
      } catch (err) {
        const status = handleStatusMsg(err, 'error');

        enqueueSnackbar(status.message, {
          variant: 'error',
        });
      } finally {
        const endLoadings = handleLoadings('createDoc', [...loadings], 'end');
        setLoadings([...endLoadings]);
      }
    };
    postApiDoc();
  }, [postDoc]);

  useEffect(() => {
    if (!user?._id) return;
    if (!currentBucket._id) return;
    setSubscribers([user]);
  }, [currentBucket]);

  const onEmojiClick = (code, emoji) => {
    setNewDoc((prevValue) => {
      let newValue;
      if (prevValue.value !== '') {
        newValue = prevValue.value + emoji.emoji;
      } else {
        newValue = emoji.emoji;
      }
      return ({
        ...prevValue,
        value: newValue,
      });
    });
  };

  const handleChanged = (event) => {
    const { value } = event.target;

    setNewDoc((prevValue) => ({
      ...prevValue,
      value,
    }));
  };

  const handleShowEmojiPicker = () => {
    setShowEmojiPicker(!showEmojiPicker);
  };

  const disableShowEmojiPicker = () => {
    setShowEmojiPicker(false);
  };

  const handlePostDoc = () => {
    const mentionedUsers = extractElementBySelectorFromString(editorModel, '#mentioned-user');

    const subscriberIds = [];

    subscribers.forEach((subscriber) => {
      subscriberIds.push(subscriber._id);
    });

    setPostDoc({
      title: newDoc.value,
      content: editorModel,
      subscribers: [...subscriberIds],
      mentionedUsers,
      isPublic: !isAccessPrivate,
    });
  };

  useEffect(() => {
    if (trigger === undefined) return;
    handlePostDoc();
  }, [trigger]);

  const handleTriggerPostDoc = () => {
    setTrigger(Math.random());
  };

  const handleCancelShowAddSubscriber = () => {
    setShowAddSubscriber(false);
  };

  const handleShowAddSubscriber = () => {
    setShowAddSubscriber(true);
  };

  const handleSetSubscribers = (incomingSubscribers) => {
    setSubscribers([...incomingSubscribers]);
  };

  const handleEditorModelChange = (model) => {
    setEditorModel(model);
  };

  const handleChangeAccess = useCallback((access) => {
    setAccessPrivate(access);
  }, []);

  const customConfig = {
    heightMin: 200,
  };
  const pageType = PageConstants.pageType.docsCreate;
  const breadcrumb = generateRoutesAndTitle({
    type: pageType,
    data: {
      companyId, currentTeam, currentBucket, currentBucketDoc, bucketDocPath,
    },
  });
  SearchJumpToActions.saveRecentPage({
    company: currentCompany,
    team: currentTeam,
    userId: user?._id,
    itemId: `${bucketId}-create`,
    url: breadcrumb.url,
    routes: breadcrumb.routes,
    name: breadcrumb.title,
    type: pageType,
  });

  return (
    <>
      <Title title="Buat Dokumen" />
      <GeneralSubNavBar
        breadcrumbRoutes={breadcrumb.routes}
        pageTitle={breadcrumb.title}
        bottomBarOverviewOptionId="buckets"
        withBottomNavBar={false}
      />
      <LayoutMain>
        <PageLoading wait="createDocPage" loadings={loadings}>
          <SurfacePaperMain>
            <div className={styles.container}>
              <div className={styles.headerSection}>
                <div className={styles.inputTitleSection}>
                  <TextareaAutosize placeholder="Ketik judul..." onClick={disableShowEmojiPicker} onChange={(event) => handleChanged(event)} minRows={1} maxRows={2} value={newDoc.value} />
                  <MoodIcon onClick={handleShowEmojiPicker} />
                  {showEmojiPicker
                    ? (
                      <div className={styles.emojiPicker}>
                        <EmojiPicker onEmojiClick={onEmojiClick} />
                      </div>
                    ) : null}
                </div>
              </div>
              <SeparatorLine />
              <div className={styles.docsSection}>
                {currentTeam.members ? <EditorFull companyId={companyId} model={editorModel} onModelChange={handleEditorModelChange} triggerLists={currentTeam.members} type="doc" handleAction={handleTriggerPostDoc} customConfig={customConfig} /> : null }
              </div>
              <SeparatorLine />

              <div className={styles.manageSubscribers}>
                <ManageSubscribersContainer
                  type="doc"
                  initialSubscribers={subscribers}
                  handleSetSubscribers={handleSetSubscribers}
                  companyId={companyId}
                />
              </div>

              <InputSwitchMain
                title="Apakah dokumen ini Rahasia untuk Penerima aja?"
                labelSwitch="Rahasia"
                handleChange={handleChangeAccess}
              />

              <div className={styles.actionSection}>
                <div>
                  <Button handleClick={handlePostDoc} variant="success" spinnerSize="sm" wait="createDoc" loadings={loadings}>Publikasikan</Button>
                  <Button handleClick={handleCancel} variant="danger">Batal</Button>
                </div>

              </div>
            </div>
          </SurfacePaperMain>
        </PageLoading>

      </LayoutMain>
    </>
  );
}

export default CreateDocPage;
