import React from 'react';
import CloseIcon from '@mui/icons-material/Close';
import PropTypes from 'prop-types';
import BoxPopOver from '../../../components/UI/BoxPopOver/BoxPopOver';
import SeparatorLine from '../../../components/UI/SeparatorLine/SeparatorLine';
import styles from './ChangeNameFormWithBox.module.css';
import ChangeNameForm from '../ChangeNameForm/ChangeNameForm';
import { NavigationMenuBasic } from '../../../components/UI_V2/Navigation/Menu';

const ChangeNameFormWithBox = ({ card, cancel }) => (

  // <BoxPopOver customStyle={{ width: '20vw', minWidth: '250px' }} left>
  <NavigationMenuBasic size="lg" onClose={cancel} position="topLeft">
    <div className={styles.container}>
      <div className={styles.headerSection}>
        <h1>
          Ubah nama Tugas
        </h1>
        <CloseIcon onClick={cancel} />
      </div>
      <SeparatorLine />
      <div className={styles.bodySection}>
        <ChangeNameForm card={card} cancel={cancel} withSaveButton />
      </div>
    </div>
  </NavigationMenuBasic>
  // </BoxPopOver>
);

ChangeNameFormWithBox.propTypes = {
  card: PropTypes.object.isRequired,
  cancel: PropTypes.func.isRequired,
};

export default ChangeNameFormWithBox;
