import React, { memo, useState } from 'react';
import PropTypes from 'prop-types';
import { Box, Skeleton } from '@mui/material';
import styles from './Body.module.css';

const PostBlastPageSkeletonBody = () => (
  <Box className={styles.container}>
    <Skeleton
      animation="wave"
      variant="text"
      width={200}
    />
    <Skeleton
      animation="wave"
      variant="text"
    />
    <Skeleton
      animation="wave"
      variant="text"
    />
    <Skeleton
      animation="wave"
      variant="text"
    />
    <Box className={styles.image}>
      <Skeleton
        animation="wave"
        variant="rectangular"
        width={200}
        height={200}
      />
    </Box>
    <Skeleton
      animation="wave"
      variant="text"
    />
    <Skeleton
      animation="wave"
      variant="text"
    />
    <Skeleton
      animation="wave"
      variant="text"
    />
    <Skeleton
      animation="wave"
      variant="text"
    />
    <Skeleton
      animation="wave"
      variant="text"
    />
    <Skeleton
      animation="wave"
      variant="text"
    />
    <Skeleton
      animation="wave"
      variant="text"
    />
  </Box>
);

export default memo(PostBlastPageSkeletonBody);
