import React, { memo, useState } from 'react';
import PropTypes from 'prop-types';
import HomeWorkOutlinedIcon from '@mui/icons-material/HomeWorkOutlined';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import styles from './MemberItemUserReport.module.css';
import Color from '../../../../../themes/colors';
import { limitChar } from '../../../../../utilities/stringUtil';
import { DisplayTextBody } from '../../../../../components/UI_V2/Display/Text';
import { DisplayAvatarMember } from '../../../../../components/UI_V2/Display/Avatar';

const selectedStyles = {
  false: '',
  true: styles.selected,
};

const MemberItemUserReport = ({ member, isSelected }) => (
  <div className={`${styles.container} ${selectedStyles[isSelected]}`}>
    <div className={styles.icon}>
      <DisplayAvatarMember src={member?.photoUrl} size="xs" />
    </div>
    <div className={styles.name}>
      <DisplayTextBody
        mode="12"
        decoration="regular"
        color={Color.black}
        fontFamily="openSans"
      >
        {limitChar(member?.fullName, 20)}
      </DisplayTextBody>
    </div>
  </div>
);

MemberItemUserReport.propTypes = {
  member: PropTypes.object.isRequired,
  isSelected: PropTypes.bool,
};

MemberItemUserReport.defaultProps = {
  isSelected: false,
};

export default memo(MemberItemUserReport);
