import React, { memo, useState } from 'react';
import PropTypes, { oneOf } from 'prop-types';
import TextareaAutosize from 'react-textarea-autosize';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import styles from './Main.module.css';
import Color from '../../../../../themes/colors';

const borderVariantStyles = {
  primary: styles.primary,
  secondary: styles.secondary,
  danger: styles.danger,
  success: styles.success,
};

const borderVariantFocusStyles = {
  primary: styles.primaryFocus,
  secondary: styles.secondaryFocus,
  danger: styles.dangerFocus,
  success: styles.successFocus,
};

const borderStyles = {
  default: {
    borderRadius: '5px',
  },
  semiRounded: {
    borderRadius: '10px',
  },
  rounded: {
    borderRadius: '15px',
  },
};

const boxSizeStyles = {
  sm: {
    minHeight: '26px',
  },
  md: {
    minHeight: '32px',
  },
  lg: {
    minHeight: '38px',
  },
};

const inputSizeStyles = {
  sm: {
    fontSize: '10px',
  },
  md: {
    fontSize: '12px',
  },
  lg: {
    fontSize: '14px',
  },
};

const inputCursorStyles = {
  default: {},
  pointer: {
    cursor: 'pointer',
  },
};

const helperIconColorStyles = {
  primary: styles.helperIconPrimaryColor,
  secondary: styles.helperIconSecondaryColor,
  danger: styles.helperIconDangerColor,
  success: styles.helperIconSuccessColor,
};

const helperIconSizeStyles = {
  sm: styles.helperIconSizeSm,
  md: styles.helperIconSizeMd,
  lg: styles.helperIconSizeLg,
};

const InputTextFieldMain = ({
  placeholder,
  name,
  onChange,
  onClick,
  value,
  multiline,
  autoFocus,
  border,
  size,
  minRows,
  maxRows,
  cursor,
  variant,
  type,
  showHelperIcon,
  helperIcon,
  onClickHelperIcon,
  id,
}) => {
  const [isFocused, setIsFocused] = useState(false);

  const handleFocus = () => {
    setIsFocused(true);
  };

  const handleBlur = () => {
    setIsFocused(false);
  };

  return (
    <div
      style={{
        ...borderStyles[border],
        ...boxSizeStyles[size],
      }}
      className={
        `${styles.container} ${isFocused ? borderVariantFocusStyles[variant] : borderVariantStyles[variant]} ${multiline ? styles.containerMultiline : ''}`
      }
      onClick={onClick}
    >
      {!multiline && (
      <>
        <input
          id={id || 'inputTextFieldMain'}
          className={`${styles.input}`}
          style={{
            ...inputSizeStyles[size],
            ...inputCursorStyles[cursor],
          }}
          // eslint-disable-next-line jsx-a11y/no-autofocus
          autoFocus={autoFocus}
          placeholder={placeholder}
          name={name}
          onChange={onChange}
          value={value}
          type={type}
          onFocus={handleFocus}
          onBlur={handleBlur}
        />
        {showHelperIcon && (
        <div
          className={`${styles.helperIcon} ${helperIconColorStyles[variant]} ${helperIconSizeStyles[size]}`}
          onClick={onClickHelperIcon}
        >
          {helperIcon}
        </div>
        )}
      </>
      )}
      {multiline && (
      <>
        <TextareaAutosize
          id={id || 'inputTextFieldMain'}
          style={{
            ...inputSizeStyles[size],
            ...inputCursorStyles[cursor],
          }}
          autoFocus={autoFocus}
          className={`${styles.input}`}
          placeholder={placeholder}
          name={name}
          onChange={onChange}
          value={value}
          minRows={minRows}
          maxRows={maxRows}
          type={type}
          onFocus={handleFocus}
          onBlur={handleBlur}
        />
        {showHelperIcon && (
        <div
          className={`${styles.helperIcon} ${helperIconColorStyles[variant]} ${helperIconSizeStyles[size]}`}
          onClick={onClickHelperIcon}
        >
          {helperIcon}
        </div>
        )}
      </>
      )}
    </div>
  );
};

InputTextFieldMain.propTypes = {
  autoFocus: PropTypes.bool,
  placeholder: PropTypes.string,
  name: PropTypes.string,
  minRows: PropTypes.number,
  maxRows: PropTypes.number,
  cursor: PropTypes.oneOf(['default', 'pointer']),
  variant: PropTypes.oneOf(['primary', 'secondary', 'danger', 'success']),
  size: PropTypes.oneOf(['sm', 'md', 'lg']),
  border: PropTypes.oneOf(['default', 'semiRounded', 'rounded']),
  onChange: PropTypes.func.isRequired,
  value: PropTypes.string.isRequired,
  onClick: PropTypes.func,
  multiline: PropTypes.bool,
  type: PropTypes.oneOf(['text', 'number', 'tel', 'password', 'email']),
  id: PropTypes.string,
  showHelperIcon: PropTypes.bool,
  helperIcon: PropTypes.node,
  onClickHelperIcon: PropTypes.func,
};

InputTextFieldMain.defaultProps = {
  autoFocus: false,
  size: 'md',
  border: 'default',
  cursor: 'default',
  variant: 'primary',
  placeholder: null,
  name: null,
  minRows: 4,
  maxRows: 4,
  onClick: () => null,
  multiline: false,
  type: 'text',
  id: undefined,
  showHelperIcon: false,
  helperIcon: <HelpOutlineIcon />,
  onClickHelperIcon: () => null,
};

export default memo(InputTextFieldMain);
