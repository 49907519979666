import React, { useEffect, useRef } from 'react';
import CloseIcon from '@mui/icons-material/Close';
import PropTypes from 'prop-types';
import BoxPopOver from '../../../components/UI/BoxPopOver/BoxPopOver';
import SeparatorLine from '../../../components/UI/SeparatorLine/SeparatorLine';
import styles from './ManageDescWithBox.module.css';
import ManageDesc from '../ManageDesc/ManageDesc';
import { NavigationMenuBasic } from '../../../components/UI_V2/Navigation/Menu';

const ManageDescWithBox = ({ card, cancel, disableScrollIntoView }) => {
  const itemRef = useRef(null);

  useEffect(() => {
    if (itemRef.current && !disableScrollIntoView) {
      // make sure all element has been loaded
      setTimeout(() => {
        itemRef.current.scrollIntoView({ behavior: 'smooth' });
      }, 100);
    }
  }, []);

  return (
    // <BoxPopOver customStyle={{ width: '40vw', minWidth: '350px' }} left>
    <NavigationMenuBasic size="lgWider" onClose={cancel} position="topLeft">
      <div className={styles.container}>
        <div ref={itemRef} className={styles.headerSection}>
          <h1>
            Ubah Catatan
          </h1>
          <CloseIcon onClick={cancel} />
        </div>
        <SeparatorLine />
        <div className={styles.bodySection}>
          <ManageDesc card={card} autoOpen />
        </div>
      </div>
    </NavigationMenuBasic>
  // </BoxPopOver>
  );
};

ManageDescWithBox.propTypes = {
  card: PropTypes.object.isRequired,
  cancel: PropTypes.func.isRequired,
  disableScrollIntoView: PropTypes.bool.isRequired,
};

export default ManageDescWithBox;
