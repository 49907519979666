import React, { memo, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import styles from './Basic.module.css';
import { SurfacePaperFloating } from '../../../Surface/Paper';

const NavigationMenuBasic = ({
  onClose,
  disabled,
  children,
  position,
  size,
}) => (
  <SurfacePaperFloating
    size={size}
    onOutsideClick={onClose}
    disabled={disabled}
    position={position}
  >
    <div className={styles.container}>
      {children}
    </div>
  </SurfacePaperFloating>
);

NavigationMenuBasic.propTypes = {
  size: PropTypes.oneOf(['xs', 'sm', 'md', 'lg']),
  onClose: PropTypes.func,
  children: PropTypes.node,
  disabled: PropTypes.bool,
  position: PropTypes.oneOf(['topLeft', 'topRight', 'bottomLeft', 'bottomRight', 'centerRight', 'centerLeft']),
};

NavigationMenuBasic.defaultProps = {
  onClose: () => null,
  children: null,
  disabled: false,
  position: 'topLeft',
  size: 'sm',
};

export default memo(NavigationMenuBasic);
