import React, { memo, useState } from 'react';
import PropTypes from 'prop-types';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import styles from './UserReportPageSkeleton.module.css';
import Color from '../../../../themes/colors';
import UserReportPageSkeletonHeader from './Header/Header';
import UserReportPageSkeletonBody from './Body/Body';

const UserRerportPageSkeleton = () => (
  <div className={styles.container}>
    <div className={styles.header}>
      <UserReportPageSkeletonHeader />
    </div>
    <div className={styles.body}>
      <UserReportPageSkeletonBody />
    </div>
  </div>
);

UserRerportPageSkeleton.propTypes = {};

UserRerportPageSkeleton.defaultProps = {};

export default memo(UserRerportPageSkeleton);
