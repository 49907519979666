import React, {
  useState, useEffect, useCallback, useContext,
} from 'react';
import draftToHtml from 'draftjs-to-html';
import PropTypes from 'prop-types';
import ArchiveOutlinedIcon from '@mui/icons-material/ArchiveOutlined';
import CheckCircleOutlineOutlinedIcon from '@mui/icons-material/CheckCircleOutlineOutlined';
import ModeEditOutlinedIcon from '@mui/icons-material/ModeEditOutlined';
import { DisplayBadgeCounter } from '../../../components/UI_V2/Display/Badge';
import styles from './Post.module.css';
import { DisplayDateBox } from '../../../components/UI_V2/Display/Date';
import PrivateIcon from '../../../components/UI/PrivateIcon/PrivateIcon';
import checkIsJson from '../../../utilities/checkIsJson';
import { GlobalContext } from '../../../contexts/GlobalStateProvider';
import { PostConstants } from '../../../constants';
import Color from '../../../themes/colors';
import { DisplayTextSubHeadline, DisplayTextBody } from '../../../components/UI_V2/Display/Text';
import LinkNoDecor from '../../../components/LinkNoDecor/LinkNoDecor';
import BlastPostSubAction from './SubAction/SubAction';
import OverlayButton from '../../../components/UI/Button/OverlayButton/OverlayButton';
import { DisplayAvatarMember } from '../../../components/UI_V2/Display/Avatar';
import { checkIfUserAuthorizedToEditData } from '../../../actions/UserActions';

const borderVariantStyles = {
  square: styles.borderSquare,
  squareSemiRoundedTopLeft: styles.boderSquareSemiRoundedTopLeft,
  squareSemiRoundedTopRight: styles.boderSquareSemiRoundedTopRight,
  squareSemiRoundedBottomRight: styles.boderSquareSemiRoundedBottomRight,
  squareSemiRoundedBottomLeft: styles.boderSquareSemiRoundedBottomLeft,
  semiRounded: styles.borderSemiRounded,
};

const borderLineVariantStyles = {
  disabled: styles.borderLineDisabled,
  regularThin: styles.borderLineRegularThin,
};

const Post = ({
  post,
  url,
  handleClickEdit,
  handleClickArchive,
  handleClickComplete,
  waitComplete,
  loadings,
  borderVariant,
  borderLineVariant,
}) => {
  const [{ user, currentRoleUser, currentTeamBelowRole }] = useContext(GlobalContext);
  const [parsedContent, setParsedContent] = useState('');
  const [parsedTitle, setParsedTitle] = useState('');
  const [isPostComplete, setCompletePost] = useState(false);
  const [showSubAction, setShowSubAction] = useState(false);
  const [isPostAutoCompleteDueDate, setPostAutoCompleteDueDate] = useState(false);

  useEffect(() => {
    let initialContent;
    if (checkIsJson(post.content)) {
      initialContent = draftToHtml(JSON.parse(post.content));
    } else {
      initialContent = post.content;
    }

    initialContent = initialContent.replace(/<\/?("[^"]*"|'[^']*'|[^>])*(>|$)/g, '').replace(/\n/g, ' ');

    if (initialContent.length > 500) {
      initialContent = `${initialContent.slice(0, 500)}...`;
    }

    if (post?.complete?.status !== undefined) {
      setCompletePost(post?.complete?.status);
    }

    if (post?.autoComplete?.status !== undefined) {
      setPostAutoCompleteDueDate(post?.autoComplete?.status);
    }

    setParsedContent(initialContent);

    let tempTitle;
    if (post.title.length < 100) {
      tempTitle = post.title;
    } else {
      tempTitle = `${post.title.slice(0, 100)}...`;
    }

    setParsedTitle(tempTitle);
  }, [post]);

  const handleShowSubAction = useCallback(() => {
    setShowSubAction(true);
  }, []);

  const handleHideSubAction = useCallback(() => {
    setShowSubAction();
  }, []);

  const isEnableShowSubAction = handleClickEdit && handleClickArchive && handleClickComplete;

  return (
    // eslint-disable-next-line jsx-a11y/mouse-events-have-key-events
    <div
      className={`${styles.container} ${borderVariantStyles[borderVariant]} ${borderLineVariantStyles[borderLineVariant]}`}
      // onMouseOver={isEnableShowSubAction && handleShowSubAction}
      // onMouseLeave={isEnableShowSubAction && handleHideSubAction}
    >
      <LinkNoDecor to={url}><div className={styles.link} /></LinkNoDecor>
      <div className={styles.photoSection}>
        <DisplayAvatarMember src={post.creator?.photoUrl} size="md" />
      </div>
      <div className={styles.contentSection}>
        <div className={styles.contentSection__header}>
          <div className={styles.contentSection__header__title}>
            <div className={styles.contentSection__header__title__text}>
              <DisplayTextSubHeadline
                mode="22"
                decoration="bold"
                color={Color.grayIconEditDocs}
              >
                <PrivateIcon data={post} size="small" />
                {' '}
                {parsedTitle}
              </DisplayTextSubHeadline>
            </div>
            {showSubAction && (
            <div className={styles.contentSection__header__title__icon}>
              <div className={styles.boxTransparentIconHeader}>
                <div className={styles.boxTransparentIconHeader__background} />
              </div>
              {!isPostComplete && !isPostAutoCompleteDueDate
              && checkIfUserAuthorizedToEditData(post,
                user, currentRoleUser, currentTeamBelowRole) && (
                <div className={styles.contentSection__header__title__icon__subaction}>
                  <OverlayButton wait={waitComplete} loadings={loadings}>
                    <BlastPostSubAction
                      icon={<CheckCircleOutlineOutlinedIcon />}
                      onClick={handleClickComplete}
                    />
                  </OverlayButton>
                </div>
              )}
              {checkIfUserAuthorizedToEditData(post,
                user, currentRoleUser, currentTeamBelowRole) && (
                <>
                  <div className={styles.contentSection__header__title__icon__subaction}>
                    <BlastPostSubAction
                      icon={<ModeEditOutlinedIcon />}
                      onClick={handleClickEdit}
                    />
                  </div>
                  <div className={styles.contentSection__header__title__icon__subactionLast}>
                    <BlastPostSubAction
                      icon={<ArchiveOutlinedIcon />}
                      onClick={handleClickArchive}
                    />
                  </div>
                </>
              )}
              <div className={styles.boxTransparentIconHeader} />
            </div>
            )}
          </div>
          <div className={styles.contentSection__header__date}>
            <DisplayDateBox date={post.createdAt} relativeTime dateStyle="MonthDate" />
            {isPostComplete
              ? <DisplayDateBox icon date={post.complete?.updatedAt} changeColorDate complete={PostConstants.completeDueDate} dateStyle="MonthDate" />
              : <DisplayDateBox icon date={isPostAutoCompleteDueDate ? post.autoComplete.date : post.dueDate} changeColorDate dateStyle="MonthDate" />}
            {isPostAutoCompleteDueDate && <DisplayTextBody mode="11" color={Color.gray4}>Selesai Otomatis</DisplayTextBody>}
          </div>
          <div className={styles.creator}>
            <div>
              <DisplayTextSubHeadline
                mode="14"
                color={Color.blueNavy4}
              >
                {post.creator?.fullName}
              </DisplayTextSubHeadline>
            </div>
            <div className={styles.bodySection__counter}>
              <DisplayBadgeCounter
                badgeContent={post?.comments?.length}
                backgroundColor={Color.deleteColor}
                height="20px"
                minWidth="20px"
                fontSize="0.7rem"
              />
            </div>
          </div>
        </div>
        <div className={styles.contentSection__content}>
          <DisplayTextBody>
            {parsedContent}
          </DisplayTextBody>
        </div>
      </div>
    </div>
  );
};

Post.propTypes = {
  post: PropTypes.object.isRequired,
  url: PropTypes.string.isRequired,
  handleClickArchive: PropTypes.func.isRequired,
  handleClickComplete: PropTypes.func.isRequired,
  handleClickEdit: PropTypes.func.isRequired,
  waitComplete: PropTypes.string.isRequired,
  loadings: PropTypes.array.isRequired,
  borderVariant: PropTypes.oneOf(['square',
    'semiRounded',
    'squareSemiRoundedTopLeft',
    'squareSemiRoundedTopRight',
    'squareSemiRoundedBottomLeft',
    'squareSemiRoundedBottomRight',
  ]),
  borderLineVariant: PropTypes.oneOf(['disabled', 'regularThin']),
};

Post.defaultProps = {
  borderVariant: 'semiRounded',
  borderLineVariant: 'regularThin',
};

export default Post;
