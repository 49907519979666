import React, {
  memo, useCallback, useContext, useState,
} from 'react';
import PropTypes from 'prop-types';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { useParams } from 'react-router-dom';
import { useMediaQuery } from '@mui/material';
import styles from './ActivityContainer.module.css';
import Color from '../../themes/colors';
import HeaderActivity from './HeaderActivity/HeaderActivity';
import ListActivity from './ListActivity/ListActivity';
import FilterActivity from './FilterActivity/FilterActivity';
import { GlobalContext } from '../../contexts/GlobalStateProvider';
import { ActivityConstants } from '../../constants';
import { SurfacePaperFloating } from '../../components/UI_V2/Surface/Paper';
import { getStoreFilterActivity, setStoreFilterActivity } from '../../utilities/localStorage';
import { DisplayDividerMain } from '../../components/UI_V2/Display/Divider';

const ActivityContainer = ({ params, cancel, position }) => {
  const [{ user, currentCompany, teams }] = useContext(GlobalContext);
  // const params = useParams();

  const { companyId } = params;

  const initialFilterActivity = getStoreFilterActivity(
    { companyId, userId: user._id },
  );

  const initialFilterTeams = initialFilterActivity?.filterTeams || teams.map((team) => team._id);
  // eslint-disable-next-line max-len
  // const initialFilterFeatureTypes = ActivityConstants.featureTypeList.map((feature) => feature._id).filter((featureId) => featureId === 'team');
  const initialFilterFeatureTypes = ActivityConstants.featureTypeList.map((feature) => feature._id);

  const [filters, setFilters] = useState({
    filterTeams: initialFilterTeams,
    filterFeatureTypes: initialFilterFeatureTypes,
  });

  const onApplyFilters = useCallback((filtersValue) => {
    setFilters(filtersValue);
    setStoreFilterActivity({
      companyId,
      userId: user._id,
      filter: filtersValue,
    });
  }, []);

  const customStyle = {
    height: '84vh',
  };

  const isMobile = useMediaQuery('(max-width:720px)');

  // if (isMobile) {
  //   customStyle.width = '330px';
  //   customStyle.right = '-3.6rem';
  // }

  return (
    <SurfacePaperFloating
      onOutsideClick={cancel}
      size="mlg"
      customStyle={customStyle}
      position={position}
    >
      <div className={styles.container}>
        <div className={styles.header}>
          <HeaderActivity />
        </div>
        {/* <DisplayDividerMain marginTop="0.3rem" marginBottom="0.5rem" /> */}
        <div className={styles.filter}>
          <FilterActivity
            onApplyFilters={onApplyFilters}
            listTeams={teams}
            listFeatureTypes={ActivityConstants.featureTypeList}
            initialFilterTeams={filters.filterTeams}
            initialFilterFeatureTypes={filters.filterFeatureTypes}
          />
        </div>
        <DisplayDividerMain borderColor={Color.gray6} marginTop="0.5rem" marginBottom="0.5rem" />
        <div id="listActivities" className={styles.list}>
          <ListActivity
            filters={filters}
            params={params}
            company={currentCompany}
          />
        </div>
      </div>

    </SurfacePaperFloating>
  );
};

ActivityContainer.propTypes = {
  cancel: PropTypes.func.isRequired,
  params: PropTypes.object.isRequired,
  position: PropTypes.string,
};

ActivityContainer.defaultProps = {
  position: 'topLeft',
};

export default memo(ActivityContainer);
