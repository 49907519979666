import React, {
  memo, useState, useEffect, useCallback, useContext,
} from 'react';
import { useSnackbar } from 'notistack';
import { useParams, useLocation } from 'react-router-dom';
import SendIcon from '@mui/icons-material/Send';
import AttachFileIcon from '@mui/icons-material/AttachFile';
import PropTypes from 'prop-types';
import { useResizeDetector } from 'react-resize-detector';
import { useMediaQuery } from '@mui/material';
import OverlayButton from '../../../../components/UI/Button/OverlayButton/OverlayButton';
import { GlobalContext } from '../../../../contexts/GlobalStateProvider';
import styles from './CreateMessage.module.css';
import AttachFileContainer from '../../../AttachFileContainer/AttachFileContainer';
import EditorMin from '../../../FroalaEditor/EditorMin/EditorMin';
import extractElementBySelectorFromString from '../../../../utilities/extractElementBySelectorFromString';
import handleStatusMsg from '../../../../utilities/handleStatusMsg';
import handleLoadings from '../../../../utilities/handleLoadings';
import { ApiConstants } from '../../../../constants';
import apiUtil from '../../../../utilities/apiUtil';
import { ChatActions } from '../../../../actions';
import { useIsTyping, useUpdateUserTypingStatus } from '../../../../hooks/UserTypingHooks';
import { elementId } from '../../../../constants/ElementIdConstants';

const editorTextStyles = {
  chat: {},
  groupChat: {
    width: '68vw',
  },
  mobile: {
    width: '62vw',
  },
};

const CreateMessage = ({
  handleChangeHeightDivMessagesSection, handleLoading, chatWith, type,
  socket, handleUploadFiles,
}) => {
  const [{ user }, dispatch] = useContext(GlobalContext);
  const [postMessage, setPostMessage] = useState();
  const { enqueueSnackbar } = useSnackbar();
  const [loadings, setLoadings] = useState([]);
  const [editorModel, setEditorModel] = useState();
  const [trigger, setTrigger] = useState();
  const [isAction, setIsAction] = useState();
  const params = useParams();
  const location = useLocation();

  const {
    companyId, teamId, chatId, groupChatId,
  } = params;

  const [isTyping, handleTyping] = useIsTyping({});
  useUpdateUserTypingStatus({ isTyping, socket, userId: user?._id });

  const handleCancel = () => {
    setEditorModel();
    setIsAction(Math.random());
  };

  const isChat = type === 'chat';

  useEffect(() => {
    if (postMessage === undefined) {
      return;
    }

    const postApiMessage = async () => {
      try {
        const startLoadings = handleLoadings(`create-${type}-message`, [...loadings], 'start');
        setLoadings([...startLoadings]);
        switch (type) {
          case 'groupChat':
            await apiUtil.post(
              ApiConstants.URL_V1.GROUP_CHAT_MESSAGE({ groupChatId }),
              postMessage,
              {
                params: {
                  companyId,
                  teamId,
                },
              },
            );
            break;
          case 'chat':
            await apiUtil.post(
              ApiConstants.URL_V1.CHAT_MESSAGE({ chatId }),
              postMessage,
              {
                params: {
                  companyId,
                },
              },
            );
            // update unread messages
            ChatActions.setCurrentChatUnreadMessagesCounter(0, dispatch);
            break;
          default:
            await apiUtil.post(
              ApiConstants.URL_V1.CHAT_MESSAGE({ chatId }),
              postMessage,
              {
                params: {
                  companyId,
                },
              },
            );
            // update unread messages
            ChatActions.setCurrentChatUnreadMessagesCounter(0, dispatch);
            break;
        }
        // clear here if only no error happens so the text wont dissapear
        handleCancel();
      } catch (err) {
        const status = handleStatusMsg(err, 'error');

        enqueueSnackbar(status.message, {
          variant: 'error',
        });
      } finally {
        const endLoadings = handleLoadings(`create-${type}-message`, [...loadings], 'end');
        setLoadings([...endLoadings]);
      }
    };

    postApiMessage();
  }, [postMessage]);

  useEffect(() => {
    if (trigger === undefined) return;

    // prevent double post
    const checkWait = loadings.filter((loading) => loading === `create-${type}-message`);
    if (checkWait.length > 0) return;

    // prevent empty post
    if (!editorModel) {
      handleCancel();
      return;
    }

    const mentionedUsers = extractElementBySelectorFromString(editorModel, '#mentioned-user');

    const newMessage = { content: editorModel, mentionedUsers };

    setPostMessage(newMessage);
  }, [trigger]);

  const handleTriggerPostMessage = () => {
    setTrigger(Math.random());
  };

  const handleEditorModelChange = (model) => {
    setEditorModel(model);
  };

  // Change height text area
  const defaultHeightEditorMin = 9;

  const onResize = useCallback((width, height) => {
    handleChangeHeightDivMessagesSection(height + defaultHeightEditorMin);
  }, []);

  const { width, height, ref } = useResizeDetector({
    handleWidth: false,
    onResize,
  });

  const isTablet = useMediaQuery('(max-width:1024px)');
  const isMobile = useMediaQuery('(max-width:720px)');

  const mobileEditorTextStyles = isMobile && editorTextStyles.mobile;

  return (
    <div className={styles.container} ref={ref}>
      <AttachFileContainer
        handleUploadForm={handleUploadFiles}
        handleLoading={handleLoading}
        type={type}
      >
        <div
          id={isChat ? elementId.chatMessageAttachmentButton
            : elementId.groupChatMessageAttachmentButton}
          className={styles.attachment}
        >
          <AttachFileIcon />
        </div>
      </AttachFileContainer>
      <div
        className={styles.text}
        style={{
          ...editorTextStyles[type],
          ...mobileEditorTextStyles,
        }}
      >
        {chatWith
          ? (
            <EditorMin
              model={editorModel}
              onModelChange={handleEditorModelChange}
              triggerLists={chatWith}
              type={type}
              handleAction={handleTriggerPostMessage}
              isAction={isAction}
              companyId={companyId}
              handleTyping={handleTyping}
            />
          ) : null}
      </div>
      <OverlayButton handleClick={handleTriggerPostMessage} wait={`create-${type}-message`} loadings={loadings}>
        <div
          id={isChat ? elementId.chatMessageSendButton : elementId.groupChatMessageSendButton}
          className={styles.send}
          onClick={handleTriggerPostMessage}
        >
          <SendIcon />
        </div>
      </OverlayButton>
    </div>
  );
};

CreateMessage.propTypes = {
  handleChangeHeightDivMessagesSection: PropTypes.func.isRequired,
  handleLoading: PropTypes.func.isRequired,
  chatWith: PropTypes.object.isRequired,
  type: PropTypes.string,
  socket: PropTypes.object.isRequired,
  handleUploadFiles: PropTypes.func.isRequired,
};

CreateMessage.defaultProps = {
  type: 'chat',
};

export default memo(CreateMessage);
