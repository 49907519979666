import React, {
  useState, useEffect,
} from 'react';
import { useLocation } from 'react-router-dom';
import draftToHtml from 'draftjs-to-html';
import PropTypes from 'prop-types';
import styles from './MessageSnapshot.module.css';
import DisplayAvatarMember from '../../../../components/UI_V2/Display/Avatar/Member';
import checkIsJson from '../../../../utilities/checkIsJson';

const MessageSnapshot = ({ message }) => {
  const [isInitialContentReady, setIsInitialContentReady] = useState(false);
  const [editorModel, setEditorModel] = useState();

  const location = useLocation();

  const isMessageDeleted = message.isDeleted;

  useEffect(() => {
    let initialContent;
    if (checkIsJson(message.content)) {
      initialContent = draftToHtml(JSON.parse(message.content));
    } else {
      initialContent = message.content;
    }

    setEditorModel(initialContent);
    setIsInitialContentReady(true);
  }, [message]);

  return (
    <div key={message._id} className={styles.Message}>
      <div className={styles.mainContainerNotSelf}>
        <div className={styles.photoSection}>
          <DisplayAvatarMember
            src={message.creator?.photoUrl}
            size="sm"
          />
        </div>

        <div className={styles.bodySection} style={{ backgroundColor: '#ebecf1' }}>
          <div className={styles.headerMessage}>
            <div className={styles.senderName}>
              <p>{message.creator?.fullName}</p>
            </div>
          </div>
          <div className={styles.contentMessage}>
            {isMessageDeleted ? <p><i>{message.content}</i></p> : <p>{message.content}</p>}
          </div>
        </div>
      </div>
    </div>
  );
};

MessageSnapshot.propTypes = {
  message: PropTypes.object.isRequired,
};

export default MessageSnapshot;
