import React, { memo, useState, useContext } from 'react';
import { useSnackbar } from 'notistack';
import { useHistory } from 'react-router-dom';
import { MenuItem } from '@mui/material';
import PropTypes, { element } from 'prop-types';
import styles from './SceneFillingProfile.module.css';
import { GlobalContext } from '../../../../contexts/GlobalStateProvider';
import { DisplayTextBody, DisplayTextHeadline, DisplayTextSubHeadline } from '../../../../components/UI_V2/Display/Text';
import Color from '../../../../themes/colors';
import { InputTextFieldMain } from '../../../../components/UI_V2/Input/TextField';
import { InputButtonMain } from '../../../../components/UI_V2/Input/Button';
import { InputSelectMain } from '../../../../components/UI_V2/Input/Select';
import handleStatusMsg from '../../../../utilities/handleStatusMsg';
import handleLoadings from '../../../../utilities/handleLoadings';
import { UserActions } from '../../../../actions';
import { isValidIDPhoneNumber } from '../../../../utilities/regexUtil';
import { limitChar } from '../../../../utilities/stringUtil';
import { elementId } from '../../../../constants/ElementIdConstants';

const SceneFillingProfile = ({
  handleClickNext,
}) => {
  const [{
    user,
  }, dispatch] = useContext(GlobalContext);

  const [newCurrentUser, setNewCurrentUser] = useState({
    fullName: user?.fullName || '',
    bio: user?.bio || '',
    status: user?.status || '',
    age: user?.age || '',
    location: user?.location || '',
    country: user?.country || 'ID',
    phoneNumber: user?.phoneNumber || null,
    gender: user?.gender || 'man',
    appUsedBeforeCicle: user?.appUsedBeforeCicle || 'waTrello',
    whereKnowCicle: user?.whereKnowCicle || 'ig',
    reasonUsingCicle: user?.reasonUsingCicle || '',
    teamSizeCategory: user?.teamSizeCategory || 'micro',
    companyRemoteOrOfflineStatus: user?.companyRemoteOrOfflineStatus || 'hybrid',
  });

  const { enqueueSnackbar } = useSnackbar();
  const [loadings, setLoadings] = useState([]);
  const history = useHistory();

  const handleChanged = (event) => {
    const { name, value } = event.target;
    setNewCurrentUser((prevValue) => ({
      ...prevValue,
      [name]: value,
    }));
  };

  const handleClicked = () => {
    // execute save profile
    if (newCurrentUser.fullName === undefined || newCurrentUser.fullName.length < 1) {
      enqueueSnackbar('Name cannot be empty', {
        variant: 'error',
      });
      return;
    }

    if (newCurrentUser.status === undefined || newCurrentUser.status.length < 1) {
      enqueueSnackbar('Job Title cannot be empty', {
        variant: 'error',
      });
      return;
    }

    if (newCurrentUser.age === undefined || newCurrentUser.age.length < 1) {
      enqueueSnackbar('Age cannot be empty', {
        variant: 'error',
      });
      return;
    }

    if (newCurrentUser.location === undefined || newCurrentUser.location.length < 1) {
      enqueueSnackbar('City you lived in cannot be empty', {
        variant: 'error',
      });
      return;
    }

    if (newCurrentUser.country === undefined || newCurrentUser.country.length < 1) {
      enqueueSnackbar('Country cannot be empty', {
        variant: 'error',
      });
      return;
    }

    if (newCurrentUser.gender === undefined || newCurrentUser.gender.length < 1) {
      enqueueSnackbar('Gender cannot be empty', {
        variant: 'error',
      });
      return;
    }

    if (newCurrentUser.appUsedBeforeCicle === undefined
      || newCurrentUser.appUsedBeforeCicle.length < 1) {
      enqueueSnackbar('Apps before Cicle cannot be empty', {
        variant: 'error',
      });
      return;
    }

    if (newCurrentUser.whereKnowCicle === undefined || newCurrentUser.whereKnowCicle.length < 1) {
      enqueueSnackbar('Where You Know Cicle cannot be empty', {
        variant: 'error',
      });
      return;
    }

    if (newCurrentUser.reasonUsingCicle === undefined
      || newCurrentUser.reasonUsingCicle.length < 1) {
      enqueueSnackbar('Problems with apps you used cannot be empty', {
        variant: 'error',
      });
      return;
    }

    if (newCurrentUser.teamSizeCategory === undefined
      || newCurrentUser.teamSizeCategory.length < 1) {
      enqueueSnackbar('Team members amount cannot be empty', {
        variant: 'error',
      });
      return;
    }

    if (newCurrentUser.companyRemoteOrOfflineStatus === undefined
      || newCurrentUser.companyRemoteOrOfflineStatus.length < 1) {
      enqueueSnackbar('Company Remote Status cannot be empty', {
        variant: 'error',
      });
      return;
    }

    const modifyPayloadWithValidityPhoneNumber = (userValue) => {
      const payload = {
        ...userValue,
      };

      if (!userValue?.phoneNumber || userValue?.phoneNumber === '') {
        delete payload.phoneNumber;
        return payload;
      }

      if (!isValidIDPhoneNumber(userValue.phoneNumber)) {
        return null;
      }

      return payload;
    };

    const postApiCurrentUser = async () => {
      const currentUserId = user?._id;

      try {
        const startLoadings = handleLoadings('editUser', [...loadings], 'start');
        setLoadings([...startLoadings]);

        const payload = modifyPayloadWithValidityPhoneNumber(newCurrentUser);

        if (!payload) {
          enqueueSnackbar('Phone number should in this format : +628474647484', {
            variant: 'error',
          });
          return;
        }

        const result = await UserActions.updateUser(
          { userId: currentUserId, body: payload },
          dispatch,
        );

        const status = handleStatusMsg(result, 'success');

        enqueueSnackbar(status.message, {
          variant: 'success',
        });

        setNewCurrentUser(result.data.user);

        // then execute handleClickNext
        handleClickNext();
      } catch (err) {
        const status = handleStatusMsg(err, 'error');

        enqueueSnackbar(status.message, {
          variant: 'error',
        });
      } finally {
        const endLoadings = handleLoadings('editUser', [...loadings], 'end');
        setLoadings([...endLoadings]);
      }
    };
    postApiCurrentUser();
  };

  return (
    <div className={styles.container}>
      <div className={styles.title}>
        <DisplayTextHeadline
          color={Color.blueNavyCicle}
          mode="48"
          decoration="bold"
          position="center"
        >
          {`Halo, ${limitChar(user?.fullName, 20)}`}
        </DisplayTextHeadline>
      </div>
      <div className={styles.text}>
        <DisplayTextSubHeadline
          color={Color.gray3}
          mode="18"
          position="center"
        >
          Sebelum mulai, Mincle mohon izin untuk mengenal kamu terlebih dahulu ya.
          Supaya Mincle bisa ngembangin aplikasi Cicle lebih baik lagi untuk kamu.
        </DisplayTextSubHeadline>
      </div>
      <div className={styles.formSection}>
        <div className={styles.form}>
          <div className={styles.formText}>
            <DisplayTextBody
              decoration="bold"
            >
              Siapa nama kamu? (Wajib)
            </DisplayTextBody>
          </div>
          <div className={styles.formArea}>
            <InputTextFieldMain
              placeholder="Bambang Dharmawan"
              name="fullName"
              autoFocus
              onChange={(event) => handleChanged(event)}
              value={newCurrentUser.fullName}
              id={elementId.quetionaire_profile_fullName_text_field}
            />
          </div>
        </div>
        <div className={styles.form}>
          <div className={styles.formText}>
            <DisplayTextBody
              decoration="bold"
            >
              Apa jabatan kamu? (Wajib)
            </DisplayTextBody>
          </div>
          <div className={styles.formArea}>
            <InputTextFieldMain
              placeholder="HRD, Owner, Direktur, Sales Representative"
              name="status"
              onChange={(event) => handleChanged(event)}
              value={newCurrentUser.status}
              id={elementId.quetionaire_profile_status_text_field}
            />
          </div>
        </div>
        <div className={styles.form}>
          <div>
            <DisplayTextBody
              decoration="bold"
            >
              Berapa nomor HP yang konek ke WA-mu?
            </DisplayTextBody>
          </div>
          <div className={styles.formText}>
            <DisplayTextBody
              color={Color.gray3}
              mode="11"
            >
              Tidak wajib. Tapi kalo kamu isi, kamu bisa terima pengingat pembayaran via WA lho.
            </DisplayTextBody>
          </div>
          <div className={styles.formArea}>
            <InputTextFieldMain
              placeholder="+628541414141"
              name="phoneNumber"
              onChange={(event) => handleChanged(event)}
              value={newCurrentUser.phoneNumber}
              type="tel"
              id={elementId.quetionaire_profile_phoneNumber_text_field}
            />
          </div>
        </div>
        <div className={styles.form}>
          <div className={styles.formText}>
            <DisplayTextBody
              decoration="bold"
            >
              Berapa usia-mu? (Wajib)
            </DisplayTextBody>
          </div>
          <div className={styles.formArea}>
            <InputTextFieldMain
              placeholder="28, 35, 18"
              name="age"
              onChange={(event) => handleChanged(event)}
              value={newCurrentUser.age}
              type="number"
              id={elementId.quetionaire_profile_age_text_field}
            />
          </div>
        </div>
        <div className={styles.form}>
          <div className={styles.formText}>
            <DisplayTextBody
              decoration="bold"
            >
              Kamu tinggal di Kota apa? (Wajib)
            </DisplayTextBody>
          </div>
          <div className={styles.formArea}>
            <InputTextFieldMain
              placeholder="Jakarta, Bandung, Surabaya, dll."
              name="location"
              onChange={(event) => handleChanged(event)}
              value={newCurrentUser.location}
              id={elementId.quetionaire_profile_location_text_field}
            />
          </div>
        </div>
        <div className={styles.form}>
          <div className={styles.formText}>
            <DisplayTextBody
              decoration="bold"
            >
              Negara kamu? (Wajib)
            </DisplayTextBody>
          </div>
          <div className={styles.formArea}>
            <InputSelectMain
              label="Negara"
              name="country"
              onChange={(event) => handleChanged(event)}
              value={newCurrentUser.country}
              id={elementId.quetionaire_profile_country_select}
            >
              <MenuItem value="ID">Indonesia</MenuItem>
            </InputSelectMain>
          </div>
        </div>
        <div className={styles.form}>
          <div className={styles.formText}>
            <DisplayTextBody
              decoration="bold"
            >
              Kamu Pria/Wanita ya? (Wajib)
            </DisplayTextBody>
          </div>
          <div className={styles.formArea}>
            <InputSelectMain
              label="Gender"
              name="gender"
              onChange={(event) => handleChanged(event)}
              value={newCurrentUser.gender}
              id={elementId.quetionaire_profile_gender_select}
            >
              <MenuItem value="man">Pria</MenuItem>
              <MenuItem value="woman">Wanita</MenuItem>
            </InputSelectMain>
          </div>
        </div>
        <div className={styles.form}>
          <div className={styles.formText}>
            <DisplayTextBody
              decoration="bold"
            >
              Dari mana kamu tau tentang Cicle? (Wajib)
            </DisplayTextBody>
          </div>
          <div className={styles.formArea}>
            <InputSelectMain
              label="Dari mana kamu tau tentang Cicle?"
              name="whereKnowCicle"
              onChange={(event) => handleChanged(event)}
              value={newCurrentUser.whereKnowCicle}
              id={elementId.quetionaire_profile_whereKnowCicle_select}
            >
              <MenuItem value="friend">Rekomendasi Teman</MenuItem>
              <MenuItem value="leader">Dari Atasan Saya</MenuItem>
              <MenuItem value="google">Google</MenuItem>
              <MenuItem value="ig">Instagram</MenuItem>
              <MenuItem value="fb">Facebook</MenuItem>
              <MenuItem value="tiktok">Tiktok</MenuItem>
              <MenuItem value="youtube">Youtube</MenuItem>
              <MenuItem value="webinar">Webinars</MenuItem>
              <MenuItem value="blog">Blog</MenuItem>
              <MenuItem value="other">Lainnya</MenuItem>
            </InputSelectMain>
          </div>
        </div>
        <div className={styles.form}>
          <div className={styles.formText}>
            <DisplayTextBody
              decoration="bold"
            >
              Kamu pake Aplikasi apa sih buat mengelola tugas sebelumnya? (Required)
            </DisplayTextBody>
          </div>
          <div className={styles.formArea}>
            <InputSelectMain
              label="Aplikasi kelola pekerjaan sebelumnya"
              name="appUsedBeforeCicle"
              onChange={(event) => handleChanged(event)}
              value={newCurrentUser.appUsedBeforeCicle}
              id={elementId.quetionaire_profile_appUsedBeforeCicle_select}
            >
              <MenuItem value="wa">Whatsapp</MenuItem>
              <MenuItem value="waTrello">Whatsapp + Trello</MenuItem>
              <MenuItem value="slack">Slack</MenuItem>
              <MenuItem value="slackTrello">Slack + Trello</MenuItem>
              <MenuItem value="teams">Microsoft Teams</MenuItem>
              <MenuItem value="basecamp">Basecamp</MenuItem>
              <MenuItem value="asana">Asana</MenuItem>
              <MenuItem value="notion">Notion</MenuItem>
              <MenuItem value="traditional">Tradisional (Email/Google Sheet/Meeting Offline)</MenuItem>
              <MenuItem value="cicle">Cicle</MenuItem>
              <MenuItem value="other">Lainnya</MenuItem>
            </InputSelectMain>
          </div>
        </div>
        <div className={styles.form}>
          <div className={styles.formText}>
            <DisplayTextBody
              decoration="bold"
            >
              Kendala apa yang kamu alami pake aplikasi itu kok sampe pengen nyobain Cicle? (Wajib)
            </DisplayTextBody>
          </div>
          <div className={styles.formArea}>
            <InputTextFieldMain
              placeholder="Terlalu mahal, ga bisa monitoring kerjaan kalo pake WA, tim saya sering ga buka"
              name="reasonUsingCicle"
              multiline
              minRows={4}
              maxRows={4}
              onChange={(event) => handleChanged(event)}
              value={newCurrentUser.reasonUsingCicle}
              id={elementId.quetionaire_profile_reasonUsingCicle_text_field}
            />
          </div>
        </div>
        <div className={styles.form}>
          <div className={styles.formText}>
            <DisplayTextBody
              decoration="bold"
            >
              Berapa anggota tim yang kamu bawahi/di tempat kamu bekerja? (Wajib)
            </DisplayTextBody>
          </div>
          <div className={styles.formArea}>
            <InputSelectMain
              label="Team Members Amount"
              name="teamSizeCategory"
              onChange={(event) => handleChanged(event)}
              value={newCurrentUser.teamSizeCategory}
              id={elementId.quetionaire_profile_teamSizeCategory_select}
            >
              <MenuItem value="solo">Hanya saya (Owner)</MenuItem>
              <MenuItem value="micro">1-10 Orang</MenuItem>
              <MenuItem value="small">11-30 Orang</MenuItem>
              <MenuItem value="medium">31-50 Orang</MenuItem>
              <MenuItem value="big">51-100 Orang</MenuItem>
              <MenuItem value="enterprise">&gt; 100 Orang</MenuItem>
            </InputSelectMain>
          </div>
        </div>
        <div className={styles.form}>
          <div className={styles.formText}>
            <DisplayTextBody
              decoration="bold"
            >
              Apakah kamu sama tim kamu kerjanya Remote? (Required)
            </DisplayTextBody>
          </div>
          <div className={styles.formArea}>
            <InputSelectMain
              label="Company Remote Status"
              name="companyRemoteOrOfflineStatus"
              onChange={(event) => handleChanged(event)}
              value={newCurrentUser.companyRemoteOrOfflineStatus}
              id={elementId.quetionaire_profile_companyRemoteOrOfflineStatus_select}
            >
              <MenuItem value="remote">Remote Semua</MenuItem>
              <MenuItem value="hybrid">Hybrid (Sebagian Remote & Sebagian Dari Kantor)</MenuItem>
              <MenuItem value="offline">Dari Kantor Semua</MenuItem>
            </InputSelectMain>
          </div>
        </div>
      </div>
      <div className={styles.button}>
        <InputButtonMain
          handleClick={handleClicked}
          border="rounded"
          wide="wider"
          loadings={loadings}
          wait="editUser"
          id={elementId.quetionaire_profile_next_button}
        >
          Selanjutnya
        </InputButtonMain>
      </div>
    </div>
  );
};

SceneFillingProfile.propTypes = {
  handleClickNext: PropTypes.func.isRequired,
};

export default memo(SceneFillingProfile);
