import React from 'react';
import PropTypes from 'prop-types';
import styles from './LittleIcon.module.css';

const LittleIcon = ({ color, children }) => (
  <div className={styles.box} style={{ backgroundColor: color }}>
    {children}
  </div>
);

LittleIcon.propTypes = {
  children: PropTypes.node.isRequired,
  color: PropTypes.string.isRequired,
};

export default LittleIcon;
