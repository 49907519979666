import React, { memo, useContext, useState } from 'react';
import PropTypes from 'prop-types';
import ModeEditOutlineOutlinedIcon from '@mui/icons-material/ModeEditOutlineOutlined';
import ArchiveOutlinedIcon from '@mui/icons-material/ArchiveOutlined';
import StarBorderIcon from '@mui/icons-material/StarBorder';
import { useSnackbar } from 'notistack';
import { useParams, useHistory } from 'react-router-dom';
import PersonAddAltIcon from '@mui/icons-material/PersonAddAlt';
import styles from './TeamCardMenu.module.css';
import { NavigationMenuBasic, NavigationMenuItem } from '../../../../../components/UI_V2/Navigation/Menu';
import { FeedbackDialogAlert } from '../../../../../components/UI_V2/Feedback/Dialog';
import ModalUpdateTeam from './ModalUpdateTeam/ModalUpdateTeam';
import { TeamActions, TeamFavoriteActions } from '../../../../../actions';
import handleLoadings from '../../../../../utilities/handleLoadings';
import { GlobalContext } from '../../../../../contexts/GlobalStateProvider';
import handleStatusMsg from '../../../../../utilities/handleStatusMsg';
import ManageMultipleMembersContainer from '../../../../ManageMultipleMembersContainer/ManageMultipleMembersContainer';
import OverlayButton from '../../../../../components/UI/Button/OverlayButton/OverlayButton';
import { useDelayShowHideHandler } from '../../../../../hooks/HelperHooks';
import ModalUpdateTeamColor from './ModalUpdateTeamColor/ModalUpdateTeamColor';

function TeamCardMenu({
  cancel,
  team,
  customParams,
  customStyle,
  isFromTeamFavorite,
}) {
  const [{ user, teams, currentCompany }, dispatch] = useContext(GlobalContext);
  const { enqueueSnackbar } = useSnackbar();
  const [loadings, setLoadings] = useState([]);
  const [showUpdateTeam, setShowUpdateTeam] = useState(false);
  const [showUpdateTeamColor, setShowUpdateTeamColor] = useState(false);
  const [showArchivePopUp, setShowArchivePopUp] = useState(false);
  const [showAddMember,
    handleShowAddMember,
    handleCancelShowAddMember] = useDelayShowHideHandler(false);

  const history = useHistory();
  // const params = useParams();

  const {
    companyId,
  } = customParams;

  const handleArchiveTeam = async () => {
    try {
      const startLoadings = handleLoadings('archiveTeam', [...loadings], 'start');
      setLoadings([...startLoadings]);
      const result = await TeamActions.archiveTeamThenUpdateCompanyAndCompanies({
        teamId: team._id,
        companyId,
        teams,
        currentCompany,
        userId: user?._id,
      }, dispatch);

      const status = handleStatusMsg(result, 'success');

      enqueueSnackbar(status.message, {
        variant: 'success',
      });

      history.push(`/companies/${companyId}`);
    } catch (error) {
      const status = handleStatusMsg(error, 'error');

      enqueueSnackbar(status.message, {
        variant: 'error',
      });
    } finally {
      const endLoadings = handleLoadings('archiveTeam', [...loadings], 'end');
      setLoadings([...endLoadings]);
    }
  };

  const handleSetFavoriteTeam = async () => {
    try {
      const startLoadings = handleLoadings('setFavoriteTeam', [...loadings], 'start');
      setLoadings([...startLoadings]);
      const result = await TeamFavoriteActions.setTeamAsFavorite({
        teamId: team._id,
        companyId,
        userId: user?._id,
      }, dispatch);

      const status = handleStatusMsg(result, 'success');

      enqueueSnackbar(status.message, {
        variant: 'success',
      });
    } catch (error) {
      const status = handleStatusMsg(error, 'error');

      enqueueSnackbar(status.message, {
        variant: 'error',
      });
    } finally {
      const endLoadings = handleLoadings('setFavoriteTeam', [...loadings], 'end');
      setLoadings([...endLoadings]);
      cancel();
    }
  };

  const handleUnsetFavoriteTeam = async () => {
    try {
      const startLoadings = handleLoadings('unsetFavoriteTeam', [...loadings], 'start');
      setLoadings([...startLoadings]);
      const result = await TeamFavoriteActions.unsetTeamFromFavorite({
        teamId: team._id,
        companyId,
        userId: user?._id,
      }, dispatch);

      const status = handleStatusMsg(result, 'success');

      enqueueSnackbar(status.message, {
        variant: 'success',
      });
    } catch (error) {
      const status = handleStatusMsg(error, 'error');

      enqueueSnackbar(status.message, {
        variant: 'error',
      });
    } finally {
      const endLoadings = handleLoadings('unsetFavoriteTeam', [...loadings], 'end');
      setLoadings([...endLoadings]);
      cancel();
    }
  };

  const handleCloseUpdateTeam = () => {
    setShowUpdateTeam(false);
    cancel();
  };

  const handleOpenUpdateTeam = () => {
    setShowUpdateTeam(true);
  };

  const handleCloseUpdateTeamColor = () => {
    setShowUpdateTeamColor(false);
    cancel();
  };

  const handleOpenUpdateTeamColor = () => {
    setShowUpdateTeamColor(true);
  };

  const handleShowArchivePopUp = () => {
    setShowArchivePopUp(true);
  };

  const handleHideArchivePopUp = () => {
    setShowArchivePopUp(false);
  };

  return (
    <div className={styles.container} style={{ ...customStyle }}>
      <NavigationMenuBasic
        onClose={cancel}
        position="topLeft"
        disabled={showArchivePopUp || showUpdateTeam || showUpdateTeamColor}
      >
        <NavigationMenuItem
          icon={<PersonAddAltIcon />}
          text="Kelola Anggota"
          onClick={handleShowAddMember}
        />
        {!isFromTeamFavorite && (
          <OverlayButton
            loadings={loadings}
            wait="setFavoriteTeam"
          >
            <NavigationMenuItem
              icon={<StarBorderIcon />}
              text="Tambah ke Favorit"
              onClick={handleSetFavoriteTeam}
            />
          </OverlayButton>
        )}
        {isFromTeamFavorite && (
          <OverlayButton
            loadings={loadings}
            wait="unsetFavoriteTeam"
          >
            <NavigationMenuItem
              icon={<StarBorderIcon />}
              text="Hapus dari Favorit"
              onClick={handleUnsetFavoriteTeam}
            />
          </OverlayButton>
        )}
        <NavigationMenuItem
          icon={(
            <div className={styles.color}>
              <div
                className={styles.color__icon}
              />
            </div>
)}
          text="Atur Warna"
          onClick={handleOpenUpdateTeamColor}
        />
        <NavigationMenuItem
          icon={<ModeEditOutlineOutlinedIcon />}
          text="Ubah"
          onClick={handleOpenUpdateTeam}
          disableDivider={!!team?.archived?.status}
        />
        {!team?.archived?.status && (
          <NavigationMenuItem
            onClick={handleShowArchivePopUp}
            icon={<ArchiveOutlinedIcon />}
            text="Arsipkan"
            disableDivider
          />
        )}
        <FeedbackDialogAlert
          open={showArchivePopUp}
          onClose={handleHideArchivePopUp}
          handleConfirmNo={handleHideArchivePopUp}
          handleConfirmYes={handleArchiveTeam}
          variantButtonYes="danger"
          waitYes="archiveTeam"
          loadings={loadings}
          labelYes="Arsipkan"
          labelNo="Batal"
          title="Arsipkan Tim ini?"
          content="Tim ini tidak akan tampil lagi. Admin bisa mengembalikan Tim ini melalui menu Arsip Tim."
        />
        <ModalUpdateTeam
          open={showUpdateTeam}
          onClose={handleCloseUpdateTeam}
          team={team}
        />
        {showUpdateTeamColor && (
        <ModalUpdateTeamColor
          open={showUpdateTeamColor}
          onClose={handleCloseUpdateTeamColor}
          team={team}
        />
        )}
        {showAddMember
            && (
            <ManageMultipleMembersContainer
              team={team}
              type="team"
              cancel={handleCancelShowAddMember}
              position="bottomMoreLeft"
              customParams={customParams}
            />
            )}
      </NavigationMenuBasic>
    </div>
  );
}

TeamCardMenu.propTypes = {
  cancel: PropTypes.func.isRequired,
  team: PropTypes.object.isRequired,
  customParams: PropTypes.object,
  customStyle: PropTypes.object,
  isFromTeamFavorite: PropTypes.bool,
};

TeamCardMenu.defaultProps = {
  customParams: { companyId: null, teamId: null },
  customStyle: {},
  isFromTeamFavorite: false,
};

export default memo(TeamCardMenu);
