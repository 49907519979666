import React, { memo, useState } from 'react';
import PropTypes from 'prop-types';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import DiscountIcon from '@mui/icons-material/Discount';
import { useParams } from 'react-router-dom';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import { useSnackbar } from 'notistack';
import styles from './SelectCouponButton.module.css';
import { DisplayTextBody } from '../../../../components/UI_V2/Display/Text';
import Color from '../../../../themes/colors';
import { formatToBasicThousandSeparator } from '../../../../utilities/numberUtil';
import OverlayButton from '../../../../components/UI/Button/OverlayButton/OverlayButton';
import { BillingActions } from '../../../../actions';
import handleStatusMsg from '../../../../utilities/handleStatusMsg';
import handleLoadings from '../../../../utilities/handleLoadings';

const couponExistStyles = {
  true: styles.exist,
  false: '',
};

const SelectCouponButton = ({
  onClick,
  coupon,
  handleSetOrder,
  order,
}) => {
  const [loadings, setLoadings] = useState([]);
  const { enqueueSnackbar } = useSnackbar();
  const params = useParams();

  const { companyId } = params;

  const isCouponExist = coupon.code !== undefined;
  const discountValueText = formatToBasicThousandSeparator(coupon.discountValue);
  const isTypePercentage = coupon.discountType === 'percentage';

  const handleClickRemoveCoupon = async (event) => {
    try {
      event.stopPropagation();
      const startLoadings = handleLoadings('coupon-remove', [...loadings], 'start');
      setLoadings([...startLoadings]);
      const result = await BillingActions.removeCouponOrderSummary({
        companyId,
        orderId: order?._id,
      });

      handleSetOrder(result.data);

      const status = handleStatusMsg(result, 'success');

      enqueueSnackbar(status.message, {
        variant: 'success',
      });
    } catch (err) {
      const status = handleStatusMsg(err, 'error');

      enqueueSnackbar(status.message, {
        variant: 'error',
      });
    } finally {
      const endLoadings = handleLoadings('coupon-remove', [...loadings], 'end');
      setLoadings([...endLoadings]);
    }
  };

  return (
    <OverlayButton wait="coupon-remove" loadings={loadings}>
      <div onClick={onClick} className={`${styles.container} ${couponExistStyles[isCouponExist]}`}>
        {!isCouponExist && (
        <div className={styles.icon}>
          <DiscountIcon />
        </div>
        )}
        {isCouponExist && (
        <div className={styles.iconExist}>
          <CheckCircleOutlineIcon />
        </div>
        )}
        <div className={styles.text}>
          <DisplayTextBody>
            {!isCouponExist && 'Pilih Kupon'}
            {isCouponExist && `${coupon.code} - Diskon `}
            {isCouponExist && !isTypePercentage && 'Rp. '}
            {isCouponExist && `${discountValueText}`}
            {isCouponExist && isTypePercentage && '%'}
          </DisplayTextBody>
        </div>
        {isCouponExist && (
        <div onClick={(event) => handleClickRemoveCoupon(event)} className={styles.removeCoupon}>
          <DeleteOutlineIcon />
        </div>
        )}
      </div>
    </OverlayButton>
  );
};

SelectCouponButton.propTypes = {
  onClick: PropTypes.func.isRequired,
  coupon: PropTypes.object,
  handleSetOrder: PropTypes.func.isRequired,
  order: PropTypes.object.isRequired,
};

SelectCouponButton.defaultProps = {
  coupon: {},
};

export default memo(SelectCouponButton);
