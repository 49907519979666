import React, {
  memo, useState, useEffect, useReducer, useCallback,
} from 'react';
import { useParams } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Box } from '@mui/material';
import MoreHorizOutlinedIcon from '@mui/icons-material/MoreHorizOutlined';
import { DisplayTextBody, DisplayTextSubHeadline } from '../../../../../components/UI_V2/Display/Text';
import ItemJumpTo from './ItemJumpTo/ItemJumpTo';
import { limitChar } from '../../../../../utilities/stringUtil';
import { SearchJumpToConstants } from '../../../../../constants';
import Color from '../../../../../themes/colors';
import LinkNoDecor from '../../../../../components/LinkNoDecor/LinkNoDecor';

const showMoreTextStyles = {
  display: 'flex',
  alignItems: 'center',
  height: '20px',
  paddingTop: '1px',
  paddingBottom: '1px',
  paddingLeft: '8px',
  paddingRight: '8px',
  width: '95%',
  cursor: 'pointer',
  marginTop: '5px',
  '&:hover': {
    opacity: '0.8',
  },
};

const iconBoxStyles = {
  marginRight: '13px',
};

const iconStyles = {
  color: Color.blueNavy4,
};

const ListJumpTo = ({
  list, type, selectedIndex, onClose, params,
}) => {
  const [showMoreList, setShowMoreList] = useState(false);
  // const listByType = list.filter((item) => item.type === type);
  const hasMoreLength = list.length > SearchJumpToConstants.limitMoreList.team;
  const moreLength = list.length - SearchJumpToConstants.limitMoreList.team;
  const emptyList = list.length < 1;
  const { companyId } = params;

  const handleShowOpenMoreList = useCallback(() => {
    setShowMoreList(true);
  }, []);
  return (
    <>
      {list.map((item, index) => {
        if (index >= SearchJumpToConstants.limitMoreList.team) return null;
        return (
          <ItemJumpTo
            key={item._id}
            item={item}
            isSelected={index === selectedIndex}
            onClick={onClose}
            params={params}
          />
        );
      })}
      {hasMoreLength && !showMoreList
        ? (
          <Box
            sx={{
              ...showMoreTextStyles,
            }}
            onClick={handleShowOpenMoreList}
          >
            <Box
              sx={{
                ...iconBoxStyles,
              }}
            >
              <MoreHorizOutlinedIcon sx={{
                ...iconStyles,
              }}
              />
            </Box>
            <DisplayTextBody
              mode="14"
              color={Color.blueNavy4}
            >
              Tampilkan
              {' '}
              {moreLength}
              {' '}
              lagi
            </DisplayTextBody>
          </Box>
        )
        : null}
      {hasMoreLength && showMoreList ? (
        <>
          {list.map((item, index) => {
            if (index < SearchJumpToConstants.limitMoreList.team) return null;
            return (
              <ItemJumpTo
                key={item._id}
                item={item}
                isSelected={index === selectedIndex}
                onClick={onClose}
                params={params}
              />
            );
          })}
        </>
      )
        : null}
      {emptyList && (
      <DisplayTextBody color={Color.gray4}>
        You have no
        {' '}
        {type}
        {' '}
        here yet
      </DisplayTextBody>
      )}
    </>
  );
};

ListJumpTo.propTypes = {
  type: PropTypes.oneOf(['hq', 'team', 'project']).isRequired,
  selectedIndex: PropTypes.number.isRequired,
  list: PropTypes.array.isRequired,
  onClose: PropTypes.func.isRequired,
  params: PropTypes.object,
};

ListJumpTo.defaultProps = {
  params: { companyId: null, teamId: null },
};

export default memo(ListJumpTo);
