import React from 'react';
import PropTypes from 'prop-types';
import {
  Chart as ChartJS,
  CategoryScale, LinearScale, Title, Tooltip, BarElement,
} from 'chart.js';
import { Bar } from 'react-chartjs-2';

ChartJS.register(
  CategoryScale, LinearScale, Title, Tooltip, BarElement,
);

const DisplayChartStackedBar = ({ labels, datasets, colors }) => {
  const chartData = {
    labels,
    datasets: datasets.map((dataset, index) => ({
      label: dataset.label,
      data: dataset.data,
      backgroundColor: colors[index],
    })),
  };

  const chartOptions = {
    scales: {
      x: {
        stacked: true,
      },
      y: {
        stacked: true,
      },
    },
    plugins: {
      legend: {
        display: false,
      },
    },
  };

  return <Bar data={chartData} options={chartOptions} />;
};

DisplayChartStackedBar.propTypes = {
  labels: PropTypes.array,
  datasets: PropTypes.array,
  colors: PropTypes.array,
};

DisplayChartStackedBar.defaultProps = {
  labels: [],
  datasets: [],
  colors: [],
};

export default DisplayChartStackedBar;
