import React, { memo, useState } from 'react';
import PropTypes from 'prop-types';
import { Box } from '@mui/material';
import PostBlastPageSkeletonHeader from './Header/Header';
import PostBlastPageSkeletonBody from './Body/Body';
import { DisplayDividerMain } from '../../../components/UI_V2/Display/Divider';

const PostBlastPageSkeleton = () => (
  <Box>
    <PostBlastPageSkeletonHeader />
    <DisplayDividerMain />
    <PostBlastPageSkeletonBody />
  </Box>
);

export default memo(PostBlastPageSkeleton);
