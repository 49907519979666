import React, { memo, useState } from 'react';
import PropTypes from 'prop-types';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import styles from './DetailInfoBox.module.css';
import { DisplayTextBody, DisplayTextSubHeadline } from '../../../../../components/UI_V2/Display/Text';
import Color from '../../../../../themes/colors';
import { DisplayDividerMain } from '../../../../../components/UI_V2/Display/Divider';

const DetailInfoBox = ({ title, children }) => (
  <div className={styles.container}>
    <div className={styles.title}>
      <DisplayTextSubHeadline
        decoration="bold"
        mode="18"
      >
        {title}
      </DisplayTextSubHeadline>
    </div>
    <DisplayDividerMain
      borderColor={Color.gray3}
    />
    <div className={styles.body}>
      {children}
    </div>
  </div>
);

DetailInfoBox.propTypes = {
  title: PropTypes.string.isRequired,
  children: PropTypes.node,
};

DetailInfoBox.defaultProps = {
  children: <></>,
};

export default memo(DetailInfoBox);
