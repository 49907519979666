import React, {
  memo, useContext,
} from 'react';
import PropTypes from 'prop-types';
import useMediaQuery from '@mui/material/useMediaQuery';
import styles from './MiddleNavBar.module.css';
import { GlobalContext } from '../../../contexts/GlobalStateProvider';
import ListTabs from './ListTabs/ListTabs';
import OverviewOptionButton from '../BottomNavBar/OverviewOptionButton/OverviewOptionButton';

const containerWithSidebarStyles = {
  true: `${styles.container} ${styles.sidebarOpen}`,
  false: styles.container,
};

const MiddleNavBar = ({
  overviewOptionId,
  globalActions,
  otherActions,
}) => {
  const isMobile = useMediaQuery('(max-width:720px)');
  const hasGlobalActions = Boolean(globalActions?.props?.children);
  const [{ isSidebarOpen }] = useContext(GlobalContext);

  return (
    <div className={containerWithSidebarStyles[isSidebarOpen]}>
      {!isMobile && <ListTabs overviewOptionId={overviewOptionId} />}
      {isMobile && <OverviewOptionButton defaultMenuId={overviewOptionId} />}
    </div>
  );
};

MiddleNavBar.propTypes = {
  overviewOptionId: PropTypes.string,
  globalActions: PropTypes.node,
  otherActions: PropTypes.node,
};

MiddleNavBar.defaultProps = {
  overviewOptionId: null,
  globalActions: <></>,
  otherActions: <></>,
};

export default memo(MiddleNavBar);
