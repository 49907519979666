/* eslint-disable no-return-await */
import React from 'react';
import { useLocation } from 'react-router-dom';
// import Badge from '@mui/material/Badge';
import MessageIcon from '@mui/icons-material/Message';
import QuestionAnswerIcon from '@mui/icons-material/QuestionAnswer';
import NotificationsIcon from '@mui/icons-material/Notifications';
import FolderIcon from '@mui/icons-material/Folder';
import _ from 'lodash';
import AlternateEmailIcon from '@mui/icons-material/AlternateEmail';
import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import HighlightIcon from '@mui/icons-material/Highlight';
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import HelpIcon from '@mui/icons-material/Help';
import AssignmentIcon from '@mui/icons-material/Assignment';
import AttachFileIcon from '@mui/icons-material/AttachFile';
import PropTypes from 'prop-types';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { DisplayBadgeCounter } from '../../../components/UI_V2/Display/Badge';
import LinkNoDecor from '../../../components/LinkNoDecor/LinkNoDecor';
import LittleIcon from '../../../components/UI/LittleIcon/LittleIcon';
import DateBox from '../../../components/UI/DateBox/DateBox';
import ImageMember from '../../../components/UI/ImageMember/ImageMember';
import styles from './Notification.module.css';
import logoSquareImage from '../../../assets/Logo/logo-square.png';
import groupChatIcon from '../../../assets/Notification/group-chat.png';
import Color from '../../../themes/colors';
import { DisplayTextBody } from '../../../components/UI_V2/Display/Text';
import { dateBeautifyFormatter } from '../../../utilities/dateUtil';
import { DisplayDateText } from '../../../components/UI_V2/Display/Date';
import limitChar from '../../../utilities/limitChar';

const variantStyles = {
  regular: '',
  fullWidth: styles.fullWidth,
};

const Notification = ({
  notification,
  user,
  clicked,
  markReadMode,
  selectedReadNotifs,
  variant,
}) => {
  // default is blue
  let backgroundIconColor;
  let icon;
  let content;
  let photoUrl;
  let clickUrl;
  let type;
  let fullName;
  let activityContent;

  if (_.isEmpty(notification.childService)) {
    type = notification.service.serviceType;
  } else {
    type = notification.childService.serviceType;
  }

  const topActivity = notification?.activities?.[0];

  const location = useLocation();

  const isLocationMyTaskListAll = location.pathname.includes('/my-tasks/list/all');
  const isLocationMyTaskCalendarAll = location.pathname.includes('/my-tasks/calendar/all');
  const isLocationBoard = !isLocationMyTaskCalendarAll && !isLocationMyTaskListAll;

  switch (type) {
    // Post
    case 'post':
      icon = <MessageIcon />;
      photoUrl = notification.sender?.photoUrl;
      content = `Membuat Pengumuman baru : ${notification.content}`;
      clickUrl = `/companies/${notification.company}/teams/${notification?.team?._id}/posts/${notification.service.serviceId}?readNotifId=${notification._id}`;
      if (notification.team) {
        fullName = `${limitChar(notification.sender?.fullName, 14)} - ${limitChar(notification.team.name, 20)}`;
      } else {
        fullName = limitChar(notification.sender?.fullName, 14);
      }
      break;
    case 'postMention':
      icon = <AlternateEmailIcon />;
      backgroundIconColor = '#cc0e74';
      photoUrl = notification.sender?.photoUrl;
      content = notification.content;
      clickUrl = `/companies/${notification.company}/teams/${notification?.team?._id}/posts/${notification.service.serviceId}?readNotifId=${notification._id}`;
      if (notification.team) {
        fullName = `${limitChar(notification.sender?.fullName, 14)} - ${limitChar(notification.team.name, 20)}`;
      } else {
        fullName = limitChar(notification.sender?.fullName, 14);
      }
      break;
    case 'postSubscription':
      icon = <NotificationsIcon />;
      photoUrl = notification.sender?.photoUrl;
      content = notification.content;
      clickUrl = `/companies/${notification.company}/teams/${notification?.team?._id}/posts/${notification.service.serviceId}?readNotifId=${notification._id}`;
      if (notification.team) {
        fullName = `${limitChar(notification.sender?.fullName, 14)} - ${limitChar(notification.team.name, 20)}`;
      } else {
        fullName = limitChar(notification.sender?.fullName, 14);
      }
      break;
    case 'postSystem':
      // icon = <AssignmentIcon />;
      icon = <NotificationsIcon />;
      backgroundIconColor = '#f0a500';
      photoUrl = logoSquareImage;
      content = notification.childContent;
      clickUrl = `/companies/${notification.company}/teams/${notification?.team?._id}/posts/${notification.service.serviceId}?readNotifId=${notification._id}`;
      if (notification.team) {
        fullName = `Cicle - ${limitChar(notification.team.name, 20)}`;
      } else {
        fullName = 'Cicle';
      }
      break;

    // Post comment
    case 'comment':
      icon = <MessageIcon />;
      photoUrl = notification.sender?.photoUrl;
      content = `Komentar baru di Pengumuman : ${notification.content}`;
      activityContent = topActivity && topActivity.activityPayload?.commentContent
        ? topActivity.activityPayload.commentContent : notification.activityContent;
      clickUrl = `/companies/${notification.company}/teams/${notification?.team?._id}/posts/${notification.service.serviceId}?readNotifId=${notification._id}`;
      if (notification.team) {
        fullName = `${limitChar(notification.sender?.fullName, 14)} - ${limitChar(notification.team.name, 20)}`;
      } else {
        fullName = limitChar(notification.sender?.fullName, 14);
      }
      break;
    case 'postCommentMention':
      icon = <AlternateEmailIcon />;
      backgroundIconColor = '#cc0e74';
      photoUrl = notification.sender?.photoUrl;
      content = notification.content;
      activityContent = notification.activityContent;
      clickUrl = `/companies/${notification.company}/teams/${notification?.team?._id}/posts/${notification.service.serviceId}?readNotifId=${notification._id}`;
      if (notification.team) {
        fullName = `${limitChar(notification.sender?.fullName, 14)} - ${limitChar(notification.team.name, 20)}`;
      } else {
        fullName = limitChar(notification.sender?.fullName, 14);
      }
      break;

    // Post discussion
    case 'commentDiscussionPost':
      icon = <MessageIcon />;
      photoUrl = notification.sender?.photoUrl;
      content = notification.childContent;
      activityContent = notification.activityContent;
      clickUrl = `/companies/${notification.company}/teams/${notification?.team?._id}/posts/${notification.service.serviceId}/comments/${notification.childService.serviceId}?readNotifId=${notification._id}`;
      if (notification.team) {
        fullName = `${limitChar(notification.sender?.fullName, 14)} - ${limitChar(notification.team.name, 20)}`;
      } else {
        fullName = limitChar(notification.sender?.fullName, 14);
      }
      break;
    case 'commentDiscussionMentionPost':
      icon = <AlternateEmailIcon />;
      backgroundIconColor = '#cc0e74';
      photoUrl = notification.sender?.photoUrl;
      content = notification.content;
      activityContent = notification.activityContent;
      clickUrl = `/companies/${notification.company}/teams/${notification?.team?._id}/posts/${notification.service.serviceId}/comments/${notification.childService.serviceId}?readNotifId=${notification._id}`;
      if (notification.team) {
        fullName = `${limitChar(notification.sender?.fullName, 14)} - ${limitChar(notification.team.name, 20)}`;
      } else {
        fullName = limitChar(notification.sender?.fullName, 14);
      }
      break;

    // Card
    case 'card':
      icon = <AssignmentIcon />;
      backgroundIconColor = '#ff7a8a';
      photoUrl = notification.sender?.photoUrl;
      content = `Membuat Tugas baru : ${notification.content}`;

      if (isLocationMyTaskCalendarAll) {
        clickUrl = `/companies/${notification.company}/my-tasks/calendar/all/teams/${notification?.team?._id}/cards/${notification.service.serviceId}?readNotifId=${notification._id}`;
      }

      if (isLocationMyTaskListAll) {
        clickUrl = `/companies/${notification.company}/my-tasks/list/all/teams/${notification?.team?._id}/cards/${notification.service.serviceId}?readNotifId=${notification._id}`;
      }

      if (isLocationBoard) {
        clickUrl = `/companies/${notification.company}/teams/${notification?.team?._id}/cards/${notification.service.serviceId}?readNotifId=${notification._id}`;
      }

      if (notification.team) {
        fullName = `${limitChar(notification.sender?.fullName, 14)} - ${limitChar(notification.team.name, 20)}`;
      } else {
        fullName = limitChar(notification.sender?.fullName, 14);
      }
      break;
    case 'cardMember':
      icon = <AssignmentIcon />;
      backgroundIconColor = '#ff7a8a';
      photoUrl = notification.sender?.photoUrl;
      content = notification.content;
      if (isLocationMyTaskCalendarAll) {
        clickUrl = `/companies/${notification.company}/my-tasks/calendar/all/teams/${notification?.team?._id}/cards/${notification.service.serviceId}?readNotifId=${notification._id}`;
      }

      if (isLocationMyTaskListAll) {
        clickUrl = `/companies/${notification.company}/my-tasks/list/all/teams/${notification?.team?._id}/cards/${notification.service.serviceId}?readNotifId=${notification._id}`;
      }

      if (isLocationBoard) {
        clickUrl = `/companies/${notification.company}/teams/${notification?.team?._id}/cards/${notification.service.serviceId}?readNotifId=${notification._id}`;
      }
      if (notification.team) {
        fullName = `${limitChar(notification.sender?.fullName, 14)} - ${limitChar(notification.team.name, 20)}`;
      } else {
        fullName = limitChar(notification.sender?.fullName, 14);
      }
      break;
    case 'cardMoved':
      icon = <AssignmentIcon />;
      backgroundIconColor = '#ff7a8a';
      photoUrl = notification.sender?.photoUrl;
      content = notification.childContent;
      if (isLocationMyTaskCalendarAll) {
        clickUrl = `/companies/${notification.company}/my-tasks/calendar/all/teams/${notification?.team?._id}/cards/${notification.service.serviceId}?readNotifId=${notification._id}`;
      }

      if (isLocationMyTaskListAll) {
        clickUrl = `/companies/${notification.company}/my-tasks/list/all/teams/${notification?.team?._id}/cards/${notification.service.serviceId}?readNotifId=${notification._id}`;
      }

      if (isLocationBoard) {
        clickUrl = `/companies/${notification.company}/teams/${notification?.team?._id}/cards/${notification.service.serviceId}?readNotifId=${notification._id}`;
      }
      if (notification.team) {
        fullName = `${limitChar(notification.sender?.fullName, 14)} - ${limitChar(notification.team.name, 20)}`;
      } else {
        fullName = limitChar(notification.sender?.fullName, 14);
      }
      break;
    case 'cardArchived':
      icon = <AssignmentIcon />;
      backgroundIconColor = '#ff7a8a';
      photoUrl = notification.sender?.photoUrl;
      content = notification.childContent;
      if (isLocationMyTaskCalendarAll) {
        clickUrl = `/companies/${notification.company}/my-tasks/calendar/all/teams/${notification?.team?._id}/cards/${notification.service.serviceId}?readNotifId=${notification._id}`;
      }

      if (isLocationMyTaskListAll) {
        clickUrl = `/companies/${notification.company}/my-tasks/list/all/teams/${notification?.team?._id}/cards/${notification.service.serviceId}?readNotifId=${notification._id}`;
      }

      if (isLocationBoard) {
        clickUrl = `/companies/${notification.company}/teams/${notification?.team?._id}/cards/${notification.service.serviceId}?readNotifId=${notification._id}`;
      }
      if (notification.team) {
        fullName = `${limitChar(notification.sender?.fullName, 14)} - ${limitChar(notification.team.name, 20)}`;
      } else {
        fullName = limitChar(notification.sender?.fullName, 14);
      }
      break;
    case 'cardMention':
      icon = <AlternateEmailIcon />;
      backgroundIconColor = '#cc0e74';
      photoUrl = notification.sender?.photoUrl;
      content = notification.content;
      if (isLocationMyTaskCalendarAll) {
        clickUrl = `/companies/${notification.company}/my-tasks/calendar/all/teams/${notification?.team?._id}/cards/${notification.service.serviceId}?readNotifId=${notification._id}`;
      }

      if (isLocationMyTaskListAll) {
        clickUrl = `/companies/${notification.company}/my-tasks/list/all/teams/${notification?.team?._id}/cards/${notification.service.serviceId}?readNotifId=${notification._id}`;
      }

      if (isLocationBoard) {
        clickUrl = `/companies/${notification.company}/teams/${notification?.team?._id}/cards/${notification.service.serviceId}?readNotifId=${notification._id}`;
      }
      if (notification.team) {
        fullName = `${limitChar(notification.sender?.fullName, 14)} - ${limitChar(notification.team.name, 20)}`;
      } else {
        fullName = limitChar(notification.sender?.fullName, 14);
      }
      break;
    case 'cardSystem':
      icon = <AssignmentIcon />;
      backgroundIconColor = '#f0a500';
      photoUrl = logoSquareImage;
      content = `Tugas ${notification.childContent}`;
      if (isLocationMyTaskCalendarAll) {
        clickUrl = `/companies/${notification.company}/my-tasks/calendar/all/teams/${notification?.team?._id}/cards/${notification.service.serviceId}?readNotifId=${notification._id}`;
      }

      if (isLocationMyTaskListAll) {
        clickUrl = `/companies/${notification.company}/my-tasks/list/all/teams/${notification?.team?._id}/cards/${notification.service.serviceId}?readNotifId=${notification._id}`;
      }

      if (isLocationBoard) {
        clickUrl = `/companies/${notification.company}/teams/${notification?.team?._id}/cards/${notification.service.serviceId}?readNotifId=${notification._id}`;
      }
      if (notification.team) {
        fullName = `Cicle - ${limitChar(notification.team.name, 20)}`;
      } else {
        fullName = 'Cicle';
      }
      break;

    // Card comment
    case 'cardComment':
      icon = <AssignmentIcon />;
      backgroundIconColor = '#ff7a8a';
      photoUrl = notification.sender?.photoUrl;
      content = `Komentar baru di Tugas : ${notification.content}`;
      activityContent = topActivity && topActivity.activityPayload?.commentContent
        ? topActivity.activityPayload.commentContent : notification.activityContent;
      if (isLocationMyTaskCalendarAll) {
        clickUrl = `/companies/${notification.company}/my-tasks/calendar/all/teams/${notification?.team?._id}/cards/${notification.service.serviceId}?readNotifId=${notification._id}`;
      }

      if (isLocationMyTaskListAll) {
        clickUrl = `/companies/${notification.company}/my-tasks/list/all/teams/${notification?.team?._id}/cards/${notification.service.serviceId}?readNotifId=${notification._id}`;
      }

      if (isLocationBoard) {
        clickUrl = `/companies/${notification.company}/teams/${notification?.team?._id}/cards/${notification.service.serviceId}?readNotifId=${notification._id}`;
      }
      if (notification.team) {
        fullName = `${limitChar(notification.sender?.fullName, 14)} - ${limitChar(notification.team.name, 20)}`;
      } else {
        fullName = limitChar(notification.sender?.fullName, 14);
      }
      break;
    case 'cardCommentMention':
      icon = <AlternateEmailIcon />;
      backgroundIconColor = '#cc0e74';
      photoUrl = notification.sender?.photoUrl;
      content = notification.content;
      activityContent = notification.activityContent;
      if (isLocationMyTaskCalendarAll) {
        clickUrl = `/companies/${notification.company}/my-tasks/calendar/all/teams/${notification?.team?._id}/cards/${notification.service.serviceId}?readNotifId=${notification._id}`;
      }

      if (isLocationMyTaskListAll) {
        clickUrl = `/companies/${notification.company}/my-tasks/list/all/teams/${notification?.team?._id}/cards/${notification.service.serviceId}?readNotifId=${notification._id}`;
      }

      if (isLocationBoard) {
        clickUrl = `/companies/${notification.company}/teams/${notification?.team?._id}/cards/${notification.service.serviceId}?readNotifId=${notification._id}`;
      }
      if (notification.team) {
        fullName = `${limitChar(notification.sender?.fullName, 14)} - ${limitChar(notification.team.name, 20)}`;
      } else {
        fullName = limitChar(notification.sender?.fullName, 14);
      }
      break;

    // Card discussion
    case 'commentDiscussionCard':
      icon = <AssignmentIcon />;
      backgroundIconColor = '#ff7a8a';
      photoUrl = notification.sender?.photoUrl;
      content = notification.childContent;
      activityContent = notification.activityContent;
      if (isLocationMyTaskCalendarAll) {
        clickUrl = `/companies/${notification.company}/my-tasks/calendar/all/teams/${notification?.team?._id}/cards/${notification.service.serviceId}/comments/${notification.childService.serviceId}?readNotifId=${notification._id}`;
      }
      if (isLocationMyTaskListAll) {
        clickUrl = `/companies/${notification.company}/my-tasks/list/all/teams/${notification?.team?._id}/cards/${notification.service.serviceId}/comments/${notification.childService.serviceId}?readNotifId=${notification._id}`;
      }
      if (isLocationBoard) {
        clickUrl = `/companies/${notification.company}/teams/${notification?.team?._id}/cards/${notification.service.serviceId}/comments/${notification.childService.serviceId}?readNotifId=${notification._id}`;
      }
      if (notification.team) {
        fullName = `${limitChar(notification.sender?.fullName, 14)} - ${limitChar(notification.team.name, 20)}`;
      } else {
        fullName = limitChar(notification.sender?.fullName, 14);
      }
      break;
    case 'commentDiscussionMentionCard':
      icon = <AlternateEmailIcon />;
      backgroundIconColor = '#cc0e74';
      photoUrl = notification.sender?.photoUrl;
      content = notification.content;
      activityContent = notification.activityContent;
      if (isLocationMyTaskCalendarAll) {
        clickUrl = `/companies/${notification.company}/my-tasks/calendar/all/teams/${notification?.team?._id}/cards/${notification.service.serviceId}/comments/${notification.childService.serviceId}?readNotifId=${notification._id}`;
      }
      if (isLocationMyTaskListAll) {
        clickUrl = `/companies/${notification.company}/my-tasks/list/all/teams/${notification?.team?._id}/cards/${notification.service.serviceId}/comments/${notification.childService.serviceId}?readNotifId=${notification._id}`;
      }
      if (isLocationBoard) {
        clickUrl = `/companies/${notification.company}/teams/${notification?.team?._id}/cards/${notification.service.serviceId}/comments/${notification.childService.serviceId}?readNotifId=${notification._id}`;
      }
      if (notification.team) {
        fullName = `${limitChar(notification.sender?.fullName, 14)} - ${limitChar(notification.team.name, 20)}`;
      } else {
        fullName = limitChar(notification.sender?.fullName, 14);
      }
      break;

    /// Chat
    case 'chat':
      icon = <MessageIcon />;
      backgroundIconColor = '#363062';
      photoUrl = '/images/chat.png';
      content = notification.content;
      activityContent = notification.activityContent;
      clickUrl = `/companies/${notification.company}/chats/${notification.service.serviceId}?readNotifId=${notification._id}`;
      fullName = limitChar(notification.sender?.fullName, 14);
      break;
    case 'chatMention':
      icon = <AlternateEmailIcon />;
      backgroundIconColor = '#cc0e74';
      photoUrl = notification.sender?.photoUrl;
      content = notification.content;
      activityContent = notification.activityContent;
      clickUrl = `/companies/${notification.company}/chats/${notification.service.serviceId}?readNotifId=${notification._id}`;
      fullName = limitChar(notification.sender?.fullName, 14);
      break;

    // GroupChat
    case 'groupChat':
      icon = <QuestionAnswerIcon />;
      backgroundIconColor = '#363062';
      photoUrl = groupChatIcon;
      content = notification.content;
      activityContent = notification.activityContent;
      clickUrl = `/companies/${notification.company}/teams/${notification?.team?._id}/group-chats/${notification.service.serviceId}?readNotifId=${notification._id}`;
      if (notification.team) {
        fullName = `${limitChar(notification.sender?.fullName, 14)} - ${limitChar(notification.team.name, 20)}`;
      } else {
        fullName = limitChar(notification.sender?.fullName, 14);
      }
      break;
    case 'groupChatMention':
      icon = <AlternateEmailIcon />;
      backgroundIconColor = '#cc0e74';
      photoUrl = notification.sender?.photoUrl;
      content = notification.content;
      activityContent = notification.activityContent;
      clickUrl = `/companies/${notification.company}/teams/${notification?.team?._id}/group-chats/${notification.service.serviceId}?readNotifId=${notification._id}`;
      if (notification.team) {
        fullName = `${limitChar(notification.sender?.fullName, 14)} - ${limitChar(notification.team.name, 20)}`;
      } else {
        fullName = limitChar(notification.sender?.fullName, 14);
      }
      break;

    // Question
    case 'question':
      icon = <HelpIcon />;
      backgroundIconColor = '#f0a500';
      photoUrl = notification.sender?.photoUrl;
      content = `Membuat Pertanyaan baru : ${notification.content}`;
      clickUrl = `/companies/${notification.company}/teams/${notification?.team?._id}/questions/${notification.service.serviceId}?readNotifId=${notification._id}`;
      if (notification.team) {
        fullName = `${limitChar(notification.sender?.fullName, 14)} - ${limitChar(notification.team.name, 20)}`;
      } else {
        fullName = limitChar(notification.sender?.fullName, 14);
      }
      break;
    case 'questionSystem':
      icon = <HelpIcon />;
      backgroundIconColor = '#f0a500';
      photoUrl = logoSquareImage;
      content = notification.childContent;
      clickUrl = `/companies/${notification.company}/teams/${notification?.team?._id}/questions/${notification.service.serviceId}?readNotifId=${notification._id}`;
      if (notification.team) {
        fullName = `Cicle - ${limitChar(notification.team.name, 20)}`;
      } else {
        fullName = 'Cicle';
      }
      break;
    case 'questionSubscription':
      icon = <HelpIcon />;
      backgroundIconColor = '#28df99';
      photoUrl = notification.sender?.photoUrl;
      content = notification.content;
      clickUrl = `/companies/${notification.company}/teams/${notification?.team?._id}/questions/${notification.service.serviceId}?readNotifId=${notification._id}`;
      if (notification.team) {
        fullName = `${limitChar(notification.sender?.fullName, 14)} - ${limitChar(notification.team.name, 20)}`;
      } else {
        fullName = limitChar(notification.sender?.fullName, 14);
      }
      break;

    // Question comment
    case 'questionComment':
      icon = <HelpIcon />;
      backgroundIconColor = '#28df99';
      photoUrl = notification.sender?.photoUrl;
      content = `Menjawab ke : ${notification.content}`;
      activityContent = topActivity && topActivity.activityPayload?.commentContent
        ? topActivity.activityPayload.commentContent : notification.activityContent;
      clickUrl = `/companies/${notification.company}/teams/${notification?.team?._id}/questions/${notification.service.serviceId}?readNotifId=${notification._id}`;
      if (notification.team) {
        fullName = `${limitChar(notification.sender?.fullName, 14)} - ${limitChar(notification.team.name, 20)}`;
      } else {
        fullName = limitChar(notification.sender?.fullName, 14);
      }
      break;
    case 'questionCommentMention':
      icon = <AlternateEmailIcon />;
      backgroundIconColor = '#cc0e74';
      photoUrl = notification.sender?.photoUrl;
      content = notification.content;
      activityContent = notification.activityContent;
      clickUrl = `/companies/${notification.company}/teams/${notification?.team?._id}/questions/${notification.service.serviceId}?readNotifId=${notification._id}`;
      if (notification.team) {
        fullName = `${limitChar(notification.sender?.fullName, 14)} - ${limitChar(notification.team.name, 20)}`;
      } else {
        fullName = limitChar(notification.sender?.fullName, 14);
      }
      break;

    // Question discussion
    case 'commentDiscussionQuestion':
      icon = <HelpIcon />;
      backgroundIconColor = '#28df99';
      photoUrl = notification.sender?.photoUrl;
      content = notification.childContent;
      activityContent = notification.activityContent;
      clickUrl = `/companies/${notification.company}/teams/${notification?.team?._id}/questions/${notification.service.serviceId}/comments/${notification.childService.serviceId}?readNotifId=${notification._id}`;
      if (notification.team) {
        fullName = `${limitChar(notification.sender?.fullName, 14)} - ${limitChar(notification.team.name, 20)}`;
      } else {
        fullName = limitChar(notification.sender?.fullName, 14);
      }
      break;
    case 'commentDiscussionMentionQuestion':
      icon = <AlternateEmailIcon />;
      backgroundIconColor = '#cc0e74';
      photoUrl = notification.sender?.photoUrl;
      content = notification.content;
      activityContent = notification.activityContent;
      clickUrl = `/companies/${notification.company}/teams/${notification?.team?._id}/questions/${notification.service.serviceId}/comments/${notification.childService.serviceId}?readNotifId=${notification._id}`;
      if (notification.team) {
        fullName = `${limitChar(notification.sender?.fullName, 14)} - ${limitChar(notification.team.name, 20)}`;
      } else {
        fullName = limitChar(notification.sender?.fullName, 14);
      }
      break;

    // Recurring Occurrence
    case 'recurringOccurrence':
      icon = <CalendarTodayIcon />;
      backgroundIconColor = '#ce1212';
      photoUrl = notification.sender?.photoUrl;
      content = `Membuat Jadwal : ${notification.content}`;
      clickUrl = `/companies/${notification.company}/teams/${notification?.team?._id}/events/${notification.service.serviceId}/occurrences/${notification.grandChildService.serviceId}?readNotifId=${notification._id}`;
      if (notification.team) {
        fullName = `${limitChar(notification.sender?.fullName, 14)} - ${limitChar(notification.team.name, 20)}`;
      } else {
        fullName = limitChar(notification.sender?.fullName, 14);
      }
      break;
    case 'recurringOccurrenceMention':
      icon = <AlternateEmailIcon />;
      backgroundIconColor = '#cc0e74';
      photoUrl = notification.sender?.photoUrl;
      content = notification.content;
      clickUrl = `/companies/${notification.company}/teams/${notification?.team?._id}/events/${notification.service.serviceId}/occurrences/${notification.grandChildService.serviceId}?readNotifId=${notification._id}`;
      if (notification.team) {
        fullName = `${limitChar(notification.sender?.fullName, 14)} - ${limitChar(notification.team.name, 20)}`;
      } else {
        fullName = limitChar(notification.sender?.fullName, 14);
      }
      break;
    case 'recurringOccurrenceSystem':
      icon = <CalendarTodayIcon />;
      backgroundIconColor = '#f0a500';
      photoUrl = logoSquareImage;
      content = `Jadwal ${notification.childContent}`;
      clickUrl = `/companies/${notification.company}/teams/${notification?.team?._id}/events/${notification.service.serviceId}/occurrences/${notification.grandChildService.serviceId}?readNotifId=${notification._id}`;
      if (notification.team) {
        fullName = `Cicle - ${limitChar(notification.team.name, 20)}`;
      } else {
        fullName = 'Cicle';
      }
      break;
    case 'recurringOccurrenceSubscription':
      icon = <CalendarTodayIcon />;
      backgroundIconColor = '#ce1212';
      photoUrl = notification.sender?.photoUrl;
      content = notification.content;
      clickUrl = `/companies/${notification.company}/teams/${notification?.team?._id}/events/${notification.service.serviceId}/occurrences/${notification.grandChildService.serviceId}?readNotifId=${notification._id}`;
      if (notification.team) {
        fullName = `${limitChar(notification.sender?.fullName, 14)} - ${limitChar(notification.team.name, 20)}`;
      } else {
        fullName = limitChar(notification.sender?.fullName, 14);
      }
      break;

    // Recurring Occurrence comment
    case 'recurringOccurrenceComment':
      icon = <CalendarTodayIcon />;
      backgroundIconColor = '#ce1212';
      photoUrl = notification.sender?.photoUrl;
      content = `Komentar baru di Jadwal : ${notification.content}`;
      activityContent = topActivity && topActivity.activityPayload?.commentContent
        ? topActivity.activityPayload.commentContent : notification.activityContent;
      clickUrl = `/companies/${notification.company}/teams/${notification?.team?._id}/events/${notification.service.serviceId}/occurrences/${notification.grandChildService.serviceId}?readNotifId=${notification._id}`;
      if (notification.team) {
        fullName = `${limitChar(notification.sender?.fullName, 14)} - ${limitChar(notification.team.name, 20)}`;
      } else {
        fullName = limitChar(notification.sender?.fullName, 14);
      }
      break;
    case 'recurringOccurrenceCommentMention':
      icon = <AlternateEmailIcon />;
      backgroundIconColor = '#cc0e74';
      photoUrl = notification.sender?.photoUrl;
      content = notification.content;
      activityContent = notification.activityContent;
      clickUrl = `/companies/${notification.company}/teams/${notification?.team?._id}/events/${notification.service.serviceId}/occurrences/${notification.grandChildService.serviceId}?readNotifId=${notification._id}`;
      if (notification.team) {
        fullName = `${limitChar(notification.sender?.fullName, 14)} - ${limitChar(notification.team.name, 20)}`;
      } else {
        fullName = limitChar(notification.sender?.fullName, 14);
      }
      break;

    // Recurring Occurrence discussion
    case 'commentDiscussionRecurringOccurrence':
      icon = <CalendarTodayIcon />;
      backgroundIconColor = '#ce1212';
      photoUrl = notification.sender?.photoUrl;
      content = notification.childContent;
      activityContent = notification.activityContent;
      clickUrl = `/companies/${notification.company}/teams/${notification?.team?._id}/events/${notification.service.serviceId}/occurrences/${notification.grandChildService.serviceId}/comments/${notification.childService.serviceId}?readNotifId=${notification._id}`;
      if (notification.team) {
        fullName = `${limitChar(notification.sender?.fullName, 14)} - ${limitChar(notification.team.name, 20)}`;
      } else {
        fullName = limitChar(notification.sender?.fullName, 14);
      }
      break;
    case 'commentDiscussionMentionRecurringOccurrence':
      icon = <AlternateEmailIcon />;
      backgroundIconColor = '#cc0e74';
      photoUrl = notification.sender?.photoUrl;
      content = notification.content;
      activityContent = notification.activityContent;
      clickUrl = `/companies/${notification.company}/teams/${notification?.team?._id}/events/${notification.service.serviceId}/occurrences/${notification.grandChildService.serviceId}/comments/${notification.childService.serviceId}?readNotifId=${notification._id}`;
      if (notification.team) {
        fullName = `${limitChar(notification.sender?.fullName, 14)} - ${limitChar(notification.team.name, 20)}`;
      } else {
        fullName = limitChar(notification.sender?.fullName, 14);
      }
      break;

    // Event
    case 'event':
      icon = <CalendarTodayIcon />;
      backgroundIconColor = '#ce1212';
      photoUrl = notification.sender?.photoUrl;
      content = `Membuat Jadwal : ${notification.content}`;
      clickUrl = `/companies/${notification.company}/teams/${notification?.team?._id}/events/${notification.service.serviceId}?readNotifId=${notification._id}`;
      if (notification.team) {
        fullName = `${limitChar(notification.sender?.fullName, 14)} - ${limitChar(notification.team.name, 20)}`;
      } else {
        fullName = limitChar(notification.sender?.fullName, 14);
      }
      break;
    case 'eventMention':
      icon = <AlternateEmailIcon />;
      backgroundIconColor = '#cc0e74';
      photoUrl = notification.sender?.photoUrl;
      content = notification.content;
      clickUrl = `/companies/${notification.company}/teams/${notification?.team?._id}/events/${notification.service.serviceId}?readNotifId=${notification._id}`;
      if (notification.team) {
        fullName = `${limitChar(notification.sender?.fullName, 14)} - ${limitChar(notification.team.name, 20)}`;
      } else {
        fullName = limitChar(notification.sender?.fullName, 14);
      }
      break;
    case 'eventSystem':
      icon = <CalendarTodayIcon />;
      backgroundIconColor = '#f0a500';
      photoUrl = logoSquareImage;
      content = `Jadwal ${notification.childContent}`;
      clickUrl = `/companies/${notification.company}/teams/${notification?.team?._id}/events/${notification.service.serviceId}?readNotifId=${notification._id}`;
      if (notification.team) {
        fullName = `Cicle - ${limitChar(notification.team.name, 20)}`;
      } else {
        fullName = 'Cicle';
      }
      break;
    case 'eventSubscription':
      icon = <CalendarTodayIcon />;
      backgroundIconColor = '#ce1212';
      photoUrl = notification.sender?.photoUrl;
      content = notification.content;
      clickUrl = `/companies/${notification.company}/teams/${notification?.team?._id}/events/${notification.service.serviceId}?readNotifId=${notification._id}`;
      if (notification.team) {
        fullName = `${limitChar(notification.sender?.fullName, 14)} - ${limitChar(notification.team.name, 20)}`;
      } else {
        fullName = limitChar(notification.sender?.fullName, 14);
      }
      break;

    // Event comment
    case 'eventComment':
      icon = <CalendarTodayIcon />;
      backgroundIconColor = '#ce1212';
      photoUrl = notification.sender?.photoUrl;
      content = `Komentar baru di Jadwal : ${notification.content}`;
      activityContent = topActivity && topActivity.activityPayload?.commentContent
        ? topActivity.activityPayload.commentContent : notification.activityContent;
      clickUrl = `/companies/${notification.company}/teams/${notification?.team?._id}/events/${notification.service.serviceId}?readNotifId=${notification._id}`;
      if (notification.team) {
        fullName = `${limitChar(notification.sender?.fullName, 14)} - ${limitChar(notification.team.name, 20)}`;
      } else {
        fullName = limitChar(notification.sender?.fullName, 14);
      }
      break;
    case 'eventCommentMention':
      icon = <AlternateEmailIcon />;
      backgroundIconColor = '#cc0e74';
      photoUrl = notification.sender?.photoUrl;
      content = notification.content;
      activityContent = notification.activityContent;
      clickUrl = `/companies/${notification.company}/teams/${notification?.team?._id}/events/${notification.service.serviceId}?readNotifId=${notification._id}`;
      if (notification.team) {
        fullName = `${limitChar(notification.sender?.fullName, 14)} - ${limitChar(notification.team.name, 20)}`;
      } else {
        fullName = limitChar(notification.sender?.fullName, 14);
      }
      break;

    // Event discussion
    case 'commentDiscussionEvent':
      icon = <CalendarTodayIcon />;
      backgroundIconColor = '#ce1212';
      photoUrl = notification.sender?.photoUrl;
      content = notification.childContent;
      activityContent = notification.activityContent;
      clickUrl = `/companies/${notification.company}/teams/${notification?.team?._id}/events/${notification.service.serviceId}/comments/${notification.childService.serviceId}?readNotifId=${notification._id}`;
      if (notification.team) {
        fullName = `${limitChar(notification.sender?.fullName, 14)} - ${limitChar(notification.team.name, 20)}`;
      } else {
        fullName = limitChar(notification.sender?.fullName, 14);
      }
      break;
    case 'commentDiscussionMentionEvent':
      icon = <AlternateEmailIcon />;
      backgroundIconColor = '#cc0e74';
      photoUrl = notification.sender?.photoUrl;
      content = notification.content;
      activityContent = notification.activityContent;
      clickUrl = `/companies/${notification.company}/teams/${notification?.team?._id}/events/${notification.service.serviceId}/comments/${notification.childService.serviceId}?readNotifId=${notification._id}`;
      if (notification.team) {
        fullName = `${limitChar(notification.sender?.fullName, 14)} - ${limitChar(notification.team.name, 20)}`;
      } else {
        fullName = limitChar(notification.sender?.fullName, 14);
      }
      break;

    // Doc
    case 'doc':
      icon = <InsertDriveFileIcon />;
      backgroundIconColor = '#7868e6';
      photoUrl = notification.sender?.photoUrl;
      content = `Membuat Dokumen baru : ${notification.content}`;
      clickUrl = `/companies/${notification.company}/teams/${notification?.team?._id}/docs/${notification.service.serviceId}?readNotifId=${notification._id}`;
      if (notification.team) {
        fullName = `${limitChar(notification.sender?.fullName, 14)} - ${limitChar(notification.team.name, 20)}`;
      } else {
        fullName = limitChar(notification.sender?.fullName, 14);
      }
      break;
    case 'docMention':
      icon = <AlternateEmailIcon />;
      backgroundIconColor = '#cc0e74';
      photoUrl = notification.sender?.photoUrl;
      content = notification.content;
      clickUrl = `/companies/${notification.company}/teams/${notification?.team?._id}/docs/${notification.service.serviceId}?readNotifId=${notification._id}`;
      if (notification.team) {
        fullName = `${limitChar(notification.sender?.fullName, 14)} - ${limitChar(notification.team.name, 20)}`;
      } else {
        fullName = limitChar(notification.sender?.fullName, 14);
      }
      break;
    case 'docSubscription':
      icon = <InsertDriveFileIcon />;
      backgroundIconColor = '#7868e6';
      photoUrl = notification.sender?.photoUrl;
      content = notification.content;
      clickUrl = `/companies/${notification.company}/teams/${notification?.team?._id}/docs/${notification.service.serviceId}?readNotifId=${notification._id}`;
      if (notification.team) {
        fullName = `${limitChar(notification.sender?.fullName, 14)} - ${limitChar(notification.team.name, 20)}`;
      } else {
        fullName = limitChar(notification.sender?.fullName, 14);
      }
      break;

    // Doc comment
    case 'docComment':
      icon = <InsertDriveFileIcon />;
      backgroundIconColor = '#7868e6';
      photoUrl = notification.sender?.photoUrl;
      content = `Komentar baru di Dokumen : ${notification.content}`;
      activityContent = topActivity && topActivity.activityPayload?.commentContent
        ? topActivity.activityPayload.commentContent : notification.activityContent;
      clickUrl = `/companies/${notification.company}/teams/${notification?.team?._id}/docs/${notification.service.serviceId}?readNotifId=${notification._id}`;
      if (notification.team) {
        fullName = `${limitChar(notification.sender?.fullName, 14)} - ${limitChar(notification.team.name, 20)}`;
      } else {
        fullName = limitChar(notification.sender?.fullName, 14);
      }
      break;
    case 'docCommentMention':
      icon = <AlternateEmailIcon />;
      backgroundIconColor = '#cc0e74';
      photoUrl = notification.sender?.photoUrl;
      content = notification.content;
      activityContent = notification.activityContent;
      clickUrl = `/companies/${notification.company}/teams/${notification?.team?._id}/docs/${notification.service.serviceId}?readNotifId=${notification._id}`;
      if (notification.team) {
        fullName = `${limitChar(notification.sender?.fullName, 14)} - ${limitChar(notification.team.name, 20)}`;
      } else {
        fullName = limitChar(notification.sender?.fullName, 14);
      }
      break;

    // Doc discussion
    case 'commentDiscussionDoc':
      icon = <InsertDriveFileIcon />;
      backgroundIconColor = '#7868e6';
      photoUrl = notification.sender?.photoUrl;
      content = notification.childContent;
      activityContent = notification.activityContent;
      clickUrl = `/companies/${notification.company}/teams/${notification?.team?._id}/docs/${notification.service.serviceId}/comments/${notification.childService.serviceId}?readNotifId=${notification._id}`;
      if (notification.team) {
        fullName = `${limitChar(notification.sender?.fullName, 14)} - ${limitChar(notification.team.name, 20)}`;
      } else {
        fullName = limitChar(notification.sender?.fullName, 14);
      }
      break;
    case 'commentDiscussionMentionDoc':
      icon = <AlternateEmailIcon />;
      backgroundIconColor = '#cc0e74';
      photoUrl = notification.sender?.photoUrl;
      content = notification.content;
      activityContent = notification.activityContent;
      clickUrl = `/companies/${notification.company}/teams/${notification?.team?._id}/docs/${notification.service.serviceId}/comments/${notification.childService.serviceId}?readNotifId=${notification._id}`;
      if (notification.team) {
        fullName = `${limitChar(notification.sender?.fullName, 14)} - ${limitChar(notification.team.name, 20)}`;
      } else {
        fullName = limitChar(notification.sender?.fullName, 14);
      }
      break;

    // File
    case 'file':
      icon = <AttachFileIcon />;
      backgroundIconColor = '#822659';
      photoUrl = notification.sender?.photoUrl;
      content = `Mengunggah File baru : ${notification.content}`;
      clickUrl = `/companies/${notification.company}/teams/${notification?.team?._id}/files/${notification.service.serviceId}?readNotifId=${notification._id}`;
      if (notification.team) {
        fullName = `${limitChar(notification.sender?.fullName, 14)} - ${limitChar(notification.team.name, 20)}`;
      } else {
        fullName = limitChar(notification.sender?.fullName, 14);
      }
      break;
    case 'fileSubscription':
      icon = <AttachFileIcon />;
      backgroundIconColor = '#822659';
      photoUrl = notification.sender?.photoUrl;
      content = notification.content;
      clickUrl = `/companies/${notification.company}/teams/${notification?.team?._id}/files/${notification.service.serviceId}?readNotifId=${notification._id}`;
      if (notification.team) {
        fullName = `${limitChar(notification.sender?.fullName, 14)} - ${limitChar(notification.team.name, 20)}`;
      } else {
        fullName = limitChar(notification.sender?.fullName, 14);
      }
      break;

    // File comment
    case 'fileComment':
      icon = <AttachFileIcon />;
      backgroundIconColor = '#822659';
      photoUrl = notification.sender?.photoUrl;
      content = `Komentar baru di File : ${notification.content}`;
      activityContent = topActivity && topActivity.activityPayload?.commentContent
        ? topActivity.activityPayload.commentContent : notification.activityContent;
      clickUrl = `/companies/${notification.company}/teams/${notification?.team?._id}/files/${notification.service.serviceId}?readNotifId=${notification._id}`;
      if (notification.team) {
        fullName = `${limitChar(notification.sender?.fullName, 14)} - ${limitChar(notification.team.name, 20)}`;
      } else {
        fullName = limitChar(notification.sender?.fullName, 14);
      }
      break;
    case 'fileCommentMention':
      icon = <AlternateEmailIcon />;
      backgroundIconColor = '#cc0e74';
      photoUrl = notification.sender?.photoUrl;
      content = notification.content;
      activityContent = notification.activityContent;
      clickUrl = `/companies/${notification.company}/teams/${notification?.team?._id}/files/${notification.service.serviceId}?readNotifId=${notification._id}`;
      if (notification.team) {
        fullName = `${limitChar(notification.sender?.fullName, 14)} - ${limitChar(notification.team.name, 20)}`;
      } else {
        fullName = limitChar(notification.sender?.fullName, 14);
      }
      break;

    // File discussion
    case 'commentDiscussionFile':
      icon = <AttachFileIcon />;
      backgroundIconColor = '#822659';
      photoUrl = notification.sender?.photoUrl;
      content = notification.childContent;
      activityContent = notification.activityContent;
      clickUrl = `/companies/${notification.company}/teams/${notification?.team?._id}/files/${notification.service.serviceId}/comments/${notification.childService.serviceId}?readNotifId=${notification._id}`;
      if (notification.team) {
        fullName = `${limitChar(notification.sender?.fullName, 14)} - ${limitChar(notification.team.name, 20)}`;
      } else {
        fullName = limitChar(notification.sender?.fullName, 14);
      }
      break;
    case 'commentDiscussionMentionFile':
      icon = <AlternateEmailIcon />;
      backgroundIconColor = '#cc0e74';
      photoUrl = notification.sender?.photoUrl;
      content = notification.content;
      activityContent = notification.activityContent;
      clickUrl = `/companies/${notification.company}/teams/${notification?.team?._id}/files/${notification.service.serviceId}/comments/${notification.childService.serviceId}?readNotifId=${notification._id}`;
      if (notification.team) {
        fullName = `${limitChar(notification.sender?.fullName, 14)} - ${limitChar(notification.team.name, 20)}`;
      } else {
        fullName = limitChar(notification.sender?.fullName, 14);
      }
      break;

    // bucket
    case 'bucketSubscription':
      icon = <FolderIcon />;
      backgroundIconColor = '#f0a500';
      photoUrl = notification.sender?.photoUrl;
      content = notification.content;
      clickUrl = `/companies/${notification.company}/teams/${notification?.team?._id}/buckets/${notification.service.serviceId}?readNotifId=${notification._id}`;
      if (notification.team) {
        fullName = `${limitChar(notification.sender?.fullName, 14)} - ${limitChar(notification.team.name, 20)}`;
      } else {
        fullName = limitChar(notification.sender?.fullName, 14);
      }
      break;

    // Subsription system
    case 'subscriptionSystem':
      // eslint-disable-next-line no-case-declarations
      const companyNotifId = notification?.activities?.[0]?.service?.serviceId;
      icon = <NotificationsIcon />;
      backgroundIconColor = '#f0a500';
      photoUrl = logoSquareImage;
      content = notification.content;
      clickUrl = companyNotifId ? `/companies/${companyNotifId}/billings?readNotifId=${notification._id}` : '#';
      fullName = 'Cicle';
      break;

    // Cheer
    case 'cheersSystem':
      icon = <HighlightIcon />;
      backgroundIconColor = '#007965';
      photoUrl = logoSquareImage;
      content = notification.content;
      clickUrl = `/companies/${notification.company}/cheers?readNotifId=${notification._id}`;
      fullName = 'Cicle';
      break;

    // Task
    case 'taskSystem':
      icon = <NotificationsIcon />;
      backgroundIconColor = '#f0a500';
      photoUrl = logoSquareImage;
      content = notification.content;
      clickUrl = `/companies/${notification.company}/my-tasks/`;
      fullName = 'Cicle';
      break;

    case 'task':
      icon = <NotificationsIcon />;
      backgroundIconColor = '#f0a500';
      photoUrl = logoSquareImage;
      content = notification.childContent;
      clickUrl = `/companies/${notification.company}/my-tasks/`;
      fullName = 'Cicle';
      break;

    default:
    //
  }

  const checkId = [user._id];
  const filteredActivities = notification.activities.filter(
    (activity) => !activity.readBy.some((readBy) => checkId.includes(readBy.reader)),
  );

  const customStyleBadge = {
    zIndex: '3',
    marginRight: '8px',
    backgroundColor: Color.yellowAccentCicle,
  };

  const isNotifSystem = type.indexOf('System') !== -1 || type === 'task';
  const isGroupChat = type === 'groupChat';
  return (
    <>
      {markReadMode ? (
        <>
          {filteredActivities.length < 1 ? (
            <div
              className={
                selectedReadNotifs.includes(notification._id)
                  ? `${styles.Notification} ${styles.selected} ${styles.noclick}`
                  : `${styles.Notification} ${styles.noclick}`
              }
            >
              <div className={styles.iconSection}>
                {isNotifSystem || isGroupChat
                  ? <LazyLoadImage className={styles.notifSystemIcon} src={photoUrl} />
                  : <ImageMember photoUrl={photoUrl} />}
                <LittleIcon color={backgroundIconColor}>{icon}</LittleIcon>
              </div>
              <div className={`${styles.bodySection} ${variantStyles[variant]}`}>
                <div className={styles.bodySection__text}>
                  <div className={styles.bodySection__text__title}>
                    <DisplayTextBody
                      decoration="bold"
                    >
                      {limitChar(content, 100)}
                    </DisplayTextBody>
                    {activityContent && (
                    <div className={styles.activityContent}>
                      <DisplayTextBody color={Color.gray3}>
                        {`"${activityContent}"`}
                      </DisplayTextBody>
                    </div>
                    )}
                  </div>
                  <div className={styles.bodySection__counter}>
                    <DisplayBadgeCounter
                      customStyle={customStyleBadge}
                      badgeContent={filteredActivities?.length}
                    />
                  </div>
                </div>
                <div className={styles.bodySection__text__desc}>
                  <div className={styles.fullNameAndTeamSection}>
                    <DisplayTextBody
                      mode="11"
                      color={Color.gray3}
                    >
                      {fullName}
                    </DisplayTextBody>
                  </div>
                  <DisplayDateText
                    mode="11"
                    color={Color.gray3}
                    date={notification.updatedAt}
                  />
                </div>
              </div>
            </div>
          ) : (
            <div
              onClick={async () => await clicked(notification._id, type)}
              className={
                selectedReadNotifs.includes(notification._id)
                  ? `${styles.Notification} ${styles.selected}`
                  : `${styles.Notification} ${styles.selected}`
              }
            >
              <div className={styles.iconSection}>
                {isNotifSystem || isGroupChat
                  ? <LazyLoadImage className={styles.notifSystemIcon} src={photoUrl} />
                  : <ImageMember photoUrl={photoUrl} />}
                <LittleIcon color={backgroundIconColor}>{icon}</LittleIcon>
              </div>
              <div className={`${styles.bodySection} ${variantStyles[variant]}`}>
                <div className={styles.bodySection__text}>
                  <div className={styles.bodySection__text__title}>
                    <DisplayTextBody
                      decoration="bold"
                    >
                      {limitChar(content, 100)}
                    </DisplayTextBody>
                    {activityContent && (
                    <div className={styles.activityContent}>
                      <DisplayTextBody color={Color.gray3}>
                        {`"${activityContent}"`}
                      </DisplayTextBody>
                    </div>
                    )}
                  </div>
                  <div className={styles.bodySection__counter}>
                    <DisplayBadgeCounter
                      customStyle={customStyleBadge}
                      badgeContent={filteredActivities?.length}
                    />
                    {selectedReadNotifs.includes(notification._id) ? (
                      <CheckBoxIcon className={styles.selectedIcon} />
                    ) : (
                      <CheckBoxOutlineBlankIcon
                        className={styles.unselectedIcon}
                      />
                    )}
                  </div>
                </div>
                <div className={styles.bodySection__text__desc}>
                  <div className={styles.fullNameAndTeamSection}>
                    <DisplayTextBody
                      mode="11"
                      color={Color.gray3}
                    >
                      {fullName}
                    </DisplayTextBody>
                  </div>
                  <DisplayDateText
                    mode="11"
                    color={Color.gray3}
                    date={notification.updatedAt}
                  />
                </div>
              </div>
            </div>
          )}
        </>
      ) : (
        <LinkNoDecor to={clickUrl}>
          <div
            onClick={async () => await clicked(notification._id, type)}
            className={
              filteredActivities.length < 1
                ? `${styles.Notification}`
                : `${styles.Notification} ${styles.selected}`
            }
          >
            <div className={styles.iconSection}>
              {isNotifSystem || isGroupChat
                ? <LazyLoadImage className={styles.notifSystemIcon} src={photoUrl} />
                : <ImageMember photoUrl={photoUrl} />}
              <LittleIcon color={backgroundIconColor}>{icon}</LittleIcon>
            </div>
            <div className={`${styles.bodySection} ${variantStyles[variant]}`}>
              <div className={styles.bodySection__text}>
                <div className={styles.bodySection__text__title}>
                  <DisplayTextBody
                    decoration="bold"
                  >
                    {limitChar(content, 100)}
                  </DisplayTextBody>
                  {activityContent && (
                    <div className={styles.activityContent}>
                      <DisplayTextBody color={Color.gray3}>
                        {`"${activityContent}"`}
                      </DisplayTextBody>
                    </div>
                  )}
                </div>
                <div className={styles.bodySection__counter}>
                  <DisplayBadgeCounter
                    customStyle={customStyleBadge}
                    badgeContent={filteredActivities?.length}
                  />
                </div>
              </div>
              <div className={styles.bodySection__text__desc}>
                <div className={styles.fullNameAndTeamSection}>
                  <DisplayTextBody
                    mode="11"
                    color={Color.gray3}
                  >
                    {fullName}
                  </DisplayTextBody>
                </div>
                <DisplayDateText
                  mode="11"
                  color={Color.gray3}
                  date={notification.updatedAt}
                />
              </div>
            </div>
          </div>
        </LinkNoDecor>
      )}
    </>
  );
};

Notification.propTypes = {
  notification: PropTypes.object.isRequired,
  user: PropTypes.object.isRequired,
  clicked: PropTypes.func.isRequired,
  markReadMode: PropTypes.bool.isRequired,
  selectedReadNotifs: PropTypes.array.isRequired,
  variant: PropTypes.oneOf(['fullWidth', 'regular']),
};

Notification.defaultProps = {
  variant: 'regular',
};

export default Notification;
