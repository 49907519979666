import React, {
  useState, useEffect, useContext, useCallback,
} from 'react';
import { useHistory, useParams, useLocation } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import queryString from 'query-string';
import { isEmpty } from 'lodash';
import PropTypes from 'prop-types';
import Tooltip from '@mui/material/Tooltip';
import PlaylistAddCheckIcon from '@mui/icons-material/PlaylistAddCheck';
import OutsideClickHandler from 'react-outside-click-handler';
import { useMediaQuery } from '@mui/material';
import { InputButtonMain } from '../../components/UI_V2/Input/Button';
import InfinityScroll from '../../components/UI/InfinityScroll/InfinityScroll';
import OverlayButton from '../../components/UI/Button/OverlayButton/OverlayButton';
import handleLoadings from '../../utilities/handleLoadings';
import handleStatusMsg from '../../utilities/handleStatusMsg';
import Notification from './Notification/Notification';
import styles from './NotificationContainer.module.css';
import BoxPopOver from '../../components/UI/BoxPopOver/BoxPopOver';
import { GlobalContext, initialState } from '../../contexts/GlobalStateProvider';
import apiUtil from '../../utilities/apiUtil';
import { NotificationActions } from '../../actions';
import { useInfinityScrollNotificationHooks } from '../../hooks/InfinityScrollHooks';
import { useNotificationCounterTeamsSocket, useNotificationUnreadSocket } from '../../hooks/NotificationHooks';
import { ApiConstants, NotificationConstants } from '../../constants';
import LinkNoDecor from '../../components/LinkNoDecor/LinkNoDecor';
import NotificationsCategoryContainer from './NotificationsCategoryContainer/NotificationsCategoryContainer';
import CheersButton from './CheersButton/CheersButton';
import FilterNotifButton from './FilterNotifButton/FilterNotifButton';
import FilterNotifPopUp from './FilterNotifPopUp/FilterNotifPopUp';
import { useLocalStorage } from '../../hooks/HelperHooks';
import MarkSelection from './MarkSelection/MarkSelection';
import { NOTIF_ACTIVITY_API_URL } from '../../constants/ApiConstants';
import { SurfacePaperFloating } from '../../components/UI_V2/Surface/Paper';

const NotificationContainer = ({ cancel, params }) => {
  const [{
    user, currentNotifications, counterNotif, currentCompany, counterTeamsNotif,
  }, dispatch] = useContext(GlobalContext);
  const [markReadMode, setMarkReadMode] = useState(false);
  const [openFilterNotif, setOpenFilterNotif] = useState(false);
  const [selectedTabNotif, setSelectedTabNotif] = useState('all');
  const [selectedReadNotifs, setSelectedReadNotifs] = useState([]);
  const [postSelectedReadNotifs, setPostSelectedReadNotifs] = useState([]);
  // const [postReadAllNotifs, setPostReadAllNotifs] = useState([]);
  const [checkMode, setCheckMode] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const [loadings, setLoadings] = useState([]);

  const history = useHistory();
  const { companyId, teamId } = params;
  const location = useLocation();
  const query = queryString.parse(location.search);

  const setCheckAllMode = useCallback(() => {
    if (selectedReadNotifs.length === currentNotifications.data.length
      && currentNotifications.data.length > 0
    ) {
      setCheckMode(true);
    } else {
      setCheckMode(false);
    }
  }, [selectedReadNotifs, currentNotifications]);

  useEffect(() => {
    setCheckAllMode();
  }, [currentNotifications, selectedReadNotifs]);

  const [defaultViewNotif, setDefaultViewNotif] = useLocalStorage(`notifConfig-${user?._id}-${companyId}`, {
    defaultView: 'all',
  });
  const isItemSelected = (id) => !!selectedTabNotif.includes(id);
  const handleSelectTab = useCallback((id) => {
    if (id === 'all' && isItemSelected('all')) return;
    if (isItemSelected(id)) {
      setSelectedTabNotif('all');
      // reset notifications
      NotificationActions.resetCurrentNotif({}, dispatch);
      return;
    }
    setSelectedTabNotif(id);
    // reset checklist
    setSelectedReadNotifs([]);
    // reset notifications
    NotificationActions.resetCurrentNotif({}, dispatch);
  }, [selectedTabNotif, selectedReadNotifs, currentNotifications]);

  const handleChangeDefaultViewNotif = useCallback((value) => {
    setDefaultViewNotif((prevValue) => ({
      ...prevValue,
      defaultView: value,
    }));
  }, []);

  const getCounterAnchorCounterTeams = useCallback((id) => {
    const result = counterTeamsNotif.find((value) => value.team._id === id);
    return result?.unreadNotification;
  }, [counterTeamsNotif]);

  // useNotificationCounterTeamsSocket({ companyId, userId: user?._id }, dispatch);

  const isSelectedTabIsAll = selectedTabNotif === 'all';
  const socketParams = isSelectedTabIsAll ? {
    userId: user?._id, companyId, typeNotif: 'popUp', filter: 'unread',
  } : {
    userId: user?._id, companyId, teamId: selectedTabNotif, typeNotif: 'popUp', filter: 'unread',
  };
  const eventParam = isSelectedTabIsAll ? `${companyId}-${user?._id}-unread` : `${selectedTabNotif}-${user?._id}-unread`;

  const { socket } = useNotificationUnreadSocket({
    ...socketParams,
  }, dispatch);

  const getMoreLists = async () => {
    const nextTotalNotif = currentNotifications.total
      + NotificationConstants.limitNotif;

    socket.emit(eventParam, { limit: nextTotalNotif, filter: 'unread' });
  };

  const resetPreviousLists = useCallback(() => {
    NotificationActions.setCurrentNotif({
      data: initialState.currentNotifications.data,
    }, dispatch);
    NotificationActions.setTotalNotif({
      total: initialState.currentNotifications.total,
    }, dispatch);
  }, []);

  const {
    lists,
    handleLoadMoreLists,
    checkIfListNeedToLoad,
  } = useInfinityScrollNotificationHooks({
    currentObjectWithKeyProperty: currentNotifications,
    resetPreviousLists,
    getMoreLists,
    keyProperty: 'data',
    additionalCounterAnchor:
    isSelectedTabIsAll
      ? counterNotif?.nonChat : getCounterAnchorCounterTeams(selectedTabNotif),
  });

  useEffect(() => {
    if (isEmpty(currentNotifications.data)) {
      const startLoadings = handleLoadings('notifPopUp', [...loadings], 'start');
      setLoadings([...startLoadings]);
    }
    if (defaultViewNotif.defaultView === 'team' && teamId) {
      handleSelectTab(teamId);
      return;
    }
    if (defaultViewNotif.defaultView === 'team') {
      if (query.teamId && query.teamId !== null && query.teamId !== undefined) {
        handleSelectTab(query.teamId);
      }
    }
  }, []);

  useEffect(() => {
    if (postSelectedReadNotifs.length < 1) return;

    const handleApiMarkSelected = async () => {
      try {
        const startLoadings = handleLoadings('notifPopUp', [...loadings], 'start');
        setLoadings([...startLoadings]);

        const bodyRequest = {
          notificationIds: [...postSelectedReadNotifs],
          companyId,
        };
        const result = await apiUtil.patch(
          ApiConstants.URL_V1.NOTIFICATIONS(),
          bodyRequest,
          {
            params: {
              selectBy: 'selected',
              companyId,
            },
          },
          NOTIF_ACTIVITY_API_URL,
        );
        const status = handleStatusMsg(result, 'success');

        enqueueSnackbar(status.message, {
          variant: 'success',
        });
      } catch (err) {
        const status = handleStatusMsg(err, 'error');

        enqueueSnackbar(status.message, {
          variant: 'error',
        });
      } finally {
        const endLoadings = handleLoadings('notifPopUp', [...loadings], 'end');
        setLoadings([...endLoadings]);
        setSelectedReadNotifs([]);
        setPostSelectedReadNotifs([]);
        setMarkReadMode(!markReadMode);
      }
    };

    handleApiMarkSelected();
  }, [postSelectedReadNotifs]);

  const handlePostMarkSelected = () => {
    if (selectedReadNotifs.length < 1) {
      setMarkReadMode(!markReadMode);
      return;
    }

    setPostSelectedReadNotifs([...selectedReadNotifs]);
  };

  // const readNotification = async (notificationId) => {
  //   try {
  //     await apiUtil.patch(
  //       ApiConstants.URL_V1.NOTIFICATION({ notificationId }),
  //       {},
  //       {
  //         params: {
  //           companyId,
  //         },
  //       },
  //       NOTIF_ACTIVITY_API_URL,
  //     );
  //   } catch (err) {
  //     const status = handleStatusMsg(err, 'error');

  //     enqueueSnackbar(status.message, {
  //       variant: 'error',
  //     });
  //   }
  // };

  const handleClickNotif = async (notificationId, typeNotification) => {
    if (markReadMode) {
      let currentNotifs = [...selectedReadNotifs];
      const filteredNotifs = selectedReadNotifs.filter((notif) => notif === notificationId);

      if (filteredNotifs.length < 1) {
        currentNotifs.push(notificationId);
      } else {
        const removedNotifs = currentNotifs.filter((notif) => notif !== notificationId);
        currentNotifs = [...removedNotifs];
      }

      if (currentNotifs.length === currentNotifications.data.length) {
        setCheckMode(true);
      } else {
        setCheckMode(false);
      }

      setSelectedReadNotifs([...currentNotifs]);
    } else {
      // been handled by readNotifId on page open
      // await readNotification(notificationId);
      // await readAllRelatedNotifications(typeNotification);
    }
  };

  const handleClickMarkSelected = () => {
    setMarkReadMode(!markReadMode);
  };

  const handleCheckAll = () => {
    setCheckMode(!checkMode);

    if (!checkMode) {
      const notificationIds = [];

      currentNotifications.data.forEach((notif) => {
        notificationIds.push(notif._id);
      });

      setSelectedReadNotifs([...notificationIds]);
    } else {
      setSelectedReadNotifs([]);
    }
  };

  const handleOpenFilterPopUp = useCallback(() => {
    setOpenFilterNotif(true);
  }, []);

  const handleCloseFilterPopUp = useCallback(() => {
    setOpenFilterNotif(false);
  }, []);

  const getUrlViewAllNotifications = useCallback(() => {
    let url = '/';
    if (companyId && teamId) {
      url = `/companies/${companyId}/notifications?teamId=${teamId}`;
    }

    if (companyId && !teamId) {
      url = `/companies/${companyId}/notifications`;
    }

    return url;
  }, [companyId, teamId]);

  const isMobile = useMediaQuery('(max-width:720px)');

  // const customStyle = {};

  // if (isMobile) {
  //   customStyle.width = '330px';
  //   customStyle.right = '-2.6rem';
  // }

  return (
    <SurfacePaperFloating
      onOutsideClick={cancel}
      size="mlg"
      // customStyle={customStyle}
      position="topLeft"
      disabled={openFilterNotif}
    >
      <div className={styles.bodySection}>
        <div className={styles.newSection}>
          <div className={styles.newSection__header}>
            <div className={styles.leftHeaderSection}>
              <div className={styles.leftHeaderSection__cheers}>
                <LinkNoDecor to={companyId ? `/companies/${companyId}/cheers` : '/'}>
                  <CheersButton />
                </LinkNoDecor>
              </div>
              <div className={styles.leftHeaderSection__filter}>
                <FilterNotifButton onClick={handleOpenFilterPopUp} />
                <FilterNotifPopUp
                  open={openFilterNotif}
                  onClose={handleCloseFilterPopUp}
                  listCounterTeams={counterTeamsNotif}
                  handleSelectTab={handleSelectTab}
                  handleChangeDefaultViewNotif={handleChangeDefaultViewNotif}
                  defaultViewNotif={defaultViewNotif}
                  selectedTab={selectedTabNotif}
                  type="popUp"
                />
              </div>
            </div>
            <div className={styles.markReadSection}>
              {markReadMode ? (
                <MarkSelection
                  handleCancel={handleClickMarkSelected}
                  handlePublish={handlePostMarkSelected}
                  handleCheckAll={handleCheckAll}
                  isListEmpty={lists.length < 1}
                  checkMode={checkMode}
                />
              ) : null}
              {!markReadMode ? (
                <Tooltip title="Tandai notifikasi udah dibaca">
                  <PlaylistAddCheckIcon
                    className={styles.checkBoxUnread}
                    onClick={handleClickMarkSelected}
                  />
                </Tooltip>
              ) : null}
            </div>
          </div>
          <div className={styles.newSection__tabCategory}>
            <NotificationsCategoryContainer
              listNotifications={lists}
              listCounterTeams={counterTeamsNotif}
              handleSelectTab={handleSelectTab}
              selectedTab={selectedTabNotif}
              type="popUp"
            />
          </div>
          <div id="notification-pop-up" className={styles.newSection__body}>
            {companyId ? (
              <InfinityScroll
                scrollableTarget="notification-pop-up"
                dataLength={lists?.length || 0}
                next={handleLoadMoreLists}
                hasMore={checkIfListNeedToLoad()}
                emptyMessage={selectedTabNotif === 'all'
                  ? 'Belum ada notifikasi untukmu saat ini'
                  : 'Belum ada notifikasi di tim ini saat ini'}
              >
                <OverlayButton wait={lists.length > 0 ? 'notifPopUp' : null} loadings={loadings}>
                  {lists?.map((notification) => (
                    <Notification
                      notification={notification}
                      user={user}
                      clicked={handleClickNotif}
                      markReadMode={markReadMode}
                      selectedReadNotifs={selectedReadNotifs}
                    />
                  ))}
                </OverlayButton>
              </InfinityScroll>
            ) : null}
          </div>
        </div>
        <LinkNoDecor to={getUrlViewAllNotifications}>
          <div className={styles.actionSection}>
            <InputButtonMain variant="grayLight" wide="block">Lihat Semua Notifikasi</InputButtonMain>
          </div>
        </LinkNoDecor>
      </div>
    </SurfacePaperFloating>
  );
};

NotificationContainer.propTypes = {
  cancel: PropTypes.func.isRequired,
  params: PropTypes.object,
};

NotificationContainer.defaultProps = {
  params: { companyId: null, teamId: null },
};

export default NotificationContainer;
