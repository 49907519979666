import { isArray, isNumber, lowerFirst } from 'lodash';
import { extractTextFromOldEditor } from './regexUtil';

const adjustServerUrl = (url) => {
  if (!url) return null;
  let result;
  if (url?.charAt(0) === '/') {
    result = process.env.REACT_APP_PRIMARY_API_URL + url;
  } else {
    result = url;
  }
  return result;
};

const splitString = (string, delimiter) => {
  if (!string || !delimiter) return [];
  return string.split(delimiter);
};

const getSingleOrPlural = (singleText, anchor) => {
  if (!singleText && !anchor) return null;
  if (singleText && !anchor) return singleText;
  let counter;
  if (isNumber(anchor)) counter = anchor;
  if (isArray(anchor)) counter = anchor.length;
  if (!counter) return singleText;
  let text = singleText;
  if (counter > 1) {
    text = `${singleText}s`;
  }
  return text;
};

const limitChar = (content, limit, isRemoveHTML, isWithoutDots) => {
  try {
    if (!content) {
      return null;
    }

    let result = content;

    if (isRemoveHTML) {
      result = result.replace(/<\/?("[^"]*"|'[^']*'|[^>])*(>|$)/g, '').replace(/\n/g, ' ');
    }

    if (!limit) {
      return result;
    }

    if (result.length > limit) {
      if (isWithoutDots) {
        result = `${result.slice(0, limit)}`;
      } else {
        result = `${result.slice(0, limit)}...`;
      }
    }

    return result;
  } catch (err) {
    return err;
  }
};

function sliceNumber(number, length) {
  const numberString = number.toString();
  return numberString.slice(0, length);
}

const getPureStringWithLimit = (content, limit) => {
  try {
    let text = limitChar(content, null, true);
    text = extractTextFromOldEditor(text);
    text = limitChar(text, limit);
    return text;
  } catch (error) {
    return error;
  }
};

function extractElementBySelectorFromString(HTMLString, search) {
  const domDoc = new DOMParser().parseFromString(HTMLString, 'text/html');

  const domResults = domDoc.querySelectorAll(search);
  const finalResults = [];
  domResults.forEach((el) => {
    const { mentionedUserId } = el.dataset;
    if (!finalResults.includes(mentionedUserId)) finalResults.push(mentionedUserId);
  });
  return finalResults;
}

function checkIsJson(str) {
  try {
    JSON.parse(str);
  } catch (e) {
    return false;
  }
  // eslint-disable-next-line no-restricted-globals
  return isNaN(str);
}

const getFileExt = (str) => str.slice((Math.max(0, str.lastIndexOf('.')) || Infinity) + 1);

function firstCharToLower(inputString) {
  if (!inputString || inputString.length === 0) {
    // Handle empty input string, return an empty string
    return '';
  }

  // Extract the first character and convert it to lowercase
  const firstCharLower = inputString[0].toLowerCase();

  // Concatenate the lowercased first character with the rest of the input string
  const resultString = firstCharLower + inputString.slice(1);

  return resultString;
}

export {
  adjustServerUrl,
  splitString,
  getSingleOrPlural,
  limitChar,
  extractElementBySelectorFromString,
  checkIsJson,
  getFileExt,
  lowerFirst,
  getPureStringWithLimit,
  firstCharToLower,
  sliceNumber,
};
