import React, {
  memo, useContext, useEffect, useState,
} from 'react';
import { useParams } from 'react-router-dom';
import PropTypes from 'prop-types';
import { useSnackbar } from 'notistack';
import { MenuItem } from '@mui/material';
import styles from './ModalCreateTeam.module.css';
import { FeedbackDialogGeneral } from '../../../../components/UI_V2/Feedback/Dialog';
import { GlobalContext } from '../../../../contexts/GlobalStateProvider';
import handleLoadings from '../../../../utilities/handleLoadings';
import { TeamActions } from '../../../../actions';
import handleStatusMsg from '../../../../utilities/handleStatusMsg';
import { DisplayTextBody, DisplayTextHeadline, DisplayTextSubHeadline } from '../../../../components/UI_V2/Display/Text';
import { InputTextFieldMain } from '../../../../components/UI_V2/Input/TextField';
import { InputButtonMain } from '../../../../components/UI_V2/Input/Button';
import Color from '../../../../themes/colors';
import { InputSelectMain } from '../../../../components/UI_V2/Input/Select';
import { InputRadioButtonMain } from '../../../../components/UI_V2/Input/RadioButton';
import RadioTeamType from './RadioTeamType/RadioTeamType';
import { elementId } from '../../../../constants/ElementIdConstants';

function ModalCreateTeam({
  open, onClose, initialType, params,
}) {
  const [{
    user,
    teams,
    currentCompany,
  }, dispatch] = useContext(GlobalContext);
  const { enqueueSnackbar } = useSnackbar();
  const [loadings, setLoadings] = useState([]);
  const [newTeam, setNewTeam] = useState({
    name: '',
    desc: '',
    type: initialType || 'team',
  });

  // const params = useParams();
  const { companyId } = params;

  const clearValue = () => {
    setNewTeam({
      name: '',
      desc: '',
      type: initialType || 'team',
    });
  };

  const handleChanged = (event) => {
    const { name, value } = event.target;

    setNewTeam((prevValue) => ({
      ...prevValue,
      [name]: value,
    }));
  };

  const handleClicked = async () => {
    try {
      const startLoadings = handleLoadings('createTeam', [...loadings], 'start');
      setLoadings([...startLoadings]);

      if (!newTeam.name || newTeam.name.length < 1) {
        enqueueSnackbar('Team name required', {
          variant: 'error',
        });
        return;
      }

      if (!newTeam.desc || newTeam.desc.length < 1) {
        enqueueSnackbar('Team description required', {
          variant: 'error',
        });
        return;
      }

      if (!newTeam.type) {
        enqueueSnackbar('Team type required', {
          variant: 'error',
        });
        return;
      }
      const payload = {
        name: newTeam.name,
        desc: newTeam.desc,
        type: newTeam.type,
      };
      const result = await TeamActions.createTeamAndUpdateTeamsThenUpdateCompanyAndCompanies(
        {
          companyId, payload, user, teams, currentCompany,
        }, dispatch,
      );

      const status = handleStatusMsg(result, 'success');

      enqueueSnackbar(status.message, {
        variant: 'success',
      });

      clearValue();
      onClose();
    } catch (err) {
      const status = handleStatusMsg(err, 'error');

      enqueueSnackbar(status.message, {
        variant: 'error',
      });
    } finally {
      const endLoadings = handleLoadings('createTeam', [...loadings], 'end');
      setLoadings([...endLoadings]);
    }
  };
  return (
    <FeedbackDialogGeneral
      open={open}
      onClose={onClose}
      maxWidth="xs"
    //   fullWidth={false}
    >
      <div className={styles.container}>
        <div className={styles.title}>
          <DisplayTextHeadline
            color={Color.blueNavyCicle}
            decoration="bold"
          >
            Buat Tim
          </DisplayTextHeadline>
        </div>
        {/* <div className={styles.description}>
          <DisplayTextSubHeadline
            mode="16"
          >
            You can name it such as Company HQ or Marketing Team.
            Don&#39;t worry, you can edit this later.
          </DisplayTextSubHeadline>
        </div> */}
        <div className={styles.formSection}>
          <div className={styles.form}>
            <div className={styles.formText}>
              <DisplayTextBody
                decoration="bold"
              >
                Nama Tim
              </DisplayTextBody>
            </div>
            <div className={styles.formArea}>
              <InputTextFieldMain
                placeholder="Cicle HQ, Marketing Division, Project Vendor X"
                name="name"
                autoFocus
                onChange={(event) => handleChanged(event)}
                value={newTeam.name}
                id={elementId.createTeam_name_text_field}
              />
            </div>
          </div>
          <div className={styles.form}>
            <div className={styles.formText}>
              <DisplayTextBody
                decoration="bold"
              >
                {/* Description */}
                Deskripsi
              </DisplayTextBody>
            </div>
            <div className={styles.formArea}>
              <InputTextFieldMain
                placeholder="Tim untuk koordinasi marketing, Tempat semua kumpul orang, Proyek dengan vendor X"
                name="desc"
                onChange={(event) => handleChanged(event)}
                value={newTeam.desc}
                id={elementId.createTeam_desc_text_field}
              />
            </div>
          </div>
          <div className={styles.form}>
            <div className={styles.formText}>
              <DisplayTextBody
                decoration="bold"
              >
                Untuk apakah peruntukan Tim ini?
              </DisplayTextBody>
            </div>
            <div className={styles.formArea}>
              <div className={styles.radio}>
                <RadioTeamType
                  name="type"
                  value="hq"
                  checked={newTeam.type === 'hq'}
                  onChange={(event) => handleChanged(event)}
                >
                  Kantor Pusat
                </RadioTeamType>
              </div>
              <div className={styles.radio}>
                <RadioTeamType
                  name="type"
                  value="team"
                  checked={newTeam.type === 'team'}
                  onChange={(event) => handleChanged(event)}
                >
                  Divisi/Departemen Utama
                </RadioTeamType>
              </div>
              <div className={styles.radio}>
                <RadioTeamType
                  name="type"
                  value="project"
                  checked={newTeam.type === 'project'}
                  onChange={(event) => handleChanged(event)}
                >
                  Proyek Multi Divisi
                </RadioTeamType>
              </div>
            </div>
          </div>
        </div>
        <div className={styles.button}>
          <InputButtonMain
            handleClick={handleClicked}
            // border="rounded"
            wide="block"
            loadings={loadings}
            wait="createTeam"
            id={elementId.createTeam_create_button}
          >
            Buat
          </InputButtonMain>
        </div>
      </div>
    </FeedbackDialogGeneral>
  );
}

ModalCreateTeam.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  initialType: PropTypes.string.isRequired,
  params: PropTypes.object,
};

ModalCreateTeam.defaultProps = {
  params: { companyId: null, teamId: null },
};

export default memo(ModalCreateTeam);
