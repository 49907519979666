import React, {
  useState, useContext, useEffect, useCallback, memo,
} from 'react';
import { useHistory, useParams, useLocation } from 'react-router-dom';
import { useMediaQuery } from '@mui/material';
import { GlobalContext } from '../../../contexts/GlobalStateProvider';
import styles from './SideBarGroupChatSection.module.css';
import { DisplayTextBody } from '../../../components/UI_V2/Display/Text';
import Color from '../../../themes/colors';
import ListMembersGroupChat from './ListMembersGroupChat/ListMembersGroupChat';
import { useSearch } from '../../../hooks/HelperHooks';
import { InputSearchGeneral } from '../../../components/UI_V2/Input/Search';
import { sortMembersByStatusOnline } from '../../../actions/UserOnlineActions';

function SideBarGroupChatSection() {
  const [{ user, currentTeam, allUserOnline }, dispatch] = useContext(GlobalContext);
  const params = useParams();

  const [sortedMembers, setSortedMembers] = useState([]);

  useEffect(() => {
    const sortedByStatusMembers = sortMembersByStatusOnline(currentTeam.members, allUserOnline);
    setSortedMembers(sortedByStatusMembers);
  }, [currentTeam.members, allUserOnline]);

  const isTablet = useMediaQuery('(max-width:1024px)');
  const isMobile = useMediaQuery('(max-width:720px)');

  const {
    searchResult,
    keyword,
    keywordArray,
    setKeyword,
    isTyping,
    onChange,
    resetSearch,
  } = useSearch({ originalList: sortedMembers });
  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <DisplayTextBody
          mode="12"
          color={Color.gray3}
        >
          {!isMobile && 'Anggota Chat Grup '}
          {isMobile && 'Anggota '}
          {!isMobile && `(${currentTeam?.members?.length})`}
        </DisplayTextBody>
        <div className={styles.search}>
          <InputSearchGeneral
            onChange={onChange}
            value={keyword}
            placeholder="Cari anggota..."
            onClickCloseIcon={resetSearch}
            size="sm"
            autoFocus={false}
          />
        </div>
      </div>
      <div className={styles.body}>
        <ListMembersGroupChat members={searchResult} groupChatId={params.groupChatId} />
      </div>
    </div>
  );
}

export default memo(SideBarGroupChatSection);
