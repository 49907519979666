import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import Form from 'react-bootstrap/Form';
import { useSnackbar } from 'notistack';
import CloseIcon from '@mui/icons-material/Close';
import PropTypes from 'prop-types';
import styles from './ChangeNameForm.module.css';
import OverlayButton from '../../../components/UI/Button/OverlayButton/OverlayButton';
import Button from '../../../components/UI/Button/Button';
import handleStatusMsg from '../../../utilities/handleStatusMsg';
import handleLoadings from '../../../utilities/handleLoadings';
import { CardActions } from '../../../actions';

const ChangeNameForm = ({
  card, cancel, withSaveButton,
}) => {
  const [newCardUpdated, setNewCardUpdated] = useState({ value: card.name });
  const [cardUpdated, setCardUpdated] = useState();
  const [loadings, setLoadings] = useState([]);
  const { enqueueSnackbar } = useSnackbar();

  const params = useParams();
  const { companyId } = params;
  const teamId = params.teamId || card.team?._id || card.team;

  useEffect(() => {
    if (cardUpdated !== undefined) {
      const updateApiCardName = async () => {
        const cardId = params.cardId || card._id;

        try {
          const startLoadings = handleLoadings('changeCardName', [...loadings], 'start');
          setLoadings([...startLoadings]);

          const result = await CardActions.updateCard(
            {
              cardId, body: cardUpdated, companyId, teamId,
            },
          );

          const status = handleStatusMsg(result, 'success');

          enqueueSnackbar(status.message, {
            variant: 'success',
          });

          cancel();
        } catch (err) {
          const status = handleStatusMsg(err, 'error');

          enqueueSnackbar(status.message, {
            variant: 'error',
          });
        } finally {
          const endLoadings = handleLoadings('changeCardName', [...loadings], 'end');
          setLoadings([...endLoadings]);
        }
      };
      updateApiCardName();
    }
  }, [cardUpdated]);

  const handleChanged = (event) => {
    const { value } = event.target;
    setNewCardUpdated((prevValue) => ({
      ...prevValue,
      value,
    }));
  };

  const handleSubmitUpdate = () => {
    if (newCardUpdated === undefined
      || newCardUpdated.value === undefined
      || newCardUpdated.value.length < 1) {
      enqueueSnackbar('Card name cannot be empty', {
        variant: 'error',
      });
      return;
    }
    setCardUpdated({ name: newCardUpdated.value });
  };

  return (
    <>
      {withSaveButton
        ? (
          <>
            <Form onSubmit={(event) => {
              event.preventDefault();
              handleSubmitUpdate();
            }}
            >
              <Form.Control autoFocus className={styles.teamName} onChange={(event) => handleChanged(event)} as="input" name="name" value={newCardUpdated.value} />
              <input style={{ display: 'none' }} type="submit" />
            </Form>
            <div className={styles.actionSection}>
              <div>
                <Button handleClick={handleSubmitUpdate} variant="success" size="sm" wait="changeCardName" loadings={loadings}>Save</Button>
                <CloseIcon className={styles.icon} onClick={cancel} />
              </div>
            </div>
          </>
        )
        : (
          <OverlayButton loadings={loadings} wait="changeCardName">
            <Form onSubmit={(event) => {
              event.preventDefault();
              handleSubmitUpdate();
            }}
            >
              <Form.Control autoFocus className={styles.teamName} onChange={(event) => handleChanged(event)} as="input" name="name" value={newCardUpdated.value} />
              <input style={{ display: 'none' }} type="submit" />
            </Form>
          </OverlayButton>
        )}
    </>

  );
};

ChangeNameForm.propTypes = {
  card: PropTypes.object.isRequired,
  cancel: PropTypes.func.isRequired,
  withSaveButton: PropTypes.bool,
};

ChangeNameForm.defaultProps = {
  withSaveButton: false,
};

export default ChangeNameForm;
