import React, { memo, useState } from 'react';
import PropTypes from 'prop-types';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import styles from './CardActivitiesChart.module.css';
import { DisplayTextBody } from '../../../../../components/UI_V2/Display/Text';
import Color from '../../../../../themes/colors';
import LineChart from '../LineChart/LineChart';
import { dateBeautifyFormatter } from '../../../../../utilities/dateUtil';

const CardActivitiesChart = ({
  labels,
  activities,
}) => {
  const firstData = activities.map((elem) => (elem.cards.cardsCreated));
  const secondData = activities.map((elem) => (elem.cards.commentsCreated));

  const firstDataset = {
    label: 'Card Created',
    data: firstData,
  };

  const secondDataset = {
    label: 'Comment Created',
    data: secondData,
  };

  const datasets = [
    firstDataset,
    secondDataset,
  ];

  return (
    <div className={styles.container}>
      <LineChart
        labels={labels}
        datasets={datasets}
      />
    </div>
  );
};

CardActivitiesChart.propTypes = {
  labels: PropTypes.array,
  activities: PropTypes.array,
};

CardActivitiesChart.defaultProps = {
  labels: [],
  activities: [],
};

export default memo(CardActivitiesChart);
