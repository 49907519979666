const limitInvoices = 5;
const limitSubscriptions = 10;

const typeCallback = {
  NEW: 'new',
  DELETE: 'delete',
  EDIT: 'edit',
};

export {
  limitInvoices,
  typeCallback,
  limitSubscriptions,
};
