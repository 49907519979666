import React, {
  memo,
} from 'react';
import PropTypes from 'prop-types';
import ResultItem from '../../SearchResults/ListResult/ResultItem/ResultItem';
import { DisplayTextBody } from '../../../../../components/UI_V2/Display/Text';
import Color from '../../../../../themes/colors';

const ListRecentViewPages = ({
  list, selectedIndex, onClose, params,
}) => (
  <>
    {list.map((item, index) => (
      <ResultItem
        params={params}
        key={item._id}
        item={item}
        isSelected={index === selectedIndex}
        onClick={onClose}
        limitTitleChar={40}
      />
    ))}
    {list?.length < 1 && (
      <DisplayTextBody color={Color.gray4}>
        You haven&#39;t visited any page yet
      </DisplayTextBody>
    )}
  </>
);

ListRecentViewPages.propTypes = {
  selectedIndex: PropTypes.number.isRequired,
  list: PropTypes.array.isRequired,
  onClose: PropTypes.func.isRequired,
  params: PropTypes.object,
};

ListRecentViewPages.defaultProps = {
  params: { companyId: null, teamId: null },
};

export default memo(ListRecentViewPages);
