import React, {
  useState, useEffect, useRef, useContext, useCallback,
  memo,
} from 'react';
import { useParams } from 'react-router-dom';
import CloseIcon from '@mui/icons-material/Close';
import { useSnackbar } from 'notistack';
import PropTypes from 'prop-types';
import { isEqual } from 'lodash';
import BoxPopOver from '../../../components/UI/BoxPopOver/BoxPopOver';
import SeparatorLine from '../../../components/UI/SeparatorLine/SeparatorLine';
import styles from './QuickCommentsWithBox.module.css';
import OverlayButton from '../../../components/UI/Button/OverlayButton/OverlayButton';
import CommentsSectionContainer from '../../CommentsSectionContainer/CommentsSectionContainer';
import handleStatusMsg from '../../../utilities/handleStatusMsg';
import handleLoadings from '../../../utilities/handleLoadings';
import { GlobalContext, initialState } from '../../../contexts/GlobalStateProvider';
import InfinityScroll from '../../../components/UI/InfinityScroll/InfinityScroll';
import { useInfinityScrollHooks } from '../../../hooks/InfinityScrollHooks';
import { CardActions } from '../../../actions';
import { useCardActivities, useCardCheerNCommentSocket } from '../../../hooks/CardHooks';
import { useDelayShowHideHandler } from '../../../hooks/HelperHooks';
import ListActivitiesCard from '../ListActivitiesCard/ListActivitiesCard';
import { serviceTypeRef } from '../../../constants/ActivityConstants';
import { useUserTypingCardSocket } from '../../../hooks/UserTypingHooks';
import UserTyping from '../../../components/Container/UserTyping/UserTyping';
import { NavigationMenuBasic } from '../../../components/UI_V2/Navigation/Menu';

const QuickCommentsWithBox = ({
  card, type, cancel, disableScrollIntoView,
}) => {
  const [{
    user, currentCard, currentTeam, currentBoard, previousCardComment, currentCompany,
    allUserTypingCard,
  }, dispatch] = useContext(GlobalContext);
  const { enqueueSnackbar } = useSnackbar();
  const [loadings, setLoadings] = useState([]);
  const [openCreateCommentForm, setOpenCreateCommentForm] = useState(false);
  const itemRef = useRef(null);
  const params = useParams();
  const { companyId } = params;
  const teamId = params.teamId || card.team?._id || card.team;

  const [
    showActivities,
    handleShowActivities,
    handleHideActivities,
  ] = useDelayShowHideHandler();

  useCardCheerNCommentSocket({ cardId: card?._id, userId: user?._id }, dispatch);

  const { socket } = useUserTypingCardSocket({
    companyId,
    cardId: card?._id,
    teamId,
    userId: user?._id,
  }, dispatch);

  // for activities
  const {
    listActivities,
    handleLoadMoreListActivities,
    checkIfListNeedToLoadActivities,
    setCheckLoadMoreActivities,
    isListsLengthAtTheLimitOrAboveActivities,
    loadingsActivities,
  } = useCardActivities({ showActivities, forceCardId: card?._id, forceTeamId: teamId });

  const getMoreLists = async () => {
    const cardId = card._id;
    const result = await CardActions.loadMoreCardComment({
      cardId,
      currentCard,
      companyId,
      teamId,
    }, dispatch);

    return result;
  };

  const resetPreviousLists = useCallback(() => {
    CardActions.setPreviousCardComment({
      previousCardComment: initialState.previousCardComment,
    }, dispatch);
    CardActions.setCurrentCard({
      currentCard: initialState.currentCard,
    }, dispatch);
  }, []);

  const {
    lists,
    handleLoadMoreLists,
    checkIfListNeedToLoad,
    setCheckLoadMore,
    isListsLengthAtTheLimitOrAbove,
  } = useInfinityScrollHooks({
    currentObjectWithKeyProperty: currentCard,
    previousLists: previousCardComment,
    resetPreviousLists,
    getMoreLists,
  });

  useEffect(() => {
    if (!card?._id) return;

    if (itemRef?.current && !disableScrollIntoView) {
      itemRef.current.scrollIntoView({ behavior: 'smooth' });
    }

    const fetchCardApi = async () => {
      const cardId = card._id;

      const startLoadings = handleLoadings('quickCommentPage', [...loadings], 'start');
      setLoadings([...startLoadings]);

      try {
        const result = await CardActions.initiateCardV2(
          {
            cardId, currentBoardId: currentBoard?._id, companyId, teamId, currentTeam,
          },
          dispatch,
        );

        if (isListsLengthAtTheLimitOrAbove(result?.data?.card?.comments)) {
          setCheckLoadMore(true);
        }
      } catch (err) {
        console.log(err);
        const status = handleStatusMsg(err, 'error');

        enqueueSnackbar(status.message, {
          variant: 'error',
        });
      } finally {
        const endLoadings = handleLoadings('quickCommentPage', [...loadings], 'end');
        setLoadings([...endLoadings]);
      }
    };

    fetchCardApi();
  }, []);

  // for open create comment
  const handleOpenCreateCommentForm = useCallback((value) => {
    setOpenCreateCommentForm(value);
  }, []);

  return (
    // <BoxPopOver customStyle={{ width: '40vw', minWidth: '350px' }} left>
    <NavigationMenuBasic size="lgWider" onClose={cancel} position="topLeft">
      <div className={styles.container}>
        <div ref={itemRef} className={styles.headerSection}>
          <h1>
            Beri Komentar
          </h1>
          <CloseIcon onClick={cancel} />
        </div>
        <SeparatorLine />
        <div className={styles.typingSection}>
          <UserTyping
            allUserTyping={allUserTypingCard}
            companyMembers={currentCompany?.members}
          />
        </div>
        <div id="listQuickCommentsBox" className={styles.bodySection}>
          {!showActivities && (
          <InfinityScroll
            dataLength={lists?.length}
            scrollableTarget="listQuickCommentsBox"
            hasMore={checkIfListNeedToLoad()}
            next={handleLoadMoreLists}
            emptyMessage="Belum ada komentar disini..."
          >
            <OverlayButton wait="quickCommentPage" loadings={loadings}>
              <CommentsSectionContainer
                comments={lists}
                parentIds={[card._id]}
                type={type}
                openForm={openCreateCommentForm}
                onOpenForm={handleOpenCreateCommentForm}
                handleShowActivities={handleShowActivities}
                socket={socket}
                parentTeamId={teamId}
              />
            </OverlayButton>
          </InfinityScroll>
          )}
          {showActivities && (
          <InfinityScroll
            dataLength={listActivities?.length}
            scrollableTarget="listQuickCommentsBox"
            hasMore={checkIfListNeedToLoadActivities()}
            next={handleLoadMoreListActivities}
            emptyMessage="Belum ada Aktifitas disini..."
          >
            <ListActivitiesCard
              variant={serviceTypeRef.card}
              handleHideActivities={handleHideActivities}
              lists={listActivities}
              company={currentCompany}
              loadings={loadingsActivities}
            />
          </InfinityScroll>
          )}
        </div>
      </div>
    </NavigationMenuBasic>
  // </BoxPopOver>
  );
};

QuickCommentsWithBox.propTypes = {
  card: PropTypes.object.isRequired,
  type: PropTypes.string.isRequired,
  cancel: PropTypes.func.isRequired,
  disableScrollIntoView: PropTypes.bool.isRequired,
};

const isComponentDataEqual = (prevProps, nextProps) => {
  const { card, type } = nextProps;
  return isEqual(prevProps.card, card)
    && prevProps.type === type;
};

export default memo(QuickCommentsWithBox, isComponentDataEqual);
