const limitMoreList = {
  hq: 1,
  team: 6,
  project: 9,
  teams: 4,
  groupChats: 4,
  blasts: 4,
  checkIns: 4,
  schedules: 4,
  buckets: 4,
  boards: 4,
  reports: 4,
  billings: 4,
  cheers: 4,
  companyMembers: 4,
  notifications: 4,
};

const limitRecentViewPages = 8;

const limitSearch = 10;
const limitSearchMinimum = 3;

const typeCallback = {
  NEW: 'new',
  DELETE: 'delete',
  EDIT: 'edit',
};

export {
  limitMoreList,
  limitRecentViewPages,
  typeCallback,
  limitSearch,
  limitSearchMinimum,
};
