import React, { memo, useState } from 'react';
import PropTypes from 'prop-types';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import styles from './ProfileUserReport.module.css';
import Color from '../../../../../../themes/colors';
import { DisplayAvatarMember } from '../../../../../../components/UI_V2/Display/Avatar';
import { DisplayTextBody } from '../../../../../../components/UI_V2/Display/Text';
import RoleUserBadge from '../../../../../../components/Container/MyRoleUser/RoleUserBadge/RoleUserBadge';
import { limitChar } from '../../../../../../utilities/stringUtil';

const ProfileUserReport = ({ member }) => (
  <div className={styles.container}>
    <div className={styles.photo}>
      <DisplayAvatarMember size="xl" src={member?.photoUrl} />
    </div>
    <div className={styles.nameAndBio}>
      <div className={styles.name}>
        <DisplayTextBody
          mode="14"
          decoration="bold"
        >
          {limitChar(member?.fullName, 10)}
        </DisplayTextBody>
      </div>
      <div className={styles.bio}>
        <RoleUserBadge roleUser={{
          role: member.role,
        }}
        />
      </div>
    </div>
  </div>
);

ProfileUserReport.propTypes = {
  member: PropTypes.object.isRequired,
};

ProfileUserReport.defaultProps = {};

export default memo(ProfileUserReport);
