import { cloneDeep, isArray } from 'lodash';
import { actionTypes } from '../reducers/reducer';
import { ApiConstants, BlastConstants } from '../constants';
import apiUtil from '../utilities/apiUtil';
import {
  getLastListCreatedAt,
  mergeObjectListAndRemoveDuplicate,
  updateListProperty,
} from '../utilities/arrayUtil';
import { TeamActions } from '.';
import { ErrorException } from '../utilities/handleError';

/*
  Dispatcher
*/

function dispatchPreviousBlast({ previousBlast }, dispatch) {
  dispatch({
    type: actionTypes.SET_PREVIOUS_BLAST,
    previousBlast,
  });
}

function dispatchCurrentBlast({ currentBlast }, dispatch) {
  dispatch({
    type: actionTypes.SET_CURRENT_BLAST,
    currentBlast,
  });
}

function dispatchUpdateBlast({ updateBlast }, dispatch) {
  dispatch({
    type: actionTypes.UPDATE_CURRENT_BLAST,
    updateBlast,
  });
}

/*
  SetterDispatcher
*/

function setPreviousBlast({ previousBlast }, dispatch) {
  if (!previousBlast) return;

  dispatchPreviousBlast(
    { previousBlast: cloneDeep(previousBlast) }, dispatch,
  );
}

function setCurrentBlast({ currentBlast }, dispatch) {
  if (!currentBlast) return;

  dispatchCurrentBlast(
    { currentBlast: cloneDeep(currentBlast) }, dispatch,
  );
}

/*
  Helpers
*/

function modifyResponseBlast({ result, id }) {
  let data = {};

  // from v1
  if (!isArray(result?.data?.blast) && result?.data?.blast?.posts) {
    data = {
      ...result.data.blast,
    };
  } else {
    // v2 plural blasts
    data = {
      posts: result.data.blasts,
      _id: id,
    };
  }

  return data;
}

function modifyResponseLoadBlast({ result, prevBlast }) {
  return {
    ...prevBlast,
    posts: result.data.blasts,
  };
}

function modifyAndSetCurrentBlast({ result, blastId }, dispatch) {
  const modifiedResult = modifyResponseBlast({ result, id: blastId });
  setCurrentBlast({ currentBlast: modifiedResult }, dispatch);
}

/*
  Method
*/

function incomingBlast({
  post, typeAction, keyProperty = 'posts',
}, dispatch) {
  if (!post) return;

  const updateBlast = (currentBlast) => updateListProperty({
    keyProperty,
    newData: post,
    currentList: currentBlast,
    typeAction,
  });

  dispatchUpdateBlast({ updateBlast }, dispatch);
}

async function initiateBlast({ blastId, companyId, teamId }, dispatch) {
  try {
    const result = await apiUtil.get(ApiConstants.URL_V2.BLAST({ blastId }), {
      params: {
        limit: BlastConstants.limitBlast,
        companyId,
        teamId,
        createdAt: new Date(),
      },
    });

    const modifiedResult = modifyResponseBlast({ result, id: blastId });

    setCurrentBlast({ currentBlast: modifiedResult }, dispatch);
    // TeamActions.saveCurrentTeam({ currentTeam: result?.data?.currentTeam }, dispatch);

    return modifiedResult;
  } catch (error) {
    throw new ErrorException(error);
  }
}

async function loadMoreBlast({
  blastId, companyId, currentBlast, teamId,
}, dispatch) {
  try {
    const result = await apiUtil.get(ApiConstants.URL_V2.BLAST({ blastId }), {
      params: {
        limit: BlastConstants.limitBlast,
        createdAt: getLastListCreatedAt(currentBlast?.posts),
        companyId,
        teamId,
      },
    });

    const modifiedResult = modifyResponseLoadBlast({ result, prevBlast: currentBlast });
    const mergedBlast = mergeObjectListAndRemoveDuplicate({
      currentObjectList: currentBlast,
      nextObjectList: modifiedResult,
      keyObject: 'posts',
    });

    setPreviousBlast({ previousBlast: modifiedResult.posts }, dispatch);
    setCurrentBlast({ currentBlast: mergedBlast }, dispatch);

    return modifiedResult;
  } catch (error) {
    throw new ErrorException(error);
  }
}

const resetBlast = ({}, dispatch) => {
  setCurrentBlast({ currentBlast: {} }, dispatch);
};

export {
  incomingBlast,
  initiateBlast,
  loadMoreBlast,
  setPreviousBlast,
  modifyAndSetCurrentBlast,
  resetBlast,
};
