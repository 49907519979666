import React, { useState, useEffect, useContext } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import Form from 'react-bootstrap/Form';
import { useSnackbar } from 'notistack';
import PropTypes from 'prop-types';
import Button from '../../../components/UI/Button/Button';
import { GlobalContext } from '../../../contexts/GlobalStateProvider';
import { actionTypes } from '../../../reducers/reducer';
import styles from './EditProfileContainer.module.css';
import handleStatusMsg from '../../../utilities/handleStatusMsg';
import handleLoadings from '../../../utilities/handleLoadings';
import { UserActions } from '../../../actions';
import { DisplayTextBody } from '../../../components/UI_V2/Display/Text';
import { InputTextFieldMain } from '../../../components/UI_V2/Input/TextField';
import { InputButtonMain } from '../../../components/UI_V2/Input/Button';

const EditProfileContainer = ({ profileUser, cancel }) => {
  const [{ }, dispatch] = useContext(GlobalContext);
  const [loadings, setLoadings] = useState([]);
  const { enqueueSnackbar } = useSnackbar();
  const [newCurrentUser, setNewCurrentUser] = useState({
    fullName: profileUser.fullName,
    status: profileUser.status,
    bio: profileUser.bio,
  });
  const [postCurrentUser, setPostCurrentUser] = useState();

  const history = useHistory();
  const params = useParams();

  const { companyId } = params;

  useEffect(() => {
    if (postCurrentUser !== undefined) {
      const postApiCurrentUser = async () => {
        const currentUserId = profileUser._id;

        try {
          const startLoadings = handleLoadings('editUser', [...loadings], 'start');
          setLoadings([...startLoadings]);

          const result = await UserActions.updateUser(
            { userId: currentUserId, body: postCurrentUser, companyId },
            dispatch,
          );

          const status = handleStatusMsg(result, 'success');

          enqueueSnackbar(status.message, {
            variant: 'success',
          });

          setNewCurrentUser(result.data.user);

          window.location.reload();
          // cancel();
        } catch (err) {
          const status = handleStatusMsg(err, 'error');

          enqueueSnackbar(status.message, {
            variant: 'error',
          });
        } finally {
          // const endLoadings = handleLoadings('editUser', [...loadings] , 'end');
          // setLoadings([...endLoadings]);
        }
      };
      postApiCurrentUser();
    }
  }, [postCurrentUser]);

  const handleChanged = (event) => {
    const { name, value } = event.target;
    setNewCurrentUser((prevValue) => ({
      ...prevValue,
      [name]: value,
    }));
  };

  const handlePostCurrentUser = () => {
    if (newCurrentUser.fullName === undefined || newCurrentUser.fullName.length < 1) {
      enqueueSnackbar('Name cannot be empty', {
        variant: 'error',
      });
      return;
    }

    if (newCurrentUser.bio === undefined || newCurrentUser.bio.length < 1) {
      enqueueSnackbar('Bio cannot be empty', {
        variant: 'error',
      });
      return;
    }

    if (newCurrentUser.status === undefined || newCurrentUser.status.length < 1) {
      enqueueSnackbar('Title cannot be empty', {
        variant: 'error',
      });
      return;
    }

    setPostCurrentUser(newCurrentUser);
  };

  return (
    <>
      <div>
        <form onSubmit={(event) => {
          event.preventDefault();
          handlePostCurrentUser();
        }}
        >
          <div className={styles.form}>
            <div className={styles.formText}>
              <DisplayTextBody
                decoration="bold"
              >
                Nama Lengkap
              </DisplayTextBody>
            </div>
            <div className={styles.formArea}>
              <InputTextFieldMain
                placeholder="Joko Priyatno"
                name="fullName"
                onChange={(event) => handleChanged(event)}
                value={newCurrentUser.fullName}
                border="rounded"
                type="text"
                size="lg"
              />
            </div>
          </div>
          <div className={styles.form}>
            <div className={styles.formText}>
              <DisplayTextBody
                decoration="bold"
              >
                Jabatan
              </DisplayTextBody>
            </div>
            <div className={styles.formArea}>
              <InputTextFieldMain
                placeholder="HRD, Accounting Officer"
                name="status"
                onChange={(event) => handleChanged(event)}
                value={newCurrentUser.status}
                border="rounded"
                type="text"
                size="lg"
              />
            </div>
          </div>
          <div className={styles.form}>
            <div className={styles.formText}>
              <DisplayTextBody
                decoration="bold"
              >
                Bio & Waktu Bekerja
              </DisplayTextBody>
            </div>
            <div className={styles.formArea}>
              <InputTextFieldMain
                placeholder="Seorang yang supel dan kreatif. Jam Kerja : 9-12 WIB."
                name="bio"
                onChange={(event) => handleChanged(event)}
                value={newCurrentUser.bio}
                border="rounded"
                type="text"
                size="lg"
                multiline
                maxRows={5}
                minRows={5}
              />
            </div>
          </div>
        </form>
      </div>
      <div className={styles.actionSection}>
        <div className={styles.actionSection__edit}>
          <InputButtonMain handleClick={cancel} variant="light">Batal</InputButtonMain>
        </div>
        <div className={styles.actionSection__edit}>
          <InputButtonMain handleClick={handlePostCurrentUser} variant="default" border="rounded" wait="editUser" loadings={loadings}>Simpan</InputButtonMain>
        </div>
      </div>
    </>
  );
};

EditProfileContainer.propTypes = {
  profileUser: PropTypes.object.isRequired,
  cancel: PropTypes.func.isRequired,
};

export default EditProfileContainer;
