import React, { memo, useState } from 'react';
import PropTypes from 'prop-types';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import TrendingUpIcon from '@mui/icons-material/TrendingUp';
import TrendingDownIcon from '@mui/icons-material/TrendingDown';
import TrendingFlatIcon from '@mui/icons-material/TrendingFlat';
import styles from './RatingTrendInterpretation.module.css';
import Color from '../../../../../themes/colors';
import { DisplayTextBody, DisplayTextHeadline } from '../../../../../components/UI_V2/Display/Text';
import { sliceNumber } from '../../../../../utilities/stringUtil';

const iconColorStyles = {
  good: styles.iconGood,
  normal: styles.iconNormal,
  bad: styles.iconBad,
};

const categoryTitleStyles = {
  good: 'Membaik',
  normal: 'Stagnan',
  bad: 'Memburuk',
};

const categoryIconStyles = {
  good: <TrendingUpIcon />,
  normal: <TrendingFlatIcon />,
  bad: <TrendingDownIcon />,
};

const RatingTrendInterpretation = ({
  ratingTrend,
}) => (
  <div className={styles.container}>
    <div className={styles.title}>
      <DisplayTextHeadline color={Color.blueNavy2} decoration="bold">
        {categoryTitleStyles[ratingTrend.category]}
      </DisplayTextHeadline>
      <div className={`${styles.icon} ${iconColorStyles[ratingTrend.category]}`}>
        {categoryIconStyles[ratingTrend.category]}
      </div>
    </div>
    <div className={styles.subTitle}>
      <DisplayTextBody position="center" color={Color.gray3}>
        tren progress rating
      </DisplayTextBody>
    </div>
  </div>
);

RatingTrendInterpretation.propTypes = {
  ratingTrend: PropTypes.object,
};

RatingTrendInterpretation.defaultProps = {
  ratingTrend: {
    value: 0,
    category: 'bad',
  },
};

export default memo(RatingTrendInterpretation);
