import React, { memo, useState } from 'react';
import PropTypes from 'prop-types';
import { Box, Skeleton } from '@mui/material';
import styles from './Footer.module.css';

const CommentDiscussPageSkeletonFooter = () => (
  <Box className={styles.container}>
    <Box className={styles.createComment}>
      <Box className={styles.commentBox}>
        <Box className={styles.photo}>
          <Skeleton
            animation="wave"
            variant="circular"
            width={30}
            height={30}
          />
        </Box>
        <Box className={styles.content}>
          <Skeleton
            animation="wave"
            variant="rectangular"
            height={60}
          />
        </Box>
      </Box>
    </Box>

    <Box className={styles.date}>
      <Skeleton
        animation="wave"
        variant="text"
        width={100}
      />
    </Box>
    <Box className={styles.comment}>
      <Box className={styles.photoAndUser}>
        <Box className={styles.photo}>
          <Skeleton
            animation="wave"
            variant="circular"
            width={30}
            height={30}
          />
        </Box>
        <Skeleton
          animation="wave"
          variant="text"
          width={150}
        />
      </Box>
      <Box className={styles.content}>
        <Skeleton
          animation="wave"
          variant="text"
        />
        <Skeleton
          animation="wave"
          variant="text"
        />
        <Skeleton
          animation="wave"
          variant="text"
        />
        <Skeleton
          animation="wave"
          variant="text"
          width={150}
        />
      </Box>
    </Box>
  </Box>
);

export default memo(CommentDiscussPageSkeletonFooter);
