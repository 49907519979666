const limitListCheer = 5;

const typeCallback = {
  NEW: 'new',
  DELETE: 'delete',
};

const typePrimaryParent = {
  POST: 'postCheers',
  EVENT: 'eventCheers',
  DOC: 'docCheers',
  FILE: 'fileCheers',
  CARD: 'cardCheers',
  RECURRING_OCCURRENCE: 'recurringOccurrenceCheers',
};

export {
  limitListCheer,
  typeCallback,
  typePrimaryParent,
};
