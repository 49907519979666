/* eslint-disable consistent-return */
import { useCallback, useContext, useEffect } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import { TeamActions } from '../actions';
import { BlastConstants, TeamConstants } from '../constants';
import { connectSocketTeam } from '../services/socket';
import { useSocketHooks } from './SocketHooks';
import { ErrorException } from '../utilities/handleError';
import { GlobalContext } from '../contexts/GlobalStateProvider';
import handleStatusMsg from '../utilities/handleStatusMsg';

function useTeamHooks({ teamId, userId }, dispatch) {
  // team overview callback
  // not handled yet
  // const callbackBlastOverview = useCallback((blast) => {
  //   TeamActions.incomingOverviewUpdate({
  //     item: blast,
  //     typeOverview: TeamConstants.typeOverview.BLAST,
  //     typeAction: TeamConstants.typeCallback.EDIT,
  //   }, dispatch);
  // }, []);

  // const callbackScheduleOverview = useCallback((schedule) => {
  //   TeamActions.incomingOverviewUpdate({
  //     item: schedule,
  //     typeOverview: TeamConstants.typeOverview.SCHEDULE,
  //     typeAction: TeamConstants.typeCallback.EDIT,
  //   }, dispatch);
  // }, []);

  // const callbackCheckInOverview = useCallback((checkIn) => {
  //   TeamActions.incomingOverviewUpdate({
  //     item: checkIn,
  //     typeOverview: TeamConstants.typeOverview.CHECK_IN,
  //     typeAction: TeamConstants.typeCallback.EDIT,
  //   }, dispatch);
  // }, []);

  // const callbackBoardOverview = useCallback((board) => {
  //   TeamActions.incomingOverviewUpdate({
  //     item: board,
  //     typeOverview: TeamConstants.typeOverview.BOARD,
  //     typeAction: TeamConstants.typeCallback.EDIT,
  //   }, dispatch);
  // }, []);

  // const callbackDocsFilesOverview = useCallback((docsFiles) => {
  //   TeamActions.incomingOverviewUpdate({
  //     item: docsFiles,
  //     typeOverview: TeamConstants.typeOverview.DOCS_FILES,
  //     typeAction: TeamConstants.typeCallback.EDIT,
  //   }, dispatch);
  // }, []);

  // const callbackGroupChatNewOverview = useCallback((message) => {
  //   TeamActions.incomingOverviewUpdate({
  //     item: message,
  //     typeOverview: TeamConstants.typeOverview.GROUP_CHAT,
  //     typeAction: TeamConstants.typeCallback.NEW,
  //   }, dispatch);
  // }, []);

  // const callbackGroupChatDeleteOverview = useCallback((message) => {
  //   TeamActions.incomingOverviewUpdate({
  //     item: message,
  //     typeOverview: TeamConstants.typeOverview.GROUP_CHAT,
  //     typeAction: TeamConstants.typeCallback.DELETE,
  //   }, dispatch);
  // }, []);

  // team members callback
  const callbackMemberNew = useCallback((user) => {
    TeamActions.incomingMemberUpdate({
      user,
      typeAction: TeamConstants.typeCallback.NEW,
    }, dispatch);
  }, []);

  const callbackMemberRemove = useCallback((user) => {
    TeamActions.incomingMemberUpdate({
      user,
      typeAction: TeamConstants.typeCallback.DELETE,
    }, dispatch);
  }, []);

  // const callbackMemberUpdate = useCallback((user) => {
  //   TeamActions.incomingMemberUpdate({
  //     user,
  //     typeAction: TeamConstants.typeCallback.EDIT,
  //   }, dispatch);
  // }, []);

  const listenSocket = (socket, params) => {
    socket
      .on('member-new', callbackMemberNew)
      .on('member-remove', callbackMemberRemove);
    // .on('member-update', callbackMemberUpdate)
    // .on('blast-overview', callbackBlastOverview)
    // .on('checkIn-overview', callbackCheckInOverview)
    // .on('board-overview', callbackBoardOverview)
    // .on('schedule-overview', callbackScheduleOverview)
    // .on('groupChat-new', callbackGroupChatNewOverview)
    // .on('groupChat-delete', callbackGroupChatDeleteOverview)
    // .on('docsFiles-overview', callbackDocsFilesOverview);
  };
  const removeListener = (socket, params) => {
    socket
      .off('member-new')
      .off('member-remove');
    // .off('member-update')
    // .off('blast-overview')
    // .off('checkIn-overview')
    // .off('board-overview')
    // .off('schedule-overview')
    // .off('groupChat-new')
    // .off('groupChat-delete')
    // .off('docsFiles-overview');
    socket.disconnect();
  };

  useSocketHooks({
    params: { teamId, userId },
    connectSocket: connectSocketTeam,
    listenSocket,
    removeListener,
  });
}

function useArchivedTeamsHooks() {
  const [{ user, teams, currentCompany }, dispatch] = useContext(GlobalContext);
  const params = useParams();
  const history = useHistory();
  const { companyId } = params;

  const initiateListData = async ({
    filters, page, limit,
  }) => {
    try {
      const result = await TeamActions.getListTeams({
        filters,
        limit,
        companyId,
        page,
      });
      return result;
    } catch (error) {
      throw new ErrorException(error);
    }
  };

  const loadMoreListData = async ({
    filters, page, limit,
  }) => {
    try {
      const result = await TeamActions.getListTeams({
        filters,
        limit,
        companyId,
        page,
      });
      return result;
    } catch (error) {
      throw new ErrorException(error);
    }
  };

  const unarchiveData = async ({
    item,
  }) => {
    try {
      await TeamActions.unarchiveTeamAndUpdateCompany({
        companyId,
        teamId: item._id,
        userId: user?._id,
        teams,
        currentCompany,
      }, dispatch);
      history.push(`/companies/${companyId}`);
    } catch (error) {
      throw new ErrorException(error);
    }
  };

  return [initiateListData, loadMoreListData, unarchiveData];
}

const useInitiateTeamBelowRole = async ({ minimumRole }) => {
  const [{ user, currentTeam, currentTeamBelowRole }, dispatch] = useContext(GlobalContext);
  const params = useParams();
  const history = useHistory();
  const { companyId, teamId } = params;
  const { enqueueSnackbar } = useSnackbar();

  const initiateTeamBelowRoleApi = async () => {
    try {
      const result = await TeamActions.initiateTeamBelowRole({
        teamId,
        companyId,
        currentTeamBelowRole,
        minimumRole,
        currentTeam,
      }, dispatch);
    } catch (error) {
      const status = handleStatusMsg(error, 'error');

      enqueueSnackbar(status.message, {
        variant: 'error',
      });
    }
  };

  useEffect(() => {
    if (!currentTeam?._id || currentTeam?._id !== teamId) return;
    initiateTeamBelowRoleApi();
  }, [currentTeam]);
};

export {
  useTeamHooks,
  useArchivedTeamsHooks,
  useInitiateTeamBelowRole,
};
