import React, { useState, useEffect } from 'react';
import draftToHtml from 'draftjs-to-html';
import PropTypes from 'prop-types';
import styles from './PostSnapshot.module.css';
import ImageMember from '../../../../components/UI/ImageMember/ImageMember';
import checkIsJson from '../../../../utilities/checkIsJson';
import PrivateIcon from '../../../../components/UI/PrivateIcon/PrivateIcon';
import { PostConstants } from '../../../../constants';
import DateBox from '../../../../components/UI/DateBox/DateBox';
import Color from '../../../../themes/colors';
import { DisplayBadgeCounter } from '../../../../components/UI_V2/Display/Badge';

const PostSnapshot = ({ post }) => {
  const [parsedContent, setParsedContent] = useState('');
  const [parsedTitle, setParsedTitle] = useState('');
  const [isPostComplete, setCompletePost] = useState(false);
  const [isPostAutoCompleteDueDate, setPostAutoCompleteDueDate] = useState(false);

  useEffect(() => {
    let initialContent;
    if (checkIsJson(post.content)) {
      initialContent = draftToHtml(JSON.parse(post.content));
    } else {
      initialContent = post.content;
    }

    // let htmlContent = draftToHtml(convertToRaw(editorState.getCurrentContent()))
    initialContent = initialContent.replace(/<\/?("[^"]*"|'[^']*'|[^>])*(>|$)/g, '').replace(/\n/g, ' ');

    if (initialContent.length < 30) {
      //
    } else {
      initialContent = `${initialContent.slice(0, 30)}...`;
    }

    if (post?.complete?.status !== undefined) {
      setCompletePost(post?.complete?.status);
    }
    if (post?.autoComplete?.status !== undefined) {
      setPostAutoCompleteDueDate(post?.autoComplete?.status);
    }

    setParsedContent(initialContent);

    let tempTitle;
    if (post.title.length < 40) {
      tempTitle = post.title;
    } else {
      tempTitle = `${post.title.slice(0, 40)}...`;
    }

    setParsedTitle(tempTitle);
  }, []);

  return (
    <div className={styles.outerContainer}>
      <div className={styles.mainContainer}>
        <div className={styles.photoContentSection}>
          <div className={styles.photoSection}>
            <ImageMember photoUrl={post.creator?.photoUrl} small />
          </div>
          <div className={styles.contentSection}>
            <div className={styles.contentSection__header}>
              <div className={styles.contentSection__header__title}>
                <h1>
                  <PrivateIcon data={post} size="small" />
                  {' '}
                  {parsedTitle}
                </h1>
                {isPostComplete
                  ? <DateBox icon date={post.complete?.updatedAt} changeColorDate complete={PostConstants.completeDueDate} dateStyle="MonthDate" />
                  : <DateBox icon date={isPostAutoCompleteDueDate ? post.autoComplete.date : post.dueDate} changeColorDate dateStyle="MonthDate" />}
              </div>
            </div>
            <div className={styles.contentSection__content}>
              <p>{parsedContent}</p>
            </div>
          </div>
        </div>
        <div className={styles.bodySection__counter}>
          <DisplayBadgeCounter
            badgeContent={post.comments.length}
            backgroundColor={Color.deleteColor}
          />
        </div>
      </div>
    </div>
  );
};

PostSnapshot.propTypes = {
  post: PropTypes.object.isRequired,
};

export default PostSnapshot;
