import React, { memo, useContext } from 'react';
import PropTypes from 'prop-types';
import { useParams } from 'react-router-dom';
import { Skeleton } from '@mui/material';
import styles from './SubscriptionStatus.module.css';
import { GlobalContext } from '../../../contexts/GlobalStateProvider';
import SubscriptionStatusBox from './Box/Box';
import { DisplayTextBody, DisplayTextSubHeadline } from '../../../components/UI_V2/Display/Text';
import Color from '../../../themes/colors';
import { InputButtonMain } from '../../../components/UI_V2/Input/Button';
import {
  dateBeautifyFormatter,
  dateDifferenceInDays,
  timeBeautifyFormatter, isDateBeforeNow,
} from '../../../utilities/dateUtil';
import { formatToBasicThousandSeparator } from '../../../utilities/numberUtil';
import { FeedbackLoadingMain } from '../../../components/UI_V2/Feedback/Loading';
import { BillingActions } from '../../../actions';
import { getSingleOrPlural } from '../../../utilities/stringUtil';

const BillingSubscriptionStatus = ({
  subscription,
  handleOpenPlanSelection,
  handleOpenOrderSummary,
  loadings,
}) => {
  const [
    {
      currentCompany,
    },
    dispatch,
  ] = useContext(GlobalContext);
  const params = useParams();

  const { companyId } = params;

  let end;
  let plan;
  if (subscription) {
    end = subscription?.end;
    plan = subscription?.plan;
  }

  const isEndToday = dateBeautifyFormatter(end, false, 'MonthDateYear') === 'Hari Ini';
  const isEndTomorrow = dateBeautifyFormatter(end, false, 'MonthDateYear') === 'Besok';
  const isEndYesterday = dateBeautifyFormatter(end, false, 'MonthDateYear') === 'Kemarin';

  const subscriptionDayLeft = dateDifferenceInDays(end, new Date()) + 1;

  const isNoSubscription = subscription?._id === undefined;
  const isSubscriptionOver = subscriptionDayLeft < 0 || isDateBeforeNow(end, new Date());
  const isSubscriptionSoon = !isNoSubscription
  && subscriptionDayLeft < 5
  && !isSubscriptionOver;
  const isSubscriptionTrial = plan?.name === 'basic';

  const getQuotaStatus = ({ userUsage, maxUser }) => {
    let maxUserString = maxUser;

    let isOverQuota = false;
    let quotaLeft = 0;

    if (maxUser === 0 || isNoSubscription) {
      maxUserString = '\u221E';
      quotaLeft = 'unlimited';
    } else {
      isOverQuota = userUsage >= maxUser;
      quotaLeft = maxUser - userUsage;
    }

    return {
      text: `${userUsage}/${maxUserString}`,
      isOverQuota,
      quotaLeft,
    };
  };

  const quota = getQuotaStatus(
    {
      userUsage: subscription?.userUsage
      || currentCompany?.members?.length || currentCompany?.membersJoined?.length || 0,
      maxUser: subscription?.maxUser,
    },
  );

  return (
    <div className={styles.container}>
      <div className={styles.leftBox}>
        <SubscriptionStatusBox
          variant="leftBox"
          title="Langganan"
          mainText={!isNoSubscription ? plan?.desc : 'Paket Godlike'}
          loadings={loadings}
          isExpired={isSubscriptionOver}
          enableSubscriptionIndicator
          isSoon={isSubscriptionSoon}
        >
          <div className={styles.leftBox__action}>
            <div className={styles.leftBox__price}>
              <FeedbackLoadingMain
                wait="billingSubscriptionStatus"
                loadings={loadings}
                loadingComponent={<Skeleton variant="text" width={100} />}
              >
                <DisplayTextBody
                  mode="12"
                  color={Color.gray3}
                >
                  {isNoSubscription && 'Aktif selamanya. Ga perlu pembaruan. Yeay!'}
                  {!isNoSubscription && !isSubscriptionOver && 'Tagihan terdekat : '}
                  {!isNoSubscription && isSubscriptionOver && 'Tanggal berakhir : '}
                  {!isNoSubscription && `${dateBeautifyFormatter(end, false, 'MonthDateYear')}`}
                  {' '}
                  {!isNoSubscription && (isEndToday || isEndTomorrow || isEndYesterday) && `${timeBeautifyFormatter(end)} `}
                  {!isNoSubscription && isSubscriptionOver && '(berakhir)'}
                  {!isNoSubscription && !isSubscriptionOver && `(${subscriptionDayLeft} ${getSingleOrPlural('day', subscriptionDayLeft)} tersisa)`}
                </DisplayTextBody>
              </FeedbackLoadingMain>
            </div>
            {!isNoSubscription && (
            <div className={styles.leftBox__button}>
              <InputButtonMain handleClick={handleOpenPlanSelection} border="rounded" wide="wider" variant="success">Perpanjang</InputButtonMain>
            </div>
            )}
          </div>
        </SubscriptionStatusBox>
      </div>
      <div className={styles.rightBox}>
        <SubscriptionStatusBox
          variant="rightBox"
          title="Pemakaian Anggota"
          mainText={quota.text}
          loadings={loadings}
        >
          <div className={styles.leftBox__action}>
            <FeedbackLoadingMain
              wait="billingSubscriptionStatus"
              loadings={loadings}
              loadingComponent={<Skeleton variant="text" />}
            >
              <DisplayTextBody
                mode="12"
                color={Color.gray3}
              >
                {!isNoSubscription && quota.isOverQuota && !isSubscriptionOver && 'Pemakaian anggotamu melebihi kuota. Silahkan beli addon pengguna atau upgrade paket langgananmu.'}
                {!isNoSubscription && quota.isOverQuota && isSubscriptionOver && 'Pemakaian anggotamu melebihi kuota. Silahkan beli addon pengguna saat memperbarui paket langgananmu.'}
                {!isNoSubscription && !quota.isOverQuota && !isSubscriptionOver && `Kamu bisa undang ${quota.quotaLeft} anggota lagi`}
                {!isNoSubscription && !quota.isOverQuota && isSubscriptionOver && 'Kamu ga bisa undang anggota lagi saat ini'}
                {!isNoSubscription && isSubscriptionOver && ' (berakhir)'}
                {isNoSubscription && 'Kamu bisa undang anggota tak terbatas'}
              </DisplayTextBody>
            </FeedbackLoadingMain>
            {!isSubscriptionTrial && !isSubscriptionOver && !isNoSubscription && (
            <div className={styles.leftBox__button}>
              <InputButtonMain
                handleClick={handleOpenOrderSummary}
                border="rounded"
                wide="wider"
                variant="success"
              >
                Tambah Kuota
              </InputButtonMain>
            </div>
            )}
          </div>
        </SubscriptionStatusBox>
      </div>
    </div>
  );
};

BillingSubscriptionStatus.propTypes = {
  handleOpenPlanSelection: PropTypes.func.isRequired,
  handleOpenOrderSummary: PropTypes.func.isRequired,
  subscription: PropTypes.object.isRequired,
  loadings: PropTypes.array.isRequired,
};

export default memo(BillingSubscriptionStatus);
