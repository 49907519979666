import React, { memo } from 'react';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import PropTypes from 'prop-types';
import { DisplayTextBody, DisplayTextHeadline, DisplayTextSubHeadline } from '../../../../components/UI_V2/Display/Text';
import styles from './SceneHelp.module.css';
import Color from '../../../../themes/colors';
import contactUsGif from '../../../../assets/ModalWelcomeTrial/contactus.gif';
import { InputButtonMain } from '../../../../components/UI_V2/Input/Button';
import { InputCheckboxMain } from '../../../../components/UI_V2/Input/Checkbox';

const SceneHelp = ({
  handleClickNext,
  handleClickDontShowAgain,
  isDontShowWelcomeAgain,
}) => (
  <div className={styles.container}>
    <div className={styles.title}>
      <DisplayTextHeadline
        color={Color.blueNavyCicle}
        mode="48"
        decoration="bold"
        position="center"
      >
        Ga perlu kuatir, ada Mincle disini.
      </DisplayTextHeadline>
    </div>
    <div className={styles.text}>
      <DisplayTextSubHeadline
        color={Color.gray3}
        position="center"
      >
        Kamu bisa Chat Mincle kapanpun kalo masih bingung cara pake Cicle.
      </DisplayTextSubHeadline>
    </div>
    <div className={styles.image}>
      <LazyLoadImage
        alt="logo"
        src={contactUsGif}
      />
    </div>
    <div
      className={styles.dontShowSection}
      onClick={() => handleClickDontShowAgain(!isDontShowWelcomeAgain)}
    >
      <div className={styles.dontShowSection__checkbox}>
        <InputCheckboxMain
          label="Jangan tampilkan lagi pop up untuk perusahaan ini"
          checked={isDontShowWelcomeAgain}
        />
      </div>
      <div className={styles.dontShowSection__text}>
        <DisplayTextBody>
          Jangan tampilkan lagi pop up untuk perusahaan ini
        </DisplayTextBody>
      </div>
    </div>
    <div className={styles.button}>
      <InputButtonMain
        handleClick={handleClickNext}
        border="rounded"
        wide="wider"
      >
        Mulai pake Cicle!
      </InputButtonMain>
    </div>
  </div>
);

SceneHelp.propTypes = {
  handleClickNext: PropTypes.func.isRequired,
  isDontShowWelcomeAgain: PropTypes.bool.isRequired,
  handleClickDontShowAgain: PropTypes.func.isRequired,
};

export default memo(SceneHelp);
