import React from 'react';
import PropTypes from 'prop-types';
import styles from './Question.module.css';
import ImageMember from '../../../components/UI/ImageMember/ImageMember';
import PrivateIcon from '../../../components/UI/PrivateIcon/PrivateIcon';
import { DisplayAvatarMember } from '../../../components/UI_V2/Display/Avatar';

const borderVariantStyles = {
  square: styles.borderSquare,
  squareSemiRoundedTopLeft: styles.boderSquareSemiRoundedTopLeft,
  squareSemiRoundedTopRight: styles.boderSquareSemiRoundedTopRight,
  squareSemiRoundedBottomRight: styles.boderSquareSemiRoundedBottomRight,
  squareSemiRoundedBottomLeft: styles.boderSquareSemiRoundedBottomLeft,
  semiRounded: styles.borderSemiRounded,
};

const borderLineVariantStyles = {
  disabled: styles.borderLineDisabled,
  regularThin: styles.borderLineRegularThin,
};

const Question = ({
  question,
  clicked,
  borderVariant,
  borderLineVariant,
}) => {
  const unsortedDays = [];
  question.schedule.days.forEach((schedule) => {
    let dayId;

    switch (schedule) {
      case 'Sun':
        dayId = 0;
        break;
      case 'Mon':
        dayId = 1;
        break;
      case 'Tue':
        dayId = 2;
        break;
      case 'Wed':
        dayId = 3;
        break;
      case 'Thu':
        dayId = 4;
        break;
      case 'Fri':
        dayId = 5;
        break;
      case 'Sat':
        dayId = 6;
        break;
      default:
        //
    }

    unsortedDays.push(dayId);
  });

  const sortedDays = unsortedDays.sort();

  let days = '';

  sortedDays.forEach((schedule) => {
    let dayName;

    switch (schedule) {
      case 0:
        dayName = 'Sun';
        break;
      case 1:
        dayName = 'Mon';
        break;
      case 2:
        dayName = 'Tue';
        break;
      case 3:
        dayName = 'Wed';
        break;
      case 4:
        dayName = 'Thu';
        break;
      case 5:
        dayName = 'Fri';
        break;
      case 6:
        dayName = 'Sat';
        break;
      default:
        //
    }

    days = `${days + dayName} `;
  });

  const UTCHour = question.schedule.hour;
  const UTCMinute = question.schedule.minute;
  const today = new Date();
  const baseDate = new Date(
    Date.UTC(today.getUTCFullYear(),
      today.getUTCMonth(),
      today.getUTCDate(),
      UTCHour, UTCMinute, 0),
  );

  // turn into local time
  const minute = baseDate.getMinutes();
  let hour = baseDate.getHours();
  const ampm = hour >= 12 ? 'PM' : 'AM';
  // eslint-disable-next-line no-mixed-operators
  hour = ((hour + 11) % 12 + 1);
  const prefixHour = hour >= 10 ? '' : '0';
  const prefixMinute = minute >= 10 ? '' : '0';

  const time = `${prefixHour + hour}:${prefixMinute}${minute} ${ampm}`;

  return (
    <div onClick={clicked} className={`${styles.Question} ${borderVariantStyles[borderVariant]} ${borderLineVariantStyles[borderLineVariant]}`}>
      <div className={styles.mainContainer}>
        <div className={styles.contentSection}>
          <p>
            Menanyakan
            {' '}
            {question.subscribers.length}
            {' '}
            orang tiap
            {' '}
            {days}
            {' '}
            pada
            {' '}
            {time}
          </p>
          <h1>
            <PrivateIcon data={question} size="small" />
            {' '}
            {question.title}
          </h1>
          <div className={styles.subscribers}>
            {question.subscribers.map(
              (subscriber) => (
                <DisplayAvatarMember
                  key={subscriber?._id}
                  src={subscriber?.photoUrl}
                  alt={subscriber?.fullName}
                  size="md"
                />
              ),
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

Question.propTypes = {
  question: PropTypes.object.isRequired,
  clicked: PropTypes.func.isRequired,
  borderVariant: PropTypes.oneOf(['square',
    'semiRounded',
    'squareSemiRoundedTopLeft',
    'squareSemiRoundedTopRight',
    'squareSemiRoundedBottomLeft',
    'squareSemiRoundedBottomRight',
  ]),
  borderLineVariant: PropTypes.oneOf(['disabled', 'regularThin']),
};

Question.defaultProps = {
  borderVariant: 'semiRounded',
  borderLineVariant: 'regularThin',
};

export default Question;
