import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { Tooltip } from '@mui/material';
import styles from './RoleUserBadge.module.css';
import { DisplayTextBody } from '../../../UI_V2/Display/Text';
import { roleTitle, role } from '../../../../constants/RoleConstants';
import Color from '../../../../themes/colors';

const badgeStyles = {
  super: styles.super,
  admin: styles.admin,
  creator: styles.creator,
  manager: styles.manager,
  spv: styles.spv,
  member: styles.member,
  guest: styles.guest,
  public: styles.public,
};

const colorTextStyles = {
  super: Color.white,
  admin: Color.white,
  creator: Color.white,
  manager: Color.white,
  spv: Color.white,
  member: Color.fontColor,
  guest: Color.fontColor,
  public: Color.fontColor,
};

const RoleUserBadge = ({
  roleUser,
}) => (
  <div className={`${styles.container} ${badgeStyles[role[roleUser.role]]}`}>
    <DisplayTextBody
      color={colorTextStyles[role[roleUser.role]]}
    >
      {roleTitle[roleUser.role]}
    </DisplayTextBody>
  </div>
);

RoleUserBadge.propTypes = {
  roleUser: PropTypes.object.isRequired,
};

export default memo(RoleUserBadge);
