const typeCallback = {
  NEW: 'new',
  DELETE: 'delete',
  EDIT: 'edit',
};

const limitUserTyping = 5;
const limitUserTypingMobile = 2;

const limitUserTypingNameChar = 10;

const socketEvent = {
  UPDATE_STATUS: 'update-status',
};

const socketTypingStatus = {
  START: 'start',
  STOP: 'stop',
};

export {
  typeCallback,
  limitUserTyping,
  limitUserTypingNameChar,
  socketEvent,
  socketTypingStatus,
  limitUserTypingMobile,
};
