/* eslint-disable consistent-return */
import { isNil } from 'lodash';
import React, { useCallback, useState, useEffect } from 'react';
import { ScheduleActions } from '../actions';
import { ScheduleConstants } from '../constants';
import { connectSocketSchedule } from '../services/socket';
import { useSocketHooks } from './SocketHooks';

function useScheduleHooks({ scheduleId, userId }, dispatch) {
  // event callback
  const callbackNewEvent = useCallback((event) => {
    ScheduleActions.incomingSchedule({
      event,
      typeAction: ScheduleConstants.typeCallback.NEW,
    }, dispatch);
  }, []);
  const callbackUpdateEvent = useCallback((event) => {
    ScheduleActions.incomingSchedule({
      event,
      typeAction: ScheduleConstants.typeCallback.EDIT,
    }, dispatch);
  }, []);
  const callbackArchiveEvent = useCallback((event) => {
    ScheduleActions.incomingSchedule({
      event,
      typeAction: ScheduleConstants.typeCallback.DELETE,
    }, dispatch);
  }, []);

  // occurrence callback
  // const callbackNewOccurrence = useCallback((occurrence) => {
  //   console.log('new occurrence', { occurrence });
  // }, []);
  // const callbackUpdateOccurrence = useCallback((occurrence) => {
  //   console.log('update occurrence', { occurrence });
  // }, []);
  // const callbackArchiveOccurrence = useCallback((occurrence) => {
  //   console.log('archive occurrence', { occurrence });
  // }, []);

  const listenSocket = (socket, params) => {
    socket
      .on('event-new', callbackNewEvent)
      .on(`event-update-${params?.scheduleId}`, callbackUpdateEvent)
      .on(`event-archive-${params?.scheduleId}`, callbackArchiveEvent);
    // .on('occurrence-new', callbackNewOccurrence)
    // .on(`occurrence-update-${params?.scheduleId}`, callbackUpdateOccurrence)
    // .on(`occurrence-archive-${params?.scheduleId}`, callbackArchiveOccurrence);
  };
  const removeListener = (socket, params) => {
    socket
      .off('event-new')
      .off(`event-update-${params?.scheduleId}`)
      .off(`event-archive-${params?.scheduleId}`);
    socket.disconnect();
    // .off('occurrence-new-')
    // .off(`occurrence-update-${params?.scheduleId}`)
    // .off(`occurrence-archive-${params?.scheduleId}`);
  };

  useSocketHooks({
    params: { scheduleId, userId },
    connectSocket: connectSocketSchedule,
    listenSocket,
    removeListener,
  });
}

const useCalendarHooks = ({ events, handleFetchNewEvents, keyObjStartDate }) => {
  const [clickedDay, setClickedDay] = useState({ selectedDay: new Date() });
  const [filteredEvents, setFilteredEvents] = useState();
  // preparation for pagination schedule events
  const initialFromDate = new Date();
  // fix UTC
  initialFromDate.setDate(1);
  initialFromDate.setHours(0, 0, 0, 0);
  const initialUntilDate = new Date(initialFromDate);
  initialUntilDate.setUTCDate(initialUntilDate.getUTCDate() + 60);
  const [fromDate, setFromDate] = useState(initialFromDate);
  const [untilDate, setUntilDate] = useState(initialUntilDate);

  useEffect(() => {
    if (!filteredEvents) return;
    handleFetchNewEvents();
  }, [fromDate, untilDate]);

  useEffect(() => {
    if (events === undefined) return;

    if (clickedDay.selectedDay === undefined) {
      setFilteredEvents(events);
      return;
    }

    const greaterThanTodayEvents = events.filter((scheduleEvent) => {
      let eventDate = new Date(scheduleEvent[keyObjStartDate]?.startDate
        || scheduleEvent?.startDate);
      // resetting hour, so we can compare
      eventDate = eventDate.setHours(0, 0, 0, 0);
      const compareDate = clickedDay.selectedDay.setHours(0, 0, 0, 0);
      return eventDate >= compareDate;
    });

    setFilteredEvents(greaterThanTodayEvents);
  }, [clickedDay, events]);

  const handleDayClick = (day, { selected }) => {
    if (selected) {
      setClickedDay({ selectedDay: undefined });
      return;
    }
    setClickedDay({ selectedDay: day });
  };

  const renderDay = (day) => {
    const date = day.getDate();

    const dateStyle = {
      position: 'absolute',
      color: 'black',
      bottom: 0,
      right: 0,
      fontSize: 20,
    };
    const cellStyle = {
      height: 30,
      width: 30,
      position: 'relative',
      cursor: 'pointer',
    };
    const eventStyle = {
      width: 6,
      height: 6,
      backgroundColor: 'red',
      borderRadius: '100%',
      marginBottom: 2,
    };
    const dotEvent = {
      width: 13,
      height: 13,
      backgroundColor: 'red',
      borderRadius: '100%',
      justifyContent: 'center',
      alignItems: 'center',
      marginRight: 2,
    };
    const dotCountEvent = {
      fontSize: 8,
      fontWeight: 500,
      color: 'white',
    };

    const eventOnTheCurrentDay = events?.filter((scheduleEvent) => {
      let eventDate = new Date(scheduleEvent[keyObjStartDate]?.startDate
        || scheduleEvent?.startDate);
      // resetting hour, so we can compare
      eventDate = eventDate.setHours(0, 0, 0, 0);
      const compareDate = day.setHours(0, 0, 0, 0);

      return eventDate === compareDate;
    });
    const isEventAboveLimit = eventOnTheCurrentDay?.length >= 4;

    return (
      <div style={cellStyle}>
        <div style={dateStyle}>{date}</div>
        {
          !isNil(eventOnTheCurrentDay) && isEventAboveLimit && (
            <>
              <div style={dotEvent}>
                <p style={dotCountEvent}>{eventOnTheCurrentDay?.length}</p>
              </div>
            </>
          )
        }
        {
          !isNil(eventOnTheCurrentDay) && !isEventAboveLimit && (
            <>
              {eventOnTheCurrentDay.map(() => <div style={eventStyle} />)}
            </>
          )
        }
      </div>
    );
  };

  const handleMonthChange = (month) => {
    // set month hours, minutes, ms to 0
    // fix UTC
    const startDate = new Date(month);
    startDate.setHours(startDate.getHours() - 7);
    setFromDate(startDate);

    const endDate = new Date(startDate);
    endDate.setDate(endDate.getDate() + 60);
    setUntilDate(endDate);

    setClickedDay({ selectedDay: startDate });
  };

  return {
    handleDayClick,
    handleMonthChange,
    fromDate,
    untilDate,
    renderDay,
    clickedDay,
    filteredEvents,
  };
};

export {
  useScheduleHooks,
  useCalendarHooks,
};
