import React, { useState, useEffect, memo } from 'react';
import Spinner from 'react-bootstrap/Spinner';
import PropTypes from 'prop-types';
import styles from './OverlayButton.module.css';

const OverlayButton = ({
  wait, loadings, anchorId, children, spinnerSize, spinnerAnimation,
  spinnerVariant, disableChildrenOnWait, style, noBackgroundOverlay,
}) => {
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (loadings === undefined) return;
    const checkWait = loadings.filter((loading) => loading === wait);

    if (anchorId === undefined) {
      if (checkWait.length > 0) {
        setIsLoading(true);
        return;
      }

      setIsLoading(false);
    }

    // anchorId only used to prevent loading unknownly activated
    if (checkWait.length > 0 && wait === anchorId) {
      setIsLoading(true);
      return;
    }

    if (isLoading && checkWait.length > 0) {
      setIsLoading(true);
      return;
    }

    setIsLoading(false);
  }, [loadings]);

  return (
    <div className={styles.container}>
      <div className={styles.box} style={style}>
        {disableChildrenOnWait ? <>{!isLoading ? children : null}</> : children}
        {isLoading ? (
          <>
            <div className={`${styles.box} ${styles.backgroundOverlay}`} style={noBackgroundOverlay ? { backgroundColor: 'transparent' } : {}} />
            <div className={`${styles.box} ${styles.contentOverlay}`}>
              <Spinner variant={spinnerVariant || 'warning'} animation={spinnerAnimation || 'border'} size={spinnerSize || 'sm'} />
            </div>
          </>
        ) : null}

      </div>
    </div>
  );
};

OverlayButton.propTypes = {
  wait: PropTypes.string.isRequired,
  anchorId: PropTypes.string.isRequired,
  spinnerSize: PropTypes.string.isRequired,
  spinnerAnimation: PropTypes.string.isRequired,
  spinnerVariant: PropTypes.string.isRequired,
  disableChildrenOnWait: PropTypes.bool.isRequired,
  loadings: PropTypes.array.isRequired,
  children: PropTypes.node.isRequired,
  style: PropTypes.object,
  noBackgroundOverlay: PropTypes.bool,
};

OverlayButton.defaultProps = {
  style: {},
  noBackgroundOverlay: false,
};

export default memo(OverlayButton);
