/* eslint-disable consistent-return */
import {
  useCallback, useState, useEffect, useRef, useContext,
} from 'react';
import { cloneDeep, isArray, lowerCase } from 'lodash';
import { useLocation, useParams } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import handleLoadings from '../utilities/handleLoadings';
import { RoleActions } from '../actions';
import handleStatusMsg from '../utilities/handleStatusMsg';
import { GlobalContext } from '../contexts/GlobalStateProvider';

const useInitiateRoleUser = async ({
  refetchWhenLocationChange = true,
  payload,
}) => {
  const [{
    user,
  }, dispatch] = useContext(GlobalContext);
  const [loadings, setLoadings] = useState([]);
  const location = useLocation();
  const params = useParams();
  const { enqueueSnackbar } = useSnackbar();
  const [previousLocation, setPreviousLocation] = useState(null);

  const { companyId } = params;

  const initiateCurrentRoleUser = async () => {
    try {
      const isFeatureIdUndefined = payload?.featureType && !payload?.featureId;

      if (isFeatureIdUndefined) return;

      const startLoadings = handleLoadings('getRoleUser', [...loadings], 'start');
      setLoadings([...startLoadings]);

      const result = await RoleActions.getAndSetRoleUser(
        {
          companyId: payload?.companyId || companyId,
          userId: payload?.userId || user?._id,
          teamId: payload?.teamId,
          featureType: payload?.featureType,
          featureId: payload?.featureId,
        }, dispatch,
      );
    } catch (error) {
      const status = handleStatusMsg(error, 'error');

      enqueueSnackbar(status.message, {
        variant: 'error',
      });
    } finally {
      const endLoadings = handleLoadings('getRoleUser', [...loadings], 'end');
      setLoadings([...endLoadings]);
    }
  };

  useEffect(() => {
    if (!user?._id) return;
    if (!companyId) return;
    if (!refetchWhenLocationChange) return;
    if (previousLocation) {
      if (location.pathname === previousLocation.pathname) {
        // Location exactly the same
        return;
      }
    }

    initiateCurrentRoleUser();
    // Update the previous location
    setPreviousLocation(location);
  }, [location]);

  useEffect(() => {
    if (!user?._id) return;
    if (!companyId) return;
    if (refetchWhenLocationChange) return;
    initiateCurrentRoleUser();
  }, []);
};

export {
  useInitiateRoleUser,
};
