import React, {
  memo, useCallback, useContext, useEffect, useState,
} from 'react';
import PropTypes from 'prop-types';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import AssessmentOutlinedIcon from '@mui/icons-material/AssessmentOutlined';
import { useSnackbar } from 'notistack';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import queryString from 'query-string';
import styles from './UserReportPageV2.module.css';
import Color from '../../themes/colors';
import handleLoadings from '../../utilities/handleLoadings';
import { GlobalContext } from '../../contexts/GlobalStateProvider';
import { useTeamHooks } from '../../hooks/TeamHooks';
import { useInitiateRoleUser } from '../../hooks/RoleHooks';
import { roles, typeRef } from '../../constants/RoleConstants';
import { SearchJumpToActions, TeamActions } from '../../actions';
import handleStatusMsg from '../../utilities/handleStatusMsg';
import { actionTypes } from '../../reducers/reducer';
import { generateRoutesAndTitle } from '../../actions/BreadCrumbActions';
import { hasPermission } from '../../actions/RoleActions';
import { PageConstants } from '../../constants';
import Title from '../../components/Title/Title';
import GeneralSubNavBar from '../../components/GeneralSubNavBar/GeneralSubNavBar';
import MemberInvalidSection from './MemberInvalidSection/MemberInvalidSection';
import { FeedbackLoadingMain } from '../../components/UI_V2/Feedback/Loading';
import { getStoreFilterReport, setStoreFilterReport } from '../../utilities/localStorage';
import { getMonthNumber, getYearNumber } from '../../utilities/dateUtil';
import GlobalActionButton from '../../components/GeneralSubNavBar/BottomNavBar/GlobalActionButton/GlobalActionButton';
import { modifyInitialDateFilterReport } from '../../actions/ReportActions';
import DataRecapMonthWeeklyReport from './DataRecapMonthWeeklyReport/DataRecapMonthWeeklyReport';
import SidebarUserReport from './SidebarUserReport/SidebarUserReport';
import DataRealtimeReport from './DataRealtimeReport/DataRealtimeReport';
import UserReportPageSkeleton from './SidebarUserReport/UserReportPageSkeleton/UserReportPageSkeleton';
import { useCheckPrevLocationTheSame } from '../../hooks/HelperHooks';
import LinkNoDecor from '../../components/LinkNoDecor/LinkNoDecor';

const bodyMobileVariantStyles = {
  realtime: styles.bodyRealtimeMobile,
  recapMonthWeekly: styles.bodyRecapMonthWeeklyMobile,
};

const containerWithSidebarStyles = {
  true: styles.containerSidebarOpen,
  false: undefined,
};

const bodyWithSidebarStyles = {
  true: styles.bodySidebarOpen,
  false: undefined,
};

const UserReportPageV2 = () => {
  const [{
    user, currentCompany, currentTeam, teams, currentRoleUser,
    isSidebarOpen,
    currentBoard,
  }, dispatch] = useContext(GlobalContext);

  const [loadings, setLoadings] = useState([]);
  const { enqueueSnackbar } = useSnackbar();

  const history = useHistory();
  const params = useParams();
  const { companyId, teamId, memberId } = params;

  const [isPrevLocTheSame, location] = useCheckPrevLocationTheSame();
  const query = queryString.parse(location.search);

  const { variantUrl } = query;

  const initialFilterReport = getStoreFilterReport(
    { companyId, userId: user._id },
  );

  const initialVariantUrl = variantUrl || initialFilterReport?.variantUrl || 'realtime';

  // REALTIME PART
  let initialUntilDate;
  let initialFromDate;
  if (initialFilterReport) {
    [initialFromDate, initialUntilDate] = modifyInitialDateFilterReport(
        initialFilterReport?.filterFromUntilDate?.selectedQuickDate,
        initialFilterReport?.filterFromUntilDate?.fromDate,
        initialFilterReport?.filterFromUntilDate?.untilDate,
    );
  } else {
    [initialFromDate, initialUntilDate] = modifyInitialDateFilterReport(
      'thisMonth',
    );
  }
  const initialSelectedQuickDate = initialFilterReport?.filterFromUntilDate?.selectedQuickDate || 'thisMonth';

  const [filters, setFilters] = useState({
    filterFromUntilDate: {
      fromDate: initialFromDate,
      untilDate: initialUntilDate,
      selectedQuickDate: initialSelectedQuickDate,
    },
    variantUrl: initialVariantUrl,
  });
  const [isFirstLoad, setIsFirstLoad] = useState(true);
  const [filteredRoleMembers, setFilteredRoleMembers] = useState();

  const handleSetFilteredRoleMembers = useCallback((value) => {
    setFilteredRoleMembers(value);
  }, []);

  const [allCount, setAllCount] = useState([
    0, 0, 0, 0, 0,
  ]);

  const handleSetAllCount = useCallback((value) => {
    setAllCount(value);
  }, []);

  const [allPercentage, setAllPercentage] = useState([
    0, 0, 0, 0, 0,
  ]);

  const handleSetAllPercentage = useCallback((value) => {
    setAllPercentage(value);
  }, []);

  // RECAP MONTH WEEKLY PART
  const initialMonth = getMonthNumber(new Date());
  const initialYear = getYearNumber(new Date());
  const [month, setMonth] = useState(initialMonth);
  const [year, setYear] = useState(initialYear);

  const handleSetMonth = useCallback((value) => {
    setMonth(value);
  }, []);

  const handleSetYear = useCallback((value) => {
    setYear(value);
  }, []);

  // GENERAL PART

  const handleSetVariantUrl = useCallback((variantValue) => {
    let url;

    if (variantValue !== 'realtime') {
      setStoreFilterReport({
        companyId,
        userId: user._id,
        filter: {
          ...filters,
          variantUrl: variantValue,
        },
      });
    }

    switch (variantValue) {
      case 'realtime':
        url = `/companies/${companyId}/teams/${teamId}/reports/members/${memberId}?variantUrl=realtime`;
        break;
      case 'recapMonthWeekly':
        url = `/companies/${companyId}/teams/${teamId}/reports/members/${memberId}?variantUrl=recapMonthWeekly`;
        break;
      default:
        break;
    }

    history.push(url);
  }, [companyId, teamId, memberId, filters]);

  if (!variantUrl) handleSetVariantUrl(initialVariantUrl);

  const isVariantRealtime = variantUrl === 'realtime';
  const isVariantRecapMonthWeekly = variantUrl === 'recapMonthWeekly';

  useTeamHooks({ teamId, userId: user?._id }, dispatch);
  useInitiateRoleUser({
    payload: {
      teamId,
      featureType: typeRef.team,
      featureId: teamId,
    },
  });

  const initiateCurrentTeamApi = async () => {
    try {
      const startLoadings = handleLoadings('initiateTeam', [...loadings], 'start');
      setLoadings([...startLoadings]);
      const result = await TeamActions.initiateCurrentTeam({ teamId, companyId }, dispatch);

      if (isFirstLoad) setIsFirstLoad(false);
    } catch (err) {
      const status = handleStatusMsg(err, 'error');

      dispatch({
        type: actionTypes.SET_ERROR_RESPONSE,
        errorResponse: { message: status.message },
      });

      history.push(`/errors?previousPath=${location.pathname}`);
    } finally {
      const endLoadings = handleLoadings('initiateTeam', [...loadings], 'end');
      setLoadings([...endLoadings]);
    }
  };

  useEffect(() => {
    if (user._id === undefined) {
      history.push(`/check-login?previousPath=${location.pathname}`);
      return;
    }
    if (currentTeam?._id === teamId && !isFirstLoad) return;
    if (isPrevLocTheSame) return;
    initiateCurrentTeamApi();
  }, [location]);

  const getMemberObject = (members = [], id = '') => {
    const member = members.find((elem) => elem._id === id);
    return member;
  };

  const selectedMember = getMemberObject(currentTeam?.members, memberId);
  const isCurrentTeamReady = currentTeam?._id === teamId
      && currentTeam?.members
      && filteredRoleMembers;

  let isSelectedMemberExistInFilteredRoleMembers;
  if (filteredRoleMembers?.length > 0) {
    isSelectedMemberExistInFilteredRoleMembers = getMemberObject(filteredRoleMembers, memberId);
  }

  const isForbiddenAccessPage = isCurrentTeamReady && selectedMember
      && !isSelectedMemberExistInFilteredRoleMembers;
  const isMemberNotInThisTeam = isCurrentTeamReady && !selectedMember;
  const isAllowedAccessPage = isCurrentTeamReady
      && selectedMember
      && isSelectedMemberExistInFilteredRoleMembers;

  const isUserManagerOrAbove = hasPermission(currentRoleUser, roles.managerOrAbove);

  const onApplyFilters = useCallback(
    (currentFromDate, currentUntilDate, currentSelectedQuickDate) => {
      const filtersValue = {
        filterFromUntilDate: {
          fromDate: currentFromDate,
          untilDate: currentUntilDate,
          selectedQuickDate: currentSelectedQuickDate,
        },
        variantUrl,
      };

      setFilters(filtersValue);
      setStoreFilterReport({
        companyId,
        userId: user._id,
        filter: filtersValue,
      });
    }, [],
  );

  const pageType = PageConstants.pageType.reports;

  const breadcrumb = generateRoutesAndTitle(
    {
      type: pageType,
      data: {
        currentCompany,
        currentTeam,
        userId: user?._id,
      },
    },
  );
  SearchJumpToActions.saveRecentPage({
    company: currentCompany,
    team: currentTeam,
    userId: user?._id,
    itemId: `${teamId}-reports`,
    url: breadcrumb.url,
    routes: breadcrumb.routes,
    name: breadcrumb.title,
    type: pageType,
  });

  return (
    <>
      <Title title={`Laporan Kinerja - ${currentTeam?.name}`} />
      <GeneralSubNavBar
        breadcrumbRoutes={breadcrumb.routes}
        pageTitle={breadcrumb.title}
        bottomBarOverviewOptionId="reports"
        withBottomNavBar={false}
  //       bottomBarGlobalActions={(
  //         <>
  //           {
  //              currentTeam?._id === teamId
  //              && (
  //              <LinkNoDecor
  //                to={`/companies/${companyId}/teams/${teamId}/
  // boards/${currentTeam?.boards?.[0]?._id || currentBoard?._id}`}
  //              >
  //                <GlobalActionButton
  //                  text="Lihat Tugas"
  //                  icon={PageConstants.pageProperties.boards.icon}
  //                />
  //              </LinkNoDecor>
  //              )
  //           }
  //         </>
  // )}
      />
      <div className={`${styles.container} ${containerWithSidebarStyles[isSidebarOpen]}`}>
        <div className={styles.sidebar}>
          <SidebarUserReport
            handleSetFilteredRoleMembers={handleSetFilteredRoleMembers}
            variantUrl={variantUrl}
            handleSetVariantUrl={handleSetVariantUrl}
            recapMonthWeeklyProps={{
              month,
              year,
              handleSetMonth,
              handleSetYear,
            }}
            realtimeProps={{
              selectedQuickDate: filters.filterFromUntilDate.selectedQuickDate,
              handleSetRealtimeDate: onApplyFilters,
            }}
          />
        </div>
        <div className={`${styles.body} ${bodyMobileVariantStyles[variantUrl]} ${bodyWithSidebarStyles[isSidebarOpen]}`}>
          <FeedbackLoadingMain
            wait="initiateTeam"
            loadings={loadings}
            loadingComponent={<UserReportPageSkeleton />}
          >
            {isAllowedAccessPage && isVariantRecapMonthWeekly && (
            <DataRecapMonthWeeklyReport
              month={month}
              year={year}
              member={isSelectedMemberExistInFilteredRoleMembers}
            />
            )}
            {isAllowedAccessPage && isVariantRealtime && (
            <DataRealtimeReport
              allCount={allCount}
              allPercentage={allPercentage}
              fromDate={filters.filterFromUntilDate.fromDate}
              untilDate={filters.filterFromUntilDate.untilDate}
              handleSetAllCount={handleSetAllCount}
              handleSetAllPercentage={handleSetAllPercentage}
              member={isSelectedMemberExistInFilteredRoleMembers}
            />
            )}

            {isMemberNotInThisTeam
                && (
                <div className={styles.invalidIntro}>
                  <MemberInvalidSection invalidType="memberNotInThisTeam" />
                </div>
                )}
            {isForbiddenAccessPage
                && (
                <div className={styles.invalidIntro}>
                  <MemberInvalidSection invalidType="userHaveNoAccess" />
                </div>
                )}
          </FeedbackLoadingMain>
        </div>
      </div>
    </>
  );
};

UserReportPageV2.propTypes = {};

UserReportPageV2.defaultProps = {};

export default memo(UserReportPageV2);
