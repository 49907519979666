import React, {
  memo, useState, useCallback, useEffect,
} from 'react';
import PropTypes from 'prop-types';
import { Box } from '@mui/material';
import { DisplayTextBody } from '../../../../components/UI_V2/Display/Text';
import Color from '../../../../themes/colors';
import TeamItem from './TeamItem/TeamItem';

const ListTeams = ({
  list, handleSelectTab, selectedTab, handleCancel,
}) => {
  const isItemSelected = (id) => !!selectedTab.includes(id);
  const handleClick = (value) => {
    if (value !== selectedTab) handleSelectTab(value);
    handleCancel();
  };
  const listNoNotifLength = list.filter((value) => value.unreadNotification < 1).length;
  const isAllTeamNoNotif = listNoNotifLength === list.length;
  return (
    <>
      {isAllTeamNoNotif
        ? (
          <Box>
            <DisplayTextBody
              position="center"
            >
              Belum ada tim yang punya notifikasi
            </DisplayTextBody>
          </Box>
        )
        : (
          <>
            {list?.map((value) => {
              if (value.unreadNotification < 1 && !isItemSelected(value?.team?._id)) return null;
              return (
                <Box
                  sx={{
                    marginBottom: '5px',
                  }}
                >
                  <TeamItem
                    _id={value.team._id}
                    name={value.team.name}
                    key={value.team._id}
                    counter={value.unreadNotification}
                    onClick={() => handleClick(value?.team?._id)}
                    selected={isItemSelected(value?.team?._id)}
                  />
                </Box>
              );
            })}
          </>
        )}
    </>
  );
};

ListTeams.propTypes = {
  list: PropTypes.array.isRequired,
  handleSelectTab: PropTypes.func.isRequired,
  handleCancel: PropTypes.func.isRequired,
  selectedTab: PropTypes.string.isRequired,
};

export default memo(ListTeams);
