import React, { memo, useState } from 'react';
import PropTypes from 'prop-types';
import { Box, Skeleton } from '@mui/material';
import styles from './Body.module.css';

const CommentDiscussPageSkeletonBody = () => (
  <Box className={styles.container}>
    <Box className={styles.date}>
      <Skeleton
        animation="wave"
        variant="text"
        width={100}
      />
    </Box>
    <Box className={styles.comment}>
      <Box className={styles.photo}>
        <Skeleton
          animation="wave"
          variant="circular"
          width={30}
          height={30}
          sx={{ marginRight: '10px' }}
        />
        <Skeleton
          animation="wave"
          variant="text"
          width={150}
        />
      </Box>
      <Box className={styles.content}>
        <Skeleton
          animation="wave"
          variant="text"
        />
        <Skeleton
          animation="wave"
          variant="text"
        />
        <Skeleton
          animation="wave"
          variant="text"
        />
        <Skeleton
          animation="wave"
          variant="text"
          width={150}
        />
      </Box>
    </Box>
  </Box>
);

export default memo(CommentDiscussPageSkeletonBody);
