import React, { memo, useState } from 'react';
import PropTypes from 'prop-types';
import ScheduleIcon from '@mui/icons-material/Schedule';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { useMediaQuery } from '@mui/material';
import styles from './ThinBox.module.css';
import Color from '../../../../../themes/colors';
import { determineDueStatus } from '../../../../../utilities/dateUtil';

const colorStyles = {
  overdue: styles.overdue,
  dueSoon: styles.dueSoon,
  dueLater: styles.dueLater,
  completed: styles.completed,
};

const DisplayDateThinBox = ({ dueDate, completeStatus }) => {
  const dueStatus = determineDueStatus(dueDate, completeStatus);
  return (
    <div className={`${styles.container} ${colorStyles[dueStatus]}`}>
      <ScheduleIcon />
    </div>
  );
};

DisplayDateThinBox.propTypes = {
  dueDate: PropTypes.object.isRequired,
  completeStatus: PropTypes.object.isRequired,
};

DisplayDateThinBox.defaultProps = {};

export default memo(DisplayDateThinBox);
