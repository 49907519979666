import React, { memo } from 'react';
import PropTypes from 'prop-types';

function ButtonDiv(props) {
  const {
    children, onClick, className, onKeyPress, tabIndex,
  } = props;
  return (
    <div
      role="button"
      tabIndex={tabIndex}
      onClick={onClick}
      className={className}
      onKeyPress={onKeyPress} // for accessibility
    >
      {children}
    </div>
  );
}

ButtonDiv.propTypes = {
  children: PropTypes.node.isRequired,
  onClick: PropTypes.func.isRequired,
  className: PropTypes.string,
  onKeyPress: PropTypes.func,
  tabIndex: PropTypes.number,
};

ButtonDiv.defaultProps = {
  className: '',
  onKeyPress: () => null,
  tabIndex: 0,
};

export default memo(ButtonDiv);
