import React, {
  memo, useContext, useState, useCallback,
} from 'react';
import { useParams, useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Avatar } from '@mui/material';
import styles from './MemberWithOnline.module.css';
import Color from '../../../../../themes/colors';
import { adjustServerUrl } from '../../../../../utilities/stringUtil';
import { GlobalContext } from '../../../../../contexts/GlobalStateProvider';
import { getUserOnlineStatus } from '../../../../../actions/UserOnlineActions';
import { useDelayShowHideHandler } from '../../../../../hooks/HelperHooks';
import ModalProfile from './ModalProfile/ModalProfile';
import BubbleOnline from './BubbleOnline/BubbleOnline';

const shadowStyles = {
  true: {
    boxShadow: '0px 2px 2px rgba(0, 0, 0, 0.15)',
  },
  false: {},
};

const sizeStyles = {
  xs: {
    height: '16px',
    width: '16px',
  },
  sm: {
    height: '24px',
    width: '24px',
  },
  md: {
    height: '30px',
    width: '30px',
  },
  lg: {
    height: '36px',
    width: '36px',
  },
  xl: {
    height: '40px',
    width: '40px',
  },
  xxl: {
    height: '50px',
    width: '50px',
  },
  super: {
    height: '150px',
    width: '150px',
  },
};

const iconSizeStyles = {
  xs: styles.icon,
  sm: styles.icon,
  md: styles.icon,
  lg: styles.icon,
  xl: styles.icon,
  xxl: styles.icon,
  super: styles.iconSuper,
};

function DisplayAvatarMemberWithOnline({
  variant, shadow, size, alt, src, userId,
  marginTop, marginBottom, marginRight, marginLeft,
  onClick, enableOnClickCustom, positionPopUp,
}) {
  const [{ allUserOnline }] = useContext(GlobalContext);

  const [
    showProfile,
    handleShowProfile,
    handleHideProfile,
  ] = useDelayShowHideHandler(false);
  const { status, lastActiveTimestamp } = getUserOnlineStatus({ userId, allUserOnline });

  let photoSource = src;
  if (src) {
    photoSource = adjustServerUrl(src);
  }

  return (
    <div
      className={styles.container}
      style={{
        marginTop,
        marginLeft,
        marginBottom,
        marginRight,
      }}
      onClick={enableOnClickCustom ? onClick : handleShowProfile}
    >
      <Avatar
        // onClick={userId ? () => history.push(url) : onClick}
        src={photoSource}
        alt={alt}
        variant={variant}
        sx={{
          ...shadowStyles[shadow],
          ...sizeStyles[size],
        }}
      />
      <div className={iconSizeStyles[size]}>
        <BubbleOnline status={status} size={size} />
      </div>
      <ModalProfile
        userId={userId}
        status={status}
        lastActiveTimestamp={lastActiveTimestamp}
        open={showProfile}
        onClose={handleHideProfile}
        positionPopUp={positionPopUp}
      />
    </div>
  );
}

DisplayAvatarMemberWithOnline.propTypes = {
  variant: PropTypes.oneOf(['circular', 'rounded', 'square']),
  size: PropTypes.oneOf(['xs', 'sm', 'md', 'lg', 'xl', 'xxl']),
  src: PropTypes.string.isRequired,
  shadow: PropTypes.bool,
  alt: PropTypes.string,
  marginRight: PropTypes.string,
  marginLeft: PropTypes.string,
  marginTop: PropTypes.string,
  marginBottom: PropTypes.string,
  userId: PropTypes.string,
  onClick: PropTypes.func,
  enableOnClickCustom: PropTypes.bool,
  positionPopUp: PropTypes.oneOf(['topRight', 'topLeft', 'bottomRight', 'bottomLeft']),
};

DisplayAvatarMemberWithOnline.defaultProps = {
  variant: 'circular',
  size: 'md',
  shadow: true,
  alt: '',
  marginTop: '0px',
  marginBottom: '0px',
  marginRight: '6px',
  marginLeft: '0px',
  userId: null,
  onClick: () => null,
  enableOnClickCustom: false,
  positionPopUp: 'bottomLeft',
};

const isComponentDataEqual = (prevProps, nextProps) => {
  const {
    variant, shadow, size, alt, src, userId,
    marginTop, marginBottom, marginRight, marginLeft,
  } = nextProps;
  const isStringOrBoolTheSame = prevProps.variant === variant
    && prevProps.shadow === shadow
    && prevProps.size === size
    && prevProps.alt === alt
    && prevProps.src === src
    && prevProps.userId === userId
    && prevProps.marginTop === marginTop
    && prevProps.marginBottom === marginBottom
    && prevProps.marginRight === marginRight
    && prevProps.marginLeft === marginLeft
    && prevProps.marginTop === marginTop;

  const isTheSame = isStringOrBoolTheSame;
  return isTheSame;
};

export default memo(DisplayAvatarMemberWithOnline, isComponentDataEqual);
