import React, { memo, useState } from 'react';
import PropTypes from 'prop-types';
import DownloadIcon from '@mui/icons-material/Download';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { useMediaQuery } from '@mui/material';
import styles from './ModalSearch.module.css';
import Color from '../../../../themes/colors';
import { FeedbackDialogGeneral } from '../../../UI_V2/Feedback/Dialog';
import SearchJumpToContainer from '../../../../pages/SearchJumpToContainer/SearchJumpToContainer';

const ModalSearch = ({
  open,
  onClose,
  params,
}) => {
  const isMobile = useMediaQuery('(max-width:720px)');
  return (
    <FeedbackDialogGeneral
      open={open}
      onClose={onClose}
    >
      <div className={styles.container}>
        <SearchJumpToContainer
          onCloseModal={onClose}
          params={params}
        />
      </div>
    </FeedbackDialogGeneral>
  );
};

ModalSearch.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
  params: PropTypes.object,
};

ModalSearch.defaultProps = {
  open: false,
  onClose: () => null,
  params: { companyId: null, teamId: null },
};

export default memo(ModalSearch);
