import React from 'react';
import CloseIcon from '@mui/icons-material/Close';
import PropTypes from 'prop-types';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import BoxPopOver from '../../../components/UI/BoxPopOver/BoxPopOver';
import SeparatorLine from '../../../components/UI/SeparatorLine/SeparatorLine';
import styles from './AddEventCalendarMenu.module.css';
import ButtonDiv from '../../../components/UI/Button/ButtonDiv/ButtonDiv';
import gcalIcon from '../../../assets/SchedulePage/gcal.png';
import icalIcon from '../../../assets/SchedulePage/ical.png';
import outlookIcon from '../../../assets/SchedulePage/outlook.png';

const AddEventCalendarMenu = ({ event, cancel }) => {
  const handleClickGCal = () => {
    window.open(event?.googleCalendar?.eventGCalTemplateLink, '_blank');
  };

  const handleClickICal = () => {
    window.open(process.env.REACT_APP_PRIMARY_API_URL + event?.googleCalendar?.eventIcsLink, '_blank');
  };

  const handleClickOutlook = () => {
    window.open(process.env.REACT_APP_PRIMARY_API_URL + event?.googleCalendar?.eventIcsLink, '_blank');
  };

  return (
    <BoxPopOver customStyle={{ top: '0', right: '100px' }}>
      <div className={styles.headerSection}>
        <h1>
          Tambahkan Jadwal Manual
        </h1>
        <CloseIcon onClick={cancel} />
      </div>
      <SeparatorLine />
      <div className={styles.bodySection}>
        <ButtonDiv onClick={handleClickGCal} className={styles.menu}>
          <LazyLoadImage src={gcalIcon} />
          <p>Tambah ke Google Calendar</p>
        </ButtonDiv>
        <ButtonDiv onClick={handleClickICal} className={styles.menu}>
          <LazyLoadImage src={icalIcon} />
          <p>Tambah ke Apple Calendar</p>
        </ButtonDiv>
        <ButtonDiv onClick={handleClickOutlook} className={styles.menu}>
          <LazyLoadImage src={outlookIcon} />
          <p>Tambah ke Outlook</p>
        </ButtonDiv>
      </div>
    </BoxPopOver>
  );
};

AddEventCalendarMenu.propTypes = {
  event: PropTypes.object.isRequired,
  cancel: PropTypes.func.isRequired,
};

export default AddEventCalendarMenu;
