import React, { memo, useState } from 'react';
import PropTypes from 'prop-types';
import { Box } from '@mui/material';
import CheckIcon from '@mui/icons-material/Check';
import { isEmpty } from 'lodash';
import styles from './SingleTeamFilterSection.module.css';
import ButtonDiv from '../../../UI/Button/ButtonDiv/ButtonDiv';
import ButtonTextWithCheck from '../ButtonTextWithCheck/ButtonTextWithCheck';
import { limitChar } from '../../../../utilities/stringUtil';
import ButtonTextWithCheckBox from '../ButtonTextWithCheckBox/ButtonTextWithCheckBox';
import { DisplayDividerMain } from '../../../UI_V2/Display/Divider';
import { InputSearchGeneral } from '../../../UI_V2/Input/Search';
import { DisplayTextBody } from '../../../UI_V2/Display/Text';
import Color from '../../../../themes/colors';

const SingleTeamFilterSection = ({
  searchHook,
  checkSingleTeam,
  handleClickSingleTeam,
}) => {
  const {
    searchResult,
    keyword,
    keywordArray,
    setKeyword,
    isTyping,
    onChange,
    resetSearch,
  } = searchHook;
  return (
    <Box className={styles.container}>
      <Box className={styles.titleBox}>
        <DisplayTextBody>
          Cari Tim
        </DisplayTextBody>
      </Box>
      <Box className={styles.searchBox}>
        <InputSearchGeneral
          onChange={onChange}
          value={keyword}
          placeholder="Cari tim..."
          onClickCloseIcon={resetSearch}
          size="sm"
          autoFocus={false}
        />
      </Box>
      <DisplayDividerMain borderColor={Color.gray6} marginTop="0.4rem" marginBottom="0px" />
      <Box className={styles.body}>
        {searchResult.length < 1
          && <span>Tim ga ketemu</span>}
        {searchResult.map((team, index) => (
          <ButtonTextWithCheck
            text={limitChar(team?.name, 30)}
            onClick={() => handleClickSingleTeam(team._id)}
            checked={checkSingleTeam === team._id}
          />
        ))}
      </Box>
    </Box>
  );
};

SingleTeamFilterSection.propTypes = {
  searchHook: PropTypes.object.isRequired,
  checkSingleTeam: PropTypes.string.isRequired,
  handleClickSingleTeam: PropTypes.func.isRequired,
};

export default memo(SingleTeamFilterSection);
