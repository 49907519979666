import React, { memo, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Alert, useMediaQuery } from '@mui/material';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import styles from './RegisterEmailPassword.module.css';
import Color from '../../../themes/colors';
import { InputButtonMain } from '../../../components/UI_V2/Input/Button';
import { InputTextFieldMain } from '../../../components/UI_V2/Input/TextField';
import { DisplayTextBody } from '../../../components/UI_V2/Display/Text';
import { validateEmail, validateFullName, validatePassword } from '../../../utilities/inputValidatorUtil';
import LinkNoDecor from '../../../components/LinkNoDecor/LinkNoDecor';
import { AuthActions } from '../../../actions';
import handleLoadings from '../../../utilities/handleLoadings';
import handleStatusMsg from '../../../utilities/handleStatusMsg';

const RegisterEmailPassword = ({
  isFromEmailInvitation,
}) => {
  const [newUser, setNewUser] = useState({
    fullName: '',
    email: '',
    password: '',
    passwordConfirmation: '',
  });
  const [loadings, setLoadings] = useState([]);

  const [showPassword, setShowPassword] = useState(false);
  const [isValidPassword, setIsValidPassword] = useState(true);
  const [isPasswordEmpty, setIsPasswordEmpty] = useState(true);
  const [isPasswordConfirmationEmpty, setIsPasswordConfirmationEmpty] = useState(true);
  const [isEmailEmpty, setIsEmailEmpty] = useState(true);
  const [isFullNameEmpty, setIsFullNameEmpty] = useState(true);
  const [isValidPasswordConfirmation, setIsValidPasswordConfirmation] = useState(true);
  const [errorMsgPassword, setErrorMsgPassword] = useState('');
  const [errorMsgPasswordConfirmation, setErrorMsgPasswordConfirmation] = useState('');
  const [isValidEmail, setIsValidEmail] = useState(true);
  const [errorMsgEmail, setErrorMsgEmail] = useState('');
  const [isValidFullName, setIsValidFullName] = useState(true);
  const [errorMsgFullName, setErrorMsgFullName] = useState('');
  const [errorMsgEmpty, setErrorMsgEmpty] = useState('');
  const [showErrorEmpty, setShowErrorEmpty] = useState(false);
  const [errorMsgClickStillError, setErrorMsgClickStillError] = useState('');
  const [showErrorClickStillError, setShowErrorClickStillError] = useState(false);

  const [showSuccessRegister, setShowSuccessRegister] = useState(false);
  const [successRegisterMsg, setSuccessRegisterMsg] = useState('');

  const [showErrorRegister, setShowErrorRegister] = useState(false);
  const [errorRegisterMsg, setErrorRegisterMsg] = useState('');

  const [isProcessing, setIsProcessing] = useState(false);

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const handleChanged = (event) => {
    const { name, value } = event.target;
    setNewUser((prevValue) => ({
      ...prevValue,
      [name]: value,
    }));

    if (showErrorEmpty) {
      setShowErrorEmpty(false);
      setErrorMsgEmpty('');
    }

    if (showErrorClickStillError) {
      setShowErrorClickStillError(false);
      setErrorMsgClickStillError('');
    }

    if (showSuccessRegister) {
      setShowSuccessRegister(false);
      setSuccessRegisterMsg('');
    }

    if (showErrorRegister) {
      setShowErrorRegister(false);
      setErrorRegisterMsg('');
    }

    if (name === 'password') {
      const isEmpty = value.length < 1;

      if (isEmpty) {
        setIsPasswordEmpty(true);
        setIsValidPassword(true);
        setErrorMsgPassword('');
        return;
      }

      setIsPasswordEmpty(false);

      const { isValid, errorMsg } = validatePassword(value);

      if (isValid) {
        setIsValidPassword(true);
        setErrorMsgPassword('');
      } else {
        setIsValidPassword(false);
        setErrorMsgPassword(errorMsg);
      }

      const isEmptyPasswordConfirmation = newUser.passwordConfirmation.length < 1;

      if (isEmptyPasswordConfirmation) {
        setIsPasswordConfirmationEmpty(true);
        setIsValidPasswordConfirmation(true);
        setErrorMsgPasswordConfirmation('');
        return;
      }

      setIsPasswordConfirmationEmpty(false);

      const isTheSameWithPassword = value === newUser.passwordConfirmation;
      if (isTheSameWithPassword) {
        setIsValidPasswordConfirmation(true);
        setErrorMsgPasswordConfirmation('');
      } else {
        setIsValidPasswordConfirmation(false);
        setErrorMsgPasswordConfirmation('Konfirmasi password belum sama. ');
      }
    }

    if (name === 'passwordConfirmation') {
      const isEmpty = value.length < 1;

      if (isEmpty) {
        setIsPasswordConfirmationEmpty(true);
        setIsValidPasswordConfirmation(true);
        setErrorMsgPasswordConfirmation('');
        return;
      }

      setIsPasswordConfirmationEmpty(false);

      const isTheSameWithPassword = value === newUser.password;
      if (isTheSameWithPassword) {
        setIsValidPasswordConfirmation(true);
        setErrorMsgPasswordConfirmation('');
      } else {
        setIsValidPasswordConfirmation(false);
        setErrorMsgPasswordConfirmation('Konfirmasi password belum sama. ');
      }
    }

    if (name === 'email') {
      const isEmpty = value.length < 1;

      if (isEmpty) {
        setIsEmailEmpty(true);
        setIsValidEmail(true);
        setErrorMsgEmail('');
        return;
      }

      setIsEmailEmpty(false);

      const validEmail = validateEmail(value);

      if (validEmail) {
        setIsValidEmail(true);
        setErrorMsgEmail('');
      } else {
        setIsValidEmail(false);
        setErrorMsgEmail('Email harus dengan format nama@domain.com (cth: john@doe.com)');
      }
    }

    if (name === 'fullName') {
      const isEmpty = value.length < 1;

      if (isEmpty) {
        setIsFullNameEmpty(true);
        setIsValidFullName(true);
        setErrorMsgFullName('');
        return;
      }

      setIsFullNameEmpty(false);

      const { isValid, errorMsg } = validateFullName(value);

      if (isValid) {
        setIsValidFullName(true);
        setErrorMsgFullName('');
      } else {
        setIsValidFullName(false);
        setErrorMsgFullName(errorMsg);
      }
    }
  };

  const isThereAnyEmpty = isFullNameEmpty
  || isEmailEmpty || isPasswordEmpty
  || isPasswordConfirmationEmpty;

  const isThereAnyError = !isValidEmail || !isValidFullName
  || !isValidPassword || !isValidPasswordConfirmation;

  const handleClickWhenEmpty = (event) => {
    if (event) {
      event.preventDefault();
    }

    let message = '';
    if (isFullNameEmpty) {
      message = 'Nama Lengkap masih kosong. ';
    }

    if (isEmailEmpty) {
      message = `${message}Email masih kosong. `;
    }

    if (isPasswordEmpty) {
      message = `${message}Password masih kosong. `;
    }

    if (isPasswordConfirmationEmpty) {
      message = `${message}Konfirmasi Password masih kosong. `;
    }

    setErrorMsgEmpty(message);
    setShowErrorEmpty(true);
  };

  const handleClickWhenStillError = () => {
    setErrorMsgClickStillError('Belum bisa melanjutkan, perbaiki dulu error diatas.');
    setShowErrorClickStillError(true);
  };

  const processRegisterApi = async ({
    email,
    password,
    fullName,
  }) => {
    try {
      const startLoadings = handleLoadings('registerByEmailAndPassword', [...loadings], 'start');
      setLoadings([...startLoadings]);

      const result = await AuthActions.registerByEmailAndPassword({
        email,
        password,
        fullName,
      });

      // const status = handleStatusMsg(result, 'success');

      setShowSuccessRegister(true);
      setShowErrorRegister(false);
      setSuccessRegisterMsg('Pendaftaran berhasil. Silahkan klik link verifikasi di email kamu untuk melanjutkan.');
      setErrorRegisterMsg('');
    } catch (err) {
      const status = handleStatusMsg(err, 'error');

      setShowErrorRegister(true);
      setShowSuccessRegister(false);
      setErrorRegisterMsg(status.message);
      setSuccessRegisterMsg('');
    } finally {
      const endLoadings = handleLoadings('registerByEmailAndPassword', [...loadings], 'end');
      setLoadings([...endLoadings]);
      setIsProcessing(false);
    }
  };

  const handleClickRegister = (event) => {
    if (event) {
      event.preventDefault();
    }

    if (isThereAnyEmpty) {
      handleClickWhenEmpty();
      return;
    }

    if (isThereAnyError) {
      handleClickWhenStillError();
      return;
    }

    if (isProcessing) return;

    setIsProcessing(true);

    processRegisterApi({
      email: newUser.email,
      password: newUser.password,
      fullName: newUser.fullName,
    });
  };

  const getSignInUrl = () => {
    if (isFromEmailInvitation) {
      return '/signin?sourcePage=emailInvitation';
    }
    return '/signin';
  };

  return (
    <div className={styles.container}>
      <form onSubmit={isThereAnyEmpty ? handleClickWhenEmpty : handleClickRegister}>
        <div className={styles.form}>
          <div className={styles.formText}>
            <DisplayTextBody
              decoration="bold"
            >
              Nama Lengkap
            </DisplayTextBody>
          </div>
          <div className={styles.formArea}>
            <InputTextFieldMain
              placeholder="Bambang Dharmawan"
              name="fullName"
              autoFocus
              onChange={(event) => handleChanged(event)}
              value={newUser.fullName}
              border="rounded"
              type="text"
              size="lg"
            />
          </div>
        </div>
        <div className={styles.form}>
          <div className={styles.formText}>
            <DisplayTextBody
              decoration="bold"
            >
              Email
            </DisplayTextBody>
          </div>
          <div className={styles.formArea}>
            <InputTextFieldMain
              placeholder="emailkamu@gmail.com"
              name="email"
              onChange={(event) => handleChanged(event)}
              value={newUser.email}
              border="rounded"
              type="email"
              size="lg"
            />
          </div>
        </div>
        <div className={styles.form}>
          <div className={styles.formText}>
            <DisplayTextBody
              decoration="bold"
            >
              Password
            </DisplayTextBody>
          </div>
          <div className={styles.formArea}>
            <InputTextFieldMain
              placeholder="**********"
              name="password"
              onChange={(event) => handleChanged(event)}
              value={newUser.password}
              border="rounded"
              type={showPassword ? 'text' : 'password'}
              size="lg"
              showHelperIcon
              helperIcon={showPassword ? <VisibilityOffIcon /> : <VisibilityIcon />}
              onClickHelperIcon={togglePasswordVisibility}
            />
          </div>
        </div>
        <div className={styles.form}>
          <div className={styles.formText}>
            <DisplayTextBody
              decoration="bold"
            >
              Konfirmasi Password
            </DisplayTextBody>
          </div>
          <div className={styles.formArea}>
            <InputTextFieldMain
              placeholder="**********"
              name="passwordConfirmation"
              onChange={(event) => handleChanged(event)}
              value={newUser.passwordConfirmation}
              border="rounded"
              type={showPassword ? 'text' : 'password'}
              size="lg"
              showHelperIcon
              helperIcon={showPassword ? <VisibilityOffIcon /> : <VisibilityIcon />}
              onClickHelperIcon={togglePasswordVisibility}
            />
          </div>
        </div>
        {!isValidFullName ? <Alert severity="error">{errorMsgFullName}</Alert>
          : (
            <>
              {!isValidEmail ? <Alert severity="error">{errorMsgEmail}</Alert> : (
                <>
                  {!isValidPassword && !isValidPasswordConfirmation && <Alert severity="error">{errorMsgPassword}</Alert>}
                  {isValidPassword
                    && !isValidPasswordConfirmation && <Alert severity="error">{errorMsgPasswordConfirmation}</Alert>}
                  {!isValidPassword && isValidPasswordConfirmation && <Alert severity="error">{errorMsgPassword}</Alert>}
                </>
              )}
            </>
          )}

        {isThereAnyEmpty && showErrorEmpty && <div className={styles.alertEmptySection}><Alert severity="error">{errorMsgEmpty}</Alert></div>}
        {isThereAnyError && showErrorClickStillError && <div className={styles.alertEmptySection}><Alert severity="error">{errorMsgClickStillError}</Alert></div>}
        {showSuccessRegister && (
        <div className={styles.alertClickRegisterSection}>
          <Alert severity="success">{successRegisterMsg}</Alert>
        </div>
        )}
        {showErrorRegister && (
        <div className={styles.alertClickRegisterSection}>
          <Alert severity="error">{errorRegisterMsg}</Alert>
        </div>
        )}
        <div className={styles.action}>
          <InputButtonMain
            border="rounded"
            size="lg"
            wide="block"
            handleClick={isThereAnyEmpty ? handleClickWhenEmpty : handleClickRegister}
            loadings={loadings}
            wait="registerByEmailAndPassword"
            type="submit"
          >
            Daftar
          </InputButtonMain>
        </div>
      </form>
      <div className={styles.loginNowSection}>
        <div className={styles.loginNowSection__text}>
          <DisplayTextBody>
            Sudah punya akun?
          </DisplayTextBody>
        </div>
        <div className={styles.loginNowSection__button}>
          <LinkNoDecor to={getSignInUrl}>
            <DisplayTextBody color={Color.brightBlue}>
              Masuk
            </DisplayTextBody>
          </LinkNoDecor>
        </div>
      </div>
    </div>
  );
};

RegisterEmailPassword.propTypes = {
  isFromEmailInvitation: PropTypes.bool,
};

RegisterEmailPassword.defaultProps = {
  isFromEmailInvitation: false,
};

export default memo(RegisterEmailPassword);
