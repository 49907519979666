import React, { memo, useState } from 'react';
import PropTypes from 'prop-types';
import { Box } from '@mui/material';
import CheckIcon from '@mui/icons-material/Check';
import { isEmpty } from 'lodash';
import styles from './ArchivedFilterSection.module.css';
import ButtonDiv from '../../../UI/Button/ButtonDiv/ButtonDiv';
import ButtonTextWithCheck from '../ButtonTextWithCheck/ButtonTextWithCheck';

const ArchivedFilterSection = ({ checkArchived, handleClickArchived }) => (
  <Box className={styles.container}>
    <ButtonTextWithCheck
      text="Terarsip Saja"
      onClick={() => handleClickArchived(true)}
      checked={checkArchived === true}
    />
    <ButtonTextWithCheck
      text="Normal"
      onClick={() => handleClickArchived(false)}
      checked={checkArchived === false}
    />
  </Box>
);

ArchivedFilterSection.propTypes = {
  checkArchived: PropTypes.object.isRequired,
  handleClickArchived: PropTypes.func.isRequired,
};

export default memo(ArchivedFilterSection);
