import { cloneDeep, isArray } from 'lodash';
import { actionTypes } from '../reducers/reducer';
import { ApiConstants, CheckInConstants } from '../constants';
import apiUtil from '../utilities/apiUtil';
import {
  getLastListCreatedAt, removeDuplicateByID, mergeList, updateListProperty,
} from '../utilities/arrayUtil';
import { BreadCrumbActions, TeamActions } from '.';
import { ErrorException } from '../utilities/handleError';

/*
  Dispatcher
*/

function dispatchCurrentCheckIn({ currentCheckIn }, dispatch) {
  dispatch({
    type: actionTypes.SET_CURRENT_CHECK_IN,
    currentCheckIn,
  });
}

function dispatchPreviousCheckIn({ previousCheckIn }, dispatch) {
  dispatch({
    type: actionTypes.SET_PREVIOUS_CHECK_IN,
    previousCheckIn,
  });
}

function dispatchUpdateCheckIn({ updateCheckIn }, dispatch) {
  dispatch({
    type: actionTypes.UPDATE_CURRENT_CHECK_IN,
    updateCheckIn,
  });
}

/*
  SetterDispatcher
*/

function setCurrentCheckIn({ currentCheckIn }, dispatch) {
  if (!currentCheckIn) return;

  dispatchCurrentCheckIn({ currentCheckIn: cloneDeep(currentCheckIn) }, dispatch);
}

function setPreviousCheckIn({ previousCheckIn }, dispatch) {
  if (!previousCheckIn) return;

  dispatchPreviousCheckIn({ previousCheckIn: cloneDeep(previousCheckIn) }, dispatch);
}

/*
  Helpers
*/

function mergeCheckIn({ currentCheckIn, nextCheckIn }) {
  if (!currentCheckIn || !nextCheckIn) return [];

  let newCheckIn = mergeList(
    { currentList: currentCheckIn?.questions, nextList: nextCheckIn?.questions },
  );
  newCheckIn = removeDuplicateByID(newCheckIn);
  const mergedCheckIn = {
    ...currentCheckIn,
    questions: newCheckIn,
  };

  return mergedCheckIn;
}

function modifyResponseCheckIn({ result, id }) {
  let data = {};

  // from v1
  if (!isArray(result?.data?.checkIn) && result?.data?.checkIn?.questions) {
    data = {
      ...result.data.checkIn,
    };
  } else {
    // v2 plural checkIns
    data = {
      questions: result.data.checkIns,
      _id: id,
    };
  }

  return data;
}

function modifyResponseLoadCheckIn({ res, prevCheckIn }) {
  return {
    ...prevCheckIn,
    questions: res.data.checkIns,
  };
}

function modifyAndSetCurrentCheckIn({ result, checkInId }, dispatch) {
  const modifiedResult = modifyResponseCheckIn({ result, id: checkInId });
  setCurrentCheckIn({ currentCheckIn: modifiedResult }, dispatch);
}

/*
  Method
*/

function incomingCheckIn({
  question, typeAction, keyProperty = 'questions',
}, dispatch) {
  if (!question) return;

  const updateCheckIn = (currentCheckIn) => updateListProperty({
    keyProperty,
    newData: question,
    currentList: currentCheckIn,
    typeAction,
  });

  dispatchUpdateCheckIn({ updateCheckIn }, dispatch);
}

async function initiateCheckInV2({ checkInId, companyId, teamId }, dispatch) {
  try {
    const result = await apiUtil.get(ApiConstants.URL_V2.CHECK_IN({ checkInId }), {
      params: {
        limit: CheckInConstants.limitCheckIn,
        companyId,
        teamId,
        createdAt: new Date(),
      },
    });

    const modifiedResult = modifyResponseCheckIn({ result, id: checkInId });

    setCurrentCheckIn({ currentCheckIn: modifiedResult }, dispatch);
    // TeamActions.saveCurrentTeam({ currentTeam: result?.data?.currentTeam }, dispatch);

    return modifiedResult;
  } catch (error) {
    throw new ErrorException(error);
  }
}

async function loadMoreCheckIn(
  {
    checkInId, companyId, currentCheckIn, teamId,
  }, dispatch,
) {
  try {
    const result = await apiUtil.get(ApiConstants.URL_V2.CHECK_IN({ checkInId }), {
      params: {
        limit: CheckInConstants.limitCheckIn,
        createdAt: getLastListCreatedAt(currentCheckIn.questions),
        companyId,
        teamId,
      },
    });

    const modifiedResult = modifyResponseLoadCheckIn({ res: result, prevCheckIn: currentCheckIn });
    const mergedCheckIn = mergeCheckIn({ currentCheckIn, nextCheckIn: modifiedResult });

    setPreviousCheckIn({ previousCheckIn: modifiedResult }, dispatch);
    setCurrentCheckIn({ currentCheckIn: mergedCheckIn }, dispatch);

    return result;
  } catch (error) {
    throw new ErrorException(error);
  }
}

export {
  initiateCheckInV2,
  loadMoreCheckIn,
  modifyResponseCheckIn,
  setPreviousCheckIn,
  setCurrentCheckIn,
  modifyAndSetCurrentCheckIn,
  incomingCheckIn,
};
