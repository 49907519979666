import React, { memo, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useParams } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import styles from './PICInfoBox.module.css';
import { DisplayTextBody } from '../../../../../components/UI_V2/Display/Text';
import Color from '../../../../../themes/colors';
import handleLoadings from '../../../../../utilities/handleLoadings';
import { BillingActions } from '../../../../../actions';
import handleStatusMsg from '../../../../../utilities/handleStatusMsg';
import DetailInfoBox from '../DetailInfoBox/DetailInfoBox';
import TextInfo from '../DetailInfoBox/TextInfo/TextInfo';
import { getSingleOrPlural } from '../../../../../utilities/stringUtil';

const PICInfoBox = ({
  creator,
}) => (
  <div className={styles.container}>
    <DetailInfoBox
      title="PIC Info"
    >
      <TextInfo
        title="Name"
        text={creator?.fullName}
      />
      <TextInfo
        title="Phone"
        text={creator?.phoneNumber}
      />
      <TextInfo
        title="Email"
        text={creator?.email}
      />
      <TextInfo
        title="Job Title"
        text={creator?.status}
      />
      <TextInfo
        title="Age"
        text={creator?.age}
      />
      <TextInfo
        title="Location"
        text={creator?.location}
      />
      <TextInfo
        title="Gender"
        text={creator?.gender}
      />
      <TextInfo
        title="Solution Before Cicle"
        text={creator?.appUsedBeforeCicle}
      />
      <TextInfo
        title="Reason Using Cicle"
        text={creator?.reasonUsingCicle}
      />
      <TextInfo
        title="Know Cicle from where?"
        text={creator?.whereKnowCicle}
      />
    </DetailInfoBox>
  </div>
);

PICInfoBox.propTypes = {
  creator: PropTypes.object.isRequired,
};

PICInfoBox.defaultProps = {};

export default memo(PICInfoBox);
