import React, {
  memo,
} from 'react';
import PropTypes from 'prop-types';
import styles from './ListActivitiesCard.module.css';
import { handleCompareDate } from '../../../utilities/dateUtil';
import ActivityItem from '../../ActivityContainer/ListActivity/ActivityItem/ActivityItem';
import ListActivitySkeleton from '../../ActivityContainer/ListActivity/ListActivitySkeleton/ListActivitySkeleton';
import FeedbackLoadingMain from '../../../components/UI_V2/Feedback/Loading/Main/Main';
import DateSeparator from '../../CommentsSectionContainer/DateSeparator/DateSeparator';
import HeaderActivity from '../../ActivityByModule/HeaderActivity/HeaderActivity';
import ListSkeletonActivityByModule from '../../ActivityByModule/ListSkeletonActivityByModule/ListSkeletonActivityByModule';

const ListActivitiesCard = ({
  handleHideActivities,
  lists,
  loadings,
  company,
}) => (
  <div className={styles.container}>
    <div className={styles.header}>
      <HeaderActivity handleHideActivities={handleHideActivities} />
    </div>
    <FeedbackLoadingMain
      loadingComponent={<ListSkeletonActivityByModule />}
      wait="initiateActivities"
      loadings={loadings}
    >
      {lists.map((item, index) => {
        const isSameDate = handleCompareDate(lists, item, (index - 1), 'createdAt');

        if (!isSameDate) {
          return (
            <>
              <DateSeparator date={item.createdAt} />
              <div className={styles.item}>
                <ActivityItem
                  item={item}
                  company={company}
                  disableClick
                />
              </div>
            </>
          );
        }
        return (
          <div className={styles.item}>
            <ActivityItem
              item={item}
              company={company}
              disableClick
            />
          </div>
        );
      })}
    </FeedbackLoadingMain>
  </div>
);
ListActivitiesCard.propTypes = {
  handleHideActivities: PropTypes.func,
  lists: PropTypes.array,
  company: PropTypes.object.isRequired,
  loadings: PropTypes.array,
};

ListActivitiesCard.defaultProps = {
  handleHideActivities: () => null,
  lists: [],
  loadings: [],
};

export default memo(ListActivitiesCard);
