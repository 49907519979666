import React, { memo, useState } from 'react';
import PropTypes from 'prop-types';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import styles from './RecapHeaderSection.module.css';
import Color from '../../../../themes/colors';
import { DisplayTextBody, DisplayTextSubHeadline } from '../../../../components/UI_V2/Display/Text';
import ExportReportRecap from './ExportReportRecap/ExportReportRecap';
import { getMonthName } from '../../../../utilities/dateUtil';
import { limitChar } from '../../../../utilities/stringUtil';
import { DisplayAvatarMember } from '../../../../components/UI_V2/Display/Avatar';
import { pageProperties } from '../../../../constants/PageConstants';
import RecapHeaderSkeleton from './RecapHeaderSkeleton/RecapHeaderSkeleton';

const RecapHeaderSection = ({
  team,
  member,
  month,
  year,
  isLoading,
}) => (
  <div className={styles.container}>
    {isLoading && <RecapHeaderSkeleton />}
    <div className={styles.text}>
      {!isLoading && (
      <>
        <div className={styles.title}>
          <DisplayTextSubHeadline decoration="bold">
            {`Rekap Laporan Kinerja Bulan ${getMonthName(month)} ${year}`}
          </DisplayTextSubHeadline>
        </div>
        <div className={styles.subTitle}>
          <div className={styles.subItem}>
            <div className={styles.picture}>
              <DisplayAvatarMember size="xs" src={member.photoUrl} />
            </div>
            <DisplayTextBody color={Color.gray3}>
              {limitChar(member.fullName, 20)}
            </DisplayTextBody>
          </div>
          <div className={styles.subItem}>
            <div className={styles.icon}>
              {pageProperties.teams.icon}
            </div>
            <DisplayTextBody color={Color.gray3}>
              {limitChar(team.name, 30)}
            </DisplayTextBody>
          </div>
        </div>
      </>
      )}
    </div>
    {/* <div className={styles.export}>
      <ExportReportRecap />
    </div> */}
  </div>
);

RecapHeaderSection.propTypes = {
  team: PropTypes.object.isRequired,
  member: PropTypes.object.isRequired,
  month: PropTypes.number.isRequired,
  year: PropTypes.number.isRequired,
  isLoading: PropTypes.bool.isRequired,
};

RecapHeaderSection.defaultProps = {};

export default memo(RecapHeaderSection);
