import React, { memo, useContext, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Drawer, useMediaQuery } from '@mui/material';
import styles from './Sidebar.module.css';
import Color from '../../themes/colors';
import GlobalStateProvider, { GlobalContext } from '../../contexts/GlobalStateProvider';
import CompanySection from './CompanySection/CompanySection';
import { DisplayDividerMain } from '../UI_V2/Display/Divider';
import PrimarySection from './PrimarySection/PrimarySection';
import FavoriteSection from './FavoriteSection/FavoriteSection';
import TeamSection from './TeamSection/TeamSection';
import SearchSection from './SearchSection/SearchSection';
import DrawerLogoSection from './DrawerLogoSection/DrawerLogoSection';
import { getStoreSidebarConfig } from '../../utilities/localStorage';
import { handleCloseSidebar, handleOpenSidebar } from '../../actions/SidebarActions';

const Sidebar = ({
  params,
  handleShowNotification,
  handleShowActivityMenu,
  handleShowCompaniesSelect,
  handleShowEditTeamMenu,
  handleShowEditTeamFavoriteMenu,
  handleShowUserMenu,
  handleCancelShow,
}) => {
  const isMobile = useMediaQuery('(max-width:720px)');
  const [{ isSidebarOpen, isFavoriteListEmpty }, dispatch] = useContext(GlobalContext);

  useEffect(() => {
    const isSidebarOpenLs = getStoreSidebarConfig();
    if (isSidebarOpenLs === true && !isSidebarOpen) handleOpenSidebar({}, dispatch);
    if (isSidebarOpenLs === false && isSidebarOpen) handleCloseSidebar({}, dispatch);
  }, []);
  return (
    <Drawer
      open={isSidebarOpen}
      variant="persistent"
    >
      <div className={styles.container}>
        <div className={styles.mainSection}>
          <DrawerLogoSection
            params={params}
            handleShowUserMenu={handleShowUserMenu}
          />
          <CompanySection
            params={params}
            handleShowCompaniesSelect={handleShowCompaniesSelect}
          />
          <SearchSection params={params} />
        </div>
        <DisplayDividerMain
          marginTop="0rem"
          marginBottom="0.5rem"
          borderColor={Color.gray5}
        />
        <div className={styles.listSection}>
          <PrimarySection
            params={params}
            handleShowActivityMenu={handleShowActivityMenu}
            handleShowNotification={handleShowNotification}
          />
          {!isFavoriteListEmpty && (
          <DisplayDividerMain
            marginTop="0.5rem"
            marginBottom="0.5rem"
            borderColor={Color.gray5}
          />
          )}
          <FavoriteSection
            params={params}
            handleShowEditTeamFavoriteMenu={handleShowEditTeamFavoriteMenu}
            handleCloseEditTeamFavoriteMenuAndAll={handleCancelShow}
          />
          <DisplayDividerMain
            marginTop="0.5rem"
            marginBottom="0.5rem"
            borderColor={Color.gray5}
          />
          <TeamSection
            params={params}
            handleShowEditTeamMenu={handleShowEditTeamMenu}
            handleCloseEditTeamMenuAndAll={handleCancelShow}
          />
        </div>
      </div>
    </Drawer>
  );
};

Sidebar.propTypes = {
  params: PropTypes.object,
  handleShowCompaniesSelect: PropTypes.func,
  handleShowNotification: PropTypes.func,
  handleShowActivityMenu: PropTypes.func,
  handleShowEditTeamMenu: PropTypes.func,
  handleShowEditTeamFavoriteMenu: PropTypes.func,
  handleShowUserMenu: PropTypes.func,
  handleCancelShow: PropTypes.func,
};

Sidebar.defaultProps = {
  params: { companyId: null, teamId: null },
  handleShowActivityMenu: () => null,
  handleShowNotification: () => null,
  handleShowCompaniesSelect: () => null,
  handleShowEditTeamMenu: () => null,
  handleShowEditTeamFavoriteMenu: () => null,
  handleCancelShow: () => null,
  handleShowUserMenu: () => null,
};

export default memo(Sidebar);
