import axios from 'axios';
import { isArray, isNumber } from 'lodash';
import { handleErrorAPI } from './handleError';
import { refreshTokenAndSetNewToken } from '../actions/CheckLoginActions';
import { PRIMARY_API_URL } from '../constants/ApiConstants';

let isRefreshing = false;
let failedQueue = [];

const getAccessToken = () => localStorage.getItem('token');

const getInstance = (config, baseUrl) => {
  const instance = axios.create({
    baseURL: baseUrl || PRIMARY_API_URL,
    headers: {
      'Content-Type': 'application/json',
    },
  });

  instance.interceptors.request.use(
    (conf) => {
      const configInstace = conf;
      if (
        configInstace.method === 'get'
            || configInstace.method === 'delete'
            || configInstace.data === undefined
      ) {
        configInstace.data = true;
      }
      const newConfig = { ...configInstace, ...config, withCredentials: true };
      const token = getAccessToken();

      if (token) {
        // newConfig.withCredentials = true;
        newConfig.headers.Authorization = `jwt ${token}`;
      }
      if (process.env.NODE_ENV === 'staging' || process.env.NODE_ENV === 'development') {
        // eslint-disable-next-line no-console
        console.log(newConfig);
      }
      return newConfig;
    },
    (error) => Promise.reject(error),
  );

  // response interceptor
  // currently only handle for automatically refresh token.
  instance.interceptors.response.use(
    (response) => response,
    async (error) => {
      const originalRequest = error.config;

      if (error.response && error.response.status === 401 && !originalRequest._retry) {
        originalRequest._retry = true;

        if (!isRefreshing) {
          isRefreshing = true;
          try {
            const newAuthToken = await refreshTokenAndSetNewToken();

            if (newAuthToken) {
              originalRequest.headers.Authorization = `jwt ${newAuthToken}`;
              return instance(originalRequest);
            }
          } catch (refreshError) {
            return Promise.reject(error);
          } finally {
            isRefreshing = false;
            // After token is refreshed, replay the queued requests
            if (failedQueue.length > 0) {
              failedQueue.forEach((queuedRequest) => {
                queuedRequest();
              });
              failedQueue = [];
            }
          }
        } else {
          // If a token refresh is already in progress, add the request to the queue
          const retryRequest = new Promise((resolve, reject) => {
            failedQueue.push(() => {
              resolve(instance(originalRequest));
            });
          });

          return retryRequest;
        }
      }

      // If the error is not due to an expired token or refresh failure, reject the request
      return Promise.reject(error);
    },
  );

  return instance;
};

const httpRequest = (method, path, data, config = {}, baseUrl) => new Promise((resolve, reject) => {
  getInstance(config, baseUrl)[method](path, data, {})
    .then((response) => resolve(response))
    .catch((error) => reject(handleErrorAPI(error)));
});

function getNextPage({ data, limitPerPage }) {
  if (!isArray(data) || !isNumber(limitPerPage)) return null;

  const totalCurrentData = data?.length;
  const actualPageByCurrentData = Math.ceil(totalCurrentData / limitPerPage);
  const deficitData = (actualPageByCurrentData * limitPerPage) - totalCurrentData;

  if (deficitData === 0) {
    return actualPageByCurrentData + 1;
  }

  return actualPageByCurrentData;
}

export {
  getNextPage,
};

export default {
  get(path, data, config, baseUrl) {
    return httpRequest('get', path, data, config, baseUrl);
  },
  post(path, data, config, baseUrl) {
    return httpRequest('post', path, data, config, baseUrl);
  },
  put(path, data, config, baseUrl) {
    return httpRequest('put', path, data, config, baseUrl);
  },
  // ADA BUGS DI DELETE
  // delete(path, data, config) {
  //    return httpRequest("delete", path, data, config);
  // },
  patch(path, data, config, baseUrl) {
    return httpRequest('patch', path, data, config, baseUrl);
  },
};
