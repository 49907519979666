import React, { memo, useState } from 'react';
import PropTypes from 'prop-types';
import { Box, Skeleton } from '@mui/material';
import styles from './Header.module.css';

const EditBlastPostPageSkeletonHeader = () => (
  <Box className={styles.container}>
    <Box className={styles.item}>
      <Skeleton
        animation="wave"
        variant="rectangular"
        height={24}
        width={200}
      />
      <Skeleton
        animation="wave"
        variant="circular"
        height={24}
        width={24}
      />
    </Box>
  </Box>
);

export default memo(EditBlastPostPageSkeletonHeader);
