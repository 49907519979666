import { isNil, isObject } from 'lodash';

function getStore(key, { parse }) {
  const value = localStorage.getItem(key);
  if (isNil(value)) return null;
  let valueStore = value;
  if (parse) valueStore = JSON.parse(value);
  return valueStore;
}

function setStore(key, value) {
  if (value === undefined) return null;
  let valueStore = value;
  if (isObject(value)) valueStore = JSON.stringify(value);
  return localStorage.setItem(key, valueStore);
}
// not used anymore
function getStoreCurrentCompany() {
  const currentCompany = localStorage.getItem('currentCompany');

  if (currentCompany) return JSON.parse(currentCompany);

  return null;
}
// not used anymore
function setStoreCurrentCompany(value) {
  if (value === undefined) return null;

  let valueStore = value;

  if (isObject(value)) {
    valueStore = JSON.stringify(value);
  }

  return localStorage.setItem('currentCompany', valueStore);
}

function getStoreLastOpenedCompany({ userId }) {
  const lastOpenedCompany = localStorage.getItem(`lastOpenedCompany-${userId}`);

  if (lastOpenedCompany) return JSON.parse(lastOpenedCompany);

  return null;
}

function setStoreWelcomeUserPopUp({ userId, companyId, value }) {
  if (companyId === undefined || userId === undefined
  || !value?.welcomeTutorial
  ) return null;

  let valueStore;

  if (isObject(value)) {
    valueStore = JSON.stringify(value);
  }

  return localStorage.setItem(`welcomeUserPopUp-${userId}-${companyId}`, valueStore);
}

function getStoreWelcomeUserPopUp({ userId, companyId }) {
  const welcomeUserPopUp = localStorage.getItem(`welcomeUserPopUp-${userId}-${companyId}`);

  if (welcomeUserPopUp) return JSON.parse(welcomeUserPopUp);

  return null;
}

function getStoreCompanies() {
  const currentCompanies = localStorage.getItem('currentCompanies');

  if (currentCompanies) return JSON.parse(currentCompanies);

  return null;
}

function setStoreCompanies(value) {
  if (value === undefined) return null;

  let valueStore = value;

  if (isObject(value)) {
    valueStore = JSON.stringify(value);
  }

  return localStorage.setItem('currentCompanies', valueStore);
}

function getStoreUserToken() {
  const token = localStorage.getItem('token');

  if (token) return JSON.parse(token);

  return null;
}

function setStoreUserToken(value) {
  if (value === undefined) return null;

  let valueStore = value;

  if (isObject(value)) {
    valueStore = JSON.stringify(value);
  }

  return localStorage.setItem('token', valueStore);
}

function setStoreLastOpenedCompany({ company, userId }) {
  if (company === undefined || userId === undefined) return null;

  let valueStore = company;

  if (isObject(company)) {
    valueStore = JSON.stringify(company);
  }

  return localStorage.setItem(`lastOpenedCompany-${userId}`, valueStore);
}

function getStoreCurrentTeam() {
  const currentTeam = localStorage.getItem('currentTeam');

  if (currentTeam) return JSON.parse(currentTeam);

  return null;
}

function setStoreCurrentTeam(value) {
  if (value === undefined) return null;

  let valueStore = value;

  if (isObject(value)) {
    valueStore = JSON.stringify(value);
  }

  return localStorage.setItem('currentTeam', valueStore);
}

function getStoreRecentViewPages({ companyId, userId }) {
  const recentViewPages = localStorage.getItem(`recentViewPages-${userId}-${companyId}`);

  if (recentViewPages) return JSON.parse(recentViewPages);

  return null;
}

function setStoreRecentViewPages({ companyId, userId, page }) {
  if (page === undefined) return null;

  let valueStore = page;

  if (isObject(page)) {
    valueStore = JSON.stringify(page);
  }

  return localStorage.setItem(`recentViewPages-${userId}-${companyId}`, valueStore);
}

function getStoreMyTaskCardView({ companyId, userId }) {
  const myTaskCardView = localStorage.getItem(`myTaskCardView-${userId}-${companyId}`);

  if (myTaskCardView) return JSON.parse(myTaskCardView);

  return null;
}

function setStoreMyTaskCardView({ companyId, userId, view }) {
  if (view === undefined) return null;

  let valueStore = view;

  if (isObject(view)) {
    valueStore = JSON.stringify(view);
  }

  return localStorage.setItem(`myTaskCardView-${userId}-${companyId}`, valueStore);
}

function getStoreBoardCardView({ companyId, userId }) {
  const boardCardView = localStorage.getItem(`boardCardView-${userId}-${companyId}`);

  if (boardCardView) return JSON.parse(boardCardView);

  return null;
}

function setStoreBoardCardView({ companyId, userId, view }) {
  if (view === undefined) return null;

  let valueStore = view;

  if (isObject(view)) {
    valueStore = JSON.stringify(view);
  }

  return localStorage.setItem(`boardCardView-${userId}-${companyId}`, valueStore);
}

function getStoreFilterSearch({ companyId, userId }) {
  const filterSearch = localStorage.getItem(`filterSearch-${userId}-${companyId}`);

  if (filterSearch) return JSON.parse(filterSearch);

  return null;
}

function setStoreFilterSearch({ companyId, userId, filter }) {
  if (filter === undefined) return null;

  let valueStore = filter;

  if (isObject(filter)) {
    valueStore = JSON.stringify(filter);
  }

  return localStorage.setItem(`filterSearch-${userId}-${companyId}`, valueStore);
}

function getStoreFilterReport({ companyId, userId }) {
  const filterReport = localStorage.getItem(`filterReport-${userId}-${companyId}`);

  if (filterReport) return JSON.parse(filterReport);

  return null;
}

function setStoreFilterReport({ companyId, userId, filter }) {
  if (filter === undefined) return null;

  let valueStore = filter;

  if (isObject(filter)) {
    valueStore = JSON.stringify(filter);
  }

  return localStorage.setItem(`filterReport-${userId}-${companyId}`, valueStore);
}

function getStoreFilterActivity({ companyId, userId }) {
  const filterActivity = localStorage.getItem(`filterActivity-${userId}-${companyId}`);

  if (filterActivity) return JSON.parse(filterActivity);

  return null;
}

function setStoreFilterActivity({ companyId, userId, filter }) {
  if (filter === undefined) return null;

  let valueStore = filter;

  if (isObject(filter)) {
    valueStore = JSON.stringify(filter);
  }

  return localStorage.setItem(`filterActivity-${userId}-${companyId}`, valueStore);
}

function getStoreLastVisitDate({ companyId, userId }) {
  const lastVisitDate = localStorage.getItem(`lastVisitDate-${userId}-${companyId}`);

  if (lastVisitDate) return JSON.parse(lastVisitDate);

  return null;
}

function setStoreLastVisitDate({ companyId, userId, date }) {
  if (date === undefined) return null;

  let valueStore = date;

  if (isObject(date)) {
    valueStore = JSON.stringify(date);
  }

  return localStorage.setItem(`lastVisitDate-${userId}-${companyId}`, valueStore);
}

function getStoreFilterMyTaskCard({ companyId, userId }) {
  const filterMyTaskCard = localStorage.getItem(`filterMyTaskCard-${userId}-${companyId}`);

  if (filterMyTaskCard) return JSON.parse(filterMyTaskCard);

  return null;
}

function setStoreFilterMyTaskCard({ companyId, userId, filter }) {
  if (filter === undefined) return null;

  let valueStore = filter;

  if (isObject(filter)) {
    valueStore = JSON.stringify(filter);
  }

  return localStorage.setItem(`filterMyTaskCard-${userId}-${companyId}`, valueStore);
}

function getStoreSidebarConfig() {
  const sidebarConfig = localStorage.getItem('sidebarConfig');

  if (sidebarConfig) return JSON.parse(sidebarConfig);

  return null;
}

function setStoreSidebarConfig(status) {
  if (status === undefined) return null;

  let valueStore = status;

  if (isObject(status)) {
    valueStore = JSON.stringify(status);
  }

  return localStorage.setItem('sidebarConfig', valueStore);
}

function getStoreFilterBoardCard({ boardId, userId }) {
  const filterBoardCard = localStorage.getItem(`filterBoardCard-${userId}-${boardId}`);

  if (filterBoardCard) return JSON.parse(filterBoardCard);

  return null;
}

function setStoreFilterBoardCard({ boardId, userId, filter }) {
  if (filter === undefined) return null;

  let valueStore = filter;

  if (isObject(filter)) {
    valueStore = JSON.stringify(filter);
  }

  return localStorage.setItem(`filterBoardCard-${userId}-${boardId}`, valueStore);
}

function getStoreTeamsColor({ companyId, userId }) {
  const teamsColor = localStorage.getItem(`teamsColor-${userId}-${companyId}`);

  if (teamsColor) return JSON.parse(teamsColor);

  return null;
}

function addOrUpdateStoreTeamsColor({
  companyId,
  userId,
  currentTeamsColor,
  teamId, color,
}) {
  const teamColor = {
    teamId,
    color,
  };

  if (!currentTeamsColor) return null;

  const isTeamExist = currentTeamsColor.find((elem) => elem.teamId === teamId);

  if (isTeamExist) {
    const newCurrentTeamsColor = currentTeamsColor.filter((elem) => elem.teamId !== teamId);

    newCurrentTeamsColor.push(teamColor);

    return localStorage.setItem(`teamsColor-${userId}-${companyId}`, JSON.stringify(newCurrentTeamsColor));
  }
  currentTeamsColor.push(teamColor);

  return localStorage.setItem(`teamsColor-${userId}-${companyId}`, JSON.stringify(currentTeamsColor));
}

function getStoreWidgetMyTaskCardAll({ userId, companyId }) {
  const widgetMyTaskCardAll = localStorage.getItem(`widgetMyTaskCardAll-${userId}-${companyId}`);

  if (widgetMyTaskCardAll) return JSON.parse(widgetMyTaskCardAll);

  return null;
}

function setStoreWidgetMyTaskCardAll({ userId, companyId, itemType }) {
  if (itemType === undefined) return null;

  let valueStore = itemType;

  if (isObject(itemType)) {
    valueStore = JSON.stringify(itemType);
  }

  return localStorage.setItem(`widgetMyTaskCardAll-${userId}-${companyId}`, valueStore);
}

function getStoreTeamSortType({ userId, companyId }) {
  const teamSortType = localStorage.getItem(`teamSortType-${userId}-${companyId}`);

  if (teamSortType) return JSON.parse(teamSortType);

  return null;
}

function setStoreTeamSortType({ userId, companyId, teamSortType }) {
  if (teamSortType === undefined) return null;

  let valueStore = teamSortType;

  if (isObject(teamSortType)) {
    valueStore = JSON.stringify(teamSortType);
  }

  return localStorage.setItem(`teamSortType-${userId}-${companyId}`, valueStore);
}

function getStoreCollapsibleTeamSidebar({ userId, companyId }) {
  const collapsibleTeamSidebar = localStorage.getItem(`collapsibleTeamSidebar-${userId}-${companyId}`);

  if (collapsibleTeamSidebar) return JSON.parse(collapsibleTeamSidebar);

  return null;
}

function setStoreCollapsibleTeamSidebar({ userId, companyId, collapsibleTeamSidebar }) {
  if (collapsibleTeamSidebar === undefined) return null;

  let valueStore = collapsibleTeamSidebar;

  if (isObject(collapsibleTeamSidebar)) {
    valueStore = JSON.stringify(collapsibleTeamSidebar);
  }

  return localStorage.setItem(`collapsibleTeamSidebar-${userId}-${companyId}`, valueStore);
}

function getStoreDashboardWidget({ userId, companyId }) {
  const dashboardWidget = localStorage.getItem(`dashboardWidget-${userId}-${companyId}`);

  if (dashboardWidget) return JSON.parse(dashboardWidget);

  return null;
}

function setStoreDashboardWidget({ userId, companyId, dashboardWidget }) {
  if (dashboardWidget === undefined) return null;

  let valueStore = dashboardWidget;

  if (isObject(dashboardWidget)) {
    valueStore = JSON.stringify(dashboardWidget);
  }

  return localStorage.setItem(`dashboardWidget-${userId}-${companyId}`, valueStore);
}

function getStoreWidgetMyTaskIsOpen({ userId, companyId }) {
  const widgetMyTaskIsOpen = localStorage.getItem(`widgetMyTaskIsOpen-${userId}-${companyId}`);

  if (widgetMyTaskIsOpen) return JSON.parse(widgetMyTaskIsOpen);

  return null;
}

function setStoreWidgetMyTaskIsOpen({ userId, companyId, widgetMyTaskIsOpen }) {
  if (widgetMyTaskIsOpen === undefined) return null;

  let valueStore = widgetMyTaskIsOpen;

  if (isObject(widgetMyTaskIsOpen)) {
    valueStore = JSON.stringify(widgetMyTaskIsOpen);
  }

  return localStorage.setItem(`widgetMyTaskIsOpen-${userId}-${companyId}`, valueStore);
}

export {
  getStore,
  setStore,
  getStoreCurrentCompany,
  setStoreCurrentCompany,
  getStoreCurrentTeam,
  setStoreCurrentTeam,
  setStoreLastOpenedCompany,
  getStoreLastOpenedCompany,
  getStoreUserToken,
  setStoreUserToken,
  getStoreCompanies,
  setStoreCompanies,
  getStoreRecentViewPages,
  setStoreRecentViewPages,
  getStoreMyTaskCardView,
  setStoreMyTaskCardView,
  getStoreWelcomeUserPopUp,
  setStoreWelcomeUserPopUp,
  getStoreFilterSearch,
  setStoreFilterSearch,
  getStoreLastVisitDate,
  setStoreLastVisitDate,
  getStoreFilterReport,
  setStoreFilterReport,
  getStoreFilterActivity,
  setStoreFilterActivity,
  getStoreFilterMyTaskCard,
  setStoreFilterMyTaskCard,
  getStoreFilterBoardCard,
  setStoreFilterBoardCard,
  getStoreBoardCardView,
  setStoreBoardCardView,
  getStoreTeamsColor,
  addOrUpdateStoreTeamsColor,
  getStoreSidebarConfig,
  setStoreSidebarConfig,
  getStoreWidgetMyTaskCardAll,
  setStoreWidgetMyTaskCardAll,
  getStoreTeamSortType,
  setStoreTeamSortType,
  getStoreCollapsibleTeamSidebar,
  setStoreCollapsibleTeamSidebar,
  getStoreDashboardWidget,
  setStoreDashboardWidget,
  getStoreWidgetMyTaskIsOpen,
  setStoreWidgetMyTaskIsOpen,
};
