import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { useMediaQuery } from '@mui/material';
import styles from './PersonItem.module.css';
import { DisplayTextBody } from '../../../../UI_V2/Display/Text';
import Color from '../../../../../themes/colors';
import limitChar from '../../../../../utilities/limitChar';
import { DisplayAvatarMemberWithOnline } from '../../../../UI_V2/Display/Avatar';
import StatusBoxOnline from '../../../../UI_V2/Display/Avatar/MemberWithOnline/StatusBoxOnline/StatusBoxOnline';
import { DisplayDateBox } from '../../../../UI_V2/Display/Date';

const PersonItem = ({
  member,
  positionPopUpOnlineOnPhoto,
}) => {
  const {
    _id, fullName, photoUrl, bio, status,
  } = member;
  const isEmptyDate = !member.lastActiveTimestamp;

  const isMobile = useMediaQuery('(max-width:720px)');

  const limitFullName = isMobile ? limitChar(fullName, 15) : limitChar(fullName, 40);
  const limitBio = isMobile ? limitChar(bio, 15) : limitChar(bio, 40);
  const limitStatus = isMobile ? limitChar(status, 15) : limitChar(status, 40);

  return (
    <div
      className={styles.container}
    >
      <div className={styles.header}>
        <div className={styles.user}>
          <div className={styles.photo}>
            <DisplayAvatarMemberWithOnline
              userId={_id}
              src={photoUrl}
              size="xl"
              positionPopUp={positionPopUpOnlineOnPhoto}
              enableOnClickCustom
            />
          </div>
          <div className={styles.text}>
            <div className={styles.name}>
              <DisplayTextBody decoration="bold" mode="14">
                {limitFullName}
              </DisplayTextBody>
            </div>
            <div className={styles.title}>
              <DisplayTextBody color={Color.gray3}>
                {limitStatus}
              </DisplayTextBody>
            </div>
            <div className={styles.bio}>
              <DisplayTextBody color={Color.gray3}>
                {limitBio}
              </DisplayTextBody>
            </div>
          </div>
        </div>
        <div className={styles.status__item__box}>
          <StatusBoxOnline status={member.onlineStatus} />
          {member.onlineStatus !== 'online' && (
          <div className={styles.status}>
            {!isEmptyDate && (
            <DisplayDateBox
              date={member.lastActiveTimestamp}
              enableAlsoExactTimeWhenYesterday
              relativeTime
            />
            )}
            {isEmptyDate && (
            <DisplayTextBody
              color={Color.gray3}
            >
              Belum ada data
            </DisplayTextBody>
            )}
          </div>
          )}
        </div>
      </div>
      {/* {member.bio && (
      <div className={styles.bio}>
        <DisplayTextBody
          color={Color.gray3}
        >
          {member.bio}
        </DisplayTextBody>
      </div>
      )} */}
    </div>
  );
};

PersonItem.propTypes = {
  member: PropTypes.object,
  positionPopUpOnlineOnPhoto: PropTypes.oneOf(['topRight', 'topLeft', 'bottomRight', 'bottomLeft']),
};

PersonItem.defaultProps = {
  member: {
    _id: null,
    fullName: null,
    photoUrl: null,
  },
  positionPopUpOnlineOnPhoto: 'topLeft',
};

export default memo(PersonItem);
