/* eslint-disable vars-on-top */
/* eslint-disable no-var */
import React, { useState, useEffect } from 'react';
import CloseIcon from '@mui/icons-material/Close';
import { useSnackbar } from 'notistack';
import axios from 'axios';
import { useParams } from 'react-router-dom';
import PropTypes from 'prop-types';
import BoxPopOver from '../../../../components/UI/BoxPopOver/BoxPopOver';
import SeparatorLine from '../../../../components/UI/SeparatorLine/SeparatorLine';
import styles from './DeletePopUp.module.css';
import Button from '../../../../components/UI/Button/Button';
import handleStatusMsg from '../../../../utilities/handleStatusMsg';
import handleLoadings from '../../../../utilities/handleLoadings';
import { DEFAULT_OPTION } from '../../../../constants/ApiConstants';
import { handleAxiosDeleteRefreshToken } from '../../../../actions/CheckLoginActions';

const DeletePopUp = ({ attachment, handleShowDeletePopUp }) => {
  const [deletedAttachment, setDeletedAttachment] = useState();
  const { enqueueSnackbar } = useSnackbar();
  const [loadings, setLoadings] = useState([]);
  const params = useParams();
  const { companyId, teamId, cardId } = params;

  const handleCancel = (event) => {
    handleShowDeletePopUp('');
    // disable onclick on the parent getting trigerred. stop here.
    // eslint-disable-next-line no-redeclare
    if (!event) var { event } = window;
    // eslint-disable-next-line no-param-reassign
    event.cancelBubble = true;
    if (event.stopPropagation) event.stopPropagation();
  };

  const handleDelete = (event) => {
    setDeletedAttachment(attachment._id);
    // disable onclick on the parent getting trigerred. stop here.
    // eslint-disable-next-line no-redeclare
    if (!event) var { event } = window;
    // eslint-disable-next-line no-param-reassign
    event.cancelBubble = true;
    if (event.stopPropagation) event.stopPropagation();
  };

  useEffect(() => {
    if (deletedAttachment === undefined) {
      return;
    }

    const deleteApiAttachment = async () => {
      const attachmentId = attachment._id;

      try {
        const startLoadings = handleLoadings('deleteAttachment', [...loadings], 'start');
        setLoadings([...startLoadings]);

        const result = await axios.delete(`${process.env.REACT_APP_PRIMARY_API_URL}/api/v1/cards/${cardId}/attachments/${attachmentId}`, {
          withCredentials: true,
          headers: {
            Authorization: `jwt ${localStorage.getItem('token')}`,
          },
          params: {
            companyId,
            teamId,
          },
        });

        const status = handleStatusMsg(result, 'success');

        enqueueSnackbar(status.message, {
          variant: 'success',
        });

        // close this container after finished
        handleCancel();
      } catch (err) {
        const newAuthToken = await handleAxiosDeleteRefreshToken(err);

        if (newAuthToken) {
          try {
            const result = await axios.delete(`${process.env.REACT_APP_PRIMARY_API_URL}/api/v1/cards/${cardId}/attachments/${attachmentId}`, {
              withCredentials: true,
              headers: {
                Authorization: `jwt ${newAuthToken}`,
              },
              params: {
                companyId,
                teamId,
              },
            });

            const status = handleStatusMsg(result, 'success');

            enqueueSnackbar(status.message, {
              variant: 'success',
            });

            // close this container after finished
            handleCancel();
          } catch (error) {
            const status = handleStatusMsg(error, 'error');

            enqueueSnackbar(status.message, {
              variant: 'error',
            });
          }
        } else {
          const status = handleStatusMsg(err, 'error');

          enqueueSnackbar(status.message, {
            variant: 'error',
          });
        }
      } finally {
        const endLoadings = handleLoadings('deleteAttachment', [...loadings], 'end');
        setLoadings([...endLoadings]);
      }
    };
    deleteApiAttachment();
  }, [deletedAttachment]);

  return (
    <BoxPopOver>
      <div className={styles.headerSection}>
        <h1>
          Hapus lampiran?
        </h1>
        <CloseIcon onClick={(event) => handleCancel(event)} />
      </div>
      <SeparatorLine />
      <div className={styles.bodySection}>
        <p>Lampiran ini akan hilang selamanya. Tidak bisa dikembalikan.</p>
      </div>
      <div className={styles.actionSection}>
        <Button
          handleClick={(event) => handleDelete(event)}
          variant="danger"
          size="sm"
          block
          wait="deleteAttachment"
          loadings={loadings}
        >
          Hapus
        </Button>
      </div>
    </BoxPopOver>
  );
};

DeletePopUp.propTypes = {
  attachment: PropTypes.object.isRequired,
  handleShowDeletePopUp: PropTypes.func.isRequired,
};

export default DeletePopUp;
