import React, { memo } from 'react';
import PropTypes from 'prop-types';
import styles from './ChatPersonItem.module.css';
import ImageMember from '../../../../components/UI/ImageMember/ImageMember';
import { DisplayTextBody } from '../../../../components/UI_V2/Display/Text';
import Color from '../../../../themes/colors';
import { DisplayBadgeCounter } from '../../../../components/UI_V2/Display/Badge';
import limitChar from '../../../../utilities/limitChar';
import EditorView from '../../../FroalaEditor/EditorView/EditorView';
import { DisplayAvatarMember, DisplayAvatarMemberWithOnline } from '../../../../components/UI_V2/Display/Avatar';

const ChatPersonItem = ({
  photoUrl,
  fullName,
  subContent,
  counter,
  clicked,
  onClickId,
  selectedChatId,
  userId,
  positionPopUpOnlineOnPhoto
}) => {
  const limitFullName = limitChar(fullName, 20);
  const limitSubContent = limitChar(subContent, 20);

  const isSelected = selectedChatId === onClickId?.chatId;

  return (
    <div
      className={isSelected ? `${styles.container} ${styles.selected}` : styles.container}
      onClick={() => clicked(onClickId)}
    >
      <div className={styles.photo}>
        <DisplayAvatarMemberWithOnline
          userId={userId}
          src={photoUrl}
          size="xl"
          positionPopUp={positionPopUpOnlineOnPhoto}
        />
      </div>
      <div className={styles.textAndCounter}>
        <div className={styles.text}>
          <div className={styles.name}>
            <DisplayTextBody
              mode="12"
              color={Color.fontColor}
              decoration="bold"
            >
              {limitFullName}
            </DisplayTextBody>
          </div>
          <div className={styles.subContent}>
            <EditorView model={limitSubContent} />
          </div>
        </div>
        <div className={styles.counter}>
          <DisplayBadgeCounter badgeContent={counter} />
        </div>
      </div>

    </div>
  );
};

ChatPersonItem.propTypes = {
  clicked: PropTypes.func,
  onClickId: PropTypes.string.isRequired,
  photoUrl: PropTypes.string,
  fullName: PropTypes.string,
  subContent: PropTypes.string,
  counter: PropTypes.number,
  selectedChatId: PropTypes.string.isRequired,
  userId: PropTypes.string.isRequired,
  positionPopUpOnlineOnPhoto: PropTypes.oneOf(['topRight', 'topLeft', 'bottomRight', 'bottomLeft']),
};

ChatPersonItem.defaultProps = {
  clicked: () => null,
  photoUrl: '',
  fullName: '',
  subContent: '',
  counter: 0,
  positionPopUpOnlineOnPhoto: 'topLeft',
};

export default memo(ChatPersonItem);
