import React, { memo, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useParams } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import styles from './AddOnSection.module.css';
import AddOnCard from './AddOnCard/AddOnCard';
import { InputButtonMain } from '../../../../components/UI_V2/Input/Button';
import handleLoadings from '../../../../utilities/handleLoadings';
import { BillingActions } from '../../../../actions';
import handleStatusMsg from '../../../../utilities/handleStatusMsg';

const AddOnSection = ({
  handleSetOrder,
  isOrderSummaryOpen,
  order,
}) => {
  const [loadings, setLoadings] = useState([]);
  const [addons, setAddons] = useState([]);
  const [tempOrder, setTempOrder] = useState({});
  const params = useParams();
  const { companyId } = params;
  const { enqueueSnackbar } = useSnackbar();

  const getAddonsWithQtyApi = async (orderId) => {
    try {
      const startLoadings = handleLoadings('billingOrderListAddons', [...loadings], 'start');
      setLoadings([...startLoadings]);

      const result = await BillingActions.getAddonsWithQty({
        companyId,
        orderId,
      });

      setAddons(result.data);
    } catch (err) {
      const status = handleStatusMsg(err, 'error');

      enqueueSnackbar(status.message, {
        variant: 'error',
      });
    } finally {
      const endLoadings = handleLoadings('billingOrderListAddons', [...loadings], 'end');
      setLoadings([...endLoadings]);
    }
  };

  const updateOrderApi = async (items) => {
    try {
      const startLoadings = handleLoadings('billingUpdateOrder', [...loadings], 'start');
      setLoadings([...startLoadings]);

      const result = await BillingActions.updateOrderSummary({
        companyId,
        items,
        orderId: order?._id,
      });

      handleSetOrder(result.data);

      return result.data;
    } catch (err) {
      const status = handleStatusMsg(err, 'error');

      enqueueSnackbar(status.message, {
        variant: 'error',
      });
      return null;
    } finally {
      const endLoadings = handleLoadings('billingUpdateOrder', [...loadings], 'end');
      setLoadings([...endLoadings]);
    }
  };

  const mergeOrderItemsWithUpdatedQty = ({ orderItems, item }) => {
    if (!item || !orderItems) return null;
    if (orderItems.length < 1) {
      return [item];
    }

    const items = orderItems.map((elem) => (
      {
        _id: elem.product._id,
        qty: elem.qty,
      }
    ));

    const index = items.findIndex((elem) => elem._id === item._id);

    const newItems = [...items];
    if (index !== -1) {
      newItems[index].qty = item.qty;
    } else {
      newItems.push(item);
    }
    return newItems;
  };

  const handleClickUpdateAddonQty = async (item) => {
    const updatedItems = mergeOrderItemsWithUpdatedQty({ item, orderItems: order.items });
    const resultOrder = await updateOrderApi(updatedItems);
    if (resultOrder?._id) {
      getAddonsWithQtyApi(resultOrder._id);
    }
  };

  const initiateAddons = async () => {
    getAddonsWithQtyApi(order._id);
    setTempOrder(order);
  };

  useEffect(() => {
    if (!isOrderSummaryOpen) return;
    if (!order?._id) return;
    if (tempOrder?._id) return;
    initiateAddons();
  }, [order]);

  return (
    <div className={styles.container}>
      <div className={styles.items}>
        {addons.map((addon) => (
          <div className={styles.item}>
            <AddOnCard
              handleClickUpdateAddonQty={handleClickUpdateAddonQty}
              loadings={loadings}
              addon={addon}
            />
          </div>
        ))}
      </div>
    </div>
  );
};

AddOnSection.propTypes = {
  order: PropTypes.object.isRequired,
  isOrderSummaryOpen: PropTypes.bool.isRequired,
  handleSetOrder: PropTypes.func.isRequired,
};

export default memo(AddOnSection);
