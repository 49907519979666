/* eslint-disable no-shadow */
/* eslint-disable import/extensions */
import React, { useState, useEffect, useRef } from 'react';
// main editor
import 'froala-editor/css/froala_style.min.css';
import 'froala-editor/css/froala_editor.pkgd.min.css';
// PLUGINS//
// Emoticons
import 'froala-editor/js/plugins/emoticons.min.js';
import 'froala-editor/css/plugins/emoticons.min.css';
// Colors
import 'froala-editor/js/plugins/colors.min.js';
import 'froala-editor/css/plugins/colors.min.css';
// quote
// import 'froala-editor/js/plugins/quote.min.js';
// lists
import 'froala-editor/js/plugins/lists.min.js';
// link
import 'froala-editor/js/plugins/link.min.js';
// url
import 'froala-editor/js/plugins/url.min.js';
// Tribute mention plugin
import Tribute from 'tributejs';
import 'tributejs/dist/tribute.css';

import FroalaEditorComponent from 'react-froala-wysiwyg';
import PropTypes from 'prop-types';
import styles from './EditorMin.module.css';

const EditorMin = ({
  customConfig,
  model,
  onModelChange,
  initialContent,
  triggerLists,
  type,
  handleAction,
  isAction,
  placeholderText,
  companyId,
  handleTyping,
}) => {
  const [editor, setEditor] = useState();
  const ref = useRef({ editor: null });

  const tribute = new Tribute({
    collection: [
      {
        lookup: 'fullName',
        fillAttr: 'fullName',
        values: [
          ...triggerLists,
        ],
        selectTemplate(item) {
          let photoSource;
          if (item.original?.photoUrl) {
            if (item.original?.photoUrl?.charAt(0) === '/') {
              photoSource = process.env.REACT_APP_PRIMARY_API_URL + item.original?.photoUrl;
            } else {
              photoSource = item.original?.photoUrl;
            }
          }
          return `<span id="mentioned-user" contenteditable="false" data-mentioned-user-id="${item.original._id}" class="fr-deletable fr-tribute" style="padding:1px; background-color:#e8ffff; border-radius:2px; display:inline-flex; align-items:center"><img style="width:20px; height:20px; object-fit: cover; margin-right:3px; border-radius:100%;" src="${photoSource}" /><a href="/companies/${companyId}/profiles/${item.original._id}">${item.original?.fullName}</a></span>`;
        },
        menuItemTemplate(item) {
          let photoSource;
          if (item.original?.photoUrl) {
            if (item.original?.photoUrl?.charAt(0) === '/') {
              photoSource = process.env.REACT_APP_PRIMARY_API_URL + item.original?.photoUrl;
            } else {
              photoSource = item.original?.photoUrl;
            }
          }
          return `<span style="display:inline-flex; align-items:center"><img style="width:20px; height:20px; object-fit: cover; margin-right:3px; border-radius:100%;" src="${photoSource}" /><a style="font-weight:400;">${item.original?.fullName}</a></span>`;
        },
      },
    ],

  });

  useEffect(() => {
    if (isAction === undefined) return;

    const { editor } = ref.current;
    if (!editor || !editor?.html || !editor?.el) return;
    editor.html.set('');
    editor.el.focus();
  }, [isAction]);

  const config = {
    // general options
    key: process.env.REACT_APP_EDITOR_LICENSE_KEY,
    pluginsEnabled: ['url', 'link', 'emoticons', 'colors', 'lists'],
    toolbarButtons: ['emoticons', 'bold', 'italic', 'underline', 'strikeThrough', 'formatOLSimple', 'formatUL', 'backgroundColor'],
    toolbarInline: true,
    linkAlwaysBlank: true,
    heightMax: 100,
    //   multiLine: false,
    autofocus: true,
    //   pastePlain: true,
    placeholderText: placeholderText || 'Ketik pesan disini...',
    attribution: false,
    typingTimer: 250,
    events: {
      async initialized() {
        await new Promise((resolve) => setTimeout(resolve, 1));
        const editor = this;
        setEditor(editor);
        tribute.attach(editor.el);
        editor.el.focus();
        editor.events.on(
          'keydown',
          // eslint-disable-next-line consistent-return
          (e) => {
            if (e.which === 13 && tribute.isActive) {
              return false;
            }

            if (type === 'chat' || type === 'groupChat') {
              if (e.ctrlKey && e.keyCode === 13) {
                setTimeout(handleAction, 250);
                return false;
              }
            }

            if (handleTyping) handleTyping(e.key);
          },
          true,
        );

        if (initialContent) editor.html.set(initialContent);
      },
      focus() {
        tribute.append(0, triggerLists, true);
      },
    },

  };

  return (
    <FroalaEditorComponent
      ref={ref}
      tag="textarea"
      model={model}
      onModelChange={onModelChange}
      config={{ ...config, ...customConfig }}
    />
  );
};

EditorMin.propTypes = {
  customConfig: PropTypes.object.isRequired,
  model: PropTypes.string.isRequired,
  onModelChange: PropTypes.func.isRequired,
  initialContent: PropTypes.string.isRequired,
  triggerLists: PropTypes.array.isRequired,
  type: PropTypes.string.isRequired,
  handleAction: PropTypes.func.isRequired,
  isAction: PropTypes.bool.isRequired,
  placeholderText: PropTypes.string,
  companyId: PropTypes.string.isRequired,
  handleTyping: PropTypes.func.isRequired,
};

EditorMin.defaultProps = {
  placeholderText: null,
};

export default EditorMin;
