import React, { memo, useState } from 'react';
import PropTypes from 'prop-types';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import styles from './Revenue.module.css';
import { DisplayTextBody, DisplayTextSubHeadline } from '../../../../../components/UI_V2/Display/Text';
import Color from '../../../../../themes/colors';
import arrowIllustration from '../../../../../assets/DashboardAdminPage/PirateMetricPage/arrow-illustration.png';
import { getSingleOrPlural } from '../../../../../utilities/stringUtil';
import BoxText from '../BoxText/BoxText';
import PercentageText from '../PercentageText/PercentageText';

const Revenue = ({
  value,
  percentage,
  url,
}) => (
  <div className={styles.container}>
    <div className={styles.box}>
      <BoxText
        value={value}
        desc="Buy"
        url={url}
      />
    </div>
    <div className={styles.content}>
      <div className={styles.title}>
        <DisplayTextSubHeadline
          decoration="bold"
        >
          Revenue
        </DisplayTextSubHeadline>
      </div>
      <div className={styles.arrowIllustration}>
        <LazyLoadImage src={arrowIllustration} />
      </div>
      <div className={styles.percentage}>
        <PercentageText percentage={percentage} />
      </div>
    </div>
  </div>
);

Revenue.propTypes = {
  value: PropTypes.number,
  percentage: PropTypes.number,
  url: PropTypes.string.isRequired,
};

Revenue.defaultProps = {
  value: 0,
  percentage: 0,
};

export default memo(Revenue);
