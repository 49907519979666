import React, {
  memo, useCallback, useState, useReducer, useEffect, useContext,
} from 'react';
import { useLocation, useParams } from 'react-router-dom';
import PropTypes from 'prop-types';
import FilterAltOutlinedIcon from '@mui/icons-material/FilterAltOutlined';
import { Box } from '@mui/material';
import { isEqual } from 'lodash';
import { InputSearchGeneral } from '../../components/UI_V2/Input/Search';
import { GlobalContext } from '../../contexts/GlobalStateProvider';
import {
  useSearch,
  useEventListener, useFocusAndBlur,
  useDelayShowHideHandler,
} from '../../hooks/HelperHooks';
import ShowResults from './ShowResults/ShowResults';
import { SearchJumpToActions, TeamActions } from '../../actions';
import { getStoreRecentViewPages } from '../../utilities/localStorage';

const SearchJumpToContainer = ({ params, onCloseModal }) => {
  const [{
    user, currentCompany, teams, searchList,
  }, dispatch] = useContext(GlobalContext);
  // const [openResult, setOpenResult] = useState(false);
  // const [openResult, handleOpenResult, handleCloseResult] = useDelayShowHideHandler();
  const location = useLocation();
  const { companyId } = params;
  const {
    searchResult,
    keyword,
    keywordArray,
    setKeyword,
    isTyping,
    onChange,
    debouncedKeyword,
  } = useSearch({ originalList: searchList, debounceDelay: 2000 });
  // const [inputRef, setInputRefFocus, setInputRefBlur] = useFocusAndBlur();
  const listRecentViewPages = getStoreRecentViewPages({ companyId, userId: user?._id }) || [];

  // const handleOpenResult = useCallback(() => {
  //   setOpenResult(true);
  // }, []);

  const handleResetSearch = useCallback(() => {
    setKeyword('');
  }, []);

  // const handleOpenAndFocus = useCallback(() => {
  //   handleOpenResult();
  //   setInputRefFocus();
  // }, []);

  // const handleCloseAll = useCallback(() => {
  //   handleResetSearch();
  //   setInputRefBlur();
  //   handleCloseResult();
  // }, []);

  // const handleKeyPressed = useCallback(({ ctrlKey, key }) => {
  //   if (ctrlKey && key === '/') {
  //     if (openResult) {
  //       handleCloseAll();
  //     } else {
  //       handleOpenAndFocus();
  //     }
  //   }
  // }, [openResult]);

  // useEventListener('keydown', (event) => handleKeyPressed(event));

  // const handleCloseResult = useCallback(() => {
  //   setOpenResult(false);
  // }, []);

  // useEffect(() => {
  //   if (debouncedKeyword.length > 0) handleResetSearch();
  //   onCloseModal();
  // }, [location]);

  const [isMouseOnSearchBar, setIsMouseOnSearchBar] = useState(false);

  const handleSetMouseStatus = useCallback((value) => {
    setIsMouseOnSearchBar(value);
  }, []);

  return (
    <Box
      sx={{ position: 'relative' }}
      onMouseOver={() => handleSetMouseStatus(true)}
      onMouseLeave={() => handleSetMouseStatus(false)}
      onTouchStart={() => handleSetMouseStatus(true)}
    >
      <InputSearchGeneral
        // ref={inputRef}
        onChange={onChange}
        placeholder="Lompat ke halaman lain atau ketikkan yang Kamu cari..."
        value={keyword}
        autoFocus
        border="semiRounded"
        size="sm"
        onClick={() => null}
        onClickCloseIcon={handleResetSearch}
      />

      <ShowResults
        isTyping={isTyping}
            // keyword={keyword}
        isOnSearchBar={isMouseOnSearchBar}
        debouncedKeyword={debouncedKeyword}
        onClose={onCloseModal}
        listSearch={searchResult}
        listTeams={teams}
        listRecentViewPages={listRecentViewPages}
        keywordArray={keywordArray}
        params={params}
      />
    </Box>
  );
};

SearchJumpToContainer.propTypes = {
  params: PropTypes.object,
  onCloseModal: PropTypes.func,
};

SearchJumpToContainer.defaultProps = {
  params: { companyId: null, teamId: null },
  onCloseModal: () => null,
};

// const isComponentDataEqual = (prevProps, nextProps) => {
//   const { params } = nextProps;
//   return isEqual(prevProps.params, params);
// };

export default memo(SearchJumpToContainer);
