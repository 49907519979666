import React from 'react';
import DeleteIcon from '@mui/icons-material/Delete';
import PropTypes from 'prop-types';
import styles from './CheersItem.module.css';
import ImageMember from '../../../../components/UI/ImageMember/ImageMember';
import { DisplayAvatarMember } from '../../../../components/UI_V2/Display/Avatar';
import OverlayButton from '../../../../components/UI/Button/OverlayButton/OverlayButton';
import ButtonDiv from '../../../../components/UI/Button/ButtonDiv/ButtonDiv';
import { DisplayTextBody } from '../../../../components/UI_V2/Display/Text';

const CheersItem = ({
  handleToggleDelete, handleDeleteCheer, showDelete, cheer, loadings, disableClick,
}) => (
  <OverlayButton wait={`deleteCheers-${cheer._id}`} loadings={loadings}>
    <ButtonDiv
      onClick={disableClick ? null : () => handleToggleDelete(cheer)}
      className={styles.container}
    >
      <div className={styles.photo}>
        <DisplayAvatarMember src={cheer?.creator?.photoUrl} size="md" />
      </div>
      <div className={styles.content}>
        <DisplayTextBody fontFamily="openSans">{cheer?.content}</DisplayTextBody>
      </div>
      {showDelete === cheer._id ? (
        <div className={styles.deleteIcon}>
          <DeleteIcon onClick={() => handleDeleteCheer(cheer)} />
        </div>
      ) : null}

    </ButtonDiv>
  </OverlayButton>
);

CheersItem.propTypes = {
  disableClick: PropTypes.bool.isRequired,
  loadings: PropTypes.array.isRequired,
  showDelete: PropTypes.string.isRequired,
  handleToggleDelete: PropTypes.func.isRequired,
  handleDeleteCheer: PropTypes.func.isRequired,
  cheer: PropTypes.object.isRequired,
};

export default CheersItem;
