import React, { useState, useEffect } from 'react';
import draftToHtml from 'draftjs-to-html';
import PropTypes from 'prop-types';
import styles from './Doc.module.css';
import checkIsJson from '../../../utilities/checkIsJson';
import EditorView from '../../FroalaEditor/EditorView/EditorView';
import PrivateIcon from '../../../components/UI/PrivateIcon/PrivateIcon';

const Doc = ({ doc, clicked }) => {
  const [editorModel, setEditorModel] = useState('');
  const [isInitialContentReady, setIsInitialContentReady] = useState(false);

  useEffect(() => {
    let initialContent;
    if (checkIsJson(doc.content)) {
      initialContent = draftToHtml(JSON.parse(doc.content));
    } else {
      initialContent = doc.content;
    }

    setEditorModel(initialContent);
    setIsInitialContentReady(true);
  }, []);

  return (
    <div onClick={clicked} className={styles.Doc}>
      <div className={styles.main}>
        <div className={styles.main__title}>
          <h1>
            <PrivateIcon data={doc} size="small" />
            {' '}
            {doc.title}
          </h1>
        </div>
        <div className={styles.main__content}>
          {isInitialContentReady ? <EditorView model={editorModel} /> : <p>Waiting content...</p>}
        </div>
      </div>
    </div>
  );
};

Doc.propTypes = {
  doc: PropTypes.object.isRequired,
  clicked: PropTypes.func.isRequired,
};

export default Doc;
