import React, { memo, useContext, useState } from 'react';
import PropTypes from 'prop-types';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { useMediaQuery } from '@mui/material';
import styles from './CountCardRecapSection.module.css';
import Color from '../../../../themes/colors';
import DisplayChartStackedBar from '../../../../components/UI_V2/Display/Chart/StackedBar/StackedBar';
import TitleChart from '../../DataRealtimeReport/ChartUserReport/TitleChart/TitleChart';
import BackgroundChart from '../../DataRealtimeReport/ChartUserReport/BackgroundChart/BackgroundChart';
import WorkloadInterpretation from './WorkloadInterpretation/WorkloadInterpretation';
import CompleteRatioInterpretation from './CompleteRatioInterpretation/CompleteRatioInterpretation';
import OverdueRatioInterpretation from './OverdueRatioInterpretation/OverdueRatioInterpretation';
import RecapDataSkeleton from '../RecapDataSkeleton/RecapDataSkeleton';
import { GlobalContext } from '../../../../contexts/GlobalStateProvider';

const chartWithSidebarStyles = {
  true: `${styles.chart} ${styles.chartSidebarOpen}`,
  false: styles.chart,
};

const CountCardRecapSection = ({
  listReport,
  cardRecapInterpretation,
  isLoading,
}) => {
  const [{ isSidebarOpen }] = useContext(GlobalContext);
  const labels = listReport.map((elem) => `W${elem?.week}`);

  const colors = [
    Color.gray3,
    Color.lighterBlueCicle,
    Color.deleteColor,
    Color.tosca,
  ];

  const backlogData = listReport.map((elem) => elem?.count?.backlog?.value);
  const backlogDataset = {
    label: 'Belum',
    data: backlogData,
  };

  const ongoingData = listReport.map((elem) => elem?.count?.ongoing?.value);
  const ongoingDataset = {
    label: 'Dikerjakan',
    data: ongoingData,
  };

  const overdueData = listReport.map((elem) => elem?.count?.overdue?.value);
  const overdueDataset = {
    label: 'Terlambat',
    data: overdueData,
  };

  const completedData = listReport.map((elem) => elem?.count?.completed?.value);
  const completedDataset = {
    label: 'Selesai',
    data: completedData,
  };

  const datasets = [
    backlogDataset,
    ongoingDataset,
    overdueDataset,
    completedDataset,
  ];

  const isTablet = useMediaQuery('(max-width:1200px)');
  const isMobile = useMediaQuery('(max-width:720px)');
  return (
    <div className={styles.container}>
      <BackgroundChart
        heightVariant={isTablet ? 'low' : 'medium'}
      >
        <div className={styles.title}>
          <TitleChart>
            Progress Tugas per Minggu
          </TitleChart>
        </div>
        {isLoading && <RecapDataSkeleton />}
        {!isLoading && (
        <>
          <div className={chartWithSidebarStyles[isSidebarOpen]}>
            <DisplayChartStackedBar labels={labels} colors={colors} datasets={datasets} />
          </div>
          <div className={styles.interpretation}>
            <div className={styles.interpretationItem}>
              <WorkloadInterpretation value={cardRecapInterpretation?.workloadAverage?.value} />
            </div>
            <div className={styles.interpretationItem}>
              <CompleteRatioInterpretation
                completeRatioAverage={cardRecapInterpretation?.completeRatioAverage}
              />
            </div>
            <div className={styles.interpretationItem}>
              <OverdueRatioInterpretation
                overdueRatioAverage={cardRecapInterpretation?.overdueRatioAverage}
              />
            </div>
          </div>
        </>
        )}
      </BackgroundChart>
    </div>
  );
};

CountCardRecapSection.propTypes = {
  listReport: PropTypes.array,
  cardRecapInterpretation: PropTypes.string,
  isLoading: PropTypes.bool.isRequired,
};

CountCardRecapSection.defaultProps = {
  listReport: [],
  cardRecapInterpretation: null,
};

export default memo(CountCardRecapSection);
