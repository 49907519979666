import React, {
  useState, useContext, useEffect, useCallback, createRef,
} from 'react';
import { useHistory, useParams, useLocation } from 'react-router-dom';
import _ from 'lodash';
import '../../../node_modules/react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import ArchiveIcon from '@mui/icons-material/Archive';
import VerifiedUserIcon from '@mui/icons-material/VerifiedUser';
import AddIcon from '@mui/icons-material/Add';
import FileMenu from './FileMenu/FileMenu';
import MainLayout from '../../components/Layout/MainLayout/MainLayout';
import RoundActionMenu from '../../components/UI/RoundActionMenu/RoundActionMenu';
import File from '../BucketPage/File/File';
import ImageMember from '../../components/UI/ImageMember/ImageMember';
import CommentsSectionContainer from '../CommentsSectionContainer/CommentsSectionContainer';
import styles from './FilePage.module.css';
import Paper from '../../components/UI/Paper/Paper';
import SeparatorLine from '../../components/UI/SeparatorLine/SeparatorLine';
import { actionTypes } from '../../reducers/reducer';
import { GlobalContext, initialState } from '../../contexts/GlobalStateProvider';
import Title from '../../components/Title/Title';
import handleStatusMsg from '../../utilities/handleStatusMsg';
import handleLoadings from '../../utilities/handleLoadings';
import PageLoading from '../../components/UI/Button/PageLoading/PageLoading';
import CheersContainer from '../CheersContainer/CheersContainer';
import {
  CommentActions,
  FileActions,
  SearchJumpToActions, TeamActions,
} from '../../actions';
import { useInfinityScrollHooks } from '../../hooks/InfinityScrollHooks';
import InfinityScroll from '../../components/UI/InfinityScroll/InfinityScroll';
import {
  useFileCheerNCommentSocket,
  useFileSocket,
  useFileViewer,
} from '../../hooks/FileHooks';
import { useScrollToTargetComment } from '../../hooks/DiscussionHooks';
import GeneralSubNavBar from '../../components/GeneralSubNavBar/GeneralSubNavBar';
import { generateRoutesAndTitle } from '../../actions/BreadCrumbActions';
import GlobalActionButton from '../../components/GeneralSubNavBar/BottomNavBar/GlobalActionButton/GlobalActionButton';
import { PageConstants, SeenConstants } from '../../constants';
import { useDelayShowHideHandler } from '../../hooks/HelperHooks';
import { useInitiateRoleUser } from '../../hooks/RoleHooks';
import { roleTypeRef, typeRef } from '../../constants/RoleConstants';
import MyRoleUser from '../../components/Container/MyRoleUser/MyRoleUser';
import { checkIfUserAuthorizedToEditData } from '../../actions/UserActions';
import { useInitiateTeamBelowRole } from '../../hooks/TeamHooks';
import ActivityByModule from '../ActivityByModule/ActivityByModule';
import { serviceTypeRef } from '../../constants/ActivityConstants';
import { DisplayCreatorMain } from '../../components/UI_V2/Display/Creator';
import { DisplayAvatarMemberWithOnline } from '../../components/UI_V2/Display/Avatar';
import SeenInfo from '../../components/Container/SeenModal/SeenInfo/SeenInfo';
import SeenModal from '../../components/Container/SeenModal/SeenModal';
import { useUserTypingFileSocket } from '../../hooks/UserTypingHooks';
import UserTyping from '../../components/Container/UserTyping/UserTyping';
import { useMarkReadNotificationOnPageOpen } from '../../hooks/NotificationHooks';
import { LayoutMain } from '../../components/UI_V2/Layout';
import { SurfacePaperMain } from '../../components/UI_V2/Surface/Paper';
import FileViewerDialog from '../../components/FileViewerDialog/FileViewerDialog';

function FilePage() {
  const [{
    user, currentBucket, currentBucketFile,
    previousFileComment, bucketDocPath, currentTeam,
    currentCompany, currentRoleUser, currentTeamBelowRole,
    allUserTypingFile,
  }, dispatch] = useContext(GlobalContext);
  const [showFileMenu, setShowFileMenu] = useState(false);
  const [openCreateCommentForm, setOpenCreateCommentForm] = useState(false);
  const [loadings, setLoadings] = useState([]);
  const [
    showModalRoleUser,
    handleShowModalRoleUser,
    handleHideModalRoleUser,
  ] = useDelayShowHideHandler();

  const [
    showSeenModal,
    handleShowSeenModal,
    handleHideSeenModal,
  ] = useDelayShowHideHandler();

  const history = useHistory();
  const location = useLocation();
  const params = useParams();
  const { companyId, teamId, fileId } = params;
  const { scrollToTargetComment } = useScrollToTargetComment();

  useInitiateRoleUser({
    payload: {
      teamId,
      featureType: typeRef.file,
      featureId: fileId,
    },
  });

  const { socket } = useUserTypingFileSocket({
    companyId,
    fileId,
    teamId,
    userId: user?._id,
  }, dispatch);

  // read notif automatically if there is readNotifId
  useMarkReadNotificationOnPageOpen();

  // initiate members below role
  // useInitiateTeamBelowRole({});
  const isUserCreator = currentBucketFile?.creator?._id === user?._id;

  const [
    showActivities,
    handleShowActivities,
    handleHideActivities,
  ] = useDelayShowHideHandler();

  const {
    openFileViewer,
    fileViewerObj,
    handleOpenAndSetFileViewer,
    handleCloseFileViewer,
  } = useFileViewer();

  // for open create comment
  const handleOpenCreateCommentForm = useCallback((value) => {
    setOpenCreateCommentForm(value);
  }, []);
  const createCommentRef = createRef();
  const scrollAndOpenCreateCommentForm = useCallback(() => {
    if (createCommentRef && createCommentRef.current) {
      createCommentRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
    }
    setTimeout(() => {
      handleOpenCreateCommentForm(true);
    }, 800);
  }, [createCommentRef]);

  const getMoreComments = async () => {
    const result = await FileActions.loadMoreFileComment({
      fileId,
      currentBucketFile,
      companyId,
      teamId,
    }, dispatch);

    return result;
  };

  const resetPreviousComment = useCallback(() => {
    FileActions.setPreviousBucketFileComment({
      previousFileComment: initialState.previousFileComment,
    }, dispatch);
  }, []);

  const {
    lists,
    handleLoadMoreLists,
    checkIfListNeedToLoad,
    setCheckLoadMore,
    isListsLengthAtTheLimitOrAbove,
  } = useInfinityScrollHooks({
    currentObjectWithKeyProperty: currentBucketFile,
    previousLists: previousFileComment,
    resetPreviousLists: resetPreviousComment,
    getMoreLists: getMoreComments,
  });

  useFileSocket({
    bucketId: currentBucket?._id,
    fileId: params?.fileId,
    userId: user?._id,
  }, dispatch);
  useFileCheerNCommentSocket({ fileId: params?.fileId, userId: user?._id }, dispatch);

  useEffect(() => {
    if (user._id === undefined) {
      history.push(`/check-login?previousPath=${location.pathname}`);
      return;
    }

    const startLoadings = handleLoadings('filePage', [...loadings], 'start');
    setLoadings([...startLoadings]);

    const fetchApiFileDetail = async () => {
      try {
        const result = await FileActions.initiateFile({
          fileId,
          companyId,
          teamId,
          limit: CommentActions.getLimitCommentByPreviousCommentDiscussion(),
        }, dispatch);
        await TeamActions.initiateTeam({
          teamId,
          companyId,
          currentTeam,
        }, dispatch);
        if (isListsLengthAtTheLimitOrAbove(result?.data?.comments)) {
          setCheckLoadMore(true);
        }
        scrollToTargetComment();
      } catch (err) {
        const status = handleStatusMsg(err, 'error');

        dispatch({
          type: actionTypes.SET_ERROR_RESPONSE,
          errorResponse: { message: status.message },
        });

        history.push(`/errors?previousPath=${location.pathname}`);
      } finally {
        const endLoadings = handleLoadings('filePage', [...loadings], 'end');
        setLoadings([...endLoadings]);
      }
    };

    fetchApiFileDetail();
  }, [location]);

  const handleShowFileMenu = () => {
    setShowFileMenu(true);
  };

  const handleCancelShowFileMenu = () => {
    setShowFileMenu(false);
  };
  const pageType = PageConstants.pageType.files;
  const breadcrumb = generateRoutesAndTitle({
    type: pageType,
    data: {
      companyId, currentTeam, currentBucketFile, bucketDocPath,
    },
  });
  SearchJumpToActions.saveRecentPage({
    company: currentCompany,
    team: currentTeam,
    userId: user?._id,
    itemId: fileId,
    url: breadcrumb.url,
    routes: breadcrumb.routes,
    name: breadcrumb.title,
    type: pageType,
  });

  return (
    <>
      <Title title={currentBucketFile.title === undefined ? 'File' : currentBucketFile.title} />
      <GeneralSubNavBar
        breadcrumbRoutes={breadcrumb.routes}
        pageTitle={breadcrumb.title}
        bottomBarOverviewOptionId="buckets"
        bottomBarGlobalActions={(
          <>
            <GlobalActionButton handleClick={scrollAndOpenCreateCommentForm} text="Tambah Komentar" icon={<AddIcon />} />
            <GlobalActionButton
              handleClick={handleShowModalRoleUser}
              text="Akses Saya"
              icon={<VerifiedUserIcon />}
            />
          </>
        )}
      />
      <LayoutMain>
        <PageLoading wait="filePage" loadings={loadings}>
          <SurfacePaperMain>
            <div className={styles.container}>
              <div className={styles.headerSection}>
                <div className={styles.titleSection}>
                  <div className={styles.titleSection__title}>
                    {currentBucketFile.archived ? (
                      <>
                        {currentBucketFile.archived.status ? (
                          <div className={styles.archivedSection}>
                            <ArchiveIcon />
                            <h1>File ini udah terarsip</h1>
                          </div>
                        ) : null}
                      </>
                    ) : null}
                  </div>
                  <div className={styles.creator}>
                    <DisplayCreatorMain data={currentBucketFile} />
                  </div>
                </div>
                <div className={styles.menuSection}>
                  {checkIfUserAuthorizedToEditData(currentBucketFile, user,
                    currentRoleUser,
                    currentTeamBelowRole) && (
                    <RoundActionMenu
                      clicked={handleShowFileMenu}
                      cancel={handleCancelShowFileMenu}
                      medium
                    >
                      <MoreHorizIcon />
                    </RoundActionMenu>
                  )}
                  {showFileMenu
                    ? <FileMenu file={currentBucketFile} cancel={handleCancelShowFileMenu} />
                    : null}
                </div>
              </div>
              <div className={styles.filesSection}>
                <File
                  handleOpenAndSetFileViewer={handleOpenAndSetFileViewer}
                  file={currentBucketFile}
                  big
                />
              </div>
              <div className={styles.cheersSection}>
                {_.isEmpty(currentBucketFile) ? null : <CheersContainer cheers={currentBucketFile?.cheers} type="fileMain" receiver={currentBucketFile?.creator?._id} primaryParentId={currentBucketFile?._id} />}
              </div>
              <div className={styles.seenSection}>
                {isUserCreator && (
                <SeenInfo
                  count={currentBucketFile?.seen?.length}
                  onClick={handleShowSeenModal}
                  variant="black"
                />
                )}
              </div>
              <div className={styles.typingSection}>
                <UserTyping
                  allUserTyping={allUserTypingFile}
                  companyMembers={currentCompany?.members}
                />
              </div>
              <div className={styles.commentSection}>
                {!showActivities && (
                <InfinityScroll
                  dataLength={lists?.length}
                  hasMore={checkIfListNeedToLoad()}
                  next={handleLoadMoreLists}
                  emptyMessage="Belum ada komentar disini..."
                  style={{
                    overflow: undefined,
                  }}
                >
                  <div>
                    <CommentsSectionContainer
                      comments={lists}
                      type="file"
                      ref={createCommentRef}
                      openForm={openCreateCommentForm}
                      onOpenForm={handleOpenCreateCommentForm}
                      handleShowActivities={handleShowActivities}
                      socket={socket}
                    />
                  </div>
                </InfinityScroll>
                )}
                {showActivities && (
                <ActivityByModule
                  variant={serviceTypeRef.file}
                  handleHideActivities={handleHideActivities}
                />
                )}
              </div>
              <SeparatorLine />
              {currentBucketFile.subscribers !== undefined ? (
                <div className={styles.Subscribers}>
                  <div className={styles.subscribersHeader}>
                    {currentBucketFile.subscribers.length < 1
                      ? (
                        <p>
                          <strong>File ini diterima oleh.</strong>
                        </p>
                      ) : <p>File ini diterima oleh : </p>}
                  </div>
                  <div className={styles.subscribersItem}>

                    {currentBucketFile.subscribers.map((subscriber) => (
                      <div>
                        <DisplayAvatarMemberWithOnline
                          src={subscriber?.photoUrl}
                          userId={subscriber._id}
                          size="sm"
                        />
                      </div>
                    ))}
                  </div>
                </div>
              ) : null}
            </div>
          </SurfacePaperMain>
        </PageLoading>
        <MyRoleUser
          open={showModalRoleUser}
          onClose={handleHideModalRoleUser}
          featureId={fileId}
          featureType={typeRef.file}
          featureTitle={currentBucketFile?.title}
          roleType={roleTypeRef.feature}
          featureData={currentBucketFile}
        />
        <SeenModal
          open={showSeenModal}
          onClose={handleHideSeenModal}
          type={SeenConstants.typeModule.file}
          itemId={fileId}
        />
        <FileViewerDialog
          open={openFileViewer}
          onClose={handleCloseFileViewer}
          file={fileViewerObj}
        />
      </LayoutMain>
    </>
  );
}

export default FilePage;
