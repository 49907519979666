import React, { useState, useEffect, useContext } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import _ from 'lodash';
import queryString from 'query-string';
import ErrorButton from './ErrorButton/ErrorButton';
import { GlobalContext } from '../../contexts/GlobalStateProvider';
import styles from './ErrorPage.module.css';
import Title from '../../components/Title/Title';
import { CheckLoginActions } from '../../actions';
import LogoHeader from '../../components/UI/LogoHeader/LogoHeader';
import errorGeneralImage from '../../assets/ErrorPage/error-general.png';
import error404Image from '../../assets/ErrorPage/error-404.png';

const containerSidebarStyles = {
  true: `${styles.ErrorPage__container} ${styles.sidebarOpen}`,
  false: styles.ErrorPage__container,
};

function ErrorPage() {
  const [{ user, errorResponse, isSidebarOpen }, dispatch] = useContext(GlobalContext);
  const [isNotFound, setIsNotFound] = useState(false);
  const [isTokenExpired, setIsTokenExpired] = useState(false);
  const [errorMessage, setErrorMessage] = useState({
    title: '',
    message: '',
  });

  const history = useHistory();
  const location = useLocation();

  const query = queryString.parse(location.search);
  // detect previous location.
  let previousPath = '/';

  if (query.previousPath) {
    previousPath = query.previousPath;
  }

  const companyId = CheckLoginActions.getCurrentCompanyId({ previousPath, userId: user?._id });
  const url = companyId ? `/companies/${companyId}` : '/';

  useEffect(() => {
    if (_.isEmpty(errorResponse)) {
      setErrorMessage({
        title: '404 Not Found',
        message: "The page you're looking for is not found",
      });
      setIsNotFound(true);
    } else {
      setErrorMessage({
        title: 'Oops!',
        message: errorResponse.message,
      });
    }

    if (errorResponse.message === 'Maaf sesi kamu di Cicle sudah berakhir. Silahkan login kembali') {
      setIsTokenExpired(true);
    }
  }, [errorResponse]);

  const clickedRefresh = () => {
    history.push(previousPath);
  };

  const clickedDashboard = () => {
    history.push(url);
  };

  const clickedRelogin = () => {
    window.open(`${process.env.REACT_APP_BASE_CLIENT_URL}/signin`, '_self');
  };

  return (
    <>
      <Title title={isTokenExpired ? 'Sesi Berakhir' : 'Terjadi kesalahan di sistem Cicle'} />
      <LogoHeader url={url} />
      <div
        style={isNotFound
          ? { backgroundImage: `url(${error404Image})` }
          : { backgroundImage: `url(${errorGeneralImage})` }}
        className={containerSidebarStyles[isSidebarOpen]}
      >
        <div className={styles.ErrorPage__main}>
          <div className={styles.ErrorPage__main__content}>
            <div className={styles.ErrorPage__main__content__text}>
              <h1>{errorMessage.title}</h1>
              <p>{errorMessage.message}</p>
            </div>
            <div className={styles.ErrorPage__main__content__button}>
              {!isTokenExpired && (
              <>
                <ErrorButton handleClick={clickedRefresh}>
                  <p>Coba Lagi</p>
                </ErrorButton>
                <ErrorButton handleClick={clickedDashboard}>
                  <p>Kembali ke Beranda</p>
                </ErrorButton>
              </>
              )}
              {isTokenExpired && (
              <ErrorButton handleClick={clickedRelogin}>
                <p>Login Ulang</p>
              </ErrorButton>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default ErrorPage;
