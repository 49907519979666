import React, {
  useState, useContext, useEffect, useCallback, memo
} from 'react';
import { useDropzone } from 'react-dropzone';
import axios from 'axios';
import Spinner from 'react-bootstrap/Spinner';
import { useSnackbar } from 'notistack';
import PropTypes from 'prop-types';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { GlobalContext } from '../../../../contexts/GlobalStateProvider';
import { actionTypes } from '../../../../reducers/reducer';
import styles from './LogoCompanyContainer.module.css';
import handleStatusMsg from '../../../../utilities/handleStatusMsg';
import { adjustServerUrl } from '../../../../utilities/stringUtil';
import { CompanyActions } from '../../../../actions';

const LogoCompanyContainer = ({ company }) => {
  const [{ user }, dispatch] = useContext(GlobalContext);
  const [loadingUpload, setLoadingUpload] = useState(false);
  const [showUploadInstruction, setShowUploadInstruction] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const [files, setFiles] = useState();
  const onDrop = useCallback((acceptedFiles, fileRejections) => {
    if (acceptedFiles.length < 1) {
      setFiles(fileRejections);
    } else {
      setFiles(acceptedFiles);
    }
  }, []);

  const {
    getRootProps,
    getInputProps,
    isDragActive,
  } = useDropzone({
    onDrop,
    maxSize: '10242880',
  });

  useEffect(() => {
    if (files === undefined) {
      return;
    }

    const postApiLogo = async () => {
      const data = new FormData();
      for (let i = 0; i < files.length; i += 1) {
        data.append('file', files[i]);
      }

      setLoadingUpload(true);

      try {
        const companyId = company._id;

        const result = await CompanyActions.uploadLogoAndUpdateCompanies(
          { companyId, payload: data, userId: user?._id },
          dispatch,
        );

        const status = handleStatusMsg(result, 'success');

        enqueueSnackbar(status.message, {
          variant: 'success',
        });
      } catch (err) {
        const getErrorMessageDropzoneFile = files?.[0]?.errors?.[0];
        const messageError = getErrorMessageDropzoneFile === undefined
          ? err : getErrorMessageDropzoneFile;
        const status = handleStatusMsg(messageError, 'error');

        enqueueSnackbar(status.message, {
          variant: 'error',
        });
      } finally {
        setLoadingUpload(false);
        setShowUploadInstruction(false);
      }
    };
    postApiLogo();
  }, [files]);

  const handleMouseEnter = () => {
    setShowUploadInstruction(true);
  };

  const handleMouseLeave = () => {
    setShowUploadInstruction(false);
  };

  let logoSource;
  if (company?.logo) {
    logoSource = adjustServerUrl(company.logo);
  }
  return (
    <div
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      className={styles.container}
      {...getRootProps()}
    >
      <input {...getInputProps()} />
      <div className={styles.box}>
        <LazyLoadImage className={styles.logo} src={logoSource} />
      </div>
      {isDragActive ? (
        <>
          <div className={`${styles.box} ${styles.backgroundOverlay}`} />
          <div className={`${styles.box} ${styles.contentOverlay}`}>
            <h1>Lepaskan file untuk diunggah.</h1>
          </div>
        </>
      ) : null}
      {showUploadInstruction ? (
        <>
          <div className={`${styles.box} ${styles.backgroundOverlay}`} />
          <div className={`${styles.box} ${styles.contentOverlay}`}>
            <h1>Ubah Logo</h1>
          </div>
        </>
      ) : null}
      {loadingUpload ? (
        <>
          <div className={`${styles.box} ${styles.backgroundOverlay}`} />
          <div className={`${styles.box} ${styles.contentOverlay}`}>
            <div className={styles.loadingContainer}>
              <Spinner animation="border" size="sm" variant="warning" />
            </div>
          </div>
        </>
      ) : null}
    </div>

  );
};

LogoCompanyContainer.propTypes = {
  company: PropTypes.object.isRequired,
};

export default memo(LogoCompanyContainer);
