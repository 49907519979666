import React from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import styles from './LinkNoDecor.module.css';

const LinkNoDecor = ({ to, children }) => (
  <Link className={styles.Link} to={to}>
    {children}
  </Link>
);

LinkNoDecor.propTypes = {
  children: PropTypes.node.isRequired,
  to: PropTypes.string.isRequired,
};

export default LinkNoDecor;
