import React, { useState, useEffect } from 'react';
import draftToHtml from 'draftjs-to-html';
import PropTypes from 'prop-types';
import styles from './DocSnapshot.module.css';
import checkIsJson from '../../../../utilities/checkIsJson';
import PrivateIcon from '../../../../components/UI/PrivateIcon/PrivateIcon';

const DocSnapshot = ({ doc, clicked }) => {
  const [parsedContent, setParsedContent] = useState('');

  useEffect(() => {
    let initialContent;
    if (checkIsJson(doc.content)) {
      initialContent = draftToHtml(JSON.parse(doc.content));
    } else {
      initialContent = doc.content;
    }

    initialContent = initialContent?.replace(/<\/?("[^"]*"|'[^']*'|[^>])*(>|$)/g, '').replace(/\n/g, ' ');
    if (initialContent?.length > 30) {
      initialContent = `${initialContent?.slice(0, 30)}...`;
    }

    setParsedContent(initialContent);
  }, []);
  return (
    <div onClick={clicked} className={styles.Doc}>
      <div className={styles.main}>
        <div className={styles.main__title}>
          <h1>
            <PrivateIcon data={doc} size="small" />
            {' '}
            {doc.title}
          </h1>
        </div>
        <div className={styles.main__content}>
          <p>{parsedContent}</p>
        </div>
      </div>
    </div>
  );
};

DocSnapshot.propTypes = {
  doc: PropTypes.object.isRequired,
  clicked: PropTypes.func.isRequired,
};

export default DocSnapshot;
