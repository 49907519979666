const typeCallback = {
  NEW: 'new',
  DELETE: 'delete',
  EDIT: 'edit',
};

const cicleTutorialUrl = 'https://cicle.app/tutorial';
const cicleContactUrlWeb = 'https://web.whatsapp.com/send?phone=6281384163253&text=Hi%20Mincle%20!%20Saya%20butuh%20bantuan%20terkait%20Cicle';
const cicleContactUrlMobile = 'https://api.whatsapp.com/send?phone=6281384163253&text=Hi%20Mincle%20!%20Saya%20butuh%20bantuan%20terkait%20Cicle';
const cicleTutorialCreateTeam = 'https://www.youtube.com/watch?v=gwG3QPZiEY8&list=PLR0K_dzH9v3NXBxl_bkXZtEq5_qOgcgQk&index=3';

export {
  typeCallback,
  cicleTutorialUrl,
  cicleContactUrlWeb,
  cicleContactUrlMobile,
  cicleTutorialCreateTeam,
};
