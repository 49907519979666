import React, {
  memo, useCallback, useContext, useEffect, useState,
} from 'react';
import PropTypes from 'prop-types';
import { Box } from '@mui/material';
import { DisplayTextSubHeadline, DisplayTextBody } from '../../../../components/UI_V2/Display/Text';
import Color from '../../../../themes/colors';
import { getSingleOrPlural, limitChar } from '../../../../utilities/stringUtil';
import ListResult from './ListResult/ListResult';
import TitleJumpTo from '../JumpTo/TitleJumpTo/TitleJumpTo';
import styles from './SearhResults.module.css';
import ListResultCard from './ListResultCard/ListResultCard';
import ListResultPost from './ListResultPost/ListResultPost';
import ListResultQuestion from './ListResultQuestion/ListResultQuestion';
import ListResultDoc from './ListResultDoc/ListResultDoc';
import ListResultEvent from './ListResultEvent/ListResultEvent';
import ListResultGroupMessage from './ListResultGroupMessage/ListResultGroupMessage';
import ListResultPrivateMessage from './ListResultPrivateMessage/ListResultPrivateMessage';
import ListResultComment from './ListResultComment/ListResultComment';
import FilterSearch from './FilterSearch/FilterSearch';
import Skeleton from '../Skeleton/Skeleton';
import { FilterBarConstants, PageConstants } from '../../../../constants';
import { GlobalContext } from '../../../../contexts/GlobalStateProvider';
import ListResultFile from './ListResultFile/ListResultFile';
import ListResultBucket from './ListResultBucket/ListResultBucket';
import ListResultDiscussion from './ListResultDiscussion/ListResultDiscussion';

const SearchResults = ({
  list, onClose, params, keywordArray,
  // keyword,
  debouncedKeyword,
  isTyping,
}) => {
  const [filters, setFilters] = useState({
    filterTeams: [],
    filterFeatureTypes: [],
    filterArchived: false,
  });

  const isSearchCard = filters.filterFeatureTypes.includes('cards');
  const isSearchComment = filters.filterFeatureTypes.includes('comments');
  const isSearchDiscussion = filters.filterFeatureTypes.includes('discussions');
  const isSearchPrivateChat = filters.filterFeatureTypes.includes('chats');
  const isSearchGroupChat = filters.filterFeatureTypes.includes('groupChats');
  const isSearchPost = filters.filterFeatureTypes.includes('posts');
  const isSearchDoc = filters.filterFeatureTypes.includes('docs');
  const isSearchFile = filters.filterFeatureTypes.includes('files');
  const isSearchBucket = filters.filterFeatureTypes.includes('buckets');
  const isSearchEvent = filters.filterFeatureTypes.includes('events');
  const isSearchQuestion = filters.filterFeatureTypes.includes('questions');
  const isSearchTeam = filters.filterFeatureTypes.includes('teams');
  const isSearchReport = filters.filterFeatureTypes.includes('reports');
  const isSearchCompanyModule = filters.filterFeatureTypes.includes(
    PageConstants.pageType.companyModuleSearch,
  );
  const isSearchMyTasksModule = filters.filterFeatureTypes.includes('myTasks');

  const [listCardTotal, setListCardTotal] = useState(0);
  const [listPostTotal, setListPostTotal] = useState(0);
  const [listQuestionTotal, setListQuestionTotal] = useState(0);
  const [listEventTotal, setListEventTotal] = useState(0);
  const [listDocTotal, setListDocTotal] = useState(0);
  const [listFileTotal, setListFileTotal] = useState(0);
  const [listBucketTotal, setListBucketTotal] = useState(0);
  const [listGroupMessageTotal, setListGroupMessageTotal] = useState(0);
  const [listPrivateMessageTotal, setListPrivateMessageTotal] = useState(0);
  const [listCommentTotal, setListCommentTotal] = useState(0);
  const [listDiscussionTotal, setListDiscussionTotal] = useState(0);
  const [listTeamTotal, setListTeamTotal] = useState(0);
  const [listReportTotal, setListReportTotal] = useState(0);
  const [listCompanyModuleTotal, setListCompanyModuleTotal] = useState(0);
  const [listMyTasksModuleTotal, setListMyTasksModuleTotal] = useState(0);
  const [listTeamModuleBlastTotal, setListTeamModuleBlastTotal] = useState(0);
  const [listTeamModuleScheduleTotal, setListTeamModuleScheduleTotal] = useState(0);
  const [listTeamModuleDocsFilesTotal, setListTeamModuleDocsFilesTotal] = useState(0);
  const [listTeamModuleCheckInTotal, setListTeamModuleCheckInTotal] = useState(0);
  const [listTeamModuleGroupChatTotal, setListTeamModuleGroupChatTotal] = useState(0);
  const [listTeamModuleKanbanTotal, setListTeamModuleKanbanTotal] = useState(0);
  const [allTotal, setAllTotal] = useState(0);

  const handleSetCardTotal = useCallback((value) => {
    setListCardTotal(value);
  }, []);

  const handleSetPostTotal = useCallback((value) => {
    setListPostTotal(value);
  }, []);

  const handleSetQuestionTotal = useCallback((value) => {
    setListQuestionTotal(value);
  }, []);

  const handleSetEventTotal = useCallback((value) => {
    setListEventTotal(value);
  }, []);

  const handleSetDocTotal = useCallback((value) => {
    setListDocTotal(value);
  }, []);

  const handleSetFileTotal = useCallback((value) => {
    setListFileTotal(value);
  }, []);

  const handleSetBucketTotal = useCallback((value) => {
    setListBucketTotal(value);
  }, []);

  const handleSetGroupMessageTotal = useCallback((value) => {
    setListGroupMessageTotal(value);
  }, []);

  const handleSetPrivateMessageTotal = useCallback((value) => {
    setListPrivateMessageTotal(value);
  }, []);

  const handleSetCommentTotal = useCallback((value) => {
    setListCommentTotal(value);
  }, []);

  const handleSetDiscussionTotal = useCallback((value) => {
    setListDiscussionTotal(value);
  }, []);

  const handleSetTeamTotal = useCallback((value) => {
    setListTeamTotal(value);
  }, []);

  const handleSetReportTotal = useCallback((value) => {
    setListReportTotal(value);
  }, []);

  const handleSetCompanyModuleTotal = useCallback((value) => {
    setListCompanyModuleTotal(value);
  }, []);

  const handleSetMyTasksModuleTotal = useCallback((value) => {
    setListMyTasksModuleTotal(value);
  }, []);

  const handleSetTeamModuleBlastTotal = useCallback((value) => {
    setListTeamModuleBlastTotal(value);
  }, []);

  const handleSetTeamModuleGroupChatTotal = useCallback((value) => {
    setListTeamModuleGroupChatTotal(value);
  }, []);

  const handleSetTeamModuleScheduleTotal = useCallback((value) => {
    setListTeamModuleScheduleTotal(value);
  }, []);

  const handleSetTeamModuleCheckInTotal = useCallback((value) => {
    setListTeamModuleCheckInTotal(value);
  }, []);

  const handleSetTeamModuleKanbanTotal = useCallback((value) => {
    setListTeamModuleKanbanTotal(value);
  }, []);

  const handleSetTeamModuleDocsFilesTotal = useCallback((value) => {
    setListTeamModuleDocsFilesTotal(value);
  }, []);

  useEffect(() => {
    const totalLength = listTeamTotal
    + listReportTotal
    + listCompanyModuleTotal
    + listMyTasksModuleTotal
    + listTeamModuleBlastTotal
    + listTeamModuleCheckInTotal
    + listTeamModuleDocsFilesTotal
    + listTeamModuleGroupChatTotal
    + listTeamModuleKanbanTotal
    + listTeamModuleScheduleTotal
    + listDocTotal
    + listFileTotal
    + listBucketTotal
    + listEventTotal
    + listQuestionTotal
    + listPostTotal
    + listCardTotal
    + listGroupMessageTotal
    + listPrivateMessageTotal
    + listCommentTotal
    + listDiscussionTotal;
    setAllTotal(totalLength);
  }, [listCardTotal,
    listPostTotal,
    listQuestionTotal,
    listEventTotal,
    listDocTotal,
    listFileTotal,
    listBucketTotal,
    listGroupMessageTotal,
    listPrivateMessageTotal,
    listCommentTotal,
    listDiscussionTotal,
    listTeamTotal,
    listReportTotal,
    listCompanyModuleTotal,
    listMyTasksModuleTotal,
    listTeamModuleBlastTotal,
    listTeamModuleCheckInTotal,
    listTeamModuleDocsFilesTotal,
    listTeamModuleGroupChatTotal,
    listTeamModuleKanbanTotal,
    listTeamModuleScheduleTotal,
  ]);

  // Trigger refetch list result
  const [isFetching, setIsFetching] = useState(false);

  const handleTriggerFetching = useCallback(() => {
    setIsFetching(true);
    setTimeout(() => {
      setIsFetching(false);
    }, 500);
  }, []);

  const handleApplyFilters = useCallback((value, isNotTriggerFetching) => {
    setFilters(value);
    if (!isNotTriggerFetching) {
      handleTriggerFetching();
    }
  }, []);

  return (
    <Box>
      <Box
        sx={{
          marginBottom: '10px',
        }}
      >
        <div className={styles.filter}>
          <FilterSearch
            onApplyFilters={handleApplyFilters}
          />
        </div>
        <div className={styles.title}>
          <DisplayTextSubHeadline
            mode="14"
            decoration="bold"
          >
            {/* {`${allTotal} ${getSingleOrPlural('result', allTotal)} found`} */}
            {`${allTotal} hasil ditemukan`}
          </DisplayTextSubHeadline>
        </div>
      </Box>
      {(isTyping || isFetching) && <Skeleton />}
      {(!isTyping && !isFetching) && (
      <div className={styles.content}>
        {isSearchTeam && (
        <ListResult
          list={list}
          onClose={onClose}
          keywordArray={keywordArray}
          type="teams"
          title="Tim"
          handleSetTotal={handleSetTeamTotal}
          params={params}
        />
        )}
        {isSearchCompanyModule && (
        <ListResult
          list={list}
          onClose={onClose}
          keywordArray={keywordArray}
          type={PageConstants.pageType.companyModuleSearch}
          title="Menu Perusahaan"
          handleSetTotal={handleSetCompanyModuleTotal}
          params={params}
        />
        )}
        {isSearchReport && (
        <ListResult
          list={list}
          onClose={onClose}
          keywordArray={keywordArray}
          type="reports"
          title="List Laporan Kinerja"
          handleSetTotal={handleSetReportTotal}
          params={params}
        />
        )}
        {isSearchMyTasksModule && (
        <ListResult
          list={list}
          onClose={onClose}
          keywordArray={keywordArray}
          type="myTasks"
          title="Semua Tugas"
          handleSetTotal={handleSetMyTasksModuleTotal}
          params={params}
        />
        )}
        {isSearchCard && (
        <ListResult
          list={list}
          onClose={onClose}
          keywordArray={keywordArray}
          type="boards"
          title="List Tugas"
          handleSetTotal={handleSetTeamModuleKanbanTotal}
          params={params}
        />
        )}
        {isSearchPost && (
        <ListResult
          list={list}
          onClose={onClose}
          keywordArray={keywordArray}
          type="blasts"
          title="List Pengumuman"
          handleSetTotal={handleSetTeamModuleBlastTotal}
          params={params}
        />
        )}
        {isSearchBucket && (
        <ListResult
          list={list}
          onClose={onClose}
          keywordArray={keywordArray}
          type="buckets"
          title="List Dokumen & File"
          handleSetTotal={handleSetTeamModuleDocsFilesTotal}
          params={params}
        />
        )}
        {isSearchEvent && (
        <ListResult
          list={list}
          onClose={onClose}
          keywordArray={keywordArray}
          type="schedules"
          title="List Jadwal"
          handleSetTotal={handleSetTeamModuleScheduleTotal}
          params={params}
        />
        )}
        {isSearchGroupChat && (
        <ListResult
          list={list}
          onClose={onClose}
          keywordArray={keywordArray}
          type="groupChats"
          title="Chat Grup"
          handleSetTotal={handleSetTeamModuleGroupChatTotal}
          params={params}
        />
        )}
        {isSearchQuestion && (
        <ListResult
          list={list}
          onClose={onClose}
          keywordArray={keywordArray}
          type="checkIns"
          title="List Pertanyaan"
          handleSetTotal={handleSetTeamModuleCheckInTotal}
          params={params}
        />
        )}
        {isSearchCard && (
        <ListResultCard
          onClose={onClose}
          keywordArray={keywordArray}
          debouncedKeyword={debouncedKeyword}
          title="Tugas"
          params={params}
          handleSetTotal={handleSetCardTotal}
          filters={filters}
        />
        )}
        {isSearchPost && (
        <ListResultPost
          onClose={onClose}
          keywordArray={keywordArray}
          debouncedKeyword={debouncedKeyword}
          title="Pengumuman"
          params={params}
          handleSetTotal={handleSetPostTotal}
          filters={filters}
        />
        )}
        {isSearchDoc && (
        <ListResultDoc
          onClose={onClose}
          keywordArray={keywordArray}
          debouncedKeyword={debouncedKeyword}
          title="Dokumen"
          params={params}
          handleSetTotal={handleSetDocTotal}
          filters={filters}
        />
        )}
        {isSearchEvent && (
        <ListResultEvent
          onClose={onClose}
          keywordArray={keywordArray}
          debouncedKeyword={debouncedKeyword}
          title="Jadwal"
          params={params}
          handleSetTotal={handleSetEventTotal}
          filters={filters}
        />
        )}
        {isSearchFile && (
        <ListResultFile
          onClose={onClose}
          keywordArray={keywordArray}
          debouncedKeyword={debouncedKeyword}
          title="File"
          params={params}
          handleSetTotal={handleSetFileTotal}
          filters={filters}
        />
        )}
        {isSearchBucket && (
        <ListResultBucket
          onClose={onClose}
          keywordArray={keywordArray}
          debouncedKeyword={debouncedKeyword}
          title="Folder"
          params={params}
          handleSetTotal={handleSetBucketTotal}
          filters={filters}
        />
        )}
        {isSearchQuestion && (
        <ListResultQuestion
          onClose={onClose}
          keywordArray={keywordArray}
          debouncedKeyword={debouncedKeyword}
          title="Pertanyaan"
          params={params}
          handleSetTotal={handleSetQuestionTotal}
          filters={filters}
        />
        )}
        {isSearchComment && (
        <ListResultComment
          onClose={onClose}
          keywordArray={keywordArray}
          debouncedKeyword={debouncedKeyword}
          title="Komentar"
          params={params}
          handleSetTotal={handleSetCommentTotal}
          filters={filters}
        />
        )}
        {isSearchComment && (
        <ListResultDiscussion
          onClose={onClose}
          keywordArray={keywordArray}
          debouncedKeyword={debouncedKeyword}
          title="Diskusi"
          params={params}
          handleSetTotal={handleSetDiscussionTotal}
          filters={filters}
        />
        )}
        {isSearchPrivateChat && (
        <ListResultPrivateMessage
          onClose={onClose}
          keywordArray={keywordArray}
          debouncedKeyword={debouncedKeyword}
          title="Pesan Pribadi"
          params={params}
          handleSetTotal={handleSetPrivateMessageTotal}
          filters={filters}
        />
        )}
        {isSearchGroupChat && (
        <ListResultGroupMessage
          onClose={onClose}
          keywordArray={keywordArray}
          debouncedKeyword={debouncedKeyword}
          title="Pesan Chat Grup"
          params={params}
          handleSetTotal={handleSetGroupMessageTotal}
          filters={filters}
        />
        )}
        {allTotal < 1 && <DisplayTextBody position="center">Ga ketemu yang kamu cari</DisplayTextBody>}
      </div>
      )}
    </Box>
  );
};

SearchResults.propTypes = {
  list: PropTypes.array.isRequired,
  onClose: PropTypes.func.isRequired,
  keywordArray: PropTypes.array.isRequired,
  // keyword: PropTypes.string.isRequired,
  params: PropTypes.object,
  debouncedKeyword: PropTypes.string.isRequired,
  isTyping: PropTypes.bool.isRequired,
};

SearchResults.defaultProps = {
  params: { companyId: null, teamId: null },
};

export default memo(SearchResults);
