import React, { useState, useContext, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import PropTypes from 'prop-types';
import { useSnackbar } from 'notistack';
import { GlobalContext } from '../../../../contexts/GlobalStateProvider';
import styles from './ListNameOption.module.css';
import handleStatusMsg from '../../../../utilities/handleStatusMsg';
import handleLoadings from '../../../../utilities/handleLoadings';
import OverlayButton from '../../../../components/UI/Button/OverlayButton/OverlayButton';
import ButtonDiv from '../../../../components/UI/Button/ButtonDiv/ButtonDiv';
import { ListActions } from '../../../../actions';

const ListNameOption = ({
  cancel, handleUpdateDestListId, handleUpdateViewListName, lists,
}) => {
  const [{ currentBoardLists, currentBoard }, dispatch] = useContext(GlobalContext);
  const [loadings, setLoadings] = useState([]);
  const { enqueueSnackbar } = useSnackbar();
  const params = useParams();
  const { companyId, teamId } = params;

  const handleSelectList = (listId, listName) => {
    handleUpdateDestListId({
      droppableId: listId,
      index: 0,
    });

    handleUpdateViewListName({
      listName,
      listPosition: 1,
    });

    cancel();
  };

  return (
    <div className={styles.mainSection}>
      <OverlayButton wait="moveCardLists" loadings={loadings}>
        {lists?.map((list) => (
          <ButtonDiv
            onClick={() => {
              handleSelectList(list._id, list.name);
            }}
            className={styles.listSection}
          >
            <a>{list.name}</a>
          </ButtonDiv>
        ))}
      </OverlayButton>
    </div>
  );
};

ListNameOption.propTypes = {
  cancel: PropTypes.func.isRequired,
  handleUpdateDestListId: PropTypes.func.isRequired,
  handleUpdateViewListName: PropTypes.func.isRequired,
  lists: PropTypes.array.isRequired,
};

export default ListNameOption;
