import React, { memo, useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import styles from './TractionPage.module.css';
import { DisplayTextBody, DisplayTextSubHeadline } from '../../../components/UI_V2/Display/Text';
import Color from '../../../themes/colors';
import introImage from '../../../assets/PrivateChatPage/intro-chat-section.png';
import { LayoutMain } from '../../../components/UI_V2/Layout';
import { SurfacePaperMain } from '../../../components/UI_V2/Surface/Paper';
import Header from '../Header/Header';
import ChartTractionList from './ChartTractionList/ChartTractionList';
import { dateBeautifyFormatter } from '../../../utilities/dateUtil';
import TextInfo from '../CompanyDetailPage/CompanyInfo/DetailInfoBox/TextInfo/TextInfo';
import TractionInfo from './TractionInfo/TractionInfo';

const TractionPage = () => {
  const [headerText, setHeaderText] = useState({
    title: 'Traction',
    desc: 'How to track is company heading to success or not',
  });

  const handleSetHeaderText = useCallback((values) => {
    const { fromDate, untilDate } = values;

    setHeaderText({
      title: headerText.title,
      desc: `Period : ${dateBeautifyFormatter(fromDate, true)} - ${dateBeautifyFormatter(untilDate, true)}`,
    });
  }, []);

  return (
    <LayoutMain>
      <SurfacePaperMain>
        <div className={styles.container}>
          <div className={styles.header}>
            <Header
              title={headerText.title}
              desc={headerText.desc}
            />
          </div>
          <div className={styles.body}>
            <div className={styles.chartTractionList}>
              <DisplayTextSubHeadline>
                Progress
              </DisplayTextSubHeadline>
              <ChartTractionList
                handleSetHeaderText={handleSetHeaderText}
              />
            </div>
            <div className={styles.information}>
              <TractionInfo />
            </div>
          </div>
        </div>
      </SurfacePaperMain>
    </LayoutMain>
  );
};

TractionPage.propTypes = {};

TractionPage.defaultProps = {};

export default memo(TractionPage);
