import React, { memo, useContext } from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import Tooltip from '@mui/material/Tooltip';
import OneSignal from 'react-onesignal';
import ExitToApp from '@mui/icons-material/ExitToApp';
import { useMediaQuery } from '@mui/material';
import { GlobalContext } from '../../../contexts/GlobalStateProvider';
import styles from './LogoHeader.module.css';
import logoImage from '../../../assets/Logo/logo-new.png';
import IconItem from '../../../pages/UserMenuContainer/IconItem/IconItem';
import { UserActions } from '../../../actions';
import { useLogoutUser } from '../../../hooks/UserHooks';
import { InputButtonLogout } from '../../UI_V2/Input/Button';

const LogoHeader = ({ showLogout, url }) => {
  const history = useHistory();
  const isMobile = useMediaQuery('(max-width:720px)');

  const { handleLogout, isLoadingLogout } = useLogoutUser();

  return (
    <>
      <LazyLoadImage
        onClick={() => {
          history.push(url);
        }}
        className={isMobile ? `${styles.LogoHeader} ${styles.LogoHeaderMobile}` : styles.LogoHeader}
        alt="logo-header"
        src={logoImage}
      />
      {showLogout ? (
        <div className={isMobile ? `${styles.logoutIcon} ${styles.logoutIconMobile}` : styles.logoutIcon}>
          <InputButtonLogout handleLogout={handleLogout} isLoadingLogout={isLoadingLogout} />
        </div>
      ) : null}
    </>
  );
};

LogoHeader.propTypes = {
  showLogout: PropTypes.bool,
  url: PropTypes.string,
};

LogoHeader.defaultProps = {
  showLogout: false,
  url: '/',
};

export default memo(LogoHeader);
