const limitReport = 10;

const typeRef = {
  backlog: 'backlog',
  ongoing: 'ongoing',
  overdue: 'overdue',
  completed: 'completed',
};

const categoryRef = {
  good: 'good',
  normal: 'normal',
  bad: 'bad',
};

const titleRealtimePeriod = {
  thisWeek: 'Minggu Ini',
  thisMonth: 'Bulan Ini',
  thisTwoWeek: '2 Minggu Ini',
};

export {
  limitReport,
  typeRef,
  categoryRef,
  titleRealtimePeriod,
};
