// deprecated, use string util instead
function extractElementBySelectorFromString(HTMLString, search) {
  const domDoc = new DOMParser().parseFromString(HTMLString, 'text/html');

  const domResults = domDoc.querySelectorAll(search);
  const finalResults = [];
  domResults.forEach((el) => {
    const { mentionedUserId } = el.dataset;
    if (!finalResults.includes(mentionedUserId)) finalResults.push(mentionedUserId);
  });
  return finalResults;
}

export default extractElementBySelectorFromString;
