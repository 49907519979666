/* eslint-disable consistent-return */
import { useCallback } from 'react';
import { ChatActions } from '../actions';
import { ChatConstants } from '../constants';
import { connectSocketPrivateChat, connectSocketRecentChats } from '../services/socket';
import { useSocketHooks } from './SocketHooks';

function usePrivateMessageSocket({ chatId, userId }, dispatch) {
  const callbackNewMessage = useCallback((message) => {
    ChatActions.incomingChat({
      message,
      categoryChat: ChatConstants.categoryChat.PRIVATE,
      typeAction: ChatConstants.typeCallback.NEW,
    }, dispatch);
  }, []);
  const callbackDeleteMessage = useCallback((message) => {
    ChatActions.incomingChat({
      message,
      categoryChat: ChatConstants.categoryChat.PRIVATE,
      typeAction: ChatConstants.typeCallback.DELETE,
    }, dispatch);
  }, []);
  const callbackUpdateMessage = useCallback((message) => {
    ChatActions.incomingChat({
      message,
      categoryChat: ChatConstants.categoryChat.PRIVATE,
      typeAction: ChatConstants.typeCallback.EDIT,
    }, dispatch);
  }, []);

  const listenSocket = (socket, params) => {
    socket
      .on(`chat-new-${params.chatId}`, callbackNewMessage)
      .on(`chat-delete-${params.chatId}`, callbackDeleteMessage)
      .on(`chat-update-${params.chatId}`, callbackUpdateMessage);
  };
  const removeListener = (socket, params) => {
    socket
      .off(`chat-new-${params.chatId}`)
      .off(`chat-delete-${params.chatId}`)
      .off(`chat-update-${params.chatId}`);
    socket.disconnect();
  };

  useSocketHooks({
    params: { chatId, userId },
    connectSocket: connectSocketPrivateChat,
    listenSocket,
    removeListener,
  });
}

function useRecentChatsSocket({ companyId, userId }, dispatch) {
  const callbackNewMessage = useCallback(({ chatId, lastMessage }) => {
    ChatActions.manipulateRecentChats({
      chatId,
      lastMessage,
      typeAction: ChatConstants.typeCallback.NEW,
    }, dispatch);
  }, []);
  const callbackDeleteMessage = useCallback(({ chatId, lastMessage, isLatestMessage }) => {
    ChatActions.manipulateRecentChats({
      chatId,
      lastMessage,
      isLatestMessage,
      typeAction: ChatConstants.typeCallback.DELETE,
    }, dispatch);
  }, []);

  const listenSocket = (socket, params) => {
    socket
      .on('chat-new', callbackNewMessage)
      .on('chat-delete', callbackDeleteMessage);
  };
  const removeListener = (socket, params) => {
    socket
      .off('chat-new')
      .off('chat-delete');
    socket.disconnect();
  };

  useSocketHooks({
    params: { companyId, userId },
    connectSocket: connectSocketRecentChats,
    listenSocket,
    removeListener,
  });
}

export {
  usePrivateMessageSocket,
  useRecentChatsSocket,
};
