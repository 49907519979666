import React, {
  memo, useCallback, useContext, useEffect, useState,
} from 'react';
import PropTypes from 'prop-types';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { useParams, useHistory, useLocation } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import styles from './DataRecapMonthWeeklyReport.module.css';
import Color from '../../../themes/colors';
import RatingAverageSection from './RatingAverageSection/RatingAverageSection';
import RatingRecapSection from './RatingRecapSection/RatingRecapSection';
import CountCardRecapSection from './CountCardRecapSection/CountCardRecapSection';
import CardSnapshotRecapSection from './CardSnapshotRecapSection/CardSnapshotRecapSection';
import SuggestionRecapSection from './SuggestionRecapSection/SuggestionRecapSection';
import RecapHeaderSection from './RecapHeaderSection/RecapHeaderSection';
import CardBottleneckRecapSection from './CardBottleneckRecapSection/CardBottleneckRecapSection';
import handleLoadings from '../../../utilities/handleLoadings';
import { ReportRecapActions } from '../../../actions';
import handleStatusMsg from '../../../utilities/handleStatusMsg';
import { getFirstAndLastDateByMonth } from '../../../utilities/dateUtil';
import { FeedbackLoadingMain } from '../../../components/UI_V2/Feedback/Loading';
import { GlobalContext } from '../../../contexts/GlobalStateProvider';

const DataRecapMonthWeeklyReport = ({
  month,
  year,
  member,
}) => {
  const [{ currentTeam }] = useContext(GlobalContext);
  const [loadings, setLoadings] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [
    currentReportRecapMonthWeeklyCardMemberData,
    setCurrentReportRecapMonthWeeklyCardMemberData,
  ] = useState({});
  const [selectedWeek, setSelectedWeek] = useState();

  const handleSetSelectedWeek = useCallback((value) => {
    setSelectedWeek(value);
  }, []);

  useEffect(() => {
    if (!currentReportRecapMonthWeeklyCardMemberData.ratingFinal) return;
    handleSetSelectedWeek(currentReportRecapMonthWeeklyCardMemberData?.listReport?.[0]?.week);
  }, [currentReportRecapMonthWeeklyCardMemberData]);

  const [fromDate, untilDate] = getFirstAndLastDateByMonth(month, year);

  const params = useParams();
  const history = useHistory();
  const location = useLocation();
  const {
    companyId, teamId, memberId,
  } = params;

  const { enqueueSnackbar } = useSnackbar();

  const initiateReportRecapMonthWeeklyCardMemberCountApi = async () => {
    try {
      setIsLoading(true);
      const startLoadings = handleLoadings('initiateReportRecapMonthWeeklyCardMemberCount', [...loadings], 'start');
      setLoadings([...startLoadings]);

      const result = await ReportRecapActions.initiateReportRecapMonthWeeklyCardMemberCount({
        teamId,
        memberId,
        companyId,
        month,
        year,
        setCurrentReportRecapMonthWeeklyCardMemberData,
      });
    } catch (err) {
      const status = handleStatusMsg(err, 'error');

      enqueueSnackbar(status.message, {
        variant: 'error',
      });
    } finally {
      setIsLoading(false);
      const endLoadings = handleLoadings('initiateReportRecapMonthWeeklyCardMemberCount', [...loadings], 'end');
      setLoadings([...endLoadings]);
    }
  };

  useEffect(() => {
    initiateReportRecapMonthWeeklyCardMemberCountApi();
  }, [location, month, year]);

  const checkListReportExist = currentReportRecapMonthWeeklyCardMemberData?.listReport?.filter(
    (elem) => elem?.count?.backlog,
  );

  const isEmptyData = checkListReportExist && checkListReportExist.length < 1;

  return (
    <div className={styles.container}>
      {/* <FeedbackLoadingMain
        wait="initiateReportRecapMonthWeeklyCardMemberCount"
        loadings={loadings}
        useSpinner
      > */}
      <div className={styles.headerSection}>
        <RecapHeaderSection
          member={member}
          year={year}
          month={month}
          team={currentTeam}
          isLoading={isLoading}
        />
      </div>
      <div className={styles.topSection}>
        <RatingAverageSection
          member={member}
          rating={currentReportRecapMonthWeeklyCardMemberData.ratingFinal}
          isEmptyData={isEmptyData}
          isLoading={isLoading}
          fromDate={fromDate}
          untilDate={untilDate}
        />
        <SuggestionRecapSection
          isEmptyData={isEmptyData}
          isLoading={isLoading}
          suggestionText={currentReportRecapMonthWeeklyCardMemberData.suggestionFinal}
        />
      </div>
      <div className={styles.middleSection}>
        <RatingRecapSection
          listReport={currentReportRecapMonthWeeklyCardMemberData.listReport}
          isLoading={isLoading}
          ratingRecapInterpretation={
                currentReportRecapMonthWeeklyCardMemberData.ratingProgressInterpretation
              }
        />
        <CountCardRecapSection
          listReport={currentReportRecapMonthWeeklyCardMemberData.listReport}
          isLoading={isLoading}
          cardRecapInterpretation={
                currentReportRecapMonthWeeklyCardMemberData.cardProgressInterpretation
              }
        />
      </div>
      <div className={styles.bottomSection}>
        <CardSnapshotRecapSection
          listReport={currentReportRecapMonthWeeklyCardMemberData.listReport}
          isLoading={isLoading}
          selectedWeek={selectedWeek}
          handleSetSelectedWeek={handleSetSelectedWeek}
          year={year}
        />
        <CardBottleneckRecapSection
          listReport={currentReportRecapMonthWeeklyCardMemberData.listReport}
          isLoading={isLoading}
          week={selectedWeek}
          handleSetSelectedWeek={handleSetSelectedWeek}
          year={year}
        />
      </div>
      {/* </FeedbackLoadingMain> */}
    </div>
  );
};

DataRecapMonthWeeklyReport.propTypes = {
  month: PropTypes.number.isRequired,
  year: PropTypes.number.isRequired,
  member: PropTypes.object.isRequired,
};

DataRecapMonthWeeklyReport.defaultProps = {};

export default memo(DataRecapMonthWeeklyReport);
