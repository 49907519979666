import { Box } from '@mui/material';
import CheckIcon from '@mui/icons-material/Check';
import React, { memo } from 'react';
import PropTypes from 'prop-types';
import styles from './ButtonTextWithCheck.module.css';
import ButtonDiv from '../../../UI/Button/ButtonDiv/ButtonDiv';
import { DisplayTextBody } from '../../../UI_V2/Display/Text';

const ButtonTextWithCheck = ({ onClick, checked, text }) => (
  <ButtonDiv onClick={onClick} className={styles.menu}>
    <Box className={styles.text}>
      <DisplayTextBody>
        {text}
      </DisplayTextBody>
      {' '}
      {checked && <CheckIcon />}
    </Box>
  </ButtonDiv>
);

ButtonTextWithCheck.propTypes = {
  onClick: PropTypes.func,
  checked: PropTypes.bool,
  text: PropTypes.string.isRequired,
};

ButtonTextWithCheck.defaultProps = {
  onClick: () => null,
  checked: false,
};

export default memo(ButtonTextWithCheck);
