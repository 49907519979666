import React, { memo, useState } from 'react';
import PropTypes from 'prop-types';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import styles from './AdditionalInfoAcquisition.module.css';
import { DisplayTextBody } from '../../../../../../components/UI_V2/Display/Text';
import Color from '../../../../../../themes/colors';
import TextInfo from '../../../../CompanyDetailPage/CompanyInfo/DetailInfoBox/TextInfo/TextInfo';
import { dateBeautifyFormatter } from '../../../../../../utilities/dateUtil';

const AdditionalInfoAcquisition = ({
  item,
}) => {
  const isOnTrial = item?.isOnTrial;
  return (
    <div className={styles.container}>
      {isOnTrial && (
      <TextInfo
        title="Start Trial Date"
        text={dateBeautifyFormatter(item?.additionalInfo.startTrialDate)}
      />
      )}
      {!isOnTrial && (
      <>
        <TextInfo
          title="Login Date"
          text={dateBeautifyFormatter(item?.metricDate)}
        />
        <TextInfo
          title="User Name"
          text={item?.additionalInfo?.userFullName}
        />
        <TextInfo
          title="Title"
          text={item?.additionalInfo?.userStatus}
        />
        <TextInfo
          title="Role"
          text={item?.additionalInfo?.userRole}
        />
      </>
      )}
    </div>
  );
};

AdditionalInfoAcquisition.propTypes = {
  item: PropTypes.object.isRequired,
};

AdditionalInfoAcquisition.defaultProps = {};

export default memo(AdditionalInfoAcquisition);
