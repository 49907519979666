import React, { memo } from 'react';
import PropTypes from 'prop-types';
import styles from './GroupChatPersonItem.module.css';
import { DisplayTextBody } from '../../../../../components/UI_V2/Display/Text';
import Color from '../../../../../themes/colors';
import limitChar from '../../../../../utilities/limitChar';
import { DisplayAvatarMemberWithOnline } from '../../../../../components/UI_V2/Display/Avatar';

const GroupChatPersonItem = ({
  photoUrl,
  fullName,
  userId,
  positionPopUpOnlineOnPhoto,
}) => {
  const limitFullName = limitChar(fullName, 20);

  return (
    <div
      className={styles.container}
    >
      <div className={styles.photo}>
        <DisplayAvatarMemberWithOnline
          userId={userId}
          src={photoUrl}
          size="sm"
          positionPopUp={positionPopUpOnlineOnPhoto}
        />
      </div>
      <div className={styles.text}>
        <DisplayTextBody
          mode="12"
          color={Color.gray3}
        //   decoration="bold"
        >
          {limitFullName}
        </DisplayTextBody>
      </div>
    </div>
  );
};

GroupChatPersonItem.propTypes = {
  photoUrl: PropTypes.string,
  fullName: PropTypes.string,
  userId: PropTypes.string.isRequired,
  positionPopUpOnlineOnPhoto: PropTypes.oneOf(['topRight', 'topLeft', 'bottomRight', 'bottomLeft']),
};

GroupChatPersonItem.defaultProps = {
  photoUrl: '',
  fullName: '',
  positionPopUpOnlineOnPhoto: 'topLeft',
};

export default memo(GroupChatPersonItem);
