import React, {
  useState, useContext, useEffect,
} from 'react';
import { useHistory, useParams, useLocation } from 'react-router-dom';
import GroupsOutlinedIcon from '@mui/icons-material/GroupsOutlined';
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined';
import _ from 'lodash';
import { actionTypes } from '../../reducers/reducer';
import { GlobalContext } from '../../contexts/GlobalStateProvider';
import Title from '../../components/Title/Title';
import handleLoadings from '../../utilities/handleLoadings';
import PageLoading from '../../components/UI/Button/PageLoading/PageLoading';
import handleStatusMsg from '../../utilities/handleStatusMsg';
import { CompanyActions, SearchJumpToActions } from '../../actions';
import GeneralSubNavBar from '../../components/GeneralSubNavBar/GeneralSubNavBar';
import GlobalActionButton from '../../components/GeneralSubNavBar/BottomNavBar/GlobalActionButton/GlobalActionButton';
import { generateRoutesAndTitle } from '../../actions/BreadCrumbActions';
import TabEditCompanyContainer from './TabEditCompanyContainer/TabEditCompanyContainer';
import TabInviteMemberContainer from './TabInviteMemberContainer/TabInviteMemberContainer';
import LinkNoDecor from '../../components/LinkNoDecor/LinkNoDecor';
import { PageConstants } from '../../constants';
import { checkIsUserAdmin } from '../../actions/UserActions';
import { useInitiateRoleUser } from '../../hooks/RoleHooks';
import { hasPermission } from '../../actions/RoleActions';
import { role, roles } from '../../constants/RoleConstants';

function CompanyPage() {
  const [{
    user, currentCompanies, currentCompany, currentRoleUser,
  }, dispatch] = useContext(GlobalContext);
  const [loadings, setLoadings] = useState([]);

  const params = useParams();
  const history = useHistory();
  const location = useLocation();
  const { companyId, tabId } = params;

  useInitiateRoleUser({ });

  useEffect(() => {
    if (user._id === undefined) {
      history.push(`/check-login?previousPath=${location.pathname}`);
      return;
    }
    if (currentCompanies.length < 1) {
      const fetchCompanyApi = async () => {
        try {
          const startLoadings = handleLoadings('companyPage', [...loadings], 'start');
          setLoadings([...startLoadings]);

          const result = await CompanyActions.getAndSaveCompanyAndLastOpenedCompany(
            { companyId, companies: currentCompanies, userId: user?._id }, dispatch,
          );
        } catch (err) {
          const status = handleStatusMsg(err, 'error');

          dispatch({
            type: actionTypes.SET_ERROR_RESPONSE,
            errorResponse: { message: status.message },
          });

          history.push(`/errors?previousPath=${location.pathname}`);
        } finally {
          const endLoadings = handleLoadings('companyPage', [...loadings], 'end');
          setLoadings([...endLoadings]);
        }
      };
      fetchCompanyApi();
    }
  }, []);

  const companyType = params.tabId === 'edit' ? PageConstants.pageType.companySettings : PageConstants.pageType.companyMembers;

  const breadcrumb = generateRoutesAndTitle({ type: companyType, data: { currentCompany } });

  SearchJumpToActions.saveRecentPage({
    company: currentCompany,
    userId: user?._id,
    itemId: `${currentCompany?._id}-${companyType}`,
    url: breadcrumb.url,
    routes: breadcrumb.routes,
    name: breadcrumb.title,
    type: companyType,
  });

  const isUserAdminOrAbove = hasPermission(currentRoleUser, roles.adminOrAbove);
  const isUserSpvOrAbove = hasPermission(currentRoleUser, roles.spvOrAbove);
  const isUserSuper = hasPermission(currentRoleUser, role.super);

  return (
    <>
      <Title title={currentCompany === undefined ? 'Perusahaan' : currentCompany.name} />
      <GeneralSubNavBar
        breadcrumbRoutes={breadcrumb.routes}
        pageTitle={breadcrumb.title}
        bottomBarOverviewOptionId={null}
        withMiddleNavBar={false}
        bottomBarGlobalActions={(
          <>
            <LinkNoDecor to={`/companies/${params.companyId}/settings/edit`}>
              <GlobalActionButton
                buttonMode={params.tabId === 'edit' ? 'active' : 'passive'}
                text="Pengaturan"
                icon={<SettingsOutlinedIcon />}
              />
            </LinkNoDecor>
            <LinkNoDecor to={`/companies/${params.companyId}/settings/members`}>
              <GlobalActionButton
                buttonMode={tabId === 'members' ? 'active' : 'passive'}
                text="Anggota"
                icon={<GroupsOutlinedIcon />}
              />
            </LinkNoDecor>
            {isUserSpvOrAbove && (
            <LinkNoDecor to={`/companies/${params.companyId}/billings`}>
              <GlobalActionButton
                buttonMode="passive"
                text="Tagihan"
                icon={PageConstants.pageProperties.billings.icon}
              />
            </LinkNoDecor>
            )}
          </>
        )}
      />
      <PageLoading wait="companyPage" loadings={loadings}>

        {tabId === 'members'
          ? (
            <>
              {_.isEmpty(currentCompany)
                ? null
                : (
                  <TabInviteMemberContainer />
                ) }
            </>
          )
          : null}
        {tabId === 'edit'
          ? (
            <>
              {_.isEmpty(currentCompany)
                ? null
                : (
                  <TabEditCompanyContainer
                    isUserSuper={isUserSuper}
                    isUserAdminOrAbove={isUserAdminOrAbove}
                    company={currentCompany}
                  />
                ) }
            </>
          )
          : null}
      </PageLoading>
    </>
  );
}

export default CompanyPage;
