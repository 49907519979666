import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { Tooltip } from '@mui/material';
import styles from './BadgeMetric.module.css';
import { DisplayTextBody } from '../../../../../../components/UI_V2/Display/Text';
import { roleTitle, role } from '../../../../../../constants/RoleConstants';
import Color from '../../../../../../themes/colors';

const badgeStyles = {
  visitor: styles.visitor,
  acquisition: styles.acquisition,
  activation: styles.activation,
  retention: styles.retention,
  revenue: styles.revenue,
  referral: styles.referral,
  churn: styles.churn,
};

const colorTextStyles = {
  visitor: Color.white,
  acquisition: Color.white,
  activation: Color.white,
  retention: Color.white,
  revenue: Color.fontColor,
  referral: Color.fontColor,
  churn: Color.fontColor,
};

const badgeTitleStyles = {
  visitor: 'Visitor',
  acquisition: 'Acquisition',
  activation: 'Activation',
  retention: 'Retention',
  revenue: 'Revenue',
  referral: 'Referral',
  churn: 'Churn',
};

const BadgeMetric = ({
  category,
}) => (
  <div className={`${styles.container} ${badgeStyles[category]}`}>
    <DisplayTextBody
      color={colorTextStyles[category]}
    >
      {badgeTitleStyles[category]}
    </DisplayTextBody>
  </div>
);

BadgeMetric.propTypes = {
  category: PropTypes.string.isRequired,
};

export default memo(BadgeMetric);
