import React, { memo } from 'react';
import PropTypes from 'prop-types';
import styles from './BoxPopOver.module.css';

function BoxPopOver({
  left, big, children, customStyle,
}) {
  let usedClasses;

  if (left && big) {
    usedClasses = `${styles.BoxPopOver} ${styles.BoxPopOver__left} ${styles.BoxPopOver__big}`;
  } else if (left) {
    usedClasses = `${styles.BoxPopOver} ${styles.BoxPopOver__left}`;
  } else if (big) {
    usedClasses = `${styles.BoxPopOver} ${styles.BoxPopOver__big}`;
  } else {
    usedClasses = styles.BoxPopOver;
  }

  return (
    <div className={usedClasses} style={customStyle}>
      {children}
    </div>
  );
}

BoxPopOver.propTypes = {
  left: PropTypes.bool,
  big: PropTypes.bool,
  customStyle: PropTypes.object,
  children: PropTypes.node.isRequired,
};

BoxPopOver.defaultProps = {
  left: false,
  big: false,
  customStyle: {},
};

export default memo(BoxPopOver);
