/* eslint-disable jsx-a11y/media-has-caption */
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useMediaQuery } from '@mui/material';
import PlayCircleIcon from '@mui/icons-material/PlayCircle';
import styles from './FileSnapshot.module.css';
import PrivateIcon from '../../../../components/UI/PrivateIcon/PrivateIcon';
import { AttachmentActions } from '../../../../actions';
import PdfThumbnail from '../../../../components/PdfThumbnail/PdfThumbnail';
import VideoPlayerThumbnail from '../../../../components/VideoPlayerThumbnail/VideoPlayerThumbnail';

const FileSnapshot = ({ file }) => {
  const isMobile = useMediaQuery('(max-width:720px)');
  const [title, setTitle] = useState();
  const [imageUrl, setImageUrl] = useState();
  const [isImage, setIsImage] = useState();
  const [isVideo, setIsVideo] = useState();
  const [isPdf, setIsPdf] = useState();

  useEffect(() => {
    if (file.title === undefined) return;

    let tempTitle;
    if (file.title.length < 40) {
      tempTitle = file.title;
    } else {
      tempTitle = `${file.title.slice(0, 40)}...`;
    }

    setTitle(tempTitle);

    const fileUrl = file.url?.charAt(0) === '/'
      ? process.env.REACT_APP_PRIMARY_API_URL + file.url
      : file.url;
    const result = AttachmentActions.getMimeTypeUrl(
      { mimeType: file.mimeType, fileUrl },
    );

    setImageUrl(result?.imageUrl);
    setIsImage(result?.isImage);
    setIsVideo(result?.isVideo);
    setIsPdf(result?.isPdf);
  }, [file]);

  return (
    <div className={styles.main}>
      <div className={styles.main__icon}>
        {(!isVideo && !isPdf) && (<img src={imageUrl} />)}
        {isVideo
        && (
          <VideoPlayerThumbnail
            videoUrl={imageUrl}
            variant="fileSnapshot"
          />
        )}
        {isPdf && (
        <PdfThumbnail fileUrl={imageUrl} height={40} />
        )}
      </div>
      <div className={styles.main__title}>
        <h1>
          <PrivateIcon data={file} size="small" />
          {' '}
          {title}
        </h1>
      </div>
    </div>
  );
};

FileSnapshot.propTypes = {
  file: PropTypes.object.isRequired,
};

export default FileSnapshot;
