import React, { memo, useContext, useState } from 'react';
import PropTypes from 'prop-types';
import ArrowLeftIcon from '@mui/icons-material/ArrowLeft';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { Tooltip } from '@mui/material';
import styles from './WidgetSection.module.css';
import Color from '../../../themes/colors';
import { DisplayTextBody, DisplayTextHeadline } from '../../../components/UI_V2/Display/Text';
import WidgetRecentlyVisit from './WidgetRecentlyVisit/WidgetRecentlyVisit';
import WidgetMyTaskCardAll from './WidgetMyTaskCardAll/WidgetMyTaskCardAll';
import { GlobalContext } from '../../../contexts/GlobalStateProvider';
import { dateBeautifyFormatter, getGreeting } from '../../../utilities/dateUtil';
import { getStoreWidgetMyTaskIsOpen, setStoreWidgetMyTaskIsOpen } from '../../../utilities/localStorage';

const itemWithSidebarOpenStyles = {
  false: `${styles.item} ${styles.itemSidebarOpen}`,
  true: styles.item,
};

const WidgetSection = () => {
  const [{ user, currentCompany, isSidebarOpen }] = useContext(GlobalContext);
  const initialIsOpen = getStoreWidgetMyTaskIsOpen({
    userId: user?._id,
    companyId: currentCompany?._id,
  });

  const [isOpen, setIsOpen] = useState(initialIsOpen !== null ? initialIsOpen : true);

  const onClickIsOpen = () => {
    setIsOpen(!isOpen);
    setStoreWidgetMyTaskIsOpen({
      userId: user?._id,
      companyId: currentCompany?._id,
      widgetMyTaskIsOpen: !isOpen,
    });
  };
  return (
    <div className={styles.container}>
      <Tooltip title={isOpen ? 'Sembunyikan Tugas Saya & Terakhir Dilihat' : 'Tampilkan Tugas Saya & Terakhir Dilihat'}>
        <div className={styles.openWidgetIcon} onClick={onClickIsOpen}>
          {isOpen && <ArrowDropDownIcon />}
          {!isOpen && (
          <ArrowLeftIcon />
          )}
        </div>
      </Tooltip>
      {isOpen && (
      <div className={styles.widget}>
        <div className={itemWithSidebarOpenStyles[isSidebarOpen]}>
          <WidgetMyTaskCardAll userId={user?._id} companyId={currentCompany?._id} />
        </div>
        <div className={itemWithSidebarOpenStyles[isSidebarOpen]}>
          <WidgetRecentlyVisit />
        </div>
      </div>
      )}
    </div>
  );
};

WidgetSection.propTypes = {};

WidgetSection.defaultProps = {};

export default memo(WidgetSection);
