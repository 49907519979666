import React from 'react';
import PropTypes from 'prop-types';
import {
  NavLink,
} from 'react-router-dom';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import HomeWorkOutlinedIcon from '@mui/icons-material/HomeWorkOutlined';
import CorporateFareOutlinedIcon from '@mui/icons-material/CorporateFareOutlined';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import useMediaQuery from '@mui/material/useMediaQuery';
import styles from './BreadcrumbContainer.module.css';
import limitChar from '../../../utilities/limitChar';

const BreadcrumbContainer = ({ routes }) => {
  const isMobile = useMediaQuery('(max-width:720px)');

  return (
    <div
      className={styles.breadCrumbSection}
    >
      <Breadcrumbs className={styles.breadCrumb} separator={<ArrowForwardIosIcon />} aria-label="breadcrumb">
        {routes.map((route) => {
          const name = limitChar(route.name, isMobile ? 16 : 25);
          return (
            <div>
              {route.pathname === '#'
                ? (
                  <div className={styles.separateRoute}>
                    {route.label === 'team' ? <HomeWorkOutlinedIcon /> : null}
                    {route.label === 'company' ? <CorporateFareOutlinedIcon /> : null}
                    <span>
                      {name}
                    </span>
                  </div>
                ) : (
                  <NavLink
                    className={styles.breadcrumbItem}
                    key={route.pathname}
                    to={name === '...' ? '' : route.pathname}
                    draggable={false}
                  >
                    {/* {name === 'Home' ? <HomeOutlinedIcon /> : null} */}
                    {route.label === 'team' ? <HomeWorkOutlinedIcon /> : null}
                    {route.label === 'company' ? <CorporateFareOutlinedIcon /> : null}
                    {name === 'Back' ? <ArrowBackIcon /> : null}
                    {name}
                  </NavLink>
                )}
            </div>
          );
        })}
      </Breadcrumbs>
    </div>
  );
};

BreadcrumbContainer.propTypes = {
  routes: PropTypes.array.isRequired,
};

export default BreadcrumbContainer;
