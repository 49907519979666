import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { Checkbox } from '@mui/material';
import styles from './Main.module.css';

const InputCheckboxMain = ({
  onChange, label, checked,
}) => (
  <div className={styles.container}>
    <Checkbox
      checked={checked}
      onChange={onChange}
      label={label}
    />
  </div>
);

InputCheckboxMain.propTypes = {
  checked: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
  label: PropTypes.string.isRequired,
};

InputCheckboxMain.defaultProps = {
  checked: false,
};
export default memo(InputCheckboxMain);
