import React, { memo, useState } from 'react';
import PropTypes from 'prop-types';
import { Box } from '@mui/material';
import CheckIcon from '@mui/icons-material/Check';
import { isEmpty } from 'lodash';
import styles from './TrialFilterSection.module.css';
import ButtonDiv from '../../../UI/Button/ButtonDiv/ButtonDiv';
import ButtonTextWithCheck from '../ButtonTextWithCheck/ButtonTextWithCheck';

const TrialFilterSection = ({ checkTrial, handleClickTrial }) => (
  <Box className={styles.container}>
    <ButtonTextWithCheck
      text="Trial"
      onClick={() => handleClickTrial(true)}
      checked={checkTrial === true}
    />
    <ButtonTextWithCheck
      text="Customer"
      onClick={() => handleClickTrial(false)}
      checked={checkTrial === false}
    />
    <ButtonTextWithCheck
      text="Both"
      onClick={() => handleClickTrial(undefined)}
      checked={checkTrial === undefined}
    />
  </Box>
);

TrialFilterSection.propTypes = {
  checkTrial: PropTypes.object.isRequired,
  handleClickTrial: PropTypes.func.isRequired,
};

export default memo(TrialFilterSection);
