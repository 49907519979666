import { cloneDeep } from 'lodash';
import { actionTypes } from '../reducers/reducer';
import { TeamActions } from '.';
import {
  mergeList,
  mergeObjectListAndRemoveDuplicate,
  removeItemArrayImmutable, updateListSocket,
} from '../utilities/arrayUtil';
import { getStoreRecentViewPages, setStoreRecentViewPages } from '../utilities/localStorage';
import { ApiConstants, PageConstants, SearchJumpToConstants } from '../constants';
import { ErrorException } from '../utilities/handleError';
import apiUtil, { getNextPage } from '../utilities/apiUtil';

/*
  Dispatcher
*/

function dispatchSearchList({ searchList }, dispatch) {
  dispatch({
    type: actionTypes.SET_SEARCH_LIST,
    searchList,
  });
}

function dispatchUpdateSearchList({ updateSearchList }, dispatch) {
  dispatch({
    type: actionTypes.UPDATE_SEARCH_LIST,
    updateSearchList,
  });
}

/*
  SetterDispatcher
*/

function setSearchList({ searchList }, dispatch) {
  if (!searchList) return;

  dispatchSearchList({ searchList: cloneDeep(searchList) }, dispatch);
}

/*
  Helper
*/
const getUrlItem = ({ companyId, item }) => {
  if (item.url) return item.url;
  let url;
  switch (item.type) {
    case 'cardComments':
      url = `/companies/${companyId}/teams/${item.teamId}/cards/${item.cardId}?commentId=${item._id}`;
      break;
    case 'cardDiscussions':
      url = `/companies/${companyId}/teams/${item.teamId}/cards/${item.cardId}/comments/${item.commentId}?commentId=${item._id}`;
      break;
    case 'postComments':
      url = `/companies/${companyId}/teams/${item.teamId}/posts/${item.postId}?commentId=${item._id}`;
      break;
    case 'postDiscussions':
      url = `/companies/${companyId}/teams/${item.teamId}/posts/${item.postId}/comments/${item.commentId}?commentId=${item._id}`;
      break;
    case 'questionComments':
      url = `/companies/${companyId}/teams/${item.teamId}/questions/${item.questionId}?commentId=${item._id}`;
      break;
    case 'questionDiscussions':
      url = `/companies/${companyId}/teams/${item.teamId}/questions/${item.questionId}/comments/${item.commentId}?commentId=${item._id}`;
      break;
    case 'docComments':
      url = `/companies/${companyId}/teams/${item.teamId}/docs/${item.docId}?commentId=${item._id}`;
      break;
    case 'docDiscussions':
      url = `/companies/${companyId}/teams/${item.teamId}/docs/${item.docId}/comments/${item.commentId}?commentId=${item._id}`;
      break;
    case 'fileComments':
      url = `/companies/${companyId}/teams/${item.teamId}/files/${item.fileId}?commentId=${item._id}`;
      break;
    case 'fileDiscussions':
      url = `/companies/${companyId}/teams/${item.teamId}/files/${item.fileId}/comments/${item.commentId}?commentId=${item._id}`;
      break;
    case 'eventComments':
      url = `/companies/${companyId}/teams/${item.teamId}/events/${item.eventId}?commentId=${item._id}`;
      break;
    case 'eventDiscussions':
      url = `/companies/${companyId}/teams/${item.teamId}/events/${item.eventId}/comments/${item.commentId}?commentId=${item._id}`;
      break;
    case 'occurrenceComments':
      url = `/companies/${companyId}/teams/${item.teamId}/events/${item.eventId}/occurrences/${item.occurrenceId}?commentId=${item._id}`;
      break;
    case 'occurrenceDiscussions':
      url = `/companies/${companyId}/teams/${item.teamId}/events/${item.eventId}/occurrences/${item.occurrenceId}/comments/${item.commentId}?commentId=${item._id}`;
      break;
    case 'groupChats':
      url = `/companies/${companyId}/teams/${item?.teamId}/group-chats/${item.groupChatId}?messageId=${item._id}`;
      break;
    case 'chats':
      url = `/companies/${companyId}/chats/${item.chatId}?messageId=${item._id}`;
      break;
    case 'blasts':
      url = `/companies/${companyId}/teams/${item?.teamId}/blasts/${item._id}`;
      break;
    case 'posts':
      url = `/companies/${companyId}/teams/${item?.teamId}/posts/${item._id}`;
      break;
    case 'checkIns':
      url = `/companies/${companyId}/teams/${item?.teamId}/check-ins/${item._id}`;
      break;
    case 'questions':
      url = `/companies/${companyId}/teams/${item?.teamId}/questions/${item._id}`;
      break;
    case 'schedules':
      url = `/companies/${companyId}/teams/${item?.teamId}/schedules/${item._id}`;
      break;
    case 'events':
      url = `/companies/${companyId}/teams/${item?.teamId}/events/${item._id}`;
      break;
    case 'occurrences':
      url = `/companies/${companyId}/teams/${item?.teamId}/events/${item.event}/occurrences/${item._id}`;
      break;
    case 'boards':
      url = `/companies/${companyId}/teams/${item?.teamId}/boards/${item._id}`;
      break;
    case 'boardsList':
      url = `/companies/${companyId}/teams/${item?.teamId}/boards/${item._id}/list/all`;
      break;
    case 'cards':
      url = `/companies/${companyId}/teams/${item?.teamId}/cards/${item._id}`;
      break;
    case 'buckets':
      url = `/companies/${companyId}/teams/${item?.teamId}/buckets/${item._id}`;
      break;
    case 'docs':
      url = `/companies/${companyId}/teams/${item?.teamId}/docs/${item._id}`;
      break;
    case 'files':
      url = `/companies/${companyId}/teams/${item?.teamId}/files/${item._id}`;
      break;
    default:
      url = `/companies/${companyId}/teams/${item._id}`;
      break;
  }
  return url;
};

const extractTeamsFeatureIdIntoList = ({ list, company, userId }) => {
  const newList = [];
  // eslint-disable-next-line no-restricted-syntax
  for (const item of list) {
    const groupChat = {
      _id: item?.groupChat,
      name: `Chat Grup: ${item.name}`,
      teamId: item._id,
      companyId: company._id,
      type: PageConstants.pageType.groupChats,
      url: `/companies/${company?._id}/teams/${item?._id}/group-chats/${item?.groupChat}`,
      routes: [
        {
          name: item.name,
        },
        {
          name: 'Chat Grup',
        },
      ],
    };
    const schedule = {
      _id: item?.schedule,
      name: `Kalender: ${item.name}`,
      teamId: item._id,
      companyId: company._id,
      type: PageConstants.pageType.schedules,
      url: `/companies/${company?._id}/teams/${item?._id}/schedules/${item?.schedule}`,
      routes: [
        {
          name: item.name,
        },
        {
          name: 'Kalender',
        },
      ],
    };
    const blast = {
      _id: item?.blast,
      name: `List Pengumuman: ${item.name}`,
      teamId: item._id,
      companyId: company._id,
      type: PageConstants.pageType.blasts,
      url: `/companies/${company?._id}/teams/${item?._id}/blasts/${item?.blast}`,
      routes: [
        {
          name: item.name,
        },
        {
          name: 'List Pengumuman',
        },
      ],
    };
    const checkIn = {
      _id: item?.checkIn,
      name: `List Pertanyaan: ${item.name}`,
      teamId: item._id,
      companyId: company._id,
      type: PageConstants.pageType.checkIns,
      url: `/companies/${company?._id}/teams/${item?._id}/check-ins/${item?.checkIn}`,
      routes: [
        {
          name: item.name,
        },
        {
          name: 'List Pertanyaan',
        },
      ],
    };
    const docsFiles = {
      _id: item?.bucket,
      name: `List Dokumen & File: ${item.name}`,
      teamId: item._id,
      companyId: company._id,
      type: PageConstants.pageType.buckets,
      url: `/companies/${company?._id}/teams/${item?._id}/buckets/${item?.bucket}`,
      routes: [
        {
          name: item.name,
        },
        {
          name: 'List Dokumen & File',
        },
      ],
    };
    const board = {
      _id: item?.boards?.[0],
      name: `List Tugas: ${item.name} - Kanban`,
      teamId: item._id,
      companyId: company._id,
      type: PageConstants.pageType.boards,
      url: `/companies/${company?._id}/teams/${item?._id}/boards/${item?.boards?.[0]}/kanban/all`,
      routes: [
        {
          name: item.name,
        },
        {
          name: 'List Tugas - Kanban',
        },
      ],
    };
    const boardList = {
      _id: item?.boards?.[0],
      name: `List Tugas: ${item.name} - List`,
      teamId: item._id,
      companyId: company._id,
      type: PageConstants.pageType.boardsList,
      url: `/companies/${company?._id}/teams/${item?._id}/boards/${item?.boards?.[0]}/list/all`,
      routes: [
        {
          name: item.name,
        },
        {
          name: 'List Tugas - List',
        },
      ],
    };
    const boardCalendar = {
      _id: item?.boards?.[0],
      name: `List Tugas: ${item.name} - Kalender`,
      teamId: item._id,
      companyId: company._id,
      type: PageConstants.pageType.boardsCalendar,
      url: `/companies/${company?._id}/teams/${item?._id}/boards/${item?.boards?.[0]}/calendar/all`,
      routes: [
        {
          name: item.name,
        },
        {
          name: 'List Tugas - Kalender',
        },
      ],
    };
    const report = {
      _id: userId,
      name: `Laporan Kinerja: ${item.name}`,
      teamId: item._id,
      companyId: company._id,
      type: PageConstants.pageType.reports,
      url: `/companies/${company?._id}/teams/${item?._id}/reports/members/${userId}`,
      routes: [
        {
          name: item.name,
        },
        {
          name: 'Laporan Kinerja',
        },
      ],
    };
    const newItem = {
      ...item,
      url: `/companies/${company?._id}/teams/${item?._id}`,
      routes: [
        {
          name: 'Beranda',
        },
        {
          name: item.name,
        },
      ],
    };
    newList.push(board);
    newList.push(boardList);
    newList.push(boardCalendar);
    newList.push(report);
    newList.push(groupChat);
    newList.push(schedule);
    newList.push(blast);
    newList.push(checkIn);
    newList.push(docsFiles);
    newList.push(newItem);
  }

  return newList;
};

const extractCompaniesFeatureIdIntoList = ({ company, userId }) => {
  const newList = [];

  const billing = {
    _id: `${company?._id}-billings`,
    name: `Tagihan & Pembayaran: ${company?.name}`,
    teamId: null,
    companyId: company._id,
    type: PageConstants.pageType.billings,
    url: `/companies/${company?._id}/billings`,
    routes: [
      {
        name: company?.name,
      },
      {
        name: 'Tagihan & Pembayaran',
      },
    ],
  };

  const member = {
    _id: `${company?._id}-members`,
    name: `Anggota Perusahaan: ${company?.name}`,
    teamId: null,
    companyId: company._id,
    type: PageConstants.pageType.companyMembers,
    url: `/companies/${company?._id}/settings/members`,
    routes: [
      {
        name: company?.name,
      },
      {
        name: 'Anggota',
      },
    ],
  };

  const cheer = {
    _id: `${company?._id}-cheers`,
    name: 'Ringkasan Cheers',
    teamId: null,
    companyId: company._id,
    type: PageConstants.pageType.cheers,
    url: `/companies/${company?._id}/cheers`,
    routes: [
      {
        name: company?.name,
      },
      {
        name: 'Ringkasan Cheers',
      },
    ],
  };

  const notification = {
    _id: `${company?._id}-notifications`,
    name: 'Semua Notifikasi',
    teamId: null,
    companyId: company._id,
    type: PageConstants.pageType.notifications,
    url: `/companies/${company?._id}/notifications`,
    routes: [
      {
        name: company?.name,
      },
      {
        name: 'Semua Notifikasi',
      },
    ],
  };

  newList.push(member);
  newList.push(billing);
  newList.push(cheer);
  newList.push(notification);

  return newList;
};

const extractMyTasksFeatureIdIntoList = ({ company, userId }) => {
  const newList = [];

  const myTasksList = {
    _id: `${company?._id}-myTasksList`,
    name: 'Semua Tugas - List',
    teamId: null,
    companyId: company._id,
    type: PageConstants.pageType.myTasksList,
    url: `/companies/${company?._id}/my-tasks/list/all`,
    routes: [
      {
        name: company?.name,
      },
      {
        name: 'Semua Tugas - List',
      }
    ],
  };

  const myTasksCalendar = {
    _id: `${company?._id}-myTasksCalendar`,
    name: 'Semua Tugas - Kalender',
    teamId: null,
    companyId: company._id,
    type: PageConstants.pageType.myTasksCalendar,
    url: `/companies/${company?._id}/my-tasks/calendar/all`,
    routes: [
      {
        name: company?.name,
      },
      {
        name: 'Semua Tugas - Kalender',
      }
    ],
  };

  newList.push(myTasksList);
  newList.push(myTasksCalendar);

  return newList;
};

/*
  Method
*/

const initiateSearchList = ({ teams, currentCompany, userId }, dispatch) => {
  let teamList = teams;
  if (!teamList || teamList.length < 1) {
    teamList = TeamActions.initiateTeams({ currentCompany, userId }, dispatch);
  }
  if (!teamList) return;
  const teamFeatureList = extractTeamsFeatureIdIntoList(
    { list: teamList, company: currentCompany, userId, },
  );

  const companyFeatureList = extractCompaniesFeatureIdIntoList({
    company: currentCompany,
  });

  const myTasksFeatureList = extractMyTasksFeatureIdIntoList({
    company: currentCompany,
  });

  const searchList = [...teamFeatureList, ...companyFeatureList, ...myTasksFeatureList];
  setSearchList({ searchList }, dispatch);
};

const saveRecentPage = ({
  company, team, userId, type, itemId, name, url, routes,
}) => {
  if (!company || !itemId || !userId || !type || !name || !url || !routes) return;
  const recentViewPages = getStoreRecentViewPages({ companyId: company?._id, userId }) || [];
  const page = {
    _id: itemId,
    name,
    teamId: team?._id,
    teamType: team?.type,
    companyId: company._id,
    url,
    type,
    routes,
  };

  if (recentViewPages.length === SearchJumpToConstants.limitRecentViewPages) {
    const removedLastItem = removeItemArrayImmutable(
      recentViewPages, SearchJumpToConstants.limitRecentViewPages - 1,
    );
    const removedSameItem = updateListSocket({
      newData: page,
      currentList: removedLastItem,
      typeAction: SearchJumpToConstants.typeCallback.DELETE,
    });

    const insertNewItem = updateListSocket({
      newData: page,
      currentList: removedSameItem,
      typeAction: SearchJumpToConstants.typeCallback.NEW,
      reverse: true,
    });
    setStoreRecentViewPages({ companyId: company?._id, userId, page: insertNewItem });
  } else {
    const removedSameItem = updateListSocket({
      newData: page,
      currentList: recentViewPages,
      typeAction: SearchJumpToConstants.typeCallback.DELETE,
    });

    const insertNewItem = updateListSocket({
      newData: page,
      currentList: removedSameItem,
      typeAction: SearchJumpToConstants.typeCallback.NEW,
      reverse: true,
    });
    setStoreRecentViewPages({ companyId: company?._id, userId, page: insertNewItem });
  }
};

// SearchTeam
async function initiateSearchTeam({
  page = 1,
  limit = SearchJumpToConstants.limitSearch,
  setCurrentSearchTeam,
  setPreviousSearchTeam,
  initialStatePreviousSearchTeam,
  companyId,
  teamId,
  keyword,
}) {
  try {
    const params = {
      companyId,
      keyword,
      page,
      limit,
    };

    if (teamId) params.teamId = teamId;

    const result = await apiUtil.get(ApiConstants.URL_V2.SEARCH_TEAMS(), {
      params,
    });

    setCurrentSearchTeam(result?.data);
    setPreviousSearchTeam(initialStatePreviousSearchTeam);

    return result;
  } catch (error) {
    throw new ErrorException(error);
  }
}

async function loadMoreSearchTeam({
  currentSearchTeam,
  limit = SearchJumpToConstants.limitSearch,
  setCurrentSearchTeam,
  setPreviousSearchTeam,
  companyId,
  teamId,
  keyword,
}) {
  try {
    const page = getNextPage({
      data: currentSearchTeam.data,
      limitPerPage: limit,
    });

    const params = {
      companyId,
      keyword,
      page,
      limit,
    };

    if (teamId) params.teamId = teamId;

    const result = await apiUtil.get(ApiConstants.URL_V2.SEARCH_TEAMS(), {
      params,
    });

    const mergedDataSearchTeam = mergeObjectListAndRemoveDuplicate({
      currentObjectList: currentSearchTeam,
      nextObjectList: result?.data,
      keyObject: 'data',
    });

    const mergedSearchTeam = {
      ...result?.data,
      data: mergedDataSearchTeam?.data,
    };

    setPreviousSearchTeam(result?.data);
    setCurrentSearchTeam(mergedSearchTeam);

    return result;
  } catch (error) {
    throw new ErrorException(error);
  }
}

// SearchCard
const formatSearchCardsItem = (items) => {
  try {
    const newSearchCards = [];

    // eslint-disable-next-line no-restricted-syntax
    for (const item of items) {
      const newSearchCard = {
        ...item,
        _id: item.card._id,
      };
      newSearchCards.push(newSearchCard);
    }

    return newSearchCards;
  } catch (error) {
    throw new ErrorException(error);
  }
};
async function initiateSearchCard({
  page = 1,
  limit = SearchJumpToConstants.limitSearch,
  setCurrentSearchCard,
  setPreviousSearchCard,
  initialStatePreviousSearchCard,
  companyId,
  teamId,
  keyword,
  filters,
}) {
  try {
    let params = {
      companyId,
      keyword,
      page,
      limit,
    };

    if (filters) {
      if (filters.filterTeams) {
        params = {
          ...params,
          'filter[teams]': filters.filterTeams,
        };
      }

      if (filters.filterArchived) {
        params = {
          ...params,
          'filter[archived]': filters.filterArchived,
        };
      }
    }

    // if (teamId) params.teamId = teamId;

    const result = await apiUtil.get(ApiConstants.URL_V2.SEARCH_CARDS(), {
      params,
    });

    const newCards = formatSearchCardsItem(result?.data?.data);

    const newResultData = {
      ...result?.data,
      data: newCards,
    };

    setCurrentSearchCard(newResultData);
    setPreviousSearchCard(initialStatePreviousSearchCard);

    return result;
  } catch (error) {
    throw new ErrorException(error);
  }
}

async function loadMoreSearchCard({
  currentSearchCard,
  limit = SearchJumpToConstants.limitSearch,
  setCurrentSearchCard,
  setPreviousSearchCard,
  companyId,
  teamId,
  keyword,
  filters,
}) {
  try {
    const page = getNextPage({
      data: currentSearchCard.data,
      limitPerPage: limit,
    });

    let params = {
      companyId,
      keyword,
      page,
      limit,
    };

    if (filters) {
      if (filters.filterTeams) {
        params = {
          ...params,
          'filter[teams]': filters.filterTeams,
        };
      }

      if (filters.filterArchived) {
        params = {
          ...params,
          'filter[archived]': filters.filterArchived,
        };
      }
    }

    // if (teamId) params.teamId = teamId;

    const result = await apiUtil.get(ApiConstants.URL_V2.SEARCH_CARDS(), {
      params,
    });

    const newCards = formatSearchCardsItem(result?.data?.data);

    const newResultData = {
      ...result?.data,
      data: newCards,
    };

    const mergedDataSearchCard = mergeObjectListAndRemoveDuplicate({
      currentObjectList: currentSearchCard,
      nextObjectList: newResultData,
      keyObject: 'data',
    });

    const mergedSearchCard = {
      ...newResultData,
      data: mergedDataSearchCard?.data,
    };

    setPreviousSearchCard(newResultData);
    setCurrentSearchCard(mergedSearchCard);

    return result;
  } catch (error) {
    throw new ErrorException(error);
  }
}

// SearchPost
const formatSearchPostsItem = (items) => {
  try {
    const newSearchPosts = [];

    // eslint-disable-next-line no-restricted-syntax
    for (const item of items) {
      const newSearchPost = {
        ...item,
        _id: item.post._id,
      };
      newSearchPosts.push(newSearchPost);
    }

    return newSearchPosts;
  } catch (error) {
    throw new ErrorException(error);
  }
};

async function initiateSearchPost({
  page = 1,
  limit = SearchJumpToConstants.limitSearch,
  setCurrentSearchPost,
  setPreviousSearchPost,
  initialStatePreviousSearchPost,
  companyId,
  teamId,
  keyword,
  filters,
}) {
  try {
    let params = {
      companyId,
      keyword,
      page,
      limit,
    };

    if (filters) {
      if (filters.filterTeams) {
        params = {
          ...params,
          'filter[teams]': filters.filterTeams,
        };
      }

      if (filters.filterArchived) {
        params = {
          ...params,
          'filter[archived]': filters.filterArchived,
        };
      }
    }

    // if (teamId) params.teamId = teamId;

    const result = await apiUtil.get(ApiConstants.URL_V2.SEARCH_POSTS(), {
      params,
    });

    const newPosts = formatSearchPostsItem(result?.data?.data);

    const newResultData = {
      ...result?.data,
      data: newPosts,
    };

    setCurrentSearchPost(newResultData);
    setPreviousSearchPost(initialStatePreviousSearchPost);

    return result;
  } catch (error) {
    throw new ErrorException(error);
  }
}

async function loadMoreSearchPost({
  currentSearchPost,
  limit = SearchJumpToConstants.limitSearch,
  setCurrentSearchPost,
  setPreviousSearchPost,
  companyId,
  teamId,
  keyword,
  filters,
}) {
  try {
    const page = getNextPage({
      data: currentSearchPost.data,
      limitPerPage: limit,
    });

    let params = {
      companyId,
      keyword,
      page,
      limit,
    };

    if (filters) {
      if (filters.filterTeams) {
        params = {
          ...params,
          'filter[teams]': filters.filterTeams,
        };
      }

      if (filters.filterArchived) {
        params = {
          ...params,
          'filter[archived]': filters.filterArchived,
        };
      }
    }

    // if (teamId) params.teamId = teamId;

    const result = await apiUtil.get(ApiConstants.URL_V2.SEARCH_POSTS(), {
      params,
    });

    const newPosts = formatSearchPostsItem(result?.data?.data);

    const newResultData = {
      ...result?.data,
      data: newPosts,
    };

    const mergedDataSearchPost = mergeObjectListAndRemoveDuplicate({
      currentObjectList: currentSearchPost,
      nextObjectList: newResultData,
      keyObject: 'data',
    });

    const mergedSearchPost = {
      ...newResultData,
      data: mergedDataSearchPost?.data,
    };

    setPreviousSearchPost(newResultData);
    setCurrentSearchPost(mergedSearchPost);

    return result;
  } catch (error) {
    throw new ErrorException(error);
  }
}

// SearchQuestion
const formatSearchQuestionsItem = (items) => {
  try {
    const newSearchQuestions = [];

    // eslint-disable-next-line no-restricted-syntax
    for (const item of items) {
      const newSearchQuestion = {
        ...item,
        _id: item.question._id,
      };
      newSearchQuestions.push(newSearchQuestion);
    }

    return newSearchQuestions;
  } catch (error) {
    throw new ErrorException(error);
  }
};

async function initiateSearchQuestion({
  page = 1,
  limit = SearchJumpToConstants.limitSearch,
  setCurrentSearchQuestion,
  setPreviousSearchQuestion,
  initialStatePreviousSearchQuestion,
  companyId,
  teamId,
  keyword,
  filters,
}) {
  try {
    let params = {
      companyId,
      keyword,
      page,
      limit,
    };

    if (filters) {
      if (filters.filterTeams) {
        params = {
          ...params,
          'filter[teams]': filters.filterTeams,
        };
      }

      if (filters.filterArchived) {
        params = {
          ...params,
          'filter[archived]': filters.filterArchived,
        };
      }
    }

    // if (teamId) params.teamId = teamId;

    const result = await apiUtil.get(ApiConstants.URL_V2.SEARCH_QUESTIONS(), {
      params,
    });

    const newPosts = formatSearchQuestionsItem(result?.data?.data);

    const newResultData = {
      ...result?.data,
      data: newPosts,
    };

    setCurrentSearchQuestion(newResultData);
    setPreviousSearchQuestion(initialStatePreviousSearchQuestion);

    return result;
  } catch (error) {
    throw new ErrorException(error);
  }
}

async function loadMoreSearchQuestion({
  currentSearchQuestion,
  limit = SearchJumpToConstants.limitSearch,
  setCurrentSearchQuestion,
  setPreviousSearchQuestion,
  companyId,
  teamId,
  keyword,
  filters,
}) {
  try {
    const page = getNextPage({
      data: currentSearchQuestion.data,
      limitPerPage: limit,
    });

    let params = {
      companyId,
      keyword,
      page,
      limit,
    };

    if (filters) {
      if (filters.filterTeams) {
        params = {
          ...params,
          'filter[teams]': filters.filterTeams,
        };
      }

      if (filters.filterArchived) {
        params = {
          ...params,
          'filter[archived]': filters.filterArchived,
        };
      }
    }

    // if (teamId) params.teamId = teamId;

    const result = await apiUtil.get(ApiConstants.URL_V2.SEARCH_QUESTIONS(), {
      params,
    });

    const newPosts = formatSearchQuestionsItem(result?.data?.data);

    const newResultData = {
      ...result?.data,
      data: newPosts,
    };

    const mergedDataSearchQuestion = mergeObjectListAndRemoveDuplicate({
      currentObjectList: currentSearchQuestion,
      nextObjectList: newResultData,
      keyObject: 'data',
    });

    const mergedSearchQuestion = {
      ...newResultData,
      data: mergedDataSearchQuestion?.data,
    };

    setPreviousSearchQuestion(newResultData);
    setCurrentSearchQuestion(mergedSearchQuestion);

    return result;
  } catch (error) {
    throw new ErrorException(error);
  }
}

// SearchDoc
const formatSearchDocsItem = (items) => {
  try {
    const newSearchDocs = [];

    // eslint-disable-next-line no-restricted-syntax
    for (const item of items) {
      const newSearchDoc = {
        ...item,
        _id: item.doc._id,
      };
      newSearchDocs.push(newSearchDoc);
    }

    return newSearchDocs;
  } catch (error) {
    throw new ErrorException(error);
  }
};

async function initiateSearchDoc({
  page = 1,
  limit = SearchJumpToConstants.limitSearch,
  setCurrentSearchDoc,
  setPreviousSearchDoc,
  initialStatePreviousSearchDoc,
  companyId,
  teamId,
  keyword,
  filters,
}) {
  try {
    let params = {
      companyId,
      keyword,
      page,
      limit,
    };

    if (filters) {
      if (filters.filterTeams) {
        params = {
          ...params,
          'filter[teams]': filters.filterTeams,
        };
      }

      if (filters.filterArchived) {
        params = {
          ...params,
          'filter[archived]': filters.filterArchived,
        };
      }
    }

    // if (teamId) params.teamId = teamId;

    const result = await apiUtil.get(ApiConstants.URL_V2.SEARCH_DOCS(), {
      params,
    });

    const newPosts = formatSearchDocsItem(result?.data?.data);

    const newResultData = {
      ...result?.data,
      data: newPosts,
    };

    setCurrentSearchDoc(newResultData);
    setPreviousSearchDoc(initialStatePreviousSearchDoc);

    return result;
  } catch (error) {
    throw new ErrorException(error);
  }
}

async function loadMoreSearchDoc({
  currentSearchDoc,
  limit = SearchJumpToConstants.limitSearch,
  setCurrentSearchDoc,
  setPreviousSearchDoc,
  companyId,
  teamId,
  keyword,
  filters,
}) {
  try {
    const page = getNextPage({
      data: currentSearchDoc.data,
      limitPerPage: limit,
    });

    let params = {
      companyId,
      keyword,
      page,
      limit,
    };

    if (filters) {
      if (filters.filterTeams) {
        params = {
          ...params,
          'filter[teams]': filters.filterTeams,
        };
      }

      if (filters.filterArchived) {
        params = {
          ...params,
          'filter[archived]': filters.filterArchived,
        };
      }
    }

    // if (teamId) params.teamId = teamId;

    const result = await apiUtil.get(ApiConstants.URL_V2.SEARCH_DOCS(), {
      params,
    });

    const newPosts = formatSearchDocsItem(result?.data?.data);

    const newResultData = {
      ...result?.data,
      data: newPosts,
    };

    const mergedDataSearchDoc = mergeObjectListAndRemoveDuplicate({
      currentObjectList: currentSearchDoc,
      nextObjectList: newResultData,
      keyObject: 'data',
    });

    const mergedSearchDoc = {
      ...newResultData,
      data: mergedDataSearchDoc?.data,
    };

    setPreviousSearchDoc(newResultData);
    setCurrentSearchDoc(mergedSearchDoc);

    return result;
  } catch (error) {
    throw new ErrorException(error);
  }
}

// SearchFile
const formatSearchFilesItem = (items) => {
  try {
    const newSearchFiles = [];

    // eslint-disable-next-line no-restricted-syntax
    for (const item of items) {
      const newSearchFile = {
        ...item,
        _id: item.file._id,
      };
      newSearchFiles.push(newSearchFile);
    }

    return newSearchFiles;
  } catch (error) {
    throw new ErrorException(error);
  }
};

async function initiateSearchFile({
  page = 1,
  limit = SearchJumpToConstants.limitSearch,
  setCurrentSearchFile,
  setPreviousSearchFile,
  initialStatePreviousSearchFile,
  companyId,
  teamId,
  keyword,
  filters,
}) {
  try {
    let params = {
      companyId,
      keyword,
      page,
      limit,
    };

    if (filters) {
      if (filters.filterTeams) {
        params = {
          ...params,
          'filter[teams]': filters.filterTeams,
        };
      }

      if (filters.filterArchived) {
        params = {
          ...params,
          'filter[archived]': filters.filterArchived,
        };
      }
    }

    // if (teamId) params.teamId = teamId;

    const result = await apiUtil.get(ApiConstants.URL_V2.SEARCH_FILES(), {
      params,
    });

    const newSearchFiles = formatSearchFilesItem(result?.data?.data);

    const newResultData = {
      ...result?.data,
      data: newSearchFiles,
    };

    setCurrentSearchFile(newResultData);
    setPreviousSearchFile(initialStatePreviousSearchFile);

    return result;
  } catch (error) {
    throw new ErrorException(error);
  }
}

async function loadMoreSearchFile({
  currentSearchFile,
  limit = SearchJumpToConstants.limitSearch,
  setCurrentSearchFile,
  setPreviousSearchFile,
  companyId,
  teamId,
  keyword,
  filters,
}) {
  try {
    const page = getNextPage({
      data: currentSearchFile.data,
      limitPerPage: limit,
    });

    let params = {
      companyId,
      keyword,
      page,
      limit,
    };

    if (filters) {
      if (filters.filterTeams) {
        params = {
          ...params,
          'filter[teams]': filters.filterTeams,
        };
      }

      if (filters.filterArchived) {
        params = {
          ...params,
          'filter[archived]': filters.filterArchived,
        };
      }
    }

    // if (teamId) params.teamId = teamId;

    const result = await apiUtil.get(ApiConstants.URL_V2.SEARCH_FILES(), {
      params,
    });

    const newSearchFiles = formatSearchFilesItem(result?.data?.data);

    const newResultData = {
      ...result?.data,
      data: newSearchFiles,
    };

    const mergedDataSearchFile = mergeObjectListAndRemoveDuplicate({
      currentObjectList: currentSearchFile,
      nextObjectList: newResultData,
      keyObject: 'data',
    });

    const mergedSearchFile = {
      ...newResultData,
      data: mergedDataSearchFile?.data,
    };

    setPreviousSearchFile(newResultData);
    setCurrentSearchFile(mergedSearchFile);

    return result;
  } catch (error) {
    throw new ErrorException(error);
  }
}

// SearchBucket
const formatSearchBucketsItem = (items) => {
  try {
    const newSearchBuckets = [];

    // eslint-disable-next-line no-restricted-syntax
    for (const item of items) {
      const newSearchBucket = {
        ...item,
        _id: item.bucket._id,
      };
      newSearchBuckets.push(newSearchBucket);
    }

    return newSearchBuckets;
  } catch (error) {
    throw new ErrorException(error);
  }
};

async function initiateSearchBucket({
  page = 1,
  limit = SearchJumpToConstants.limitSearch,
  setCurrentSearchBucket,
  setPreviousSearchBucket,
  initialStatePreviousSearchBucket,
  companyId,
  teamId,
  keyword,
  filters,
}) {
  try {
    let params = {
      companyId,
      keyword,
      page,
      limit,
    };

    if (filters) {
      if (filters.filterTeams) {
        params = {
          ...params,
          'filter[teams]': filters.filterTeams,
        };
      }

      if (filters.filterArchived) {
        params = {
          ...params,
          'filter[archived]': filters.filterArchived,
        };
      }
    }

    // if (teamId) params.teamId = teamId;

    const result = await apiUtil.get(ApiConstants.URL_V2.SEARCH_BUCKETS(), {
      params,
    });

    const newSearchBuckets = formatSearchBucketsItem(result?.data?.data);

    const newResultData = {
      ...result?.data,
      data: newSearchBuckets,
    };

    setCurrentSearchBucket(newResultData);
    setPreviousSearchBucket(initialStatePreviousSearchBucket);

    return result;
  } catch (error) {
    throw new ErrorException(error);
  }
}

async function loadMoreSearchBucket({
  currentSearchBucket,
  limit = SearchJumpToConstants.limitSearch,
  setCurrentSearchBucket,
  setPreviousSearchBucket,
  companyId,
  teamId,
  keyword,
  filters,
}) {
  try {
    const page = getNextPage({
      data: currentSearchBucket.data,
      limitPerPage: limit,
    });

    let params = {
      companyId,
      keyword,
      page,
      limit,
    };

    if (filters) {
      if (filters.filterTeams) {
        params = {
          ...params,
          'filter[teams]': filters.filterTeams,
        };
      }

      if (filters.filterArchived) {
        params = {
          ...params,
          'filter[archived]': filters.filterArchived,
        };
      }
    }

    // if (teamId) params.teamId = teamId;

    const result = await apiUtil.get(ApiConstants.URL_V2.SEARCH_BUCKETS(), {
      params,
    });

    const newSearchBuckets = formatSearchBucketsItem(result?.data?.data);

    const newResultData = {
      ...result?.data,
      data: newSearchBuckets,
    };

    const mergedDataSearchBucket = mergeObjectListAndRemoveDuplicate({
      currentObjectList: currentSearchBucket,
      nextObjectList: newResultData,
      keyObject: 'data',
    });

    const mergedSearchBucket = {
      ...newResultData,
      data: mergedDataSearchBucket?.data,
    };

    setPreviousSearchBucket(newResultData);
    setCurrentSearchBucket(mergedSearchBucket);

    return result;
  } catch (error) {
    throw new ErrorException(error);
  }
}

// SearchEvent
const formatSearchEventsItem = (items) => {
  try {
    const newSearchEvents = [];

    // eslint-disable-next-line no-restricted-syntax
    for (const item of items) {
      const newSearchEvent = {
        ...item,
        _id: item.event._id,
      };
      newSearchEvents.push(newSearchEvent);
    }

    return newSearchEvents;
  } catch (error) {
    throw new ErrorException(error);
  }
};

async function initiateSearchEvent({
  page = 1,
  limit = SearchJumpToConstants.limitSearch,
  setCurrentSearchEvent,
  setPreviousSearchEvent,
  initialStatePreviousSearchEvent,
  companyId,
  teamId,
  keyword,
  filters,
}) {
  try {
    let params = {
      companyId,
      keyword,
      page,
      limit,
    };

    if (filters) {
      if (filters.filterTeams) {
        params = {
          ...params,
          'filter[teams]': filters.filterTeams,
        };
      }

      if (filters.filterArchived) {
        params = {
          ...params,
          'filter[archived]': filters.filterArchived,
        };
      }
    }

    // if (teamId) params.teamId = teamId;

    const result = await apiUtil.get(ApiConstants.URL_V2.SEARCH_EVENTS(), {
      params,
    });

    const newPosts = formatSearchEventsItem(result?.data?.data);

    const newResultData = {
      ...result?.data,
      data: newPosts,
    };

    setCurrentSearchEvent(newResultData);
    setPreviousSearchEvent(initialStatePreviousSearchEvent);

    return result;
  } catch (error) {
    throw new ErrorException(error);
  }
}

async function loadMoreSearchEvent({
  currentSearchEvent,
  limit = SearchJumpToConstants.limitSearch,
  setCurrentSearchEvent,
  setPreviousSearchEvent,
  companyId,
  teamId,
  keyword,
  filters,
}) {
  try {
    const page = getNextPage({
      data: currentSearchEvent.data,
      limitPerPage: limit,
    });

    let params = {
      companyId,
      keyword,
      page,
      limit,
    };

    if (filters) {
      if (filters.filterTeams) {
        params = {
          ...params,
          'filter[teams]': filters.filterTeams,
        };
      }

      if (filters.filterArchived) {
        params = {
          ...params,
          'filter[archived]': filters.filterArchived,
        };
      }
    }

    // if (teamId) params.teamId = teamId;

    const result = await apiUtil.get(ApiConstants.URL_V2.SEARCH_EVENTS(), {
      params,
    });

    const newPosts = formatSearchEventsItem(result?.data?.data);

    const newResultData = {
      ...result?.data,
      data: newPosts,
    };

    const mergedDataSearchEvent = mergeObjectListAndRemoveDuplicate({
      currentObjectList: currentSearchEvent,
      nextObjectList: newResultData,
      keyObject: 'data',
    });

    const mergedSearchEvent = {
      ...newResultData,
      data: mergedDataSearchEvent?.data,
    };

    setPreviousSearchEvent(newResultData);
    setCurrentSearchEvent(mergedSearchEvent);

    return result;
  } catch (error) {
    throw new ErrorException(error);
  }
}

// SearchGroupMessage
const formatSearchGroupMessagesItem = (items) => {
  try {
    const newSearchGroupMessages = [];

    // eslint-disable-next-line no-restricted-syntax
    for (const item of items) {
      const newSearchGroupMessage = {
        ...item,
        _id: item.message._id,
      };
      newSearchGroupMessages.push(newSearchGroupMessage);
    }

    return newSearchGroupMessages;
  } catch (error) {
    throw new ErrorException(error);
  }
};

async function initiateSearchGroupMessage({
  page = 1,
  limit = SearchJumpToConstants.limitSearch,
  setCurrentSearchGroupMessage,
  setPreviousSearchGroupMessage,
  initialStatePreviousSearchGroupMessage,
  companyId,
  teamId,
  keyword,
  filters,
}) {
  try {
    let params = {
      companyId,
      keyword,
      page,
      limit,
    };

    if (filters) {
      if (filters.filterTeams) {
        params = {
          ...params,
          'filter[teams]': filters.filterTeams,
        };
      }

      if (filters.filterArchived) {
        params = {
          ...params,
          'filter[archived]': filters.filterArchived,
        };
      }
    }

    // if (teamId) params.teamId = teamId;

    const result = await apiUtil.get(ApiConstants.URL_V2.SEARCH_GROUP_CHATS(), {
      params,
    });

    const newPosts = formatSearchGroupMessagesItem(result?.data?.data);

    const newResultData = {
      ...result?.data,
      data: newPosts,
    };

    setCurrentSearchGroupMessage(newResultData);
    setPreviousSearchGroupMessage(initialStatePreviousSearchGroupMessage);

    return result;
  } catch (error) {
    throw new ErrorException(error);
  }
}

async function loadMoreSearchGroupMessage({
  currentSearchGroupMessage,
  limit = SearchJumpToConstants.limitSearch,
  setCurrentSearchGroupMessage,
  setPreviousSearchGroupMessage,
  companyId,
  teamId,
  keyword,
  filters,
}) {
  try {
    const page = getNextPage({
      data: currentSearchGroupMessage.data,
      limitPerPage: limit,
    });

    let params = {
      companyId,
      keyword,
      page,
      limit,
    };

    if (filters) {
      if (filters.filterTeams) {
        params = {
          ...params,
          'filter[teams]': filters.filterTeams,
        };
      }

      if (filters.filterArchived) {
        params = {
          ...params,
          'filter[archived]': filters.filterArchived,
        };
      }
    }

    // if (teamId) params.teamId = teamId;

    const result = await apiUtil.get(ApiConstants.URL_V2.SEARCH_GROUP_CHATS(), {
      params,
    });

    const newPosts = formatSearchGroupMessagesItem(result?.data?.data);

    const newResultData = {
      ...result?.data,
      data: newPosts,
    };

    const mergedDataSearchGroupMessage = mergeObjectListAndRemoveDuplicate({
      currentObjectList: currentSearchGroupMessage,
      nextObjectList: newResultData,
      keyObject: 'data',
    });

    const mergedSearchGroupMessage = {
      ...newResultData,
      data: mergedDataSearchGroupMessage?.data,
    };

    setPreviousSearchGroupMessage(newResultData);
    setCurrentSearchGroupMessage(mergedSearchGroupMessage);

    return result;
  } catch (error) {
    throw new ErrorException(error);
  }
}

// SearchPrivateMessage
const formatSearchPrivateMessagesItem = (items) => {
  try {
    const newSearchPrivateMessages = [];

    // eslint-disable-next-line no-restricted-syntax
    for (const item of items) {
      const newSearchPrivateMessage = {
        ...item,
        _id: item.message._id,
      };
      newSearchPrivateMessages.push(newSearchPrivateMessage);
    }

    return newSearchPrivateMessages;
  } catch (error) {
    throw new ErrorException(error);
  }
};

async function initiateSearchPrivateMessage({
  page = 1,
  limit = SearchJumpToConstants.limitSearch,
  setCurrentSearchPrivateMessage,
  setPreviousSearchPrivateMessage,
  initialStatePreviousSearchPrivateMessage,
  companyId,
  teamId,
  keyword,
  filters,
}) {
  try {
    let params = {
      companyId,
      keyword,
      page,
      limit,
    };

    if (filters) {
      if (filters.filterTeams) {
        params = {
          ...params,
          'filter[teams]': filters.filterTeams,
        };
      }

      if (filters.filterArchived) {
        params = {
          ...params,
          'filter[archived]': filters.filterArchived,
        };
      }
    }

    // if (teamId) params.teamId = teamId;

    const result = await apiUtil.get(ApiConstants.URL_V2.SEARCH_CHATS(), {
      params,
    });

    const newPosts = formatSearchPrivateMessagesItem(result?.data?.data);

    const newResultData = {
      ...result?.data,
      data: newPosts,
    };

    setCurrentSearchPrivateMessage(newResultData);
    setPreviousSearchPrivateMessage(initialStatePreviousSearchPrivateMessage);

    return result;
  } catch (error) {
    throw new ErrorException(error);
  }
}

async function loadMoreSearchPrivateMessage({
  currentSearchPrivateMessage,
  limit = SearchJumpToConstants.limitSearch,
  setCurrentSearchPrivateMessage,
  setPreviousSearchPrivateMessage,
  companyId,
  teamId,
  keyword,
  filters,
}) {
  try {
    const page = getNextPage({
      data: currentSearchPrivateMessage.data,
      limitPerPage: limit,
    });

    let params = {
      companyId,
      keyword,
      page,
      limit,
    };

    if (filters) {
      if (filters.filterTeams) {
        params = {
          ...params,
          'filter[teams]': filters.filterTeams,
        };
      }

      if (filters.filterArchived) {
        params = {
          ...params,
          'filter[archived]': filters.filterArchived,
        };
      }
    }

    // if (teamId) params.teamId = teamId;

    const result = await apiUtil.get(ApiConstants.URL_V2.SEARCH_CHATS(), {
      params,
    });

    const newPosts = formatSearchPrivateMessagesItem(result?.data?.data);

    const newResultData = {
      ...result?.data,
      data: newPosts,
    };

    const mergedDataSearchPrivateMessage = mergeObjectListAndRemoveDuplicate({
      currentObjectList: currentSearchPrivateMessage,
      nextObjectList: newResultData,
      keyObject: 'data',
    });

    const mergedSearchPrivateMessage = {
      ...newResultData,
      data: mergedDataSearchPrivateMessage?.data,
    };

    setPreviousSearchPrivateMessage(newResultData);
    setCurrentSearchPrivateMessage(mergedSearchPrivateMessage);

    return result;
  } catch (error) {
    throw new ErrorException(error);
  }
}

// SearchComment
const formatSearchCommentsItem = (items) => {
  try {
    const newSearchComments = [];

    // eslint-disable-next-line no-restricted-syntax
    for (const item of items) {
      const newSearchComment = {
        ...item,
        _id: item.comment._id,
      };
      newSearchComments.push(newSearchComment);
    }

    return newSearchComments;
  } catch (error) {
    throw new ErrorException(error);
  }
};

async function initiateSearchComment({
  page = 1,
  limit = SearchJumpToConstants.limitSearchMinimum,
  setCurrentSearchComment,
  setPreviousSearchComment,
  initialStatePreviousSearchComment,
  companyId,
  teamId,
  keyword,
  filters,
}) {
  try {
    let params = {
      companyId,
      keyword,
      page,
      limit,
    };

    if (filters) {
      if (filters.filterTeams) {
        params = {
          ...params,
          'filter[teams]': filters.filterTeams,
        };
      }

      if (filters.filterArchived) {
        params = {
          ...params,
          'filter[archived]': filters.filterArchived,
        };
      }
    }

    // if (teamId) params.teamId = teamId;

    const result = await apiUtil.get(ApiConstants.URL_V2.SEARCH_COMMENTS(), {
      params,
    });

    const newSearchComments = formatSearchCommentsItem(result?.data?.data);

    const newResultData = {
      ...result?.data,
      data: newSearchComments,
    };

    setCurrentSearchComment(newResultData);
    setPreviousSearchComment(initialStatePreviousSearchComment);

    return result;
  } catch (error) {
    throw new ErrorException(error);
  }
}

async function loadMoreSearchComment({
  currentSearchComment,
  limit = SearchJumpToConstants.limitSearchMinimum,
  setCurrentSearchComment,
  setPreviousSearchComment,
  companyId,
  teamId,
  keyword,
  filters,
}) {
  try {
    const page = getNextPage({
      data: currentSearchComment.data,
      limitPerPage: limit,
    });

    let params = {
      companyId,
      keyword,
      page,
      limit,
    };

    if (filters) {
      if (filters.filterTeams) {
        params = {
          ...params,
          'filter[teams]': filters.filterTeams,
        };
      }

      if (filters.filterArchived) {
        params = {
          ...params,
          'filter[archived]': filters.filterArchived,
        };
      }
    }

    // if (teamId) params.teamId = teamId;

    const result = await apiUtil.get(ApiConstants.URL_V2.SEARCH_COMMENTS(), {
      params,
    });

    const newSearchComments = formatSearchCommentsItem(result?.data?.data);

    const newResultData = {
      ...result?.data,
      data: newSearchComments,
    };

    const mergedDataSearchComment = mergeObjectListAndRemoveDuplicate({
      currentObjectList: currentSearchComment,
      nextObjectList: newResultData,
      keyObject: 'data',
    });

    const mergedSearchComment = {
      ...newResultData,
      data: mergedDataSearchComment?.data,
    };

    setPreviousSearchComment(newResultData);
    setCurrentSearchComment(mergedSearchComment);

    return result;
  } catch (error) {
    throw new ErrorException(error);
  }
}

// SearchDiscussion
const formatSearchDiscussionsItem = (items) => {
  try {
    const newSearchDiscussions = [];

    // eslint-disable-next-line no-restricted-syntax
    for (const item of items) {
      const newSearchDiscussion = {
        ...item,
        _id: item.discussion._id,
      };
      newSearchDiscussions.push(newSearchDiscussion);
    }

    return newSearchDiscussions;
  } catch (error) {
    throw new ErrorException(error);
  }
};

async function initiateSearchDiscussion({
  page = 1,
  limit = SearchJumpToConstants.limitSearchMinimum,
  setCurrentSearchDiscussion,
  setPreviousSearchDiscussion,
  initialStatePreviousSearchDiscussion,
  companyId,
  teamId,
  keyword,
  filters,
}) {
  try {
    let params = {
      companyId,
      keyword,
      page,
      limit,
    };

    if (filters) {
      if (filters.filterTeams) {
        params = {
          ...params,
          'filter[teams]': filters.filterTeams,
        };
      }

      if (filters.filterArchived) {
        params = {
          ...params,
          'filter[archived]': filters.filterArchived,
        };
      }
    }

    // if (teamId) params.teamId = teamId;

    const result = await apiUtil.get(ApiConstants.URL_V2.SEARCH_DISCUSSIONS(), {
      params,
    });

    const newSearchDiscussions = formatSearchDiscussionsItem(result?.data?.data);

    const newResultData = {
      ...result?.data,
      data: newSearchDiscussions,
    };

    setCurrentSearchDiscussion(newResultData);
    setPreviousSearchDiscussion(initialStatePreviousSearchDiscussion);

    return result;
  } catch (error) {
    throw new ErrorException(error);
  }
}

async function loadMoreSearchDiscussion({
  currentSearchDiscussion,
  limit = SearchJumpToConstants.limitSearchMinimum,
  setCurrentSearchDiscussion,
  setPreviousSearchDiscussion,
  companyId,
  teamId,
  keyword,
  filters,
}) {
  try {
    const page = getNextPage({
      data: currentSearchDiscussion.data,
      limitPerPage: limit,
    });

    let params = {
      companyId,
      keyword,
      page,
      limit,
    };

    if (filters) {
      if (filters.filterTeams) {
        params = {
          ...params,
          'filter[teams]': filters.filterTeams,
        };
      }

      if (filters.filterArchived) {
        params = {
          ...params,
          'filter[archived]': filters.filterArchived,
        };
      }
    }

    // if (teamId) params.teamId = teamId;

    const result = await apiUtil.get(ApiConstants.URL_V2.SEARCH_DISCUSSIONS(), {
      params,
    });

    const newSearchDiscussions = formatSearchDiscussionsItem(result?.data?.data);

    const newResultData = {
      ...result?.data,
      data: newSearchDiscussions,
    };

    const mergedDataSearchDiscussion = mergeObjectListAndRemoveDuplicate({
      currentObjectList: currentSearchDiscussion,
      nextObjectList: newResultData,
      keyObject: 'data',
    });

    const mergedSearchDiscussion = {
      ...newResultData,
      data: mergedDataSearchDiscussion?.data,
    };

    setPreviousSearchDiscussion(newResultData);
    setCurrentSearchDiscussion(mergedSearchDiscussion);

    return result;
  } catch (error) {
    throw new ErrorException(error);
  }
}

export {
  initiateSearchList,
  getUrlItem,
  saveRecentPage,
  initiateSearchTeam,
  loadMoreSearchTeam,
  initiateSearchCard,
  loadMoreSearchCard,
  initiateSearchPost,
  loadMoreSearchPost,
  initiateSearchQuestion,
  loadMoreSearchQuestion,
  initiateSearchDoc,
  loadMoreSearchDoc,
  initiateSearchFile,
  loadMoreSearchFile,
  initiateSearchBucket,
  loadMoreSearchBucket,
  initiateSearchEvent,
  loadMoreSearchEvent,
  initiateSearchGroupMessage,
  loadMoreSearchGroupMessage,
  initiateSearchPrivateMessage,
  loadMoreSearchPrivateMessage,
  initiateSearchComment,
  loadMoreSearchComment,
  initiateSearchDiscussion,
  loadMoreSearchDiscussion,
};
