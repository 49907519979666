import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { useMediaQuery } from '@mui/material';
import styles from './Main.module.css';
import { DisplayTextHeadline, DisplayTextSubHeadline } from '../../../Display/Text';
import Color from '../../../../../themes/colors';
import { InputButtonMain } from '../../../Input/Button';

function FeedbackIntroMain({
  illustrationImage,
  headline,
  description,
  primaryCtaFunc,
  primaryCtaText,
  secondaryCtaFunc,
  secondaryCtaText,
}) {
  const isTablet = useMediaQuery('(max-width:1024px)');
  const isMobile = useMediaQuery('(max-width:720px)');
  return (
    <div className={styles.container}>
      <div className={styles.illustration}>
        <LazyLoadImage src={illustrationImage} />
      </div>
      <div className={styles.content}>
        <div className={styles.title}>
          <DisplayTextHeadline
            color={Color.blueNavyCicle}
            decoration="bold"
            position={isMobile && 'center'}
          >
            {headline}
          </DisplayTextHeadline>
        </div>
        <div className={styles.description}>
          <DisplayTextSubHeadline
            mode="20"
            position={isMobile && 'center'}
          >
            {description}
          </DisplayTextSubHeadline>
        </div>
        <div className={styles.cta}>
          {primaryCtaFunc && primaryCtaText && (
          <div className={styles.ctaButton}>
            <InputButtonMain
              handleClick={primaryCtaFunc}
            >
              {primaryCtaText}
            </InputButtonMain>
          </div>
          )}
          {secondaryCtaFunc && secondaryCtaText && (
          <InputButtonMain
            handleClick={secondaryCtaFunc}
            variant="outlinedDefault"
          >
            {secondaryCtaText}
          </InputButtonMain>
          )}
        </div>
      </div>
    </div>
  );
}

FeedbackIntroMain.propTypes = {
  illustrationImage: PropTypes.string.isRequired,
  headline: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  primaryCtaFunc: PropTypes.func,
  primaryCtaText: PropTypes.string,
  secondaryCtaFunc: PropTypes.func,
  secondaryCtaText: PropTypes.string,
};

FeedbackIntroMain.defaultProps = {
  primaryCtaFunc: null,
  primaryCtaText: null,
  secondaryCtaFunc: null,
  secondaryCtaText: null,
};

export default memo(FeedbackIntroMain);
