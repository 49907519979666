import React, {
  useState, useContext, useEffect, useCallback,
} from 'react';
import { useHistory, useParams, useLocation } from 'react-router-dom';
import {
  isEmpty, cloneDeep, lowerCase,
} from 'lodash';
import PropTypes from 'prop-types';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import VerifiedUserIcon from '@mui/icons-material/VerifiedUser';
import AssessmentOutlinedIcon from '@mui/icons-material/AssessmentOutlined';
import Tooltip from '@mui/material/Tooltip';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import ViewKanbanIcon from '@mui/icons-material/ViewKanban';
import FormatListBulletedIcon from '@mui/icons-material/FormatListBulleted';
import ArchiveOutlinedIcon from '@mui/icons-material/ArchiveOutlined';
import FilterAltOutlinedIcon from '@mui/icons-material/FilterAltOutlined';
import { GlobalContext } from '../../contexts/GlobalStateProvider';
import { actionTypes } from '../../reducers/reducer';
import styles from './BoardPage.module.css';
import ListContainer from '../ListContainer/ListContainer';
import FilterContainer from './FilterContainer/FilterContainer';
import Title from '../../components/Title/Title';
import handleStatusMsg from '../../utilities/handleStatusMsg';
import handleLoadings from '../../utilities/handleLoadings';
import PageLoading from '../../components/UI/Button/PageLoading/PageLoading';
import NavHeader from '../../components/NavHeader/NavHeader';
import {
  useArchivedBoardCardsHooks,
  useArchivedBoardListsHooks,
  useBoardV2Socket,
  useGetBoardCardViewData, useSetBoardCardView,
} from '../../hooks/BoardHooks';
import { BoardActions, SearchJumpToActions, TeamActions } from '../../actions';
import GeneralSubNavBar from '../../components/GeneralSubNavBar/GeneralSubNavBar';
import { generateRoutesAndTitle } from '../../actions/BreadCrumbActions';
import GlobalActionButton from '../../components/GeneralSubNavBar/BottomNavBar/GlobalActionButton/GlobalActionButton';
import { BoardConstants, PageConstants } from '../../constants';
import { FeedbackSpinnerGeneral } from '../../components/UI_V2/Feedback/Spinner';
import Color from '../../themes/colors';
import { DisplayTextHeadline } from '../../components/UI_V2/Display/Text';
import ArchivedItemsBar from '../../components/Container/ArchivedItemsBar/ArchivedItemsBar';
import { useDelayShowHideHandler } from '../../hooks/HelperHooks';
import { useInitiateRoleUser } from '../../hooks/RoleHooks';
import { roleTypeRef, typeRef } from '../../constants/RoleConstants';
import LinkNoDecor from '../../components/LinkNoDecor/LinkNoDecor';

const mainSectionWithSidebarStyles = {
  true: `${styles.mainSection} ${styles.sidebarOpen}`,
  false: styles.mainSection,
};

function BoardPage() {
  const history = useHistory();
  const location = useLocation();
  const params = useParams();
  const [loadings, setLoadings] = useState([]);
  const [shouldFetchLists, setShouldFetchLists] = useState();
  const [shouldFetchCards, setShouldFetchCards] = useState([]);
  // const [
  //   showModalRoleUser,
  //   handleShowModalRoleUser,
  //   handleHideModalRoleUser,
  // ] = useDelayShowHideHandler();
  const [{
    user, currentTeam, currentBoard, currentBoardLists, currentCompany,
    isSidebarOpen,
  }, dispatch] = useContext(GlobalContext);
  const [showFilterSearch, setShowFilterSearch] = useState(false);
  const [isFirstLoadPage, setIsFirstLoadPage] = useState(true);
  const [showArchivedCards, setShowArchivedCards] = useState(false);
  const [showArchivedLists, setShowArchivedLists] = useState(false);
  const [showFilterSearchActive, setShowFilterSearchActive] = useState(false);
  const [updatedBoardId, setUpdatedBoardId] = useState(false);
  const [
    initiateListArchivedCards,
    loadMoreListArchivedCards,
    unarchiveArchivedCard,
  ] = useArchivedBoardCardsHooks();
  const [
    initiateListArchivedLists,
    loadMoreListArchivedLists,
    unarchiveArchivedList,
  ] = useArchivedBoardListsHooks();

  const {
    companyId, teamId, boardId, cardId,
  } = params;
  useInitiateRoleUser({
    payload: {
      teamId,
      featureType: typeRef.board,
      featureId: cardId ? undefined : boardId,
    },
  });

  const [boardCardViewMainValue,
    boardCardViewUrl,
    boardCardViewInitialCalendarViewValue] = useGetBoardCardViewData({
    boardId,
    userId: user?._id,
    companyId,
    teamId,
  });

  useSetBoardCardView({
    user,
    boardId,
    companyId,
    initialCalendarViewValue: boardCardViewInitialCalendarViewValue,
    mainValue: 'kanbanAll',
  });

  // initiate members below role
  // useInitiateTeamBelowRole({});

  const { socket, removeListener } = useBoardV2Socket({
    boardId: cardId ? undefined : boardId,
    userId: user?._id,
  }, dispatch);
  // retrieve checkLabels config from localstorage
  let initialCheckLabels = [];
  const checkLabelsLs = JSON.parse(localStorage.getItem(`confKanbanCheckLabels-${params.boardId}`));
  if (checkLabelsLs && checkLabelsLs.length > 0) initialCheckLabels = [...checkLabelsLs];
  const [checkLabels, setCheckLabels] = useState([...initialCheckLabels]);

  // retrieve checkMembers config from localstorage
  let initialCheckMembers = [];
  const checkMembersLs = JSON.parse(localStorage.getItem(`confKanbanCheckMembers-${params.boardId}`));
  if (checkMembersLs && checkMembersLs.length > 0) initialCheckMembers = [...checkMembersLs];
  const [checkMembers, setCheckMembers] = useState([...initialCheckMembers]);

  // retrieve checkDueDate config from localstorage
  let initialCheckDueDate = {};
  const checkDueDateLs = JSON.parse(localStorage.getItem(`confKanbanCheckDueDate-${params.boardId}`));
  if (checkDueDateLs) initialCheckDueDate = { ...checkDueDateLs };
  const [checkDueDate, setCheckDueDate] = useState({ ...initialCheckDueDate });

  // retrieve checkSearchName config from localstorage
  let initialCheckSearchName = '';
  const checkSearchNameLs = localStorage.getItem(`confKanbanCheckSearchName-${params.boardId}`);
  if (checkSearchNameLs && checkSearchNameLs.length > 0) initialCheckSearchName = checkSearchNameLs;
  const [checkSearchName, setCheckSearchName] = useState(initialCheckSearchName);

  useEffect(() => {
    if (currentBoard === undefined) return;
    setUpdatedBoardId(currentBoard._id);
  }, [currentBoard]);

  useEffect(() => {
    if (updatedBoardId === undefined) return;

    // retrieve checkLabels config from localstorage
    let listCheckLabels = [];
    const listCheckLabelsLs = JSON.parse(localStorage.getItem(`confKanbanCheckLabels-${updatedBoardId}`));
    if (listCheckLabelsLs && listCheckLabelsLs.length > 0) listCheckLabels = [...listCheckLabelsLs];
    setCheckLabels([...listCheckLabels]);

    // retrieve checkMembers config from localstorage
    let listCheckMembers = [];
    const listCheckMembersLs = JSON.parse(localStorage.getItem(`confKanbanCheckMembers-${updatedBoardId}`));
    if (listCheckMembersLs && listCheckMembersLs.length > 0) {
      listCheckMembers = [...listCheckMembersLs];
    }
    setCheckMembers([...listCheckMembers]);

    // retrieve checkDueDate config from localstorage
    let objectCheckDueDate = {};
    const objectCheckDueDateLs = JSON.parse(localStorage.getItem(`confKanbanCheckDueDate-${updatedBoardId}`));
    if (objectCheckDueDateLs) objectCheckDueDate = { ...objectCheckDueDateLs };
    setCheckDueDate({ ...objectCheckDueDate });

    // retrieve checkSearchName config from localstorage
    let stringCheckSearchName = '';
    const stringCheckSearchNameLs = localStorage.getItem(`confKanbanCheckSearchName-${updatedBoardId}`);
    if (stringCheckSearchNameLs && stringCheckSearchNameLs.length > 0) {
      stringCheckSearchName = stringCheckSearchNameLs;
    }
    setCheckSearchName(stringCheckSearchName);
  }, [updatedBoardId]);

  const handleShouldFetchLists = useCallback((value) => {
    setShouldFetchLists(value);
  }, []);

  const handleShouldFetchCards = useCallback((array) => {
    setShouldFetchCards(array);
  }, []);

  const clearBoardAndLists = useCallback(() => {
    BoardActions.resetBoardAndLists({}, dispatch);
  }, []);

  useEffect(() => {
    if (user._id === undefined) {
      history.push(`/check-login?previousPath=${location.pathname}`);
      return;
    }
    const startLoadings = handleLoadings('boardPage', [...loadings], 'start');
    setLoadings([...startLoadings]);

    const fetchCurrentBoard = async () => {
      try {
        let result;
        // if boardid undefined, then fetch from cardAPI.
        // because it must be request from cards
        if (boardId && cardId && currentBoard?._id === boardId) {
          // result = await CardActions.initiateCardV2(
          //   {
          //     cardId,
          //     currentBoardId: boardId,
          //     teamId,
          //     companyId,
          //     currentBoard,
          //     currentBoardLists,
          //   },
          //   dispatch,
          // );
          // console.log(result);
          // if params board id exist, then fetch as usual
        } else {
          result = await BoardActions.initiateBoardV2({
            boardId,
            currentBoard,
            currentBoardLists,
            teamId,
            companyId,
            shouldFetchLists: handleShouldFetchLists,
          }, dispatch);
          const resultTeam = await TeamActions.initiateTeam({
            teamId,
            companyId,
            currentTeam,
          }, dispatch);
          await TeamActions.initiateTeamMembers({
            teamId,
            companyId,
            currentTeam: resultTeam?.data?.team,
          }, dispatch);
        }
        setIsFirstLoadPage(false);
      } catch (err) {
        const status = handleStatusMsg(err, 'error');

        dispatch({
          type: actionTypes.SET_ERROR_RESPONSE,
          errorResponse: { message: status.message },
        });

        history.push(`/errors?previousPath=${location.pathname}`);
      } finally {
        const endLoadings = handleLoadings('boardPage', [...loadings], 'end');
        setLoadings([...endLoadings]);
      }
    };
    fetchCurrentBoard();

    // eslint-disable-next-line consistent-return
    return () => {
      clearBoardAndLists();
    };
  }, []);

  // trigger fetch list and cards on children after close card detail
  // also switch listen board socket to the inside card detail
  useEffect(() => {
    if (user._id === undefined) return;
    if (cardId) {
      if (socket) {
        removeListener(socket);
      }
      return;
    }
    if (isFirstLoadPage) return;

    const refetchBoardsListsCardsApi = async () => {
      try {
        const result = await BoardActions.initiateBoardV2({
          boardId,
          currentBoard,
          currentBoardLists,
          teamId,
          companyId,
          shouldFetchLists: handleShouldFetchLists,
        }, dispatch);
        await TeamActions.initiateTeam({
          teamId,
          companyId,
          currentTeam,
        }, dispatch);
      } catch (error) {
        const status = handleStatusMsg(error, 'error');

        dispatch({
          type: actionTypes.SET_ERROR_RESPONSE,
          errorResponse: { message: status.message },
        });

        history.push(`/errors?previousPath=${location.pathname}`);
      }
    };
    refetchBoardsListsCardsApi();
  }, [location]);

  useEffect(() => {
    if (user._id === undefined) return;
    const filtered = [];
    if (shouldFetchCards?.length > 0) return;
    currentBoardLists.forEach((list) => {
      const dueDateChecker = (objectCheckDueDate, cardDueDate) => {
        if (!isEmpty(objectCheckDueDate)) {
          if (cardDueDate) {
            const cardDate = new Date(cardDueDate);
            cardDate.setHours(0, 0, 0, 0);
            const todayDate = new Date(objectCheckDueDate.todayDate);

            const compareDate = new Date(objectCheckDueDate.compareDate);

            if (objectCheckDueDate.type === 'overdue' && cardDate < todayDate) {
              return true;
            } if (objectCheckDueDate.type === 'soon' && cardDate >= todayDate && cardDate <= compareDate) {
              return true;
            } if (objectCheckDueDate.type === 'today' && cardDate.getTime() === todayDate.getTime()) {
              return true;
            }
            return false;
          }
          return false;
        }
        return false;
      };

      const listCards = [];

      const filteredCards = [];
      list.cards.forEach((card) => {
        // AND
        const hasLabelsFinder = () => {
          for (let i = 0; i < checkLabels.length; i += 1) {
            const isFound = card.labels.some((label) => checkLabels[i].includes(label._id));
            if (isFound === false) {
              return false;
              // break;
            }
          }

          if (checkLabels.length > 0 && card.labels.length < 1) {
            return false;
          }
          return true;
        };

        const hasLabels = hasLabelsFinder();

        const hasMembersFinder = () => {
          for (let i = 0; i < checkMembers.length; i += 1) {
            const isFound = card.members.some((member) => checkMembers[i].includes(member._id));
            if (isFound === false) {
              return false;
              // break;
            }
          }

          if (checkMembers.length > 0 && card.members.length < 1) {
            return false;
          }
          return true;
        };

        const hasMembers = hasMembersFinder();
        const hasMatchDate = dueDateChecker(checkDueDate, card.dueDate);

        // filter search card by name
        const hasSearchNameFinder = () => {
          const lowName = lowerCase(card.name);
          const lowSearch = lowerCase(checkSearchName);
          return lowName.includes(lowSearch);
        };

        const hasSearchName = hasSearchNameFinder();

        let newCard = {};
        // 1 0000
        if (checkLabels.length < 1 && checkMembers.length < 1
          && isEmpty(checkDueDate) && checkSearchName.length < 1) {
          newCard = {
            ...card,
            notDisplayed: false,
          };
        } else {
          // 2 1111
          if (checkLabels.length > 0 && checkMembers.length > 0
            && !isEmpty(checkDueDate) && checkSearchName.length > 0) {
            if (hasLabels && hasMembers && hasMatchDate && hasSearchName) {
              newCard = {
                ...card,
                notDisplayed: false,
              };
            } else {
              newCard = {
                ...card,
                notDisplayed: true,
              };
            }
          }
          // 3 1110
          if (checkLabels.length > 0 && checkMembers.length > 0
            && !isEmpty(checkDueDate) && checkSearchName.length < 1) {
            if (hasLabels && hasMembers && hasMatchDate) {
              newCard = {
                ...card,
                notDisplayed: false,
              };
            } else {
              newCard = {
                ...card,
                notDisplayed: true,
              };
            }
          }

          // 4 1101
          if (checkLabels.length > 0 && checkMembers.length > 0
            && isEmpty(checkDueDate) && checkSearchName.length > 0) {
            if (hasLabels && hasMembers && hasSearchName) {
              newCard = {
                ...card,
                notDisplayed: false,
              };
            } else {
              newCard = {
                ...card,
                notDisplayed: true,
              };
            }
          }

          // 5 1011
          if (checkLabels.length > 0 && checkMembers.length < 1
            && !isEmpty(checkDueDate) && checkSearchName.length > 0) {
            if (hasLabels && hasMatchDate && hasSearchName) {
              newCard = {
                ...card,
                notDisplayed: false,
              };
            } else {
              newCard = {
                ...card,
                notDisplayed: true,
              };
            }
          }

          // 6 0111
          if (checkLabels.length < 1 && checkMembers.length > 0
            && !isEmpty(checkDueDate) && checkSearchName.length > 0) {
            if (hasMembers && hasMatchDate && hasSearchName) {
              newCard = {
                ...card,
                notDisplayed: false,
              };
            } else {
              newCard = {
                ...card,
                notDisplayed: true,
              };
            }
          }

          // 7 1100
          if (checkLabels.length > 0 && checkMembers.length > 0
            && isEmpty(checkDueDate) && checkSearchName.length < 1) {
            if (hasLabels && hasMembers) {
              newCard = {
                ...card,
                notDisplayed: false,
              };
            } else {
              newCard = {
                ...card,
                notDisplayed: true,
              };
            }
          }

          // 8 1001
          if (checkLabels.length > 0 && checkMembers.length < 1
            && isEmpty(checkDueDate) && checkSearchName.length > 0) {
            if (hasLabels && hasSearchName) {
              newCard = {
                ...card,
                notDisplayed: false,
              };
            } else {
              newCard = {
                ...card,
                notDisplayed: true,
              };
            }
          }

          // 9 0011
          if (checkLabels.length < 1 && checkMembers.length < 1
            && !isEmpty(checkDueDate) && checkSearchName.length > 0) {
            if (hasMatchDate && hasSearchName) {
              newCard = {
                ...card,
                notDisplayed: false,
              };
            } else {
              newCard = {
                ...card,
                notDisplayed: true,
              };
            }
          }

          // 10 1010
          if (checkLabels.length > 0 && checkMembers.length < 1
            && !isEmpty(checkDueDate) && checkSearchName.length < 1) {
            if (hasLabels && hasMatchDate) {
              newCard = {
                ...card,
                notDisplayed: false,
              };
            } else {
              newCard = {
                ...card,
                notDisplayed: true,
              };
            }
          }

          // 11 0101
          if (checkLabels.length < 1 && checkMembers.length > 0
            && isEmpty(checkDueDate) && checkSearchName.length > 0) {
            if (hasMembers && hasSearchName) {
              newCard = {
                ...card,
                notDisplayed: false,
              };
            } else {
              newCard = {
                ...card,
                notDisplayed: true,
              };
            }
          }

          // 12 0110
          if (checkLabels.length < 1 && checkMembers.length > 0
            && !isEmpty(checkDueDate) && checkSearchName.length < 1) {
            if (hasMembers && hasMatchDate) {
              newCard = {
                ...card,
                notDisplayed: false,
              };
            } else {
              newCard = {
                ...card,
                notDisplayed: true,
              };
            }
          }

          // 13 1000
          if (checkLabels.length > 0 && checkMembers.length < 1
            && isEmpty(checkDueDate) && checkSearchName.length < 1) {
            if (hasLabels) {
              newCard = {
                ...card,
                notDisplayed: false,
              };
            } else {
              newCard = {
                ...card,
                notDisplayed: true,
              };
            }
          }

          // 14 0001
          if (checkLabels.length < 1 && checkMembers.length < 1
            && isEmpty(checkDueDate) && checkSearchName.length > 0) {
            if (hasSearchName) {
              newCard = {
                ...card,
                notDisplayed: false,
              };
            } else {
              newCard = {
                ...card,
                notDisplayed: true,
              };
            }
          }

          // 15 0010
          if (checkLabels.length < 1 && checkMembers.length < 1
            && !isEmpty(checkDueDate) && checkSearchName.length < 1) {
            if (hasMatchDate) {
              newCard = {
                ...card,
                notDisplayed: false,
              };
            } else {
              newCard = {
                ...card,
                notDisplayed: true,
              };
            }
          }

          // 16 0100
          if (checkLabels.length < 1 && checkMembers.length > 0
            && isEmpty(checkDueDate) && checkSearchName.length < 1) {
            if (hasMembers) {
              newCard = {
                ...card,
                notDisplayed: false,
              };
            } else {
              newCard = {
                ...card,
                notDisplayed: true,
              };
            }
          }
        }

        // check for archived
        if (card.archived.status === true) {
          newCard = {
            ...card,
            notDisplayed: true,
          };
        }

        filteredCards.push(newCard);
      });

      let newList = {
        ...list,
        cards: [...filteredCards],
      };

      // check for archived
      if (list.archived.status === true) {
        newList = {
          ...newList,
          notDisplayed: true,
        };
      }

      filtered.push(newList);
    });

    // show that filter search is active
    if (checkLabels.length > 0
      || checkMembers.length > 0
      || !isEmpty(checkDueDate)
      || checkSearchName.length > 0) {
      setShowFilterSearchActive(true);
    } else {
      setShowFilterSearchActive(false);
    }

    dispatch({
      type: actionTypes.SET_FILTERED_LISTS,
      filteredLists: cloneDeep(filtered),
    });
  }, [currentBoardLists, shouldFetchCards]);

  useEffect(() => {
    if (user._id === undefined) return;
    const updateFilteredBoardLists = (prevFilteredLists) => {
      const filtered = [];

      prevFilteredLists.forEach((list) => {
        const dueDateChecker = (objectCheckDueDate, cardDueDate) => {
          if (!isEmpty(objectCheckDueDate)) {
            if (cardDueDate) {
              const cardDate = new Date(cardDueDate);
              cardDate.setHours(0, 0, 0, 0);
              const todayDate = new Date(objectCheckDueDate.todayDate);

              const compareDate = new Date(objectCheckDueDate.compareDate);

              if (objectCheckDueDate.type === 'overdue' && cardDate < todayDate) {
                return true;
              } if (objectCheckDueDate.type === 'soon' && cardDate >= todayDate && cardDate <= compareDate) {
                return true;
              } if (objectCheckDueDate.type === 'today' && cardDate.getTime() === todayDate.getTime()) {
                return true;
              }
              return false;
            }
            return false;
          }
          return false;
        };

        const listCards = [];

        const filteredCards = [];
        list.cards.forEach((card) => {
          // AND
          const hasLabelsFinder = () => {
            for (let i = 0; i < checkLabels.length; i += 1) {
              const isFound = card.labels.some((label) => checkLabels[i].includes(label._id));
              if (isFound === false) {
                return false;
                // break;
              }
            }

            if (checkLabels.length > 0 && card.labels.length < 1) {
              return false;
            }
            return true;
          };

          const hasLabels = hasLabelsFinder();

          const hasMembersFinder = () => {
            for (let i = 0; i < checkMembers.length; i += 1) {
              const isFound = card.members.some((member) => checkMembers[i].includes(member._id));
              if (isFound === false) {
                return false;
                // break;
              }
            }

            if (checkMembers.length > 0 && card.members.length < 1) {
              return false;
            }
            return true;
          };

          const hasMembers = hasMembersFinder();
          const hasMatchDate = dueDateChecker(checkDueDate, card.dueDate);

          // filter search card by name
          const hasSearchNameFinder = () => {
            const lowName = lowerCase(card.name);
            const lowSearch = lowerCase(checkSearchName);
            return lowName.includes(lowSearch);
          };

          const hasSearchName = hasSearchNameFinder();

          let newCard = {};
          // 1 0000
          if (checkLabels.length < 1 && checkMembers.length < 1
            && isEmpty(checkDueDate) && checkSearchName.length < 1) {
            newCard = {
              ...card,
              notDisplayed: false,
            };
          } else {
            // 2 1111
            if (checkLabels.length > 0 && checkMembers.length > 0
              && !isEmpty(checkDueDate) && checkSearchName.length > 0) {
              if (hasLabels && hasMembers && hasMatchDate && hasSearchName) {
                newCard = {
                  ...card,
                  notDisplayed: false,
                };
              } else {
                newCard = {
                  ...card,
                  notDisplayed: true,
                };
              }
            }
            // 3 1110
            if (checkLabels.length > 0 && checkMembers.length > 0
              && !isEmpty(checkDueDate) && checkSearchName.length < 1) {
              if (hasLabels && hasMembers && hasMatchDate) {
                newCard = {
                  ...card,
                  notDisplayed: false,
                };
              } else {
                newCard = {
                  ...card,
                  notDisplayed: true,
                };
              }
            }

            // 4 1101
            if (checkLabels.length > 0 && checkMembers.length > 0
              && isEmpty(checkDueDate) && checkSearchName.length > 0) {
              if (hasLabels && hasMembers && hasSearchName) {
                newCard = {
                  ...card,
                  notDisplayed: false,
                };
              } else {
                newCard = {
                  ...card,
                  notDisplayed: true,
                };
              }
            }

            // 5 1011
            if (checkLabels.length > 0 && checkMembers.length < 1
              && !isEmpty(checkDueDate) && checkSearchName.length > 0) {
              if (hasLabels && hasMatchDate && hasSearchName) {
                newCard = {
                  ...card,
                  notDisplayed: false,
                };
              } else {
                newCard = {
                  ...card,
                  notDisplayed: true,
                };
              }
            }

            // 6 0111
            if (checkLabels.length < 1 && checkMembers.length > 0
              && !isEmpty(checkDueDate) && checkSearchName.length > 0) {
              if (hasMembers && hasMatchDate && hasSearchName) {
                newCard = {
                  ...card,
                  notDisplayed: false,
                };
              } else {
                newCard = {
                  ...card,
                  notDisplayed: true,
                };
              }
            }

            // 7 1100
            if (checkLabels.length > 0 && checkMembers.length > 0
              && isEmpty(checkDueDate) && checkSearchName.length < 1) {
              if (hasLabels && hasMembers) {
                newCard = {
                  ...card,
                  notDisplayed: false,
                };
              } else {
                newCard = {
                  ...card,
                  notDisplayed: true,
                };
              }
            }

            // 8 1001
            if (checkLabels.length > 0 && checkMembers.length < 1
              && isEmpty(checkDueDate) && checkSearchName.length > 0) {
              if (hasLabels && hasSearchName) {
                newCard = {
                  ...card,
                  notDisplayed: false,
                };
              } else {
                newCard = {
                  ...card,
                  notDisplayed: true,
                };
              }
            }

            // 9 0011
            if (checkLabels.length < 1 && checkMembers.length < 1
              && !isEmpty(checkDueDate) && checkSearchName.length > 0) {
              if (hasMatchDate && hasSearchName) {
                newCard = {
                  ...card,
                  notDisplayed: false,
                };
              } else {
                newCard = {
                  ...card,
                  notDisplayed: true,
                };
              }
            }

            // 10 1010
            if (checkLabels.length > 0 && checkMembers.length < 1
              && !isEmpty(checkDueDate) && checkSearchName.length < 1) {
              if (hasLabels && hasMatchDate) {
                newCard = {
                  ...card,
                  notDisplayed: false,
                };
              } else {
                newCard = {
                  ...card,
                  notDisplayed: true,
                };
              }
            }

            // 11 0101
            if (checkLabels.length < 1 && checkMembers.length > 0
              && isEmpty(checkDueDate) && checkSearchName.length > 0) {
              if (hasMembers && hasSearchName) {
                newCard = {
                  ...card,
                  notDisplayed: false,
                };
              } else {
                newCard = {
                  ...card,
                  notDisplayed: true,
                };
              }
            }

            // 12 0110
            if (checkLabels.length < 1 && checkMembers.length > 0
              && !isEmpty(checkDueDate) && checkSearchName.length < 1) {
              if (hasMembers && hasMatchDate) {
                newCard = {
                  ...card,
                  notDisplayed: false,
                };
              } else {
                newCard = {
                  ...card,
                  notDisplayed: true,
                };
              }
            }

            // 13 1000
            if (checkLabels.length > 0 && checkMembers.length < 1
              && isEmpty(checkDueDate) && checkSearchName.length < 1) {
              if (hasLabels) {
                newCard = {
                  ...card,
                  notDisplayed: false,
                };
              } else {
                newCard = {
                  ...card,
                  notDisplayed: true,
                };
              }
            }

            // 14 0001
            if (checkLabels.length < 1 && checkMembers.length < 1
              && isEmpty(checkDueDate) && checkSearchName.length > 0) {
              if (hasSearchName) {
                newCard = {
                  ...card,
                  notDisplayed: false,
                };
              } else {
                newCard = {
                  ...card,
                  notDisplayed: true,
                };
              }
            }

            // 15 0010
            if (checkLabels.length < 1 && checkMembers.length < 1
              && !isEmpty(checkDueDate) && checkSearchName.length < 1) {
              if (hasMatchDate) {
                newCard = {
                  ...card,
                  notDisplayed: false,
                };
              } else {
                newCard = {
                  ...card,
                  notDisplayed: true,
                };
              }
            }

            // 16 0100
            if (checkLabels.length < 1 && checkMembers.length > 0
              && isEmpty(checkDueDate) && checkSearchName.length < 1) {
              if (hasMembers) {
                newCard = {
                  ...card,
                  notDisplayed: false,
                };
              } else {
                newCard = {
                  ...card,
                  notDisplayed: true,
                };
              }
            }
          }

          // check for archived
          if (card.archived.status === true) {
            newCard = {
              ...card,
              notDisplayed: true,
            };
          }

          filteredCards.push(newCard);
        });

        let newList = {
          ...list,
          cards: [...filteredCards],
        };

        // check for archived
        if (list.archived.status === true) {
          newList = {
            ...newList,
            notDisplayed: true,
          };
        }

        filtered.push(newList);
      });

      // show that filter search is active
      if (checkLabels.length > 0
        || checkMembers.length > 0
        || !isEmpty(checkDueDate)
        || checkSearchName.length > 0) {
        setShowFilterSearchActive(true);
      } else {
        setShowFilterSearchActive(false);
      }

      return cloneDeep(filtered);
    };

    BoardActions.dispatchUpdateFilteredBoardLists({
      updateFilteredBoardLists,
    }, dispatch);
  }, [checkLabels, checkMembers, checkDueDate, checkSearchName]);

  const handleShowFilterSearch = () => {
    setShowFilterSearch(!showFilterSearch);
  };

  const handleShowArchivedCards = () => {
    setShowArchivedCards(!showArchivedCards);
    setShowArchivedLists(false);
  };

  const handleShowArchivedLists = () => {
    setShowArchivedLists(!showArchivedLists);
    setShowArchivedCards(false);
  };

  const handleClickLabel = (labelId) => {
    const labelsLocalStorage = JSON.parse(localStorage.getItem(`confKanbanCheckLabels-${params.boardId}`));
    let newCheckLabels = [];
    if (labelsLocalStorage && labelsLocalStorage?.length >= 0) {
      newCheckLabels = cloneDeep(labelsLocalStorage);
    }
    if (!checkLabels.includes(labelId)) {
      newCheckLabels.push(labelId);
      setCheckLabels([...newCheckLabels]);
      localStorage.setItem(`confKanbanCheckLabels-${params.boardId}`, JSON.stringify(newCheckLabels));
    } else {
      newCheckLabels = newCheckLabels.filter((id) => id !== labelId);
      setCheckLabels([...newCheckLabels]);
      localStorage.setItem(`confKanbanCheckLabels-${params.boardId}`, JSON.stringify(newCheckLabels));
    }
  };

  const handleClickMember = (memberId) => {
    let newCheckMembers = [...checkMembers];
    if (!checkMembers.includes(memberId)) {
      newCheckMembers.push(memberId);
      setCheckMembers([...newCheckMembers]);
      localStorage.setItem(`confKanbanCheckMembers-${params.boardId}`, JSON.stringify(newCheckMembers));
    } else {
      newCheckMembers = newCheckMembers.filter((id) => id !== memberId);
      setCheckMembers([...newCheckMembers]);
      localStorage.setItem(`confKanbanCheckMembers-${params.boardId}`, JSON.stringify(newCheckMembers));
    }
  };

  const handleClickDueDate = (type) => {
    if (!isEmpty(checkDueDate) && checkDueDate.type === type) {
      setCheckDueDate({});
      localStorage.setItem(`confKanbanCheckDueDate-${params.boardId}`, JSON.stringify({}));
      return;
    }
    const todayDate = new Date();
    todayDate.setHours(0, 0, 0, 0); // last midnight
    let compareDate = new Date();
    compareDate.setHours(0, 0, 0, 0);

    switch (type) {
      // case 'today':
      //   compareDate = compareDate.setHours(24,0,0,0); //set next midnight
      //   break;
      case 'soon':
        // compareDate = compareDate.setHours(0,0,0,0); //set last midnight
        compareDate = compareDate.setHours(compareDate.getHours() + 72); // set next 3 days
        break;

      default:
        //
        break;
    }

    const newCheckDueDate = {
      type,
      todayDate,
      compareDate,
    };
    setCheckDueDate({
      ...newCheckDueDate,
    });
    localStorage.setItem(`confKanbanCheckDueDate-${params.boardId}`, JSON.stringify(newCheckDueDate));
  };

  const handleSearchName = (keyword) => {
    setCheckSearchName(keyword);
    localStorage.setItem(`confKanbanCheckSearchName-${params.boardId}`, keyword);
  };

  const handleClearFilter = () => {
    setCheckLabels([]);
    localStorage.setItem(`confKanbanCheckLabels-${params.boardId}`, JSON.stringify([]));
    setCheckMembers([]);
    localStorage.setItem(`confKanbanCheckMembers-${params.boardId}`, JSON.stringify([]));
    setCheckDueDate({});
    localStorage.setItem(`confKanbanCheckDueDate-${params.boardId}`, JSON.stringify({}));
    setCheckSearchName('');
    localStorage.setItem(`confKanbanCheckSearchName-${params.boardId}`, '');
  };

  const pageType = PageConstants.pageType.boards;
  const breadcrumb = generateRoutesAndTitle({
    type: pageType,
    data: {
      currentTeam, currentBoard, boardId, companyId,
    },
  });
  SearchJumpToActions.saveRecentPage({
    company: currentCompany,
    team: currentTeam,
    userId: user?._id,
    itemId: `${boardId || currentBoard?._id}-kanban`,
    url: breadcrumb.url,
    routes: breadcrumb.routes,
    name: breadcrumb.title,
    type: pageType,
  });

  return (
    <>
      <Title title={currentBoard.name === undefined ? 'List Tugas' : `List Tugas - ${currentTeam.name} - Kanban`} />
      <div className={styles.container}>
        {/* Should use navheader here because max content */}
        {/* <NavHeader
          handleCancelShow={handleCancelShow}
          handleShowUserMenu={handleShowUserMenu}
          params={params}
          isKanbanView
        /> */}
        <GeneralSubNavBar
          breadcrumbRoutes={breadcrumb.routes}
          pageTitle={breadcrumb.title}
          bottomBarOverviewOptionId="boards"
          // bottomBarGlobalActions={(
          //   <>
          //     {currentTeam?._id === teamId
          //   && (
          //     <LinkNoDecor
          // to={`/companies/${companyId}/teams/${teamId}/reports/members/${user._id}`}>
          //       <GlobalActionButton
          //         text="Kinerja Saya"
          //         icon={PageConstants.pageProperties.reports.icon}
          //       />
          //     </LinkNoDecor>
          // )}
          //     <GlobalActionButton
          // buttonMode="active" text="View Kanban" icon={<ViewKanbanIcon />} />
          //     <LinkNoDecor
          // to={`/companies/${companyId}/teams/${teamId}/boards/${boardId}/list/all`}>
          //       <GlobalActionButton text="View List" icon={<FormatListBulletedIcon />} />
          //     </LinkNoDecor>
          //     <LinkNoDecor
          // to={`/companies/${companyId}/teams/${teamId}/boards/${boardId}/
          // calendar/all?initialCalendarView=${boardCardViewInitialCalendarViewValue}`}>
          //       <GlobalActionButton text="View Kalender" icon={<CalendarMonthIcon />} />
          //     </LinkNoDecor>
          //   </>
          // )}
          bottomBarGlobalActions={(
            <>
              <GlobalActionButton
                handleClick={handleShowFilterSearch}
                buttonMode="outlined"
                text="Filter"
                icon={<FilterAltOutlinedIcon />}
              >
                {showFilterSearchActive ? (
                  <Tooltip
                    title="Filter-mu aktif. Atur atau hapus filter kalo kamu ga menemukan yang kamu cari."
                  >
                    <div className={styles.activeFilterIcon}><CheckCircleIcon /></div>
                  </Tooltip>
                ) : null}
              </GlobalActionButton>
              <GlobalActionButton
                handleClick={handleShowArchivedCards}
                buttonMode="outlined"
                text="Arsip Tugas"
                icon={<ArchiveOutlinedIcon />}
              />
              <GlobalActionButton
                handleClick={handleShowArchivedLists}
                buttonMode="outlined"
                text="Arsip List"
                icon={<ArchiveOutlinedIcon />}
              />
            </>
          )}
        />
        {(shouldFetchLists && currentBoardLists?.length > 0)
        || (shouldFetchCards?.length > 0 && currentBoardLists?.length > 0) ? (
          <div className={styles.forLoading}>
            <div className={styles.forLoading__box} />
            <div className={styles.forLoading__item}>
              <FeedbackSpinnerGeneral size={36} color={Color.yellowAccentCicle} thickness={6} />
              <DisplayTextHeadline
                color={Color.blueNavyCicle}
                mode="28"
                decoration="bold"
                position="center"
              >
                Menyiapkan data...
              </DisplayTextHeadline>
            </div>
          </div>
          ) : null}
        <PageLoading wait="boardPage" loadings={loadings}>
          <div className={showFilterSearch ? `${mainSectionWithSidebarStyles[isSidebarOpen]} ${styles.mainSectionMarginRight}` : mainSectionWithSidebarStyles[isSidebarOpen]}>
            {showFilterSearch
              ? (
                <div className={styles.menuSection}>
                  <FilterContainer
                    cancel={handleShowFilterSearch}
                    handleClickMember={handleClickMember}
                    handleClickLabel={handleClickLabel}
                    handleClickDueDate={handleClickDueDate}
                    handleClearFilter={handleClearFilter}
                    handleSearchName={handleSearchName}
                    checkDueDate={checkDueDate}
                    checkLabels={checkLabels}
                    checkMembers={checkMembers}
                    checkSearchName={checkSearchName}
                  />
                </div>
              )
              : null}

            {/* {showArchivedItems
              ? (
                <div className={styles.menuSection}>
                  <ArchivedItemsMenu
                    cancel={handleShowArchivedItems}
                    handleClickMember={handleClickMember}
                    handleClickLabel={handleClickLabel}
                    handleClickDueDate={handleClickDueDate}
                    handleClearFilter={handleClearFilter}
                    handleSearchName={handleSearchName}
                    checkDueDate={checkDueDate}
                    checkLabels={checkLabels}
                    checkMembers={checkMembers}
                    checkSearchName={checkSearchName}
                  />
                </div>
              ) : null} */}
            {showArchivedCards
              && (
              <ArchivedItemsBar
                topPositionVariant="withBottomBar"
                initiateListDataFunc={initiateListArchivedCards}
                loadMoreListDataFunc={loadMoreListArchivedCards}
                unarchiveDataFunc={unarchiveArchivedCard}
                onClose={handleShowArchivedCards}
                featureType="card"
                barTitle="Arsip Tugas"
              />
              )}
            {showArchivedLists
              && (
              <ArchivedItemsBar
                topPositionVariant="withBottomBar"
                initiateListDataFunc={initiateListArchivedLists}
                loadMoreListDataFunc={loadMoreListArchivedLists}
                unarchiveDataFunc={unarchiveArchivedList}
                onClose={handleShowArchivedLists}
                featureType="list"
                barTitle="Arsip List"
              />
              )}
            <div>
              <ListContainer
                updatedBoardId={updatedBoardId}
                shouldFetch={shouldFetchLists}
                onShouldFetch={handleShouldFetchLists}
                shouldFetchCards={shouldFetchCards}
                onShouldFetchCards={handleShouldFetchCards}
                socket={socket}
              />
            </div>
          </div>
          {/* <MyRoleUser
            open={showModalRoleUser}
            onClose={handleHideModalRoleUser}
            featureTitle={currentTeam?.name}
            featureType={typeRef.board}
            featureId={boardId}
            roleType={roleTypeRef.team}
          /> */}
        </PageLoading>
      </div>
    </>
  );
}

BoardPage.propTypes = {
};

BoardPage.defaultProps = {
};

export default BoardPage;
