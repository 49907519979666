import React, {
  memo, useContext, useEffect, useState,
} from 'react';
import PropTypes from 'prop-types';
import DownloadIcon from '@mui/icons-material/Download';
import HomeOutlinedIcon from '@mui/icons-material/HomeOutlined';
import AssignmentIcon from '@mui/icons-material/Assignment';
import HistoryIcon from '@mui/icons-material/History';
import NotificationsNoneIcon from '@mui/icons-material/NotificationsNone';
import ChatBubbleOutlineOutlinedIcon from '@mui/icons-material/ChatBubbleOutlineOutlined';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { useMediaQuery } from '@mui/material';
import { useLocation } from 'react-router-dom';
import styles from './PrimarySection.module.css';
import Color from '../../../themes/colors';
import { InputButtonMain } from '../../UI_V2/Input/Button';
import SidebarItem from '../SidebarItem/SidebarItem';
import { pageProperties } from '../../../constants/PageConstants';
import { GlobalContext, initialState } from '../../../contexts/GlobalStateProvider';
import { NotificationActions } from '../../../actions';
import { actionTypes } from '../../../reducers/reducer';
import { getSessionMyTaskCardView } from '../../../utilities/sessionStorage';
import { getStoreMyTaskCardView } from '../../../utilities/localStorage';
import LinkNoDecor from '../../LinkNoDecor/LinkNoDecor';
import ActivityContainer from '../../../pages/ActivityContainer/ActivityContainer';
import { PageConstants } from '../../../constants';

const PrimarySection = ({
  params,
  handleShowNotification,
  handleShowActivityMenu,
}) => {
  const [
    {
      user,
      counterNotif,
      teams,
    },
    dispatch,
  ] = useContext(GlobalContext);
  const { companyId, teamId } = params;

  let initialMyTaskCardView;

  initialMyTaskCardView = getSessionMyTaskCardView(
    { companyId, userId: user?._id },
  );

  if (!initialMyTaskCardView?.mainValue) {
    initialMyTaskCardView = getStoreMyTaskCardView(
      { companyId, userId: user?._id },
    );
  }

  const myTaskCardViewInitialCalendarViewValue = initialMyTaskCardView?.initialCalendarViewValue || 'dayGridMonth';
  const mytaskCardViewMainValue = initialMyTaskCardView?.mainValue || 'listAll';

  let myTaskCardViewUrl;

  switch (mytaskCardViewMainValue) {
    case 'listAll':
      myTaskCardViewUrl = `/companies/${companyId}/my-tasks/list/all`;
      break;
    case 'calendarAll':
      myTaskCardViewUrl = `/companies/${companyId}/my-tasks/calendar/all?initalCalendarView=${myTaskCardViewInitialCalendarViewValue}`;
      break;
    default:
      myTaskCardViewUrl = `/companies/${companyId}/my-tasks/list/all`;
      break;
  }

  const isHasTeam = teams?.length > 0;
  const firstTeam = teams?.[0];

  return (
    <div className={styles.container}>
      {/* <LinkNoDecor to={companyId ? `/companies/${companyId}` : '/'}>
        <SidebarItem
          text="Beranda"
          iconLeft={<HomeOutlinedIcon />}
          enableIconLeft
        />
      </LinkNoDecor> */}
      <LinkNoDecor to={myTaskCardViewUrl}>
        <SidebarItem
          text="Semua Tugas"
          iconLeft={<AssignmentIcon />}
          enableIconLeft
        />
      </LinkNoDecor>
      <div onClick={handleShowNotification}>
        <SidebarItem
          text="Notifikasi"
          iconLeft={<NotificationsNoneIcon />}
          enableIconLeft
          enableIconRightCounter
          iconRightCounterValue={counterNotif.nonChat}
        />
      </div>
      <div onClick={handleShowActivityMenu}>
        <SidebarItem
          text="Aktifitas"
          iconLeft={<HistoryIcon />}
          enableIconLeft
        />
      </div>
      {isHasTeam && (
      <LinkNoDecor to={companyId ? `/companies/${companyId}/teams/${teamId || firstTeam._id}/reports/members/${user?._id}` : '/'}>
        <SidebarItem
          text="Laporan Kinerja"
          iconLeft={PageConstants.pageProperties.reports.icon}
          enableIconLeft
        />
      </LinkNoDecor>
      )}
      {/* <LinkNoDecor to={companyId ? `/companies/${companyId}/chats` : '/'}>
        <SidebarItem
          text="Chat Pribadi"
          iconLeft={<ChatBubbleOutlineOutlinedIcon />}
          enableIconLeft
          enableIconRightCounter
          iconRightCounterValue={counterNotif.chat}
        />
      </LinkNoDecor> */}
    </div>
  );
};

PrimarySection.propTypes = {
  params: PropTypes.object,
  handleShowNotification: PropTypes.func,
  handleShowActivityMenu: PropTypes.func,
};

PrimarySection.defaultProps = {
  params: { companyId: null, teamId: null },
  handleShowActivityMenu: () => null,
  handleShowNotification: () => null,
};

export default memo(PrimarySection);
