import React, { memo } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Avatar } from '@mui/material';
import Color from '../../../../themes/colors';
import { adjustServerUrl } from '../../../../utilities/stringUtil';

const shadowStyles = {
  true: {
    boxShadow: '0px 2px 2px rgba(0, 0, 0, 0.15)',
  },
  false: {},
};

const sizeStyles = {
  xs: {
    height: '16px',
    width: '16px',
  },
  sm: {
    height: '24px',
    width: '24px',
  },
  md: {
    height: '30px',
    width: '30px',
  },
  lg: {
    height: '36px',
    width: '36px',
  },
  xl: {
    height: '40px',
    width: '40px',
  },
  xxl: {
    height: '50px',
    width: '50px',
  },
};

function DisplayAvatarMember({
  variant, shadow, size, alt, src, userId, prevLoc,
  marginTop, marginBottom, marginRight, marginLeft, children,
  // onClick,
}) {
  // const params = useParams();
  // const history = useHistory();
  // const { companyId } = params;
  // let url;
  // if (userId) {
  //   if (prevLoc) {
  //     url = { pathname: `/companies/${companyId}/profiles/${userId}`, state: { from: prevLoc } };
  //   } else {
  //     url = { pathname: `/companies/${companyId}/profiles/${userId}` };
  //   }
  // }

  let photoSource = src;
  if (src) {
    photoSource = adjustServerUrl(src);
  }
  return (
    <Avatar
      // onClick={userId ? () => history.push(url) : onClick}
      src={photoSource}
      alt={alt}
      variant={variant}
      sx={{
        ...shadowStyles[shadow],
        ...sizeStyles[size],
        marginTop,
        marginLeft,
        marginBottom,
        marginRight,
        // cursor: userId || typeof onClick === 'function' ? 'pointer' : 'default',
      }}
    >
      {children}
    </Avatar>
  );
}

DisplayAvatarMember.propTypes = {
  variant: PropTypes.oneOf(['circular', 'rounded', 'square']),
  size: PropTypes.oneOf(['xs', 'sm', 'md', 'lg', 'xl', 'xxl']),
  src: PropTypes.string.isRequired,
  shadow: PropTypes.bool,
  alt: PropTypes.string,
  marginRight: PropTypes.string,
  marginLeft: PropTypes.string,
  marginTop: PropTypes.string,
  marginBottom: PropTypes.string,
  children: PropTypes.node,
  userId: PropTypes.string,
  prevLoc: PropTypes.string,
  // onClick: PropTypes.func,
};

DisplayAvatarMember.defaultProps = {
  variant: 'circular',
  size: 'md',
  children: null,
  shadow: true,
  alt: '',
  marginTop: '0px',
  marginBottom: '0px',
  marginRight: '6px',
  marginLeft: '0px',
  userId: null,
  prevLoc: null,
  // onClick: null,
};

export default memo(DisplayAvatarMember);
