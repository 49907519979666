import React, {
  useContext, useState, useEffect, memo,
} from 'react';
import PropTypes from 'prop-types';
import KeyboardDoubleArrowRightOutlinedIcon from '@mui/icons-material/KeyboardDoubleArrowRightOutlined';
import KeyboardDoubleArrowLeftOutlinedIcon from '@mui/icons-material/KeyboardDoubleArrowLeftOutlined';
import { VisibilityContext } from 'react-horizontal-scrolling-menu';
import styles from './Arrow.module.css';

const gradientStyles = {
  left: styles.leftGradient,
  right: styles.rightGradient,
};

const boxStyles = {
  popUpleft: styles.leftBox,
  popUpright: styles.rightBox,
  allleft: styles.leftBoxAll,
  allright: styles.rightBoxAll,
};

function Arrow({
  children,
  disabled,
  onClick,
  position,
  typeNotif,
}) {
  return (
    // eslint-disable-next-line jsx-a11y/click-events-have-key-events
    <div
      role="button"
      className={styles.container}
      onClick={onClick}
      disabled={disabled}
      style={{
        opacity: disabled ? '0' : '1',
      }}
    >
      <div className={`${styles.box} ${boxStyles[`${typeNotif}${position}`]} ${gradientStyles[position]}`}>
        {children}
      </div>
    </div>
  );
}

export function LeftArrow({ typeNotif }) {
  const {
    isFirstItemVisible,
    scrollPrev,
    visibleItemsWithoutSeparators,
    initComplete,
  } = useContext(VisibilityContext);

  const [disabled, setDisabled] = useState(
    !initComplete || (initComplete && isFirstItemVisible),
  );
  useEffect(() => {
    if (visibleItemsWithoutSeparators.length) {
      setDisabled(isFirstItemVisible);
    }
  }, [isFirstItemVisible, visibleItemsWithoutSeparators]);

  return (
    <Arrow disabled={disabled} typeNotif={typeNotif} position="left" onClick={() => scrollPrev()}>
      <KeyboardDoubleArrowLeftOutlinedIcon />
    </Arrow>
  );
}

export function RightArrow({ typeNotif }) {
  const {
    isLastItemVisible,
    scrollNext,
    visibleItemsWithoutSeparators,
  } = useContext(VisibilityContext);

  const [disabled, setDisabled] = useState(
    !visibleItemsWithoutSeparators.length && isLastItemVisible,
  );
  useEffect(() => {
    if (visibleItemsWithoutSeparators.length) {
      setDisabled(isLastItemVisible);
    }
  }, [isLastItemVisible, visibleItemsWithoutSeparators]);

  return (
    <Arrow disabled={disabled} typeNotif={typeNotif} position="right" onClick={() => scrollNext()}>
      <KeyboardDoubleArrowRightOutlinedIcon />
    </Arrow>
  );
}

Arrow.propTypes = {
  children: PropTypes.node.isRequired,
  disabled: PropTypes.bool.isRequired,
  onClick: PropTypes.func.isRequired,
  position: PropTypes.string.isRequired,
  typeNotif: PropTypes.string.isRequired,
};

LeftArrow.propTypes = {
  typeNotif: PropTypes.string.isRequired,
};

RightArrow.propTypes = {
  typeNotif: PropTypes.string.isRequired,
};

export default memo(Arrow);
