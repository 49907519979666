import React, { memo, useState } from 'react';
import PropTypes from 'prop-types';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import styles from './WorkloadInterpretation.module.css';
import Color from '../../../../../themes/colors';
import { DisplayTextBody, DisplayTextHeadline, DisplayTextSubHeadline } from '../../../../../components/UI_V2/Display/Text';
import { sliceNumber } from '../../../../../utilities/stringUtil';

const WorkloadInterpretation = ({ value }) => (
  <div className={styles.container}>
    <div className={styles.title}>
      <DisplayTextHeadline decoration="bold" color={Color.blueNavy2}>
        {sliceNumber(value, 4)}
      </DisplayTextHeadline>
    </div>
    <div className={styles.subTitle}>
      <DisplayTextBody position="center" color={Color.gray3}>
        rata-rata tugas mingguan
      </DisplayTextBody>
    </div>
  </div>
);

WorkloadInterpretation.propTypes = {
  value: PropTypes.string,
};

WorkloadInterpretation.defaultProps = {
  value: '-',
};

export default memo(WorkloadInterpretation);
