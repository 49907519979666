import React from 'react';
import PropTypes from 'prop-types';
import { Check } from '@mui/icons-material';
import styles from './BodySwitchCompany.module.css';
import { DisplayTextBody, DisplayTextSubBody } from '../../../../components/UI_V2/Display/Text';
import Line from '../../../../components/UI/Line/Line';
import Color from '../../../../themes/colors';
import LinkNoDecor from '../../../../components/LinkNoDecor/LinkNoDecor';
import { DisplayBadgeCounter } from '../../../../components/UI_V2/Display/Badge';
import { getSingleOrPlural } from '../../../../utilities/stringUtil';

const BodySwitchCompany = ({ cancel, companies }) => {
  const lineCustomStyle = {
    border: '1px solid #F7FAFF',
    marginTop: '3px',
    // marginBottom: '3px',
  };
  return (
    <div className={styles.container}>
      {companies.map((value, index) => (
        <>
          <LinkNoDecor to={`/companies/${value?.company?._id}`}>
            <div onClick={cancel} className={styles.companySection}>
              <div
                className={styles.text}
              >
                <div className={styles.title}>
                  <DisplayTextBody
                    color={Color.blueNavy4}
                    mode="11"
                  >
                    {value?.company?.name}
                  </DisplayTextBody>
                </div>

                { value.unreadNotification > 0 && value.unreadChat > 0
                  ? (
                    <div
                      className={styles.desc}
                    >
                      <DisplayTextSubBody
                        color={Color.gray4}
                        mode="9"
                      >
                        {`${value.unreadNotification} notifikasi dan ${value.unreadChat} pesan`}
                      </DisplayTextSubBody>
                    </div>
                  )
                  : null }
                { value.unreadNotification > 0 && value.unreadChat < 1
                  ? (
                    <div
                      className={styles.desc}
                    >
                      <DisplayTextSubBody
                        color={Color.gray4}
                        mode="9"
                      >
                        {/* {`${value.unreadNotification}
                        ${getSingleOrPlural('notification', value.unreadNotification)}`} */}
                        {`${value.unreadNotification} notifikasi`}
                      </DisplayTextSubBody>
                    </div>
                  )
                  : null }
                { value.unreadNotification < 1 && value.unreadChat > 0
                  ? (
                    <div
                      className={styles.desc}
                    >
                      <DisplayTextSubBody
                        color={Color.gray4}
                        mode="9"
                      >
                        {/* {`${value.unreadChat}
                        ${getSingleOrPlural('message', value.unreadChat)}`} */}
                        {`${value.unreadChat} pesan`}
                      </DisplayTextSubBody>
                    </div>
                  )
                  : null }
              </div>
              <div className={styles.counter}>
                <DisplayBadgeCounter
                  badgeContent={value.unreadChat + value.unreadNotification}
                    // customStyle={{ top: -3, right: 12 }}
                  backgroundColor={Color.yellowAccentCicle}
                />
              </div>
            </div>
          </LinkNoDecor>
          {index === (companies.length - 1) ? null : <Line customStyle={lineCustomStyle} />}
        </>
      ))}
      {/* {companies.length < 1
        ? (
          <div className={styles.text}>
            <DisplayTextSubBody
              color={Color.yellowAccentCicle}
              mode="9"
            >
              You have no company
            </DisplayTextSubBody>
          </div>
        )
        : null} */}
    </div>
  );
};

BodySwitchCompany.propTypes = {
  companies: PropTypes.array.isRequired,
  cancel: PropTypes.func.isRequired,
};

export default BodySwitchCompany;
