import { ApiConstants, ReportConstants } from '../constants';
import { limitReport } from '../constants/ReportConstants';
import apiUtil from '../utilities/apiUtil';
import { getLastListDate, mergeObjectListAndRemoveDuplicate } from '../utilities/arrayUtil';
import { getBackwardMonths } from '../utilities/dateUtil';
import { ErrorException } from '../utilities/handleError';

const initiateReportRecapMonthWeeklyCardMemberCount = async ({
  memberId,
  teamId,
  companyId,
  month,
  year,
  setCurrentReportRecapMonthWeeklyCardMemberData,
}) => {
  try {
    const result = await apiUtil.get(
      ApiConstants.URL_V1.REPORTS_RECAP_MONTH_WEEKLY_CARDS_TEAM_MEMBERS_COUNT(
        {
          memberId,
          teamId,
        },
      ), {
        params: {
          companyId,
          month,
          year,
        },
      },
    );

    const { data } = result.data;

    const {
      listReport,
      ratingFinal,
      suggestionFinal,
      ratingProgressInterpretation,
      cardProgressInterpretation,
    } = data;

    setCurrentReportRecapMonthWeeklyCardMemberData({
      listReport,
      ratingFinal,
      suggestionFinal,
      ratingProgressInterpretation,
      cardProgressInterpretation,
    });
  } catch (error) {
    throw new ErrorException(error);
  }
};

const initiateReportListCardSnapshot = async ({
  memberId,
  teamId,
  companyId,
  week,
  year,
  setCurrentReportListCardSnapshot,
  setPreviousReportListCardSnapshot,
  initialStatePreviousReportListCardSnapshot,
  itemType,
  limit = limitReport,
}) => {
  try {
    const result = await apiUtil.get(
      ApiConstants.URL_V1.REPORTS_RECAP_WEEK_WEEKLY_CARDS_TEAM_MEMBERS(
        {
          memberId,
          teamId,
        },
      ), {
        params: {
          companyId,
          week,
          year,
          limit,
          'filter[status]': itemType,
        },
      },
    );

    setCurrentReportListCardSnapshot(result?.data);
    setPreviousReportListCardSnapshot(initialStatePreviousReportListCardSnapshot);

    return result;
  } catch (error) {
    throw new ErrorException(error);
  }
};

const loadMoreReportListCardSnapshot = async ({
  memberId,
  teamId,
  companyId,
  week,
  year,
  setCurrentReportListCardSnapshot,
  setPreviousReportListCardSnapshot,
  currentReportListCardSnapshot,
  itemType,
  limit = limitReport,
}) => {
  try {
    const params = {
      companyId,
      week,
      year,
      limit,
      'filter[status]': itemType,
    };
    if (itemType === ReportConstants.typeRef.completed) {
      const completedAt = getLastListDate(currentReportListCardSnapshot.data, 'setAt', 'complete');
      params.completedAt = completedAt;
    } else if (itemType === ReportConstants.typeRef.ongoing
      || itemType === ReportConstants.typeRef.overdue) {
      const dueDateAt = getLastListDate(currentReportListCardSnapshot.data, 'dueDate');
      params.dueDateAt = dueDateAt;
    } else {
      const createdAt = getLastListDate(currentReportListCardSnapshot.data, 'createdAt');
      params.createdAt = createdAt;
    }

    const result = await apiUtil.get(
      ApiConstants.URL_V1.REPORTS_RECAP_WEEK_WEEKLY_CARDS_TEAM_MEMBERS(
        {
          teamId,
          memberId,
        },
      ), {
        params,
      },
    );

    const mergedDataListData = mergeObjectListAndRemoveDuplicate({
      currentObjectList: currentReportListCardSnapshot,
      nextObjectList: result?.data,
      keyObject: 'data',
    });

    const mergedListData = {
      ...result?.data,
      data: mergedDataListData?.data,
    };

    setPreviousReportListCardSnapshot(result?.data);
    setCurrentReportListCardSnapshot(mergedListData);

    return result;
  } catch (error) {
    throw new ErrorException(error);
  }
};

const initiateReportListBottleneckBacklogCardSnapshot = async ({
  memberId,
  teamId,
  companyId,
  week,
  year,
  setCurrentReportListBottleneckBacklogCardSnapshot,
  itemType = 'bottleneckBacklog',
  limit = 1,
}) => {
  try {
    const result = await apiUtil.get(
      ApiConstants.URL_V1.REPORTS_RECAP_WEEK_WEEKLY_CARDS_TEAM_MEMBERS(
        {
          memberId,
          teamId,
        },
      ), {
        params: {
          companyId,
          week,
          year,
          limit,
          'filter[status]': itemType,
        },
      },
    );

    setCurrentReportListBottleneckBacklogCardSnapshot(result?.data?.data?.[0]);

    return result;
  } catch (error) {
    throw new ErrorException(error);
  }
};

const initiateReportListBottleneckOngoingCardSnapshot = async ({
  memberId,
  teamId,
  companyId,
  week,
  year,
  setCurrentReportListBottleneckOngoingCardSnapshot,
  itemType = 'bottleneckOngoing',
  limit = 1,
}) => {
  try {
    const result = await apiUtil.get(
      ApiConstants.URL_V1.REPORTS_RECAP_WEEK_WEEKLY_CARDS_TEAM_MEMBERS(
        {
          memberId,
          teamId,
        },
      ), {
        params: {
          companyId,
          week,
          year,
          limit,
          'filter[status]': itemType,
        },
      },
    );

    setCurrentReportListBottleneckOngoingCardSnapshot(result?.data?.data?.[0]);

    return result;
  } catch (error) {
    throw new ErrorException(error);
  }
};

// not used
function getBackwardMonthsExceptSep23AndBelow(monthNumber, yearNumber, iteration = 4) {
  const monthsArray = [];

  // eslint-disable-next-line no-plusplus
  for (let i = 0; i < iteration; i++) {
    if (monthNumber - i >= 0) {
      const month = monthNumber - i;
      const year = yearNumber;

      if (year === 2023 && month <= 8) {
        break; // Stop when reaching September 2023 or earlier
      }
      monthsArray.push({ month, year });
    } else {
      const month = 12 - (i - monthNumber);
      const year = yearNumber - 1;

      if (year === 2023 && month <= 8) {
        break; // Stop when reaching September 2023 or earlier
      }
      monthsArray.push({ month, year });
    }
  }

  return monthsArray;
}

function getBackwardMonthsExceptOct23AndBelow(monthNumber, yearNumber, iteration = 4) {
  const monthsArray = [];

  // eslint-disable-next-line no-plusplus
  for (let i = 0; i < iteration; i++) {
    if (monthNumber - i >= 0) {
      const month = monthNumber - i;
      const year = yearNumber;

      if (year === 2023 && month <= 9) {
        break; // Stop when reaching October 2023 or earlier
      }
      monthsArray.push({ month, year });
    } else {
      const month = 12 - (i - monthNumber);
      const year = yearNumber - 1;

      if (year === 2023 && month <= 9) {
        break; // Stop when reaching October 2023 or earlier
      }
      monthsArray.push({ month, year });
    }
  }

  return monthsArray;
}

function getBackwardMonthsExceptNov23AndBelow(monthNumber, yearNumber, iteration = 4) {
  const monthsArray = [];

  // eslint-disable-next-line no-plusplus
  for (let i = 0; i < iteration; i++) {
    if (monthNumber - i >= 0) {
      const month = monthNumber - i;
      const year = yearNumber;

      if (year === 2023 && month <= 10) {
        break; // Stop when reaching November 2023 or earlier
      }
      monthsArray.push({ month, year });
    } else {
      const month = 12 - (i - monthNumber);
      const year = yearNumber - 1;

      if (year === 2023 && month <= 10) {
        break; // Stop when reaching November 2023 or earlier
      }
      monthsArray.push({ month, year });
    }
  }

  return monthsArray;
}

export {
  initiateReportRecapMonthWeeklyCardMemberCount,
  initiateReportListCardSnapshot,
  loadMoreReportListCardSnapshot,
  initiateReportListBottleneckBacklogCardSnapshot,
  initiateReportListBottleneckOngoingCardSnapshot,
  getBackwardMonthsExceptSep23AndBelow,
  getBackwardMonthsExceptOct23AndBelow,
  getBackwardMonthsExceptNov23AndBelow,
};
