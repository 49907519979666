import { cloneDeep } from 'lodash';
import { actionTypes } from '../reducers/reducer';
import { ApiConstants, CommentConstants } from '../constants';
import apiUtil from '../utilities/apiUtil';
import {
  getLastListCreatedAt,
  mergeObjectListAndRemoveDuplicate,
  updateListProperty,
} from '../utilities/arrayUtil';
import { ErrorException } from '../utilities/handleError';
import { TeamActions, BucketActions, CommentActions } from '.';
import { mergeObjectWithKeyProperty } from '../utilities/objectUtil';

/*
  Dispatcher
*/

function dispatchCurrentBucketDoc({ currentBucketDoc }, dispatch) {
  dispatch({
    type: actionTypes.SET_CURRENT_BUCKET_DOC,
    currentBucketDoc,
  });
}

function dispatchCurrentBucketDocPath({ bucketDocPath }, dispatch) {
  dispatch({
    type: actionTypes.SET_BUCKET_DOC_PATH,
    bucketDocPath,
  });
}

function dispatchPreviousBucketDocComment({ previousDocComment }, dispatch) {
  dispatch({
    type: actionTypes.SET_PREVIOUS_DOC_COMMENT,
    previousDocComment,
  });
}

function dispatchUpdateBucketDoc({ updateBucketDoc }, dispatch) {
  dispatch({
    type: actionTypes.UPDATE_CURRENT_BUCKET_DOC,
    updateBucketDoc,
  });
}

/*
  SetterDispatcher
*/

function setCurrentBucketDoc({ currentBucketDoc }, dispatch) {
  if (!currentBucketDoc) return;

  dispatchCurrentBucketDoc(
    { currentBucketDoc: cloneDeep(currentBucketDoc) }, dispatch,
  );
}

function setCurrentBucketDocPath({ bucketDocPath }, dispatch) {
  if (!bucketDocPath) return;

  dispatchCurrentBucketDocPath(
    { bucketDocPath: cloneDeep(bucketDocPath) }, dispatch,
  );
}

function setPreviousBucketDocComment({ previousDocComment }, dispatch) {
  if (!previousDocComment) return;

  dispatchPreviousBucketDocComment(
    { previousDocComment: cloneDeep(previousDocComment) }, dispatch,
  );
}

/*
  Method
*/

function incomingDoc({ doc }, dispatch) {
  if (!doc) return;

  const updateBucketDoc = (currentBucketDoc) => {
    let newCurrentBucketDoc = {
      ...doc,
      comments: currentBucketDoc.comments,
      cheers: currentBucketDoc.cheers,
    };
    newCurrentBucketDoc = cloneDeep(newCurrentBucketDoc);

    return newCurrentBucketDoc;
  };

  dispatchUpdateBucketDoc({ updateBucketDoc }, dispatch);
}

function incomingDocComment({
  comment, typeAction, updateCommentCheer, keyProperty = 'comments',
}, dispatch) {
  if (!comment) return;

  const updateBucketDoc = (currentBucketDoc) => updateListProperty({
    keyProperty,
    newData: comment,
    currentList: currentBucketDoc,
    typeAction,
    actionEdit: updateCommentCheer,
  });

  dispatchUpdateBucketDoc({ updateBucketDoc }, dispatch);
}

async function initiateDoc({
  docId, limit, companyId, teamId,
}, dispatch) {
  try {
    const result = await apiUtil.get(ApiConstants.URL_V2.DOC({ docId }), {
      params: {
        companyId,
        teamId,
      },
    });
    const resultComment = await apiUtil.get(ApiConstants.URL_V2.DOC_COMMENT({ docId }), {
      params: {
        limit,
        companyId,
        teamId,
      },
    });

    const modifiedResult = mergeObjectWithKeyProperty({
      obj: result?.data?.doc,
      keyProperty: 'comments',
      newPropertyValue: resultComment?.data?.comments,
    });

    // BucketActions.setCurrentBucket({ currentBucket: result?.data?.bucket }, dispatch);
    setCurrentBucketDoc({ currentBucketDoc: modifiedResult }, dispatch);
    setCurrentBucketDocPath({ bucketDocPath: result?.data?.bucketDocPath }, dispatch);
    // TeamActions.saveCurrentTeam({ currentTeam: result?.data?.currentTeam }, dispatch);

    const objComments = CommentActions.sliceResultDataforLastCommentNearLimitComment(resultComment);
    const payload = {
      data: {
        doc: result?.data?.doc,
        comments: objComments?.data?.comments,
      },
    };

    return payload;
  } catch (error) {
    throw new ErrorException(error);
  }
}

async function loadMoreDocComment({
  docId, currentBucketDoc, companyId, teamId,
}, dispatch) {
  try {
    const result = await apiUtil.get(ApiConstants.URL_V2.DOC_COMMENT({ docId }), {
      params: {
        limit: CommentConstants.limitComment,
        createdAt: getLastListCreatedAt(currentBucketDoc.comments),
        companyId,
        teamId,
      },
    });

    const modifiedResult = mergeObjectWithKeyProperty({
      obj: currentBucketDoc,
      keyProperty: 'comments',
      newPropertyValue: result?.data?.comments,
    });

    const mergedDoc = mergeObjectListAndRemoveDuplicate({
      currentObjectList: currentBucketDoc,
      nextObjectList: modifiedResult,
      keyObject: 'comments',
    });

    setPreviousBucketDocComment({ previousDocComment: modifiedResult?.comments }, dispatch);
    setCurrentBucketDoc({ currentBucketDoc: mergedDoc }, dispatch);

    return result;
  } catch (error) {
    throw new ErrorException(error);
  }
}

async function createDoc({
  bucketId, companyId, teamId, body,
}) {
  try {
    const result = await apiUtil.post(ApiConstants.URL_V1.BUCKET_DOCS({ bucketId }), body, {
      params: {
        companyId,
        teamId,
      },
    });

    return result;
  } catch (error) {
    throw new ErrorException(error);
  }
}

async function archiveDoc({ docId, companyId, teamId }) {
  try {
    const result = await apiUtil.patch(ApiConstants.URL_V1.DOC_ARCHIVE({ docId }), {}, {
      params: {
        companyId,
        teamId,
      },
    });

    return result;
  } catch (error) {
    throw new ErrorException(error);
  }
}

async function editDoc({
  docId, body, companyId, teamId,
}) {
  try {
    const result = await apiUtil.patch(ApiConstants.URL_V1.DOC({ docId }), body, {
      params: {
        companyId,
        teamId,
      },
    });

    return result;
  } catch (error) {
    throw new ErrorException(error);
  }
}

async function unarchiveDoc({
  teamId,
  companyId,
  docId,
}, dispatch) {
  try {
    const result = await apiUtil.patch(ApiConstants.URL_V1.DOC_UNARCHIVE({ docId }), {}, {
      params: {
        companyId,
        teamId,
      },
    });

    return result;
  } catch (error) {
    throw new ErrorException(error);
  }
}

export {
  initiateDoc,
  setPreviousBucketDocComment,
  loadMoreDocComment,
  incomingDoc,
  incomingDocComment,
  dispatchUpdateBucketDoc,
  setCurrentBucketDocPath,
  createDoc,
  archiveDoc,
  editDoc,
  unarchiveDoc,
};
