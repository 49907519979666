import React, { useState, useEffect } from 'react';
import CloseIcon from '@mui/icons-material/Close';
import { useSnackbar } from 'notistack';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import PropTypes from 'prop-types';
import Modal from 'react-bootstrap/Modal';
import BoxPopOver from '../../../../../components/UI/BoxPopOver/BoxPopOver';
import SeparatorLine from '../../../../../components/UI/SeparatorLine/SeparatorLine';
import styles from './DeleteMessagePopUp.module.css';
import Button from '../../../../../components/UI/Button/Button';
import handleStatusMsg from '../../../../../utilities/handleStatusMsg';
import handleLoadings from '../../../../../utilities/handleLoadings';
import { handleAxiosDeleteRefreshToken, refreshTokenAndSetNewToken } from '../../../../../actions/CheckLoginActions';
import { InputButtonMain } from '../../../../../components/UI_V2/Input/Button';
import { elementId } from '../../../../../constants/ElementIdConstants';

const DeleteMessagePopUp = ({
  messageFile, show, onClose, onParentClose, type,
}) => {
  const [deletedMessage, setDeletedMessage] = useState();
  const { enqueueSnackbar } = useSnackbar();
  const [loadings, setLoadings] = useState([]);
  const params = useParams();
  const {
    companyId, teamId, chatId, groupChatId,
  } = params;

  const isChat = type === 'private-chat-message' || type === 'private-chat-attachment';

  useEffect(() => {
    if (deletedMessage === undefined) {
      return;
    }

    const deleteApiMessage = async () => {
      let id;
      let messageFileId;
      let url;

      switch (type) {
        case 'group-chat-message':
          id = groupChatId;
          messageFileId = messageFile._id;
          url = `${process.env.REACT_APP_PRIMARY_API_URL}/api/v1/group-chats/${id}/messages/${messageFileId}`;
          break;
        case 'group-chat-attachment':
          id = groupChatId;
          messageFileId = messageFile._id;
          url = `${process.env.REACT_APP_PRIMARY_API_URL}/api/v1/group-chats/${id}/attachments/${messageFileId}`;
          break;
        case 'private-chat-message':
          id = chatId;
          messageFileId = messageFile._id;
          url = `${process.env.REACT_APP_PRIMARY_API_URL}/api/v1/chats/${id}/messages/${messageFileId}`;
          break;
        case 'private-chat-attachment':
          id = chatId;
          messageFileId = messageFile._id;
          url = `${process.env.REACT_APP_PRIMARY_API_URL}/api/v1/chats/${id}/attachments/${messageFileId}`;
          break;
        default:
        //
      }

      try {
        const startLoadings = handleLoadings('deleteMessage', [...loadings], 'start');
        setLoadings([...startLoadings]);
        // cannot change to apiutil, always get cors
        const result = await axios.delete(url, {
          withCredentials: true,
          headers: { Authorization: `jwt ${localStorage.getItem('token')}` },
          params: {
            companyId,
            teamId,
          },
        });

        // switch (type) {
        //   case 'group-chat-message':
        //   case 'group-chat-attachment':
        //   case 'private-chat-message':
        //   case 'private-chat-attachment':
        //     break;
        //   default:
        // }
        const status = handleStatusMsg(result, 'success');

        enqueueSnackbar(status.message, {
          variant: 'success',
        });
      } catch (err) {
        const newAuthToken = await handleAxiosDeleteRefreshToken(err);

        if (newAuthToken) {
          try {
            const result = await axios.delete(url, {
              withCredentials: true,
              headers: { Authorization: `jwt ${newAuthToken}` },
              params: {
                companyId,
                teamId,
              },
            });

            const status = handleStatusMsg(result, 'success');

            enqueueSnackbar(status.message, {
              variant: 'success',
            });
          } catch (error) {
            const status = handleStatusMsg(error, 'error');

            enqueueSnackbar(status.message, {
              variant: 'error',
            });
          }
        } else {
          const status = handleStatusMsg(err, 'error');

          enqueueSnackbar(status.message, {
            variant: 'error',
          });
        }
      } finally {
        const endLoadings = handleLoadings('deleteMessage', [...loadings], 'end');
        setLoadings([...endLoadings]);
      }
      // close this container after finished
      onClose();
      onParentClose();
    };

    deleteApiMessage();
  }, [deletedMessage]);

  const handleDelete = () => {
    setDeletedMessage(messageFile._id);
  };

  return (
    <Modal centered show={show} onHide={onClose}>
      <Modal.Header closeButton>
        <Modal.Title>
          Hapus Pesan?
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        Hati-hati, pesan ini akan hilang selamanya.
      </Modal.Body>
      <Modal.Footer>
        <InputButtonMain
          handleClick={handleDelete}
          variant="danger"
          wait="deleteMessage"
          loadings={loadings}
          wide="block"
          id={isChat ? elementId.chatMessageDeletePopupConfirmationYesButton
            : elementId.groupChatMessageDeletePopupConfirmationYesButton}
        >
          Hapus
        </InputButtonMain>
      </Modal.Footer>
    </Modal>
  );
};

DeleteMessagePopUp.propTypes = {
  messageFile: PropTypes.object.isRequired,
  show: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onParentClose: PropTypes.func.isRequired,
  type: PropTypes.string.isRequired,
};

export default DeleteMessagePopUp;
