import React, { memo, useState } from 'react';
import PropTypes from 'prop-types';
import SortIcon from '@mui/icons-material/Sort';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { useMediaQuery } from '@mui/material';
import styles from './CardListColumnItem.module.css';
import Color from '../../../../themes/colors';
import { DisplayTextBody } from '../../../UI_V2/Display/Text';
import CardListColumnBox from '../../CardListColumnBox/CardListColumnBox';

const CardListColumnItem = ({
  onClick,
  text,
  icon,
  showIcon,
  widthPercentage,
  variantBorder,
}) => (
  <CardListColumnBox
    variantBorder={variantBorder}
    widthPercentage={widthPercentage}
  >
    <div className={styles.container} onClick={onClick}>
      <div className={styles.text}>
        <DisplayTextBody
          // decoration="bold"
          fontFamily="openSans"
        >
          {text}
        </DisplayTextBody>
      </div>
      {showIcon && (
      <div className={styles.icon}>
        {icon}
      </div>
      )}
    </div>
  </CardListColumnBox>
);

CardListColumnItem.propTypes = {
  onClick: PropTypes.func,
  text: PropTypes.string,
  icon: PropTypes.node,
  showIcon: PropTypes.bool,
  widthPercentage: PropTypes.number,
  variantBorder: PropTypes.oneOf(['noLeftBorder', 'noRightBorder', 'noLeftRightBorder', 'noTopLeftBorder', 'noTopRightBorder', 'noTopLeftRightBorder']),
};

CardListColumnItem.defaultProps = {
  onClick: () => null,
  text: null,
  icon: <SortIcon />,
  showIcon: false,
  widthPercentage: 100,
  variantBorder: 'noLeftBorder',
};

export default memo(CardListColumnItem);
