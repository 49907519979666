import React from 'react';
import PropTypes from 'prop-types';
import styles from './NavIcon.module.css';

const NavIcon = ({ children, clicked }) => (
  <>
    <div onClick={clicked} className={styles.box}>
      <div className={styles.back} />
      <div className={styles.icon}>
        {children}
      </div>
    </div>

  </>
);

NavIcon.propTypes = {
  children: PropTypes.node.isRequired,
  clicked: PropTypes.func.isRequired,
};

export default NavIcon;
