import React, { memo, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Skeleton } from '@mui/material';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import styles from './AddOnCard.module.css';
import { DisplayTextBody, DisplayTextHeadline, DisplayTextSubHeadline } from '../../../../../components/UI_V2/Display/Text';
import Color from '../../../../../themes/colors';
import { InputTextFieldMain } from '../../../../../components/UI_V2/Input/TextField';
import { FeedbackLoadingMain } from '../../../../../components/UI_V2/Feedback/Loading';
import { splitString } from '../../../../../utilities/stringUtil';
import { formatToBasicThousandSeparator } from '../../../../../utilities/numberUtil';
import OverlayButton from '../../../../../components/UI/Button/OverlayButton/OverlayButton';

const variantConfig = {
  user_monthly: {
    styles: styles.user,
    durationCode: 'bulan',
  },
  user_quarterly: {
    styles: styles.user,
    durationCode: '3 bulan',
  },
  user_biyearly: {
    styles: styles.user,
    durationCode: '6 bulan',
  },
  user_yearly: {
    styles: styles.user,
    durationCode: '1 tahun',
  },
  storage_monthly: {
    styles: styles.storage,
    durationCode: 'bulan',
  },
  storage_quarterly: {
    styles: styles.storage,
    durationCode: '3 bulan',
  },
  storage_biyearly: {
    styles: styles.storage,
    durationCode: '6 bulan',
  },
  storage_yearly: {
    styles: styles.storage,
    durationCode: '1 tahun',
  },
};

const AddOnCard = ({
  addon,
  loadings,
  handleClickUpdateAddonQty,
}) => {
  const [item, setItem] = useState({
    _id: addon.product._id || {},
    qty: addon.qty || 0,
  });
  const [showAction, setShowAction] = useState(false);

  useEffect(() => {
    if (Number(addon.qty) === 0 && Number(item.qty) === 0) {
      setShowAction(false);
      return;
    }

    if (Number(item.qty) !== Number(addon.qty)) {
      setShowAction(true);
      return;
    }

    setShowAction(false);
  }, [item.qty, addon]);

  const isShowPriceSaving = addon?.product?.priceSaving?.percentage > 0;

  let finalPricePerMonth = addon?.product?.pricePerMonth;
  if (isShowPriceSaving) {
    finalPricePerMonth = addon?.product?.pricePerMonth - addon.product.priceSaving.valuePerMonth;
  }

  finalPricePerMonth = formatToBasicThousandSeparator(finalPricePerMonth);
  const finalTotalPrice = formatToBasicThousandSeparator(addon?.product?.price);

  const addonExtract = splitString(addon?.product?.name, '_');
  const isNotMonthlyPlan = addonExtract[1] !== 'monthly';

  let addonName = addonExtract[0];
  const isAddonNameStorage = addonName === 'storage';

  if (isAddonNameStorage) addonName = 'GB';

  const handleChanged = (event) => {
    const { name, value } = event.target;

    if (value < 0) return;
    setItem((prevValue) => ({
      ...prevValue,
      [name]: value,
    }));
  };

  const handleCancelChangeAddon = () => {
    setItem((prevValue) => ({
      ...prevValue,
      qty: Number(addon.qty),
    }));
  };
  return (
    <div className={styles.container}>
      <div className={styles.information}>
        <div className={styles.title}>
          <DisplayTextSubHeadline
            color={Color.black}
            mode="16"
          >
            {addon.product.desc}
          </DisplayTextSubHeadline>
        </div>
        {/* <div className={styles.desc}>
          <DisplayTextBody>
            {addon.product.desc}
          </DisplayTextBody>
        </div> */}
        <div className={styles.price}>
          <FeedbackLoadingMain
            wait="billingOrderListAddons"
            loadings={loadings}
            loadingComponent={<Skeleton variant="text" width={120} />}
          >
            <div className={styles.price__main}>

              <DisplayTextBody
                color={Color.black}
              >
                Rp.
                {' '}
                {finalPricePerMonth}
                {` / ${addonName} / bulan`}
              </DisplayTextBody>
            </div>
            {isShowPriceSaving && (
            <div className={styles.price__saving}>
              <DisplayTextBody
                color={Color.greenCicle}
              >
                {`(Hemat ${addon?.product?.priceSaving?.percentage}%!)`}
              </DisplayTextBody>
            </div>
            )}
          </FeedbackLoadingMain>
        </div>
        {isNotMonthlyPlan && (
        <div className={styles.priceTotal}>
          <FeedbackLoadingMain
            wait="billingOrderListAddons"
            loadings={loadings}
            loadingComponent={<Skeleton variant="text" width={300} />}
          >
            <div className={styles.price__main}>
              <DisplayTextBody
                color={Color.black}
                decoration="bold"
              >
                Total Rp.
                {' '}
                {finalTotalPrice}
              </DisplayTextBody>
            </div>
            <div className={styles.price__saving}>
              <DisplayTextBody
                color={Color.black}
                decoration="bold"
              >
                {`/ ${addonName} / ${variantConfig[addon?.product?.name]?.durationCode}`}
              </DisplayTextBody>
            </div>
          </FeedbackLoadingMain>
        </div>
        )}
      </div>
      <div className={styles.amount}>
        <div className={styles.amount__inputAndTitle}>
          <div className={styles.amount__input}>
            <InputTextFieldMain
              type="number"
              name="qty"
              onChange={(event) => handleChanged(event)}
              value={item.qty}
            />
          </div>
          <div className={styles.amount__title}>
            <DisplayTextBody
              color={Color.black}
            >
              {addonName}
            </DisplayTextBody>
          </div>
        </div>
        {showAction && (
          <OverlayButton wait="billingUpdateOrder" loadings={loadings}>
            <div className={styles.amount__action}>
              <div className={styles.amount__action__button}>
                <CheckCircleOutlineIcon
                  onClick={() => handleClickUpdateAddonQty(item)}
                  className={styles.checkIcon}
                />
              </div>
              <div className={styles.amount__action__button}>
                <HighlightOffIcon
                  onClick={handleCancelChangeAddon}
                  className={styles.cancelIcon}
                />
              </div>
            </div>
          </OverlayButton>
        )}
      </div>
    </div>
  );
};

AddOnCard.propTypes = {
  addon: PropTypes.object.isRequired,
  loadings: PropTypes.array.isRequired,
  handleClickUpdateAddonQty: PropTypes.func.isRequired,
};

export default memo(AddOnCard);
