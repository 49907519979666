import React, { useState, useContext, useEffect } from 'react';
import {
  useHistory, useParams, useLocation,
} from 'react-router-dom';
import Form from 'react-bootstrap/Form';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import MainLayout from '../../../components/Layout/MainLayout/MainLayout';
import { GlobalContext } from '../../../contexts/GlobalStateProvider';
import { actionTypes } from '../../../reducers/reducer';
import SeparatorLine from '../../../components/UI/SeparatorLine/SeparatorLine';
import Paper from '../../../components/UI/Paper/Paper';
import styles from './SetPublicCalendarPage.module.css';
import 'react-day-picker/lib/style.css';
import Title from '../../../components/Title/Title';
import handleStatusMsg from '../../../utilities/handleStatusMsg';
import handleLoadings from '../../../utilities/handleLoadings';
import PageLoading from '../../../components/UI/Button/PageLoading/PageLoading';
import SetPublicCalendarMenu from './SetPublicCalendarMenu/SetPublicCalendarMenu';
import GeneralSubNavBar from '../../../components/GeneralSubNavBar/GeneralSubNavBar';
import GlobalActionButton from '../../../components/GeneralSubNavBar/BottomNavBar/GlobalActionButton/GlobalActionButton';
import { generateRoutesAndTitle } from '../../../actions/BreadCrumbActions';
import gcalIcon from '../../../assets/SchedulePage/gcal.png';
import icalIcon from '../../../assets/SchedulePage/ical.png';
import outlookIcon from '../../../assets/SchedulePage/outlook.png';
import { initiateSchedule, initiateScheduleV2 } from '../../../actions/ScheduleActions';
import { SearchJumpToActions, TeamActions } from '../../../actions';
import { PageConstants } from '../../../constants';
import { LayoutMain } from '../../../components/UI_V2/Layout';
import { SurfacePaperMain } from '../../../components/UI_V2/Surface/Paper';

function SetPublicCalendarPage() {
  const [{
    user, currentSchedule, currentTeam, currentCompany,
  }, dispatch] = useContext(GlobalContext);
  const [loadings, setLoadings] = useState([]);
  const [showMenu, setShowMenu] = useState(false);

  const history = useHistory();
  const location = useLocation();
  const params = useParams();
  const { companyId, teamId, scheduleId } = params;

  useEffect(() => {
    if (user._id === undefined) {
      history.push({
        pathname: '/check-login',
        state: {
          from: location.pathname,
        },
      });
      return;
    }

    if (currentSchedule && currentSchedule?._id === scheduleId) return;

    const startLoadings = handleLoadings('setPublicCalendarPage', [...loadings], 'start');
    setLoadings([...startLoadings]);
    const fetchApiScheduleDetail = async () => {
      try {
        const result = await initiateScheduleV2({
          companyId,
          fromDate: new Date(),
          untilDate: new Date(),
          scheduleId,
          teamId,
        }, dispatch);
        await TeamActions.initiateTeam({
          teamId,
          companyId,
          currentTeam,
        }, dispatch);
      } catch (err) {
        const status = handleStatusMsg(err, 'error');

        dispatch({
          type: actionTypes.SET_ERROR_RESPONSE,
          errorResponse: { message: status.message },
        });

        history.push('/errors');
      } finally {
        const endLoadings = handleLoadings('setPublicCalendarPage', [...loadings], 'end');
        setLoadings([...endLoadings]);
      }
    };
    fetchApiScheduleDetail();
    // return () => socket.off('schedule')
  }, [location]);

  const handleShowMenu = () => {
    setShowMenu(!showMenu);
  };

  const handleCancelShowMenu = () => {
    setShowMenu(false);
  };

  const handleFocus = (event) => {
    event.target.select();
  };

  const handleClickGCal = () => {
    window.open('https://support.google.com/calendar/answer/37100?co=GENIE.Platform%3DDesktop&hl=en', '_blank');
  };

  const handleClickICal = () => {
    window.open('https://discussions.apple.com/thread/6034963#:~:text=to%20a%20calendar.-,Go%20to%20Settings%20%3E%20Mail%2C%20Contacts%2C%20Calendars%2C%20then%20tap,ics%20file%20to%20subscribe%20to.', '_blank');
  };

  const handleClickOutlook = () => {
    window.open('https://support.microsoft.com/en-us/office/import-calendars-into-outlook-8e8364e1-400e-4c0f-a573-fe76b5a2d379', '_blank');
  };

  const pageType = PageConstants.pageType.schedulesPublic;
  const breadcrumb = generateRoutesAndTitle(
    { type: pageType, data: { companyId, currentTeam, currentSchedule } },
  );
  SearchJumpToActions.saveRecentPage({
    company: currentCompany,
    team: currentTeam,
    userId: user?._id,
    itemId: `${scheduleId}-public`,
    url: breadcrumb.url,
    name: breadcrumb.title,
    type: pageType,
    routes: breadcrumb.routes,
  });

  return (
    <>
      <Title title="Jadwal" />
      <GeneralSubNavBar
        breadcrumbRoutes={breadcrumb.routes}
        pageTitle={breadcrumb.title}
        bottomBarOverviewOptionId="schedules"
        withBottomNavBar={false}
      />
      <LayoutMain>
        <PageLoading wait="setPublicCalendarPage" loadings={loadings}>
          <SurfacePaperMain>
            <div className={styles.container}>
              <div className={styles.headerSection}>
                <h1>Pilih Jadwal</h1>
              </div>
              <SeparatorLine />
              <div className={styles.eventsSection}>
                {!currentSchedule?.setPublic && !currentSchedule?.googleCalendar?.calendarIcsLink
                  ? (
                    <div className={styles.eventsSection__setPublic}>
                      <p>Sinkronisasikan realtime semua jadwal di Tim ini melalui :</p>
                      <div className={styles.logo}>
                        <div onClick={handleShowMenu} className={styles.logo__gCal}>
                          <LazyLoadImage src={gcalIcon} />
                          <a>Google Calendar</a>
                        </div>
                        <div onClick={handleShowMenu} className={styles.logo__iCal}>
                          <LazyLoadImage src={icalIcon} />
                          <a>Apple Calendar</a>
                        </div>
                        <div onClick={handleShowMenu} className={styles.logo__outlook}>
                          <LazyLoadImage src={outlookIcon} />
                          <a>Outlook</a>
                        </div>
                        {showMenu
                          ? (
                            <SetPublicCalendarMenu
                              left
                              schedule={currentSchedule}
                              cancel={handleCancelShowMenu}
                            />
                          ) : null}
                      </div>

                    </div>
                  )

                  : (
                    <div className={styles.eventsSection__setPublic}>
                      <p>
                        Sinkronisasikan realtime semua jadwal di Tim
                        ini menggunakan tautan berikut :

                      </p>
                      <div className={styles.formSection}>
                        <Form.Control
                          autoFocus
                          onFocus={(event) => handleFocus(event)}
                          value={currentSchedule?.googleCalendar?.calendarIcsLink}
                        />
                      </div>
                      <div className={styles.logo}>
                        <p>Liat cara menambahkan tautan ini ke : </p>
                        <div onClick={handleClickGCal} className={styles.logo__gCal}>
                          <LazyLoadImage src={gcalIcon} />
                          <a>Google Calendar</a>
                        </div>
                        <div onClick={handleClickICal} className={styles.logo__iCal}>
                          <LazyLoadImage src={icalIcon} />
                          <a>Apple Calendar</a>
                        </div>
                        <div onClick={handleClickOutlook} className={styles.logo__outlook}>
                          <LazyLoadImage src={outlookIcon} />
                          <a>Outlook</a>
                        </div>
                      </div>
                      {/* <div className={styles.unsetSection}>
                        <p onClick={handleShowMenu}>Or, remove this schedule as public calendar</p>
                        {showMenu
                        ? <SetPublicCalendarMenu left schedule={currentSchedule}
                        cancel={handleCancelShowMenu}/> : null}
                    </div> */}
                    </div>
                  )}
              </div>
            </div>
          </SurfacePaperMain>
        </PageLoading>
      </LayoutMain>
    </>
  );
}

export default SetPublicCalendarPage;
