import React, { memo, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Alert, useMediaQuery } from '@mui/material';
import styles from './ForgotPasswordSection.module.css';
import Color from '../../../themes/colors';
import { InputButtonMain } from '../../../components/UI_V2/Input/Button';
import { InputTextFieldMain } from '../../../components/UI_V2/Input/TextField';
import { DisplayTextBody } from '../../../components/UI_V2/Display/Text';
import { validateEmail } from '../../../utilities/inputValidatorUtil';
import LinkNoDecor from '../../../components/LinkNoDecor/LinkNoDecor';
import handleLoadings from '../../../utilities/handleLoadings';
import { AuthActions } from '../../../actions';
import handleStatusMsg from '../../../utilities/handleStatusMsg';

const ForgotPasswordSection = ({
  isFromEmailInvitation,
}) => {
  const [newUser, setNewUser] = useState({
    email: '',
    password: '',
  });

  const [loadings, setLoadings] = useState([]);

  const [isEmailEmpty, setIsEmailEmpty] = useState(true);
  const [isValidEmail, setIsValidEmail] = useState(true);
  const [errorMsgEmail, setErrorMsgEmail] = useState('');
  const [errorMsgEmpty, setErrorMsgEmpty] = useState('');
  const [showErrorEmpty, setShowErrorEmpty] = useState(false);
  const [errorMsgClickStillError, setErrorMsgClickStillError] = useState('');
  const [showErrorClickStillError, setShowErrorClickStillError] = useState(false);

  const [showSuccessResetPassword, setShowSuccessResetPassword] = useState(false);
  const [successResetPasswordMsg, setSuccessResetPasswordMsg] = useState('');

  const [showErrorResetPassword, setShowErrorResetPassword] = useState(false);
  const [errorResetPasswordMsg, setErrorResetPasswordMsg] = useState('');

  const [isProcessing, setIsProcessing] = useState(false);

  const handleChanged = (event) => {
    const { name, value } = event.target;
    setNewUser((prevValue) => ({
      ...prevValue,
      [name]: value,
    }));

    if (showErrorEmpty) {
      setShowErrorEmpty(false);
      setErrorMsgEmpty('');
    }

    if (showErrorClickStillError) {
      setShowErrorClickStillError(false);
      setErrorMsgClickStillError('');
    }

    if (showSuccessResetPassword) {
      setShowSuccessResetPassword(false);
      setSuccessResetPasswordMsg('');
    }

    if (showErrorResetPassword) {
      setShowErrorResetPassword(false);
      setErrorResetPasswordMsg('');
    }

    if (name === 'email') {
      const isEmpty = value.length < 1;

      if (isEmpty) {
        setIsEmailEmpty(true);
        setIsValidEmail(true);
        setErrorMsgEmail('');
        return;
      }

      setIsEmailEmpty(false);

      const validEmail = validateEmail(value);

      if (validEmail) {
        setIsValidEmail(true);
        setErrorMsgEmail('');
      } else {
        setIsValidEmail(false);
        setErrorMsgEmail('Email harus dengan format nama@domain.com (cth: john@doe.com)');
      }
    }
  };

  const isThereAnyEmpty = isEmailEmpty;

  const isThereAnyError = !isValidEmail;

  const handleClickWhenEmpty = (event) => {
    if (event) {
      event.preventDefault();
    }

    let message = '';
    if (isEmailEmpty) {
      message = `${message}Email masih kosong. `;
    }

    setErrorMsgEmpty(message);
    setShowErrorEmpty(true);
  };

  const handleClickWhenStillError = () => {
    setErrorMsgClickStillError('Belum bisa melanjutkan, perbaiki dulu error diatas.');
    setShowErrorClickStillError(true);
  };

  const processResetPasswordApi = async ({
    email,
  }) => {
    try {
      const startLoadings = handleLoadings('requestResetPassword', [...loadings], 'start');
      setLoadings([...startLoadings]);

      const result = await AuthActions.requestResetPassword({
        email,
      });

      // const status = handleStatusMsg(result, 'success');

      setShowSuccessResetPassword(true);
      setShowErrorResetPassword(false);
      setSuccessResetPasswordMsg('Permintaan reset password berhasil. Klik link di email kamu untuk melanjutkan.');
      setErrorResetPasswordMsg('');
    } catch (err) {
      const status = handleStatusMsg(err, 'error');

      setShowErrorResetPassword(true);
      setShowSuccessResetPassword(false);
      setErrorResetPasswordMsg(status.message);
      setSuccessResetPasswordMsg('');
    } finally {
      const endLoadings = handleLoadings('requestResetPassword', [...loadings], 'end');
      setLoadings([...endLoadings]);
      setIsProcessing(false);
    }
  };

  const handleClickRequestResetPassword = (event) => {
    if (event) {
      event.preventDefault();
    }

    if (isThereAnyEmpty) {
      handleClickWhenEmpty();
      return;
    }

    if (isThereAnyError) {
      handleClickWhenStillError();
      return;
    }

    if (isProcessing) return;

    setIsProcessing(true);

    processResetPasswordApi({
      email: newUser.email,
    });
  };

  const getSignInUrl = () => {
    if (isFromEmailInvitation) {
      return '/signin?sourcePage=emailInvitation';
    }
    return '/signin';
  };

  return (
    <div className={styles.container}>
      <form onSubmit={isThereAnyEmpty ? handleClickWhenEmpty : handleClickRequestResetPassword}>
        <div className={styles.form}>
          <div className={styles.formText}>
            <DisplayTextBody
              decoration="bold"
            >
              Email
            </DisplayTextBody>
          </div>
          <div className={styles.formArea}>
            <InputTextFieldMain
              placeholder="emailkamu@gmail.com"
              name="email"
              onChange={(event) => handleChanged(event)}
              value={newUser.email}
              border="rounded"
              type="email"
              size="lg"
            />
          </div>
        </div>
        {!isValidEmail && <Alert severity="error">{errorMsgEmail}</Alert>}

        {isThereAnyEmpty && showErrorEmpty && <div className={styles.alertEmptySection}><Alert severity="error">{errorMsgEmpty}</Alert></div>}
        {isThereAnyError && showErrorClickStillError && <div className={styles.alertEmptySection}><Alert severity="error">{errorMsgClickStillError}</Alert></div>}

        {showSuccessResetPassword && (
        <div className={styles.alertClickResetPasswordSection}>
          <Alert severity="success">{successResetPasswordMsg}</Alert>
        </div>
        )}
        {showErrorResetPassword && (
        <div className={styles.alertClickResetPasswordSection}>
          <Alert severity="error">{errorResetPasswordMsg}</Alert>
        </div>
        )}
        <div className={styles.action}>
          <InputButtonMain
            border="rounded"
            size="lg"
            wide="block"
            handleClick={isThereAnyEmpty ? handleClickWhenEmpty : handleClickRequestResetPassword}
            loadings={loadings}
            wait="requestResetPassword"
            type="submit"
          >
            Reset Password
          </InputButtonMain>
        </div>
      </form>
      <div className={styles.loginNowSection}>
        <div className={styles.loginNowSection__text}>
          <DisplayTextBody>
            Sudah punya akun?
          </DisplayTextBody>
        </div>
        <div className={styles.loginNowSection__button}>
          <LinkNoDecor to={getSignInUrl}>
            <DisplayTextBody color={Color.brightBlue}>
              Masuk
            </DisplayTextBody>
          </LinkNoDecor>
        </div>
      </div>
    </div>
  );
};

ForgotPasswordSection.propTypes = {
  isFromEmailInvitation: PropTypes.bool,
};

ForgotPasswordSection.defaultProps = {
  isFromEmailInvitation: false,
};

export default memo(ForgotPasswordSection);
