import React, { memo, useState } from 'react';
import PropTypes from 'prop-types';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { useMediaQuery } from '@mui/material';
import styles from './SuggestionRecapSection.module.css';
import Color from '../../../../themes/colors';
import { DisplayChartDoughnut } from '../../../../components/UI_V2/Display/Chart';
import { DisplayTextBody } from '../../../../components/UI_V2/Display/Text';
import BackgroundChart from '../../DataRealtimeReport/ChartUserReport/BackgroundChart/BackgroundChart';
import TitleChart from '../../DataRealtimeReport/ChartUserReport/TitleChart/TitleChart';
import ChartUserSkeleton from '../../DataRealtimeReport/ChartUserReport/ChartUserSkeleton/ChartUserSkeleton';
import SuggestionText from './SuggestionText/SuggestionText';

const SuggestionRecapSection = ({
  suggestionText,
  isEmptyData,
  isLoading,
}) => (
  <div className={styles.container}>
    <BackgroundChart>
      <div className={styles.title}>
        <TitleChart>
          Kesimpulan
        </TitleChart>
      </div>
      {isLoading && <div className={styles.skeleton}><ChartUserSkeleton /></div>}
      <div className={styles.body}>
        {!isLoading && (
          <div className={styles.statText}>
            {isEmptyData && (
            <DisplayTextBody>
              Belum ada data
            </DisplayTextBody>
            )}
            {!isEmptyData && (
            <SuggestionText suggestionText={suggestionText} />
            )}
          </div>
        )}
      </div>
    </BackgroundChart>
  </div>
);

SuggestionRecapSection.propTypes = {
  suggestionText: PropTypes.string,
  isEmptyData: PropTypes.bool.isRequired,
  isLoading: PropTypes.bool,
};

SuggestionRecapSection.defaultProps = {
  suggestionText: null,
  isLoading: true,
};

export default memo(SuggestionRecapSection);
