import {
  cloneDeep, isEmpty, isArray, isNumber, isObject, isString,
} from 'lodash';
import { actionTypes } from '../reducers/reducer';
import { ErrorException } from '../utilities/handleError';
import { setStoreUserToken } from '../utilities/localStorage';
import apiUtil from '../utilities/apiUtil';
import { ApiConstants } from '../constants';
import { getIndexItemByIDFromList, getItemByIDFromList } from '../utilities/arrayUtil';
import { hasPermission } from './RoleActions';
import { role, roles } from '../constants/RoleConstants';
import { REALTIME_API_URL } from '../constants/ApiConstants';

/*
Dispatcher
*/
const dispatchUser = ({ user }, dispatch) => {
  dispatch({
    type: actionTypes.SET_USER,
    user,
  });
};

/*
Setter Dispatcher
*/
const setUser = ({ user }, dispatch) => {
  if (!user) return;

  dispatchUser({ user: cloneDeep(user) }, dispatch);
};

/*
Method
*/

const checkIsUserAdmin = ({ company, userId }) => {
  const user = company?.admins?.find((admin) => admin?._id === userId);
  if (user) {
    return true;
  }
  return false;
};

const checkIsUserCreator = ({ data, userId }) => {
  const isCreator = data?.creator === userId;
  return isCreator;
};

const checkIsUserMember = ({ subscribersOrMembers, userId }) => {
  if (!isNumber(subscribersOrMembers?.length) || subscribersOrMembers?.length < 1) return false;

  if (isObject(subscribersOrMembers[0])) {
    const indexUserFromSubscribers = getIndexItemByIDFromList(
      subscribersOrMembers,
      { _id: userId },
    );

    return indexUserFromSubscribers >= 0;
  }

  if (isString(subscribersOrMembers[0])) {
    return subscribersOrMembers.includes(userId);
  }

  return false;
};

function checkCredential({ userId, locationName, history }) {
  if (userId === undefined) {
    history.push(`/check-login?previousPath=${locationName}`);
  }
}

const saveUserAndToken = ({ user, token }, dispatch) => {
  try {
    setStoreUserToken(token);
    setUser({ user }, dispatch);
  } catch (error) {
    throw new ErrorException(error);
  }
};

async function getUser({ userId }) {
  try {
    const result = await apiUtil.get(
      ApiConstants.URL_V1.USER({ userId }), null, {}, REALTIME_API_URL,
    );

    return result;
  } catch (error) {
    throw new ErrorException(error);
  }
}

async function updateUser({ userId, body, companyId }, dispatch) {
  try {
    const result = await apiUtil.patch(ApiConstants.URL_V1.USER({ userId }), body, {
      params: {
        companyId,
      },
    });
    localStorage.setItem('token', result?.data?.token);
    setUser({ user: result?.data?.user }, dispatch);
    return result;
  } catch (error) {
    throw new ErrorException(error);
  }
}

async function destroyRefreshToken() {
  try {
    const result = await apiUtil.post(ApiConstants.URL_V1.DESTROY_REFRESH_TOKEN(), {});
    return result;
  } catch (error) {
    throw new ErrorException(error);
  }
}

async function updateUserPhoto({ userId, body, companyId }, dispatch) {
  try {
    const result = await apiUtil.post(ApiConstants.URL_V1.USER_PHOTO({ userId }), body, {
      params: {
        companyId,
      },
    });
    // localStorage.setItem('token', result?.data?.token);
    setUser({ user: result?.data?.user }, dispatch);
    return result;
  } catch (error) {
    throw new ErrorException(error);
  }
}

/*
Helper
*/

const handleLogout = async ({ OneSignal, userId }) => {
  try {
    await destroyRefreshToken();
  } catch (error) {
    // do nothing, continue below code.
    // need to wrap it inside try catch like this
  }

  await OneSignal.logout();
  localStorage.removeItem('token');
  localStorage.removeItem('currentCompanies');
  localStorage.removeItem('isOptedOut');
  localStorage.removeItem('isPushNotificationsEnabled');
  localStorage.removeItem('os_pageViews');

  localStorage.setItem('logout', '1');
  setTimeout(() => {
    window.open(`${process.env.REACT_APP_BASE_CLIENT_URL}/check-login`, '_self');
  }, 5000);
};

// DEPRECATED. NOT USED ANYWHERE AGAIN.
// not using role user yet.
const checkIfUserHaveAccessToData = (data, user, company) => {
  const userId = user?._id;
  if (!data) return false;

  // data not yet have isPublic property
  if (data?.isPublic === undefined || userId === undefined) return true;

  if (data?.isPublic) return true;

  // user is superUser
  if (isArray(company?.admins)) {
    const adminsContainUser = company.admins?.[0]?._id
      ? company.admins.findIndex((obj) => obj._id === userId) > -1
      : company.admins.indexOf(userId) > -1;
    if (adminsContainUser) return true;
  }

  // user is the creator
  const creatorData = data?.creator;
  if (creatorData?._id === userId) return true;

  const subscribersOrMembers = data?.subscribers || data?.members;
  if (!isNumber(subscribersOrMembers?.length) || subscribersOrMembers?.length < 1) return false;

  if (isObject(subscribersOrMembers[0])) {
    const indexUserFromSubscribers = getIndexItemByIDFromList(
      subscribersOrMembers,
      { _id: userId },
    );

    return indexUserFromSubscribers >= 0;
  }

  if (isString(subscribersOrMembers[0])) {
    return subscribersOrMembers.includes(userId);
  }

  return false;
};

// works with roles and specific list data only, not button or feature or section.
const checkIfUserHaveAccessToDataV3 = (data, user, roleUser) => {
  const userId = user?._id;
  if (!data) return false;

  // data not yet have isPublic property
  if (data?.isPublic === undefined || userId === undefined) return true;

  if (data?.isPublic) return true;

  // user is superUser
  if (hasPermission(roleUser, roles.adminOrAbove)) return true;

  // user is the creator
  const creatorData = data?.creator;
  if (creatorData?._id === userId) return true;

  const subscribersOrMembers = data?.subscribers || data?.members;
  if (!isNumber(subscribersOrMembers?.length) || subscribersOrMembers?.length < 1) return false;

  if (isObject(subscribersOrMembers[0])) {
    const indexUserFromSubscribers = getIndexItemByIDFromList(
      subscribersOrMembers,
      { _id: userId },
    );

    return indexUserFromSubscribers >= 0;
  }

  if (isString(subscribersOrMembers[0])) {
    return subscribersOrMembers.includes(userId);
  }

  return false;
};

const checkIfDataPrivate = (data) => data?.isPublic !== undefined && !data?.isPublic;

const checkIfUserAuthorizedToEditData = (data, user, roleUser) => {
  const userId = user?._id;
  if (!data) return false;
  // dapetin role dari creatornya dan check apakah dia higher dari role creator
  const creatorData = data?.creator;

  // kalo admin ke atas udah pasti langsung boleh.
  if (hasPermission(roleUser, roles.adminOrAbove)) return true;

  // kalo creator langsung boleh.
  if ((creatorData?._id || creatorData) === userId) return true;

  // kalo manager bisa edit, ga perlu subscribers.
  const isManagerOrAbove = hasPermission(roleUser, roles.managerOrAbove);
  if (isManagerOrAbove) return true;

  // kalo supervisor bisa edit asal subscribers.
  const subscribersOrMembers = data?.subscribers || data?.members;
  if (!isNumber(subscribersOrMembers?.length) || subscribersOrMembers?.length < 1) return false;

  const isSpvOrAbove = hasPermission(roleUser, roles.spvOrAbove);
  if (isObject(subscribersOrMembers[0])) {
    const indexUserFromSubscribers = getIndexItemByIDFromList(
      subscribersOrMembers,
      { _id: userId },
    );

    return indexUserFromSubscribers >= 0 && isSpvOrAbove;
  }

  if (isString(subscribersOrMembers[0])) {
    return subscribersOrMembers.includes(userId) && isSpvOrAbove;
  }

  return false;
};

const checkIfUserAuthorizedToEditCardAttachment = (attachment, card, user, roleUser) => {
  const userId = user?._id;
  if (!attachment || !card) return false;
  // dapetin role dari creatornya dan check apakah dia higher dari role creator
  const creatorData = attachment?.creator;

  // kalo admin ke atas udah pasti langsung boleh.
  if (hasPermission(roleUser, roles.adminOrAbove)) return true;

  // kalo creator langsung boleh.
  if ((creatorData?._id || creatorData) === userId) return true;

  // kalo manager bisa edit, ga perlu subscribers.
  const isManagerOrAbove = hasPermission(roleUser, roles.managerOrAbove);
  if (isManagerOrAbove) return true;

  // kalo supervisor bisa edit asal subscribers.
  const subscribersOrMembers = card?.subscribers || card?.members;
  if (!isNumber(subscribersOrMembers?.length) || subscribersOrMembers?.length < 1) return false;

  const isSpvOrAbove = hasPermission(roleUser, roles.spvOrAbove);
  if (isObject(subscribersOrMembers[0])) {
    const indexUserFromSubscribers = getIndexItemByIDFromList(
      subscribersOrMembers,
      { _id: userId },
    );

    return indexUserFromSubscribers >= 0 && isSpvOrAbove;
  }

  if (isString(subscribersOrMembers[0])) {
    return subscribersOrMembers.includes(userId) && isSpvOrAbove;
  }

  return false;
};

// BACKUP, EDIT WITH CHECK BELOW ROLE
// const checkIfUserAuthorizedToEditData = (data, user, roleUser, teamBelowRole) => {
//   const userId = user?._id;
//   if (!data) return false;
//   // dapetin role dari creatornya dan check apakah dia higher dari role creator
//   const creatorData = data?.creator;
//   const membersBelowRole = teamBelowRole?.members || [];
//   const roleCreator = getItemByIDFromList(membersBelowRole, creatorData?._id);

//   // kalo super udah pasti langsung boleh.
//   if (hasPermission(roleUser, [role.super])) return true;

//   // kalo creator langsung boleh.
//   if (creatorData?._id === userId) return true;

//   // kalo admin edit asal higher dari creator dan ga perlu team members.
//   const isAdminAndHigherThanCreator = hasPermission(roleUser, [role.admin], roleCreator);
//   if (isAdminAndHigherThanCreator && roleCreator) return true;

//   // kalo manager bisa edit asal higher dari creator,
// perlu team members, tapi ga perlu subscribers.
//   const isManagerAndHigherThanCreator = hasPermission(roleUser, [role.manager], roleCreator);
//   if (isManagerAndHigherThanCreator && roleCreator) return true;

//   // kalo supervisor bisa edit asal higher dari creator,
// perlu team members, dan perlu subscribers.
//   const subscribersOrMembers = data?.subscribers || data?.members;
//   if (!isNumber(subscribersOrMembers?.length) || subscribersOrMembers?.length < 1) return false;

//   const isSpvAndHigherThanCreator = hasPermission(roleUser, [role.spv], roleCreator);
//   if (isObject(subscribersOrMembers[0])) {
//     const indexUserFromSubscribers = getIndexItemByIDFromList(
//       subscribersOrMembers,
//       { _id: userId },
//     );

//     return indexUserFromSubscribers >= 0 && isSpvAndHigherThanCreator && roleCreator;
//   }

//   if (isString(subscribersOrMembers[0])) {
//     return subscribersOrMembers.includes(userId) && isSpvAndHigherThanCreator && roleCreator;
//   }

//   return false;
// };

const checkIfUserAuthorizedToModifyCard = (data, user, roleUser) => {
  const userId = user?._id;

  if (!data) return false;
  // kalo admin or above bisa edit asal higher dari creator dan ga perlu team members.
  // sementara ga bisa check higher role dari creator.
  if (hasPermission(roleUser, roles.adminOrAbove)) return true;

  // kalo creator bisa edit.
  const creatorData = data?.creator;
  if ((creatorData?._id || creatorData) === userId) return true;

  // kalo manager bisa edit asal higher dari creator, perlu team members, tapi ga perlu subscribers.
  // sementara ga bisa check higher role dari creator.
  const isUserManager = roleUser.role === role.manager;
  if (isUserManager) return true;

  // kalo supervisor ke bawah perlu subscribers.
  const isUserSpvOrBelow = hasPermission(roleUser, roles.spvOrBelow);

  const subscribersOrMembers = data?.subscribers || data?.members;
  if (!isNumber(subscribersOrMembers?.length) || subscribersOrMembers?.length < 1) return false;

  if (isObject(subscribersOrMembers[0])) {
    const indexUserFromSubscribers = getIndexItemByIDFromList(
      subscribersOrMembers,
      { _id: userId },
    );

    return indexUserFromSubscribers >= 0 && isUserSpvOrBelow;
  }

  if (isString(subscribersOrMembers[0])) {
    return subscribersOrMembers.includes(userId) && isUserSpvOrBelow;
  }

  return false;
};

export {
  checkCredential,
  saveUserAndToken,
  checkIsUserAdmin,
  getUser,
  updateUser,
  updateUserPhoto,
  handleLogout,
  checkIfDataPrivate,
  checkIfUserHaveAccessToData,
  checkIfUserHaveAccessToDataV3,
  checkIfUserAuthorizedToEditData,
  checkIfUserAuthorizedToModifyCard,
  checkIfUserAuthorizedToEditCardAttachment,
};
