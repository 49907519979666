import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import styles from './Folder.module.css';
import FolderSnapshot from './FolderSnapshot/FolderSnapshot';
import FileSnapshot from './FileSnapshot/FileSnapshot';
import DocSnapshot from './DocSnapshot/DocSnapshot';
import { useFolderSocket } from '../../../hooks/BucketHooks';
import { GlobalContext } from '../../../contexts/GlobalStateProvider';
import PrivateIcon from '../../../components/UI/PrivateIcon/PrivateIcon';

const Folder = ({ folder, clicked }) => {
  const [{ user }, dispatch] = useContext(GlobalContext);

  useFolderSocket({
    bucketId: folder?._id,
    userId: user?._id,
  }, dispatch);

  const docs = [];
  folder.docs.forEach((doc) => {
    const newDoc = {
      ...doc,
      type: 'doc',
    };

    docs.push(newDoc);
  });

  const buckets = [];
  folder.buckets.forEach((bucket) => {
    const newBucket = {
      ...bucket,
      type: 'bucket',
    };

    buckets.push(newBucket);
  });

  const filesUpload = [];
  folder.files.forEach((fileUpload) => {
    const newFile = {
      ...fileUpload,
      type: 'file',
    };

    filesUpload.push(newFile);
  });

  // join 2 array
  let joinedDatas = [...docs, ...buckets, ...filesUpload];
  // sort by created date
  joinedDatas = joinedDatas.sort((a, b) => {
    const c = new Date(a.createdAt);
    const d = new Date(b.createdAt);
    return d - c;
  });

  return (
    <div onClick={clicked} className={styles.Folder}>
      <div className={styles.border}>
        <div className={styles.insideBorder} />
      </div>
      <div className={styles.main}>
        <div className={styles.main__title}>
          <h1>
            <PrivateIcon data={folder} size="small" />
            {' '}
            {folder.title}
          </h1>
        </div>
        <div className={styles.main__content}>
          {joinedDatas.map((docFile) => {
            if (docFile.type === 'doc') {
              return <DocSnapshot doc={docFile} />;
            } if (docFile.type === 'bucket') {
              return <FolderSnapshot folder={docFile} />;
            } if (docFile.type === 'file') {
              return <FileSnapshot file={docFile} />;
            }

            return null;
          })}
        </div>
      </div>
    </div>
  );
};

Folder.propTypes = {
  folder: PropTypes.object.isRequired,
  clicked: PropTypes.func.isRequired,
};

export default Folder;
