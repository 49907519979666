import React, { memo, useState } from 'react';
import PropTypes from 'prop-types';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import styles from './RoleBadgeExplanationItem.module.css';
import { DisplayTextBody } from '../../../../UI_V2/Display/Text';
import Color from '../../../../../themes/colors';
import RolePermissionInfo from '../../RolePemissionInfo/RolePermissionInfo';
import RoleUserBadge from '../../RoleUserBadge/RoleUserBadge';
import { DisplayDividerMain } from '../../../../UI_V2/Display/Divider';

const RoleBadgeExplanationItem = ({
  featureType,
  roleUser,
}) => (
  <div className={styles.container}>
    <div className={styles.roleBadge}>
      <RoleUserBadge roleUser={roleUser} />
    </div>
    <div className={styles.divider}>
      <DisplayDividerMain
        orientation="vertical"
        borderColor={Color.gray5}
        marginRight="1rem"
        marginLeft="1rem"
        marginTop="0px"
        marginBottom="0px"
      />
    </div>
    <div className={styles.rolePermissionInfo}>
      <RolePermissionInfo
        featureType={featureType}
        roleUser={roleUser}
        orientation="vertical"
      />
    </div>
  </div>
);

RoleBadgeExplanationItem.propTypes = {
  featureType: PropTypes.string.isRequired,
  roleUser: PropTypes.object.isRequired,
};

RoleBadgeExplanationItem.defaultProps = {};

export default memo(RoleBadgeExplanationItem);
