import React, { memo } from 'react';
import CheckIcon from '@mui/icons-material/Check';
import PropTypes from 'prop-types';
import styles from './LabelThin.module.css';

const LabelThin = ({
  color, block,
}) => (
  <div
    className={block ? styles.label__block : styles.label}
    style={{ backgroundColor: color }}
  />
);

LabelThin.propTypes = {
  color: PropTypes.string.isRequired,
  block: PropTypes.bool.isRequired,
};

// const isComponentDataEqual = (prevProps, nextProps) => {
//   const { name, color, checked } = nextProps;
//   return prevProps.name === name
//     && prevProps.color === color
//     && prevProps.checked === checked;
// };

export default memo(LabelThin);
