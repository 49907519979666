const elementId = {
  navbarAccountIcon: 'navbar_account_icon',
  navbarAccountChatIcon: 'navbar_account_chat_icon',
  // chatMessageTextField: 'chat_message_text_field',
  // groupChatMessageTextField: 'groupChat_message_text_field',
  chatNewChatIcon: 'chat_new_chat_icon',
  chatMessageSendButton: 'chat_message_send_button',
  chatMessageAttachmentButton: 'chat_message_attachment_button',
  chatMessageDropdown: 'chat_message_dropdown',
  chatMessageDeleteButton: 'chat_message_delete_button',
  chatMessageDeletePopupConfirmationYesButton: 'chat_message_delete_popup_confirmation_yes_button',
  groupChatMessageSendButton: 'groupChat_message_send_button',
  groupChatMessageAttachmentButton: 'groupChat_message_attachment_button',
  groupChatMessageDropdown: 'groupChat_message_dropdown',
  groupChatMessageDeleteButton: 'groupChat_message_delete_button',
  groupChatMessageDeletePopupConfirmationYesButton: 'groupChat_message_delete_popup_confirmation_yes_button',
  companyInviteButton: 'company_invite_button',
  companyInviteEmailTextField: 'company_invite_email_text_field',
  companyInviteEmailInviteButton: 'company_invite_email_invite_button',
  companyMemberToggleAddButton: 'company_member_toggle_add_button',
  teamMemberToggleAddButton: 'team_member_toggle_add_button',
  teamMemberToggleApplyButton: 'team_member_toggle_apply_button',
  welcome_createCompany_card: 'welcome_createCompany_card',
  welcome_linkInvitation_card: 'welcome_linkInvitation_card',
  createCompany_create_button: 'createCompany_create_button',
  createCompany_name_text_field: 'createCompany_name_text_field',
  createCompany_desc_text_field: 'createCompany_desc_text_field',
  quetionaire_profile_fullName_text_field: 'quetionaire_profile_fullName_text_field',
  quetionaire_profile_status_text_field: 'quetionaire_profile_status_text_field',
  quetionaire_profile_phoneNumber_text_field: 'quetionaire_profile_phoneNumber_text_field',
  quetionaire_profile_age_text_field: 'quetionaire_profile_age_text_field',
  quetionaire_profile_location_text_field: 'quetionaire_profile_location_text_field',
  quetionaire_profile_reasonUsingCicle_text_field: 'quetionaire_profile_reasonUsingCicle_text_field',
  quetionaire_profile_country_select: 'quetionaire_profile_country_select',
  quetionaire_profile_gender_select: 'quetionaire_profile_gender_select',
  quetionaire_profile_whereKnowCicle_select: 'quetionaire_profile_whereKnowCicle_select',
  quetionaire_profile_appUsedBeforeCicle_select: 'quetionaire_profile_appUsedBeforeCicle_select',
  quetionaire_profile_teamSizeCategory_select: 'quetionaire_profile_teamSizeCategory_select',
  quetionaire_profile_companyRemoteOrOfflineStatus_select: 'quetionaire_profile_companyRemoteOrOfflineStatus_select',
  quetionaire_profile_next_button: 'quetionaire_profile_next_button',
  home_intro_createTeam_button: 'home_intro_createTeam_button',
  createTeam_name_text_field: 'createTeam_name_text_field',
  createTeam_desc_text_field: 'createTeam_desc_text_field',
  createTeam_create_button: 'createTeam_create_button',
  updateTeam_name_text_field: 'updateTeam_name_text_field',
  updateTeam_desc_text_field: 'updateTeam_desc_text_field',
  updateTeam_update_button: 'updateTeam_update_button',
};

export {
  elementId,
};
