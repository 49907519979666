import React, { memo, useState } from 'react';
import PropTypes from 'prop-types';
import { Box } from '@mui/material';
import BlastPageSkeletonPost from './Post/Post';

const textStyles = {
  marginBottom: '5px',
};

const rectangularStyles = {
  marginBottom: '5px',
};

const sectionStyles = {
  marginBottom: '10px',
};

const BlastPageSkeleton = () => (
  <Box>
    <BlastPageSkeletonPost />
    <BlastPageSkeletonPost />
    <BlastPageSkeletonPost />
    <BlastPageSkeletonPost />
  </Box>
);

export default memo(BlastPageSkeleton);
