import React, { memo, useState } from 'react';
import PropTypes from 'prop-types';
import { Box, Skeleton } from '@mui/material';
import styles from './Header.module.css';

const CommentDiscussPageSkeletonHeader = () => (
  <Box className={styles.container}>
    <Box className={styles.introText}>
      <Skeleton
        animation="wave"
        variant="text"
        // width={500}
      />
    </Box>
    <Box className={styles.headerPost}>
      <Box className={styles.photo}>
        <Skeleton
          animation="wave"
          variant="circular"
          width={40}
          height={40}
        />
      </Box>
      <Box className={styles.item}>
        <Box className={styles.titleAndDate}>
          <Box className={styles.title}>
            <Skeleton
              animation="wave"
              variant="text"
              height={24}
              width={150}
            />
          </Box>
          <Box className={styles.date}>
            <Skeleton
              animation="wave"
              variant="text"
              width={70}
            />
          </Box>
        </Box>
        <Box className={styles.creator}>
          <Skeleton
            animation="wave"
            variant="text"
            // width={140}
          />
        </Box>
      </Box>
    </Box>
  </Box>
);

export default memo(CommentDiscussPageSkeletonHeader);
