import React, { memo } from 'react';
import PropTypes from 'prop-types';
import LoadingItem from '../Drawer/LoadingItem/LoadingItem';

const FeedbackLoadingBlock = ({
  loadingUpload,
  listFilesBeingUploaded,
  progressUpload,
  backgroundColorItem,
  heightItem,
}) => (
  <>
    {loadingUpload ? (
      <>
        {listFilesBeingUploaded.map((file) => {
          const isProgressUploadFinish = progressUpload[file._id] === 100;
          const isNotYetUploaded = progressUpload[file._id] === undefined;

          if (isProgressUploadFinish) return <></>;
          return (
            <LoadingItem
              isProgressUploadFinish={isProgressUploadFinish}
              isNotYetUploaded={isNotYetUploaded}
              progressUpload={progressUpload}
              file={file}
              backgroundColor={backgroundColorItem}
              height={heightItem}
            />
          );
        })}
      </>
    ) : null}
  </>
);

FeedbackLoadingBlock.propTypes = {
  loadingUpload: PropTypes.bool,
  progressUpload: PropTypes.object,
  listFilesBeingUploaded: PropTypes.array,
  backgroundColorItem: PropTypes.string,
  heightItem: PropTypes.string,
};

FeedbackLoadingBlock.defaultProps = {
  loadingUpload: false,
  progressUpload: {},
  listFilesBeingUploaded: [],
  backgroundColorItem: undefined,
  heightItem: undefined,
};

export default memo(FeedbackLoadingBlock);
