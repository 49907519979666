import React, {
  useContext, memo,
} from 'react';
import { Link } from 'react-router-dom';
import MenuIcon from '@mui/icons-material/Menu';
import PropTypes from 'prop-types';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import NotificationContainer from '../../pages/NotificationContainer/NotificationContainer';
import styles from './NavHeader.module.css';
import logoImage from '../../assets/Logo/logo-new.png';
import { GlobalContext } from '../../contexts/GlobalStateProvider';
import CompaniesMenuContainer from '../../pages/CompaniesMenuContainer/CompaniesMenuContainer';
import ActivityContainer from '../../pages/ActivityContainer/ActivityContainer';
import { DisplayAvatarMemberWithOnline } from '../UI_V2/Display/Avatar';
import { elementId } from '../../constants/ElementIdConstants';
import { handleCloseSidebar, handleOpenSidebar } from '../../actions/SidebarActions';
import TeamCardMenu from '../../pages/DashboardPage/FetchTeamsCards/Card/TeamCardMenu/TeamCardMenu';
import UserMenuContainer from '../../pages/UserMenuContainer/UserMenuContainer';

const containerWithSidebarStyles = {
  sidebarOpenKanban: `${styles.container} ${styles.sidebarOpenKanban}`,
  sidebarOpenNotKanban: `${styles.container} ${styles.sidebarOpenNotKanban}`,
  sidebarClosed: styles.container,
};

function NavHeader({
  params,
  handleCancelShow,
  handleShowUserMenu,
  isKanbanView,
}) {
  const [
    {
      user,
      showUserMenu,
      showNotification,
      showActivityMenu,
      showCompaniesSelect,
      currentCompany,
      isSidebarOpen,
      showEditTeamMenu,
      showEditTeamFavoriteMenu,
      selectedEditTeam,
      selectedEditTeamFavorite,
      currentTeamFavorites,
    },
    dispatch,
  ] = useContext(GlobalContext);

  const { companyId } = params;

  // let showEditTeamMenuTop = '25rem';

  // if (currentTeamFavorites.data.length <= 1) {
  //   showEditTeamMenuTop = '18rem';
  // }

  // if (currentTeamFavorites.data.length === 2) {
  //   showEditTeamMenuTop = '20rem';
  // }

  // if (currentTeamFavorites.data.length === 3) {
  //   showEditTeamMenuTop = '22rem';
  // }

  // if (currentTeamFavorites.data.length === 4) {
  //   showEditTeamMenuTop = '24rem';
  // }

  let sidebarStyles;

  if (isSidebarOpen) {
    if (isKanbanView) {
      sidebarStyles = 'sidebarOpenKanban';
    } else {
      sidebarStyles = 'sidebarOpenNotKanban';
    }
  } else {
    sidebarStyles = 'sidebarClosed';
  }

  return (
    <>
      <div className={containerWithSidebarStyles[sidebarStyles]}>
        <div className={styles.Navbar__brand}>
          <div className={styles.drawerAndLogo}>
            <div
              className={styles.drawerIcon}
              onClick={isSidebarOpen
                ? () => handleCloseSidebar({}, dispatch)
                : () => handleOpenSidebar({}, dispatch)}
            >
              <MenuIcon />
              {/* <DisplayBadgeCounter
              badgeContent={counterNotif.nonChat
                 + counterNotif.chat + counterCompaniesNotif.totalCounter}
              variant="dot"
              height="10px"
              minWidth="10px"
              customStyle={{ top: -3, right: 2 }}
            /> */}
            </div>
            <Link to={companyId ? `/companies/${companyId}` : '/'}>
              <div className={styles.logoIcon}>
                <LazyLoadImage
                  alt="logo"
                  src={currentCompany?.logo ? currentCompany?.logo : logoImage}
                />
              </div>
            </Link>
          </div>
          <div className={styles.rightColumn}>
            <DisplayAvatarMemberWithOnline
              src={user?.photoUrl}
              positionPopUp="topRight"
              size="sm"
              marginRight="0px"
              userId={user?._id}
              enableOnClickCustom
            />
          </div>
        </div>
        {user._id ? (
          <>
            <div className={styles.popUpSidebarSection}>
              {/* {showActivityMenu && (
              <ActivityContainer
                params={params}
                cancel={() => handleCancelShow('activityMenu')}
              />
              )}
              {showNotification && (
                <NotificationContainer
                  params={params}
                  cancel={() => handleCancelShow('notifMenu')}
                />
              )}
              {showCompaniesSelect && (
                <CompaniesMenuContainer
                  cancel={() => handleCancelShow('companiesMenu')}
                />
              )}
              {showEditTeamMenu && (
              <TeamCardMenu
                team={selectedEditTeam}
                cancel={() => handleCancelShow('editTeamMenu')}
                isCanCreateTeam
                customParams={params}
                customStyle={{
                  position: 'absolute',
                  top: showEditTeamMenuTop,
                }}
              />
              )}
              {showEditTeamFavoriteMenu && (
              <TeamCardMenu
                team={selectedEditTeamFavorite}
                cancel={() => handleCancelShow('editTeamFavoriteMenu')}
                isCanCreateTeam
                customParams={params}
                customStyle={{
                  position: 'absolute',
                  top: '14rem',
                }}
                isFromTeamFavorite
              />
              )}
              {showUserMenu && (
                <UserMenuContainer params={params} cancel={() => handleCancelShow('userMenu')} />
              )} */}
            </div>
            {/* <div className={styles.searchSection}>
              <SearchJumpToContainer params={params} />
            </div> */}
            <div className={styles.rightSection}>
              {/* <div className={styles.companyNameSection}>
                <DisplayTextBody
                  decoration="bold"
                  fontFamily="openSans"
                  color={Color.gray3}
                >
                  {companyId ? limitChar(currentCompany?.name, isMobile ? 8 : 35) : null}
                </DisplayTextBody>
              </div> */}
              {/* <div className={styles.navIconSection}>
                <NavIcon clicked={handleShowCompaniesSelect}>
                  <Tooltip title="Ganti Perusahaan">
                    <MapsHomeWorkOutlinedIcon />
                  </Tooltip>
                  <DisplayBadgeCounter
                    badgeContent={counterCompaniesNotif.totalCounter}
                    variant="dot"
                    height="10px"
                    minWidth="10px"
                    customStyle={{ top: -4, right: 10 }}
                  />
                </NavIcon>
                {showCompaniesSelect ? (
                  <CompaniesMenuContainer
                    cancel={() => handleCancelShow('companiesMenu')}
                  />
                ) : null}
              </div> */}
              {/* <div className={styles.navIconSection}>
                <LinkNoDecor to={myTaskCardViewUrl}>
                  <NavIcon>
                    <Tooltip title="Semua Tugas">
                      <AssignmentIcon />
                    </Tooltip>
                  </NavIcon>
                </LinkNoDecor>
              </div> */}
              {/* <div className={styles.navIconSection}>
                <NavIcon clicked={handleShowActivityMenu}>
                  <Tooltip title="Lihat Aktifitas">
                    <HistoryIcon />
                  </Tooltip>
                </NavIcon>
                {showActivityMenu && (
              <ActivityContainer
                params={params}
                cancel={() => handleCancelShow('activityMenu')}
              />
              )}
              </div> */}
              {/* <div className={`${styles.navIconSection} ${styles.navIconSectionLast}`}>
                <NavIcon clicked={handleShowNotification}>
                  <Tooltip title="Notifikasi">
                    <NotificationsNoneIcon />
                  </Tooltip>
                  <DisplayBadgeCounter
                    badgeContent={counterNotif.nonChat}
                    customStyle={{ top: -3, right: 12 }}
                  />
                </NavIcon>
                {showNotification ? (
                  <NotificationContainer
                    params={params}
                    cancel={() => handleCancelShow('notifMenu')}
                  />
                ) : null}
              </div> */}
              <div
                id={elementId.navbarAccountIcon}
                className={styles.navMemberSection}
                onClick={() => handleShowUserMenu()}
              >
                {/* <ImageMember
                  photoUrl={user?.photoUrl}
                /> */}
                {/* <DisplayBadgeCounter
                  badgeContent={counterNotif.chat}
                  customStyle={{ top: -14.5, right: 1 }}
                /> */}
              </div>
            </div>
          </>
        ) : null}
      </div>
    </>
  );
}

NavHeader.propTypes = {
  params: PropTypes.object,
  handleCancelShow: PropTypes.func,
  handleShowUserMenu: PropTypes.func,
  isKanbanView: PropTypes.bool,
};

NavHeader.defaultProps = {
  params: { companyId: null, teamId: null },
  handleCancelShow: () => null,
  handleShowUserMenu: () => null,
  isKanbanView: false,
};

export default memo(NavHeader);
