import React, {
  memo,
} from 'react';
import PropTypes from 'prop-types';
import styles from './ListMembersGroupChat.module.css';
import ChatPersonItem from '../../../PrivateChatPage/SideBarChatSection/ChatPersonItem/ChatPersonItem';
import GroupChatPersonItem from './GroupChatPersonItem/GroupChatPersonItem';

const ListMembersGroupChat = ({ members, groupChatId }) => (
  <div className={styles.container}>
    {members?.map((member) => (
      <GroupChatPersonItem
        fullName={member.fullName}
        photoUrl={member.photoUrl}
        selectedChatId={groupChatId}
        userId={member._id}
        positionPopUpOnlineOnPhoto="topLeft"
      />
    ))}
  </div>
);

ListMembersGroupChat.propTypes = {
  members: PropTypes.array.isRequired,
  groupChatId: PropTypes.string.isRequired,
};

export default memo(ListMembersGroupChat);
