import React, { memo } from 'react';
import ArchiveIcon from '@mui/icons-material/Archive';
import PropTypes from 'prop-types';
import styles from './ArchivedIcon.module.css';

const ArchivedIcon = ({ archived, clicked }) => (
  <>
    {!archived ? null : (
      <div onClick={clicked} className={styles.ArchivedIcon}>
        <ArchiveIcon />
      </div>
    ) }
  </>
);

ArchivedIcon.propTypes = {
  archived: PropTypes.bool.isRequired,
  clicked: PropTypes.func.isRequired,
};

const isComponentDataEqual = (prevProps, nextProps) => {
  const { archived } = nextProps;
  return prevProps.archived === archived;
};

export default memo(ArchivedIcon, isComponentDataEqual);
