import React from 'react';
import PropTypes from 'prop-types';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import { Line } from 'react-chartjs-2';
import Color from '../../../../../themes/colors';

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
);

const borderColorStyles = [
  Color.blueAquaMarine,
  Color.yellowAccentCicle,
  Color.tosca,
  Color.gray3,
  Color.deleteColor,
];

const LineChart = ({ labels, datasets }) => {
  const datasetsData = datasets.map((elem, index) => ({
    label: elem.label,
    data: elem.data,
    fill: false,
    borderColor: borderColorStyles[index],
    tension: 0.1,
  }));

  const chartData = {
    labels,
    datasets: datasetsData,
  };

  const chartOptions = {
    responsive: true,
    scales: {
      y: {
        beginAtZero: true,
      },
    },
  };

  return <Line data={chartData} options={chartOptions} />;
};

LineChart.propTypes = {
  labels: PropTypes.array,
  datasets: PropTypes.array,
};

LineChart.defaultProps = {
  labels: [],
  datasets: [],
};

export default LineChart;
