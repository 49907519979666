/* eslint-disable no-shadow */
/* eslint-disable consistent-return */
import { useCallback, useEffect, useRef } from 'react';
import { scroller, animateScroll } from 'react-scroll';
import { CheerActions, CommentActions } from '../actions';
import { CheerConstants, CommentConstants } from '../constants';
import { connectSocketDiscussionComment } from '../services/socket';
import { getStore, setStore } from '../utilities/localStorage';
import { useSocketHooks } from './SocketHooks';

function useDiscussionMainCommentSocket({
  actionSocket, paramsSocket, commentId, eventSocket,
}, dispatch) {
  const callbackCommentSocket = useCallback((comment) => {
    // eslint-disable-next-line prefer-const
    let { currentComment, userId } = comment;
    if (!currentComment) currentComment = comment;

    if (currentComment?._id === commentId) {
      CommentActions.setCurrentComment({
        currentComment,
      }, dispatch);
    }
  }, []);

  const listenSocket = (socket, params) => {
    socket
      .on(eventSocket, callbackCommentSocket);
  };
  const removeListener = (socket, params) => {
    socket
      .off(eventSocket);
    socket.disconnect();
  };

  useSocketHooks({
    params: { ...paramsSocket, commentId },
    connectSocket: actionSocket,
    listenSocket,
    removeListener,
  });
}

function useDiscussionSocket({
  typeDiscussion, commentId,
}, dispatch) {
  useDiscussionMainCommentSocket({
    actionSocket: typeDiscussion?.actionSocket,
    paramsSocket: typeDiscussion?.paramsSocket,
    commentId,
    eventSocket: typeDiscussion?.eventSocket,
  }, dispatch);
}

function useDiscussionCheerNCommentSocket({ userId, commentId }, dispatch) {
  const callbackNewCheer = useCallback((cheer) => {
    CheerActions.incomingCheerFromPrimaryParent({
      cheer,
      typeAction: CheerConstants.typeCallback.NEW,
      primaryParentAction:
      ({ updateStateParent }) => CommentActions.dispatchUpdateCurrentComment({
        updateComment: updateStateParent,
      }, dispatch),
      keyProperty: 'cheers',
    });
  }, []);
  const callbackDeleteCheer = useCallback((cheer) => {
    CheerActions.incomingCheerFromPrimaryParent({
      cheer,
      typeAction: CheerConstants.typeCallback.DELETE,
      primaryParentAction:
      ({ updateStateParent }) => CommentActions.dispatchUpdateCurrentComment({
        updateComment: updateStateParent,
      }, dispatch),
      keyProperty: 'cheers',
    });
  }, []);

  const callbackNewCommentDiscussion = useCallback((discussion) => {
    CommentActions.incomingDiscussionComment({
      discussion,
      typeAction: CommentConstants.typeCallback.NEW,
    }, dispatch);
  }, []);
  const callbackUpdateCommentDiscussion = useCallback((discussion) => {
    CommentActions.incomingDiscussionComment({
      discussion,
      typeAction: CommentConstants.typeCallback.EDIT,
    }, dispatch);
  }, []);
  const callbackDeleteCommentDiscussion = useCallback((discussion) => {
    CommentActions.incomingDiscussionComment({
      discussion,
      typeAction: CommentConstants.typeCallback.DELETE,
    }, dispatch);
  }, []);

  const callbackNewCheerCommentDiscussion = useCallback((cheer) => {
    CheerActions.incomingCheerFromCommentDiscussion({
      cheer,
      typeAction: CheerConstants.typeCallback.NEW,
      secondaryParentAction: CommentActions.incomingDiscussionComment,
      typeActionEditSecondaryParent: CommentConstants.typeCallback.EDIT,
    }, dispatch);
  }, []);
  const callbackDeleteCheerDiscussion = useCallback((cheer) => {
    CheerActions.incomingCheerFromCommentDiscussion({
      cheer,
      typeAction: CheerConstants.typeCallback.DELETE,
      secondaryParentAction: CommentActions.incomingDiscussionComment,
      typeActionEditSecondaryParent: CommentConstants.typeCallback.EDIT,
    }, dispatch);
  }, []);

  const listenSocket = (socket, params) => {
    socket
      .on('cheer-new', callbackNewCheer)
      .on('cheer-delete', callbackDeleteCheer)
      .on(`commentDiscussion-new-${params?.commentId}`, callbackNewCommentDiscussion)
      .on(`commentDiscussion-update-${params?.commentId}`, callbackUpdateCommentDiscussion)
      .on(`commentDiscussion-delete-${params?.commentId}`, callbackDeleteCommentDiscussion)
      .on('cheerDiscussion-new', callbackNewCheerCommentDiscussion)
      .on('cheerDiscussion-delete', callbackDeleteCheerDiscussion);
  };
  const removeListener = (socket, params) => {
    socket
      .off('cheer-new')
      .off('cheer-delete')
      .off(`commentDiscussion-new-${params?.commentId}`)
      .off(`commentDiscussion-update-${params?.commentId}`)
      .off(`commentDiscussion-delete-${params?.commentId}`)
      .off('cheerDiscussion-new')
      .off('cheerDiscussion-delete');
    socket.disconnect();
  };

  useSocketHooks({
    params: { commentId, userId },
    connectSocket: connectSocketDiscussionComment,
    listenSocket,
    removeListener,
  });
}

function useScrollToTargetComment() {
  const idTimeout = useRef(null);
  const scrollToTargetComment = () => {
    const previousCommentDiscussion = getStore(
      'previousCommentDiscussion',
      { parse: true },
    );
    if (!previousCommentDiscussion?.commentId) return;
    animateScroll.scrollToTop();
    idTimeout.current = setTimeout(() => {
      scroller.scrollTo(previousCommentDiscussion?.commentId, {
        duration: 1000,
        delay: 100,
        smooth: true,
        offset: -60,
      });
    }, 100);
    setStore('previousCommentDiscussion', {});
  };

  useEffect(() => () => {
    if (idTimeout?.current) {
      clearTimeout(idTimeout);
    }
  }, []);

  return {
    scrollToTargetComment,
  };
}

export {
  useDiscussionSocket,
  useDiscussionCheerNCommentSocket,
  useScrollToTargetComment,
};
