import { io } from 'socket.io-client';

/*
  Primary Socket
*/

const connectSocketDiscussionComment = ({ commentId, userId }) => io(`${process.env.REACT_APP_PRIMARY_SOCKET_URL}/socket/comments/${commentId}`, {
  query: {
    userId,
  },
  forceNew: true,
  withCredentials: true,
});

const connectSocketCheers = ({ primaryParentType, userId }) => io(`${process.env.REACT_APP_PRIMARY_SOCKET_URL}/socket/cheers/${primaryParentType}`, {
  query: {
    userId,
  },
  forceNew: true,
  withCredentials: true,
});

const connectSocketGroupChat = ({ groupChatId, userId }) => io(`${process.env.REACT_APP_PRIMARY_SOCKET_URL}/socket/group-chats/${groupChatId}`, {
  query: {
    userId,
  },
  forceNew: true,
  withCredentials: true,
});

const connectSocketPrivateChat = ({ chatId, userId }) => io(`${process.env.REACT_APP_PRIMARY_SOCKET_URL}/socket/chats/${chatId}`, {
  query: {
    userId,
  },
  forceNew: true,
  withCredentials: true,
});

const connectSocketRecentChats = ({ companyId, userId }) => io(`${process.env.REACT_APP_PRIMARY_SOCKET_URL}/socket/recent-chats/${userId}/company/${companyId}`, {
  query: {
    userId,
  },
  forceNew: true,
  withCredentials: true,
});

const connectSocketQuestion = ({ questionId, userId }) => io(`${process.env.REACT_APP_PRIMARY_SOCKET_URL}/socket/questions/${questionId}`, {
  query: {
    userId,
  },
  forceNew: true,
  withCredentials: true,
});

const connectSocketPost = ({ postId, userId }) => io(`${process.env.REACT_APP_PRIMARY_SOCKET_URL}/socket/posts/${postId}`, {
  query: {
    userId,
  },
  forceNew: true,
  withCredentials: true,
});

const connectSocketEvent = ({ eventId, userId }) => io(`${process.env.REACT_APP_PRIMARY_SOCKET_URL}/socket/events/${eventId}`, {
  query: {
    userId,
  },
  forceNew: true,
  withCredentials: true,
});

// occurences <== type harusnya occurrence
const connectSocketOccurrence = ({ eventId, occurrenceId, userId }) => io(`${process.env.REACT_APP_PRIMARY_SOCKET_URL}/socket/events/${eventId}/occurences/${occurrenceId}`, {
  query: {
    userId,
  },
  forceNew: true,
  withCredentials: true,
});

const connectSocketOccurrenceNoTypo = ({ eventId, occurrenceId, userId }) => io(`${process.env.REACT_APP_PRIMARY_SOCKET_URL}/socket/events/${eventId}/occurrences/${occurrenceId}`, {
  query: {
    userId,
  },
  forceNew: true,
  withCredentials: true,
});

const connectSocketDoc = ({ docId, userId }) => io(`${process.env.REACT_APP_PRIMARY_SOCKET_URL}/socket/docs/${docId}`, {
  query: {
    userId,
  },
  forceNew: true,
  withCredentials: true,
});

const connectSocketFile = ({ fileId, userId }) => io(`${process.env.REACT_APP_PRIMARY_SOCKET_URL}/socket/files/${fileId}`, {
  query: {
    userId,
  },
  forceNew: true,
  withCredentials: true,
});

const connectSocketBlast = ({ blastId, userId }) => io(`${process.env.REACT_APP_PRIMARY_SOCKET_URL}/socket/blasts/${blastId}`, {
  query: {
    userId,
  },
  forceNew: true,
  withCredentials: true,
});

const connectSocketCheckIn = ({ checkInId, userId }) => io(`${process.env.REACT_APP_PRIMARY_SOCKET_URL}/socket/check-ins/${checkInId}`, {
  query: {
    userId,
  },
  forceNew: true,
  withCredentials: true,
});

const connectSocketBucket = ({ bucketId, userId }) => io(`${process.env.REACT_APP_PRIMARY_SOCKET_URL}/socket/buckets/${bucketId}`, {
  query: {
    userId,
  },
  forceNew: true,
  withCredentials: true,
});

const connectSocketSchedule = ({ scheduleId, userId }) => io(`${process.env.REACT_APP_PRIMARY_SOCKET_URL}/socket/schedules/${scheduleId}`, {
  query: {
    userId,
  },
  forceNew: true,
  withCredentials: true,
});

const connectSocketCompany = ({ userId, companyId }) => io(`${process.env.REACT_APP_PRIMARY_SOCKET_URL}/socket/companies/${companyId}`, {
  query: {
    userId,
  },
  forceNew: true,
  withCredentials: true,
});

const connectSocketTeam = ({ userId, teamId }) => io(`${process.env.REACT_APP_PRIMARY_SOCKET_URL}/socket/teams/${teamId}`, {
  query: {
    userId,
  },
  forceNew: true,
  withCredentials: true,
});

const connectSocketBoard = ({ boardId, userId }) => io(`${process.env.REACT_APP_PRIMARY_SOCKET_URL}/socket/boards/${boardId}`, {
  query: {
    userId,
  },
  forceNew: true,
  withCredentials: true,
});

const connectSocketCard = ({ cardId, userId }) => io(`${process.env.REACT_APP_PRIMARY_SOCKET_URL}/socket/cards/${cardId}`, {
  query: {
    userId,
  },
  forceNew: true,
  withCredentials: true,
});

const connectSocketBoardPopulatedCard = ({ userId, boardId }) => io(`${process.env.REACT_APP_PRIMARY_SOCKET_URL}/socket/boards-populated-card/${boardId}`, {
  query: {
    userId,
  },
  forceNew: true,
  withCredentials: true,
});

const connectSocketTeamFavorite = ({ companyId, userId }) => io(`${process.env.REACT_APP_PRIMARY_SOCKET_URL}/socket/team-favorites/users/${userId}/companies/${companyId}`, {
  query: {
    companyId,
    userId,
  },
  forceNew: true,
  withCredentials: true,
});

/*
  Primary Socket - My Task
*/
const connectSocketMyTaskCard = ({ companyId, userId }) => io(`${process.env.REACT_APP_PRIMARY_SOCKET_URL}/socket/my-tasks/cards/users/${userId}/companies/${companyId}`, {
  query: {
    companyId,
    userId,
  },
  forceNew: true,
  withCredentials: true,
});

/*
  Realtime Socket
*/

const connectSocketUserOnline = ({ companyId, userId }) => io(`${process.env.REACT_APP_REALTIME_SOCKET_URL}/socket/user-online/companies`, {
  query: {
    userId,
    companyId,
  },
  forceNew: true,
  withCredentials: true,
});

/*
  Notif Activity Socket
*/

const connectSocketNotification = ({ userId, companyId, filter }) => io(`${process.env.REACT_APP_NOTIF_ACTIVITY_SOCKET_URL}/socket/notifications`, {
  query: {
    userId,
    companyId,
    filter,
  },
  forceNew: true,
  withCredentials: true,
});

const connectSocketNotificationTeam = ({
  userId, companyId, teamId, filter,
}) => io(`${process.env.REACT_APP_NOTIF_ACTIVITY_SOCKET_URL}/socket/notifications/team`, {
  query: {
    userId,
    teamId,
    companyId,
    filter,
  },
  forceNew: true,
  withCredentials: true,
});

const connectSocketNotificationChat = ({ userId, companyId }) => io(`${process.env.REACT_APP_NOTIF_ACTIVITY_SOCKET_URL}/socket/notifications-chat`, {
  query: {
    userId,
    companyId,
  },
  forceNew: true,
  withCredentials: true,
});

const connectSocketNotificationCounterTeams = ({ userId, companyId }) => io(`${process.env.REACT_APP_NOTIF_ACTIVITY_SOCKET_URL}/socket/notifications-counter/teams`, {
  query: {
    userId,
    companyId,
  },
  forceNew: true,
  withCredentials: true,
});

const connectSocketNotificationCounter = ({ userId, companyId }) => io(`${process.env.REACT_APP_NOTIF_ACTIVITY_SOCKET_URL}/socket/notifications-counter`, {
  query: {
    userId,
    companyId,
  },
  forceNew: true,
  withCredentials: true,
});

const connectSocketNotificationCounterTeam = ({ userId, teamId }) => io(`${process.env.REACT_APP_NOTIF_ACTIVITY_SOCKET_URL}/socket/notifications-counter`, {
  query: {
    userId,
    teamId,
  },
  forceNew: true,
  withCredentials: true,
});

const connectSocketNotificationCounterCompanies = ({ userId, companyId }) => io(`${process.env.REACT_APP_NOTIF_ACTIVITY_SOCKET_URL}/socket/notifications-counter/companies`, {
  query: {
    userId,
    companyId,
  },
  forceNew: true,
  withCredentials: true,
});

/*
  User Typing Socket
*/

const connectSocketUserTypingChat = ({ userId, chatId, companyId }) => io(`${process.env.REACT_APP_REALTIME_SOCKET_URL}/socket/user-typing/chats/${chatId}`, {
  query: {
    userId,
    companyId,
  },
  forceNew: true,
  withCredentials: true,
});

const connectSocketUserTypingGroupChat = ({
  userId, groupChatId, companyId, teamId,
}) => io(`${process.env.REACT_APP_REALTIME_SOCKET_URL}/socket/user-typing/group-chats/${groupChatId}`, {
  query: {
    userId,
    companyId,
    teamId,
  },
  forceNew: true,
  withCredentials: true,
});

const connectSocketUserTypingCard = ({
  userId, cardId, companyId, teamId,
}) => io(`${process.env.REACT_APP_REALTIME_SOCKET_URL}/socket/user-typing/cards/${cardId}`, {
  query: {
    userId,
    companyId,
    teamId,
  },
  forceNew: true,
  withCredentials: true,
});

const connectSocketUserTypingPost = ({
  userId, postId, companyId, teamId,
}) => io(`${process.env.REACT_APP_REALTIME_SOCKET_URL}/socket/user-typing/posts/${postId}`, {
  query: {
    userId,
    companyId,
    teamId,
  },
  forceNew: true,
  withCredentials: true,
});

const connectSocketUserTypingDoc = ({
  userId, docId, companyId, teamId,
}) => io(`${process.env.REACT_APP_REALTIME_SOCKET_URL}/socket/user-typing/docs/${docId}`, {
  query: {
    userId,
    companyId,
    teamId,
  },
  forceNew: true,
  withCredentials: true,
});

const connectSocketUserTypingFile = ({
  userId, fileId, companyId, teamId,
}) => io(`${process.env.REACT_APP_REALTIME_SOCKET_URL}/socket/user-typing/files/${fileId}`, {
  query: {
    userId,
    companyId,
    teamId,
  },
  forceNew: true,
  withCredentials: true,
});

const connectSocketUserTypingEvent = ({
  userId, eventId, companyId, teamId,
}) => io(`${process.env.REACT_APP_REALTIME_SOCKET_URL}/socket/user-typing/events/${eventId}`, {
  query: {
    userId,
    companyId,
    teamId,
  },
  forceNew: true,
  withCredentials: true,
});

const connectSocketUserTypingOccurrence = ({
  userId, eventId, occurrenceId, companyId, teamId,
}) => io(`${process.env.REACT_APP_REALTIME_SOCKET_URL}/socket/user-typing/events/${eventId}/occurrences/${occurrenceId}`, {
  query: {
    userId,
    companyId,
    teamId,
  },
  forceNew: true,
  withCredentials: true,
});

const connectSocketUserTypingQuestion = ({
  userId, questionId, companyId, teamId,
}) => io(`${process.env.REACT_APP_REALTIME_SOCKET_URL}/socket/user-typing/questions/${questionId}`, {
  query: {
    userId,
    companyId,
    teamId,
  },
  forceNew: true,
  withCredentials: true,
});

const connectSocketUserTypingDiscussion = ({
  userId, commentId, companyId, teamId,
}) => io(`${process.env.REACT_APP_REALTIME_SOCKET_URL}/socket/user-typing/comments/${commentId}`, {
  query: {
    userId,
    companyId,
    teamId,
  },
  forceNew: true,
  withCredentials: true,
});

export {
  connectSocketGroupChat,
  connectSocketPrivateChat,
  connectSocketRecentChats,
  connectSocketQuestion,
  connectSocketPost,
  connectSocketDiscussionComment,
  connectSocketEvent,
  connectSocketOccurrenceNoTypo,
  connectSocketOccurrence,
  connectSocketCheers,
  connectSocketBlast,
  connectSocketCheckIn,
  connectSocketBucket,
  connectSocketDoc,
  connectSocketFile,
  connectSocketSchedule,
  connectSocketNotification,
  connectSocketNotificationChat,
  connectSocketNotificationCounter,
  connectSocketBoard,
  connectSocketCard,
  connectSocketCompany,
  connectSocketTeam,
  connectSocketNotificationCounterCompanies,
  connectSocketNotificationTeam,
  connectSocketNotificationCounterTeams,
  connectSocketNotificationCounterTeam,
  connectSocketUserOnline,
  connectSocketUserTypingChat,
  connectSocketUserTypingGroupChat,
  connectSocketUserTypingCard,
  connectSocketUserTypingPost,
  connectSocketUserTypingDoc,
  connectSocketUserTypingFile,
  connectSocketUserTypingEvent,
  connectSocketUserTypingOccurrence,
  connectSocketUserTypingQuestion,
  connectSocketUserTypingDiscussion,
  connectSocketMyTaskCard,
  connectSocketBoardPopulatedCard,
  connectSocketTeamFavorite,
};
