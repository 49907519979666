import React, { memo, useState } from 'react';
import PropTypes from 'prop-types';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { Skeleton } from '@mui/material';
import styles from './Header.module.css';
import Color from '../../../../../themes/colors';
import BackgroundChart from '../../../DataRealtimeReport/ChartUserReport/BackgroundChart/BackgroundChart';

const UserRerportPageSkeletonHeader = () => (
  <div className={styles.container}>
    <div className={styles.item}>
      <BackgroundChart>
        <div className={styles.skeleton}>
          <Skeleton
            animation="wave"
            variant="rounded"
            height={30}
          />
        </div>
        <div className={styles.skeleton}>
          <Skeleton
            animation="wave"
            variant="rounded"
            height={85}
          />
        </div>
      </BackgroundChart>
    </div>
    <div className={styles.item}>
      <BackgroundChart>
        <div className={styles.skeleton}>
          <Skeleton
            animation="wave"
            variant="rounded"
            height={30}
          />
        </div>
        <div className={styles.skeleton}>
          <Skeleton
            animation="wave"
            variant="rounded"
            height={85}
          />
        </div>
      </BackgroundChart>
    </div>
    <div className={styles.item}>
      <BackgroundChart>
        <div className={styles.skeleton}>
          <Skeleton
            animation="wave"
            variant="rounded"
            height={30}
          />
        </div>
        <div className={styles.skeleton}>
          <Skeleton
            animation="wave"
            variant="rounded"
            height={85}
          />
        </div>
      </BackgroundChart>
    </div>
  </div>
);

UserRerportPageSkeletonHeader.propTypes = {};

UserRerportPageSkeletonHeader.defaultProps = {};

export default memo(UserRerportPageSkeletonHeader);
