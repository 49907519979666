/* eslint-disable jsx-a11y/media-has-caption */
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import PlayCircleIcon from '@mui/icons-material/PlayCircle';
import { useMediaQuery } from '@mui/material';
import styles from './AttachmentFile.module.css';
import { AttachmentActions } from '../../../../actions';
import PdfThumbnail from '../../../../components/PdfThumbnail/PdfThumbnail';
import VideoPlayerThumbnail from '../../../../components/VideoPlayerThumbnail/VideoPlayerThumbnail';

const AttachmentFile = ({ attachment }) => {
  const isMobile = useMediaQuery('(max-width:720px)');

  const attachmentUrl = attachment.url?.charAt(0) === '/'
    ? process.env.REACT_APP_PRIMARY_API_URL + attachment.url
    : attachment.url;
  const result = AttachmentActions.getMimeTypeUrl(
    { mimeType: attachment.mimeType, fileUrl: attachmentUrl },
  );

  const imageUrl = result?.imageUrl;
  const isImage = result?.isImage;
  const isVideo = result?.isVideo;
  const isPdf = result?.isPdf;

  return (
    <div
      className={styles.main}
    >
      <div className={styles.main__icon}>
        {(!isVideo && !isPdf) && (<img src={imageUrl} style={isImage ? {} : { maxWidth: '70px', maxHeight: '50px' }} />)}
        {isVideo
        && (
          <VideoPlayerThumbnail videoUrl={imageUrl} />
        )}
        {isPdf && (
          <PdfThumbnail fileUrl={imageUrl} />
        )}
      </div>
    </div>

  );
};

AttachmentFile.propTypes = {
  attachment: PropTypes.object.isRequired,
};

export default AttachmentFile;
