import React, { memo, useState } from 'react';
import PropTypes from 'prop-types';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import styles from './Main.module.css';
import Color from '../../../../../themes/colors';
import { DisplayAvatarMember } from '../../Avatar';
import { handleCheckToday } from '../../../../../utilities/dateUtil';
import { DisplayDateBox } from '../../Date';
import { DisplayTextBody } from '../../Text';

const DisplayCreatorMain = ({ data }) => (
  <div className={styles.container}>
    {data.creator
      ? <DisplayAvatarMember size="lg" src={data.creator?.photoUrl} />
      : null}
    {data.creator
      ? (
        <div className={styles.creator__creatorAndDate}>
          <DisplayTextBody>{data.creator?.fullName}</DisplayTextBody>
          {handleCheckToday(data)
            ? <DisplayDateBox date={data.createdAt} relativeTime />
            : <DisplayDateBox date={data.createdAt} />}
        </div>
      )
      : (
        <div className={styles.creator__notFound}>
          <DisplayTextBody>Creator not found. This created at : </DisplayTextBody>
          {handleCheckToday(data)
            ? <DisplayDateBox date={data.createdAt} relativeTime />
            : <DisplayDateBox date={data.createdAt} />}
        </div>
      )}

  </div>
);

DisplayCreatorMain.propTypes = {
  data: PropTypes.object,
};

DisplayCreatorMain.defaultProps = {
  data: {},
};

export default memo(DisplayCreatorMain);
