import React, { forwardRef } from 'react';
import PropTypes from 'prop-types';
import styles from './CommentsSectionContainer.module.css';
import CreateCommentForm from './CreateCommentForm/CreateCommentForm';
import ShowComments from './ShowComments/ShowComments';
import { DisplayTextSubHeadline } from '../../components/UI_V2/Display/Text';
import Color from '../../themes/colors';
import { InputButtonMain } from '../../components/UI_V2/Input/Button';

const CommentsSectionContainer = forwardRef(({
  comments, type, parentIds, hideReply, hideAddComment, onOpenForm, openForm,
  disableAutoScrollHook, handleShowActivities, hideShowActivitiesButton, socket, parentTeamId,
}, ref) => (
  <>
    {!hideAddComment && (
    <div className={styles.commentTitle}>
      <DisplayTextSubHeadline
        color={Color.gray3}
        decoration="semibold"
        mode="16"
      >
        Komentar & Aktifitas
      </DisplayTextSubHeadline>

      {!hideShowActivitiesButton && (
      <div className={styles.activityButton}>
        <InputButtonMain variant="light" handleClick={handleShowActivities} size="sm">
          Tampilkan Aktifitas
        </InputButtonMain>
      </div>
      )}
    </div>
    )}

    {!hideAddComment && (
      <CreateCommentForm
        openForm={openForm}
        onOpenForm={onOpenForm}
        ref={ref}
        type={type}
        parentIds={parentIds}
        socket={socket}
        parentTeamId={parentTeamId}
      />
    )}
    <ShowComments
      parentIds={parentIds}
      comments={comments}
      type={type}
      hideReply={hideReply}
      disableAutoScrollHook={disableAutoScrollHook}
      socket={socket}
      parentTeamId={parentTeamId}
    />

  </>
));

CommentsSectionContainer.propTypes = {
  type: PropTypes.string.isRequired,
  comments: PropTypes.array.isRequired,
  parentIds: PropTypes.array.isRequired,
  hideReply: PropTypes.bool.isRequired,
  openForm: PropTypes.bool.isRequired,
  onOpenForm: PropTypes.func.isRequired,
  hideAddComment: PropTypes.bool.isRequired,
  disableAutoScrollHook: PropTypes.bool,
  handleShowActivities: PropTypes.func,
  hideShowActivitiesButton: PropTypes.bool,
  socket: PropTypes.object.isRequired,
  parentTeamId: PropTypes.string,
};

CommentsSectionContainer.defaultProps = {
  disableAutoScrollHook: false,
  hideShowActivitiesButton: false,
  handleShowActivities: () => null,
  parentTeamId: undefined,
};

export default CommentsSectionContainer;
