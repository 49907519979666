import React, {
  useState, useEffect, memo,
  useContext,
} from 'react';
import CloseIcon from '@mui/icons-material/Close';
import { useSnackbar } from 'notistack';
import axios from 'axios';
import { useParams } from 'react-router-dom';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import Tooltip from '@mui/material/Tooltip';
import PropTypes from 'prop-types';
import { isEqual } from 'lodash';
import BoxPopOver from '../../../components/UI/BoxPopOver/BoxPopOver';
import SeparatorLine from '../../../components/UI/SeparatorLine/SeparatorLine';
import styles from './ListMenu.module.css';
import OverlayButton from '../../../components/UI/Button/OverlayButton/OverlayButton';
import handleStatusMsg from '../../../utilities/handleStatusMsg';
import handleLoadings from '../../../utilities/handleLoadings';
import ButtonDiv from '../../../components/UI/Button/ButtonDiv/ButtonDiv';
import apiUtil from '../../../utilities/apiUtil';
import { DEFAULT_OPTION } from '../../../constants/ApiConstants';
import { setStore } from '../../../utilities/localStorage';
import { CardActions, ListActions } from '../../../actions';

import { GlobalContext } from '../../../contexts/GlobalStateProvider';
import { hasPermission } from '../../../actions/RoleActions';
import { roles } from '../../../constants/RoleConstants';

const ListMenu = ({
  list, allConfigListBoard, setConfigListBoard, cancel,
}) => {
  const [{ currentRoleUser }] = useContext(GlobalContext);
  const [postCards, setPostCards] = useState([]);
  const [postListWithCards, setPostListWithCards] = useState();
  const [loadings, setLoadings] = useState([]);
  const { enqueueSnackbar } = useSnackbar();
  const [completeList, setCompleteList] = useState();
  const configListBoard = allConfigListBoard[list._id];

  const params = useParams();
  const { companyId, boardId, teamId } = params;

  const isUserManagerOrAbove = hasPermission(currentRoleUser, roles.managerOrAbove);

  useEffect(() => {
    if (postCards.length < 1) return;
    const archivedApiCards = async () => {
      const listId = list._id;

      try {
        const startLoadings = handleLoadings('listMenuArchived', [...loadings], 'start');
        setLoadings([...startLoadings]);

        const bodyRequest = {
          boardId, listId, cards: [...postCards], selector: { boardId },
        };
        const result = await CardActions.archiveCards({ companyId, teamId, body: bodyRequest });
        const status = handleStatusMsg(result, 'success');

        enqueueSnackbar(status.message, {
          variant: 'success',
        });

        cancel();
      } catch (err) {
        const status = handleStatusMsg(err, 'error');

        enqueueSnackbar(status.message, {
          variant: 'error',
        });
      } finally {
        const endLoadings = handleLoadings('listMenuArchived', [...loadings], 'end');
        setLoadings([...endLoadings]);
      }
    };
    archivedApiCards();
  }, [postCards]);

  useEffect(() => {
    if (postListWithCards === undefined) return;

    const archivedApiList = async () => {
      const { listId } = postListWithCards;
      const cardIds = postListWithCards.cards;

      try {
        const startLoadings = handleLoadings('listMenuArchived', [...loadings], 'start');
        setLoadings([...startLoadings]);

        const bodyRequest = { boardId, cards: [...cardIds], selector: { boardId } };
        const result = await ListActions.archiveList({
          listId, companyId, body: bodyRequest, teamId,
        });

        const status = handleStatusMsg(result, 'success');

        enqueueSnackbar(status.message, {
          variant: 'success',
        });

        cancel();
      } catch (err) {
        const status = handleStatusMsg(err, 'error');

        enqueueSnackbar(status.message, {
          variant: 'error',
        });
      } finally {
        const endLoadings = handleLoadings('listMenuArchived', [...loadings], 'end');
        setLoadings([...endLoadings]);
      }
    };
    archivedApiList();
  }, [postListWithCards]);

  useEffect(() => {
    if (completeList === undefined) return;

    const completeListApi = async () => {
      const { listId } = completeList;
      const { completeStatus } = completeList;

      try {
        const startLoadings = handleLoadings('listMenuArchived', [...loadings], 'start');
        setLoadings([...startLoadings]);

        let result;
        if (completeStatus) {
          result = await ListActions.unsetCompleteStatus({
            boardId, listId, companyId, teamId,
          });
        } else {
          result = await ListActions.setCompleteStatus({
            body: { boardId }, listId, companyId, teamId,
          });
        }

        const status = handleStatusMsg(result, 'success');

        enqueueSnackbar(status.message, {
          variant: 'success',
        });

        cancel();
      } catch (err) {
        const status = handleStatusMsg(err, 'error');

        enqueueSnackbar(status.message, {
          variant: 'error',
        });
      } finally {
        const endLoadings = handleLoadings('listMenuArchived', [...loadings], 'end');
        setLoadings([...endLoadings]);
      }
    };

    completeListApi();
  }, [completeList]);

  const handleArchivedCards = () => {
    const cardIds = [];
    list.cards.forEach((card) => {
      if (card.archived.status !== true) cardIds.push(card._id);
    });

    setPostCards([...cardIds]);
  };

  const handleArchivedList = () => {
    const cardIds = [];
    list.cards.forEach((card) => {
      if (card.archived.status !== true) cardIds.push(card._id);
    });

    setPostListWithCards({ listId: list._id, cards: [...cardIds] });
  };

  const handleSetCompleteList = () => {
    let completeStatus = false;
    if (list?.complete?.status === true) {
      completeStatus = true;
    }

    setCompleteList({ listId: list._id, completeStatus });
  };

  const handleSortListByAToZ = () => {
    const currentValueConf = configListBoard?.sortAlphabetic === 'obverse';
    const valueFilter = !currentValueConf
      ? {
        sortAlphabetic: 'obverse',
      } : {};
    setStore(
      `confListKanbanCheckSorting-${list._id}`,
      valueFilter,
    );
    setConfigListBoard({
      [list._id]: valueFilter,
    });
    cancel();
  };

  const handleSortListByZToA = () => {
    const currentValueConf = configListBoard?.sortAlphabetic === 'reverse';
    const valueFilter = !currentValueConf
      ? {
        sortAlphabetic: 'reverse',
      } : {};
    setStore(
      `confListKanbanCheckSorting-${list._id}`,
      valueFilter,
    );
    setConfigListBoard({
      [list._id]: valueFilter,
    });
    cancel();
  };

  const handleSortListByNearestDueDate = () => {
    const currentValueConf = configListBoard?.sortDueDate === 'obverse';
    const valueFilter = !currentValueConf
      ? {
        sortDueDate: 'obverse',
      } : {};
    setStore(
      `confListKanbanCheckSorting-${list._id}`,
      valueFilter,
    );
    setConfigListBoard({
      [list._id]: valueFilter,
    });
    cancel();
  };

  const handleSortListByFurthestDueDate = () => {
    const currentValueConf = configListBoard?.sortDueDate === 'reverse';
    const valueFilter = !currentValueConf
      ? {
        sortDueDate: 'reverse',
      } : {};
    setStore(
      `confListKanbanCheckSorting-${list._id}`,
      valueFilter,
    );
    setConfigListBoard({
      [list._id]: valueFilter,
    });
    cancel();
  };

  const handleSortListByNearestCreatedDate = () => {
    const currentValueConf = configListBoard?.sortCreateDate === 'obverse';
    const valueFilter = !currentValueConf
      ? {
        sortCreateDate: 'obverse',
      } : {};
    setStore(
      `confListKanbanCheckSorting-${list._id}`,
      valueFilter,
    );
    setConfigListBoard({
      [list._id]: valueFilter,
    });
    cancel();
  };

  const handleSortListByFurthestCreatedDate = () => {
    const currentValueConf = configListBoard?.sortCreateDate === 'reverse';
    const valueFilter = !currentValueConf
      ? {
        sortCreateDate: 'reverse',
      } : {};
    setStore(
      `confListKanbanCheckSorting-${list._id}`,
      valueFilter,
    );
    setConfigListBoard({
      [list._id]: valueFilter,
    });
    cancel();
  };

  return (
    <BoxPopOver left>
      <div className={styles.headerSection}>
        <h1>
          Aksi List
        </h1>
        <CloseIcon onClick={cancel} />
      </div>
      <SeparatorLine />
      <div className={styles.bodySection}>
        <OverlayButton loadings={loadings} wait="listMenuArchived">
          {isUserManagerOrAbove && (
          <ButtonDiv onClick={handleArchivedCards} className={styles.menu}>
            <p>Arsipkan semua Tugas di List ini</p>
          </ButtonDiv>
          )}

          {isUserManagerOrAbove && (
          <ButtonDiv onClick={handleArchivedList} className={styles.menu}>
            <p>Arsipkan List ini</p>
          </ButtonDiv>
          )}

          {isUserManagerOrAbove && (
          <Tooltip title={list?.complete?.status
            ? 'Semua tugas di list ini akan dapat pengingat tenggat waktu lagi'
            : 'Semua tugas di list ini selalu dianggap Selesai dan ga akan dapat pengingat tenggat waktu lagi'}
          >
            <ButtonDiv onClick={handleSetCompleteList} className={styles.menu}>
              {list?.complete?.status ? (
                <>
                  <p>Kembalikan jadi List biasa</p>
                  <CheckCircleIcon className={styles.iconUnset} />
                </>
              ) : (
                <>
                  <p>Atur sebagai List Selesai</p>
                  <CheckCircleIcon className={styles.icon} />
                </>
              )}
            </ButtonDiv>
          </Tooltip>
          )}

          <ButtonDiv
            onClick={handleSortListByAToZ}
            className={
              configListBoard?.sortAlphabetic === 'obverse'
                ? styles.menuSelected
                : styles.menu
            }
          >
            <p>Urutkan Tugas dari A ke Z</p>
          </ButtonDiv>

          <ButtonDiv
            onClick={handleSortListByZToA}
            className={
              configListBoard?.sortAlphabetic === 'reverse'
                ? styles.menuSelected
                : styles.menu
            }
          >
            <p>Urutkan Tugas dari Z ke A</p>
          </ButtonDiv>

          <ButtonDiv
            onClick={handleSortListByNearestDueDate}
            className={
              configListBoard?.sortDueDate === 'obverse'
                ? styles.menuSelected
                : styles.menu
            }
          >
            <p>Urutkan Tugas dari tenggat waktu terdekat</p>
          </ButtonDiv>

          <ButtonDiv
            onClick={handleSortListByFurthestDueDate}
            className={
              configListBoard?.sortDueDate === 'reverse'
                ? styles.menuSelected
                : styles.menu
            }
          >
            <p>Urutkan Tugas dari tenggat waktu terjauh</p>
          </ButtonDiv>

          <ButtonDiv
            onClick={handleSortListByNearestCreatedDate}
            className={
              configListBoard?.sortCreateDate === 'obverse'
                ? styles.menuSelected
                : styles.menu
            }
          >
            <p>Urutkan tugas dari waktu dibuat terdekat</p>
          </ButtonDiv>

          <ButtonDiv
            onClick={handleSortListByFurthestCreatedDate}
            className={
              configListBoard?.sortCreateDate === 'reverse'
                ? styles.menuSelected
                : styles.menu
            }
          >
            <p>Urutkan tugas dari waktu dibuat terjauh</p>
          </ButtonDiv>

          {/* <ButtonDiv onClick={handleArchivedList} className={styles.menu}>
            <p>Sort This List by Member</p>
          </ButtonDiv>

          <ButtonDiv onClick={handleArchivedList} className={styles.menu}>
            <p>Sort This List by Label</p>
          </ButtonDiv> */}

        </OverlayButton>
      </div>
      {/* <div className={styles.actionSection}>
        <Button variant="danger" size="sm" block>Delete</Button>
      </div> */}
    </BoxPopOver>
  );
};

ListMenu.propTypes = {
  list: PropTypes.object.isRequired,
  allConfigListBoard: PropTypes.object,
  cancel: PropTypes.func.isRequired,
  setConfigListBoard: PropTypes.func.isRequired,
};

ListMenu.defaultProps = {
  allConfigListBoard: {},
};

const isComponentDataEqual = (prevProps, nextProps) => {
  const { list, allConfigListBoard } = nextProps;
  return isEqual(prevProps.list, list)
  && isEqual(
    prevProps.allConfigListBoard?.[list?._id],
    allConfigListBoard?.[list?._id],
  );
};

export default memo(ListMenu, isComponentDataEqual);
