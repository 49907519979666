import React, {
  useState, useEffect, useCallback, useContext,
} from 'react';
import PropTypes from 'prop-types';
import styles from './SubAction.module.css';

const BlastPostSubAction = ({ icon, onClick }) => {
  const [parsedContent, setParsedContent] = useState('');
  const [parsedTitle, setParsedTitle] = useState('');
  const [isPostComplete, setCompletePost] = useState(false);
  const [isPostAutoCompleteDueDate, setPostAutoCompleteDueDate] = useState(false);

  return (
    <div className={styles.container} onClick={onClick}>
      {icon}
    </div>
  );
};

BlastPostSubAction.propTypes = {
  icon: PropTypes.node.isRequired,
  onClick: PropTypes.func.isRequired,
};

export default BlastPostSubAction;
