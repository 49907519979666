import React, {
  memo,
} from 'react';
import styles from './VerticalSeparator.module.css';

const VerticalSeparator = () => (
  <div className={styles.container} />
);

export default memo(VerticalSeparator);
