import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import styles from './EventSnapshot.module.css';
import ImageMember from '../../../../components/UI/ImageMember/ImageMember';
import PrivateIcon from '../../../../components/UI/PrivateIcon/PrivateIcon';
import Color from '../../../../themes/colors';
import { DisplayBadgeCounter } from '../../../../components/UI_V2/Display/Badge';

const EventSnapshot = ({ event }) => {
  const [time, setTime] = useState({
    start: undefined,
    end: undefined,
  });

  const formatTime = (inputDate) => {
    const minute = inputDate.getMinutes();
    let hour = inputDate.getHours();
    const ampm = hour >= 12 ? 'PM' : 'AM';
    // eslint-disable-next-line no-mixed-operators
    hour = ((hour + 11) % 12 + 1);
    const prefixHour = hour >= 10 ? '' : '0';
    const prefixMinute = minute >= 10 ? '' : '0';

    const formattedTime = `${prefixHour + hour}:${prefixMinute}${minute} ${ampm}`;
    return formattedTime;
  };

  useEffect(() => {
    // bikin start time
    const initialStartDate = new Date(event?.startDate);
    const startTime = formatTime(initialStartDate);

    setTime((prevValue) => ({
      ...prevValue,
      start: startTime,
    }));

    // bikin end time
    const initialEndDate = new Date(event?.endDate);
    const endTime = formatTime(initialEndDate);

    setTime((prevValue) => ({
      ...prevValue,
      end: endTime,
    }));
  }, [event]);

  return (
    <div className={styles.Event}>
      <div className={styles.mainContent}>
        {/* <div className={styles.boxDate}>
        <CalendarTodayIcon />
      </div> */}
        <div className={styles.contentSection}>
          {/* <div className={styles.contentSection__date}>
            <h1>{date.start}</h1>
        </div> */}
          <div className={styles.contentSection__header}>
            <div className={styles.contentSection__header__title}>
              <p>
                <PrivateIcon data={event} size="small" />
                {' '}
                {event.title}
              </p>
              {event.subscribers.map((subscriber) => (
                <ImageMember photoUrl={subscriber?.photoUrl} />
              ))}
            </div>
          </div>
          <div className={styles.contentSection__time}>
            {event.status === 'onStart'
              ? (
                <p>
                  {time.start}
                  <span> onward</span>
                </p>
              )
              : null}
            {event.status === 'onGoing' ? null : null}
            {event.status === 'onEnd'
              ? (
                <p>
                  <span>Until </span>
                  {time.end}
                </p>
              )
              : null}
            {event.status === 'sameDay'
              ? (
                <p>
                  <span>{time.start}</span>
                  <span> - </span>
                  {time.end}
                </p>
              )
              : null}
          </div>
        </div>
      </div>
      <div className={styles.bodySection__counter}>
        <DisplayBadgeCounter
          badgeContent={event.comments.length}
          backgroundColor={Color.deleteColor}
        />
      </div>
    </div>
  );
};

EventSnapshot.propTypes = {
  event: PropTypes.object.isRequired,
};

export default EventSnapshot;
