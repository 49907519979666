import React, { memo, useState } from 'react';
import PropTypes from 'prop-types';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import styles from './InterpretationText.module.css';
import Color from '../../../../themes/colors';
import { DisplayTextBody } from '../../../../components/UI_V2/Display/Text';

const InterpretationText = ({
  title,
  content,
}) => (
  <div className={styles.container}>
    <div className={styles.title}>
      <DisplayTextBody decoration="bold" color={Color.gray3}>
        {title}
      </DisplayTextBody>
    </div>
    <div className={styles.content}>
      <DisplayTextBody color={Color.gray3}>
        {content}
      </DisplayTextBody>
    </div>
  </div>
);

InterpretationText.propTypes = {
  title: PropTypes.string,
  content: PropTypes.string,
};

InterpretationText.defaultProps = {
  title: 'Interpretasi :',
  content: '',
};

export default memo(InterpretationText);
