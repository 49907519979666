import React, { memo } from 'react';
import { Link, useParams } from 'react-router-dom';
import PropTypes from 'prop-types';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import styles from './ImageMember.module.css';
import { adjustServerUrl } from '../../../utilities/stringUtil';

const ImageMember = ({
  clicked, photoUrl, userId, prevLoc, children, medium, small,
  colorImageGray,
}) => {
  const params = useParams();
  const { companyId } = params;
  let url;
  if (userId) {
    if (prevLoc) {
      url = { pathname: `/companies/${companyId}/profiles/${userId}`, state: { from: prevLoc } };
    } else {
      url = { pathname: `/companies/${companyId}/profiles/${userId}` };
    }
  }

  let photoSource;
  if (photoUrl) {
    photoSource = adjustServerUrl(photoUrl);
  }

  return (
    <>
      {url
        ? (
          <Link to={url}>
            <div className={styles.wrapper}>
              {colorImageGray && <div className={medium ? `${styles.colorImageGray} ${styles.medium}` : styles.colorImageGray} />}
              <LazyLoadImage effect="blur" className={medium ? `${styles.image} ${styles.medium}` : styles.image} style={small ? { width: '23px', height: '23px' } : null} src={photoSource} />
              {children}
            </div>
          </Link>
        )
        : (
          <div onClick={clicked} className={styles.wrapper}>
            {colorImageGray && <div className={medium ? `${styles.colorImageGray} ${styles.medium}` : styles.colorImageGray} />}
            <LazyLoadImage effect="blur" className={medium ? `${styles.image} ${styles.medium}` : styles.image} style={small ? { width: '23px', height: '23px' } : null} src={photoSource} />
            {children}
          </div>
        )}
    </>

  );
};

ImageMember.propTypes = {
  clicked: PropTypes.func.isRequired,
  photoUrl: PropTypes.string.isRequired,
  userId: PropTypes.string.isRequired,
  prevLoc: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
  medium: PropTypes.bool.isRequired,
  small: PropTypes.bool.isRequired,
  colorImageGray: PropTypes.bool,
};

ImageMember.defaultProps = {
  colorImageGray: false,
};

const isComponentDataEqual = (prevProps, nextProps) => {
  const {
    photoUrl, userId, prevLoc, medium, small,
  } = nextProps;
  return prevProps.photoUrl === photoUrl
    && prevProps.userId === userId
    && prevProps.prevLoc === prevLoc
    && prevProps.medium === medium
    && prevProps.small === small;
};

export default memo(ImageMember, isComponentDataEqual);
