import React, { memo, useState } from 'react';
import PropTypes from 'prop-types';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { Skeleton } from '@mui/material';
import styles from './ChartUserSkeleton.module.css';
import Color from '../../../../../themes/colors';

const ChartUserSkeleton = () => (
  <div className={styles.container}>
    <div className={styles.skeleton}>
      <Skeleton
        animation="wave"
        variant="rounded"
        height={20}
      />
    </div>
    <div className={styles.skeleton}>
      <Skeleton
        animation="wave"
        variant="rounded"
        height={75}
      />
    </div>
  </div>
);

ChartUserSkeleton.propTypes = {};

ChartUserSkeleton.defaultProps = {};

export default memo(ChartUserSkeleton);
