import React, { memo, useState } from 'react';
import PropTypes from 'prop-types';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import styles from './RatingAverageInterpretation.module.css';
import Color from '../../../../../themes/colors';
import { DisplayTextBody, DisplayTextHeadline } from '../../../../../components/UI_V2/Display/Text';
import { sliceNumber } from '../../../../../utilities/stringUtil';

const titleColorStyles = {
  good: Color.tosca,
  normal: Color.blueNavy2,
  bad: Color.deleteColor,
};

const RatingAverageInterpretation = ({
  ratingAverage,
}) => (
  <div className={styles.container}>
    <div className={styles.title}>
      <DisplayTextHeadline color={titleColorStyles[ratingAverage.category]} decoration="bold">
        {sliceNumber(ratingAverage.value, 4)}
      </DisplayTextHeadline>
    </div>
    <div className={styles.subTitle}>
      <DisplayTextBody position="center" color={Color.gray3}>
        rating rata-rata
      </DisplayTextBody>
    </div>
  </div>
);

RatingAverageInterpretation.propTypes = {
  ratingAverage: PropTypes.object,
};

RatingAverageInterpretation.defaultProps = {
  ratingAverage: {
    value: 0,
    category: 'bad',
  },
};

export default memo(RatingAverageInterpretation);
