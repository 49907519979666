import React, { useState, useContext, useEffect } from 'react';
import PropTypes from 'prop-types';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import { useParams } from 'react-router-dom';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import { useSnackbar } from 'notistack';
import styles from './ManageSubscribersContainer.module.css';
import { GlobalContext } from '../../contexts/GlobalStateProvider';
import ShowLimitMembers from '../../components/ShowLimitMembers/ShowLimitMembers';
import { DisplayAvatarMember } from '../../components/UI_V2/Display/Avatar';
import { SurfacePaperFloating } from '../../components/UI_V2/Surface/Paper';
import { DisplayTextBody, DisplayTextSubHeadline } from '../../components/UI_V2/Display/Text';
import Color from '../../themes/colors';
import { useDelayShowHideHandler, useSearch } from '../../hooks/HelperHooks';
import { DisplayDividerMain } from '../../components/UI_V2/Display/Divider';
import { InputSearchGeneral } from '../../components/UI_V2/Input/Search';
import {
  filterListObjectByListObject,
  isAllElementExistInList,
  isListLengthEqual,
  mergeListAndRemoveDuplicate,
} from '../../utilities/arrayUtil';
import { initiateTeamMembers } from '../../actions/TeamActions';
import handleStatusMsg from '../../utilities/handleStatusMsg';

const ManageSubscribersContainer = ({
  limitSubscribers,
  initialSubscribers,
  handleSetSubscribers,
  // companyId,
  labelPrimary,
  labelSecondary,
}) => {
  const { enqueueSnackbar } = useSnackbar();
  const params = useParams();
  const { companyId, teamId } = params;
  const [{ currentTeam }, dispatch] = useContext(GlobalContext);
  const [showAddSubscriber,
    handleShowAddSubscriber,
    handleHideShowAddSubscriber] = useDelayShowHideHandler();
  const {
    searchResult,
    keyword,
    keywordArray,
    setKeyword,
    isTyping,
    onChange,
    resetSearch,
  } = useSearch({ originalList: currentTeam?.members });
  const [subscribers, setSubscribers] = useState([...initialSubscribers]);
  const [checkMode, setCheckMode] = useState(false);

  const handleToggleMember = (member) => {
    if (subscribers.some((subscriber) => subscriber._id === member._id)) {
      const newSubscribers = subscribers.filter((subscriber) => subscriber._id !== member._id);
      setSubscribers([...newSubscribers]);
    } else {
      const newSubscribers = [...subscribers];
      newSubscribers.push(member);
      setSubscribers([...newSubscribers]);
    }
  };

  const handleCheckAll = () => {
    const isSearchResultAllChecked = isAllElementExistInList(searchResult, subscribers);
    if (isSearchResultAllChecked) {
      const filteredSubscribers = filterListObjectByListObject(subscribers, searchResult);
      setSubscribers([...filteredSubscribers]);
    } else {
      const mergedSubscribers = mergeListAndRemoveDuplicate(subscribers, searchResult);
      setSubscribers([...mergedSubscribers]);
    }
  };

  useEffect(() => {
    if (isAllElementExistInList(subscribers, currentTeam?.members)
    && subscribers?.length > 0
    && isListLengthEqual(subscribers, currentTeam?.members)) {
      setCheckMode(true);
    } else {
      setCheckMode(false);
    }
  }, [searchResult, subscribers]);

  const handleResetAndHideShowAddSubscriber = () => {
    setSubscribers([...initialSubscribers]);
    handleHideShowAddSubscriber();
  };

  const handleDoneSubscribers = () => {
    handleSetSubscribers([...subscribers]);
    handleHideShowAddSubscriber();
  };

  useEffect(() => {
    if (currentTeam.members) return;
    const fetchApiTeamMembers = async () => {
      try {
        await initiateTeamMembers({
          teamId,
          companyId,
          currentTeam,
        }, dispatch);
      } catch (error) {
        const status = handleStatusMsg(error, 'error');

        enqueueSnackbar(status.message, {
          variant: 'error',
        });
      }
    };
    fetchApiTeamMembers();
  }, []);

  useEffect(() => {
    setSubscribers([...initialSubscribers]);
  }, [initialSubscribers]);

  return (
    <>
      <div className={styles.Subscribers}>
        <div className={styles.subscribersHeader}>
          <DisplayTextSubHeadline
            mode="14"
            color={Color.deepGray2}
          >
            {labelPrimary}
          </DisplayTextSubHeadline>
        </div>

        <div className={styles.subscribersItem}>
          <ShowLimitMembers
            members={initialSubscribers || []}
            limit={limitSubscribers}
            additionalPlusButton={initialSubscribers?.length >= limitSubscribers}
            handleClickButton={handleShowAddSubscriber}
            companyId={companyId}
            buttonVariant="secondary"
            handleClickPhoto={handleShowAddSubscriber}
            enableOnClickCustomOnPhoto
          />
          {showAddSubscriber && (
          <SurfacePaperFloating
            onOutsideClick={handleResetAndHideShowAddSubscriber}
            position="centerRight"
            size="lg"
          >
            <div className={styles.popUpContainer}>
              <div className={styles.ManageSubscribersContainer__Header}>
                <DisplayTextBody
                  color={Color.gray4}
                  fontFamily="openSans"
                >
                  {labelSecondary}
                </DisplayTextBody>
                <div
                  className={styles.doneButton}
                  onClick={handleDoneSubscribers}
                >
                  <DisplayTextBody
                    color={Color.blueNavy4}
                    mode="11"
                  >
                    Simpan
                  </DisplayTextBody>
                </div>
              </div>
              <DisplayDividerMain borderColor={Color.gray6} marginBottom="0.4rem" />
              <div className={styles.ManageSubscribersContainer__Title}>
                <DisplayTextBody
                  color={Color.blueNavy4}
                >
                  Anggota Tim
                </DisplayTextBody>
              </div>
              <DisplayDividerMain borderColor={Color.gray6} marginTop="0.4rem" marginBottom="0.4rem" />
              <div className={styles.ManageSubscribersContainer__Search}>
                <InputSearchGeneral
                  autoFocus
                  onChange={onChange}
                  value={keyword}
                  placeholder="Cari anggota..."
                  border="semiRounded"
                  onClickCloseIcon={resetSearch}
                />
              </div>
              <DisplayDividerMain borderColor={Color.gray6} marginTop="0.4rem" marginBottom="0.4rem" />
              <div className={styles.ManageSubscribersContainer__SelectAll}>
                <DisplayTextBody
                  decoration="bold"
                  fontFamily="openSans"
                  color={Color.grayIconEditDocs}
                >
                  Pilih Semua
                </DisplayTextBody>
                <div
                  onClick={searchResult?.length === 0 ? () => null : handleCheckAll}
                  className={styles.selectAllSection}
                >
                  {checkMode
                    ? <CheckBoxIcon className={styles.checkBoxRead} />
                    : <CheckBoxOutlineBlankIcon className={styles.checkBoxUnread} />}
                </div>
              </div>
              <DisplayDividerMain borderColor={Color.gray6} marginTop="0.4rem" marginBottom="0px" />
              <div className={styles.ManageSubscribersContainer__body}>
                {searchResult.map((member) => (
                  <div
                    onClick={() => handleToggleMember(member)}
                    className={styles.ManageSubscribersContainer__teamMember}
                  >
                    <div className={styles.member}>
                      <DisplayAvatarMember marginRight="12px" src={member.photoUrl} alt={member.fullName} size="xs" />
                      <DisplayTextBody mode="11" fontFamily="openSans" decoration="bold">{member.fullName}</DisplayTextBody>
                    </div>
                    {subscribers.some((checkMember) => checkMember._id === member._id)
                      ? <CheckBoxIcon className={styles.checkBoxRead} />
                      : <CheckBoxOutlineBlankIcon className={styles.checkBoxUnread} />}
                  </div>
                ))}
              </div>
            </div>
          </SurfacePaperFloating>
          )}
        </div>
      </div>

    </>
  );
};

ManageSubscribersContainer.propTypes = {
  initialSubscribers: PropTypes.array.isRequired,
  handleSetSubscribers: PropTypes.func.isRequired,
  limitSubscribers: PropTypes.number,
  // companyId: PropTypes.string.isRequired,
  labelPrimary: PropTypes.string,
  labelSecondary: PropTypes.string,
};

ManageSubscribersContainer.defaultProps = {
  limitSubscribers: 100,
  labelPrimary: 'Siapa aja Penerimanya?',
  labelSecondary: 'Tambah/Hapus Penerima',
};

export default ManageSubscribersContainer;
