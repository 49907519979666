import React, {
  useState, useContext, useEffect, useCallback, memo, useRef, createRef,
} from 'react';
import { useHistory, useParams, useLocation } from 'react-router-dom';
import {
  isEmpty,
} from 'lodash';
import ArchiveIcon from '@mui/icons-material/Archive';
import draftToHtml from 'draftjs-to-html';
import VerifiedUserIcon from '@mui/icons-material/VerifiedUser';
import AddIcon from '@mui/icons-material/Add';
import { useSnackbar } from 'notistack';
import CheckCircleOutlineOutlinedIcon from '@mui/icons-material/CheckCircleOutlineOutlined';
import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked';
import { GlobalContext, initialState } from '../../contexts/GlobalStateProvider';
import { actionTypes } from '../../reducers/reducer';
import '../../../node_modules/react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import styles from './PostBlastPage.module.css';
import CommentsSectionContainer from '../CommentsSectionContainer/CommentsSectionContainer';
import PostMenu from './PostMenu/PostMenu';
import Title from '../../components/Title/Title';
import checkIsJson from '../../utilities/checkIsJson';
import EditorView from '../FroalaEditor/EditorView/EditorView';
import handleLoadings from '../../utilities/handleLoadings';
import handleStatusMsg from '../../utilities/handleStatusMsg';
import CheersContainer from '../CheersContainer/CheersContainer';
import {
  CommentActions,
  PostActions, SearchJumpToActions, TeamActions,
} from '../../actions';
import InfinityScroll from '../../components/UI/InfinityScroll/InfinityScroll';
import { usePostCheerNCommentSocket, usePostSocket } from '../../hooks/PostHooks';
import { useInfinityScrollHooks } from '../../hooks/InfinityScrollHooks';
import { useScrollToTargetComment } from '../../hooks/DiscussionHooks';
import OverlayButton from '../../components/UI/Button/OverlayButton/OverlayButton';
import { PostConstants, PageConstants, SeenConstants } from '../../constants';
import GeneralSubNavBar from '../../components/GeneralSubNavBar/GeneralSubNavBar';
import { generateRoutesAndTitle } from '../../actions/BreadCrumbActions';
import GlobalActionButton from '../../components/GeneralSubNavBar/BottomNavBar/GlobalActionButton/GlobalActionButton';
import { LayoutMain } from '../../components/UI_V2/Layout';
import { SurfacePaperMain } from '../../components/UI_V2/Surface/Paper';
import { FeedbackLoadingMain } from '../../components/UI_V2/Feedback/Loading';
import { DisplayDividerMain } from '../../components/UI_V2/Display/Divider';
import { DisplayTextBody, DisplayTextSubHeadline } from '../../components/UI_V2/Display/Text';
import Color from '../../themes/colors';
import { DisplayDateBox } from '../../components/UI_V2/Display/Date';
import BlastPostSubAction from '../BlastPage/Post/SubAction/SubAction';
import { DisplayAvatarMember, DisplayAvatarMemberWithOnline } from '../../components/UI_V2/Display/Avatar';
import PostBlastPageSkeleton from './Skeleton/Skeleton';
import { useDelayShowHideHandler } from '../../hooks/HelperHooks';
import { InputButtonMore } from '../../components/UI_V2/Input/Button';
import PrivateIcon from '../../components/UI/PrivateIcon/PrivateIcon';
import { useInitiateRoleUser } from '../../hooks/RoleHooks';
import { roleTypeRef, typeRef } from '../../constants/RoleConstants';
import MyRoleUser from '../../components/Container/MyRoleUser/MyRoleUser';
import { checkIfUserAuthorizedToEditData } from '../../actions/UserActions';
import { useInitiateTeamBelowRole } from '../../hooks/TeamHooks';
import { serviceTypeRef } from '../../constants/ActivityConstants';
import ActivityByModule from '../ActivityByModule/ActivityByModule';
import SeenInfo from '../../components/Container/SeenModal/SeenInfo/SeenInfo';
import SeenModal from '../../components/Container/SeenModal/SeenModal';
import { useUserTypingPostSocket } from '../../hooks/UserTypingHooks';
import UserTyping from '../../components/Container/UserTyping/UserTyping';
import { useMarkReadNotificationOnPageOpen } from '../../hooks/NotificationHooks';

const containerWithSidebarStyles = {
  true: `${styles.container} ${styles.sidebarOpen}`,
  false: styles.container,
};

function PostBlastPage() {
  const [{
    user, currentBlast,
    currentBlastPost,
    previousBlastComment,
    currentTeam,
    currentCompany,
    currentRoleUser,
    currentTeamBelowRole,
    allUserTypingPost,
    isSidebarOpen,
  }, dispatch] = useContext(GlobalContext);
  const [loadings, setLoadings] = useState([]);
  const [
    showPostMenu,
    handleShowPostMenu,
    handleCancelShowPostMenu,
  ] = useDelayShowHideHandler();
  const [
    showModalRoleUser,
    handleShowModalRoleUser,
    handleHideModalRoleUser,
  ] = useDelayShowHideHandler();

  const [
    showSeenModal,
    handleShowSeenModal,
    handleHideSeenModal,
  ] = useDelayShowHideHandler();

  const [
    showActivities,
    handleShowActivities,
    handleHideActivities,
  ] = useDelayShowHideHandler();
  const [openCreateCommentForm, setOpenCreateCommentForm] = useState(false);
  const [isInitialContentReady, setIsInitialContentReady] = useState(false);
  const [editorModel, setEditorModel] = useState();
  const [isPostComplete, setCompletePost] = useState(false);
  const [isPostAutoCompleteDueDate, setPostAutoCompleteDueDate] = useState(false);
  const { enqueueSnackbar } = useSnackbar();

  const history = useHistory();
  const location = useLocation();
  const params = useParams();
  const {
    companyId, teamId, postId,
  } = params;
  const { scrollToTargetComment } = useScrollToTargetComment();

  useInitiateRoleUser({
    payload: {
      teamId,
      featureType: typeRef.post,
      featureId: postId,
    },
  });

  const { socket } = useUserTypingPostSocket({
    companyId,
    postId,
    teamId,
    userId: user?._id,
  }, dispatch);

  // read notif automatically if there is readNotifId
  useMarkReadNotificationOnPageOpen();

  // initiate members below role
  // useInitiateTeamBelowRole({});

  const isUserCreator = currentBlastPost?.creator?._id === user?._id;

  // for open create comment
  const handleOpenCreateCommentForm = useCallback((value) => {
    setOpenCreateCommentForm(value);
  }, []);
  const createCommentRef = createRef();
  const scrollAndOpenCreateCommentForm = useCallback(() => {
    if (createCommentRef && createCommentRef.current) {
      createCommentRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
    }
    setTimeout(() => {
      handleOpenCreateCommentForm(true);
    }, 800);
  }, [createCommentRef]);

  usePostSocket({
    blastId: currentBlast?._id,
    postId,
    userId: user?._id,
  }, dispatch);
  usePostCheerNCommentSocket({ postId, userId: user?._id }, dispatch);

  const getMoreLists = async () => {
    const result = await PostActions.loadMorePostComment({
      postId,
      currentBlastPost,
      companyId,
      teamId,
    }, dispatch);

    return result;
  };

  const resetPreviousLists = useCallback(() => {
    PostActions.setPreviousBlastComment({
      previousBlastComment: initialState.previousBlastComment,
    }, dispatch);
    PostActions.setCurrentBlastPost({
      currentBlastPost: initialState.currentBlastPost,
    }, dispatch);
  }, []);

  const {
    lists,
    handleLoadMoreLists,
    checkIfListNeedToLoad,
    setCheckLoadMore,
    isListsLengthAtTheLimitOrAbove,
  } = useInfinityScrollHooks({
    currentObjectWithKeyProperty: currentBlastPost,
    previousLists: previousBlastComment,
    resetPreviousLists,
    getMoreLists,
  });

  useEffect(() => {
    if (user._id === undefined) {
      history.push(`/check-login?previousPath=${location.pathname}`);
      return;
    }

    const startLoadings = handleLoadings('blastPostPage', [...loadings], 'start');
    setLoadings([...startLoadings]);

    const fetchApiPostDetail = async () => {
      try {
        const result = await PostActions.initiatePost({
          postId,
          companyId,
          blastId: currentBlast?._id,
          teamId,
        }, dispatch);
        const resultComment = await PostActions.initiatePostComment({
          postId,
          companyId,
          teamId,
          limit: CommentActions.getLimitCommentByPreviousCommentDiscussion(),
        }, dispatch);
        await TeamActions.initiateTeam({
          teamId,
          companyId,
          currentTeam,
        }, dispatch);
        if (isListsLengthAtTheLimitOrAbove(resultComment?.data?.comments)) {
          setCheckLoadMore(true);
        }
        scrollToTargetComment();
      } catch (err) {
        const status = handleStatusMsg(err, 'error');

        dispatch({
          type: actionTypes.SET_ERROR_RESPONSE,
          errorResponse: { message: status.message },
        });

        history.push(`/errors?previousPath=${location.pathname}`);
      } finally {
        const endLoadings = handleLoadings('blastPostPage', [...loadings], 'end');
        setLoadings([...endLoadings]);
      }
    };
    fetchApiPostDetail();
  }, [location]);

  useEffect(() => {
    if (isEmpty(currentBlastPost)) {
      return;
    }

    let initialContent;
    if (checkIsJson(currentBlastPost.content)) {
      initialContent = draftToHtml(JSON.parse(currentBlastPost.content));
    } else {
      initialContent = currentBlastPost.content;
    }

    if (currentBlastPost?.complete?.status !== undefined) {
      setCompletePost(currentBlastPost?.complete?.status);
    }

    if (currentBlastPost?.autoComplete?.status !== undefined) {
      setPostAutoCompleteDueDate(currentBlastPost?.autoComplete?.status);
    }

    setEditorModel(initialContent);
    setIsInitialContentReady(true);
  }, [currentBlastPost]);

  const handleLoadingChangeCompleteStatus = (status) => {
    if (status) {
      const startLoadings = handleLoadings('completePost', [...loadings], 'start');
      setLoadings([...startLoadings]);
    } else {
      const endLoadings = handleLoadings('completePost', [...loadings], 'end');
      setLoadings([...endLoadings]);
    }
  };

  const handleUpdateAccessCard = async () => {
    handleLoadingChangeCompleteStatus(true);
    try {
      const result = await PostActions.toggleCompletePost({
        isComplete: !isPostComplete,
        postId,
        companyId,
        teamId,
      });
      setCompletePost(!isPostComplete);

      const status = handleStatusMsg(result, 'success');

      enqueueSnackbar(status.message, {
        variant: 'success',
      });
    } catch (error) {
      const status = handleStatusMsg(error, 'error');

      if (status?.message) {
        enqueueSnackbar(status.message, {
          variant: 'error',
        });
      }
    } finally {
      handleLoadingChangeCompleteStatus(false);
    }
  };

  const pageType = PageConstants.pageType.posts;

  const breadcrumb = generateRoutesAndTitle({
    type: pageType,
    data: {
      companyId, currentTeam, currentBlast, currentBlastPost,
    },
  });

  SearchJumpToActions.saveRecentPage({
    company: currentCompany,
    team: currentTeam,
    userId: user?._id,
    itemId: postId,
    url: breadcrumb.url,
    routes: breadcrumb.routes,
    name: breadcrumb.title,
    type: pageType,
  });

  return (
    <>
      <Title title={
        currentBlastPost?.title || PageConstants.pageProperties[pageType]?.defaultTitle
        }
      />
      <GeneralSubNavBar
        breadcrumbRoutes={breadcrumb.routes}
        pageTitle={breadcrumb.title}
        bottomBarOverviewOptionId="blasts"
        bottomBarGlobalActions={(
          <>
            <GlobalActionButton
              text="Tambah Komentar"
              icon={<AddIcon />}
              handleClick={scrollAndOpenCreateCommentForm}
            />
            <GlobalActionButton
              handleClick={handleShowModalRoleUser}
              text="Akses Saya"
              icon={<VerifiedUserIcon />}
            />
          </>
        )}
      />
      <LayoutMain>
        <SurfacePaperMain>
          <div className={containerWithSidebarStyles[isSidebarOpen]}>
            <FeedbackLoadingMain
              wait="blastPostPage"
              loadings={loadings}
              loadingComponent={<PostBlastPageSkeleton />}
            >
              <div className={styles.headerSection}>
                <div className={styles.titleSection}>
                  <div className={styles.titleSection__title}>
                    {currentBlastPost?.archived?.status && (
                      <div className={styles.archivedSection}>
                        <ArchiveIcon />
                        <h1>Pengumuman ini udah terarsip</h1>
                      </div>
)}
                    <div className={styles.titleSection__creator}>
                      <div className={styles.titleSection__creator__photo}>
                        <DisplayAvatarMember
                          alt={currentBlastPost?.creator?.fullName}
                          src={currentBlastPost?.creator?.photoUrl}
                          size="xxl"
                        />
                      </div>
                      <div className={styles.titleSection__creator__body}>
                        <DisplayTextSubHeadline
                          mode="22"
                          decoration="bold"
                          color={Color.grayIconEditDocs}
                        >
                          <PrivateIcon data={currentBlastPost} />
                          {' '}
                          {currentBlastPost?.title}
                        </DisplayTextSubHeadline>
                        <div className={styles.creatorName}>
                          <DisplayTextSubHeadline
                            mode="14"
                            color={Color.blueNavy4}
                          >
                            {currentBlastPost?.creator?.fullName}
                          </DisplayTextSubHeadline>
                        </div>
                        <div className={styles.dateCreatorSection}>
                          <DisplayDateBox date={currentBlastPost.createdAt} relativeTime dateStyle="MonthDate" />
                          { isPostComplete
                            ? <DisplayDateBox date={currentBlastPost.complete?.updatedAt} changeColorDate complete={PostConstants.completeDueDate} icon dateStyle="MonthDate" />
                            : (
                              <>
                                {
                          isPostAutoCompleteDueDate
                            ? <DisplayDateBox date={currentBlastPost.autoComplete.date} icon changeColorDate dateStyle="MonthDate" />
                            : <>{currentBlastPost.dueDate ? <DisplayDateBox date={currentBlastPost.dueDate} icon changeColorDate dateStyle="MonthDate" /> : null}</>
}
                              </>
                            )}
                          {!isPostAutoCompleteDueDate
                          && checkIfUserAuthorizedToEditData(
                            currentBlastPost, user, currentRoleUser,
                            currentTeamBelowRole,
                          )
                          && (
                            <div className={styles.contentSection__header__title__icon__subaction}>
                              <OverlayButton wait="completePost" loadings={loadings}>
                                <BlastPostSubAction
                                  icon={
                                    isPostComplete
                                      ? <CheckCircleOutlineOutlinedIcon />
                                      : <RadioButtonUncheckedIcon />
                                  }
                                  onClick={handleUpdateAccessCard}
                                />
                              </OverlayButton>
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className={styles.menuSection}>
                  {checkIfUserAuthorizedToEditData(currentBlastPost, user,
                    currentRoleUser, currentTeamBelowRole)
                  && <InputButtonMore onClick={handleShowPostMenu} />}
                  {showPostMenu ? (
                    <PostMenu
                      post={currentBlastPost}
                      cancel={handleCancelShowPostMenu}
                    />
                  ) : null}
                </div>
              </div>
              <DisplayDividerMain />
              <div className={styles.postsSection}>
                {isInitialContentReady ? <EditorView model={editorModel} /> : <p>Please wait...</p>}
              </div>
              <div className={styles.cheersSection}>
                {isEmpty(currentBlastPost) ? null : <CheersContainer cheers={currentBlastPost?.cheers} type="blastMain" receiver={currentBlastPost?.creator?._id} primaryParentId={currentBlastPost?._id} />}
              </div>
              <div className={styles.seenSection}>
                {isUserCreator && (
                  <SeenInfo
                    count={currentBlastPost?.seen?.length}
                    onClick={handleShowSeenModal}
                    variant="black"
                  />
                )}
              </div>
              <div className={styles.typingSection}>
                <UserTyping
                  allUserTyping={allUserTypingPost}
                  companyMembers={currentCompany?.members}
                />
              </div>
              <div className={styles.commentSection}>
                {!showActivities && (
                <InfinityScroll
                  dataLength={lists?.length}
                  hasMore={checkIfListNeedToLoad()}
                  next={handleLoadMoreLists}
                  emptyMessage="Belum ada komentar disini..."
                  style={{
                    overflow: undefined,
                  }}
                >
                  <div>
                    <CommentsSectionContainer
                      openForm={openCreateCommentForm}
                      onOpenForm={handleOpenCreateCommentForm}
                      ref={createCommentRef}
                      comments={lists}
                      type="blast"
                      handleShowActivities={handleShowActivities}
                      socket={socket}
                    />
                  </div>
                </InfinityScroll>
                )}
                {showActivities && (
                <ActivityByModule
                  variant={serviceTypeRef.post}
                  handleHideActivities={handleHideActivities}
                />
                )}
              </div>
              <DisplayDividerMain />
              {currentBlastPost.subscribers !== undefined ? (
                <div className={styles.Subscribers}>
                  <div className={styles.subscribersHeader}>
                    {currentBlastPost.subscribers.length < 1
                      ? (
                        <DisplayTextBody decoration="bold">
                          {/* This blast post doesn&apos;t have any subscribers. */}
                          Pengumuman ini belum punya penerima.
                        </DisplayTextBody>
                      )
                      : (
                        <DisplayTextBody>
                          {/* This post is notified to */}
                          Pengumuman ini diterima oleh
                          {' '}
                          {currentBlastPost.subscribers.length}
                          {' '}
                          anggota :
                        </DisplayTextBody>
                      )}
                  </div>
                  <div className={styles.subscribersItem}>

                    {currentBlastPost.subscribers.map((subscriber) => (
                      <div key={subscriber?._id}>
                        <DisplayAvatarMemberWithOnline
                          src={subscriber.photoUrl}
                          userId={subscriber._id}
                          // prevLoc={location.pathname}
                          size="sm"
                        />
                      </div>
                    ))}
                  </div>
                </div>
              ) : null}
            </FeedbackLoadingMain>
          </div>
        </SurfacePaperMain>
        <MyRoleUser
          open={showModalRoleUser}
          onClose={handleHideModalRoleUser}
          featureId={postId}
          featureType={typeRef.post}
          featureTitle={currentBlastPost?.title}
          roleType={roleTypeRef.feature}
          featureData={currentBlastPost}
        />
        <SeenModal
          open={showSeenModal}
          onClose={handleHideSeenModal}
          type={SeenConstants.typeModule.post}
          itemId={postId}
        />
      </LayoutMain>
    </>
  );
}

export default memo(PostBlastPage);
