import React, { useState, useEffect } from 'react';
import { useSnackbar } from 'notistack';
import CloseIcon from '@mui/icons-material/Close';
import EditIcon from '@mui/icons-material/Edit';
import PropTypes from 'prop-types';
import { useHistory, useParams, useLocation } from 'react-router';
import BoxPopOver from '../../../components/UI/BoxPopOver/BoxPopOver';
import SeparatorLine from '../../../components/UI/SeparatorLine/SeparatorLine';
import styles from './FileMenu.module.css';
import OverlayButton from '../../../components/UI/Button/OverlayButton/OverlayButton';
import handleStatusMsg from '../../../utilities/handleStatusMsg';
import handleLoadings from '../../../utilities/handleLoadings';
import apiUtil from '../../../utilities/apiUtil';
import { FileActions } from '../../../actions';

const FileMenu = ({ file, cancel }) => {
  const [archiveFile, setArchiveFile] = useState();
  const { enqueueSnackbar } = useSnackbar();
  const [loadings, setLoadings] = useState([]);

  const history = useHistory();
  const location = useLocation();
  const params = useParams();
  const { companyId, teamId } = params;

  useEffect(() => {
    if (archiveFile === undefined) return;

    const archivedApiFile = async () => {
      const fileId = archiveFile;

      try {
        const startLoadings = handleLoadings('fileMenu', [...loadings], 'start');
        setLoadings([...startLoadings]);

        const result = await FileActions.archiveFile({ fileId, companyId, teamId, });

        const status = handleStatusMsg(result, 'success');

        enqueueSnackbar(status.message, {
          variant: 'success',
        });

        setArchiveFile();

        cancel();
      } catch (err) {
        const status = handleStatusMsg(err, 'error');

        enqueueSnackbar(status.message, {
          variant: 'error',
        });
      } finally {
        const endLoadings = handleLoadings('fileMenu', [...loadings], 'end');
        setLoadings([...endLoadings]);
      }
    };
    archivedApiFile();
  }, [archiveFile]);

  const handleEditFile = () => {
    history.push(`${location.pathname}/edit`);
  };

  const handleArchivedFile = (fileId) => {
    setArchiveFile(fileId);
  };

  return (
    <BoxPopOver>
      <div className={styles.headerSection}>
        <h1>
          Menu
        </h1>
        <CloseIcon onClick={cancel} />
      </div>
      <SeparatorLine />
      <div className={styles.bodySection}>
        <OverlayButton loadings={loadings} wait="fileMenu">
          <div onClick={handleEditFile} className={styles.menu}>
            <EditIcon />
            <p>Ubah</p>
          </div>
          {file.archived ? (
            <>
              {file.archived.status ? (
                <div className={styles.menu}>
                  <p>File ini udah terarsip</p>
                </div>
              ) : (
                <div onClick={() => handleArchivedFile(file._id)} className={styles.menu}>
                  <p>Arsipkan File ini</p>
                </div>
              )}
            </>
          ) : null}
        </OverlayButton>
      </div>
    </BoxPopOver>
  );
};

FileMenu.propTypes = {
  file: PropTypes.object.isRequired,
  cancel: PropTypes.func.isRequired,
};

export default FileMenu;
