import { useContext, useEffect, useCallback } from 'react';
import { useLocation } from 'react-router-dom';
import { GlobalContext } from '../contexts/GlobalStateProvider';
import { WebTrackingActions } from '../actions';
import { getStoreLastVisitDate, setStoreLastVisitDate } from '../utilities/localStorage';
import { isDayToday } from '../utilities/dateUtil';
import { connectGTMBase } from '../services/reactGtm';

function useTrackGTM({
  enableExecuteWithoutUser = false,
  payloadGTM,
}) {
  const [{ user }, dispatch] = useContext(GlobalContext);
  const location = useLocation();

  useEffect(() => {
    if (!enableExecuteWithoutUser) {
      if (!user?._id) return;
    }
    connectGTMBase();
  }, [user, location]);
}

function useTrackMetricVisitorPublic({
  planName,
}) {
  const createMetricVisitorPublicApi = useCallback(async () => {
    try {
      await WebTrackingActions.createMetricVisitorPublic({ planName });
    } catch (error) {
      console.log(error);
    }
  }, []);

  useEffect(() => {
    if (!planName) return;
    createMetricVisitorPublicApi();
  }, []);
}

function useTrackMetricVisitor({
  companyId,
  user,
}) {
  const userId = user?._id;

  const createMetricVisitorApi = useCallback(async () => {
    try {
      await WebTrackingActions.createMetricVisitor({ companyId });
    } catch (error) {
      console.log(error);
    }
  }, []);

  useEffect(() => {
    if (!userId || !companyId) return;

    const today = new Date();
    const date = getStoreLastVisitDate({ companyId, userId });

    if (!date) {
      createMetricVisitorApi();
      setStoreLastVisitDate({ companyId, userId, date: today });
      return;
    }

    if (!isDayToday(date)) {
      createMetricVisitorApi();
      setStoreLastVisitDate({ companyId, userId, date: today });
    }
  }, [user]);
}

export {
  useTrackMetricVisitor,
  useTrackMetricVisitorPublic,
  useTrackGTM,
};
