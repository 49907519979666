import React, {
  memo, useContext, useEffect, useState,
} from 'react';
import PropTypes from 'prop-types';
import DownloadIcon from '@mui/icons-material/Download';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { Alert, useMediaQuery } from '@mui/material';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import { useHistory } from 'react-router-dom';
import styles from './LoginEmailPassword.module.css';
import Color from '../../../themes/colors';
import { InputButtonMain } from '../../../components/UI_V2/Input/Button';
import { InputTextFieldMain } from '../../../components/UI_V2/Input/TextField';
import { DisplayTextBody } from '../../../components/UI_V2/Display/Text';
import LinkNoDecor from '../../../components/LinkNoDecor/LinkNoDecor';
import handleStatusMsg from '../../../utilities/handleStatusMsg';
import handleLoadings from '../../../utilities/handleLoadings';
import {
  AuthActions,
  CheckLoginActions, CompanyActions,
  TeamActions,
} from '../../../actions';
import { GlobalContext } from '../../../contexts/GlobalStateProvider';

const LoginEmailPassword = ({
  isFromEmailInvitation,
}) => {
  const [{}, dispatch] = useContext(GlobalContext);
  const [newUser, setNewUser] = useState({
    email: '',
    password: '',
  });

  const history = useHistory();

  const [loadings, setLoadings] = useState([]);

  const [showPassword, setShowPassword] = useState(false);
  // const [isValidPassword, setIsValidPassword] = useState(true);
  const [isPasswordEmpty, setIsPasswordEmpty] = useState(true);
  const [isEmailEmpty, setIsEmailEmpty] = useState(true);
  // const [errorMsgPassword, setErrorMsgPassword] = useState('');
  // const [isValidEmail, setIsValidEmail] = useState(true);
  // const [errorMsgEmail, setErrorMsgEmail] = useState('');
  const [errorMsgEmpty, setErrorMsgEmpty] = useState('');
  const [showErrorEmpty, setShowErrorEmpty] = useState(false);
  // const [errorMsgClickStillError, setErrorMsgClickStillError] = useState('');
  // const [showErrorClickStillError, setShowErrorClickStillError] = useState(false);

  const [showSuccessLogin, setShowSuccessLogin] = useState(false);
  const [successLoginMsg, setSuccessLoginMsg] = useState('');

  const [showErrorLogin, setShowErrorLogin] = useState(false);
  const [errorLoginMsg, setErrorLoginMsg] = useState('');

  const [isProcessing, setIsProcessing] = useState(false);

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const handleChanged = (event) => {
    const { name, value } = event.target;
    setNewUser((prevValue) => ({
      ...prevValue,
      [name]: value,
    }));

    if (showErrorEmpty) {
      setShowErrorEmpty(false);
      setErrorMsgEmpty('');
    }

    // if (showErrorClickStillError) {
    //   setShowErrorClickStillError(false);
    //   setErrorMsgClickStillError('');
    // }

    if (showSuccessLogin) {
      setShowSuccessLogin(false);
      setSuccessLoginMsg('');
    }

    if (showErrorLogin) {
      setShowErrorLogin(false);
      setErrorLoginMsg('');
    }

    if (name === 'password') {
      const isEmpty = value.length < 1;

      if (isEmpty) {
        setIsPasswordEmpty(true);
        // setIsValidPassword(true);
        // setErrorMsgPassword('');
        return;
      }

      setIsPasswordEmpty(false);

      // const { isValid, errorMsg } = validatePassword(value);

      // if (isValid) {
      //   setIsValidPassword(true);
      //   setErrorMsgPassword('');
      // } else {
      //   setIsValidPassword(false);
      //   setErrorMsgPassword(errorMsg);
      // }
    }

    if (name === 'email') {
      const isEmpty = value.length < 1;

      if (isEmpty) {
        setIsEmailEmpty(true);
        // setIsValidEmail(true);
        // setErrorMsgEmail('');
        return;
      }

      setIsEmailEmpty(false);

      // const validEmail = validateEmail(value);

      // if (validEmail) {
      //   setIsValidEmail(true);
      //   setErrorMsgEmail('');
      // } else {
      //   setIsValidEmail(false);
      //   setErrorMsgEmail('Email harus dengan format nama@domain.com (cth: john@doe.com)');
      // }
    }
  };

  const isThereAnyEmpty = isEmailEmpty || isPasswordEmpty;

  // const isThereAnyError = !isValidEmail
  // || !isValidPassword;

  const handleClickWhenEmpty = (event) => {
    if (event) {
      event.preventDefault();
    }

    let message = '';
    if (isEmailEmpty) {
      message = `${message}Email masih kosong. `;
    }

    if (isPasswordEmpty) {
      message = `${message}Password masih kosong. `;
    }

    setErrorMsgEmpty(message);
    setShowErrorEmpty(true);
  };

  // const handleClickWhenStillError = () => {
  //   setErrorMsgClickStillError('Belum bisa melanjutkan, perbaiki dulu error diatas.');
  //   setShowErrorClickStillError(true);
  // };
  const checkAndGoFromEmailInvitation = () => {
    const emailInvitationToken = localStorage.getItem('emailInvitationToken');
    history.push(`/invitation/${emailInvitationToken}`);
  };

  const processLoginApi = async ({
    email,
    password,
  }) => {
    try {
      const startLoadings = handleLoadings('loginByEmailAndPassword', [...loadings], 'start');
      setLoadings([...startLoadings]);

      const result = await AuthActions.loginByEmailAndPassword({
        email,
        password,
      });

      const { user, token } = result;

      if (user && token) {
        // const status = handleStatusMsg(result, 'success');

        setShowSuccessLogin(true);
        setShowErrorLogin(false);
        setSuccessLoginMsg('Login berhasil, tunggu sebentar');
        setErrorLoginMsg('');

        const resultCompanies = await CompanyActions.initiateCompanies({}, dispatch);
        CheckLoginActions.setUserAndToken({ user, token }, dispatch);

        if (isFromEmailInvitation) {
          checkAndGoFromEmailInvitation();
          return;
        }

        if (!resultCompanies || resultCompanies.length < 1) {
          history.push('/welcome');
        } else {
          const resultCompany = await CheckLoginActions.initiateCurrentCompany(
            { previousPath: '/', companies: resultCompanies, userId: user._id },
            dispatch,
          );

          TeamActions.initiateTeams(
            {
              currentCompany: resultCompany,
              userId: user._id,
            },
            dispatch,
          );

          history.push(`/companies/${resultCompany._id}`);
        }
      } else {
        const status = handleStatusMsg('User or Token not found', 'error');

        setShowErrorLogin(true);
        setShowSuccessLogin(false);
        setErrorLoginMsg(status.message);
        setSuccessLoginMsg('');
      }
    } catch (err) {
      const status = handleStatusMsg(err, 'error');

      setShowErrorLogin(true);
      setShowSuccessLogin(false);
      setErrorLoginMsg(status.message);
      setSuccessLoginMsg('');
    } finally {
      const endLoadings = handleLoadings('loginByEmailAndPassword', [...loadings], 'end');
      setLoadings([...endLoadings]);
      setIsProcessing(false);
    }
  };

  const handleClickLogin = (event) => {
    if (event) {
      event.preventDefault();
    }

    if (isThereAnyEmpty) {
      handleClickWhenEmpty();
      return;
    }

    // if (isThereAnyError) {
    //   handleClickWhenStillError();
    //   return;
    // }

    if (isProcessing) return;

    setIsProcessing(true);

    processLoginApi({
      email: newUser.email,
      password: newUser.password,
    });
  };

  const getForgotPasswordUrl = () => {
    if (isFromEmailInvitation) {
      return '/auth/email/password/reset?sourcePage=emailInvitation';
    }
    return '/auth/email/password/reset';
  };

  const getSignUpUrl = () => {
    if (isFromEmailInvitation) {
      return '/signup?sourcePage=emailInvitation';
    }
    return '/signup';
  };

  return (
    <div className={styles.container}>
      <form onSubmit={isThereAnyEmpty ? handleClickWhenEmpty : handleClickLogin}>
        <div className={styles.form}>
          <div className={styles.formText}>
            <DisplayTextBody
              decoration="bold"
            >
              Email
            </DisplayTextBody>
          </div>
          <div className={styles.formArea}>
            <InputTextFieldMain
              placeholder="emailkamu@gmail.com"
              name="email"
              onChange={(event) => handleChanged(event)}
              value={newUser.email}
              border="rounded"
              type="email"
              size="lg"
            />
          </div>
        </div>
        <div className={styles.form}>
          <div className={styles.formText}>
            <DisplayTextBody
              decoration="bold"
            >
              Password
            </DisplayTextBody>
          </div>
          <div className={styles.formArea}>
            <InputTextFieldMain
              placeholder="**********"
              name="password"
              onChange={(event) => handleChanged(event)}
              value={newUser.password}
              border="rounded"
              type={showPassword ? 'text' : 'password'}
              size="lg"
              showHelperIcon
              helperIcon={showPassword ? <VisibilityOffIcon /> : <VisibilityIcon />}
              onClickHelperIcon={togglePasswordVisibility}
            />
          </div>
        </div>
        <div className={styles.forgotPasswordSection}>
          <LinkNoDecor to={getForgotPasswordUrl}>
            <DisplayTextBody
              color={Color.brightBlue}
            >
              Lupa Password?
            </DisplayTextBody>
          </LinkNoDecor>
        </div>
        {isThereAnyEmpty && showErrorEmpty && <div className={styles.alertEmptySection}><Alert severity="error">{errorMsgEmpty}</Alert></div>}
        {/* {isThereAnyError && showErrorClickStillError
        && <div className={styles.alertEmptySection}><Alert
        severity="error">{errorMsgClickStillError}</Alert></div>} */}

        {showSuccessLogin && (
        <div className={styles.alertClickLoginSection}>
          <Alert severity="success">{successLoginMsg}</Alert>
        </div>
        )}
        {showErrorLogin && (
        <div className={styles.alertClickLoginSection}>
          <Alert severity="error">{errorLoginMsg}</Alert>
        </div>
        )}
        <div className={styles.action}>
          <InputButtonMain
            border="rounded"
            size="lg"
            wide="block"
            loadings={loadings}
            wait="loginByEmailAndPassword"
            handleClick={isThereAnyEmpty ? handleClickWhenEmpty : handleClickLogin}
            type="submit"
          >
            Masuk
          </InputButtonMain>
        </div>
      </form>
      <div className={styles.registerNowSection}>
        <div className={styles.registerNowSection__text}>
          <DisplayTextBody>
            Belum punya akun?
          </DisplayTextBody>
        </div>
        <div className={styles.registerNowSection__button}>
          <LinkNoDecor to={getSignUpUrl}>
            <DisplayTextBody color={Color.brightBlue}>
              Daftar Sekarang
            </DisplayTextBody>
          </LinkNoDecor>
        </div>
      </div>
    </div>
  );
};

LoginEmailPassword.propTypes = {
  isFromEmailInvitation: PropTypes.bool,
};

LoginEmailPassword.defaultProps = {
  isFromEmailInvitation: false,
};

export default memo(LoginEmailPassword);
