import React, { memo, useState } from 'react';
import PropTypes from 'prop-types';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import styles from './TeamLocationUserReport.module.css';
import Color from '../../../../../../themes/colors';
import { DisplayTextBody, DisplayTextSubHeadline } from '../../../../../../components/UI_V2/Display/Text';

const TeamLocationUserReport = ({ title, subTitle }) => (
  <div className={styles.container}>
    <div className={styles.title}>
      <DisplayTextBody
        mode="12"
        color={Color.gray3}
      >
        {title}
      </DisplayTextBody>
    </div>
    <div className={styles.text}>
      <DisplayTextSubHeadline
        mode="16"
        decoration="bold"
        color={Color.blueNavy3}
      >
        {subTitle}
      </DisplayTextSubHeadline>
    </div>
  </div>
);

TeamLocationUserReport.propTypes = {
  title: PropTypes.string.isRequired,
  subTitle: PropTypes.string.isRequired,
};

TeamLocationUserReport.defaultProps = {};

export default memo(TeamLocationUserReport);
