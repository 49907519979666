import React, { memo, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';
import queryString from 'query-string';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { useSnackbar } from 'notistack';
import styles from './PirateMetricContent.module.css';
import { DisplayTextBody } from '../../../../components/UI_V2/Display/Text';
import Color from '../../../../themes/colors';
import factoryIllustration from '../../../../assets/DashboardAdminPage/PirateMetricPage/2-factory-illustration.png';
import activationIllustration from '../../../../assets/DashboardAdminPage/PirateMetricPage/3-activation-illustration.png';
import visitorIllustration from '../../../../assets/DashboardAdminPage/PirateMetricPage/1-visit-illustration.png';
import revenueIllustration from '../../../../assets/DashboardAdminPage/PirateMetricPage/4-revenue-illustration.png';
import Acquisition from './Acquisition/Acquisition';
import Illustration from './Illustration/Illustration';
import Activation from './Activation/Activation';
import Revenue from './Revenue/Revenue';
import Retention from './Retention/Retention';
import Referral from './Referral/Referral';
import { initiateMetricCount } from '../../../../actions/AdministratorActions';
import handleLoadings from '../../../../utilities/handleLoadings';
import handleStatusMsg from '../../../../utilities/handleStatusMsg';
import FeedbackLoadingMain from '../../../../components/UI_V2/Feedback/Loading/Main/Main';
import FilterDropdown from '../../../../components/Container/FilterDropdown/FilterDropdown';
import Visitor from './Visitor/Visitor';
import Churn from './Churn/Churn';
import { calculateMonthday } from '../../../../utilities/dateUtil';

const PirateMetricContent = ({
  handleSetHeaderText,
}) => {
  const location = useLocation();
  const { enqueueSnackbar } = useSnackbar();
  const [loadings, setLoadings] = useState([]);
  const [metricCount, setMetricCount] = useState({
    visitor: {
      value: 0,
      percentage: 0,
    },
    acquisition: {
      value: 0,
      percentage: 0,
    },
    activation: {
      value: 0,
      percentage: 0,
    },
    retention: {
      value: 0,
      percentage: 0,
    },
    revenue: {
      value: 0,
      percentage: 0,
    },
    referral: {
      value: 0,
      percentage: 0,
    },
    churn: {
      value: 0,
      percentage: 0,
    },
  });

  const query = queryString.parse(location.search);

  const {
    fromDate,
    untilDate,
    isOnTrial,
  } = query;

  let initialFromDate = calculateMonthday(new Date(), 14, 'subtract');

  if (fromDate) {
    initialFromDate = new Date(fromDate);
  }

  let initialUntilDate = new Date();
  if (untilDate) {
    initialUntilDate = new Date(untilDate);
  }

  let initialTrial;

  if (isOnTrial === 'true') {
    initialTrial = true;
  }
  if (isOnTrial === 'false') {
    initialTrial = false;
  }

  const [filters, setFilters] = useState({
    filterFromUntilDate: {
      fromDate: initialFromDate,
      untilDate: initialUntilDate,
    },
    filterTrial: initialTrial,
  });

  const initiateMetricCountApi = async (filtersValue) => {
    try {
      const startLoadings = handleLoadings('initiateMetricCount', [...loadings], 'start');
      setLoadings([...startLoadings]);
      const result = await initiateMetricCount({
        setMetricCount,
        filters: filtersValue,
      });

      handleSetHeaderText({
        fromDate: filtersValue.filterFromUntilDate.fromDate,
        untilDate: filtersValue.filterFromUntilDate.untilDate,
      });
    } catch (error) {
      const status = handleStatusMsg(error, 'error');

      enqueueSnackbar(status.message, {
        variant: 'error',
      });
    } finally {
      const endLoadings = handleLoadings('initiateMetricCount', [...loadings], 'end');
      setLoadings([...endLoadings]);
    }
  };

  useEffect(() => {
    initiateMetricCountApi(filters);
  }, []);

  const onApplyFilters = (values) => {
    initiateMetricCountApi(values);
    setFilters(values);
  };

  const urlBasis = `/administrators/reports/companies?fromDate=${filters.filterFromUntilDate.fromDate.toISOString()}&untilDate=${filters.filterFromUntilDate.untilDate.toISOString()}&isOnTrial=${filters.filterTrial}`;
  const urlVisitor = `${urlBasis}&category=visitor`;
  const urlAcquisition = `${urlBasis}&category=acquisition`;
  const urlActivation = `${urlBasis}&category=activation`;
  const urlRetention = `${urlBasis}&category=retention`;
  const urlRevenue = `${urlBasis}&category=revenue`;
  const urlReferral = `${urlBasis}&category=referral`;
  const urlChurn = `${urlBasis}&category=churn`;

  const isFilterCustomerActive = filters.filterTrial === false;
  return (
    <div className={styles.container}>
      <div className={styles.filter}>
        <FilterDropdown
          showFeatureTypeSection={false}
          showArchivedSection={false}
          showTeamSection={false}
          showFromUntilDateSection
          onApplyFilters={onApplyFilters}
          loadingsOnApplyFilters={loadings}
          waitOnApplyFilters="initiateMetricCount"
          initialFromDate={filters.filterFromUntilDate.fromDate}
          initialUntilDate={filters.filterFromUntilDate.untilDate}
          showTrialSection
          initialTrial={filters.filterTrial}
        />
      </div>
      <FeedbackLoadingMain
        wait="initiateMetricCount"
        loadings={loadings}
        useSpinner
      >
        <div className={styles.content}>
          <div className={styles.visitAcquisitionFactory}>
            {!isFilterCustomerActive && (
            <div className={styles.visitor}>
              <Visitor
                value={metricCount.visitor.value}
                percentage={metricCount.visitor.percentage}
                url={urlVisitor}
              />
            </div>
            )}
            {isFilterCustomerActive && (
            <div className={styles.illustration}>
              <Illustration src={visitorIllustration} />
            </div>
            )}
            <div className={styles.acquisition}>
              <Acquisition
                value={metricCount.acquisition.value}
                percentage={metricCount.acquisition.percentage}
                url={urlAcquisition}
                filterTrial={filters.filterTrial}
              />
            </div>
            <div className={styles.illustration}>
              <Illustration src={factoryIllustration} />
            </div>
          </div>
          <div className={styles.activationRetentionReferral}>
            <div className={styles.retention}>
              <Retention
                value={metricCount.retention.value}
                percentage={metricCount.retention.percentage}
                url={urlRetention}
              />
            </div>
            <div className={styles.activation}>
              <Activation
                value={metricCount.activation.value}
                percentage={metricCount.activation.percentage}
                url={urlActivation}
              />
            </div>
            <div className={styles.referral}>
              <Referral
                value={metricCount.referral.value}
                percentage={metricCount.referral.percentage}
                url={urlReferral}
              />
            </div>
          </div>
          <div className={styles.activationRevenueIllustration}>
            <div className={styles.illustration}>
              <Illustration src={activationIllustration} />
            </div>
            <div className={styles.revenueAndChurn}>
              <div className={styles.revenue}>
                <Revenue
                  value={metricCount.revenue.value}
                  percentage={metricCount.revenue.percentage}
                  url={urlRevenue}
                />
              </div>
              <div className={styles.churn}>
                <Churn
                  value={metricCount.churn.value}
                  percentage={metricCount.churn.percentage}
                  url={urlChurn}
                />
              </div>
            </div>
            <div className={styles.illustration}>
              <Illustration src={revenueIllustration} className={styles.revenueIllustration} />
            </div>
          </div>
        </div>
      </FeedbackLoadingMain>
    </div>
  );
};

PirateMetricContent.propTypes = {
  handleSetHeaderText: PropTypes.func.isRequired,
};

export default memo(PirateMetricContent);
