import React, { memo, useCallback, useEffect } from 'react';
import PropTypes from 'prop-types';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import styles from './QuickDateSelection.module.css';
import Color from '../../../../../themes/colors';
import ButtonTextWithCheck from '../../ButtonTextWithCheck/ButtonTextWithCheck';
import { calculateMonthday } from '../../../../../utilities/dateUtil';

const QuickDateSelection = ({
  handleSelectQuickDate,
  selectedQuickDate,
  // handleDateChangeUntil,
  // handleDateChangeFrom,
  onApply,
  modifyFromUntilDateByQuickDate,
  handleSetIsFirstLoad,
  handleSetIsFirstLoadDate,
  handleUnsetIsFirstLoadDate,
  listDisableQuickDate,
}) => {
  const chooseQuickDate = useCallback((value) => {
    const isCustomSelected = value === 'custom';

    if (isCustomSelected) {
      handleSelectQuickDate(value);
      handleSetIsFirstLoad(false);
      handleSetIsFirstLoadDate(true);
      // modifyFromUntilDateByQuickDate(value);
      return;
    }

    modifyFromUntilDateByQuickDate(value);

    handleSelectQuickDate(value);
    onApply();
  }, []);

  useEffect(() => {
    if (selectedQuickDate === 'custom') return;
    modifyFromUntilDateByQuickDate(selectedQuickDate);
  }, []);

  const isTodayDisabled = listDisableQuickDate.includes('today');
  const isYesterdayDisabled = listDisableQuickDate.includes('yesterday');
  const isThisWeekDisabled = listDisableQuickDate.includes('thisWeek');
  const isPreviousWeekDisabled = listDisableQuickDate.includes('previousWeek');
  const isThisTwoWeekDisabled = listDisableQuickDate.includes('thisTwoWeek');
  const isPreviousTwoWeekDisabled = listDisableQuickDate.includes('previousTwoWeek');
  const isThisMonthDisabled = listDisableQuickDate.includes('thisMonth');
  const isPreviousMonthDisabled = listDisableQuickDate.includes('previousMonth');
  const isThisQuarterDisabled = listDisableQuickDate.includes('thisQuarter');
  const isPreviousQuarterDisabled = listDisableQuickDate.includes('previousQuarter');
  const isThisYearDisabled = listDisableQuickDate.includes('thisYear');
  const isPreviousYearDisabled = listDisableQuickDate.includes('previousYear');
  const isCustomDisabled = listDisableQuickDate.includes('custom');

  return (
    <div className={styles.container}>
      <div className={styles.select}>
        {!isTodayDisabled && (
        <ButtonTextWithCheck
          text="Hari ini"
          onClick={() => chooseQuickDate('today')}
          checked={selectedQuickDate === 'today'}
        />
        )}
        {!isYesterdayDisabled && (
        <ButtonTextWithCheck
          text="Kemarin"
          onClick={() => chooseQuickDate('yesterday')}
          checked={selectedQuickDate === 'yesterday'}
        />
        )}
        {!isThisWeekDisabled && (
        <ButtonTextWithCheck
          text="Minggu ini"
          onClick={() => chooseQuickDate('thisWeek')}
          checked={selectedQuickDate === 'thisWeek'}
        />
        )}
        {!isPreviousWeekDisabled && (
        <ButtonTextWithCheck
          text="Minggu lalu"
          onClick={() => chooseQuickDate('previousWeek')}
          checked={selectedQuickDate === 'previousWeek'}
        />
        )}
        {!isThisTwoWeekDisabled && (
        <ButtonTextWithCheck
          text="2 Minggu ini"
          onClick={() => chooseQuickDate('thisTwoWeek')}
          checked={selectedQuickDate === 'thisTwoWeek'}
        />
        )}
        {!isPreviousTwoWeekDisabled && (
        <ButtonTextWithCheck
          text="2 Minggu lalu"
          onClick={() => chooseQuickDate('previousTwoWeek')}
          checked={selectedQuickDate === 'previousTwoWeek'}
        />
        )}
        {!isThisMonthDisabled && (
        <ButtonTextWithCheck
          text="Bulan ini"
          onClick={() => chooseQuickDate('thisMonth')}
          checked={selectedQuickDate === 'thisMonth'}
        />
        )}
        {!isPreviousMonthDisabled && (
        <ButtonTextWithCheck
          text="Bulan lalu"
          onClick={() => chooseQuickDate('previousMonth')}
          checked={selectedQuickDate === 'previousMonth'}
        />
        )}
        {!isThisQuarterDisabled && (
        <ButtonTextWithCheck
          text="Kuartal ini"
          onClick={() => chooseQuickDate('thisQuarter')}
          checked={selectedQuickDate === 'thisQuarter'}
        />
        )}
        {!isPreviousQuarterDisabled && (
        <ButtonTextWithCheck
          text="Kuartal lalu"
          onClick={() => chooseQuickDate('previousQuarter')}
          checked={selectedQuickDate === 'previousQuarter'}
        />
        )}
        {!isThisYearDisabled && (
        <ButtonTextWithCheck
          text="Tahun ini"
          onClick={() => chooseQuickDate('thisYear')}
          checked={selectedQuickDate === 'thisYear'}
        />
        )}
        {!isPreviousYearDisabled && (
        <ButtonTextWithCheck
          text="Tahun lalu"
          onClick={() => chooseQuickDate('previousYear')}
          checked={selectedQuickDate === 'previousYear'}
        />
        )}
        {!isCustomDisabled && (
        <ButtonTextWithCheck
          text="Kustom"
          onClick={() => chooseQuickDate('custom')}
          checked={selectedQuickDate === 'custom'}
        />
        )}
      </div>
    </div>
  );
};

QuickDateSelection.propTypes = {
  handleSelectQuickDate: PropTypes.func.isRequired,
  selectedQuickDate: PropTypes.string.isRequired,
  onApply: PropTypes.func.isRequired,
  // handleDateChangeFrom: PropTypes.func.isRequired,
  // handleDateChangeUntil: PropTypes.func.isRequired,
  modifyFromUntilDateByQuickDate: PropTypes.func.isRequired,
  handleSetIsFirstLoad: PropTypes.func.isRequired,
  handleSetIsFirstLoadDate: PropTypes.func.isRequired,
  handleUnsetIsFirstLoadDate: PropTypes.func.isRequired,
  listDisableQuickDate: PropTypes.array,
};

QuickDateSelection.defaultProps = {
  listDisableQuickDate: [],
};

export default memo(QuickDateSelection);
