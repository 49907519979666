import React, { useState, useContext, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import CloseIcon from '@mui/icons-material/Close';
import { useSnackbar } from 'notistack';
import PropTypes from 'prop-types';
import BoxPopOver from '../../../components/UI/BoxPopOver/BoxPopOver';
import SeparatorLine from '../../../components/UI/SeparatorLine/SeparatorLine';
import { GlobalContext } from '../../../contexts/GlobalStateProvider';
import styles from './ManageMoveCard.module.css';
import ListNameOption from './ListNameOption/ListNameOption';
import ListPositionOption from './ListPositionOption/ListPositionOption';
import Button from '../../../components/UI/Button/Button';
import ButtonDiv from '../../../components/UI/Button/ButtonDiv/ButtonDiv';
import handleStatusMsg from '../../../utilities/handleStatusMsg';
import handleLoadings from '../../../utilities/handleLoadings';
import apiUtil from '../../../utilities/apiUtil';
import { CardActions, ListActions } from '../../../actions';
import { NavigationMenuBasic } from '../../../components/UI_V2/Navigation/Menu';
import { useDelayShowHideHandler } from '../../../hooks/HelperHooks';
import { InputButtonMain } from '../../../components/UI_V2/Input/Button';
import CreateListForm from './CreateListForm/CreateListForm';

const ManageMoveCard = ({
  fixedMode,
  topPos,
  leftPos,
  cancel,
  left,
  card,
  list,
  socket,
  enableCreateList,
}) => {
  const [{ currentBoard, currentBoardLists }, dispatch] = useContext(GlobalContext);
  const { enqueueSnackbar } = useSnackbar();
  const [loadings, setLoadings] = useState([]);
  const [movedCard, setMovedCard] = useState();
  const [showCreateListForm, handleShowCreateListForm,
    handleHideCreateListForm] = useDelayShowHideHandler();
  const [destData, setDestData] = useState({
    draggableId: card._id,
    source: {
      droppableId: list._id,
    },
    destination: {
      droppableId: list._id,
      index: 0,
    },
  });

  const [viewListData, setViewListData] = useState({
    listName: list.name,
    listPosition: 1,
  });

  const [showListName, setShowListName] = useState(false);
  const [showListPosition, setShowListPosition] = useState(false);
  const [lists, setLists] = useState([]);
  const params = useParams();
  const { companyId } = params;
  const teamId = params.teamId || card.team?._id || card.team;
  const boardId = params.boardId || card.boards?.[0]?._id || card.boards?.[0] || currentBoard?._id;

  useEffect(() => {
    const fetchAllListsApi = async () => {
      try {
        const startLoadings = handleLoadings('moveCardLists', [...loadings], 'start');
        setLoadings([...startLoadings]);
        const result = await ListActions.getAllListsWithoutCards({
          companyId,
          teamId,
          currentBoard,
          currentBoardLists,
        });
        setLists(result?.lists);
      } catch (error) {
        const status = handleStatusMsg(error, 'error');
        if (status?.message) {
          enqueueSnackbar(status.message, {
            variant: 'error',
          });
        }
      } finally {
        const endLoadings = handleLoadings('moveCardLists', [...loadings], 'end');
        setLoadings([...endLoadings]);
      }
    };
    fetchAllListsApi();
  }, [currentBoardLists]);

  useEffect(() => {
    if (movedCard === undefined) {
      return;
    }

    const patchApiMoveCard = async () => {
      const data = { ...movedCard };
      // catatan sementara {destination, source, draggableId, type}
      // draggableId adalah card ID yang dipindah
      // source.droppableId adalah list sumber
      // destination.droppableId adalah list tujuan
      // destination.index adalah posisi di list tujuan

      try {
        const startLoadings = handleLoadings('moveCard', [...loadings], 'start');
        setLoadings([...startLoadings]);

        const result = await CardActions.moveCard({
          boardId,
          data,
          companyId,
          socket,
          currentMoveBoardLists: lists,
          card,
          currentBoardLists,
          teamId,
        }, dispatch);

        // CardActions.incomingCardListUpdate({ list: result?.data?.list }, dispatch);

        const status = handleStatusMsg(result, 'success');

        enqueueSnackbar(status.message, {
          variant: 'success',
        });

        // close this container after finished
        cancel();
      } catch (err) {
        const status = handleStatusMsg(err, 'error');

        enqueueSnackbar(status.message, {
          variant: 'error',
        });
      } finally {
        const endLoadings = handleLoadings('moveCard', [...loadings], 'end');
        setLoadings([...endLoadings]);
      }
    };
    patchApiMoveCard();
  }, [movedCard]);

  const handleShowMoveListName = () => {
    setShowListName(!showListName);
  };

  const handleShowMoveListPosition = () => {
    setShowListPosition(!showListPosition);
  };

  const handleUpdateDestList = (value) => {
    setDestData((prevValue) => ({
      ...prevValue,
      destination: {
        ...prevValue.destination,
        ...value,
      },
    }));
  };

  const handleUpdateViewList = (value) => {
    setViewListData((prevValue) => ({
      ...prevValue,
      ...value,
    }));
  };

  const handleSaveMoveCard = () => {
    setMovedCard({ ...destData });
  };

  return (
    // <BoxPopOver customStyle={fixedMode ?
    // { position: 'fixed', top: topPos, left: leftPos } : null} left={!!left}>
    <NavigationMenuBasic disabled={showListName || showListPosition} size="mdWider" onClose={cancel} position={left ? 'topLeft' : 'topRight'}>
      {!showCreateListForm && (
      <div className={styles.container}>
        <div className={styles.ManageMoveCard__Header}>
          <h1 className="mr-auto">
            Pindahkan Tugas
          </h1>
          {/* <CloseIcon onClick={cancel} /> */}
        </div>
        <SeparatorLine />
        <div className={styles.body}>
          <p className={styles.ManageMoveCard__smallTitle}>PILIH LIST TUJUAN</p>
          <div className={styles.listSection}>
            <ButtonDiv onClick={handleShowMoveListName} className={styles.listSection__listName}>
              <a>List</a>
              <p>{viewListData.listName}</p>
            </ButtonDiv>
            {showListName
              ? (
                <ListNameOption
                  handleUpdateDestListId={handleUpdateDestList}
                  handleUpdateViewListName={handleUpdateViewList}
                  cancel={handleShowMoveListName}
                  lists={lists}
                />
              )
              : null}
            <ButtonDiv
              onClick={handleShowMoveListPosition}
              className={styles.listSection__listPosition}
            >
              <a>Posisi</a>
              <p>{viewListData.listPosition}</p>
              {showListPosition
                ? (
                  <ListPositionOption
                    handleUpdateDestListIndex={handleUpdateDestList}
                    handleUpdateViewListPosition={handleUpdateViewList}
                    destData={destData}
                    list={list}
                    cancel={handleShowMoveListPosition}
                    lists={lists}
                  />
                )
                : null}
            </ButtonDiv>
          </div>
        </div>
        <div className={styles.footer}>
          <InputButtonMain handleClick={handleSaveMoveCard} variant="success" size="sm" wait="moveCard" loadings={loadings}>Pindahkan</InputButtonMain>
          {enableCreateList && <InputButtonMain handleClick={handleShowCreateListForm} variant="default" size="sm" wait="moveCard" loadings={loadings}>Buat List</InputButtonMain>}
        </div>
      </div>
      )}
      {showCreateListForm && (
      <CreateListForm
        onClose={handleHideCreateListForm}
        companyId={companyId}
        teamId={teamId}
        boardId={boardId}
      />
      )}
    </NavigationMenuBasic>
  // </BoxPopOver>
  );
};

ManageMoveCard.propTypes = {
  fixedMode: PropTypes.bool,
  left: PropTypes.bool,
  topPos: PropTypes.number,
  leftPos: PropTypes.number,
  cancel: PropTypes.func.isRequired,
  card: PropTypes.object.isRequired,
  list: PropTypes.object.isRequired,
  socket: PropTypes.func.isRequired,
  enableCreateList: PropTypes.bool,
};

ManageMoveCard.defaultProps = {
  fixedMode: false,
  left: false,
  topPos: 0,
  leftPos: 0,
  enableCreateList: false,
};

export default ManageMoveCard;
