import React, { memo, useState } from 'react';
import PropTypes from 'prop-types';
import { Box, Skeleton } from '@mui/material';
import styles from './Header.module.css';

const PostBlastPageSkeletonHeader = () => (
  <Box className={styles.container}>
    <div className={styles.photoAndUser}>
      <div className={styles.photo}>
        <Skeleton
          animation="wave"
          variant="circular"
          width={30}
          height={30}
        />
      </div>
      <Skeleton
        animation="wave"
        variant="text"
        width={150}
      />
    </div>
    <Box className={styles.item}>
      <Box className={styles.title}>
        <Skeleton
          animation="wave"
          variant="rectangular"
          height={24}
        />
      </Box>
      <Box className={styles.creator}>
        <Skeleton
          animation="wave"
          variant="text"
          width={140}
        />
      </Box>
      <Box className={styles.date}>
        <Skeleton
          sx={{
            marginRight: '5px',
          }}
          animation="wave"
          variant="rectangular"
          width={100}
          height={14}
        />
        <Skeleton
          animation="wave"
          variant="rectangular"
          width={100}
          height={14}
        />
      </Box>
    </Box>
  </Box>
);

export default memo(PostBlastPageSkeletonHeader);
