import React, { memo, useContext, useState } from 'react';
import PropTypes from 'prop-types';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { useSnackbar } from 'notistack';
import { useParams, useHistory } from 'react-router-dom';
import styles from './RestoreButton.module.css';
import { DisplayTextBody } from '../../../../../UI_V2/Display/Text';
import OverlayButton from '../../../../../UI/Button/OverlayButton/OverlayButton';
import handleLoadings from '../../../../../../utilities/handleLoadings';
import { ArchivedItemsActions } from '../../../../../../actions';
import handleStatusMsg from '../../../../../../utilities/handleStatusMsg';
import { GlobalContext } from '../../../../../../contexts/GlobalStateProvider';
import { InputButtonMain } from '../../../../../UI_V2/Input/Button';

const RestoreButton = ({
  item,
  unarchiveDataFunc,
  initiateListDataApi,
}) => {
  const [loadings, setLoadings] = useState([]);
  const { enqueueSnackbar } = useSnackbar();

  const handleClickRestore = async () => {
    try {
      const startLoadings = handleLoadings(item._id, [...loadings], 'start');
      setLoadings([...startLoadings]);

      await unarchiveDataFunc({ item });
      await initiateListDataApi();

      enqueueSnackbar('Successfully restore item', {
        variant: 'success',
      });
    } catch (err) {
      const status = handleStatusMsg(err, 'error');

      enqueueSnackbar(status.message, {
        variant: 'error',
      });
    } finally {
      const endLoadings = handleLoadings(item._id, [...loadings], 'end');
      setLoadings([...endLoadings]);
    }
  };
  return (

    <div className={styles.container}>
      <InputButtonMain handleClick={handleClickRestore} wait={item._id} loadings={loadings} size="xs" variant="outlinedSuccess">
        Kembalikan
      </InputButtonMain>
    </div>
  );
};

RestoreButton.propTypes = {
  item: PropTypes.object.isRequired,
  unarchiveDataFunc: PropTypes.func.isRequired,
  initiateListDataApi: PropTypes.func.isRequired,
};
export default memo(RestoreButton);
