import React, { memo, useState } from 'react';
import PropTypes from 'prop-types';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import styles from './BubbleOnline.module.css';
import Color from '../../../../../../themes/colors';

const statusStyles = {
  online: styles.online,
  idle: styles.idle,
  offline: styles.offline,
};

const sizeBubbleStyles = {
  xs: styles.xs,
  sm: styles.sm,
  md: styles.md,
  lg: styles.lg,
  xl: styles.xl,
  xxl: styles.xxl,
  super: styles.super,
};

const BubbleOnline = ({ status, size }) => (
  <div className={`${styles.container} ${statusStyles[status]} ${sizeBubbleStyles[size]}`} />
);

BubbleOnline.propTypes = {
  status: PropTypes.oneOf(['online', 'idle', 'offline']),
  size: PropTypes.oneOf(['xs', 'sm', 'md', 'lg', 'xl', 'xxl']),
};

BubbleOnline.defaultProps = {
  status: 'offline',
  size: 'md',
};

export default memo(BubbleOnline);
