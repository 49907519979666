import React, { memo, useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import styles from './ItemUserReport.module.css';
import Color from '../../../../themes/colors';
import ListCardUserReport from './ListCardUserReport/ListCardUserReport';
import ColumnListCardUserReport from './ColumnListCardUserReport/ColumnListCardUserReport';
import { ReportConstants } from '../../../../constants';

const ItemUserReport = ({
  fromDate,
  untilDate,
}) => {
  const [itemType, setItemType] = useState(ReportConstants.typeRef.overdue);

  const onClickTab = useCallback((value) => {
    setItemType(value);
  }, []);

  return (
    <div className={styles.container}>
      <div className={styles.column}>
        <ColumnListCardUserReport
          itemId={itemType}
          handleSelectTab={onClickTab}
        />
      </div>
      <div id="listReportListCard" className={styles.list}>
        <ListCardUserReport
          fromDate={fromDate}
          untilDate={untilDate}
          itemType={itemType}
        />
      </div>
    </div>
  );
};

ItemUserReport.propTypes = {
  fromDate: PropTypes.string.isRequired,
  untilDate: PropTypes.string.isRequired,
};

ItemUserReport.defaultProps = {};

export default memo(ItemUserReport);
