const typeCallback = {
  NEW: 'new',
  DELETE: 'delete',
  EDIT: 'edit',
};

const typeModule = {
  chatMessage: 'chatMessage',
  chatAttachment: 'chatAttachment',
  groupChatMessage: 'groupChatMessage',
  groupChatAttachment: 'groupChatAttachment',
  card: 'card',
  cardComment: 'cardComment',
  cardDiscussion: 'cardDiscussion',
  post: 'post',
  postComment: 'postComment',
  postDiscussion: 'postDiscussion',
  doc: 'doc',
  docComment: 'docComment',
  docDiscussion: 'docDiscussion',
  questionComment: 'questionComment',
  questionDiscussion: 'questionDiscussion',
  file: 'file',
  fileComment: 'fileComment',
  fileDiscussion: 'fileDiscussion',
  event: 'event',
  eventComment: 'eventComment',
  eventDiscussion: 'eventDiscussion',
  occurrence: 'occurrence',
  occurrenceComment: 'occurrenceComment',
  occurrenceDiscussion: 'occurrenceDiscussion',
};

const titleModule = {
  chatMessage: 'Pesan',
  chatAttachment: 'Lampiran',
  groupChatMessage: 'Pesan',
  groupChatAttachment: 'Lampiran',
  card: 'Tugas',
  cardComment: 'Komentar',
  cardDiscussion: 'Diskusi',
  post: 'Pengumuman',
  postComment: 'Komentar',
  postDiscussion: 'Diskusi',
  doc: 'Dokumen',
  docComment: 'Komentar',
  docDiscussion: 'Diskusi',
  questionComment: 'Jawaban',
  questionDiscussion: 'Diskusi',
  file: 'File',
  fileComment: 'Komentar',
  fileDiscussion: 'Diskusi',
  event: 'Jadwal',
  eventComment: 'Komentar',
  eventDiscussion: 'Diskusi',
  occurrence: 'Jadwal',
  occurrenceComment: 'Komentar',
  occurrenceDiscussion: 'Diskusi',
};

const limitSeen = 10;

export {
  typeCallback,
  typeModule,
  limitSeen,
  titleModule,
};
