import React, {
  useEffect, useState, useContext, memo,
} from 'react';
import { useHistory } from 'react-router-dom';
import _ from 'lodash';
import { useSnackbar } from 'notistack';
import axios from 'axios';
import { InputTextFieldMain } from '../../components/UI_V2/Input/TextField';
import styles from './WelcomeCreateCompanyPage.module.css';
import Title from '../../components/Title/Title';
import LogoHeader from '../../components/UI/LogoHeader/LogoHeader';
import { DisplayTextBody, DisplayTextHeadline, DisplayTextSubHeadline } from '../../components/UI_V2/Display/Text';
import { InputButtonMain } from '../../components/UI_V2/Input/Button';
import Color from '../../themes/colors';
import { actionTypes } from '../../reducers/reducer';
import { GlobalContext } from '../../contexts/GlobalStateProvider';
import handleStatusMsg from '../../utilities/handleStatusMsg';
import handleLoadings from '../../utilities/handleLoadings';
import { CompanyActions } from '../../actions';
import { elementId } from '../../constants/ElementIdConstants';

const WelcomeCreateCompanyPage = () => {
  const [{
    currentCompany,
    currentCompanies,
    user,
  }, dispatch] = useContext(GlobalContext);
  const [newCompany, setNewCompany] = useState({
    name: '',
    desc: '',
  });
  const { enqueueSnackbar } = useSnackbar();
  const [postCompany, setPostCompany] = useState();
  const [loadings, setLoadings] = useState([]);
  const history = useHistory();

  const handleChanged = (event) => {
    const { name, value } = event.target;
    setNewCompany((prevValue) => ({
      ...prevValue,
      [name]: value,
    }));
  };

  useEffect(() => {
    const postNewCompany = async () => {
      if (postCompany !== undefined) {
        try {
          const startLoadings = handleLoadings('createCompany', [...loadings], 'start');
          setLoadings([...startLoadings]);

          const result = await CompanyActions.createCompanyAndUpdateCompanies({
            payload: {
              name: postCompany.name,
              desc: postCompany.desc,
            },
          }, dispatch);

          const companyId = result?.data?.newCompany?._id;

          CompanyActions.modifyShowWelcomeTutorialAndTrialMessage({
            userId: user?._id,
            companyId,
          });

          const endLoadings = handleLoadings('createCompany', [...loadings], 'end');
          setLoadings([...endLoadings]);

          CompanyActions.saveCompanyAndLastOpenedCompany(
            { currentCompany: result?.data?.newCompany, userId: user?._id }, dispatch,
          );

          const status = handleStatusMsg(result, 'success');

          enqueueSnackbar(status.message, {
            variant: 'success',
          });

          history.push('/success');
        } catch (err) {
          const status = handleStatusMsg(err, 'error');

          enqueueSnackbar(status.message, {
            variant: 'error',
          });
        }
      }
    };
    postNewCompany();
  }, [postCompany]);

  const handleClicked = () => {
    setPostCompany(newCompany);
  };

  const logoClickUrl = currentCompany?._id && `/companies/${currentCompany?._id}`;
  return (
    <>
      <Title title="Buat Perusahaanmu" />
      <LogoHeader url={logoClickUrl} showLogout={!logoClickUrl && true} />
      <div className={styles.container}>
        <div className={styles.title}>
          <DisplayTextHeadline
            color={Color.blueNavyCicle}
            mode="48"
            decoration="bold"
            position="center"
          >
            Yuk Buat Perusahaanmu!
          </DisplayTextHeadline>
        </div>
        <div className={styles.text}>
          <DisplayTextSubHeadline
            color={Color.gray3}
            mode="18"
            position="center"
          >
            Di dalam Perusahaan kamu bisa mengundang karyawan, mengelompokkan Tim dan Project,
            serta mengelola tugas-tugas karyawan, ya Leader!.
          </DisplayTextSubHeadline>
        </div>
        <div className={styles.formSection}>
          <div className={styles.form}>
            <div className={styles.formText}>
              <DisplayTextBody
                decoration="bold"
              >
                Nama Perusahaan
              </DisplayTextBody>
            </div>
            <div className={styles.formArea}>
              <InputTextFieldMain
                placeholder="PT. Makmur Jaya Indo"
                name="name"
                autoFocus
                onChange={(event) => handleChanged(event)}
                value={newCompany.name}
                id={elementId.createCompany_name_text_field}
              />
            </div>
          </div>
          <div className={styles.form}>
            <div className={styles.formText}>
              <DisplayTextBody
                decoration="bold"
              >
                Deskripsi Perusahaan
              </DisplayTextBody>
            </div>
            <div className={styles.formArea}>
              <InputTextFieldMain
                placeholder="Kami adalah perusahaan konsultan marketing terbaik di Indonesia!"
                name="desc"
                multiline
                minRows={4}
                maxRows={4}
                onChange={(event) => handleChanged(event)}
                value={newCompany.desc}
                id={elementId.createCompany_desc_text_field}
              />
            </div>
          </div>
          <div className={styles.button}>
            <InputButtonMain
              handleClick={handleClicked}
              border="rounded"
              wide="wider"
              loadings={loadings}
              wait="createCompany"
              id={elementId.createCompany_create_button}
            >
              Buat Perusahaan
            </InputButtonMain>
          </div>
        </div>
      </div>
    </>
  );
};

export default memo(WelcomeCreateCompanyPage);
