import React, { memo, useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import styles from './CardSnapshotRecapSection.module.css';
import Color from '../../../../themes/colors';
import ColumnListCardSnapshot from './ColumnListCardSnapshot/ColumnListCardSnapshot';
import ListCardSnapshot from './ListCardSnapshot/ListCardSnapshot';
import SelectWeekCardSnapshot from './SelectWeekCardSnapshot/SelectWeekCardSnapshot';
import { ReportConstants } from '../../../../constants';

const CardSnapshotRecapSection = ({
  listReport,
  selectedWeek,
  handleSetSelectedWeek,
  year,
}) => {
  const [itemType, setItemType] = useState(ReportConstants.typeRef.overdue);
  const onSelectItemType = useCallback((value) => {
    setItemType(value);
  }, []);
  return (
    <div className={styles.container}>
      <div className={styles.selectWeek}>
        <SelectWeekCardSnapshot
          listReport={listReport}
          handleSetSelectedWeek={handleSetSelectedWeek}
          selectedWeek={selectedWeek}
        />
      </div>
      <div className={styles.column}>
        <ColumnListCardSnapshot
          handleSelectTab={onSelectItemType}
          itemId={itemType}
        />
      </div>
      <div id="listReportListCardSnapshot" className={styles.list}>
        <ListCardSnapshot
          week={selectedWeek}
          year={year}
          itemType={itemType}
        />
      </div>
    </div>
  );
};

CardSnapshotRecapSection.propTypes = {
  listReport: PropTypes.array,
  selectedWeek: PropTypes.string,
  handleSetSelectedWeek: PropTypes.func,
  year: PropTypes.string,
};

CardSnapshotRecapSection.defaultProps = {
  listReport: [],
  selectedWeek: null,
  handleSetSelectedWeek: () => null,
  year: null,
};

export default memo(CardSnapshotRecapSection);
