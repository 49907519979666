import React, { memo, useState } from 'react';
import PropTypes from 'prop-types';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import styles from './TitleSeen.module.css';
import Color from '../../../../themes/colors';
import { DisplayTextSubHeadline } from '../../../UI_V2/Display/Text';
import { SeenConstants } from '../../../../constants';

const { titleModule } = SeenConstants;

const TitleSeen = ({ type }) => (
  <div className={styles.container}>
    <DisplayTextSubHeadline>
      {`Orang yang udah membaca ${titleModule[type]} ini`}
    </DisplayTextSubHeadline>
  </div>
);

TitleSeen.propTypes = {
  type: PropTypes.string.isRequired,
};

TitleSeen.defaultProps = {};

export default memo(TitleSeen);
