const featureTypeList = [
  {
    _id: 'cards',
    name: 'Tugas',
  },
  {
    _id: 'myTasks',
    name: 'Semua Tugas',
  },
  {
    _id: 'reports',
    name: 'Laporan Kinerja',
  },
  {
    _id: 'posts',
    name: 'Pengumuman',
  },
  {
    _id: 'docs',
    name: 'Dokumen',
  },
  {
    _id: 'events',
    name: 'Jadwal',
  },
  {
    _id: 'files',
    name: 'File',
  },
  {
    _id: 'buckets',
    name: 'Folder',
  },
  {
    _id: 'questions',
    name: 'Pertanyaan',
  },
  {
    _id: 'comments',
    name: 'Komentar',
  },
  {
    _id: 'chats',
    name: 'Chat Pribadi',
  },
  {
    _id: 'groupChats',
    name: 'Chat Grup',
  },
  {
    _id: 'teams',
    name: 'Tim',
  },
  {
    _id: 'companyModuleSearch',
    name: 'Menu Perusahaan',
  },
];

const subCategoryType = {
  OVERDUE: 'overdue',
  ONGOING: 'ongoing',
  BACKLOG: 'backlog',
  COMPLETED: 'completed',
  ALL: 'all',
};

const subCategoryTitle = {
  OVERDUE: 'Terlambat',
  ONGOING: 'Dikerjakan',
  BACKLOG: 'Belum',
  COMPLETED: 'Selesai',
  ALL: 'Semua',
};

export {
  featureTypeList,
  subCategoryTitle,
  subCategoryType,
};
