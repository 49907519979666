import React, { memo, useState } from 'react';
import PropTypes from 'prop-types';
import DownloadIcon from '@mui/icons-material/Download';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { useMediaQuery } from '@mui/material';
import styles from './SortModal.module.css';
import Color from '../../../themes/colors';
import { InputButtonMain } from '../../UI_V2/Input/Button';
import { FeedbackDialogGeneral } from '../../UI_V2/Feedback/Dialog';
import { DisplayTextHeadline } from '../../UI_V2/Display/Text';
import RadioSortType from './RadioSortType/RadioSortType';

const SortModal = ({
  open,
  onClose,
  onSelectSortType,
  initialSortType,
  title,
}) => {
  const handleChanged = (event) => {
    const { value } = event.target;

    onSelectSortType(value);
    onClose();
  };

  return (
    <FeedbackDialogGeneral
      open={open}
      onClose={onClose}
      maxWidth="xs"
    //   fullWidth={false}
    >
      <div className={styles.container}>
        <div className={styles.title}>
          <DisplayTextHeadline
            color={Color.blueNavyCicle}
            decoration="bold"
          >
            {`Urutkan ${title}`}
          </DisplayTextHeadline>
        </div>
        <div className={styles.formArea}>
          <div className={styles.radio}>
            <RadioSortType
              name="type"
              value="alphabetAsc"
              checked={initialSortType === 'alphabetAsc'}
              onChange={(event) => handleChanged(event)}
            >
              Secara alfabet dari A-z
            </RadioSortType>
          </div>
          <div className={styles.radio}>
            <RadioSortType
              name="type"
              value="alphabetDesc"
              checked={initialSortType === 'alphabetDesc'}
              onChange={(event) => handleChanged(event)}
            >
              Secara alfabet dari Z-a
            </RadioSortType>
          </div>
          <div className={styles.radio}>
            <RadioSortType
              name="type"
              value="createdAsc"
              checked={initialSortType === 'createdAsc'}
              onChange={(event) => handleChanged(event)}
            >
              Dari yang paling baru dibuat
            </RadioSortType>
          </div>
          <div className={styles.radio}>
            <RadioSortType
              name="type"
              value="createdDesc"
              checked={initialSortType === 'createdDesc'}
              onChange={(event) => handleChanged(event)}
            >
              Dari yang paling lama dibuat
            </RadioSortType>
          </div>
          <div className={styles.radio}>
            <RadioSortType
              name="type"
              value="updatedAsc"
              checked={initialSortType === 'updatedAsc'}
              onChange={(event) => handleChanged(event)}
            >
              Dari yang paling baru diupdate
            </RadioSortType>
          </div>
          <div className={styles.radio}>
            <RadioSortType
              name="type"
              value="updatedDesc"
              checked={initialSortType === 'updatedDesc'}
              onChange={(event) => handleChanged(event)}
            >
              Dari yang paling lama diupdate
            </RadioSortType>
          </div>
        </div>
      </div>
    </FeedbackDialogGeneral>
  );
};

SortModal.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
  onSelectSortType: PropTypes.func,
  initialSortType: PropTypes.oneOf(['alphabetAsc', 'alphabetDesc', 'createdAsc', 'createdDesc', 'updatedAsc', 'updatedDesc']),
  title: PropTypes.string,
};

SortModal.defaultProps = {
  open: false,
  onClose: () => null,
  onSelectSortType: () => null,
  initialSortType: 'alphabestAsc',
  title: 'Time',
};

export default memo(SortModal);
