import React, { useState, useEffect, memo } from 'react';
import PropTypes from 'prop-types';
import { useMediaQuery } from '@mui/material';
import Color from '../../../../../themes/colors';
import { DisplayTextBody, DisplayTextSubBody, DisplayTextSubHeadline } from '../../../Display/Text';
import styles from './Main.module.css';
import { FeedbackSpinnerGeneral } from '../../../Feedback/Spinner';

const defaultStyles = {
  container: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '0px 1rem',
    cursor: 'pointer',
    width: 'auto',
    height: '33px',
  },
  textIcon: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
};

const variantStyles = {
  default: {
    backgroundColor: Color.yellowAccentCicle,
  },
  success: {
    backgroundColor: Color.darkerTosca,
  },
  danger: {
    backgroundColor: Color.deleteColor,
  },
  grayLight: {
    backgroundColor: Color.gray3,
  },
  light: {
    backgroundColor: 'transparent',
  },
  blueLight: {
    backgroundColor: Color.lighterBlue2,
  },
  greenLight: {
    backgroundColor: Color.greenLight,
  },
  blueAquaMarine: {
    backgroundColor: Color.blueAquaMarine,
  },
  outlinedDefault: {
    border: `1px solid ${Color.yellowAccentCicle}`,
    backgroundColor: 'transparent',
  },
  outlinedSuccess: {
    border: `1px solid ${Color.greenCicle}`,
    backgroundColor: 'transparent',
  },
  outlinedDanger: {
    border: `1px solid ${Color.red}`,
    backgroundColor: 'transparent',
  },
};

const fontColorStyles = {
  default: Color.white,
  success: Color.white,
  danger: Color.white,
  blueAquaMarine: Color.white,
  light: Color.gray4,
  grayLight: Color.white,
  greenLight: Color.white,
  blueLight: Color.blueNavy3,
  outlinedDefault: Color.yellowAccentCicle,
  outlinedSuccess: Color.greenCicle,
  outlinedDanger: Color.red,
};

const iconStyles = {
  default: styles.iconDefault,
  success: styles.iconSuccess,
  danger: styles.iconDanger,
  blueAquaMarine: styles.iconBlueAquamarine,
  light: styles.iconLight,
  grayLight: styles.iconGrayLight,
  greenLight: styles.iconGreenLight,
  blueLight: styles.iconBlueLight,
  outlinedDefault: styles.iconOutlinedDefault,
  outlinedSuccess: styles.iconOutlinedSuccess,
  outlinedDanger: styles.iconOutlinedDanger,
};

const spinnerColorStyles = {
  default: Color.white,
  success: Color.white,
  danger: Color.white,
  blueAquaMarine: Color.white,
  grayLight: Color.white,
  light: Color.yellowAccentCicle,
  blueLight: Color.yellowAccentCicle,
  greenLight: Color.white,
  outlinedDefault: Color.yellowAccentCicle,
  outlinedSuccess: Color.yellowAccentCicle,
  outlinedDanger: Color.yellowAccentCicle,
};

const borderStyles = {
  default: {
    borderRadius: '5px',
  },
  semiRounded: {
    borderRadius: '10px',
  },
  rounded: {
    borderRadius: '15px',
  },
};

const sizeStyles = {
  xs: {
    textMode: '9',
    spinnerSize: 17,
    styles: {
      height: '20px',
      padding: '0px 0.3rem',
    },
  },
  sm: {
    textMode: '12',
    spinnerSize: 20,
    styles: {
      height: '25px',
      padding: '0px 0.5rem',
    },
  },
  md: {
    textMode: '16',
    spinnerSize: 24,
    styles: {
      height: '33px',
    },
  },
  lg: {
    textMode: '18',
    spinnerSize: 26,
    styles: {
      height: '40px',
    },
  },
};

const iconSizeStyles = {
  xs: styles.iconXs,
  sm: styles.iconSm,
  md: styles.iconMd,
  lg: styles.iconLg,
};

const wideStyles = {
  default: {
    // maxWidth: '133px',
  },
  block: {
    width: '100%',
  },
  wider: {
    // maxWidth: '180px',
    paddingRight: '4rem',
    paddingLeft: '4rem',
  },
  lesserWider: {
    // maxWidth: '180px',
    paddingRight: '2.3rem',
    paddingLeft: '2.3rem',
  },
  leastWider: {
    // maxWidth: '180px',
    paddingRight: '1.2rem',
    paddingLeft: '1.2rem',
  },
};

const iconPositionStyles = {
  start: styles.iconPositionStart,
  end: styles.iconPositionEnd,
  center: styles.iconPositionCenter,
};

const childrenPositionStyles = {
  center: {
    justifyContent: 'center',
  },
  start: {
    justifyContent: 'flex-start',
  },
  end: {
    justifyContent: 'flex-end',
  },
};

const disabledStyles = {
  true: {
    cursor: 'default',
    opacity: '0.7',
  },
  false: {},
};

const disableHoverStyles = {
  true: {
    cursor: 'default',
    opacity: '1',
  },
  false: {},
};

const InputButtonMain = ({
  variant,
  size,
  wait,
  loadings,
  anchorId,
  handleClick,
  wide,
  border,
  iconPosition,
  childrenPosition,
  icon,
  children,
  disableHover,
  type,
  id,
}) => {
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (loadings === undefined) return;
    const checkWait = loadings.filter((loading) => loading === wait);
    if (anchorId === undefined) {
      if (checkWait.length > 0) {
        setIsLoading(true);
        return;
      }

      setIsLoading(false);
    }

    // anchorId only used to prevent loading unknownly activated
    if (checkWait.length > 0 && wait === anchorId) {
      setIsLoading(true);
      return;
    }

    if (isLoading && checkWait.length > 0) {
      setIsLoading(true);
      return;
    }

    setIsLoading(false);
  }, [loadings]);

  const isUsingTextSubHeadline = size === 'md' || size === 'lg';
  const isUsingTextBody = size === 'sm';
  const isUsingTextSubBody = size === 'xs';

  const isTablet = useMediaQuery('(max-width:1024px)');
  const isMobile = useMediaQuery('(max-width:720px)');

  const tabletWideWiderStyles = isTablet && wide === 'wider' && wideStyles.lesserWider;
  const mobileWideWiderStyles = isMobile && wide === 'wider' && wideStyles.leastWider;

  return (
    <button
      id={id || 'inputButtonMain'}
      // eslint-disable-next-line react/button-has-type
      type={type}
      disabled={isLoading}
      className={styles.container}
      onClick={handleClick}
      style={
        {
          ...defaultStyles.container,
          ...variantStyles[variant],
          ...sizeStyles[size].styles,
          ...borderStyles[border],
          ...wideStyles[wide],
          ...childrenPositionStyles[childrenPosition],
          ...disabledStyles[`${isLoading}`],
          ...disableHoverStyles[disableHover],
          ...tabletWideWiderStyles,
          ...mobileWideWiderStyles,
        }
      }
    >
      <div className={`${styles.iconText} ${iconStyles[variant]} ${iconPositionStyles[iconPosition]} ${iconSizeStyles[size]}`}>
        {icon}
        {isUsingTextSubHeadline && (
        <DisplayTextSubHeadline
          mode={sizeStyles[size].textMode}
          decoration="bold"
          color={fontColorStyles[variant]}
        >
          {children}
        </DisplayTextSubHeadline>
        )}
        {isUsingTextBody && (
        <DisplayTextBody
          mode={sizeStyles[size].textMode}
          decoration="bold"
          color={fontColorStyles[variant]}
        >
          {children}
        </DisplayTextBody>
        )}
        {isUsingTextSubBody && (
        <DisplayTextSubBody
          mode={sizeStyles[size].textMode}
          decoration="bold"
          color={fontColorStyles[variant]}
        >
          {children}
        </DisplayTextSubBody>
        )}
      </div>
      {isLoading
        ? (
          <div className={styles.spinner}>
            <FeedbackSpinnerGeneral
              size={sizeStyles[size].spinnerSize}
              color={spinnerColorStyles[variant]}
            />
          </div>
        )
        : null}
    </button>
  );
};

InputButtonMain.propTypes = {
  variant: PropTypes.oneOf(['default', 'success', 'danger', 'light', 'blueLight', 'greenLight', 'blueAquaMarine', 'outlinedDefault', 'outlinedSuccess', 'outlinedDanger']),
  size: PropTypes.string,
  wait: PropTypes.string,
  loadings: PropTypes.array,
  anchorId: PropTypes.string,
  handleClick: PropTypes.func,
  wide: PropTypes.oneOf(['default', 'block', 'wider']),
  border: PropTypes.oneOf(['default', 'semiRounded', 'rounded']),
  childrenPosition: PropTypes.oneOf(['center', 'start', 'end']),
  iconPosition: PropTypes.oneOf(['start', 'end']),
  icon: PropTypes.node,
  children: PropTypes.node,
  disableHover: PropTypes.bool,
  type: PropTypes.oneOf(['button', 'submit']),
  id: PropTypes.string,
};

InputButtonMain.defaultProps = {
  wait: '',
  loadings: [],
  anchorId: undefined,
  handleClick: () => null,
  variant: 'default',
  size: 'md',
  wide: 'default',
  border: 'default',
  childrenPosition: 'center',
  icon: <></>,
  children: <></>,
  iconPosition: 'start',
  disableHover: false,
  type: 'button',
  id: undefined,
};

export default memo(InputButtonMain);
