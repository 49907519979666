import React, { memo } from 'react';
import PropTypes from 'prop-types';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Box from '@mui/material/Box';
import { InputButtonMain } from '../../Input/Button';
import Color from '../../../../themes/colors';
import { DisplayTextSubHeadline } from '../../Display/Text';

const FeedbackDialogAlert = ({
  open,
  handleClose,
  title,
  content,
  labelYes,
  labelNo,
  handleConfirmYes,
  handleConfirmNo,
  waitYes,
  waitNo,
  loadings,
  variantButtonYes,
  variantButtonNo,
}) => (
  <Dialog
    open={open}
    onClose={handleClose}
    aria-labelledby="alert-dialog-title"
    aria-describedby="alert-dialog-description"
  >
    <Box
      sx={{
        padding: '1rem',
      }}
    >
      <DialogTitle>
        <Box sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
        >
          <DisplayTextSubHeadline mode="22" decoration="bold">
            {title}
          </DisplayTextSubHeadline>
        </Box>
      </DialogTitle>
      <DialogContent>
        <Box sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
        >
          <DisplayTextSubHeadline mode="18" color={Color.gray4} position="center">
            {content}
          </DisplayTextSubHeadline>
        </Box>
      </DialogContent>
      <DialogActions>
        <Box sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          width: '100%',
        }}
        >
          <InputButtonMain
            handleClick={handleConfirmNo}
            border="semiRounded"
            variant={variantButtonNo}
            wide="wider"
            wait={waitNo}
            loadings={loadings}
          >
            {labelNo}
          </InputButtonMain>
        </Box>
        <Box sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          width: '100%',
        }}
        >
          <InputButtonMain
            wait={waitYes}
            loadings={loadings}
            handleClick={handleConfirmYes}
            border="semiRounded"
            variant={variantButtonYes}
            wide="wider"
          >
            {labelYes}
          </InputButtonMain>
        </Box>
      </DialogActions>
    </Box>
  </Dialog>
);

FeedbackDialogAlert.propTypes = {
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
  content: PropTypes.string.isRequired,
  labelYes: PropTypes.string.isRequired,
  labelNo: PropTypes.string.isRequired,
  handleConfirmYes: PropTypes.func.isRequired,
  handleConfirmNo: PropTypes.func.isRequired,
  waitYes: PropTypes.string,
  waitNo: PropTypes.string,
  loadings: PropTypes.array,
  variantButtonYes: PropTypes.oneOf(['default', 'success', 'danger', 'light', 'blueLight', 'blueAquaMarine', 'outlinedDefault', 'outlinedSuccess', 'outlinedDanger']),
  variantButtonNo: PropTypes.oneOf(['default', 'success', 'danger', 'light', 'blueLight', 'blueAquaMarine', 'outlinedDefault', 'outlinedSuccess', 'outlinedDanger']),
};

FeedbackDialogAlert.defaultProps = {
  waitYes: null,
  waitNo: null,
  variantButtonYes: 'blueAquaMarine',
  variantButtonNo: 'light',
  loadings: [],
};

export default memo(FeedbackDialogAlert);
