/* eslint-disable consistent-return */
import { useCallback } from 'react';
import { CheerActions, PostActions } from '../actions';
import { CheerConstants, PostConstants } from '../constants';
import { connectSocketBlast, connectSocketPost } from '../services/socket';
import { useSocketHooks } from './SocketHooks';

function usePostSocket({ blastId, postId, userId }, dispatch) {
  const callbackPost = useCallback((post) => {
    if (post?._id === postId) {
      PostActions.incomingPost({ post }, dispatch);
    }
  }, [postId]);

  const listenSocket = (socket, params) => {
    socket
      .on(`post-update-${params?.blastId}`, callbackPost)
      .on(`post-archive-${params?.blastId}`, callbackPost);
  };
  const removeListener = (socket, params) => {
    socket
      .off(`post-update-${params?.blastId}`)
      .off(`post-archive-${params?.blastId}`);
    socket.disconnect();
  };

  useSocketHooks({
    params: { blastId, userId },
    connectSocket: connectSocketBlast,
    listenSocket,
    removeListener,
  });
}

function usePostCheerNCommentSocket({ postId, userId }, dispatch) {
  // callback cheer main post
  const callbackNewCheer = useCallback((cheer) => {
    CheerActions.incomingCheerFromPrimaryParent({
      cheer,
      typeAction: CheerConstants.typeCallback.NEW,
      primaryParentAction: ({ updateStateParent }) => PostActions.dispatchUpdateCurrentBlastPost(
        { updateCurrentBlastPost: updateStateParent },
        dispatch,
      ),
      keyProperty: 'cheers',
    });
  }, []);
  const callbackDeleteCheer = useCallback((cheer) => {
    CheerActions.incomingCheerFromPrimaryParent({
      cheer,
      typeAction: CheerConstants.typeCallback.DELETE,
      primaryParentAction: ({ updateStateParent }) => PostActions.dispatchUpdateCurrentBlastPost(
        { updateCurrentBlastPost: updateStateParent },
        dispatch,
      ),
      keyProperty: 'cheers',
    });
  }, []);

  // callback cheer comment post
  const callbackNewCheerComment = useCallback((cheer) => {
    CheerActions.incomingCheerFromSecondaryParent({
      cheer,
      typeAction: CheerConstants.typeCallback.NEW,
      secondaryParentAction: PostActions.incomingPostComment,
      typeActionEditSecondaryParent: PostConstants.typeCallback.EDIT,
    }, dispatch);
  }, []);
  const callbackDeleteCheerComment = useCallback((cheer) => {
    CheerActions.incomingCheerFromSecondaryParent({
      cheer,
      typeAction: CheerConstants.typeCallback.DELETE,
      secondaryParentAction: PostActions.incomingPostComment,
      typeActionEditSecondaryParent: PostConstants.typeCallback.EDIT,
    }, dispatch);
  }, []);

  // callback comment post
  const callbackNewComment = useCallback((comment) => {
    PostActions.incomingPostComment({
      comment,
      typeAction: PostConstants.typeCallback.NEW,
    }, dispatch);
  }, []);
  const callbackUpdateComment = useCallback((comment) => {
    PostActions.incomingPostComment({
      comment,
      typeAction: PostConstants.typeCallback.EDIT,
    }, dispatch);
  }, []);
  const callbackDeleteComment = useCallback((comment) => {
    PostActions.incomingPostComment({
      comment,
      typeAction: PostConstants.typeCallback.DELETE,
    }, dispatch);
  }, []);

  const listenSocket = (socket, params) => {
    socket
      .on('cheer-new', callbackNewCheer)
      .on('cheer-delete', callbackDeleteCheer)
      .on(`postsComment-new-${params?.postId}`, callbackNewComment)
      .on(`postsComment-update-${params?.postId}`, callbackUpdateComment)
      .on(`postsComment-delete-${params?.postId}`, callbackDeleteComment)
      .on('cheerComment-new', callbackNewCheerComment)
      .on('cheerComment-delete', callbackDeleteCheerComment);
  };
  const removeListener = (socket, params) => {
    socket
      .off('cheer-new')
      .off('cheer-delete')
      .off(`postsComment-new-${params?.postId}`)
      .off(`postsComment-update-${params?.postId}`)
      .off(`postsComment-delete-${params?.postId}`)
      .off('cheerComment-new')
      .off('cheerComment-delete');
    socket.disconnect();
  };

  useSocketHooks({
    params: { postId, userId },
    connectSocket: connectSocketPost,
    listenSocket,
    removeListener,
  });
}

export {
  usePostSocket,
  usePostCheerNCommentSocket,
};
