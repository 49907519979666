import React, { useEffect, memo, useContext } from 'react';
import { useHistory } from 'react-router-dom';
import _ from 'lodash';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { GlobalContext } from '../../contexts/GlobalStateProvider';
import styles from './SuccessRedirectionPage.module.css';
import Title from '../../components/Title/Title';
import LogoHeader from '../../components/UI/LogoHeader/LogoHeader';
import logoSquareImage from '../../assets/Logo/logo-square.png';
import { DisplayTextHeadline, DisplayTextSubHeadline } from '../../components/UI_V2/Display/Text';
import Color from '../../themes/colors';

const SuccessRedirectionPage = () => {
  const [{
    currentCompany,
  }, dispatch] = useContext(GlobalContext);
  const history = useHistory();

  useEffect(() => {
    setTimeout(() => {
      if (currentCompany?._id) {
        history.push(`/companies/${currentCompany._id}`);
      } else {
        history.push('/');
      }
    }, 2000);
  }, []);

  return (
    <>
      <Title title="Berhasil!" />
      <LogoHeader />
      <div className={styles.container}>
        <div className={styles.imageSection}>
          <LazyLoadImage alt="" src={logoSquareImage} />
        </div>
        <div className={styles.textSection}>
          <div className={styles.headline}>
            <DisplayTextHeadline
              color={Color.blueNavyCicle}
              mode="48"
              decoration="bold"
              position="center"
            >
              Berhasil!
            </DisplayTextHeadline>
          </div>
          <DisplayTextSubHeadline
            mode="18"
            color={Color.gray3}
            decoration="bold"
            position="center"
          >
            Sebentar lagi, Kamu bakalan di arahkan ke halaman selanjutnya ya...
          </DisplayTextSubHeadline>
        </div>
      </div>
    </>
  );
};

export default memo(SuccessRedirectionPage);
