import { cloneDeep, isArray, isNumber } from 'lodash';
import apiUtil, { getNextPage } from '../utilities/apiUtil';
import { actionTypes } from '../reducers/reducer';
import { ErrorException } from '../utilities/handleError';
import { ApiConstants, CouponConstants } from '../constants';
import { mergeNewObjectListAndRemoveDuplicate, mergeObjectListAndRemoveDuplicate, updateListProperty } from '../utilities/arrayUtil';
import { initialState } from '../contexts/GlobalStateProvider';
import { level, typeRef } from '../constants/RoleConstants';

/*
  Dispatcher
*/

function dispatchPreviousCoupons({ previousCoupons }, dispatch) {
  dispatch({
    type: actionTypes.SET_PREVIOUS_COUPONS,
    previousCoupons,
  });
}

function dispatchCurrentCoupons({ currentCoupons }, dispatch) {
  dispatch({
    type: actionTypes.SET_CURRENT_COUPONS,
    currentCoupons,
  });
}

function dispatchUpdateCoupons({ updateCoupons }, dispatch) {
  dispatch({
    type: actionTypes.UPDATE_CURRENT_COUPONS,
    updateCoupons,
  });
}

/*
  SetterDispatcher
*/

function setPreviousCoupons({ previousCoupons }, dispatch) {
  if (!previousCoupons) return;

  dispatchPreviousCoupons(
    { previousCoupons: cloneDeep(previousCoupons) }, dispatch,
  );
}

function setCurrentCoupons({ currentCoupons }, dispatch) {
  if (!currentCoupons) return;
  dispatchCurrentCoupons(
    { currentCoupons: cloneDeep(currentCoupons) }, dispatch,
  );
}

/*
  Method
*/

async function initiateCoupons({
  page = 1, limit = CouponConstants.limitCoupons,
  companyId,
}, dispatch) {
  try {
    const result = await apiUtil.get(ApiConstants.URL_V1.COUPONS(), {
      params: {
        page,
        limit,
        companyId,
      },
    });

    setCurrentCoupons({ currentCoupons: result?.data }, dispatch);
    setPreviousCoupons({
      previousCoupons: initialState.previousCoupons,
    }, dispatch);

    return result;
  } catch (error) {
    throw new ErrorException(error);
  }
}

async function loadMoreCoupons({
  currentCoupons,
  limit = CouponConstants.limitCoupons,
  companyId,
}, dispatch) {
  try {
    const page = getNextPage({
      data: currentCoupons.data,
      limitPerPage: limit,
    });

    const result = await apiUtil.get(ApiConstants.URL_V1.COUPONS(), {
      params: {
        page,
        limit,
        companyId,
      },
    });

    const mergedDataCoupons = mergeObjectListAndRemoveDuplicate({
      currentObjectList: currentCoupons,
      nextObjectList: result?.data,
      keyObject: 'data',
    });

    const mergedCoupons = {
      ...result?.data,
      data: mergedDataCoupons?.data,
    };

    setPreviousCoupons({ previousCoupons: result?.data }, dispatch);
    setCurrentCoupons({ currentCoupons: mergedCoupons }, dispatch);

    return result;
  } catch (error) {
    throw new ErrorException(error);
  }
}

export {
  initiateCoupons,
  loadMoreCoupons,
  setPreviousCoupons,
  setCurrentCoupons,
};
