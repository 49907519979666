import React, { memo, useState } from 'react';
import PropTypes from 'prop-types';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import DiscountIcon from '@mui/icons-material/Discount';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import CodeIcon from '@mui/icons-material/Code';
import ReceiptIcon from '@mui/icons-material/Receipt';
import DataUsageIcon from '@mui/icons-material/DataUsage';
import styles from './CouponItem.module.css';
import { DisplayTextBody, DisplayTextSubHeadline } from '../../../../../components/UI_V2/Display/Text';
import Color from '../../../../../themes/colors';
import { InputButtonMain } from '../../../../../components/UI_V2/Input/Button';
import { getSingleOrPlural, limitChar } from '../../../../../utilities/stringUtil';
import { formatToBasicThousandSeparator } from '../../../../../utilities/numberUtil';

const CouponItem = ({
  coupon,
  handleClickApplyCoupon,
  loadings,
}) => {
  const discountValueText = formatToBasicThousandSeparator(coupon.discountValue);
  const isTypePercentage = coupon.discountType === 'percentage';

  const isNoMinOrder = coupon.minOrderValue < 1;
  const minOrderValueText = formatToBasicThousandSeparator(coupon.minOrderValue);

  const isUnlimitedUsage = !coupon.maxUsageSubscription;
  const isCanOnlyUseOnce = !coupon.isCanBeUsedOnSameSubscription;
  let quotaUseLeft = coupon.maxUsageSubscription - coupon.usageSubscriptionCount;
  if (isCanOnlyUseOnce) quotaUseLeft = 1;
  // const uses = getSingleOrPlural('use', quotaUseLeft);
  const uses = 'pemakaian';

  return (
    <div className={styles.container}>
      <div className={styles.content}>
        <div className={styles.title}>
          <DisplayTextSubHeadline
            decoration="bold"
            mode="20"
            color={Color.tosca}
          >
            Diskon
            {' '}
            {!isTypePercentage && 'Rp. '}
            {`${discountValueText}`}
            {isTypePercentage && '%'}
          </DisplayTextSubHeadline>
        </div>
        <div className={styles.desc}>
          <DisplayTextBody>
            {limitChar(coupon.desc, 40)}
          </DisplayTextBody>
        </div>
      </div>
      <div className={styles.footer}>
        <div className={styles.info}>
          <div className={styles.icon}>
            <CodeIcon />
          </div>
          <div className={styles.text}>
            <DisplayTextBody
              color={Color.gray4}
            >
              {coupon.code}
            </DisplayTextBody>
          </div>
        </div>
        <div className={styles.info}>
          <div className={styles.icon}>
            <ReceiptIcon />
          </div>
          <div className={styles.text}>
            <DisplayTextBody
              color={Color.gray4}
            >
              {!isNoMinOrder && `Minimum pesanan : Rp. ${minOrderValueText}`}
              {isNoMinOrder && 'Ga ada minimum pesanan'}
            </DisplayTextBody>
          </div>
        </div>
      </div>
      <div className={styles.footer}>
        <div className={styles.info}>
          <div className={styles.icon}>
            <DataUsageIcon />
          </div>
          <div className={styles.text}>
            <DisplayTextBody
              color={Color.gray4}
            >
              {isUnlimitedUsage && 'Pemakaian tak terbatas'}
              {!isUnlimitedUsage && `${quotaUseLeft} ${uses} tersisa`}
            </DisplayTextBody>
          </div>
        </div>
        <div className={styles.info}>
          <div className={styles.icon}>
            <AccessTimeIcon />
          </div>
          <div className={styles.text}>
            <DisplayTextBody
              color={Color.gray4}
            >
              Ketersediaan terbatas
            </DisplayTextBody>
          </div>
        </div>
      </div>
      <div className={styles.button}>
        <InputButtonMain
          size="sm"
          variant="outlinedSuccess"
          handleClick={() => handleClickApplyCoupon(coupon.code)}
          loadings={loadings}
          wait={`coupon-add-${coupon.code}`}
        >
          Terapkan
        </InputButtonMain>
      </div>
    </div>
  );
};

CouponItem.propTypes = {
  coupon: PropTypes.object.isRequired,
  handleClickApplyCoupon: PropTypes.func.isRequired,
  loadings: PropTypes.array.isRequired,
};

export default memo(CouponItem);
