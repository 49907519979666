import React, { memo, useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import styles from './WidgetMyTaskCardAll.module.css';
import Color from '../../../../themes/colors';
import ListCardWidgetMyTaskCardAll from './ListCardWidgetMyTaskCardAll/ListCardWidgetMyTaskCardAll';
import ColumnListCardWidgetMyTaskCardAll from './ColumnListCardWidgetMyTaskCardAll/ColumnListCardWidgetMyTaskCardAll';
import { ReportConstants } from '../../../../constants';
import { DisplayTextSubHeadline } from '../../../../components/UI_V2/Display/Text';
import { DisplayDividerMain } from '../../../../components/UI_V2/Display/Divider';
import { getStoreWidgetMyTaskCardAll, setStoreWidgetMyTaskCardAll } from '../../../../utilities/localStorage';

const WidgetMyTaskCardAll = ({ userId, companyId }) => {
  const initialItemType = getStoreWidgetMyTaskCardAll(
    { companyId, userId },
  );
  const [itemType, setItemType] = useState(initialItemType
    ? initialItemType.itemType : ReportConstants.typeRef.overdue);

  const onClickTab = useCallback((value) => {
    setItemType(value);
    setStoreWidgetMyTaskCardAll({ userId, companyId, itemType: { itemType: value } });
  }, []);

  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <DisplayTextSubHeadline color={Color.blueNavyCicle} decoration="bold">
          Tugas Saya
        </DisplayTextSubHeadline>
      </div>
      <div className={styles.column}>
        <ColumnListCardWidgetMyTaskCardAll
          itemId={itemType}
          handleSelectTab={onClickTab}
        />
      </div>
      <DisplayDividerMain
        borderColor={Color.gray6}
        marginTop="0px"
        marginBottom="1rem"
      />
      <div id="listReportListCard" className={styles.list}>
        <ListCardWidgetMyTaskCardAll
          itemType={itemType}
        />
      </div>
    </div>
  );
};

WidgetMyTaskCardAll.propTypes = {
  userId: PropTypes.string.isRequired,
  companyId: PropTypes.string.isRequired,
};

WidgetMyTaskCardAll.defaultProps = {};

export default memo(WidgetMyTaskCardAll);
