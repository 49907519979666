import { ApiConstants } from '../constants';
import { METRIC_API_URL } from '../constants/ApiConstants';
import apiUtil from '../utilities/apiUtil';
import { ErrorException } from '../utilities/handleError';

const createMetricVisitorPublic = async ({
  planName,
}) => {
  try {
    const result = await apiUtil.post(ApiConstants.URL_V1.METRICS_PUBLIC_VISITORS(), {
      planName,
    }, {}, METRIC_API_URL);

    return result?.data;
  } catch (error) {
    throw new ErrorException(error);
  }
};

const createMetricVisitor = async ({
  companyId,
}) => {
  try {
    const result = await apiUtil.post(ApiConstants.URL_V1.METRICS_VISITORS(), {}, {
      params: {
        companyId,
      },
    }, METRIC_API_URL);

    return result?.data;
  } catch (error) {
    throw new ErrorException(error);
  }
};

export {
  createMetricVisitorPublic,
  createMetricVisitor,
};
