import React, {
  memo, useState, useEffect, useContext,
} from 'react';
import PropTypes from 'prop-types';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import styles from './MemberOnlineModal.module.css';
import Color from '../../../themes/colors';
import { FeedbackDialogGeneral } from '../../UI_V2/Feedback/Dialog';
import ListMemberOnline from './ListMemberOnline/ListMemberOnline';
import { DisplayTextSubHeadline } from '../../UI_V2/Display/Text';
import { useSearch } from '../../../hooks/HelperHooks';
import { InputSearchGeneral } from '../../UI_V2/Input/Search';
import { GlobalContext } from '../../../contexts/GlobalStateProvider';
import { sortMembersByStatusOnline } from '../../../actions/UserOnlineActions';
import { limitChar } from '../../../utilities/stringUtil';

const MemberOnlineModal = ({
  open,
  onClose,
  members,
  title,
}) => {
  const [{ allUserOnline }] = useContext(GlobalContext);
  const [sortedMembers, setSortedMembers] = useState([]);

  useEffect(() => {
    const sortedByStatusMembers = sortMembersByStatusOnline(members, allUserOnline);
    setSortedMembers(sortedByStatusMembers);
  }, [members, allUserOnline]);

  const {
    searchResult,
    keyword,
    keywordArray,
    setKeyword,
    isTyping,
    onChange,
    resetSearch,
  } = useSearch({ originalList: sortedMembers });

  return (
    <FeedbackDialogGeneral
      open={open}
      onClose={onClose}
      maxWidth="sm"
    >
      <div className={styles.container}>
        <div className={styles.title}>
          <DisplayTextSubHeadline>
            {`Anggota Aktif di ${limitChar(title, 20)}`}
          </DisplayTextSubHeadline>
        </div>
        <div className={styles.search}>
          <InputSearchGeneral
            onChange={onChange}
            value={keyword}
            placeholder="Cari anggota..."
            onClickCloseIcon={resetSearch}
            size="sm"
            autoFocus={false}
          />
        </div>
        <div className={styles.list}>
          <ListMemberOnline
            members={searchResult}
          />
        </div>
      </div>
    </FeedbackDialogGeneral>
  );
};

MemberOnlineModal.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  members: PropTypes.array,
  title: PropTypes.string,
};

MemberOnlineModal.defaultProps = {
  members: [],
  title: 'Chat Grup',
};

export default memo(MemberOnlineModal);
