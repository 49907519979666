import React, {
  memo, useCallback, useContext, useEffect, useState, forwardRef,
} from 'react';
import PropTypes from 'prop-types';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { useParams, useHistory, useLocation } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import { useInView } from 'react-intersection-observer';
import styles from './ListActivityByModule.module.css';
import Color from '../../../themes/colors';
import { ActivityConstants } from '../../../constants';
import { ActivityActions } from '../../../actions';
import { useInfinityScrollHooks } from '../../../hooks/InfinityScrollHooks';
import handleLoadings from '../../../utilities/handleLoadings';
import handleStatusMsg from '../../../utilities/handleStatusMsg';
import InfinityScroll from '../../../components/UI/InfinityScroll/InfinityScroll';
import { handleCompareDate } from '../../../utilities/dateUtil';
import ActivityItem from '../../ActivityContainer/ListActivity/ActivityItem/ActivityItem';
import FeedbackLoadingMain from '../../../components/UI_V2/Feedback/Loading/Main/Main';
import { GlobalContext } from '../../../contexts/GlobalStateProvider';
import { serviceTypeRef } from '../../../constants/ActivityConstants';
import { DisplayTextSubHeadline } from '../../../components/UI_V2/Display/Text';
import { InputButtonMain } from '../../../components/UI_V2/Input/Button';
import { FeedbackSpinnerGeneral } from '../../../components/UI_V2/Feedback/Spinner';
import DateSeparator from '../../CommentsSectionContainer/DateSeparator/DateSeparator';
import ListSkeletonActivityByModule from '../ListSkeletonActivityByModule/ListSkeletonActivityByModule';

const ListActivityByModule = ({
  variant,
}) => {
  const [{ currentCompany }, dispatch] = useContext(GlobalContext);
  /* Initiate list data */
  const initialStatePreviousActivities = {
    data: new Array(ActivityConstants.limitActivity),
  };
  const initialStateCurrentActivities = {
    data: [],
  };
  const [previousActivities, setPreviousActivities] = useState(initialStatePreviousActivities);
  const [currentActivities, setCurrentActivities] = useState(initialStateCurrentActivities);
  const [isFirstLoad, setIsFirstLoad] = useState(true);
  const [loadings, setLoadings] = useState([]);

  // const params = useParams();
  const history = useHistory();
  const location = useLocation();
  const params = useParams();
  const {
    companyId, teamId,
    chatId, cardId, commentId,
    groupChatId, postId, questionId,
    eventId, occurrenceId,
    docId, fileId,
    bucketId,
  } = params;

  const { enqueueSnackbar } = useSnackbar();

  const getMoreLists = async () => {
    let result;
    switch (variant) {
      case serviceTypeRef.card:
        result = await ActivityActions.loadMoreCardActivities({
          companyId,
          teamId,
          cardId,
          setCurrentActivities,
          setPreviousActivities,
          currentActivities,
        });
        break;
      case serviceTypeRef.post:
        result = await ActivityActions.loadMorePostActivities({
          companyId,
          teamId,
          postId,
          setCurrentActivities,
          setPreviousActivities,
          currentActivities,
        });
        break;
      case serviceTypeRef.question:
        result = await ActivityActions.loadMoreQuestionActivities({
          companyId,
          teamId,
          questionId,
          setCurrentActivities,
          setPreviousActivities,
          currentActivities,
        });
        break;
      case serviceTypeRef.doc:
        result = await ActivityActions.loadMoreDocActivities({
          companyId,
          teamId,
          docId,
          setCurrentActivities,
          setPreviousActivities,
          currentActivities,
        });
        break;
      case serviceTypeRef.file:
        result = await ActivityActions.loadMoreFileActivities({
          companyId,
          teamId,
          fileId,
          setCurrentActivities,
          setPreviousActivities,
          currentActivities,
        });
        break;
      case serviceTypeRef.event:
        result = await ActivityActions.loadMoreEventActivities({
          companyId,
          teamId,
          eventId,
          setCurrentActivities,
          setPreviousActivities,
          currentActivities,
        });
        break;
      case serviceTypeRef.recurringOccurrence:
        result = await ActivityActions.loadMoreOccurrenceActivities({
          companyId,
          teamId,
          eventId,
          occurrenceId,
          setCurrentActivities,
          setPreviousActivities,
          currentActivities,
        });
        break;

      default:
        break;
    }

    return result;
  };

  const resetPreviousLists = useCallback(() => {
    setPreviousActivities(initialStatePreviousActivities);
    setCurrentActivities(initialStateCurrentActivities);
  }, []);

  const {
    lists,
    handleLoadMoreLists,
    checkIfListNeedToLoad,
    setCheckLoadMore,
    isListsLengthAtTheLimitOrAbove,
  } = useInfinityScrollHooks({
    currentObjectWithKeyProperty: currentActivities,
    previousLists: previousActivities?.data,
    resetPreviousLists,
    getMoreLists: () => getMoreLists(),
    keyProperty: 'data',
    limitList: ActivityConstants.limitActivity,
  });

  const initiateActivitiesApi = async () => {
    try {
      const startLoadings = handleLoadings('initiateActivities', [...loadings], 'start');
      setLoadings([...startLoadings]);

      let result;

      switch (variant) {
        case serviceTypeRef.card:
          result = await ActivityActions.initiateCardActivities({
            companyId,
            teamId,
            cardId,
            setCurrentActivities,
            setPreviousActivities,
            initialStatePreviousActivities,
          });
          break;
        case serviceTypeRef.post:
          result = await ActivityActions.initiatePostActivities({
            companyId,
            teamId,
            postId,
            setCurrentActivities,
            setPreviousActivities,
            initialStatePreviousActivities,
          });
          break;
        case serviceTypeRef.question:
          result = await ActivityActions.initiateQuestionActivities({
            companyId,
            teamId,
            questionId,
            setCurrentActivities,
            setPreviousActivities,
            initialStatePreviousActivities,
          });
          break;
        case serviceTypeRef.doc:
          result = await ActivityActions.initiateDocActivities({
            companyId,
            teamId,
            docId,
            setCurrentActivities,
            setPreviousActivities,
            initialStatePreviousActivities,
          });
          break;
        case serviceTypeRef.file:
          result = await ActivityActions.initiateFileActivities({
            companyId,
            teamId,
            fileId,
            setCurrentActivities,
            setPreviousActivities,
            initialStatePreviousActivities,
          });
          break;
        case serviceTypeRef.event:
          result = await ActivityActions.initiateEventActivities({
            companyId,
            teamId,
            eventId,
            setCurrentActivities,
            setPreviousActivities,
            initialStatePreviousActivities,
          });
          break;
        case serviceTypeRef.recurringOccurrence:
          result = await ActivityActions.initiateOccurrenceActivities({
            companyId,
            teamId,
            eventId,
            occurrenceId,
            setCurrentActivities,
            setPreviousActivities,
            initialStatePreviousActivities,
          });
          break;

        default:
          break;
      }

      if (isListsLengthAtTheLimitOrAbove(result?.data?.data)) {
        setCheckLoadMore(true);
      } else {
        setCheckLoadMore(false);
      }

      if (isFirstLoad) {
        setIsFirstLoad(false);
      }
    } catch (err) {
      const status = handleStatusMsg(err, 'error');

      enqueueSnackbar(status.message, {
        variant: 'error',
      });
    } finally {
      const endLoadings = handleLoadings('initiateActivities', [...loadings], 'end');
      setLoadings([...endLoadings]);
    }
  };

  useEffect(() => {
    initiateActivitiesApi();
  }, []);

  return (
    <FeedbackLoadingMain
      loadingComponent={<ListSkeletonActivityByModule />}
      wait="initiateActivities"
      loadings={loadings}
    >
      <InfinityScroll
            // scrollableTarget="listActivities"
        dataLength={lists?.length || 0}
        hasMore={checkIfListNeedToLoad()}
        next={handleLoadMoreLists}
        emptyMessage="Belum ada aktifitas apapun disini..."
      >
        {lists.map((item, index) => {
          const isSameDate = handleCompareDate(lists, item, (index - 1), 'createdAt');

          if (!isSameDate) {
            return (
              <>
                <DateSeparator date={item.createdAt} />
                <div className={styles.item}>
                  <ActivityItem
                    item={item}
                    company={currentCompany}
                    disableClick
                  />
                </div>
              </>
            );
          }
          return (
            <div className={styles.item}>
              <ActivityItem
                item={item}
                company={currentCompany}
                disableClick
              />
            </div>
          );
        })}
      </InfinityScroll>
    </FeedbackLoadingMain>
  );
};

ListActivityByModule.propTypes = {
  variant: PropTypes.oneOf([
    serviceTypeRef.card,
    serviceTypeRef.post,
    serviceTypeRef.question,
    serviceTypeRef.event,
    serviceTypeRef.recurringOccurrence,
    serviceTypeRef.bucket,
    serviceTypeRef.doc,
    serviceTypeRef.file,
  ]),
};

ListActivityByModule.defaultProps = {
  variant: serviceTypeRef.card,
};

export default memo(ListActivityByModule);
