import React, {
  useState, useEffect, useContext,
} from 'react';
import CloseIcon from '@mui/icons-material/Close';
import { useParams } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import PropTypes from 'prop-types';
import styles from './InputFormDesc.module.css';
import { GlobalContext } from '../../../../contexts/GlobalStateProvider';
import EditorFull from '../../../FroalaEditor/EditorFull/EditorFull';
import extractElementBySelectorFromString from '../../../../utilities/extractElementBySelectorFromString';
import Button from '../../../../components/UI/Button/Button';
import handleStatusMsg from '../../../../utilities/handleStatusMsg';
import handleLoadings from '../../../../utilities/handleLoadings';
import apiUtil from '../../../../utilities/apiUtil';
import { CardActions } from '../../../../actions';

const InputFormDesc = ({ card, handleCancel }) => {
  const [{ currentTeam }, dispatch] = useContext(GlobalContext);
  const { enqueueSnackbar } = useSnackbar();
  const [postDesc, setPostDesc] = useState();
  const [loadings, setLoadings] = useState([]);
  const [editorModel, setEditorModel] = useState();
  const [trigger, setTrigger] = useState();

  const params = useParams();
  const { companyId } = params;
  const teamId = params.teamId || card.team?._id || card.team;

  useEffect(() => {
    if (postDesc === undefined) {
      return;
    }

    const postApiDesc = async () => {
      const cardId = params.cardId || card._id;
      let data;

      if (postDesc?.desc?.length === 0 || postDesc === undefined) {
        data = { $unset: { desc: undefined } };
      } else {
        data = { desc: postDesc.desc, mentionedUsers: postDesc.mentionedUsers };
      }

      try {
        const startLoadings = handleLoadings('inputDesc', [...loadings], 'start');
        setLoadings([...startLoadings]);

        const result = await CardActions.updateCard({
          teamId, cardId, body: data, companyId,
        });

        const status = handleStatusMsg(result, 'success');

        enqueueSnackbar(status.message, {
          variant: 'success',
        });

        // close this container after finished if only its succeeded so the
        // text wont dissapeared
        handleCancel();
      } catch (err) {
        const status = handleStatusMsg(err, 'error');

        enqueueSnackbar(status.message, {
          variant: 'error',
        });
      } finally {
        const endLoadings = handleLoadings('inputDesc', [...loadings], 'end');
        setLoadings([...endLoadings]);
      }
    };
    postApiDesc();
  }, [postDesc]);

  const handlePostDesc = () => {
    // prevent double post
    const checkWait = loadings.filter((loading) => loading === 'inputDesc');
    if (checkWait.length > 0) return;

    // prevent empty post
    if (!editorModel) {
      handleCancel();
      return;
    }

    const mentionedUsers = extractElementBySelectorFromString(editorModel, '#mentioned-user');

    setPostDesc({ desc: editorModel, mentionedUsers });
    // setEditorModel();
  };

  useEffect(() => {
    if (trigger === undefined) return;
    handlePostDesc();
  }, [trigger]);

  const handleTriggerPostDesc = () => {
    setTrigger(Math.random());
  };

  const handleEditorModelChange = (model) => {
    setEditorModel(model);
  };

  const customConfig = {
    zIndex: 15000,
    toolbarSticky: false,
    heightMax: 400,
  };

  return (
    <>
      <div>
        <EditorFull companyId={companyId} model={editorModel} onModelChange={handleEditorModelChange} triggerLists={currentTeam.members} type="comment" handleAction={handleTriggerPostDesc} customConfig={customConfig} />
      </div>
      <div className={styles.actionSection}>
        <div>
          <Button handleClick={handlePostDesc} variant="success" size="sm" wait="inputDesc" loadings={loadings}>Simpan</Button>
          <CloseIcon onClick={handleCancel} />
        </div>

      </div>

    </>
  );
};

InputFormDesc.propTypes = {
  card: PropTypes.object.isRequired,
  handleCancel: PropTypes.func.isRequired,
};

export default InputFormDesc;
