import React, { memo } from 'react';
import PropTypes from 'prop-types';
import styles from './RadioTeamType.module.css';
import { InputRadioButtonMain } from '../../../../../components/UI_V2/Input/RadioButton';
import { DisplayTextBody } from '../../../../../components/UI_V2/Display/Text';

const RadioTeamType = ({
  onChange,
  value,
  checked,
  children,
  name,
}) => (
  <div className={styles.container}>
    <div className={styles.radio}>
      <InputRadioButtonMain
        value={value}
        checked={checked}
        onChange={onChange}
        name={name}
      />
    </div>
    <div className={styles.text}>
      <DisplayTextBody>
        {children}
      </DisplayTextBody>
    </div>
  </div>
);

RadioTeamType.propTypes = {
  onChange: PropTypes.func.isRequired,
  value: PropTypes.string.isRequired,
  checked: PropTypes.bool.isRequired,
  children: PropTypes.node.isRequired,
  name: PropTypes.string.isRequired,
};

export default memo(RadioTeamType);
