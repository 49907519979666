import React, { useState, useEffect, useContext } from 'react';
import CloseIcon from '@mui/icons-material/Close';
import { useSnackbar } from 'notistack';
import _ from 'lodash';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import EditIcon from '@mui/icons-material/Edit';
import PropTypes from 'prop-types';
import BoxPopOver from '../../../components/UI/BoxPopOver/BoxPopOver';
import SeparatorLine from '../../../components/UI/SeparatorLine/SeparatorLine';
import styles from './EventMenu.module.css';
import OverlayButton from '../../../components/UI/Button/OverlayButton/OverlayButton';
import { GlobalContext } from '../../../contexts/GlobalStateProvider';
import { actionTypes } from '../../../reducers/reducer';
import handleStatusMsg from '../../../utilities/handleStatusMsg';
import handleLoadings from '../../../utilities/handleLoadings';
import apiUtil from '../../../utilities/apiUtil';
import { ApiConstants } from '../../../constants';

const EventMenu = ({ event, cancel }) => {
  const [{}, dispatch] = useContext(GlobalContext);
  const [archiveEvent, setArchiveEvent] = useState();
  const { enqueueSnackbar } = useSnackbar();
  const [loadings, setLoadings] = useState([]);
  const history = useHistory();
  const location = useLocation();
  const params = useParams();
  const { companyId, teamId } = params;

  useEffect(() => {
    if (archiveEvent === undefined) return;

    const archivedApiEvent = async () => {
      const eventId = archiveEvent;

      try {
        const startLoadings = handleLoadings('eventMenu', [...loadings], 'start');
        setLoadings([...startLoadings]);

        const result = await apiUtil.patch(ApiConstants.URL_V1.EVENT_ARCHIVE({ eventId }), {}, {
          params: {
            companyId,
            teamId,
          },
        });

        dispatch({
          type: actionTypes.SET_CURRENT_SCHEDULE_EVENT,
          currentScheduleEvent: _.cloneDeep(result.data.event),
        });

        const status = handleStatusMsg(result, 'success');

        enqueueSnackbar(status.message, {
          variant: 'success',
        });

        cancel();
      } catch (err) {
        const status = handleStatusMsg(err, 'error');

        enqueueSnackbar(status.message, {
          variant: 'error',
        });
      } finally {
        const endLoadings = handleLoadings('eventMenu', [...loadings], 'end');
        setLoadings([...endLoadings]);
      }
    };
    archivedApiEvent();
  }, [archiveEvent]);

  const handleArchivedEvent = (eventId) => {
    setArchiveEvent(eventId);
  };

  const handleEditEvent = (eventId) => {
    history.push(`${location.pathname}/edit`);
  };

  return (
    <BoxPopOver>
      <div className={styles.headerSection}>
        <h1>
          Menu
        </h1>
        <CloseIcon onClick={cancel} />
      </div>
      <SeparatorLine />
      <div className={styles.bodySection}>
        <OverlayButton wait="eventMenu" loadings={loadings}>
          <div onClick={() => handleEditEvent(event._id)} className={styles.menu}>
            <EditIcon />
            <p>Ubah</p>
          </div>
          {event.archived ? (
            <>
              {event.archived.status ? (
                <div className={styles.menu}>
                  <p>Jadwal ini udah terarsip</p>
                </div>
              ) : (
                <div onClick={() => handleArchivedEvent(event._id)} className={styles.menu}>
                  <p>Arsipkan Jadwal Ini</p>
                </div>
              )}
            </>
          ) : null}
        </OverlayButton>
      </div>
      {/* <div className={styles.actionSection}>
        <Button variant="danger" size="sm" block>Delete</Button>
      </div> */}
    </BoxPopOver>
  );
};

EventMenu.propTypes = {
  event: PropTypes.object.isRequired,
  cancel: PropTypes.func.isRequired,
};

export default EventMenu;
