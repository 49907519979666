import React, { memo, useState } from 'react';
import PropTypes from 'prop-types';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import styles from './ChartBox.module.css';
import { DisplayTextBody } from '../../../../../components/UI_V2/Display/Text';
import Color from '../../../../../themes/colors';

const ChartBox = ({
  title,
  children,
}) => (
  <div className={styles.container}>
    <div className={styles.title}>
      <DisplayTextBody
        decoration="bold"
      >
        {title}
      </DisplayTextBody>
    </div>
    <div className={styles}>
      {children}
    </div>
  </div>
);

ChartBox.propTypes = {
  title: PropTypes.string.isRequired,
  children: PropTypes.node,
};

ChartBox.defaultProps = {
  children: <></>,
};

export default memo(ChartBox);
