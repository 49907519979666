import React, { memo, useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import DownloadIcon from '@mui/icons-material/Download';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { useMediaQuery } from '@mui/material';
import styles from './SidebarItem.module.css';
import Color from '../../../themes/colors';
import { InputButtonMain } from '../../UI_V2/Input/Button';
import { DisplayTextBody } from '../../UI_V2/Display/Text';
import { DisplayBadgeCounter } from '../../UI_V2/Display/Badge';

const SidebarItem = ({
  enableIconLeft,
  enableCollapsible,
  enableIconRightCounter,
  enableIconRightGeneral,
  text,
  iconLeft,
  iconRightGeneral,
  onClickCollapsible,
  onClickIconRightGeneral,
  iconRightCounterValue,
  isCollapsed,
}) => {
  const [showCollapsible, setShowCollapsible] = useState(false);
  const handleShowCollapsible = useCallback(() => {
    setShowCollapsible(true);
  }, []);
  const handleHideCollapsible = useCallback(() => {
    setShowCollapsible(false);
  }, []);
  const isMobile = useMediaQuery('(max-width:720px)');

  return (
    <div
      className={styles.container}
      onMouseEnter={handleShowCollapsible}
      onMouseLeave={handleHideCollapsible}
    >
      {enableCollapsible && showCollapsible && (
      <div
        className={styles.collapsible}
        onClick={onClickCollapsible}
      >
        {isCollapsed && <ArrowDropDownIcon />}
        {!isCollapsed && <ArrowRightIcon />}
      </div>
      )}
      {enableIconLeft && (
      <div className={styles.iconLeft}>
        {iconLeft}
      </div>
      )}
      <div className={styles.textAndIconRight}>
        <div className={styles.text}>
          <DisplayTextBody mode="14">
            {text}
          </DisplayTextBody>
        </div>
        {enableIconRightCounter && !enableIconRightGeneral && (
        <div className={styles.iconRightCounter}>
          <DisplayBadgeCounter badgeContent={iconRightCounterValue} />
        </div>
        )}
        {enableIconRightGeneral && !enableIconRightCounter && (
        <div className={styles.iconRightGeneral} onClick={onClickIconRightGeneral}>
          {iconRightGeneral}
        </div>
        )}
      </div>

    </div>
  );
};

SidebarItem.propTypes = {
  enableCollapsible: PropTypes.bool,
  enableIconLeft: PropTypes.bool,
  enableIconRightCounter: PropTypes.bool,
  enableIconRightGeneral: PropTypes.bool,
  text: PropTypes.string,
  onClickCollapsible: PropTypes.func,
  onClickIconRightGeneral: PropTypes.func,
  iconLeft: PropTypes.node,
  iconRightGeneral: PropTypes.node,
  iconRightCounterValue: PropTypes.number,
  isCollapsed: PropTypes.bool,
};

SidebarItem.defaultProps = {
  enableCollapsible: false,
  enableIconLeft: false,
  enableIconRightCounter: false,
  enableIconRightGeneral: false,
  text: '',
  onClickCollapsible: () => null,
  onClickIconRightGeneral: () => null,
  iconLeft: <></>,
  iconRightGeneral: <></>,
  iconRightCounterValue: 0,
  isCollapsed: true,
};

export default memo(SidebarItem);
