import React, { memo, useState } from 'react';
import PropTypes from 'prop-types';
import { Box } from '@mui/material';
import CommentDiscussPageSkeletonHeader from './Header/Header';
import CommentDiscussPageSkeletonBody from './Body/Body';
import CommentDiscussPageSkeletonFooter from './Footer/Footer';
import { DisplayDividerMain } from '../../../components/UI_V2/Display/Divider';

const CommentDiscussPageSkeleton = () => (
  <Box>
    <CommentDiscussPageSkeletonHeader />
    <DisplayDividerMain />
    <CommentDiscussPageSkeletonBody />
    <DisplayDividerMain />
    <CommentDiscussPageSkeletonFooter />
  </Box>
);

export default memo(CommentDiscussPageSkeleton);
