import React, { memo } from 'react';
import { useParams } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import PropTypes from 'prop-types';
import { isEqual } from 'lodash';
import handleStatusMsg from '../../../utilities/handleStatusMsg';
import ButtonDiv from '../../../components/UI/Button/ButtonDiv/ButtonDiv';
import { CardActions } from '../../../actions';

const ChangeAccessCardContainer = ({
  card, children, handleLoading, loadings,
}) => {
  const { enqueueSnackbar } = useSnackbar();
  const params = useParams();
  const { companyId } = params;
  const teamId = params.teamId || card.team?._id || card.team;

  const handleUpdateAccessCard = async () => {
    handleLoading(true);

    try {
      const cardId = card?._id;
      const body = {
        isPublic: !card?.isPublic,
      };
      const result = await CardActions.updateCard({
        cardId,
        body,
        companyId,
        teamId,
      });

      const status = handleStatusMsg(result, 'success');

      enqueueSnackbar(status.message, {
        variant: 'success',
      });
    } catch (error) {
      const status = handleStatusMsg(error, 'error');

      if (status?.message) {
        enqueueSnackbar(status.message, {
          variant: 'error',
        });
      }
    } finally {
      handleLoading(false);
    }
  };

  return (
    <ButtonDiv
      onClick={handleUpdateAccessCard}
      // className='container'
    >
      {children}
    </ButtonDiv>
  );
};

ChangeAccessCardContainer.propTypes = {
  card: PropTypes.object.isRequired,
  children: PropTypes.node.isRequired,
  handleLoading: PropTypes.func.isRequired,
  loadings: PropTypes.array.isRequired,
};

const isComponentDataEqual = (prevProps, nextProps) => {
  const { card, loadings } = nextProps;
  return isEqual(prevProps.card, card)
    && isEqual(prevProps.loadings, loadings);
};

export default memo(ChangeAccessCardContainer, isComponentDataEqual);
