/* eslint-disable no-nested-ternary */
import React, {
  memo,
} from 'react';
import styles from './UnreadMessagesSeparator.module.css';
import { DisplayTextSubBody } from '../../../../../components/UI_V2/Display/Text';
import Color from '../../../../../themes/colors';

const UnreadMessagesSeparator = () => (
  <div className={styles.container}>
    <DisplayTextSubBody
      mode="9"
      fontFamily="openSans"
      color={Color.gray4}
    >
      Dibawah ini adalah pesan yang belum dibaca
    </DisplayTextSubBody>
  </div>
);

export default memo(UnreadMessagesSeparator);
