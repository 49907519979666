import React, { memo } from 'react';
import PropTypes from 'prop-types';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { Skeleton } from '@mui/material';
import styles from './PlanCard.module.css';
import Color from '../../../../themes/colors';
import { DisplayTextBody, DisplayTextHeadline } from '../../../../components/UI_V2/Display/Text';
import { InputButtonMain } from '../../../../components/UI_V2/Input/Button';
import { formatToBasicThousandSeparator } from '../../../../utilities/numberUtil';
import { splitString } from '../../../../utilities/stringUtil';
import { FeedbackLoadingMain } from '../../../../components/UI_V2/Feedback/Loading';

const variantConfig = {
  starter_monthly: {
    styles: styles.starter,
    durationCode: 'bulan',
  },
  starter_quarterly: {
    styles: styles.starter,
    durationCode: '3 bulan',
  },
  starter_biyearly: {
    styles: styles.starter,
    durationCode: '6 bulan',
  },
  starter_yearly: {
    styles: styles.starter,
    durationCode: '1 tahun',
  },
  growth_monthly: {
    styles: styles.growth,
    durationCode: 'bulan',
  },
  growth_quarterly: {
    styles: styles.growth,
    durationCode: '3 bulan',
  },
  growth_biyearly: {
    styles: styles.growth,
    durationCode: '6 bulan',
  },
  growth_yearly: {
    styles: styles.growth,
    durationCode: '1 tahun',
  },
  pro_monthly: {
    styles: styles.pro,
    durationCode: 'bulan',
  },
  pro_quarterly: {
    styles: styles.pro,
    durationCode: '3 bulan',
  },
  pro_biyearly: {
    styles: styles.pro,
    durationCode: '6 bulan',
  },
  pro_yearly: {
    styles: styles.pro,
    durationCode: '1 tahun',
  },
  scale_monthly: {
    styles: styles.scale,
    durationCode: 'bulan',
  },
  scale_quarterly: {
    styles: styles.scale,
    durationCode: '3 bulan',
  },
  scale_biyearly: {
    styles: styles.scale,
    durationCode: '6 bulan',
  },
  scale_yearly: {
    styles: styles.scale,
    durationCode: '1 tahun',
  },
  premium_monthly: {
    styles: styles.premium,
    durationCode: 'bulan',
  },
  premium_quarterly: {
    styles: styles.premium,
    durationCode: '3 bulan',
  },
  premium_biyearly: {
    styles: styles.premium,
    durationCode: '6 bulan',
  },
  premium_yearly: {
    styles: styles.premium,
    durationCode: '1 tahun',
  },
};

const ModalPlanSelectionPlanCard = ({
  plan,
  subscription,
  handleClickPlan,
  loadings,
}) => {
  const {
    name,
    basicTitle,
    price,
    pricePerMonth,
    duration,
    priceSaving,
    features,
  } = plan;

  const isShowPriceSaving = priceSaving?.percentage > 0;

  let finalPricePerMonth = pricePerMonth;
  if (isShowPriceSaving) {
    finalPricePerMonth = pricePerMonth - priceSaving.valuePerMonth;
  }

  finalPricePerMonth = formatToBasicThousandSeparator(finalPricePerMonth);
  const finalTotalPrice = formatToBasicThousandSeparator(price);

  const isCurrentSubscriptionPlan = plan?.name === subscription?.plan?.name;

  const planExtract = splitString(plan?.name, '_');
  const isNotMonthlyPlan = planExtract[1] !== 'monthly';

  let buttonVariant = 'default';

  switch (planExtract[0]) {
    case 'starter':
    case 'growth':
    case 'pro':
      buttonVariant = 'blueLight';
      break;
    case 'scale':
      buttonVariant = 'greenLight';
      break;
    case 'premium':
      buttonVariant = 'default';
      break;
    default:
      break;
  }

  return (
    <div className={`${styles.container} ${variantConfig[name].styles}`}>
      {isCurrentSubscriptionPlan && (
      <div className={styles.currentPlanText}>
        <DisplayTextBody
          color={Color.gray3}
        >
          Paket aktif saat ini
        </DisplayTextBody>
      </div>
      )}
      <div className={styles.title}>
        <DisplayTextHeadline
          mode="36"
          color={Color.blueNavy2}
          decoration="bold"
        >
          {basicTitle}
        </DisplayTextHeadline>
      </div>
      <div className={styles.price}>
        <FeedbackLoadingMain
          wait="billingModalPlanSelection"
          loadings={loadings}
          loadingComponent={<Skeleton variant="text" width={120} />}
        >
          <div className={styles.price__main}>

            <DisplayTextBody
              color={Color.black}
            >
              Rp.
              {' '}
              {finalPricePerMonth}
              {' / bulan'}
            </DisplayTextBody>
          </div>
          {isShowPriceSaving && (
          <div className={styles.price__saving}>
            <DisplayTextBody
              color={Color.greenCicle}
            >
              {`(Hemat ${priceSaving.percentage}%!)`}
            </DisplayTextBody>
          </div>
          )}
        </FeedbackLoadingMain>
      </div>
      {isNotMonthlyPlan && (
      <div className={styles.priceTotal}>
        <FeedbackLoadingMain
          wait="billingModalPlanSelection"
          loadings={loadings}
          loadingComponent={<Skeleton variant="text" width={300} />}
        >
          <div className={styles.price__main}>
            <DisplayTextBody
              color={Color.black}
              decoration="bold"
            >
              Total Rp.
              {' '}
              {finalTotalPrice}
            </DisplayTextBody>
          </div>
          <div className={styles.price__saving}>
            <DisplayTextBody
              color={Color.black}
              decoration="bold"
            >
              {`/ ${variantConfig[name].durationCode}`}
            </DisplayTextBody>
          </div>
        </FeedbackLoadingMain>
      </div>
      )}
      <div className={styles.features}>
        {features.map((feature) => (
          <div className={styles.feature}>
            <div className={styles.icon}>
              <CheckCircleIcon />
            </div>
            <div className={styles.text}>
              <DisplayTextBody
                color={Color.black}
                mode="13"
              >
                {feature.name}
              </DisplayTextBody>
            </div>
          </div>
        ))}
      </div>
      <div className={styles.button}>
        <InputButtonMain
          wide="block"
          border="semiRounded"
          variant={buttonVariant}
          handleClick={() => handleClickPlan({ planId: plan._id, qty: 1 })}
          loadings={loadings}
          wait={`billingSelectPlan-${plan._id}`}
        >
          Pilih
        </InputButtonMain>
      </div>
    </div>
  );
};

ModalPlanSelectionPlanCard.propTypes = {
  plan: PropTypes.object.isRequired,
  subscription: PropTypes.object.isRequired,
  handleClickPlan: PropTypes.func.isRequired,
  loadings: PropTypes.array.isRequired,
};

export default memo(ModalPlanSelectionPlanCard);
