import React from 'react';
import PropTypes from 'prop-types';
import NotifAndCheers from './NotifAndCheers/NotifAndCheers';
import { handleCompareDate } from '../../../utilities/checkSameDay';
import DateSeparator from '../../CommentsSectionContainer/DateSeparator/DateSeparator';

const ShowNotifAndCheers = ({ notifCheers }) => (
  <>
    {notifCheers.map((notifCheer, index) => {
      const isSameDate = handleCompareDate(
        notifCheers,
        notifCheer,
        (index - 1),
        'updatedAt',
      );
      if (!isSameDate) {
        return (
          <>
            <DateSeparator date={notifCheer.updatedAt} />
            <NotifAndCheers notifCheer={notifCheer} />
          </>
        );
      }
      return (
        <NotifAndCheers notifCheer={notifCheer} />
      );
    })}
  </>
);

ShowNotifAndCheers.propTypes = {
  notifCheers: PropTypes.array.isRequired,
};

export default ShowNotifAndCheers;
