/* eslint-disable consistent-return */
import { useCallback } from 'react';
import { CheckInActions } from '../actions';
import { CheckInConstants } from '../constants';
import { connectSocketCheckIn } from '../services/socket';
import { useSocketHooks } from './SocketHooks';

function useCheckInSocket({ checkInId, userId }, dispatch) {
  const callbackNewCheckIn = useCallback((question) => {
    CheckInActions.incomingCheckIn({
      question,
      typeAction: CheckInConstants.typeCallback.NEW,
    }, dispatch);
  }, []);
  const callbackUpdateCheckIn = useCallback((question) => {
    CheckInActions.incomingCheckIn({
      question,
      typeAction: CheckInConstants.typeCallback.EDIT,
    }, dispatch);
  }, []);
  const callbackArchiveCheckIn = useCallback((question) => {
    CheckInActions.incomingCheckIn({
      question,
      typeAction: CheckInConstants.typeCallback.DELETE,
    }, dispatch);
  }, []);

  const listenSocket = (socket, params) => {
    socket
      .on(`question-new-${params?.checkInId}`, callbackNewCheckIn)
      .on(`question-update-${params?.checkInId}`, callbackUpdateCheckIn)
      .on(`question-archive-${params?.checkInId}`, callbackArchiveCheckIn);
  };
  const removeListener = (socket, params) => {
    socket
      .off(`question-new-${params?.checkInId}`)
      .off(`question-update-${params?.checkInId}`)
      .off(`question-archive-${params?.checkInId}`);
    socket.disconnect();
  };

  useSocketHooks({
    params: { checkInId, userId },
    connectSocket: connectSocketCheckIn,
    listenSocket,
    removeListener,
  });
}

export {
  useCheckInSocket,
};
