import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { Tooltip } from '@mui/material';
import styles from './BadgeTrialCategory.module.css';
import { DisplayTextBody } from '../../../../../../components/UI_V2/Display/Text';
import { roleTitle, role } from '../../../../../../constants/RoleConstants';
import Color from '../../../../../../themes/colors';

const badgeStyles = {
  true: styles.true,
  false: styles.false,
};

const colorTextStyles = {
  true: Color.white,
  false: Color.white,
};

const badgeTitleStyles = {
  true: 'Trial',
  false: 'Customer',
};

const BadgeTrialCategory = ({
  category,
}) => (
  <div className={`${styles.container} ${badgeStyles[category]}`}>
    <DisplayTextBody
      color={colorTextStyles[category]}
    >
      {badgeTitleStyles[category]}
    </DisplayTextBody>
  </div>
);

BadgeTrialCategory.propTypes = {
  category: PropTypes.string.isRequired,
};

export default memo(BadgeTrialCategory);
