import React, { memo, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { useLocation, useParams, useHistory } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import styles from './ChartCardUserReport.module.css';
import Color from '../../../../../themes/colors';
import { DisplayChartDoughnut } from '../../../../../components/UI_V2/Display/Chart';
import BackgroundChart from '../BackgroundChart/BackgroundChart';
import TitleChart from '../TitleChart/TitleChart';
import LegendCardItem from './LegendCardItem/LegendCardItem';
import handleStatusMsg from '../../../../../utilities/handleStatusMsg';
import handleLoadings from '../../../../../utilities/handleLoadings';
import { ReportActions } from '../../../../../actions';
import { ReportConstants } from '../../../../../constants';
import { initiateUserCardRatingFromResultReportCount, initiateUserCardStatRatioFromResultReportCount } from '../../../../../actions/ReportActions';
import { DisplayTextBody } from '../../../../../components/UI_V2/Display/Text';
import { FeedbackLoadingMain } from '../../../../../components/UI_V2/Feedback/Loading';
import ChartUserSkeleton from '../ChartUserSkeleton/ChartUserSkeleton';

const ChartCardUserReport = ({
  fromDate,
  untilDate,
  handleSetRating,
  handleSetRatio,
  handleSetIsEmptyData,
  handleSetAllCount,
  handleSetAllPercentage,
  handleSetIsLoading,
}) => {
  const [loadings, setLoadings] = useState([]);
  const [currentReportCardMemberCount, setCurrentReportCardMemberCount] = useState([
    0,
    0,
    0,
    0,
    0,
    0,
  ]);
  const [currentReportCardMemberCountPercentage,
    setCurrentReportCardMemberCountPercentage] = useState([
    0,
    0,
    0,
    0,
    0,
    0,
  ]);

  const params = useParams();
  const history = useHistory();
  const location = useLocation();
  const {
    companyId, teamId, memberId,
  } = params;

  const { enqueueSnackbar } = useSnackbar();

  const initiateAllReportCardMemberCountApi = async () => {
    try {
      const startLoadings = handleLoadings('initiateReportCardMemberCount', [...loadings], 'start');
      setLoadings([...startLoadings]);
      handleSetIsLoading(true);

      const result = await ReportActions.initiateReportCardMemberCount({
        teamId,
        memberId,
        companyId,
        fromDate,
        untilDate,
        setCurrentReportCardMemberCount,
        setCurrentReportCardMemberCountPercentage,
      });

      const ratio = initiateUserCardRatingFromResultReportCount({ result, handleSetRating });
      const rating = initiateUserCardStatRatioFromResultReportCount({ result, handleSetRatio });
    } catch (err) {
      const status = handleStatusMsg(err, 'error');

      enqueueSnackbar(status.message, {
        variant: 'error',
      });
    } finally {
      const endLoadings = handleLoadings('initiateReportCardMemberCount', [...loadings], 'end');
      setLoadings([...endLoadings]);
      handleSetIsLoading(false);
    }
  };

  useEffect(() => {
    initiateAllReportCardMemberCountApi();
  }, [location, fromDate, untilDate]);

  useEffect(() => {
    handleSetAllCount(currentReportCardMemberCount);
    handleSetAllPercentage(currentReportCardMemberCountPercentage);
  }, [currentReportCardMemberCount, currentReportCardMemberCountPercentage]);

  const labels = [
    'Belum',
    'Dikerjakan',
    'Terlambat',
    'Selesai',
  ];

  const colors = [
    Color.gray3,
    Color.lighterBlueCicle,
    Color.deleteColor,
    Color.tosca,
  ];

  const firstDataset = {
    label: 'Laporan Tugas',
    data: [
      currentReportCardMemberCount?.[0],
      currentReportCardMemberCount?.[1],
      currentReportCardMemberCount?.[2],
      currentReportCardMemberCount?.[3],
    ],
  };

  const datasets = [
    firstDataset,
  ];

  const isDataEmpty = Number(currentReportCardMemberCount?.[0])
  + Number(currentReportCardMemberCount?.[1])
  + Number(currentReportCardMemberCount?.[2])
  + Number(currentReportCardMemberCount?.[3]) === 0;

  handleSetIsEmptyData(isDataEmpty);

  return (
    <div className={styles.container}>
      <BackgroundChart>
        <div className={styles.title}>
          <TitleChart>
            Ringkasan Tugas Realtime
          </TitleChart>
        </div>
        <FeedbackLoadingMain
          wait="initiateReportCardMemberCount"
          loadings={loadings}
          loadingComponent={
            <div className={styles.skeleton}><ChartUserSkeleton /></div>
}
        >
          <div className={styles.body}>
            <div className={styles.chart}>
              {isDataEmpty && (
              <div className={styles.emptyData}>
                <DisplayTextBody>
                  Belum ada data
                </DisplayTextBody>
              </div>
              )}
              {!isDataEmpty && (
              <DisplayChartDoughnut
                labels={labels}
                datasets={datasets}
                colors={colors}
              />
              )}
            </div>
            <div className={styles.legend}>
              <div className={styles.legendItem}>
                <LegendCardItem
                  type={ReportConstants.typeRef.backlog}
                  value={currentReportCardMemberCount?.[0]}
                  percentage={currentReportCardMemberCountPercentage?.[0]}
                />
              </div>
              <div className={styles.legendItem}>
                <LegendCardItem
                  type={ReportConstants.typeRef.ongoing}
                  value={currentReportCardMemberCount?.[1]}
                  percentage={currentReportCardMemberCountPercentage?.[1]}
                />
              </div>
              <div className={styles.legendItem}>
                <LegendCardItem
                  type={ReportConstants.typeRef.overdue}
                  value={currentReportCardMemberCount?.[2]}
                  percentage={currentReportCardMemberCountPercentage?.[2]}
                />
              </div>
              <div className={styles.legendItem}>
                <LegendCardItem
                  type={ReportConstants.typeRef.completed}
                  value={currentReportCardMemberCount?.[3]}
                  percentage={currentReportCardMemberCountPercentage?.[3]}
                />
              </div>
            </div>
          </div>
        </FeedbackLoadingMain>
      </BackgroundChart>
    </div>
  );
};

ChartCardUserReport.propTypes = {
  fromDate: PropTypes.string.isRequired,
  untilDate: PropTypes.string.isRequired,
  handleSetRating: PropTypes.func.isRequired,
  handleSetRatio: PropTypes.func.isRequired,
  handleSetIsEmptyData: PropTypes.func.isRequired,
  handleSetAllCount: PropTypes.func.isRequired,
  handleSetAllPercentage: PropTypes.func.isRequired,
  handleSetIsLoading: PropTypes.func.isRequired,
};

ChartCardUserReport.defaultProps = {};

export default memo(ChartCardUserReport);
