import React, {
  memo, useState, useEffect, useReducer, useCallback,
} from 'react';
import { useParams } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Box } from '@mui/material';
import MoreHorizOutlinedIcon from '@mui/icons-material/MoreHorizOutlined';
import { DisplayTextBody, DisplayTextSubHeadline } from '../../../../../components/UI_V2/Display/Text';
import ResultItem from './ResultItem/ResultItem';
import { limitChar } from '../../../../../utilities/stringUtil';
import { PageConstants, SearchJumpToConstants } from '../../../../../constants';
import Color from '../../../../../themes/colors';
import LinkNoDecor from '../../../../../components/LinkNoDecor/LinkNoDecor';
import TitleJumpTo from '../../JumpTo/TitleJumpTo/TitleJumpTo';

const showMoreTextStyles = {
  display: 'flex',
  alignItems: 'center',
  height: '20px',
  paddingTop: '1px',
  paddingBottom: '1px',
  paddingLeft: '8px',
  paddingRight: '8px',
  width: '95%',
  cursor: 'pointer',
  marginTop: '5px',
  '&:hover': {
    opacity: '0.8',
  },
};

const iconBoxStyles = {
  marginRight: '13px',
};

const iconStyles = {
  color: Color.blueNavy4,
};

const ListResult = ({
  list, type, selectedIndex, onClose, title, keywordArray, params,
  handleSetTotal,
}) => {
  const [showMoreList, setShowMoreList] = useState(false);
  const listByType = list.filter((item) => {
    if (type === 'teams') {
      return item.type === 'hq' || item.type === 'team' || item.type === 'project';
    }
    if (type === 'myTasks') {
      return item.type === 'myTasksList' || item.type === 'myTasksCalendar';
    }

    if (type === 'boards') {
      return item.type === 'boards' || item.type === 'boardsList' || item.type === 'boardsCalendar';
    }
    if (type === PageConstants.pageType.companyModuleSearch) {
      return item.type === 'billings'
        || item.type === 'cheers'
        || item.type === 'notifications'
        || item.type === 'companyMembers';
    }
    return item.type === type;
  });
  const hasMoreLength = listByType.length > SearchJumpToConstants.limitMoreList[type];
  const moreLength = listByType.length - SearchJumpToConstants.limitMoreList[type];
  const isListByTypeExist = listByType.length > 0;

  const { companyId } = params;

  const handleShowOpenMoreList = useCallback(() => {
    setShowMoreList(true);
  }, []);

  useEffect(() => {
    handleSetTotal(listByType.length);
    return () => {
      handleSetTotal(0);
    };
  }, [listByType]);
  return (
    <>
      {isListByTypeExist && (
        <Box
          sx={{
            marginTop: '10px',
            marginBottom: '10px',
          }}
        >
          <TitleJumpTo>
            {title}
          </TitleJumpTo>
          {listByType.map((item, index) => {
            if (index >= SearchJumpToConstants.limitMoreList[type]) return null;
            return (
              <ResultItem
                params={params}
                key={item._id}
                keywordArray={keywordArray}
                item={item}
                isSelected={index === selectedIndex}
                onClick={onClose}
              />
            );
          })}
          {hasMoreLength && !showMoreList
            ? (
              <Box
                sx={{
                  ...showMoreTextStyles,
                }}
                onClick={handleShowOpenMoreList}
              >
                <Box
                  sx={{
                    ...iconBoxStyles,
                  }}
                >
                  <MoreHorizOutlinedIcon sx={{
                    ...iconStyles,
                  }}
                  />
                </Box>
                <DisplayTextBody
                  mode="14"
                  color={Color.blueNavy4}
                >
                  Tampilkan
                  {' '}
                  {moreLength}
                  {' '}
                  lagi
                </DisplayTextBody>
              </Box>
            )
            : null}
          {hasMoreLength && showMoreList ? (
            <>
              {listByType.map((item, index) => {
                if (index < SearchJumpToConstants.limitMoreList[type]) return null;
                return (
                  <ResultItem
                    params={params}
                    key={item._id}
                    item={item}
                    keywordArray={keywordArray}
                    isSelected={index === selectedIndex}
                    onClick={onClose}
                  />
                );
              })}
            </>
          )
            : null}
        </Box>
      )}
    </>
  );
};

ListResult.propTypes = {
  type: PropTypes.oneOf(['teams', 'groupChats', 'blasts', 'schedules', 'checkIns', 'boards', 'buckets']).isRequired,
  selectedIndex: PropTypes.number.isRequired,
  list: PropTypes.array.isRequired,
  onClose: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
  keywordArray: PropTypes.array.isRequired,
  params: PropTypes.object,
  handleSetTotal: PropTypes.func.isRequired,
};

ListResult.defaultProps = {
  params: { companyId: null, teamId: null },
};

export default memo(ListResult);
