import React, { useEffect, memo, useContext } from 'react';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import _ from 'lodash';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { useMediaQuery } from '@mui/material';
import styles from './DashboardAdminPage.module.css';
import Title from '../../components/Title/Title';
import LogoHeader from '../../components/UI/LogoHeader/LogoHeader';
import { DisplayTextHeadline, DisplayTextSubHeadline } from '../../components/UI_V2/Display/Text';
import Color from '../../themes/colors';
import LinkNoDecor from '../../components/LinkNoDecor/LinkNoDecor';
import PirateMetricPage from './PirateMetricPage/PirateMetricPage';
import { InputButtonMain } from '../../components/UI_V2/Input/Button';
import MenuAdmin from './MenuAdmin/MenuAdmin';
import CompanyListPage from './CompanyListPage/CompanyListPage';
import { administrators } from '../../constants/AdministratorConstants';

import { GlobalContext } from '../../contexts/GlobalStateProvider';
import { actionTypes } from '../../reducers/reducer';
import CompanyDetailPage from './CompanyDetailPage/CompanyDetailPage';
import TractionPage from './TractionPage/TractionPage';

const DashboardAdminPage = () => {
  const [{ user }, dispatch] = useContext(GlobalContext);
  const isTablet = useMediaQuery('(max-width:1024px)');
  const isMobile = useMediaQuery('(max-width:720px)');

  const location = useLocation();
  const history = useHistory();
  const params = useParams();

  const { companyId } = params;

  const path = location.pathname;

  let activePage = '';

  switch (path) {
    case '/administrators/reports/metrics':
      activePage = 'metricPage';
      break;
    case '/administrators/reports/tractions':
      activePage = 'tractionPage';
      break;
    case '/administrators/reports/insights':
      activePage = 'insightPage';
      break;
    case '/administrators/reports/companies':
      activePage = 'companyListPage';
      break;
    default:
      if (companyId) {
        activePage = 'companyDetailPage';
      }
      break;
  }

  const isMetricPage = activePage === 'metricPage';
  const isCompanyListPage = activePage === 'companyListPage';
  const isTractionPage = activePage === 'tractionPage';
  const isInsightPage = activePage === 'insightPage';
  const isCompanyDetailPage = activePage === 'companyDetailPage';

  useEffect(() => {
    if (!administrators.includes(user.email)) {
      dispatch({
        type: actionTypes.SET_ERROR_RESPONSE,
        errorResponse: { message: 'Unauthorized' },
      });

      history.push(`/errors?previousPath=${location.pathname}`);
    }
  }, [location]);

  const titlePage = {
    metricPage: 'Metric Page - Dashboard Admin',
    tractionPage: 'Traction Page - Dashboard Admin',
    companyListPage: 'List Company on Each Category Page - Dashboard Admin',
    copmanyDetailPage: 'Company Detail & Activities Page - Dashboard Admin',
  };

  return (
    <>
      <Title title={titlePage[activePage]} />
      <div className={styles.menu}>
        <MenuAdmin
          activePage={activePage}
        />
      </div>
      <div className={styles.container}>
        {isMetricPage && <PirateMetricPage />}
        {isCompanyListPage && <CompanyListPage />}
        {isCompanyDetailPage && <CompanyDetailPage />}
        {isTractionPage && <TractionPage />}
      </div>
    </>
  );
};

export default memo(DashboardAdminPage);
