import React, { useState, useContext } from 'react';
import CloseIcon from '@mui/icons-material/Close';
import CheckIcon from '@mui/icons-material/Check';
import { useHistory, useParams, useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';
import styles from './FilterContainer.module.css';
import SeparatorLine from '../../../components/UI/SeparatorLine/SeparatorLine';
import ImageMember from '../../../components/UI/ImageMember/ImageMember';
import Label from '../../../components/UI/Label/Label';
import { GlobalContext } from '../../../contexts/GlobalStateProvider';
import SearchCards from './SearchCards/SearchCards';
import ButtonDiv from '../../../components/UI/Button/ButtonDiv/ButtonDiv';

const containerSidebarStyles = {
  true: `${styles.container} ${styles.sidebarOpen}`,
  false: styles.container,
};

const FilterContainer = ({
  cancel, handleClickLabel, handleClickMember, handleClearFilter, handleClickDueDate,
  handleSearchName, checkDueDate, checkLabels, checkMembers, checkSearchName,
}) => {
  const [{
    currentTeam, currentBoardLabels,
    isSidebarOpen,
  }] = useContext(GlobalContext);
  // const [checkLabels, setCheckLabels] = useState([]);
  // const [checkMembers, setCheckMembers] = useState([]);

  const [showAllLabels, setShowAllLabels] = useState(false);
  const [showAllMembers, setShowAllMembers] = useState(false);

  const params = useParams();
  const history = useHistory();
  const location = useLocation();
  //
  // useEffect(() => {
  //   let filtered = []
  //   currentBoardLists.forEach(list => {
  //     let listCards = []
  //
  //     if (checkLabels.length < 1 && checkMembers.length < 1) {
  //       listCards = [...list.cards]
  //     } else if (checkLabels.length < 1 || checkMembers.length < 1) {
  //       listCards = list.cards.filter(card => card.labels.some(
  // label => checkLabels.some(search => label._id.includes(search)) )
  // || card.members.some(member => checkMembers.some(
  //   search => member?._id.includes(search))));
  //     } else {
  //       listCards = list.cards.filter(card => card.labels.some(
  // label => checkLabels.some(search => label._id.includes(search)) )
  // && card.members.some(member => checkMembers.some(
  //   search => member?._id.includes(search))));
  //     }
  //
  //     let newList = {
  //       ...list,
  //       cards: [...listCards]
  //     }
  //
  //     filtered.push(newList)
  //   });
  //
  //   console.log(filtered);
  //
  //   dispatch({
  //     type: actionTypes.SET_FILTERED_LISTS,
  //     filteredLists: _.cloneDeep(filtered)
  //   });
  //
  // }, [checkLabels, checkMembers, currentBoardLists])
  //
  //
  // const handleClickLabel = labelId => {
  //   let newCheckLabels = [...checkLabels]
  //   if (!checkLabels.includes(labelId)) {
  //     newCheckLabels.push(labelId);
  //     setCheckLabels([...newCheckLabels])
  //   } else {
  //     newCheckLabels = newCheckLabels.filter(id => id !== labelId);
  //     setCheckLabels([...newCheckLabels])
  //   }
  // }
  //
  // const handleClickMember = memberId => {
  //   let newCheckMembers = [...checkMembers]
  //   if (!checkMembers.includes(memberId)) {
  //     newCheckMembers.push(memberId);
  //     setCheckMembers([...newCheckMembers])
  //   } else {
  //     newCheckMembers = newCheckMembers.filter(id => id !== memberId);
  //     setCheckMembers([...newCheckMembers])
  //   }
  // }

  const handleShowAllLabels = () => {
    setShowAllLabels(!showAllLabels);
  };

  const handleShowAllMembers = () => {
    setShowAllMembers(!showAllMembers);
  };

  return (
    <div className={containerSidebarStyles[isSidebarOpen]}>
      <div className={styles.header}>
        <h1>Filter Tugas</h1>
        <CloseIcon onClick={cancel} />
      </div>
      <div className={styles.body}>
        <>
          <div className={styles.searchSection}>
            <SearchCards handleSearchName={handleSearchName} boardId={params.boardId} />
            <p>Filter nama tugas</p>
          </div>
          <SeparatorLine />
          <div className={styles.labelSection}>
            {currentBoardLabels.length < 1
              ? <span>Lokasi tugas ini belum punya label.</span>
              : null}

            {showAllLabels
              ? (
                <>
                  {currentBoardLabels.map((label, index) => {
                    if (index === (currentBoardLabels.length - 1)) {
                      return (
                        <>
                          <Label
                            clicked={() => handleClickLabel(label._id)}
                            color={label.color.name}
                            name={label.name}
                            block
                            checked={checkLabels.some((checkLabel) => checkLabel === label._id)}
                          />
                          <ButtonDiv onClick={handleShowAllLabels} className={styles.menu}>
                            <p>Sembunyikan sebagian Label</p>
                          </ButtonDiv>
                        </>
                      );
                    }
                    return (
                      <Label
                        clicked={() => handleClickLabel(label._id)}
                        color={label.color.name}
                        name={label.name}
                        block
                        checked={checkLabels.some((checkLabel) => checkLabel === label._id)}
                      />
                    );
                  })}
                </>
              )
              : (
                <>
                  {currentBoardLabels.map((label, index) => {
                    if (index < 3) {
                      return (
                        <Label
                          clicked={() => handleClickLabel(label._id)}
                          color={label.color.name}
                          name={label.name}
                          block
                          checked={checkLabels.some((checkLabel) => checkLabel === label._id)}
                        />
                      );
                    } if (index === 3) {
                      return (
                        <>
                          <Label
                            clicked={() => handleClickLabel(label._id)}
                            color={label.color.name}
                            name={label.name}
                            block
                            checked={checkLabels.some((checkLabel) => checkLabel === label._id)}
                          />
                          {index !== currentBoardLabels.length - 1 ? (
                            <ButtonDiv onClick={handleShowAllLabels} className={styles.menu}>
                              <p>
                                Tampilkan semua Label (
                                {`${currentBoardLabels.length - 4} tersembunyi`}
                                )
                              </p>
                            </ButtonDiv>
                          ) : null}
                        </>
                      );
                    }
                    return null;
                  })}
                </>
              )}
          </div>
          <SeparatorLine />
          <div className={styles.memberSection}>
            {currentTeam?.members?.length < 1
              ? <span>Tim ini belum punya Anggota.</span>
              : null}

            {showAllMembers
              ? (
                <>
                  {currentTeam?.members?.map((member, index) => {
                    if (index === (currentTeam?.members?.length - 1)) {
                      return (
                        <>
                          <ButtonDiv
                            onClick={() => handleClickMember(member?._id)}
                            className={styles.menu}
                          >
                            <div className={styles.icon}>
                              <ImageMember photoUrl={member?.photoUrl} />
                            </div>
                            <div className={styles.text}>
                              <p>{member?.fullName}</p>
                              {' '}
                              {checkMembers.some((checkMember) => checkMember === member?._id)
                                ? <CheckIcon />
                                : null}
                            </div>
                          </ButtonDiv>
                          <ButtonDiv onClick={handleShowAllMembers} className={styles.menu}>
                            <p>Sembunyikan sebagian anggota</p>
                          </ButtonDiv>
                        </>
                      );
                    }
                    return (
                      <ButtonDiv
                        onClick={() => handleClickMember(member?._id)}
                        className={styles.menu}
                      >
                        <div className={styles.icon}>
                          <ImageMember photoUrl={member?.photoUrl} />
                        </div>
                        <div className={styles.text}>
                          <p>{member?.fullName}</p>
                          {' '}
                          {checkMembers.some((checkMember) => checkMember === member?._id)
                            ? <CheckIcon />
                            : null}
                        </div>
                      </ButtonDiv>
                    );
                  })}
                </>
              )
              : (
                <>
                  {currentTeam?.members?.map((member, index) => {
                    if (index < 3) {
                      return (
                        <ButtonDiv
                          onClick={() => handleClickMember(member?._id)}
                          className={styles.menu}
                        >
                          <div className={styles.icon}>
                            <ImageMember photoUrl={member?.photoUrl} />
                          </div>
                          <div className={styles.text}>
                            <p>{member?.fullName}</p>
                            {' '}
                            {checkMembers.some((checkMember) => checkMember === member?._id)
                              ? <CheckIcon />
                              : null}
                          </div>
                        </ButtonDiv>
                      );
                    } if (index === 3) {
                      return (
                        <>
                          <ButtonDiv
                            onClick={() => handleClickMember(member?._id)}
                            className={styles.menu}
                          >
                            <div className={styles.icon}>
                              <ImageMember photoUrl={member?.photoUrl} />
                            </div>
                            <div className={styles.text}>
                              <p>{member?.fullName}</p>
                              {' '}
                              {checkMembers.some((checkMember) => checkMember === member?._id)
                                ? <CheckIcon /> : null}
                            </div>
                          </ButtonDiv>
                          {index !== currentTeam?.members?.length - 1 ? (
                            <ButtonDiv onClick={handleShowAllMembers} className={styles.menu}>
                              <p>
                                Tampilkan semua anggota (
                                {`${currentTeam?.members?.length - 4} tersembunyi`}
                                )
                              </p>
                            </ButtonDiv>
                          ) : null}
                        </>
                      );
                    }
                    return null;
                  })}
                </>
              )}
          </div>
          <SeparatorLine />
          <div className={styles.dueDateSection}>
            <ButtonDiv onClick={() => handleClickDueDate('soon')} className={styles.menu}>
              <div className={styles.text}>
                <p>Tenggat segera</p>
                {' '}
                {checkDueDate !== undefined ? <>{checkDueDate.type === 'soon' ? <CheckIcon /> : null}</> : null}
              </div>
            </ButtonDiv>
            <ButtonDiv onClick={() => handleClickDueDate('today')} className={styles.menu}>
              <div className={styles.text}>
                <p>Tenggat hari ini</p>
                {' '}
                {checkDueDate !== undefined ? <>{checkDueDate.type === 'today' ? <CheckIcon /> : null}</> : null}
              </div>
            </ButtonDiv>
            <ButtonDiv onClick={() => handleClickDueDate('overdue')} className={styles.menu}>
              <div className={styles.text}>
                <p>Terlambat</p>
                {' '}
                {checkDueDate !== undefined ? <>{checkDueDate.type === 'overdue' ? <CheckIcon /> : null}</> : null}
              </div>
            </ButtonDiv>
          </div>
          <SeparatorLine />
          <div className={styles.clearSearchSection}>
            <ButtonDiv onClick={() => handleClearFilter()} className={styles.menu}>
              <div className={styles.text}><p>Hapus Filter</p></div>
            </ButtonDiv>
          </div>
        </>
      </div>
    </div>

  );
};

FilterContainer.propTypes = {
  cancel: PropTypes.func.isRequired,
  handleClickLabel: PropTypes.func.isRequired,
  handleClickMember: PropTypes.func.isRequired,
  handleClearFilter: PropTypes.func.isRequired,
  handleClickDueDate: PropTypes.func.isRequired,
  handleSearchName: PropTypes.func.isRequired,
  checkDueDate: PropTypes.object.isRequired,
  checkLabels: PropTypes.array.isRequired,
  checkMembers: PropTypes.array.isRequired,
  checkSearchName: PropTypes.string,
};

FilterContainer.defaultProps = {
  checkSearchName: '',
};

export default FilterContainer;
