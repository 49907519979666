import React, { memo, useState } from 'react';
import PropTypes from 'prop-types';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import styles from './BoxText.module.css';
import { DisplayTextBody } from '../../../../../components/UI_V2/Display/Text';
import Color from '../../../../../themes/colors';
import { getSingleOrPlural } from '../../../../../utilities/stringUtil';
import LinkNoDecor from '../../../../../components/LinkNoDecor/LinkNoDecor';

const BoxText = ({
  value,
  desc,
  url,
}) => (
  <LinkNoDecor to={url}>
    <div className={styles.container}>
      <div className={styles.amount}>
        <DisplayTextBody
          decoration="bold"
          color={Color.tosca}
        >
          {value}
        </DisplayTextBody>
      </div>
      <div className={styles.desc}>
        <DisplayTextBody>
          {getSingleOrPlural(desc, value)}
        </DisplayTextBody>
      </div>
    </div>
  </LinkNoDecor>
);

BoxText.propTypes = {
  value: PropTypes.number.isRequired,
  desc: PropTypes.string.isRequired,
  url: PropTypes.string.isRequired,
};

export default memo(BoxText);
