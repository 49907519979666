/* eslint-disable consistent-return */
import { useCallback } from 'react';
import { BlastActions } from '../actions';
import { BlastConstants } from '../constants';
import { connectSocketBlast } from '../services/socket';
import { useSocketHooks } from './SocketHooks';

function useBlastSocket({ blastId, userId }, dispatch) {
  const callbackNewBlast = useCallback((post) => {
    BlastActions.incomingBlast({
      post,
      typeAction: BlastConstants.typeCallback.NEW,
    }, dispatch);
  }, []);
  const callbackUpdateBlast = useCallback((post) => {
    BlastActions.incomingBlast({
      post,
      typeAction: BlastConstants.typeCallback.EDIT,
    }, dispatch);
  }, []);
  const callbackArchiveBlast = useCallback((post) => {
    BlastActions.incomingBlast({
      post,
      typeAction: BlastConstants.typeCallback.DELETE,
    }, dispatch);
  }, []);

  const listenSocket = (socket, params) => {
    socket
      .on(`post-new-${params?.blastId}`, callbackNewBlast)
      .on(`post-update-${params?.blastId}`, callbackUpdateBlast)
      .on(`post-archive-${params?.blastId}`, callbackArchiveBlast);
  };
  const removeListener = (socket, params) => {
    socket
      .off(`post-new-${params?.blastId}`)
      .off(`post-update-${params?.blastId}`)
      .off(`post-archive-${params?.blastId}`);
    socket.disconnect();
  };

  useSocketHooks({
    params: { blastId, userId },
    connectSocket: connectSocketBlast,
    listenSocket,
    removeListener,
  });
}

export {
  useBlastSocket,
};
