import React, { memo, useState } from 'react';
import PropTypes from 'prop-types';
import DownloadIcon from '@mui/icons-material/Download';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { useMediaQuery } from '@mui/material';
import styles from './EmailSection.module.css';
import Color from '../../../../themes/colors';
import BoxTextSingle from '../../BoxTextSingle/BoxTextSingle';
import { DisplayTextBody } from '../../../../components/UI_V2/Display/Text';

const EmailSection = ({
  email,
  onClick,
}) => (
  <div className={styles.container}>
    <div className={styles.box}>
      <BoxTextSingle text={email} />
    </div>
    <div className={styles.action} onClick={onClick}>
      <DisplayTextBody color={Color.brightBlue}>
        Ubah Email
      </DisplayTextBody>
    </div>
  </div>
);

EmailSection.propTypes = {
  email: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
};

EmailSection.defaultProps = {};

export default memo(EmailSection);
