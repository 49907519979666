import { cloneDeep } from 'lodash';
import {
  BreadCrumbActions, CommentActions, EventActions, ScheduleActions, TeamActions,
} from '.';
import { ApiConstants, OccurrenceConstants } from '../constants';
import { actionTypes } from '../reducers/reducer';
import apiUtil from '../utilities/apiUtil';
import { getLastListCreatedAt, mergeObjectListAndRemoveDuplicate, updateListProperty } from '../utilities/arrayUtil';
import { ErrorException } from '../utilities/handleError';

/*
  Dispatcher
*/

function dispatchCurrentOccurrence({ currentOccurrence }, dispatch) {
  dispatch({
    type: actionTypes.SET_CURRENT_OCCURRENCE,
    currentOccurrence,
  });
}

function dispatchUpdateOccurrence({ updateOccurrence }, dispatch) {
  dispatch({
    type: actionTypes.UPDATE_CURRENT_OCCURRENCE,
    updateOccurrence,
  });
}

/*
  SetterDispatcher
*/

function setCurrentOccurrence({ currentOccurrence }, dispatch) {
  if (!currentOccurrence) return;

  dispatchCurrentOccurrence(
    { currentOccurrence: cloneDeep(currentOccurrence) },
    dispatch,
  );
}

/*
  Helpers
*/

const modifyEventAndEventComments = (events, comments) => ({
  ...events,
  comments,
});

/*
  Method
*/

function incomingRecurringEvent({ event }, dispatch) {
  if (!event) return;

  const updateOccurrence = (currentOccurrence) => {
    let newOccurrence = {
      ...currentOccurrence,
      seen: event.seen,
      title: event.title,
      subscribers: event.subscribers,
      content: event.content,
    };
    newOccurrence = cloneDeep(newOccurrence);

    return newOccurrence;
  };

  dispatchUpdateOccurrence({ updateOccurrence }, dispatch);
}

function incomingOccurrenceComment({
  comment, typeAction, updateCommentCheer, keyProperty = 'comments',
}, dispatch) {
  if (!comment) return;

  const updateOccurrence = (currentOccurrence) => updateListProperty({
    keyProperty,
    newData: comment,
    currentList: currentOccurrence,
    typeAction,
    actionEdit: updateCommentCheer,
  });

  dispatchUpdateOccurrence({ updateOccurrence }, dispatch);
}

async function initiateOccurrenceV2({
  eventId, occurrenceId, limit, companyId, teamId,
}, dispatch) {
  try {
    const result = await apiUtil.get(
      ApiConstants.URL_V2.EVENT_OCCURRENCE_NEW({ eventId, occurrenceId }),
      {
        params: {
          companyId,
          teamId,
        },
      },
    );
    const resultComment = await apiUtil.get(
      ApiConstants.URL_V2.EVENT_OCCURRENCE_COMMENT({ eventId, occurrenceId }), {
        params: {
          limit,
          companyId,
          teamId,
        },
      },
    );

    const modifiedResult = modifyEventAndEventComments(
      result?.data?.occurrence,
      resultComment?.data?.comments,
    );

    setCurrentOccurrence({ currentOccurrence: modifiedResult }, dispatch);
    ScheduleActions.setCurrentSchedule(
      { currentSchedule: { _id: result?.data?.occurrence?.schedule } }, dispatch,
    );
    // TeamActions.saveCurrentTeam({ currentTeam: result?.data?.currentTeam }, dispatch);

    const objComments = CommentActions.sliceResultDataforLastCommentNearLimitComment(resultComment);
    const payload = {
      data: {
        occurrence: result?.data?.occurrence,
        comments: objComments?.data?.comments,
      },
    };

    return payload;
  } catch (error) {
    throw new ErrorException(error);
  }
}

async function loadMoreOccurrenceComment({
  eventId, occurrenceId, currentOccurrence, companyId, teamId,
}, dispatch) {
  try {
    const result = await apiUtil.get(
      ApiConstants.URL_V2.EVENT_OCCURRENCE_COMMENT({ eventId, occurrenceId }), {
        params: {
          limit: OccurrenceConstants.limitComment,
          createdAt: getLastListCreatedAt(currentOccurrence.comments),
          companyId,
          teamId,
        },
      },
    );

    const modifiedResult = modifyEventAndEventComments(
      currentOccurrence,
      result?.data?.comments,
    );
    const mergedEvent = mergeObjectListAndRemoveDuplicate({
      currentObjectList: currentOccurrence,
      nextObjectList: modifiedResult,
      keyObject: 'comments',
    });

    EventActions.setPreviousEventComment(
      { previousEventComment: modifiedResult?.comments },
      dispatch,
    );
    setCurrentOccurrence({ currentOccurrence: mergedEvent }, dispatch);

    return result;
  } catch (error) {
    throw new ErrorException(error);
  }
}

export {
  initiateOccurrenceV2,
  loadMoreOccurrenceComment,
  setCurrentOccurrence,
  dispatchUpdateOccurrence,
  incomingOccurrenceComment,
  incomingRecurringEvent,
};
