import React, { memo, useState } from 'react';
import PropTypes from 'prop-types';
import DownloadIcon from '@mui/icons-material/Download';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { useMediaQuery } from '@mui/material';
import styles from './ExportReportRecap.module.css';
import Color from '../../../../../themes/colors';
import { InputButtonMain } from '../../../../../components/UI_V2/Input/Button';

const ExportReportRecap = () => {
  const isMobile = useMediaQuery('(max-width:720px)');
  return (
    <div className={styles.container}>
      <InputButtonMain icon={<DownloadIcon />} size={isMobile ? 'xs' : 'lg'} variant="success">Unduh</InputButtonMain>
    </div>
  );
};

ExportReportRecap.propTypes = {};

ExportReportRecap.defaultProps = {};

export default memo(ExportReportRecap);
