import React, {
  useState, useContext, useEffect, useCallback,
} from 'react';
import { useHistory, useParams, useLocation } from 'react-router-dom';
import AddIcon from '@mui/icons-material/Add';
import { useSnackbar } from 'notistack';
import ViewKanbanIcon from '@mui/icons-material/ViewKanban';
import queryString from 'query-string';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import FormatListBulletedIcon from '@mui/icons-material/FormatListBulleted';
import ArchiveOutlinedIcon from '@mui/icons-material/ArchiveOutlined';
import FilterAltOutlinedIcon from '@mui/icons-material/FilterAltOutlined';
import { Tooltip } from '@mui/material';
import { GlobalContext } from '../../contexts/GlobalStateProvider';
import styles from './BoardCardCalendarPage.module.css';
import Title from '../../components/Title/Title';
import {
  BoardActions,
  SearchJumpToActions,
  TeamActions,
} from '../../actions';
import { PageConstants } from '../../constants';
import GeneralSubNavBar from '../../components/GeneralSubNavBar/GeneralSubNavBar';
import GlobalActionButton from '../../components/GeneralSubNavBar/BottomNavBar/GlobalActionButton/GlobalActionButton';
import { generateRoutesAndTitle } from '../../actions/BreadCrumbActions';
import CalendarListCards from './CalendarListCards/CalendarListCards';
import { useDelayShowHideHandler } from '../../hooks/HelperHooks';
import ModalCreateCardWithTeamListSelection from '../../components/Container/ModalCreateCardWithTeamListSelection/ModalCreateCardWithTeamListSelection';
import VerticalSeparator from '../../components/GeneralSubNavBar/BottomNavBar/VerticalSeparator/VerticalSeparator';
import ArchivedItemsBar from '../../components/Container/ArchivedItemsBar/ArchivedItemsBar';
import {
  useArchivedBoardCardsHooks,
  useArchivedBoardListsHooks,
  useGetBoardCardViewData, useSetBoardCardView,
} from '../../hooks/BoardHooks';
import LinkNoDecor from '../../components/LinkNoDecor/LinkNoDecor';

const containerWithSidebarStyles = {
  true: `${styles.container} ${styles.sidebarOpen}`,
  false: styles.container,
};

function BoardCardCalendarPage() {
  const [{
    user, currentCompany,
    currentTeam, currentBoard,
    isSidebarOpen,
  }, dispatch] = useContext(GlobalContext);
  /* Show/Hide Filters */
  const [showFilters, handleShowFilters, handleHideFilters] = useDelayShowHideHandler();
  const [isFilterActive,
    handleShowFilterActive, handleHideFilterActive] = useDelayShowHideHandler();
  const [showCreateBoardCard,
    handleShowCreateBoardCard, handleHideCreateBoardCard] = useDelayShowHideHandler();
  const [selectedDueDate,
    setSelectedDueDate] = useState(new Date());
  const [showArchivedCards, setShowArchivedCards] = useState(false);
  const [showArchivedLists, setShowArchivedLists] = useState(false);

  const handleShowArchivedCards = () => {
    setShowArchivedCards(!showArchivedCards);
    setShowArchivedLists(false);
  };

  const handleShowArchivedLists = () => {
    setShowArchivedLists(!showArchivedLists);
    setShowArchivedCards(false);
  };

  const location = useLocation();
  const query = queryString.parse(location.search);
  const { initialCalendarView } = query;
  const history = useHistory();
  const params = useParams();
  const { companyId, boardId, teamId } = params;

  const [
    initiateListArchivedCards,
    loadMoreListArchivedCards,
    unarchiveArchivedCard,
  ] = useArchivedBoardCardsHooks();
  const [
    initiateListArchivedLists,
    loadMoreListArchivedLists,
    unarchiveArchivedList,
  ] = useArchivedBoardListsHooks();

  const [boardCardViewMainValue,
    boardCardViewUrl,
    boardCardViewInitialCalendarViewValue] = useGetBoardCardViewData({
    boardId,
    userId: user?._id,
    companyId,
    teamId,
  });

  useSetBoardCardView({
    user,
    boardId,
    companyId,
    initialCalendarViewValue: boardCardViewInitialCalendarViewValue,
    mainValue: 'calendarAll',
  });

  const pageType = PageConstants.pageType.boardsCalendar;
  const breadcrumb = generateRoutesAndTitle({
    type: pageType,
    data: {
      currentTeam, currentBoard, boardId, companyId,
    },
  });
  SearchJumpToActions.saveRecentPage({
    company: currentCompany,
    team: currentTeam,
    userId: user?._id,
    itemId: `${boardId || currentBoard?._id}-calendar`,
    url: breadcrumb.url,
    routes: breadcrumb.routes,
    name: breadcrumb.title,
    type: pageType,
  });

  return (
    <>
      <Title title={currentTeam.name === undefined ? 'List Tugas' : `List Tugas - ${currentTeam.name} - Kalender`} />
      <GeneralSubNavBar
        breadcrumbRoutes={breadcrumb.routes}
        pageTitle={breadcrumb.title}
        bottomBarOverviewOptionId="boardsCalendar"
        // bottomBarGlobalActions={(
        //   <>
        //     {currentTeam?._id === teamId
        //     && (
        //       <LinkNoDecor
        // to={`/companies/${companyId}/teams/${teamId}/reports/members/${user._id}`}>
        //         <GlobalActionButton
        //           text="Kinerja Saya"
        //           icon={PageConstants.pageProperties.reports.icon}
        //         />
        //       </LinkNoDecor>
        //   )}
        //     <LinkNoDecor
        // to={`/companies/${companyId}/teams/${teamId}/boards/${boardId}/kanban/all`}>
        //       <GlobalActionButton text="View Kanban" icon={<ViewKanbanIcon />} />
        //     </LinkNoDecor>
        //     <LinkNoDecor
        // to={`/companies/${companyId}/teams/${teamId}/boards/${boardId}/list/all`}>
        //       <GlobalActionButton text="View List" icon={<FormatListBulletedIcon />} />
        //     </LinkNoDecor>
        //     <GlobalActionButton
        // buttonMode="active" text="View Kalender" icon={<CalendarMonthIcon />} />
        //   </>
        //   )}
        bottomBarGlobalActions={(
          <>
            <GlobalActionButton
              handleClick={handleShowFilters}
              buttonMode="outlined"
              text="Filter"
              icon={<FilterAltOutlinedIcon />}
            >
              {isFilterActive ? (
                <Tooltip
                  title="Filter-mu aktif. Atur atau hapus filter kalo kamu ga menemukan yang kamu cari."
                >
                  <div className={styles.activeFilterIcon}><CheckCircleIcon /></div>
                </Tooltip>
              ) : null}
            </GlobalActionButton>
            <GlobalActionButton
              handleClick={handleShowArchivedCards}
              buttonMode="outlined"
              text="Arsip Tugas"
              icon={<ArchiveOutlinedIcon />}
            />
            <GlobalActionButton
              handleClick={handleShowArchivedLists}
              buttonMode="outlined"
              text="Arsip List"
              icon={<ArchiveOutlinedIcon />}
            />
          </>
          )}
      />
      <div className={containerWithSidebarStyles[isSidebarOpen]}>
        <CalendarListCards
          showFilters={showFilters}
          handleHideFilters={handleHideFilters}
          handleShowFilterActive={handleShowFilterActive}
          handleHideFilterActive={handleHideFilterActive}
          handleShowCreateBoardCard={handleShowCreateBoardCard}
          handleSetDueDate={setSelectedDueDate}
          initialCalendarView={boardCardViewInitialCalendarViewValue}
        />
        <ModalCreateCardWithTeamListSelection
          open={showCreateBoardCard}
          onClose={handleHideCreateBoardCard}
          initialDueDate={selectedDueDate}
          disableSelectTeam
        />
        {showArchivedCards
              && (
              <ArchivedItemsBar
                topPositionVariant="withBottomBar"
                initiateListDataFunc={initiateListArchivedCards}
                loadMoreListDataFunc={loadMoreListArchivedCards}
                unarchiveDataFunc={unarchiveArchivedCard}
                onClose={handleShowArchivedCards}
                featureType="card"
                barTitle="Arsip Tugas"
              />
              )}
        {showArchivedLists
              && (
              <ArchivedItemsBar
                topPositionVariant="withBottomBar"
                initiateListDataFunc={initiateListArchivedLists}
                loadMoreListDataFunc={loadMoreListArchivedLists}
                unarchiveDataFunc={unarchiveArchivedList}
                onClose={handleShowArchivedLists}
                featureType="list"
                barTitle="Arsip List"
              />
              )}
      </div>
    </>
  );
}

export default BoardCardCalendarPage;
