import React, {
  memo, useState, useEffect, useContext, useCallback,
} from 'react';
import { useLocation, useHistory, useParams } from 'react-router-dom';
import PropTypes from 'prop-types';
import { useSnackbar } from 'notistack';
import OverlayButton from '../../../../components/UI/Button/OverlayButton/OverlayButton';
import { GlobalContext } from '../../../../contexts/GlobalStateProvider';
import { actionTypes } from '../../../../reducers/reducer';
import styles from './ListRecentChatsContainer.module.css';
import ChatPersonItem from '../ChatPersonItem/ChatPersonItem';
import apiUtil from '../../../../utilities/apiUtil';
import handleStatusMsg from '../../../../utilities/handleStatusMsg';
import handleLoadings from '../../../../utilities/handleLoadings';
import { ChatActions } from '../../../../actions';
import { ApiConstants } from '../../../../constants';
import { NOTIF_ACTIVITY_API_URL } from '../../../../constants/ApiConstants';

const ListRecentChatsContainer = ({ filteredRecentChats }) => {
  const [{
    user, currentChatNotifications, currentChatUnreadMessagesCounter,
  }, dispatch] = useContext(GlobalContext);
  const [updatedNotifAndChat, setUpdatedNotifAndChat] = useState();
  const { enqueueSnackbar } = useSnackbar();
  const params = useParams();
  const history = useHistory();

  const { companyId } = params;

  const goToChat = (chatId) => {
    history.push(`/companies/${companyId}/chats/${chatId}`);
  };

  // auto read notif when opening same chatId
  useEffect(() => {
    if (!currentChatNotifications) return;
    if (!params.chatId) return;

    const notif = currentChatNotifications.find(
      (notification) => notification.service.serviceId === params.chatId
      && notification.activities.length > 0,
    );

    if (!notif) return;

    const updateApiNotification = () => {
      try {
        const result = apiUtil.patch(
          ApiConstants.URL_V1.NOTIFICATION({ notificationId: notif._id }), {}, {
            params: {
              companyId,
            },
          },
          NOTIF_ACTIVITY_API_URL,
        );
        // reset unreadMessagesCounter when incoming chat but only on same room chatId
        if (currentChatUnreadMessagesCounter > 0) {
          ChatActions.setCurrentChatUnreadMessagesCounter(0, dispatch);
        }
      } catch (err) {
        const status = handleStatusMsg(err, 'error');

        enqueueSnackbar(status.message, {
          variant: 'error',
        });
      }
    };
    updateApiNotification();
  }, [currentChatNotifications]);

  useEffect(() => {
    if (!updatedNotifAndChat) return;

    const updateApiNotification = () => {
      try {
        const { notificationId, chatId } = updatedNotifAndChat;
        const result = apiUtil.patch(
          ApiConstants.URL_V1.NOTIFICATION({ notificationId }), {}, {
            params: {
              companyId,
            },
          },
          NOTIF_ACTIVITY_API_URL,
        );
        goToChat(chatId);
      } catch (err) {
        const status = handleStatusMsg(err, 'error');

        enqueueSnackbar(status.message, {
          variant: 'error',
        });
      }
    };
    updateApiNotification();
  }, [updatedNotifAndChat]);

  const handleUpdateUnreadMessagesCounter = (counter) => {
    ChatActions.setCurrentChatUnreadMessagesCounter(counter, dispatch);
  };

  const handleClickRecentChats = ({ notificationId, chatId, counter }) => {
    if (counter < 1) {
      if (chatId === params.chatId) return;
      handleUpdateUnreadMessagesCounter(counter);
      goToChat(chatId);
    } else {
      handleUpdateUnreadMessagesCounter(counter);
      setUpdatedNotifAndChat({ notificationId, chatId, counter });
    }
  };

  const countChatCounters = (notification) => {
    if (!notification) return 0;

    let checkId = [];
    let filteredActivities = [];
    if (notification !== undefined) {
      checkId = [user._id];
      filteredActivities = notification.activities.filter(
        (activity) => !activity.readBy.some((readBy) => checkId.includes(readBy.reader)),
      );
    }

    return filteredActivities?.length;
  };

  return (
    <div className={styles.container}>
      {filteredRecentChats.length < 1 ? <span>Chat Terbaru ga ketemu</span> : (
        <>
          {filteredRecentChats.map((chat) => {
            const notif = currentChatNotifications.find(
              (notification) => (notification.service.serviceId === chat._id),
            );
            const counter = countChatCounters(notif);
            return (
              <ChatPersonItem
                photoUrl={chat.members?.[0]?.photoUrl}
                fullName={chat.members?.[0]?.fullName}
                userId={chat.members?.[0]?._id}
                clicked={handleClickRecentChats}
                counter={counter}
                subContent={chat.lastMessage}
                onClickId={{
                  memberId: null,
                  notificationId: notif?._id,
                  chatId: chat._id,
                  counter,
                }}
                selectedChatId={params.chatId}
              />
            );
          })}
        </>
      )}
    </div>
  );
};

ListRecentChatsContainer.propTypes = {
  filteredRecentChats: PropTypes.array.isRequired,
};

export default memo(ListRecentChatsContainer);
