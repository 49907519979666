import React, { useContext, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import FitnessCenterIcon from '@mui/icons-material/FitnessCenter';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import VideoLibraryIcon from '@mui/icons-material/VideoLibrary';
import OneSignal from 'react-onesignal';
import PropTypes from 'prop-types';
import OutsideClickHandler from 'react-outside-click-handler';
import ChatBubbleOutlineOutlinedIcon from '@mui/icons-material/ChatBubbleOutlineOutlined';
import ExitToApp from '@mui/icons-material/ExitToApp';
import Tooltip from '@mui/material/Tooltip';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import useMediaQuery from '@mui/material/useMediaQuery';
import { enqueueSnackbar } from 'notistack';
import styles from './UserMenuContainer.module.css';
import { GlobalContext } from '../../contexts/GlobalStateProvider';
import BoxPopOver from '../../components/UI/BoxPopOver/BoxPopOver';
import FeatureItem from './FeatureItem/FeatureItem';
import Line from '../../components/UI/Line/Line';
import IconItem from './IconItem/IconItem';
import { DisplayBadgeCounter } from '../../components/UI_V2/Display/Badge';
import LinkNoDecor from '../../components/LinkNoDecor/LinkNoDecor';
import Color from '../../themes/colors';
import ImageMember from '../../components/UI/ImageMember/ImageMember';
import { UserActions } from '../../actions';
import { CompanyConstants, PageConstants } from '../../constants';
import { FeedbackSpinnerGeneral } from '../../components/UI_V2/Feedback/Spinner';
import { useLogoutUser } from '../../hooks/UserHooks';
import { InputButtonLogout } from '../../components/UI_V2/Input/Button';
import { elementId } from '../../constants/ElementIdConstants';
import { DisplayTextBody } from '../../components/UI_V2/Display/Text';
import { SurfacePaperFloating } from '../../components/UI_V2/Surface/Paper';

const UserMenuContainer = ({ cancel, left, params }) => {
  const [{
    user,
    counterNotif,
    currentCompany,
  }, dispatch] = useContext(GlobalContext);
  const isMobile = useMediaQuery('(max-width:720px)');
  const { handleLogout, isLoadingLogout } = useLogoutUser();
  const [isOutsideClickDisabled, setIsOutsideClickDisabled] = useState(false);

  const history = useHistory();

  const { companyId } = params;

  const boxCustomStyle = {
    width: '226px',
  };

  const lineCustomStyle = {
    border: '1px solid #EFF5FF',
    marginTop: '3px',
    marginBottom: '3px',
  };

  return (
    <SurfacePaperFloating
      onOutsideClick={cancel}
      disabled={isOutsideClickDisabled}
      customStyle={boxCustomStyle}
      size="sm"
      position="topLeft"
    >
      <div className={styles.container}>
        <div className={styles.iconSection}>
          <Tooltip title="Profil">
            <LinkNoDecor to={companyId ? `/companies/${companyId}/profiles/${user?._id}` : '/'}>
              <div className={`${styles.iconSection__item}`}>
                <IconItem
                  icon={<ImageMember className={styles.profileImage} photoUrl={user?.photoUrl} />}
                />
                {/* <div className={styles.iconSection__item__text}>
                  <DisplayTextBody
                    color={Color.blueNavy4}
                    mode="11"
                  >
                    Profil
                  </DisplayTextBody>
                </div> */}
              </div>
            </LinkNoDecor>
          </Tooltip>
          <div
            id={elementId.navbarAccountChatIcon}
            // className={styles.iconSection__item__margin}
          >
            <LinkNoDecor to={companyId ? `/companies/${companyId}/chats` : '/'}>
              <Tooltip title="Chat Pribadi">
                <div className={styles.iconSection__item} onClick={cancel}>
                  <DisplayBadgeCounter
                    badgeContent={counterNotif.chat}
                    customStyle={{ top: -4, right: -12 }}
                  />
                  <IconItem icon={<ChatBubbleOutlineOutlinedIcon />} />
                </div>
              </Tooltip>
            </LinkNoDecor>
          </div>
          <div onClick={handleLogout} className={`${styles.iconSection__item} ${styles.iconSection__item__margin}`}>
            <InputButtonLogout isLoadingLogout={isLoadingLogout} />
            {/* <div className={styles.iconSection__item__text}>
              <DisplayTextBody
                color={Color.blueNavy4}
                mode="11"
              >
                Logout
              </DisplayTextBody>
            </div> */}
          </div>
        </div>
        <Line customStyle={lineCustomStyle} />
        <div className={styles.listSection}>
          <FeatureItem
            clickUrl={`/companies/${companyId}/billings`}
            icon={PageConstants.pageProperties.billings.icon}
            text="Tagihan & Pembayaran"
          />
          <FeatureItem
            clickUrl={CompanyConstants.cicleTutorialUrl}
            icon={<VideoLibraryIcon />}
            text="Tutorial"
            isOpenNewTab
          />
          <FeatureItem
            clickUrl={isMobile ? CompanyConstants.cicleContactUrlMobile
              : CompanyConstants.cicleContactUrlWeb}
            icon={<WhatsAppIcon />}
            text="Kontak"
            isOpenNewTab
          />
        </div>
      </div>
    </SurfacePaperFloating>
  );
};

UserMenuContainer.propTypes = {
  left: PropTypes.bool.isRequired,
  cancel: PropTypes.func.isRequired,
  params: PropTypes.object,
};

UserMenuContainer.defaultProps = {
  params: { companyId: null, teamId: null },
};

export default UserMenuContainer;
