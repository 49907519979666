import React, { memo, useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import styles from './SearchSection.module.css';
import SearchJumpToContainer from '../../../pages/SearchJumpToContainer/SearchJumpToContainer';
import ModalSearch from './ModalSearch/ModalSearch';
import { InputSearchGeneral } from '../../UI_V2/Input/Search';
import { useDelayShowHideHandler, useEventListener } from '../../../hooks/HelperHooks';

const SearchSection = ({ params }) => {
  const [isOpenSearch, handleOpenSearch, handleHideSearch] = useDelayShowHideHandler(false);

  const handleKeyPressed = useCallback(({ ctrlKey, key }) => {
    if (ctrlKey && key === '/') {
      if (isOpenSearch) {
        handleHideSearch();
      } else {
        handleOpenSearch();
      }
    }
  }, [isOpenSearch]);

  useEventListener('keydown', (event) => handleKeyPressed(event));

  return (
    <div className={styles.container}>
      <InputSearchGeneral
        onClick={handleOpenSearch}
        value=""
        placeholder="Cari apapun disini..."
        size="sm"
        autoFocus={false}
      />
      {isOpenSearch && (
        <ModalSearch
          params={params}
          open={isOpenSearch}
          onClose={handleHideSearch}
        />
      )}
    </div>
  );
};

SearchSection.propTypes = {
  params: PropTypes.func,
};

SearchSection.defaultProps = {
  params: { companyId: null, teamId: null },
};

export default memo(SearchSection);
