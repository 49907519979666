import React, { useState, useContext, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import axios from 'axios';
import Modal from 'react-bootstrap/Modal';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import { useSnackbar } from 'notistack';
import Button from '../../../../components/UI/Button/Button';
import styles from './CreateCompanyModal.module.css';
import { actionTypes } from '../../../../reducers/reducer';
import { GlobalContext } from '../../../../contexts/GlobalStateProvider';
import handleStatusMsg from '../../../../utilities/handleStatusMsg';
import handleLoadings from '../../../../utilities/handleLoadings';
import { CompanyActions } from '../../../../actions';

const CreateCompanyModal = ({ show, onHide }) => {
  const [{
    user,
    currentCompanies,
  }, dispatch] = useContext(GlobalContext);
  const [newCompany, setNewCompany] = useState({
    name: '',
    desc: '',
  });
  const { enqueueSnackbar } = useSnackbar();
  const [postCompany, setPostCompany] = useState();
  const [loadings, setLoadings] = useState([]);
  const history = useHistory();

  const handleChanged = (event) => {
    const { name, value } = event.target;
    setNewCompany((prevValue) => ({
      ...prevValue,
      [name]: value,
    }));
  };

  useEffect(() => {
    const postNewCompany = async () => {
      if (postCompany !== undefined) {
        try {
          const startLoadings = handleLoadings('createCompany', [...loadings], 'start');
          setLoadings([...startLoadings]);

          const result = await CompanyActions.createCompanyAndUpdateCompanies({
            payload: {
              name: postCompany.name,
              desc: postCompany.desc,
            },
          }, dispatch);

          const companyId = result?.data?.newCompany?._id;

          CompanyActions.modifyShowWelcomeTutorialAndTrialMessage({
            userId: user?._id,
            companyId,
          });

          const endLoadings = handleLoadings('createCompany', [...loadings], 'end');
          setLoadings([...endLoadings]);

          const status = handleStatusMsg(result, 'success');

          enqueueSnackbar(status.message, {
            variant: 'success',
          });

          history.push(`/companies/${companyId}`);

          onHide();
        } catch (err) {
          const status = handleStatusMsg(err, 'error');

          enqueueSnackbar(status.message, {
            variant: 'error',
          });
        }
      }
    };
    postNewCompany();
  }, [postCompany]);

  const handleClicked = () => {
    setPostCompany(newCompany);
  };

  return (
    <Modal size="lg" show={show} onHide={onHide}>
      <Modal.Header closeButton>
        <img className={styles.ImageHeader} src="https://a.trellocdn.com/prgb/dist/images/organization/empty-board.286f8fc83e01c93ed27e.svg" />
      </Modal.Header>
      <Modal.Body>
        <Row>
          <Col className={styles.titleSection}>
            <h1>Let&apos;s Build a Company</h1>
            <p>Access your company remotely in one location.</p>
          </Col>
        </Row>
        <Row>
          <Col className={styles.formSection}>
            <Form.Group controlId="companyName">
              <Form.Label>Company Name</Form.Label>
              <Form.Control autoFocus onChange={(event) => handleChanged(event)} as="input" name="name" placeholder="Dream Co." />
              <Form.Text className="text-muted">
                You can input your company or organization name.
              </Form.Text>
            </Form.Group>
            <Form.Group controlId="companyDesc">
              <Form.Label>Company Description</Form.Label>
              <Form.Control onChange={(event) => handleChanged(event)} as="textarea" rows="3" name="desc" placeholder="Enter your company description." />
            </Form.Group>
          </Col>
        </Row>
        <Row>
          <Col className={styles.continueSection}>
            <Button handleClick={handleClicked} variant="success" size="sm" loadings={loadings} wait="createCompany" block>Create</Button>
          </Col>
        </Row>
      </Modal.Body>
    </Modal>
  );
};

CreateCompanyModal.propTypes = {
  show: PropTypes.bool.isRequired,
  onHide: PropTypes.func.isRequired,
};

export default CreateCompanyModal;
