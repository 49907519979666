import React, { memo, useState } from 'react';
import PropTypes from 'prop-types';
import { Box } from '@mui/material';
import CheckIcon from '@mui/icons-material/Check';
import { isEmpty } from 'lodash';
import styles from './MetricCategoryFilterSection.module.css';
import ButtonDiv from '../../../UI/Button/ButtonDiv/ButtonDiv';
import ButtonTextWithCheck from '../ButtonTextWithCheck/ButtonTextWithCheck';

const MetricCategoryFilterSection = ({ checkMetricCategory, handleClickMetricCategory }) => (
  <Box className={styles.container}>
    <ButtonTextWithCheck
      text="Visitor"
      onClick={() => handleClickMetricCategory('visitor')}
      checked={checkMetricCategory === 'visitor'}
    />
    <ButtonTextWithCheck
      text="Acquisition"
      onClick={() => handleClickMetricCategory('acquisition')}
      checked={checkMetricCategory === 'acquisition'}
    />
    <ButtonTextWithCheck
      text="Activation"
      onClick={() => handleClickMetricCategory('activation')}
      checked={checkMetricCategory === 'activation'}
    />
    <ButtonTextWithCheck
      text="Retention"
      onClick={() => handleClickMetricCategory('retention')}
      checked={checkMetricCategory === 'retention'}
    />
    <ButtonTextWithCheck
      text="Revenue"
      onClick={() => handleClickMetricCategory('revenue')}
      checked={checkMetricCategory === 'revenue'}
    />
    <ButtonTextWithCheck
      text="Referral"
      onClick={() => handleClickMetricCategory('referral')}
      checked={checkMetricCategory === 'referral'}
    />
    <ButtonTextWithCheck
      text="Churn"
      onClick={() => handleClickMetricCategory('churn')}
      checked={checkMetricCategory === 'churn'}
    />
    <ButtonTextWithCheck
      text="All"
      onClick={() => handleClickMetricCategory(undefined)}
      checked={checkMetricCategory === undefined}
    />
  </Box>
);

MetricCategoryFilterSection.propTypes = {
  checkMetricCategory: PropTypes.object.isRequired,
  handleClickMetricCategory: PropTypes.func.isRequired,
};

export default memo(MetricCategoryFilterSection);
