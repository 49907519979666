import React, { memo, useState } from 'react';
import PropTypes from 'prop-types';
import DownloadIcon from '@mui/icons-material/Download';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { useMediaQuery } from '@mui/material';
import { useSnackbar } from 'notistack';
import styles from './CreateListForm.module.css';
import Color from '../../../../themes/colors';
import { InputButtonMain } from '../../../../components/UI_V2/Input/Button';
import SeparatorLine from '../../../../components/UI/SeparatorLine/SeparatorLine';
import ButtonDiv from '../../../../components/UI/Button/ButtonDiv/ButtonDiv';
import { DisplayTextBody } from '../../../../components/UI_V2/Display/Text';
import { InputTextFieldMain } from '../../../../components/UI_V2/Input/TextField';
import handleStatusMsg from '../../../../utilities/handleStatusMsg';
import handleLoadings from '../../../../utilities/handleLoadings';
import { ListActions } from '../../../../actions';

const CreateListForm = ({
  onClose,
  companyId,
  teamId,
  boardId,
}) => {
  const isMobile = useMediaQuery('(max-width:720px)');
  const { enqueueSnackbar } = useSnackbar();
  const [loadings, setLoadings] = useState([]);
  const [newList, setNewList] = useState({
    name: '',
  });
  const clearValue = () => {
    setNewList({
      name: '',
    });
  };
  const handleChanged = (event) => {
    const { name, value } = event.target;

    setNewList((prevValue) => ({
      ...prevValue,
      [name]: value,
    }));
  };

  const createListApi = async () => {
    try {
      const startLoadings = handleLoadings('createList', [...loadings], 'start');
      setLoadings([...startLoadings]);

      if (!newList.name || newList.name.length < 1) {
        enqueueSnackbar('Team name required', {
          variant: 'error',
        });
        return;
      }
      const body = { selector: { boardId }, data: { name: newList.name } };
      const result = await ListActions.createList(
        {
          body, companyId, teamId,
        },
      );

      const status = handleStatusMsg(result, 'success');

      enqueueSnackbar(status.message, {
        variant: 'success',
      });

      clearValue();
      onClose();
    } catch (err) {
      const status = handleStatusMsg(err, 'error');

      enqueueSnackbar(status.message, {
        variant: 'error',
      });
    } finally {
      const endLoadings = handleLoadings('createList', [...loadings], 'end');
      setLoadings([...endLoadings]);
    }
  };
  return (
    <div className={styles.container}>
      <div className={styles.ManageMoveCard__Header}>
        <h1 className="mr-auto">
          Buat List
        </h1>
      </div>
      <SeparatorLine />
      <div className={styles.body}>
        <p className={styles.ManageMoveCard__smallTitle}>NAMA LIST</p>
        <div className={styles.formSection}>
          <div className={styles.form}>
            <div className={styles.formArea}>
              <InputTextFieldMain
                placeholder="Backlog, Dikerjakan, Selesai, dll"
                name="name"
                autoFocus
                onChange={(event) => handleChanged(event)}
                value={newList.name}
              />
            </div>
          </div>
        </div>
      </div>
      <div className={styles.footer}>
        <InputButtonMain handleClick={createListApi} variant="success" size="sm" wait="createList" loadings={loadings}>Buat List</InputButtonMain>
        <InputButtonMain handleClick={onClose} variant="light" size="sm">Kembali</InputButtonMain>
      </div>
    </div>
  );
};

CreateListForm.propTypes = {
  onClose: PropTypes.func.isRequired,
  companyId: PropTypes.string.isRequired,
  teamId: PropTypes.string.isRequired,
  boardId: PropTypes.string.isRequired,
};

CreateListForm.defaultProps = {};

export default memo(CreateListForm);
