import React, { useState, useContext, useEffect } from 'react';
import Button from 'react-bootstrap/Button';
import { useHistory, useParams, useLocation } from 'react-router-dom';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import Title from '../../components/Title/Title';
import MainLayout from '../../components/Layout/MainLayout/MainLayout';
import styles from './ProfilePopUpContainer.module.css';
import { actionTypes } from '../../reducers/reducer';
import { GlobalContext } from '../../contexts/GlobalStateProvider';
import Paper from '../../components/UI/Paper/Paper';
import ProfilePhotoContainer from './ProfilePhotoContainer/ProfilePhotoContainer';
import EditProfileContainer from './EditProfileContainer/EditProfileContainer';
import handleStatusMsg from '../../utilities/handleStatusMsg';
import handleLoadings from '../../utilities/handleLoadings';
import PageLoading from '../../components/UI/Button/PageLoading/PageLoading';
import GeneralSubNavBar from '../../components/GeneralSubNavBar/GeneralSubNavBar';
import GlobalActionButton from '../../components/GeneralSubNavBar/BottomNavBar/GlobalActionButton/GlobalActionButton';
import { generateRoutesAndTitle } from '../../actions/BreadCrumbActions';
import { UserActions, SearchJumpToActions } from '../../actions';
import { PageConstants } from '../../constants';
import BoxTextSingle from './BoxTextSingle/BoxTextSingle';
import BoxTextMultiline from './BoxTextMultiline/BoxTextMultiline';
import { InputButtonMain } from '../../components/UI_V2/Input/Button';
import { DisplayAvatarMemberWithOnline } from '../../components/UI_V2/Display/Avatar';
import SensitiveInfoSection from './SensitiveInfoSection/SensitiveInfoSection';
import { useDelayShowHideHandler } from '../../hooks/HelperHooks';
import ChangePasswordContainer from './SensitiveInfoSection/ChangePasswordContainer/ChangePasswordContainer';
import { DisplayTextHeadline } from '../../components/UI_V2/Display/Text';
import ChangeEmailContainer from './SensitiveInfoSection/ChangeEmailContainer/ChangeEmailContainer';
import AddNewPasswordContainer from './SensitiveInfoSection/AddNewPasswordContainer/AddNewPasswordContainer';

const containerWithSidebarStyles = {
  true: `${styles.container} ${styles.sidebarOpen}`,
  false: styles.container,
};

const ProfilePopUpContainer = () => {
  const [{ user, currentCompany, isSidebarOpen }, dispatch] = useContext(GlobalContext);
  const [showEditProfile, setShowEditProfile] = useState(false);
  const [
    showChangePassword,
    handleShowChangePassword,
    handleHideChangePassword,
  ] = useDelayShowHideHandler();
  const [
    showChangeEmail,
    handleShowChangeEmail,
    handleHideChangeEmail,
  ] = useDelayShowHideHandler();
  const [
    showAddNewPassword,
    handleShowAddNewPassword,
    handleHideAddNewPassword,
  ] = useDelayShowHideHandler();
  const [profileUser, setProfileuser] = useState();
  const [photoSource, setPhotoSource] = useState('');
  const [loadings, setLoadings] = useState([]);
  const params = useParams();
  const history = useHistory();
  const location = useLocation();
  const { companyId, profileId } = params;

  const isThereAnyEditActive = showEditProfile || showChangePassword
    || showChangeEmail || showAddNewPassword;
  const isTheSameUser = profileUser?._id === user?._id;

  useEffect(() => {
    if (user._id === undefined) {
      history.push({
        pathname: '/check-login',
        state: {
          from: location.pathname,
        },
      });
      return;
    }

    const startLoadings = handleLoadings('profilePage', [...loadings], 'start');
    setLoadings([...startLoadings]);

    const fetchUser = async () => {
      try {
        const result = await UserActions.getUser({ userId: profileId });

        setProfileuser(result?.data?.user);
      } catch (err) {
        const status = handleStatusMsg(err, 'error');

        dispatch({
          type: actionTypes.SET_ERROR_RESPONSE,
          errorResponse: { message: status.message },
        });

        history.push('/errors');
      } finally {
        const endLoadings = handleLoadings('profilePage', [...loadings], 'end');
        setLoadings([...endLoadings]);
      }
    };
    fetchUser();
  }, [location]);

  const handleShowEditProfile = () => {
    setShowEditProfile(true);
  };

  const handleCancelShowEditProfile = () => {
    setShowEditProfile(false);
  };

  useEffect(() => {
    if (profileUser === undefined) return;

    if (profileUser.photoUrl) {
      if (profileUser.photoUrl?.charAt(0) === '/') {
        setPhotoSource(process.env.REACT_APP_PRIMARY_API_URL + profileUser.photoUrl);
      } else {
        setPhotoSource(profileUser.photoUrl);
      }
    }
  }, [profileUser]);
  const pageType = PageConstants.pageType.profiles;
  const breadcrumb = generateRoutesAndTitle(
    { type: pageType, data: { companyId, profileUser } },
  );
  SearchJumpToActions.saveRecentPage({
    company: currentCompany,
    userId: user?._id,
    itemId: `${profileId}-profiles`,
    url: breadcrumb.url,
    routes: breadcrumb.routes,
    name: breadcrumb.title,
    type: pageType,
  });
  return (
    <>
      <Title title={profileUser === undefined ? 'Profil' : profileUser.fullName} />
      <GeneralSubNavBar
        breadcrumbRoutes={breadcrumb.routes}
        pageTitle={breadcrumb.title}
        withBottomNavBar={false}
        withMiddleNavBar={false}
      />
      <PageLoading wait="profilePage" loadings={loadings}>
        {profileUser
          ? (
            <div className={containerWithSidebarStyles[isSidebarOpen]}>
              {(!isThereAnyEditActive || (isThereAnyEditActive && showEditProfile)) && (
              <div className={styles.photoSection}>
                {isTheSameUser
                  ? <ProfilePhotoContainer profileUser={profileUser} />
                  : (
                    <DisplayAvatarMemberWithOnline
                      userId={profileUser._id}
                      src={photoSource}
                      size="super"
                      enableOnClickCustom
                      onClick={() => null}
                    />
                  )}
              </div>
              )}
              {isThereAnyEditActive && !showEditProfile && (
              <div className={styles.titleSectionEdit}>
                <DisplayTextHeadline decoration="bold">
                  {showChangePassword && 'Ubah Password'}
                  {showChangeEmail && 'Ubah Email'}
                  {showAddNewPassword && 'Tambahkan Password'}
                </DisplayTextHeadline>
              </div>
              )}
              <div className={styles.bioSection}>
                {showEditProfile && (
                <EditProfileContainer
                  profileUser={profileUser}
                  cancel={handleCancelShowEditProfile}
                />
                )}
                {showChangePassword && (
                  <ChangePasswordContainer
                    handleHideChangePassword={handleHideChangePassword}
                    emailUser={profileUser.email}
                  />
                )}
                {showChangeEmail && (
                  <ChangeEmailContainer
                    handleHideChangeEmail={handleHideChangeEmail}
                    emailUserBefore={profileUser.email}
                  />
                )}
                {showAddNewPassword && (
                <AddNewPasswordContainer
                  handleHideAddNewPassword={handleHideAddNewPassword}
                  emailUser={profileUser.email}
                />
                )}
                {!isThereAnyEditActive && (
                  <>
                    <div className={styles.profileText}>
                      <BoxTextSingle
                        variant="subheadline"
                        text={profileUser.fullName}
                      />
                    </div>
                    <div className={styles.profileText}>
                      <BoxTextSingle
                        variant="body"
                        text={profileUser.status}
                      />
                    </div>
                    <div className={styles.descText}>
                      <BoxTextMultiline
                        text={profileUser.bio}
                      />
                    </div>
                  </>
                )}
              </div>
              {isTheSameUser && !isThereAnyEditActive && (
                <div className={styles.action}>
                  <InputButtonMain handleClick={handleShowEditProfile} border="rounded" wide="wider" size="md" block>Ubah Profil</InputButtonMain>
                </div>
              )}

              {isTheSameUser && !isThereAnyEditActive && (
                <div className={styles.sensitiveInfoSection}>
                  <SensitiveInfoSection
                    email={profileUser.email}
                    isTheSameUser={isTheSameUser}
                    profileUserId={profileUser?._id}
                    handleShowChangePassword={handleShowChangePassword}
                    handleShowChangeEmail={handleShowChangeEmail}
                    handleShowAddNewPassword={handleShowAddNewPassword}
                    // hasPassword
                  />
                </div>
              )}
            </div>
          )
          : null}
      </PageLoading>
    </>
  );
};

export default ProfilePopUpContainer;
