import { cloneDeep, isArray, isNumber } from 'lodash';
import apiUtil, { getNextPage } from '../utilities/apiUtil';
import { actionTypes } from '../reducers/reducer';
import { ErrorException } from '../utilities/handleError';
import { ApiConstants, TeamFavoriteConstants } from '../constants';
import { mergeObjectListAndRemoveDuplicate, updateListProperty, updateListSocket } from '../utilities/arrayUtil';
import { initialState } from '../contexts/GlobalStateProvider';
import { level, typeRef } from '../constants/RoleConstants';
import { TeamActions } from '.';

/*
  Dispatcher
*/

function dispatchPreviousTeamFavorites({ previousTeamFavorites }, dispatch) {
  dispatch({
    type: actionTypes.SET_PREVIOUS_TEAM_FAVORITES,
    previousTeamFavorites,
  });
}

function dispatchCurrentTeamFavorites({ currentTeamFavorites }, dispatch) {
  dispatch({
    type: actionTypes.SET_CURRENT_TEAM_FAVORITES,
    currentTeamFavorites,
  });
}

function dispatchUpdateTeamFavorites({ updateTeamFavorites }, dispatch) {
  dispatch({
    type: actionTypes.UPDATE_CURRENT_TEAM_FAVORITES,
    updateTeamFavorites,
  });
}

function dispatchIsFavoriteListEmpty({ isFavoriteListEmpty }, dispatch) {
  dispatch({
    type: actionTypes.SET_IS_FAVORITE_LIST_EMPTY,
    isFavoriteListEmpty,
  });
}

/*
  SetterDispatcher
*/

function setPreviousTeamFavorites({ previousTeamFavorites }, dispatch) {
  if (!previousTeamFavorites) return;

  dispatchPreviousTeamFavorites(
    { previousTeamFavorites: cloneDeep(previousTeamFavorites) }, dispatch,
  );
}

function setCurrentTeamFavorites({ currentTeamFavorites }, dispatch) {
  if (!currentTeamFavorites) return;
  dispatchCurrentTeamFavorites(
    { currentTeamFavorites: cloneDeep(currentTeamFavorites) }, dispatch,
  );
}

function setIsFavoriteListEmpty({ isFavoriteListEmpty }, dispatch) {
  dispatchIsFavoriteListEmpty(
    { isFavoriteListEmpty: cloneDeep(isFavoriteListEmpty) }, dispatch,
  );
}

/*
  Method
*/

async function initiateTeamFavorites({
  page = 1, limit = TeamFavoriteConstants.limitTeamFavorites,
  companyId,
  userId,
}, dispatch) {
  try {
    const result = await apiUtil.get(ApiConstants.URL_V1.TEAM_FAVORITES({
      userId,
      companyId,
    }), {
      params: {
        page,
        limit,
      },
    });

    setCurrentTeamFavorites({ currentTeamFavorites: result?.data }, dispatch);
    setPreviousTeamFavorites({
      previousTeamFavorites: initialState.previousTeamFavorites,
    }, dispatch);

    return result;
  } catch (error) {
    throw new ErrorException(error);
  }
}

async function loadMoreTeamFavorites({
  currentTeamFavorites,
  limit = TeamFavoriteConstants.limitTeamFavorites,
  companyId,
  userId,
}, dispatch) {
  try {
    const page = getNextPage({
      data: currentTeamFavorites.data,
      limitPerPage: limit,
    });

    const result = await apiUtil.get(ApiConstants.URL_V1.TEAM_FAVORITES({
      userId,
      companyId,
    }), {
      params: {
        page,
        limit,
      },
    });

    const mergedDataTeamFavorites = mergeObjectListAndRemoveDuplicate({
      currentObjectList: currentTeamFavorites,
      nextObjectList: result?.data,
      keyObject: 'data',
    });

    const mergedTeamFavorites = {
      ...result?.data,
      data: mergedDataTeamFavorites?.data,
    };

    setPreviousTeamFavorites({ previousTeamFavorites: result?.data }, dispatch);
    setCurrentTeamFavorites({ currentTeamFavorites: mergedTeamFavorites }, dispatch);

    return result;
  } catch (error) {
    throw new ErrorException(error);
  }
}

async function setTeamAsFavorite({ userId, companyId, teamId }) {
  try {
    const result = await apiUtil.post(ApiConstants.URL_V1.SET_TEAM_FAVORITE(
      { userId, companyId, teamId },
    ), {}, {
      params: {
      },
    });

    return result;
  } catch (error) {
    throw new ErrorException(error);
  }
}

async function unsetTeamFromFavorite({ userId, companyId, teamId }) {
  try {
    const result = await apiUtil.post(ApiConstants.URL_V1.UNSET_TEAM_FAVORITE(
      { userId, companyId, teamId },
    ), {}, {
      params: {
      },
    });

    return result;
  } catch (error) {
    throw new ErrorException(error);
  }
}

async function reorderTeamFavoritePosition({
  userId, companyId, teamId,
  position,
}) {
  try {
    const result = await apiUtil.post(ApiConstants.URL_V1.REORDER_TEAM_FAVORITE(
      { userId, companyId, teamId },
    ), { position }, {
      params: {
      },
    });
  } catch (error) {
    throw new ErrorException(error);
  }
}

function incomingTeamFavorites({
  teamId, typeAction, keyProperty = 'data',
}, dispatch) {
  if (!teamId) return;

  const updateTeams = (teams) => {
    const team = teams.find((elem) => elem._id === teamId);
    if (!team) return teams;

    const updateTeamFavorites = (currentTeamFavorites) => {
      const isDataEmpty = currentTeamFavorites.data.length < 1;
      const isDataIsOneMoreAndToBeDeleted = currentTeamFavorites?.data?.length === 1
        && typeAction === TeamFavoriteConstants.typeCallback.DELETE
        && currentTeamFavorites?.data?.[0]?._id === teamId;

      if (isDataEmpty && typeAction === TeamFavoriteConstants.typeCallback.NEW) {
        setIsFavoriteListEmpty({ isFavoriteListEmpty: false }, dispatch);
        return {
          data: [team],
        };
      }

      if (isDataIsOneMoreAndToBeDeleted) {
        setIsFavoriteListEmpty({ isFavoriteListEmpty: true }, dispatch);
      }

      return updateListProperty({
        keyProperty,
        newData: team,
        currentList: currentTeamFavorites,
        typeAction,
      });
    };

    dispatchUpdateTeamFavorites({ updateTeamFavorites }, dispatch);

    return teams;
  };

  TeamActions.dispatchUpdateTeams({ updateTeams }, dispatch);
}

function incomingTeamFavoriteMove({
  teamId, position,
}, dispatch) {
  if (!teamId) return;

  const updateTeamFavorites = (currentTeamFavorites) => {
    const team = currentTeamFavorites.data.find((elem) => elem._id === teamId);

    if (!team) return currentTeamFavorites;

    const removedTeams = updateListSocket({
      newData: team,
      currentList: currentTeamFavorites.data,
      typeAction: TeamFavoriteConstants.typeCallback.DELETE,
    });

    const addedTeams = updateListSocket({
      newData: team,
      currentList: removedTeams,
      typeAction: TeamFavoriteConstants.typeCallback.NEW,
      destIndex: position,
    });

    const newCurrentTeamFavorites = {
      ...currentTeamFavorites,
      data: addedTeams,
    };

    return newCurrentTeamFavorites;
  };

  dispatchUpdateTeamFavorites({ updateTeamFavorites }, dispatch);
}

export {
  initiateTeamFavorites,
  loadMoreTeamFavorites,
  setPreviousTeamFavorites,
  setCurrentTeamFavorites,
  setTeamAsFavorite,
  unsetTeamFromFavorite,
  incomingTeamFavorites,
  reorderTeamFavoritePosition,
  incomingTeamFavoriteMove,
  setIsFavoriteListEmpty,
};
