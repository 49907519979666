import React, { memo, useState } from 'react';
import PropTypes from 'prop-types';
import SortIcon from '@mui/icons-material/Sort';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { useMediaQuery } from '@mui/material';
import styles from './CardListColumnBox.module.css';
import Color from '../../../themes/colors';
import { DisplayTextBody } from '../../UI_V2/Display/Text';

const CardListColumnBox = ({
  onClick,
  children,
  widthPercentage,
  variantBorder,
  variantCursor,
}) => {
  const widthStyles = {
    width: `${widthPercentage}%`,
  };

  const cursorStyles = {
    pointer: {
      cursor: 'pointer',
    },
    default: {
      default: 'default',
    },
  };

  const variantBorderStyles = {
    noLeftBorder: styles.noLeftBorder,
    noRightBorder: styles.noRightBorder,
    noLeftRightBorder: styles.noLeftRightBorder,
    noTopLeftBorder: styles.noTopLeftBorder,
    noTopRightBorder: styles.noTopRightBorder,
    noTopLeftRightBorder: styles.noTopLeftRightBorder,
  };

  return (
    <div style={{ ...widthStyles, ...cursorStyles[variantCursor] }} className={`${styles.container} ${variantBorderStyles[variantBorder]}`} onClick={onClick}>
      {children}
    </div>
  );
};

CardListColumnBox.propTypes = {
  onClick: PropTypes.func,
  children: PropTypes.node,
  widthPercentage: PropTypes.number,
  variantBorder: PropTypes.oneOf(['noLeftBorder', 'noRightBorder', 'noLeftRightBorder', 'noTopLeftBorder', 'noTopRightBorder', 'noTopLeftRightBorder']),
  variantCursor: PropTypes.oneOf(['pointer', 'default']),
};

CardListColumnBox.defaultProps = {
  onClick: () => null,
  children: <></>,
  widthPercentage: 100,
  variantBorder: 'noLeftBorder',
  variantCursor: 'default',
};

export default memo(CardListColumnBox);
