import React, { memo, useState } from 'react';
import PropTypes from 'prop-types';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import styles from './RatingAverageSection.module.css';
import Color from '../../../../themes/colors';
import ChartMemberUserReport from '../../DataRealtimeReport/ChartUserReport/ChartMemberUserReport/ChartMemberUserReport';

const RatingAverageSection = ({
  member,
  fromDate,
  untilDate,
  rating,
  isEmptyData,
  isLoading,
}) => (
  <div className={styles.container}>
    <ChartMemberUserReport
      member={member}
      fromDate={fromDate}
      untilDate={untilDate}
      rating={rating}
      isEmptyData={isEmptyData}
      isLoading={isLoading}
      title="Rating Final"
    />
  </div>
);

RatingAverageSection.propTypes = {
  member: PropTypes.object.isRequired,
  fromDate: PropTypes.object.isRequired,
  untilDate: PropTypes.object.isRequired,
  rating: PropTypes.string.isRequired,
  isEmptyData: PropTypes.bool.isRequired,
  isLoading: PropTypes.bool.isRequired,
};

RatingAverageSection.defaultProps = {};

export default memo(RatingAverageSection);
