import React, {
  useState, useContext, useEffect, forwardRef, useCallback,
} from 'react';
import _, { initial } from 'lodash';
import {
  useParams, useLocation,
} from 'react-router-dom';
import ReplyIcon from '@mui/icons-material/Reply';
import draftToHtml from 'draftjs-to-html';
import PropTypes from 'prop-types';
import Highlighter from 'react-highlight-words';
import ImageMember from '../../../../components/UI/ImageMember/ImageMember';
import { GlobalContext } from '../../../../contexts/GlobalStateProvider';
import styles from './CommentItem.module.css';
import CommentAction from '../CommentAction/CommentAction';
import EditCommentForm from '../../EditCommentForm/EditCommentForm';
import checkIsJson from '../../../../utilities/checkIsJson';
import EditorView from '../../../FroalaEditor/EditorView/EditorView';
import LinkNoDecor from '../../../../components/LinkNoDecor/LinkNoDecor';
import CheersContainer from '../../../CheersContainer/CheersContainer';
import { DisplayDateBox } from '../../../../components/UI_V2/Display/Date';
import Color from '../../../../themes/colors';
import { DisplayTextBody, DisplayTextSubBody } from '../../../../components/UI_V2/Display/Text';
import CommentMenu from './CommentMenu/CommentMenu';
import { InputButtonMore } from '../../../../components/UI_V2/Input/Button';
import { useDelayShowHideHandler } from '../../../../hooks/HelperHooks';
import { DisplayAvatarMember, DisplayAvatarMemberWithOnline } from '../../../../components/UI_V2/Display/Avatar';
import { getPureStringWithLimit, limitChar } from '../../../../utilities/stringUtil';
import SeenInfo from '../../../../components/Container/SeenModal/SeenInfo/SeenInfo';
import { DisplayIconMessageSent } from '../../../../components/UI_V2/Display/Icon';

const CommentItem = forwardRef(({
  commentIndex, comment, type, parentIds, hideReply, isToday,
  handleClickDeleteComment,
  handleClickShowSeenModal,
  disabledOutsideClickHandlerMenu,
  limitContent,
  hideCheers,
  hideCommentOption,
  withHighlighter,
  keywordArray,
  socket,
  parentTeamId,
}, ref) => {
  const [{ user }] = useContext(GlobalContext);
  const [
    showCommentMenu,
    handleShowCommentMenu,
    handleCancelCommentMenu,
  ] = useDelayShowHideHandler();
  const [showEditForm, setShowEditForm] = useState();
  // const [showCommentMenu, setShowCommentMenu] = useState();
  const [commentUrl, setCommentUrl] = useState('#');
  const [lastDiscussions, setLastDiscussions] = useState({ users: [], date: Date.now() });
  const [isInitialContentReady, setIsInitialContentReady] = useState(false);
  const [editorModel, setEditorModel] = useState();

  const isUserCreator = comment?.creator?._id === user?._id;

  const params = useParams();
  const location = useLocation();

  const isLocationMyTaskListAll = location.pathname.includes('/my-tasks/list/all');
  const isLocationMyTaskCalendarAll = location.pathname.includes('/my-tasks/calendar/all');
  const isLocationBoard = !isLocationMyTaskCalendarAll && !isLocationMyTaskListAll;

  const {
    companyId,
  } = params;

  const teamId = params.teamId || parentTeamId;

  useEffect(() => {
    if (_.isEmpty(comment)) {
      return;
    }

    let initialContent;
    if (checkIsJson(comment.content)) {
      initialContent = draftToHtml(JSON.parse(comment.content));
    } else {
      initialContent = comment.content;
    }

    if (limitContent) {
      initialContent = limitChar(initialContent, limitContent, true);
    }

    setEditorModel(initialContent);
    setIsInitialContentReady(true);

    // set commentUrl
    const ids = [];
    switch (type) {
      case 'question':
        ids[0] = params.questionId || parentIds[0];
        setCommentUrl(`/companies/${companyId}/teams/${teamId}/questions/${ids[0]}/comments/${comment._id}?commentIndex=${commentIndex}`);
        break;
      case 'event':
        ids[0] = params.eventId || parentIds[0];
        setCommentUrl(`/companies/${companyId}/teams/${teamId}/events/${ids[0]}/comments/${comment._id}?commentIndex=${commentIndex}`);
        break;
      case 'occurrence':
        ids[0] = params.eventId || parentIds[0];
        ids[1] = params.occurrenceId || parentIds[1];
        setCommentUrl(`/companies/${companyId}/teams/${teamId}/events/${ids[0]}/occurrences/${ids[1]}/comments/${comment._id}?commentIndex=${commentIndex}`);
        break;
      case 'blast':
        ids[0] = params.postId || parentIds[0];
        setCommentUrl(`/companies/${companyId}/teams/${teamId}/posts/${ids[0]}/comments/${comment._id}?commentIndex=${commentIndex}`);
        break;
      case 'card':
        ids[0] = params.cardId || parentIds[0];
        if (isLocationMyTaskCalendarAll) {
          setCommentUrl(`/companies/${companyId}/my-tasks/calendar/all/teams/${teamId}/cards/${ids[0]}/comments/${comment._id}?commentIndex=${commentIndex}`);
        }
        if (isLocationMyTaskListAll) {
          setCommentUrl(`/companies/${companyId}/my-tasks/list/all/teams/${teamId}/cards/${ids[0]}/comments/${comment._id}?commentIndex=${commentIndex}`);
        }
        if (isLocationBoard) {
          setCommentUrl(`/companies/${companyId}/teams/${teamId}/cards/${ids[0]}/comments/${comment._id}?commentIndex=${commentIndex}`);
        }
        break;
      case 'doc':
        ids[0] = params.docId || parentIds[0];
        setCommentUrl(`/companies/${companyId}/teams/${teamId}/docs/${ids[0]}/comments/${comment._id}?commentIndex=${commentIndex}`);
        break;
      case 'file':
        ids[0] = params.fileId || parentIds[0];
        setCommentUrl(`/companies/${companyId}/teams/${teamId}/files/${ids[0]}/comments/${comment._id}?commentIndex=${commentIndex}`);
        break;

      default:
        break;
    }

    // set last replies users & date

    if (comment.discussions) {
      const tempDiscussionUsers = [];
      let tempLastReplyDate;
      comment.discussions.forEach((discussion, index) => {
        if (index > 3) return;
        if (index === 0) tempLastReplyDate = discussion.createdAt;
        if (tempDiscussionUsers.some(
          (userDiscussion) => userDiscussion._id === discussion.creator?._id,
        )) return;
        tempDiscussionUsers.push(discussion.creator);
      });

      setLastDiscussions({
        users: [...tempDiscussionUsers],
        date: tempLastReplyDate,
      });
    }
  }, [comment]);

  const handleShowEditForm = (commentId) => {
    setShowEditForm(commentId);
    handleCancelCommentMenu();
  };

  const handleShowSeenModal = useCallback((commentId) => {
    handleClickShowSeenModal(commentId);
  }, []);

  return (
    <div key={comment._id} id={`comment_${comment._id}`} className={styles.commentsSection} ref={ref}>
      <div>
        <DisplayAvatarMemberWithOnline
          src={comment.creator?.photoUrl}
          userId={comment.creator?._id}
          // prevLoc={location.pathname}
          size="md"
          marginRight="10px"
        />
      </div>
      <div className={styles.commentHeaderTextActionSection}>
        {comment.creator?._id === user._id && !hideCommentOption && (
        <div className={styles.commentMenuSection}>
          <div className={styles.seenSection}>
            {isUserCreator && (
            <SeenInfo
              count={comment?.seen?.length}
              onClick={() => handleShowSeenModal(comment._id)}
              variant="black"
              shouldHideTextWhenMobile
            />
            )}
          </div>
          <InputButtonMore onClick={() => handleShowCommentMenu(comment._id)} variant="horizontal" />
          {showCommentMenu === comment._id && (
          <CommentMenu
            disabledOutsideClickHandlerMenu={disabledOutsideClickHandlerMenu}
            handleCancelCommentMenu={handleCancelCommentMenu}
            handleShowEditForm={() => handleShowEditForm(comment._id)}
            handleShowDeletePopUp={() => handleClickDeleteComment(comment._id)}
          />
          )}
        </div>
        )}
        <div className={styles.commentHeaderContainer}>
          <p className={styles.commentUser}>{comment.creator?.fullName}</p>
          <DisplayDateBox
            date={comment.createdAt}
            relativeTime={!!isToday}
            exactTime={!isToday}
            customBackgroundColor={Color.lighterBlue2}
            customTextColor={Color.blueNavy4}
          />
          {isUserCreator && (
          <div className={styles.sentIcon}>
            <DisplayIconMessageSent
              featureType="comment"
              sentStatus="readAll"
            />
          </div>
          )}
        </div>
        {showEditForm !== comment._id
          ? (
            <>
              <div className={styles.commentTextContainer}>
                {(isInitialContentReady && !limitContent)
                  && <EditorView model={editorModel} />}
                {isInitialContentReady && limitContent && withHighlighter && (
                <DisplayTextBody
                  mode="14"
                  color={Color.gray3}
                >
                  <Highlighter
                    highlightStyle={{
                      color: Color.yellowAccentCicle,
                      padding: '0px',
                      backgroundColor: 'transparent',
                    }}
                    searchWords={keywordArray}
                    autoEscape
                    textToHighlight={getPureStringWithLimit(comment.content, limitContent)}
                  />
                </DisplayTextBody>
                )}
              </div>
              {!hideCheers && (
              <div className={styles.cheersSection}>
                <CheersContainer
                  parentIds={parentIds}
                  cheers={comment.cheers}
                  type={type}
                  comment={comment}
                  receiver={comment.creator?._id}
                  primaryParentId={comment._id}
                />
              </div>
              )}
              {type && !hideReply ? (
                <>
                  {!type.includes('Discussion')

                    ? (
                      <div className={styles.discussSection}>
                        {comment?.discussions?.length < 1
                          ? (
                            <div className={styles.discussSection__newReply}>
                              <LinkNoDecor to={commentUrl}>
                                <div className={styles.discussSection__newReply__item}>
                                  <ReplyIcon />
                                  <DisplayTextSubBody
                                    mode="9"
                                    color={Color.gray3}
                                  >
                                    Balas
                                  </DisplayTextSubBody>
                                </div>
                              </LinkNoDecor>
                            </div>
                          )
                          : (
                            <>
                              <div className={styles.discussSection__info}>
                                {lastDiscussions.users.map((userLastDiscussion) => (
                                  <DisplayAvatarMemberWithOnline
                                    userId={userLastDiscussion?._id}
                                    // prevLoc={location.pathname}
                                    src={userLastDiscussion?.photoUrl}
                                    size="xs"
                                    marginRight="2px"
                                  />
                                ))}

                                <LinkNoDecor to={commentUrl}>
                                  <div className={styles.discussSection__info__countText}>
                                    <ReplyIcon />
                                    <DisplayTextSubBody
                                      mode="9"
                                      color={Color.blueNavy4}
                                    >
                                      {comment?.discussions?.length}
                                      {' '}
                                      {comment?.discussions?.length > 1 ? 'balasan' : 'balasan'}
                                    </DisplayTextSubBody>
                                  </div>
                                </LinkNoDecor>
                                <div className={styles.discussSection__lastReply}>
                                  <div className={styles.lastReplyText}>
                                    <DisplayTextSubBody
                                      mode="9"
                                      color={Color.gray4}
                                      className={styles.discussSection__info__dateText}
                                    >
                                      Terakhir :
                                      {' '}
                                    </DisplayTextSubBody>
                                  </div>
                                  <DisplayDateBox
                                    customBackgroundColor={Color.lighterBlue2}
                                    customTextColor={Color.blueNavy4}
                                    date={lastDiscussions.date}
                                    relativeTime
                                  />
                                </div>
                              </div>
                            </>
                          )}
                      </div>
                    ) : null}
                </>
              ) : null}
            </>
          )
          : (
            <EditCommentForm
              parentIds={parentIds}
              comment={comment}
              type={type}
              handleShowEditForm={handleShowEditForm}
              socket={socket}
              parentTeamId={teamId}
            />
          )}
      </div>
    </div>
  );
});

CommentItem.propTypes = {
  commentIndex: PropTypes.number.isRequired,
  type: PropTypes.string.isRequired,
  comment: PropTypes.object.isRequired,
  parentIds: PropTypes.array.isRequired,
  hideReply: PropTypes.bool.isRequired,
  isToday: PropTypes.bool.isRequired,
  handleClickDeleteComment: PropTypes.func.isRequired,
  handleClickShowSeenModal: PropTypes.func.isRequired,
  disabledOutsideClickHandlerMenu: PropTypes.bool.isRequired,
  hideCheers: PropTypes.bool,
  hideCommentOption: PropTypes.bool,
  limitContent: PropTypes.number,
  withHighlighter: PropTypes.bool,
  keywordArray: PropTypes.array,
  socket: PropTypes.object.isRequired,
  parentTeamId: PropTypes.string,
};

CommentItem.defaultProps = {
  hideCheers: false,
  hideCommentOption: false,
  limitContent: null,
  withHighlighter: false,
  keywordArray: [],
  parentTeamId: undefined,
};

export default CommentItem;
