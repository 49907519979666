import React from 'react';
import PropTypes from 'prop-types';
import styles from './BlastSnapshot.module.css';
import PostSnapshot from './PostSnapshot/PostSnapshot';

const BlastSnapshot = ({ posts }) => (
  <div className={styles.postsSection}>
    <div className={styles.mainContainer}>
      {posts !== undefined
        ? (
          <>
            {posts.map((post) => (
              <PostSnapshot post={post} />
            ))}
          </>
        ) : null}
    </div>
  </div>
);

BlastSnapshot.propTypes = {
  posts: PropTypes.array.isRequired,
};

export default BlastSnapshot;
