import { cloneDeep } from 'lodash';
import { actionTypes } from '../reducers/reducer';
import { ApiConstants, CommentConstants, QuestionConstants } from '../constants';
import apiUtil from '../utilities/apiUtil';
import {
  getIndexItemByIDFromList,
  getLastListCreatedAt,
  mergeObjectListAndRemoveDuplicate,
} from '../utilities/arrayUtil';
import { CheckInActions, TeamActions, CommentActions } from '.';
import { ErrorException } from '../utilities/handleError';

/*
  Dispatcher
*/

function dispatchUpdateCheckInQuestion({ updateCheckInQuestion }, dispatch) {
  dispatch({
    type: actionTypes.UPDATE_CURRENT_CHECK_IN_QUESTION,
    updateCheckInQuestion,
  });
}

function dispatchCurrentCheckInQuestion({ currentCheckInQuestion }, dispatch) {
  dispatch({
    type: actionTypes.SET_CURRENT_CHECK_IN_QUESTION,
    currentCheckInQuestion,
  });
}

function dispatchCurrentQuestionComment({ currentQuestionComment }, dispatch) {
  dispatch({
    type: actionTypes.SET_CURRENT_QUESTION_COMMENT,
    currentQuestionComment,
  });
}

function dispatchPreviousCheckInComment({ previousCheckInComment }, dispatch) {
  dispatch({
    type: actionTypes.SET_PREVIOUS_CHECK_IN_COMMENT,
    previousCheckInComment,
  });
}

/*
  SetterDispatcher
*/

function setCurrentCheckInQuestion({ currentCheckInQuestion }, dispatch) {
  if (!currentCheckInQuestion || currentCheckInQuestion?.comments === undefined) return;

  dispatchCurrentCheckInQuestion(
    { currentCheckInQuestion: cloneDeep(currentCheckInQuestion) }, dispatch,
  );
}

function setCurrentQuestionComment({ currentQuestionComment }, dispatch) {
  if (!currentQuestionComment) return;

  dispatchCurrentQuestionComment({ currentQuestionComment }, dispatch);
}

function setPreviousCheckInComment({ previousCheckInComment }, dispatch) {
  if (!previousCheckInComment) return;

  dispatchPreviousCheckInComment({ previousCheckInComment }, dispatch);
}

/*
  Helpers
*/

function modifyResponseLoadComment({ result, previousQuestion }) {
  return {
    ...previousQuestion,
    comments: result?.data?.comments,
  };
}

function modifyAndSetCurrentCheckInQuestion({ result }, dispatch) {
  setCurrentCheckInQuestion({
    currentCheckInQuestion: {
      ...result?.data?.question,
      comments: null,
    },
  }, dispatch);

  CheckInActions.modifyAndSetCurrentCheckIn(
    { result, checkInId: result?.data?.question?.checkIn }, dispatch,
  );
}

/*
  Method
*/

function incomingQuestion({ question }, dispatch) {
  if (!question) return;

  const updateCheckInQuestion = (currentCheckInQuestion) => {
    let newCurrentCheckInQuestion = {
      ...question,
      comments: currentCheckInQuestion.comments,
      cheers: currentCheckInQuestion.cheers,
    };
    newCurrentCheckInQuestion = cloneDeep(newCurrentCheckInQuestion);

    return newCurrentCheckInQuestion;
  };

  dispatchUpdateCheckInQuestion({ updateCheckInQuestion }, dispatch);
}

function incomingQuestionComment({ comment, typeAction, updateCommentCheer }, dispatch) {
  if (!comment) return;

  const updateCheckInQuestion = (currentCheckInQuestion) => {
    const listData = currentCheckInQuestion.comments;
    const indexNewComment = getIndexItemByIDFromList(listData, comment);

    switch (typeAction) {
      case QuestionConstants.typeCallback.NEW:
        if (indexNewComment >= 0) {
          listData[indexNewComment] = comment;
        } else {
          listData.splice(0, 0, comment);
        }
        break;
      case QuestionConstants.typeCallback.DELETE:
        if (indexNewComment >= 0) {
          listData.splice(indexNewComment, 1);
        }
        break;
      default:
        if (indexNewComment >= 0) {
          if (updateCommentCheer) {
            listData[indexNewComment] = updateCommentCheer(listData[indexNewComment]);
          } else {
            listData[indexNewComment] = comment;
          }
        }
    }

    let newCheckInQuestion = { ...currentCheckInQuestion, comments: listData };
    newCheckInQuestion = cloneDeep(newCheckInQuestion);

    return newCheckInQuestion;
  };

  dispatchUpdateCheckInQuestion({ updateCheckInQuestion }, dispatch);
}

async function archiveQuestion({ questionId, companyId, teamId }, dispatch) {
  try {
    const result = await apiUtil.patch(ApiConstants.URL_V1.QUESTION_ARCHIVE({ questionId }), {}, {
      params: {
        companyId,
        teamId,
      },
    });

    setCurrentCheckInQuestion({ currentCheckInQuestion: result?.data?.question }, dispatch);

    return result;
  } catch (error) {
    throw new ErrorException(error);
  }
}

async function initiateQuestion(
  {
    checkInId, teamId, questionId, companyId,
  }, dispatch,
) {
  try {
    const result = await apiUtil.get(ApiConstants.URL_V2.QUESTION_DETAIL(
      { checkInId, questionId },
    ), {
      params: {
        companyId,
        teamId,
      },
    });

    modifyAndSetCurrentCheckInQuestion({ result }, dispatch);
    // TeamActions.saveCurrentTeam({ currentTeam: result?.data?.currentTeam }, dispatch);

    return result;
  } catch (error) {
    throw new ErrorException(error);
  }
}

async function initiateQuestionComment(
  {
    questionId, companyId, teamId, limit,
  }, dispatch,
) {
  try {
    const result = await apiUtil.get(ApiConstants.URL_V2.QUESTION_COMMENT({ questionId }), {
      params: {
        limit,
        companyId,
        teamId,
      },
    });

    setCurrentQuestionComment({ currentQuestionComment: result?.data?.comments }, dispatch);

    return CommentActions.sliceResultDataforLastCommentNearLimitComment(result);
  } catch (error) {
    throw new ErrorException(error);
  }
}

async function loadMoreQuestionComment(
  {
    questionId, companyId, currentCheckInQuestion, teamId,
  },
  dispatch,
) {
  try {
    const result = await apiUtil.get(ApiConstants.URL_V2.QUESTION_COMMENT({ questionId }), {
      params: {
        limit: CommentConstants.limitComment,
        createdAt: getLastListCreatedAt(currentCheckInQuestion.comments),
        companyId,
        teamId,
      },
    });

    const modifiedResult = modifyResponseLoadComment(
      { result, previousQuestion: currentCheckInQuestion },
    );
    const mergedQuestionComment = mergeObjectListAndRemoveDuplicate({
      currentObjectList: currentCheckInQuestion,
      nextObjectList: modifiedResult,
      keyObject: 'comments',
    });

    setPreviousCheckInComment({ previousCheckInComment: modifiedResult?.comments }, dispatch);
    setCurrentCheckInQuestion({ currentCheckInQuestion: mergedQuestionComment }, dispatch);

    return result;
  } catch (error) {
    throw new ErrorException(error);
  }
}

export {
  incomingQuestionComment,
  initiateQuestion,
  initiateQuestionComment,
  archiveQuestion,
  loadMoreQuestionComment,
  setPreviousCheckInComment,
  setCurrentCheckInQuestion,
  modifyAndSetCurrentCheckInQuestion,
  incomingQuestion,
};
