import React, {
  memo, useContext,
} from 'react';
import PropTypes from 'prop-types';
import useMediaQuery from '@mui/material/useMediaQuery';
import styles from './MyTaskCardMiddleNavBar.module.css';
import { GlobalContext } from '../../../contexts/GlobalStateProvider';
import ListTabs from './ListTabs/ListTabs';

const containerWithSidebarStyles = {
  true: `${styles.container} ${styles.sidebarOpen}`,
  false: styles.container,
};

const MyTaskCardMiddleNavBar = ({
  overviewOptionId,
  globalActions,
  otherActions,
}) => {
  const isMobile = useMediaQuery('(max-width:720px)');
  const hasGlobalActions = Boolean(globalActions?.props?.children);
  const [{ isSidebarOpen }] = useContext(GlobalContext);

  return (
    <div className={containerWithSidebarStyles[isSidebarOpen]}>
      <ListTabs overviewOptionId={overviewOptionId} />
    </div>
  );
};

MyTaskCardMiddleNavBar.propTypes = {
  overviewOptionId: PropTypes.string,
  globalActions: PropTypes.node,
  otherActions: PropTypes.node,
};

MyTaskCardMiddleNavBar.defaultProps = {
  overviewOptionId: null,
  globalActions: <></>,
  otherActions: <></>,
};

export default memo(MyTaskCardMiddleNavBar);
