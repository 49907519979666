import React, {
  memo, useCallback, useContext, useEffect, useState,
} from 'react';
import PropTypes from 'prop-types';
import { Box } from '@mui/material';
import styles from './ArchivedItemsBar.module.css';
import Color from '../../../themes/colors';
import HeaderArchivedItemsSection from './HeaderArchivedItemsSection/HeaderArchivedItemsSection';
import { DisplayDividerMain } from '../../UI_V2/Display/Divider';
import ListDataSection from './ListDataSection/ListDataSection';
import { GlobalContext } from '../../../contexts/GlobalStateProvider';

const topPositionVariantStyles = {
  navBarOnly: styles.topPositionNavbarOnly,
  withSubNavBar: styles.topPositionWithSubNavBar,
  withBottomBar: styles.topPositionWithBottomBar,
};

const bodyOverflowStyles = {
  navBarOnly: styles.bodyOverflowNavbarOnly,
  withSubNavBar: styles.bodyOverflowWithSubNavBar,
  withBottomBar: styles.bodyOverflowWithBottomBar,
};

const containerSidebarStyles = {
  true: `${styles.container} ${styles.sidebarOpen}`,
  false: styles.container,
};

const ContainerArchivedItemsBar = ({
  topPositionVariant,
  barTitle,
  onClose,
  featureType,
  initiateListDataFunc,
  loadMoreListDataFunc,
  unarchiveDataFunc,
}) => {
  const [{ isSidebarOpen }] = useContext(GlobalContext);
  /* Show/Hide Filters */
  const [showFilters, setShowFilters] = useState(false);
  const [isFilterActive, setIsFilterActive] = useState(false);

  const handleClickShowFilters = () => {
    setShowFilters(true);
  };

  const handleClickHideFilters = () => {
    setShowFilters(false);
  };

  const handleSetIsFilterActive = (value) => {
    setIsFilterActive(value);
  };

  return (
    <>
      <Box className={`${containerSidebarStyles[isSidebarOpen]} ${topPositionVariantStyles[topPositionVariant]}`}>
        <Box className={styles.header}>
          <HeaderArchivedItemsSection
            title={barTitle}
            onClose={onClose}
            handleClickShowFilters={handleClickShowFilters}
            isFilterActive={isFilterActive}
          />
        </Box>
        <DisplayDividerMain borderColor={Color.gray4} />
        <Box id="archivedItemsList" className={`${styles.body} ${bodyOverflowStyles[topPositionVariant]}`}>
          <ListDataSection
            featureType={featureType}
            showFilters={showFilters}
            handleHideFilters={handleClickHideFilters}
            initiateListDataFunc={initiateListDataFunc}
            loadMoreListDataFunc={loadMoreListDataFunc}
            unarchiveDataFunc={unarchiveDataFunc}
            handleSetIsFilterActive={handleSetIsFilterActive}
            topPositionVariant={topPositionVariant}
          />
        </Box>
      </Box>
    </>
  );
};

ContainerArchivedItemsBar.propTypes = {
  topPositionVariant: PropTypes.oneOf(['navbarOnly', 'withSubNavBar', 'withBottomBar']),
  barTitle: PropTypes.string,
  onClose: PropTypes.func.isRequired,
  featureType: PropTypes.oneOf(['team', 'card', 'bucket']),
  initiateListDataFunc: PropTypes.func.isRequired,
  loadMoreListDataFunc: PropTypes.func.isRequired,
  unarchiveDataFunc: PropTypes.func.isRequired,
};

ContainerArchivedItemsBar.defaultProps = {
  topPositionVariant: 'withSubNavBar',
  barTitle: 'Archived Items',
  featureType: 'team',
};

export default memo(ContainerArchivedItemsBar);
