/* eslint-disable jsx-a11y/media-has-caption */
/* eslint-disable no-nested-ternary */
import React, {
  memo, useState, useEffect, useRef, forwardRef,
} from 'react';
import _ from 'lodash';
import PropTypes from 'prop-types';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import ExpandMoreOutlinedIcon from '@mui/icons-material/ExpandMoreOutlined';
import draftToHtml from 'draftjs-to-html';
import { useMediaQuery } from '@mui/material';
import VideoPlayerThumbnail from '../../../../../components/VideoPlayerThumbnail/VideoPlayerThumbnail';
import styles from './Message.module.css';
import ImageMember from '../../../../../components/UI/ImageMember/ImageMember';
import { DisplayTextBody, DisplayTextSubHeadline } from '../../../../../components/UI_V2/Display/Text';
import Color from '../../../../../themes/colors';
import DateBox from '../../../../../components/UI/DateBox/DateBox';
import checkIsJson from '../../../../../utilities/checkIsJson';
import EditorView from '../../../../FroalaEditor/EditorView/EditorView';
import DeleteMessagePopUp from '../DeleteMessagePopUp/DeleteMessagePopUp';
import MessageMenuPopUp from '../MessageMenuPopUp/MessageMenuPopUp';
import { AttachmentActions } from '../../../../../actions';
import SeenModal from '../../../../../components/Container/SeenModal/SeenModal';
import { SeenConstants } from '../../../../../constants';
import { useDelayShowHideHandler } from '../../../../../hooks/HelperHooks';
import SeenInfo from '../../../../../components/Container/SeenModal/SeenInfo/SeenInfo';
import { DisplayIconMessageSent } from '../../../../../components/UI_V2/Display/Icon';
import { elementId } from '../../../../../constants/ElementIdConstants';
import PdfThumbnail from '../../../../../components/PdfThumbnail/PdfThumbnail';

const containerStyles = {
  self: {
    marginLeft: 'auto',
  },
  other: {
    marginLeft: '0',
  },
};

const balloonMessageStyles = {
  self: {
    background: Color.yellowCicle4,
    borderRadius: '10px 0px 10px 10px',
  },
  other: {
    background: Color.white,
  },
};

const balloonMessageContentStyles = {
  message: {},
  attachment: {
    minHeight: '150px',
    padding: '15px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
  },
};

const Message = forwardRef(({
  messageFile, category, owner, isToday,
  handleOpenAndSetFileViewer,
}, ref) => {
  const isMobile = useMediaQuery('(max-width:720px)');
  const [isInitialContentReady, setIsInitialContentReady] = useState(false);
  const [editorModel, setEditorModel] = useState();
  const [fileName, setFileName] = useState('');
  const [fileUrl, setFileUrl] = useState('');
  const [imageUrl, setImageUrl] = useState('');
  const [isImage, setIsImage] = useState('');
  const [isVideo, setIsVideo] = useState('');
  const [isPdf, setIsPdf] = useState('');
  const [showMessageMenu, setShowMessageMenu] = useState(false);
  const [showMessageDelete, setShowMessageDelete] = useState(false);
  const [showSeenModal, handleShowSeenModal, handleHideSeenModal] = useDelayShowHideHandler();
  const [messageMenuType, setMessageMenuType] = useState(`${category}-message`);

  const balloonMessageRef = useRef();

  const isChat = category === 'private-chat';

  const {
    creator, content, type, createdAt, url, name, mimeType, isDeleted,
  } = messageFile;

  useEffect(() => {
    switch (type) {
      case 'message':
        // eslint-disable-next-line no-case-declarations
        let initialContent;
        if (checkIsJson(content)) {
          initialContent = draftToHtml(JSON.parse(content));
        } else {
          initialContent = content;
        }

        setEditorModel(initialContent);
        setIsInitialContentReady(true);
        break;
      case 'attachment':
        setMessageMenuType(`${category}-attachment`);
        // eslint-disable-next-line no-case-declarations
        let tempName = '';
        if (name.length > 40) {
          tempName = `${name.slice(0, 40)}...`;
        } else {
          tempName = name;
        }

        setFileName(tempName);

        // eslint-disable-next-line no-case-declarations
        let attachmentUrl = null;
        if (url) {
          if (url?.charAt(0) === '/') {
            attachmentUrl = process.env.REACT_APP_PRIMARY_API_URL + url;
          } else {
            attachmentUrl = url;
          }
        }

        setFileUrl(attachmentUrl);

        // eslint-disable-next-line no-case-declarations
        const result = AttachmentActions.getMimeTypeUrl(
          { mimeType, fileUrl: attachmentUrl },
        );

        setImageUrl(result?.imageUrl);
        setIsImage(result?.isImage);
        setIsVideo(result?.isVideo);
        setIsPdf(result?.isPdf);
        break;

      default:
        break;
    }
  }, [messageFile]);

  const handleShowMessageDelete = () => {
    setShowMessageDelete(true);
  };

  const handleShowMessageMenu = () => {
    setShowMessageMenu(true);
  };

  const handleCancelShowMessageDelete = () => {
    setShowMessageDelete(false);
  };

  const handleCancelShowMessageMenu = () => {
    setShowMessageMenu(false);
  };

  let seenModalType;

  switch (type) {
    case 'message':
      if (`${category}-message` === 'group-chat-message') {
        seenModalType = SeenConstants.typeModule.groupChatMessage;
      } else {
        seenModalType = SeenConstants.typeModule.chatMessage;
      }
      break;
    case 'attachment':
      if (`${category}-attachment` === 'group-chat-attachment') {
        seenModalType = SeenConstants.typeModule.groupChatAttachment;
      } else {
        seenModalType = SeenConstants.typeModule.chatAttachment;
      }
      break;
    default:
      break;
  }

  return (
    <div ref={ref} className={styles.container} style={containerStyles[owner]}>
      { owner === 'self' ? null : (
        <div className={styles.photo}>
          <ImageMember photoUrl={creator?.photoUrl} />
        </div>
      )}
      <div className={styles.message}>
        {owner === 'self' ? null : (
          <div className={styles.name}>
            <DisplayTextSubHeadline
              mode="16"
              decoration="bold"
              color={Color.fontColor}
            >
              {creator?.fullName}
            </DisplayTextSubHeadline>
          </div>
        )}
        <div className={styles.balloon}>
          {owner === 'self' ? null : <div className={styles.vectorBalloon} />}
          <div
            className={styles.balloonMessage}
            style={balloonMessageStyles[owner]}
            ref={balloonMessageRef}
          >
            <div
              className={
                    isDeleted || owner === 'other'
                      ? styles.iconOptionDeleted
                      : styles.iconOption
                  }
              onClick={handleShowMessageMenu}
              id={isChat ? elementId.chatMessageDropdown
                : elementId.groupChatMessageDropdown}
            >
              <ExpandMoreOutlinedIcon />
            </div>
            {showMessageMenu
              ? (
                <MessageMenuPopUp
                  onClose={handleCancelShowMessageMenu}
                  onDelete={handleShowMessageDelete}
                  onShowSeenModal={handleShowSeenModal}
                  owner={owner}
                  parentDivRef={balloonMessageRef}
                />
              ) : null}
            <DeleteMessagePopUp
              messageFile={messageFile}
              show={showMessageDelete}
              onClose={handleCancelShowMessageDelete}
              onParentClose={handleCancelShowMessageMenu}
              type={messageMenuType}
            />
            <SeenModal
              open={showSeenModal}
              onClose={handleHideSeenModal}
              type={seenModalType}
              itemId={messageFile._id}
            />
            <div
              className={styles.content}
              style={isDeleted ? {} : balloonMessageContentStyles[type]}
            >
              {type === 'message' ? (
                <>
                  {isInitialContentReady
                    ? isDeleted
                      ? <i>{content}</i>
                      : <EditorView model={editorModel} />
                    : <p>Please wait...</p>}
                </>
              )
                : (
                  <>
                    {isDeleted ? null : (
                      <div
                        className={styles.attachment__icon}
                        onClick={() => handleOpenAndSetFileViewer(messageFile)}
                      >
                        {!isVideo && !isPdf && (
                          <LazyLoadImage
                            src={imageUrl}
                            style={isImage
                              ? { maxWidth: '350px', maxHeight: '320px' }
                              : { maxWidth: '70px' }}
                          />
                        )}
                        {isVideo
        && (
          // // eslint-disable-next-line react/jsx-no-comment-textnodes
          // <>
          //   <video width={isMobile ? 140 : 180}>
          //     <source
          //       src={imageUrl}
          //     />
          //   </video>
          //   <div className={styles.playIcon}>
          //     <PlayCircleIcon />
          //   </div>
          // </>
          <VideoPlayerThumbnail
            videoUrl={imageUrl}
            variant="chat"
          />
        )}
                        {isPdf && (
                          <PdfThumbnail
                            fileUrl={imageUrl}
                            height={240}
                          />
                        )}
                      </div>
                    )}
                    <div className={styles.attachment__title}>
                      {isDeleted ? <i>{fileName}</i> : (
                        <>
                          <DisplayTextBody
                            mode="12"
                            position="center"
                            color={Color.blueNavyCicle}
                          >
                            {fileName}
                          </DisplayTextBody>
                          <a href={fileUrl} target="_blank" rel="noreferrer">
                            Unduh
                          </a>
                        </>
                      )}
                    </div>

                  </>
                )}
            </div>
            <div className={styles.timeStamp}>
              {isToday ? (
                <DateBox date={createdAt} relativeTime />
              ) : (
                <DateBox date={createdAt} exactTime />
              )}
            </div>
            <div className={styles.seen}>
              {owner === 'self' && messageFile.seen && (
                <SeenInfo
                  count={messageFile?.seen?.length}
                  onClick={handleShowSeenModal}
                />
              )}
            </div>
            {owner === 'self' && (
            <div className={styles.sentIcon}>
              <DisplayIconMessageSent
                featureType="message"
                sentStatus="readAll"
              />
            </div>
            )}
          </div>
          {owner !== 'self' ? null : <div className={styles.vectorBalloonSelf} />}
        </div>
      </div>
    </div>
  );
});

Message.propTypes = {
  messageFile: PropTypes.object,
  category: PropTypes.oneOf(['private-chat', 'group-chat']),
  owner: PropTypes.oneOf(['self', 'other']),
  isToday: PropTypes.bool,
  handleOpenAndSetFileViewer: PropTypes.func.isRequired,
};

Message.defaultProps = {
  messageFile: {
    creator: {
      photoUrl: '',
      fullName: '',
    },
    content: '',
  },
  isToday: false,
  category: 'private-chat',
  owner: 'other',
};

export default memo(Message);
