import React, { memo, useState } from 'react';
import PropTypes from 'prop-types';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import styles from './Header.module.css';
import { DisplayTextBody, DisplayTextHeadline, DisplayTextSubHeadline } from '../../../components/UI_V2/Display/Text';
import Color from '../../../themes/colors';
import { DisplayDividerMain } from '../../../components/UI_V2/Display/Divider';

const Header = ({
  title,
  desc
}) => (
  <div className={styles.container}>
    <div className={styles.title}>
      <DisplayTextHeadline
        decoration="bold"
        fontFamily="openSans"
        color={Color.blueNavy2}
      >
        {title}
      </DisplayTextHeadline>
    </div>
    <div className={styles.desc}>
      <DisplayTextBody>
        {desc}
      </DisplayTextBody>
    </div>
    <DisplayDividerMain />
  </div>
);

Header.propTypes = {
  title: PropTypes.string.isRequired,
  desc: PropTypes.string.isRequired,
};

export default memo(Header);
