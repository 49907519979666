import React, {
  useState, useContext, useEffect, useCallback, createRef,
} from 'react';
import {
  useHistory, useParams, useLocation,
} from 'react-router-dom';
import {
  differenceWith, isArray, isEmpty, isEqual, isNumber,
} from 'lodash';
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import AddIcon from '@mui/icons-material/Add';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import ArchiveIcon from '@mui/icons-material/Archive';
import VerifiedUserIcon from '@mui/icons-material/VerifiedUser';
import draftToHtml from 'draftjs-to-html';
import { useSnackbar } from 'notistack';
import MainLayout from '../../components/Layout/MainLayout/MainLayout';
import { GlobalContext, initialState } from '../../contexts/GlobalStateProvider';
import { actionTypes } from '../../reducers/reducer';
import SeparatorLine from '../../components/UI/SeparatorLine/SeparatorLine';
import Paper from '../../components/UI/Paper/Paper';
import '../../../node_modules/react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import styles from './OccurrencePage.module.css';
import CommentsSectionContainer from '../CommentsSectionContainer/CommentsSectionContainer';
import ImageMember from '../../components/UI/ImageMember/ImageMember';
import RoundActionMenu from '../../components/UI/RoundActionMenu/RoundActionMenu';
import OccurrenceMenu from './OccurrenceMenu/OccurrenceMenu';
import Title from '../../components/Title/Title';
import checkIsJson from '../../utilities/checkIsJson';
import EditorView from '../FroalaEditor/EditorView/EditorView';
import handleLoadings from '../../utilities/handleLoadings';
import PageLoading from '../../components/UI/Button/PageLoading/PageLoading';
import handleStatusMsg from '../../utilities/handleStatusMsg';
import CheersContainer from '../CheersContainer/CheersContainer';
import AddOccurrenceCalendarMenu from './AddOccurrenceCalendarMenu/AddOccurrenceCalendarMenu';
import { dateDifferenceInDays } from '../../utilities/date';
import TimeAgo from '../../components/UI/TimeAgo/TimeAgo';
import {
  CommentActions, EventActions, OccurrenceActions, SearchJumpToActions, TeamActions,
} from '../../actions';
import { sortingListByCreatedAt } from '../../utilities/arrayUtil';
import { OccurrenceConstants, PageConstants, SeenConstants } from '../../constants';
import InfinityScroll from '../../components/UI/InfinityScroll/InfinityScroll';
import {
  useOccurrenceCheerSocket, useOccurrenceCommentSocket, useRecurringEventSocket,
} from '../../hooks/OccurrenceHooks';
import { useScrollToTargetComment } from '../../hooks/DiscussionHooks';
import PrivateIcon from '../../components/UI/PrivateIcon/PrivateIcon';
import GeneralSubNavBar from '../../components/GeneralSubNavBar/GeneralSubNavBar';
import { generateRoutesAndTitle } from '../../actions/BreadCrumbActions';
import GlobalActionButton from '../../components/GeneralSubNavBar/BottomNavBar/GlobalActionButton/GlobalActionButton';
import { useDelayShowHideHandler } from '../../hooks/HelperHooks';
import { useInitiateRoleUser } from '../../hooks/RoleHooks';
import { roleTypeRef, typeRef } from '../../constants/RoleConstants';
import MyRoleUser from '../../components/Container/MyRoleUser/MyRoleUser';
import { checkIfUserAuthorizedToEditData } from '../../actions/UserActions';
import { useInitiateTeamBelowRole } from '../../hooks/TeamHooks';
import ActivityByModule from '../ActivityByModule/ActivityByModule';
import { serviceTypeRef } from '../../constants/ActivityConstants';
import { DisplayAvatarMemberWithOnline } from '../../components/UI_V2/Display/Avatar';
import SeenInfo from '../../components/Container/SeenModal/SeenInfo/SeenInfo';
import SeenModal from '../../components/Container/SeenModal/SeenModal';
import { useUserTypingOccurrenceSocket } from '../../hooks/UserTypingHooks';
import UserTyping from '../../components/Container/UserTyping/UserTyping';
import { useMarkReadNotificationOnPageOpen } from '../../hooks/NotificationHooks';
import { LayoutMain } from '../../components/UI_V2/Layout';
import { SurfacePaperMain } from '../../components/UI_V2/Surface/Paper';

function OccurrencePage() {
  const [{
    user, currentOccurrence,
    previousEventComment,
    currentTeam,
    currentSchedule, currentCompany,
    currentRoleUser, currentTeamBelowRole,
    allUserTypingOccurrence,
  }, dispatch] = useContext(GlobalContext);
  const [loadings, setLoadings] = useState([]);
  const [showOccurrenceMenu, setShowOccurrenceMenu] = useState(false);
  const [showAddCalendarMenu, setShowAddCalendarMenu] = useState(false);
  const [openCreateCommentForm, setOpenCreateCommentForm] = useState(false);
  const [isInitialContentReady, setIsInitialContentReady] = useState(false);
  const [
    showModalRoleUser,
    handleShowModalRoleUser,
    handleHideModalRoleUser,
  ] = useDelayShowHideHandler();
  const [
    showSeenModal,
    handleShowSeenModal,
    handleHideSeenModal,
  ] = useDelayShowHideHandler();
  const [editorModel, setEditorModel] = useState();
  const [date, setDate] = useState({
    start: undefined,
    end: undefined,
  });
  const [time, setTime] = useState({
    start: undefined,
    end: undefined,
  });
  const [duration, setDuration] = useState();
  const [commentsFiles, setCommentsFiles] = useState([]);
  const [isNeedCheckLoadMore, setCheckLoadMore] = useState(false);
  const [isErrorLoadMore, setStatusErrorLoadMore] = useState(false);
  const { enqueueSnackbar } = useSnackbar();

  const history = useHistory();
  const location = useLocation();
  const params = useParams();
  const {
    companyId, teamId, eventId, occurrenceId,
  } = params;
  const { scrollToTargetComment } = useScrollToTargetComment();

  useInitiateRoleUser({
    payload: {
      teamId,
      featureType: typeRef.event,
      featureId: eventId,
    },
  });

  const { socket } = useUserTypingOccurrenceSocket({
    companyId,
    eventId,
    occurrenceId,
    teamId,
    userId: user?._id,
  }, dispatch);

  // read notif automatically if there is readNotifId
  useMarkReadNotificationOnPageOpen();

  // initiate members below role
  // useInitiateTeamBelowRole({});
  const isUserCreator = currentOccurrence?.creator?._id === user?._id;

  const [
    showActivities,
    handleShowActivities,
    handleHideActivities,
  ] = useDelayShowHideHandler();

  // for open create comment
  const handleOpenCreateCommentForm = useCallback((value) => {
    setOpenCreateCommentForm(value);
  }, []);
  const createCommentRef = createRef();
  const scrollAndOpenCreateCommentForm = useCallback(() => {
    if (createCommentRef && createCommentRef.current) {
      createCommentRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
    }
    setTimeout(() => {
      handleOpenCreateCommentForm(true);
    }, 800);
  }, [createCommentRef]);

  useRecurringEventSocket({
    scheduleId: currentSchedule?._id,
    eventId: params?.eventId,
    userId: user?._id,
  }, dispatch);
  useOccurrenceCheerSocket({
    eventId,
    occurrenceId,
    userId: user?._id,
  }, dispatch);
  useOccurrenceCommentSocket({
    eventId,
    occurrenceId,
    userId: user?._id,
  }, dispatch);

  const sortingCurrentEventComment = useCallback(
    () => sortingListByCreatedAt(currentOccurrence?.comments),
    [currentOccurrence],
  );
  const isCommentsLengthAtTheLimitOrAbove = useCallback(
    (comments) => isNumber(comments?.length)
    && comments?.length >= OccurrenceConstants.limitComment,
    [],
  );
  const checkIfOccurrenceNeedToLoad = useCallback(() => {
    if (!isNeedCheckLoadMore || isErrorLoadMore) return false;

    return isCommentsLengthAtTheLimitOrAbove(previousEventComment);
  }, [previousEventComment, isErrorLoadMore, isNeedCheckLoadMore]);

  const resetPreviousOccurrenceComment = useCallback(() => {
    EventActions.setPreviousEventComment({
      previousEventComment: initialState.previousEventComment,
    }, dispatch);
    OccurrenceActions.setCurrentOccurrence({
      currentOccurrence: initialState.currentOccurrence,
    }, dispatch);
  }, []);

  useEffect(() => () => resetPreviousOccurrenceComment(), []);

  useEffect(() => {
    if (currentOccurrence.comments === undefined) return;

    const joinedData = sortingCurrentEventComment();

    const prevArray = [...commentsFiles];
    const updatedItems = differenceWith(joinedData, prevArray, isEqual);
    const isItemRemoved = isArray(prevArray) && isArray(joinedData)
    && prevArray.length > joinedData.length;

    if (updatedItems.length > 0 || isItemRemoved) {
      setCommentsFiles([...joinedData]);
    }
  }, [currentOccurrence]);

  useEffect(() => {
    if (user._id === undefined) {
      history.push(`/check-login?previousPath=${location.pathname}`);
      return;
    }

    const startLoadings = handleLoadings('occurrencePage', [...loadings], 'start');
    setLoadings([...startLoadings]);

    const fetchApiEventDetail = async () => {
      try {
        const result = await OccurrenceActions.initiateOccurrenceV2(
          {
            eventId,
            occurrenceId,
            companyId,
            teamId,
            limit: CommentActions.getLimitCommentByPreviousCommentDiscussion(),
          },
          dispatch,
        );
        await TeamActions.initiateTeam({
          teamId,
          companyId,
          currentTeam,
        }, dispatch);
        if (isCommentsLengthAtTheLimitOrAbove(result?.data?.comments)) {
          setCheckLoadMore(true);
        }
        scrollToTargetComment();
      } catch (err) {
        const status = handleStatusMsg(err, 'error');

        dispatch({
          type: actionTypes.SET_ERROR_RESPONSE,
          errorResponse: { message: status.message },
        });

        history.push(`/errors?previousPath=${location.pathname}`);
      } finally {
        const endLoadings = handleLoadings('occurrencePage', [...loadings], 'end');
        setLoadings([...endLoadings]);
      }
    };
    fetchApiEventDetail();
  }, [location]);

  const formatTime = (inputDate) => {
    const minute = inputDate.getMinutes();
    let hour = inputDate.getHours();
    const ampm = hour >= 12 ? 'PM' : 'AM';
    // eslint-disable-next-line no-mixed-operators
    hour = ((hour + 11) % 12 + 1);
    const prefixHour = hour >= 10 ? '' : '0';
    const prefixMinute = minute >= 10 ? '' : '0';

    const formattedTime = `${prefixHour + hour}:${prefixMinute}${minute} ${ampm}`;
    return formattedTime;
  };

  useEffect(() => {
    if (isEmpty(currentOccurrence)) {
      return;
    }

    let initialContent;
    if (checkIsJson(currentOccurrence.content)) {
      initialContent = draftToHtml(JSON.parse(currentOccurrence.content));
    } else {
      initialContent = currentOccurrence.content;
    }

    setEditorModel(initialContent);
    setIsInitialContentReady(true);

    // bikin tanggal start
    let startDateString;
    startDateString = new Date(currentOccurrence?.startDate);
    startDateString = startDateString.toString();
    startDateString = startDateString.split(' ').slice(0, 3).join(' ');
    setDate((prevValue) => ({
      ...prevValue,
      start: startDateString,
    }));

    // bikin tanggal end
    let endDateString;
    endDateString = new Date(currentOccurrence?.endDate);
    endDateString = endDateString.toString();
    endDateString = endDateString.split(' ').slice(0, 3).join(' ');
    setDate((prevValue) => ({
      ...prevValue,
      end: endDateString,
    }));

    // bikin duration hari jika lebih dari 1 hari eventnya
    const differenceDays = dateDifferenceInDays(
      currentOccurrence?.endDate,
      currentOccurrence?.startDate,
    );
    setDuration(differenceDays);

    // bikin start time
    const initialStartDate = new Date(currentOccurrence?.startDate);
    const startTime = formatTime(initialStartDate);

    setTime((prevValue) => ({
      ...prevValue,
      start: startTime,
    }));

    // bikin end time
    const initialEndDate = new Date(currentOccurrence?.endDate);
    const endTime = formatTime(initialEndDate);

    setTime((prevValue) => ({
      ...prevValue,
      end: endTime,
    }));
  }, [currentOccurrence]);

  const handleShowOccurrenceMenu = () => {
    setShowOccurrenceMenu(!showOccurrenceMenu);
  };

  const handleCancelShowOccurrenceMenu = () => {
    setShowOccurrenceMenu(false);
  };

  const handleShowAddCalendarMenu = () => {
    setShowAddCalendarMenu(!showAddCalendarMenu);
  };

  const handleCancelShowAddCalendarMenu = () => {
    setShowAddCalendarMenu(false);
  };

  const handleLoadMoreComments = async () => {
    try {
      const result = await OccurrenceActions.loadMoreOccurrenceComment({
        eventId,
        occurrenceId,
        companyId,
        currentOccurrence,
      }, dispatch);
    } catch (error) {
      const status = handleStatusMsg(error, 'error');

      if (status?.message) {
        enqueueSnackbar(status.message, {
          variant: 'error',
        });
      }

      setStatusErrorLoadMore(true);
    }
  };
  const pageType = PageConstants.pageType.occurrences;
  const breadcrumb = generateRoutesAndTitle({
    type: pageType,
    data: {
      companyId, currentTeam, currentSchedule, currentOccurrence,
    },
  });
  SearchJumpToActions.saveRecentPage({
    company: currentCompany,
    team: currentTeam,
    userId: user?._id,
    itemId: `${eventId}-${occurrenceId}`,
    url: breadcrumb.url,
    routes: breadcrumb.routes,
    name: breadcrumb.title,
    type: pageType,
  });
  return (
    <>
      <Title title={currentOccurrence.title === undefined ? 'Jadwal' : currentOccurrence.title} />
      <GeneralSubNavBar
        breadcrumbRoutes={breadcrumb.routes}
        pageTitle={breadcrumb.title}
        bottomBarOverviewOptionId="schedules"
        bottomBarGlobalActions={(
          <>
            <GlobalActionButton handleClick={scrollAndOpenCreateCommentForm} text="Tambah Komentar" icon={<AddIcon />} />
            <GlobalActionButton
              handleClick={handleShowModalRoleUser}
              text="Akses Saya"
              icon={<VerifiedUserIcon />}
            />
          </>
        )}
      />
      <LayoutMain>
        <PageLoading wait="occurrencePage" loadings={loadings}>
          <SurfacePaperMain>
            <div className={styles.container}>
              <div className={styles.headerSection}>
                <div className={styles.titleSection}>
                  <div className={styles.titleSection__container}>
                    {currentOccurrence.archived ? (
                      <>
                        {currentOccurrence.archived.status ? (
                          <div className={styles.archivedSection}>
                            <ArchiveIcon />
                            <h1>Jadwal ini udah terarsip</h1>
                          </div>
                        ) : null}
                      </>
                    ) : null}
                    <div className={styles.titleSection__title}>
                      {duration
                        ? (
                          <div className={styles.boxDateContainer}>
                            <div className={styles.boxDate}>
                              <CalendarTodayIcon />
                              <p>{date.start}</p>
                            </div>
                            <span>-</span>
                            <div className={styles.boxDate}>
                              <CalendarTodayIcon />
                              <p>{date.end}</p>
                            </div>
                          </div>
                        )
                        : (
                          <div className={styles.boxDate}>
                            <CalendarTodayIcon />
                            <p>{date.start}</p>
                          </div>
                        )}
                      <div className={styles.titleAndCreator}>
                        {currentOccurrence
                          ? (
                            <>
                              <h1>
                                <PrivateIcon data={currentOccurrence} />
                                {' '}
                                {currentOccurrence?.title}
                              </h1>
                              <span>
                                {`Dibuat oleh ${currentOccurrence?.creator?.fullName} `}
                                <TimeAgo date={currentOccurrence?.createdAt} />
                              </span>
                            </>
                          )
                          : null}

                      </div>
                    </div>

                  </div>
                </div>
                <div className={styles.menuSection}>
                  {checkIfUserAuthorizedToEditData(currentOccurrence, user,
                    currentRoleUser,
                    currentTeamBelowRole) && (
                    <RoundActionMenu
                      clicked={handleShowOccurrenceMenu}
                      cancel={handleCancelShowOccurrenceMenu}
                      medium
                    >
                      <MoreHorizIcon />
                    </RoundActionMenu>
                  )}
                  {showOccurrenceMenu
                    ? (
                      <OccurrenceMenu
                        occurrence={currentOccurrence}
                        cancel={handleCancelShowOccurrenceMenu}
                      />
                    ) : null}
                </div>
              </div>

              <SeparatorLine />
              <div className={styles.titleSection__detail}>
                <div className={styles.detail__menu}>
                  <div className={styles.detail__menu__title}>
                    <p>Kapan</p>
                  </div>
                  <div className={styles.detail__menu__content}>
                    {duration
                      ? <p>{`${date.start}, ${time.start} - ${date.end}, ${time.end} (${duration} days)`}</p>
                      : <p>{`${date.start}, ${time.start} - ${time.end}`}</p>}
                    {currentOccurrence?.googleCalendar?.eventGCalTemplateLink
                      ? (
                        <p
                          onClick={handleShowAddCalendarMenu}
                          className={styles.addToCalendar}
                        >
                          Tambahkan ke Kalender Saya
                        </p>
                    ) : null}

                    {showAddCalendarMenu
                      ? (
                        <AddOccurrenceCalendarMenu
                          occurrence={currentOccurrence}
                          cancel={handleCancelShowAddCalendarMenu}
                        />
                      )
                      : null}
                  </div>
                </div>
                {currentOccurrence?.originalStartPattern?.readableText
                  ? (
                    <div className={styles.detail__menu}>
                      <div className={styles.detail__menu__title}>
                        <p>Ulangi</p>
                      </div>
                      <div className={styles.detail__menu__content}>
                        <p>{currentOccurrence.originalStartPattern.readableText}</p>
                      </div>
                    </div>
                )
                  : null}

                <div className={styles.detail__menu}>
                  <div className={styles.detail__menu__title}>
                    <p>Peserta</p>
                  </div>
                  <div className={styles.detail__menu__content__subscriber}>
                    {currentOccurrence?.subscribers?.length < 1
                      ? <p>Jadwal ini belum mempunyai peserta.</p> : null}
                    {currentOccurrence?.subscribers !== undefined
                      ? (
                        <>
                          {currentOccurrence.subscribers.map((subscriber) => (
                            <>
                              <div className={styles.subscribers}>
                                <DisplayAvatarMemberWithOnline
                                  src={subscriber?.photoUrl}
                                  userId={subscriber._id}
                                  size="sm"
                                  marginRight="0px"
                                />
                                <p>{subscriber.fullName}</p>
                              </div>
                            </>
                          ))}
                        </>
                    ) : null}

                  </div>
                </div>
                <div className={styles.detail__menu__notes}>
                  <div className={styles.detail__menu__title}>
                    <p>Catatan</p>
                  </div>
                  <div className={styles.detail__menu__content}>
                    {currentOccurrence?.content === '' || !currentOccurrence?.content
                      ? <p>Jadwal ini belum mempunyai catatan.</p> : null}
                    {isInitialContentReady
                      ? <EditorView model={editorModel} /> : <p>Please wait...</p>}
                  </div>
                </div>
              </div>
              <div className={styles.cheersSection}>
                {isEmpty(currentOccurrence) ? null
                  : (
                    <CheersContainer
                      cheers={currentOccurrence?.cheers}
                      type="occurrenceMain"
                      receiver={currentOccurrence?.creator?._id}
                      primaryParentId={currentOccurrence?._id}
                    />
                  )}
              </div>
              <div className={styles.seenSection}>
                {isUserCreator && (
                <SeenInfo
                  count={currentOccurrence?.seen?.length}
                  onClick={handleShowSeenModal}
                  variant="black"
                />
                )}
              </div>
              <div className={styles.typingSection}>
                <UserTyping
                  allUserTyping={allUserTypingOccurrence}
                  companyMembers={currentCompany?.members}
                />
              </div>
              <div className={styles.commentSection}>
                {!showActivities && (
                <InfinityScroll
                  dataLength={commentsFiles?.length}
                  hasMore={checkIfOccurrenceNeedToLoad()}
                  next={handleLoadMoreComments}
                  emptyMessage="Belum ada komentar disini..."
                  style={{
                    overflow: undefined,
                  }}
                >
                  <div>
                    <CommentsSectionContainer
                      comments={commentsFiles}
                      type="occurrence"
                      ref={createCommentRef}
                      openForm={openCreateCommentForm}
                      onOpenForm={handleOpenCreateCommentForm}
                      handleShowActivities={handleShowActivities}
                      socket={socket}
                    />
                  </div>
                </InfinityScroll>
                )}
                {showActivities && (
                <ActivityByModule
                  variant={serviceTypeRef.recurringOccurrence}
                  handleHideActivities={handleHideActivities}
                />
                )}
              </div>
            </div>
          </SurfacePaperMain>
        </PageLoading>
        <MyRoleUser
          open={showModalRoleUser}
          onClose={handleHideModalRoleUser}
          featureId={eventId}
          featureType={typeRef.event}
          featureTitle={currentOccurrence?.title}
          roleType={roleTypeRef.feature}
          featureData={currentOccurrence}
        />
        <SeenModal
          open={showSeenModal}
          onClose={handleHideSeenModal}
          type={SeenConstants.typeModule.occurrence}
          itemId={eventId}
        />
      </LayoutMain>
    </>
  );
}

export default OccurrencePage;
