import React, {
  memo, useCallback, useContext, useState,
} from 'react';
import PropTypes from 'prop-types';
import { useParams } from 'react-router-dom';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { VerifiedUser } from '@mui/icons-material';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import { Tooltip } from '@mui/material';
import styles from './MyRoleSection.module.css';
import { DisplayTextBody, DisplayTextHeadline } from '../../../UI_V2/Display/Text';
import Color from '../../../../themes/colors';
import introImage from '../../../../assets/PrivateChatPage/intro-chat-section.png';
import MyRoleItem from './MyRoleItem/MyRoleItem';
import {
  roleTypeRef,
  roles,
  typeRef,
  moduleTitleByRoleTypeRef,
} from '../../../../constants/RoleConstants';
import RolePermissionInfo from '../RolePemissionInfo/RolePermissionInfo';
import { InputButtonMain } from '../../../UI_V2/Input/Button';
import { checkIsUserCreatorByFeatureType, hasPermission } from '../../../../actions/RoleActions';
import { limitChar } from '../../../../utilities/stringUtil';
import { GlobalContext } from '../../../../contexts/GlobalStateProvider';

const MyRoleSection = ({
  featureTitle,
  featureType,
  featureId,
  currentRoleUser,
  user,
  roleType,
  handleShowListRoleBadgeSection,
  handleHideMyRoleSection,
  handleShowManageRoleSection,
}) => {
  const [{
    currentBucket, currentBlastPost,
    currentCard,
    currentBucketDoc,
    currentCheckInQuestion,
    currentBucketFile,
    currentOccurrence,
    currentScheduleEvent,
  }] = useContext(GlobalContext);
  const params = useParams();
  const { bucketId } = params;
  const isUserManagerOrAbove = hasPermission(currentRoleUser, roles.managerOrAbove);
  const isUserAdminOrAbove = hasPermission(currentRoleUser, roles.adminOrAbove);
  const isUserSpvOrBelow = hasPermission(currentRoleUser, roles.spvOrBelow);
  // const isUserCreator = checkIsUserCreatorByFeatureType({
  //   featureType,
  //   userId: user._id,
  //   currentBlastPost,
  //   currentCard,
  //   currentBucketDoc,
  //   currentCheckInQuestion,
  //   currentBucketFile,
  //   currentOccurrence,
  //   currentScheduleEvent,
  // });

  const isRoleTypeCompany = roleType === roleTypeRef.company;
  const isRoleTypeTeam = roleType === roleTypeRef.team;
  const isRoleTypeFeature = roleType === roleTypeRef.feature;

  const isFeatureTypeDontHaveManageRoles = featureType === typeRef.blast
    || featureType === typeRef.board
    || featureType === typeRef.schedule
    || featureType === typeRef.checkIn
    || featureType === typeRef.groupChat
    || featureType === typeRef.schedule
    || (featureType === typeRef.bucket && bucketId && currentBucket?.type === 'main');

  const handleOpenListRoleBadgeSection = useCallback(() => {
    handleShowListRoleBadgeSection();
    handleHideMyRoleSection();
  }, []);

  const handleOpenManageRoleSection = useCallback(() => {
    handleShowManageRoleSection();
    handleHideMyRoleSection();
  }, []);

  const isAuthorizedToManageRoles = ((isRoleTypeCompany && isUserAdminOrAbove)
  || ((isRoleTypeTeam || isRoleTypeFeature) && (isUserManagerOrAbove)))
  && !isFeatureTypeDontHaveManageRoles;

  return (
    <div className={styles.container}>
      <div className={styles.title}>
        <DisplayTextHeadline
          color={Color.blueNavyCicle}
          decoration="bold"
        >
          {isRoleTypeCompany && `Akses default saya di ${moduleTitleByRoleTypeRef[featureType]} : ${limitChar(featureTitle, 20)}`}
          {!isRoleTypeCompany && `Akses saya di ${moduleTitleByRoleTypeRef[featureType]} : ${limitChar(featureTitle, 20)}`}
        </DisplayTextHeadline>
        <Tooltip title={`Pelajari akses saya di ${moduleTitleByRoleTypeRef[featureType]} : ${limitChar(featureTitle, 20)}`}>
          <div onClick={handleOpenListRoleBadgeSection} className={styles.listRoleIconAction}>
            <HelpOutlineIcon />
          </div>
        </Tooltip>
      </div>
      <div className={styles.userRoleSection}>
        <MyRoleItem
          user={user}
          currentRoleUser={currentRoleUser}
        />
      </div>
      <div className={styles.permissionInfo}>
        <RolePermissionInfo
          featureType={featureType}
          roleUser={currentRoleUser}
        />
      </div>
      <div className={styles.explanation}>
        {isRoleTypeCompany && (
        <DisplayTextBody>
          Ini adalah Akses default perusahaan kamu. Kamu memiliki Akses yang sama di semua tempat
          berdasarkan ini.
          Hingga Super User/Admin mengubah Akses Kamu secara khusus di suatu Tim
          atau Tugas/Dokumen lain.
          Kalo Kamu mengalami kendala saat melakukan sesuatu, harap hubungi orang diatas.
        </DisplayTextBody>
        )}
        {!isRoleTypeCompany && (
        <DisplayTextBody>
          Kamu memiliki Akses yang sama di semua tempat berdasarkan
          Akses default perusahaan Kamu.
          Hingga Creator/Manager/Admin/Super User mengubah Akses Kamu secara khusus di suatu Tim
          atau Tugas/Dokumen lain.
          Kalo Kamu mengalami kendala saat melakukan sesuatu, harap hubungi orang diatas.
        </DisplayTextBody>
        )}
      </div>
      <div className={styles.action}>
        <InputButtonMain
          size="md"
          icon={<VerifiedUser />}
          handleClick={handleOpenManageRoleSection}
        >
          {isAuthorizedToManageRoles && 'Atur Akses Pengguna'}
          {!isAuthorizedToManageRoles && 'Liat Daftar Akses Pengguna'}
        </InputButtonMain>
      </div>
    </div>
  );
};

MyRoleSection.propTypes = {
  featureType: PropTypes.string.isRequired,
  roleType: PropTypes.string.isRequired,
  featureId: PropTypes.string.isRequired,
  featureTitle: PropTypes.string.isRequired,
  currentRoleUser: PropTypes.object.isRequired,
  user: PropTypes.object.isRequired,
  handleShowListRoleBadgeSection: PropTypes.func.isRequired,
  handleHideMyRoleSection: PropTypes.func.isRequired,
  handleShowManageRoleSection: PropTypes.func.isRequired,
};

MyRoleSection.defaultProps = {};

export default memo(MyRoleSection);
