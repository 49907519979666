import { cloneDeep, slice } from 'lodash';
import { actionTypes } from '../reducers/reducer';
import { ApiConstants, CommentConstants } from '../constants';
import apiUtil from '../utilities/apiUtil';
import {
  getIndexItemByIDFromList,
  getLastListCreatedAt,
  mergeList,
  removeDuplicateByID,
} from '../utilities/arrayUtil';
import { initialState } from '../contexts/GlobalStateProvider';
import { ErrorException } from '../utilities/handleError';
import { getStore } from '../utilities/localStorage';

/*
  Dispatcher
*/

function dispatchCurrentComment({ currentComment }, dispatch) {
  dispatch({
    type: actionTypes.SET_CURRENT_COMMENT,
    currentComment,
  });
}

function dispatchCurrentCommentDiscussions({ currentCommentDiscussions }, dispatch) {
  dispatch({
    type: actionTypes.SET_CURRENT_COMMENT_DISCUSSIONS,
    currentCommentDiscussions,
  });
}

function dispatchPreviousDiscussionComment({ previousDiscussionComment }, dispatch) {
  dispatch({
    type: actionTypes.SET_PREVIOUS_DISCUSSION_COMMENT,
    previousDiscussionComment,
  });
}

function dispatchUpdateDiscussionComment({ updateDiscussionComment }, dispatch) {
  dispatch({
    type: actionTypes.UPDATE_CURRENT_COMMENT_DISCUSSIONS,
    updateDiscussionComment,
  });
}

function dispatchUpdateCurrentComment({ updateComment }, dispatch) {
  dispatch({
    type: actionTypes.UPDATE_CURRENT_COMMENT,
    updateComment,
  });
}

/*
  SetterDispatcher
*/

function setCurrentComment({ currentComment }, dispatch) {
  if (!currentComment) return;

  dispatchCurrentComment(
    { currentComment: cloneDeep(currentComment) }, dispatch,
  );
}

function setCurrentCommentDiscussions({ currentCommentDiscussions }, dispatch) {
  if (!currentCommentDiscussions) return;

  dispatchCurrentCommentDiscussions(
    { currentCommentDiscussions: cloneDeep(currentCommentDiscussions) }, dispatch,
  );
}

function setPreviousDiscussionComment({ previousDiscussionComment }, dispatch) {
  if (!previousDiscussionComment) return;

  dispatchPreviousDiscussionComment(
    { previousDiscussionComment: cloneDeep(previousDiscussionComment) }, dispatch,
  );
}

/*
  Helpers
*/

function mergeDiscussionComment({ currentComments, nextComments }) {
  if (!currentComments || !nextComments) return [];

  let newComments = mergeList(
    { currentList: currentComments, nextList: nextComments },
  );
  newComments = removeDuplicateByID(newComments);

  return newComments;
}

function modifyResponse({ commentDetail }) {
  return {
    ...commentDetail,
    discussions: null,
  };
}

function resetDiscussionComment(dispatch) {
  setCurrentComment({ currentComment: initialState.currentComment }, dispatch);
  setPreviousDiscussionComment({
    previousDiscussionComment: initialState.previousDiscussionComment,
  }, dispatch);
  setCurrentCommentDiscussions({
    currentCommentDiscussions: initialState.currentCommentDiscussions,
  }, dispatch);
}

function getNearestLimitByIndexComment(commentIndex) {
  const actualOrder = commentIndex + 1;
  const currentPageList = Math.ceil(actualOrder / CommentConstants.limitComment);
  const expectedTotalList = currentPageList * CommentConstants.limitComment;
  return expectedTotalList;
}

function getLimitCommentByPreviousCommentDiscussion() {
  const previousCommentDiscussion = getStore(
    'previousCommentDiscussion',
    { parse: true },
  );
  const limitComment = previousCommentDiscussion?.commentIndex
    ? getNearestLimitByIndexComment(
      Number(previousCommentDiscussion?.commentIndex),
    )
    : CommentConstants.limitComment;

  return limitComment;
}

function sliceLastCommentNearLimitComment(commentList) {
  const comments = commentList;
  const lengthComments = comments?.length;
  const currentPageList = Math.ceil(lengthComments / CommentConstants.limitComment);
  const previousPageList = Math.floor(lengthComments / CommentConstants.limitComment);
  const diffComments = lengthComments % CommentConstants.limitComment;
  if (diffComments === 0) {
    const firstIndexToCut = (currentPageList * CommentConstants.limitComment)
      - CommentConstants.limitComment;
    return slice(comments, firstIndexToCut, lengthComments);
  }
  return slice(comments, (previousPageList * CommentConstants.limitComment), lengthComments);
}

function sliceResultDataforLastCommentNearLimitComment(result) {
  return {
    ...result,
    data: {
      ...result.data,
      comments: sliceLastCommentNearLimitComment(
        result?.data?.comments,
      ),
    },
  };
}

/*
  Method
*/

function incomingDiscussionComment({
  discussion, typeAction, updateCommentCheer,
}, dispatch) {
  if (!discussion) return;

  const updateDiscussionComment = (currentCommentDiscussions) => {
    const listData = currentCommentDiscussions;
    const indexNewComment = getIndexItemByIDFromList(listData, discussion);

    switch (typeAction) {
      case CommentConstants.typeCallback.NEW:
        if (indexNewComment >= 0) {
          listData[indexNewComment] = discussion;
        } else {
          listData.splice(0, 0, discussion);
        }
        break;
      case CommentConstants.typeCallback.DELETE:
        if (indexNewComment >= 0) {
          listData.splice(indexNewComment, 1);
        }
        break;
      default:
        if (indexNewComment >= 0) {
          if (updateCommentCheer) {
            listData[indexNewComment] = updateCommentCheer(listData[indexNewComment]);
          } else {
            listData[indexNewComment] = discussion;
          }
        }
    }

    // Jangan lupa deep clone agar useEffect di page nya ke trigger
    const newComments = cloneDeep(listData);

    return newComments;
  };

  dispatchUpdateDiscussionComment({ updateDiscussionComment }, dispatch);
}

async function initiateDiscussionComment({ commentId, companyId, teamId }) {
  try {
    const result = await apiUtil.get(ApiConstants.URL_V2.DISCUSSION_COMMENT_LIST({ commentId }), {
      params: {
        limit: CommentConstants.limitComment,
        companyId,
        teamId,
      },
    });

    return result?.data?.discussions;
  } catch (error) {
    throw new ErrorException(error);
  }
}

async function initiateDiscussion({ commentId, companyId, teamId }, dispatch) {
  try {
    const result = await apiUtil.get(ApiConstants.URL_V2.DISCUSSION_COMMENT_DETAIL({ commentId }), {
      params: {
        companyId,
        teamId,
        noReturnTeam: true,
      },
    });
    const resultComments = await initiateDiscussionComment({ commentId, companyId, teamId });
    const modifiedResult = modifyResponse({ commentDetail: result?.data?.comment });

    setCurrentComment({ currentComment: modifiedResult }, dispatch);
    setCurrentCommentDiscussions({ currentCommentDiscussions: resultComments }, dispatch);

    return {
      ...modifiedResult,
      discussions: resultComments,
      team: result?.data?.team,
    };
  } catch (error) {
    throw new ErrorException(error);
  }
}

async function loadMoreDiscussionComment(
  {
    commentId, companyId, teamId, currentCommentDiscussions,
  },
  dispatch,
) {
  try {
    const result = await apiUtil.get(ApiConstants.URL_V2.DISCUSSION_COMMENT_LIST({ commentId }), {
      params: {
        limit: CommentConstants.limitComment,
        createdAt: getLastListCreatedAt(currentCommentDiscussions),
        companyId,
        teamId,
      },
    });

    const mergedDiscussionComment = mergeDiscussionComment({
      currentComments: currentCommentDiscussions,
      nextComments: result?.data?.discussions,
    });

    setPreviousDiscussionComment({
      previousDiscussionComment: result?.data?.discussions,
    }, dispatch);
    setCurrentCommentDiscussions({
      currentCommentDiscussions: mergedDiscussionComment,
    }, dispatch);

    return result;
  } catch (error) {
    throw new ErrorException(error);
  }
}

const getUrlApiDeleteCommentByType = ({
  params, parentIds, comment, type,
}) => {
  let id;
  let ids;
  let commentId;
  let url;
  let discussionId;

  switch (type) {
    case 'card':
      id = params.cardId || parentIds[0];
      commentId = comment._id;
      url = `${process.env.REACT_APP_PRIMARY_API_URL}/api/v1/cards/${id}/comments/${commentId}`;
      break;
    case 'cardDiscussion':
      id = params.cardId || parentIds[0];
      commentId = params.commentId || parentIds[1];
      // below is discussion id
      discussionId = comment._id;
      url = `${process.env.REACT_APP_PRIMARY_API_URL}/api/v1/cards/${id}/comments/${commentId}/discussions/${discussionId}`;
      break;
    case 'blast':
      id = params.postId;
      commentId = comment._id;
      url = `${process.env.REACT_APP_PRIMARY_API_URL}/api/v1/posts/${id}/comments/${commentId}`;
      break;
    case 'blastDiscussion':
      id = params.postId;
      commentId = params.commentId;
      // below is discussion id
      discussionId = comment._id;
      url = `${process.env.REACT_APP_PRIMARY_API_URL}/api/v1/posts/${id}/comments/${commentId}/discussions/${discussionId}`;
      break;
    case 'question':
      id = params.questionId;
      commentId = comment._id;
      url = `${process.env.REACT_APP_PRIMARY_API_URL}/api/v1/questions/${id}/comments/${commentId}`;
      break;
    case 'questionDiscussion':
      id = params.questionId;
      commentId = params.commentId;
      // below is discussion id
      discussionId = comment._id;
      url = `${process.env.REACT_APP_PRIMARY_API_URL}/api/v1/questions/${id}/comments/${commentId}/discussions/${discussionId}`;
      break;
    case 'event':
      id = params.eventId;
      commentId = comment._id;
      url = `${process.env.REACT_APP_PRIMARY_API_URL}/api/v1/events/${id}/comments/${commentId}`;
      break;
    case 'eventDiscussion':
      id = params.eventId;
      commentId = params.commentId;
      // below is discussion id
      discussionId = comment._id;
      url = `${process.env.REACT_APP_PRIMARY_API_URL}/api/v1/events/${id}/comments/${commentId}/discussions/${discussionId}`;
      break;
    case 'occurrence':
      ids = [params.eventId, params.occurrenceId];
      commentId = comment._id;
      url = `${process.env.REACT_APP_PRIMARY_API_URL}/api/v1/events/${ids[0]}/occurrences/${ids[1]}/comments/${commentId}`;
      break;
    case 'occurrenceDiscussion':
      ids = [params.eventId, params.occurrenceId];
      commentId = params.commentId;
      // below is discussion id
      discussionId = comment._id;
      url = `${process.env.REACT_APP_PRIMARY_API_URL}/api/v1/events/${ids[0]}/occurrences/${ids[1]}/comments/${commentId}/discussions/${discussionId}`;
      break;
    case 'doc':
      id = params.docId;
      commentId = comment._id;
      url = `${process.env.REACT_APP_PRIMARY_API_URL}/api/v1/docs/${id}/comments/${commentId}`;
      break;
    case 'docDiscussion':
      id = params.docId;
      commentId = params.commentId;
      // below is discussion id
      discussionId = comment._id;
      url = `${process.env.REACT_APP_PRIMARY_API_URL}/api/v1/docs/${id}/comments/${commentId}/discussions/${discussionId}`;
      break;
    case 'file':
      id = params.fileId;
      commentId = comment._id;
      url = `${process.env.REACT_APP_PRIMARY_API_URL}/api/v1/files/${id}/comments/${commentId}`;
      break;
    case 'fileDiscussion':
      id = params.fileId;
      commentId = params.commentId;
      // below is discussion id
      discussionId = comment._id;
      url = `${process.env.REACT_APP_PRIMARY_API_URL}/api/v1/files/${id}/comments/${commentId}/discussions/${discussionId}`;
      break;
    default:
        //
  }
  return url;
};

const dispatchDeleteApiCommentByType = ({ type }, dispatch) => {
  switch (type) {
    case 'card':
      dispatch({
        type: actionTypes.SET_CURRENT_COMMENT,
        currentComment: null,
      });
      dispatch({
        type: actionTypes.SET_CURRENT_COMMENT_DISCUSSIONS,
        currentCommentDiscussions: [],
      });
      // }
      break;
    case 'occurrenceDiscussion':
    case 'eventDiscussion':
    case 'docDiscussion':
    case 'questionDiscussion':
    case 'blastDiscussion':
    case 'fileDiscussion':
    case 'cardDiscussion':
      break;
    case 'blast':
      dispatch({
        type: actionTypes.SET_CURRENT_COMMENT,
        currentComment: null,
      });
      dispatch({
        type: actionTypes.SET_CURRENT_COMMENT_DISCUSSIONS,
        currentCommentDiscussions: [],
      });
      break;
    case 'question':
      dispatch({
        type: actionTypes.SET_CURRENT_COMMENT,
        currentComment: null,
      });
      dispatch({
        type: actionTypes.SET_CURRENT_COMMENT_DISCUSSIONS,
        currentCommentDiscussions: [],
      });
      break;
    case 'occurrence':
      dispatch({
        type: actionTypes.SET_CURRENT_COMMENT,
        currentComment: null,
      });
      dispatch({
        type: actionTypes.SET_CURRENT_COMMENT_DISCUSSIONS,
        currentCommentDiscussions: [],
      });
      break;
    case 'event':
      dispatch({
        type: actionTypes.SET_CURRENT_COMMENT,
        currentComment: null,
      });
      dispatch({
        type: actionTypes.SET_CURRENT_COMMENT_DISCUSSIONS,
        currentCommentDiscussions: [],
      });
      break;
    case 'doc':
      dispatch({
        type: actionTypes.SET_CURRENT_COMMENT,
        currentComment: null,
      });
      dispatch({
        type: actionTypes.SET_CURRENT_COMMENT_DISCUSSIONS,
        currentCommentDiscussions: [],
      });
      break;
    case 'file':
      dispatch({
        type: actionTypes.SET_CURRENT_COMMENT,
        currentComment: null,
      });
      dispatch({
        type: actionTypes.SET_CURRENT_COMMENT_DISCUSSIONS,
        currentCommentDiscussions: [],
      });
      break;
    default:
        //
  }
};

export {
  initiateDiscussion,
  loadMoreDiscussionComment,
  setPreviousDiscussionComment,
  setCurrentCommentDiscussions,
  resetDiscussionComment,
  incomingDiscussionComment,
  setCurrentComment,
  dispatchUpdateCurrentComment,
  sliceResultDataforLastCommentNearLimitComment,
  getLimitCommentByPreviousCommentDiscussion,
  getUrlApiDeleteCommentByType,
  dispatchDeleteApiCommentByType,
};
