import React, { memo, useState } from 'react';
import PropTypes from 'prop-types';
import { Box } from '@mui/material';
import { cloneDeep } from 'lodash';
import styles from './LabelFilterSection.module.css';
import { useDelayShowHideHandler, useSearch, useSelectItemsOnList } from '../../../../hooks/HelperHooks';
import { InputSearchGeneral } from '../../../UI_V2/Input/Search';
import Label from '../../../UI/Label/Label';
import ButtonDiv from '../../../UI/Button/ButtonDiv/ButtonDiv';
import { DisplayTextBody } from '../../../UI_V2/Display/Text';

const LabelFilterSection = ({
  searchHook,
  showAllLabels,
  handleShowAllLabels,
  handleHideAllLabels,
  checkLabels,
  handleClickLabel,
}) => {
  const {
    searchResult,
    keyword,
    keywordArray,
    setKeyword,
    isTyping,
    onChange,
    resetSearch,
  } = searchHook;

  return (
    <Box className={styles.container}>
      <Box className={styles.titleBox}>
        <DisplayTextBody>
          Filter Label
        </DisplayTextBody>
      </Box>
      <Box className={styles.searchBox}>
        <InputSearchGeneral
          onChange={onChange}
          value={keyword}
          placeholder="Cari label..."
          onClickCloseIcon={resetSearch}
          size="sm"
          autoFocus={false}
        />
      </Box>
      <Box className={styles.labels}>
        {searchResult.length < 1
          && <span>Label ga ketemu</span>}

        {showAllLabels
          ? (
            <>
              {searchResult.map((label, index) => {
                if (index === (searchResult.length - 1)) {
                  return (
                    <>
                      <Label
                        clicked={() => handleClickLabel(label._id)}
                        color={label.color.name}
                        name={label.name}
                        block
                        checked={checkLabels.some((checkLabel) => checkLabel === label._id)}
                      />
                      {index >= 4 && (
                      <ButtonDiv onClick={handleHideAllLabels} className={styles.menu}>
                        <p>Hide some</p>
                      </ButtonDiv>
                      )}
                    </>
                  );
                }
                return (
                  <Label
                    clicked={() => handleClickLabel(label._id)}
                    color={label.color.name}
                    name={label.name}
                    block
                    checked={checkLabels.some((checkLabel) => checkLabel === label._id)}
                  />
                );
              })}
            </>
          )
          : (
            <>
              {searchResult.map((label, index) => {
                if (index < 3) {
                  return (
                    <Label
                      clicked={() => handleClickLabel(label._id)}
                      color={label.color.name}
                      name={label.name}
                      block
                      checked={checkLabels.some((checkLabel) => checkLabel === label._id)}
                    />
                  );
                } if (index === 3) {
                  return (
                    <>
                      <Label
                        clicked={() => handleClickLabel(label._id)}
                        color={label.color.name}
                        name={label.name}
                        block
                        checked={checkLabels.some((checkLabel) => checkLabel === label._id)}
                      />
                      {index !== searchResult.length - 1 ? (
                        <ButtonDiv onClick={handleShowAllLabels} className={styles.menu}>
                          <p>
                            Tampilkan lagi (
                            {`${searchResult.length - 4} lagi`}
                            )
                          </p>
                        </ButtonDiv>
                      ) : null}
                    </>
                  );
                }
                return null;
              })}
            </>
          )}
      </Box>
    </Box>
  );
};

LabelFilterSection.propTypes = {
  searchHook: PropTypes.object.isRequired,
  showAllLabels: PropTypes.bool.isRequired,
  handleShowAllLabels: PropTypes.func.isRequired,
  handleHideAllLabels: PropTypes.func.isRequired,
  checkLabels: PropTypes.array.isRequired,
  handleClickLabel: PropTypes.func.isRequired,
};

export default memo(LabelFilterSection);
