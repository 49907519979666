import React from 'react';
import PropTypes from 'prop-types';
import styles from './EventDateSeparator.module.css';
import { dateBeautifyFormatter } from '../../../utilities/dateUtil';

const EventDateSeparator = ({ date }) => {
  const beautyDate = dateBeautifyFormatter(date, true);

  return (
    <div className={styles.container}>
      <p>
        {beautyDate}
      </p>
    </div>
  );
};

EventDateSeparator.propTypes = {
  date: PropTypes.instanceOf(Date).isRequired,
};

export default EventDateSeparator;
