/* eslint-disable consistent-return */
import { isEmpty } from 'lodash';
import { useEffect, useState } from 'react';

function useSocketHooks({
  params, connectSocket, listenSocket, removeListener,
}) {
  const firstParams = params[Object.keys(params)[0]];
  const secondParams = params[Object.keys(params)[1]];
  const thirdParams = params[Object.keys(params)[2]];
  const totalParams = Object.keys(params)?.length;

  const [socketInstance, setSocketInstance] = useState(null);

  const listenFromSocket = () => {
    if (isEmpty(params)) return;

    // if (process.env.NODE_ENV === 'staging' || process.env.NODE_ENV === 'development') {
    //   console.log('socket connect', { params });
    // }
    if (process.env.NODE_ENV === 'staging' || process.env.NODE_ENV === 'development') {
      // eslint-disable-next-line no-console
      console.log('socket connect', { params });
      console.log('socket instance', socketInstance);
    }

    listenSocket(socketInstance, params);

    socketInstance.on('connect', () => {
      // console.log('Socket.on :', socketInstance.connected); // true
    });

    socketInstance.on('connect_error', (error) => {
      // console.log('connect_error');
      // console.log('error message', { error });
    });

    socketInstance.on('disconnect', (reason) => {
      if (process.env.NODE_ENV === 'staging' || process.env.NODE_ENV === 'development') {
        // eslint-disable-next-line no-console
        console.log('socket disconnected');
        console.log('disconnect reason: ', { reason });
      }
      if (reason === 'io server disconnect') {
        // console.log('disconnect by server, try to reconnect');
        socketInstance.connect();
      }

      if (reason === 'io client disconnect') {
        // console.log('disconnected intentionally by client. do nothing');
      }
    });

    socketInstance.io.on('ping', () => {
      // console.log('ping', socketInstance);
    });

    socketInstance.io.on('close', () => {
      // console.log('io closed', socketInstance);
    });

    socketInstance.io.on('error', (error) => {
      if (process.env.NODE_ENV === 'staging' || process.env.NODE_ENV === 'development') {
        // eslint-disable-next-line no-console
        console.log('error', socketInstance);
        console.log('error message', error);
      }
    });

    socketInstance.io.on('reconnection_attempt', (number) => {
      if (process.env.NODE_ENV === 'staging' || process.env.NODE_ENV === 'development') {
        // eslint-disable-next-line no-console
        console.log('reconnection_attempt', socketInstance);
        console.log('trying reconnection_attempt', number);
      }
    });

    socketInstance.io.on('reconnect', (number) => {
      if (process.env.NODE_ENV === 'staging' || process.env.NODE_ENV === 'development') {
        // eslint-disable-next-line no-console
        console.log('successful reconnect', socketInstance);
        console.log('successful reconnect after attempts :', number);
      }
    });

    socketInstance.io.on('reconnect_error', (error) => {
      if (process.env.NODE_ENV === 'staging' || process.env.NODE_ENV === 'development') {
        // eslint-disable-next-line no-console
        console.log('reconnect_error', socketInstance);
        console.log('reason reconnect_error', { error });
        console.log('still trying reconnecting...');
      }
    });

    socketInstance.io.on('reconnect_failed', () => {
      if (process.env.NODE_ENV === 'staging' || process.env.NODE_ENV === 'development') {
        // eslint-disable-next-line no-console
        console.log('reconnect_failed', socketInstance);
        console.log('Thats all. We will not try to reconnect again.');
      }
    });
  };

  useEffect(() => {
    if (totalParams === 2 && (!firstParams || !secondParams)) return;
    if (totalParams === 3 && (!firstParams || !secondParams || !thirdParams)) return;

    const socket = connectSocket(params);

    setSocketInstance((prevSocket) => {
      if (prevSocket) {
        prevSocket.disconnect();
      }
      return socket;
    });
    return () => {
      removeListener(socket, params);
      // console.log('socket off & disconnect', socket);
    };
  }, [
    firstParams,
    secondParams,
    thirdParams,
  ]);

  useEffect(() => {
    if (socketInstance) {
      listenFromSocket();
    }
  }, [socketInstance]);

  return {
    socket: socketInstance,
  };
}

export {
  useSocketHooks,
};
