import React from 'react';
import PropTypes from 'prop-types';
import {
  NavLink,
} from 'react-router-dom';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import useMediaQuery from '@mui/material/useMediaQuery';
import styles from './Breadcrumb.module.css';
import { limitChar } from '../../../../../utilities/stringUtil';

const Breadcrumb = ({ routes }) => {
  const isMobile = useMediaQuery('(max-width:720px)');

  return (
    <div
      className={styles.breadCrumbSection}
    >
      <Breadcrumbs className={styles.breadCrumb} separator={<ArrowForwardIosIcon />} aria-label="breadcrumb">
        {routes.map((route, index) => {
          // if (index === routes.length - 1) return null;
          const name = limitChar(route.name, isMobile ? 10 : 20);
          return (
            <div className={styles.separateRoute}>
              <span>
                {name}
              </span>
            </div>
          );
        })}
      </Breadcrumbs>
    </div>
  );
};

Breadcrumb.propTypes = {
  routes: PropTypes.array,
};

Breadcrumb.defaultProps = {
  routes: [],
};

export default Breadcrumb;
