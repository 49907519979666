import React, {
  memo,
} from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import styles from './RoleUserItem.module.css';
import { DisplayTextBody, DisplayTextSubHeadline } from '../../../../UI_V2/Display/Text';
import SelectRoleUser from './SelectRoleUser/SelectRoleUser';
import { DisplayAvatarMember } from '../../../../UI_V2/Display/Avatar';
import RoleUserBadge from '../../RoleUserBadge/RoleUserBadge';
import { hasPermission } from '../../../../../actions/RoleActions';
import { role, roles } from '../../../../../constants/RoleConstants';

const RoleUserItem = ({
  user,
  roleUser,
  handleSelectRoleUser,
  handleUnselectRoleUser,
  disableSelectRole,
  currentRoleUser,
  isAuthorizedToManageRoles,
  isRoleTypeCompany,
}) => {
  const member = roleUser.user;

  const isUserManagerOrAboveAndHasHigherLevel = hasPermission(
    currentRoleUser, roles.managerOrAbove, roleUser,
  );
  const isUserSpvOrBelow = hasPermission(currentRoleUser, roles.spvOrBelow);

  const isMemberAdminOrAbove = hasPermission(roleUser, roles.adminOrAbove);
  const isMemberCreator = hasPermission(roleUser, [role.creator]);

  return (
    <div className={styles.container}>
      <div className={styles.member}>
        <div className={styles.member__photo}>
          <DisplayAvatarMember src={member?.photoUrl} />
        </div>
        <div className={styles.member__status}>
          <div className={styles.nameAndBadge}>
            <DisplayTextSubHeadline
              mode="16"
              decoration="bold"
            >
              {member?.fullName}
            </DisplayTextSubHeadline>
            <div className={styles.badge}>
              <RoleUserBadge roleUser={roleUser} />
            </div>
          </div>
          <DisplayTextBody>
            {member?.status}
          </DisplayTextBody>
        </div>
      </div>
      {!disableSelectRole && (
      <div className={styles.selectRoleButton}>
        <SelectRoleUser
          roleUser={roleUser}
          disabled={!isUserManagerOrAboveAndHasHigherLevel
            || isUserSpvOrBelow || !isAuthorizedToManageRoles
            || (isMemberAdminOrAbove && !isRoleTypeCompany)
            || isMemberCreator}
          handleSelectRoleUser={handleSelectRoleUser}
          handleUnselectRoleUser={handleUnselectRoleUser}
          currentRoleUser={currentRoleUser}
          isRoleTypeCompany={isRoleTypeCompany}
        />
      </div>
      )}
    </div>
  );
};

RoleUserItem.propTypes = {
  user: PropTypes.object.isRequired,
  roleUser: PropTypes.object.isRequired,
  handleSelectRoleUser: PropTypes.func.isRequired,
  handleUnselectRoleUser: PropTypes.func.isRequired,
  disableSelectRole: PropTypes.bool,
  currentRoleUser: PropTypes.object.isRequired,
  isAuthorizedToManageRoles: PropTypes.bool.isRequired,
  isRoleTypeCompany: PropTypes.bool.isRequired,
};

RoleUserItem.defaultProps = {
  disableSelectRole: false,
};

export default memo(RoleUserItem);
