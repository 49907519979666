import React, { memo, useState } from 'react';
import PropTypes from 'prop-types';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { Skeleton } from '@mui/material';
import styles from './ListItemSkeleton.module.css';
import { DisplayTextBody } from '../../../../../components/UI_V2/Display/Text';
import Color from '../../../../../themes/colors';

const ListItemSkeleton = () => (
  <div className={styles.container}>
    <div className={styles.skeleton}>
      <Skeleton
        animation="wave"
        variant="rounded"
        height={90}
      />
    </div>
    <div className={styles.skeleton}>
      <Skeleton
        animation="wave"
        variant="rounded"
        height={90}
      />
    </div>
    <div className={styles.skeleton}>
      <Skeleton
        animation="wave"
        variant="rounded"
        height={90}
      />
    </div>
    <div className={styles.skeleton}>
      <Skeleton
        animation="wave"
        variant="rounded"
        height={90}
      />
    </div>
    <div className={styles.skeleton}>
      <Skeleton
        animation="wave"
        variant="rounded"
        height={90}
      />
    </div>
  </div>
);

ListItemSkeleton.propTypes = {};

ListItemSkeleton.defaultProps = {};

export default memo(ListItemSkeleton);
