import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import styles from './SidebarUserReport.module.css';
import Color from '../../../themes/colors';
import OverviewOptionButton from '../../../components/GeneralSubNavBar/BottomNavBar/OverviewOptionButton/OverviewOptionButton';
import { DisplayDividerMain } from '../../../components/UI_V2/Display/Divider';
import SelectTeamUserReport from './SelectTeamUserReport/SelectTeamUserReport';
import SelectMemberUserReport from './SelectMemberUserReport/SelectMemberUserReport';
import SelectPeriod from './SelectPeriod/SelectPeriod';
import BadgePeriod from './BadgePeriod/BadgePeriod';

const SidebarUserReport = ({
  handleSetFilteredRoleMembers,
  recapMonthWeeklyProps,
  realtimeProps,
  variantUrl,
  handleSetVariantUrl,
}) => (
  <div className={styles.container}>
    <div className={styles.badge}>
      <BadgePeriod variant={variantUrl} />
    </div>
    <div className={styles.list}>
      <div className={styles.filter}>
        <SelectPeriod
          recapMonthWeeklyProps={recapMonthWeeklyProps}
          realtimeProps={realtimeProps}
          handleSetVariantUrl={handleSetVariantUrl}
          variantUrl={variantUrl}
        />
      </div>
      <DisplayDividerMain
        borderColor={Color.gray6}
      />
      <div className={styles.team}>
        <SelectTeamUserReport
          variantUrlTeam={variantUrl}
        />
      </div>
      <DisplayDividerMain
        borderColor={Color.gray6}
      />
      <div className={styles.member}>
        <SelectMemberUserReport
          handleSetFilteredRoleMembers={handleSetFilteredRoleMembers}
          variantUrlMember={variantUrl}
        />
      </div>
    </div>
  </div>
);

SidebarUserReport.propTypes = {
  realtimeProps: PropTypes.object,
  recapMonthWeeklyProps: PropTypes.object,
  handleSetFilteredRoleMembers: PropTypes.func.isRequired,
  variantUrl: PropTypes.string,
  handleSetVariantUrl: PropTypes.func.isRequired,
};

SidebarUserReport.defaultProps = {
  realtimeProps: {
    fromDate: null,
    untilDate: null,
    selectedQuickDate: null,
    handleSetRealtimeDate: () => null,
  },
  recapMonthWeeklyProps: {
    month: null,
    year: null,
    handleSetMonth: () => null,
    handleSetYear: () => null,
  },
  variantUrl: 'realtime',
};

export default memo(SidebarUserReport);
