import React, { memo } from 'react';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import PropTypes from 'prop-types';
import { DisplayTextHeadline, DisplayTextSubHeadline } from '../../../../components/UI_V2/Display/Text';
import styles from './SceneTutorial.module.css';
import Color from '../../../../themes/colors';
import tutorial1Image from '../../../../assets/ModalWelcomeTrial/tutorial1.png';
import tutorial2Image from '../../../../assets/ModalWelcomeTrial/tutorial2.png';
import { CompanyConstants } from '../../../../constants';
import { InputButtonMain } from '../../../../components/UI_V2/Input/Button';
import LinkNoDecor from '../../../../components/LinkNoDecor/LinkNoDecor';
import LinkNoDecorV2 from '../../../../components/LinkNoDecor/LinkNoDecorV2/LinkNoDecorV2';

const SceneTutorial = ({
  handleClickNext, isNewlyCreatedOrJoinedCompany
}) => {
  const handleClickOpenTutorial = (url) => {
    window.open(url, '_blank');
  };

  return (
    <div className={styles.container}>
      <div className={styles.title}>
        <DisplayTextHeadline
          color={Color.blueNavyCicle}
          mode="48"
          decoration="bold"
          position="center"
        >
          {isNewlyCreatedOrJoinedCompany ? 'Selamat Datang di Perusahaan-mu!' : 'Pelajari cara pakai Cicle'}
        </DisplayTextHeadline>
      </div>
      <div className={styles.text}>
        <DisplayTextSubHeadline
          color={Color.gray3}
          position="center"
        >
          Gapapa kalo kamu belum paham cara pake Cicle, kamu bisa klik gambar dibawah
          untuk liat Tutorial-Tutorial Cicle.
        </DisplayTextSubHeadline>
      </div>
      <LinkNoDecorV2 to={CompanyConstants.cicleTutorialUrl} isOpenNewTab>
        <div
          className={styles.tutorial}
        >
          <div className={styles.image}>
            <LazyLoadImage
              alt="logo"
              src={tutorial1Image}
            />
          </div>
          {/* <div className={styles.image}>
            <LazyLoadImage
              alt="logo"
              src={tutorial2Image}
              width={400}
            />
          </div> */}
        </div>
      </LinkNoDecorV2>
      <div className={styles.buttonGroup}>
        <LinkNoDecorV2 to={CompanyConstants.cicleTutorialUrl} isOpenNewTab>
          <div className={styles.button}>
            <InputButtonMain
              variant="outlinedDefault"
            //   handleClick={(() => handleClickOpenTutorial(CompanyConstants.cicleTutorialUrl))}
              border="rounded"
              wide="wider"
            >
              Cek Tutorial
            </InputButtonMain>
          </div>
        </LinkNoDecorV2>
        <div className={styles.button}>
          <InputButtonMain
            handleClick={handleClickNext}
            border="rounded"
            wide="wider"
        //   loadings={loadings}
            // wait="saveProfile"
          >
            Selanjutnya
          </InputButtonMain>
        </div>
      </div>

    </div>
  );
};

SceneTutorial.propTypes = {
  handleClickNext: PropTypes.func.isRequired,
  isNewlyCreatedOrJoinedCompany: PropTypes.bool.isRequired,
};

export default memo(SceneTutorial);
