import React, {
  memo,
} from 'react';
import PropTypes from 'prop-types';
import limitChar from '../../../utilities/limitChar';
import styles from './HeadlineSubNavBar.module.css';
import { DisplayTextSubHeadline } from '../../UI_V2/Display/Text';

const HeadlineSubNavBar = ({ text }) => {
  const limitedText = limitChar(text, 30);

  return (
    <DisplayTextSubHeadline
      decoration="extra_bold"
      mode="20"
      color="#393E46"
      style={{ whiteSpace: 'nowrap' }}
    >
      {limitedText}
    </DisplayTextSubHeadline>
  );
};

HeadlineSubNavBar.propTypes = {
  text: PropTypes.string.isRequired,
};

export default memo(HeadlineSubNavBar);
