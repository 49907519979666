import React from 'react';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import ModeEditOutlineOutlinedIcon from '@mui/icons-material/ModeEditOutlineOutlined';
import PropTypes from 'prop-types';
import styles from './CommentMenu.module.css';
import { NavigationMenuBasic, NavigationMenuItem } from '../../../../../components/UI_V2/Navigation/Menu';

const CommentMenu = ({
  handleCancelCommentMenu,
  handleShowEditForm,
  handleShowDeletePopUp,
  disabledOutsideClickHandlerMenu,
}) => (
  <NavigationMenuBasic
    onClose={handleCancelCommentMenu}
    position="topRight"
    disabled={disabledOutsideClickHandlerMenu}
  >
    <NavigationMenuItem
      icon={<ModeEditOutlineOutlinedIcon />}
      text="Ubah"
      onClick={handleShowEditForm}
    />

    <NavigationMenuItem
      onClick={handleShowDeletePopUp}
      icon={<DeleteOutlineOutlinedIcon />}
      text="Hapus"
      variant="danger"
      disableDivider
    />
  </NavigationMenuBasic>
);

CommentMenu.propTypes = {
  handleCancelCommentMenu: PropTypes.func.isRequired,
  handleShowEditForm: PropTypes.func.isRequired,
  handleShowDeletePopUp: PropTypes.func.isRequired,
  disabledOutsideClickHandlerMenu: PropTypes.bool.isRequired,
};

export default CommentMenu;
