import React, { useState } from 'react';
import CloseIcon from '@mui/icons-material/Close';
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined';
import VisibilityIcon from '@mui/icons-material/Visibility';
import PropTypes from 'prop-types';
import BoxPopOver from '../../../../../components/UI/BoxPopOver/BoxPopOver';
import SeparatorLine from '../../../../../components/UI/SeparatorLine/SeparatorLine';
import styles from './MessageMenuPopUp.module.css';
import OverlayButton from '../../../../../components/UI/Button/OverlayButton/OverlayButton';
import { elementId } from '../../../../../constants/ElementIdConstants';

const MessageMenuPopUp = ({
  onClose,
  onDelete,
  parentDivRef,
  owner,
}) => {
  const [loadings, setLoadings] = useState([]);

  const target = parentDivRef.current.getBoundingClientRect();
  const isBottomAreaMessage = target.top > 450;

  const isOtherMessage = owner === 'other';
  return (
    <BoxPopOver left={isOtherMessage} customStyle={isBottomAreaMessage ? { top: '-100px' } : {}}>
      <div className={styles.headerSection}>
        <h1>
          Menu Pesan
        </h1>
        <CloseIcon onClick={onClose} />
      </div>
      <SeparatorLine />
      <div className={styles.bodySection}>
        <OverlayButton wait="messageMenu" loadings={loadings}>
          <div id={elementId.messageDeleteButton} onClick={onDelete} className={styles.menu}>
            <DeleteOutlinedIcon />
            <p>Hapus Pesan</p>
          </div>
        </OverlayButton>
      </div>
    </BoxPopOver>
  );
};

MessageMenuPopUp.propTypes = {
  onClose: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
  parentDivRef: PropTypes.node.isRequired,
  owner: PropTypes.string.isRequired,
};

export default MessageMenuPopUp;
