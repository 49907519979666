import React, { useState, useEffect } from 'react';
import CloseIcon from '@mui/icons-material/Close';
import { useSnackbar } from 'notistack';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import EditIcon from '@mui/icons-material/Edit';
import ModeEditOutlineOutlinedIcon from '@mui/icons-material/ModeEditOutlineOutlined';
import ArchiveOutlinedIcon from '@mui/icons-material/ArchiveOutlined';
import PropTypes from 'prop-types';
import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked';
import RadioButtonCheckedIcon from '@mui/icons-material/RadioButtonChecked';
import styles from './SelectRoleUserMenu.module.css';
import { NavigationMenuBasic, NavigationMenuItem } from '../../../../../../UI_V2/Navigation/Menu';
import { role, roleTitle, roles } from '../../../../../../../constants/RoleConstants';
import { hasPermission } from '../../../../../../../actions/RoleActions';

const SelectRoleUserMenu = ({
  roleUser,
  handleSelectRoleUser,
  handleUnselectRoleUser,
  handleUpdateTempRoleUser,
  tempRoleUser,
  cancel,
  currentRoleUser,
  isRoleTypeCompany,
}) => {
  const { enqueueSnackbar } = useSnackbar();
  const [loadings, setLoadings] = useState([]);
  const [selectedRole, setSelectedRole] = useState(tempRoleUser.role);
  const history = useHistory();
  const location = useLocation();
  const params = useParams();
  const {
    companyId, teamId, blastId,
  } = params;

  const handleClick = (newRole) => {
    setSelectedRole(newRole);

    const payload = {
      ...roleUser,
      role: newRole,
    };

    if (newRole === roleUser.role) {
      handleUnselectRoleUser(payload);
    } else {
      handleSelectRoleUser(payload);
    }

    handleUpdateTempRoleUser(payload);

    cancel();
  };

  const isUserSuper = hasPermission(currentRoleUser, [role.super]);
  const isUserManagerOrAbove = hasPermission(currentRoleUser, roles.managerOrAbove);
  const isUserAdminOrAbove = hasPermission(currentRoleUser, roles.adminOrAbove);

  return (
    <NavigationMenuBasic
      onClose={cancel}
      position="topRight"
    >
      {isUserSuper && isRoleTypeCompany && (
      <NavigationMenuItem
        icon={selectedRole !== role.admin
          ? <RadioButtonUncheckedIcon /> : <RadioButtonCheckedIcon />}
        text={roleTitle.admin}
        onClick={() => handleClick(role.admin)}
      />
      )}
      {isUserAdminOrAbove && (
      <NavigationMenuItem
        icon={selectedRole !== role.manager
          ? <RadioButtonUncheckedIcon /> : <RadioButtonCheckedIcon />}
        text={roleTitle.manager}
        onClick={() => handleClick(role.manager)}
      />
      )}
      {isUserManagerOrAbove && (
      <NavigationMenuItem
        icon={selectedRole !== role.spv
          ? <RadioButtonUncheckedIcon /> : <RadioButtonCheckedIcon />}
        text={roleTitle.spv}
        onClick={() => handleClick(role.spv)}
      />
      )}
      {isUserManagerOrAbove && (
      <NavigationMenuItem
        icon={selectedRole !== role.member
          ? <RadioButtonUncheckedIcon /> : <RadioButtonCheckedIcon />}
        text={roleTitle.member}
        onClick={() => handleClick(role.member)}
        disableDivider
      />
      )}
    </NavigationMenuBasic>
  );
};

SelectRoleUserMenu.propTypes = {
  roleUser: PropTypes.object.isRequired,
  tempRoleUser: PropTypes.object.isRequired,
  cancel: PropTypes.func.isRequired,
  handleSelectRoleUser: PropTypes.func.isRequired,
  handleUnselectRoleUser: PropTypes.func.isRequired,
  handleUpdateTempRoleUser: PropTypes.func.isRequired,
  currentRoleUser: PropTypes.object.isRequired,
  isRoleTypeCompany: PropTypes.bool.isRequired,
};

export default SelectRoleUserMenu;
