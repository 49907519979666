import React, { memo, useState } from 'react';
import PropTypes from 'prop-types';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import styles from './LegendCardItem.module.css';
import Color from '../../../../../../themes/colors';
import { DisplayTextBody } from '../../../../../../components/UI_V2/Display/Text';
import { ReportConstants } from '../../../../../../constants';

const boxTypeStyles = {
  backlog: styles.backlog,
  ongoing: styles.ongoing,
  overdue: styles.overdue,
  completed: styles.completed,
};

const titleType = {
  backlog: 'Belum',
  ongoing: 'Dikerjakan',
  overdue: 'Terlambat',
  completed: 'Selesai',
};

const LegendCardItem = ({
  value,
  percentage,
  type,
}) => (
  <div className={styles.container}>
    <div className={`${styles.box} ${boxTypeStyles[type]}`} />
    <div className={styles.boxTitle}>
      <DisplayTextBody>
        {`${titleType[type]} : `}
      </DisplayTextBody>
    </div>
    <div>
      <DisplayTextBody>
        {`${value} (${percentage}%)`}
      </DisplayTextBody>
    </div>
  </div>
);

LegendCardItem.propTypes = {
  value: PropTypes.number,
  percentage: PropTypes.number,
  type: PropTypes.oneOf([
    ReportConstants.typeRef.overdue,
    ReportConstants.typeRef.ongoing,
    ReportConstants.typeRef.backlog,
    ReportConstants.typeRef.completed,
  ]),
};

LegendCardItem.defaultProps = {
  value: 0,
  percentage: 0,
  type: ReportConstants.typeRef.overdue,
};

export default memo(LegendCardItem);
