import React, {
  useState, useContext, useEffect, useCallback,
} from 'react';
import { useHistory, useParams, useLocation } from 'react-router-dom';
import AddIcon from '@mui/icons-material/Add';
import ArchiveOutlinedIcon from '@mui/icons-material/ArchiveOutlined';
import FilterAltOutlinedIcon from '@mui/icons-material/FilterAltOutlined';
import VerifiedUserIcon from '@mui/icons-material/VerifiedUser';
import MainLayout from '../../components/Layout/MainLayout/MainLayout';
import { GlobalContext, initialState } from '../../contexts/GlobalStateProvider';
import { actionTypes } from '../../reducers/reducer';
import Paper from '../../components/UI/Paper/Paper';
import styles from './CheckInPage.module.css';
import Question from './Question/Question';
import Title from '../../components/Title/Title';
import handleStatusMsg from '../../utilities/handleStatusMsg';
import handleLoadings from '../../utilities/handleLoadings';
import PageLoading from '../../components/UI/Button/PageLoading/PageLoading';
import { CheckInActions, SearchJumpToActions, TeamActions } from '../../actions';
import { CheckInConstants, PageConstants } from '../../constants';
import { useInfinityScrollHooks } from '../../hooks/InfinityScrollHooks';
import InfinityScroll from '../../components/UI/InfinityScroll/InfinityScroll';
import { useCheckInSocket } from '../../hooks/CheckInHooks';
import { checkIfUserHaveAccessToDataV3 } from '../../actions/UserActions';
import GeneralSubNavBar from '../../components/GeneralSubNavBar/GeneralSubNavBar';
import GlobalActionButton from '../../components/GeneralSubNavBar/BottomNavBar/GlobalActionButton/GlobalActionButton';
import { generateRoutesAndTitle } from '../../actions/BreadCrumbActions';
import LinkNoDecor from '../../components/LinkNoDecor/LinkNoDecor';
import { useDelayShowHideHandler } from '../../hooks/HelperHooks';
import { useInitiateRoleUser } from '../../hooks/RoleHooks';
import { roleTypeRef, typeRef } from '../../constants/RoleConstants';
import MyRoleUser from '../../components/Container/MyRoleUser/MyRoleUser';
import { SurfacePaperMain } from '../../components/UI_V2/Surface/Paper';
import { LayoutMain } from '../../components/UI_V2/Layout';

function CheckInPage() {
  const [{
    user, currentTeam,
    currentCheckIn,
    previousCheckIn, currentCompany,
    currentRoleUser,
  }, dispatch] = useContext(GlobalContext);
  const [loadings, setLoadings] = useState([]);
  // const [
  //   showModalRoleUser,
  //   handleShowModalRoleUser,
  //   handleHideModalRoleUser,
  // ] = useDelayShowHideHandler();

  const history = useHistory();
  const location = useLocation();
  const params = useParams();
  const { companyId, teamId, checkInId } = params;

  useInitiateRoleUser({
    payload: {
      teamId,
      featureType: typeRef.checkIn,
      featureId: checkInId,
    },
  });

  useCheckInSocket({ checkInId, userId: user?._id }, dispatch);

  const getMoreLists = async () => {
    const result = await CheckInActions.loadMoreCheckIn(
      {
        checkInId, companyId, teamId, currentCheckIn,
      },
      dispatch,
    );

    return result;
  };

  const resetPreviousLists = useCallback(() => {
    CheckInActions.setPreviousCheckIn({
      previousCheckIn: initialState.previousCheckIn,
    }, dispatch);
  }, []);

  const {
    lists,
    handleLoadMoreLists,
    checkIfListNeedToLoad,
    setCheckLoadMore,
    isListsLengthAtTheLimitOrAbove,
  } = useInfinityScrollHooks({
    currentObjectWithKeyProperty: currentCheckIn,
    previousLists: previousCheckIn?.questions,
    resetPreviousLists,
    getMoreLists,
    keyProperty: 'questions',
    limitList: CheckInConstants.limitCheckIn,
  });

  useEffect(() => {
    if (user._id === undefined) {
      history.push(`/check-login?previousPath=${location.pathname}`);
      return;
    }

    const fetchApiCheckInDetail = async () => {
      const startLoadings = handleLoadings('checkInPage', [...loadings], 'start');
      setLoadings([...startLoadings]);

      try {
        const resultV2 = await CheckInActions.initiateCheckInV2(
          { checkInId, companyId, teamId }, dispatch,
        );
        await TeamActions.initiateTeam({
          teamId,
          companyId,
          currentTeam,
        }, dispatch);
        if (isListsLengthAtTheLimitOrAbove(resultV2?.questions)) {
          setCheckLoadMore(true);
        }
      } catch (err) {
        const status = handleStatusMsg(err, 'error');

        dispatch({
          type: actionTypes.SET_ERROR_RESPONSE,
          errorResponse: { message: status.message },
        });

        history.push(`/errors?previousPath=${location.pathname}`);
      } finally {
        const endLoadings = handleLoadings('checkInPage', [...loadings], 'end');
        setLoadings([...endLoadings]);
      }
    };
    fetchApiCheckInDetail();
  }, [location]);

  const handleCreateCheckIn = () => {
    history.push(`${location.pathname}/create`);
  };
  const pageType = PageConstants.pageType.checkIns;
  const breadcrumb = generateRoutesAndTitle({ type: pageType, data: { companyId, currentTeam } });
  SearchJumpToActions.saveRecentPage({
    company: currentCompany,
    team: currentTeam,
    userId: user?._id,
    itemId: checkInId,
    url: breadcrumb.url,
    routes: breadcrumb.routes,
    name: breadcrumb.title,
    type: pageType,
  });
  return (
    <>
      <Title title="List Pertanyaan" />
      <GeneralSubNavBar
        breadcrumbRoutes={breadcrumb.routes}
        pageTitle={breadcrumb.title}
        bottomBarOverviewOptionId="check-ins"
        bottomBarGlobalActions={(
          <>
            <GlobalActionButton handleClick={handleCreateCheckIn} text="Buat Pertanyaan" icon={<AddIcon />} />
            {/* {
            currentTeam?._id === teamId
            && (
            <GlobalActionButton
              handleClick={handleShowModalRoleUser}
              text="My Role Here"
              icon={<VerifiedUserIcon />}
            />
          )
} */}
          </>
        )}
      />
      <LayoutMain>
        <PageLoading wait="checkInPage" loadings={loadings}>
          <SurfacePaperMain>
            <div className={styles.questionsSection}>
              <InfinityScroll
                dataLength={lists?.length || 0}
                hasMore={checkIfListNeedToLoad()}
                next={handleLoadMoreLists}
                emptyMessage="Belum ada Pertanyaan disini..."
              >
                {lists !== undefined
                  ? (
                    <>
                      {lists.map((question) => {
                        if (!checkIfUserHaveAccessToDataV3(question, user, currentRoleUser)) {
                          return null;
                        }
                        return (
                          <LinkNoDecor to={`/companies/${companyId}/teams/${teamId}/questions/${question?._id}`}>
                            <div className={styles.questionItem}>
                              <Question
                                key={question?._id}
                                question={question}
                              />
                            </div>
                          </LinkNoDecor>
                        );
                      })}
                    </>
                  ) : null}
              </InfinityScroll>
            </div>
          </SurfacePaperMain>
        </PageLoading>
        {/* <MyRoleUser
          open={showModalRoleUser}
          onClose={handleHideModalRoleUser}
          featureTitle={currentTeam?.name}
          featureType={typeRef.checkIn}
          featureId={checkInId}
          roleType={roleTypeRef.team}
        /> */}
      </LayoutMain>
    </>
  );
}

export default CheckInPage;
