import React, { useContext, useState, useEffect } from 'react';
import _ from 'lodash';
import PropTypes from 'prop-types';
import Card from './Card/Card';
import { GlobalContext } from '../../../contexts/GlobalStateProvider';
import { getStoreTeamsColor } from '../../../utilities/localStorage';
import { DisplayTextBody } from '../../../components/UI_V2/Display/Text';
import styles from './FetchTeamsCards.module.css';
// import LinkNoDecor from '../../../components/LinkNoDecor/LinkNoDecor';
// import { CompanyConstants } from '../../../constants';
// import LinkNoDecorV2 from '../../../components/LinkNoDecor/LinkNoDecorV2/LinkNoDecorV2';

const FetchTeamsCards = ({
  type, companyId, teams, isCanCreateTeam,
}) => {
  const [{ user, shouldCheckTeamsColor }] = useContext(GlobalContext);
  const [teamsColor, setTeamsColor] = useState([]);

  useEffect(() => {
    if (!companyId) return;
    if (!user?._id) return;
    setTeamsColor(getStoreTeamsColor({
      companyId, userId: user?._id,
    }) || []);
  }, [companyId, user?._id, shouldCheckTeamsColor]);
  // const hqColors = [
  //   '#0880AE',
  //   '#E2007C',
  //   '#93D144',
  //   '#F0B418',
  //   '#FA78CD',
  //   '#32B4FD',
  //   '#535c68',
  //   '#FD6332',
  //   '#03C4A1',
  // ];

  // const teamColors = [
  //   '#32B4FD',
  //   '#03C4A1',
  //   '#FD6332',
  //   '#0880AE',
  //   '#E2007C',
  //   '#93D144',
  //   '#535c68',
  //   '#F0B418',
  //   '#FA78CD',
  // ];

  // const projectColors = [
  //   '#F0B418',
  //   '#FA78CD',
  //   '#535c68',
  //   '#0880AE',
  //   '#E2007C',
  //   '#93D144',
  //   '#FD6332',
  //   '#03C4A1',
  //   '#32B4FD',
  // ];

  // let number = -1;
  // let colors = [];

  // only show specific team type
  let filteredTeams = _.cloneDeep(teams);
  filteredTeams = filteredTeams.filter((team) => team.type === type);
  // only show team that has archived status false
  filteredTeams = filteredTeams.filter(
    (team) => team.archived.status === false || team.archived === undefined,
  );

  // let infoMsg = {};
  // switch (type) {
  //   case 'hq':
  //     // infoMsg = {
  //     //   title: 'Here is the area of your Headquarter.',
  //     //   desc: 'Just like your main Whatsapp Group for the company where
  //     // everybody is gathering. Create a new one by clicking yellow button on the right,
  //     // or ask your leader to invite you.',
  //     // };
  //     colors = [...hqColors];
  //     break;
  //   case 'team':
  //     // infoMsg = {
  //     //   title: 'Here is the area of your Teams.',
  //     //   desc: 'Usually for your main Division. For examples : Marketing Division,
  //     // Content Division, Engineer Division. Create a team by clicking yellow button
  //     // on the right, or ask your leader to invite you.',
  //     // };
  //     colors = [...teamColors];
  //     break;
  //   case 'project':
  //     // infoMsg = {
  //     //   title: 'Here is the area of your Projects.',
  //     //   desc: 'For collaborating with multiple Division. For
  //     // examples : Marketing & Engineer for Tracking Customers Complain.
  //     // You can create a new one or ask your leader to invite you too.',
  //     // };
  //     colors = [...projectColors];
  //     break;

  //   default:
  //     break;
  // }

  // eslint-disable-next-line implicit-arrow-linebreak
  return (
    <>
      {filteredTeams.map((team, index) =>
      // const teamId = team._id;
      // if (number === 8) {
      //   number = -1;
      // }
      // number += 1;
        // eslint-disable-next-line implicit-arrow-linebreak
        (
        // <LinkNoDecor to={`/companies/${companyId}/teams/${teamId}`}>
          <Card
            isCanCreateTeam={isCanCreateTeam}
            companyId={companyId}
            team={team}
            // color={colors[number]}
            key={team._id}
            index={index}
            teamsColor={teamsColor}
          />
        // </LinkNoDecor>
        ))}
      {filteredTeams.length < 1 && (
        <div className={styles.emptySection}>
          <DisplayTextBody>
            Belum ada tim
          </DisplayTextBody>
        </div>
      )}
      {/* {filteredTeams.length < 1
        ? (
          <div className={styles.infoSection}>
            <h1>{infoMsg.title}</h1>
            <p>
              {infoMsg.desc}
              {' '}
              <LinkNoDecorV2 to={CompanyConstants.cicleTutorialCreateTeam} isOpenNewTab>
                <span style={{ fontWeight: 'bold', fontStyle: 'italic' }}>Learn More</span>
              </LinkNoDecorV2>
            </p>
          </div>
        ) : null} */}
    </>
  );
};
FetchTeamsCards.propTypes = {
  type: PropTypes.string.isRequired,
  companyId: PropTypes.string.isRequired,
  teams: PropTypes.array.isRequired,
  isCanCreateTeam: PropTypes.bool.isRequired,
};

export default FetchTeamsCards;
