const limitCheckIn = 10;
const typeCallback = {
  NEW: 'new',
  DELETE: 'delete',
  EDIT: 'edit',
};

export {
  limitCheckIn,
  typeCallback,
};
