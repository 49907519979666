import { pageProperties } from './PageConstants';

const menus = [
  {
    _id: 'teams',
    title: 'Ringkasan',
    icon: pageProperties.teams.icon,
  },
  {
    _id: 'boards',
    title: 'Kanban',
    icon: pageProperties.boards.icon,
  },
  {
    _id: 'boardsList',
    title: 'List',
    icon: pageProperties.boardsList.icon,
  },
  {
    _id: 'boardsCalendar',
    title: 'Kalender',
    icon: pageProperties.boardsCalendar.icon,
  },
  {
    _id: 'group-chats',
    title: 'Chat Grup',
    icon: pageProperties.groupChats.icon,
  },
  {
    _id: 'buckets',
    title: 'Dokumen & File',
    icon: pageProperties.buckets.icon,
  },
  {
    _id: 'reports',
    title: 'Laporan',
    icon: pageProperties.reports.icon,
  },
  {
    _id: 'check-ins',
    title: 'Pertanyaan',
    icon: pageProperties.checkIns.icon,
  },
  {
    _id: 'blasts',
    title: 'Pengumuman',
    icon: pageProperties.blasts.icon,
  },
  {
    _id: 'schedules',
    title: 'Jadwal',
    icon: pageProperties.schedules.icon,
  },
];

const menusMyTaskCard = [
  {
    _id: 'myTasksList',
    title: 'List',
    icon: pageProperties.myTasksList.icon,
  },
  {
    _id: 'myTasksCalendar',
    title: 'Kalender',
    icon: pageProperties.myTasksCalendar.icon,
  },
];

export {
  menus,
  menusMyTaskCard,
};
