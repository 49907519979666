import React, { useState } from 'react';
import CloseIcon from '@mui/icons-material/Close';
import { useHistory, useLocation } from 'react-router-dom';
import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile';
import CreateNewFolderIcon from '@mui/icons-material/CreateNewFolder';
import PublishIcon from '@mui/icons-material/Publish';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import PropTypes from 'prop-types';
import CreateFolderContainer from './CreateFolderContainer/CreateFolderContainer';
import styles from './NewItemMenu.module.css';
import SeparatorLine from '../../../components/UI/SeparatorLine/SeparatorLine';
import BoxPopOver from '../../../components/UI/BoxPopOver/BoxPopOver';
import AttachFileContainer from '../../AttachFileContainer/AttachFileContainer';

const NewItemMenu = ({
  list, handleLoading,
  handleUploadFiles,
  cancel,
}) => {
  const [showCreateFolder, setShowCreateFolder] = useState(false);

  const history = useHistory();
  const location = useLocation();

  const handleCreateBucket = () => {
    history.push(`${location.pathname}/create`);
  };

  const handleClear = () => {
    setShowCreateFolder(false);
  };

  const handleShowCreateFolder = () => {
    setShowCreateFolder(true);
  };
  return (
    <BoxPopOver left>
      <div className={styles.headerSection}>
        {showCreateFolder ? <ArrowBackIosIcon onClick={handleClear} /> : null }
        <h1>
          {showCreateFolder ? 'Buat Folder' : 'Tambah Berkas ke Folder ini'}
        </h1>
        <CloseIcon onClick={cancel} />
      </div>
      <SeparatorLine />
      <div className={styles.bodySection}>
        {showCreateFolder ? <CreateFolderContainer cancel={cancel} /> : (
          <>
            <div onClick={handleCreateBucket} className={styles.menu}>
              <InsertDriveFileIcon />
              <p>Tambah Dokumen</p>
            </div>
            <div onClick={handleShowCreateFolder} className={styles.menu}>
              <CreateNewFolderIcon />
              <p>Buat Folder</p>
            </div>
            <AttachFileContainer
              handleUploadForm={handleUploadFiles}
              handleLoading={handleLoading}
              cancel={cancel}
              type="file"
            >
              <div className={styles.menu}>
                <PublishIcon />
                <p>Unggah File</p>
              </div>
            </AttachFileContainer>
          </>
        )}
      </div>
      {/* <div className={styles.actionSection}>
        <Button variant="danger" size="sm" block>Delete</Button>
      </div> */}
    </BoxPopOver>
  );
};

NewItemMenu.propTypes = {
  list: PropTypes.object.isRequired,
  handleLoading: PropTypes.func.isRequired,
  cancel: PropTypes.func.isRequired,
  handleUploadFiles: PropTypes.func.isRequired,
};

export default NewItemMenu;
