import React, {
  useState, useContext, useEffect, useCallback, createRef,
} from 'react';
import { useHistory, useParams, useLocation } from 'react-router-dom';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import ArchiveIcon from '@mui/icons-material/Archive';
import AddIcon from '@mui/icons-material/Add';
import VerifiedUserIcon from '@mui/icons-material/VerifiedUser';
import MainLayout from '../../components/Layout/MainLayout/MainLayout';
import { GlobalContext, initialState } from '../../contexts/GlobalStateProvider';
import { actionTypes } from '../../reducers/reducer';
import SeparatorLine from '../../components/UI/SeparatorLine/SeparatorLine';
import Paper from '../../components/UI/Paper/Paper';
import '../../../node_modules/react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import styles from './QuestionPage.module.css';
import CommentsSectionContainer from '../CommentsSectionContainer/CommentsSectionContainer';
import RoundActionMenu from '../../components/UI/RoundActionMenu/RoundActionMenu';
import QuestionMenu from './QuestionMenu/QuestionMenu';
import Title from '../../components/Title/Title';
import handleLoadings from '../../utilities/handleLoadings';
import PageLoading from '../../components/UI/Button/PageLoading/PageLoading';
import handleStatusMsg from '../../utilities/handleStatusMsg';
import {
  CommentActions, QuestionActions, SearchJumpToActions, TeamActions,
} from '../../actions';
import InfinityScroll from '../../components/UI/InfinityScroll/InfinityScroll';
import { useQuestionCheerNCommentSocket, useQuestionSocket } from '../../hooks/QuestionHooks';
import { useInfinityScrollHooks } from '../../hooks/InfinityScrollHooks';
import { useScrollToTargetComment } from '../../hooks/DiscussionHooks';
import PrivateIcon from '../../components/UI/PrivateIcon/PrivateIcon';
import GeneralSubNavBar from '../../components/GeneralSubNavBar/GeneralSubNavBar';
import { generateRoutesAndTitle } from '../../actions/BreadCrumbActions';
import GlobalActionButton from '../../components/GeneralSubNavBar/BottomNavBar/GlobalActionButton/GlobalActionButton';
import { PageConstants } from '../../constants';
import MyRoleUser from '../../components/Container/MyRoleUser/MyRoleUser';
import { roleTypeRef, typeRef } from '../../constants/RoleConstants';
import { useDelayShowHideHandler } from '../../hooks/HelperHooks';
import { useInitiateRoleUser } from '../../hooks/RoleHooks';
import { checkIfUserAuthorizedToEditData } from '../../actions/UserActions';
import { useInitiateTeamBelowRole } from '../../hooks/TeamHooks';
import ActivityByModule from '../ActivityByModule/ActivityByModule';
import { serviceTypeRef } from '../../constants/ActivityConstants';
import { DisplayCreatorMain } from '../../components/UI_V2/Display/Creator';
import { useUserTypingQuestionSocket } from '../../hooks/UserTypingHooks';
import UserTyping from '../../components/Container/UserTyping/UserTyping';
import { useMarkReadNotificationOnPageOpen } from '../../hooks/NotificationHooks';
import { LayoutMain } from '../../components/UI_V2/Layout';
import { SurfacePaperMain } from '../../components/UI_V2/Surface/Paper';

function QuestionPage() {
  const [{
    user,
    currentTeam,
    currentCheckIn,
    currentCheckInQuestion,
    previousCheckInComment,
    currentCompany,
    currentRoleUser,
    currentTeamBelowRole,
    allUserTypingQuestion,
  }, dispatch] = useContext(GlobalContext);
  const [showQuestionMenu, setShowQuestionMenu] = useState(false);
  const [openCreateCommentForm, setOpenCreateCommentForm] = useState(false);
  const [
    showModalRoleUser,
    handleShowModalRoleUser,
    handleHideModalRoleUser,
  ] = useDelayShowHideHandler();
  const [loadings, setLoadings] = useState([]);

  const history = useHistory();
  const location = useLocation();
  const params = useParams();
  const { companyId, teamId, questionId } = params;
  const { scrollToTargetComment } = useScrollToTargetComment();
  const [days, setDays] = useState('');
  const [time, setTime] = useState('');

  useInitiateRoleUser({
    payload: {
      teamId,
      featureType: typeRef.question,
      featureId: questionId,
    },
  });

  const { socket } = useUserTypingQuestionSocket({
    companyId,
    questionId,
    teamId,
    userId: user?._id,
  }, dispatch);

  // read notif automatically if there is readNotifId
  useMarkReadNotificationOnPageOpen();

  // initiate members below role
  // useInitiateTeamBelowRole({});

  const [
    showActivities,
    handleShowActivities,
    handleHideActivities,
  ] = useDelayShowHideHandler();

  // for open create comment
  const handleOpenCreateCommentForm = useCallback((value) => {
    setOpenCreateCommentForm(value);
  }, []);
  const createCommentRef = createRef();
  const scrollAndOpenCreateCommentForm = useCallback(() => {
    if (createCommentRef && createCommentRef.current) {
      createCommentRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
    }
    setTimeout(() => {
      handleOpenCreateCommentForm(true);
    }, 800);
  }, [createCommentRef]);

  useQuestionSocket({
    checkInId: currentCheckIn?._id,
    questionId,
    userId: user?._id,
  }, dispatch);
  useQuestionCheerNCommentSocket({
    questionId,
    userId: user?._id,
  }, dispatch);

  const getMoreLists = async () => {
    const result = await QuestionActions.loadMoreQuestionComment({
      questionId,
      teamId,
      companyId,
      currentCheckInQuestion,
    }, dispatch);

    return result;
  };

  const resetPreviousLists = useCallback(() => {
    QuestionActions.setPreviousCheckInComment({
      previousCheckInComment: initialState.previousCheckInComment,
    }, dispatch);
    QuestionActions.setCurrentCheckInQuestion({
      currentCheckInQuestion: initialState.currentCheckInQuestion,
    }, dispatch);
  }, []);

  const {
    lists,
    handleLoadMoreLists,
    checkIfListNeedToLoad,
    setCheckLoadMore,
    isListsLengthAtTheLimitOrAbove,
  } = useInfinityScrollHooks({
    currentObjectWithKeyProperty: currentCheckInQuestion,
    previousLists: previousCheckInComment,
    resetPreviousLists,
    getMoreLists,
  });

  useEffect(() => {
    if (user._id === undefined) {
      history.push(`/check-login?previousPath=${location.pathname}`);
      return;
    }

    const startLoadings = handleLoadings('questionPage', [...loadings], 'start');
    setLoadings([...startLoadings]);

    const fetchApiQuestionDetail = async () => {
      try {
        const result = await QuestionActions.initiateQuestion(
          {
            questionId,
            checkInId: currentCheckIn?._id,
            teamId,
            companyId,
          }, dispatch,
        );
        const resultComment = await QuestionActions.initiateQuestionComment(
          {
            questionId,
            companyId,
            teamId,
            limit: CommentActions.getLimitCommentByPreviousCommentDiscussion(),
          },
          dispatch,
        );
        await TeamActions.initiateTeam({
          teamId,
          companyId,
          currentTeam,
        }, dispatch);
        if (isListsLengthAtTheLimitOrAbove(resultComment?.data?.comments)) {
          setCheckLoadMore(true);
        }
        scrollToTargetComment();
      } catch (err) {
        const status = handleStatusMsg(err, 'error');

        dispatch({
          type: actionTypes.SET_ERROR_RESPONSE,
          errorResponse: { message: status.message },
        });

        history.push(`/errors?previousPath=${location.pathname}`);
      } finally {
        const endLoadings = handleLoadings('questionPage', [...loadings], 'end');
        setLoadings([...endLoadings]);
      }
    };
    fetchApiQuestionDetail();
  }, [location]);

  useEffect(() => {
    if (currentCheckInQuestion.title === undefined) return;

    const unsortedDays = [];
    currentCheckInQuestion.schedule.days.forEach((schedule) => {
      let dayId;

      switch (schedule) {
        case 'Sun':
          dayId = 0;
          break;
        case 'Mon':
          dayId = 1;
          break;
        case 'Tue':
          dayId = 2;
          break;
        case 'Wed':
          dayId = 3;
          break;
        case 'Thu':
          dayId = 4;
          break;
        case 'Fri':
          dayId = 5;
          break;
        case 'Sat':
          dayId = 6;
          break;
        default:
        //
      }

      unsortedDays.push(dayId);
    });

    const sortedDays = unsortedDays.sort();

    let tempDays = '';

    sortedDays.forEach((schedule) => {
      let dayName;

      switch (schedule) {
        case 0:
          dayName = 'Sun';
          break;
        case 1:
          dayName = 'Mon';
          break;
        case 2:
          dayName = 'Tue';
          break;
        case 3:
          dayName = 'Wed';
          break;
        case 4:
          dayName = 'Thu';
          break;
        case 5:
          dayName = 'Fri';
          break;
        case 6:
          dayName = 'Sat';
          break;
        default:
        //
      }

      tempDays = `${tempDays + dayName} `;
    });

    setDays(tempDays);

    const UTCHour = currentCheckInQuestion.schedule.hour;
    const UTCMinute = currentCheckInQuestion.schedule.minute;
    const today = new Date();
    const baseDate = new Date(Date.UTC(
      today.getUTCFullYear(),
      today.getUTCMonth(),
      today.getUTCDate(),
      UTCHour,
      UTCMinute,
      0,
    ));

    // turn into local time
    const minute = baseDate.getMinutes();
    let hour = baseDate.getHours();
    const ampm = hour >= 12 ? 'PM' : 'AM';
    // eslint-disable-next-line no-mixed-operators
    hour = ((hour + 11) % 12 + 1);
    const prefixHour = hour >= 10 ? '' : '0';
    const prefixMinute = minute >= 10 ? '' : '0';

    const tempTime = `${prefixHour + hour}:${prefixMinute}${minute} ${ampm}`;

    setTime(tempTime);
  }, [currentCheckInQuestion]);

  const handleShowQuestionMenu = () => {
    setShowQuestionMenu(true);
  };

  const handleCancelShowQuestionMenu = () => {
    setShowQuestionMenu(false);
  };
  const pageType = PageConstants.pageType.questions;
  const breadcrumb = generateRoutesAndTitle({
    type: pageType,
    data: {
      companyId, currentTeam, currentCheckIn, currentCheckInQuestion,
    },
  });
  SearchJumpToActions.saveRecentPage({
    company: currentCompany,
    team: currentTeam,
    userId: user?._id,
    itemId: questionId,
    url: breadcrumb.url,
    routes: breadcrumb.routes,
    name: breadcrumb.title,
    type: pageType,
  });

  return (
    <>
      <Title title={currentCheckInQuestion.title === undefined ? 'Pertanyaan' : currentCheckInQuestion.title} />
      <GeneralSubNavBar
        breadcrumbRoutes={breadcrumb.routes}
        pageTitle={breadcrumb.title}
        bottomBarOverviewOptionId="check-ins"
        bottomBarGlobalActions={(
          <>
            <GlobalActionButton handleClick={scrollAndOpenCreateCommentForm} text="Tambah Komentar" icon={<AddIcon />} />
            <GlobalActionButton
              handleClick={handleShowModalRoleUser}
              text="Akses Saya"
              icon={<VerifiedUserIcon />}
            />
          </>
        )}
      />
      <LayoutMain>
        <PageLoading wait="questionPage" loadings={loadings}>
          <SurfacePaperMain>
            <div className={styles.container}>
              <div className={styles.headerSection}>
                <div className={styles.titleSection}>
                  <div className={styles.titleSection__title}>
                    {currentCheckInQuestion.archived ? (
                      <>
                        {currentCheckInQuestion.archived.status ? (
                          <div className={styles.archivedSection}>
                            <ArchiveIcon />
                            <h1>Pertanyaan ini udah terarsip</h1>
                          </div>
                        ) : null}
                      </>
                    ) : null}
                    {currentCheckInQuestion.subscribers ? (
                      <p>
                        Menanyakan
                        {' '}
                        {currentCheckInQuestion.subscribers.length}
                        {' '}
                        orang tiap
                        {' '}
                        { days}
                        {' '}
                        pada
                        {' '}
                        {time}
                      </p>
                    ) : null}
                    <h1>
                      <PrivateIcon data={currentCheckInQuestion} />
                      {' '}
                      {currentCheckInQuestion.title}
                    </h1>
                  </div>
                  <div className={styles.creator}>
                    <DisplayCreatorMain data={currentCheckInQuestion} />
                  </div>
                </div>
                <div className={styles.menuSection}>
                  {checkIfUserAuthorizedToEditData(currentCheckInQuestion, user,
                    currentRoleUser,
                    currentTeamBelowRole) && (
                    <RoundActionMenu
                      clicked={handleShowQuestionMenu}
                      cancel={handleCancelShowQuestionMenu}
                      medium
                    >
                      <MoreHorizIcon />
                    </RoundActionMenu>
                  )}
                  {showQuestionMenu ? (
                    <QuestionMenu
                      question={currentCheckInQuestion}
                      cancel={handleCancelShowQuestionMenu}
                    />
                  ) : null}
                </div>
              </div>
              <SeparatorLine />
              <div className={styles.typingSection}>
                <UserTyping
                  allUserTyping={allUserTypingQuestion}
                  companyMembers={currentCompany?.members}
                />
              </div>
              <div className={styles.commentSection}>
                {!showActivities && (
                <InfinityScroll
                  dataLength={lists?.length}
                  hasMore={checkIfListNeedToLoad()}
                  next={handleLoadMoreLists}
                  emptyMessage="Belum ada komentar disini..."
                  style={{
                    overflow: undefined,
                  }}
                >
                  <div>
                    <CommentsSectionContainer
                      comments={lists}
                      ref={createCommentRef}
                      type="question"
                      openForm={openCreateCommentForm}
                      onOpenForm={handleOpenCreateCommentForm}
                      handleShowActivities={handleShowActivities}
                      socket={socket}
                    />
                  </div>
                </InfinityScroll>
                )}
                {showActivities && (
                <ActivityByModule
                  variant={serviceTypeRef.question}
                  handleHideActivities={handleHideActivities}
                />
                )}
              </div>
            </div>
          </SurfacePaperMain>
        </PageLoading>
        <MyRoleUser
          open={showModalRoleUser}
          onClose={handleHideModalRoleUser}
          featureId={questionId}
          featureType={typeRef.question}
          featureTitle={currentCheckInQuestion?.title}
          roleType={roleTypeRef.feature}
          featureData={currentCheckInQuestion}
        />
      </LayoutMain>
    </>
  );
}

export default QuestionPage;
