import React, {
  useState, useContext, useEffect, useCallback,
} from 'react';
import { useSnackbar } from 'notistack';
import CloseIcon from '@mui/icons-material/Close';
import { useParams } from 'react-router-dom';
import CheckIcon from '@mui/icons-material/Check';
import PropTypes from 'prop-types';
import BoxPopOver from '../../components/UI/BoxPopOver/BoxPopOver';
import SeparatorLine from '../../components/UI/SeparatorLine/SeparatorLine';
import OverlayButton from '../../components/UI/Button/OverlayButton/OverlayButton';
import { GlobalContext } from '../../contexts/GlobalStateProvider';
import styles from './ManageMultipleMembersContainer.module.css';
import SearchMultipleMembers from './SearchMultipleMembers/SearchMultipleMembers';
import ImageMember from '../../components/UI/ImageMember/ImageMember';
import handleStatusMsg from '../../utilities/handleStatusMsg';
import handleLoadings from '../../utilities/handleLoadings';
import ButtonDiv from '../../components/UI/Button/ButtonDiv/ButtonDiv';
import { BreadCrumbActions, CompanyActions, TeamActions } from '../../actions';
import apiUtil from '../../utilities/apiUtil';
import { InputButtonMain } from '../../components/UI_V2/Input/Button';
import { TeamConstants } from '../../constants';
import { updateListSocket } from '../../utilities/arrayUtil';
import NavigationMenuBasic from '../../components/UI_V2/Navigation/Menu/Basic/Basic';
import DisplayAvatarMember from '../../components/UI_V2/Display/Avatar/Member';
import { DisplayTextBody } from '../../components/UI_V2/Display/Text';

const ManageMultipleMembersContainer = ({
  card, team, type, cancel, left, idApplyButton,
  position, customParams,
}) => {
  const [{
    teams, user, currentTeam, currentCompany,
  }, dispatch] = useContext(GlobalContext);
  const [parentMembers, setParentMembers] = useState([]);
  const [checkMembers, setCheckMembers] = useState([]);
  const [postMembers, setPostMembers] = useState();
  const [originalListMembers, setOriginalListMembers] = useState([]);
  const [filteredMembers, setFilteredMembers] = useState([]);
  const [loadings, setLoadings] = useState([]);
  const { enqueueSnackbar } = useSnackbar();

  const params = useParams();
  // const { companyId } = params;

  useEffect(() => {
    if (postMembers === undefined) {
      return;
    }
    const patchApiToggleMultipleMembers = async () => {
      let itemId;
      let members;
      let url;
      let teamId;

      switch (type) {
        case 'team':
          itemId = customParams.teamId || params.teamId || team?._id || currentTeam._id;
          teamId = customParams.teamId || params.teamId || team?._id || currentTeam._id;
          members = postMembers;
          url = `/v2/teams/${itemId}/members`;
          break;
        case 'card':
          itemId = customParams.cardId || params.cardId || card._id;
          teamId = customParams.teamId || params.teamId || card.team?._id || card.team;
          members = postMembers;
          url = `/v2/cards/${itemId}/members`;
          break;
        default:
          //
      }

      try {
        const startLoadings = handleLoadings('toggleMultipleMembers', [...loadings], 'start');
        setLoadings([...startLoadings]);

        const result = await apiUtil.post(url, { members }, {
          params: {
            companyId: customParams.companyId || params.companyId,
            teamId,
          },
        });

        // temporary solution for syncing add/remove member on homepage
        if (type === 'team') {
          const newTeams = updateListSocket({
            newData: result?.data?.currentTeam,
            currentList: teams,
            typeAction: TeamConstants.typeCallback.EDIT,
          }, dispatch);
          CompanyActions.updateCompanyByKeyObjectThenUpdateCompanies({
            data: newTeams,
            keyObjectProperty: 'teams',
            currentCompany,
            userId: user?._id,
          }, dispatch);
        }

        const status = handleStatusMsg(result, 'success');

        enqueueSnackbar(status.message, {
          variant: 'success',
        });

        setPostMembers();
      } catch (err) {
        const status = handleStatusMsg(err, 'error');

        enqueueSnackbar(status.message, {
          variant: 'error',
        });
      } finally {
        const endLoadings = handleLoadings('toggleMultipleMembers', [...loadings], 'end');
        setLoadings([...endLoadings]);
      }
    };
    patchApiToggleMultipleMembers();
  }, [postMembers]);

  useEffect(() => {
    switch (type) {
      case 'team':
        setParentMembers([...currentCompany?.members]);
        if (team) {
          setCheckMembers([...team?.members]);
          setOriginalListMembers([...team?.members]);
        }

        if (!team && currentTeam) {
          setCheckMembers([...currentTeam?.members]);
          setOriginalListMembers([...currentTeam?.members]);
        }
        break;
      case 'card':
        setParentMembers([...currentTeam?.members]);
        setCheckMembers([...card?.members]);
        setOriginalListMembers([...card?.members]);
        break;
      default:
        //
    }
  }, [card, team, currentTeam]);

  const handlePostMembers = () => {
    const sameOrigMembers = checkMembers.filter(
      (member) => originalListMembers.some((origMember) => origMember._id === member._id),
    );
    const origMembersNeedToBeAdded = originalListMembers.filter(
      (member) => !sameOrigMembers.some((sameOrigMember) => sameOrigMember._id === member._id),
    );
    const newAddedMembers = checkMembers.filter(
      (member) => !originalListMembers.some((origMember) => origMember._id === member._id),
    );

    const memberIds = [...origMembersNeedToBeAdded, ...newAddedMembers]
      .map((member) => member._id);

    setPostMembers([...memberIds]);
  };

  const handleToggleListMembers = (clickedMember) => {
    const memberExist = checkMembers.find((member) => member._id === clickedMember._id);
    if (memberExist) {
      const newList = checkMembers.filter((member) => member._id !== clickedMember._id);
      setCheckMembers(newList);
    } else {
      setCheckMembers((prevValue) => [
        ...prevValue,
        clickedMember,
      ]);
    }
  };

  const handleFilterMembers = (filteredResults) => {
    setFilteredMembers([...filteredResults]);
  };

  useEffect(() => {
    if (parentMembers.length < 1) return;
    if (filteredMembers.length > 0) return;
    setFilteredMembers([...parentMembers]);
  }, [parentMembers]);

  return (
    <NavigationMenuBasic size="mdWider" onClose={cancel} position={position}>
      <div className={styles.container}>
        <div className={styles.ManageMultipleMembersContainer__Header}>
          <h1 className="mr-auto">
            Anggota
          </h1>
          {/* <CloseIcon onClick={cancel} /> */}
        </div>
        <SeparatorLine />
        <div>
          <SearchMultipleMembers
            members={parentMembers}
            handleFilterMembers={handleFilterMembers}
          />
        </div>
        <div className={styles.ManageMultipleMembersContainer__body}>
          <p className={styles.ManageMultipleMembersContainer__smallTitle}>
            {type === 'team' ? 'ANGGOTA PERUSAHAAN' : 'ANGGOTA TIM'}
          </p>
          {filteredMembers.length < 1 ? <span>Anggota ga ketemu</span> : null}

          {filteredMembers.map((member) => (
            <ButtonDiv
              onClick={() => handleToggleListMembers(member)}
              className={styles.ManageMultipleMembersContainer__teamMember}
            >
              <DisplayAvatarMember src={member?.photoUrl} />
              <DisplayTextBody className="mr-auto">{member?.fullName}</DisplayTextBody>
              {checkMembers.some((checkMember) => checkMember._id === member._id)
                ? <CheckIcon className={styles.ManageMultipleMembersContainer__checkIcon} />
                : null}
            </ButtonDiv>
          ))}
        </div>
        <div className={styles.applySection}>
          <InputButtonMain
            handleClick={handlePostMembers}
            wait="toggleMultipleMembers"
            loadings={loadings}
            wide="block"
            variant="success"
            id={idApplyButton || 'member_toggle_apply_button'}
          >
            Terapkan Anggota
          </InputButtonMain>
        </div>
      </div>
    </NavigationMenuBasic>
  );
};

ManageMultipleMembersContainer.propTypes = {
  card: PropTypes.object.isRequired,
  team: PropTypes.object,
  type: PropTypes.string.isRequired,
  cancel: PropTypes.func.isRequired,
  left: PropTypes.bool.isRequired,
  idApplyButton: PropTypes.string,
  position: PropTypes.string,
  customParams: PropTypes.object,
};

ManageMultipleMembersContainer.defaultProps = {
  team: undefined,
  idApplyButton: undefined,
  position: 'topRignt',
  customParams: { companyId: null, teamId: null, cardId: null, },
};

export default ManageMultipleMembersContainer;
