// deprecated all, use dateUtil instead
import { dateBeautifyFormatter } from './dateUtil';

const handleCompareDate = (items, item, prevItemIndex, compareBy) => {
  const prevItem = items[prevItemIndex];

  if (prevItem === undefined) return false;

  let dateStringItem;
  let dateStringPrevItem;

  switch (compareBy) {
    case 'updatedAt':
      dateStringItem = dateBeautifyFormatter(item.updatedAt);
      dateStringPrevItem = dateBeautifyFormatter(prevItem.updatedAt);
      break;
    case 'startDate':
      dateStringItem = dateBeautifyFormatter(item.startDate);
      dateStringPrevItem = dateBeautifyFormatter(prevItem.startDate);
      break;

    default:
      dateStringItem = dateBeautifyFormatter(item.createdAt);
      dateStringPrevItem = dateBeautifyFormatter(prevItem.createdAt);
      break;
  }

  if (dateStringItem === dateStringPrevItem) {
    return true;
  }
  return false;
};

const handleCheckToday = (item) => {
  const dateStringItem = dateBeautifyFormatter(item.createdAt);

  if (dateStringItem === 'Hari Ini') {
    return true;
  }
  return false;
};

export { handleCompareDate, handleCheckToday };
