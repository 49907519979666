import React, {
  memo, useContext, useEffect, useState,
} from 'react';
import PropTypes from 'prop-types';
import { useSnackbar } from 'notistack';
import styles from './ModalUpdateTeamColor.module.css';
import { FeedbackDialogGeneral } from '../../../../../../components/UI_V2/Feedback/Dialog';
import { GlobalContext } from '../../../../../../contexts/GlobalStateProvider';
import handleStatusMsg from '../../../../../../utilities/handleStatusMsg';
import { DisplayTextHeadline, DisplayTextSubHeadline } from '../../../../../../components/UI_V2/Display/Text';
import { InputButtonMain } from '../../../../../../components/UI_V2/Input/Button';
import Color from '../../../../../../themes/colors';
import { elementId } from '../../../../../../constants/ElementIdConstants';
import ColorBox from '../../../../../../components/UI/ColorBox/ColorBox';
import apiUtil from '../../../../../../utilities/apiUtil';
import { actionTypes } from '../../../../../../reducers/reducer';
import { addOrUpdateStoreTeamsColor, getStoreTeamsColor } from '../../../../../../utilities/localStorage';

function ModalUpdateTeamColor({
  open, onClose, team,
}) {
  const [{
    colors,
    user,
    shouldCheckTeamsColor,
  }, dispatch] = useContext(GlobalContext);
  const { enqueueSnackbar } = useSnackbar();
  const [loadings, setLoadings] = useState([]);
  const [selectedColor, setSelectedColor] = useState();

  useEffect(() => {
    const fetchApiColors = async () => {
      try {
        const teamId = team._id;
        const result = await apiUtil.get('/api/v1/colors', {
          params: {
            companyId: team.company,
            teamId,
          },
        });

        dispatch({
          type: actionTypes.SET_COLORS,
          colors: result.data.colors,
        });
      } catch (err) {
        const status = handleStatusMsg(err, 'error');

        enqueueSnackbar(status.message, {
          variant: 'error',
        });
      }
    };
    fetchApiColors();
  }, []);

  const handleSelectColor = (color) => {
    setSelectedColor(color);
  };

  const handleClicked = () => {
    const teamsColor = getStoreTeamsColor({
      companyId: team.company, userId: user?._id,
    }) || [];
    addOrUpdateStoreTeamsColor({
      companyId: team.company,
      userId: user._id,
      currentTeamsColor: teamsColor,
      teamId: team._id,
      color: selectedColor?.name,
    });

    dispatch({
      type: actionTypes.SET_SHOULD_CHECK_TEAMS_COLOR,
      shouldCheckTeamsColor: !shouldCheckTeamsColor,
    });

    onClose();
  };

  return (
    <FeedbackDialogGeneral
      open={open}
      onClose={onClose}
      maxWidth="xs"
    >
      <div className={styles.container}>
        <div className={styles.title}>
          <DisplayTextHeadline
            color={Color.blueNavyCicle}
            decoration="bold"
          >
            Atur Warna Tim
          </DisplayTextHeadline>
        </div>
        <div className={styles.description}>
          <DisplayTextSubHeadline
            mode="16"
          >
            Warna yang kamu atur disini hanya berlaku untuk kamu saja.
          </DisplayTextSubHeadline>
        </div>
        <div className={styles.colorSection}>
          {colors.map((color) => (
            <ColorBox
              clicked={() => handleSelectColor(color)}
              color={color.name}
              checked={selectedColor?._id === color._id}
            />
          ))}
        </div>
        <div className={styles.button}>
          <InputButtonMain
            handleClick={handleClicked}
              // border="rounded"
            wide="block"
            loadings={loadings}
            wait="updateTeam"
          >
            Simpan Perubahan
          </InputButtonMain>
        </div>
      </div>
    </FeedbackDialogGeneral>
  );
}

ModalUpdateTeamColor.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  team: PropTypes.object.isRequired,
};

export default memo(ModalUpdateTeamColor);
