import React, {
  memo,
} from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import styles from './UserItem.module.css';
import { DisplayTextBody, DisplayTextSubHeadline } from '../../../../components/UI_V2/Display/Text';
import { DisplayAvatarMember } from '../../../../components/UI_V2/Display/Avatar';
import RoleUserBadge from '../../../../components/Container/MyRoleUser/RoleUserBadge/RoleUserBadge';
import RemoveMemberButton from '../RemoveMemberButton/RemoveMemberButton';
import { hasPermission } from '../../../../actions/RoleActions';
import { role, roles } from '../../../../constants/RoleConstants';

const UserItem = ({
  user,
  roleUser,
  handleSelectRoleUser,
  handleUnselectRoleUser,
  disableSelectRole,
  enableDeleteMember,
  currentRoleUser,
}) => {
  const member = roleUser.user;

  const isNotSuperUser = currentRoleUser.role !== role.super;
  const isTheSameUserAndNotSuperUser = user._id === member?._id && isNotSuperUser;
  const isUserManagerOrAboveAndHasHigherLevel = hasPermission(
    currentRoleUser, roles.managerOrAbove, roleUser,
  );
  const isAbleDeleteMember = isUserManagerOrAboveAndHasHigherLevel || isTheSameUserAndNotSuperUser;

  return (
    <div className={styles.container}>
      <div className={styles.member}>
        <div className={styles.member__photo}>
          <DisplayAvatarMember src={member?.photoUrl} />
        </div>
        <div className={styles.member__status}>
          <div className={styles.nameAndBadge}>
            <DisplayTextSubHeadline
              mode="16"
              decoration="bold"
            >
              {member?.fullName}
            </DisplayTextSubHeadline>
            <div className={styles.badge}>
              <RoleUserBadge roleUser={roleUser} />
            </div>
          </div>
          <DisplayTextBody>
            {member?.status}
          </DisplayTextBody>
        </div>
      </div>
      {enableDeleteMember && isAbleDeleteMember && (
      <div className={styles.removeButton}>
        <RemoveMemberButton member={member} />
      </div>
      )}
    </div>
  );
};

UserItem.propTypes = {
  user: PropTypes.object.isRequired,
  roleUser: PropTypes.object.isRequired,
  handleSelectRoleUser: PropTypes.func.isRequired,
  handleUnselectRoleUser: PropTypes.func.isRequired,
  disableSelectRole: PropTypes.bool,
  enableDeleteMember: PropTypes.bool,
  currentRoleUser: PropTypes.object.isRequired,
};

UserItem.defaultProps = {
  disableSelectRole: false,
  enableDeleteMember: false,
};

export default memo(UserItem);
