import React, {
  memo, useContext, useEffect, useState,
} from 'react';
import PropTypes from 'prop-types';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import styles from './FilterActivity.module.css';
import Color from '../../../themes/colors';
import { GlobalContext } from '../../../contexts/GlobalStateProvider';
import { ActivityConstants } from '../../../constants';
import FilterDropdown from '../../../components/Container/FilterDropdown/FilterDropdown';

const FilterActivity = ({
  onApplyFilters,
  initialFilterFeatureTypes,
  initialFilterTeams,
  listTeams,
  listFeatureTypes,
}) => (
  <div className={styles.container}>
    <FilterDropdown
      listFeatureTypes={listFeatureTypes}
      initialSelectedFeatureTypes={initialFilterFeatureTypes}
      listTeams={listTeams}
      initialSelectedTeams={initialFilterTeams}
      onApplyFilters={onApplyFilters}
        // initialArchived={initialFilters.filterArchived}
      invertResetFeatureTypeCondition
      invertResetTeamCondition
      showArchivedSection={false}
      buttonSizeVariant="sm"
    />
  </div>
);

FilterActivity.propTypes = {
  onApplyFilters: PropTypes.func,
  initialFilterFeatureTypes: PropTypes.array,
  initialFilterTeams: PropTypes.array,
  listTeams: PropTypes.array,
  listFeatureTypes: PropTypes.array,
};

FilterActivity.defaultProps = {
  onApplyFilters: () => null,
  initialFilterFeatureTypes: [],
  initialFilterTeams: [],
  listTeams: [],
  listFeatureTypes: [],
};

export default memo(FilterActivity);
