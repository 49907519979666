import React, { memo, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useParams } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import styles from './SubscriptionBox.module.css';
import { DisplayTextBody } from '../../../../../components/UI_V2/Display/Text';
import Color from '../../../../../themes/colors';
import handleLoadings from '../../../../../utilities/handleLoadings';
import { BillingActions } from '../../../../../actions';
import handleStatusMsg from '../../../../../utilities/handleStatusMsg';
import DetailInfoBox from '../DetailInfoBox/DetailInfoBox';
import {
  dateBeautifyFormatter,
  dateDiffInDays,
  dateDifferenceInDays, isDateBeforeNow, timeBeautifyFormatter,
} from '../../../../../utilities/dateUtil';
import { getSingleOrPlural } from '../../../../../utilities/stringUtil';
import TextInfo from '../DetailInfoBox/TextInfo/TextInfo';

const SubscriptionBox = () => {
  const [loadings, setLoadings] = useState([]);
  const [subscription, setSubscription] = useState();

  const params = useParams();
  const { companyId } = params;

  const { enqueueSnackbar } = useSnackbar();

  const initiateSubscriptionApi = async () => {
    try {
      const startLoadings = handleLoadings('billingSubscriptionStatus', [...loadings], 'start');
      setLoadings([...startLoadings]);

      const result = await BillingActions.getSubscriptionStatus({
        companyId,
      });

      setSubscription(result.data);

      // const status = handleStatusMsg(result, 'success');

      // enqueueSnackbar(status.message, {
      //   variant: 'success',
      // });
    } catch (err) {
      const status = handleStatusMsg(err, 'error');

      enqueueSnackbar(status.message, {
        variant: 'error',
      });
    } finally {
      const endLoadings = handleLoadings('billingSubscriptionStatus', [...loadings], 'end');
      setLoadings([...endLoadings]);
    }
  };

  useEffect(() => {
    initiateSubscriptionApi();
  }, []);

  let end;
  let start;
  let plan;
  if (subscription) {
    end = subscription?.end;
    plan = subscription?.plan;
    start = subscription?.start;
  }

  const durationSubscription = dateDiffInDays(start, new Date());

  const isEndToday = dateBeautifyFormatter(end, false, 'MonthDateYear') === 'Hari Ini';
  const isEndTomorrow = dateBeautifyFormatter(end, false, 'MonthDateYear') === 'Besok';
  const isEndYesterday = dateBeautifyFormatter(end, false, 'MonthDateYear') === 'Kemarin';

  const subscriptionDayLeft = dateDifferenceInDays(end, new Date()) + 1;

  const isNoSubscription = subscription?._id === undefined;
  const isSubscriptionOver = subscriptionDayLeft < 0 || isDateBeforeNow(end, new Date());
  const isSubscriptionSoon = !isNoSubscription
  && subscriptionDayLeft < 5
  && !isSubscriptionOver;
  const isSubscriptionTrial = plan?.name === 'basic';

  return (
    <div className={styles.container}>
      <DetailInfoBox
        title="Subscription"
      >
        <div className={styles.text}>
          <TextInfo
            title="Plan"
            text={!isNoSubscription ? plan?.desc : 'Godlike Plan'}
          />
        </div>
        <div className={styles.text}>
          <TextInfo
            title="Active Until"
            text={(
              <>
                {isNoSubscription && 'Active forever. No need to renew. Yeay!'}
                {!isNoSubscription && !isSubscriptionOver && 'Next billing date : '}
                {!isNoSubscription && isSubscriptionOver && 'Expired date : '}
                {!isNoSubscription && `${dateBeautifyFormatter(end, false, 'MonthDateYear')}`}
                {' '}
                {!isNoSubscription && (isEndToday || isEndTomorrow || isEndYesterday) && `${timeBeautifyFormatter(end)} `}
                {!isNoSubscription && isSubscriptionOver && '(expired)'}
                {!isNoSubscription && !isSubscriptionOver && `(${subscriptionDayLeft} ${getSingleOrPlural('day', subscriptionDayLeft)} left)`}
              </>
)}
          />
          <DisplayTextBody />
        </div>
        <div className={styles.text}>
          <TextInfo
            title="How long has been subscribing"
            text={`${durationSubscription} ${getSingleOrPlural('Day', durationSubscription)}`}
          />
        </div>
      </DetailInfoBox>
    </div>
  );
};

SubscriptionBox.propTypes = {};

SubscriptionBox.defaultProps = {};

export default memo(SubscriptionBox);
