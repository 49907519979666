import React, {
  memo, useContext,
} from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import styles from './MyRoleItem.module.css';
import { DisplayTextBody, DisplayTextSubHeadline } from '../../../../UI_V2/Display/Text';
import { DisplayAvatarMember } from '../../../../UI_V2/Display/Avatar';
import RoleUserBadge from '../../RoleUserBadge/RoleUserBadge';
import { hasPermission } from '../../../../../actions/RoleActions';
import { role, roles } from '../../../../../constants/RoleConstants';
import GlobalStateProvider, { GlobalContext } from '../../../../../contexts/GlobalStateProvider';

const MyRoleItem = () => {
  const [{ user, currentRoleUser }, dispatch] = useContext(GlobalContext);

  const isUserSpvOrBelow = hasPermission(currentRoleUser, roles.spvOrBelow);

  return (
    <div className={styles.container}>
      <div className={styles.member}>
        <div className={styles.member__photo}>
          <DisplayAvatarMember
            src={user?.photoUrl}
            size="xl"
          />
        </div>
        <div className={styles.member__status}>
          <div className={styles.nameAndBadge}>
            <DisplayTextSubHeadline
              mode="20"
              decoration="bold"
            >
              {user.fullName}
            </DisplayTextSubHeadline>
            <div className={styles.badge}>
              <RoleUserBadge roleUser={currentRoleUser} />
            </div>
          </div>
          <DisplayTextBody>
            {user.status}
          </DisplayTextBody>
        </div>
      </div>
    </div>
  );
};

MyRoleItem.propTypes = {};

MyRoleItem.defaultProps = {};

export default memo(MyRoleItem);
