import React, {
  useContext, useEffect,
} from 'react';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { actionTypes } from '../../reducers/reducer';
import MainLayout from '../../components/Layout/MainLayout/MainLayout';
import styles from './BoardRedirectionPage.module.css';
import { GlobalContext } from '../../contexts/GlobalStateProvider';
import handleStatusMsg from '../../utilities/handleStatusMsg';
import {
  RedirectUrlActions, CheckLoginActions,
} from '../../actions';
import LoadingWithAnimation from '../../components/UI/LoadingWithAnimation/LoadingWithAnimation';
import { useGetBoardCardViewData } from '../../hooks/BoardHooks';

function BoardRedirectionPage() {
  const [{ user, currentCompanies, currentCompany }, dispatch] = useContext(GlobalContext);

  const params = useParams();
  const history = useHistory();
  const location = useLocation();

  const {
    companyId, teamId, boardId, cardId,
  } = params;

  const [boardCardViewMainValue,
    boardCardViewUrl,
    boardCardViewInitialCalendarViewValue] = useGetBoardCardViewData({
    boardId,
    userId: user?._id,
    companyId,
    teamId,
  });

  useEffect(() => {
    if (user._id === undefined) {
      history.push(`/check-login?previousPath=${location.pathname}`);
      return;
    }
    history.push(boardCardViewUrl);
  }, []);

  return (
    <>
      <MainLayout>
        <LoadingWithAnimation>
          <h1>Tunggu ya</h1>
          <h1>Mengalihkan ke view tugas yang kamu tuju...</h1>
        </LoadingWithAnimation>
      </MainLayout>
    </>
  );
}

export default BoardRedirectionPage;
