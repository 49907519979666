import React, {
  useEffect, useState, useContext, useCallback,
} from 'react';
import { useHistory, useParams, useLocation } from 'react-router-dom';
import _ from 'lodash';
import { useSnackbar } from 'notistack';
import { GlobalContext } from '../../../contexts/GlobalStateProvider';
import { actionTypes } from '../../../reducers/reducer';
import styles from './TeamSettingsContainer.module.css';
import handleStatusMsg from '../../../utilities/handleStatusMsg';
import Title from '../../../components/Title/Title';
import handleLoadings from '../../../utilities/handleLoadings';
import { DisplayTextBody, DisplayTextHeadline } from '../../../components/UI_V2/Display/Text';
import GeneralSubNavBar from '../../../components/GeneralSubNavBar/GeneralSubNavBar';
import { generateRoutesAndTitle } from '../../../actions/BreadCrumbActions';
import { InputButtonMain } from '../../../components/UI_V2/Input/Button';
import Color from '../../../themes/colors';
import { TeamActions, SearchJumpToActions, CompanyActions } from '../../../actions';
import EditTeam from './EditTeam/EditTeam';
import { PageConstants } from '../../../constants';
import { useTeamHooks } from '../../../hooks/TeamHooks';
import { FeedbackDialogAlert } from '../../../components/UI_V2/Feedback/Dialog';
import { hasPermission } from '../../../actions/RoleActions';
import { roles, typeRef } from '../../../constants/RoleConstants';
import { useInitiateRoleUser } from '../../../hooks/RoleHooks';

const boxWithSidebarStyles = {
  true: `${styles.box} ${styles.sidebarOpen}`,
  false: styles.box,
};

const TeamSettingsContainer = () => {
  const [{
    user, currentCompany, currentTeam, teams, currentRoleUser,
    isSidebarOpen,
  }, dispatch] = useContext(GlobalContext);
  const [showEditForm, setShowEditForm] = useState(false);
  const [showArchivePopUp, setShowArchivePopUp] = useState(false);
  const [loadings, setLoadings] = useState([]);
  const { enqueueSnackbar } = useSnackbar();
  const history = useHistory();
  const location = useLocation();
  const params = useParams();
  const { companyId, teamId } = params;

  useTeamHooks({ teamId, userId: user?._id }, dispatch);
  useInitiateRoleUser({
    payload: {
      teamId,
      featureType: typeRef.team,
      featureId: teamId,
    },
  });

  useEffect(() => {
    if (user._id === undefined) {
      history.push(`/check-login?previousPath=${location.pathname}`);
      return;
    }
    const startLoadings = handleLoadings('teamPageSettings', [...loadings], 'start');
    setLoadings([...startLoadings]);

    const fetchCurrentTeam = async () => {
      try {
        if (currentTeam?._id !== teamId
          || !currentTeam?.blast
          || !currentTeam?.checkIn
          || !currentTeam?.groupChat
          || !currentTeam?.boards
          || !currentTeam?.schedule
        ) {
          const result = await TeamActions.initiateCurrentTeam({ teamId, companyId }, dispatch);
        }
      } catch (err) {
        const status = handleStatusMsg(err, 'error');

        dispatch({
          type: actionTypes.SET_ERROR_RESPONSE,
          errorResponse: { message: status.message },
        });

        history.push(`/errors?previousPath=${location.pathname}`);
      } finally {
        const endLoadings = handleLoadings('teamPageSettings', [...loadings], 'end');
        setLoadings([...endLoadings]);
      }
    };
    fetchCurrentTeam();
  }, []);

  const postArchiveTeam = useCallback(async () => {
    try {
      const startLoadings = handleLoadings('archiveTeam', [...loadings], 'start');
      setLoadings([...startLoadings]);
      const result = await TeamActions.archiveTeamThenUpdateCompanyAndCompanies({
        teamId,
        companyId,
        teams,
        currentCompany,
        currentTeam,
        userId: user?._id,
      }, dispatch);

      const status = handleStatusMsg(result, 'success');

      enqueueSnackbar(status.message, {
        variant: 'success',
      });
      history.push(`/companies/${companyId}/teams/${teamId}`);
    } catch (error) {
      const status = handleStatusMsg(error, 'error');

      enqueueSnackbar(status.message, {
        variant: 'error',
      });
    } finally {
      const endLoadings = handleLoadings('archiveTeam', [...loadings], 'end');
      setLoadings([...endLoadings]);
    }
  }, []);

  const handleShowEditForm = useCallback((value) => {
    setShowEditForm(value);
  }, []);

  const handleShowArchivePopUp = useCallback(() => {
    setShowArchivePopUp(true);
  }, []);

  const handleHideArchivePopUp = useCallback(() => {
    setShowArchivePopUp(false);
  }, []);

  const isUserManagerOrAbove = hasPermission(currentRoleUser, roles.managerOrAbove);

  const pageType = PageConstants.pageType.teamsSettings;

  const breadcrumb = generateRoutesAndTitle(
    { type: pageType, data: { currentCompany, currentTeam } },
  );
  SearchJumpToActions.saveRecentPage({
    company: currentCompany,
    team: currentTeam,
    userId: user?._id,
    itemId: `${teamId}-settings`,
    url: breadcrumb.url,
    routes: breadcrumb.routes,
    name: breadcrumb.title,
    type: pageType,
  });
  return (
    <>
      <Title title={`${currentTeam?.name} - Pengaturan`} />
      <GeneralSubNavBar
        breadcrumbRoutes={breadcrumb.routes}
        pageTitle={breadcrumb.title}
        bottomBarOverviewOptionId="teams"
        withBottomNavBar={false}
        // bottomBarGlobalActions={(
        //   <>
        //     <GlobalActionButton text="Team Settings" icon={<SettingsOutlinedIcon />} />
        //   </>
        // )}
        // bottomBarOtherActions={(
        //   <>
        //     {currentTeam.members
        //       ? (
        //         <ShowLimitMembers
        //           members={currentTeam?.members}
        //           limit={5}
        //           companyId={companyId}
        //           handleClickButton={() => handleShowAddMember(true)}
        //         />
        //       ) : null}
        // eslint-disable-next-line max-len
        //     {showAddMember ? <ManageMultipleMembersContainer type="team" cancel={() => handleShowAddMember(false)} /> : null}
        //   </>
        // )}
      />
      <div className={boxWithSidebarStyles[isSidebarOpen]}>
        <div className={styles.container}>
          <div className={styles.leftSection}>
            <div className={styles.header}>
              <div className={styles.header__title}>
                <DisplayTextHeadline
                  mode="16"
                  decoration="bold"
                >
                  Ubah Tim
                </DisplayTextHeadline>
              </div>
              <div className={styles.header__body}>
                <DisplayTextBody
                  color={Color.gray3}
                  mode="12"
                >
                  Ubah nama dan deskripsi Tim
                </DisplayTextBody>
              </div>
            </div>
            {!showEditForm ? (
              <div className={styles.mainSection}>
                <div className={styles.infoSection}>
                  <div className={styles.infoSection__title}>
                    <DisplayTextBody
                      decoration="bold"
                      mode="12"
                    >
                      Nama Tim
                    </DisplayTextBody>
                  </div>
                  <div className={styles.infoSection__item}>
                    <DisplayTextBody
                      mode="12"
                    >
                      {currentTeam?.name}
                    </DisplayTextBody>
                  </div>
                </div>
                <div className={styles.infoSection}>
                  <div className={styles.infoSection__title}>
                    <DisplayTextBody
                      decoration="bold"
                      mode="12"
                    >
                      Deskripsi Tim
                    </DisplayTextBody>
                  </div>
                  <div className={styles.infoSection__item__desc}>
                    <DisplayTextBody
                      mode="12"
                    >
                      {currentTeam?.desc}
                    </DisplayTextBody>
                  </div>
                </div>
                <div className={styles.buttonSection}>
                  {currentTeam?._id === teamId && isUserManagerOrAbove
                && (
                <InputButtonMain
                  handleClick={handleShowEditForm}
                  wide="wider"
                  border="semiRounded"
                >
                  Ubah Tim
                </InputButtonMain>
                )}
                </div>
              </div>
            ) : <EditTeam handleShowEditForm={handleShowEditForm} team={currentTeam} />}
          </div>
          <div className={styles.rightSection}>
            <div className={styles.archiveSection}>
              <div className={styles.header}>
                <div className={styles.header__title}>
                  {!currentTeam?.archived?.status ? (
                    <DisplayTextHeadline
                      mode="16"
                      decoration="bold"
                    >
                      Arsipkan Tim
                    </DisplayTextHeadline>
                  )
                    : (
                      <DisplayTextHeadline
                        mode="16"
                        decoration="bold"
                      >
                        Tim ini udah terarsip
                      </DisplayTextHeadline>
                    )}
                </div>
                <div className={styles.header__body}>
                  {!currentTeam?.archived?.status && (
                  <DisplayTextBody
                    color={Color.gray3}
                    mode="12"
                  >
                    Arsipkan Tim yang udah ga digunakan
                  </DisplayTextBody>
                  )}
                </div>
              </div>
              <div className={styles.buttonSection}>
                {currentTeam?._id === teamId && !currentTeam?.archived?.status
                && isUserManagerOrAbove
                && (
                <InputButtonMain
                  handleClick={handleShowArchivePopUp}
                  wide="wider"
                  border="semiRounded"
                  loadings={loadings}
                  wait="archiveTeam"
                  variant="danger"
                >
                  Arsipkan
                </InputButtonMain>
                )}
              </div>
            </div>
          </div>
        </div>
        <FeedbackDialogAlert
          open={showArchivePopUp}
          onClose={handleHideArchivePopUp}
          handleConfirmNo={handleHideArchivePopUp}
          handleConfirmYes={postArchiveTeam}
          waitYes="archiveTeam"
          loadings={loadings}
          variantButtonYes="danger"
          labelYes="Arsipkan"
          labelNo="Batal"
          title="Arsipkan Tim ini?"
          content="Tim ini tidak akan tampil lagi. Admin bisa mengembalikan Tim ini melalui menu Arsip Tim"
        />
      </div>
    </>
  );
};

export default TeamSettingsContainer;
