import React, {
  memo,
} from 'react';
import PropTypes from 'prop-types';
import useMediaQuery from '@mui/material/useMediaQuery';
import styles from './GlobalActionButton.module.css';
import { DisplayTextBody, DisplayTextSubBody } from '../../../UI_V2/Display/Text';
import Color from '../../../../themes/colors';
import { limitChar } from '../../../../utilities/stringUtil';

const GlobalActionButton = ({
  text, icon, handleClick, buttonMode, children,
}) => {
  const isMobile = useMediaQuery('(max-width:720px)');

  const buttonModeStyle = {
    default: styles.container,
    outlined: styles.outlinedContainer,
    passive: styles.passiveContainer,
    active: styles.activeContainer,
  };

  const textStyle = {
    default: {
      mode: '11',
      decoration: 'bold',
      style: { whiteSpace: 'nowrap' },
      color: Color.yellowNavyCicle,
    },
    outlined: {
      mode: '11',
      decoration: 'bold',
      style: { whiteSpace: 'nowrap' },
      color: Color.gray3,
    },
    passive: {
      mode: '11',
      style: { whiteSpace: 'nowrap' },
      color: Color.gray3,
    },
    active: {
      mode: '11',
      decoration: 'bold',
      style: { whiteSpace: 'nowrap' },
      color: Color.white,
    },
  };

  const iconStyle = {
    default: styles.icon,
    outlined: styles.outlinedIcon,
    passive: styles.passiveIcon,
    active: styles.activeIcon,
  };

  return (
    <div className={buttonModeStyle[buttonMode]} onClick={handleClick}>
      <div className={iconStyle[buttonMode]}>
        {icon}
      </div>
      {buttonMode === 'outlined' ? (
        <>
          {!isMobile ? (
            <div className={styles.text}>
              <DisplayTextSubBody
                mode={textStyle[buttonMode].mode}
                decoration={textStyle[buttonMode].decoration}
                color={textStyle[buttonMode].color}
                style={textStyle[buttonMode].style}
              >
                {text}
              </DisplayTextSubBody>
              {children}
            </div>
          ) : (
            <div className={styles.text}>
              <DisplayTextSubBody
                mode={textStyle[buttonMode].mode}
                decoration={textStyle[buttonMode].decoration}
                color={textStyle[buttonMode].color}
                style={textStyle[buttonMode].style}
              >
                {limitChar(text, 5)}
              </DisplayTextSubBody>
              {children}
            </div>
          ) }
        </>
      )
        : (
          <div className={styles.text}>
            <DisplayTextSubBody
              mode={textStyle[buttonMode].mode}
              decoration={textStyle[buttonMode].decoration}
              color={textStyle[buttonMode].color}
              style={textStyle[buttonMode].style}
            >
              {text}
            </DisplayTextSubBody>
            {children}
          </div>
        )}
    </div>
  );
};

GlobalActionButton.propTypes = {
  text: PropTypes.string.isRequired,
  handleClick: PropTypes.func,
  icon: PropTypes.node,
  buttonMode: PropTypes.string,
  children: PropTypes.node,
};

GlobalActionButton.defaultProps = {
  handleClick: () => null,
  icon: <></>,
  buttonMode: 'outlined',
  children: <></>,
};

export default memo(GlobalActionButton);
