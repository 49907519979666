import React, {
  memo, useState, useContext, useCallback, useEffect,
} from 'react';
import PropTypes from 'prop-types';
import OneSignal from 'react-onesignal';
import { Tooltip } from '@mui/material';
import { useParams } from 'react-router-dom';
import { ExitToApp } from '@mui/icons-material';
import styles from './ModalWelcomeUser.module.css';
import { FeedbackDialogGeneral } from '../../../components/UI_V2/Feedback/Dialog';
import { GlobalContext } from '../../../contexts/GlobalStateProvider';
import SceneFillingProfile from './SceneFillingProfile/SceneFillingProfile';
import SceneTutorial from './SceneTutorial/SceneTutorial';
import SceneHelp from './SceneHelp/SceneHelp';
import IconItem from '../../UserMenuContainer/IconItem/IconItem';
import { UserActions } from '../../../actions';
import { useLogoutUser } from '../../../hooks/UserHooks';
import { InputButtonLogout } from '../../../components/UI_V2/Input/Button';

const ModalWelcomeUser = ({
  open,
  onClose,
  handleClickDontShowAgain,
  isDontShowWelcomeAgain,
  showWelcomeProfileForm,
}) => {
  const [{
    user,
    teams,
  }, dispatch] = useContext(GlobalContext);
  const { handleLogout, isLoadingLogout } = useLogoutUser();
  const [sceneSequence, setSceneSequence] = useState(showWelcomeProfileForm ? 0 : 1);

  const params = useParams();

  const { companyId } = params;

  const isFirstScene = sceneSequence === 0;
  const isSecondScene = sceneSequence === 1;
  const isThirdScene = sceneSequence === 2;

  useEffect(() => {
    if (showWelcomeProfileForm) setSceneSequence(0);
  }, [showWelcomeProfileForm]);

  const isNewlyCreatedOrJoinedCompany = teams.length < 1;

  const handleClickNext = () => {
    if (sceneSequence === 2) {
      onClose();
    } else {
      setSceneSequence((prevValue) => prevValue + 1);
    }
  };

  return (
    <FeedbackDialogGeneral
      open={open}
      onClose={onClose}
      disableCloseIcon
      disableEscapeKeyDown
      disableBackdropClick
    >
      <div className={styles.container}>
        {!companyId && (
          <div className={styles.logoutIcon}>
            <InputButtonLogout handleLogout={handleLogout} isLoadingLogout={isLoadingLogout} />
          </div>
        )}
        <div className={styles.scene}>
          {isFirstScene && <SceneFillingProfile handleClickNext={handleClickNext} />}
          {isSecondScene
          && (
          <SceneTutorial
            isNewlyCreatedOrJoinedCompany={isNewlyCreatedOrJoinedCompany}
            handleClickNext={handleClickNext}
          />
          )}
          {isThirdScene && (
          <SceneHelp
            handleClickNext={handleClickNext}
            handleClickDontShowAgain={handleClickDontShowAgain}
            isDontShowWelcomeAgain={isDontShowWelcomeAgain}
          />
          )}
        </div>
      </div>
    </FeedbackDialogGeneral>
  );
};

ModalWelcomeUser.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  isDontShowWelcomeAgain: PropTypes.bool.isRequired,
  handleClickDontShowAgain: PropTypes.func.isRequired,
  showWelcomeProfileForm: PropTypes.bool.isRequired,
};

export default memo(ModalWelcomeUser);
