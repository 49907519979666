import React, { memo, useState } from 'react';
import PropTypes from 'prop-types';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { useMediaQuery } from '@mui/material';
import styles from './ChartStatUserReport.module.css';
import Color from '../../../../../themes/colors';
import { DisplayChartDoughnut } from '../../../../../components/UI_V2/Display/Chart';
import BackgroundChart from '../BackgroundChart/BackgroundChart';
import TitleChart from '../TitleChart/TitleChart';
import StatText from '../StatText/StatText';
import { DisplayTextBody } from '../../../../../components/UI_V2/Display/Text';
import ChartUserSkeleton from '../ChartUserSkeleton/ChartUserSkeleton';

const ChartStatUserReport = ({
  ratio,
  isEmptyData,
  isLoading,
}) => {
  const {
    completeRatio,
    ongoingRatio,
    overdueRatio,
  } = ratio;

  const isTablet = useMediaQuery('(max-width:1200px)');
  const isMobile = useMediaQuery('(max-width:720px)');
  return (
    <div className={styles.container}>
      <BackgroundChart
        heightVariant={isTablet ? 'low' : 'medium'}
      >
        <div className={styles.title}>
          <TitleChart>
            Saran
          </TitleChart>
        </div>
        {isLoading && <div className={styles.skeleton}><ChartUserSkeleton /></div>}
        <div className={styles.body}>
          {!isLoading && (
          <div className={styles.statText}>
            {isEmptyData && (
            <DisplayTextBody>
              Belum ada data
            </DisplayTextBody>
            )}
            {!isEmptyData && (
            <StatText
              completeRatio={completeRatio}
              ongoingRatio={ongoingRatio}
              overdueRatio={overdueRatio}
            />
            )}
          </div>
          )}
        </div>
      </BackgroundChart>
    </div>
  );
};

ChartStatUserReport.propTypes = {
  ratio: PropTypes.object,
  isEmptyData: PropTypes.bool.isRequired,
  isLoading: PropTypes.bool,
};

ChartStatUserReport.defaultProps = {
  ratio: {
    completeRatio: {
      percentage: undefined,
      category: undefined,
    },
    ongoingRatio: {
      percentage: undefined,
      category: undefined,
    },
    overdueRatio: {
      percentage: undefined,
      category: undefined,
    },
  },
  isLoading: true,
};

export default memo(ChartStatUserReport);
