import React, { memo, useState } from 'react';
import PropTypes from 'prop-types';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import styles from './AdditionalInfoChurn.module.css';
import { DisplayTextBody } from '../../../../../../components/UI_V2/Display/Text';
import Color from '../../../../../../themes/colors';
import TextInfo from '../../../../CompanyDetailPage/CompanyInfo/DetailInfoBox/TextInfo/TextInfo';
import { dateBeautifyFormatter } from '../../../../../../utilities/dateUtil';
import { getSingleOrPlural } from '../../../../../../utilities/stringUtil';

const AdditionalInfoChurn = ({
  item,
}) => (
  <div className={styles.container}>
    <TextInfo
      title="Churn Date"
      text={dateBeautifyFormatter(item?.additionalInfo.churnDate)}
    />
    <TextInfo
      title="Subscription Before"
      text={item?.additionalInfo?.subscriptionBefore}
    />
    <TextInfo
      title="Churn After How Long Trial?"
      text={`${item?.additionalInfo?.churnAfterDaysTrial} ${getSingleOrPlural('Day', item?.additionalInfo?.churnAfterDaysTrial)}`}
    />
  </div>
);

AdditionalInfoChurn.propTypes = {
  item: PropTypes.object.isRequired,
};

AdditionalInfoChurn.defaultProps = {};

export default memo(AdditionalInfoChurn);
