import React from 'react';
import Card from 'react-bootstrap/Card';
import Image from 'react-bootstrap/Image';
import PropTypes from 'prop-types';
import CardSnapshot from './CardSnapshot/CardSnapshot';

const BoardSnapshot = ({ lists }) => {
  let joinedCards = [];

  lists.forEach((list) => {
    list.cards.forEach((card) => {
      const newCard = {
        ...card,
        list,
      };
      joinedCards.push(newCard);
    });
  });

  joinedCards = joinedCards.sort((a, b) => {
    const c = new Date(a.updatedAt);
    const d = new Date(b.updatedAt);
    return d - c;
  });

  return (
    <>
      {joinedCards.length < 1
        ? (
          <>
            <Image src={`${process.env.REACT_APP_PRIMARY_API_URL}/public/images/trello.png`} className="w-50" />
            <Card.Subtitle className="mb-2 text-muted">Get more things done by tracking work progress using Kanban Board</Card.Subtitle>
          </>
        )

        : (
          <>
            {joinedCards.map((card) => (
              <CardSnapshot card={card} />
            ))}
          </>
        )}

    </>
  );
};

BoardSnapshot.propTypes = {
  lists: PropTypes.array.isRequired,
};

export default BoardSnapshot;
