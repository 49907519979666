import React, { useState, useEffect } from 'react';
import CloseIcon from '@mui/icons-material/Close';
import { useSnackbar } from 'notistack';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import EditIcon from '@mui/icons-material/Edit';
import PropTypes from 'prop-types';
import BoxPopOver from '../../../components/UI/BoxPopOver/BoxPopOver';
import SeparatorLine from '../../../components/UI/SeparatorLine/SeparatorLine';
import styles from './BucketMenu.module.css';
import OverlayButton from '../../../components/UI/Button/OverlayButton/OverlayButton';
import handleStatusMsg from '../../../utilities/handleStatusMsg';
import handleLoadings from '../../../utilities/handleLoadings';
import { BucketActions } from '../../../actions';
import { SurfacePaperFloating } from '../../../components/UI_V2/Surface/Paper';

const BucketMenu = ({ bucket, cancel }) => {
  const [archiveBucket, setArchiveBucket] = useState();
  const { enqueueSnackbar } = useSnackbar();
  const [loadings, setLoadings] = useState([]);

  const history = useHistory();
  const location = useLocation();
  const params = useParams();
  const { companyId, teamId } = params;

  useEffect(() => {
    if (archiveBucket === undefined) return;

    const archivedApiBucket = async () => {
      const bucketId = archiveBucket;

      try {
        const startLoadings = handleLoadings('bucketMenu', [...loadings], 'start');
        setLoadings([...startLoadings]);

        const result = await BucketActions.archiveBucket({ bucketId, companyId, teamId });

        const status = handleStatusMsg(result, 'success');

        enqueueSnackbar(status.message, {
          variant: 'success',
        });

        cancel();
      } catch (err) {
        const status = handleStatusMsg(err, 'error');

        enqueueSnackbar(status.message, {
          variant: 'error',
        });
      } finally {
        const endLoadings = handleLoadings('bucketMenu', [...loadings], 'end');
        setLoadings([...endLoadings]);
      }
    };
    archivedApiBucket();
  }, [archiveBucket]);

  const handleArchivedBucket = (bucketId) => {
    setArchiveBucket(bucketId);
  };

  const handleEditBucket = () => {
    history.push(`${location.pathname}/edit`);
  };

  return (
    <SurfacePaperFloating
      size="md"
      position="topLeft"
    >
      <div className={styles.container}>
        <div className={styles.headerSection}>
          <h1>
            Menu
          </h1>
          <CloseIcon onClick={cancel} />
        </div>
        <SeparatorLine />
        <div className={styles.bodySection}>
          <OverlayButton wait="bucketMenu" loadings={loadings}>
            {bucket.type === 'folder' ? (
              <>
                <div onClick={handleEditBucket} className={styles.menu}>
                  <EditIcon />
                  <p>Ubah</p>
                </div>
                {bucket.archived ? (
                  <>
                    {bucket.archived.status ? (
                      <div className={styles.menu}>
                        <p>Folder ini udah terarsip</p>
                      </div>
                    ) : (
                      <div onClick={() => handleArchivedBucket(bucket._id)} className={styles.menu}>
                        <p>Arsipkan folder ini</p>
                      </div>
                    )}
                  </>
                ) : null}
              </>
            ) : <p>Docs & Files is the best place to centralized your team documents & files!</p>}
          </OverlayButton>
        </div>
      </div>
    </SurfacePaperFloating>
  );
};

BucketMenu.propTypes = {
  bucket: PropTypes.object.isRequired,
  cancel: PropTypes.func.isRequired,
};

export default BucketMenu;
