import React, { useContext, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import queryString from 'query-string';
import _ from 'lodash';
import useMediaQuery from '@mui/material/useMediaQuery';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { GlobalContext } from '../../contexts/GlobalStateProvider';
import styles from './ForgotPasswordPage.module.css';
import LogoHeader from '../../components/UI/LogoHeader/LogoHeader';
import mobileViewImage from '../../assets/AuthPage/login-illustration-mobileview.png';
import webViewImage from '../../assets/AuthPage/login-illustration-webview.png';
import Title from '../../components/Title/Title';
import { useTrackMetricVisitorPublic, } from '../../hooks/WebTrackingHooks';
import ForgotPasswordSection from './ForgotPasswordSection/ForgotPasswordSection';

function ForgotPasswordPage() {
  const [{ user }, dispatch] = useContext(GlobalContext);

  const history = useHistory();
  const location = useLocation();
  const query = queryString.parse(location.search);
  const isMobile = useMediaQuery('(max-width:1024px)');

  let titleDescText = {
    title: '',
    subTitle: null,
    desc: '',
  };

  const isFromEmailInvitation = query.sourcePage === 'emailInvitation';

  if (isFromEmailInvitation) {
    titleDescText = {
      title: 'Reset Password',
      subTitle: 'Kamu telah klik undangan bergabung ke Company di Cicle.',
      desc: 'Masukkan email untuk me-reset password',
    };
  } else {
    titleDescText = {
      title: 'Reset Password',
      desc: 'Masukkan email untuk me-reset password',
    };
  }

  return (
    <>
      <Title title="Cicle - Kelola tugas karyawan, laporan kinerja, dan komunikasi realtime dalam 1 aplikasi" />
      <LogoHeader />
      {user._id === undefined ? (
        <div className={styles.ForgotPasswordPage__container}>
          <div className={styles.ForgotPasswordPage__leftSection}>
            <LazyLoadImage
              alt=""
              className={styles.ForgotPasswordPage__leftSection__illustration}
              src={isMobile
                ? mobileViewImage
                : webViewImage}
            />
          </div>
          <div className={styles.ForgotPasswordPage__rightSection}>
            <div className={styles.ForgotPasswordPage__rightSection__text}>
              <div className={styles.ForgotPasswordPage__textSection}>
                <h1>{titleDescText.title}</h1>
                {titleDescText.subTitle
                && <p className={styles.subtitle}>{titleDescText.subTitle}</p>}
                <p>{titleDescText.desc}</p>
              </div>
            </div>
            <div className={styles.ForgotPasswordPage__rightSection__loginButton}>
              <ForgotPasswordSection isFromEmailInvitation={isFromEmailInvitation} />
            </div>
          </div>
        </div>
      ) : history.push('/')}
    </>
  );
}

export default ForgotPasswordPage;
