import React, { useContext, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import queryString from 'query-string';
import _ from 'lodash';
import useMediaQuery from '@mui/material/useMediaQuery';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { GlobalContext } from '../../contexts/GlobalStateProvider';
import styles from './RegisterPage.module.css';
import LogoHeader from '../../components/UI/LogoHeader/LogoHeader';
import mobileViewImage from '../../assets/AuthPage/login-illustration-mobileview.png';
import webViewImage from '../../assets/AuthPage/login-illustration-webview.png';
import Title from '../../components/Title/Title';
import { useTrackMetricVisitorPublic, } from '../../hooks/WebTrackingHooks';
import RegisterEmailPassword from './RegisterEmailPassword/RegisterEmailPassword';

function RegisterPage() {
  const [{ user }, dispatch] = useContext(GlobalContext);

  const history = useHistory();
  const location = useLocation();
  const query = queryString.parse(location.search);
  const isMobile = useMediaQuery('(max-width:1024px)');

  let titleDescText = {
    title: '',
    subTitle: null,
    desc: '',
  };

  const isFromEmailInvitation = query.sourcePage === 'emailInvitation';
  const isFromSalesPageTrialStarter = query.sourcePage === '7-days-trial-web-starter';
  const isFromSalesPageTrialGrowth = query.sourcePage === '7-days-trial-web-growth';
  const isFromSalesPageTrialPro = query.sourcePage === '7-days-trial-web-pro';
  const isFromSalesPageTrialScale = query.sourcePage === '7-days-trial-web-scale';
  const isFromSalesPageTrialPremium = query.sourcePage === '7-days-trial-web-premium';
  const isFromSalesPageTrial = isFromSalesPageTrialStarter
    || isFromSalesPageTrialPro || isFromSalesPageTrialPremium
    || isFromSalesPageTrialGrowth || isFromSalesPageTrialScale;

  let planName;
  if (isFromEmailInvitation) {
    titleDescText = {
      title: 'Satu langkah lagi...',
      subTitle: 'Kamu telah klik undangan bergabung ke Company di Cicle.',
      desc: 'Silahkan daftar/masuk terlebih dahulu dengan email-mu, sebelum kami tambahkan kamu ke dalam Company.',
    };
  } else if (isFromSalesPageTrial) {
    titleDescText = {
      title: 'Klaim Trial Gratis Cicle',
      subTitle: 'Kamu bisa mencoba Cicle gratis untuk 7 hari kedepan.',
      desc: 'Ga perlu data kartu kredit, cukup daftar dengan mengisi form dibawah. Cuss, langsung aja.',
    };

    planName = query.sourcePage;
  } else {
    titleDescText = {
      title: 'Daftar Cicle',
      desc: 'Silahkan daftar terlebih dahulu.',
    };
  }

  if (!isFromEmailInvitation) {
    localStorage.removeItem('emailInvitationToken');
  }

  useTrackMetricVisitorPublic({
    planName,
  });

  return (
    <>
      <Title title="Cicle - Kelola tugas karyawan, laporan kinerja, dan komunikasi realtime dalam 1 aplikasi" />
      <LogoHeader />
      {user._id === undefined ? (
        <div className={styles.RegisterPage__container}>
          <div className={styles.RegisterPage__leftSection}>
            <LazyLoadImage
              alt=""
              className={styles.RegisterPage__leftSection__illustration}
              src={isMobile
                ? mobileViewImage
                : webViewImage}
            />
          </div>
          <div className={styles.RegisterPage__rightSection}>
            <div className={styles.RegisterPage__rightSection__text}>
              <div className={styles.RegisterPage__textSection}>
                <h1>{titleDescText.title}</h1>
                {titleDescText.subTitle
                && <p className={styles.subtitle}>{titleDescText.subTitle}</p>}
                <p>{titleDescText.desc}</p>
              </div>
            </div>
            <div className={styles.RegisterPage__rightSection__loginButton}>
              <RegisterEmailPassword isFromEmailInvitation={isFromEmailInvitation} />
            </div>
          </div>
        </div>
      ) : history.push('/')}
    </>
  );
}

export default RegisterPage;
