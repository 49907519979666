import React, {
  memo, useCallback, useEffect, useState,
} from 'react';
import PropTypes from 'prop-types';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import ViewModuleIcon from '@mui/icons-material/ViewModule';
import GroupsIcon from '@mui/icons-material/Groups';
import ArchiveIcon from '@mui/icons-material/Archive';
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import styles from './FilterDropdown.module.css';
import { DisplayTextBody } from '../../UI_V2/Display/Text';
import Color from '../../../themes/colors';
import { InputButtonDropdown, InputButtonMain } from '../../UI_V2/Input/Button';
import {
  useDelayShowHideHandler, useSearch, useSelectFromUntilDate, useSelectItemsOnList,
} from '../../../hooks/HelperHooks';
import { NavigationMenuBasic } from '../../UI_V2/Navigation/Menu';
import TeamFilterSection from '../FilterBar/TeamFilterSection/TeamFilterSection';
import DropdownMenu from './DropdownMenu/DropdownMenu';
import ArchivedFilterSection from '../FilterBar/ArchivedFilterSection/ArchivedFilterSection';
import FeatureTypeFilterSection from '../FilterBar/FeatureTypeFilterSection/FeatureTypeFilterSection';
import { extractNamesFromObjectItems, sortListByStringValue } from '../../../utilities/arrayUtil';
import FromUntilDateFilterSection from '../FilterBar/FromUntilDateFilterSection/FromUntilDateFilterSection';
import {
  dateBeautifyFormatter,
  getPreviousMonthDate,
  getPreviousQuarterDate,
  getPreviousTwoWeekDate,
  getPreviousWeekDate,
  getPreviousYearDate,
  getThisMonthDate,
  getThisQuarterDate,
  getThisTwoWeekDate,
  getThisWeekDate,
  getThisYearDate,
  getTodayDate,
  getYesterdayDate,
} from '../../../utilities/dateUtil';
import TrialFilterSection from '../FilterBar/TrialFilterSection/TrialFilterSection';
import MetricCategoryFilterSection from '../FilterBar/MetricCategoryFilterSection/MetricCategoryFilterSection';
import SingleTeamFilterSection from '../FilterBar/SingleTeamFilterSection/SingleTeamFilterSection';
import { modifyInitialDateFilterReport } from '../../../actions/ReportActions';

// lingkupnya :
// dapetin initial data, filtering selected data
// reset selected data, kirim balik selected data.
// tentuin mana aja filter yg di show dan tidak.

const orientationContainerStyles = {
  horizontal: '',
  vertical: styles.verticalContainer,
};

const orientationFilterSectionStyles = {
  horizontal: '',
  vertical: styles.verticalFilterSection,
};

const orientationFilterItemStyles = {
  horizontal: '',
  vertical: styles.verticalFilterItem,
};

const FilterDropdown = ({
  topPositionVariant,
  buttonSizeVariant,
  listLabels,
  initialSelectedLabels,
  showLabelSection,
  listMembers,
  initialSelectedMembers,
  showMemberSection,
  initialDueDate,
  showDueDateSection,
  listData,
  initialSearch,
  showSearchSection,
  listTeams,
  initialSelectedTeams,
  showTeamSection,
  showFromUntilDateSection,
  initialFromDate,
  initialUntilDate,
  initialSelectedQuickDate,
  showArchivedSection,
  initialArchived,
  showSingleTeamSection,
  initialSingleTeam,
  listSingleTeams,
  showTrialSection,
  initialTrial,
  showMetricCategorySection,
  initialMetricCategory,
  initialSelectedFeatureTypes,
  listFeatureTypes,
  showFeatureTypeSection,
  barTitle,
  onClose,
  onApplyFilters,
  waitOnApplyFilters,
  loadingsOnApplyFilters,
  disableResetSearch,
  disableResetTeam,
  disableResetSingleTeam,
  disableResetLabel,
  disableResetMember,
  disableResetDueDate,
  disableResetFromUntilDate,
  disableResetArchived,
  disableResetTrial,
  disableResetMetricCategory,
  disableResetFeatureType,
  showBackIconOnClose,
  disableCloseIcon,
  invertResetTeamCondition,
  invertResetFeatureTypeCondition,
  orientation,
  listDisableQuickDate,
}) => {
  const [isFilterTeamsActive, setIsFilterTeamsActive] = useState(false);
  const [isFilterArchivedActive, setIsFilterArchivedActive] = useState(false);
  const [isFilterFeatureTypesActive, setIsFilterFeatureTypesActive] = useState(false);
  const [isFilterFromUntilDateActive, setIsFilterFromUntilDateActive] = useState(false);
  const [isFilterTrialActive, setIsFilterTrialActive] = useState(false);
  const [isFilterSingleTeamActive, setIsFilterSingleTeamActive] = useState(false);
  const [isFilterMetricCategoryActive, setIsFilterMetricCategoryActive] = useState(false);
  /*
    Feature Type hook section
  */
  const featureTypeFilterSectionSearchHook = useSearch({ originalList: listFeatureTypes });

  const [
    showFeatureTypeSectionMenu,
    handleShowFeatureTypeSectionMenu,
    handleHideFeatureTypeSectionMenu,
  ] = useDelayShowHideHandler();

  const [
    showAllFeatureTypes,
    handleShowAllFeatureTypes,
    handleHideAllFeatureTypes,
  ] = useDelayShowHideHandler();

  const [
    checkFeatureTypes,
    handleClickFeatureType,
    isAllFeatureTypesSelected,
    handleClickAllFeatureTypes,
    isNoneFeatureTypesSelected,
    handleResetFeatureTypes,
  ] = useSelectItemsOnList({
    list: listFeatureTypes,
    initialSelectedItems: initialSelectedFeatureTypes,
    invertResetCondition: invertResetFeatureTypeCondition,
  });

  const getFeatureTypeText = (arrayIds, listItems) => {
    let text = '';

    if (isAllFeatureTypesSelected && arrayIds.length === listItems.length) {
      text = 'Semua Fitur';
      return text;
    }
    if (isNoneFeatureTypesSelected && arrayIds.length < 1) {
      text = 'Tidak Ada';
      return text;
    }

    text = extractNamesFromObjectItems(arrayIds, listItems);
    return text;
  };

  /*
    Team filter hook section
  */
  const teamFilterSectionSearchHook = useSearch({ originalList: listTeams });

  const [
    showTeamSectionMenu,
    handleShowTeamSectionMenu,
    handleHideTeamSectionMenu,
  ] = useDelayShowHideHandler();

  const [
    showAllTeams,
    handleShowAllTeams,
    handleHideAllTeams,
  ] = useDelayShowHideHandler();

  const [
    checkTeams,
    handleClickTeam,
    isAllTeamsSelected,
    handleClickAllTeams,
    isNoneTeamsSelected,
    handleResetTeams,
  ] = useSelectItemsOnList({
    list: listTeams,
    initialSelectedItems: initialSelectedTeams,
    invertResetCondition: invertResetTeamCondition,
  });

  const getTeamText = (arrayIds, listItems) => {
    let text = '';

    if (isAllTeamsSelected && arrayIds.length === listItems.length) {
      text = 'Semua Tim';
      return text;
    }
    if (isNoneTeamsSelected && arrayIds.length < 1) {
      text = 'Tidak Ada';
      return text;
    }

    text = extractNamesFromObjectItems(arrayIds, listItems);
    return text;
  };

  /*
    Single team filter hook section
  */

  const singleTeamFilterSectionSearchHook = useSearch({ originalList: listSingleTeams });

  const [
    showSingleTeamSectionMenu,
    handleShowSingleTeamSectionMenu,
    handleHideSingleTeamSectionMenu,
  ] = useDelayShowHideHandler();

  const [checkSingleTeam, setCheckSingleTeam] = useState(initialSingleTeam);
  const handleClickSingleTeam = (valueTeamId) => {
    setCheckSingleTeam(valueTeamId);
  };
  const handleResetSingleTeam = () => {
    setCheckSingleTeam(undefined);
  };

  const getSingleTeamText = (valueTeamId) => {
    let text = '';
    const filterSingleTeamArr = singleTeamFilterSectionSearchHook.searchResult.filter(
      (elem) => elem._id === valueTeamId,
    );

    if (filterSingleTeamArr && filterSingleTeamArr.length > 0) {
      text = filterSingleTeamArr?.[0]?.name;
    }
    return text;
  };

  /*
    Archived filter hook section
  */
  const [
    showArchivedSectionMenu,
    handleShowArchivedSectionMenu,
    handleHideArchivedSectionMenu,
  ] = useDelayShowHideHandler();

  const [checkArchived, setCheckArchived] = useState(initialArchived);
  const handleClickArchived = (value) => {
    setCheckArchived(value);
  };
  const handleResetArchived = () => {
    setCheckArchived(false);
  };

  /*
    Select From Until Date section
  */

  const [
    showFromUntilDateSectionMenu,
    handleShowFromUntilDateSectionMenu,
    handleHideFromUntilDateSectionMenu,
  ] = useDelayShowHideHandler();

  const selectFromUntilHook = useSelectFromUntilDate(initialFromDate, initialUntilDate);

  const [selectedQuickDate, setSelectedQuickDate] = useState(initialSelectedQuickDate);

  const handleSelectQuickDate = useCallback((value) => {
    setSelectedQuickDate(value);
  }, []);

  const [
    initFromDate,
    fromDate,
    handleDateChangeFrom,
    handleDateChangeInitFrom,
    initUntilDate,
    untilDate,
    handleDateChangeUntil,
    handleDateChangeInitUntil,
    handleResetFromUntilDate,
  ] = selectFromUntilHook;

  // const getNewFromUntilDateByQuickDate = useCallback((value) => {
  //   let newFromDate;
  //   let newUntilDate;

  //   // let minusDay = 0;
  //   switch (value) {
  //     case 'today':
  //       [newFromDate, newUntilDate] = getTodayDate();
  //       break;
  //     case 'yesterday':
  //       [newFromDate, newUntilDate] = getYesterdayDate();
  //       break;
  //     case 'thisWeek':
  //       [newFromDate, newUntilDate] = getThisWeekDate();
  //       break;
  //     case 'previousWeek':
  //       [newFromDate, newUntilDate] = getPreviousWeekDate();
  //       break;
  //     case 'thisTwoWeek':
  //       [newFromDate, newUntilDate] = getThisTwoWeekDate();
  //       break;
  //     case 'previousTwoWeek':
  //       [newFromDate, newUntilDate] = getPreviousTwoWeekDate();
  //       break;
  //     case 'thisMonth':
  //       [newFromDate, newUntilDate] = getThisMonthDate();
  //       break;
  //     case 'previousMonth':
  //       [newFromDate, newUntilDate] = getPreviousMonthDate();
  //       break;
  //     case 'thisQuarter':
  //       [newFromDate, newUntilDate] = getThisQuarterDate();
  //       break;
  //     case 'previousQuarter':
  //       [newFromDate, newUntilDate] = getPreviousQuarterDate();
  //       break;
  //     case 'thisYear':
  //       [newFromDate, newUntilDate] = getThisYearDate();
  //       break;
  //     case 'previousYear':
  //       [newFromDate, newUntilDate] = getPreviousYearDate();
  //       break;
  //     default:
  //       break;
  //   }

  //   // const newFromDate = calculateMonthday(newUntilDate, minusDay, 'subtract');

  //   return [
  //     newFromDate,
  //     newUntilDate,
  //   ];
  // }, []);

  const modifyFromUntilDateByQuickDate = useCallback((value) => {
    const [
      newFromDate,
      newUntilDate,
    ] = modifyInitialDateFilterReport(value);

    handleDateChangeFrom(newFromDate);
    handleDateChangeUntil(newUntilDate, true);
  }, []);

  const getFromUntilDateText = (fromDateValue, untilDateValue) => {
    const fromDateText = dateBeautifyFormatter(fromDateValue, true, 'MonthDate');
    const untilDateText = dateBeautifyFormatter(untilDateValue, true, 'MonthDate');
    const text = `${fromDateText} - ${untilDateText}`;
    return text;
  };

  const [
    isFirstLoadDate,
    handleShowIsFirstLoadDate,
    handleHideIsFirstLoadDate,
  ] = useDelayShowHideHandler(false);

  /*
    Trial filter hook section
  */

  const [
    showTrialSectionMenu,
    handleShowTrialSectionMenu,
    handleHideTrialSectionMenu,
  ] = useDelayShowHideHandler();

  const [checkTrial, setCheckTrial] = useState(initialTrial);
  const handleClickTrial = (value) => {
    setCheckTrial(value);
  };
  const handleResetTrial = () => {
    setCheckTrial(undefined);
  };

  const getTrialText = (value) => {
    let text = '';
    switch (value) {
      case true:
        text = 'Trial';
        break;
      case false:
        text = 'Customer';
        break;
      default:
        text = 'Both';
        break;
    }
    return text;
  };

  /*
    MetricCategory filter hook section
  */

  const [
    showMetricCategorySectionMenu,
    handleShowMetricCategorySectionMenu,
    handleHideMetricCategorySectionMenu,
  ] = useDelayShowHideHandler();

  const [checkMetricCategory, setCheckMetricCategory] = useState(initialMetricCategory);
  const handleClickMetricCategory = (value) => {
    setCheckMetricCategory(value);
  };
  const handleResetMetricCategory = () => {
    setCheckMetricCategory(undefined);
  };

  const getMetricCategoryText = (value) => {
    let text = '';
    switch (value) {
      case 'visitor':
        text = 'Visitor';
        break;
      case 'acquisition':
        text = 'Acquisition';
        break;
      case 'activation':
        text = 'Activation';
        break;
      case 'retention':
        text = 'Retention';
        break;
      case 'revenue':
        text = 'Revenue';
        break;
      case 'referral':
        text = 'Referral';
        break;
      case 'churn':
        text = 'Churn';
        break;
      default:
        text = 'All';
        break;
    }
    return text;
  };

  /*
    Filter active count
  */
  const [filterActiveCount, setFilterActiveCount] = useState(0);
  useEffect(() => {
    // const isSearchFilterActive = searchFilterSectionSearchHook.debouncedKeyword.length > 0
    //   && showSearchSection;
    let isTeamsFilterActive = checkTeams.length > 0 && showTeamSection;
    if (invertResetTeamCondition) {
      isTeamsFilterActive = checkTeams.length < listTeams.length && showTeamSection;
    }
    setIsFilterTeamsActive(isTeamsFilterActive);
    // const isLabelsFilterActive = checkLabels.length > 0 && showLabelSection;
    // const isMembersFilterActive = checkMembers.length > 0 && showMemberSection;
    // const isDueDateFilterActive = checkDueDate && showDueDateSection;
    const isArchivedFilterActive = checkArchived && showArchivedSection;
    setIsFilterArchivedActive(isArchivedFilterActive);

    const isTrialFilterActive = checkTrial !== undefined && showTrialSection;
    setIsFilterTrialActive(isTrialFilterActive);

    const isSingleTeamFilterActive = checkSingleTeam !== undefined && showSingleTeamSection;
    setIsFilterSingleTeamActive(isSingleTeamFilterActive);

    const isMetricCategoryFilterActive = checkMetricCategory !== undefined
    && showMetricCategorySection;
    setIsFilterMetricCategoryActive(isMetricCategoryFilterActive);

    let isFeatureTypesFilterActive = checkFeatureTypes.length > 0 && showFeatureTypeSection;
    if (invertResetFeatureTypeCondition) {
      isFeatureTypesFilterActive = checkFeatureTypes.length < listFeatureTypes.length
      && showFeatureTypeSection;
    }
    setIsFilterFeatureTypesActive(isFeatureTypesFilterActive);

    // fromUntilDateSection will always have false filter active.

    let count = 0;
    // if (isSearchFilterActive) count += 1;
    if (isTeamsFilterActive) count += 1;
    // if (isLabelsFilterActive) count += 1;
    // if (isMembersFilterActive) count += 1;
    // if (isDueDateFilterActive) count += 1;
    if (isArchivedFilterActive) count += 1;
    if (isTrialFilterActive) count += 1;
    if (isMetricCategoryFilterActive) count += 1;
    if (isFeatureTypesFilterActive) count += 1;
    setFilterActiveCount(count);
  }, [
    // searchFilterSectionSearchHook.debouncedKeyword,
    checkTeams,
    checkSingleTeam,
    // checkLabels,
    // checkMembers,
    // checkDueDate,
    checkArchived,
    checkFeatureTypes,
    checkTrial,
    checkMetricCategory,
    barTitle,
  ]);

  /* Should apply filter button shows up? */
  const [showApplyFilterButton, setShowApplyFilterButton] = useState(false);
  useEffect(() => {
    const isTeamDifferent = getTeamText(checkTeams, listTeams)
    !== getTeamText(initialSelectedTeams, listTeams);

    const isFeatureTypeDifferent = getFeatureTypeText(checkFeatureTypes, listFeatureTypes)
    !== getFeatureTypeText(initialSelectedFeatureTypes, listFeatureTypes);

    const isArchivedDifferent = checkArchived !== initialArchived;

    const isFromUntilDateDifferent = getFromUntilDateText(fromDate, untilDate)
    !== getFromUntilDateText(initFromDate, initUntilDate);

    const isTrialDifferent = checkTrial !== initialTrial;
    const isSingleTeamDifferent = checkSingleTeam !== initialSingleTeam;

    const isMetricCategoryDifferent = checkMetricCategory !== initialMetricCategory;

    if (isTeamDifferent || isFeatureTypeDifferent || isArchivedDifferent
      || isFromUntilDateDifferent || isTrialDifferent
      || isMetricCategoryDifferent
      || isSingleTeamDifferent
    ) {
      setShowApplyFilterButton(true);
    } else {
      setShowApplyFilterButton(false);
    }
  }, [// searchFilterSectionSearchHook.debouncedKeyword,
    checkTeams,
    checkSingleTeam,
    // checkLabels,
    // checkMembers,
    // checkDueDate,
    checkArchived,
    checkFeatureTypes,
    fromDate,
    initFromDate,
    untilDate,
    initUntilDate,
    checkTrial,
    initialTrial,
    checkMetricCategory,
    initialMetricCategory,
    barTitle]);

  /*
      Reset Filter
    */

  const handleResetFilter = () => {
    // reset search name
    // if (!disableResetSearch) {
    //   searchFilterSectionSearchHook.resetSearch();
    // }
    // reset teams
    if (!disableResetTeam) {
      teamFilterSectionSearchHook.resetSearch();
      handleResetTeams();
    }
    // // resetlabels
    // if (!disableResetLabel) {
    //   labelFilterSectionSearchHook.resetSearch();
    //   handleResetLabels();
    // }
    // // reset members
    // if (!disableResetMember) {
    //   memberFilterSectionSearchHook.resetSearch();
    //   handleResetMembers();
    // }
    // reset due date
    // if (!disableResetDueDate) {
    //   handleResetDueDate();
    // }

    // reset archived
    if (!disableResetArchived) {
      handleResetArchived();
    }

    // reset single team
    if (!disableResetSingleTeam) {
      handleResetSingleTeam();
    }

    // reset from until
    if (!disableResetFromUntilDate) {
      handleResetFromUntilDate();
    }

    // reset trial
    if (!disableResetTrial) {
      handleResetTrial();
    }

    // reset metric category
    if (!disableResetMetricCategory) {
      handleResetMetricCategory();
    }

    // reset feature types
    if (!disableResetFeatureType) {
      teamFilterSectionSearchHook.resetSearch();
      handleResetFeatureTypes();
    }
  };

  /*
      Apply Filter
    */

  const handleClickApply = () => {
    onApplyFilters({
      filterTeams: checkTeams,
      filterSingleTeam: checkSingleTeam,
      // filterSubscribers: checkMembers,
      // filterLabels: checkLabels,
      // filterTitle: searchFilterSectionSearchHook.debouncedKeyword,
      // filterDueDate: checkDueDate,
      filterArchived: checkArchived,
      filterFeatureTypes: checkFeatureTypes,
      filterFromUntilDate: {
        fromDate,
        untilDate,
        selectedQuickDate,
      },
      filterTrial: checkTrial,
      filterMetricCategory: checkMetricCategory,
    });
  };

  return (
    <div className={`${styles.container} ${orientationContainerStyles[orientation]}`}>
      <div className={`${styles.filterSection} ${orientationFilterSectionStyles[orientation]}`}>
        {showFeatureTypeSection && (
        <div className={`${styles.filterItem} ${orientationFilterItemStyles[orientation]}`}>
          <InputButtonDropdown
            size={buttonSizeVariant}
            text={getFeatureTypeText(checkFeatureTypes, listFeatureTypes)}
            initialDataString={getFeatureTypeText(initialSelectedFeatureTypes, listFeatureTypes)}
            onClick={handleShowFeatureTypeSectionMenu}
            icon={<ViewModuleIcon />}
            isFilterActive={isFilterFeatureTypesActive}
          />
          {showFeatureTypeSectionMenu && (
          <DropdownMenu
            onClose={handleHideFeatureTypeSectionMenu}
          >
            <FeatureTypeFilterSection
              searchHook={featureTypeFilterSectionSearchHook}
              showAllFeatureTypes={showAllFeatureTypes}
              handleShowAllFeatureTypes={handleShowAllFeatureTypes}
              handleHideAllFeatureTypes={handleHideAllFeatureTypes}
              checkFeatureTypes={checkFeatureTypes}
              handleClickFeatureType={handleClickFeatureType}
              isAllFeatureTypesSelected={isAllFeatureTypesSelected}
              handleClickAllFeatureTypes={handleClickAllFeatureTypes}
            />
          </DropdownMenu>
          )}
        </div>
        )}
        {showTeamSection && (
        <div className={`${styles.filterItem} ${orientationFilterItemStyles[orientation]}`}>
          <InputButtonDropdown
            size={buttonSizeVariant}
            text={getTeamText(checkTeams, listTeams)}
            initialDataString={getTeamText(initialSelectedTeams, listTeams)}
            onClick={handleShowTeamSectionMenu}
            icon={<GroupsIcon />}
            isFilterActive={isFilterTeamsActive}
          />
          {showTeamSectionMenu
          && (
          <DropdownMenu
            onClose={handleHideTeamSectionMenu}
          >
            <TeamFilterSection
              searchHook={teamFilterSectionSearchHook}
              showAllTeams={showAllTeams}
              handleShowAllTeams={handleShowAllTeams}
              handleHideAllTeams={handleHideAllTeams}
              checkTeams={checkTeams}
              handleClickTeam={handleClickTeam}
              isAllTeamsSelected={isAllTeamsSelected}
              handleClickAllTeams={handleClickAllTeams}
            />
          </DropdownMenu>
          )}
        </div>
        )}
        {showArchivedSection && (
        <div className={`${styles.filterItem} ${orientationFilterItemStyles[orientation]}`}>
          <InputButtonDropdown
            size={buttonSizeVariant}
            text={checkArchived ? 'Terarsip Saja' : 'Normal'}
            initialDataString={initialArchived ? 'Terarsip Saja' : 'Normal'}
            onClick={handleShowArchivedSectionMenu}
            icon={<ArchiveIcon />}
            isFilterActive={isFilterArchivedActive}
          />
          {showArchivedSectionMenu && (
          <DropdownMenu
            onClose={handleHideArchivedSectionMenu}
          >
            <ArchivedFilterSection
              checkArchived={checkArchived}
              handleClickArchived={handleClickArchived}
            />
          </DropdownMenu>
          )}
        </div>
        )}
        {showSingleTeamSection && (
        <div className={`${styles.filterItem} ${orientationFilterItemStyles[orientation]}`}>
          <InputButtonDropdown
            size={buttonSizeVariant}
            text={getSingleTeamText(checkSingleTeam)}
            initialDataString={getSingleTeamText(initialSingleTeam)}
            onClick={handleShowSingleTeamSectionMenu}
            icon={<ArchiveIcon />}
            isFilterActive={isFilterSingleTeamActive}
          />
          {showSingleTeamSectionMenu && (
          <DropdownMenu
            onClose={handleHideSingleTeamSectionMenu}
          >
            <SingleTeamFilterSection
              checkSingleTeam={checkSingleTeam}
              handleClickSingleTeam={handleClickSingleTeam}
              searchHook={singleTeamFilterSectionSearchHook}
            />
          </DropdownMenu>
          )}
        </div>
        )}
        {showFromUntilDateSection && (
        <div className={`${styles.filterItem} ${orientationFilterItemStyles[orientation]}`}>
          <InputButtonDropdown
            size={buttonSizeVariant}
            text={getFromUntilDateText(fromDate, untilDate)}
            initialDataString={getFromUntilDateText(initFromDate, initUntilDate)}
            onClick={handleShowFromUntilDateSectionMenu}
            icon={<CalendarTodayIcon />}
            isFilterActive={false} // always false since changing any date doesnt matter.
          />
          {showFromUntilDateSectionMenu && (
          <DropdownMenu
            onClose={handleHideFromUntilDateSectionMenu}
            disabled={showFromUntilDateSectionMenu && isFirstLoadDate}
          >
            <FromUntilDateFilterSection
              selectFromUntilHook={selectFromUntilHook}
              onApply={handleHideFromUntilDateSectionMenu}
              selectedQuickDate={selectedQuickDate}
              handleSelectQuickDate={handleSelectQuickDate}
              modifyFromUntilDateByQuickDate={modifyFromUntilDateByQuickDate}
              handleSetIsFirstLoadDate={handleShowIsFirstLoadDate}
              handleUnsetIsFirstLoadDate={handleHideIsFirstLoadDate}
              listDisableQuickDate={listDisableQuickDate}
            />
          </DropdownMenu>
          )}
        </div>
        )}
        {showTrialSection && (
        <div className={`${styles.filterItem} ${orientationFilterItemStyles[orientation]}`}>
          <InputButtonDropdown
            size={buttonSizeVariant}
            text={getTrialText(checkTrial)}
            initialDataString={getTrialText(initialTrial)}
            onClick={handleShowTrialSectionMenu}
            icon={<ArchiveIcon />}
            isFilterActive={isFilterTrialActive}
          />
          {showTrialSectionMenu && (
          <DropdownMenu
            onClose={handleHideTrialSectionMenu}
          >
            <TrialFilterSection
              checkTrial={checkTrial}
              handleClickTrial={handleClickTrial}
            />
          </DropdownMenu>
          )}
        </div>
        )}
        {showMetricCategorySection && (
        <div className={`${styles.filterItem} ${orientationFilterItemStyles[orientation]}`}>
          <InputButtonDropdown
            size={buttonSizeVariant}
            text={getMetricCategoryText(checkMetricCategory)}
            initialDataString={getMetricCategoryText(initialMetricCategory)}
            onClick={handleShowMetricCategorySectionMenu}
            icon={<ArchiveIcon />}
            isFilterActive={isFilterMetricCategoryActive}
          />
          {showMetricCategorySectionMenu && (
          <DropdownMenu
            onClose={handleHideMetricCategorySectionMenu}
          >
            <MetricCategoryFilterSection
              checkMetricCategory={checkMetricCategory}
              handleClickMetricCategory={handleClickMetricCategory}
            />
          </DropdownMenu>
          )}
        </div>
        )}
      </div>
      <div className={styles.action}>
        {showApplyFilterButton && (
        <div className={styles.apply}>
          <InputButtonMain handleClick={handleClickApply} wait={waitOnApplyFilters} size="sm" loadings={loadingsOnApplyFilters}>Apply</InputButtonMain>
        </div>
        )}
        {filterActiveCount > 0 && (
        <div onClick={handleResetFilter} className={styles.reset}>
          <DisplayTextBody
            mode="12"
            color={Color.deleteColor}
            decoration="bold"
          >
            Reset
          </DisplayTextBody>
        </div>
        )}
      </div>
    </div>
  );
};

FilterDropdown.propTypes = {
  topPositionVariant: PropTypes.oneOf(['navbarOnly', 'withSubNavBar', 'withBottomBar']),
  buttonSizeVariant: PropTypes.oneOf(['sm', 'md']),
  listData: PropTypes.array.isRequired,
  listLabels: PropTypes.array,
  initialSelectedLabels: PropTypes.array,
  showLabelSection: PropTypes.bool,
  listMembers: PropTypes.array,
  initialSelectedMembers: PropTypes.array,
  showMemberSection: PropTypes.bool,
  initialDueDate: PropTypes.string,
  showDueDateSection: PropTypes.bool,
  initialSearch: PropTypes.string,
  showSearchSection: PropTypes.bool,
  listTeams: PropTypes.array,
  initialSelectedTeams: PropTypes.array,
  showTeamSection: PropTypes.bool,
  listFeatureTypes: PropTypes.array,
  initialSelectedFeatureTypes: PropTypes.array,
  showFeatureTypeSection: PropTypes.bool,
  initialArchived: PropTypes.bool,
  showArchivedSection: PropTypes.bool,
  initialSingleTeam: PropTypes.string,
  showSingleTeamSection: PropTypes.bool,
  listSingleTeams: PropTypes.array,
  initialTrial: PropTypes.bool,
  showTrialSection: PropTypes.bool,
  initialMetricCategory: PropTypes.string,
  showMetricCategorySection: PropTypes.bool,
  showFromUntilDateSection: PropTypes.bool,
  initialFromDate: PropTypes.string,
  initialUntilDate: PropTypes.string,
  initialSelectedQuickDate: PropTypes.string,
  barTitle: PropTypes.string,
  onClose: PropTypes.func.isRequired,
  onApplyFilters: PropTypes.func.isRequired,
  waitOnApplyFilters: PropTypes.string.isRequired,
  loadingsOnApplyFilters: PropTypes.array.isRequired,
  disableResetDueDate: PropTypes.bool,
  disableResetLabel: PropTypes.bool,
  disableResetMember: PropTypes.bool,
  disableResetTeam: PropTypes.bool,
  disableResetSingleTeam: PropTypes.bool,
  disableResetSearch: PropTypes.bool,
  disableResetArchived: PropTypes.bool,
  disableResetMetricCategory: PropTypes.bool,
  disableResetTrial: PropTypes.bool,
  disableResetFeatureType: PropTypes.bool,
  disableResetFromUntilDate: PropTypes.bool,
  invertResetTeamCondition: PropTypes.bool,
  invertResetFeatureTypeCondition: PropTypes.bool,
  showBackIconOnClose: PropTypes.bool,
  disableCloseIcon: PropTypes.bool,
  orientation: PropTypes.oneOf(['vertical', 'horizontal']),
  listDisableQuickDate: PropTypes.array,
};

FilterDropdown.defaultProps = {
  topPositionVariant: 'withSubNavBar',
  buttonSizeVariant: 'md',
  listLabels: [],
  initialSelectedLabels: [],
  showLabelSection: true,
  listMembers: [],
  initialSelectedMembers: [],
  showMemberSection: true,
  showDueDateSection: true,
  showSearchSection: true,
  listTeams: [],
  initialSelectedTeams: [],
  listFeatureTypes: [],
  initialSelectedFeatureTypes: [],
  initialSearch: '',
  initialDueDate: undefined,
  showTeamSection: true,
  showFeatureTypeSection: true,
  initialArchived: false,
  showArchivedSection: true,
  initialSingleTeam: undefined,
  showSingleTeamSection: false,
  listSingleTeams: [],
  initialTrial: undefined,
  showTrialSection: false,
  initialMetricCategory: undefined,
  showMetricCategorySection: false,
  showFromUntilDateSection: false,
  initialFromDate: new Date(),
  initialUntilDate: new Date(),
  initialSelectedQuickDate: 'thisMonth',
  barTitle: 'Filter',
  disableResetDueDate: false,
  disableResetSearch: false,
  disableResetMember: false,
  disableResetTeam: false,
  disableResetSingleTeam: false,
  disableResetFromUntilDate: false,
  disableResetLabel: false,
  disableResetArchived: false,
  disableResetTrial: false,
  disableResetMetricCategory: false,
  disableResetFeatureType: false,
  showBackIconOnClose: false,
  disableCloseIcon: false,
  invertResetFeatureTypeCondition: false,
  invertResetTeamCondition: false,
  orientation: 'horizontal',
  listDisableQuickDate: [],
};

export default memo(FilterDropdown);
