import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { Paper } from '@mui/material';
import { styled } from '@mui/material/styles';
import styles from './Main.module.css';
import Color from '../../../../../themes/colors';

const defaultStyles = {
  position: 'absolute',
  top: '15px',
  zIndex: 4,
  border: '1px solid #DEEAFF',
};

const SurfacePaperMain = ({
  elevation,
  square,
  variant,
  children,
  onOutsideClick,
  disabled,
  customStyle,
  position,
  width,
  height,
  top,
  left,
  right,
  bottom,
}) => (
  <Paper
    elevation={elevation}
    square={square}
    variant={variant}
    className={styles.container}
  >
    {children}
  </Paper>
);

SurfacePaperMain.propTypes = {
  elevation: PropTypes.number,
  variant: PropTypes.oneOf(['outlined', 'elevation']),
  square: PropTypes.bool,
  children: PropTypes.node,
  onOutsideClick: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  customStyle: PropTypes.object,
  position: PropTypes.oneOf(['left', 'right']),
  width: PropTypes.string,
  height: PropTypes.string,
  top: PropTypes.string,
  left: PropTypes.string,
  right: PropTypes.string,
  bottom: PropTypes.string,
};

SurfacePaperMain.defaultProps = {
  elevation: 1,
  variant: 'elevation',
  square: false,
  children: null,
  disabled: false,
  customStyle: {},
  position: 'center',
  width: '50vw',
  height: '40vh',
  top: '0px',
  left: '0px',
  right: 'auto',
  bottom: 'auto',
};

export default memo(SurfacePaperMain);
