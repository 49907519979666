import React, { memo, useState } from 'react';
import PropTypes from 'prop-types';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import styles from './BackgroundChart.module.css';
import Color from '../../../../../themes/colors';
import DisplayTextSubHeadline from '../../../../../components/UI_V2/Display/Text/SubHeadline';

const heightVariantStyles = {
  low: styles.lowHeight,
  medium: styles.mediumHeight,
  high: styles.highHeight,
};

const BackgroundChart = ({
  children,
  heightVariant,
}) => (
  <div className={`${styles.container} ${heightVariantStyles[heightVariant]}`}>
    {children}
  </div>
);

BackgroundChart.propTypes = {
  children: PropTypes.node,
  heightVariant: PropTypes.oneOf(['high', 'medium', 'low']),
};

BackgroundChart.defaultProps = {
  children: <></>,
  heightVariant: 'medium',
};

export default memo(BackgroundChart);
