import React, { memo, useState } from 'react';
import PropTypes from 'prop-types';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import styles from './InputManualCoupon.module.css';
import { DisplayTextBody, DisplayTextSubBody } from '../../../../../components/UI_V2/Display/Text';
import Color from '../../../../../themes/colors';
import introImage from '../../../../../assets/PrivateChatPage/intro-chat-section.png';
import { InputButtonMain } from '../../../../../components/UI_V2/Input/Button';
import { InputTextFieldMain } from '../../../../../components/UI_V2/Input/TextField';

const InputManualCoupon = ({
  handleClickApplyCoupon,
  loadings,
}) => {
  const [couponCode, setCouponCode] = useState('');
  const handleChanged = (event) => {
    setCouponCode((prevValue) => event.target.value);
  };
  return (
    <div className={styles.container}>
      <div className={styles.actionText}>
        <DisplayTextBody>
          Belum ada kupon?
        </DisplayTextBody>
        <DisplayTextSubBody>
          Kamu bisa masukkan kode kupon secara manual
        </DisplayTextSubBody>
      </div>
      <div className={styles.actionButton}>
        <div className={styles.actionInput}>
          <InputTextFieldMain
            placeholder="COUPON1"
            onChange={(event) => handleChanged(event)}
            value={couponCode}
          />
        </div>
        <div className={styles.actionApply}>
          <InputButtonMain
            loadings={loadings}
            size="sm"
            wait={`coupon-add-${couponCode}`}
            handleClick={() => handleClickApplyCoupon(couponCode)}
          >
            Terapkan
          </InputButtonMain>
        </div>
      </div>
    </div>
  );
};

InputManualCoupon.propTypes = {
  handleClickApplyCoupon: PropTypes.func.isRequired,
  loadings: PropTypes.array.isRequired,
};

export default memo(InputManualCoupon);
