import React, { memo, useContext } from 'react';
import PropTypes from 'prop-types';
import styles from './Main.module.css';
import { GlobalContext } from '../../../../contexts/GlobalStateProvider';

const leftColWithSidebarStyles = {
  true: `${styles.leftCol} ${styles.leftColSidebarOpen}`,
  false: styles.leftCol,
};

const rightColWithSidebarStyles = {
  true: `${styles.rightCol} ${styles.rightColSidebarOpen}`,
  false: styles.rightCol,
};

const LayoutMain = ({ children }) => {
  const [{ isSidebarOpen }] = useContext(GlobalContext);
  return (
    <div className={styles.container}>
      <div className={leftColWithSidebarStyles[isSidebarOpen]} />
      <div className={styles.mainCol}>{children}</div>
      <div className={rightColWithSidebarStyles[isSidebarOpen]} />
    </div>
  );
};

LayoutMain.propTypes = {
  children: PropTypes.node.isRequired,
};

export default memo(LayoutMain);
