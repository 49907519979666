import React, {
  memo,
  useCallback,
  useEffect, useState,
} from 'react';
import { useParams, useHistory, useLocation } from 'react-router-dom';
import queryString from 'query-string';
import PropTypes from 'prop-types';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { useSnackbar } from 'notistack';
import styles from './ChartTractionList.module.css';
import { DisplayTextBody, DisplayTextSubHeadline } from '../../../../components/UI_V2/Display/Text';
import Color from '../../../../themes/colors';
import handleLoadings from '../../../../utilities/handleLoadings';
import handleStatusMsg from '../../../../utilities/handleStatusMsg';
import { AdministratorActions } from '../../../../actions';
import { calculateMonthday, dateBeautifyFormatter } from '../../../../utilities/dateUtil';
import { FeedbackLoadingMain } from '../../../../components/UI_V2/Feedback/Loading';
import FilterDropdown from '../../../../components/Container/FilterDropdown/FilterDropdown';
import ChartTractionRevenue from './ChartTractionRevenue/ChartTractionRevenue';

const ChartTractionList = ({
  handleSetHeaderText
}) => {
  const params = useParams();
  const history = useHistory();
  const location = useLocation();

  const { companyId } = params;

  const query = queryString.parse(location.search);

  const {
    fromDate,
    untilDate,
  } = query;

  let initialFromDate = calculateMonthday(new Date(), 14, 'subtract');

  if (fromDate) {
    initialFromDate = new Date(fromDate);
  }

  let initialUntilDate = new Date();
  if (untilDate) {
    initialUntilDate = new Date(untilDate);
  }

  const [filters, setFilters] = useState({
    filterFromUntilDate: {
      fromDate: initialFromDate,
      untilDate: initialUntilDate,
    },
  });
  const [loadings, setLoadings] = useState([]);
  const { enqueueSnackbar } = useSnackbar();
  const [currentMetricCounts, setCurrentMetricCounts] = useState({
    data: [],
  });
  const [currentTractionModels, setCurrentTractionModels] = useState({
    data: [],
  });

  const initiateMetricCountsApi = async (filtersValue) => {
    try {
      const startLoadings = handleLoadings('initiateMetricCounts', [...loadings], 'start');
      setLoadings([...startLoadings]);
      const result = await AdministratorActions.initiateMetricCounts({
        setCurrentMetricCounts,
        filters: filtersValue,
      });
    } catch (error) {
      const status = handleStatusMsg(error, 'error');

      enqueueSnackbar(status.message, {
        variant: 'error',
      });
    } finally {
      const endLoadings = handleLoadings('initiateMetricCounts', [...loadings], 'end');
      setLoadings([...endLoadings]);
    }
  };

  const initiateTractionModelsApi = async (filtersValue) => {
    try {
      const startLoadings = handleLoadings('initiateTractionModels', [...loadings], 'start');
      setLoadings([...startLoadings]);
      const result = await AdministratorActions.initiateTractionModels({
        setCurrentTractionModels,
        filters: filtersValue,
        companyId,
      });

      handleSetHeaderText({
        fromDate: filtersValue.filterFromUntilDate.fromDate,
        untilDate: filtersValue.filterFromUntilDate.untilDate,
      });
    } catch (error) {
      const status = handleStatusMsg(error, 'error');

      enqueueSnackbar(status.message, {
        variant: 'error',
      });
    } finally {
      const endLoadings = handleLoadings('initiateTractionModels', [...loadings], 'end');
      setLoadings([...endLoadings]);
    }
  };

  useEffect(() => {
    initiateMetricCountsApi(filters);
    initiateTractionModelsApi(filters);
  }, [location]);

  //   useEffect(() => {
  //     if (currentMetricCounts?.company) {
  //       handleSetCompany(currentMetricCounts.company);
  //     }
  //   }, [currentMetricCounts]);

  const onApplyFilters = useCallback((values) => {
    initiateMetricCountsApi(values);
    initiateTractionModelsApi(values);
    setFilters(values);
  }, []);

  const labels = currentMetricCounts.data.map((elem) => (
    dateBeautifyFormatter(elem.date, true, 'MonthDate')
  ));

  const metricCounts = currentMetricCounts.data.map((elem) => (
    elem.metricCounts
  ));

  const tractions = currentTractionModels.data.map((elem) => (
    elem.tractions
  ));
  return (
    <div className={styles.container}>
      <div className={styles.filter}>
        <FilterDropdown
          showFeatureTypeSection={false}
          showArchivedSection={false}
          showTeamSection={false}
          showFromUntilDateSection
          onApplyFilters={onApplyFilters}
          loadingsOnApplyFilters={loadings}
          waitOnApplyFilters="initiateMetricCounts"
          initialFromDate={filters.filterFromUntilDate.fromDate}
          initialUntilDate={filters.filterFromUntilDate.untilDate}
        />
      </div>
      <FeedbackLoadingMain
        wait="initiateMetricCounts"
        loadings={loadings}
        useSpinner
      >
        <div className={styles.chartSection}>
          <div className={styles.chartBox}>
            <ChartTractionRevenue
              labels={labels}
              metricCounts={metricCounts}
              tractionModels={tractions}
            />
          </div>
        </div>
      </FeedbackLoadingMain>
    </div>
  );
};

ChartTractionList.propTypes = {
  handleSetHeaderText: PropTypes.func.isRequired,
};

ChartTractionList.defaultProps = {};

export default memo(ChartTractionList);
