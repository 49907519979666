const limitComment = 10;
const typeCallback = {
  NEW: 'new',
  DELETE: 'delete',
  EDIT: 'edit',
};

const moduleTitleByCommentType = {
  card: 'Tugas',
  question: 'Pertanyaan',
  blast: 'Pengumuman',
  event: 'Jadwal',
  occurrence: 'Jadwal Berulang',
  doc: 'Dokumen',
  file: 'File',
};

export {
  limitComment,
  typeCallback,
  moduleTitleByCommentType,
};
