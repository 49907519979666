import React, {
  useState, useContext, useEffect, useCallback,
} from 'react';
import { useParams, useLocation } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import CheckBoxOutlinedIcon from '@mui/icons-material/CheckBoxOutlined';
import FilterAltOutlinedIcon from '@mui/icons-material/FilterAltOutlined';
import { isEmpty } from 'lodash';
import queryString from 'query-string';
import InfinityScroll from '../../components/UI/InfinityScroll/InfinityScroll';
import { GlobalContext, initialState } from '../../contexts/GlobalStateProvider';
import styles from './NotificationPage.module.css';
import Notification from '../NotificationContainer/Notification/Notification';
import Title from '../../components/Title/Title';
import handleStatusMsg from '../../utilities/handleStatusMsg';
import handleLoadings from '../../utilities/handleLoadings';
import PageLoading from '../../components/UI/Button/PageLoading/PageLoading';
import OverlayButton from '../../components/UI/Button/OverlayButton/OverlayButton';
import { NotificationActions, SearchJumpToActions } from '../../actions';
import { useNotificationSocket, useNotificationCounterTeamsSocket } from '../../hooks/NotificationHooks';
import { ApiConstants, NotificationConstants, PageConstants } from '../../constants';
import { useInfinityScrollNotificationHooks } from '../../hooks/InfinityScrollHooks';
import apiUtil from '../../utilities/apiUtil';
import GeneralSubNavBar from '../../components/GeneralSubNavBar/GeneralSubNavBar';
import GlobalActionButton from '../../components/GeneralSubNavBar/BottomNavBar/GlobalActionButton/GlobalActionButton';
import { generateRoutesAndTitle } from '../../actions/BreadCrumbActions';
import CheersButton from '../NotificationContainer/CheersButton/CheersButton';
import LinkNoDecor from '../../components/LinkNoDecor/LinkNoDecor';
import NotificationsCategoryContainer from '../NotificationContainer/NotificationsCategoryContainer/NotificationsCategoryContainer';
import { useLocalStorage } from '../../hooks/HelperHooks';
import { handleCompareDate } from '../../utilities/dateUtil';
import DateSeparator from '../CommentsSectionContainer/DateSeparator/DateSeparator';
import MarkSelection from '../NotificationContainer/MarkSelection/MarkSelection';
import FilterNotifPopUp from '../NotificationContainer/FilterNotifPopUp/FilterNotifPopUp';
import { NOTIF_ACTIVITY_API_URL } from '../../constants/ApiConstants';

const containerWithSidebarStyles = {
  true: `${styles.container} ${styles.sidebarOpen}`,
  false: styles.container,
};

function NotificationPage() {
  const [
    {
      user,
      allNotifications,
      counterTeamsNotif,
      currentCompany,
      isSidebarOpen,
    },
    dispatch,
  ] = useContext(GlobalContext);

  const [loadings, setLoadings] = useState([]);
  const [openFilterNotif, setOpenFilterNotif] = useState(false);
  const [markReadMode, setMarkReadMode] = useState(false);
  const [selectedReadNotifs, setSelectedReadNotifs] = useState([]);
  const [postSelectedReadNotifs, setPostSelectedReadNotifs] = useState([]);
  const [selectedTabNotif, setSelectedTabNotif] = useState('all');
  const [checkMode, setCheckMode] = useState(false);

  const { enqueueSnackbar } = useSnackbar();
  const params = useParams();
  const { companyId } = params;
  const location = useLocation();
  const query = queryString.parse(location.search);

  const setCheckAllMode = useCallback(() => {
    if (selectedReadNotifs.length === allNotifications.data.length) {
      setCheckMode(true);
    } else {
      setCheckMode(false);
    }
  }, [selectedReadNotifs, allNotifications]);

  const [defaultViewNotif, setDefaultViewNotif] = useLocalStorage(`notifConfig-${user?._id}-${companyId}`, {
    defaultView: 'all',
  });
  const isItemSelected = (id) => !!selectedTabNotif.includes(id);
  const handleSelectTab = useCallback((id) => {
    if (id === 'all' && isItemSelected('all')) return;
    if (isItemSelected(id)) {
      setSelectedTabNotif('all');
      // reset notif
      NotificationActions.resetAllNotif({}, dispatch);
      return;
    }
    setSelectedTabNotif(id);
    // reset checklist
    setSelectedReadNotifs([]);
    setCheckAllMode();
    // reset notif
    NotificationActions.resetAllNotif({}, dispatch);
  }, [selectedTabNotif, selectedReadNotifs, allNotifications]);

  const handleChangeDefaultViewNotif = useCallback((value) => {
    setDefaultViewNotif((prevValue) => ({
      ...prevValue,
      defaultView: value,
    }));
  }, []);

  // useNotificationCounterTeamsSocket({ companyId, userId: user?._id }, dispatch);

  const isSelectedTabIsAll = selectedTabNotif === 'all';
  const socketParams = isSelectedTabIsAll ? {
    userId: user?._id, companyId, typeNotif: 'allNotif', filter: 'all',
  } : {
    userId: user?._id, companyId, teamId: selectedTabNotif, typeNotif: 'allNotif', filter: 'all',
  };
  const eventParam = isSelectedTabIsAll ? `${companyId}-${user?._id}` : `${selectedTabNotif}-${user?._id}`;

  const { socket } = useNotificationSocket({
    ...socketParams,
  }, dispatch);

  const getMoreLists = async () => {
    const nextTotalNotif = allNotifications.total
      + NotificationConstants.limitNotif;

    socket.emit(eventParam, { limit: nextTotalNotif, filter: 'all' });
  };

  const resetPreviousLists = useCallback(() => {
    NotificationActions.setAllNotif({
      data: initialState.allNotifications.data,
    }, dispatch);
    NotificationActions.setTotalAllNotif({
      total: initialState.allNotifications.total,
    }, dispatch);
  }, []);

  const {
    lists,
    handleLoadMoreLists,
    checkIfListNeedToLoad,
  } = useInfinityScrollNotificationHooks({
    currentObjectWithKeyProperty: allNotifications,
    resetPreviousLists,
    getMoreLists,
    keyProperty: 'data',
  });

  useEffect(() => {
    if (isEmpty(allNotifications.data)) {
      const startLoadings = handleLoadings('notifPage', [...loadings], 'start');
      setLoadings([...startLoadings]);
    }
    if (defaultViewNotif.defaultView === 'team') {
      if (query.teamId && query.teamId !== null && query.teamId !== undefined) {
        handleSelectTab(query.teamId);
      }
    }
  }, []);

  useEffect(() => {
    if (postSelectedReadNotifs.length < 1) return;

    const handleApiMarkSelected = async () => {
      try {
        const startLoadings = handleLoadings('notifPage', [...loadings], 'start');
        setLoadings([...startLoadings]);

        const bodyRequest = {
          notificationIds: [...postSelectedReadNotifs],
          companyId,
        };

        const result = await apiUtil.patch(ApiConstants.URL_V1.NOTIFICATIONS(),
          bodyRequest,
          {
            params: {
              selectBy: 'selected',
              companyId,
            },
          },
          NOTIF_ACTIVITY_API_URL);
        const status = handleStatusMsg(result, 'success');

        enqueueSnackbar(status.message, {
          variant: 'success',
        });
      } catch (err) {
        const status = handleStatusMsg(err, 'error');

        enqueueSnackbar(status.message, {
          variant: 'error',
        });
      } finally {
        const endLoadings = handleLoadings('notifPage', [...loadings], 'end');
        setLoadings([...endLoadings]);
        setSelectedReadNotifs([]);
        setPostSelectedReadNotifs([]);
        setMarkReadMode(!markReadMode);
      }
    };

    handleApiMarkSelected();
  }, [postSelectedReadNotifs]);

  const handlePostMarkSelected = () => {
    if (selectedReadNotifs.length < 1) {
      setMarkReadMode(!markReadMode);
      return;
    }

    setPostSelectedReadNotifs([...selectedReadNotifs]);
  };

  // const readNotification = async (notificationId) => {
  //   try {
  //     await apiUtil.patch(
  //       ApiConstants.URL_V1.NOTIFICATION({ notificationId }),
  //       {},
  //       {
  //         params: {
  //           companyId,
  //         },
  //       },
  //       NOTIF_ACTIVITY_API_URL,
  //     );
  //   } catch (err) {
  //     const status = handleStatusMsg(err, 'error');

  //     enqueueSnackbar(status.message, {
  //       variant: 'error',
  //     });
  //   }
  // };

  const handleClickNotif = async (notificationId, typeNotification) => {
    if (markReadMode) {
      let currentNotifs = [...selectedReadNotifs];
      const filteredNotifs = selectedReadNotifs.filter((notif) => notif === notificationId);

      if (filteredNotifs.length < 1) {
        currentNotifs.push(notificationId);
      } else {
        const removedNotifs = currentNotifs.filter((notif) => notif !== notificationId);
        currentNotifs = [...removedNotifs];
      }

      if (currentNotifs.length === allNotifications.data.length) {
        setCheckMode(true);
      } else {
        setCheckMode(false);
      }

      setSelectedReadNotifs([...currentNotifs]);
    } else {
      // been handled by readNotifId on page open
      // await readNotification(notificationId);
    }
  };

  const handleClickMarkSelected = () => {
    setMarkReadMode(!markReadMode);
  };

  const handleCheckAll = () => {
    setCheckMode(!checkMode);

    if (!checkMode) {
      const notificationIds = [];

      allNotifications.data.forEach((notif) => {
        const checkId = [user._id];
        const filteredActivities = notif.activities.filter(
          (activity) => !activity.readBy.some((readBy) => checkId.includes(readBy.reader)),
        );

        if (filteredActivities.length < 1) return;

        notificationIds.push(notif._id);
      });

      setSelectedReadNotifs([...notificationIds]);
    } else {
      setSelectedReadNotifs([]);
    }
  };

  const handleOpenFilterPopUp = useCallback(() => {
    setOpenFilterNotif(true);
  }, []);

  const handleCloseFilterPopUp = useCallback(() => {
    setOpenFilterNotif(false);
  }, []);
  const pageType = PageConstants.pageType.notifications;
  const breadcrumb = generateRoutesAndTitle({ type: pageType, data: { companyId } });
  SearchJumpToActions.saveRecentPage({
    company: currentCompany,
    userId: user?._id,
    itemId: `${companyId}-notifications`,
    url: breadcrumb.url,
    routes: breadcrumb.routes,
    name: breadcrumb.title,
    type: pageType,
  });
  return (
    <>
      <Title title="Semua Notifikasi" />
      <GeneralSubNavBar
        breadcrumbRoutes={breadcrumb.routes}
        pageTitle={breadcrumb.title}
        withMiddleNavBar={false}
        bottomBarGlobalActions={(
          <>
            <LinkNoDecor to={companyId ? `/companies/${companyId}/cheers` : '/'}>
              <div className={styles.cheersButtonSection}>
                <CheersButton />
              </div>
            </LinkNoDecor>
            <GlobalActionButton
              handleClick={handleClickMarkSelected}
              text="Tandai dibaca"
              icon={<CheckBoxOutlinedIcon />}
            />
            <GlobalActionButton
              buttonMode="outlined"
              text="Filter"
              icon={<FilterAltOutlinedIcon />}
              handleClick={handleOpenFilterPopUp}
            />
            <FilterNotifPopUp
              open={openFilterNotif}
              onClose={handleCloseFilterPopUp}
              listCounterTeams={counterTeamsNotif}
              handleSelectTab={handleSelectTab}
              handleChangeDefaultViewNotif={handleChangeDefaultViewNotif}
              defaultViewNotif={defaultViewNotif}
              selectedTab={selectedTabNotif}
              type="all"
            />
          </>
        )}
        // bottomBarOtherActions={(
        //   <>
        //     <GlobalActionButton
        //       buttonMode="outlined"
        //       text="Filter"
        //       icon={<FilterAltOutlinedIcon />}
        //       handleClick={handleOpenFilterPopUp}
        //     />
        //     <FilterNotifPopUp
        //       open={openFilterNotif}
        //       onClose={handleCloseFilterPopUp}
        //       listCounterTeams={counterTeamsNotif}
        //       handleSelectTab={handleSelectTab}
        //       handleChangeDefaultViewNotif={handleChangeDefaultViewNotif}
        //       defaultViewNotif={defaultViewNotif}
        //       selectedTab={selectedTabNotif}
        //       type="all"
        //     />
        //   </>
        // )}
      />
      <PageLoading wait="notifPageFirst" loadings={loadings}>
        <div className={containerWithSidebarStyles[isSidebarOpen]}>
          <div className={styles.categoryTabSection}>
            <NotificationsCategoryContainer
              listNotifications={lists}
              listCounterTeams={counterTeamsNotif}
              handleSelectTab={handleSelectTab}
              selectedTab={selectedTabNotif}
              type="all"
            />
          </div>
          <div className={styles.bodySection}>
            {markReadMode ? (
              <div className={styles.markReadSection}>
                <MarkSelection
                  handleCancel={handleClickMarkSelected}
                  handlePublish={handlePostMarkSelected}
                  handleCheckAll={handleCheckAll}
                  checkMode={checkMode}
                />
              </div>
            ) : null}
            <div id="notification-page" className={styles.mainContainer}>
              <InfinityScroll
                scrollableTarget="notification-page"
                dataLength={lists.length || 0}
                next={() => {
                  handleLoadMoreLists();
                  setCheckAllMode();
                }}
                hasMore={checkIfListNeedToLoad()}
                emptyMessage={selectedTabNotif === 'all'
                  ? 'You have no notifications at the moment'
                  : 'No notifications on this team at the moment'}
              >
                <OverlayButton wait={lists.length > 0 ? 'notifPage' : null} loadings={loadings}>
                  {lists.map((notification, index) => {
                    const isSameDate = handleCompareDate(
                      lists,
                      notification,
                      (index - 1),
                      'updatedAt',
                    );
                    if (!isSameDate) {
                      return (
                        <>
                          <DateSeparator date={notification.updatedAt} />
                          <div className={styles.wrapperNotification}>
                            <Notification
                              notification={notification}
                              user={user}
                              clicked={handleClickNotif}
                              markReadMode={markReadMode}
                              selectedReadNotifs={selectedReadNotifs}
                              variant="fullWidth"
                            />
                          </div>
                        </>
                      );
                    }
                    return (
                      <div className={styles.wrapperNotification}>
                        <Notification
                          notification={notification}
                          user={user}
                          clicked={handleClickNotif}
                          markReadMode={markReadMode}
                          selectedReadNotifs={selectedReadNotifs}
                          variant="fullWidth"
                        />
                      </div>
                    );
                  })}
                </OverlayButton>
              </InfinityScroll>
            </div>
          </div>
        </div>
      </PageLoading>
    </>
  );
}

export default NotificationPage;
