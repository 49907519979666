import React, {
  memo, useCallback, useContext, useState,
} from 'react';
import PropTypes from 'prop-types';
import DownloadIcon from '@mui/icons-material/Download';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { useMediaQuery } from '@mui/material';
import styles from './TeamItem.module.css';
import Color from '../../../../../themes/colors';
import { InputButtonMain } from '../../../../UI_V2/Input/Button';
import { GlobalContext } from '../../../../../contexts/GlobalStateProvider';
import { DisplayTextBody } from '../../../../UI_V2/Display/Text';
import { limitChar } from '../../../../../utilities/stringUtil';
import TeamCardMenu from '../../../../../pages/DashboardPage/FetchTeamsCards/Card/TeamCardMenu/TeamCardMenu';
import LinkNoDecor from '../../../../LinkNoDecor/LinkNoDecor';

const containerWithSelectedStyles = {
  teamOpen: `${styles.container} ${styles.containerOpen}`,
  teamSelected: `${styles.container} ${styles.containerSelected}`,
  team: styles.container,
};

const TeamItem = ({
  team,
  handleSelectAndShowTeamMenu,
  handleCancelShowTeamMenu,
  isTeamSelected,
  isTeamOpen,
  onOpenTeam,
  teamsColor,
}) => {
  const [showMenuIcon, setShowMenuIcon] = useState(false);
  const handleShowMenuIcon = useCallback(() => {
    setShowMenuIcon(true);
  }, []);
  const handleHideMenuIcon = useCallback(() => {
    setShowMenuIcon(false);
  }, []);

  const handleClickMoreButton = () => {
    handleSelectAndShowTeamMenu(team);
  };

  const handleCloseShowButton = () => {
    handleHideMenuIcon();
    if (!isTeamSelected) {
      handleCancelShowTeamMenu();
    }
  };
  const isMobile = useMediaQuery('(max-width:720px)');

  let containerStylesCode = 'team';

  if (isTeamSelected) {
    containerStylesCode = 'teamSelected';
  }

  if (isTeamOpen) {
    containerStylesCode = 'teamOpen';
  }

  const teamColor = teamsColor.find((elem) => elem.teamId === team._id);
  const color = teamColor?.color || '#7a7a7a';

  return (
    <div
      className={containerWithSelectedStyles[containerStylesCode]}
      onMouseEnter={handleShowMenuIcon}
      onMouseLeave={handleCloseShowButton}
    >
      <LinkNoDecor
        to={`/companies/${team.company}/teams/${team._id}`}
      >
        <div className={styles.colorAndTitle} onClick={onOpenTeam}>
          <div className={styles.color}>
            <div
              className={styles.color__icon}
              style={{
                backgroundColor: color,
              }}
            />
          </div>
          <div className={styles.title}>
            <DisplayTextBody mode="14">
              {limitChar(team.name, 50)}
            </DisplayTextBody>
          </div>
        </div>
      </LinkNoDecor>
      {showMenuIcon && (
        <div className={styles.menu} onClick={handleClickMoreButton}>
          <MoreVertIcon />
        </div>
      )}
    </div>
  );
};

TeamItem.propTypes = {
  team: PropTypes.object,
  handleCancelShowTeamMenu: PropTypes.func,
  handleSelectAndShowTeamMenu: PropTypes.func,
  isTeamSelected: PropTypes.bool,
  isTeamOpen: PropTypes.bool,
  onOpenTeam: PropTypes.func,
  teamsColor: PropTypes.array,
};

TeamItem.defaultProps = {
  team: {},
  handleSelectAndShowTeamMenu: () => null,
  handleCancelShowTeamMenu: () => null,
  isTeamSelected: false,
  isTeamOpen: false,
  onOpenTeam: () => null,
  teamsColor: [],
};

export default memo(TeamItem);
