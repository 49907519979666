import React, { memo, useState } from 'react';
import PropTypes from 'prop-types';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import styles from './CompanyListSkeleton.module.css';
import { DisplayTextBody } from '../../../../../components/UI_V2/Display/Text';
import Color from '../../../../../themes/colors';

const CompanyListSkeleton = () => (
  <div className={styles.container} />
);

export default memo(CompanyListSkeleton);
