import React, {
  memo,
  useCallback,
  useContext, useEffect, useState,
} from 'react';
import PropTypes from 'prop-types';
import AddIcon from '@mui/icons-material/Add';
import GroupsOutlinedIcon from '@mui/icons-material/GroupsOutlined';
import MapsHomeWorkOutlinedIcon from '@mui/icons-material/MapsHomeWorkOutlined';
import AssignmentOutlinedIcon from '@mui/icons-material/AssignmentOutlined';
import SortIcon from '@mui/icons-material/Sort';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { useMediaQuery } from '@mui/material';
import styles from './TeamSection.module.css';
import Color from '../../../themes/colors';
import { InputButtonMain } from '../../UI_V2/Input/Button';
import SidebarItem from '../SidebarItem/SidebarItem';
import ListTeams from './ListTeams/ListTeams';
import ModalCreateTeam from '../../../pages/DashboardPage/EmptyTeamsSectionForAdmins/ModalCreateTeam/ModalCreateTeam';
import { GlobalContext } from '../../../contexts/GlobalStateProvider';
import { actionTypes } from '../../../reducers/reducer';
import { hasPermission } from '../../../actions/RoleActions';
import { roles } from '../../../constants/RoleConstants';
import { TeamConstants } from '../../../constants';
import { useSearch } from '../../../hooks/HelperHooks';
import { InputSearchGeneral } from '../../UI_V2/Input/Search';
import SortButton from '../../SortButton/SortButton';
import { setSortedTeams } from '../../../actions/TeamActions';
import { getStoreCollapsibleTeamSidebar, setStoreCollapsibleTeamSidebar } from '../../../utilities/localStorage';

const TeamSection = ({
  params,
  handleShowEditTeamMenu,
  handleCloseEditTeamMenuAndAll,
  teamType,
}) => {
  const [{
    currentRoleUser,
    teams,
    sortedTeams,
    user,
  }, dispatch] = useContext(GlobalContext);
  const isMobile = useMediaQuery('(max-width:720px)');
  const initialCollapsibleTeamSidebar = getStoreCollapsibleTeamSidebar({
    userId: user?._id,
    companyId: params?.companyId,
  });

  const [showListHq, setShowListHq] = useState(initialCollapsibleTeamSidebar?.hq !== undefined
    ? initialCollapsibleTeamSidebar?.hq : true);
  const [showListTeam, setShowListTeam] = useState(initialCollapsibleTeamSidebar?.team !== undefined
    ? initialCollapsibleTeamSidebar?.team : true);
  const [showListProject, setShowListProject] = useState(
    initialCollapsibleTeamSidebar?.project !== undefined
      ? initialCollapsibleTeamSidebar?.project : true,
  );

  useEffect(() => {
    setShowListHq(initialCollapsibleTeamSidebar?.hq !== undefined
      ? initialCollapsibleTeamSidebar?.hq : true);
    setShowListTeam(initialCollapsibleTeamSidebar?.team !== undefined
      ? initialCollapsibleTeamSidebar?.team : true);
    setShowListProject(initialCollapsibleTeamSidebar?.project !== undefined
      ? initialCollapsibleTeamSidebar?.project : true);
  }, [JSON.stringify(initialCollapsibleTeamSidebar)]);

  const {
    searchResult,
    keyword,
    keywordArray,
    setKeyword,
    isTyping,
    onChange,
    resetSearch,
  } = useSearch({ originalList: sortedTeams });

  const isAllTeamsEmpty = teams.length < 1;
  const listHqs = teams.filter((team) => team.type === 'hq');
  const isHqsEmpty = listHqs.length < 1;
  const listTeams = teams.filter((team) => team.type === 'team');
  const isTeamsEmpty = listTeams.length < 1;
  const listProjects = teams.filter((team) => team.type === 'project');
  const isProjectsEmpty = listProjects.length < 1;

  const handleShowListTeams = (value) => {
    switch (value) {
      case 'hq':
        setShowListHq(true);
        break;
      case 'team':
        setShowListTeam(true);
        break;
      case 'project':
        setShowListProject(true);
        break;
      default:
        break;
    }

    setStoreCollapsibleTeamSidebar({
      userId: user?._id,
      companyId: params?.companyId,
      collapsibleTeamSidebar: {
        ...initialCollapsibleTeamSidebar,
        [value]: true,
      },
    });
  };
  const handleHideListTeams = (value) => {
    switch (value) {
      case 'hq':
        setShowListHq(false);
        break;
      case 'team':
        setShowListTeam(false);
        break;
      case 'project':
        setShowListProject(false);
        break;
      default:
        break;
    }
    setStoreCollapsibleTeamSidebar({
      userId: user?._id,
      companyId: params?.companyId,
      collapsibleTeamSidebar: {
        ...initialCollapsibleTeamSidebar,
        [value]: false,
      },
    });
  };

  const [isCreate, setIsCreate] = useState(false);

  const openCreateTeam = () => {
    setIsCreate(true);
  };

  const handleCancel = () => {
    setIsCreate(false);
  };

  const handleShowTeamMenuAndSelectEditTeam = (team) => {
    dispatch({
      type: actionTypes.SET_SELECTED_EDIT_TEAM,
      selectedEditTeam: team,
    });
    dispatch({
      type: actionTypes.SET_SELECTED_EDIT_TEAM_FAVORITE,
      selectedEditTeamFavorite: {},
    });
    handleShowEditTeamMenu();
  };

  const handleCloseTeamMenu = () => {
    dispatch({
      type: actionTypes.SET_SELECTED_EDIT_TEAM,
      selectedEditTeam: {},
    });
    dispatch({
      type: actionTypes.SET_SELECTED_EDIT_TEAM_FAVORITE,
      selectedEditTeamFavorite: {},
    });
    handleCloseEditTeamMenuAndAll('editTeamMenu');
  };

  const isUserManagerOrAbove = hasPermission(currentRoleUser, roles.managerOrAbove);

  const icon = {
    hq: <MapsHomeWorkOutlinedIcon />,
    team: <GroupsOutlinedIcon />,
    project: <AssignmentOutlinedIcon />,
  };

  const handleChangeSortedTeams = (newList) => {
    setSortedTeams({ sortedTeams: newList }, dispatch);
  };

  useEffect(() => {
    handleChangeSortedTeams(teams);
  }, [JSON.stringify(teams)]);

  return (
    <div className={styles.container}>
      <div className={styles.searchSortCreate}>
        {!isAllTeamsEmpty && (
        <>
          <div className={styles.search}>
            <InputSearchGeneral
              onChange={onChange}
              value={keyword}
              placeholder={`Cari ${TeamConstants.teamType[teamType]}`}
              onClickCloseIcon={resetSearch}
              size="sm"
              autoFocus={false}
            />
          </div>
          <div className={styles.sortAndCreate}>
            <div className={styles.actionSortCreate}>
              <SortButton
                originalList={teams}
                handleChangeOriginalListToNewList={handleChangeSortedTeams}
                companyId={params?.companyId}
                userId={user?._id}
              />
            </div>
            <div className={styles.actionSortCreate} onClick={openCreateTeam}>
              <AddIcon />
            </div>
          </div>
        </>
        )}
      </div>
      {!isHqsEmpty && (
      <div className={styles.teamSection}>
        <SidebarItem
          text={TeamConstants.teamType.hq}
          isCollapsed={showListHq}
          enableCollapsible
          enableIconLeft
          iconLeft={icon.hq}
          // enableIconRightGeneral={!!isUserManagerOrAbove}
          // iconRightGeneral={<AddIcon />}
          onClickCollapsible={showListHq ? () => handleHideListTeams('hq') : () => handleShowListTeams('hq')}
        />
        {showListHq && (
        <ListTeams
          handleSelectAndShowTeamMenu={(team) => handleShowTeamMenuAndSelectEditTeam(team)}
          handleCancelShowTeamMenu={handleCloseTeamMenu}
          params={params}
          teamType="hq"
          listTeams={searchResult}
        />
        )}
      </div>
      )}
      {!isTeamsEmpty && (
      <div className={styles.teamSection}>
        <SidebarItem
          text={TeamConstants.teamType.team}
          isCollapsed={showListTeam}
          enableCollapsible
          enableIconLeft
          iconLeft={icon.team}
          // enableIconRightGeneral={!!isUserManagerOrAbove}
          // iconRightGeneral={<AddIcon />}
          onClickCollapsible={showListTeam ? () => handleHideListTeams('team') : () => handleShowListTeams('team')}
        />
        {showListTeam && (
        <ListTeams
          handleSelectAndShowTeamMenu={(team) => handleShowTeamMenuAndSelectEditTeam(team)}
          handleCancelShowTeamMenu={handleCloseTeamMenu}
          params={params}
          teamType="team"
          listTeams={searchResult}
        />
        )}
      </div>
      )}
      {!isProjectsEmpty && (
      <div className={styles.teamSection}>
        <SidebarItem
          text={TeamConstants.teamType.project}
          isCollapsed={showListProject}
          enableCollapsible
          enableIconLeft
          iconLeft={icon.project}
          // enableIconRightGeneral={!!isUserManagerOrAbove}
          // iconRightGeneral={<AddIcon />}
          onClickCollapsible={showListProject ? () => handleHideListTeams('project') : () => handleShowListTeams('project')}
        />
        {showListProject && (
        <ListTeams
          handleSelectAndShowTeamMenu={(team) => handleShowTeamMenuAndSelectEditTeam(team)}
          handleCancelShowTeamMenu={handleCloseTeamMenu}
          params={params}
          teamType="project"
          listTeams={searchResult}
        />
        )}
      </div>
      )}

      {isCreate && (
      <ModalCreateTeam
        open={isCreate}
        onClose={handleCancel}
        initialType={teamType}
        params={params}
      />
      )}

    </div>
  );
};

TeamSection.propTypes = {
  params: PropTypes.object,
  handleShowEditTeamMenu: PropTypes.func,
  handleCloseEditTeamMenuAndAll: PropTypes.func,
  teamType: PropTypes.oneOf(['team', 'hq', 'project']),
};

TeamSection.defaultProps = {
  params: { companyId: null, teamId: null },
  handleShowEditTeamMenu: () => null,
  handleCloseEditTeamMenuAndAll: () => null,
  teamType: 'team',
};

export default memo(TeamSection);
