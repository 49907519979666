import React, {
  memo, useCallback, useEffect, useState,
} from 'react';
import PropTypes from 'prop-types';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import styles from './MenuSelectPeriod.module.css';
import Color from '../../../../../themes/colors';
import { DisplayTextBody } from '../../../../../components/UI_V2/Display/Text';
import SelectPeriodRecapMonthWeekly from './SelectPeriodRecapMonthWeekly/SelectPeriodRecapMonthWeekly';
import { getMonthNumber, getYearNumber } from '../../../../../utilities/dateUtil';
import { modifyInitialDateFilterReport } from '../../../../../actions/ReportActions';
import SelectPeriodRealtime from './SelectPeriodRealtime/SelectPeriodRealtime';
import { titleRealtimePeriod } from '../../../../../constants/ReportConstants';
import { getBackwardMonthsExceptNov23AndBelow } from '../../../../../actions/ReportRecapActions';

const MenuSelectPeriod = ({
  recapMonthWeeklyProps,
  realtimeProps,
  onHide,
  handleSetVariantUrl,
  variantUrl,
  handleSetIndexMonthYear,
}) => {
// REALTIME PART
  const {
    fromDate,
    untilDate,
    selectedQuickDate,
    handleSetRealtimeDate,
  } = realtimeProps;

  const handleSetCurrentPeriodRealtime = useCallback((selectedQuickDateValue) => {
    const [
      currentFromDate,
      currentUntilDate,
    ] = modifyInitialDateFilterReport(selectedQuickDateValue);
    handleSetRealtimeDate(currentFromDate, currentUntilDate, selectedQuickDateValue);
    // handleSetTextButton(titleRealtimePeriod[selectedQuickDateValue]);
    handleSetVariantUrl('realtime');
    onHide();
  }, []);

  // RECAP MONTH WEEKLY PART
  const {
    month,
    year,
    handleSetMonth,
    handleSetYear,
  } = recapMonthWeeklyProps;

  const currentMonth = getMonthNumber(new Date());
  const currentYear = getYearNumber(new Date());

  const listMonthsYear = getBackwardMonthsExceptNov23AndBelow(currentMonth, currentYear);

  // const [listMonthsYear, setListMonthsYear] = useState(initialListMonthsYear);
  // const [selectedMonthYear, setSelectedMonthYear] = useState();

  // useEffect(() => {
  //   setSelectedMonthYear(`${getMonthName(month)} ${year}`);
  // }, [month, year]);

  const handleSetCurrentMonthYear = useCallback((monthNumber, yearNumber, indexMonthYear) => {
    handleSetMonth(monthNumber);
    handleSetYear(yearNumber);
    // handleSetTextButton(`${getMonthName(monthNumber)} ${yearNumber}`);
    handleSetIndexMonthYear(indexMonthYear);
    handleSetVariantUrl('recapMonthWeekly');
    onHide();
  }, []);

  const isVariantRealtime = variantUrl === 'realtime';
  const isVariantRecapMonthWeekly = variantUrl === 'recapMonthWeekly';

  return (
    <div className={styles.container}>
      <div className={styles.realtimeSection}>
        <div className={styles.title}>
          <DisplayTextBody color={Color.gray4}>
            Data  Realtime
          </DisplayTextBody>
        </div>
        <div className={styles.list}>
          <SelectPeriodRealtime
            selectedPeriodRealtime={selectedQuickDate}
            onClickPeriodRealtime={handleSetCurrentPeriodRealtime}
            hideChecked={isVariantRecapMonthWeekly}
          />
        </div>
      </div>
      <div className={styles.recapMonthWeeklySection}>
        <div className={styles.title}>
          <DisplayTextBody color={Color.gray4}>
            Data Rekapitulasi
          </DisplayTextBody>
        </div>
        <div className={styles.list}>
          <SelectPeriodRecapMonthWeekly
            listMonthsYear={listMonthsYear}
            selectedMonth={month}
            selectedYear={year}
            onClickMonthYear={handleSetCurrentMonthYear}
            hideChecked={isVariantRealtime}
          />
        </div>
      </div>
    </div>
  );
};

MenuSelectPeriod.propTypes = {
  recapMonthWeeklyProps: PropTypes.object.isRequired,
  realtimeProps: PropTypes.object.isRequired,
  onHide: PropTypes.func.isRequired,
  handleSetVariantUrl: PropTypes.func.isRequired,
  variantUrl: PropTypes.string.isRequired,
  handleSetIndexMonthYear: PropTypes.func.isRequired,
};

MenuSelectPeriod.defaultProps = {};

export default memo(MenuSelectPeriod);
