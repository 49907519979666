import React, {
  useState, useContext, useEffect,
} from 'react';
import { useHistory, useParams } from 'react-router-dom';
import CloseIcon from '@mui/icons-material/Close';
import { useSnackbar } from 'notistack';
import PropTypes from 'prop-types';
import DeleteIcon from '@mui/icons-material/Delete';
import { GlobalContext } from '../../../../contexts/GlobalStateProvider';
import BoxPopOver from '../../../../components/UI/BoxPopOver/BoxPopOver';
import SeparatorLine from '../../../../components/UI/SeparatorLine/SeparatorLine';
import { InputButtonMain } from '../../../../components/UI_V2/Input/Button';
import styles from './RemoveMemberButton.module.css';
import handleStatusMsg from '../../../../utilities/handleStatusMsg';
import handleLoadings from '../../../../utilities/handleLoadings';
import apiUtil from '../../../../utilities/apiUtil';
import { ApiConstants } from '../../../../constants';
import { CompanyActions, UserActions } from '../../../../actions';

const RemoveMemberButton = ({ member }) => {
  const [{ user, currentCompany }, dispatch] = useContext(GlobalContext);
  const { enqueueSnackbar } = useSnackbar();
  const [loadings, setLoadings] = useState([]);
  const [isRemove, setIsRemove] = useState(false);
  const [sendMemberRemoved, setSendMemberRemoved] = useState();
  const history = useHistory();
  const params = useParams();

  const { companyId } = params;
  const memberId = member?._id;

  const handleCancel = () => {
    setIsRemove(false);
  };

  useEffect(() => {
    if (sendMemberRemoved === undefined) {
      return;
    }
    const startLoadings = handleLoadings(`deleteMember-${memberId}`, [...loadings], 'start');
    setLoadings([...startLoadings]);
    const unsetDefaultCompanyApi = async () => {
      try {
        const userId = user._id;
        const data = { $unset: { defaultCompany: '' } };
        const result = await apiUtil.patch(ApiConstants.URL_V1.USER({ userId }), data);
        UserActions.saveUserAndToken(
          { user: result?.data?.user, token: result?.data?.token }, dispatch,
        );
      } catch (err) {
        const status = handleStatusMsg(err, 'error');

        enqueueSnackbar(status.message, {
          variant: 'error',
        });
      }
    };

    const postMemberRemoved = async () => {
      try {
        const result = await CompanyActions.deleteCompanyMemberAndUpdateCompanies(
          { companyId, memberId, userId: user?._id },
          dispatch,
        );

        const status = handleStatusMsg(result, 'success');

        enqueueSnackbar(status.message, {
          variant: 'success',
        });

        if (member._id === user._id) {
          history.push('/');
        } else {
          handleCancel();
        }
      } catch (err) {
        const status = handleStatusMsg(err, 'error');

        enqueueSnackbar(status.message, {
          variant: 'error',
        });
      } finally {
        const endLoadings = handleLoadings(`deleteMember-${memberId}`, [...loadings], 'end');
        setLoadings([...endLoadings]);
      }
    };
    if (companyId === user?.defaultCompany?._id) {
      unsetDefaultCompanyApi();
    }
    postMemberRemoved();
  }, [sendMemberRemoved]);

  const openRemoveMember = () => {
    setIsRemove(true);
  };

  const handleClicked = () => {
    setSendMemberRemoved(member._id);
  };

  return (
    <>
      {isRemove
        ? (
          <BoxPopOver>
            <div className={styles.headerSection}>
              <h1>
                Hapus Anggota?
              </h1>
              <CloseIcon onClick={handleCancel} />
            </div>
            <SeparatorLine />
            <div className={styles.bodySection}>
              {/* <p>This member will no longer have access to this company.</p> */}
              <p>Anggota ini ga akan punya akses lagi ke perusahaan ini.</p>
            </div>
            <div className={styles.actionSection}>
              <InputButtonMain
                handleClick={handleClicked}
                variant="danger"
                wide="block"
                wait={`deleteMember-${member._id}`}
                loadings={loadings}
                icon={<DeleteIcon />}
              >
                Hapus
              </InputButtonMain>
            </div>
          </BoxPopOver>
        )
        : null}
      <InputButtonMain
        variant="danger"
        iconPosition="end"
        border="rounded"
        handleClick={openRemoveMember}
        icon={<DeleteIcon />}
      >
        Hapus
      </InputButtonMain>
    </>
  );
};

RemoveMemberButton.propTypes = {
  member: PropTypes.object.isRequired,
};

export default RemoveMemberButton;
