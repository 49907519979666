import React, {
  useState, useContext, useEffect, useCallback,
} from 'react';
import { useHistory, useParams, useLocation } from 'react-router-dom';
import AddIcon from '@mui/icons-material/Add';
import ArchiveOutlinedIcon from '@mui/icons-material/ArchiveOutlined';
import FilterAltOutlinedIcon from '@mui/icons-material/FilterAltOutlined';
import VerifiedUserIcon from '@mui/icons-material/VerifiedUser';
import { useSnackbar } from 'notistack';
import MainLayout from '../../components/Layout/MainLayout/MainLayout';
import { GlobalContext, initialState } from '../../contexts/GlobalStateProvider';
import { actionTypes } from '../../reducers/reducer';
import Paper from '../../components/UI/Paper/Paper';
import styles from './BlastPage.module.css';
import Post from './Post/Post';
import Title from '../../components/Title/Title';
import handleLoadings from '../../utilities/handleLoadings';
import PageLoading from '../../components/UI/Button/PageLoading/PageLoading';
import handleStatusMsg from '../../utilities/handleStatusMsg';
import {
  BlastActions, SearchJumpToActions,
  PostActions, TeamActions,
} from '../../actions';
import InfinityScroll from '../../components/UI/InfinityScroll/InfinityScroll';
import { useBlastSocket } from '../../hooks/BlastHooks';
import { useInfinityScrollHooks } from '../../hooks/InfinityScrollHooks';
import { BlastConstants, PageConstants } from '../../constants';
import GeneralSubNavBar from '../../components/GeneralSubNavBar/GeneralSubNavBar';
import GlobalActionButton from '../../components/GeneralSubNavBar/BottomNavBar/GlobalActionButton/GlobalActionButton';
import { generateRoutesAndTitle } from '../../actions/BreadCrumbActions';
import LinkNoDecor from '../../components/LinkNoDecor/LinkNoDecor';
import { SurfacePaperMain } from '../../components/UI_V2/Surface/Paper';
import { LayoutMain } from '../../components/UI_V2/Layout';
import apiUtil from '../../utilities/apiUtil';
import { FeedbackDialogAlert } from '../../components/UI_V2/Feedback/Dialog';
import { FeedbackLoadingMain } from '../../components/UI_V2/Feedback/Loading';
import BlastPageSkeleton from './Skeleton/Skeleton';
import { checkIfUserHaveAccessToDataV3 } from '../../actions/UserActions';
import { useDelayShowHideHandler } from '../../hooks/HelperHooks';
import MyRoleUser from '../../components/Container/MyRoleUser/MyRoleUser';
import { roleTypeRef, typeRef } from '../../constants/RoleConstants';
import { useInitiateRoleUser } from '../../hooks/RoleHooks';
import { useInitiateTeamBelowRole } from '../../hooks/TeamHooks';

function BlastPage() {
  const [{
    user, previousBlast, currentBlast,
    currentTeam, currentCompany, currentRoleUser,
  }, dispatch] = useContext(GlobalContext);
  const [loadings, setLoadings] = useState([]);
  const [archivedPostId, setArchivePostId] = useState();
  const [showAlertArchive, setShowAlertArchive] = useState(false);
  // const [
  //   showModalRoleUser,
  //   handleShowModalRoleUser,
  //   handleHideModalRoleUser,
  // ] = useDelayShowHideHandler();
  const { enqueueSnackbar } = useSnackbar();

  // initiate members below role
  // useInitiateTeamBelowRole({});

  const history = useHistory();
  const location = useLocation();
  const params = useParams();
  const { companyId, teamId, blastId } = params;

  useInitiateRoleUser({
    payload: {
      teamId,
      featureType: typeRef.blast,
      featureId: blastId,
    },
  });

  useBlastSocket({ blastId: params?.blastId, userId: user?._id }, dispatch);

  const getMoreLists = async () => {
    const result = await BlastActions.loadMoreBlast({
      blastId,
      companyId,
      currentBlast,
      teamId,
    }, dispatch);

    return result;
  };

  const resetPreviousLists = useCallback(() => {
    BlastActions.setPreviousBlast({
      previousBlast: initialState.previousBlast,
    }, dispatch);
  }, []);

  const {
    lists,
    handleLoadMoreLists,
    checkIfListNeedToLoad,
    setCheckLoadMore,
    isListsLengthAtTheLimitOrAbove,
  } = useInfinityScrollHooks({
    currentObjectWithKeyProperty: currentBlast,
    previousLists: previousBlast,
    resetPreviousLists,
    getMoreLists,
    keyProperty: 'posts',
    limitList: BlastConstants.limitBlast,
  });

  useEffect(() => {
    if (user._id === undefined) {
      history.push(`/check-login?previousPath=${location.pathname}`);
      return;
    }
    const startLoadings = handleLoadings('blastPage', [...loadings], 'start');
    setLoadings([...startLoadings]);

    const fetchApiBlastDetail = async () => {
      try {
        const result = await BlastActions.initiateBlast({ blastId, companyId, teamId }, dispatch);
        await TeamActions.initiateTeam({
          teamId,
          companyId,
          currentTeam,
        }, dispatch);
        if (isListsLengthAtTheLimitOrAbove(result?.posts)) {
          setCheckLoadMore(true);
        }
      } catch (err) {
        const status = handleStatusMsg(err, 'error');

        dispatch({
          type: actionTypes.SET_ERROR_RESPONSE,
          errorResponse: { message: status.message },
        });

        history.push(`/errors?previousPath=${location.pathname}`);
      } finally {
        const endLoadings = handleLoadings('blastPage', [...loadings], 'end');
        setLoadings([...endLoadings]);
      }
    };

    fetchApiBlastDetail();

    // eslint-disable-next-line consistent-return
    return () => BlastActions.resetBlast({}, dispatch);
  }, [location]);

  const handleCreateBlast = () => {
    history.push(`${location.pathname}/create`);
  };

  const handleClickEdit = (id) => {
    history.push(`/companies/${companyId}/teams/${teamId}/posts/${id}/edit`);
  };

  const handleLoadingChangeCompleteStatus = (status) => {
    if (status) {
      const startLoadings = handleLoadings('completePost', [...loadings], 'start');
      setLoadings([...startLoadings]);
    } else {
      const endLoadings = handleLoadings('completePost', [...loadings], 'end');
      setLoadings([...endLoadings]);
    }
  };

  const handleClickComplete = async (postId, isPostComplete) => {
    handleLoadingChangeCompleteStatus(true);
    try {
      const result = await PostActions.toggleCompletePost({
        isComplete: !isPostComplete,
        postId,
        companyId,
        teamId,
      });

      const status = handleStatusMsg(result, 'success');

      enqueueSnackbar(status.message, {
        variant: 'success',
      });
    } catch (error) {
      const status = handleStatusMsg(error, 'error');

      if (status?.message) {
        enqueueSnackbar(status.message, {
          variant: 'error',
        });
      }
    } finally {
      handleLoadingChangeCompleteStatus(false);
    }
  };

  const handleShowAlertArchive = () => {
    setShowAlertArchive(true);
  };

  const handleHideAlertArchive = () => {
    setShowAlertArchive(false);
  };

  const handleClickArchive = (postId) => {
    setArchivePostId(postId);
    handleShowAlertArchive();
  };

  const handlePostArchive = async () => {
    try {
      const postId = archivedPostId;
      const startLoadings = handleLoadings('blastPostMenu', [...loadings], 'start');
      setLoadings([...startLoadings]);

      const result = await apiUtil.patch(`/api/v1/posts/${postId}/archived`, {}, {
        params: {
          companyId,
          teamId,
        },
      });

      const status = handleStatusMsg(result, 'success');

      enqueueSnackbar(status.message, {
        variant: 'success',
      });

      handleHideAlertArchive();
    } catch (err) {
      const status = handleStatusMsg(err, 'error');

      enqueueSnackbar(status.message, {
        variant: 'error',
      });
    } finally {
      const endLoadings = handleLoadings('blastPostMenu', [...loadings], 'end');
      setLoadings([...endLoadings]);
    }
  };

  const pageType = PageConstants.pageType.blasts;
  const breadcrumb = generateRoutesAndTitle({ type: pageType, data: { companyId, currentTeam } });
  SearchJumpToActions.saveRecentPage({
    company: currentCompany,
    team: currentTeam,
    userId: user?._id,
    itemId: blastId,
    url: breadcrumb.url,
    routes: breadcrumb.routes,
    name: breadcrumb.title,
    type: pageType,
  });

  return (
    <>
      <Title title="List Pengumuman" />
      <GeneralSubNavBar
        breadcrumbRoutes={breadcrumb.routes}
        pageTitle={breadcrumb.title}
        bottomBarOverviewOptionId="blasts"
        bottomBarGlobalActions={(
          <>
            <GlobalActionButton
              handleClick={handleCreateBlast}
              buttonMode="outlined"
              text="Buat Pengumuman"
              icon={<AddIcon />}
            />
            {/* {
            currentTeam?._id === teamId
            && (
            <GlobalActionButton
              handleClick={handleShowModalRoleUser}
              text="My Role Here"
              icon={<VerifiedUserIcon />}
            />
          )
} */}
          </>
        )}
        // bottomBarOtherActions={(
        //   <>
        // eslint-disable-next-line max-len
        //     <GlobalActionButton buttonMode="outlined" text="Filter" icon={<FilterAltOutlinedIcon />} />
        // eslint-disable-next-line max-len
        //     <GlobalActionButton buttonMode="outlined" text="Archived Items" icon={<ArchiveOutlinedIcon />} />
        //   </>
        // )}
      />
      <LayoutMain>
        <SurfacePaperMain>
          <div className={styles.container}>
            <FeedbackLoadingMain
              wait="blastPage"
              loadings={loadings}
              loadingComponent={<BlastPageSkeleton />}
            >
              <InfinityScroll
                dataLength={lists?.length || 0}
                hasMore={checkIfListNeedToLoad()}
                next={handleLoadMoreLists}
                emptyMessage="Belum ada Pengumuman disini..."
              >
                {lists !== undefined
                  ? (
                    <>
                      {lists.map((post) => {
                        if (!checkIfUserHaveAccessToDataV3(post, user, currentRoleUser)) {
                          return null;
                        }
                        return (
                          <div className={styles.postItem}>
                            <Post
                              key={post?._id}
                              waitComplete="completePost"
                              loadings={loadings}
                              handleClickEdit={() => handleClickEdit(post?._id)}
                              handleClickArchive={() => handleClickArchive(post?._id)}
                              handleClickComplete={
                              () => handleClickComplete(post?._id, post?.isComplete)
                            }
                              post={post}
                              url={`/companies/${companyId}/teams/${teamId}/posts/${post._id}`}
                            />
                          </div>
                        );
                      })}
                    </>
                  ) : null}
              </InfinityScroll>
              <FeedbackDialogAlert
                open={showAlertArchive}
                onClose={handleHideAlertArchive}
                handleConfirmNo={handleHideAlertArchive}
                handleConfirmYes={handlePostArchive}
                waitYes="blastPostMenu"
                loadings={loadings}
                labelYes="Arsipkan"
                labelNo="Batal"
                title="Arsipkan Pengumuman Ini?"
                content="Kamu perlu menghubungi CS kami untuk mengembalikan Pengumuman ini."
              />
            </FeedbackLoadingMain>
          </div>
        </SurfacePaperMain>
        {/* <MyRoleUser
          open={showModalRoleUser}
          onClose={handleHideModalRoleUser}
          featureTitle={currentTeam?.name}
          featureType={typeRef.blast}
          featureId={blastId}
          roleType={roleTypeRef.team}
        /> */}
      </LayoutMain>
    </>
  );
}

export default BlastPage;
