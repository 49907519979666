import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { DisplayBadgeCounter } from '../../../components/UI_V2/Display/Badge';
import styles from './Event.module.css';
import ImageMember from '../../../components/UI/ImageMember/ImageMember';
import PrivateIcon from '../../../components/UI/PrivateIcon/PrivateIcon';
import Color from '../../../themes/colors';

const borderVariantStyles = {
  square: styles.borderSquare,
  squareSemiRoundedTopLeft: styles.boderSquareSemiRoundedTopLeft,
  squareSemiRoundedTopRight: styles.boderSquareSemiRoundedTopRight,
  squareSemiRoundedBottomRight: styles.boderSquareSemiRoundedBottomRight,
  squareSemiRoundedBottomLeft: styles.boderSquareSemiRoundedBottomLeft,
  semiRounded: styles.borderSemiRounded,
};

const Event = ({ event, clicked, borderVariant }) => {
  const [date, setDate] = useState();
  const [time, setTime] = useState({
    start: undefined,
    end: undefined,
  });

  const formatTime = (inputDate) => {
    const minute = inputDate.getMinutes();
    let hour = inputDate.getHours();
    const ampm = hour >= 12 ? 'PM' : 'AM';
    // eslint-disable-next-line no-mixed-operators
    hour = ((hour + 11) % 12 + 1);
    const prefixHour = hour >= 10 ? '' : '0';
    const prefixMinute = minute >= 10 ? '' : '0';

    const formattedTime = `${prefixHour + hour}:${prefixMinute}${minute} ${ampm}`;
    return formattedTime;
  };

  useEffect(() => {
    // bikin tanggal utama
    let dateString;

    dateString = new Date(event.startDate);
    dateString = dateString.toString();
    dateString = dateString.split(' ').slice(1, 4).join(' ');
    setDate(dateString);

    // bikin start time
    const initialStartDate = new Date(event.startDate);
    const startTime = formatTime(initialStartDate);

    setTime((prevValue) => ({
      ...prevValue,
      start: startTime,
    }));

    // bikin end time
    const initialEndDate = new Date(event.endDate);
    const endTime = formatTime(initialEndDate);

    setTime((prevValue) => ({
      ...prevValue,
      end: endTime,
    }));
  }, [event]);

  return (
    <div onClick={clicked} className={`${styles.Event} ${borderVariantStyles[borderVariant]}`}>
      <div className={styles.photoContentSection}>
        {/* <div className={styles.photoSection}>
          <h1>{date}</h1>
        </div> */}
        <div className={styles.contentSection}>
          <div className={styles.contentSection__header}>
            <div className={styles.contentSection__header__title}>
              <h1>
                <PrivateIcon data={event} size="small" />
                {' '}
                {event.title}
              </h1>
            </div>
            <div className={styles.contentSection__header__date}>
              {event.subscribers.map((subscriber) => (
                <ImageMember photoUrl={subscriber.photoUrl} small />
              ))}
            </div>
          </div>
          <div className={styles.contentSection__content}>
            {event.status === 'onStart'
              ? (
                <p>
                  {time.start}
                  <span> onward</span>
                </p>
              )
              : null}
            {event.status === 'onGoing' ? null : null}
            {event.status === 'onEnd'
              ? (
                <p>
                  <span>Until </span>
                  {time.end}
                </p>
              )
              : null}
            {event.status === 'sameDay' || !event.isAllday
              ? (
                <p>
                  <span>{time.start}</span>
                  <span> - </span>
                  {time.end}
                </p>
              )
              : null}
          </div>
        </div>
      </div>

      <div className={styles.bodySection__counter}>
        <DisplayBadgeCounter
          badgeContent={event?.comments?.length}
          backgroundColor={Color.deleteColor}
        />
      </div>
    </div>
  );
};

Event.propTypes = {
  event: PropTypes.object.isRequired,
  clicked: PropTypes.func.isRequired,
  borderVariant: PropTypes.oneOf(['square',
    'semiRounded',
    'squareSemiRoundedTopLeft',
    'squareSemiRoundedTopRight',
    'squareSemiRoundedBottomLeft',
    'squareSemiRoundedBottomRight',
  ]),
};

Event.defaultProps = {
  borderVariant: 'square',
};

export default Event;
