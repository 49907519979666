import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { styled } from '@mui/material/styles';
import { CircularProgress } from '@mui/material';
import styles from './General.module.css';
import Color from '../../../../themes/colors';
import { DisplayTextSubBody } from '../../Display/Text';

const FeedbackSpinnerGeneral = ({
  size, variant, value, color, thickness,
}) => (
  <>
    {value === undefined && (
      <CircularProgress
        sx={{ color }}
        size={size}
        variant={variant}
        value={value}
        thickness={thickness}
      />
    )}
    {value !== undefined && (
      <div className={styles.container}>
        <CircularProgress
          sx={{ color }}
          size={size}
          variant="determinate"
          value={value}
          thickness={thickness}
        />
        <div className={styles.value}>
          <DisplayTextSubBody>
            {`${Math.round(value)}%`}
          </DisplayTextSubBody>
        </div>
      </div>
    )}
  </>
);

FeedbackSpinnerGeneral.propTypes = {
  size: PropTypes.number,
  variant: PropTypes.oneOf(['indeterminate', 'determinate']),
  value: PropTypes.number,
  color: PropTypes.string,
  thickness: PropTypes.number,
};

FeedbackSpinnerGeneral.defaultProps = {
  size: 24,
  variant: 'indeterminate',
  value: undefined,
  color: Color.white,
  thickness: 6,
};

export default memo(FeedbackSpinnerGeneral);
