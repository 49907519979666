import React, { useState, useEffect, memo } from 'react';
import CloseIcon from '@mui/icons-material/Close';
import { useSnackbar } from 'notistack';
import { useParams } from 'react-router-dom';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CancelIcon from '@mui/icons-material/Cancel';
import CheckIcon from '@mui/icons-material/Check';
import PropTypes from 'prop-types';
import { isEqual } from 'lodash';
import BoxPopOver from '../../../components/UI/BoxPopOver/BoxPopOver';
import SeparatorLine from '../../../components/UI/SeparatorLine/SeparatorLine';
import styles from './ChangeCompleteIconMenu.module.css';
import OverlayButton from '../../../components/UI/Button/OverlayButton/OverlayButton';
import handleStatusMsg from '../../../utilities/handleStatusMsg';
import handleLoadings from '../../../utilities/handleLoadings';
import ButtonDiv from '../../../components/UI/Button/ButtonDiv/ButtonDiv';
import apiUtil from '../../../utilities/apiUtil';
import { ListActions } from '../../../actions';

const ChangeCompleteIconMenu = ({ list, cancel }) => {
  const [loadings, setLoadings] = useState([]);
  const { enqueueSnackbar } = useSnackbar();
  const [completeIconMenu, setCompleteIconMenu] = useState();

  const params = useParams();
  const { companyId, teamId, boardId } = params;

  useEffect(() => {
    if (completeIconMenu === undefined) return;

    const completeIconMenuApi = async () => {
      const { listId } = completeIconMenu;
      const { completeType } = completeIconMenu;

      try {
        const startLoadings = handleLoadings('completeIconMenu', [...loadings], 'start');
        setLoadings([...startLoadings]);
        const body = { boardId, completeType };
        const result = await ListActions.updateCompleteIcon({
          body, listId, companyId, teamId,
        });

        const status = handleStatusMsg(result, 'success');

        enqueueSnackbar(status.message, {
          variant: 'success',
        });

        cancel();
      } catch (err) {
        const status = handleStatusMsg(err, 'error');

        enqueueSnackbar(status.message, {
          variant: 'error',
        });
      } finally {
        const endLoadings = handleLoadings('completeIconMenu', [...loadings], 'end');
        setLoadings([...endLoadings]);
      }
    };

    completeIconMenuApi();
  }, [completeIconMenu]);

  const handleSetCompleteIconMenu = (type) => {
    setCompleteIconMenu({ listId: list._id, completeType: type });
  };

  return (
    <BoxPopOver left>
      <div className={styles.headerSection}>
        <h1>
          Ubah Ikon
        </h1>
        <CloseIcon onClick={cancel} />
      </div>
      <SeparatorLine />
      <OverlayButton loadings={loadings} wait="completeIconMenu">
        <div className={styles.bodySection}>

          <ButtonDiv onClick={() => handleSetCompleteIconMenu('done')} className={styles.menu}>
            <div className={styles.menu__leftSection}>
              <p>Selesai</p>
              <CheckCircleIcon className={styles.icon} />
            </div>
            {list?.complete?.type === 'done' ? (
              <div className={styles.menu__rightSection}>
                <CheckIcon className={styles.selectedIcon} />
              </div>
            ) : null}
          </ButtonDiv>
          <ButtonDiv onClick={() => handleSetCompleteIconMenu('blocked')} className={styles.menu}>
            <div className={styles.menu__leftSection}>
              <p>Blok</p>
              <CancelIcon className={`${styles.icon} ${styles.iconBlocked}`} />
            </div>
            {list?.complete?.type === 'blocked' ? (
              <div className={styles.menu__rightSection}>
                <CheckIcon className={styles.selectedIcon} />
              </div>
            ) : null}
          </ButtonDiv>
        </div>
      </OverlayButton>
    </BoxPopOver>
  );
};

ChangeCompleteIconMenu.propTypes = {
  list: PropTypes.object.isRequired,
  cancel: PropTypes.func.isRequired,
};

const isComponentDataEqual = (prevProps, nextProps) => {
  const { list } = nextProps;
  return isEqual(prevProps.list, list);
};

export default memo(ChangeCompleteIconMenu, isComponentDataEqual);
