import React, { memo } from 'react';
import { Document, Page, pdfjs } from 'react-pdf';
import PropTypes from 'prop-types';
import styles from './PdfThumbnail.module.css';

pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/legacy/build/pdf.worker.min.js`;

const PdfThumbnail = ({
  fileUrl,
  height,
}) => (
  <Document file={fileUrl}>
    <Page height={height} pageNumber={1} />
  </Document>
);

PdfThumbnail.propTypes = {
  fileUrl: PropTypes.string,
  height: PropTypes.number,
};

PdfThumbnail.defaultProps = {
  fileUrl: undefined,
  height: 80,
};

export default memo(PdfThumbnail);
