import React, {
  useState, useContext, useEffect, memo,
} from 'react';
import { useSnackbar } from 'notistack';
import axios from 'axios';
import { useHistory, useParams, useLocation } from 'react-router-dom';
import Form from 'react-bootstrap/Form';
import PropTypes from 'prop-types';
import { isEqual } from 'lodash';
import styles from './EditLabel.module.css';
import { actionTypes } from '../../../reducers/reducer';
import { GlobalContext } from '../../../contexts/GlobalStateProvider';
import Button from '../../../components/UI/Button/Button';
import { ShowEditLabelContext } from '../ManageLabelsContext';
import ColorBox from '../../../components/UI/ColorBox/ColorBox';
import handleStatusMsg from '../../../utilities/handleStatusMsg';
import handleLoadings from '../../../utilities/handleLoadings';
import apiUtil from '../../../utilities/apiUtil';
import { DEFAULT_OPTION } from '../../../constants/ApiConstants';
import { BoardActions } from '../../../actions';
import { handleAxiosDeleteRefreshToken } from '../../../actions/CheckLoginActions';

const EditLabel = ({ card }) => {
  const [{ user, colors }, dispatch] = useContext(GlobalContext);

  const [showEditLabel, setShowEditLabel] = useContext(ShowEditLabelContext);
  const [currentLabel, setCurrentLabel] = useState();
  const [editedLabel, setEditedLabel] = useState({ value: '' });
  const [postEditedLabel, setPostEditedLabel] = useState();
  const [postDeletedLabel, setPostDeletedLabel] = useState();
  const [selectedColor, setSelectedColor] = useState();
  const { enqueueSnackbar } = useSnackbar();
  const [loadings, setLoadings] = useState([]);
  const params = useParams();
  const history = useHistory();
  const location = useLocation();

  const { companyId } = params;

  useEffect(() => {
    if (showEditLabel === undefined) {
      return;
    }
    const fetchApiColors = async () => {
      const teamId = params.teamId || card.team?._id || card.team;
      try {
        const result = await apiUtil.get('/api/v1/colors', {
          params: {
            companyId,
            teamId,
          },
        });

        dispatch({
          type: actionTypes.SET_COLORS,
          colors: result.data.colors,
        });
      } catch (err) {
        const status = handleStatusMsg(err, 'error');

        enqueueSnackbar(status.message, {
          variant: 'error',
        });
      }
    };
    fetchApiColors();

    const fetchApiLabel = async () => {
      try {
        const labelId = showEditLabel;
        const cardId = params.cardId || card._id;
        const teamId = params.teamId || card.team?._id || card.team;
        const result = await apiUtil.get(`/api/v1/labels/${labelId}`, { params: { companyId, teamId, cardId } });

        setCurrentLabel(result.data.label);
      } catch (err) {
        const status = handleStatusMsg(err, 'error');

        enqueueSnackbar(status.message, {
          variant: 'error',
        });
      }
    };
    fetchApiLabel();
  }, [showEditLabel]);

  useEffect(() => {
    if (currentLabel === undefined) {
      return;
    }
    setEditedLabel({
      value: currentLabel?.name,
    });

    setSelectedColor(currentLabel?.color?._id);
  }, [currentLabel]);

  useEffect(() => {
    if (postEditedLabel !== undefined) {
      const patchApiLabel = async () => {
        const labelId = currentLabel._id;
        const cardId = params.cardId || card._id;
        const teamId = params.teamId || card.team?._id || card.team;
        const labelName = postEditedLabel.value;
        const labelColorId = selectedColor;

        try {
          const startLoadings = handleLoadings('editLabel', [...loadings], 'start');
          setLoadings([...startLoadings]);

          const bodyRequest = {
            selector: { cardId },
            data: { name: labelName, color: labelColorId },
          };
          const result = await apiUtil.patch(`/api/v1/labels/${labelId}`, bodyRequest, {
            params: {
              companyId,
              teamId,
            },
          });

          const status = handleStatusMsg(result, 'success');

          enqueueSnackbar(status.message, {
            variant: 'success',
          });

          setSelectedColor();
          setShowEditLabel();
        } catch (err) {
          const status = handleStatusMsg(err, 'error');

          enqueueSnackbar(status.message, {
            variant: 'error',
          });
        } finally {
          const endLoadings = handleLoadings('editLabel', [...loadings], 'end');
          setLoadings([...endLoadings]);
        }
      };
      patchApiLabel();
    }
  }, [postEditedLabel]);

  useEffect(() => {
    if (user._id === undefined) {
      history.push({
        pathname: '/check-login',
        state: {
          from: location.pathname,
        },
      });
    } else if (postDeletedLabel !== undefined) {
      const deleteApiLabel = async () => {
        const labelId = currentLabel._id;
        const cardId = params.cardId || card._id;
        const teamId = params.teamId || card.team?._id || card.team;
        try {
          const startLoadings = handleLoadings('deleteLabel', [...loadings], 'start');
          setLoadings([...startLoadings]);

          const result = await axios.delete(`${process.env.REACT_APP_PRIMARY_API_URL}/api/v1/labels/${labelId}`, {
            withCredentials: true,
            headers: {
              Authorization: `jwt ${localStorage.getItem('token')}`,
            },
            params: {
              companyId,
              teamId,
            },
            data: { selector: { cardId } },
          });

          const status = handleStatusMsg(result, 'success');

          enqueueSnackbar(status.message, {
            variant: 'success',
          });

          setSelectedColor();
          setShowEditLabel();
        } catch (err) {
          const newAuthToken = await handleAxiosDeleteRefreshToken(err);

          if (newAuthToken) {
            try {
              const result = await axios.delete(`${process.env.REACT_APP_PRIMARY_API_URL}/api/v1/labels/${labelId}`, {
                withCredentials: true,
                headers: {
                  Authorization: `jwt ${newAuthToken}`,
                },
                params: {
                  companyId,
                  teamId,
                },
                data: { selector: { cardId } },
              });

              const status = handleStatusMsg(result, 'success');

              enqueueSnackbar(status.message, {
                variant: 'success',
              });

              setSelectedColor();
              setShowEditLabel();
            } catch (error) {
              const status = handleStatusMsg(error, 'error');

              enqueueSnackbar(status.message, {
                variant: 'error',
              });
            }
          } else {
            const status = handleStatusMsg(err, 'error');

            enqueueSnackbar(status.message, {
              variant: 'error',
            });
          }
        } finally {
          const endLoadings = handleLoadings('deleteLabel', [...loadings], 'end');
          setLoadings([...endLoadings]);
        }
      };
      deleteApiLabel();
    }
  }, [postDeletedLabel]);

  const handleEditLabel = () => {
    if (editedLabel === undefined
      || editedLabel?.value === undefined
      || editedLabel?.value.length < 1) {
      enqueueSnackbar('Label name cannot be empty!', {
        variant: 'error',
      });
      return;
    }

    if (selectedColor === undefined) {
      enqueueSnackbar('Please select a color!', {
        variant: 'error',
      });
      return;
    }
    setPostEditedLabel(editedLabel);
  };

  const handleChanged = (event) => {
    const { value } = event.target;

    setEditedLabel((prevValue) => ({
      ...prevValue,
      value,
    }));
  };

  const handleSelectColor = (colorId) => {
    setSelectedColor(colorId);
  };

  const handleDeleteLabel = () => {
    setPostDeletedLabel(showEditLabel);
  };

  return (
    <>
      <div className={styles.sectionSmallTitle}>
        <p className={styles.EditLabelContainer__smallTitle}>Ubah nama :</p>
        <Form>
          <Form.Control autoFocus onChange={(event) => handleChanged(event)} as="input" value={editedLabel.value} />
        </Form>
      </div>
      <div className={styles.sectionSmallTitle}>
        <p className={styles.EditLabelContainer__smallTitle}>Ubah warna :</p>
      </div>
      <div className={styles.EditLabelContainer__body}>
        {colors.map((color) => (
          <ColorBox
            clicked={() => handleSelectColor(color._id)}
            color={color.name}
            checked={selectedColor === color._id}
          />
        ))}
      </div>
      <div className={styles.sectionFooter}>
        <Button handleClick={handleEditLabel} variant="success" size="sm" wait="editLabel" loadings={loadings}>Simpan</Button>
        <Button handleClick={handleDeleteLabel} variant="danger" size="sm" wait="deleteLabel" loadings={loadings}>Hapus</Button>
      </div>
    </>
  );
};

EditLabel.propTypes = {
  card: PropTypes.object.isRequired,
};

const isComponentDataEqual = (prevProps, nextProps) => {
  const { card } = nextProps;
  return isEqual(prevProps.card, card);
};

export default memo(EditLabel, isComponentDataEqual);
