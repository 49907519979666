import React, {
  useState, useContext, useEffect, useCallback, createRef,
} from 'react';
import {
  useHistory, useParams, useLocation,
} from 'react-router-dom';
import _ from 'lodash';
import draftToHtml from 'draftjs-to-html';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import ArchiveIcon from '@mui/icons-material/Archive';
import VerifiedUserIcon from '@mui/icons-material/VerifiedUser';
import AddIcon from '@mui/icons-material/Add';
import MainLayout from '../../components/Layout/MainLayout/MainLayout';
import { GlobalContext, initialState } from '../../contexts/GlobalStateProvider';
import { actionTypes } from '../../reducers/reducer';
import SeparatorLine from '../../components/UI/SeparatorLine/SeparatorLine';
import Paper from '../../components/UI/Paper/Paper';
import styles from './DocPage.module.css';
import CommentsSectionContainer from '../CommentsSectionContainer/CommentsSectionContainer';
import ImageMember from '../../components/UI/ImageMember/ImageMember';
import RoundActionMenu from '../../components/UI/RoundActionMenu/RoundActionMenu';
import DocMenu from './DocMenu/DocMenu';
import Title from '../../components/Title/Title';
import checkIsJson from '../../utilities/checkIsJson';
import EditorView from '../FroalaEditor/EditorView/EditorView';
import handleLoadings from '../../utilities/handleLoadings';
import PageLoading from '../../components/UI/Button/PageLoading/PageLoading';
import handleStatusMsg from '../../utilities/handleStatusMsg';
import CheersContainer from '../CheersContainer/CheersContainer';
import {
  CommentActions,
  DocActions, SearchJumpToActions, TeamActions,
} from '../../actions';
import { useInfinityScrollHooks } from '../../hooks/InfinityScrollHooks';
import InfinityScroll from '../../components/UI/InfinityScroll/InfinityScroll';
import { useDocSocket, useDocCheerNCommentSocket } from '../../hooks/DocHooks';
import { useScrollToTargetComment } from '../../hooks/DiscussionHooks';
import PrivateIcon from '../../components/UI/PrivateIcon/PrivateIcon';
import GeneralSubNavBar from '../../components/GeneralSubNavBar/GeneralSubNavBar';
import { generateRoutesAndTitle } from '../../actions/BreadCrumbActions';
import GlobalActionButton from '../../components/GeneralSubNavBar/BottomNavBar/GlobalActionButton/GlobalActionButton';
import { PageConstants, SeenConstants } from '../../constants';
import { useDelayShowHideHandler } from '../../hooks/HelperHooks';
import { useInitiateRoleUser } from '../../hooks/RoleHooks';
import { roleTypeRef, typeRef } from '../../constants/RoleConstants';
import MyRoleUser from '../../components/Container/MyRoleUser/MyRoleUser';
import { checkIfUserAuthorizedToEditData } from '../../actions/UserActions';
import { useInitiateTeamBelowRole } from '../../hooks/TeamHooks';
import ActivityByModule from '../ActivityByModule/ActivityByModule';
import { serviceTypeRef } from '../../constants/ActivityConstants';
import { DisplayCreatorMain } from '../../components/UI_V2/Display/Creator';
import { DisplayAvatarMemberWithOnline } from '../../components/UI_V2/Display/Avatar';
import SeenInfo from '../../components/Container/SeenModal/SeenInfo/SeenInfo';
import SeenModal from '../../components/Container/SeenModal/SeenModal';
import { useUserTypingDocSocket } from '../../hooks/UserTypingHooks';
import UserTyping from '../../components/Container/UserTyping/UserTyping';
import { useMarkReadNotificationOnPageOpen } from '../../hooks/NotificationHooks';
import { LayoutMain } from '../../components/UI_V2/Layout';
import { SurfacePaperMain } from '../../components/UI_V2/Surface/Paper';

function DocPage() {
  const [{
    user, currentBucket, currentBucketDoc,
    previousDocComment, bucketDocPath,
    currentTeam, currentCompany,
    currentRoleUser, currentTeamBelowRole,
    allUserTypingDoc,
  }, dispatch] = useContext(GlobalContext);
  const [showDocMenu, setShowDocMenu] = useState(false);
  const [openCreateCommentForm, setOpenCreateCommentForm] = useState(false);
  const [loadings, setLoadings] = useState([]);
  const [
    showModalRoleUser,
    handleShowModalRoleUser,
    handleHideModalRoleUser,
  ] = useDelayShowHideHandler();

  const [
    showSeenModal,
    handleShowSeenModal,
    handleHideSeenModal,
  ] = useDelayShowHideHandler();
  const [isInitialContentReady, setIsInitialContentReady] = useState(false);
  const [editorModel, setEditorModel] = useState();

  const history = useHistory();
  const location = useLocation();
  const params = useParams();
  const {
    companyId, teamId, docId,
  } = params;
  const { scrollToTargetComment } = useScrollToTargetComment();

  useInitiateRoleUser({
    payload: {
      teamId,
      featureType: typeRef.doc,
      featureId: docId,
    },
  });

  const { socket } = useUserTypingDocSocket({
    companyId,
    docId,
    teamId,
    userId: user?._id,
  }, dispatch);

  // read notif automatically if there is readNotifId
  useMarkReadNotificationOnPageOpen();

  // initiate members below role
  // useInitiateTeamBelowRole({});

  const isUserCreator = currentBucketDoc?.creator?._id === user?._id;

  const [
    showActivities,
    handleShowActivities,
    handleHideActivities,
  ] = useDelayShowHideHandler();

  // for open create comment
  const handleOpenCreateCommentForm = useCallback((value) => {
    setOpenCreateCommentForm(value);
  }, []);
  const createCommentRef = createRef();
  const scrollAndOpenCreateCommentForm = useCallback(() => {
    if (createCommentRef && createCommentRef.current) {
      createCommentRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
    }
    setTimeout(() => {
      handleOpenCreateCommentForm(true);
    }, 800);
  }, [createCommentRef]);

  const getMoreComments = async () => {
    const result = await DocActions.loadMoreDocComment({
      docId: params?.docId,
      currentBucketDoc,
      companyId,
      teamId,
    }, dispatch);

    return result;
  };

  const resetPreviousComment = useCallback(() => {
    DocActions.setPreviousBucketDocComment({
      previousDocComment: initialState.previousDocComment,
    }, dispatch);
  }, []);

  const {
    lists,
    handleLoadMoreLists,
    checkIfListNeedToLoad,
    setCheckLoadMore,
    isListsLengthAtTheLimitOrAbove,
  } = useInfinityScrollHooks({
    currentObjectWithKeyProperty: currentBucketDoc,
    previousLists: previousDocComment,
    resetPreviousLists: resetPreviousComment,
    getMoreLists: getMoreComments,
  });

  useDocSocket({
    bucketId: currentBucket?._id,
    docId: params?.docId,
    userId: user?._id,
  }, dispatch);
  useDocCheerNCommentSocket({ docId: params?.docId, userId: user?._id }, dispatch);

  useEffect(() => {
    if (user._id === undefined) {
      history.push(`/check-login?previousPath=${location.pathname}`);
      return;
    }

    const startLoadings = handleLoadings('docPage', [...loadings], 'start');
    setLoadings([...startLoadings]);

    const fetchApiDocDetail = async () => {
      try {
        const result = await DocActions.initiateDoc({
          docId,
          companyId,
          teamId,
          limit: CommentActions.getLimitCommentByPreviousCommentDiscussion(),
        }, dispatch);
        await TeamActions.initiateTeam({
          teamId,
          companyId,
          currentTeam,
        }, dispatch);
        if (isListsLengthAtTheLimitOrAbove(result?.data?.comments)) {
          setCheckLoadMore(true);
        }
        scrollToTargetComment();
      } catch (err) {
        const status = handleStatusMsg(err, 'error');

        dispatch({
          type: actionTypes.SET_ERROR_RESPONSE,
          errorResponse: { message: status.message },
        });

        history.push(`/errors?previousPath=${location.pathname}`);
      } finally {
        const endLoadings = handleLoadings('docPage', [...loadings], 'end');
        setLoadings([...endLoadings]);
      }
    };

    fetchApiDocDetail();
  }, [location]);

  useEffect(() => {
    if (_.isEmpty(currentBucketDoc)) {
      return;
    }
    let initialContent;
    if (checkIsJson(currentBucketDoc.content)) {
      initialContent = draftToHtml(JSON.parse(currentBucketDoc.content));
    } else {
      initialContent = currentBucketDoc.content;
    }

    setEditorModel(initialContent);
    setIsInitialContentReady(true);
  }, [currentBucketDoc]);

  const handleShowDocMenu = () => {
    setShowDocMenu(true);
  };

  const handleCancelShowDocMenu = () => {
    setShowDocMenu(false);
  };
  const pageType = PageConstants.pageType.docs;
  const breadcrumb = generateRoutesAndTitle({
    type: pageType,
    data: {
      companyId, currentTeam, currentBucketDoc, bucketDocPath,
    },
  });
  SearchJumpToActions.saveRecentPage({
    company: currentCompany,
    team: currentTeam,
    userId: user?._id,
    itemId: docId,
    url: breadcrumb.url,
    routes: breadcrumb.routes,
    name: breadcrumb.title,
    type: pageType,
  });

  return (
    <>
      <Title title={currentBucketDoc.title === undefined ? 'Dokumen' : currentBucketDoc.title} />
      <GeneralSubNavBar
        breadcrumbRoutes={breadcrumb.routes}
        pageTitle={breadcrumb.title}
        bottomBarOverviewOptionId="buckets"
        bottomBarGlobalActions={(
          <>
            <GlobalActionButton handleClick={scrollAndOpenCreateCommentForm} text="Tambah Komentar" icon={<AddIcon />} />
            <GlobalActionButton
              handleClick={handleShowModalRoleUser}
              text="Akses Saya"
              icon={<VerifiedUserIcon />}
            />
          </>
        )}
      />
      <LayoutMain>
        <PageLoading wait="docPage" loadings={loadings}>
          <SurfacePaperMain>
            <div className={styles.container}>
              <div className={styles.headerSection}>
                <div className={styles.titleSection}>
                  <div className={styles.titleSection__title}>
                    {currentBucketDoc.archived ? (
                      <>
                        {currentBucketDoc.archived.status ? (
                          <div className={styles.archivedSection}>
                            <ArchiveIcon />
                            <h1>Dokumen ini udah terarsip</h1>
                          </div>
                        ) : null}
                      </>
                    ) : null}
                    <h1>
                      <PrivateIcon data={currentBucketDoc} />
                      {' '}
                      {currentBucketDoc.title}
                    </h1>
                  </div>
                  <div className={styles.creator}>
                    <DisplayCreatorMain data={currentBucketDoc} />
                  </div>
                </div>
                <div className={styles.menuSection}>
                  {checkIfUserAuthorizedToEditData(currentBucketDoc,
                    user, currentRoleUser, currentTeamBelowRole) && (
                    <RoundActionMenu
                      clicked={handleShowDocMenu}
                      cancel={handleCancelShowDocMenu}
                      medium
                    >
                      <MoreHorizIcon />
                    </RoundActionMenu>
                  )}
                  {showDocMenu
                    ? <DocMenu doc={currentBucketDoc} cancel={handleCancelShowDocMenu} />
                    : null}
                </div>
              </div>

              <SeparatorLine />
              <div className={styles.docsSection}>
                {isInitialContentReady ? <EditorView model={editorModel} /> : <p>Please wait...</p>}
              </div>

              <div className={styles.cheersSection}>
                {_.isEmpty(currentBucketDoc) ? null : <CheersContainer cheers={currentBucketDoc?.cheers} type="docMain" receiver={currentBucketDoc?.creator?._id} primaryParentId={currentBucketDoc?._id} />}
              </div>
              <div className={styles.seenSection}>
                {isUserCreator && (
                <SeenInfo
                  count={currentBucketDoc?.seen?.length}
                  onClick={handleShowSeenModal}
                  variant="black"
                />
                )}
              </div>
              <div className={styles.typingSection}>
                <UserTyping
                  allUserTyping={allUserTypingDoc}
                  companyMembers={currentCompany?.members}
                />
              </div>
              <div className={styles.commentSection}>
                {!showActivities && (
                <InfinityScroll
                  dataLength={lists?.length}
                  hasMore={checkIfListNeedToLoad()}
                  next={handleLoadMoreLists}
                  emptyMessage="Belum ada komentar disini..."
                  style={{
                    overflow: undefined,
                  }}
                >
                  <div>
                    <CommentsSectionContainer
                      comments={lists}
                      type="doc"
                      ref={createCommentRef}
                      openForm={openCreateCommentForm}
                      onOpenForm={handleOpenCreateCommentForm}
                      handleShowActivities={handleShowActivities}
                      socket={socket}
                    />
                  </div>
                </InfinityScroll>
                )}
                {showActivities && (
                <ActivityByModule
                  variant={serviceTypeRef.doc}
                  handleHideActivities={handleHideActivities}
                />
                )}
              </div>
              <SeparatorLine />
              {currentBucketDoc.subscribers !== undefined ? (
                <div className={styles.Subscribers}>
                  <div className={styles.subscribersHeader}>
                    {currentBucketDoc.subscribers.length < 1
                      ? (
                        <p>
                          <strong>Dokumen ini belum punya penerima.</strong>
                        </p>
                      ) : <p>Dokumen ini diterima oleh : </p>}
                  </div>
                  <div className={styles.subscribersItem}>

                    {currentBucketDoc.subscribers.map((subscriber) => (
                      <div>
                        <DisplayAvatarMemberWithOnline
                          src={subscriber?.photoUrl}
                          userId={subscriber._id}
                          size="sm"
                        />
                      </div>
                    ))}
                  </div>
                </div>
              ) : null}
            </div>
          </SurfacePaperMain>
        </PageLoading>
        <MyRoleUser
          open={showModalRoleUser}
          onClose={handleHideModalRoleUser}
          featureId={docId}
          featureType={typeRef.doc}
          featureTitle={currentBucketDoc?.title}
          roleType={roleTypeRef.feature}
          featureData={currentBucketDoc}
        />
        <SeenModal
          open={showSeenModal}
          onClose={handleHideSeenModal}
          type={SeenConstants.typeModule.doc}
          itemId={docId}
        />
      </LayoutMain>
    </>
  );
}

export default DocPage;
