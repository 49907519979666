import React, { useContext } from 'react';
import { useParams } from 'react-router-dom';
import MessageIcon from '@mui/icons-material/Message';
import PropTypes from 'prop-types';
import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile';
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import HelpIcon from '@mui/icons-material/Help';
import AssignmentIcon from '@mui/icons-material/Assignment';
import AttachFileIcon from '@mui/icons-material/AttachFile';
import { GlobalContext } from '../../../../contexts/GlobalStateProvider';
import ImageMember from '../../../../components/UI/ImageMember/ImageMember';
import LinkNoDecor from '../../../../components/LinkNoDecor/LinkNoDecor';
import LittleIcon from '../../../../components/UI/LittleIcon/LittleIcon';
import CheersItem from '../../../CheersContainer/ShowCheers/CheersItem/CheersItem';
import styles from './NotifAndCheers.module.css';
import { DisplayTextBody, DisplayTextSubHeadline } from '../../../../components/UI_V2/Display/Text';
import Color from '../../../../themes/colors';

const NotifAndCheers = ({ notifCheer }) => {
  const [{ user }, dispatch] = useContext(GlobalContext);
  const params = useParams();
  const { companyId } = params;

  let title;
  let content;
  let icon;
  let backgroundIconColor;
  let clickUrl;

  switch (notifCheer.service.serviceType) {
    case 'postCheers':
      icon = <MessageIcon />;
      title = notifCheer.childContent;
      content = notifCheer.content;
      clickUrl = `/companies/${notifCheer.company || companyId}/teams/${notifCheer.team._id}/posts/${notifCheer.service.serviceId}`;
      break;
    case 'eventCheers':
      icon = <CalendarTodayIcon />;
      backgroundIconColor = '#ce1212';
      title = notifCheer.childContent;
      content = notifCheer.content;
      clickUrl = `/companies/${notifCheer.company || companyId}/teams/${notifCheer.team._id}/events/${notifCheer.service.serviceId}`;
      break;
    case 'recurringOccurrenceCheers':
      icon = <CalendarTodayIcon />;
      backgroundIconColor = '#ce1212';
      title = notifCheer.childContent;
      content = notifCheer.content;
      clickUrl = `/companies/${notifCheer.company || companyId}/teams/${notifCheer.team._id}/events/${notifCheer.service.serviceId}/occurrences/${notifCheer.greatGrandChildService.serviceId}`;
      break;
    case 'docCheers':
      icon = <InsertDriveFileIcon />;
      backgroundIconColor = '#7868e6';
      title = notifCheer.childContent;
      content = notifCheer.content;
      clickUrl = `/companies/${notifCheer.company || companyId}/teams/${notifCheer.team._id}/docs/${notifCheer.service.serviceId}`;
      break;
    case 'fileCheers':
      icon = <AttachFileIcon />;
      backgroundIconColor = '#822659';
      title = notifCheer.childContent;
      content = notifCheer.content;
      clickUrl = `/companies/${notifCheer.company || companyId}/teams/${notifCheer.team._id}/files/${notifCheer.service.serviceId}`;
      break;
    case 'cardCheers':
      icon = <AssignmentIcon />;
      backgroundIconColor = '#ff7a8a';
      title = notifCheer.childContent;
      content = notifCheer.content;
      clickUrl = `/companies/${notifCheer.company || companyId}/teams/${notifCheer.team._id}/cards/${notifCheer.service.serviceId}`;
      break;
    case 'postCommentCheers':
      icon = <MessageIcon />;
      title = `Komentar Pengumuman: ${notifCheer.childContent}`;
      content = notifCheer.content;
      clickUrl = `/companies/${notifCheer.company || companyId}/teams/${notifCheer.team._id}/posts/${notifCheer.childService.serviceId}`;
      break;
    case 'eventCommentCheers':
      icon = <CalendarTodayIcon />;
      backgroundIconColor = '#ce1212';
      title = `Komentar Jadwal: ${notifCheer.childContent}`;
      content = notifCheer.content;
      clickUrl = `/companies/${notifCheer.company || companyId}/teams/${notifCheer.team._id}/events/${notifCheer.childService.serviceId}`;
      break;
    case 'recurringOccurrenceCommentCheers':
      icon = <CalendarTodayIcon />;
      backgroundIconColor = '#ce1212';
      title = `Komentar Jadwal: ${notifCheer.childContent}`;
      content = notifCheer.content;
      clickUrl = `/companies/${notifCheer.company || companyId}/teams/${notifCheer.team._id}/events/${notifCheer.childService.serviceId}/occurrences/${notifCheer.greatGrandChildService.serviceId}`;
      break;
    case 'questionCommentCheers':
      icon = <HelpIcon />;
      backgroundIconColor = '#28df99';
      title = `Jawaban Pertanyaan: ${notifCheer.childContent}`;
      content = notifCheer.content;
      clickUrl = `/companies/${notifCheer.company || companyId}/teams/${notifCheer.team._id}/questions/${notifCheer.childService.serviceId}`;
      break;
    case 'docCommentCheers':
      icon = <InsertDriveFileIcon />;
      backgroundIconColor = '#7868e6';
      title = `Komentar Dokumen: ${notifCheer.childContent}`;
      content = notifCheer.content;
      clickUrl = `/companies/${notifCheer.company || companyId}/teams/${notifCheer.team._id}/docs/${notifCheer.childService.serviceId}`;
      break;
    case 'fileCommentCheers':
      icon = <AttachFileIcon />;
      backgroundIconColor = '#822659';
      title = `Komentar File: ${notifCheer.childContent}`;
      content = notifCheer.content;
      clickUrl = `/companies/${notifCheer.company || companyId}/teams/${notifCheer.team._id}/files/${notifCheer.childService.serviceId}`;
      break;
    case 'cardCommentCheers':
      icon = <AssignmentIcon />;
      backgroundIconColor = '#ff7a8a';
      title = `Komentar Tugas: ${notifCheer.childContent}`;
      content = notifCheer.content;
      clickUrl = `/companies/${notifCheer.company || companyId}/teams/${notifCheer.team._id}/cards/${notifCheer.childService.serviceId}`;
      break;
    case 'postCommentDiscussionCheers':
      icon = <MessageIcon />;
      title = `Balasan komentar di Pengumuman: ${notifCheer.grandChildContent}`;
      content = notifCheer.content;
      clickUrl = `/companies/${notifCheer.company || companyId}/teams/${notifCheer.team._id}/posts/${notifCheer.grandChildService.serviceId}/comments/${notifCheer.childService.serviceId}`;
      break;
    case 'eventCommentDiscussionCheers':
      icon = <CalendarTodayIcon />;
      backgroundIconColor = '#ce1212';
      title = `Balasan komentar di Jadwal: ${notifCheer.grandChildContent}`;
      content = notifCheer.content;
      clickUrl = `/companies/${notifCheer.company || companyId}/teams/${notifCheer.team._id}/events/${notifCheer.grandChildService.serviceId}/comments/${notifCheer.childService.serviceId}`;
      break;
    case 'recurringOccurrenceCommentDiscussionCheers':
      icon = <CalendarTodayIcon />;
      backgroundIconColor = '#ce1212';
      title = `Balasan komentar di Jadwal: ${notifCheer.grandChildContent}`;
      content = notifCheer.content;
      clickUrl = `/companies/${notifCheer.company || companyId}/teams/${notifCheer.team._id}/events/${notifCheer.grandChildService.serviceId}/occurrences/${notifCheer.greatGrandChildService.serviceId}/comments/${notifCheer.childService.serviceId}`;
      break;
    case 'questionCommentDiscussionCheers':
      icon = <HelpIcon />;
      backgroundIconColor = '#28df99';
      title = `Balasan jawaban di Pertanyaan: ${notifCheer.grandChildContent}`;
      content = notifCheer.content;
      clickUrl = `/companies/${notifCheer.company || companyId}/teams/${notifCheer.team._id}/questions/${notifCheer.grandChildService.serviceId}/comments/${notifCheer.childService.serviceId}`;
      break;
    case 'docCommentDiscussionCheers':
      icon = <InsertDriveFileIcon />;
      backgroundIconColor = '#7868e6';
      title = `Balasan komentar di Dokumen: ${notifCheer.grandChildContent}`;
      content = notifCheer.content;
      clickUrl = `/companies/${notifCheer.company || companyId}/teams/${notifCheer.team._id}/docs/${notifCheer.grandChildService.serviceId}/comments/${notifCheer.childService.serviceId}`;
      break;
    case 'fileCommentDiscussionCheers':
      icon = <AttachFileIcon />;
      backgroundIconColor = '#822659';
      title = `Balasan komentar di File: ${notifCheer.grandChildContent}`;
      content = notifCheer.content;
      clickUrl = `/companies/${notifCheer.company || companyId}/teams/${notifCheer.team._id}/files/${notifCheer.grandChildService.serviceId}/comments/${notifCheer.childService.serviceId}`;
      break;
    case 'cardCommentDiscussionCheers':
      icon = <AssignmentIcon />;
      backgroundIconColor = '#ff7a8a';
      title = `Balasan komentar di Tugas: ${notifCheer.grandChildContent}`;
      content = notifCheer.content;
      clickUrl = `/companies/${notifCheer.company || companyId}/teams/${notifCheer.team._id}/cards/${notifCheer.grandChildService.serviceId}/comments/${notifCheer.childService.serviceId}`;
      break;

    default:
      break;
  }
  return (
    <LinkNoDecor to={clickUrl}>
      <div className={styles.container}>
        <div className={styles.creatorAndBox}>
          <div className={styles.creator}>
            <ImageMember photoUrl={user?.photoUrl} />
            <LittleIcon color={backgroundIconColor}>
              {icon}
            </LittleIcon>
          </div>
          <div className={styles.notifSection}>
            <div className={styles.titleContent}>
              <div className={styles.titleContent__title}>
                <DisplayTextSubHeadline mode="14" decoration="bold">{title}</DisplayTextSubHeadline>
              </div>
              <div>
                <DisplayTextBody fontFamily="openSans">{content}</DisplayTextBody>
              </div>
              <div className={styles.titleContent__team}>
                <DisplayTextBody
                  fontFamily="openSans"
                  mode="11"
                  color={Color.gray4}
                >
                  {notifCheer.team.name}
                </DisplayTextBody>
              </div>
            </div>
          </div>
        </div>
        <div className={styles.cheersSection}>
          {notifCheer.cheers.map((cheer) => (
            <CheersItem cheer={cheer} disableClick />
          ))}
        </div>
      </div>
    </LinkNoDecor>
  );
};

NotifAndCheers.propTypes = {
  notifCheer: PropTypes.object.isRequired,
};

export default NotifAndCheers;
