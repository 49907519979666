import React, { memo } from 'react';
import PropTypes from 'prop-types';
import Dialog from '@mui/material/Dialog';
import CloseIcon from '@mui/icons-material/Close';
import { useMediaQuery } from '@mui/material';

const iconStyles = {
  position: 'absolute',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  top: '25px',
  right: '50px',
  cursor: 'pointer',
  opacity: '0.8',
  '&:hover': {
    opacity: '1',
  },
};

const closeIconStyles = {
  tablet: {
    top: '25px',
    right: '25px',
  },
  mobile: {
    top: '15px',
    right: '15px',
  },
};

// const contentStyles = {
//   paddingTop: '50px',
// };

const FeedbackDialogGeneral = ({
  open,
  onClose,
  children,
  maxWidth,
  fullWidth,
  disableBackdropClick,
  disableCloseIcon,
  disableEscapeKeyDown,
}) => {
  const isTablet = useMediaQuery('(max-width:1024px)');
  const isMobile = useMediaQuery('(max-width:720px)');

  const tabletCloseIconStyles = isTablet && closeIconStyles.tablet;
  const mobileCloseIconStyles = isMobile && closeIconStyles.mobile;
  return (
    <Dialog
      open={open}
      onClose={(_, reason) => {
        if (disableBackdropClick && reason === 'backdropClick') {
          return;
        }
        onClose();
      }}
      fullWidth={fullWidth}
      maxWidth={maxWidth}
      disableEscapeKeyDown={disableEscapeKeyDown}
    >
      {!disableCloseIcon ? (
        <CloseIcon
          sx={{
            ...iconStyles,
            ...tabletCloseIconStyles,
            ...mobileCloseIconStyles,
          }}
          onClick={onClose}
        />
      ) : null}
      {children}
    </Dialog>
  );
};

FeedbackDialogGeneral.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  children: PropTypes.node.isRequired,
  maxWidth: PropTypes.oneOf(['xs', 'sm', 'md', 'lg', 'xl']),
  fullWidth: PropTypes.bool,
  disableCloseIcon: PropTypes.bool,
  disableEscapeKeyDown: PropTypes.bool,
  disableBackdropClick: PropTypes.bool,
};

FeedbackDialogGeneral.defaultProps = {
  maxWidth: 'md',
  fullWidth: true,
  disableCloseIcon: false,
  disableEscapeKeyDown: false,
  disableBackdropClick: false,
};

export default memo(FeedbackDialogGeneral);
