import React, { memo, useContext, useState } from 'react';
import PropTypes from 'prop-types';
import DownloadIcon from '@mui/icons-material/Download';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { useMediaQuery } from '@mui/material';
import styles from './UserTyping.module.css';
import Color from '../../../themes/colors';
import { DisplayTextSubBody } from '../../UI_V2/Display/Text';
import bubbleGif from '../../../assets/UserTyping/bubble.gif';
import { getListFullNameStartTypingFromCompanyMembers, getRemainingStartUserTypingLength } from '../../../actions/UserTypingActions';
import { GlobalContext } from '../../../contexts/GlobalStateProvider';

const UserTyping = ({
  allUserTyping,
  companyMembers,
}) => {
  const isMobile = useMediaQuery('(max-width:720px)');
  const [{ user }] = useContext(GlobalContext);

  const listFullName = getListFullNameStartTypingFromCompanyMembers(
    allUserTyping, companyMembers, user?._id, isMobile,
  );
  const tooManyRemainingUserTypingLength = getRemainingStartUserTypingLength(
    allUserTyping, user?._id, isMobile,
  );

  const isSomeOneTyping = listFullName?.length > 0;
  const isTooManyTyping = tooManyRemainingUserTypingLength > 0;

  return (
    <>
      {isSomeOneTyping && (
      <div className={styles.container}>
        <div className={styles.bubble}>
          <LazyLoadImage className={styles.bubbleGif} src={bubbleGif} />
        </div>
        <div className={styles.text}>
          <div className={styles.namesText}>
            <DisplayTextSubBody
              color={Color.gray3}
              decoration="bold"
            >
              {listFullName}
              {isTooManyTyping && ` dan ${tooManyRemainingUserTypingLength} lainnya`}
            </DisplayTextSubBody>
          </div>
          <div className={styles.typingText}>
            <DisplayTextSubBody
              color={Color.gray3}
            >
              {' '}
              sedang mengetik...
            </DisplayTextSubBody>
          </div>
        </div>
      </div>
      )}
    </>
  );
};

UserTyping.propTypes = {
  allUserTyping: PropTypes.array,
  companyMembers: PropTypes.array,
};

UserTyping.defaultProps = {
  allUserTyping: [],
  companyMembers: [],
};

export default memo(UserTyping);
