import React, {
  memo,
  useCallback, useContext,
  useEffect,
  useState,
} from 'react';
import PropTypes from 'prop-types';
import DownloadIcon from '@mui/icons-material/Download';
import StarBorderOutlinedIcon from '@mui/icons-material/StarBorderOutlined';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { useMediaQuery } from '@mui/material';
import styles from './FavoriteSection.module.css';
import Color from '../../../themes/colors';
import { InputButtonMain } from '../../UI_V2/Input/Button';
import SidebarItem from '../SidebarItem/SidebarItem';
import ListFavorites from './ListFavorites/ListFavorites';
import { GlobalContext } from '../../../contexts/GlobalStateProvider';
import { actionTypes } from '../../../reducers/reducer';
import { getStoreCollapsibleTeamSidebar, setStoreCollapsibleTeamSidebar } from '../../../utilities/localStorage';

const FavoriteSection = ({
  params,
  handleShowEditTeamFavoriteMenu,
  handleCloseEditTeamFavoriteMenuAndAll,
}) => {
  const [{ user, isFavoriteListEmpty }, dispatch] = useContext(GlobalContext);
  const isMobile = useMediaQuery('(max-width:720px)');
  const initialCollapsibleTeamSidebar = getStoreCollapsibleTeamSidebar({
    userId: user?._id,
    companyId: params?.companyId,
  });
  const [showListFavorites, setShowListFavorites] = useState(
    initialCollapsibleTeamSidebar?.favorite !== undefined
      ? initialCollapsibleTeamSidebar?.favorite : true,
  );

  useEffect(() => {
    setShowListFavorites(initialCollapsibleTeamSidebar?.favorite !== undefined
      ? initialCollapsibleTeamSidebar?.favorite : true);
  }, [JSON.stringify(initialCollapsibleTeamSidebar)]);

  const handleShowListFavorites = () => {
    setShowListFavorites(true);
    setStoreCollapsibleTeamSidebar({
      userId: user?._id,
      companyId: params?.companyId,
      collapsibleTeamSidebar: {
        ...initialCollapsibleTeamSidebar,
        favorite: true,
      },
    });
  };
  const handleHideListFavorites = () => {
    setShowListFavorites(false);
    setStoreCollapsibleTeamSidebar({
      userId: user?._id,
      companyId: params?.companyId,
      collapsibleTeamSidebar: {
        ...initialCollapsibleTeamSidebar,
        favorite: false,
      },
    });
  };

  const handleShowTeamFavoriteMenuAndSelectEditTeamFavorite = (team) => {
    dispatch({
      type: actionTypes.SET_SELECTED_EDIT_TEAM_FAVORITE,
      selectedEditTeamFavorite: team,
    });
    dispatch({
      type: actionTypes.SET_SELECTED_EDIT_TEAM,
      selectedEditTeam: {},
    });
    handleShowEditTeamFavoriteMenu();
  };

  const handleCloseTeamFavoriteMenu = () => {
    dispatch({
      type: actionTypes.SET_SELECTED_EDIT_TEAM_FAVORITE,
      selectedEditTeamFavorite: {},
    });
    dispatch({
      type: actionTypes.SET_SELECTED_EDIT_TEAM,
      selectedEditTeam: {},
    });
    handleCloseEditTeamFavoriteMenuAndAll('editTeamFavoriteMenu');
  };

  return (
    <div className={styles.container}>
      {!isFavoriteListEmpty && (
      <SidebarItem
        text="Favorit"
        isCollapsed={showListFavorites}
        enableCollapsible
        enableIconLeft
        iconLeft={<StarBorderOutlinedIcon />}
        onClickCollapsible={showListFavorites ? handleHideListFavorites : handleShowListFavorites}
      />
      )}
      {showListFavorites && (
        <ListFavorites
          handleSelectAndShowTeamFavoriteMenu={
            (team) => handleShowTeamFavoriteMenuAndSelectEditTeamFavorite(team)
          }
          handleCancelShowTeamFavoriteMenu={handleCloseTeamFavoriteMenu}
          params={params}
        />
      )}
    </div>
  );
};

FavoriteSection.propTypes = {
  params: PropTypes.object,
  handleShowEditTeamFavoriteMenu: PropTypes.func,
  handleCloseEditTeamFavoriteMenuAndAll: PropTypes.func,
};

FavoriteSection.defaultProps = {
  params: { companyId: null, teamId: null },
  handleShowEditTeamFavoriteMenu: () => null,
  handleCloseEditTeamFavoriteMenuAndAll: () => null,
};

export default memo(FavoriteSection);
