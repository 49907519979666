import React, {
  memo, useContext, useEffect, useState,
} from 'react';
import PropTypes from 'prop-types';
import DownloadIcon from '@mui/icons-material/Download';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { Skeleton, useMediaQuery } from '@mui/material';
import { useLocation } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import styles from './SensitiveInfoSection.module.css';
import Color from '../../../themes/colors';
import { DisplayTextBody, DisplayTextSubBody } from '../../../components/UI_V2/Display/Text';
import EmailSection from './EmailSection/EmailSection';
import PasswordSection from './PasswordSection/PasswordSection';
import { GlobalContext } from '../../../contexts/GlobalStateProvider';
import { AuthActions } from '../../../actions';
import handleLoadings from '../../../utilities/handleLoadings';
import handleStatusMsg from '../../../utilities/handleStatusMsg';
import { FeedbackLoadingMain } from '../../../components/UI_V2/Feedback/Loading';

const SensitiveInfoSection = ({
  email,
  isTheSameUser,
  profileUserId,
  handleShowChangePassword,
  handleShowChangeEmail,
  handleShowAddNewPassword,
}) => {
  const [{ user }] = useContext(GlobalContext);
  const location = useLocation();
  const [hasPassword, setHasPassword] = useState(false);
  const [loadings, setLoadings] = useState([]);
  const { enqueueSnackbar } = useSnackbar();

  const checkUserHasPasswordApi = async () => {
    try {
      const startLoadings = handleLoadings('checkUserHasPassword', [...loadings], 'start');
      setLoadings([...startLoadings]);

      const doesHasPassword = await AuthActions.checkUserHasPassword({
        userId: profileUserId,
      });

      setHasPassword(doesHasPassword);
    } catch (error) {
      const status = handleStatusMsg(error, 'error');

      enqueueSnackbar(status.message, {
        variant: 'error',
      });
    } finally {
      const endLoadings = handleLoadings('checkUserHasPassword', [...loadings], 'end');
      setLoadings([...endLoadings]);
    }
  };

  useEffect(() => {
    if (!user?._id) return;
    if (!isTheSameUser) return;

    checkUserHasPasswordApi();
  }, [location]);

  return (
    <div className={styles.container}>
      <div className={styles.title}>
        <DisplayTextBody
          decoration="bold"
        >
          Email & Password
        </DisplayTextBody>
        <DisplayTextSubBody
          color={Color.gray3}
        >
          Semua informasi dibawah hanya tampil untuk kamu dan hanya kamu yang bisa mengubahnya.
        </DisplayTextSubBody>
      </div>
      <div className={styles.boxSection}>
        <EmailSection onClick={handleShowChangeEmail} email={email} />
      </div>
      <FeedbackLoadingMain
        wait="checkUserHasPassword"
        loadings={loadings}
        loadingComponent={(
          <div className={styles.skeletonPassSection}>
            <Skeleton variant="rectangular" height={35} />
          </div>
        )}
      >
        <div className={styles.boxSection}>
          <PasswordSection
            hasPassword={hasPassword}
            onClick={hasPassword ? handleShowChangePassword : handleShowAddNewPassword}
          />
        </div>
      </FeedbackLoadingMain>

    </div>
  );
};

SensitiveInfoSection.propTypes = {
  email: PropTypes.string.isRequired,
  isTheSameUser: PropTypes.bool.isRequired,
  handleShowChangePassword: PropTypes.func.isRequired,
  handleShowChangeEmail: PropTypes.func.isRequired,
  handleShowAddNewPassword: PropTypes.func.isRequired,
  profileUserId: PropTypes.string.isRequired,
};

SensitiveInfoSection.defaultProps = {};

export default memo(SensitiveInfoSection);
