import React, { memo } from 'react';
import PropTypes from 'prop-types';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import AvTimerIcon from '@mui/icons-material/AvTimer';
import styles from './Item.module.css';
import { DisplayAvatarMember } from '../../../../components/UI_V2/Display/Avatar';
import { DisplayTextBody } from '../../../../components/UI_V2/Display/Text';
import Color from '../../../../themes/colors';
import { adjustServerUrl, limitChar } from '../../../../utilities/stringUtil';
import { dateDifferenceInDays, isDateBeforeNow } from '../../../../utilities/dateUtil';

const selectedStyles = {
  true: styles.selected,
  false: styles.notSelected,
};

const BillingPageListCompaniesItem = ({
  isSelected,
  company,
  subscription,
}) => {
  const logoSource = adjustServerUrl(company?.logo);

  let end;
  let plan;
  if (subscription) {
    end = subscription?.end;
    plan = subscription?.plan;
  }

  const subscriptionDayLeft = dateDifferenceInDays(end, new Date()) + 1;

  const isNoSubscription = !subscription?._id;
  const isSubscriptionOver = subscriptionDayLeft < 0 || isDateBeforeNow(end, new Date());
  const isSubscriptionSoon = !isNoSubscription
  && subscriptionDayLeft < 5
  && !isSubscriptionOver;

  return (
    <div className={`${styles.container} ${selectedStyles[isSelected]}`}>
      <div className={styles.companySection}>
        <div className={styles.logo}>
          <DisplayAvatarMember
            src={logoSource}
            shadow={false}
            size="md"
          />
        </div>
        <div className={styles.name}>
          <DisplayTextBody
            mode="12"
            decoration={isSelected ? 'bold' : 'regular'}
            color={Color.black}
            fontFamily="openSans"
          >
            {limitChar(company?.name, 23)}
          </DisplayTextBody>
        </div>
      </div>
      <div className={styles.indicatorSection}>
        {isSubscriptionOver && (
        <ErrorOutlineIcon
          className={styles.expiredIcon}
        />
        )}
        {isSubscriptionSoon && (
        <AvTimerIcon
          className={styles.soonIcon}
        />
        )}
      </div>
    </div>
  );
};

BillingPageListCompaniesItem.propTypes = {
  isSelected: PropTypes.bool,
  subscription: PropTypes.object.isRequired,
  company: PropTypes.object.isRequired,
};

BillingPageListCompaniesItem.defaultProps = {
  isSelected: false,
};

export default memo(BillingPageListCompaniesItem);
