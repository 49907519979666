import React from 'react';
import { useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';
import styles from './FeatureItem.module.css';
import { DisplayTextBody, DisplayTextSubBody } from '../../../components/UI_V2/Display/Text';
import Colors from '../../../themes/colors';
import LinkNoDecor from '../../../components/LinkNoDecor/LinkNoDecor';
import LinkNoDecorV2 from '../../../components/LinkNoDecor/LinkNoDecorV2/LinkNoDecorV2';

const FeatureItem = ({
  clickUrl, text, icon, isOpenNewTab,
}) => {
  const history = useHistory();

  const goToUrl = (url) => {
    history.push(url);
  };
  return (
    <>
      {clickUrl && isOpenNewTab
    && (
    <LinkNoDecorV2 to={clickUrl} isOpenNewTab={isOpenNewTab}>
      <div className={styles.container}>
        <div className={styles.icon}>
          {icon}
        </div>
        <div className={styles.text}>
          <DisplayTextBody
            color={Colors.blueNavy4}
            mode="11"
          >
            {text}
          </DisplayTextBody>
          {/* <DisplayTextSubBody
          color={Colors.yellowAccentCicle}
          mode="9"
        >
          Coming Soon!
        </DisplayTextSubBody> */}
        </div>
      </div>
    </LinkNoDecorV2>
    )}

      {clickUrl && !isOpenNewTab
  && (
  <div onClick={() => goToUrl(clickUrl)} className={styles.container}>
    <div className={styles.icon}>
      {icon}
    </div>
    <div className={styles.text}>
      <DisplayTextBody
        color={Colors.blueNavy4}
        mode="11"
      >
        {text}
      </DisplayTextBody>
      {/* <DisplayTextSubBody
      color={Colors.yellowAccentCicle}
      mode="9"
    >
      Coming Soon!
    </DisplayTextSubBody> */}
    </div>
  </div>
  )}

      {!clickUrl && (
      <div className={styles.container}>
        <div className={styles.icon}>
          {icon}
        </div>
        <div className={styles.text}>
          <DisplayTextBody
            color={Colors.blueNavy4}
            mode="11"
          >
            {text}
          </DisplayTextBody>
          {/* <DisplayTextSubBody
              color={Colors.yellowAccentCicle}
              mode="9"
            >
              Coming Soon!
            </DisplayTextSubBody> */}
        </div>
      </div>
      )}
    </>

  );
};

FeatureItem.propTypes = {
  clickUrl: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
  icon: PropTypes.node.isRequired,
  isOpenNewTab: PropTypes.bool,
};

FeatureItem.defaultProps = {
  isOpenNewTab: false,
};

export default FeatureItem;
