import React from 'react';
import PropTypes from 'prop-types';
import { useMediaQuery } from '@mui/material';
import Colors from '../../../../themes/colors';

const styles = {
  default: {
    fontSize: '28px',
    fontFamily: 'Nunito',
    color: Colors.fontColor,
    margin: 0,
  },
  underline: {
    textDecoration: 'underline',
  },
};

const modeStyles = {
  48: {
    fontSize: '48px',
    lineHeight: '58px',
    fontFamily: 'Nunito',
  },
  36: {
    fontSize: '36px',
    lineHeight: '46px',
    fontFamily: 'Nunito',
  },
  32: {
    fontSize: '32px',
    lineHeight: '42px',
    fontFamily: 'Nunito',
  },
  30: {
    fontSize: '30px',
    lineHeight: '36px',
    fontFamily: 'Nunito',
  },
  28: {
    fontSize: '28px',
    lineHeight: '38px',
    fontFamily: 'Nunito',
  },
  26: {
    fontSize: '26px',
    lineHeight: '35px',
    fontFamily: 'Nunito',
  },
  24: {
    fontSize: '24px',
    lineHeight: '27px',
    fontFamily: 'Nunito',
  },
  20: {
    fontSize: '20px',
    lineHeight: '23px',
    fontFamily: 'Nunito',
  },
  18: {
    fontSize: '18px',
    lineHeight: '22px',
    fontFamily: 'Nunito',
  },
  16: {
    fontSize: '16px',
    lineHeight: '20px',
    fontFamily: 'Nunito',
  },
};

const decorationStyles = {
  extra_bold: { fontWeight: 800 },
  semibold: { fontWeight: 500 },
  regular: { fontWeight: 'normal' },
};

const positionStyles = {
  left: { textAlign: 'left' },
  center: { textAlign: 'center' },
  right: { textAlign: 'right' },
};

const DisplayTextHeadline = (props) => {
  const {
    className,
    children,
    style,
    mode,
    decoration,
    position,
    color,
    underline,
  } = props;

  const colorStyle = color && { color };
  const underlineStyle = underline && styles.underline;

  const isTablet = useMediaQuery('(max-width:1024px)');
  const isMobile = useMediaQuery('(max-width:720px)');

  const tabletModeStyles = isTablet && modeStyles[28];
  const mobileModeStyles = isMobile && modeStyles[24];

  return (
    <h1
      className={className}
      style={
        {
          ...styles.default,
          ...modeStyles[mode],
          ...decorationStyles[decoration],
          ...positionStyles[position],
          ...colorStyle,
          ...style,
          ...underlineStyle,
          ...tabletModeStyles,
          ...mobileModeStyles,
        }
    }
    >
      {children}
    </h1>
  );
};

DisplayTextHeadline.propTypes = {
  decoration: PropTypes.oneOf(['extra_bold', 'semibold', 'regular']),
  position: PropTypes.oneOf(['left', 'center', 'right']),
  mode: PropTypes.string,
  className: PropTypes.string,
  children: PropTypes.node.isRequired,
  style: PropTypes.object,
  color: PropTypes.string,
  underline: PropTypes.bool,
};

DisplayTextHeadline.defaultProps = {
  decoration: 'regular',
  position: 'left',
  mode: '28',
  className: '',
  style: {},
  color: '',
  underline: false,
};

export default DisplayTextHeadline;
