import React, {
  useState, useContext, useEffect, useCallback,
} from 'react';
import { useHistory, useParams, useLocation } from 'react-router-dom';
import AddIcon from '@mui/icons-material/Add';
import { useSnackbar } from 'notistack';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import FormatListBulletedIcon from '@mui/icons-material/FormatListBulleted';
import FilterAltOutlinedIcon from '@mui/icons-material/FilterAltOutlined';
import { Fab, Tooltip } from '@mui/material';
import ScrollContainer from 'react-indiana-drag-scroll';
import { GlobalContext } from '../../contexts/GlobalStateProvider';
import styles from './MyTaskCardListAllPage.module.css';
import Title from '../../components/Title/Title';
import {
  BoardActions,
  SearchJumpToActions,
  TeamActions,
} from '../../actions';
import { PageConstants } from '../../constants';
import GeneralSubNavBar from '../../components/GeneralSubNavBar/GeneralSubNavBar';
import GlobalActionButton from '../../components/GeneralSubNavBar/BottomNavBar/GlobalActionButton/GlobalActionButton';
import { generateRoutesAndTitle } from '../../actions/BreadCrumbActions';
import CardListHeader from '../../components/CardList/CardListHeader/CardListHeader';
import ListCards from './ListCards/ListCards';
import { useDelayShowHideHandler } from '../../hooks/HelperHooks';
import ModalCreateCardWithTeamListSelection from '../../components/Container/ModalCreateCardWithTeamListSelection/ModalCreateCardWithTeamListSelection';
import VerticalSeparator from '../../components/GeneralSubNavBar/BottomNavBar/VerticalSeparator/VerticalSeparator';
import { getStoreMyTaskCardView, setStoreFilterMyTaskCard, setStoreMyTaskCardView } from '../../utilities/localStorage';
import { getSessionMyTaskCardView, setSessionMyTaskCardView } from '../../utilities/sessionStorage';
import LinkNoDecor from '../../components/LinkNoDecor/LinkNoDecor';

const containerWithSidebarStyles = {
  true: `${styles.container} ${styles.sidebarOpen}`,
  false: styles.container,
};

function MyTaskCardListAllPage() {
  const [{ user, currentCompany, isSidebarOpen }, dispatch] = useContext(GlobalContext);
  /* Show/Hide Filters */
  const [showFilters, handleShowFilters, handleHideFilters] = useDelayShowHideHandler();
  const [isFilterActive,
    handleShowFilterActive, handleHideFilterActive] = useDelayShowHideHandler();
  const [showCreateMyTaskCard,
    handleShowCreateMyTaskCard, handleHideCreateMyTaskCard] = useDelayShowHideHandler();
  const [isOverflow, setIsOverflow] = useState(false);

  const history = useHistory();
  const params = useParams();
  const { companyId } = params;

  let initialMyTaskCardView;

  initialMyTaskCardView = getSessionMyTaskCardView(
    { companyId, userId: user?._id },
  );

  if (!initialMyTaskCardView?.mainValue) {
    initialMyTaskCardView = getStoreMyTaskCardView(
      { companyId, userId: user?._id },
    );
  }

  const myTaskCardViewInitialCalendarViewValue = initialMyTaskCardView?.initialCalendarViewValue || 'dayGridMonth';

  useEffect(() => {
    if (user?._id) {
      setStoreMyTaskCardView({
        companyId,
        userId: user._id,
        view: {
          initialCalendarViewValue: myTaskCardViewInitialCalendarViewValue,
          mainValue: 'listAll',
        },
      });
      setSessionMyTaskCardView({
        companyId,
        userId: user._id,
        view: {
          initialCalendarViewValue: myTaskCardViewInitialCalendarViewValue,
          mainValue: 'listAll',
        },
      });
    }

    return () => {
    //   // on exit page, do these :
    // // clear current board, boardLists, board labels
    //   BoardActions.resetBoardAndLists({}, dispatch);
    //   // clear team and team members
    //   TeamActions.resetTeamAndTeamMembers({}, dispatch);
    };
  }, [user]);

  const pageType = PageConstants.pageType.myTasksList;
  const breadcrumb = generateRoutesAndTitle({ type: pageType, data: { companyId } });
  SearchJumpToActions.saveRecentPage({
    company: currentCompany,
    userId: user?._id,
    itemId: `${companyId}-myTasksList`,
    url: breadcrumb.url,
    routes: breadcrumb.routes,
    name: breadcrumb.title,
    type: pageType,
  });

  return (
    <>
      <Title title="Semua Tugas : List" />
      <GeneralSubNavBar
        breadcrumbRoutes={breadcrumb.routes}
        pageTitle={breadcrumb.title}
        bottomBarOverviewOptionId="myTasksList"
        withMyTaskCardMiddleNavBar
        withMiddleNavBar={false}
        bottomBarGlobalActions={(
          <>
            <GlobalActionButton handleClick={handleShowCreateMyTaskCard} text="Buat Tugas" icon={<AddIcon />} />
            <GlobalActionButton
              handleClick={handleShowFilters}
              buttonMode="outlined"
              text="Filter"
              icon={<FilterAltOutlinedIcon />}
            >
              {isFilterActive ? (
                <Tooltip
                  title="Filter-mu aktif. Atur atau hapus filter kalo kamu ga menemukan yang kamu cari."
                >
                  <div className={styles.activeFilterIcon}><CheckCircleIcon /></div>
                </Tooltip>
              ) : null}
            </GlobalActionButton>
          </>
        )}
        // bottomBarOtherActions={(
        //   <>
        //     <GlobalActionButton
        //       handleClick={handleShowFilters}
        //       buttonMode="outlined"
        //       text="Filter"
        //       icon={<FilterAltOutlinedIcon />}
        //     >
        //       {isFilterActive ? (
        //         <Tooltip
        //           title="Filter-mu aktif. Atur
        // atau hapus filter kalo kamu ga menemukan yang kamu cari."
        //         >
        //           <div className={styles.activeFilterIcon}><CheckCircleIcon /></div>
        //         </Tooltip>
        //       ) : null}
        //     </GlobalActionButton>
        //   </>
        // )}
      />
      <ScrollContainer className={styles.scrollList}>
        <div className={containerWithSidebarStyles[isSidebarOpen]}>
          <div
            className={styles.header}
            style={{
              paddingRight: isOverflow ? '6px' : '0px',
            }}
          >
            <CardListHeader />
          </div>
          <div className={styles.body} id="listMyTaskCardList">
            <ListCards
              showFilters={showFilters}
              handleHideFilters={handleHideFilters}
              handleShowFilterActive={handleShowFilterActive}
              handleHideFilterActive={handleHideFilterActive}
              handleSetIsOverflow={setIsOverflow}
            />
          </div>
          <ModalCreateCardWithTeamListSelection
            open={showCreateMyTaskCard}
            onClose={handleHideCreateMyTaskCard}
          />
        </div>
      </ScrollContainer>
      {/* <div className={styles.fab}>
        <Fab onClick={handleShowCreateMyTaskCard} color="primary" aria-label="add">
          <AddIcon />
        </Fab>
      </div> */}
    </>
  );
}

export default MyTaskCardListAllPage;
