import React from 'react';
import PropTypes from 'prop-types';
import { DisplayTextBody, DisplayTextSubHeadline } from '../../../components/UI_V2/Display/Text';
import styles from './IntroText.module.css';
import Color from '../../../themes/colors';
import LinkNoDecor from '../../../components/LinkNoDecor/LinkNoDecor';
import { moduleTitleByCommentType } from '../../../constants/CommentConstants';

const CommentDiscussPageIntroText = ({
  currentComment, type, currentTeam, introTextUrl,
}) => (
  <div className={styles.container}>
    <div className={styles.marginRight}>
      <DisplayTextSubHeadline
        mode="16"
        decoration="bold"
        color={Color.blueNavy4}
      >
        {currentComment?.creator?.fullName}
      </DisplayTextSubHeadline>
    </div>
    <div className={styles.marginRight}>
      <DisplayTextBody
        fontFamily="openSans"
      >
        berkomentar ke
        {' '}
      </DisplayTextBody>
    </div>
    <div className={`${styles.marginRight} ${styles.link}`}>
      <LinkNoDecor to={introTextUrl.parentUrl}>
        <DisplayTextSubHeadline
          mode="16"
          decoration="bold"
          color={Color.blueNavy4}
        >
          {/* {type?.comment} */}
          {moduleTitleByCommentType[type?.comment]}
          {' '}
        </DisplayTextSubHeadline>
      </LinkNoDecor>
    </div>
    <div className={styles.marginRight}>
      <DisplayTextBody
        fontFamily="openSans"
      >
        di
        {' '}
      </DisplayTextBody>
    </div>
    <div className={`${styles.marginRight} ${styles.link}`}>
      <LinkNoDecor to={introTextUrl.teamUrl}>
        <DisplayTextSubHeadline
          mode="16"
          decoration="bold"
          color={Color.blueNavy4}
        >
          {currentTeam?.name}
        </DisplayTextSubHeadline>
      </LinkNoDecor>
    </div>
    {introTextUrl.myTaskUrl && (
    <div className={`${styles.marginRight} ${styles.link}`}>
      <LinkNoDecor to={introTextUrl.myTaskUrl}>
        <DisplayTextSubHeadline
          mode="16"
          decoration="bold"
          color={Color.blueNavy4}
        >
          (Semua Tugas)
        </DisplayTextSubHeadline>
      </LinkNoDecor>
    </div>
    )}
  </div>
);

CommentDiscussPageIntroText.propTypes = {
  currentComment: PropTypes.object.isRequired,
  currentTeam: PropTypes.object.isRequired,
  type: PropTypes.string.isRequired,
  introTextUrl: PropTypes.object.isRequired,
};

export default CommentDiscussPageIntroText;
