import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { Box } from '@mui/material';
import styles from './SearchFilterSection.module.css';
import { useSearch } from '../../../../hooks/HelperHooks';
import { InputSearchGeneral } from '../../../UI_V2/Input/Search';
import { DisplayTextBody } from '../../../UI_V2/Display/Text';

const SearchFilterSection = ({ searchHook }) => {
  const {
    searchResult,
    keyword,
    keywordArray,
    setKeyword,
    isTyping,
    onChange,
    resetSearch,
  } = searchHook;
  return (
    <Box className={styles.container}>
      <Box className={styles.desc}>
        <DisplayTextBody>
          Filter Judul
        </DisplayTextBody>
      </Box>
      <Box className={styles.searchBox}>
        <InputSearchGeneral
          onChange={onChange}
          value={keyword}
          placeholder="Cari judul..."
          onClickCloseIcon={resetSearch}
        />
      </Box>
    </Box>
  );
};

SearchFilterSection.propTypes = {
  // listData: PropTypes.array,
  searchHook: PropTypes.object.isRequired,
};

// SearchFilterSection.defaultProps = {
//   listData: [],
// };

export default memo(SearchFilterSection);
