import React, { memo } from 'react';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import PropTypes from 'prop-types';
import SeparatorLine from '../../../components/UI/SeparatorLine/SeparatorLine';
import styles from './BoxMenu.module.css';
import { DisplayTextHeadline, DisplayTextSubHeadline } from '../../../components/UI_V2/Display/Text';

const BoxMenu = ({
  clicked, title, hasItems, children, imageSrc, subtitle,
}) => (
  <>
    {hasItems
      ? (
        <div className={styles.container} onClick={clicked}>
          <div className={styles.headline}>
            <DisplayTextSubHeadline
              mode="16"
              decoration="bold"
              position="center"
            >
              {title}
            </DisplayTextSubHeadline>
          </div>
          <div className={styles.body}>
            {children}
          </div>
        </div>
      )
      : (
        <div className={styles.container} onClick={clicked}>
          <div className={styles.icon}>
            <LazyLoadImage src={imageSrc} />
          </div>
          <div className={styles.title}>
            <DisplayTextSubHeadline
              mode="16"
              decoration="bold"
              position="center"
            >
              {title}
            </DisplayTextSubHeadline>
          </div>
        </div>
      )}
  </>
);

BoxMenu.propTypes = {
  clicked: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
  hasItems: PropTypes.bool.isRequired,
  children: PropTypes.node.isRequired,
  imageSrc: PropTypes.string.isRequired,
  subtitle: PropTypes.string.isRequired,
};

export default memo(BoxMenu);
