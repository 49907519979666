import React from 'react';
import ChatBubbleOutlineOutlinedIcon from '@mui/icons-material/ChatBubbleOutlineOutlined';
import GroupsOutlinedIcon from '@mui/icons-material/GroupsOutlined';
import AttachFileOutlinedIcon from '@mui/icons-material/AttachFileOutlined';
import PersonAddAltOutlinedIcon from '@mui/icons-material/PersonAddAltOutlined';
import HomeOutlinedIcon from '@mui/icons-material/HomeOutlined';
import CelebrationOutlinedIcon from '@mui/icons-material/CelebrationOutlined';
import AssessmentOutlinedIcon from '@mui/icons-material/AssessmentOutlined';
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined';
import AssignmentOutlinedIcon from '@mui/icons-material/AssignmentOutlined';
import AccountCircleOutlinedIcon from '@mui/icons-material/AccountCircleOutlined';
import CampaignOutlinedIcon from '@mui/icons-material/CampaignOutlined';
import HelpOutlineOutlinedIcon from '@mui/icons-material/HelpOutlineOutlined';
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import MessageOutlinedIcon from '@mui/icons-material/MessageOutlined';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import FormatListBulletedIcon from '@mui/icons-material/FormatListBulleted';
import NotificationsOutlinedIcon from '@mui/icons-material/NotificationsOutlined';
import ArticleOutlinedIcon from '@mui/icons-material/ArticleOutlined';
import ForumOutlinedIcon from '@mui/icons-material/ForumOutlined';
import SettingsApplicationsOutlinedIcon from '@mui/icons-material/SettingsApplicationsOutlined';
import ViewKanbanIcon from '@mui/icons-material/ViewKanban';
import CalendarMonthOutlinedIcon from '@mui/icons-material/CalendarMonthOutlined';
import SnippetFolderOutlinedIcon from '@mui/icons-material/SnippetFolderOutlined';
import BusinessCenterOutlinedIcon from '@mui/icons-material/BusinessCenterOutlined';
import StickyNote2OutlinedIcon from '@mui/icons-material/StickyNote2Outlined';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';

const pageType = {
  companyModuleSearch: 'companyModuleSearch', // only for search.
  home: 'home',
  companyMembers: 'companyMembers',
  companySettings: 'companySettings',
  teams: 'teams',
  teamsSettings: 'teamsSettings',
  cheers: 'cheers',
  profiles: 'profiles',
  notifications: 'notifications',
  chats: 'chats',
  groupChats: 'groupChats',
  blasts: 'blasts',
  blastsCreate: 'blastsCreate',
  posts: 'posts',
  postsEdit: 'postsEdit',
  postsComment: 'postsComment',
  checkIns: 'checkIns',
  checkInsCreate: 'checkInsCreate',
  questions: 'questions',
  questionsEdit: 'questionsEdit',
  questionsComment: 'questionsComment',
  schedules: 'schedules',
  schedulesPublic: 'schedulesPublic',
  schedulesCreate: 'schedulesCreate',
  events: 'events',
  eventsEdit: 'eventsEdit',
  eventsComment: 'eventsComment',
  occurrences: 'occurrences',
  occurrencesEdit: 'occurrencesEdit',
  occurrencesComment: 'occurrencesComment',
  boards: 'boards',
  boardsList: 'boardsList',
  boardsCalendar: 'boardsCalendar',
  cards: 'cards',
  cardsComment: 'cardsComment',
  myTasksListCardsComment: 'myTasksListCardsComment',
  myTasksCalendarCardsComment: 'myTasksCalendarCardsComment',
  buckets: 'buckets',
  bucketsEdit: 'bucketsEdit',
  docs: 'docs',
  docsCreate: 'docsCreate',
  docsEdit: 'docsEdit',
  docsComment: 'docsComment',
  files: 'files',
  filesEdit: 'filesEdit',
  filesComment: 'filesComment',
  myTasksList: 'myTasksList',
  myTasksCalendar: 'myTasksCalendar',
  billings: 'billings',
  reports: 'reports',
};

const pageProperties = {
  home: {
    defaultTitle: 'Beranda',
    limitSearchMore: 4,
    icon: <HomeOutlinedIcon />,
  },
  companyMembers: {
    defaultTitle: 'Anggota Perusahaan',
    limitSearchMore: 4,
    icon: <PersonAddAltOutlinedIcon />,
  },
  companySettings: {
    defaultTitle: 'Pengaturan Perusahaan',
    limitSearchMore: 4,
    icon: <SettingsApplicationsOutlinedIcon />,
  },
  teams: {
    defaultTitle: 'Ringkasan Tim',
    limitSearchMore: 4,
    icon: <GroupsOutlinedIcon />,
  },
  teamsSettings: {
    defaultTitle: 'Pengaturan Tim',
    limitSearchMore: 4,
    icon: <SettingsOutlinedIcon />,
  },
  cheers: {
    defaultTitle: 'Ringkasan Cheers',
    limitSearchMore: 4,
    icon: <CelebrationOutlinedIcon />,
  },
  profiles: {
    defaultTitle: 'Profil Pengguna',
    limitSearchMore: 4,
    icon: <AccountCircleOutlinedIcon />,
  },
  notifications: {
    defaultTitle: 'Semua Notifikasi',
    limitSearchMore: 4,
    icon: <NotificationsOutlinedIcon />,
  },
  chats: {
    defaultTitle: 'Chat Pribadi',
    limitSearchMore: 4,
    icon: <ChatBubbleOutlineOutlinedIcon />,
  },
  groupChats: {
    defaultTitle: 'Chat Grup',
    limitSearchMore: 4,
    icon: <ForumOutlinedIcon />,
  },
  blasts: {
    defaultTitle: 'List Pengumuman',
    limitSearchMore: 4,
    icon: <MessageOutlinedIcon />,
  },
  blastsCreate: {
    defaultTitle: 'Membuat Pengumuman',
    limitSearchMore: 4,
    icon: <CampaignOutlinedIcon />,
  },
  posts: {
    defaultTitle: 'Pengumuman',
    limitSearchMore: 4,
    icon: <CampaignOutlinedIcon />,
  },
  postsEdit: {
    defaultTitle: '[UBAH] Pengumuman',
    limitSearchMore: 4,
    icon: <CampaignOutlinedIcon />,
  },
  postsComment: {
    defaultTitle: '[KOMENTAR] Pengumuman',
    limitSearchMore: 4,
    icon: <CampaignOutlinedIcon />,
  },
  checkIns: {
    defaultTitle: 'List Pertanyaan',
    limitSearchMore: 4,
    icon: <BusinessCenterOutlinedIcon />,
  },
  checkInsCreate: {
    defaultTitle: 'Membuat Pertanyaan',
    limitSearchMore: 4,
    icon: <HelpOutlineOutlinedIcon />,
  },
  questions: {
    defaultTitle: 'Pertanyaan',
    limitSearchMore: 4,
    icon: <HelpOutlineOutlinedIcon />,
  },
  questionsEdit: {
    defaultTitle: '[UBAH] Pertanyaan',
    limitSearchMore: 4,
    icon: <HelpOutlineOutlinedIcon />,
  },
  questionsComment: {
    defaultTitle: '[KOMENTAR] Pertanyaan',
    limitSearchMore: 4,
    icon: <HelpOutlineOutlinedIcon />,
  },
  schedules: {
    defaultTitle: 'Kalender',
    limitSearchMore: 4,
    icon: <CalendarMonthOutlinedIcon />,
  },
  schedulesPublic: {
    defaultTitle: 'Terapkan Kalender Publik',
    limitSearchMore: 4,
    icon: <CalendarMonthOutlinedIcon />,
  },
  schedulesCreate: {
    defaultTitle: 'Membuat Jadwal',
    limitSearchMore: 4,
    icon: <CalendarTodayIcon />,
  },
  events: {
    defaultTitle: 'Jadwal',
    limitSearchMore: 4,
    icon: <CalendarTodayIcon />,
  },
  eventsEdit: {
    defaultTitle: '[UBAH] Jadwal',
    limitSearchMore: 4,
    icon: <CalendarTodayIcon />,
  },
  eventsComment: {
    defaultTitle: '[KOMENTAR] Jadwal',
    limitSearchMore: 4,
    icon: <CalendarTodayIcon />,
  },
  occurrences: {
    defaultTitle: 'Jadwal Berulang',
    limitSearchMore: 4,
    icon: <CalendarTodayIcon />,
  },
  occurrencesEdit: {
    defaultTitle: '[UBAH] Jadwal Berulang',
    limitSearchMore: 4,
    icon: <CalendarTodayIcon />,
  },
  occurrencesComment: {
    defaultTitle: '[KOMENTAR] Jadwal Berulang',
    limitSearchMore: 4,
    icon: <CalendarTodayIcon />,
  },
  boards: {
    defaultTitle: 'List Tugas',
    limitSearchMore: 4,
    icon: <ViewKanbanIcon />,
  },
  boardsList: {
    defaultTitle: 'List Tugas - List',
    limitSearchMore: 4,
    icon: <FormatListBulletedIcon />,
  },
  boardsCalendar: {
    defaultTitle: 'List Tugas - Kalender',
    limitSearchMore: 4,
    icon: <CalendarMonthIcon />,
  },
  cards: {
    defaultTitle: 'Tugas',
    limitSearchMore: 4,
    icon: <StickyNote2OutlinedIcon />,
  },
  cardsComment: {
    defaultTitle: '[KOMENTAR] Tugas',
    limitSearchMore: 4,
    icon: <AssignmentOutlinedIcon />,
  },
  myTasksListCardsComment: {
    defaultTitle: '[KOMENTAR] Tugas',
    limitSearchMore: 4,
    icon: <AssignmentOutlinedIcon />,
  },
  myTasksCalendarCardsComment: {
    defaultTitle: '[KOMENTAR] Tugas',
    limitSearchMore: 4,
    icon: <AssignmentOutlinedIcon />,
  },
  buckets: {
    defaultTitle: 'Dokumen & File',
    limitSearchMore: 4,
    icon: <SnippetFolderOutlinedIcon />,
  },
  bucketsEdit: {
    defaultTitle: '[UBAH] Folder',
    limitSearchMore: 4,
    icon: <SnippetFolderOutlinedIcon />,
  },
  docs: {
    defaultTitle: 'Dokumen',
    limitSearchMore: 4,
    icon: <ArticleOutlinedIcon />,
  },
  docsCreate: {
    defaultTitle: 'Membuat Dokumen',
    limitSearchMore: 4,
    icon: <ArticleOutlinedIcon />,
  },
  docsEdit: {
    defaultTitle: '[UBAH] Dokumen',
    limitSearchMore: 4,
    icon: <ArticleOutlinedIcon />,
  },
  docsComment: {
    defaultTitle: '[KOMENTAR] Dokumen',
    limitSearchMore: 4,
    icon: <ArticleOutlinedIcon />,
  },
  files: {
    defaultTitle: 'File',
    limitSearchMore: 4,
    icon: <AttachFileOutlinedIcon />,
  },
  filesEdit: {
    defaultTitle: '[UBAH] File',
    limitSearchMore: 4,
    icon: <AttachFileOutlinedIcon />,
  },
  filesComment: {
    defaultTitle: '[KOMENTAR] File',
    limitSearchMore: 4,
    icon: <AttachFileOutlinedIcon />,
  },
  myTasksList: {
    defaultTitle: 'Semua Tugas - List',
    limitSearchMore: 4,
    icon: <FormatListBulletedIcon />,
  },
  myTasksCalendar: {
    defaultTitle: 'Semua Tugas - Kalender',
    limitSearchMore: 4,
    icon: <CalendarMonthIcon />,
  },
  billings: {
    defaultTitle: 'Tagihan',
    limitSearchMore: 4,
    icon: <AttachMoneyIcon />,
  },
  reports: {
    defaultTitle: 'Laporan',
    limitSearchMore: 4,
    icon: <AssessmentOutlinedIcon />,
  },
};

export {
  pageProperties,
  pageType,
};
