import React, { useEffect, memo } from 'react';
import { useHistory } from 'react-router-dom';
import _ from 'lodash';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { useMediaQuery } from '@mui/material';
import styles from './WelcomePage.module.css';
import Title from '../../components/Title/Title';
import LogoHeader from '../../components/UI/LogoHeader/LogoHeader';
import ActionBox from './ActionBox/ActionBox';
import createCompanyImage from '../../assets/WelcomePage/create-company.png';
import linkInvitationImage from '../../assets/WelcomePage/link-invitation.png';
import { DisplayTextHeadline, DisplayTextSubHeadline } from '../../components/UI_V2/Display/Text';
import Color from '../../themes/colors';
import LinkNoDecor from '../../components/LinkNoDecor/LinkNoDecor';
import { elementId } from '../../constants/ElementIdConstants';

const WelcomePage = () => {
  const isTablet = useMediaQuery('(max-width:1024px)');
  const isMobile = useMediaQuery('(max-width:720px)');
  return (
    <>
      <Title title="Selamat Bergabung di Cicle!" />
      <LogoHeader showLogout />
      <div className={styles.container}>
        <div className={styles.title}>
          <DisplayTextHeadline
            color={Color.blueNavyCicle}
            mode="48"
            decoration="bold"
            position={isMobile && 'center'}
          >
            Halo, Selamat Bergabung di Cicle!
          </DisplayTextHeadline>
        </div>
        <div className={styles.text}>
          <DisplayTextSubHeadline
            color={Color.gray3}
            mode="18"
            position={isMobile && 'center'}
          >
            Yuk pilih salah satu langkah berikut untuk memulai.
          </DisplayTextSubHeadline>
        </div>
        <div className={styles.action}>
          <div id={elementId.welcome_createCompany_card} className={styles.actionBox}>
            <LinkNoDecor to="/welcome/create-company">
              <ActionBox
                imageUrl={createCompanyImage}
                title="Buat Perusahaan Saya Sendiri"
              />
            </LinkNoDecor>
          </div>
          <div className={styles.separator}>
            <DisplayTextSubHeadline
              mode="48"
              decoration="bold"
              color={Color.gray3}
            >
              Atau
            </DisplayTextSubHeadline>
          </div>
          <div id={elementId.welcome_linkInvitation_card} className={styles.actionBox}>
            <LinkNoDecor to="/welcome/link-invitation">
              <ActionBox
                imageUrl={linkInvitationImage}
                title="Masukkan Link Undangan untuk masuk ke Perusahaan Bos Saya"
              />
            </LinkNoDecor>
          </div>
        </div>
      </div>
    </>
  );
};

export default memo(WelcomePage);
