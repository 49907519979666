const administrators = [
  process.env.REACT_APP_AUTHORIZED_FRONTEND_ADMINISTRATOR_1,
  process.env.REACT_APP_AUTHORIZED_FRONTEND_ADMINISTRATOR_2,
  process.env.REACT_APP_AUTHORIZED_FRONTEND_ADMINISTRATOR_3,
];

const limitMetricCompanies = 10;

export {
  administrators,
  limitMetricCompanies,
};
