import React, { memo, useState } from 'react';
import PropTypes from 'prop-types';
import VisibilityIcon from '@mui/icons-material/Visibility';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { useMediaQuery } from '@mui/material';
import styles from './SeenInfo.module.css';
import { DisplayTextBody, DisplayTextSubBody } from '../../../UI_V2/Display/Text';
import Color from '../../../../themes/colors';

const variantTextColorStyles = {
  black: Color.gray3,
  grey: Color.gray4,
};

const variantIconStyles = {
  black: styles.blackIcon,
  grey: styles.greyIcon,
};

const SeenInfo = ({
  count,
  variant,
  onClick,
  shouldHideTextWhenMobile,
}) => {
  const isTablet = useMediaQuery('(max-width:1024px)');
  const isMobile = useMediaQuery('(max-width:720px)');
  return (
    <>
      {count > 0 && (
      <div onClick={onClick} className={onClick ? styles.containerClick : styles.container}>
        <div className={variantIconStyles[variant]}>
          <VisibilityIcon />
        </div>
        <DisplayTextSubBody
          color={variantTextColorStyles[variant]}
        >
          {!shouldHideTextWhenMobile && `Dibaca ${count}`}
          {shouldHideTextWhenMobile && !isMobile && `Dibaca ${count}`}
        </DisplayTextSubBody>
      </div>
      )}
    </>
  );
};

SeenInfo.propTypes = {
  count: PropTypes.number,
  variant: PropTypes.oneOf(['black', 'grey']),
  onClick: PropTypes.func,
  shouldHideTextWhenMobile: PropTypes.bool,
};

SeenInfo.defaultProps = {
  count: 0,
  onClick: null,
  variant: 'grey',
  shouldHideTextWhenMobile: false,
};

export default memo(SeenInfo);
