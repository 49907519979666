import React, {
  memo,
} from 'react';
import PropTypes from 'prop-types';
import { Box } from '@mui/material';
import { DisplayTextSubHeadline } from '../../../../components/UI_V2/Display/Text';
import TitleJumpTo from './TitleJumpTo/TitleJumpTo';
import ListItemJumpTo from './ListItemJumpTo/ListItemJumpTo';
import styles from './JumpTo.module.css';
import ListRecentViewPages from './ListRecentViewPages/ListRecentViewPages';
import Skeleton from '../Skeleton/Skeleton';

const JumpTo = ({
  listRecentViewPages, listTeams, onClose, selectedIndex, params,
  isTyping,
}) => (
  <Box>
    <Box
      sx={{
        marginBottom: '10px',
      }}
    >
      <DisplayTextSubHeadline
        decoration="bold"
        mode="16"
      >
        Lompat Halaman
      </DisplayTextSubHeadline>
    </Box>
    {isTyping && <Skeleton />}
    {!isTyping && (
    <div
      className={styles.content}
    >
      <Box
        className={styles.recentSection}
      >
        <Box>
          <TitleJumpTo>
            Terakhir Dibuka
          </TitleJumpTo>
          <ListRecentViewPages
            params={params}
            list={listRecentViewPages}
            onClose={onClose}
          />
        </Box>
      </Box>
      <Box
        className={styles.hqteamSection}
      >
        {/* <Box>
          <TitleJumpTo>
            HQ
          </TitleJumpTo>
          <ListItemJumpTo
            params={params}
            list={listTeams}
            onClose={onClose}
            type="hq"
          />
        </Box> */}
        <TitleJumpTo>
          Tim
        </TitleJumpTo>
        <ListItemJumpTo
          params={params}
          list={listTeams}
          onClose={onClose}
        />
      </Box>
      {/* <Box
        className={styles.projectSection}
      >
        <Box>
          <TitleJumpTo>
            Proyek
          </TitleJumpTo>
          <ListItemJumpTo
            params={params}
            list={listTeams}
            onClose={onClose}
            type="project"
          />
        </Box>
      </Box> */}
    </div>
    )}
  </Box>
);

JumpTo.propTypes = {
  onClose: PropTypes.func.isRequired,
  selectedIndex: PropTypes.number.isRequired,
  listTeams: PropTypes.array.isRequired,
  listRecentViewPages: PropTypes.array.isRequired,
  params: PropTypes.object,
  isTyping: PropTypes.bool,
};

JumpTo.defaultProps = {
  params: { companyId: null, teamId: null },
  isTyping: false,
};

export default memo(JumpTo);
