import React, { memo } from 'react';
import PropTypes from 'prop-types';
import styles from './SideBarChatActionIconContainer.module.css';

const SideBarChatActionIconContainer = ({ children, handleClick }) => (
  <div className={styles.container} onClick={() => handleClick()}>
    {children}
  </div>
);

SideBarChatActionIconContainer.propTypes = {
  children: PropTypes.node,
  handleClick: PropTypes.func,
};

SideBarChatActionIconContainer.defaultProps = {
  children: <></>,
  handleClick: () => null,
};

export default memo(SideBarChatActionIconContainer);
