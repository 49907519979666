import React, {
  useState, useEffect, memo, useCallback,
} from 'react';
import { useParams } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import Switch from '@mui/material/Switch';
import Form from 'react-bootstrap/Form';
import { isEqual } from 'lodash';
import CloseIcon from '@mui/icons-material/Close';
import PropTypes from 'prop-types';
import Button from '../../../components/UI/Button/Button';
import styles from './CreateCardForm.module.css';
import handleStatusMsg from '../../../utilities/handleStatusMsg';
import handleLoadings from '../../../utilities/handleLoadings';
import apiUtil from '../../../utilities/apiUtil';
import { ApiConstants } from '../../../constants';
import { CardActions } from '../../../actions';

const CreateCardForm = ({ list, handleShowAddCard }) => {
  const { enqueueSnackbar } = useSnackbar();
  const [loadings, setLoadings] = useState([]);
  const [newCard, setNewCard] = useState('');
  const [postNewCard, setPostNewCard] = useState();
  const [listUpdated, setListUpdated] = useState();
  const [isAccessPrivate, setAccessPrivate] = useState(false);

  const params = useParams();
  const { companyId, teamId } = params;

  const handleCancel = () => {
    handleShowAddCard('');
  };

  useEffect(() => {
    if (postNewCard !== undefined) {
      const postApiCard = async () => {
        const { boardId } = params;
        const listId = listUpdated;
        const cardName = postNewCard.value;
        try {
          const startLoadings = handleLoadings('createCard', [...loadings], 'start');
          setLoadings([...startLoadings]);

          const result = await CardActions.createCard({
            boardId,
            listId,
            companyId,
            teamId,
            cardName,
            isAccessPrivate,
          });

          const status = handleStatusMsg(result, 'success');

          enqueueSnackbar(status.message, {
            variant: 'success',
          });

          handleCancel();
        } catch (err) {
          const status = handleStatusMsg(err, 'error');

          enqueueSnackbar(status.message, {
            variant: 'error',
          });
        } finally {
          const endLoadings = handleLoadings('createCard', [...loadings], 'end');
          setLoadings([...endLoadings]);
        }
      };
      postApiCard();
    }
  }, [postNewCard, isAccessPrivate]);

  const handleChanged = (event) => {
    const { value } = event.target;

    setNewCard((prevValue) => ({
      ...prevValue,
      value,
    }));
  };

  const handlePostNewCard = (listId) => {
    if (newCard === undefined || newCard.value === undefined || newCard.value.length < 1) {
      enqueueSnackbar('Card name cannot be empty', {
        variant: 'error',
      });
      return;
    }

    setPostNewCard(newCard);
    setListUpdated(listId);
  };

  const handleChangeAccess = useCallback((event) => {
    // event.preventDefault();
    setAccessPrivate(event?.target?.checked);
  }, []);

  return (
    <div className={styles.container}>
      <div className={styles.formSection}>
        <Form onSubmit={(event) => {
          event.preventDefault();
          handlePostNewCard(list._id);
        }}
        >
          <Form.Control autoFocus onChange={(event) => handleChanged(event)} placeholder="Nama tugas" />
        </Form>
      </div>

      <div className={styles.togglePrivateCard}>
        <Switch
          color="primary"
          size="small"
          onChange={handleChangeAccess}
        />
        <h1>Tugas Rahasia</h1>
      </div>

      <div className={styles.actionSection}>
        <Button handleClick={() => handlePostNewCard(list._id)} variant="success" size="sm" wait="createCard" loadings={loadings}>Buat Tugas</Button>
        <CloseIcon onClick={handleCancel} />
      </div>
    </div>
  );
};

CreateCardForm.propTypes = {
  list: PropTypes.object.isRequired,
  handleShowAddCard: PropTypes.func.isRequired,
};

const isComponentDataEqual = (prevProps, nextProps) => {
  const { list } = nextProps;
  return isEqual(prevProps.list, list);
};

export default memo(CreateCardForm, isComponentDataEqual);
