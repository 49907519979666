import React, { memo, useState } from 'react';
import PropTypes from 'prop-types';
import DownloadIcon from '@mui/icons-material/Download';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { Alert, useMediaQuery } from '@mui/material';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import { useParams } from 'react-router-dom';
import styles from './ChangePasswordContainer.module.css';
import Color from '../../../../themes/colors';
import { InputButtonMain } from '../../../../components/UI_V2/Input/Button';
import { validatePassword } from '../../../../utilities/inputValidatorUtil';
import handleLoadings from '../../../../utilities/handleLoadings';
import { AuthActions } from '../../../../actions';
import { DisplayTextBody } from '../../../../components/UI_V2/Display/Text';
import { InputTextFieldMain } from '../../../../components/UI_V2/Input/TextField';
import handleStatusMsg from '../../../../utilities/handleStatusMsg';

const ChangePasswordContainer = ({
  handleHideChangePassword,
  emailUser,
}) => {
  const params = useParams();

  const [newUser, setNewUser] = useState({
    password: '',
    passwordConfirmation: '',
    passwordBefore: '',
  });
  const [loadings, setLoadings] = useState([]);

  const [showPassword, setShowPassword] = useState(false);
  const [isValidPassword, setIsValidPassword] = useState(true);
  const [isPasswordEmpty, setIsPasswordEmpty] = useState(true);
  const [isPasswordConfirmationEmpty, setIsPasswordConfirmationEmpty] = useState(true);
  const [isValidPasswordConfirmation, setIsValidPasswordConfirmation] = useState(true);
  const [isValidPasswordBefore, setIsValidPasswordBefore] = useState(true);
  const [isPasswordBeforeEmpty, setIsPasswordBeforeEmpty] = useState(true);
  const [errorMsgPasswordBefore, setErrorMsgPasswordBefore] = useState('');
  const [errorMsgPassword, setErrorMsgPassword] = useState('');
  const [errorMsgPasswordConfirmation, setErrorMsgPasswordConfirmation] = useState('');
  const [errorMsgEmpty, setErrorMsgEmpty] = useState('');
  const [showErrorEmpty, setShowErrorEmpty] = useState(false);
  const [errorMsgClickStillError, setErrorMsgClickStillError] = useState('');
  const [showErrorClickStillError, setShowErrorClickStillError] = useState(false);

  const [showSuccessChangePassword, setShowSuccessChangePassword] = useState(false);
  const [successChangePasswordMsg, setSuccessChangePasswordMsg] = useState('');

  const [showErrorChangePassword, setShowErrorChangePassword] = useState(false);
  const [errorChangePasswordMsg, setErrorChangePasswordMsg] = useState('');

  const [isProcessing, setIsProcessing] = useState(false);

  const [showLoginButton, setShowLoginButton] = useState(false);

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const handleChanged = (event) => {
    const { name, value } = event.target;
    setNewUser((prevValue) => ({
      ...prevValue,
      [name]: value,
    }));

    if (showErrorEmpty) {
      setShowErrorEmpty(false);
      setErrorMsgEmpty('');
    }

    if (showErrorClickStillError) {
      setShowErrorClickStillError(false);
      setErrorMsgClickStillError('');
    }

    if (showSuccessChangePassword) {
      setShowSuccessChangePassword(false);
      setSuccessChangePasswordMsg('');
    }

    if (showErrorChangePassword) {
      setShowErrorChangePassword(false);
      setErrorChangePasswordMsg('');
    }

    if (name === 'password') {
      const isEmpty = value.length < 1;

      if (isEmpty) {
        setIsPasswordEmpty(true);
        setIsValidPassword(true);
        setErrorMsgPassword('');
        return;
      }

      setIsPasswordEmpty(false);

      const { isValid, errorMsg } = validatePassword(value);

      if (isValid) {
        setIsValidPassword(true);
        setErrorMsgPassword('');
      } else {
        setIsValidPassword(false);
        setErrorMsgPassword(errorMsg);
      }

      const isEmptyPasswordConfirmation = newUser.passwordConfirmation.length < 1;

      if (isEmptyPasswordConfirmation) {
        setIsPasswordConfirmationEmpty(true);
        setIsValidPasswordConfirmation(true);
        setErrorMsgPasswordConfirmation('');
        return;
      }

      setIsPasswordConfirmationEmpty(false);

      const isTheSameWithPassword = value === newUser.passwordConfirmation;
      if (isTheSameWithPassword) {
        setIsValidPasswordConfirmation(true);
        setErrorMsgPasswordConfirmation('');
      } else {
        setIsValidPasswordConfirmation(false);
        setErrorMsgPasswordConfirmation('Konfirmasi password belum sama. ');
      }
    }

    if (name === 'passwordConfirmation') {
      const isEmpty = value.length < 1;

      if (isEmpty) {
        setIsPasswordConfirmationEmpty(true);
        setIsValidPasswordConfirmation(true);
        setErrorMsgPasswordConfirmation('');
        return;
      }

      setIsPasswordConfirmationEmpty(false);

      const isTheSameWithPassword = value === newUser.password;
      if (isTheSameWithPassword) {
        setIsValidPasswordConfirmation(true);
        setErrorMsgPasswordConfirmation('');
      } else {
        setIsValidPasswordConfirmation(false);
        setErrorMsgPasswordConfirmation('Konfirmasi password belum sama. ');
      }
    }

    if (name === 'passwordBefore') {
      const isEmpty = value.length < 1;

      if (isEmpty) {
        setIsPasswordBeforeEmpty(true);
        setIsValidPasswordBefore(true);
        setErrorMsgPasswordBefore('');
        return;
      }

      setIsPasswordBeforeEmpty(false);
    }
  };

  const isThereAnyEmpty = isPasswordEmpty
  || isPasswordConfirmationEmpty || isPasswordBeforeEmpty;

  const isThereAnyError = !isValidPassword
    || !isValidPasswordConfirmation || !isValidPasswordBefore;

  const handleClickWhenEmpty = (event) => {
    if (event) {
      event.preventDefault();
    }

    let message = '';
    if (isPasswordBeforeEmpty) {
      message = 'Password lama masih kosong. ';
    }

    if (isPasswordEmpty) {
      message = `${message}Password baru masih kosong. `;
    }

    if (isPasswordConfirmationEmpty) {
      message = `${message}Konfirmasi Password baru masih kosong. `;
    }

    setErrorMsgEmpty(message);
    setShowErrorEmpty(true);
  };

  const handleClickWhenStillError = () => {
    setErrorMsgClickStillError('Belum bisa melanjutkan, perbaiki dulu error diatas.');
    setShowErrorClickStillError(true);
  };

  const processChangePasswordApi = async ({
    email,
    passwordBefore,
    password,
  }) => {
    try {
      const startLoadings = handleLoadings('changePassword', [...loadings], 'start');
      setLoadings([...startLoadings]);

      const result = await AuthActions.changePassword({
        email,
        passwordBefore,
        password,
      });

      // const status = handleStatusMsg(result, 'success');

      setShowSuccessChangePassword(true);
      setShowErrorChangePassword(false);
      setSuccessChangePasswordMsg('Ubah Password berhasil.');
      setErrorChangePasswordMsg('');
      setShowLoginButton(true);
    } catch (err) {
      const status = handleStatusMsg(err, 'error');

      setShowErrorChangePassword(true);
      setShowSuccessChangePassword(false);
      setErrorChangePasswordMsg(status.message);
      setSuccessChangePasswordMsg('');
    } finally {
      const endLoadings = handleLoadings('changePassword', [...loadings], 'end');
      setLoadings([...endLoadings]);
      setIsProcessing(false);
    }
  };

  const handleClickChangePassword = (event) => {
    if (event) {
      event.preventDefault();
    }

    if (isThereAnyEmpty) {
      handleClickWhenEmpty();
      return;
    }

    if (isThereAnyError) {
      handleClickWhenStillError();
      return;
    }

    if (isProcessing) return;

    setIsProcessing(true);

    processChangePasswordApi({
      email: emailUser,
      passwordBefore: newUser.passwordBefore,
      password: newUser.password,
    });
  };
  return (
    <div className={styles.container}>
      <form onSubmit={isThereAnyEmpty ? handleClickWhenEmpty : handleClickChangePassword}>
        <div className={styles.form}>
          <div className={styles.formText}>
            <DisplayTextBody
              decoration="bold"
            >
              Password Lama
            </DisplayTextBody>
          </div>
          <div className={styles.formArea}>
            <InputTextFieldMain
              placeholder="**********"
              name="passwordBefore"
              onChange={(event) => handleChanged(event)}
              value={newUser.passwordBefore}
              border="rounded"
              type={showPassword ? 'text' : 'password'}
              size="lg"
              showHelperIcon
              helperIcon={showPassword ? <VisibilityOffIcon /> : <VisibilityIcon />}
              onClickHelperIcon={togglePasswordVisibility}
            />
          </div>
        </div>
        <div className={styles.form}>
          <div className={styles.formText}>
            <DisplayTextBody
              decoration="bold"
            >
              Password Baru
            </DisplayTextBody>
          </div>
          <div className={styles.formArea}>
            <InputTextFieldMain
              placeholder="**********"
              name="password"
              onChange={(event) => handleChanged(event)}
              value={newUser.password}
              border="rounded"
              type={showPassword ? 'text' : 'password'}
              size="lg"
              showHelperIcon
              helperIcon={showPassword ? <VisibilityOffIcon /> : <VisibilityIcon />}
              onClickHelperIcon={togglePasswordVisibility}
            />
          </div>
        </div>
        <div className={styles.form}>
          <div className={styles.formText}>
            <DisplayTextBody
              decoration="bold"
            >
              Konfirmasi Password Baru
            </DisplayTextBody>
          </div>
          <div className={styles.formArea}>
            <InputTextFieldMain
              placeholder="**********"
              name="passwordConfirmation"
              onChange={(event) => handleChanged(event)}
              value={newUser.passwordConfirmation}
              border="rounded"
              type={showPassword ? 'text' : 'password'}
              size="lg"
              showHelperIcon
              helperIcon={showPassword ? <VisibilityOffIcon /> : <VisibilityIcon />}
              onClickHelperIcon={togglePasswordVisibility}
            />
          </div>
        </div>
        {!isValidPassword && !isValidPasswordConfirmation && <Alert severity="error">{errorMsgPassword}</Alert>}
        {isValidPassword
                    && !isValidPasswordConfirmation && <Alert severity="error">{errorMsgPasswordConfirmation}</Alert>}
        {!isValidPassword && isValidPasswordConfirmation && <Alert severity="error">{errorMsgPassword}</Alert>}

        {isThereAnyEmpty && showErrorEmpty && <div className={styles.alertEmptySection}><Alert severity="error">{errorMsgEmpty}</Alert></div>}
        {isThereAnyError && showErrorClickStillError && <div className={styles.alertEmptySection}><Alert severity="error">{errorMsgClickStillError}</Alert></div>}
        {showSuccessChangePassword && (
          <div className={styles.alertClickSection}>
            <Alert severity="success">{successChangePasswordMsg}</Alert>
          </div>
        )}
        {showErrorChangePassword && (
          <div className={styles.alertClickSection}>
            <Alert severity="error">{errorChangePasswordMsg}</Alert>
          </div>
        )}
        <div className={styles.action}>
          <div className={styles.button}>
            <InputButtonMain
              border="rounded"
              handleClick={handleHideChangePassword}
              variant="light"
            >
              Kembali
            </InputButtonMain>
          </div>
          <div className={styles.button}>
            <InputButtonMain
              border="rounded"
              handleClick={isThereAnyEmpty ? handleClickWhenEmpty : handleClickChangePassword}
              loadings={loadings}
              wait="changePassword"
              type="submit"
            >
              Ubah Password
            </InputButtonMain>
          </div>
        </div>
      </form>
    </div>
  );
};

ChangePasswordContainer.propTypes = {
  handleHideChangePassword: PropTypes.func.isRequired,
  emailUser: PropTypes.string.isRequired,
};

ChangePasswordContainer.defaultProps = {};

export default memo(ChangePasswordContainer);
