import React, { memo, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { Skeleton, useMediaQuery } from '@mui/material';
import styles from './Dropdown.module.css';
import { DisplayTextBody } from '../../../Display/Text';
import Color from '../../../../../themes/colors';
import introImage from '../../../../../assets/PrivateChatPage/intro-chat-section.png';
import { useDelayShowHideHandler } from '../../../../../hooks/HelperHooks';
import { limitChar } from '../../../../../utilities/stringUtil';
import { DisplayBadgeCounter } from '../../../Display/Badge';

const differentDataStyles = {
  true: styles.different,
  false: '',
};

const differentIconStyles = {
  true: styles.differentIcon,
  false: '',
};

const buttonWidthStyles = {
  sm: styles.buttonWidthSm,
  md: styles.buttonWidthMd,
  lg: styles.buttonWidthLg,
};

const textWidthStyles = {
  sm: styles.textWidthSm,
  md: styles.textWidthMd,
  lg: styles.textWidthLg,
};

const InputButtonDropdown = ({
  text,
  onClick,
  initialDataString,
  isFilterActive,
  icon,
  size,
  isLoading,
}) => {
  const [tempDataString, setTempDataString] = useState(initialDataString);

  useEffect(() => {
    if (initialDataString !== tempDataString) setTempDataString(initialDataString);
  }, [initialDataString]);

  const isDifferentDataString = text !== initialDataString;
  let counter = 0;
  if (isFilterActive && !isDifferentDataString) {
    counter = 1;
  } else {
    counter = 0;
  }

  const isMobile = useMediaQuery('(max-width:720px)');

  let positionBadge = { top: -7, right: -4 };
  if (isMobile) positionBadge = { top: -5, right: -2 };

  return (
    <div onClick={onClick} className={`${styles.container} ${differentDataStyles[isDifferentDataString]} ${buttonWidthStyles[size]}`}>
      {icon && (
      <div className={`${styles.arrow} ${differentIconStyles[isDifferentDataString]}`}>
        {icon}
      </div>
      )}
      {!isMobile && (
      <>
        <div className={`${styles.text} ${textWidthStyles[size]}`}>
          {!isLoading && (
          <DisplayTextBody
            color={isDifferentDataString ? Color.blueNavy4 : Color.gray4}
          >
            {text}
          </DisplayTextBody>
          )}
          {isLoading && <Skeleton width={50} />}
        </div>
        <div className={`${styles.arrow} ${differentIconStyles[isDifferentDataString]}`}>
          <KeyboardArrowDownIcon />
        </div>
      </>
      )}
      <DisplayBadgeCounter
        badgeContent={counter}
        backgroundColor={Color.blueNavy4}
        variant="dot"
        height="12px"
        minWidth="12px"
        customStyle={positionBadge}
      />
    </div>
  );
};

InputButtonDropdown.propTypes = {
  size: PropTypes.oneOf(['sm', 'md', 'lg']),
  text: PropTypes.string.isRequired,
  onClick: PropTypes.func,
  initialDataString: PropTypes.string,
  icon: PropTypes.node,
  isFilterActive: PropTypes.bool,
  isLoading: PropTypes.bool,
};

InputButtonDropdown.defaultProps = {
  onClick: () => null,
  initialDataString: null,
  icon: null,
  isFilterActive: false,
  size: 'md',
  isLoading: false,
};

export default memo(InputButtonDropdown);
