import React, {
  useState, useContext, useEffect, memo,
} from 'react';
import { useSnackbar } from 'notistack';
import { useParams } from 'react-router-dom';
import Form from 'react-bootstrap/Form';
import PropTypes from 'prop-types';
import { isEqual } from 'lodash';
import styles from './CreateLabel.module.css';
import { actionTypes } from '../../../reducers/reducer';
import { GlobalContext } from '../../../contexts/GlobalStateProvider';
import Button from '../../../components/UI/Button/Button';
import { ShowCreateLabelContext } from '../ManageLabelsContext';
import ColorBox from '../../../components/UI/ColorBox/ColorBox';
import handleStatusMsg from '../../../utilities/handleStatusMsg';
import handleLoadings from '../../../utilities/handleLoadings';
import apiUtil from '../../../utilities/apiUtil';
import { BoardActions } from '../../../actions';

const CreateLabel = ({ card }) => {
  const [{ colors }, dispatch] = useContext(GlobalContext);
  // showCreateLabel don't delete context
  const [showCreateLabel, setShowCreateLabel] = useContext(ShowCreateLabelContext);
  const [isSaved, setIsSaved] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const [loadings, setLoadings] = useState([]);
  const [newLabel, setNewLabel] = useState();
  const [selectedColor, setSelectedColor] = useState();
  const [postNewLabel, setPostNewLabel] = useState();

  const params = useParams();
  const { companyId } = params;

  useEffect(() => {
    const fetchApiColors = async () => {
      try {
        const teamId = params.teamId || card.team?._id || card.team;
        const result = await apiUtil.get('/api/v1/colors', {
          params: {
            companyId,
            teamId,
          },
        });

        dispatch({
          type: actionTypes.SET_COLORS,
          colors: result.data.colors,
        });
      } catch (err) {
        const status = handleStatusMsg(err, 'error');

        enqueueSnackbar(status.message, {
          variant: 'error',
        });
      }
    };
    fetchApiColors();
  }, []);

  useEffect(() => {
    if (postNewLabel !== undefined) {
      const postApiLabel = async () => {
        const cardId = params.cardId || card._id;
        const teamId = params.teamId || card.team?._id || card.team;
        const labelName = postNewLabel.value;
        const labelColorId = selectedColor;

        try {
          const startLoadings = handleLoadings('createLabel', [...loadings], 'start');
          setLoadings([...startLoadings]);

          const bodyRequest = {
            selector: { cardId },
            data: { name: labelName, color: labelColorId },
          };
          const result = await apiUtil.post('/api/v1/labels', bodyRequest, {
            params: {
              companyId,
              teamId,
            },
          });

          const status = handleStatusMsg(result, 'success');

          enqueueSnackbar(status.message, {
            variant: 'success',
          });

          setSelectedColor();
          setNewLabel();
          setShowCreateLabel(false);
        } catch (err) {
          const status = handleStatusMsg(err, 'error');

          enqueueSnackbar(status.message, {
            variant: 'error',
          });
        } finally {
          const endLoadings = handleLoadings('createLabel', [...loadings], 'end');
          setLoadings([...endLoadings]);
        }
      };
      postApiLabel();
    }
  }, [postNewLabel, isSaved]);

  const handleCreateLabel = () => {
    if (newLabel === undefined || newLabel?.value === undefined || newLabel?.value.length < 1) {
      enqueueSnackbar('Label name cannot be empty!', {
        variant: 'error',
      });
      return;
    }

    if (selectedColor === undefined) {
      enqueueSnackbar('Please select a color!', {
        variant: 'error',
      });
      return;
    }

    // trigger if label name is not changed
    setIsSaved(!isSaved);
    setPostNewLabel(newLabel);
  };

  const handleChanged = (event) => {
    const { value } = event.target;
    setNewLabel((prevValue) => ({
      ...prevValue,
      value,
    }));
  };

  const handleSelectColor = (colorId) => {
    setSelectedColor(colorId);
  };

  return (
    <>
      <div className={styles.sectionSmallTitle}>
        <p className={styles.CreateLabelContainer__smallTitle}>Nama</p>
        <Form>
          <Form.Control autoFocus onChange={(event) => handleChanged(event)} as="input" placeholder="Nama label" />
        </Form>
      </div>
      <div className={styles.sectionSmallTitle}>
        <p className={styles.CreateLabelContainer__smallTitle}>Pilih Warna :</p>
      </div>
      <div className={styles.CreateLabelContainer__body}>
        {colors.map((color) => (
          <ColorBox
            clicked={() => handleSelectColor(color._id)}
            color={color.name}
            checked={selectedColor === color._id}
          />
        ))}
      </div>
      <div className={styles.sectionFooter}>
        <Button handleClick={handleCreateLabel} variant="success" size="sm" wait="createLabel" loadings={loadings}>Buat</Button>
      </div>
    </>
  );
};

CreateLabel.propTypes = {
  card: PropTypes.object.isRequired,
};

const isComponentDataEqual = (prevProps, nextProps) => {
  const { card } = nextProps;
  return isEqual(prevProps.card, card);
};

export default memo(CreateLabel, isComponentDataEqual);
