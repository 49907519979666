import React, { memo, useState } from 'react';
import PropTypes from 'prop-types';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import styles from './Illustration.module.css';
import { DisplayTextBody, DisplayTextSubHeadline } from '../../../../../components/UI_V2/Display/Text';
import Color from '../../../../../themes/colors';
import factoryIllustration from '../../../../../assets/DashboardAdminPage/PirateMetricPage/2-factory-illustration.png';

const Illustration = ({
  src,
  className,
}) => (
  <div className={`${styles.container} ${className}`}>
    <LazyLoadImage src={src} />
  </div>
);

Illustration.propTypes = {
  src: PropTypes.string.isRequired,
  className: PropTypes.string,
};

Illustration.defaultProps = {
  className: null,
};

export default memo(Illustration);
