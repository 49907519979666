import React, { useState, useEffect } from 'react';
import draftToHtml from 'draftjs-to-html';
import PropTypes from 'prop-types';
import MessageSnapshot from './MessageSnapshot/MessageSnapshot';
import AttachmentItemSnapshot from './AttachmentItemSnapshot/AttachmentItemSnapshot';
import styles from './GroupChatSnapshot.module.css';
import checkIsJson from '../../../utilities/checkIsJson';

const GroupChatSnapshot = ({ messages, attachments }) => {
  const [messagesFiles, setMessagesFiles] = useState([]);

  useEffect(() => {
    if (attachments === undefined && messages === undefined) return;

    const tempAttachments = [];
    if (attachments) {
      attachments.forEach((attachment) => {
        const newAttachment = {
          ...attachment,
          type: 'attachment',
        };

        tempAttachments.push(newAttachment);
      });
    }

    const tempMessages = [];
    if (messages) {
      messages.forEach((message) => {
        let initialContent;
        if (checkIsJson(message.content)) {
          initialContent = draftToHtml(JSON.parse(message.content));
        } else {
          initialContent = message.content;
        }
        initialContent = initialContent.replace(/<\/?("[^"]*"|'[^']*'|[^>])*(>|$)/g, '').replace(/\n/g, ' ');

        if (initialContent.length >= 50) {
          initialContent = `${initialContent.slice(0, 50)}...`;
        }

        const newMessage = {
          ...message,
          content: initialContent,
          type: 'message',
        };

        tempMessages.push(newMessage);
      });
    }

    // join 2 array
    let joinedDatas = [...tempAttachments, ...tempMessages];
    // sort by created date
    joinedDatas = joinedDatas.sort((a, b) => {
      const c = new Date(a.createdAt);
      const d = new Date(b.createdAt);
      return d - c;
    });

    const cutDatas = joinedDatas.splice(0, 4);

    setMessagesFiles([...cutDatas]);
  }, [messages, attachments]);

  return (
    <div className={styles.FetchMessages}>
      {messagesFiles.map((messageFile) => {
        if (messageFile.type === 'message') {
          return <MessageSnapshot key={messageFile._id} message={messageFile} />;
        } if (messageFile.type === 'attachment') {
          return <AttachmentItemSnapshot key={messageFile._id} attachment={messageFile} />;
        }

        return null;
      })}
    </div>
  );
};

GroupChatSnapshot.propTypes = {
  messages: PropTypes.array.isRequired,
  attachments: PropTypes.array.isRequired,
};

export default GroupChatSnapshot;
