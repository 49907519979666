import React, { useState, memo, useCallback } from 'react';
import PropTypes from 'prop-types';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import styles from './SwitchComponent.module.css';
import { DisplayTextSubHeadline } from '../../UI_V2/Display/Text';
import Color from '../../../themes/colors';

const SwitchComponent = ({
  defaultValue, handleChange, title, labelSwitch, nameSwitch, colorSwitc,
  isLabelSwitchBold, marginBottom,
}) => {
  const [checked, setCheck] = useState(defaultValue);

  const handleChangeCheck = useCallback((event) => {
    setCheck(event?.target?.checked);
    handleChange(event?.target?.checked);
  }, []);

  return (
    <div className={styles.SwitchComponent} style={{ marginBottom }}>
      <div className={styles.SwitchComponentHeader}>
        <DisplayTextSubHeadline
          mode="14"
          color={Color.deepGray2}
          decoration={isLabelSwitchBold ? 'bold' : 'regular'}
        >
          {title}
        </DisplayTextSubHeadline>
      </div>
      <div className={styles.SwitchComponentContent}>
        <FormControlLabel
          className={styles.SwitchComponentContentLabel}
          control={(
            <Switch
              checked={checked}
              onChange={handleChangeCheck}
              name={nameSwitch}
              color={colorSwitc}
            />
          )}
          label={labelSwitch}
        />
      </div>
    </div>
  );
};

SwitchComponent.propTypes = {
  defaultValue: PropTypes.bool,
  handleChange: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
  labelSwitch: PropTypes.string.isRequired,
  nameSwitch: PropTypes.string,
  colorSwitc: PropTypes.string,
  isLabelSwitchBold: PropTypes.string,
  marginBottom: PropTypes.string,
};

SwitchComponent.defaultProps = {
  defaultValue: false,
  nameSwitch: 'switchItem',
  colorSwitc: 'primary',
  isLabelSwitchBold: false,
  marginBottom: '20px',
};

const isComponentDataEqual = (prevProps, nextProps) => {
  const { title, labelSwitch } = nextProps;
  return prevProps.title === title
    && prevProps.labelSwitch === labelSwitch;
};

export default memo(SwitchComponent, isComponentDataEqual);
