import React, { useState, useEffect, useContext } from 'react';
import { useSnackbar } from 'notistack';
import axios from 'axios';
import { useParams } from 'react-router-dom';
import PropTypes from 'prop-types';
import styles from './CheersContainer.module.css';
import ShowCheers from './ShowCheers/ShowCheers';
import { GlobalContext } from '../../contexts/GlobalStateProvider';
import handleStatusMsg from '../../utilities/handleStatusMsg';
import handleLoadings from '../../utilities/handleLoadings';
import { DEFAULT_OPTION } from '../../constants/ApiConstants';
import { handleAxiosDeleteRefreshToken } from '../../actions/CheckLoginActions';

const CheersContainer = (
  {
    cheers,
    receiver,
    type, // use this instead
    newVersion, // use this instead
    comment, // use this instead
    parentIds, // this can be used in the future if want to access cheers
    // container inside other menu
    primaryParentId, // deprecated
  },
) => {
  const [{ user }, dispatch] = useContext(GlobalContext);

  const [showDelete, setShowDelete] = useState('');
  const [deletedCheer, setDeletedCheer] = useState();

  const { enqueueSnackbar } = useSnackbar();
  const [loadings, setLoadings] = useState([]);

  const params = useParams();
  const { companyId, teamId } = params;

  const handleCancel = () => {
    setShowDelete('');
    setDeletedCheer();
  };

  useEffect(() => {
    if (deletedCheer === undefined) return;

    const deleteCheerApi = async () => {
      const cheerId = deletedCheer;

      let ids;
      let url;

      switch (type) {
        // main
        case 'occurrenceMain':
          ids = [params.eventId, params.occurrenceId, cheerId];
          url = `${process.env.REACT_APP_PRIMARY_API_URL}/api/v1/events/${ids[0]}/occurrences/${ids[1]}/cheers/${ids[2]}`;
          break;
        case 'blastMain':
          ids = [params.postId, cheerId];
          url = `${process.env.REACT_APP_PRIMARY_API_URL}/api/v1/posts/${ids[0]}/cheers/${ids[1]}`;
          break;
        case 'eventMain':
          ids = [params.eventId, cheerId];
          url = `${process.env.REACT_APP_PRIMARY_API_URL}/api/v1/events/${ids[0]}/cheers/${ids[1]}`;
          break;
        case 'docMain':
          ids = [params.docId, cheerId];
          url = `${process.env.REACT_APP_PRIMARY_API_URL}/api/v1/docs/${ids[0]}/cheers/${ids[1]}`;
          break;
        case 'fileMain':
          ids = [params.fileId, cheerId];
          url = `${process.env.REACT_APP_PRIMARY_API_URL}/api/v1/files/${ids[0]}/cheers/${ids[1]}`;
          break;
        case 'cardMain':
          ids = [params.cardId, cheerId];
          url = `${process.env.REACT_APP_PRIMARY_API_URL}/api/v1/cards/${ids[0]}/cheers/${ids[1]}`;
          break;
        // comment
        case 'occurrence':
          ids = [params.eventId, params.occurrenceId, comment._id, cheerId];
          url = `${process.env.REACT_APP_PRIMARY_API_URL}/api/v1/events/${ids[0]}/occurrences/${ids[1]}/comments/${ids[2]}/cheers/${ids[3]}`;
          break;
        case 'blast':
          ids = [params.postId, comment._id, cheerId];
          url = `${process.env.REACT_APP_PRIMARY_API_URL}/api/v1/posts/${ids[0]}/comments/${ids[1]}/cheers/${ids[2]}`;
          break;
        case 'question':
          ids = [params.questionId, comment._id, cheerId];
          url = `${process.env.REACT_APP_PRIMARY_API_URL}/api/v1/questions/${ids[0]}/comments/${ids[1]}/cheers/${ids[2]}`;
          break;
        case 'event':
          ids = [params.eventId, comment._id, cheerId];
          url = `${process.env.REACT_APP_PRIMARY_API_URL}/api/v1/events/${ids[0]}/comments/${ids[1]}/cheers/${ids[2]}`;
          break;
        case 'doc':
          ids = [params.docId, comment._id, cheerId];
          url = `${process.env.REACT_APP_PRIMARY_API_URL}/api/v1/docs/${ids[0]}/comments/${ids[1]}/cheers/${ids[2]}`;
          break;
        case 'file':
          ids = [params.fileId, comment._id, cheerId];
          url = `${process.env.REACT_APP_PRIMARY_API_URL}/api/v1/files/${ids[0]}/comments/${ids[1]}/cheers/${ids[2]}`;
          break;
        case 'card':
          // quick comment tidak ada params cardId
          ids = [params.cardId || parentIds?.[0], comment._id, cheerId];
          url = `${process.env.REACT_APP_PRIMARY_API_URL}/api/v1/cards/${ids[0]}/comments/${ids[1]}/cheers/${ids[2]}`;
          break;
        // discussion
        case 'occurrenceDiscussion':
          // comment._id now should be a discussion id
          ids = [params.eventId, params.occurrenceId, params.commentId, comment._id, cheerId];
          url = `${process.env.REACT_APP_PRIMARY_API_URL}/api/v1/events/${ids[0]}/occurrences/${ids[1]}/comments/${ids[2]}/discussions/${ids[3]}/cheers/${ids[4]}`;
          break;
        case 'blastDiscussion':
          ids = [params.postId, params.commentId, comment._id, cheerId];
          url = `${process.env.REACT_APP_PRIMARY_API_URL}/api/v1/posts/${ids[0]}/comments/${ids[1]}/discussions/${ids[2]}/cheers/${ids[3]}`;
          break;
        case 'questionDiscussion':
          ids = [params.questionId, params.commentId, comment._id, cheerId];
          url = `${process.env.REACT_APP_PRIMARY_API_URL}/api/v1/questions/${ids[0]}/comments/${ids[1]}/discussions/${ids[2]}/cheers/${ids[3]}`;
          break;
        case 'eventDiscussion':
          ids = [params.eventId, params.commentId, comment._id, cheerId];
          url = `${process.env.REACT_APP_PRIMARY_API_URL}/api/v1/events/${ids[0]}/comments/${ids[1]}/discussions/${ids[2]}/cheers/${ids[3]}`;
          break;
        case 'docDiscussion':
          ids = [params.docId, params.commentId, comment._id, cheerId];
          url = `${process.env.REACT_APP_PRIMARY_API_URL}/api/v1/docs/${ids[0]}/comments/${ids[1]}/discussions/${ids[2]}/cheers/${ids[3]}`;
          break;
        case 'fileDiscussion':
          ids = [params.fileId, params.commentId, comment._id, cheerId];
          url = `${process.env.REACT_APP_PRIMARY_API_URL}/api/v1/files/${ids[0]}/comments/${ids[1]}/discussions/${ids[2]}/cheers/${ids[3]}`;
          break;
        case 'cardDiscussion':
          ids = [params.cardId, params.commentId, comment._id, cheerId];
          url = `${process.env.REACT_APP_PRIMARY_API_URL}/api/v1/cards/${ids[0]}/comments/${ids[1]}/discussions/${ids[2]}/cheers/${ids[3]}`;
          break;
        default:
          break;
      }

      try {
        const startLoadings = handleLoadings(`deleteCheers-${cheerId}`, [...loadings], 'start');
        setLoadings([...startLoadings]);

        const result = await axios.delete(
          url,
          {
            withCredentials: true,
            headers: {
              Authorization: `jwt ${localStorage.getItem('token')}`,
            },
            params: {
              companyId,
              teamId,
            },
          },
        );

        const status = handleStatusMsg(result, 'success');

        enqueueSnackbar(status.message, {
          variant: 'success',
        });

        handleCancel();
      } catch (err) {
        const newAuthToken = await handleAxiosDeleteRefreshToken(err);

        if (newAuthToken) {
          try {
            const result = await axios.delete(
              url,
              {
                withCredentials: true,
                headers: {
                  Authorization: `jwt ${newAuthToken}`,
                },
                params: {
                  companyId,
                  teamId,
                },
              },
            );

            const status = handleStatusMsg(result, 'success');

            enqueueSnackbar(status.message, {
              variant: 'success',
            });

            handleCancel();
          } catch (error) {
            const status = handleStatusMsg(error, 'error');

            enqueueSnackbar(status.message, {
              variant: 'error',
            });
          }
        } else {
          const status = handleStatusMsg(err, 'error');

          enqueueSnackbar(status.message, {
            variant: 'error',
          });
        }
      } finally {
        const endLoadings = handleLoadings(`deleteCheers-${cheerId}`, [...loadings], 'end');
        setLoadings([...endLoadings]);
      }
    };
    deleteCheerApi();
  }, [deletedCheer]);

  const handleToggleDelete = (cheer) => {
    if (String(cheer.creator?._id) !== String(user._id)) return;

    if (showDelete === cheer._id) {
      setShowDelete('');
    } else {
      setShowDelete(cheer._id);
    }
  };

  const handleDeleteCheer = (cheer) => {
    setDeletedCheer(cheer._id);
  };

  return (
    <div className={styles.container}>
      <ShowCheers
        loadings={loadings}
        handleDeleteCheer={handleDeleteCheer}
        handleToggleDelete={handleToggleDelete}
        showDelete={showDelete}
        cheers={cheers}
        type={type} // use this instead
        // newVersion={newVersion} //use this instead (no need using this anymore
        // since all feature has been implemented)
        comment={comment} // use this instead
        receiver={receiver}
        parentIds={parentIds}
      />
    </div>
  );
};

CheersContainer.propTypes = {
  type: PropTypes.string.isRequired,
  newVersion: PropTypes.bool.isRequired,
  comment: PropTypes.object.isRequired,
  receiver: PropTypes.string.isRequired,
  parentIds: PropTypes.array,
  primaryParentId: PropTypes.string.isRequired,
  cheers: PropTypes.array.isRequired,
};

CheersContainer.defaultProps = {
  parentIds: [],
};

export default CheersContainer;
