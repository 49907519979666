import React, { memo, useState } from 'react';
import PropTypes from 'prop-types';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import styles from './SearchSeen.module.css';
import Color from '../../../../themes/colors';

const SearchSeen = () => (
  <div className={styles.container} />
);

SearchSeen.propTypes = {};

SearchSeen.defaultProps = {};

export default memo(SearchSeen);
