import React, {
  useState, useContext, useEffect, memo,
} from 'react';
import PropTypes from 'prop-types';
import PersonAddAlt1OutlinedIcon from '@mui/icons-material/PersonAddAlt1Outlined';
import _ from 'lodash';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import styles from './SelectRoleUser.module.css';
import { DisplayTextBody } from '../../../../../UI_V2/Display/Text';
import SelectRoleUserMenu from './SelectRoleUserMenu/SelectRoleUserMenu';
import Color from '../../../../../../themes/colors';
import { role, roleTitle } from '../../../../../../constants/RoleConstants';
import { useDelayShowHideHandler } from '../../../../../../hooks/HelperHooks';

const differentRoleStyles = {
  true: styles.differentRole,
  false: '',
};

const differentRoleIconStyles = {
  true: styles.differentRoleIcon,
  false: '',
};

const disabledStyles = {
  true: styles.disabled,
};

const SelectRoleUser = ({
  user,
  roleUser,
  handleSelectRoleUser,
  handleUnselectRoleUser,
  disabled,
  currentRoleUser,
  isRoleTypeCompany,
}) => {
  const [tempRoleUser, setTempRoleUser] = useState(roleUser);
  const [
    showSelectRole,
    handleShowSelectRole,
    handleHideSelectRole,
  ] = useDelayShowHideHandler();

  const handleUpdateTempRoleUser = (newRoleUser) => {
    setTempRoleUser(newRoleUser);
  };

  useEffect(() => {
    if (roleUser._id !== tempRoleUser._id) setTempRoleUser(roleUser);
  }, [roleUser]);

  const isDifferentRoleUser = tempRoleUser.role !== roleUser.role;

  return (
    <div className={styles.wrapper}>
      {showSelectRole
      && (
      <SelectRoleUserMenu
        cancel={handleHideSelectRole}
        roleUser={roleUser}
        handleSelectRoleUser={handleSelectRoleUser}
        handleUnselectRoleUser={handleUnselectRoleUser}
        handleUpdateTempRoleUser={handleUpdateTempRoleUser}
        tempRoleUser={tempRoleUser}
        currentRoleUser={currentRoleUser}
        isRoleTypeCompany={isRoleTypeCompany}
      />
      )}
      <div
        onClick={!disabled && handleShowSelectRole}
        className={`${styles.container} ${differentRoleStyles[isDifferentRoleUser]} ${disabledStyles[disabled]}`}
      >
        <div className={styles.text}>
          <DisplayTextBody
            color={isDifferentRoleUser ? Color.blueNavy4 : Color.gray4}
            position={disabled && 'center'}
          >
            {roleTitle[tempRoleUser.role]}
          </DisplayTextBody>
        </div>
        {!disabled && (
        <div className={`${styles.arrow} ${differentRoleIconStyles[isDifferentRoleUser]}`}>
          <KeyboardArrowDownIcon />
        </div>
        )}
      </div>
    </div>
  );
};

SelectRoleUser.propTypes = {
  user: PropTypes.object.isRequired,
  roleUser: PropTypes.object.isRequired,
  handleSelectRoleUser: PropTypes.func.isRequired,
  handleUnselectRoleUser: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  currentRoleUser: PropTypes.object.isRequired,
  isRoleTypeCompany: PropTypes.bool.isRequired,
};

SelectRoleUser.defaultProps = {
  disabled: false,
};

export default memo(SelectRoleUser);
