/* eslint-disable consistent-return */
import { isNil } from 'lodash';
import React, { useCallback, useState, useEffect } from 'react';
import { TeamFavoriteActions } from '../actions';
import { TeamFavoriteConstants } from '../constants';
import { connectSocketTeamFavorite } from '../services/socket';
import { useSocketHooks } from './SocketHooks';

function useTeamFavoriteHooks({ companyId, userId, teams }, dispatch) {
  const callbackSetTeamFavorite = useCallback(({ teamId }) => {
    TeamFavoriteActions.incomingTeamFavorites({
      teamId,
      teams,
      typeAction: TeamFavoriteConstants.typeCallback.NEW,
    }, dispatch);
  }, []);
  const callbackUnsetTeamFavorite = useCallback(({ teamId }) => {
    TeamFavoriteActions.incomingTeamFavorites({
      teamId,
      teams,
      typeAction: TeamFavoriteConstants.typeCallback.DELETE,
    }, dispatch);
  }, []);
  const callbackReorderTeamFavorite = useCallback(({ teamId, position }) => {
    TeamFavoriteActions.incomingTeamFavoriteMove({
      teamId,
      position,
    }, dispatch);
  }, []);

  const listenSocket = (socket, params) => {
    socket
      .on('set', callbackSetTeamFavorite)
      .on('unset', callbackUnsetTeamFavorite)
      .on('reorder', callbackReorderTeamFavorite);
  };
  const removeListener = (socket, params) => {
    socket
      .off('set')
      .off('unset')
      .off('reorder');
    socket.disconnect();
  };

  useSocketHooks({
    params: { companyId, userId },
    connectSocket: connectSocketTeamFavorite,
    listenSocket,
    removeListener,
  });
}

export {
  useTeamFavoriteHooks,
};
