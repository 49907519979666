export default {
  fontColor: '#262727',
  deepGray2: '#4D4D4D',
  gray3: '#7A7A7A',
  gray4: '#B5B5B5',
  gray5: '#D6D6D6',
  gray6: '#ECECEC',
  gray7: '#c4c4c4',
  gray8: '#f1f1f1',
  backgroundBrokenWhite: '#FAFAFA',
  white: '#FFFFFF',
  black: '#000000',

  blueNavyCicle: '#0C2044',
  blueNavy2: '#1F3762',
  blueNavy3: '#385282',
  blueNavy4: '#708FC7',
  blueAquaMarine: '#3466E4',
  lighterBlueCicle: '#32B4FD',
  lighterBlue: '#C1D6FD',
  lighterBlue2: '#DEEAFF',
  lighterBlue3: '#EFF5FF',

  yellowNavyCicle: '#F0B418',
  yellowCicleDarker: '#F0B418',
  yellowAccentCicle: '#FDC532',
  yellowCicle2: '#FFD974',
  yellowCicle3: '#FFE49B',
  yellowCicle4: '#FFEEC3',

  tosca: '#42E691',
  teal: '#4BEED1',
  orange: '#FF881B',
  violet: '#AF71FF',
  lightBlue3: '#43D2FF',
  brightBlue: '#001AFF',
  red: '#CF0F0F',
  brown: '#956042',

  darkerTosca: '#33CB7C',
  grayCicleWeb: '#F0F1F7',
  grayIconEditDocs: '#393E46',
  deleteColor: '#FF7171',

  greenCicle: '#93D144',
  greenLight: '#BBEE7B',
};
