import React from 'react';
import { useParams, useHistory } from 'react-router-dom';
import LoadingWithAnimation from '../../components/UI/LoadingWithAnimation/LoadingWithAnimation';
import MainLayout from '../../components/Layout/MainLayout/MainLayout';
import styles from './CheckFromExternalPage.module.css';
import Title from '../../components/Title/Title';
import LogoHeader from '../../components/UI/LogoHeader/LogoHeader';

function CheckFromExternalPage() {
  const params = useParams();
  const history = useHistory();

  const { sourcePage } = params;

  if (sourcePage) {
    history.push(`/signup?sourcePage=${sourcePage}`);
  } else {
    history.push('/');
  }

  return (
    <>
      <Title title="Sedang mengalihkan..." />
      <LogoHeader />
      <MainLayout>
        <LoadingWithAnimation>
          <h1>Tunggu ya...</h1>
          <h1>Mengalihkan</h1>
        </LoadingWithAnimation>
      </MainLayout>
    </>
  );
}

export default CheckFromExternalPage;
