import React, { useState, useEffect, useContext } from 'react';
import { useParams } from 'react-router-dom';
import CloseIcon from '@mui/icons-material/Close';
import { cloneDeep } from 'lodash';
import { useSnackbar } from 'notistack';
import PropTypes from 'prop-types';
import BoxPopOver from '../../../../components/UI/BoxPopOver/BoxPopOver';
import SeparatorLine from '../../../../components/UI/SeparatorLine/SeparatorLine';
import styles from './SetPublicCalendarMenu.module.css';
import { GlobalContext } from '../../../../contexts/GlobalStateProvider';
import { actionTypes } from '../../../../reducers/reducer';
import Button from '../../../../components/UI/Button/Button';
import handleStatusMsg from '../../../../utilities/handleStatusMsg';
import handleLoadings from '../../../../utilities/handleLoadings';
import apiUtil from '../../../../utilities/apiUtil';
import { ApiConstants } from '../../../../constants';

const SetPublicCalendarMenu = ({ schedule, cancel, left }) => {
  const { enqueueSnackbar } = useSnackbar();
  const [loadings, setLoadings] = useState([]);
  const [{}, dispatch] = useContext(GlobalContext);
  const [postSchedule, setPostSchedule] = useState();
  const params = useParams();
  const { companyId, teamId } = params;

  useEffect(() => {
    if (postSchedule === undefined) return;

    const togglePublicCalendarApi = async () => {
      const scheduleId = postSchedule;

      try {
        const startLoadings = handleLoadings('setPublicCalendarMenu', [...loadings], 'start');
        setLoadings([...startLoadings]);

        const result = await apiUtil.post(ApiConstants.URL_V1.SCHEDULE_PUBLIC({ scheduleId }), {}, {
          params: {
            companyId,
            teamId,
          },
        });

        dispatch({
          type: actionTypes.SET_CURRENT_SCHEDULE,
          currentSchedule: cloneDeep(result.data.schedule),
        });

        const status = handleStatusMsg(result, 'success');

        enqueueSnackbar(status.message, {
          variant: 'success',
        });

        cancel();
      } catch (err) {
        const status = handleStatusMsg(err, 'error');

        enqueueSnackbar(status.message, {
          variant: 'error',
        });
      } finally {
        const endLoadings = handleLoadings('setPublicCalendarMenu', [...loadings], 'end');
        setLoadings([...endLoadings]);
      }
    };
    togglePublicCalendarApi();
  }, [postSchedule]);

  const handlePostSchedule = (scheduleId) => {
    setPostSchedule(scheduleId);
  };
  const notes = 'Before subscribing, we need to set this schedule as a public calendar and anybody in the internet with the link could see it. Do you want to proceed?';

  return (
    <BoxPopOver left={left}>
      <div className={styles.headerSection}>
        <h1>
          Set as Public Calendar
        </h1>
        <CloseIcon onClick={cancel} />
      </div>
      <SeparatorLine />
      <div className={styles.bodySection}>
        <p>{notes}</p>
      </div>
      <div className={styles.actionSection}>
        <Button handleClick={() => handlePostSchedule(schedule._id)} wait="setPublicCalendarMenu" loadings={loadings} variant="success" size="sm">Proceed</Button>
      </div>
    </BoxPopOver>
  );
};

SetPublicCalendarMenu.propTypes = {
  schedule: PropTypes.object.isRequired,
  cancel: PropTypes.func.isRequired,
  left: PropTypes.bool.isRequired,
};

export default SetPublicCalendarMenu;
