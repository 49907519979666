import { cloneDeep } from 'lodash';
import { actionTypes } from '../reducers/reducer';
import { updateListSocket } from '../utilities/arrayUtil';
import { UserOnlineConstants } from '../constants';

/*
  Dispatcher
*/

function dispatchAllUserOnline({ allUserOnline }, dispatch) {
  dispatch({
    type: actionTypes.SET_ALL_USER_ONLINE,
    allUserOnline,
  });
}

function dispatchUpdateAllUserOnline({ updateAllUserOnline }, dispatch) {
  dispatch({
    type: actionTypes.UPDATE_ALL_USER_ONLINE,
    updateAllUserOnline,
  });
}

/*
    SetterDispatcher
  */

const setAllUserOnline = ({ allUserOnline }, dispatch) => {
  if (!allUserOnline) return;

  dispatchAllUserOnline({ allUserOnline: cloneDeep(allUserOnline) }, dispatch);
};

/* Helper */

const getUserOnlineStatus = ({
  userId,
  allUserOnline,
}) => {
  let status = 'offline';
  let lastActiveTimestamp = null;

  if (!userId || allUserOnline?.length < 1) {
    return { status, lastActiveTimestamp };
  }

  const isUserIdExist = allUserOnline.find((elem) => elem.user === userId);

  if (isUserIdExist) {
    status = isUserIdExist.status;
    lastActiveTimestamp = isUserIdExist.lastActiveTimestamp;
  }

  return { status, lastActiveTimestamp };
};

function sortMembersByStatusOnline(users, status) {
  if (!users) return [];
  if (!users && !status) return [];
  if (users && !status) return users;
  // Create a mapping of user _id to their corresponding status using reduce
  const statusMap = status.reduce((acc, item) => {
    acc[item.user] = item;
    return acc;
  }, {});

  // Sort the users based on status using reduce
  const sortedUsers = users.reduce((result, user) => {
    const userStatus = statusMap[user._id];

    // Create a new user object with additional fields
    const newUser = {
      ...user, // Copy all existing fields from the original user
      onlineStatus: userStatus ? userStatus.status : 'offline', // Add the status field, default to 'offline'
      lastActiveTimestamp: userStatus
        ? userStatus.lastActiveTimestamp : null, // Add lastActiveTimestamp
    };

    // Define the sorting order (online > idle > offline)
    const statusOrder = { online: 0, idle: 1, offline: 2 };

    // Find the index to insert the user based on status order and _id
    const insertIndex = result.findIndex((existingUser) => {
      const existingUserStatus = statusMap[existingUser._id];
      const existingStatusValue = existingUserStatus ? existingUserStatus.status : 'offline'; // Default to 'offline' if not found
      const statusValue = userStatus ? userStatus.status : 'offline'; // Default to 'offline' if not found

      if (statusOrder[existingStatusValue] === statusOrder[statusValue]) {
        return existingUser._id > user._id;
      }
      return statusOrder[existingStatusValue] > statusOrder[statusValue];
    });

    // Insert the user at the determined index
    if (insertIndex === -1) {
      result.push(newUser);
    } else {
      result.splice(insertIndex, 0, newUser);
    }

    return result;
  }, []);

  return sortedUsers;
}

/*
  Socket Callback Stuffs
*/

const incomingInitiateAllUserOnline = ({ allUserOnline }, dispatch) => {
  if (!allUserOnline) return;
  setAllUserOnline({ allUserOnline }, dispatch);
};

const incomingUserOnlineUpdate = ({ userOnline }, dispatch) => {
  if (!userOnline) return;
  const updateAllUserOnline = (currentAllUserOnline) => {
    const isUserOnlineExist = currentAllUserOnline.find((elem) => elem.user === userOnline.user);

    const typeAction = isUserOnlineExist ? UserOnlineConstants.typeCallback.EDIT
      : UserOnlineConstants.typeCallback.NEW;

    const isSameStatus = currentAllUserOnline.find(
      (elem) => elem.user === userOnline.user && elem.status === userOnline.status,
    );

    if (isSameStatus) return currentAllUserOnline;

    const newAllUserOnline = updateListSocket({
      newData: userOnline,
      currentList: currentAllUserOnline,
      typeAction,
      reverse: false,
    });

    return cloneDeep(newAllUserOnline);
  };

  dispatchUpdateAllUserOnline({ updateAllUserOnline }, dispatch);
};

export {
  incomingInitiateAllUserOnline,
  incomingUserOnlineUpdate,
  getUserOnlineStatus,
  sortMembersByStatusOnline,
};
