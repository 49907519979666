import React, { memo, useState } from 'react';
import PropTypes from 'prop-types';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import styles from './SelectWeekCardSnapshotMenu.module.css';
import Color from '../../../../../../themes/colors';
import ButtonTextWithCheck from '../../../../../../components/Container/FilterBar/ButtonTextWithCheck/ButtonTextWithCheck';

const SelectWeekCardSnapshotMenu = ({
  listWeeks,
  onClickWeek,
  selectedWeek,
}) => (
  <div className={styles.container}>
    {listWeeks.map((week) => (
      <ButtonTextWithCheck
        text={`W${week}`}
        onClick={() => onClickWeek(week)}
        checked={selectedWeek === week}
      />
    ))}
  </div>
);

SelectWeekCardSnapshotMenu.propTypes = {
  listWeeks: PropTypes.array,
  onClickWeek: PropTypes.func,
  selectedWeek: PropTypes.string,
};

SelectWeekCardSnapshotMenu.defaultProps = {
  listWeeks: [],
  onClickWeek: () => null,
  selectedWeek: null,
};

export default memo(SelectWeekCardSnapshotMenu);
