import React, {
  memo, useCallback,
  useEffect, useState,
} from 'react';
import PropTypes from 'prop-types';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import styles from './CompanyDetailPage.module.css';
import { DisplayTextBody, DisplayTextHeadline } from '../../../components/UI_V2/Display/Text';
import Color from '../../../themes/colors';
import introImage from '../../../assets/PrivateChatPage/intro-chat-section.png';
import { SurfacePaperMain } from '../../../components/UI_V2/Surface/Paper';
import { LayoutMain } from '../../../components/UI_V2/Layout';
import Header from '../Header/Header';
import ChartList from './ChartList/ChartList';
import { DisplayDividerMain } from '../../../components/UI_V2/Display/Divider';
import CompanyInfo from './CompanyInfo/CompanyInfo';
import { dateBeautifyFormatter } from '../../../utilities/dateUtil';

const CompanyDetailPage = () => {
  const [company, setCompany] = useState();
  const [headerText, setHeaderText] = useState({
    title: 'Company Detail Page',
    desc: 'List of activities and information company',
  });

  const handleSetHeaderText = useCallback((values) => {
    const { companyName, fromDate, untilDate } = values;

    setHeaderText({
      title: companyName,
      desc: `Period : ${dateBeautifyFormatter(fromDate, true)} - ${dateBeautifyFormatter(untilDate, true)}`,
    });
  }, []);

  const handleSetCompany = useCallback((value) => {
    setCompany(value);
  }, []);

  return (
    <LayoutMain>
      <SurfacePaperMain>
        <div className={styles.container}>
          <div className={styles.header}>
            <Header
              title={headerText.title}
              desc={headerText.desc}
            />
          </div>
          <div className={styles.body}>
            <div className={styles.chartSection}>
              <div className={styles.subTitle}>
                <DisplayTextHeadline
                  decoration="bold"
                >
                  Activities
                </DisplayTextHeadline>
              </div>
              <div className={styles.chart}>
                <ChartList
                  handleSetHeaderText={handleSetHeaderText}
                  handleSetCompany={handleSetCompany}
                />
              </div>
            </div>
            <DisplayDividerMain />
            <div className={styles.infoSection}>
              <div className={styles.subTitle}>
                <DisplayTextHeadline
                  decoration="bold"
                >
                  Information
                </DisplayTextHeadline>
              </div>
              <div className={styles.companyInfo}>
                <CompanyInfo
                  company={company}
                />
              </div>
            </div>
          </div>
        </div>
      </SurfacePaperMain>
    </LayoutMain>
  );
};

CompanyDetailPage.propTypes = {};

CompanyDetailPage.defaultProps = {};

export default memo(CompanyDetailPage);
