import React, { memo, useState } from 'react';
import PropTypes from 'prop-types';
import { FormControl, useMediaQuery } from '@mui/material';
import { enqueueSnackbar } from 'notistack';
import styles from './CreateChecklist.module.css';
import Color from '../../../themes/colors';
import { DisplayTextBody } from '../../../components/UI_V2/Display/Text';
import { InputButtonMain } from '../../../components/UI_V2/Input/Button';
import { DisplayDividerMain } from '../../../components/UI_V2/Display/Divider';
import { InputTextFieldMain } from '../../../components/UI_V2/Input/TextField';
import { NavigationMenuBasic } from '../../../components/UI_V2/Navigation/Menu';
import handleStatusMsg from '../../../utilities/handleStatusMsg';
import handleLoadings from '../../../utilities/handleLoadings';
import { createChecklistApi } from '../../../actions/ChecklistActions';
import { validateTitle } from '../../../utilities/inputValidatorUtil';
import { useEnterKeyForExecutingFunction } from '../../../hooks/HelperHooks';

const CreateChecklist = ({
  position,
  onClose,
  cardId,
  teamId,
  companyId,
}) => {
  const isMobile = useMediaQuery('(max-width:720px)');
  const [newChecklistName, setNewChecklistName] = useState('');
  const [loadings, setLoadings] = useState([]);

  const clearValue = () => {
    setNewChecklistName('');
  };

  const handleChanged = (event) => {
    const { value } = event.target;

    setNewChecklistName(() => (value));
  };

  const handleCreateChecklistApi = async () => {
    try {
      const startLoadings = handleLoadings('createChecklist', [...loadings], 'start');
      setLoadings([...startLoadings]);

      const { isValid, errorMsg } = validateTitle(newChecklistName);

      if (!isValid) {
        enqueueSnackbar(errorMsg, {
          variant: 'error',
        });
        return;
      }

      const result = await createChecklistApi({
        cardId,
        companyId,
        teamId,
        checklistName: newChecklistName,
      });

      const status = handleStatusMsg(result, 'success');

      enqueueSnackbar(status.message, {
        variant: 'success',
      });

      clearValue();
      onClose();
    } catch (error) {
      const status = handleStatusMsg(error, 'error');
      if (status?.message) {
        enqueueSnackbar(status.message, {
          variant: 'error',
        });
      }
    } finally {
      const endLoadings = handleLoadings('createChecklist', [...loadings], 'end');
      setLoadings([...endLoadings]);
    }
  };

  // hook for press enter executing create checklist;
  useEnterKeyForExecutingFunction(handleCreateChecklistApi);

  return (
    <NavigationMenuBasic size="mdWider" onClose={onClose} position={position}>
      <div className={styles.container}>
        <div className={styles.header}>
          <DisplayTextBody decoration="bold" mode="16">
            Buat Ceklis
          </DisplayTextBody>
        </div>
        <DisplayDividerMain marginBottom="0.5rem" marginTop="0.2rem" borderColor="gray" />
        <div className={styles.body}>
          <div className={styles.form}>
            <div className={styles.formArea}>
              <InputTextFieldMain
                placeholder="Judul ceklis"
                name="name"
                onChange={(event) => handleChanged(event)}
                value={newChecklistName}
                autoFocus
              />
            </div>
          </div>
        </div>
        <div className={styles.footer}>
          <div className={styles.footerButton}>
            <InputButtonMain
              handleClick={handleCreateChecklistApi}
              wait="createChecklist"
              loadings={loadings}
              size="md"
              variant="success"
            >
              Buat
            </InputButtonMain>
          </div>
          <div className={styles.footerButton}>
            <InputButtonMain
              variant="light"
              handleClick={onClose}
              size="md"
            >
              Batal
            </InputButtonMain>
          </div>
        </div>
      </div>
    </NavigationMenuBasic>
  );
};

CreateChecklist.propTypes = {
  position: PropTypes.string,
  onClose: PropTypes.func,
  cardId: PropTypes.string.isRequired,
  teamId: PropTypes.string.isRequired,
  companyId: PropTypes.string.isRequired,
};

CreateChecklist.defaultProps = {
  position: 'topRight',
  onClose: () => null,
};

export default memo(CreateChecklist);
