import React, { useCallback, memo, useEffect } from 'react';
import { useParams, useLocation } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import PropTypes from 'prop-types';
import { isEqual } from 'lodash';
import styles from './AttachFileContainer.module.css';
import handleStatusMsg from '../../utilities/handleStatusMsg';
import apiUtil from '../../utilities/apiUtil';
import { ApiConstants } from '../../constants';
import { useDropZoneFile } from '../../hooks/DropFileHook';

const AttachFileContainer = ({
  card,
  handleLoading,
  type,
  cancel,
  children,
  disableClickUpload,
  forceTeamId,
  handleUploadForm,
}) => {
  const { enqueueSnackbar } = useSnackbar();

  const params = useParams();
  const location = useLocation();

  // const {
  //   companyId, chatId, groupChatId, cardId, bucketId,
  // } = params;

  // const teamId = params.teamId || forceTeamId;

  // let url;
  // let id;

  // switch (type) {
  //   case 'chat':
  //     id = chatId;
  //     url = `${process.env.REACT_APP_PRIMARY_API_URL}/api/v1/chats/${id}/attachments`;
  //     break;
  //   case 'groupChat':
  //     id = groupChatId;
  //     url = `${process.env.REACT_APP_PRIMARY_API_URL}
  // ${ApiConstants.URL_V1.GROUP_CHAT_ATTACHMENT({ groupChatId: id })}`;
  //     break;
  //   case 'card':
  //     id = cardId || card._id;
  //     url = `${process.env.REACT_APP_PRIMARY_API_URL}/api/v1/cards/${id}/attachments`;
  //     break;
  //   case 'file':
  //     id = bucketId;
  //     url = `${process.env.REACT_APP_PRIMARY_API_URL}/api/v1/buckets/${id}/files`;
  //     break;
  //   default:
  //     break;
  // }

  const uploadFileByDrop = useCallback(async (files) => {
    if (!files) return;

    const data = new FormData();
    for (let i = 0; i < files.length; i += 1) {
      data.append('file', files[i]);
    }

    handleLoading(true);
    try {
      const result = await handleUploadForm(data);

      const status = handleStatusMsg(result, 'success');

      enqueueSnackbar(status.message, {
        variant: 'success',
      });
    } catch (err) {
      const getErrorMessageDropzoneFile = files?.[0]?.errors?.[0];
      const messageError = getErrorMessageDropzoneFile === undefined
        ? err : getErrorMessageDropzoneFile;
      const status = handleStatusMsg(messageError, 'error');

      enqueueSnackbar(status.message, {
        variant: 'error',
      });
    } finally {
      handleLoading(false);
      if (cancel) cancel();
    }
  }, [location]);

  const onDrop = useCallback(async (acceptedFiles, fileRejections) => {
    if (acceptedFiles.length < 1) {
      await uploadFileByDrop(fileRejections);
    } else {
      await uploadFileByDrop(acceptedFiles);
    }
  }, [location]);

  const {
    getRootProps,
    getInputProps,
  } = useDropZoneFile({
    noDragEventsBubbling: true,
    onDrop,
  });

  return (
    <div className={styles.container} {...getRootProps()}>
      {disableClickUpload ? null : <input {...getInputProps()} />}
      {children}
    </div>
  );
};

AttachFileContainer.propTypes = {
  children: PropTypes.node.isRequired,
  type: PropTypes.string.isRequired,
  card: PropTypes.object.isRequired,
  handleLoading: PropTypes.func.isRequired,
  cancel: PropTypes.func.isRequired,
  disableClickUpload: PropTypes.bool,
  forceTeamId: PropTypes.string,
  handleUploadForm: PropTypes.func,
};

AttachFileContainer.defaultProps = {
  disableClickUpload: false,
  forceTeamId: undefined,
  handleUploadForm: () => null,
};

const isComponentDataEqual = (prevProps, nextProps) => {
  const { card, type } = nextProps;
  return isEqual(prevProps.card, card)
  && prevProps.type === type;
};

export default memo(AttachFileContainer, isComponentDataEqual);
