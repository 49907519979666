import { AdministratorConstants, ApiConstants } from '../constants';
import { METRIC_API_URL } from '../constants/ApiConstants';
import apiUtil from '../utilities/apiUtil';
import { getLastListCreatedAt, mergeObjectListAndRemoveDuplicate } from '../utilities/arrayUtil';
import { ErrorException } from '../utilities/handleError';

const initiateMetricCount = async ({
  setMetricCount,
  filters,
}) => {
  try {
    const { fromDate } = filters.filterFromUntilDate;
    const { untilDate } = filters.filterFromUntilDate;
    const { filterTrial } = filters;
    const result = await apiUtil.get(ApiConstants.URL_V1.METRICS_COUNT(), {
      params: {
        fromDate,
        untilDate,
        'filter[isOnTrial]': filterTrial,
      },
    },
    {},
    METRIC_API_URL);

    setMetricCount(result?.data.data);

    return result?.data;
  } catch (error) {
    throw new ErrorException(error);
  }
};

async function initiateMetricCompanies({
  limit = AdministratorConstants.limitMetricCompanies,
  setCurrentMetricCompanies,
  setPreviousMetricCompanies,
  initialStatePreviousMetricCompanies,
  filters,
}) {
  try {
    const { fromDate } = filters.filterFromUntilDate;
    const { untilDate } = filters.filterFromUntilDate;
    const { filterTrial, filterMetricCategory } = filters;

    const result = await apiUtil.get(ApiConstants.URL_V1.METRICS(), {
      params: {
        fromDate,
        untilDate,
        'filter[isOnTrial]': filterTrial,
        'filter[category]': filterMetricCategory,
        limit,
      },
    }, {}, METRIC_API_URL);

    setCurrentMetricCompanies(result?.data);
    setPreviousMetricCompanies(initialStatePreviousMetricCompanies);

    return result;
  } catch (error) {
    throw new ErrorException(error);
  }
}

async function loadMoreMetricCompanies({
  currentMetricCompanies,
  limit = AdministratorConstants.limitMetricCompanies,
  setCurrentMetricCompanies,
  setPreviousMetricCompanies,
  filters,
}) {
  try {
    const createdAt = getLastListCreatedAt(currentMetricCompanies.data);

    const { fromDate } = filters.filterFromUntilDate;
    const { untilDate } = filters.filterFromUntilDate;
    const { filterTrial, filterMetricCategory } = filters;

    const result = await apiUtil.get(ApiConstants.URL_V1.METRICS(), {
      params: {
        fromDate,
        untilDate,
        'filter[isOnTrial]': filterTrial,
        'filter[category]': filterMetricCategory,
        createdAt,
        limit,
      },
    }, {}, METRIC_API_URL);

    const mergedDataMetricCompanies = mergeObjectListAndRemoveDuplicate({
      currentObjectList: currentMetricCompanies,
      nextObjectList: result?.data,
      keyObject: 'data',
    });

    const mergedMetricCompanies = {
      ...result?.data,
      data: mergedDataMetricCompanies?.data,
    };

    setPreviousMetricCompanies(result?.data);
    setCurrentMetricCompanies(mergedMetricCompanies);

    return result;
  } catch (error) {
    throw new ErrorException(error);
  }
}

async function initiateMetricCompanyActivities({
  setCurrentMetricCompanyActivities,
  filters,
  companyId,
}) {
  try {
    const { fromDate } = filters.filterFromUntilDate;
    const { untilDate } = filters.filterFromUntilDate;

    const result = await apiUtil.get(
      ApiConstants.URL_V1.METRICS_COMPANY_ACTIVITIES({ companyId }), {
        params: {
          fromDate,
          untilDate,
        },
      },
      {},
      METRIC_API_URL,
    );

    setCurrentMetricCompanyActivities(result?.data);
    return result;
  } catch (error) {
    throw new ErrorException(error);
  }
}

async function initiateMetricCounts({
  setCurrentMetricCounts,
  filters,
}) {
  try {
    const { fromDate } = filters.filterFromUntilDate;
    const { untilDate } = filters.filterFromUntilDate;

    const result = await apiUtil.get(
      ApiConstants.URL_V1.METRICS_COUNT_DAILY(), {
        params: {
          fromDate,
          untilDate,
        },
      },
      {},
      METRIC_API_URL,
    );

    setCurrentMetricCounts(result?.data);
    return result;
  } catch (error) {
    throw new ErrorException(error);
  }
}

async function initiateTractionModels({
  setCurrentTractionModels,
  filters,
}) {
  try {
    const { fromDate } = filters.filterFromUntilDate;
    const { untilDate } = filters.filterFromUntilDate;

    const result = await apiUtil.get(
      ApiConstants.URL_V1.TRACTION_MODELS_COUNT_DAILY(), {
        params: {
          fromDate,
          untilDate,
        },
      },
      {},
      METRIC_API_URL,
    );

    setCurrentTractionModels(result?.data);
    return result;
  } catch (error) {
    throw new ErrorException(error);
  }
}

export {
  initiateMetricCount,
  initiateMetricCompanies,
  loadMoreMetricCompanies,
  initiateMetricCompanyActivities,
  initiateMetricCounts,
  initiateTractionModels,
};
