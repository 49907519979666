import React, {
  useState, useContext, useEffect, memo,
} from 'react';
import { useParams } from 'react-router-dom';
import Form from 'react-bootstrap/Form';
import { isEqual } from 'lodash';
import { useSnackbar } from 'notistack';
import PropTypes from 'prop-types';
import { ShowUpdateNameListContext } from '../ListContext';
import OverlayButton from '../../../components/UI/Button/OverlayButton/OverlayButton';
import handleStatusMsg from '../../../utilities/handleStatusMsg';
import handleLoadings from '../../../utilities/handleLoadings';
import apiUtil from '../../../utilities/apiUtil';
import { ListActions } from '../../../actions';

const ChangeListNameForm = ({ list }) => {
  const [newListUpdatedName, setNewListUpdatedName] = useState({ value: list.name });
  const [listUpdatedName, setListUpdatedName] = useState();
  const [showUpdateNameList, setShowUpdateNameList] = useContext(ShowUpdateNameListContext);
  const [loadings, setLoadings] = useState([]);
  const { enqueueSnackbar } = useSnackbar();

  const params = useParams();
  const { companyId, boardId, teamId } = params;

  useEffect(() => {
    if (listUpdatedName !== undefined) {
      const updateApiListName = async () => {
        const { listId } = listUpdatedName;
        const newListName = listUpdatedName.listNewName;

        try {
          const startLoadings = handleLoadings('changeListName', [...loadings], 'start');
          setLoadings([...startLoadings]);
          const body = { selector: { boardId }, data: { name: newListName } };
          const result = await ListActions.updateList({
            body, listId, companyId, teamId,
          });

          const status = handleStatusMsg(result, 'success');

          enqueueSnackbar(status.message, {
            variant: 'success',
          });

          setShowUpdateNameList('');
        } catch (err) {
          const status = handleStatusMsg(err, 'error');

          enqueueSnackbar(status.message, {
            variant: 'error',
          });
        } finally {
          const endLoadings = handleLoadings('changeListName', [...loadings], 'end');
          setLoadings([...endLoadings]);
        }
      };
      updateApiListName();
    }
  }, [listUpdatedName]);

  const handleChangeName = (event) => {
    const { value } = event.target;
    setNewListUpdatedName((prevValue) => ({
      ...prevValue,
      value,
    }));
  };

  const handleSubmitUpdateName = (listId) => {
    if (newListUpdatedName === undefined
        || newListUpdatedName.value === undefined || newListUpdatedName.value.length < 1) {
      enqueueSnackbar('List name cannot be empty', {
        variant: 'error',
      });
      return;
    }

    setListUpdatedName({
      listId,
      listNewName: newListUpdatedName.value,
    });
  };

  return (
    <OverlayButton wait="changeListName" loadings={loadings}>
      <Form onSubmit={(event) => {
        event.preventDefault();
        handleSubmitUpdateName(list._id);
      }}
      >
        <Form.Control autoFocus onChange={(event) => handleChangeName(event)} as="input" value={newListUpdatedName.value} />
      </Form>
    </OverlayButton>
  );
};

ChangeListNameForm.propTypes = {
  list: PropTypes.object.isRequired,
};

const isComponentDataEqual = (prevProps, nextProps) => {
  const { list } = nextProps;
  return isEqual(prevProps.list, list);
};

export default memo(ChangeListNameForm, isComponentDataEqual);
