import React, { useEffect, useState, memo } from 'react';
import { useHistory } from 'react-router-dom';
import _ from 'lodash';
import { useSnackbar } from 'notistack';
import styles from './WelcomeLinkInvitationPage.module.css';
import Title from '../../components/Title/Title';
import LogoHeader from '../../components/UI/LogoHeader/LogoHeader';
import { DisplayTextHeadline, DisplayTextSubHeadline, DisplayTextBody } from '../../components/UI_V2/Display/Text';
import Color from '../../themes/colors';
import LinkNoDecor from '../../components/LinkNoDecor/LinkNoDecor';
import { InputButtonMain } from '../../components/UI_V2/Input/Button';
import { InputTextFieldMain } from '../../components/UI_V2/Input/TextField';
import handleStatusMsg from '../../utilities/handleStatusMsg';
import handleLoadings from '../../utilities/handleLoadings';

const WelcomeLinkInvitationPage = () => {
  const [inviteUrl, setInviteUrl] = useState();
  const [loadings, setLoadings] = useState([]);
  const { enqueueSnackbar } = useSnackbar();
  const history = useHistory();

  const handleChanged = (event) => {
    const { value } = event.target;

    setInviteUrl((prevValue) => ({
      ...prevValue,
      value,
    }));
  };

  const endLoading = () => {
    const endLoadings = handleLoadings('submitToken', [...loadings], 'end');
    setLoadings([...endLoadings]);
  };

  const handleSubmit = () => {
    const startLoadings = handleLoadings('submitToken', [...loadings], 'start');
    setLoadings([...startLoadings]);
    // check dulu apakah bener ada link react client slash invitation
    const { value } = inviteUrl;
    if (!value || value?.length < 1) {
      enqueueSnackbar('Link Cannot be empty', {
        variant: 'error',
      });
      endLoading();
      return;
    }
    const isFirstUrlCorrect = value.includes(`${process.env.REACT_APP_BASE_CLIENT_URL}/invitation/`);
    // kalo ada extract tokennya
    if (!isFirstUrlCorrect) {
      enqueueSnackbar('Your invitation link is not correct', {
        variant: 'error',
      });
      endLoading();
      return;
    }

    const splitUrls = value.split('/invitation/');
    const [url, token] = splitUrls;
    // history push ke /invitation/:tokenId
    if (token) {
      history.push(`/invitation/${token}`);
    }
    endLoading();
  };

  return (
    <>
      <Title title="Masukkan Link Undangan" />
      <LogoHeader showLogout />
      <div className={styles.container}>
        <div className={styles.title}>
          <DisplayTextHeadline
            color={Color.blueNavyCicle}
            mode="48"
            decoration="bold"
          >
            Halo, dan Selamat Datang di Cicle!
          </DisplayTextHeadline>
        </div>
        <div className={styles.text}>
          <DisplayTextSubHeadline
            color={Color.gray3}
            mode="18"
          >
            Kamu memilih opsi buat masukin Link Undangan dari perusahaan tempat kamu bekerja ya.
          </DisplayTextSubHeadline>
        </div>
        <div className={styles.formSection}>
          <div className={styles.form}>
            <div className={styles.form__header}>
              <DisplayTextSubHeadline
                color={Color.yellowNavyCicle}
                mode="18"
                decoration="extra_bold"
              >
                Tolong masukin Link Undangan yang kamu dapetin dari email
              </DisplayTextSubHeadline>
            </div>
            <div className={styles.form__input}>
              <InputTextFieldMain
                // key="welcomeLinkInvitationPage"
                placeholder="https://my.cicle.app/invitation/589shsdfjs7fsd8fdshsfd9h98a"
                name="desc"
                onChange={(event) => handleChanged(event)}
                value={inviteUrl?.value}
                autoFocus
              />
            </div>
            <div className={styles.form__desc}>
              <DisplayTextBody
                color={Color.blueNavyCicle}
                mode="11"
              >
                Kamu bisa meminta Manager atau Bos kamu untuk mengundangmu ke dalam Perusahaan.
              </DisplayTextBody>
            </div>
          </div>
          <div className={styles.button}>
            <InputButtonMain
              border="rounded"
              wide="wider"
              wait="submitToken"
              handleClick={handleSubmit}
              loadings={loadings}
            >
              Selanjutnya
            </InputButtonMain>
          </div>
        </div>
      </div>
    </>
  );
};

export default memo(WelcomeLinkInvitationPage);
