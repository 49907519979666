import React, { useState, useEffect, useContext } from 'react';
import draftToHtml from 'draftjs-to-html';
import EditIcon from '@mui/icons-material/Edit';
import PropTypes from 'prop-types';
import styles from './ManageDesc.module.css';
import checkIsJson from '../../../utilities/checkIsJson';
import InputFormDesc from './InputFormDesc/InputFormDesc';
import EditFormDesc from './EditFormDesc/EditFormDesc';
import EditorView from '../../FroalaEditor/EditorView/EditorView';
import ButtonDiv from '../../../components/UI/Button/ButtonDiv/ButtonDiv';
import { checkIfUserAuthorizedToEditData } from '../../../actions/UserActions';
import { GlobalContext } from '../../../contexts/GlobalStateProvider';

const ManageDesc = ({ card, autoOpen }) => {
  const [{ user, currentRoleUser, currentTeamBelowRole }] = useContext(GlobalContext);
  const [showFormPostDesc, setShowFormPostDesc] = useState(false);
  const [showEditFormPostDesc, setShowEditFormPostDesc] = useState(false);
  const [isDescEmpty, setIsDescEmpty] = useState(false);
  const [isInitialContentReady, setIsInitialContentReady] = useState(false);
  const [editorModel, setEditorModel] = useState();

  useEffect(() => {
    if (card === undefined) {
      return;
    }

    if (card.desc === undefined) {
      setIsDescEmpty(true);
      return;
    }

    let initialContent;
    if (checkIsJson(card.desc)) {
      initialContent = draftToHtml(JSON.parse(card.desc));
    } else {
      initialContent = card.desc;
    }

    setEditorModel(initialContent);
    setIsInitialContentReady(true);
    setIsDescEmpty(false);
  }, [card]);

  const handleShowPostDesc = () => {
    setShowFormPostDesc(true);
  };

  const handleShowEditPostDesc = () => {
    setShowEditFormPostDesc(true);
  };

  const handleCancel = () => {
    setShowFormPostDesc(false);
    setShowEditFormPostDesc(false);
  };

  useEffect(() => {
    if (autoOpen && !isDescEmpty) {
      setShowEditFormPostDesc(true);
      setShowFormPostDesc(false);
    } else if (autoOpen && !showFormPostDesc && isDescEmpty) {
      setShowFormPostDesc(true);
      setShowEditFormPostDesc(false);
    }
  }, [isInitialContentReady, isDescEmpty]);

  return (
    <>
      <div className={styles.header}>
        <p className={styles.mediumTitle}>Catatan</p>
        {!showEditFormPostDesc && !isDescEmpty
        && checkIfUserAuthorizedToEditData(card, user, currentRoleUser, currentTeamBelowRole)
          ? (
            <ButtonDiv onClick={handleShowEditPostDesc} className={styles.editIcon}>
              <EditIcon />
            </ButtonDiv>
          ) : null}
      </div>

      <div>
        {!showFormPostDesc && isDescEmpty
          ? (
            <ButtonDiv
              onClick={checkIfUserAuthorizedToEditData(card, user,
                currentRoleUser, currentTeamBelowRole)
                ? () => handleShowPostDesc()
                : () => null}
              className={styles.sectionEmptyDesc}
            >
              {checkIfUserAuthorizedToEditData(card, user, currentRoleUser, currentTeamBelowRole)
              && <p>Tambahkan catatan tugas disini...</p>}
              {!checkIfUserAuthorizedToEditData(card, user, currentRoleUser, currentTeamBelowRole)
              && <p>Kamu ga punya akses untuk edit catatan.</p>}
            </ButtonDiv>
          )
          : null}
        {showFormPostDesc
          ? (
            <div className={styles.sectionInputForm}>
              <InputFormDesc card={card} handleCancel={handleCancel} />
            </div>
          ) : null}
        {!showEditFormPostDesc && !isDescEmpty
          ? (
            <div className={styles.sectionDesc}>
              {isInitialContentReady ? <EditorView model={editorModel} /> : <p>Please wait...</p>}
            </div>
          ) : null}
        {showEditFormPostDesc
          ? (
            <div className={styles.sectionInputForm}>
              <EditFormDesc card={card} handleCancel={handleCancel} />
            </div>
          ) : null}
      </div>

    </>
  );
};

ManageDesc.propTypes = {
  card: PropTypes.object.isRequired,
  autoOpen: PropTypes.bool.isRequired,
};

export default ManageDesc;
