import React, { useEffect, useContext } from 'react';
import { useParams, useHistory, useLocation } from 'react-router-dom';
import axios from 'axios';
import LoadingWithAnimation from '../../components/UI/LoadingWithAnimation/LoadingWithAnimation';
import MainLayout from '../../components/Layout/MainLayout/MainLayout';
import styles from './EmailRegistrationVerificationPage.module.css';
import Title from '../../components/Title/Title';
import LogoHeader from '../../components/UI/LogoHeader/LogoHeader';
import handleStatusMsg from '../../utilities/handleStatusMsg';
import { GlobalContext } from '../../contexts/GlobalStateProvider';
import { actionTypes } from '../../reducers/reducer';
import {
  AuthActions, CheckLoginActions,
  CompanyActions, TeamActions,
} from '../../actions';

function EmailRegistrationVerificationPage() {
  const [{}, dispatch] = useContext(GlobalContext);
  const params = useParams();
  const history = useHistory();
  const location = useLocation();

  const emailInvitationToken = localStorage.getItem('emailInvitationToken');

  const checkAndGoFromEmailInvitation = (emailTokenKey) => {
    history.push(`/invitation/${emailTokenKey}`);
  };

  const verifyEmailRegistrationApi = async () => {
    const { tokenKey } = params;
    try {
      const result = await AuthActions.emailRegistrationVerification({ tokenKey });
      const { token, user } = result;

      if (user && token) {
        const resultCompanies = await CompanyActions.initiateCompanies({}, dispatch);
        CheckLoginActions.setUserAndToken({ user, token }, dispatch);

        if (emailInvitationToken) {
          checkAndGoFromEmailInvitation(emailInvitationToken);
          return;
        }

        if (resultCompanies && resultCompanies.length > 0) {
          const resultCompany = await CheckLoginActions.initiateCurrentCompany(
            { previousPath: '/', companies: resultCompanies, userId: user._id },
            dispatch,
          );

          TeamActions.initiateTeams(
            {
              currentCompany: resultCompany,
              userId: user._id,
            },
            dispatch,
          );
        }

        history.push('/success');
      } else {
        dispatch({
          type: actionTypes.SET_ERROR_RESPONSE,
          errorResponse: { message: 'User or token not found' },
        });

        history.push(`/errors?previousPath=${location.pathname}`);
      }
    } catch (err) {
      const status = handleStatusMsg(err, 'error');

      dispatch({
        type: actionTypes.SET_ERROR_RESPONSE,
        errorResponse: { message: status.message },
      });

      history.push(`/errors?previousPath=${location.pathname}`);
    }
  };

  useEffect(() => {
    verifyEmailRegistrationApi();
  }, []);

  return (
    <>
      <Title title="Verifikasi Link Registrasi" />
      <LogoHeader />
      <MainLayout>
        <LoadingWithAnimation>
          <h1>Tunggu ya...</h1>
          <h1>Memverifikasi registrasi kamu</h1>
        </LoadingWithAnimation>
      </MainLayout>
    </>
  );
}

export default EmailRegistrationVerificationPage;
