/* eslint-disable jsx-a11y/media-has-caption */
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useLocation } from 'react-router-dom';
import { useMediaQuery } from '@mui/material';
import PlayCircleIcon from '@mui/icons-material/PlayCircle';
import styles from './AttachmentItemSnapshot.module.css';
import DisplayAvatarMember from '../../../../components/UI_V2/Display/Avatar/Member';
import { AttachmentActions } from '../../../../actions';
import PdfThumbnail from '../../../../components/PdfThumbnail/PdfThumbnail';
import VideoPlayerThumbnail from '../../../../components/VideoPlayerThumbnail/VideoPlayerThumbnail';

const AttachmentItemSnapshot = ({ attachment }) => {
  const isMobile = useMediaQuery('(max-width:720px)');
  const [name, setName] = useState();
  const [imageUrl, setImageUrl] = useState();
  const [isImage, setIsImage] = useState();
  const [isVideo, setIsVideo] = useState();
  const [isPdf, setIsPdf] = useState();

  const location = useLocation();

  const isAttachmentDeleted = attachment.isDeleted;

  useEffect(() => {
    if (attachment.name === undefined) return;

    let tempName;
    if (attachment.name.length < 40) {
      tempName = attachment.name;
    } else {
      tempName = `${attachment.name.slice(0, 40)}...`;
    }

    setName(tempName);

    const attachmentUrl = attachment?.url?.charAt(0) === '/'
      ? process.env.REACT_APP_PRIMARY_API_URL + attachment?.url
      : attachment?.url;

    const result = AttachmentActions.getMimeTypeUrl(
      { mimeType: attachment.mimeType, fileUrl: attachmentUrl },
    );

    setImageUrl(result?.imageUrl);
    setIsImage(result?.isImage);
    setIsVideo(result?.isVideo);
    setIsPdf(result?.isPdf);
  }, [attachment]);

  return (

    <div key={attachment._id} className={styles.AttachmentItem}>
      <div className={styles.mainContainerNotSelf}>
        <div className={styles.photoSection}>
          <DisplayAvatarMember
            src={attachment.creator?.photoUrl}
            size="sm"
          />
        </div>
        <div className={styles.bodySection} style={{ backgroundColor: '#ebecf1' }}>
          <div className={styles.headerAttachmentItem}>
            <div className={styles.senderName}>
              <p>{attachment.creator?.fullName}</p>
            </div>
          </div>
          <div className={styles.contentAttachmentItem}>
            <div className={styles.main__icon}>
              {isAttachmentDeleted
                ? <i>{name}</i>
                : (
                  <>
                    {(!isVideo && !isPdf) && (<img src={imageUrl} />)}
                    {isVideo
        && (
          <VideoPlayerThumbnail
            videoUrl={imageUrl}
            variant="chatSnapshot"
          />
        )}
                    {isPdf && (
                    <PdfThumbnail fileUrl={imageUrl} height={40} />
                    )}
                  </>
                )}
            </div>
          </div>

        </div>
      </div>
    </div>
  );
};

AttachmentItemSnapshot.propTypes = {
  attachment: PropTypes.object.isRequired,
};

export default AttachmentItemSnapshot;
