import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { Box } from '@mui/material';
import styles from './FeatureTypeFilterSection.module.css';
import { useDelayShowHideHandler, useSearch, useSelectItemsOnList } from '../../../../hooks/HelperHooks';
import { InputSearchGeneral } from '../../../UI_V2/Input/Search';
import { DisplayTextBody, DisplayTextSubBody } from '../../../UI_V2/Display/Text';
import ButtonTextWithCheck from '../ButtonTextWithCheck/ButtonTextWithCheck';
import ButtonDiv from '../../../UI/Button/ButtonDiv/ButtonDiv';
import { limitChar } from '../../../../utilities/stringUtil';
import { DisplayDividerMain } from '../../../UI_V2/Display/Divider';
import Color from '../../../../themes/colors';
import ButtonTextWithCheckBox from '../ButtonTextWithCheckBox/ButtonTextWithCheckBox';

const FeatureTypeFilterSection = ({
  searchHook,
  showAllFeatureTypes,
  handleShowAllFeatureTypes,
  handleHideAllFeatureTypes,
  checkFeatureTypes,
  handleClickFeatureType,
  isAllFeatureTypesSelected,
  handleClickAllFeatureTypes,
}) => {
  const {
    searchResult,
    keyword,
    keywordArray,
    setKeyword,
    isTyping,
    onChange,
    resetSearch,
  } = searchHook;

  return (
    <Box className={styles.container}>
      <Box className={styles.titleBox}>
        <DisplayTextBody>
          Filter Fitur
        </DisplayTextBody>
      </Box>
      <DisplayDividerMain borderColor={Color.gray6} marginTop="0.4rem" marginBottom="0.4rem" />
      <Box className={styles.searchBox}>
        <InputSearchGeneral
          onChange={onChange}
          value={keyword}
          placeholder="Cari fitur..."
          onClickCloseIcon={resetSearch}
          size="sm"
          autoFocus={false}
        />
      </Box>
      <DisplayDividerMain borderColor={Color.gray6} marginTop="0.4rem" marginBottom="0.4rem" />
      <div className={styles.selectAll}>
        <ButtonTextWithCheckBox
          text="Pilih Semua"
          textDecoration="bold"
          textMode="12"
          onClick={handleClickAllFeatureTypes}
          checked={isAllFeatureTypesSelected}
        />
      </div>
      <DisplayDividerMain borderColor={Color.gray6} marginTop="0.4rem" marginBottom="0px" />
      <Box className={styles.body}>
        {searchResult.length < 1
          && <DisplayTextSubBody>No featureType&apos;s found</DisplayTextSubBody>}
        {/* {searchResult.length > 0
          && (
          <ButtonTextWithCheck
            text="All"
            onClick={() => handleClickAll(featureTypes)}
            checked={isAllFeatureTypesSelected}
          />
          )} */}

        {searchResult.map((featureType) => (
          <ButtonTextWithCheckBox
            text={limitChar(featureType?.name, 30)}
            onClick={() => handleClickFeatureType(featureType._id)}
            checked={checkFeatureTypes.some(
              (checkFeatureType) => checkFeatureType === featureType._id,
            )}
          />
        ))}
        {/* {showAllFeatureTypes
          ? (
            <>
              {searchResult.map((featureType, index) => {
                if (index === (searchResult.length - 1)) {
                  return (
                    <>
                      <ButtonTextWithCheck
                        text={limitChar(featureType?.name, 30)}
                        onClick={() => handleClickFeatureType(featureType._id)}
                        checked={checkFeatureTypes.some(
                          (checkFeatureType) => checkFeatureType === featureType._id,
                        )}
                      />
                      {index >= 4 && (
                      <ButtonDiv onClick={handleHideAllFeatureTypes} className={styles.menu}>
                        <p>Hide some features</p>
                      </ButtonDiv>
                      )}
                    </>
                  );
                }
                return (
                  <ButtonTextWithCheck
                    text={limitChar(featureType?.name, 30)}
                    onClick={() => handleClickFeatureType(featureType._id)}
                    checked={checkFeatureTypes.some(
                      (checkFeatureType) => checkFeatureType === featureType._id,
                    )}
                  />
                );
              })}
            </>
          )
          : (
            <>
              {searchResult.map((featureType, index) => {
                if (index < 3) {
                  return (
                    <ButtonTextWithCheck
                      text={limitChar(featureType?.name, 30)}
                      onClick={() => handleClickFeatureType(featureType._id)}
                      checked={checkFeatureTypes.some(
                        (checkFeatureType) => checkFeatureType === featureType._id,
                      )}
                    />
                  );
                }
                if (index === 3) {
                  return (
                    <>
                      <ButtonTextWithCheck
                        text={limitChar(featureType?.name, 30)}
                        onClick={() => handleClickFeatureType(featureType._id)}
                        checked={checkFeatureTypes.some(
                          (checkFeatureType) => checkFeatureType === featureType._id,
                        )}
                      />
                      {index !== searchResult.length - 1 && (
                      <ButtonDiv onClick={handleShowAllFeatureTypes} className={styles.menu}>
                        <p>
                          Show more (
                          {`${searchResult.length - 4} hidden`}
                          )
                        </p>
                      </ButtonDiv>
                      )}
                    </>
                  );
                }
                return null;
              })}
            </>
          )} */}
      </Box>
    </Box>
  );
};

FeatureTypeFilterSection.propTypes = {
  // featureTypes: PropTypes.array,
  searchHook: PropTypes.object.isRequired,
  showAllFeatureTypes: PropTypes.bool.isRequired,
  handleShowAllFeatureTypes: PropTypes.func.isRequired,
  handleHideAllFeatureTypes: PropTypes.func.isRequired,
  checkFeatureTypes: PropTypes.array.isRequired,
  handleClickFeatureType: PropTypes.func.isRequired,
  isAllFeatureTypesSelected: PropTypes.bool.isRequired,
  handleClickAllFeatureTypes: PropTypes.func.isRequired,
};

// FeatureTypeFilterSection.defaultProps = {
//   featureTypes: [],
// };

export default memo(FeatureTypeFilterSection);
