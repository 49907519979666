import React, { memo, useState } from 'react';
import PropTypes from 'prop-types';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import styles from './Acquisition.module.css';
import { DisplayTextBody, DisplayTextSubHeadline } from '../../../../../components/UI_V2/Display/Text';
import Color from '../../../../../themes/colors';
import arrowIllustration from '../../../../../assets/DashboardAdminPage/PirateMetricPage/arrow-illustration.png';
import { getSingleOrPlural } from '../../../../../utilities/stringUtil';
import BoxText from '../BoxText/BoxText';
import PercentageText from '../PercentageText/PercentageText';

const Acquisition = ({
  value,
  percentage,
  url,
  filterTrial,
}) => {
  let desc;
  if (filterTrial === undefined) desc = 'Trial & Login';
  if (filterTrial === true) desc = 'Trial';
  if (filterTrial === false) desc = 'Login';
  return (
    <div className={styles.container}>
      <div className={styles.box}>
        <BoxText
          value={value}
          desc={desc}
          url={url}
        />
      </div>
      <div className={styles.content}>
        <div className={styles.title}>
          <DisplayTextSubHeadline
            decoration="bold"
          >
            Acquisition
          </DisplayTextSubHeadline>
        </div>
        <div className={styles.arrowIllustration}>
          <LazyLoadImage src={arrowIllustration} />
        </div>
        <div className={styles.percentage}>
          <PercentageText percentage={percentage} />
        </div>
      </div>
    </div>
  );
};

Acquisition.propTypes = {
  value: PropTypes.number,
  percentage: PropTypes.number,
  url: PropTypes.string.isRequired,
  filterTrial: PropTypes.bool.isRequired,
};

Acquisition.defaultProps = {
  value: 0,
  percentage: 0,
};

export default memo(Acquisition);
