import React, {
  useState, useContext, useEffect,
} from 'react';
import { useParams } from 'react-router-dom';
import { useMediaQuery } from '@mui/material';
import { GlobalContext } from '../../contexts/GlobalStateProvider';
import styles from './PrivateChatPage.module.css';
import Title from '../../components/Title/Title';
import SideBarChatSection from './SideBarChatSection/SideBarChatSection';
import MainChatSection from './MainChatSection/MainChatSection';
import GeneralSubNavBar from '../../components/GeneralSubNavBar/GeneralSubNavBar';
import { generateRoutesAndTitle } from '../../actions/BreadCrumbActions';
import IntroChatSection from './IntroChatSection/IntroChatSection';
import { SearchJumpToActions } from '../../actions';
import { PageConstants } from '../../constants';

const containerWithSidebarStyles = {
  true: `${styles.container} ${styles.sidebarOpen}`,
  false: styles.container,
};

function PrivateChatPage() {
  const [{ user, currentCompany, isSidebarOpen }, dispatch] = useContext(GlobalContext);
  const [chatWith, setChatWith] = useState();
  const params = useParams();

  const { companyId, chatId } = params;

  const handleChangeChatWith = (chatWithMember) => {
    setChatWith([chatWithMember]);
  };

  useEffect(() => {
    if (!chatWith) return;
    setChatWith();
  }, [chatId]);

  const pageType = PageConstants.pageType.chats;
  const breadcrumb = generateRoutesAndTitle(
    { type: pageType, data: { chatWith, chatId, currentCompany } },
  );
  if (chatId && chatWith?.[0]?.fullName) {
    SearchJumpToActions.saveRecentPage({
      company: currentCompany,
      userId: user?._id,
      itemId: chatId,
      url: breadcrumb.url,
      routes: breadcrumb.routes,
      name: breadcrumb.title,
      type: pageType,
    });
  }

  const isTablet = useMediaQuery('(max-width:1024px)');
  const isMobile = useMediaQuery('(max-width:720px)');

  return (
    <>
      <Title title={chatWith === undefined ? `Chat Pribadi - ${currentCompany?.name}` : `${chatWith[0]?.fullName} - ${currentCompany?.name}`} />
      <GeneralSubNavBar
        breadcrumbRoutes={breadcrumb.routes}
        pageTitle={breadcrumb.title}
        withBottomNavBar={false}
        withMiddleNavBar={false}
      />
      <div className={containerWithSidebarStyles[isSidebarOpen]}>
        {!isMobile && <SideBarChatSection />}
        {!chatId && isMobile && <SideBarChatSection />}
        {!isMobile && !chatId && (
          <IntroChatSection />
        )}
        {!isMobile && chatId && (
          <MainChatSection
            handleChangeChatWith={handleChangeChatWith}
            chatWith={chatWith}
          />
        )}
        {isMobile && chatId && (
          <MainChatSection
            handleChangeChatWith={handleChangeChatWith}
            chatWith={chatWith}
          />
        )}
      </div>
    </>
  );
}

export default PrivateChatPage;
