import React, { memo, useState } from 'react';
import PropTypes from 'prop-types';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import { Tooltip } from '@mui/material';
import styles from './BadgePeriod.module.css';
import Color from '../../../../themes/colors';
import { DisplayTextBody, DisplayTextSubBody } from '../../../../components/UI_V2/Display/Text';

const variantStyles = {
  realtime: styles.realtime,
  recapMonthWeekly: styles.recapMonthWeekly,
};

const variantIconStyles = {
  realtime: styles.iconRealtime,
  recapMonthWeekly: styles.iconRecapMonthWeekly,
};

const variantText = {
  realtime: 'Data Realtime',
  recapMonthWeekly: 'Data Rekap',
};

const variantTooltip = {
  realtime: 'Laporan berdasarkan data realtime. Cocok untuk melihat kondisi realtime berapa yang telat, berapa yang selesai, berapa yang belum secara akurat detik ini juga. Cocok digunakan untuk terus memacu progress pekerjaan berjalan maksimal hari per harinya.',
  recapMonthWeekly: 'Laporan rekap bulanan ini mirip seperti Mutasi Rekening. Cocok untuk merangkum kinerja selama 1 bulan penuh untuk menjadi arsip laporan. Data ini statik, baru terupdate tiap Sabtu malam (tidak realtime), dan hanya ditampilkan data selama 3 bulan kebelakang saja. Terhitung sejak Desember 2023.',
};

const variantTextStyles = {
  realtime: Color.blueNavyCicle,
  recapMonthWeekly: Color.yellowAccentCicle,
};

const BadgePeriod = ({ variant }) => (
  <div className={`${styles.container} ${variantStyles[variant]}`}>
    <div className={styles.text}>
      <DisplayTextBody decoration="bold" color={variantTextStyles[variant]}>
        {variantText[variant]}
      </DisplayTextBody>
    </div>
    <div className={`${styles.icon} ${variantIconStyles[variant]}`}>
      <Tooltip title={variantTooltip[variant]}>
        <HelpOutlineIcon />
      </Tooltip>
    </div>
  </div>
);

BadgePeriod.propTypes = {
  variant: PropTypes.oneOf(['realtime', 'recapMonthWeekly']),
};

BadgePeriod.defaultProps = {
  variant: 'realtime',
};

export default memo(BadgePeriod);
