import React, {
  memo, useContext,
} from 'react';
import PropTypes from 'prop-types';
import useMediaQuery from '@mui/material/useMediaQuery';
import ScrollContainer from 'react-indiana-drag-scroll';
import OverviewOptionButton from './OverviewOptionButton/OverviewOptionButton';
import VerticalSeparator from './VerticalSeparator/VerticalSeparator';
import MoreButton from './MoreButton/MoreButton';
import styles from './BottomNavBar.module.css';
import { GlobalContext } from '../../../contexts/GlobalStateProvider';

const containerWithSidebarStyles = {
  true: `${styles.container} ${styles.sidebarOpen}`,
  false: styles.container,
};

const BottomNavBar = ({
  overviewOptionId,
  globalActions,
  otherActions,
}) => {
  const isMobile = useMediaQuery('(max-width:720px)');
  const hasGlobalActions = Boolean(globalActions?.props?.children);
  const [{ isSidebarOpen }] = useContext(GlobalContext);

  return (
    <div className={containerWithSidebarStyles[isSidebarOpen]}>
      <div className={styles.bottomBar__container}>
        <div className={styles.bottomBar__leftSection}>
          {/*
          {overviewOptionId ? <OverviewOptionButton defaultMenuId={overviewOptionId} /> : null}
           */}
          {/* { hasGlobalActions && overviewOptionId ? <VerticalSeparator /> : null} */}
          {/* {isMobile
            ? (
              <>
                { hasGlobalActions ? (
                  <MoreButton text="Aksi">
                    {globalActions}
                  </MoreButton>
                ) : null}
              </>
            )
            : (
              <ScrollContainer className={styles.bottomBar__globalActionSection}>
                {globalActions}
              </ScrollContainer>
            )} */}
          <ScrollContainer className={styles.bottomBar__globalActionSection}>
            {globalActions}
          </ScrollContainer>
        </div>
        <div className={styles.bottomBar__rightSection}>
          {otherActions}
        </div>
      </div>
    </div>
  );
};

BottomNavBar.propTypes = {
  overviewOptionId: PropTypes.string,
  globalActions: PropTypes.node,
  otherActions: PropTypes.node,
};

BottomNavBar.defaultProps = {
  overviewOptionId: null,
  globalActions: <></>,
  otherActions: <></>,
};

export default memo(BottomNavBar);
