import React, {
  memo, useState, useContext, useEffect,
} from 'react';
import { useParams } from 'react-router-dom';
import PropTypes from 'prop-types';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { useSnackbar } from 'notistack';
import ScrollContainer from 'react-indiana-drag-scroll';
import styles from './OrderSummary.module.css';
import { DisplayTextBody, DisplayTextHeadline, DisplayTextSubHeadline } from '../../../components/UI_V2/Display/Text';
import Color from '../../../themes/colors';
import { InputButtonMain } from '../../../components/UI_V2/Input/Button';
import { dateBeautifyFormatter, timeBeautifyFormatter } from '../../../utilities/dateUtil';
import { formatToBasicThousandSeparator } from '../../../utilities/numberUtil';
import AddOnSection from './AddOnSection/AddOnSection';
import { BillingActions } from '../../../actions';
import handleStatusMsg from '../../../utilities/handleStatusMsg';
import handleLoadings from '../../../utilities/handleLoadings';
import { incomingInvoices, setCurrentInvoicesUnpaidCounter } from '../../../actions/BillingActions';
import { BillingConstants } from '../../../constants';
import { GlobalContext } from '../../../contexts/GlobalStateProvider';
import SelectCouponButton from './SelectCouponButton/SelectCouponButton';
import SelectCouponSection from './SelectCouponSection/SelectCouponSection';

const BillingOrderSummary = ({
  user,
  currentCompany,
  handleClose,
  initialOrderItems,
  isOpen,
  tabSelected,
  forceReinitiateInvoicesList,
}) => {
  const [
    {
      currentInvoicesUnpaidCounter,
    },
    dispatch,
  ] = useContext(GlobalContext);
  const [order, setOrder] = useState({});
  const [orderFinalAmount, setOrderFinalAmount] = useState('');
  const [orderBeforeDiscountAmount, setOrderBeforeDiscountAmount] = useState('');
  const [orderDiscountAmount, setOrderDiscountAmount] = useState('');
  const [showCouponSection, setShowCouponSection] = useState(false);
  const [isCreatingInvoice, setIsCreatingInvoice] = useState(false);
  const [loadings, setLoadings] = useState([]);

  const { enqueueSnackbar } = useSnackbar();
  const params = useParams();

  const { companyId } = params;

  const handleSetOrder = (data) => {
    setOrder(data);
    const beforeDiscountAmount = formatToBasicThousandSeparator(
      data.totalBeforeDiscount || 0,
    );
    const discountAmount = formatToBasicThousandSeparator(data.discountAmount || 0);
    const finalAmount = formatToBasicThousandSeparator(data.total);

    setOrderBeforeDiscountAmount(beforeDiscountAmount);
    setOrderDiscountAmount(discountAmount);
    setOrderFinalAmount(finalAmount);
  };

  const createOrderApi = async (items) => {
    try {
      const startLoadings = handleLoadings('billingOrder', [...loadings], 'start');
      setLoadings([...startLoadings]);

      const result = await BillingActions.createOrderSummary({
        companyId,
        items,
      });

      handleSetOrder(result.data);

      return result.data;
    } catch (err) {
      const status = handleStatusMsg(err, 'error');

      enqueueSnackbar(status.message, {
        variant: 'error',
      });
      return err;
    } finally {
      const endLoadings = handleLoadings('billingOrder', [...loadings], 'end');
      setLoadings([...endLoadings]);
    }
  };

  const initiateOrderSummary = async (items) => {
    await createOrderApi(items);
  };

  const goToInvoiceUrl = (url) => {
    window.location.assign(url, '_self');
  };

  const handleClickContinueOrder = async (payload) => {
    try {
      const startLoadings = handleLoadings('billingCreateOrderInvoice', [...loadings], 'start');
      setLoadings([...startLoadings]);

      setIsCreatingInvoice(true);

      setTimeout(() => {
        enqueueSnackbar(
          'Tunggu ya... Mincle bakal alihkan kamu ke halaman pemilihan metode pembayaran.', {
            variant: 'info',
          },
        );
      }, 1500);

      setTimeout(() => {
        enqueueSnackbar(
          'Tunggu ya... Hampir beres, sedang mengalihkan kamu secepatnya.', {
            variant: 'info',
          },
        );
      }, 3500);

      const result = await BillingActions.createInvoice({
        companyId: payload.companyId,
        orderId: payload.orderId,
      });

      // incomingInvoices({
      //   invoice: result?.data,
      //   typeAction: BillingConstants.typeCallback.NEW,
      // }, dispatch);

      // setCurrentInvoicesUnpaidCounter(currentInvoicesUnpaidCounter + 1, dispatch);
      await forceReinitiateInvoicesList(tabSelected);

      setTimeout(() => {
        setIsCreatingInvoice(false);
        goToInvoiceUrl(result.data.invoiceUrl);
      }, 200);

      // const status = handleStatusMsg(result, 'success');

      // enqueueSnackbar(status.message, {
      //   variant: 'success',
      // });
    } catch (err) {
      const status = handleStatusMsg(err, 'error');

      enqueueSnackbar(status.message, {
        variant: 'error',
      });
    }
  };

  useEffect(() => {
    if (!isOpen) return;
    initiateOrderSummary(initialOrderItems);
  }, []);

  const handleClick = () => {
    const payload = {
      orderId: order._id,
      companyId: order.company,
    };

    handleClickContinueOrder(payload);
  };

  const handleShowCouponSection = () => {
    setShowCouponSection(true);
  };

  const handleHideCouponSection = () => {
    setShowCouponSection(false);
  };

  const isOrderListEmpty = order?.items?.length < 1;

  return (
    <div className={styles.container}>
      <div
        onClick={!showCouponSection
          ? handleClose
          : handleHideCouponSection}
        className={styles.backButton}
      >
        <ArrowBackIcon />
      </div>
      {showCouponSection && (
      <SelectCouponSection
        handleSetOrder={handleSetOrder}
        order={order}
        handleHideCouponSection={handleHideCouponSection}
      />
      )}
      {!showCouponSection && (
      <>
        <div className={styles.title}>
          <DisplayTextHeadline
            color={Color.blueNavy2}
            mode="24"
            decoration="bold"
            position="center"
          >
            Tinjau Pesananmu
          </DisplayTextHeadline>
        </div>
        <div className={styles.bodySection}>
          <div className={styles.orderAndNotes}>
            <div className={styles.listItems}>
              <div className={styles.subTitle}>
                <DisplayTextSubHeadline
                  mode="18"
                  color={Color.blueNavy2}
                  decoration="bold"
                >
                  Detil
                </DisplayTextSubHeadline>
              </div>
              <ScrollContainer className={styles.scrollListItems}>
                <div className={styles.columnTitle}>
                  {/* <div className={styles.columnTitle__plan}>
    <DisplayTextBody
      color={Color.black}
      decoration="bold"
      mode="12"
      fontFamily="openSans"
    >
      Plan
    </DisplayTextBody>
  </div> */}
                  <div className={styles.columnTitle__desc}>
                    <DisplayTextBody
                      color={Color.black}
                      decoration="bold"
                      mode="12"
                      fontFamily="openSans"
                    >
                      Produk
                    </DisplayTextBody>
                  </div>
                  {/* <div className={styles.columnTitle__subscriptionDate}>
    <DisplayTextBody
      color={Color.black}
      decoration="bold"
      mode="12"
      fontFamily="openSans"
    >
      Subscription Date
    </DisplayTextBody>
  </div> */}
                  <div className={styles.columnTitle__price}>
                    <DisplayTextBody
                      color={Color.black}
                      decoration="bold"
                      mode="12"
                      fontFamily="openSans"
                    >
                      Harga
                    </DisplayTextBody>
                  </div>
                  <div className={styles.columnTitle__qty}>
                    <DisplayTextBody
                      color={Color.black}
                      decoration="bold"
                      mode="12"
                      fontFamily="openSans"
                    >
                      Jml
                    </DisplayTextBody>
                  </div>
                  <div className={styles.columnTitle__total}>
                    <DisplayTextBody
                      color={Color.black}
                      decoration="bold"
                      mode="12"
                      fontFamily="openSans"
                    >
                      Total
                    </DisplayTextBody>
                  </div>
                </div>
                <div className={styles.items}>
                  {order?.items?.map((item) => (
                    <div className={styles.item}>
                      {/* <div className={styles.columnTitle__plan}>
            <DisplayTextBody
              color={Color.black}
              mode="12"
              fontFamily="openSans"
            >
              {item?.product?.desc}
            </DisplayTextBody>
          </div> */}
                      <div className={styles.columnTitle__desc}>
                        <div className={styles.descText}>
                          <DisplayTextBody
                            color={Color.black}
                            mode="12"
                            fontFamily="openSans"
                          >
                            {item.desc}
                          </DisplayTextBody>
                        </div>
                        {item?.estSubscriptionStartDate && (
                        <div className={styles.subsDate}>
                          <DisplayTextBody
                            color={Color.gray3}
                            mode="12"
                            fontFamily="openSans"
                          >
                            {`${dateBeautifyFormatter(item?.estSubscriptionStartDate, true, 'MonthDateYear')} - ${dateBeautifyFormatter(item?.estSubscriptionEndDate, true, 'MonthDateYear')}`}
                          </DisplayTextBody>
                        </div>
                        )}
                      </div>
                      <div className={styles.columnTitle__price}>
                        <DisplayTextBody
                          color={Color.black}
                          mode="12"
                          fontFamily="openSans"
                        >
                          {`Rp. ${formatToBasicThousandSeparator(item?.price)}`}
                        </DisplayTextBody>
                      </div>
                      <div className={styles.columnTitle__qty}>
                        <DisplayTextBody
                          color={Color.black}
                          mode="12"
                          fontFamily="openSans"
                        >
                          {item?.qty}
                        </DisplayTextBody>
                      </div>
                      <div className={styles.columnTitle__total}>
                        <DisplayTextBody
                          color={Color.black}
                          mode="12"
                          fontFamily="openSans"
                        >
                          {`Rp. ${formatToBasicThousandSeparator(item?.total)}`}
                        </DisplayTextBody>
                      </div>
                    </div>
                  ))}
                </div>
              </ScrollContainer>
              <div className={styles.addOnSection}>
                <div className={styles.subTitle}>
                  <DisplayTextSubHeadline
                    mode="18"
                    color={Color.blueNavy2}
                    decoration="bold"
                  >
                    Addon
                  </DisplayTextSubHeadline>
                </div>
                <div className={styles.addOnSection__body}>
                  <AddOnSection
                    // addons={addons}
                    // loadings={loadings}
                    // handleClickUpdateAddonQty={handleClickUpdateAddonQty}
                    order={order}
                    handleSetOrder={handleSetOrder}
                    isOrderSummaryOpen={isOpen}
                  />
                </div>
              </div>
              <div className={styles.notes}>
                <div className={styles.subTitle}>
                  <DisplayTextSubHeadline
                    mode="18"
                    color={Color.blueNavy2}
                    decoration="bold"
                  >
                    Catatan
                  </DisplayTextSubHeadline>
                </div>
                <div className={styles.notes__body}>
                  <DisplayTextBody>
                    {order?.notes}
                  </DisplayTextBody>
                </div>
              </div>
            </div>
          </div>
          <div className={styles.orderAmountAndButton}>
            <div className={styles.subTitle}>
              <DisplayTextSubHeadline
                mode="18"
                color={Color.blueNavy2}
                decoration="bold"
              >
                Ringkasan Pesanan
              </DisplayTextSubHeadline>
            </div>
            <div className={styles.totalAmount}>
              <div className={styles.amountText}>
                <div className={styles.amountText__title}>
                  <DisplayTextBody>
                    Subtotal
                    {' '}

                  </DisplayTextBody>
                </div>
                <div className={styles.amountText__body}>
                  <DisplayTextBody>
                    {`Rp. ${orderBeforeDiscountAmount}`}
                  </DisplayTextBody>
                </div>
              </div>
              <div className={styles.amountText}>
                <div className={styles.amountText__title}>
                  <DisplayTextBody>
                    Kupon
                    {' '}

                  </DisplayTextBody>
                </div>
                <div className={styles.amountText__body}>
                  <DisplayTextBody>
                    {`Rp. ${orderDiscountAmount}`}
                    {' '}

                  </DisplayTextBody>
                </div>
              </div>
              <div className={styles.amountText}>
                <div className={styles.amountText__title}>
                  <DisplayTextBody>
                    Pajak
                    {' '}

                  </DisplayTextBody>
                </div>
                <div className={styles.amountText__body}>
                  <DisplayTextBody>
                    Rp. 0
                    {' '}

                  </DisplayTextBody>
                </div>
              </div>
              <div className={`${styles.amountText} ${styles.amountText__total}`}>
                <div className={styles.amountText__title}>
                  <DisplayTextSubHeadline
                    mode="14"
                    decoration="bold"
                  >
                    Total
                  </DisplayTextSubHeadline>
                </div>
                <div className={styles.amountText__body}>
                  <DisplayTextSubHeadline
                    mode="14"
                    decoration="bold"
                  >
                    {`Rp. ${orderFinalAmount}`}
                  </DisplayTextSubHeadline>
                </div>
              </div>
            </div>
            {!isOrderListEmpty && (
            <div className={styles.selectCoupon}>
              <SelectCouponButton
                onClick={handleShowCouponSection}
                handleSetOrder={handleSetOrder}
                coupon={order.coupon}
                order={order}
              />
            </div>
            )}
            <div className={styles.notesChoosePaymentMethod}>
              <DisplayTextBody
                mode="11"
                color={Color.gray3}
              >
                Setelah klik Selanjutnya, kamu akan dialihkan ke halaman pemilihan metode bayar.
              </DisplayTextBody>
            </div>
            <div className={styles.button}>
              <InputButtonMain
                handleClick={isOrderListEmpty || isCreatingInvoice ? () => null : handleClick}
                wide="block"
                border="semiRounded"
                loadings={loadings}
                wait="billingCreateOrderInvoice"
                variant={isOrderListEmpty ? 'light' : 'default'}
              >
                Selanjutnya

              </InputButtonMain>
            </div>
            <div className={styles.billingInfo}>
              <div className={styles.subTitle}>
                <DisplayTextSubHeadline
                  mode="18"
                  color={Color.blueNavy2}
                  decoration="bold"
                >
                  Informasi Tagihan
                </DisplayTextSubHeadline>
              </div>
              <div className={styles.notes__body}>
                <div className={styles.amountText}>
                  <div className={styles.amountText__title}>
                    <DisplayTextBody>
                      Nama Perusahaan
                      {' '}

                    </DisplayTextBody>
                  </div>
                  <div className={styles.amountText__body}>
                    <DisplayTextBody>
                      {currentCompany?.name}
                    </DisplayTextBody>
                  </div>
                </div>
                <div className={styles.amountText}>
                  <div className={styles.amountText__title}>
                    <DisplayTextBody>
                      Nama Pembeli
                      {' '}

                    </DisplayTextBody>
                  </div>
                  <div className={styles.amountText__body}>
                    <DisplayTextBody>
                      {user?.fullName}
                    </DisplayTextBody>
                  </div>
                </div>
                <div className={styles.amountText}>
                  <div className={styles.amountText__title}>
                    <DisplayTextBody>
                      Alamat
                      {' '}

                    </DisplayTextBody>
                  </div>
                  <div className={styles.amountText__body}>
                    <DisplayTextBody>
                      {user?.location ? user?.location : 'N/A'}
                    </DisplayTextBody>
                  </div>
                </div>
                <div className={styles.amountText}>
                  <div className={styles.amountText__title}>
                    <DisplayTextBody>
                      Negara
                      {' '}

                    </DisplayTextBody>
                  </div>
                  <div className={styles.amountText__body}>
                    <DisplayTextBody>
                      {user?.country ? user?.country : 'N/A'}
                    </DisplayTextBody>
                  </div>
                </div>
                <div className={styles.amountText}>
                  <div className={styles.amountText__title}>
                    <DisplayTextBody>
                      Email
                      {' '}

                    </DisplayTextBody>
                  </div>
                  <div className={styles.amountText__body}>
                    <DisplayTextBody>
                      {user?.email}
                    </DisplayTextBody>
                  </div>
                </div>
                <div className={styles.amountText}>
                  <div className={styles.amountText__title}>
                    <DisplayTextBody>
                      Telepon
                      {' '}

                    </DisplayTextBody>
                  </div>
                  <div className={styles.amountText__body}>
                    <DisplayTextBody>
                      {user?.phoneNumber ? user?.phoneNumber : 'N/A'}
                    </DisplayTextBody>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
      )}
    </div>
  );
};

BillingOrderSummary.propTypes = {
  initialOrderItems: PropTypes.array.isRequired,
  user: PropTypes.object.isRequired,
  currentCompany: PropTypes.object.isRequired,
  handleClose: PropTypes.func.isRequired,
  isOpen: PropTypes.bool.isRequired,
  forceReinitiateInvoicesList: PropTypes.func.isRequired,
  tabSelected: PropTypes.string.isRequired,
};

export default memo(BillingOrderSummary);
