import React, {
  memo, useCallback, useContext, useEffect, useState,
} from 'react';
import PropTypes from 'prop-types';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { useParams, useHistory, useLocation } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import styles from './ListCoupon.module.css';
import { DisplayTextBody } from '../../../../../components/UI_V2/Display/Text';
import Color from '../../../../../themes/colors';
import introImage from '../../../../../assets/PrivateChatPage/intro-chat-section.png';
import InfinityScroll from '../../../../../components/UI/InfinityScroll/InfinityScroll';
import { GlobalContext, initialState } from '../../../../../contexts/GlobalStateProvider';
import { CouponActions } from '../../../../../actions';
import { useInfinityScrollHooks } from '../../../../../hooks/InfinityScrollHooks';
import { CouponConstants } from '../../../../../constants';
import handleStatusMsg from '../../../../../utilities/handleStatusMsg';
import CouponItem from '../CouponItem/CouponItem';

const ListCoupon = ({
  handleClickApplyCoupon,
  loadings,
}) => {
  const [{
    user, currentCoupons, previousCoupons, currentCompany, currentCoupon,
  }, dispatch] = useContext(GlobalContext);
  const [changedRoleUsers, setChangedRoleUsers] = useState([]);

  const params = useParams();
  const history = useHistory();
  const location = useLocation();
  const { companyId, tabId } = params;

  const { enqueueSnackbar } = useSnackbar();

  //   const isUserManagerOrAbove = hasPermission(currentRoleUser, roles.managerOrAbove);

  const getMoreLists = async () => {
    const result = await CouponActions.loadMoreCoupons({
      currentCoupons,
      companyId,
    }, dispatch);

    return result;
  };

  const resetPreviousLists = useCallback(() => {
    CouponActions.setPreviousCoupons({
      previousCoupons: initialState.previousCoupons,
    }, dispatch);
    CouponActions.setCurrentCoupons({
      currentCoupons: initialState.currentCoupons,
    }, dispatch);
  }, []);

  const {
    lists,
    handleLoadMoreLists,
    checkIfListNeedToLoad,
    setCheckLoadMore,
    isListsLengthAtTheLimitOrAbove,
  } = useInfinityScrollHooks({
    currentObjectWithKeyProperty: currentCoupons,
    previousLists: previousCoupons?.data,
    resetPreviousLists,
    getMoreLists,
    keyProperty: 'data',
    limitList: CouponConstants.limitCoupons,
  });

  const initiateCouponsApi = async () => {
    try {
      const result = await CouponActions.initiateCoupons({ companyId }, dispatch);

      if (isListsLengthAtTheLimitOrAbove(result?.data?.data)) {
        setCheckLoadMore(true);
      } else {
        setCheckLoadMore(false);
      }
    } catch (err) {
      const status = handleStatusMsg(err, 'error');

      enqueueSnackbar(status.message, {
        variant: 'error',
      });
    }
  };

  useEffect(() => {
    if (user._id === undefined) {
      history.push(`/check-login?previousPath=${location.pathname}`);
      return;
    }
    // if (!isUserAdminOrAbove) return;

    initiateCouponsApi();
  }, [location]);

  return (
    <InfinityScroll
      scrollableTarget="couponList"
      dataLength={lists?.length || 0}
      hasMore={checkIfListNeedToLoad()}
      next={handleLoadMoreLists}
      emptyMessage="Belum ada kupon..."
    >
      <div className={styles.list}>
        {lists.map((coupon) => (
          <div className={styles.item}>
            <CouponItem
              coupon={coupon}
              handleClickApplyCoupon={handleClickApplyCoupon}
              loadings={loadings}
            />
          </div>
        ))}
      </div>
    </InfinityScroll>
  );
};

ListCoupon.propTypes = {
  handleClickApplyCoupon: PropTypes.func.isRequired,
  loadings: PropTypes.array.isRequired,
};

export default memo(ListCoupon);
