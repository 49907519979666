/* eslint-disable consistent-return */
import { useCallback } from 'react';
import { CheerActions, EventActions } from '../actions';
import { CheerConstants, EventConstants } from '../constants';
import { connectSocketEvent, connectSocketSchedule } from '../services/socket';
import { useSocketHooks } from './SocketHooks';

function useEventSocket({ scheduleId, eventId, userId }, dispatch) {
  const callbackEvent = useCallback((event) => {
    if (event?._id !== eventId) return;
    EventActions.incomingEvent({
      event,
    }, dispatch);
  }, []);

  const listenSocket = (socket, params) => {
    socket
      .on(`event-update-${params?.scheduleId}`, callbackEvent)
      .on(`event-archive-${params?.scheduleId}`, callbackEvent);
  };
  const removeListener = (socket, params) => {
    socket
      .off(`event-update-${params?.scheduleId}`)
      .off(`event-archive-${params?.scheduleId}`);
    socket.disconnect();
  };

  useSocketHooks({
    params: { scheduleId, userId },
    connectSocket: connectSocketSchedule,
    listenSocket,
    removeListener,
  });
}

function useEventCheerNCommentSocket({ eventId, userId }, dispatch) {
  const callbackNewCheer = useCallback((cheer) => {
    CheerActions.incomingCheerFromPrimaryParent({
      cheer,
      typeAction: CheerConstants.typeCallback.NEW,
      primaryParentAction: ({ updateStateParent }) => EventActions.dispatchUpdateScheduleEvent(
        { updateScheduleEvent: updateStateParent },
        dispatch,
      ),
      keyProperty: 'cheers',
    });
  }, []);
  const callbackDeleteCheer = useCallback((cheer) => {
    CheerActions.incomingCheerFromPrimaryParent({
      cheer,
      typeAction: CheerConstants.typeCallback.DELETE,
      primaryParentAction: ({ updateStateParent }) => EventActions.dispatchUpdateScheduleEvent(
        { updateScheduleEvent: updateStateParent },
        dispatch,
      ),
      keyProperty: 'cheers',
    });
  }, []);

  const callbackNewComment = useCallback((comment) => {
    EventActions.incomingEventComment({
      comment,
      typeAction: EventConstants.typeCallback.NEW,
    }, dispatch);
  }, []);
  const callbackUpdateComment = useCallback((comment) => {
    EventActions.incomingEventComment({
      comment,
      typeAction: EventConstants.typeCallback.EDIT,
    }, dispatch);
  }, []);
  const callbackDeleteComment = useCallback((comment) => {
    EventActions.incomingEventComment({
      comment,
      typeAction: EventConstants.typeCallback.DELETE,
    }, dispatch);
  }, []);

  const callbackNewCheerComment = useCallback((cheer) => {
    CheerActions.incomingCheerFromSecondaryParent({
      cheer,
      typeAction: CheerConstants.typeCallback.NEW,
      secondaryParentAction: EventActions.incomingEventComment,
      typeActionEditSecondaryParent: EventConstants.typeCallback.EDIT,
    }, dispatch);
  }, []);
  const callbackDeleteCheerComment = useCallback((cheer) => {
    CheerActions.incomingCheerFromSecondaryParent({
      cheer,
      typeAction: CheerConstants.typeCallback.DELETE,
      secondaryParentAction: EventActions.incomingEventComment,
      typeActionEditSecondaryParent: EventConstants.typeCallback.EDIT,
    }, dispatch);
  }, []);

  const listenSocket = (socket, params) => {
    socket
      .on('cheer-new', callbackNewCheer)
      .on('cheer-delete', callbackDeleteCheer)
      .on(`eventsComment-new-${params?.eventId}`, callbackNewComment)
      .on(`eventsComment-update-${params?.eventId}`, callbackUpdateComment)
      .on(`eventsComment-delete-${params?.eventId}`, callbackDeleteComment)
      .on('cheerComment-new', callbackNewCheerComment)
      .on('cheerComment-delete', callbackDeleteCheerComment);
  };
  const removeListener = (socket, params) => {
    socket
      .off('cheer-new')
      .off('cheer-delete')
      .off(`eventsComment-new-${params?.eventId}`)
      .off(`eventsComment-update-${params?.eventId}`)
      .off(`eventsComment-delete-${params?.eventId}`)
      .off('cheerComment-new')
      .off('cheerComment-delete');
    socket.disconnect();
  };

  useSocketHooks({
    params: { eventId, userId },
    connectSocket: connectSocketEvent,
    listenSocket,
    removeListener,
  });
}

export {
  useEventCheerNCommentSocket,
  useEventSocket,
};
