import React, { memo, useState } from 'react';
import PropTypes from 'prop-types';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import ScrollContainer from 'react-indiana-drag-scroll';
import styles from './ColumnListCardSnapshot.module.css';
import Color from '../../../../../themes/colors';
import CategoryTabNotif from '../../../../NotificationContainer/NotificationsCategoryContainer/CategoryTabNotif/CategoryTabNotif';
import { ReportConstants } from '../../../../../constants';
import { DisplayDividerMain } from '../../../../../components/UI_V2/Display/Divider';

const ColumnListCardSnapshot = ({
  handleSelectTab,
  itemId,
}) => (
  <div className={styles.container}>
    <ScrollContainer className={styles.scrollTabs}>
      <div className={styles.tabs}>
        <CategoryTabNotif
          name="Terlambat"
          counter={0}
          key={ReportConstants.typeRef.overdue}
          itemId={ReportConstants.typeRef.overdue}
          isFirstItem={false}
          onClick={() => handleSelectTab(ReportConstants.typeRef.overdue)}
          selected={itemId === ReportConstants.typeRef.overdue}
        />
        <CategoryTabNotif
          name="Dikerjakan"
          counter={0}
          key={ReportConstants.typeRef.ongoing}
          itemId={ReportConstants.typeRef.ongoing}
          isFirstItem={false}
          onClick={() => handleSelectTab(ReportConstants.typeRef.ongoing)}
          selected={itemId === ReportConstants.typeRef.ongoing}
        />
        <CategoryTabNotif
          name="Belum"
          counter={0}
          key={ReportConstants.typeRef.backlog}
          itemId={ReportConstants.typeRef.backlog}
          isFirstItem={false}
          onClick={() => handleSelectTab(ReportConstants.typeRef.backlog)}
          selected={itemId === ReportConstants.typeRef.backlog}
        />
        <CategoryTabNotif
          name="Selesai"
          counter={0}
          key={ReportConstants.typeRef.completed}
          itemId={ReportConstants.typeRef.completed}
          isFirstItem={false}
          onClick={() => handleSelectTab(ReportConstants.typeRef.completed)}
          selected={itemId === ReportConstants.typeRef.completed}
        />
      </div>
    </ScrollContainer>
  </div>
);

ColumnListCardSnapshot.propTypes = {
  itemId: PropTypes.string,
  handleSelectTab: PropTypes.func,
};

ColumnListCardSnapshot.defaultProps = {
  handleSelectTab: () => null,
  itemId: ReportConstants.typeRef.overdue,
};

export default memo(ColumnListCardSnapshot);
