import React, { memo, useState } from 'react';
import PropTypes from 'prop-types';
import { useParams } from 'react-router-dom';
import { Box, useMediaQuery } from '@mui/material';
import GroupsOutlinedIcon from '@mui/icons-material/GroupsOutlined';
import MapsHomeWorkOutlinedIcon from '@mui/icons-material/MapsHomeWorkOutlined';
import AssignmentOutlinedIcon from '@mui/icons-material/AssignmentOutlined';
import Highlighter from 'react-highlight-words';
import { DisplayTextBody, DisplayTextSubHeadline } from '../../../../../../components/UI_V2/Display/Text';
import Color from '../../../../../../themes/colors';
import LinkNoDecor from '../../../../../../components/LinkNoDecor/LinkNoDecor';
import { getPureStringWithLimit, limitChar } from '../../../../../../utilities/stringUtil';
import { getUrlItem } from '../../../../../../actions/SearchJumpToActions';
import { PageConstants } from '../../../../../../constants';
import styles from './ResultItemPrivateMessage.module.css';
import Breadcrumb from '../../Breadcrumb/Breadcrumb';
import { DisplayAvatarMember } from '../../../../../../components/UI_V2/Display/Avatar';

const ResultItemPrivateMessage = ({
  item, isSelected, onClick, keywordArray, limitTitleChar, params,
}) => {
  const { companyId } = params;

  const routes = [
    {
      name: 'Chat Pribadi',
    },
    {
      name: item.chat.members?.[0]?.fullName,
    },
    {
      name: 'Pesan',
    },
  ];

  const newItem = {
    _id: item.message._id,
    name: item.message.content,
    type: 'chats',
    chatId: item.chat._id,
    companyId,
    routes,
  };

  const url = getUrlItem({ companyId, item: newItem });
  newItem.url = url;

  const senderPhotoUrl = item?.chat?.members?.[0]?.photoUrl;

  const isTablet = useMediaQuery('(max-width:1024px)');
  const isMobile = useMediaQuery('(max-width:720px)');

  return (
    <LinkNoDecor to={url}>
      <div
        onClick={onClick}
        aria-pressed={isSelected}
        className={styles.container}
      >
        <div
          className={styles.content}
        >
          <div
            className={styles.iconBox}
          >
            <DisplayAvatarMember src={senderPhotoUrl} />
          </div>
          <div>
            <DisplayTextBody
              mode="14"
              color={Color.gray3}
            >
              <Highlighter
                highlightStyle={{
                  color: Color.yellowAccentCicle,
                  padding: '0px',
                  backgroundColor: 'transparent',
                }}
                searchWords={keywordArray}
                autoEscape
                textToHighlight={getPureStringWithLimit(
                  newItem?.name, isMobile ? 80 : limitTitleChar,
                )}
              />
            </DisplayTextBody>
          </div>
        </div>
        <div className={styles.separator} />
        <div
          className={styles.breadcrumb}
        >
          <Breadcrumb routes={newItem?.routes} />
        </div>
      </div>
    </LinkNoDecor>
  );
};

ResultItemPrivateMessage.propTypes = {
  item: PropTypes.object.isRequired,
  isSelected: PropTypes.bool.isRequired,
  onClick: PropTypes.func.isRequired,
  keywordArray: PropTypes.array,
  limitTitleChar: PropTypes.number,
  params: PropTypes.object,
};

ResultItemPrivateMessage.defaultProps = {
  keywordArray: [],
  limitTitleChar: 230,
  params: { companyId: null, teamId: null },
};

export default memo(ResultItemPrivateMessage);
