import React, {
  useState, useContext, useEffect, useCallback, createRef,
} from 'react';
import {
  useHistory, useParams, useLocation,
} from 'react-router-dom';
import {
  differenceWith, isArray, isEmpty, isEqual, isNumber,
} from 'lodash';
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import ArchiveIcon from '@mui/icons-material/Archive';
import VerifiedUserIcon from '@mui/icons-material/VerifiedUser';
import AddIcon from '@mui/icons-material/Add';
import draftToHtml from 'draftjs-to-html';
import { useSnackbar } from 'notistack';
import MainLayout from '../../components/Layout/MainLayout/MainLayout';
import { GlobalContext, initialState } from '../../contexts/GlobalStateProvider';
import { actionTypes } from '../../reducers/reducer';
import SeparatorLine from '../../components/UI/SeparatorLine/SeparatorLine';
import Paper from '../../components/UI/Paper/Paper';
import '../../../node_modules/react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import styles from './EventPage.module.css';
import CommentsSectionContainer from '../CommentsSectionContainer/CommentsSectionContainer';
import ImageMember from '../../components/UI/ImageMember/ImageMember';
import RoundActionMenu from '../../components/UI/RoundActionMenu/RoundActionMenu';
import EventMenu from './EventMenu/EventMenu';
import Title from '../../components/Title/Title';
import checkIsJson from '../../utilities/checkIsJson';
import EditorView from '../FroalaEditor/EditorView/EditorView';
import handleLoadings from '../../utilities/handleLoadings';
import PageLoading from '../../components/UI/Button/PageLoading/PageLoading';
import handleStatusMsg from '../../utilities/handleStatusMsg';
import CheersContainer from '../CheersContainer/CheersContainer';
import AddEventCalendarMenu from './AddEventCalendarMenu/AddEventCalendarMenu';
import { dateDifferenceInDays } from '../../utilities/date';
import TimeAgo from '../../components/UI/TimeAgo/TimeAgo';
import {
  CommentActions, EventActions,
  SearchJumpToActions, TeamActions,
} from '../../actions';
import InfinityScroll from '../../components/UI/InfinityScroll/InfinityScroll';
import { sortingListByCreatedAt } from '../../utilities/arrayUtil';
import { EventConstants, PageConstants, SeenConstants } from '../../constants';
import { useEventCheerNCommentSocket, useEventSocket } from '../../hooks/EventHooks';
import { useScrollToTargetComment } from '../../hooks/DiscussionHooks';
import PrivateIcon from '../../components/UI/PrivateIcon/PrivateIcon';
import GeneralSubNavBar from '../../components/GeneralSubNavBar/GeneralSubNavBar';
import { generateRoutesAndTitle } from '../../actions/BreadCrumbActions';
import GlobalActionButton from '../../components/GeneralSubNavBar/BottomNavBar/GlobalActionButton/GlobalActionButton';
import { useDelayShowHideHandler } from '../../hooks/HelperHooks';
import { useInitiateRoleUser } from '../../hooks/RoleHooks';
import { roleTypeRef, typeRef } from '../../constants/RoleConstants';
import MyRoleUser from '../../components/Container/MyRoleUser/MyRoleUser';
import { checkIfUserAuthorizedToEditData } from '../../actions/UserActions';
import { useInitiateTeamBelowRole } from '../../hooks/TeamHooks';
import ActivityByModule from '../ActivityByModule/ActivityByModule';
import { serviceTypeRef } from '../../constants/ActivityConstants';
import { DisplayAvatarMemberWithOnline } from '../../components/UI_V2/Display/Avatar';
import SeenInfo from '../../components/Container/SeenModal/SeenInfo/SeenInfo';
import SeenModal from '../../components/Container/SeenModal/SeenModal';
import { useUserTypingEventSocket } from '../../hooks/UserTypingHooks';
import UserTyping from '../../components/Container/UserTyping/UserTyping';
import { useMarkReadNotificationOnPageOpen } from '../../hooks/NotificationHooks';
import { LayoutMain } from '../../components/UI_V2/Layout';
import { SurfacePaperMain } from '../../components/UI_V2/Surface/Paper';

function EventPage() {
  const [{
    user, currentScheduleEvent,
    previousEventComment,
    currentSchedule, currentTeam, currentCompany,
    currentRoleUser, currentTeamBelowRole,
    allUserTypingEvent,
  }, dispatch] = useContext(GlobalContext);
  const [loadings, setLoadings] = useState([]);
  const [showEventMenu, setShowEventMenu] = useState(false);
  const [showAddCalendarMenu, setShowAddCalendarMenu] = useState(false);
  const [isInitialContentReady, setIsInitialContentReady] = useState(false);
  const [
    showModalRoleUser,
    handleShowModalRoleUser,
    handleHideModalRoleUser,
  ] = useDelayShowHideHandler();

  const [
    showSeenModal,
    handleShowSeenModal,
    handleHideSeenModal,
  ] = useDelayShowHideHandler();
  const [editorModel, setEditorModel] = useState();
  const [date, setDate] = useState({
    start: undefined,
    end: undefined,
  });
  const [time, setTime] = useState({
    start: undefined,
    end: undefined,
  });
  const [duration, setDuration] = useState();
  const [commentsFiles, setCommentsFiles] = useState([]);
  const [openCreateCommentForm, setOpenCreateCommentForm] = useState(false);
  const [isNeedCheckLoadMore, setCheckLoadMore] = useState(false);
  const [isErrorLoadMore, setStatusErrorLoadMore] = useState(false);
  const { enqueueSnackbar } = useSnackbar();

  const history = useHistory();
  const location = useLocation();
  const params = useParams();
  const { companyId, teamId, eventId } = params;
  const { scrollToTargetComment } = useScrollToTargetComment();

  useEventSocket({
    scheduleId: currentSchedule?._id,
    eventId: params?.eventId,
    userId: user?._id,
  }, dispatch);
  useEventCheerNCommentSocket({ eventId, userId: user?._id }, dispatch);

  useInitiateRoleUser({
    payload: {
      teamId,
      featureType: typeRef.event,
      featureId: eventId,
    },
  });

  const { socket } = useUserTypingEventSocket({
    companyId,
    eventId,
    teamId,
    userId: user?._id,
  }, dispatch);

  // read notif automatically if there is readNotifId
  useMarkReadNotificationOnPageOpen();

  // initiate members below role
  // useInitiateTeamBelowRole({});
  const isUserCreator = currentScheduleEvent?.creator?._id === user?._id;

  const [
    showActivities,
    handleShowActivities,
    handleHideActivities,
  ] = useDelayShowHideHandler();

  // for open create comment
  const handleOpenCreateCommentForm = useCallback((value) => {
    setOpenCreateCommentForm(value);
  }, []);
  const createCommentRef = createRef();
  const scrollAndOpenCreateCommentForm = useCallback(() => {
    if (createCommentRef && createCommentRef.current) {
      createCommentRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
    }
    setTimeout(() => {
      handleOpenCreateCommentForm(true);
    }, 800);
  }, [createCommentRef]);

  const sortingCurrentEventComment = useCallback(
    () => sortingListByCreatedAt(currentScheduleEvent?.comments),
    [currentScheduleEvent],
  );
  const isCommentsLengthAtTheLimitOrAbove = useCallback(
    (comments) => isNumber(comments?.length) && comments?.length >= EventConstants.limitComment,
    [],
  );
  const checkIfEventNeedToLoad = useCallback(() => {
    if (!isNeedCheckLoadMore || isErrorLoadMore) return false;

    return isCommentsLengthAtTheLimitOrAbove(previousEventComment);
  }, [previousEventComment, isErrorLoadMore, isNeedCheckLoadMore]);

  const resetPreviousEventComment = useCallback(() => {
    EventActions.setPreviousEventComment({
      previousEventComment: initialState.previousEventComment,
    }, dispatch);
    EventActions.setCurrentScheduleEvent({
      currentScheduleEvent: initialState.currentScheduleEvent,
    }, dispatch);
  }, []);

  useEffect(() => () => resetPreviousEventComment(), []);

  useEffect(() => {
    if (currentScheduleEvent.comments === undefined) return;

    const joinedData = sortingCurrentEventComment();

    const prevArray = [...commentsFiles];
    const updatedItems = differenceWith(joinedData, prevArray, isEqual);
    const isItemRemoved = isArray(prevArray) && isArray(joinedData)
    && prevArray.length > joinedData.length;

    if (updatedItems.length > 0 || isItemRemoved) {
      setCommentsFiles([...joinedData]);
    }
  }, [currentScheduleEvent]);

  useEffect(() => {
    if (user._id === undefined) {
      history.push(`/check-login?previousPath=${location.pathname}`);
      return;
    }

    const startLoadings = handleLoadings('eventPage', [...loadings], 'start');
    setLoadings([...startLoadings]);

    const fetchApiEventDetail = async () => {
      try {
        const result = await EventActions.initiateEventV2({
          eventId,
          companyId,
          teamId,
          limit: CommentActions.getLimitCommentByPreviousCommentDiscussion(),
        }, dispatch);
        await TeamActions.initiateTeam({
          teamId,
          companyId,
          currentTeam,
        }, dispatch);
        if (isCommentsLengthAtTheLimitOrAbove(result?.data?.comments)) {
          setCheckLoadMore(true);
        }

        // if this is a recurring events, then redirect
        if (result?.data?.occurrenceId) {
          const { occurrenceId } = result.data;
          history.push(`/companies/${companyId}/teams/${teamId}/events/${eventId}/occurrences/${occurrenceId}`);
          return;
        }
        scrollToTargetComment();
      } catch (err) {
        const status = handleStatusMsg(err, 'error');

        dispatch({
          type: actionTypes.SET_ERROR_RESPONSE,
          errorResponse: { message: status.message },
        });

        history.push(`/errors?previousPath=${location.pathname}`);
      } finally {
        const endLoadings = handleLoadings('eventPage', [...loadings], 'end');
        setLoadings([...endLoadings]);
      }
    };
    fetchApiEventDetail();
  }, [location]);

  const formatTime = (inputDate) => {
    const minute = inputDate.getMinutes();
    let hour = inputDate.getHours();
    const ampm = hour >= 12 ? 'PM' : 'AM';
    // eslint-disable-next-line no-mixed-operators
    hour = ((hour + 11) % 12 + 1);
    const prefixHour = hour >= 10 ? '' : '0';
    const prefixMinute = minute >= 10 ? '' : '0';

    const formattedTime = `${prefixHour + hour}:${prefixMinute}${minute} ${ampm}`;
    return formattedTime;
  };

  useEffect(() => {
    if (isEmpty(currentScheduleEvent)) {
      return;
    }

    let initialContent;
    if (checkIsJson(currentScheduleEvent?.content)) {
      initialContent = draftToHtml(JSON.parse(currentScheduleEvent?.content));
    } else {
      initialContent = currentScheduleEvent?.content;
    }

    setEditorModel(initialContent);
    setIsInitialContentReady(true);

    // bikin tanggal start
    let startDateString;
    startDateString = new Date(currentScheduleEvent?.startDate);
    startDateString = startDateString.toString();
    startDateString = startDateString.split(' ').slice(0, 3).join(' ');
    setDate((prevValue) => ({
      ...prevValue,
      start: startDateString,
    }));

    // bikin tanggal end
    let endDateString;
    endDateString = new Date(currentScheduleEvent?.endDate);
    endDateString = endDateString.toString();
    endDateString = endDateString.split(' ').slice(0, 3).join(' ');
    setDate((prevValue) => ({
      ...prevValue,
      end: endDateString,
    }));

    // bikin duration hari jika lebih dari 1 hari eventnya
    const differenceDays = dateDifferenceInDays(
      currentScheduleEvent?.endDate,
      currentScheduleEvent?.startDate,
    );
    setDuration(differenceDays);

    // bikin start time
    const initialStartDate = new Date(currentScheduleEvent?.startDate);
    const startTime = formatTime(initialStartDate);

    setTime((prevValue) => ({
      ...prevValue,
      start: startTime,
    }));

    // bikin end time
    const initialEndDate = new Date(currentScheduleEvent?.endDate);
    const endTime = formatTime(initialEndDate);

    setTime((prevValue) => ({
      ...prevValue,
      end: endTime,
    }));
  }, [currentScheduleEvent]);

  const handleShowEventMenu = () => {
    setShowEventMenu(!showEventMenu);
  };

  const handleCancelShowEventMenu = () => {
    setShowEventMenu(false);
  };

  const handleShowAddCalendarMenu = () => {
    setShowAddCalendarMenu(!showAddCalendarMenu);
  };

  const handleCancelShowAddCalendarMenu = () => {
    setShowAddCalendarMenu(false);
  };

  const handleLoadMoreComments = async () => {
    try {
      const result = await EventActions.loadMoreEventComment({
        eventId,
        companyId,
        teamId,
        currentScheduleEvent,
      }, dispatch);
    } catch (error) {
      const status = handleStatusMsg(error, 'error');

      if (status?.message) {
        enqueueSnackbar(status.message, {
          variant: 'error',
        });
      }

      setStatusErrorLoadMore(true);
    }
  };
  const pageType = PageConstants.pageType.events;
  const breadcrumb = generateRoutesAndTitle({
    type: pageType,
    data: {
      companyId, currentTeam, currentSchedule, currentScheduleEvent,
    },
  });
  SearchJumpToActions.saveRecentPage({
    company: currentCompany,
    team: currentTeam,
    userId: user?._id,
    itemId: eventId,
    url: breadcrumb.url,
    routes: breadcrumb.routes,
    name: breadcrumb.title,
    type: pageType,
  });
  return (
    <>
      <Title title={currentScheduleEvent?.title === undefined ? 'Jadwal' : currentScheduleEvent?.title} />
      <GeneralSubNavBar
        breadcrumbRoutes={breadcrumb.routes}
        pageTitle={breadcrumb.title}
        bottomBarOverviewOptionId="schedules"
        bottomBarGlobalActions={(
          <>
            <GlobalActionButton
              handleClick={scrollAndOpenCreateCommentForm}
              text="Tambah Komentar"
              icon={<AddIcon />}
            />
            <GlobalActionButton
              handleClick={handleShowModalRoleUser}
              text="Akses Saya"
              icon={<VerifiedUserIcon />}
            />
          </>
        )}
      />
      <LayoutMain>
        <PageLoading wait="eventPage" loadings={loadings}>
          <SurfacePaperMain>
            <div className={styles.container}>
              <div className={styles.headerSection}>
                <div className={styles.titleSection}>
                  <div className={styles.titleSection__container}>
                    {currentScheduleEvent?.archived ? (
                      <>
                        {currentScheduleEvent?.archived.status ? (
                          <div className={styles.archivedSection}>
                            <ArchiveIcon />
                            <h1>Jadwal ini udah terarsip</h1>
                          </div>
                      ) : null}
                      </>
                  ) : null}
                    <div className={styles.titleSection__title}>
                      {duration
                        ? (
                          <div className={styles.boxDateContainer}>
                            <div className={styles.boxDate}>
                              <CalendarTodayIcon />
                              <p>{date.start}</p>
                            </div>
                            <span>-</span>
                            <div className={styles.boxDate}>
                              <CalendarTodayIcon />
                              <p>{date.end}</p>
                            </div>
                          </div>
                        )
                        : (
                          <div className={styles.boxDate}>
                            <CalendarTodayIcon />
                            <p>{date.start}</p>
                          </div>
                        )}
                      <div className={styles.titleAndCreator}>
                        {currentScheduleEvent
                          ? (
                            <>
                              <h1>
                                <PrivateIcon data={currentScheduleEvent} />
                                {' '}
                                {currentScheduleEvent?.title}
                              </h1>
                              <span>
                                {`Dibuat oleh ${currentScheduleEvent?.creator?.fullName} `}
                                <TimeAgo date={currentScheduleEvent?.createdAt} />
                              </span>
                            </>
                          )
                          : null}

                      </div>

                    </div>

                  </div>
                </div>
                <div className={styles.menuSection}>
                  {checkIfUserAuthorizedToEditData(currentScheduleEvent,
                    user,
                    currentRoleUser,
                    currentTeamBelowRole) && (
                    <RoundActionMenu
                      clicked={handleShowEventMenu}
                      cancel={handleCancelShowEventMenu}
                      medium
                    >
                      <MoreHorizIcon />
                    </RoundActionMenu>
                  )}
                  {showEventMenu
                    ? (
                      <EventMenu
                        event={currentScheduleEvent}
                        cancel={handleCancelShowEventMenu}
                      />
                    ) : null}
                </div>
              </div>

              <SeparatorLine />
              <div className={styles.titleSection__detail}>
                <div className={styles.detail__menu}>
                  <div className={styles.detail__menu__title}>
                    <p>Kapan</p>
                  </div>
                  <div className={styles.detail__menu__content}>
                    {duration
                      ? <p>{`${date.start}, ${time.start} - ${date.end}, ${time.end} (${duration} days)`}</p>
                      : <p>{`${date.start}, ${time.start} - ${time.end}`}</p>}

                    {currentScheduleEvent?.googleCalendar?.eventGCalTemplateLink
                      ? (
                        <p
                          onClick={handleShowAddCalendarMenu}
                          className={styles.addToCalendar}
                        >
                          Tambahkan ke Kalender Saya
                        </p>
                    ) : null}

                    {showAddCalendarMenu
                      ? (
                        <AddEventCalendarMenu
                          event={currentScheduleEvent}
                          cancel={handleCancelShowAddCalendarMenu}
                        />
                      )
                      : null}
                  </div>
                </div>
                <div className={styles.detail__menu}>
                  <div className={styles.detail__menu__title}>
                    <p>Peserta</p>
                  </div>
                  <div className={styles.detail__menu__content__subscriber}>
                    {currentScheduleEvent?.subscribers?.length < 1
                      ? <p>Jadwal ini belum mempunyai peserta.</p> : null}
                    {currentScheduleEvent?.subscribers !== undefined
                      ? (
                        <>
                          {currentScheduleEvent?.subscribers.map((subscriber) => (
                            <>
                              <div className={styles.subscribers}>
                                <DisplayAvatarMemberWithOnline
                                  src={subscriber?.photoUrl}
                                  userId={subscriber._id}
                                  size="sm"
                                  marginRight="0px"
                                />
                                <p>{subscriber.fullName}</p>
                              </div>
                            </>
                          ))}
                        </>
                    ) : null}

                  </div>
                </div>
                <div className={styles.detail__menu__notes}>
                  <div className={styles.detail__menu__title}>
                    <p>Catatan</p>
                  </div>
                  <div className={styles.detail__menu__content}>
                    {currentScheduleEvent?.content === '' || !currentScheduleEvent?.content
                      ? <p>Jadwal ini belum mempunyai catatan.</p> : null}
                    {isInitialContentReady
                      ? <EditorView model={editorModel} />
                      : <p>Please wait...</p>}
                  </div>
                </div>
              </div>
              <div className={styles.cheersSection}>
                {isEmpty(currentScheduleEvent) ? null : <CheersContainer cheers={currentScheduleEvent?.cheers} type="eventMain" receiver={currentScheduleEvent?.creator?._id} primaryParentId={currentScheduleEvent?._id} />}
              </div>
              <div className={styles.seenSection}>
                {isUserCreator && (
                <SeenInfo
                  count={currentScheduleEvent?.seen?.length}
                  onClick={handleShowSeenModal}
                  variant="black"
                />
                )}
              </div>
              <div className={styles.typingSection}>
                <UserTyping
                  allUserTyping={allUserTypingEvent}
                  companyMembers={currentCompany?.members}
                />
              </div>
              <div className={styles.commentSection}>
                {!showActivities && (
                <InfinityScroll
                  dataLength={commentsFiles?.length}
                  hasMore={checkIfEventNeedToLoad()}
                  next={handleLoadMoreComments}
                  emptyMessage="Belum ada komentar disini..."
                  style={{
                    overflow: undefined,
                  }}
                >
                  <div>
                    <CommentsSectionContainer
                      comments={commentsFiles}
                      type="event"
                      ref={createCommentRef}
                      openForm={openCreateCommentForm}
                      onOpenForm={handleOpenCreateCommentForm}
                      handleShowActivities={handleShowActivities}
                      socket={socket}
                    />
                  </div>
                </InfinityScroll>
                )}
                {showActivities && (
                <ActivityByModule
                  variant={serviceTypeRef.event}
                  handleHideActivities={handleHideActivities}
                />
                )}
              </div>
            </div>
          </SurfacePaperMain>
        </PageLoading>
        <MyRoleUser
          open={showModalRoleUser}
          onClose={handleHideModalRoleUser}
          featureId={eventId}
          featureType={typeRef.event}
          featureTitle={currentScheduleEvent?.title}
          roleType={roleTypeRef.feature}
          featureData={currentScheduleEvent}
        />
        <SeenModal
          open={showSeenModal}
          onClose={handleHideSeenModal}
          type={SeenConstants.typeModule.event}
          itemId={eventId}
        />
      </LayoutMain>
    </>
  );
}

export default EventPage;
