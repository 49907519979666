const limitBucket = 8;
const typeCallback = {
  NEW: 'new',
  DELETE: 'delete',
  EDIT: 'edit',
};

export {
  limitBucket,
  typeCallback,
};
