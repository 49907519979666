import React from 'react';
import PropTypes from 'prop-types';
import { DisplayAvatarMember } from '../../../components/UI_V2/Display/Avatar';
import { DisplayDateBox } from '../../../components/UI_V2/Display/Date';
import { DisplayTextSubHeadline } from '../../../components/UI_V2/Display/Text';
import styles from './HeaderPost.module.css';
import Color from '../../../themes/colors';

const CommentDiscussPageHeaderPost = ({ commentParentData }) => (
  <div className={styles.titleSection__creator}>
    <div className={styles.titleSection__creator__photo}>
      <DisplayAvatarMember
        alt={commentParentData?.creator?.fullName}
        src={commentParentData?.creator?.photoUrl}
        size="xl"
      />
    </div>
    <div className={styles.titleSection__creator__body}>
      <div className={styles.titleAndDateSection}>
        <div className={styles.title}>
          <DisplayTextSubHeadline
            mode="22"
            decoration="bold"
            color={Color.grayIconEditDocs}
          >
            {commentParentData?.title}
          </DisplayTextSubHeadline>
        </div>
        <div>
          <DisplayDateBox date={commentParentData.createdAt} relativeTime dateStyle="MonthDate" />
        </div>
      </div>
      <div className={styles.creatorName}>
        <DisplayTextSubHeadline
          mode="14"
          color={Color.blueNavy4}
        >
          {commentParentData?.creator?.fullName}
        </DisplayTextSubHeadline>
      </div>
    </div>
  </div>
);

CommentDiscussPageHeaderPost.propTypes = {
  commentParentData: PropTypes.object.isRequired,
};

export default CommentDiscussPageHeaderPost;
