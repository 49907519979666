import React, {
  memo,
} from 'react';
import PropTypes from 'prop-types';
import DoneAllIcon from '@mui/icons-material/DoneAll';
import Tooltip from '@mui/material/Tooltip';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import CloseIcon from '@mui/icons-material/Close';
import styles from './MarkSelection.module.css';

const MarkSelection = ({
  handleCancel,
  handlePublish,
  handleCheckAll,
  checkMode,
  isListEmpty,
}) => (
  <div className={styles.container}>
    <Tooltip title="Batal">
      <CloseIcon
        className={styles.buttonCancel}
        onClick={handleCancel}
      />
    </Tooltip>
    <Tooltip title="Terapkan">
      <DoneAllIcon
        className={styles.buttonRead}
        onClick={isListEmpty ? handleCancel : handlePublish}
      />
    </Tooltip>
    <div onClick={isListEmpty ? () => null : handleCheckAll} className={styles.selectAllSection}>
      {checkMode
        ? <CheckBoxIcon className={styles.checkBoxRead} />
        : <CheckBoxOutlineBlankIcon className={styles.checkBoxUnread} />}
    </div>
  </div>
);

MarkSelection.propTypes = {
  handleCancel: PropTypes.func.isRequired,
  handlePublish: PropTypes.func.isRequired,
  handleCheckAll: PropTypes.func.isRequired,
  checkMode: PropTypes.bool.isRequired,
  isListEmpty: PropTypes.bool,
};

MarkSelection.defaultProps = {
  isListEmpty: false,
};

export default memo(MarkSelection);
