/* eslint-disable consistent-return */
import { useCallback } from 'react';
import { CheerActions, QuestionActions } from '../actions';
import { CheerConstants, QuestionConstants } from '../constants';
import { connectSocketCheckIn, connectSocketQuestion } from '../services/socket';
import { useSocketHooks } from './SocketHooks';

function useQuestionSocket({ checkInId, questionId, userId }, dispatch) {
  const callbackCheckIn = useCallback((question) => {
    if (question?._id === questionId) {
      QuestionActions.incomingQuestion({ question }, dispatch);
    }
  }, [questionId]);

  const listenSocket = (socket, params) => {
    socket
      .on(`question-update-${params?.checkInId}`, callbackCheckIn)
      .on(`question-archive-${params?.checkInId}`, callbackCheckIn);
  };
  const removeListener = (socket, params) => {
    socket
      .off(`question-update-${params?.checkInId}`)
      .off(`question-archive-${params?.checkInId}`);
    socket.disconnect();
  };

  useSocketHooks({
    params: { checkInId, userId },
    connectSocket: connectSocketCheckIn,
    listenSocket,
    removeListener,
  });
}

function useQuestionCheerNCommentSocket({ questionId, userId }, dispatch) {
  // callback comment
  const callbackNewComment = useCallback((comment) => {
    QuestionActions.incomingQuestionComment({
      comment,
      typeAction: QuestionConstants.typeCallback.NEW,
    }, dispatch);
  }, []);
  const callbackUpdateComment = useCallback((comment) => {
    QuestionActions.incomingQuestionComment({
      comment,
      typeAction: QuestionConstants.typeCallback.EDIT,
    }, dispatch);
  }, []);
  const callbackDeleteComment = useCallback((comment) => {
    QuestionActions.incomingQuestionComment({
      comment,
      typeAction: QuestionConstants.typeCallback.DELETE,
    }, dispatch);
  }, []);

  // callback cheer comment
  const callbackNewCheerComment = useCallback((cheer) => {
    CheerActions.incomingCheerFromSecondaryParent({
      cheer,
      typeAction: CheerConstants.typeCallback.NEW,
      secondaryParentAction: QuestionActions.incomingQuestionComment,
      typeActionEditSecondaryParent: QuestionConstants.typeCallback.EDIT,
    }, dispatch);
  }, []);
  const callbackDeleteCheerComment = useCallback((cheer) => {
    CheerActions.incomingCheerFromSecondaryParent({
      cheer,
      typeAction: CheerConstants.typeCallback.DELETE,
      secondaryParentAction: QuestionActions.incomingQuestionComment,
      typeActionEditSecondaryParent: QuestionConstants.typeCallback.EDIT,
    }, dispatch);
  }, []);

  const listenSocket = (socket, params) => {
    socket
      .on(`questionComment-new-${params?.questionId}`, callbackNewComment)
      .on(`questionComment-update-${params?.questionId}`, callbackUpdateComment)
      .on(`questionComment-delete-${params?.questionId}`, callbackDeleteComment)
      .on('cheerComment-new', callbackNewCheerComment)
      .on('cheerComment-delete', callbackDeleteCheerComment);
  };
  const removeListener = (socket, params) => {
    socket
      .off(`questionComment-new-${params?.questionId}`)
      .off(`questionComment-update-${params?.questionId}`)
      .off(`questionComment-delete-${params?.questionId}`)
      .off('cheerComment-new')
      .off('cheerComment-delete');
    socket.disconnect();
  };

  useSocketHooks({
    params: { questionId, userId },
    connectSocket: connectSocketQuestion,
    listenSocket,
    removeListener,
  });
}

export {
  useQuestionSocket,
  useQuestionCheerNCommentSocket,
};
