import React, {
  memo, useContext, useEffect, useState,
} from 'react';
import PropTypes from 'prop-types';
import DownloadIcon from '@mui/icons-material/Download';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { enqueueSnackbar } from 'notistack';
import { useParams } from 'react-router-dom';
import styles from './SelectTeam.module.css';
import Color from '../../../../themes/colors';
import { InputButtonDropdown, InputButtonMain } from '../../../UI_V2/Input/Button';
import { NavigationMenuBasic } from '../../../UI_V2/Navigation/Menu';
import SingleTeamFilterSection from '../../FilterBar/SingleTeamFilterSection/SingleTeamFilterSection';
import { useDelayShowHideHandler, useSearch } from '../../../../hooks/HelperHooks';
import { GlobalContext } from '../../../../contexts/GlobalStateProvider';
import handleStatusMsg from '../../../../utilities/handleStatusMsg';
import { TeamActions } from '../../../../actions';
import { DisplayTextBody } from '../../../UI_V2/Display/Text';

const SelectTeam = ({
  handleSelectTeamId,
  disableSelectTeam,
}) => {
  const [{
    user,
    currentTeam, teams,
  }, dispatch] = useContext(GlobalContext);
  const singleTeamFilterSectionSearchHook = useSearch({ originalList: teams });
  const [isLoadingTeam, setIsLoadingTeam] = useState(false);

  const params = useParams();
  const { companyId } = params;

  const [
    showSingleTeamSectionMenu,
    handleShowSingleTeamSectionMenu,
    handleHideSingleTeamSectionMenu,
  ] = useDelayShowHideHandler();

  const [checkSingleTeam, setCheckSingleTeam] = useState();

  const initiateCurrentTeam = async () => {
    try {
      const result = await TeamActions.initiateTeam(
        {
          companyId,
          teamId: checkSingleTeam,
          currentTeam,
        },
        dispatch,
      );
    } catch (error) {
      const status = handleStatusMsg(error, 'error');

      enqueueSnackbar(status.message, {
        variant: 'error',
      });
    } finally {
      setIsLoadingTeam(false);
    }
  };

  const findTeamFromTeams = (teamId, listTeams) => {
    const team = listTeams.find((elem) => elem._id === teamId);
    return team;
  };

  const handleClickSingleTeam = (valueTeamId) => {
    if (valueTeamId === checkSingleTeam) {
      handleHideSingleTeamSectionMenu();
      return;
    }
    setIsLoadingTeam(true);
    setCheckSingleTeam(valueTeamId);
    handleSelectTeamId(valueTeamId);
    handleHideSingleTeamSectionMenu();
  };

  useEffect(() => {
    if (!user?._id) return;
    if (!currentTeam?._id && !checkSingleTeam) {
      handleClickSingleTeam(teams?.[0]?._id);
      setIsLoadingTeam(false);
      return;
    }
    if (currentTeam?._id && !checkSingleTeam) {
      const isTeamExistOnThisCompany = findTeamFromTeams(currentTeam._id, teams);
      if (isTeamExistOnThisCompany) {
        handleClickSingleTeam(currentTeam._id);
      } else {
        handleClickSingleTeam(teams?.[0]?._id);
      }
      setIsLoadingTeam(false);
      return;
    }
    initiateCurrentTeam();
  }, [checkSingleTeam]);

  const handleResetSingleTeam = () => {
    setCheckSingleTeam(undefined);
  };

  const firstTeamName = teams.length > 0 ? teams[0].name : 'Belum ada tim';

  return (
    <div className={styles.container}>
      {disableSelectTeam
      && (
      <DisplayTextBody>
        {currentTeam?.name ? currentTeam.name : firstTeamName}
      </DisplayTextBody>
      )}
      {!disableSelectTeam && (
      <InputButtonDropdown
        onClick={disableSelectTeam ? () => null : handleShowSingleTeamSectionMenu}
        isLoading={isLoadingTeam}
        text={currentTeam?.name ? currentTeam.name : firstTeamName}
        initialDataString={currentTeam?.name ? currentTeam.name : firstTeamName}
      />
      )}
      {showSingleTeamSectionMenu && (
        <NavigationMenuBasic
          size=""
          position="centerRight"
          onClose={handleHideSingleTeamSectionMenu}
        >
          <div className={styles.selectTeam}>
            <SingleTeamFilterSection
              checkSingleTeam={checkSingleTeam}
              handleClickSingleTeam={handleClickSingleTeam}
              searchHook={singleTeamFilterSectionSearchHook}
            />
          </div>

        </NavigationMenuBasic>
      )}
    </div>
  );
};

SelectTeam.propTypes = {
  handleSelectTeamId: PropTypes.func.isRequired,
  disableSelectTeam: PropTypes.bool,
};

SelectTeam.defaultProps = {
  disableSelectTeam: false,
};

export default memo(SelectTeam);
