import React, {
  memo, useContext,
  useEffect,
  useState,
} from 'react';
import PropTypes from 'prop-types';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import styles from './FilterSearch.module.css';
import { DisplayTextBody } from '../../../../../components/UI_V2/Display/Text';
import Color from '../../../../../themes/colors';
import introImage from '../../../../../assets/PrivateChatPage/intro-chat-section.png';
import { InputButtonDropdown } from '../../../../../components/UI_V2/Input/Button';
import FilterDropdown from '../../../../../components/Container/FilterDropdown/FilterDropdown';
import { GlobalContext } from '../../../../../contexts/GlobalStateProvider';
import { FilterBarConstants } from '../../../../../constants';
import { getStoreFilterSearch, setStoreFilterSearch } from '../../../../../utilities/localStorage';

// lingkupnya :
// dapetin initial data dari localstorage, kasih initial data ke filterDropdown.
// dapetin selected data dari filterdropdown, trigger API utk search ulang.

const FilterSearch = ({
  onApplyFilters,
}) => {
  const [{ user, currentCompany, teams }] = useContext(GlobalContext);
  const initialFilterSearch = getStoreFilterSearch(
    { companyId: currentCompany._id, userId: user._id },
  );
  const initialFilterTeams = initialFilterSearch?.filterTeams || teams.map((team) => team._id);
  let initialFilterFeatureTypes = initialFilterSearch?.filterFeatureTypes
  || FilterBarConstants.featureTypeList.map((feature) => feature._id);

  // always remove comments from initial search, since its heavyweight operation
  initialFilterFeatureTypes = initialFilterFeatureTypes.filter((feature) => (
    feature !== 'comments'
  ));
  const initialFilterArchived = initialFilterSearch?.filterArchived || false;

  const [initialFilters, setInitialFilters] = useState({
    filterTeams: initialFilterTeams,
    filterFeatureTypes: initialFilterFeatureTypes,
    filterArchived: initialFilterArchived,
  });

  const listTeams = teams;
  const listFeatureTypes = FilterBarConstants.featureTypeList;

  useEffect(() => {
    onApplyFilters(initialFilters, true);
  }, []);

  const handleClickApplyFilters = (values) => {
    onApplyFilters(values);
    setInitialFilters(values);
    setStoreFilterSearch({
      companyId: currentCompany._id,
      userId: user._id,
      filter: values,
    });
  };

  return (
    <div className={styles.container}>
      <FilterDropdown
        listFeatureTypes={listFeatureTypes}
        initialSelectedFeatureTypes={initialFilters.filterFeatureTypes}
        listTeams={listTeams}
        initialSelectedTeams={initialFilters.filterTeams}
        onApplyFilters={handleClickApplyFilters}
        initialArchived={initialFilters.filterArchived}
        invertResetFeatureTypeCondition
        invertResetTeamCondition
      />
    </div>
  );
};

FilterSearch.propTypes = {
  onApplyFilters: PropTypes.func.isRequired,
};

export default memo(FilterSearch);
