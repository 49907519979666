import React, { useEffect, useContext } from 'react';
import Helmet from 'react-helmet';
import PropTypes from 'prop-types';
import { GlobalContext } from '../../contexts/GlobalStateProvider';
import faviconIcon from '../../assets/Favicon/favicon.ico';
import faviconPlusIcon from '../../assets/Favicon/favicon-plus.ico';
import faviconOneIcon from '../../assets/Favicon/favicon1.ico';
import faviconTwoIcon from '../../assets/Favicon/favicon2.ico';
import faviconThreeIcon from '../../assets/Favicon/favicon3.ico';
import faviconFourIcon from '../../assets/Favicon/favicon4.ico';
import faviconFiveIcon from '../../assets/Favicon/favicon5.ico';
import faviconSixIcon from '../../assets/Favicon/favicon6.ico';
import faviconSevenIcon from '../../assets/Favicon/favicon7.ico';
import faviconEightIcon from '../../assets/Favicon/favicon8.ico';
import faviconNineIcon from '../../assets/Favicon/favicon9.ico';

const Title = ({ title }) => {
  const [{
    counterNotif,
  }, dispatch] = useContext(GlobalContext);

  const favicon = document.getElementById('favicon');

  useEffect(() => {
    const totalNotifCount = counterNotif.chat + counterNotif.nonChat;

    switch (totalNotifCount) {
      case 0:
        favicon.href = faviconIcon;
        break;
      case 1:
        favicon.href = faviconOneIcon;
        break;
      case 2:
        favicon.href = faviconTwoIcon;
        break;
      case 3:
        favicon.href = faviconThreeIcon;
        break;
      case 4:
        favicon.href = faviconFourIcon;
        break;
      case 5:
        favicon.href = faviconFiveIcon;
        break;
      case 6:
        favicon.href = faviconSixIcon;
        break;
      case 7:
        favicon.href = faviconSevenIcon;
        break;
      case 8:
        favicon.href = faviconEightIcon;
        break;
      case 9:
        favicon.href = faviconNineIcon;
        break;
      default:
        favicon.href = faviconPlusIcon;
        break;
    }
  }, [counterNotif]);

  return (
    <Helmet defer={false}>
      <title>{title}</title>
    </Helmet>
  );
};

Title.propTypes = {
  title: PropTypes.string,
};

Title.defaultProps = {
  title: 'Cicle - Kelola & Lihat Laporan Tugas, serta Komunikasi Realtime dalam 1 Aplikasi',
};

export default Title;
