import React, { useState, useEffect } from 'react';
import { lowerCase } from 'lodash';
import PropTypes from 'prop-types';
import { InputTextFieldMain } from '../../../components/UI_V2/Input/TextField';

const SearchMultipleMembers = ({ members, handleFilterMembers }) => {
  const [search, setSearch] = useState({ value: undefined });
  const [resultSearch, setResultSearch] = useState([...members]);

  useEffect(() => {
    if (search.value === undefined) {
      return;
    }

    const filteredResult = members.filter((member) => {
      const lowName = lowerCase(member.fullName);
      const lowSearch = lowerCase(search.value);
      return lowName.includes(lowSearch);
    });

    setResultSearch([...filteredResult]);
  }, [search]);

  const handleChanged = (event) => {
    const { value } = event.target;

    setSearch((prevValue) => ({
      ...prevValue,
      value,
    }));
  };

  useEffect(() => {
    handleFilterMembers([...resultSearch]);
  }, [resultSearch]);

  return (
    <InputTextFieldMain
      placeholder="Cari anggota..."
      autoFocus
      onChange={(event) => handleChanged(event)}
    />
  );
};

SearchMultipleMembers.propTypes = {
  members: PropTypes.array.isRequired,
  handleFilterMembers: PropTypes.func.isRequired,
};

export default SearchMultipleMembers;
