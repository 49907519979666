import React, { memo, useState } from 'react';
import PropTypes from 'prop-types';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { Skeleton } from '@mui/material';
import styles from './ListCardsSkeleton.module.css';

const lists = [
  '1',
  '1',
  '1',
  '1',
  '1',
  '1',
  '1',
  '1',
  '1',
];

const ListCardsSkeleton = () => (
  <div className={styles.container}>
    {lists.map(() => (
      <div className={styles.skeleton}>
        <Skeleton
          animation="wave"
          variant="rounded"
          height={40}
        />
      </div>
    ))}
  </div>
);

export default memo(ListCardsSkeleton);
