import React, {
  useState, useContext, useEffect, memo, useCallback,
} from 'react';
import PropTypes from 'prop-types';
import PersonAddAlt1OutlinedIcon from '@mui/icons-material/PersonAddAlt1Outlined';
import _ from 'lodash';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { useSnackbar } from 'notistack';
import { useParams, useHistory, useLocation } from 'react-router-dom';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { InputButtonMain } from '../../../UI_V2/Input/Button';
import styles from './ManageRoleSection.module.css';
import ImageMember from '../../../UI/ImageMember/ImageMember';
import {
  DisplayTextBody, DisplayTextHeadline, DisplayTextSubBody, DisplayTextSubHeadline,
} from '../../../UI_V2/Display/Text';
import RoleUserItem from './RoleUserItem/RoleUserItem';
import { RoleActions } from '../../../../actions';
import { GlobalContext, initialState } from '../../../../contexts/GlobalStateProvider';
import { useInfinityScrollHooks } from '../../../../hooks/InfinityScrollHooks';
import { RoleConstants } from '../../../../constants';
import handleStatusMsg from '../../../../utilities/handleStatusMsg';
import InfinityScroll from '../../../UI/InfinityScroll/InfinityScroll';
import { FeedbackDialogGeneral } from '../../../UI_V2/Feedback/Dialog';
import Color from '../../../../themes/colors';
import handleLoadings from '../../../../utilities/handleLoadings';
import { checkIsUserCreatorByFeatureType, hasPermission } from '../../../../actions/RoleActions';
import {
  roleTypeRef,
  roles,
  typeRef,
  moduleTitleByRoleTypeRef,
} from '../../../../constants/RoleConstants';
import ListRoleUserSkeleton from './ListRoleUserSkeleton/ListRoleUserSkeleton';
import FeedbackLoadingMain from '../../../UI_V2/Feedback/Loading/Main/Main';
import { limitChar } from '../../../../utilities/stringUtil';
import { getIndexItemByIDFromList } from '../../../../utilities/arrayUtil';

const ManageRoleSection = ({
  featureId,
  featureType,
  featureTitle,
  roleType,
  onBack,
  featureData,
}) => {
  const [{
    user,
    currentRoleUsers,
    previousRoleUsers,
    currentCompany,
    currentRoleUser,
    currentBucket,
    currentBlastPost,
    currentCard,
    currentBucketDoc,
    currentCheckInQuestion,
    currentBucketFile,
    currentOccurrence,
    currentScheduleEvent,
  }, dispatch] = useContext(GlobalContext);
  const [loadings, setLoadings] = useState([]);
  const [changedRoleUsers, setChangedRoleUsers] = useState([]);

  const params = useParams();
  const history = useHistory();
  const location = useLocation();
  const { companyId, teamId, bucketId } = params;

  const { enqueueSnackbar } = useSnackbar();
  const isUserAdminOrAbove = hasPermission(currentRoleUser, roles.adminOrAbove);
  const isUserManagerOrAbove = hasPermission(currentRoleUser, roles.managerOrAbove);
  const isUserSpvOrBelow = hasPermission(currentRoleUser, roles.spvOrBelow);

  // const isUserCreator = checkIsUserCreatorByFeatureType({
  //   featureType,
  //   userId: user._id,
  //   currentBlastPost,
  //   currentCard,
  //   currentBucketDoc,
  //   currentCheckInQuestion,
  //   currentBucketFile,
  //   currentOccurrence,
  //   currentScheduleEvent,
  // });

  const isRoleTypeCompany = roleType === roleTypeRef.company;
  const isRoleTypeTeam = roleType === roleTypeRef.team;
  const isRoleTypeFeature = roleType === roleTypeRef.feature;

  const isFeatureTypeDontHaveManageRoles = featureType === typeRef.blast
    || featureType === typeRef.board
    || featureType === typeRef.schedule
    || featureType === typeRef.checkIn
    || featureType === typeRef.groupChat
    || featureType === typeRef.schedule
    || (featureType === typeRef.bucket && bucketId && currentBucket?.type === 'main');

  const isAuthorizedToManageRoles = ((isRoleTypeCompany && isUserAdminOrAbove)
  || ((isRoleTypeTeam || isRoleTypeFeature) && (isUserManagerOrAbove)))
  && !isFeatureTypeDontHaveManageRoles;

  const getMoreLists = async () => {
    let result;
    if (isRoleTypeCompany) {
      result = await RoleActions.loadMoreRoleUsers({
        currentRoleUsers,
        companyId,
      }, dispatch);
    }

    if (isRoleTypeTeam) {
      await RoleActions.loadMoreRoleUserTeams({
        currentRoleUsers,
        companyId,
        teamId,
      }, dispatch);
    }

    if (isRoleTypeFeature) {
      result = await RoleActions.loadMoreRoleUserFeatures({
        currentRoleUsers,
        companyId,
        teamId,
        featureId,
        featureType,
      }, dispatch);
    }
    return result;
  };

  const resetPreviousLists = useCallback(() => {
    RoleActions.setPreviousRoleUsers({
      previousRoleUsers: initialState.previousRoleUsers,
    }, dispatch);
    RoleActions.setCurrentRoleUsers({
      currentRoleUsers: initialState.currentRoleUsers,
    }, dispatch);
  }, []);

  const {
    lists,
    handleLoadMoreLists,
    checkIfListNeedToLoad,
    setCheckLoadMore,
    isListsLengthAtTheLimitOrAbove,
  } = useInfinityScrollHooks({
    currentObjectWithKeyProperty: currentRoleUsers,
    previousLists: previousRoleUsers?.data,
    resetPreviousLists,
    getMoreLists,
    keyProperty: 'data',
    limitList: RoleConstants.limitRoleUsers,
  });

  const initiateRoleUsersApi = async () => {
    try {
      const startLoadings = handleLoadings('initiateRoleUsersApi', [...loadings], 'start');
      setLoadings([...startLoadings]);

      let result;

      if (isRoleTypeCompany) {
        result = await RoleActions.initiateRoleUsers({ companyId }, dispatch);
      }

      if (isRoleTypeTeam) {
        result = await RoleActions.initiateRoleUserTeams({ companyId, teamId }, dispatch);
      }

      if (isRoleTypeFeature) {
        result = await RoleActions.initiateRoleUserFeatures({
          companyId,
          teamId,
          featureType,
          featureId,
        }, dispatch);
      }
      if (isListsLengthAtTheLimitOrAbove(result?.data?.data)) {
        setCheckLoadMore(true);
      } else {
        setCheckLoadMore(false);
      }
    } catch (err) {
      const status = handleStatusMsg(err, 'error');

      enqueueSnackbar(status.message, {
        variant: 'error',
      });
    } finally {
      const endLoadings = handleLoadings('initiateRoleUsersApi', [...loadings], 'end');
      setLoadings([...endLoadings]);
    }
  };

  useEffect(() => {
    if (user._id === undefined) {
      history.push(`/check-login?previousPath=${location.pathname}`);
      return;
    }
    // if (!isUserManagerOrAbove) return;
    initiateRoleUsersApi();
  }, [location]);

  const updateRoleUsersApi = async () => {
    try {
      if (changedRoleUsers.length < 1) return;
      if (!isRoleTypeCompany && !isUserManagerOrAbove) {
        const status = handleStatusMsg('Only manager or above can do this', 'error');

        enqueueSnackbar(status.message, {
          variant: 'error',
        });
      }

      if (isRoleTypeCompany && !isUserAdminOrAbove) {
        const status = handleStatusMsg('Only admin or above can do this', 'error');

        enqueueSnackbar(status.message, {
          variant: 'error',
        });
      }
      const startLoadings = handleLoadings('updateRoleUsers', [...loadings], 'start');
      setLoadings([...startLoadings]);

      let result;
      if (isRoleTypeCompany) {
        result = await RoleActions.patchRoleUsersAndMergeNewList(
          {
            companyId,
            data: changedRoleUsers,
            currentRoleUsers,
          }, dispatch,
        );
      }

      if (isRoleTypeTeam) {
        result = await RoleActions.patchRoleUserTeamsAndMergeNewList(
          {
            companyId,
            data: changedRoleUsers,
            currentRoleUsers,
            teamId,
          }, dispatch,
        );
      }

      if (isRoleTypeFeature) {
        result = await RoleActions.patchRoleUserFeaturesAndMergeNewList(
          {
            companyId,
            data: changedRoleUsers,
            currentRoleUsers,
            teamId,
            featureType,
            featureId,
          }, dispatch,
        );
      }

      const status = handleStatusMsg(result, 'success');

      setChangedRoleUsers([]);

      enqueueSnackbar(status.message, {
        variant: 'success',
      });
    } catch (error) {
      const status = handleStatusMsg(error, 'error');

      enqueueSnackbar(status.message, {
        variant: 'error',
      });
    } finally {
      const endLoadings = handleLoadings('updateRoleUsers', [...loadings], 'end');
      setLoadings([...endLoadings]);
    }
  };

  const handleSelectRoleUser = (userRole) => {
    setChangedRoleUsers((prevValue) => [
      ...prevValue,
      userRole,
    ]);
  };

  const handleUnselectRoleUser = (userRole) => {
    setChangedRoleUsers((prevValue) => {
      const removedRoleUser = prevValue.filter((elem) => elem._id !== userRole._id);
      return removedRoleUser;
    });
  };
  return (
    <div className={styles.container}>
      <div
        onClick={onBack}
        className={styles.backButton}
      >
        <ArrowBackIcon />
      </div>
      <div className={styles.title}>
        <DisplayTextHeadline
          color={Color.blueNavyCicle}
          decoration="bold"
        >
          {`Daftar Akses di ${moduleTitleByRoleTypeRef[featureType]} : ${limitChar(featureTitle, 20)}`}
        </DisplayTextHeadline>
      </div>
      <div className={styles.description}>
        <DisplayTextBody
          mode="14"
        >
          {(isUserSpvOrBelow || isUserManagerOrAbove) && `Dibawah ini adalah akses Kamu dan orang lain spesifik di ${moduleTitleByRoleTypeRef[featureType]} ini.`}
          {isUserManagerOrAbove && !isFeatureTypeDontHaveManageRoles && ' Kamu bisa mengubah Akses orang-orang dibawahmu karena kamu adalah Creator/Manager/Admin/Super User.'}
          {isUserManagerOrAbove && isFeatureTypeDontHaveManageRoles && ' Walaupun kamu Creator/Manager/Admin/Super User, Akses di halaman ini mengikuti Akses di tingkat Tim. Kamu bisa mengubahnya disana.'}
        </DisplayTextBody>
      </div>
      <div id="roleUserList" className={styles.list}>
        <FeedbackLoadingMain
          wait="initiateRoleUsersApi"
          loadings={loadings}
          loadingComponent={<ListRoleUserSkeleton />}
        >
          <InfinityScroll
            scrollableTarget="roleUserList"
            dataLength={lists?.length || 0}
            hasMore={checkIfListNeedToLoad()}
            next={handleLoadMoreLists}
            emptyMessage="Belum ada pengguna disini..."
          >
            {lists.map((roleUser) => {
              if (!roleUser?.user?.fullName) return null;
              if (featureData) {
                const isMemberNotSubscriber = getIndexItemByIDFromList(
                  featureData.subscribers || featureData.members, roleUser.user,
                ) < 0;
                const isFeaturePrivate = !featureData.isPublic;
                const isMemberAdminOrAbove = hasPermission(roleUser, roles.adminOrAbove);
                if (isMemberNotSubscriber && isFeaturePrivate && !isMemberAdminOrAbove) {
                  return null;
                }
              }

              return (
                <RoleUserItem
                  user={user}
                  roleUser={roleUser}
                  handleSelectRoleUser={handleSelectRoleUser}
                  handleUnselectRoleUser={handleUnselectRoleUser}
                  currentRoleUser={currentRoleUser}
                  isAuthorizedToManageRoles={isAuthorizedToManageRoles}
                  isRoleTypeCompany={isRoleTypeCompany}
                />
              );
            })}
          </InfinityScroll>
        </FeedbackLoadingMain>
      </div>
      {isAuthorizedToManageRoles && (
      <div className={styles.action}>
        <div className={styles.actionText}>
          <DisplayTextBody>
            Terapkan?
          </DisplayTextBody>
          <DisplayTextSubBody>
            Ga perlu kuatir, kamu bisa mengubahnya lagi nanti.
          </DisplayTextSubBody>
        </div>
        <div className={styles.actionButton}>
          <InputButtonMain
            loadings={loadings}
            wait="updateRoleUsers"
            handleClick={updateRoleUsersApi}
          >
            Simpan

          </InputButtonMain>
        </div>
      </div>
      )}
    </div>
  );
};

ManageRoleSection.propTypes = {
  featureType: PropTypes.string.isRequired,
  featureId: PropTypes.string.isRequired,
  featureTitle: PropTypes.string.isRequired,
  roleType: PropTypes.string.isRequired,
  onBack: PropTypes.func.isRequired,
  featureData: PropTypes.object,
};

ManageRoleSection.defaultProps = {
  featureData: null,
};

export default memo(ManageRoleSection);
