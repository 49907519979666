import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import styles from './LoginButton.module.css';
import appleIcon from '../../../assets/AuthPage/login-apple-icon.png';
import googleIcon from '../../../assets/AuthPage/login-google-icon.png';

const LoginButton = ({ handleClick, loginWith, authType }) => {
  let icon = null;
  let text;

  const isSignUp = authType === 'signup';

  switch (loginWith) {
    case 'google':
      icon = googleIcon;
      text = isSignUp ? 'Daftar dengan Google' : 'Masuk dengan Google';
      break;
    case 'apple':
      icon = appleIcon;
      text = isSignUp ? 'Daftar dengan Apple ID' : 'Masuk dengan Apple ID';
      break;
    default:
      break;
  }

  return (
    <div onClick={handleClick} className={styles.LoginButton}>
      <LazyLoadImage src={icon} />
      <p>{text}</p>
    </div>
  );
};

LoginButton.propTypes = {
  handleClick: PropTypes.func.isRequired,
  loginWith: PropTypes.oneOf(['google', 'apple']),
  authType: PropTypes.oneOf(['signup', 'signin']),
};

LoginButton.defaultProps = {
  loginWith: 'google',
  authType: 'signin',
};

export default memo(LoginButton);
