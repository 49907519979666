// const limitRoleSuperUsers = 100;
const limitRoleUsers = 10;

const typeCallback = {
  NEW: 'new',
  DELETE: 'delete',
  EDIT: 'edit',
};

const roleTitle = {
  super: 'Super User',
  admin: 'Administrator',
  creator: 'Creator',
  manager: 'Manager',
  spv: 'Kontributor',
  member: 'Member',
  guest: 'Guest',
  public: 'Public',
};

const role = {
  super: 'super',
  admin: 'admin',
  creator: 'creator',
  manager: 'manager',
  spv: 'spv',
  member: 'member',
  guest: 'guest',
  public: 'public',
};

const level = {
  super: 1000000,
  admin: 100000,
  creator: 10000,
  manager: 10000,
  spv: 1000,
  member: 100,
  guest: 10,
  public: 1,
};

const roles = {
  super: [role.super],
  adminOrAbove: [role.super, role.admin],
  managerOrAbove: [role.super, role.admin, role.creator, role.manager],
  spvOrAbove: [role.super, role.admin, role.creator, role.manager, role.spv],
  memberOrAbove: [role.super, role.admin, role.creator, role.manager, role.spv, role.member],
  guestOrAbove: [role.super, role.admin,
    role.creator, role.manager, role.spv, role.member, role.guest],
  publicOrAbove: [role.super, role.admin, role.creator, role.manager, role.spv,
    role.member, role.guest, role.public],
  spvOrBelow: [role.spv, role.member, role.guest, role.public],
  memberOrBelow: [role.member, role.guest, role.public],
};

const typeRef = {
  doc: 'Doc',
  bucket: 'Bucket',
  file: 'File',
  board: 'Board',
  label: 'Label',
  list: 'List',
  card: 'Card',
  blast: 'Blast',
  post: 'Post',
  checkIn: 'CheckIn',
  question: 'Question',
  schedule: 'Schedule',
  event: 'Event',
  groupChat: 'GroupChat',
  chat: 'Chat',
  team: 'Team',
  comment: 'Comment',
  discussion: 'Discussion',
  company: 'Company',
};

const moduleTitleByRoleTypeRef = {
  Doc: 'Dokumen',
  Bucket: 'Folder',
  File: 'File',
  Board: 'List Tugas',
  Label: 'Label',
  List: 'List',
  Card: 'Tugas',
  Blast: 'List Pengumuman',
  Post: 'Pengumuman',
  CheckIn: 'List Pertanyaan',
  Question: 'Pertanyaan',
  Schedule: 'Kalender',
  Event: 'Jadwal',
  GroupChat: 'Chat Grup',
  Chat: 'Chat Pribadi',
  Team: 'Tim',
  Comment: 'Komentar',
  Discussion: 'Diskusi',
  Company: 'Perusahaan',
};

const whatUserCanDoRef = {
  super: {
    doc: [
      'Melihat, mengubah, mengarsipkan dokumen ini bahkan jika bersifat rahasia dan Kamu bukan anggotanya.',
      'Mengelola akses orang lain di sini',
      'Menambahkan komentar dan Cheers.',
    ],
    bucket: [
      'Memiliki akses penuh untuk segala sesuatu di sini, bahkan mengakses folder rahasia, dokumen, berkas yang Kamu bukan anggotanya',
      'Mengelola akses orang lain di sini',
      'Mengubah, mengarsipkan, mengembalikan folder, dokumen, berkas yang diarsipkan',
    ],
    file: [
      'Melihat, mengubah, mengarsipkan file ini bahkan jika bersifat rahasia dan Kamu bukan anggotanya.',
      'Mengelola akses orang lain di sini',
      'Menambahkan komentar dan Cheers.',
    ],
    board: [
      'Memiliki akses penuh untuk segala sesuatu di sini, bahkan mengakses tugas rahasia yang Kamu bukan anggotanya',
      'Mengelola akses orang lain di sini',
      'Mengubah, mengarsipkan, mengembalikan Tugas & List yang diarsipkan',
    ],
    card: [
      'Melihat, mengubah, mengarsipkan Tugas ini bahkan jika bersifat rahasia dan Kamu bukan anggotanya.',
      'Mengelola anggota, label, tanggal tenggat waktu bahkan jika bersifat rahasia dan Kamu bukan anggotanya',
      'Mengelola akses orang lain di sini',
      'Menambahkan komentar dan Cheers.',
    ],
    blast: [
      'Memiliki akses penuh untuk segala sesuatu di sini, bahkan mengakses Pengumuman yang Kamu bukan anggotanya',
      'Mengelola akses orang lain di sini',
      'Update, Archive, restore archived Pengumuman',
    ],
    post: [
      'Melihat, mengubah, mengarsipkan Pengumuman ini bahkan jika bersifat rahasia dan Kamu bukan anggotanya.',
      'Mengelola akses orang lain di sini',
      'Menambahkan komentar dan Cheers.',
    ],
    checkIn: [
      'Memiliki akses penuh untuk segala sesuatu di sini, bahkan mengakses Pertanyaan rahasia yang Kamu bukan anggotanya',
      'Mengelola akses orang lain di sini',
      'Mengubah, mengarsipkan, mengembalikan Pertanyaan yang diarsipkan',
    ],
    question: [
      'Melihat, mengubah, mengarsipkan Pertanyaan ini bahkan jika bersifat rahasia dan Kamu bukan anggotanya.',
      'Mengelola akses orang lain di sini',
      'Menambahkan komentar dan Cheers.',
    ],
    schedule: [
      'Memiliki akses penuh untuk segala sesuatu di sini, bahkan mengakses jadwal rahasia yang Kamu bukan anggotanya',
      'Mengelola akses orang lain di sini',
      'Mengubah, mengarsipkan, mengembalikan Jadwal yang diarsipkan',
    ],
    event: [
      'Melihat, mengubah, mengarsipkan jadwal ini bahkan jika bersifat rahasia dan Kamu bukan anggotanya.',
      'Mengelola akses orang lain di sini',
      'Menambahkan komentar dan Cheers.',
    ],
    groupChat: [
      'Melihat dan membuat pesan ke chat grup bahkan jika bukan Anggota Tim.',
    ],
    chat: [
      'Melihat dan membuat pesan ke Chat Pribadi hanya Kamu dan orang yang Kamu ajak bicara saja',
    ],
    team: [
      'Menu Tim: Melihat tim bahkan ketika bukan anggotanya, mengelola anggota tim & aksesnya, mengedit & mengarsipkan tim',
      'Menu Tugas, Dokumen, Pengumuman, Chat, dll : Membuat, mengubah, mengarsipkan semua tugas/dokumen, mengakses tugas/dokumen rahasia bahkan ketika bukan anggotanya, mengelola anggota & aksesnya tugas/dokumen.',
      'Menu Laporan Kinerja: Bisa melihat laporan kinerja semua orang',
    ],
    company: [
      'Menu Perusahaan: Mengundang & menghapus Orang dari Perusahaan, mengelola Akses Default Perusahaan, mengubah & menghapus Perusahaan.',
      'Menu Tim: Membuat, mengubah, & mengarsipkan Tim & mengelola anggotanya dan aksesnya, melihat semua Tim, bahkan jika Kamu bukan anggotanya, membuat Item (Tugas, Dokumen, dll) di Tim.',
      'Menu Tagihan: Mengelola Tagihan, Pembayaran, Faktur',
      'Menu Laporan Kinerja: Bisa melihat laporan kinerja semua orang di semua Tim',
    ],
    comment: [
      'Melihat dan membuat komentar bahkan jika Kamu bukan anggota Tugas/Dokumen tsb.',
    ],
  },
  admin: {
    doc: [
      'Melihat, mengubah, mengarsipkan dokumen ini bahkan jika bersifat rahasia dan Kamu bukan anggotanya.',
      'Mengelola akses orang lain di sini',
      'Menambahkan komentar dan Cheers.',
    ],
    bucket: [
      'Memiliki akses penuh untuk segala sesuatu di sini, bahkan mengakses folder rahasia, dokumen, berkas yang Kamu bukan anggotanya',
      'Mengelola akses orang lain di sini',
      'Mengubah, mengarsipkan, mengembalikan folder, dokumen, berkas yang diarsipkan',
    ],
    file: [
      'Melihat, mengubah, mengarsipkan file ini bahkan jika bersifat rahasia dan Kamu bukan anggotanya.',
      'Mengelola akses orang lain di sini',
      'Menambahkan komentar dan Cheers.',
    ],
    board: [
      'Memiliki akses penuh untuk segala sesuatu di sini, bahkan mengakses tugas rahasia yang Kamu bukan anggotanya',
      'Mengelola akses orang lain di sini',
      'Mengubah, mengarsipkan, mengembalikan Tugas & List yang diarsipkan',
    ],
    card: [
      'Melihat, mengubah, mengarsipkan Tugas ini bahkan jika bersifat rahasia dan Kamu bukan anggotanya.',
      'Mengelola anggota, label, tanggal tenggat waktu bahkan jika bersifat rahasia dan Kamu bukan anggotanya',
      'Mengelola akses orang lain di sini',
      'Menambahkan komentar dan Cheers.',
    ],
    blast: [
      'Memiliki akses penuh untuk segala sesuatu di sini, bahkan mengakses Pengumuman yang Kamu bukan anggotanya',
      'Mengelola akses orang lain di sini',
      'Update, Archive, restore archived Pengumuman',
    ],
    post: [
      'Melihat, mengubah, mengarsipkan Pengumuman ini bahkan jika bersifat rahasia dan Kamu bukan anggotanya.',
      'Mengelola akses orang lain di sini',
      'Menambahkan komentar dan Cheers.',
    ],
    checkIn: [
      'Memiliki akses penuh untuk segala sesuatu di sini, bahkan mengakses Pertanyaan rahasia yang Kamu bukan anggotanya',
      'Mengelola akses orang lain di sini',
      'Mengubah, mengarsipkan, mengembalikan Pertanyaan yang diarsipkan',
    ],
    question: [
      'Melihat, mengubah, mengarsipkan Pertanyaan ini bahkan jika bersifat rahasia dan Kamu bukan anggotanya.',
      'Mengelola akses orang lain di sini',
      'Menambahkan komentar dan Cheers.',
    ],
    schedule: [
      'Memiliki akses penuh untuk segala sesuatu di sini, bahkan mengakses jadwal rahasia yang Kamu bukan anggotanya',
      'Mengelola akses orang lain di sini',
      'Mengubah, mengarsipkan, mengembalikan Jadwal yang diarsipkan',
    ],
    event: [
      'Melihat, mengubah, mengarsipkan jadwal ini bahkan jika bersifat rahasia dan Kamu bukan anggotanya.',
      'Mengelola akses orang lain di sini',
      'Menambahkan komentar dan Cheers.',
    ],
    groupChat: [
      'Melihat dan membuat pesan ke chat grup bahkan jika bukan Anggota Tim.',
    ],
    chat: [
      'Melihat dan membuat pesan ke Chat Pribadi hanya Kamu dan orang yang Kamu ajak bicara saja',
    ],
    team: [
      'Menu Tim: Melihat tim bahkan ketika bukan anggotanya, mengelola anggota tim & aksesnya, mengedit & mengarsipkan tim',
      'Menu Tugas, Dokumen, Pengumuman, Chat, dll : Membuat, mengubah, mengarsipkan semua tugas/dokumen, mengakses tugas/dokumen rahasia bahkan ketika bukan anggotanya, mengelola anggota & aksesnya tugas/dokumen.',
      'Menu Laporan Kinerja: Bisa melihat laporan kinerja semua orang',
    ],
    company: [
      'Menu Perusahaan: Mengundang & menghapus Orang dari Perusahaan, mengelola Akses Default Perusahaan, mengubah Perusahaan.',
      'Menu Tim: Membuat, mengubah, & mengarsipkan Tim & mengelola anggotanya dan aksesnya, melihat semua Tim, bahkan jika Kamu bukan anggotanya, membuat Item (Tugas, Dokumen, dll) di Tim.',
      'Menu Tagihan: Mengelola Tagihan, Pembayaran, Faktur',
      'Menu Laporan Kinerja: Bisa melihat laporan kinerja semua orang di semua Tim',
    ],
    comment: [
      'Melihat dan membuat komentar bahkan jika Kamu bukan anggota Tugas/Dokumen tsb.',
    ],
  },
  creator: {
    doc: [
      'Dapat mengedit & mengarsipkan dokumen ini.',
      'Mengelola akses Kontributor atau di bawahnya saja.',
      'Tambahkan komentar dan Cheers.',
    ],
    bucket: [
      'Mengelola akses orang lain pada folder, dokumen, berkas untuk akses Kontributor atau di bawahnya saja.',
      'Memperbarui, mengarsipkan, mengembalikan folder, dokumen, berkas yang diarsipkan.',
    ],
    file: [
      'Dapat mengedit & mengarsipkan file ini.',
      'Mengelola akses Kontributor atau di bawahnya saja.',
      'Tambahkan komentar dan Cheers.',
    ],
    board: [
      'Buat List & Tugas',
      'Mengelola akses orang lain pada tugas untuk akses Kontributor atau di bawahnya saja.',
      'Memperbarui, mengarsipkan, mengembalikan Tugas & List yang diarsipkan.',
    ],
    card: [
      'Mengedit, mengarsipkan Tugas ini.',
      'Mengelola anggota, label, tanggal tenggat waktu.',
      'Mengelola akses Kontributor atau di bawahnya saja.',
      'Tambahkan komentar dan Cheers.',
    ],
    blast: [
      'Mengelola akses orang lain pada pengumuman untuk akses Kontributor atau di bawahnya saja.',
      'Memperbarui, mengarsipkan, mengembalikan pengumuman yang diarsipkan.',
    ],
    post: [
      'Dapat mengedit & mengarsipkan pengumuman ini.',
      'Mengelola akses Kontributor atau di bawahnya saja.',
      'Tambahkan komentar dan Cheers.',
    ],
    checkIn: [
      'Mengelola akses orang lain pada Pertanyaan untuk akses Kontributor atau di bawahnya saja.',
      'Memperbarui, mengarsipkan, mengembalikan Pertanyaan yang diarsipkan.',
    ],
    question: [
      'Dapat mengedit & mengarsipkan Pertanyaan ini.',
      'Mengelola akses Kontributor atau di bawahnya saja.',
      'Tambahkan komentar dan Cheers.',
    ],
    schedule: [
      'Mengelola akses orang lain pada jadwal untuk akses Kontributor atau di bawahnya saja.',
      'Memperbarui, mengarsipkan, mengembalikan jadwal yang diarsipkan.',
    ],
    event: [
      'Dapat mengedit & mengarsipkan jadwal ini.',
      'Mengelola akses Kontributor atau di bawahnya saja.',
      'Tambahkan komentar dan Cheers.',
    ],
    groupChat: [
      'Melihat dan kirim pesan ke chat grup jika Kamu adalah Anggota Tim.',
    ],
    chat: [
      'Melihat dan kirim pesan ke Chat Pribadi hanya Kamu dan orang yang Kamu ajak bicara saja',
    ],
    team: [
      'Menu Tim: Melihat tim ketika menjadi anggotanya, Undang & Hapus anggota, Edit dan arsip tim, mengelola akses Kontributor dan di bawahnya saja.',
      'Menu Tugas, Dokumen, Pengumuman, Chat, dll : Buat, Edit, Arsipkan tugas/dokumen tanpa perlu menjadi anggotanya, Akses Tugas/Dokumen rahasia ketika menjadi anggota, mengelola anggota & aksesnya tugas/dokumen untuk akses Kontributor atau di bawahnya.',
      'Menu Laporan Kinerja: Bisa melihat laporan kinerja orang yang memiliki akses dibawah kamu.',
    ],
    company: [
      'Menu Perusahaan : Undang & Hapus Orang ke/dari Perusahaan ketika orang tersebut memiliki Akses di bawah Kamu.',
      'Menu Tim : Buat Tim & mengelola Anggotanya & Aksesnya, Edit & Arsipkan tim, Akses Tim ketika Kamu adalah anggotanya, Buat Item (Tugas, Dokumen, dll) pada Tim.',
      'Menu Tagihan : Melihat tagihan, pembayaran, faktur.',
      'Menu Laporan Kinerja: Bisa melihat laporan kinerja orang yang memiliki akses dibawah kamu.',
    ],
    comment: [
      'Melihat dan buat komentar ketika Kamu adalah anggota dari Tugas/Dokumen',
    ],
  },
  manager: {
    doc: [
      'Dapat mengedit & mengarsipkan dokumen ini.',
      'Mengelola akses Kontributor atau di bawahnya saja.',
      'Tambahkan komentar dan Cheers.',
    ],
    bucket: [
      'Mengelola akses orang lain pada folder, dokumen, berkas untuk akses Kontributor atau di bawahnya saja.',
      'Memperbarui, mengarsipkan, mengembalikan folder, dokumen, berkas yang diarsipkan.',
    ],
    file: [
      'Dapat mengedit & mengarsipkan file ini.',
      'Mengelola akses Kontributor atau di bawahnya saja.',
      'Tambahkan komentar dan Cheers.',
    ],
    board: [
      'Buat List & Tugas',
      'Mengelola akses orang lain pada tugas untuk akses Kontributor atau di bawahnya saja.',
      'Memperbarui, mengarsipkan, mengembalikan Tugas & List yang diarsipkan.',
    ],
    card: [
      'Mengedit, mengarsipkan Tugas ini.',
      'Mengelola anggota, label, tanggal tenggat waktu.',
      'Mengelola akses Kontributor atau di bawahnya saja.',
      'Tambahkan komentar dan Cheers.',
    ],
    blast: [
      'Mengelola akses orang lain pada pengumuman untuk akses Kontributor atau di bawahnya saja.',
      'Memperbarui, mengarsipkan, mengembalikan pengumuman yang diarsipkan.',
    ],
    post: [
      'Dapat mengedit & mengarsipkan pengumuman ini.',
      'Mengelola akses Kontributor atau di bawahnya saja.',
      'Tambahkan komentar dan Cheers.',
    ],
    checkIn: [
      'Mengelola akses orang lain pada Pertanyaan untuk akses Kontributor atau di bawahnya saja.',
      'Memperbarui, mengarsipkan, mengembalikan Pertanyaan yang diarsipkan.',
    ],
    question: [
      'Dapat mengedit & mengarsipkan Pertanyaan ini.',
      'Mengelola akses Kontributor atau di bawahnya saja.',
      'Tambahkan komentar dan Cheers.',
    ],
    schedule: [
      'Mengelola akses orang lain pada jadwal untuk akses Kontributor atau di bawahnya saja.',
      'Memperbarui, mengarsipkan, mengembalikan jadwal yang diarsipkan.',
    ],
    event: [
      'Dapat mengedit & mengarsipkan jadwal ini.',
      'Mengelola akses Kontributor atau di bawahnya saja.',
      'Tambahkan komentar dan Cheers.',
    ],
    groupChat: [
      'Melihat dan kirim pesan ke chat grup jika Kamu adalah Anggota Tim.',
    ],
    chat: [
      'Melihat dan kirim pesan ke Chat Pribadi hanya Kamu dan orang yang Kamu ajak bicara saja',
    ],
    team: [
      'Menu Tim: Melihat tim ketika menjadi anggotanya, Undang & Hapus anggota, Edit dan arsip tim, mengelola akses Kontributor dan di bawahnya saja.',
      'Menu Tugas, Dokumen, Pengumuman, Chat, dll : Buat, Edit, Arsipkan tugas/dokumen tanpa perlu menjadi anggotanya, Akses Tugas/Dokumen rahasia ketika menjadi anggota, mengelola anggota & aksesnya tugas/dokumen untuk akses Kontributor atau di bawahnya.',
      'Menu Laporan Kinerja: Bisa melihat laporan kinerja orang yang memiliki akses dibawah kamu.',
    ],
    company: [
      'Menu Perusahaan : Undang & Hapus Orang ke/dari Perusahaan ketika orang tersebut memiliki Akses di bawah Kamu.',
      'Menu Tim : Buat Tim & mengelola Anggotanya & Aksesnya, Edit & Arsipkan tim, Akses Tim ketika Kamu adalah anggotanya, Buat Item (Tugas, Dokumen, dll) pada Tim.',
      'Menu Tagihan : Melihat tagihan, pembayaran, faktur.',
      'Menu Laporan Kinerja: Bisa melihat laporan kinerja orang yang memiliki akses dibawah kamu.',
    ],
    comment: [
      'Melihat dan buat komentar ketika Kamu adalah anggota dari Tugas/Dokumen',
    ],
  },
  spv: {
    doc: [
      'Dapat mengedit & mengarsipkan dokumen ini.',
      'Tambahkan komentar dan Cheers.',
    ],
    bucket: [
      'Buat Folder, Berkas, Dokumen',
      'Memperbarui, mengarsipkan folder, dokumen, berkas.',
    ],
    file: [
      'Dapat mengedit & mengarsipkan file ini.',
      'Tambahkan komentar dan Cheers.',
    ],
    board: [
      'Buat Tugas',
      'Memperbarui, mengarsipkan Tugas.',
    ],
    card: [
      'Mengedit, mengarsipkan Tugas ini.',
      'Mengelola anggota, label, tanggal tenggat waktu.',
      'Tambahkan komentar dan Cheers.',
    ],
    blast: [
      'Buat Pengumuman',
      'Memperbarui, mengarsipkan pengumuman.',
    ],
    post: [
      'Dapat mengedit & mengarsipkan pengumuman ini.',
      'Tambahkan komentar dan Cheers.',
    ],
    checkIn: [
      'Buat Pertanyaan',
      'Memperbarui, mengarsipkan pertanyaan.',
    ],
    question: [
      'Dapat mengedit & mengarsipkan pertanyaan ini.',
      'Tambahkan komentar dan Cheers.',
    ],
    schedule: [
      'Buat Acara',
      'Memperbarui, mengarsipkan jadwal.',
    ],
    event: [
      'Dapat mengedit & mengarsipkan jadwal ini.',
      'Tambahkan komentar dan Cheers.',
    ],
    groupChat: [
      'Melihat dan kirim pesan ke chat grup jika Kamu adalah Anggota Tim.',
    ],
    chat: [
      'Melihat dan kirim pesan ke Chat Pribadi hanya Kamu dan orang yang Kamu ajak bicara saja',
    ],
    team: [
      'Menu Tim : Melihat tim ketika menjadi anggotanya.',
      'Menu Tugas, Dokumen, Pengumuman, Chat, dll : Buat, Edit, Arsipkan tugas/dokumen selama Kamu adalah anggotanya, Akses Tugas/Dokumen rahasia ketika menjadi anggota.',
      'Menu Laporan Kinerja: Hanya bisa lihat laporan kinerja diri sendiri',
    ],
    company: [
      'Menu Perusahaan : Undang orang ke Perusahaan.',
      'Menu Tim : Melihat Tim ketika Kamu adalah anggotanya, Buat Item (Tugas, Dokumen, dll) pada Tim.',
      'Menu Tagihan : Melihat tagihan, pembayaran, faktur.',
      'Menu Laporan Kinerja: Hanya bisa lihat laporan kinerja diri sendiri di Tim yang kamu tergabung di dalamnya',
    ],
    comment: [
      'Melihat dan buat komentar ketika Kamu adalah anggota dari Tugas/Dokumen',
    ],
  },
  member: {
    doc: [
      // 'Dapat mengedit & mengarsipkan dokumen ini ketika Kamu adalah Pembuat & Anggota saja.',
      'Tambahkan komentar dan Cheers disini.',
      'Membuat Dokumen baru & punya akses penuh didalamnya',
    ],
    bucket: [
      'Buat Folder, Berkas, Dokumen',
      // 'Memperbarui, mengarsipkan folder, dokumen,
      // berkas ketika Kamu adalah Pembuat & Anggota saja.',
    ],
    file: [
      // 'Dapat mengedit & mengarsipkan file ini ketika Kamu adalah Pembuat & Anggota saja.',
      'Tambahkan komentar dan Cheers disini.',
      'Membuat File baru & punya akses penuh didalamnya',
    ],
    board: [
      'Buat Tugas',
      // 'Memperbarui, mengarsipkan Tugas ketika Kamu adalah Pembuat & Anggota saja.',
    ],
    card: [
      // 'Arsipkan Tugas ini ketika Kamu adalah Pembuat & Anggota saja.',
      'Tambahkan komentar dan Cheers disini.',
      'Mengelola label, tenggat waktu, memindahkan Tugas ke List, ketika Kamu menjadi Anggota disini.',
      'Membuat Tugas baru & punya akses penuh didalamnya',
    ],
    blast: [
      'Buat Pengumuman',
      // 'Memperbarui, mengarsipkan pengumuman ketika Kamu adalah Pembuat & Anggota saja.',
    ],
    post: [
      // 'Dapat mengedit & mengarsipkan pengumuman ini ketika Kamu adalah Pembuat & Anggota saja.',
      'Tambahkan komentar dan Cheers disini.',
      'Membuat Pengumuman baru & punya akses penuh didalamnya',
    ],
    checkIn: [
      'Buat Pertanyaan',
      // 'Memperbarui, mengarsipkan pertanyaan ketika Kamu adalah Pembuat & Anggota saja.',
    ],
    question: [
      // 'Dapat mengedit & mengarsipkan pertanyaan ini ketika Kamu adalah Pembuat & Anggota saja.',
      'Tambahkan komentar dan Cheers disini.',
      'Membuat Pertanyaan baru & punya akses penuh didalamnya',
    ],
    schedule: [
      'Buat Acara',
      // 'Memperbarui, mengarsipkan jadwal ketika Kamu adalah Pembuat & Anggota saja.',
    ],
    event: [
      // 'Dapat mengedit & mengarsipkan jadwal ini ketika Kamu adalah Pembuat & Anggota saja.',
      'Tambahkan komentar dan Cheers disini.',
      'Membuat Jadwal baru & punya akses penuh didalamnya',
    ],
    groupChat: [
      'Melihat dan kirim pesan ke chat grup jika Kamu adalah Anggota Tim.',
    ],
    chat: [
      'Melihat dan kirim pesan ke Chat Pribadi hanya Kamu dan orang yang Kamu ajak bicara saja',
    ],
    team: [
      'Menu Tim : Melihat tim ketika menjadi anggotanya.',
      'Menu Tugas, Dokumen, Pengumuman, Chat, dll : Hanya bisa membuat, melihat, dan memberi komentar saja, hanya bisa mengedit/Mengarsipkan item (Tugas, Dokumen, dll) ketika Kamu adalah pembuatnya, Akses Tugas/Dokumen rahasia ketika menjadi anggota.',
      'Menu Laporan Kinerja: Hanya bisa lihat laporan kinerja diri sendiri',
    ],
    company: [
      'Menu Tim : Melihat Tim ketika Kamu adalah anggotanya, Buat Item (Tugas, Dokumen, dll) pada Tim.',
      'Menu Laporan Kinerja: Hanya bisa lihat laporan kinerja diri sendiri di Tim yang kamu tergabung di dalamnya',
    ],
    comment: [
      'Melihat dan buat komentar ketika Kamu adalah anggota dari Tugas/Dokumen',
    ],
  },
};

const whatUserCannotDoRef = {
  super: {
    doc: [
      'Tidak ada. Super User memiliki izin tanpa batas untuk segala hal.',
    ],
    bucket: [
      'Tidak ada. Super User memiliki izin tanpa batas untuk segala hal.',
    ],
    file: [
      'Tidak ada. Super User memiliki izin tanpa batas untuk segala hal.',
    ],
    board: [
      'Tidak ada. Super User memiliki izin tanpa batas untuk segala hal.',
    ],
    card: [
      'Tidak ada. Super User memiliki izin tanpa batas untuk segala hal.',
    ],
    blast: [
      'Tidak ada. Super User memiliki izin tanpa batas untuk segala hal.',
    ],
    post: [
      'Tidak ada. Super User memiliki izin tanpa batas untuk segala hal.',
    ],
    checkIn: [
      'Tidak ada. Super User memiliki izin tanpa batas untuk segala hal.',
    ],
    question: [
      'Tidak ada. Super User memiliki izin tanpa batas untuk segala hal.',
    ],
    schedule: [
      'Tidak ada. Super User memiliki izin tanpa batas untuk segala hal.',
    ],
    event: [
      'Tidak ada. Super User memiliki izin tanpa batas untuk segala hal.',
    ],
    groupChat: [
      'Tidak dapat menghapus pesan orang lain',
    ],
    chat: [
      'Tidak dapat melihat pesan pribadi orang lain',
      'Tidak dapat menghapus pesan orang lain',
    ],
    team: [
      'Tidak ada. Super User memiliki izin tanpa batas untuk segala hal.',
    ],
    company: [
      'Tidak ada. Super User memiliki izin tanpa batas untuk segalanya.',
    ],
    comment: [
      'Tidak dapat menghapus komentar & cheers orang lain',
    ],
  },
  admin: {
    doc: [
      'Tidak ada. Administrator memiliki izin tanpa batas untuk segala hal.',
    ],
    bucket: [
      'Tidak ada. Administrator memiliki izin tanpa batas untuk segala hal.',
    ],
    file: [
      'Tidak ada. Administrator memiliki izin tanpa batas untuk segala hal.',
    ],
    board: [
      'Tidak ada. Administrator memiliki izin tanpa batas untuk segala hal.',
    ],
    card: [
      'Tidak ada. Administrator memiliki izin tanpa batas untuk segala hal.',
    ],
    blast: [
      'Tidak ada. Administrator memiliki izin tanpa batas untuk segala hal.',
    ],
    post: [
      'Tidak ada. Administrator memiliki izin tanpa batas untuk segala hal.',
    ],
    checkIn: [
      'Tidak ada. Administrator memiliki izin tanpa batas untuk segala hal.',
    ],
    question: [
      'Tidak ada. Administrator memiliki izin tanpa batas untuk segala hal.',
    ],
    schedule: [
      'Tidak ada. Administrator memiliki izin tanpa batas untuk segala hal.',
    ],
    event: [
      'Tidak ada. Administrator memiliki izin tanpa batas untuk segala hal.',
    ],
    groupChat: [
      'Tidak dapat menghapus pesan orang lain',
    ],
    chat: [
      'Tidak dapat melihat pesan pribadi orang lain',
      'Tidak dapat menghapus pesan orang lain',
    ],
    team: [
      'Tidak ada. Administrator memiliki izin tanpa batas untuk segala hal.',
    ],
    company: [
      'Tidak bisa menghapus Perusahaan',
      'Tidak bisa menghapus sesama Administrator atau Super User dari Perusahaan',
      'Hanya bisa mengatur akses orang lain menjadi Manager, Kontributor, atau Member',
    ],
    comment: [
      'Tidak dapat menghapus komentar & cheers orang lain',
    ],
  },
  creator: {
    doc: [
      'Melihat dokumen rahasia yang kamu bukan anggotanya',
      // 'Edit/Arsipkan dokumen yang Akses pembuatnya lebih tinggi',
    ],
    bucket: [
      'Tidak dapat melihat dokumen, file, folder rahasia yang kamu bukan anggotanya',
      // 'Edit/Arsipkan dokumen, file, folder yang Akses pembuatnya lebih tinggi',
    ],
    file: [
      'Melihat file rahasia yang kamu bukan anggotanya',
      // 'Edit/Arsipkan file yang Akses pembuatnya lebih tinggi',
    ],
    board: [
      'Tidak dapat melihat tugas rahasia yang kamu bukan anggotanya',
      // 'Edit/Arsipkan tugas & daftar yang Akses pembuatnya lebih tinggi',
    ],
    card: [
      'Melihat tugas rahasia yang kamu bukan anggotanya',
      // 'Edit/Arsipkan tugas yang Akses pembuatnya lebih tinggi',
    ],
    blast: [
      'Tidak dapat melihat pengumuman rahasia yang kamu bukan anggotanya',
      // 'Edit/Arsipkan pengumuman yang Akses pembuatnya lebih tinggi',
    ],
    post: [
      'Melihat pengumuman rahasia yang kamu bukan anggotanya',
      // 'Edit/Arsipkan pengumuman yang Akses pembuatnya lebih tinggi',
    ],
    checkIn: [
      'Tidak dapat melihat pertanyaan rahasia yang kamu bukan anggotanya',
      // 'Edit/Arsipkan pertanyaan yang Akses pembuatnya lebih tinggi',
    ],
    question: [
      'Melihat pertanyaan rahasia yang kamu bukan anggotanya',
      // 'Edit/Arsipkan pertanyaan yang Akses pembuatnya lebih tinggi',
    ],
    schedule: [
      'Tidak dapat melihat jadwal rahasia yang kamu bukan anggotanya',
      // 'Edit/Arsipkan jadwal yang Akses pembuatnya lebih tinggi',
    ],
    event: [
      'Melihat jadwal rahasia yang kamu bukan anggotanya',
      // 'Edit/Arsipkan jadwal yang Akses pembuatnya lebih tinggi',
    ],
    groupChat: [
      'Tidak dapat menghapus pesan orang lain',
    ],
    chat: [
      'Tidak dapat melihat pesan pribadi orang lain',
      'Tidak dapat menghapus pesan orang lain',
    ],
    team: [
      'Menu Laporan Kinerja: Ga boleh lihat laporan orang yang punya akses sama/diatas kamu',
    ],
    company: [
      'Menu Perusahaan : Edit & hapus perusahaan, Kelola Akses Perusahaan Default, Hapus orang yang memiliki Akses Manager ke atas.',
      'Menu Tim : Gabisa akses Tim jika bukan anggota.',
      'Menu Laporan Kinerja: Ga bisa akses kalo bukan anggota Tim tsb',
      // 'Menu Tagihan : Melihat tagihan, pembayaran, faktur.',
    ],
    comment: [
      'Tidak dapat menghapus komentar & Cheers orang lain',
    ],
  },
  manager: {
    doc: [
      'Melihat dokumen rahasia yang kamu bukan anggotanya',
      // 'Edit/Arsipkan dokumen yang Akses pembuatnya lebih tinggi',
    ],
    bucket: [
      'Tidak dapat melihat dokumen, file, folder rahasia yang kamu bukan anggotanya',
      // 'Edit/Arsipkan dokumen, file, folder yang Akses pembuatnya lebih tinggi',
    ],
    file: [
      'Melihat file rahasia yang kamu bukan anggotanya',
      // 'Edit/Arsipkan file yang Akses pembuatnya lebih tinggi',
    ],
    board: [
      'Tidak dapat melihat tugas rahasia yang kamu bukan anggotanya',
      // 'Edit/Arsipkan tugas & daftar yang Akses pembuatnya lebih tinggi',
    ],
    card: [
      'Melihat tugas rahasia yang kamu bukan anggotanya',
      // 'Edit/Arsipkan tugas yang Akses pembuatnya lebih tinggi',
    ],
    blast: [
      'Tidak dapat melihat pengumuman rahasia yang kamu bukan anggotanya',
      // 'Edit/Arsipkan pengumuman yang Akses pembuatnya lebih tinggi',
    ],
    post: [
      'Melihat pengumuman rahasia yang kamu bukan anggotanya',
      // 'Edit/Arsipkan pengumuman yang Akses pembuatnya lebih tinggi',
    ],
    checkIn: [
      'Tidak dapat melihat pertanyaan rahasia yang kamu bukan anggotanya',
      // 'Edit/Arsipkan pertanyaan yang Akses pembuatnya lebih tinggi',
    ],
    question: [
      'Melihat pertanyaan rahasia yang kamu bukan anggotanya',
      // 'Edit/Arsipkan pertanyaan yang Akses pembuatnya lebih tinggi',
    ],
    schedule: [
      'Tidak dapat melihat jadwal rahasia yang kamu bukan anggotanya',
      // 'Edit/Arsipkan jadwal yang Akses pembuatnya lebih tinggi',
    ],
    event: [
      'Melihat jadwal rahasia yang kamu bukan anggotanya',
      // 'Edit/Arsipkan jadwal yang Akses pembuatnya lebih tinggi',
    ],
    groupChat: [
      'Tidak dapat menghapus pesan orang lain',
    ],
    chat: [
      'Tidak dapat melihat pesan pribadi orang lain',
      'Tidak dapat menghapus pesan orang lain',
    ],
    team: [
      // 'Menu Tim : Gabisa a      ',
      'Menu Laporan Kinerja: Ga boleh lihat laporan orang yang punya akses sama/diatas kamu',
    ],
    company: [
      'Menu Perusahaan : Edit & hapus perusahaan, Kelola Akses Perusahaan Default, Hapus orang yang memiliki Akses yang sama atau lebih tinggi dari Perusahaan.',
      'Menu Tim : Gabisa akses Tim jika bukan anggota.',
      'Menu Laporan Kinerja: Ga bisa akses kalo bukan anggota Tim tsb',
      // 'Menu Tagihan : Melihat tagihan, pembayaran, faktur.',
    ],
    comment: [
      'Tidak dapat menghapus komentar & Cheers orang lain',
    ],
  },
  spv: {
    doc: [
      'Melihat dokumen rahasia yang kamu bukan anggotanya',
      'Ga bisa edit kalau belum jadi Anggota',
      // 'Edit/Arsipkan dokumen yang Akses pembuatnya lebih tinggi atau saat kamu bukan anggotanya',
      'Tidak bisa mengelola akses di sini',
    ],
    bucket: [
      'Tidak dapat melihat dokumen, folder file rahasia yang kamu bukan anggotanya',
      // 'Edit/Arsipkan dokumen, file, folder yang Akses pembuatnya
      // lebih tinggi atau saat kamu bukan anggotanya',
      'Ga bisa edit kalau belum jadi Anggota',
      'Tidak bisa mengelola akses di sini',
      'Tidak dapat membuka arsip item',
    ],
    file: [
      'Melihat file rahasia yang kamu bukan anggotanya',
      // 'Edit/Arsipkan file yang Akses pembuatnya lebih tinggi atau saat kamu bukan anggotanya',
      'Ga bisa edit kalau belum jadi Anggota',
      'Tidak bisa mengelola akses di sini',
    ],
    board: [
      'Tidak dapat melihat tugas rahasia yang kamu bukan anggotanya',
      // 'Edit/Arsipkan tugas yang Akses pembuatnya lebih tinggi atau saat kamu bukan anggotanya',
      'Ga bisa edit kalau belum jadi Anggota',
      'Tidak bisa membuat List',
      'Tidak bisa mengelola akses di sini',
      'Tidak dapat membuka arsip item',
    ],
    card: [
      'Melihat tugas rahasia yang kamu bukan anggotanya',
      // 'Edit/Arsipkan tugas yang Akses pembuatnya lebih tinggi atau saat kamu bukan anggotanya',
      'Ga bisa edit kalau belum jadi Anggota',
      'Tidak bisa mengelola akses di sini',
    ],
    blast: [
      'Tidak dapat melihat Pengumuman rahasia yang kamu bukan anggotanya',
      // 'Edit/Arsipkan Pengumuman yang Akses pembuatnya
      // lebih tinggi atau saat kamu bukan anggotanya',
      'Ga bisa edit kalau belum jadi Anggota',
      'Tidak bisa mengelola akses di sini',
      'Tidak dapat membuka arsip item',
    ],
    post: [
      'Melihat Pengumuman rahasia yang kamu bukan anggotanya',
      // 'Edit/Arsipkan Pengumuman yang Akses pembuatnya
      // lebih tinggi atau saat kamu bukan anggotanya',
      'Ga bisa edit kalau belum jadi Anggota',
      'Tidak bisa mengelola akses di sini',
    ],
    checkIn: [
      'Tidak dapat melihat pertanyaan rahasia yang kamu bukan anggotanya',
      // 'Edit/Arsipkan pertanyaan yang Akses pembuatnya
      // lebih tinggi atau saat kamu bukan anggotanya',
      'Ga bisa edit kalau belum jadi Anggota',
      'Tidak bisa mengelola akses di sini',
      'Tidak dapat membuka arsip item',
    ],
    question: [
      'Melihat pertanyaan rahasia yang kamu bukan anggotanya',
      // 'Edit/Arsipkan pertanyaan yang Akses pembuatnya
      // lebih tinggi atau saat kamu bukan anggotanya',
      'Ga bisa edit kalau belum jadi Anggota',
      'Tidak bisa mengelola akses di sini',
    ],
    schedule: [
      'Tidak dapat melihat jadwal rahasia yang kamu bukan anggotanya',
      // 'Edit/Arsipkan jadwal yang Akses pembuatnya lebih tinggi atau saat kamu bukan anggotanya',
      'Ga bisa edit kalau belum jadi Anggota',
      'Tidak bisa mengelola akses di sini',
      'Tidak dapat membuka arsip item',
    ],
    event: [
      'Melihat jadwal rahasia yang kamu bukan anggotanya',
      // 'Edit/Arsipkan jadwal yang Akses pembuatnya lebih tinggi atau saat kamu bukan anggotanya',
      'Ga bisa edit kalau belum jadi Anggota',
      'Tidak bisa mengelola akses di sini',
    ],
    groupChat: [
      'Tidak dapat menghapus pesan orang lain',
    ],
    chat: [
      'Tidak dapat melihat pesan pribadi orang lain',
      'Tidak dapat menghapus pesan orang lain',
    ],
    team: [
      'Menu Tim : Undang & hapus anggota, edit & arsipkan Tim, mengelola akses.',
      'Menu Tugas, Pengumuman, dll: Gabisa edit Tugas, Dokumen orang lain kalau belum ditambahkan jadi anggotanya',
    ],
    company: [
      'Menu Perusahaan : Hapus Orang dari Perusahaan, Kelola Akses Default Perusahaan, Edit & Hapus Perusahaan.',
      'Menu Tim : Membuat Tim (Harus ditambahkan oleh Manager ke Tim), Edit/Arsipkan Tim, Akses tim jika bukan anggota.',
      'Menu Laporan Kinerja: Ga bisa akses kalo bukan anggota Tim tsb',
      // 'Menu Tagihan : Melihat tagihan, pembayaran, faktur.',
    ],
    comment: [
      'Tidak dapat menghapus komentar & cheers orang lain',
    ],
  },
  member: {
    doc: [
      'Melihat dokumen rahasia yang kamu bukan anggotanya',
      'Edit/Arsipkan dokumen orang lain.',
      'Tidak bisa mengelola akses di sini',
    ],
    bucket: [
      'Tidak dapat melihat dokumen, folder file rahasia yang kamu bukan anggotanya',
      'Edit/Arsipkan dokumen, file, folder orang lain.',
      'Tidak bisa mengelola akses di sini',
      'Tidak dapat membuka arsip item',
    ],
    file: [
      'Melihat file rahasia yang kamu bukan anggotanya',
      'Edit/Arsipkan file orang lain.',
      'Tidak bisa mengelola akses di sini',
    ],
    board: [
      'Tidak dapat melihat tugas rahasia yang kamu bukan anggotanya',
      'Edit/Arsipkan tugas orang lain.',
      'Tidak bisa Membuat Daftar',
      'Tidak bisa mengelola akses di sini',
      'Tidak dapat membuka arsip item',
    ],
    card: [
      'Melihat tugas rahasia yang kamu bukan anggotanya',
      'Edit/Arsipkan tugas orang lain.',
      'Tidak bisa mengelola akses di sini',
    ],
    blast: [
      'Tidak dapat melihat Pengumuman rahasia yang kamu bukan anggotanya',
      'Edit/Arsipkan Pengumuman orang lain.',
      'Tidak bisa mengelola akses di sini',
      'Tidak dapat membuka arsip item',
    ],
    post: [
      'Melihat Pengumuman rahasia yang kamu bukan anggotanya',
      'Edit/Arsipkan Pengumuman orang lain.',
      'Tidak bisa mengelola akses di sini',
    ],
    checkIn: [
      'Tidak dapat melihat pertanyaan rahasia yang kamu bukan anggotanya',
      'Edit/Arsipkan pertanyaan orang lain.',
      'Tidak bisa mengelola akses di sini',
      'Tidak dapat membuka arsip item',
    ],
    question: [
      'Melihat pertanyaan rahasia yang kamu bukan anggotanya',
      'Edit/Arsipkan pertanyaan orang lain.',
      'Tidak bisa mengelola akses di sini',
    ],
    schedule: [
      'Tidak dapat melihat jadwal rahasia yang kamu bukan anggotanya',
      'Edit/Arsipkan jadwal orang lain.',
      'Tidak bisa mengelola akses di sini',
      'Tidak dapat membuka arsip item',
    ],
    event: [
      'Melihat jadwal rahasia yang kamu bukan anggotanya',
      'Edit/Arsipkan jadwal orang lain.',
      'Tidak bisa mengelola akses di sini',
    ],
    groupChat: [
      'Tidak dapat menghapus pesan orang lain',
    ],
    chat: [
      'Tidak dapat melihat pesan pribadi orang lain',
      'Tidak dapat menghapus pesan orang lain',
    ],
    team: [
      'Menu Tim : Undang & hapus anggota, edit & arsipkan Tim, mengelola akses, edit & arsipkan Item (Tugas/Doc, dll) orang lain.',
      'Menu Tugas, Pengumuman, dll: Gabisa edit sama sekali Tugas, Dokumen punya orang lain',
    ],
    company: [
      'Menu Perusahaan : Undang & Hapus Orang dari Perusahaan, Kelola Akses Default Perusahaan, Edit & Hapus Perusahaan.',
      'Menu Tim : Membuat Tim (Harus ditambahkan oleh Manajer ke Tim), Edit/Arsipkan Tim, Akses tim jika bukan anggota.',
      'Menu Tagihan : Melihat tagihan, pembayaran, faktur.',
      'Menu Laporan Kinerja: Ga bisa akses kalo bukan anggota Tim tsb',
    ],
    comment: [
      'Tidak dapat menghapus komentar & cheers orang lain',
    ],
  },
};

const depthRef = {
  feature: 2,
  team: 1,
};

const roleTypeRef = {
  company: 'company',
  team: 'team',
  feature: 'feature',
};

export {
  typeCallback,
  // limitRoleSuperUsers,
  limitRoleUsers,
  roleTitle,
  role,
  level,
  roles,
  typeRef,
  depthRef,
  roleTypeRef,
  whatUserCanDoRef,
  whatUserCannotDoRef,
  moduleTitleByRoleTypeRef,
};
