import React from 'react';
import CloseIcon from '@mui/icons-material/Close';
import PropTypes from 'prop-types';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import BoxPopOver from '../../../components/UI/BoxPopOver/BoxPopOver';
import SeparatorLine from '../../../components/UI/SeparatorLine/SeparatorLine';
import styles from './AddOccurrenceCalendarMenu.module.css';
import gcalIcon from '../../../assets/SchedulePage/gcal.png';
import icalIcon from '../../../assets/SchedulePage/ical.png';
import outlookIcon from '../../../assets/SchedulePage/outlook.png';

const AddOccurrenceCalendarMenu = ({ occurrence, cancel }) => {
  const handleClickGCal = () => {
    window.open(occurrence?.googleCalendar?.eventGCalTemplateLink, '_blank');
  };

  const handleClickICal = () => {
    window.open(process.env.REACT_APP_PRIMARY_API_URL + occurrence?.googleCalendar?.eventIcsLink, '_blank');
  };

  const handleClickOutlook = () => {
    window.open(process.env.REACT_APP_PRIMARY_API_URL + occurrence?.googleCalendar?.eventIcsLink, '_blank');
  };

  return (
    <BoxPopOver customStyle={{ top: '0', right: '100px' }}>
      <div className={styles.headerSection}>
        <h1>
          Manually Add this Event
        </h1>
        <CloseIcon onClick={cancel} />
      </div>
      <SeparatorLine />
      <div className={styles.bodySection}>
        <div onClick={handleClickGCal} className={styles.menu}>
          <LazyLoadImage src={gcalIcon} />
          <p>Add to Google Calendar</p>
        </div>
        <div onClick={handleClickICal} className={styles.menu}>
          <LazyLoadImage src={icalIcon} />
          <p>Add to Apple Calendar</p>
        </div>
        <div onClick={handleClickOutlook} className={styles.menu}>
          <LazyLoadImage src={outlookIcon} />
          <p>Add to Outlook</p>
        </div>
      </div>
    </BoxPopOver>
  );
};

AddOccurrenceCalendarMenu.propTypes = {
  occurrence: PropTypes.object.isRequired,
  cancel: PropTypes.func.isRequired,
};

export default AddOccurrenceCalendarMenu;
