import React, { memo, useState } from 'react';
import CloseIcon from '@mui/icons-material/Close';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import PropTypes from 'prop-types';
import { isEqual } from 'lodash';
import styles from './ManageLabelsContainer.module.css';
import SeparatorLine from '../../components/UI/SeparatorLine/SeparatorLine';
import BoxPopOver from '../../components/UI/BoxPopOver/BoxPopOver';
import ToggleLabels from './ToggleLabels/ToggleLabels';
import CreateLabel from './CreateLabel/CreateLabel';
import EditLabel from './EditLabel/EditLabel';
import { ShowCreateLabelContext, ShowEditLabelContext } from './ManageLabelsContext';
import NavigationMenuBasic from '../../components/UI_V2/Navigation/Menu/Basic/Basic';

const ManageLabelsContainer = ({
  fixedMode, topPos, leftPos, cancel, left, card,
}) => {
  const [showCreateLabel, setShowCreateLabel] = useState(false);
  const [showEditLabel, setShowEditLabel] = useState();

  const handleShowEditLabel = (labelId) => {
    setShowEditLabel(labelId);
  };

  const handleShowCreateLabel = () => {
    setShowCreateLabel(true);
  };

  const handleClear = () => {
    // setEditedLabel();
    setShowCreateLabel(false);
    setShowEditLabel();
  };

  return (
    // <BoxPopOver customStyle={fixedMode ?
    // { position: 'fixed', top: topPos, left: leftPos } : null} left={!!left}>
    <NavigationMenuBasic size="mdWider" onClose={cancel} position={left ? 'topLeft' : 'topRight'}>
      <div className={styles.container}>
        <div className={styles.ManageLabelsContainer__Header}>
          {showEditLabel || showCreateLabel ? <ArrowBackIosIcon onClick={handleClear} /> : null }
          <h1>
            {showEditLabel || showCreateLabel ? null : 'Label'}
            {showEditLabel ? 'Ubah Label' : null}
            {showCreateLabel ? 'Buat Label' : null}
          </h1>
          {/* <CloseIcon onClick={cancel} /> */}
        </div>
        <SeparatorLine />
        {showEditLabel || showCreateLabel ? null

          : (
            <ToggleLabels
              card={card}
              handleShowCreateLabel={handleShowCreateLabel}
              handleShowEditLabel={handleShowEditLabel}
            />
          )}
        {showEditLabel !== undefined ? (
          <ShowCreateLabelContext.Provider value={[showCreateLabel, setShowCreateLabel]}>
            <ShowEditLabelContext.Provider value={[showEditLabel, setShowEditLabel]}>
              <EditLabel card={card} />
            </ShowEditLabelContext.Provider>
          </ShowCreateLabelContext.Provider>
        ) : null}
        {showCreateLabel ? (
          <ShowCreateLabelContext.Provider value={[showCreateLabel, setShowCreateLabel]}>
            <ShowEditLabelContext.Provider value={[showEditLabel, setShowEditLabel]}>
              <CreateLabel card={card} />
            </ShowEditLabelContext.Provider>
          </ShowCreateLabelContext.Provider>
        ) : null}
      </div>
    </NavigationMenuBasic>
  );
};

ManageLabelsContainer.propTypes = {
  card: PropTypes.object.isRequired,
  cancel: PropTypes.func.isRequired,
  fixedMode: PropTypes.bool,
  left: PropTypes.bool,
  topPos: PropTypes.number,
  leftPos: PropTypes.number,
};

ManageLabelsContainer.defaultProps = {
  fixedMode: false,
  left: false,
  topPos: 0,
  leftPos: 0,
};

const isComponentDataEqual = (prevProps, nextProps) => {
  const {
    card, fixedMode, left, topPos, leftPos,
  } = nextProps;
  return isEqual(prevProps.card, card)
  && isEqual(prevProps.fixedMode, fixedMode)
  && isEqual(prevProps.left, left)
  && isEqual(prevProps.topPos, topPos)
  && isEqual(prevProps.leftPos, leftPos);
};

export default memo(ManageLabelsContainer, isComponentDataEqual);
