import React, { memo, useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { useSnackbar } from 'notistack';
import styles from './PirateMetricPage.module.css';
import { DisplayTextBody, DisplayTextHeadline } from '../../../components/UI_V2/Display/Text';
import Color from '../../../themes/colors';
import introImage from '../../../assets/PrivateChatPage/intro-chat-section.png';
import { SurfacePaperMain } from '../../../components/UI_V2/Surface/Paper';
import { LayoutMain } from '../../../components/UI_V2/Layout';
import PirateMetricContent from './PirateMetricContent/PirateMetricContent';
import Header from '../Header/Header';
import { dateBeautifyFormatter } from '../../../utilities/dateUtil';

const PirateMetricPage = () => {
  const [headerText, setHeaderText] = useState({
    title: 'Metric',
    desc: 'Pirate Metrics (AARRR)',
  });

  const handleSetHeaderText = useCallback((values) => {
    const { fromDate, untilDate } = values;

    setHeaderText({
      title: headerText.title,
      desc: `Period : ${dateBeautifyFormatter(fromDate, true)} - ${dateBeautifyFormatter(untilDate, true)}`,
    });
  }, []);
  return (
    <LayoutMain>
      <SurfacePaperMain>
        <div className={styles.container}>
          <div className={styles.header}>
            <Header
              title={headerText.title}
              desc={headerText.desc}
            />
          </div>
          <div className={styles.body}>
            <PirateMetricContent
              handleSetHeaderText={handleSetHeaderText}
            />
          </div>
        </div>
      </SurfacePaperMain>
    </LayoutMain>
  );
};

export default memo(PirateMetricPage);
