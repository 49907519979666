import React, { memo, useState } from 'react';
import PropTypes from 'prop-types';
import HomeWorkOutlinedIcon from '@mui/icons-material/HomeWorkOutlined';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import styles from './TeamItemUserReport.module.css';
import Color from '../../../../../themes/colors';
import { limitChar } from '../../../../../utilities/stringUtil';
import { DisplayTextBody } from '../../../../../components/UI_V2/Display/Text';

const selectedStyles = {
  false: '',
  true: styles.selected,
};

const TeamItemUserReport = ({ team, isSelected }) => (
  <div className={`${styles.container} ${selectedStyles[isSelected]}`}>
    <div className={styles.icon}>
      <HomeWorkOutlinedIcon />
    </div>
    <div className={styles.name}>
      <DisplayTextBody
        mode="12"
        decoration="regular"
        color={Color.black}
        fontFamily="openSans"
      >
        {limitChar(team?.name, 20)}
      </DisplayTextBody>
    </div>
  </div>
);

TeamItemUserReport.propTypes = {
  team: PropTypes.object.isRequired,
  isSelected: PropTypes.bool,
};

TeamItemUserReport.defaultProps = {
  isSelected: false,
};

export default memo(TeamItemUserReport);
