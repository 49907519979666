import React, { memo, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import styles from './CompanyInfo.module.css';
import { DisplayTextBody } from '../../../../components/UI_V2/Display/Text';
import Color from '../../../../themes/colors';
import DetailInfoBox from './DetailInfoBox/DetailInfoBox';
import SubscriptionBox from './SubscriptionBox/SubscriptionBox';
import CompanyInfoBox from './CompanyInfoBox/CompanyInfoBox';
import PICInfoBox from './PICInfoBox/PICInfoBox';

const CompanyInfo = ({
  company,
}) => (
  <div className={styles.container}>
    <div className={styles.detailInfo}>
      <CompanyInfoBox
        company={company}
      />
    </div>
    <div className={styles.detailInfo}>
      <SubscriptionBox />
    </div>
    <div className={styles.detailInfo}>
      <PICInfoBox creator={company?.creator} />
    </div>
  </div>
);

CompanyInfo.propTypes = {
  company: PropTypes.object.isRequired,
};

CompanyInfo.defaultProps = {};

export default memo(CompanyInfo);
