import React, { memo, useState } from 'react';
import PropTypes from 'prop-types';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import styles from './UserActivitiesChart.module.css';
import { DisplayTextBody } from '../../../../../components/UI_V2/Display/Text';
import Color from '../../../../../themes/colors';
import LineChart from '../LineChart/LineChart';
import { dateBeautifyFormatter } from '../../../../../utilities/dateUtil';

const UserActivitiesChart = ({
  labels,
  activities,
}) => {
  const firstData = activities.map((elem) => (elem.users.membersJoined));

  const firstDataset = {
    label: 'Members Joined',
    data: firstData,
  };

  const datasets = [
    firstDataset,
  ];

  return (
    <div className={styles.container}>
      <LineChart
        labels={labels}
        datasets={datasets}
      />
    </div>
  );
};

UserActivitiesChart.propTypes = {
  labels: PropTypes.array,
  activities: PropTypes.array,
};

UserActivitiesChart.defaultProps = {
  labels: [],
  activities: [],
};

export default memo(UserActivitiesChart);
