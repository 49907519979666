import React, { useState, useEffect, memo } from 'react';
import { isEqual, lowerCase } from 'lodash';
import Form from 'react-bootstrap/Form';
import PropTypes from 'prop-types';

const SearchLabels = ({ labels, handleFilterLabels }) => {
  const [search, setSearch] = useState({ value: undefined });
  const [resultSearch, setResultSearch] = useState([...labels]);

  useEffect(() => {
    if (search.value === undefined) {
      setResultSearch(labels);
      return;
    }

    const filteredResult = labels.filter((label) => {
      const lowName = lowerCase(label.name);
      const lowSearch = lowerCase(search.value);
      return lowName.includes(lowSearch);
    });

    setResultSearch([...filteredResult]);
  }, [search, labels]);

  const handleChanged = (event) => {
    const { value } = event.target;

    setSearch((prevValue) => ({
      ...prevValue,
      value,
    }));
  };

  useEffect(() => {
    handleFilterLabels([...resultSearch]);
  }, [resultSearch]);

  return (
    <Form>
      <Form.Control autoFocus onChange={(event) => handleChanged(event)} placeholder="Cari Label" />
    </Form>
  );
};

SearchLabels.propTypes = {
  labels: PropTypes.array.isRequired,
  handleFilterLabels: PropTypes.func.isRequired,
};

const isComponentDataEqual = (prevProps, nextProps) => {
  const { labels } = nextProps;
  return isEqual(prevProps.labels, labels);
};

export default memo(SearchLabels, isComponentDataEqual);
