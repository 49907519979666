const limitChat = 20;
const limitUserChat = 100;

const typeChat = {
  MESSAGE: 'message',
  ATTACHMENT: 'attachment',
};

const categoryChat = {
  GROUP: 'group',
  PRIVATE: 'private',
};

const typeCallback = {
  NEW: 'new',
  DELETE: 'delete',
  EDIT: 'edit',
};

export {
  limitChat,
  limitUserChat,
  typeChat,
  categoryChat,
  typeCallback,
};
