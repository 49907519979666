// deprecated, use string util instead
function checkIsJson(str) {
  try {
    JSON.parse(str);
  } catch (e) {
    return false;
  }
  // eslint-disable-next-line no-restricted-globals
  return isNaN(str);
}

export default checkIsJson;
