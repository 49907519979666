import React, { memo, useState } from 'react';
import PropTypes from 'prop-types';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import styles from './TextInfo.module.css';
import { DisplayTextBody } from '../../../../../../components/UI_V2/Display/Text';
import Color from '../../../../../../themes/colors';

const TextInfo = ({
  title,
  text,
}) => (
  <div className={styles.container}>
    <div className={styles.title}>
      <DisplayTextBody decoration="bold">
        {title}
      </DisplayTextBody>
    </div>
    <div className={styles.text}>
      <DisplayTextBody>
        {text}
      </DisplayTextBody>
    </div>
  </div>
);

TextInfo.propTypes = {
  title: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
};

TextInfo.defaultProps = {};

export default memo(TextInfo);
