import React, {
  useState, useContext, useCallback, memo,
} from 'react';
import { useHistory, useParams } from 'react-router-dom';
import _ from 'lodash';
import { useSnackbar } from 'notistack';
import PropTypes from 'prop-types';
import { GlobalContext } from '../../../../contexts/GlobalStateProvider';
import styles from './EditTeam.module.css';
import handleStatusMsg from '../../../../utilities/handleStatusMsg';
import handleLoadings from '../../../../utilities/handleLoadings';
import { DisplayTextBody } from '../../../../components/UI_V2/Display/Text';
import { InputButtonMain } from '../../../../components/UI_V2/Input/Button';
import { TeamActions } from '../../../../actions';
import { InputTextFieldMain } from '../../../../components/UI_V2/Input/TextField';

const EditTeam = ({ team, handleShowEditForm }) => {
  const [{ user, teams, currentCompany }, dispatch] = useContext(GlobalContext);
  const [newTeamUpdated, setNewTeamUpdated] = useState({ name: team.name, desc: team.desc });
  const [loadings, setLoadings] = useState([]);
  const { enqueueSnackbar } = useSnackbar();
  const history = useHistory();
  const params = useParams();
  const { companyId, teamId } = params;

  const handlePostTeam = useCallback(async (value) => {
    try {
      const startLoadings = handleLoadings('editTeam', [...loadings], 'start');
      setLoadings([...startLoadings]);
      const body = value;
      const result = await TeamActions.updateTeamThenUpdateCompanyAndCompanies(
        {
          teamId, teams, companyId, body, currentCompany, userId: user?._id,
        }, dispatch,
      );
      const status = handleStatusMsg(result, 'success');

      enqueueSnackbar(status.message, {
        variant: 'success',
      });
      handleShowEditForm(false);
    } catch (error) {
      const status = handleStatusMsg(error, 'error');

      enqueueSnackbar(status.message, {
        variant: 'error',
      });
    } finally {
      const endLoadings = handleLoadings('editTeam', [...loadings], 'end');
      setLoadings([...endLoadings]);
    }
  }, []);

  const handleChanged = useCallback((event) => {
    const { name, value } = event.target;
    setNewTeamUpdated((prevValue) => ({
      ...prevValue,
      [name]: value,
    }));
  }, []);

  return (
    <div className={styles.mainSection}>
      <div className={styles.formSection}>
        <div className={styles.formSection__title}>
          <DisplayTextBody
            decoration="bold"
            mode="12"
          >
            Nama Tim
          </DisplayTextBody>
        </div>
        <div className={styles.formSection__form}>
          <InputTextFieldMain
            placeholder="Ketik nama tim..."
            value={newTeamUpdated?.name}
            onChange={(event) => handleChanged(event)}
            name="name"
          />
        </div>
      </div>
      <div className={styles.formSection}>
        <div className={styles.formSection__title}>
          <DisplayTextBody
            decoration="bold"
            mode="12"
          >
            Deskripsi Tim
          </DisplayTextBody>
        </div>
        <div className={styles.formSection__form}>
          <InputTextFieldMain
            placeholder="Ketik deskripsi tim..."
            minRows={3}
            maxRows={3}
            value={newTeamUpdated?.desc}
            onChange={(event) => handleChanged(event)}
            name="desc"
            multiline
          />
        </div>
      </div>
      <div className={styles.buttonSection}>
        <InputButtonMain
          handleClick={() => handlePostTeam(newTeamUpdated)}
          border="semiRounded"
          variant="success"
          wait="editTeam"
          loadings={loadings}
        >
          Simpan
        </InputButtonMain>
        <InputButtonMain
          handleClick={() => handleShowEditForm(false)}
          border="semiRounded"
          variant="light"
        >
          Batal
        </InputButtonMain>
      </div>
    </div>
  );
};

EditTeam.propTypes = {
  team: PropTypes.object.isRequired,
  handleShowEditForm: PropTypes.func.isRequired,
};

export default memo(EditTeam);
