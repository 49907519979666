import React, { memo, useState } from 'react';
import PropTypes from 'prop-types';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { useMediaQuery } from '@mui/material';
import styles from './IntroEmptyBillingPage.module.css';
import { DisplayTextBody, DisplayTextHeadline, DisplayTextSubHeadline } from '../../../components/UI_V2/Display/Text';
import Color from '../../../themes/colors';
import introImage from '../../../assets/BillingPage/no-access.png';
import { limitChar } from '../../../utilities/stringUtil';

const IntroEmptyBillingPage = ({
  company,
}) => {
  const isTablet = useMediaQuery('(max-width:1024px)');
  const isMobile = useMediaQuery('(max-width:720px)');
  return (
    <div className={styles.container}>
      <div className={styles.image}>
        <LazyLoadImage src={introImage} />
      </div>
      <div className={styles.text}>
        <div className={styles.headline}>
          <DisplayTextHeadline
            decoration="bold"
            position={isMobile && 'center'}
            color={Color.blueNavyCicle}
          >
            Ups!
          </DisplayTextHeadline>
        </div>
        <DisplayTextSubHeadline
          color={Color.gray3}
          position={isMobile && 'center'}
        >
          Kamu ga punya akses ke halaman tagihan
          {' '}
          {limitChar(company?.name, 30)}
          {' '}
          .
          Silahkan pilih perusahaan lain dimana kamu adalah Admin atau pembuatnya.
        </DisplayTextSubHeadline>
      </div>
    </div>
  );
};

IntroEmptyBillingPage.propTypes = {
  company: PropTypes.object.isRequired,
};

export default memo(IntroEmptyBillingPage);
