import React, { memo, useContext, useState } from 'react';
import PropTypes from 'prop-types';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { useMediaQuery } from '@mui/material';
import { GlobalContext } from '../../../../contexts/GlobalStateProvider';
import styles from './RatingRecapSection.module.css';
import Color from '../../../../themes/colors';
import { DisplayChartLine } from '../../../../components/UI_V2/Display/Chart';
import TitleChart from '../../DataRealtimeReport/ChartUserReport/TitleChart/TitleChart';
import BackgroundChart from '../../DataRealtimeReport/ChartUserReport/BackgroundChart/BackgroundChart';
import InterpretationText from '../InterpretationText/InterpretationText';
import RatingAverageInterpretation from './RatingAverageInterpretation/RatingAverageInterpretation';
import RatingTrendInterpretation from './RatingTrendInterpretation/RatingTrendInterpretation';
import RecapDataSkeleton from '../RecapDataSkeleton/RecapDataSkeleton';

const chartWithSidebarStyles = {
  true: `${styles.chart} ${styles.chartSidebarOpen}`,
  false: styles.chart,
};

const RatingRecapSection = ({
  listReport,
  ratingRecapInterpretation,
  isLoading,
}) => {
  const [{ isSidebarOpen }] = useContext(GlobalContext);

  const isTablet = useMediaQuery('(max-width:1200px)');
  const isMobile = useMediaQuery('(max-width:720px)');

  const labels = listReport.map((elem) => `W${elem?.week}`);
  const data = listReport.map((elem) => elem?.rating?.value);

  const datasets = [
    {
      label: 'Rating',
      data,
    },
  ];
  return (
    <div className={styles.container}>
      <BackgroundChart
        heightVariant={isTablet ? 'low' : 'medium'}
      >
        <div className={styles.title}>
          <TitleChart>
            Progress Rating per Minggu
          </TitleChart>
        </div>
        {isLoading && <RecapDataSkeleton />}
        {!isLoading && (
        <>
          <div className={chartWithSidebarStyles[isSidebarOpen]}>
            <DisplayChartLine labels={labels} datasets={datasets} />
          </div>
          <div className={styles.interpretation}>
            <div className={styles.interpretationItem}>
              <RatingAverageInterpretation
                ratingAverage={ratingRecapInterpretation?.ratingAverage}
              />
            </div>
            <div className={styles.interpretationItem}>
              <RatingTrendInterpretation
                ratingTrend={ratingRecapInterpretation?.ratingTrend}
              />
            </div>
          </div>
        </>
        )}
      </BackgroundChart>
    </div>
  );
};

RatingRecapSection.propTypes = {
  listReport: PropTypes.array,
  ratingRecapInterpretation: PropTypes.string,
  isLoading: PropTypes.bool.isRequired,
};

RatingRecapSection.defaultProps = {
  listReport: [],
  ratingRecapInterpretation: null,
};

export default memo(RatingRecapSection);
