import React, { useState, useEffect, useContext } from 'react';
import { useHistory } from 'react-router-dom';
import axios from 'axios';
import { TextField } from '@mui/material';
import { useSnackbar } from 'notistack';
import DeleteIcon from '@mui/icons-material/Delete';
import PropTypes from 'prop-types';
import { InputButtonMain } from '../../../../components/UI_V2/Input/Button';
import { GlobalContext } from '../../../../contexts/GlobalStateProvider';
import { actionTypes } from '../../../../reducers/reducer';
import styles from './EditCompanyContainer.module.css';
import DeleteCompanyPopUp from '../DeleteCompanyPopUp/DeleteCompanyPopUp';
import handleStatusMsg from '../../../../utilities/handleStatusMsg';
import handleLoadings from '../../../../utilities/handleLoadings';
import { CompanyActions, UserActions } from '../../../../actions';
import { ApiConstants } from '../../../../constants';
import apiUtil from '../../../../utilities/apiUtil';

const EditCompanyContainer = ({ company, cancel, isUserSuper }) => {
  const [{
    user, currentCompanies,
  }, dispatch] = useContext(GlobalContext);
  const { enqueueSnackbar } = useSnackbar();
  const [loadings, setLoadings] = useState([]);
  const [newCompany, setNewCompany] = useState({
    name: company.name,
    desc: company.desc,
  });
  const [postCompany, setPostCompany] = useState();
  const [showDeletePopUp, setShowDeletePopUp] = useState(false);
  const [deleteCompany, setDeleteCompany] = useState();
  const history = useHistory();

  useEffect(() => {
    if (postCompany !== undefined) {
      const postApiCompany = async () => {
        const companyId = company._id;

        try {
          const startLoadings = handleLoadings('editCompany', [...loadings], 'start');
          setLoadings([...startLoadings]);
          const result = await apiUtil.patch(
            ApiConstants.URL_V1.COMPANY({ companyId }),
            postCompany,
          );

          CompanyActions.saveCompanyAndLastOpenedCompany(
            { currentCompany: result?.data?.company, userId: user?._id }, dispatch,
          );

          CompanyActions.saveCompanies(
            { currentCompanies: result?.data?.companies }, dispatch,
          );
          const status = handleStatusMsg(result, 'success');

          enqueueSnackbar(status.message, {
            variant: 'success',
          });

          cancel();
        } catch (err) {
          const status = handleStatusMsg(err, 'error');

          enqueueSnackbar(status.message, {
            variant: 'error',
          });
        } finally {
          const endLoadings = handleLoadings('companyPage', [...loadings], 'end');
          setLoadings([...endLoadings]);
        }
      };
      postApiCompany();
    }
  }, [postCompany]);

  useEffect(() => {
    if (!deleteCompany) return;
    const companyId = company._id;

    const startLoadings = handleLoadings('deleteCompany', [...loadings], 'start');
    setLoadings([...startLoadings]);
    const unsetDefaultCompanyApi = async () => {
      try {
        const userId = user._id;
        const data = { $unset: { defaultCompany: '' } };
        const result = await apiUtil.patch(ApiConstants.URL_V1.USER({ userId }), data);
        UserActions.saveUserAndToken(
          { user: result?.data?.user, token: result?.data?.token }, dispatch,
        );
      } catch (err) {
        const status = handleStatusMsg(err, 'error');

        enqueueSnackbar(status.message, {
          variant: 'error',
        });
      }
    };
    const deleteCompanyApi = async () => {
      try {
        const result = await CompanyActions.deleteCompanyAndUpdateCompanies(
          { companyId }, dispatch,
        );
        const status = handleStatusMsg(result, 'success');

        enqueueSnackbar(status.message, {
          variant: 'success',
        });

        history.push('/');
      } catch (err) {
        const status = handleStatusMsg(err, 'error');

        enqueueSnackbar(status.message, {
          variant: 'error',
        });
      } finally {
        const endLoadings = handleLoadings('deleteCompany', [...loadings], 'end');
        setLoadings([...endLoadings]);
      }
    };
    if (companyId === user?.defaultCompany?._id) {
      unsetDefaultCompanyApi();
    }
    deleteCompanyApi();
  }, [deleteCompany]);

  const handleChanged = (event) => {
    const { name, value } = event.target;
    setNewCompany((prevValue) => ({
      ...prevValue,
      [name]: value,
    }));
  };

  const handlePostCompany = () => {
    setPostCompany(newCompany);
  };

  const handleDeleteCompany = () => {
    setDeleteCompany(company._id);
  };

  const handleShowDeletePopUp = () => {
    setShowDeletePopUp(true);
  };

  const handleCancelShowDeletePopUp = () => {
    setShowDeletePopUp(false);
  };

  return (
    <>
      <div className={styles.inputSection}>
        <div className={styles.inputSection__name}>
          <TextField
            autoFocus
            label="Nama"
            fullWidth
            onChange={(event) => handleChanged(event)}
            name="name"
            value={newCompany.name}
          />
        </div>
        <div className={styles.inputSection__desc}>
          <TextField
            label="Deskripsi"
            fullWidth
            onChange={(event) => handleChanged(event)}
            name="desc"
            value={newCompany.desc}
            multiline
            maxRows={4}
          />
        </div>
      </div>
      <div className={styles.actionSection}>
        <div className={styles.actionSection__edit}>
          <InputButtonMain handleClick={handlePostCompany} variant="success" wait="editCompany" loadings={loadings}>Simpan</InputButtonMain>
          <InputButtonMain handleClick={cancel} variant="light">Batal</InputButtonMain>
        </div>
        {isUserSuper && (
        <div className={styles.actionSection__delete}>
          <InputButtonMain handleClick={handleShowDeletePopUp} iconPosition="center" variant="danger" icon={<DeleteIcon />} loadings={loadings} />
          {showDeletePopUp ? <DeleteCompanyPopUp handleDelete={handleDeleteCompany} cancel={handleCancelShowDeletePopUp} wait="deleteCompany" loadings={loadings} /> : null}
        </div>
        )}

      </div>
    </>
  );
};

EditCompanyContainer.propTypes = {
  company: PropTypes.object.isRequired,
  cancel: PropTypes.func.isRequired,
  isUserSuper: PropTypes.bool.isRequired,
};

export default EditCompanyContainer;
