import React, { memo } from 'react';
import PropTypes from 'prop-types';
import styles from './List.module.css';

function List({ provided, innerRef, children }) {
  return (
    <div
      className={styles.List}
      {...provided.draggableProps}
      {...provided.dragHandleProps}
      ref={innerRef}
    >
      {children}
    </div>
  );
}

List.propTypes = {
  provided: PropTypes.object.isRequired,
  children: PropTypes.node.isRequired,
  innerRef: PropTypes.oneOfType([
    PropTypes.func,
    // eslint-disable-next-line react/forbid-prop-types
    PropTypes.shape({ current: PropTypes.any }),
  ]).isRequired,
};

export default memo(List);
