import React from 'react';
import PropTypes from 'prop-types';
import styles from './ModalOrderSummary.module.css';
import BillingOrderSummary from '../OrderSummary/OrderSummary';
import { FeedbackDialogGeneral } from '../../../components/UI_V2/Feedback/Dialog';

const BillingModalOrderSummary = ({
  open,
  onClose,
  user,
  currentCompany,
  tabSelected,
  forceReinitiateInvoicesList,
}) => (
  <FeedbackDialogGeneral
    open={open}
    onClose={onClose}
  >
    <div className={styles.container}>
      <BillingOrderSummary
        handleClose={onClose}
        isOpen={open}
        initialOrderItems={[]}
        user={user}
        currentCompany={currentCompany}
        tabSelected={tabSelected}
        forceReinitiateInvoicesList={forceReinitiateInvoicesList}
      />
    </div>
  </FeedbackDialogGeneral>
);

BillingModalOrderSummary.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  user: PropTypes.object.isRequired,
  currentCompany: PropTypes.object.isRequired,
  forceReinitiateInvoicesList: PropTypes.func.isRequired,
  tabSelected: PropTypes.string.isRequired,
};

export default BillingModalOrderSummary;
