// deprecated all, use dateUtil instead
const { differenceInDays } = require('date-fns');

const dateDifferenceInDays = (dateLeft, dateRight) => {
  if (!dateLeft || !dateRight) return null;

  return differenceInDays(new Date(dateLeft), new Date(dateRight));
};

const dateDiffInDays = (date1, date2) => {
  const dt1 = new Date(date1);
  const dt2 = new Date(date2);
  return Math.floor(
    (
      Date.UTC(
        dt2.getFullYear(),
        dt2.getMonth(),
        dt2.getDate(),
      ) - Date.UTC(
        dt1.getFullYear(),
        dt1.getMonth(),
        dt1.getDate(),
      )
    ) / (1000 * 60 * 60 * 24),
  );
};

const getISOWeekInMonth = (date) => {
  // Copy date so don't affect original
  const d = new Date(+date);
  // eslint-disable-next-line no-restricted-globals
  if (isNaN(d)) return;
  // Move to previous Monday
  d.setDate(d.getDate() - d.getDay() + 1);
  // Week number is ceil date/7
  // eslint-disable-next-line consistent-return
  return {
    month: +d.getMonth() + 1,
    week: Math.ceil(d.getDate() / 7),
  };
};

export { dateDifferenceInDays, dateDiffInDays, getISOWeekInMonth };
