import React, {
  memo,
  useCallback, useEffect, useState,
} from 'react';
import PropTypes from 'prop-types';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import { Tooltip } from '@mui/material';
import styles from './SelectPeriod.module.css';
import Color from '../../../../themes/colors';
import { InputButtonDropdown } from '../../../../components/UI_V2/Input/Button';
import { useDelayShowHideHandler } from '../../../../hooks/HelperHooks';
import MenuSelectPeriod from './MenuSelectPeriod/MenuSelectPeriod';
import DropdownMenu from '../../../../components/Container/FilterDropdown/DropdownMenu/DropdownMenu';
import { getMonthName, } from '../../../../utilities/dateUtil';
import { DisplayTextBody } from '../../../../components/UI_V2/Display/Text';
import { titleRealtimePeriod } from '../../../../constants/ReportConstants';
import BadgePeriod from '../BadgePeriod/BadgePeriod';
import { pageProperties } from '../../../../constants/PageConstants';

const SelectPeriod = ({
  recapMonthWeeklyProps,
  realtimeProps,
  handleSetVariantUrl,
  variantUrl,
}) => {
  const [
    showSelectPeriodMenu,
    handleShowSelectPeriodMenu,
    handleHideSelectPeriodMenu,
  ] = useDelayShowHideHandler();

  const [textButton, setTextButton] = useState();

  const handleSetTextButton = useCallback((value) => {
    setTextButton(value);
  }, []);

  // REALTIME PART
  const {
    fromDate,
    untilDate,
    selectedQuickDate,
    handleSetRealtimeDate,
  } = realtimeProps;

  useEffect(() => {
    const isTextTheSame = textButton === titleRealtimePeriod[selectedQuickDate];

    const isVariantRealtime = variantUrl === 'realtime';

    if (!isVariantRealtime) return;

    if (!isTextTheSame) handleSetTextButton(titleRealtimePeriod[selectedQuickDate]);
  }, [selectedQuickDate, variantUrl]);

  // RECAP MONTH WEEKLY PART
  const {
    month,
    year,
    handleSetMonth,
    handleSetYear,
  } = recapMonthWeeklyProps;

  const [indexMonthYear, setIndexMonthYear] = useState(0);

  const handleSetIndexMonthYear = useCallback((value) => {
    setIndexMonthYear(value);
  }, []);

  useEffect(() => {
    const isThisMonth = indexMonthYear === 0;
    const isLastMonth = indexMonthYear === 1;

    let text = `${getMonthName(month)} ${year}`;

    if (isThisMonth) text = `${text} (Bulan ini)`;
    if (isLastMonth) text = `${text} (Bulan lalu)`;

    const isTextTheSame = textButton === text;
    const isVariantRealtime = variantUrl === 'realtime';

    if (isVariantRealtime) return;
    if (!isTextTheSame) handleSetTextButton(text);
  }, [month, year, variantUrl]);

  const isThisMonthSelected = indexMonthYear === 0 && variantUrl !== 'realtime';

  return (
    <div className={styles.container}>
      <div className={styles.title}>
        <DisplayTextBody
          decoration="bold"
        >
          Pilih Periode
        </DisplayTextBody>
        {isThisMonthSelected && (
        <div className={styles.icon}>
          <Tooltip title="Khusus pemilihan periode data Rekap 'Bulan Ini', itu datanya pasti tertinggal 1 minggu. Data terbaru akan di update pada hari Sabtu malam berikutnya.">
            <ErrorOutlineIcon />
          </Tooltip>
        </div>
        )}
      </div>
      <div className={styles.button}>
        <InputButtonDropdown
          onClick={handleShowSelectPeriodMenu}
          size="md"
          text={textButton}
          initialDataString={textButton}
          icon={pageProperties.events.icon}
        />
        {showSelectPeriodMenu && (
          <DropdownMenu onClose={handleHideSelectPeriodMenu}>
            <MenuSelectPeriod
              realtimeProps={realtimeProps}
              recapMonthWeeklyProps={recapMonthWeeklyProps}
              onHide={handleHideSelectPeriodMenu}
              handleSetVariantUrl={handleSetVariantUrl}
              variantUrl={variantUrl}
              handleSetIndexMonthYear={handleSetIndexMonthYear}
            />
          </DropdownMenu>
        )}
      </div>
    </div>
  );
};

SelectPeriod.propTypes = {
  recapMonthWeeklyProps: PropTypes.object.isRequired,
  realtimeProps: PropTypes.object.isRequired,
  handleSetVariantUrl: PropTypes.func.isRequired,
  variantUrl: PropTypes.string.isRequired,
};

SelectPeriod.defaultProps = {
};

export default memo(SelectPeriod);
