import React from 'react';
import PropTypes from 'prop-types';
import styles from './CardSnapshot.module.css';
import Label from '../../../../components/UI/Label/Label';
import ImageMember from '../../../../components/UI/ImageMember/ImageMember';
import DisplayDateBox from '../../../../components/UI_V2/Display/Date/Box/Box';
import DescIcon from '../../../../components/UI/DescIcon/DescIcon';
import CommentIcon from '../../../../components/UI/CommentIcon/CommentIcon';
import AttachmentIcon from '../../../../components/UI/AttachmentIcon/AttachmentIcon';
import PrivateIcon from '../../../../components/UI/PrivateIcon/PrivateIcon';
import ChecklistCountIcon from '../../../ListContainer/SmallCard/ChecklistCountIcon/ChecklistCountIcon';
import { DisplayAvatarMember } from '../../../../components/UI_V2/Display/Avatar';

const CardSnapshot = ({ card }) => {
  const {
    labels, name, dueDate, members, comments, attachments, desc, list,
  } = card;
  return (
    <div className={styles.SmallCard}>
      <div className={styles.labelsSection}>
        {labels !== undefined ? labels.map((label) => (
          <Label color={label.color.name} name={label.name} />
        )) : null}
      </div>
      <div className={styles.cardTitleSection}>
        <h1>
          <PrivateIcon data={card} size="small" />
          {' '}
          {name}
        </h1>
      </div>

      <div className={styles.othersPropertiesSection}>
        {dueDate !== undefined
          ? (
            <DisplayDateBox
              complete={card.complete}
              date={dueDate}
              startDate={card.startDate}
              icon
              changeColorDate
            />
          ) : null}
        <DescIcon desc={desc} />
        <CommentIcon comments={comments} />
        <AttachmentIcon attachments={attachments} />
        <ChecklistCountIcon
          completed={card?.allChecklistItemsCount?.completed}
          total={card?.allChecklistItemsCount?.total}
        />
      </div>
      <div className={styles.imageListSection}>
        <div className={styles.imageMemberSection}>
          {members.map((member) => (
            <DisplayAvatarMember src={member.photoUrl} size="xs" />
          ))}
        </div>
        <div className={styles.listTitle}>
          <h1>{list.name}</h1>
        </div>
      </div>

    </div>
  );
};

CardSnapshot.propTypes = {
  card: PropTypes.object.isRequired,
};

export default CardSnapshot;
