/* eslint-disable consistent-return */
import { useCallback } from 'react';
import { CheerActions, EventActions, OccurrenceActions } from '../actions';
import { CheerConstants, OccurrenceConstants } from '../constants';
import { connectSocketOccurrenceNoTypo, connectSocketSchedule } from '../services/socket';
import { useSocketHooks } from './SocketHooks';

function useRecurringEventSocket({ scheduleId, eventId, userId }, dispatch) {
  const callbackRecurringEvent = useCallback((event) => {
    if (event?._id !== eventId) return;
    OccurrenceActions.incomingRecurringEvent({
      event,
    }, dispatch);
  }, []);

  const listenSocket = (socket, params) => {
    socket
      .on(`event-update-${params?.scheduleId}`, callbackRecurringEvent)
      .on(`event-archive-${params?.scheduleId}`, callbackRecurringEvent);
  };
  const removeListener = (socket, params) => {
    socket
      .off(`event-update-${params?.scheduleId}`)
      .off(`event-archive-${params?.scheduleId}`);
    socket.disconnect();
  };

  useSocketHooks({
    params: { scheduleId, userId },
    connectSocket: connectSocketSchedule,
    listenSocket,
    removeListener,
  });
}

function useOccurrenceCheerSocket({ eventId, occurrenceId, userId }, dispatch) {
  const callbackNewCheer = useCallback((cheer) => {
    CheerActions.incomingCheerFromPrimaryParent({
      cheer,
      typeAction: CheerConstants.typeCallback.NEW,
      primaryParentAction: ({ updateStateParent }) => OccurrenceActions.dispatchUpdateOccurrence(
        { updateOccurrence: updateStateParent },
        dispatch,
      ),
      keyProperty: 'cheers',
    });
  }, []);
  const callbackDeleteCheer = useCallback((cheer) => {
    CheerActions.incomingCheerFromPrimaryParent({
      cheer,
      typeAction: CheerConstants.typeCallback.DELETE,
      primaryParentAction: ({ updateStateParent }) => OccurrenceActions.dispatchUpdateOccurrence(
        { updateOccurrence: updateStateParent },
        dispatch,
      ),
      keyProperty: 'cheers',
    });
  }, []);

  const callbackNewCheerComment = useCallback((cheer) => {
    CheerActions.incomingCheerFromSecondaryParent({
      cheer,
      typeAction: CheerConstants.typeCallback.NEW,
      secondaryParentAction: OccurrenceActions.incomingOccurrenceComment,
      typeActionEditSecondaryParent: OccurrenceConstants.typeCallback.EDIT,
    }, dispatch);
  }, []);
  const callbackDeleteCheerComment = useCallback((cheer) => {
    CheerActions.incomingCheerFromSecondaryParent({
      cheer,
      typeAction: CheerConstants.typeCallback.DELETE,
      secondaryParentAction: OccurrenceActions.incomingOccurrenceComment,
      typeActionEditSecondaryParent: OccurrenceConstants.typeCallback.EDIT,
    }, dispatch);
  }, []);

  const listenSocket = (socket, params) => {
    socket
      .on('cheer-new', callbackNewCheer)
      .on('cheer-delete', callbackDeleteCheer)
      .on('cheerComment-new', callbackNewCheerComment)
      .on('cheerComment-delete', callbackDeleteCheerComment);
  };
  const removeListener = (socket, params) => {
    socket
      .off('cheer-new')
      .off('cheer-delete')
      .off('cheerComment-new')
      .off('cheerComment-delete');
    socket.disconnect();
  };

  useSocketHooks({
    params: { eventId, occurrenceId, userId },
    connectSocket: connectSocketOccurrenceNoTypo,
    listenSocket,
    removeListener,
  });
}

function useOccurrenceCommentSocket({ eventId, occurrenceId, userId }, dispatch) {
  const callbackNewComment = useCallback((comment) => {
    OccurrenceActions.incomingOccurrenceComment({
      comment,
      typeAction: OccurrenceConstants.typeCallback.NEW,
    }, dispatch);
  }, []);
  const callbackUpdateComment = useCallback((comment) => {
    OccurrenceActions.incomingOccurrenceComment({
      comment,
      typeAction: OccurrenceConstants.typeCallback.EDIT,
    }, dispatch);
  }, []);
  const callbackDeleteComment = useCallback((comment) => {
    OccurrenceActions.incomingOccurrenceComment({
      comment,
      typeAction: OccurrenceConstants.typeCallback.DELETE,
    }, dispatch);
  }, []);

  const listenSocket = (socket, params) => {
    socket
      .on(`occurrencesComment-new-${params?.occurrenceId}`, callbackNewComment)
      .on(`occurrencesComment-update-${params?.occurrenceId}`, callbackUpdateComment)
      .on(`occurrencesComment-delete-${params?.occurrenceId}`, callbackDeleteComment);
  };
  const removeListener = (socket, params) => {
    socket
      .off(`occurrencesComment-new-${params?.occurrenceId}`)
      .off(`occurrencesComment-update-${params?.occurrenceId}`)
      .off(`occurrencesComment-delete-${params?.occurrenceId}`);
    socket.disconnect();
  };

  useSocketHooks({
    params: { eventId, occurrenceId, userId },
    connectSocket: connectSocketOccurrenceNoTypo,
    listenSocket,
    removeListener,
  });
}

export {
  useRecurringEventSocket,
  useOccurrenceCheerSocket,
  useOccurrenceCommentSocket,
};
