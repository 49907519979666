import React, { memo, useState } from 'react';
import PropTypes from 'prop-types';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import PersonAddAlt1OutlinedIcon from '@mui/icons-material/PersonAddAlt1Outlined';
import styles from './RolePermissionInfo.module.css';
import { DisplayTextBody, DisplayTextSubHeadline } from '../../../UI_V2/Display/Text';
import Color from '../../../../themes/colors';
import { typeRef, whatUserCanDoRef, whatUserCannotDoRef } from '../../../../constants/RoleConstants';
import { firstCharToLower } from '../../../../utilities/stringUtil';

const orientationStyles = {
  horizontal: styles.horizontal,
  vertical: styles.vertical,
};

const orientationPermissionBoxStyles = {
  horizontal: styles.horizontalPermission,
  vertical: styles.verticalPermission,
};

const RolePermissionInfo = ({
  featureType,
  roleUser,
  orientation,
}) => {
  // eslint-disable-next-line max-len
  const canDoList = whatUserCanDoRef[roleUser.role][firstCharToLower(typeRef[firstCharToLower(featureType)])];
  // eslint-disable-next-line max-len
  const cannotDoList = whatUserCannotDoRef[roleUser.role][firstCharToLower(typeRef[firstCharToLower(featureType)])];
  return (
    <div className={`${styles.container} ${orientationStyles[orientation]}`}>
      <div className={`${styles.permission} ${orientationPermissionBoxStyles[orientation]}`}>
        <div className={styles.title}>
          <DisplayTextSubHeadline
            mode="16"
            decoration="bold"
          >
            Boleh :
          </DisplayTextSubHeadline>
        </div>
        {canDoList.map((elem) => (
          <div className={styles.permissionItem}>
            <div className={styles.icon}>
              ✔️
            </div>
            <div className={styles.text}>
              <DisplayTextBody>
                {elem}
              </DisplayTextBody>
            </div>
          </div>
        ))}
      </div>
      <div className={`${styles.permission} ${orientationPermissionBoxStyles[orientation]}`}>
        <div className={styles.title}>
          <DisplayTextSubHeadline
            mode="16"
            decoration="bold"
          >
            Ga boleh :
          </DisplayTextSubHeadline>
        </div>
        {cannotDoList.map((elem) => (
          <div className={styles.permissionItem}>
            <div className={styles.icon}>
              ❌
            </div>
            <div className={styles.text}>
              <DisplayTextBody>
                {elem}
              </DisplayTextBody>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

RolePermissionInfo.propTypes = {
  featureType: PropTypes.string.isRequired,
  roleUser: PropTypes.object.isRequired,
  orientation: PropTypes.oneOf(['horizontal', 'vertical']),
};

RolePermissionInfo.defaultProps = {
  orientation: 'horizontal',
};

export default memo(RolePermissionInfo);
