// deprecated, use stringUtil instead
const limitChar = (content, limit, isRemoveHTML) => {
  try {
    if (!content) {
      return null;
    }

    let result = content;

    if (isRemoveHTML) {
      result = result.replace(/<\/?("[^"]*"|'[^']*'|[^>])*(>|$)/g, '').replace(/\n/g, ' ');
    }

    if (result.length > limit) {
      result = `${result.slice(0, limit)}...`;
    }

    return result;
  } catch (err) {
    return err;
  }
};

export default limitChar;
