import React from 'react';
import PropTypes from 'prop-types';
import styles from './BucketSnapshot.module.css';
import FolderSnapshot from '../../BucketPage/Folder/FolderSnapshot/FolderSnapshot';
import FileSnapshot from '../../BucketPage/Folder/FileSnapshot/FileSnapshot';
import DocSnapshot from '../../BucketPage/Folder/DocSnapshot/DocSnapshot';

const BucketSnapshot = ({ folder, clicked }) => {
  const docs = [];
  if (folder.docs) {
    folder.docs.forEach((doc) => {
      const newDoc = {
        ...doc,
        type: 'doc',
      };

      docs.push(newDoc);
    });
  }

  const buckets = [];
  if (folder.buckets) {
    folder.buckets.forEach((bucket) => {
      const newBucket = {
        ...bucket,
        type: 'bucket',
      };

      buckets.push(newBucket);
    });
  }

  const filesUpload = [];
  if (folder.files) {
    folder.files.forEach((fileUpload) => {
      const newFile = {
        ...fileUpload,
        type: 'file',
      };

      filesUpload.push(newFile);
    });
  }

  // join 2 array
  let joinedDatas = [...docs, ...buckets, ...filesUpload];
  // sort by created date
  joinedDatas = joinedDatas.sort((a, b) => {
    const c = new Date(a.createdAt);
    const d = new Date(b.createdAt);
    return d - c;
  });

  return (
    <div onClick={clicked} className={styles.Folder}>
      {joinedDatas.map((docFile) => {
        if (docFile.type === 'doc') {
          return <DocSnapshot doc={docFile} />;
        } if (docFile.type === 'bucket') {
          return <FolderSnapshot folder={docFile} />;
        } if (docFile.type === 'file') {
          return <FileSnapshot file={docFile} />;
        }

        return null;
      })}
    </div>
  );
};

BucketSnapshot.propTypes = {
  folder: PropTypes.object.isRequired,
  clicked: PropTypes.func.isRequired,
};

export default BucketSnapshot;
