import { cloneDeep, isArray, isNumber } from 'lodash';
import apiUtil, { getNextPage } from '../utilities/apiUtil';
import { actionTypes } from '../reducers/reducer';
import { ErrorException } from '../utilities/handleError';
import { ApiConstants, BillingConstants, RoleConstants } from '../constants';
import {
  mergeNewObjectListAndRemoveDuplicate,
  mergeObjectListAndRemoveDuplicate,
  removeDuplicatesByUserId,
  updateListProperty,
} from '../utilities/arrayUtil';
import { initialState } from '../contexts/GlobalStateProvider';
import { level, role, typeRef } from '../constants/RoleConstants';

/*
  Dispatcher
*/

function dispatchPreviousRoleSuperUsers({ previousRoleSuperUsers }, dispatch) {
  dispatch({
    type: actionTypes.SET_PREVIOUS_ROLE_SUPER_USERS,
    previousRoleSuperUsers,
  });
}

function dispatchCurrentRoleSuperUsers({ currentRoleSuperUsers }, dispatch) {
  dispatch({
    type: actionTypes.SET_CURRENT_ROLE_SUPER_USERS,
    currentRoleSuperUsers,
  });
}

function dispatchUpdateRoleSuperUsers({ updateRoleSuperUsers }, dispatch) {
  dispatch({
    type: actionTypes.UPDATE_CURRENT_ROLE_SUPER_USERS,
    updateRoleSuperUsers,
  });
}

function dispatchPreviousRoleUsers({ previousRoleUsers }, dispatch) {
  dispatch({
    type: actionTypes.SET_PREVIOUS_ROLE_USERS,
    previousRoleUsers,
  });
}

function dispatchCurrentRoleUsers({ currentRoleUsers }, dispatch) {
  dispatch({
    type: actionTypes.SET_CURRENT_ROLE_USERS,
    currentRoleUsers,
  });
}

function dispatchUpdateRoleUsers({ updateRoleUsers }, dispatch) {
  dispatch({
    type: actionTypes.UPDATE_CURRENT_ROLE_USERS,
    updateRoleUsers,
  });
}

function dispatchCurrentRoleUser({ currentRoleUser }, dispatch) {
  dispatch({
    type: actionTypes.SET_CURRENT_ROLE_USER,
    currentRoleUser,
  });
}

/*
  SetterDispatcher
*/

function setPreviousRoleSuperUsers({ previousRoleSuperUsers }, dispatch) {
  if (!previousRoleSuperUsers) return;

  dispatchPreviousRoleSuperUsers(
    { previousRoleSuperUsers: cloneDeep(previousRoleSuperUsers) }, dispatch,
  );
}

function setCurrentRoleSuperUsers({ currentRoleSuperUsers }, dispatch) {
  if (!currentRoleSuperUsers) return;
  dispatchCurrentRoleSuperUsers(
    { currentRoleSuperUsers: cloneDeep(currentRoleSuperUsers) }, dispatch,
  );
}

function setPreviousRoleUsers({ previousRoleUsers }, dispatch) {
  if (!previousRoleUsers) return;

  dispatchPreviousRoleUsers(
    { previousRoleUsers: cloneDeep(previousRoleUsers) }, dispatch,
  );
}

function setCurrentRoleUsers({ currentRoleUsers }, dispatch) {
  if (!currentRoleUsers) return;
  dispatchCurrentRoleUsers(
    { currentRoleUsers: cloneDeep(currentRoleUsers) }, dispatch,
  );
}

function setCurrentRoleUser({ currentRoleUser }, dispatch) {
  if (!currentRoleUser) return;
  dispatchCurrentRoleUser(
    { currentRoleUser: cloneDeep(currentRoleUser) }, dispatch,
  );
}

/* Helper */

const checkIsUserCreatorByFeatureType = ({
  featureType,
  userId,
  currentCard,
  currentBlastPost,
  currentCheckInQuestion,
  currentScheduleEvent,
  currentOccurrence,
  currentBucketDoc,
  currentBucketFile,
  currentBucket,
  occurrenceId,
}) => {
  try {
    let isCreator = false;
    let feature;
    switch (featureType) {
      case typeRef.card:
        feature = currentCard;
        isCreator = currentCard?.creator?._id === userId;
        break;
      case typeRef.post:
        feature = currentBlastPost;
        isCreator = currentBlastPost?.creator?._id === userId;
        break;
      case typeRef.question:
        feature = currentCheckInQuestion;
        isCreator = currentCheckInQuestion?.creator?._id === userId;
        break;
      case typeRef.event:
        if (occurrenceId) {
          feature = currentOccurrence;
          isCreator = currentOccurrence?.creator?._id === userId;
        } else {
          feature = currentScheduleEvent;
          isCreator = currentScheduleEvent?.creator?._id === userId;
        }
        break;
      case typeRef.doc:
        feature = currentBucketDoc;
        isCreator = currentBucketDoc?.creator?._id === userId;
        break;

      default:
        break;
    }

    return isCreator;
  } catch (error) {
    throw new ErrorException(error);
  }
};

/*
  Method
*/

// async function getAllRoleSuperUsers({ companyId }) {
//   try {
//     const result = await apiUtil.get(
//       ApiConstants.URL_V1.ROLE_COMPANIES_SUPER_USERS({ companyId }), {
//         params: {
//           limit: RoleConstants.limitRoleSuperUsers,
//         },
//       },
//     );

//     return result?.data;
//   } catch (error) {
//     throw new ErrorException(error);
//   }
// }

async function patchRoleUsersAndMergeNewList(
  { companyId, data, currentRoleUsers }, dispatch,
) {
  try {
    const result = await apiUtil.patch(
      ApiConstants.URL_V1.ROLE_COMPANIES({ companyId }), {
        roleCompanies: data,
      }, {
        params: {},
      },
    );

    const mergedDataRoleUsers = mergeNewObjectListAndRemoveDuplicate({
      currentObjectList: currentRoleUsers,
      nextObjectList: result?.data,
      keyObject: 'data',
    });

    const mergedRoleUsers = {
      data: mergedDataRoleUsers?.data,
      // ...result?.data,
    };

    // setPreviousRoleUsers({ previousRoleUsers: result?.data }, dispatch);
    setCurrentRoleUsers({ currentRoleUsers: mergedRoleUsers }, dispatch);

    return result?.data;
  } catch (error) {
    throw new ErrorException(error);
  }
}

async function patchRoleUserTeamsAndMergeNewList(
  {
    companyId, teamId, data, currentRoleUsers,
  }, dispatch,
) {
  try {
    const result = await apiUtil.patch(
      ApiConstants.URL_V1.ROLE_EXCEPTION_TEAMS({ teamId }), {
        roleExceptions: data,
      }, {
        params: {
          companyId,
          featureType: 'Team',
          featureId: teamId,
        },
      },
    );

    const mergedDataRoleUsers = mergeNewObjectListAndRemoveDuplicate({
      currentObjectList: currentRoleUsers,
      nextObjectList: result?.data,
      keyObject: 'data',
    });

    const newMergedRoleUsers = removeDuplicatesByUserId(mergedDataRoleUsers?.data);

    const mergedRoleUsers = {
      data: newMergedRoleUsers,
      // ...result?.data,
    };

    // setPreviousRoleUsers({ previousRoleUsers: result?.data }, dispatch);
    setCurrentRoleUsers({ currentRoleUsers: mergedRoleUsers }, dispatch);

    return result?.data;
  } catch (error) {
    throw new ErrorException(error);
  }
}

async function patchRoleUserFeaturesAndMergeNewList(
  {
    companyId,
    teamId,
    data,
    currentRoleUsers,
    featureId,
    featureType,
  }, dispatch,
) {
  try {
    const result = await apiUtil.patch(
      ApiConstants.URL_V1.ROLE_EXCEPTION_FEATURES({ featureId }), {
        roleExceptions: data,
      }, {
        params: {
          companyId,
          teamId,
          featureType,
          featureId,
        },
      },
    );

    const mergedDataRoleUsers = mergeNewObjectListAndRemoveDuplicate({
      currentObjectList: currentRoleUsers,
      nextObjectList: result?.data,
      keyObject: 'data',
    });

    const newMergedRoleUsers = removeDuplicatesByUserId(mergedDataRoleUsers?.data);

    const mergedRoleUsers = {
      data: newMergedRoleUsers,
      // ...result?.data,
    };

    // setPreviousRoleUsers({ previousRoleUsers: result?.data }, dispatch);
    setCurrentRoleUsers({ currentRoleUsers: mergedRoleUsers }, dispatch);

    return result?.data;
  } catch (error) {
    throw new ErrorException(error);
  }
}

const getAndSetRoleUser = async ({
  companyId,
  userId,
  teamId,
  featureType,
  featureId,
}, dispatch) => {
  try {
    const isRoleCompany = !featureType;
    const isRoleExceptionTeam = featureType === typeRef.team && featureId === teamId;
    const isRoleExceptionFeature = featureId
      && featureId !== teamId
      && featureType
      && featureType !== typeRef.team;

    let result;

    if (isRoleCompany) {
      result = await apiUtil.get(
        ApiConstants.URL_V1.ROLE_COMPANY_USER({ companyId, userId }), {
          params: {},
        },
      );
    }

    if (isRoleExceptionTeam) {
      result = await apiUtil.get(
        ApiConstants.URL_V1.ROLE_EXCEPTION_TEAM_USER({ teamId, userId }), {
          params: {
            featureType,
            companyId,
          },
        },
      );
    }

    if (isRoleExceptionFeature) {
      result = await apiUtil.get(
        ApiConstants.URL_V1.ROLE_EXCEPTION_FEATURE_USER({ featureId, userId }), {
          params: {
            featureType,
            companyId,
            teamId,
          },
        },
      );
    }

    setCurrentRoleUser({ currentRoleUser: { ...result?.data?.data, companyId } }, dispatch);
    return result?.data;
  } catch (error) {
    throw new ErrorException(error);
  }
};

// not used
const modifyCurrentRoleUser = ({
  featureType,
  userId,
  currentRoleUser,
  currentBucket,
  currentBlastPost,
  currentCard,
  currentBucketDoc,
  currentCheckInQuestion,
  currentBucketFile,
  currentOccurrence,
  currentScheduleEvent,
}, dispatch) => {
  try {
    const isUserCreator = checkIsUserCreatorByFeatureType({
      featureType,
      userId,
      currentBlastPost,
      currentCard,
      currentBucketDoc,
      currentCheckInQuestion,
      currentBucketFile,
      currentOccurrence,
      currentScheduleEvent,
    });

    const newCurrentRoleUser = {
      ...currentRoleUser,
      role: isUserCreator ? role.creator : currentRoleUser.role,
    };

    setCurrentRoleUser({ currentRoleUser: newCurrentRoleUser }, dispatch);
  } catch (error) {
    throw new ErrorException(error);
  }
};

// async function initiateRoleSuperUsers({
//   page = 1,
//   limit = RoleConstants.limitRoleSuperUsers,
//   companyId,
// }, dispatch) {
//   try {
//     const result = await apiUtil.get(
//       ApiConstants.URL_V1.ROLE_COMPANIES_SUPER_USERS({ companyId }), {
//         params: {
//           page,
//           limit,
//         },
//       },
//     );

//     setCurrentRoleSuperUsers({ currentRoleSuperUsers: result?.data }, dispatch);
//     setPreviousRoleSuperUsers({
//       previousRoleSuperUsers: initialState.previousRoleSuperUsers,
//     }, dispatch);

//     return result;
//   } catch (error) {
//     throw new ErrorException(error);
//   }
// }

// async function loadMoreRoleSuperUsers({
//   currentRoleSuperUsers,
//   limit = RoleConstants.limitRoleSuperUsers,
//   companyId,
// }, dispatch) {
//   try {
//     const page = getNextPage({
//       data: currentRoleSuperUsers.data,
//       limitPerPage: limit,
//     });

//     const result = await apiUtil.get(ApiConstants.URL_V1.ROLE_COMPANIES_SUPER_USERS({
//       companyId,
//     }), {
//       params: {
//         page,
//         limit,
//       },
//     });

//     const mergedDataRoleSuperUsers = mergeObjectListAndRemoveDuplicate({
//       currentObjectList: currentRoleSuperUsers,
//       nextObjectList: result?.data,
//       keyObject: 'data',
//     });

//     const mergedRoleSuperUsers = {
//       ...result?.data,
//       data: mergedDataRoleSuperUsers?.data,
//     };

//     setPreviousRoleSuperUsers({ previousRoleSuperUsers: result?.data }, dispatch);
//     setCurrentRoleSuperUsers({ currentRoleSuperUsers: mergedRoleSuperUsers }, dispatch);

//     return result;
//   } catch (error) {
//     throw new ErrorException(error);
//   }
// }

async function initiateRoleUsers({
  page = 1,
  limit = RoleConstants.limitRoleUsers,
  companyId,
}, dispatch) {
  try {
    const result = await apiUtil.get(ApiConstants.URL_V1.ROLE_COMPANIES({ companyId }), {
      params: {
        page,
        limit,
      },
    });

    setCurrentRoleUsers({ currentRoleUsers: result?.data }, dispatch);
    setPreviousRoleUsers({
      previousRoleUsers: initialState.previousRoleUsers,
    }, dispatch);

    return result;
  } catch (error) {
    throw new ErrorException(error);
  }
}

async function loadMoreRoleUsers({
  currentRoleUsers,
  limit = RoleConstants.limitRoleUsers,
  companyId,
}, dispatch) {
  try {
    const page = getNextPage({
      data: currentRoleUsers.data,
      limitPerPage: limit,
    });

    const result = await apiUtil.get(ApiConstants.URL_V1.ROLE_COMPANIES({ companyId }), {
      params: {
        page,
        limit,
      },
    });

    const mergedDataRoleUsers = mergeObjectListAndRemoveDuplicate({
      currentObjectList: currentRoleUsers,
      nextObjectList: result?.data,
      keyObject: 'data',
    });

    const mergedRoleUsers = {
      ...result?.data,
      data: mergedDataRoleUsers?.data,
    };

    setPreviousRoleUsers({ previousRoleUsers: result?.data }, dispatch);
    setCurrentRoleUsers({ currentRoleUsers: mergedRoleUsers }, dispatch);

    return result;
  } catch (error) {
    throw new ErrorException(error);
  }
}

const sortRoleUserTeams = (roleSuperUsers, roleUserTeams) => {
  try {
    // join 2 array
    let joinedDatas = [...roleSuperUsers, ...roleUserTeams];
    // sort by created date
    joinedDatas = joinedDatas.sort((a, b) => {
      const c = new Date(a.createdAt);
      const d = new Date(b.createdAt);
      return d - c;
    });

    return joinedDatas;
  } catch (error) {
    throw new ErrorException(error);
  }
};

async function initiateRoleUserTeams({
  page = 1, limit = RoleConstants.limitRoleUsers,
  companyId,
  teamId,
}, dispatch) {
  try {
    const resultSuperUsers = await apiUtil.get(
      ApiConstants.URL_V1.ROLE_COMPANIES_SUPER_USERS({ companyId }), {
        params: {
          page,
          limit,
        },
      },
    );

    const result = await apiUtil.get(ApiConstants.URL_V1.ROLE_EXCEPTION_TEAMS({ teamId }), {
      params: {
        page,
        limit,
        companyId,
        featureType: 'Team',
        featureId: teamId,
      },
    });

    const data = sortRoleUserTeams(resultSuperUsers?.data?.data, result?.data?.data);

    setCurrentRoleUsers({ currentRoleUsers: { data } }, dispatch);
    setPreviousRoleUsers({
      previousRoleUsers: initialState.previousRoleUsers,
    }, dispatch);

    return {
      data: {
        data,
      },
    };
  } catch (error) {
    throw new ErrorException(error);
  }
}

async function loadMoreRoleUserTeams({
  currentRoleUsers,
  limit = RoleConstants.limitRoleUsers,
  companyId,
  teamId,
}, dispatch) {
  try {
    const page = getNextPage({
      data: currentRoleUsers.data,
      limitPerPage: limit,
    });

    const resultSuperUsers = await apiUtil.get(
      ApiConstants.URL_V1.ROLE_COMPANIES_SUPER_USERS({ companyId }), {
        params: {
          page,
          limit,
        },
      },
    );

    const result = await apiUtil.get(ApiConstants.URL_V1.ROLE_EXCEPTION_TEAMS({ teamId }), {
      params: {
        page,
        limit,
        companyId,
        featureType: 'Team',
        featureId: teamId,
      },
    });

    const data = sortRoleUserTeams(resultSuperUsers?.data?.data, result?.data?.data);

    const mergedDataRoleUsers = mergeObjectListAndRemoveDuplicate({
      currentObjectList: currentRoleUsers,
      nextObjectList: { data },
      keyObject: 'data',
    });

    const mergedRoleUsers = {
      ...data,
      data: mergedDataRoleUsers?.data,
    };

    setPreviousRoleUsers({ previousRoleUsers: { data } }, dispatch);
    setCurrentRoleUsers({ currentRoleUsers: mergedRoleUsers }, dispatch);

    return {
      data: {
        data,
      },
    };
  } catch (error) {
    throw new ErrorException(error);
  }
}

async function initiateRoleUserFeatures({
  page = 1, limit = RoleConstants.limitRoleUsers,
  companyId,
  teamId,
  featureId,
  featureType,
}, dispatch) {
  try {
    const resultSuperUsers = await apiUtil.get(
      ApiConstants.URL_V1.ROLE_COMPANIES_SUPER_USERS({ companyId }), {
        params: {
          page,
          limit,
        },
      },
    );

    const result = await apiUtil.get(ApiConstants.URL_V1.ROLE_EXCEPTION_FEATURES({ featureId }), {
      params: {
        page,
        limit,
        companyId,
        featureType,
        featureId,
        teamId,
      },
    });

    const data = sortRoleUserTeams(resultSuperUsers?.data?.data, result?.data?.data);

    setCurrentRoleUsers({ currentRoleUsers: { data } }, dispatch);
    setPreviousRoleUsers({
      previousRoleUsers: initialState.previousRoleUsers,
    }, dispatch);

    return {
      data: {
        data,
      },
    };
  } catch (error) {
    throw new ErrorException(error);
  }
}

async function loadMoreRoleUserFeatures({
  currentRoleUsers,
  limit = RoleConstants.limitRoleUsers,
  companyId,
  teamId,
  featureType,
  featureId,
}, dispatch) {
  try {
    const page = getNextPage({
      data: currentRoleUsers.data,
      limitPerPage: limit,
    });

    const resultSuperUsers = await apiUtil.get(
      ApiConstants.URL_V1.ROLE_COMPANIES_SUPER_USERS({ companyId }), {
        params: {
          page,
          limit,
        },
      },
    );

    const result = await apiUtil.get(ApiConstants.URL_V1.ROLE_EXCEPTION_FEATURES({ featureId }), {
      params: {
        page,
        limit,
        companyId,
        featureType,
        featureId,
        teamId,
      },
    });

    const data = sortRoleUserTeams(resultSuperUsers?.data?.data, result?.data?.data);

    const mergedDataRoleUsers = mergeObjectListAndRemoveDuplicate({
      currentObjectList: currentRoleUsers,
      nextObjectList: { data },
      keyObject: 'data',
    });

    const mergedRoleUsers = {
      ...data,
      data: mergedDataRoleUsers?.data,
    };

    setPreviousRoleUsers({ previousRoleUsers: { data } }, dispatch);
    setCurrentRoleUsers({ currentRoleUsers: mergedRoleUsers }, dispatch);

    return {
      data: {
        data,
      },
    };
  } catch (error) {
    throw new ErrorException(error);
  }
}

function incomingRoleUsers({
  roleUser, typeAction, keyProperty = 'data',
}, dispatch) {
  if (!roleUser) return;

  const updateRoleUsers = (currentRoleUsers) => updateListProperty({
    keyProperty,
    newData: roleUser,
    currentList: currentRoleUsers,
    typeAction,
  });

  dispatchUpdateRoleUsers({ updateRoleUsers }, dispatch);
}

/*
  Permission Checker
*/

const hasPermission = (roleUser, checkRoles, alsoCheckHigherThanThisRoleUser) => {
  try {
    let result = true;
    result = checkRoles.includes(roleUser.role);
    if (!alsoCheckHigherThanThisRoleUser) return result;
    if (!result) return result;

    result = level[roleUser.role] > level[alsoCheckHigherThanThisRoleUser.role];

    return result;
  } catch (error) {
    throw new ErrorException(error);
  }
};

export {
  // initiateRoleSuperUsers,
  // loadMoreRoleSuperUsers,
  setPreviousRoleSuperUsers,
  setCurrentRoleSuperUsers,
  initiateRoleUsers,
  loadMoreRoleUsers,
  initiateRoleUserTeams,
  loadMoreRoleUserTeams,
  initiateRoleUserFeatures,
  loadMoreRoleUserFeatures,
  setPreviousRoleUsers,
  setCurrentRoleUsers,
  // getAllRoleSuperUsers,
  patchRoleUsersAndMergeNewList,
  patchRoleUserTeamsAndMergeNewList,
  patchRoleUserFeaturesAndMergeNewList,
  incomingRoleUsers,
  hasPermission,
  getAndSetRoleUser,
  checkIsUserCreatorByFeatureType,
  modifyCurrentRoleUser,
};
