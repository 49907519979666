import React, {
  memo, useState, useCallback, useEffect,
} from 'react';
import PropTypes from 'prop-types';
import FilterAltOutlinedIcon from '@mui/icons-material/FilterAltOutlined';
import Box from '@mui/material/Box';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import { Tooltip } from '@mui/material';
import Color from '../../../themes/colors';
import styles from './FilterNotifPopUp.module.css';
import { FeedbackDialogGeneral } from '../../../components/UI_V2/Feedback/Dialog';
import { DisplayTextBody, DisplayTextSubHeadline } from '../../../components/UI_V2/Display/Text';
import { InputSearchGeneral } from '../../../components/UI_V2/Input/Search';
import ListTeams from './ListTeams/ListTeams';
import Line from '../../../components/UI/Line/Line';
import { useSearch } from '../../../hooks/HelperHooks';
import { InputRadioButtonMain } from '../../../components/UI_V2/Input/RadioButton';

const containerStyles = {
  padding: '50px',
};

const flexStyles = {
  display: 'flex',
  alignItems: 'center',
};

const sectionStyles = {
  marginBottom: '20px',
};

const titleStyles = {
  marginBottom: '4px',
};

const lineCustomStyle = {
  border: `1px solid ${Color.gray6}`,
  marginBottom: '15px',
  marginTop: '5px',
};

const FilterNotifPopUp = ({
  open,
  onClose,
  listCounterTeams,
  handleSelectTab,
  selectedTab,
  handleChangeDefaultViewNotif,
  defaultViewNotif,
}) => {
  const {
    searchResult,
    keyword,
    keywordArray,
    setKeyword,
    isTyping,
    onChange,
    resetSearch,
  } = useSearch({ originalList: listCounterTeams, keyObjectProperty: ['team', 'name'] });

  const handleResetList = useCallback(() => {
    handleSelectTab(selectedTab);
    onClose();
  }, [selectedTab]);

  return (
    <FeedbackDialogGeneral open={open} onClose={onClose}>
      <Box sx={{
        ...containerStyles,
      }}
      >
        <Box
          sx={{
            ...sectionStyles,
          }}
        >
          <Box
            sx={{
              ...titleStyles,
              ...flexStyles,
            }}
          >
            <DisplayTextSubHeadline
              mode="18"
              decoration="bold"
            >
              Filter Notifikasi
            </DisplayTextSubHeadline>
            <Box
              sx={{
                marginLeft: '5px',
              }}
            >
              <Tooltip title="Kamu bisa atur tampilan default notifikasi dan mem-filter notifikasi berdasarkan Tim.">
                <HelpOutlineIcon />
              </Tooltip>
            </Box>
          </Box>
          <Box>
            <DisplayTextSubHeadline
              mode="14"
              color={Color.gray3}
            >
              Lihat notifikasi yang mau kamu lihat
            </DisplayTextSubHeadline>
          </Box>
        </Box>
        <Box>
          <Box>
            <DisplayTextBody>
              Tampilan Default
            </DisplayTextBody>
          </Box>
          <Box
            sx={{
              ...flexStyles,
            }}
          >
            <Box
              sx={{
                ...flexStyles,
                marginRight: '6px',
              }}
            >
              <DisplayTextBody
                color={Color.gray3}
              >
                Semua notifikasi
              </DisplayTextBody>
              <InputRadioButtonMain
                value={defaultViewNotif.defaultView}
                checked={defaultViewNotif.defaultView === 'all'}
                onChange={() => handleChangeDefaultViewNotif('all')}
              />
            </Box>
            <Box
              sx={{
                ...flexStyles,
              }}
            >
              <DisplayTextBody
                color={Color.gray3}
              >
                {/* Show notifications for team I opened first */}
                Tampilkan notifikasi untuk tim yang saya buka duluan
              </DisplayTextBody>
              <InputRadioButtonMain
                value={defaultViewNotif.defaultView}
                checked={defaultViewNotif.defaultView === 'team'}
                onChange={() => handleChangeDefaultViewNotif('team')}
              />
            </Box>
          </Box>
        </Box>
        <Line customStyle={lineCustomStyle} />
        <Box
          sx={{
            ...sectionStyles,
          }}
        >
          <Box
            sx={{
              ...titleStyles,
              ...flexStyles,
              justifyContent: 'space-between',
            }}
          >
            <DisplayTextBody
              color={Color.gray3}
            >
              Filter Tim
            </DisplayTextBody>
            <Box
              sx={{
                opacity: '1',
                '&:hover': {
                  opacity: '0.7',
                },
                cursor: 'pointer',
              }}
              onClick={handleResetList}
            >
              <DisplayTextBody
                color={Color.deleteColor}
              >
                Reset
              </DisplayTextBody>
            </Box>
          </Box>
          <Box>
            <InputSearchGeneral
              autoFocus
              onChange={onChange}
              value={keyword}
              placeholder="Cari tim..."
              onClickCloseIcon={resetSearch}
            />
          </Box>
        </Box>
        <Box
          sx={{
            height: '33vh',
            maxHeight: '33vh',
            overflowY: 'auto',
            '&::-webkit-scrollbar': {
              width: '7px',
            },
            '&::-webkit-scrollbar-thumb': {
              borderRadius: '20px',
              backgroundColor: '#c4c4c4',
            },
            '&::-webkit-scrollbar-thumb:hover': {
              background: '#d1d1d1',
            },
          }}
        >
          <ListTeams
            list={searchResult}
            handleSelectTab={handleSelectTab}
            selectedTab={selectedTab}
            handleCancel={onClose}
          />
        </Box>
      </Box>
    </FeedbackDialogGeneral>
  );
};

FilterNotifPopUp.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  handleSelectTab: PropTypes.func.isRequired,
  selectedTab: PropTypes.string.isRequired,
  listCounterTeams: PropTypes.array.isRequired,
  handleChangeDefaultViewNotif: PropTypes.func.isRequired,
  defaultViewNotif: PropTypes.object.isRequired,
};

export default memo(FilterNotifPopUp);
