import React, { memo, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { useSnackbar } from 'notistack';
import styles from './ModalProfile.module.css';
import Color from '../../../../../../themes/colors';
import { FeedbackDialogGeneral } from '../../../../Feedback/Dialog';
import { DisplayAvatarMember, DisplayAvatarMemberWithOnline } from '../..';
import { DisplayTextBody, DisplayTextSubHeadline } from '../../../Text';
import { SurfacePaperFloating } from '../../../../Surface/Paper';
import handleLoadings from '../../../../../../utilities/handleLoadings';
import { UserActions } from '../../../../../../actions';
import handleStatusMsg from '../../../../../../utilities/handleStatusMsg';
import { FeedbackLoadingMain } from '../../../../Feedback/Loading';
import SkeletonModalProfile from './SkeletonModalProfile/SkeletonModalProfile';
import BubbleOnline from '../BubbleOnline/BubbleOnline';
import StatusBoxOnline from '../StatusBoxOnline/StatusBoxOnline';
import DisplayDateBox from '../../../Date/Box/Box';
import { dateBeautifyFormatter } from '../../../../../../utilities/dateUtil';
import { limitChar } from '../../../../../../utilities/stringUtil';

const ModalProfile = ({
  open,
  onClose,
  userId,
  lastActiveTimestamp,
  status,
  positionPopUp,
}) => {
  const [user, setUser] = useState({
    fullName: null,
    photoUrl: null,
    bio: null,
    status: null,
  });
  const enqueueSnackbar = useSnackbar();
  const [loadings, setLoadings] = useState([]);
  useEffect(() => {
    if (!open) return;
    const fetchUser = async () => {
      try {
        const startLoadings = handleLoadings('userProfile', [...loadings], 'start');
        setLoadings([...startLoadings]);
        const result = await UserActions.getUser({ userId });

        setUser(result?.data?.user);
      } catch (err) {
        const statusError = handleStatusMsg(err, 'error');

        enqueueSnackbar(statusError.message, {
          variant: 'error',
        });
      } finally {
        const endLoadings = handleLoadings('userProfile', [...loadings], 'end');
        setLoadings([...endLoadings]);
      }
    };
    fetchUser();
  }, [open]);

  const isYesterday = dateBeautifyFormatter(lastActiveTimestamp) === 'Kemarin';
  const isToday = dateBeautifyFormatter(lastActiveTimestamp) === 'Hari Ini';
  const isMoreThanYesterday = !isYesterday && !isToday;
  const isEmptyDate = !lastActiveTimestamp;

  return (
    <>
      {open && (
        <SurfacePaperFloating position={positionPopUp} onOutsideClick={onClose} size="md">
          <div className={styles.container}>
            <FeedbackLoadingMain
              wait="userProfile"
              loadings={loadings}
              loadingComponent={<SkeletonModalProfile />}
            >
              <div className={styles.header}>
                <div className={styles.user}>
                  <div className={styles.photo}>
                    <DisplayAvatarMemberWithOnline userId={user._id} size="lg" src={user?.photoUrl} />
                  </div>
                  <div className={styles.text}>
                    <div className={styles.name}>
                      <DisplayTextBody decoration="bold" mode="14">
                        {limitChar(user.fullName, 16)}
                      </DisplayTextBody>
                    </div>
                    <div className={styles.title}>
                      <DisplayTextBody color={Color.gray3}>
                        {limitChar(user.status, 21)}
                      </DisplayTextBody>
                    </div>
                  </div>
                </div>
                {/* <div className={styles.bubble}>
                  <BubbleOnline status={status} />
                </div> */}
              </div>
              <div className={styles.status}>
                <div className={styles.status__item}>
                  <div className={styles.status__item__title}>
                    <DisplayTextBody
                      color={Color.gray3}
                    >
                      Status
                    </DisplayTextBody>
                  </div>
                  <div className={styles.status__item__box}>
                    <StatusBoxOnline status={status} />
                  </div>
                </div>
              </div>
              {status !== 'online' && (
              <div className={styles.status}>
                <div className={styles.status__item}>
                  <div className={styles.status__item__title}>
                    <DisplayTextBody
                      color={Color.gray3}
                    >
                      Terakhir aktif
                    </DisplayTextBody>
                  </div>
                  <div className={styles.status__item__box__date}>
                    {!isEmptyDate && (
                    <DisplayDateBox
                      date={lastActiveTimestamp}
                      enableAlsoExactTimeWhenYesterday
                      relativeTime
                    />
                    )}
                    {isEmptyDate && (
                      <DisplayTextBody
                        color={Color.gray3}
                      >
                        Belum ada data
                      </DisplayTextBody>
                    )}
                  </div>
                </div>
              </div>
              )}
              {user.bio && (
              <div className={styles.bio}>
                <DisplayTextBody
                  color={Color.gray3}
                >
                  {user.bio}
                </DisplayTextBody>
              </div>
              )}
            </FeedbackLoadingMain>
          </div>
        </SurfacePaperFloating>
      )}
    </>

  );
};

ModalProfile.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
  userId: PropTypes.string.isRequired,
  lastActiveTimestamp: PropTypes.string.isRequired,
  status: PropTypes.string.isRequired,
  positionPopUp: PropTypes.oneOf(['topRight', 'topLeft', 'bottomRight', 'bottomLeft']),
};

ModalProfile.defaultProps = {
  open: false,
  onClose: () => null,
  positionPopUp: 'bottomLeft',
};

export default memo(ModalProfile);
