import React, {
  useState, memo, useEffect, useContext,
} from 'react';
import PropTypes from 'prop-types';
import { useParams, useLocation } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import styles from './StickySubscriptionBar.module.css';
import { DisplayTextBody } from '../../../components/UI_V2/Display/Text';
import { dateBeautifyFormatter, dateDifferenceInDays, isDateBeforeNow } from '../../../utilities/dateUtil';
import handleLoadings from '../../../utilities/handleLoadings';
import { BillingActions } from '../../../actions';
import handleStatusMsg from '../../../utilities/handleStatusMsg';
import { GlobalContext } from '../../../contexts/GlobalStateProvider';
import { getSingleOrPlural } from '../../../utilities/stringUtil';
import LinkNoDecor from '../../../components/LinkNoDecor/LinkNoDecor';
import { hasPermission } from '../../../actions/RoleActions';
import { roles } from '../../../constants/RoleConstants';

const statusOverStyles = {
  true: styles.isOver,
  false: '',
};

const statusSoonStyles = {
  true: styles.isSoon,
  false: '',
};

const sidebarStyles = {
  true: styles.sidebarOpen,
  false: '',
};

const StickySubscriptionBar = () => {
  const [{
    user, currentCompany, currentCompanies, teams, currentRoleUser,
    isSidebarOpen,
  }, dispatch] = useContext(GlobalContext);

  const [subscription, setSubscription] = useState({});
  const [loadings, setLoadings] = useState([]);
  const location = useLocation();
  const params = useParams();
  const { enqueueSnackbar } = useSnackbar();

  const { companyId } = params;

  const getSubscriptionApi = async () => {
    try {
      const startLoadings = handleLoadings('billingSubscriptionStatus', [...loadings], 'start');
      setLoadings([...startLoadings]);

      const result = await BillingActions.getSubscriptionStatus({
        companyId,
      });

      setSubscription(result.data);
    } catch (err) {
      const status = handleStatusMsg(err, 'error');

      enqueueSnackbar(status.message, {
        variant: 'error',
      });
    } finally {
      const endLoadings = handleLoadings('billingSubscriptionStatus', [...loadings], 'end');
      setLoadings([...endLoadings]);
    }
  };

  let end;
  let plan;
  if (subscription) {
    end = subscription?.end;
    plan = subscription?.plan;
  }

  const subscriptionDayLeft = dateDifferenceInDays(end, new Date()) + 1;

  const isNoSubscription = subscription?._id === undefined;
  const isSubscriptionOver = subscriptionDayLeft < 0 || isDateBeforeNow(end, new Date());
  const isSubscriptionSoon = !isNoSubscription
  && subscriptionDayLeft < 5
  && !isSubscriptionOver;

  useEffect(() => {
    if (user._id === undefined) return;
    if (currentCompanies.length < 1) return;
    if (!companyId) return;
    getSubscriptionApi();
  }, [location]);

  const isUserSpvOrAbove = hasPermission(currentRoleUser, roles.spvOrAbove);

  return (
    <>
      {(isSubscriptionOver || isSubscriptionSoon) && (
      <div className={`${styles.container} ${statusOverStyles[isSubscriptionOver]} ${statusSoonStyles[isSubscriptionSoon]} ${sidebarStyles[isSidebarOpen]}`}>
        <DisplayTextBody>
          {isSubscriptionSoon && `Langganan berakhir dalam ${subscriptionDayLeft} hari.`}
          {isSubscriptionOver && 'Perusahaanmu ga aktif.'}
        </DisplayTextBody>
        {isUserSpvOrAbove && (
        <>
          <div className={styles.cta}>
            <DisplayTextBody>
              Yuk perbarui di
            </DisplayTextBody>
          </div>
          <LinkNoDecor to={`/companies/${companyId}/billings`}>
            <div className={styles.cta}>
              <DisplayTextBody
                underline
              >
                laman Tagihan.
              </DisplayTextBody>
            </div>
          </LinkNoDecor>
        </>
        )}
      </div>
      )}
    </>
  );
};

StickySubscriptionBar.propTypes = {};

export default memo(StickySubscriptionBar);
