import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { Divider } from '@mui/material';
import Color from '../../../../themes/colors';

function DisplayDividerMain({
  borderColor, variant, textAlign, light,
  marginTop, marginBottom, orientation, children, flexItem,
  marginRight, marginLeft,
}) {
  return (
    <Divider
      orientation={orientation}
      flexItem={flexItem}
      light={light}
      variant={variant}
      textAlign={textAlign}
      sx={{
        borderColor,
        marginTop,
        marginBottom,
        marginRight,
        marginLeft,
      }}
    >
      {children}
    </Divider>
  );
}

DisplayDividerMain.propTypes = {
  variant: PropTypes.oneOf(['fullWidth', 'inset', 'middle']),
  textAlign: PropTypes.oneOf(['center', 'left', 'right']),
  orientation: PropTypes.oneOf(['horizontal', 'vertical']),
  light: PropTypes.bool,
  borderColor: PropTypes.string,
  marginTop: PropTypes.string,
  marginBottom: PropTypes.string,
  marginRight: PropTypes.string,
  marginLeft: PropTypes.string,
  children: PropTypes.node,
  flexItem: PropTypes.bool,
};

DisplayDividerMain.defaultProps = {
  borderColor: Color.yellowCicle2,
  variant: 'fullWidth',
  textAlign: 'center',
  orientation: 'horizontal',
  marginTop: '1rem',
  marginBottom: '1rem',
  marginRight: '0px',
  marginLeft: '0px',
  children: null,
  light: false,
  flexItem: false,
};

export default memo(DisplayDividerMain);
