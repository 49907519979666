import React, {
  memo,
  useCallback, useEffect, useState,
} from 'react';
import PropTypes from 'prop-types';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import { Tooltip, useMediaQuery } from '@mui/material';
import styles from './SelectWeekCardSnapshot.module.css';
import Color from '../../../../../themes/colors';
import { InputButtonDropdown } from '../../../../../components/UI_V2/Input/Button';
import TitleChart from '../../../DataRealtimeReport/ChartUserReport/TitleChart/TitleChart';
import { useDelayShowHideHandler } from '../../../../../hooks/HelperHooks';
import SelectWeekCardSnapshotMenu from './SelectWeekCardSnapshotMenu/SelectWeekCardSnapshotMenu';
import DropdownMenu from '../../../../../components/Container/FilterDropdown/DropdownMenu/DropdownMenu';
import { pageProperties } from '../../../../../constants/PageConstants';

const SelectWeekCardSnapshot = ({
  listReport,
  handleSetSelectedWeek,
  selectedWeek,
}) => {
  const [
    showSelectWeekMenu,
    handleShowSelectWeekMenu,
    handleHideSelectWeekMenu,
  ] = useDelayShowHideHandler();

  const [weeks, setWeeks] = useState(listReport.map((elem) => elem.week));

  useEffect(() => {
    setWeeks(listReport.map((elem) => elem.week));
  }, [listReport]);

  const handleSetCurrentWeek = useCallback((value) => {
    handleSetSelectedWeek(value);
    handleHideSelectWeekMenu();
  }, []);

  const isMobile = useMediaQuery('(max-width:720px)');
  return (
    <div className={styles.container}>
      <div className={styles.title}>
        <TitleChart>
          Rangkuman Tugas per Minggu
        </TitleChart>
        <div className={styles.icon}>
          <Tooltip title="List tugas dibawah ini adalah snapshot kondisi Tugas pada saat dicatat sesuai Minggu yang dipilih. Tidak merepresentasikan kondisi realtime saat ini. Disini juga hanya ditampilkan max 5 list tugas untuk masing-masing kategori.">
            <ErrorOutlineIcon />
          </Tooltip>
        </div>
      </div>
      <div className={styles.buttonWrapper}>
        <div className={styles.button}>
          <InputButtonDropdown
            onClick={handleShowSelectWeekMenu}
            size="sm"
            text={`W${selectedWeek}`}
            initialDataString={`W${selectedWeek}`}
            icon={pageProperties.events.icon}
          />
          {showSelectWeekMenu && (
          <DropdownMenu onClose={handleHideSelectWeekMenu} position={isMobile ? 'topRight' : 'topLeft'}>
            <SelectWeekCardSnapshotMenu
              listWeeks={weeks}
              onClickWeek={handleSetCurrentWeek}
              selectedWeek={selectedWeek}
            />
          </DropdownMenu>
          )}
        </div>
      </div>
    </div>
  );
};

SelectWeekCardSnapshot.propTypes = {
  listReport: PropTypes.array,
  handleSetSelectedWeek: PropTypes.func,
  selectedWeek: PropTypes.string,
};

SelectWeekCardSnapshot.defaultProps = {
  listReport: [],
  handleSetSelectedWeek: () => null,
  selectedWeek: null,
};

export default memo(SelectWeekCardSnapshot);
