import React, {
  useState, useContext, useEffect, useCallback,
} from 'react';
import { useDropzone } from 'react-dropzone';
import Spinner from 'react-bootstrap/Spinner';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { useSnackbar } from 'notistack';
import PropTypes from 'prop-types';
import { useParams } from 'react-router-dom';
import { GlobalContext } from '../../../contexts/GlobalStateProvider';
import { actionTypes } from '../../../reducers/reducer';
import styles from './ProfilePhotoContainer.module.css';
import handleStatusMsg from '../../../utilities/handleStatusMsg';
import { UserActions } from '../../../actions';
import { DisplayAvatarMemberWithOnline } from '../../../components/UI_V2/Display/Avatar';

const ProfilePhotoContainer = ({ profileUser }) => {
  const [{ }, dispatch] = useContext(GlobalContext);
  const [loadingUpload, setLoadingUpload] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const [showUploadInstruction, setShowUploadInstruction] = useState(false);
  const [currentUser, setCurrentUser] = useState(profileUser);

  const params = useParams();

  const { companyId } = params;

  const [files, setFiles] = useState();

  const onDrop = useCallback((acceptedFiles, fileRejections) => {
    if (acceptedFiles.length < 1) {
      setFiles(fileRejections);
    } else {
      setFiles(acceptedFiles);
    }
  }, []);

  const {
    getRootProps,
    getInputProps,
    isDragActive,
  } = useDropzone({
    onDrop,
    maxSize: '10242880',
  });

  useEffect(() => {
    if (files === undefined) {
      return;
    }

    const postApiLogo = async () => {
      const data = new FormData();
      for (let i = 0; i < files.length; i += 1) {
        data.append('file', files[i]);
      }

      setLoadingUpload(true);

      try {
        const userId = currentUser._id;
        const result = await UserActions.updateUserPhoto(
          { userId, body: data, companyId }, dispatch,
        );

        setCurrentUser(result?.data?.user);

        const status = handleStatusMsg(result, 'success');

        enqueueSnackbar(status.message, {
          variant: 'success',
        });

        window.location.reload();
      } catch (err) {
        const getErrorMessageDropzoneFile = files?.[0]?.errors?.[0];
        const messageError = getErrorMessageDropzoneFile === undefined
          ? err : getErrorMessageDropzoneFile;
        const status = handleStatusMsg(messageError, 'error');

        enqueueSnackbar(status.message, {
          variant: 'error',
        });
      } finally {
        setLoadingUpload(false);
        setShowUploadInstruction(false);
      }
    };
    postApiLogo();
  }, [files]);

  const handleMouseEnter = () => {
    setShowUploadInstruction(true);
  };

  const handleMouseLeave = () => {
    setShowUploadInstruction(false);
  };

  let photoSource;
  if (currentUser.photoUrl) {
    if (currentUser.photoUrl?.charAt(0) === '/') {
      photoSource = process.env.REACT_APP_PRIMARY_API_URL + currentUser.photoUrl;
    } else {
      photoSource = currentUser.photoUrl;
    }
  }

  return (
    <div
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      className={styles.container}
      {...getRootProps()}
    >
      <input {...getInputProps()} />
      <div className={styles.box}>
        <DisplayAvatarMemberWithOnline
          userId={profileUser._id}
          src={photoSource}
          size="super"
          enableOnClickCustom
          onClick={() => null}
        />
      </div>
      {isDragActive ? (
        <>
          <div className={`${styles.box} ${styles.backgroundOverlay}`} />
          <div className={`${styles.box} ${styles.contentOverlay}`}>
            <h1>Lepaskan file untuk diunggah.</h1>
          </div>
        </>
      ) : null}
      {showUploadInstruction ? (
        <>
          <div className={`${styles.box} ${styles.backgroundOverlay}`} />
          <div className={`${styles.box} ${styles.contentOverlay}`}>
            <h1>Ubah Foto</h1>
          </div>
        </>
      ) : null}
      {loadingUpload ? (
        <>
          <div className={`${styles.box} ${styles.backgroundOverlay}`} />
          <div className={`${styles.box} ${styles.contentOverlay}`}>
            <div className={styles.loadingContainer}>
              <Spinner animation="border" size="sm" variant="warning" />
            </div>
          </div>
        </>
      ) : null}
    </div>

  );
};

ProfilePhotoContainer.propTypes = {
  profileUser: PropTypes.object.isRequired,
};

export default ProfilePhotoContainer;
