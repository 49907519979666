import React, { memo } from 'react';
import PropTypes from 'prop-types';
import styles from './LinkNoDecorV2.module.css';

const LinkNoDecorV2 = ({ to, children, isOpenNewTab }) => (
  <a className={styles.Link} href={to} target={isOpenNewTab ? '_blank' : ''} rel="noreferrer">
    {children}
  </a>
);

LinkNoDecorV2.propTypes = {
  to: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
  isOpenNewTab: PropTypes.bool,
};

LinkNoDecorV2.defaultProps = {
  isOpenNewTab: false,
};

export default memo(LinkNoDecorV2);
