import React, {
  memo, useState, useCallback, useEffect,
} from 'react';
import { useParams, useHistory, useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { useSnackbar } from 'notistack';
import queryString from 'query-string';
import styles from './CompanyList.module.css';
import { DisplayTextBody } from '../../../../components/UI_V2/Display/Text';
import Color from '../../../../themes/colors';
import ColumnList from './ColumnList/ColumnList';
import InfinityScroll from '../../../../components/UI/InfinityScroll/InfinityScroll';
import { DisplayDividerMain } from '../../../../components/UI_V2/Display/Divider';
import { useInfinityScrollHooks } from '../../../../hooks/InfinityScrollHooks';
import handleLoadings from '../../../../utilities/handleLoadings';
import handleStatusMsg from '../../../../utilities/handleStatusMsg';
import { AdministratorActions } from '../../../../actions';
import CompanyListSkeleton from './CompanyListSkeleton/CompanyListSkeleton';
import CompanyItem from './CompanyItem/CompanyItem';
import { AdministratorConstants } from '../../../../constants';
import FilterDropdown from '../../../../components/Container/FilterDropdown/FilterDropdown';
import LinkNoDecor from '../../../../components/LinkNoDecor/LinkNoDecor';
import { calculateMonthday, handleCompareDate } from '../../../../utilities/dateUtil';
import DateSeparator from '../../../CommentsSectionContainer/DateSeparator/DateSeparator';
import { InputSearchGeneral } from '../../../../components/UI_V2/Input/Search';
import { useSearch } from '../../../../hooks/HelperHooks';

const CompanyList = ({
  handleSetHeaderText
}) => {
  /* Initiate list data */
  const params = useParams();
  const history = useHistory();
  const location = useLocation();

  const query = queryString.parse(location.search);

  const {
    category,
    fromDate,
    untilDate,
    isOnTrial,
  } = query;

  let initialFromDate = calculateMonthday(new Date(), 14, 'subtract');
  if (fromDate) {
    initialFromDate = new Date(fromDate);
  }

  let initialUntilDate = new Date();
  if (untilDate) {
    initialUntilDate = new Date(untilDate);
  }

  let initialTrial;

  if (isOnTrial === 'true') {
    initialTrial = true;
  }
  if (isOnTrial === 'false') {
    initialTrial = false;
  }

  const initialStatePreviousMetricCompanies = {
    data: new Array(10),
  };
  const initialStateCurrentMetricCompanies = {
    data: [],
  };
  const [previousMetricCompanies,
    setPreviousMetricCompanies] = useState(initialStatePreviousMetricCompanies);
  const [currentMetricCompanies,
    setCurrentMetricCompanies] = useState(initialStateCurrentMetricCompanies);
  const [isFirstLoad, setIsFirstLoad] = useState(true);
  const [filters, setFilters] = useState({
    filterFromUntilDate: {
      fromDate: initialFromDate,
      untilDate: initialUntilDate,
    },
    filterTrial: initialTrial,
    filterMetricCategory: category || undefined,
  });
  const [loadings, setLoadings] = useState([]);

  const { enqueueSnackbar } = useSnackbar();
  const getMoreLists = async () => {
    const result = await AdministratorActions.loadMoreMetricCompanies({
      currentMetricCompanies,
      setCurrentMetricCompanies,
      setPreviousMetricCompanies,
      filters,
    });

    return result;
  };

  const resetPreviousLists = useCallback(() => {
    setPreviousMetricCompanies(initialStatePreviousMetricCompanies);
    setCurrentMetricCompanies(initialStateCurrentMetricCompanies);
  }, []);

  const {
    lists,
    handleLoadMoreLists,
    checkIfListNeedToLoad,
    setCheckLoadMore,
    isListsLengthAtTheLimitOrAbove,
  } = useInfinityScrollHooks({
    currentObjectWithKeyProperty: currentMetricCompanies,
    previousLists: previousMetricCompanies?.data,
    resetPreviousLists,
    getMoreLists,
    keyProperty: 'data',
    limitList: AdministratorConstants.limitMetricCompanies,
    dontSorting: true,
  });

  const initiateMetricCompaniesApi = async (filtersValue) => {
    try {
      const startLoadings = handleLoadings('initiateMetricCompanies', [...loadings], 'start');
      setLoadings([...startLoadings]);

      const result = await AdministratorActions.initiateMetricCompanies({
        setCurrentMetricCompanies,
        setPreviousMetricCompanies,
        initialStatePreviousMetricCompanies,
        filters: filtersValue,
      });

      if (isListsLengthAtTheLimitOrAbove(result?.data?.data)) {
        setCheckLoadMore(true);
      } else {
        setCheckLoadMore(false);
      }

      if (isFirstLoad) {
        setIsFirstLoad(false);
      }

      handleSetHeaderText({
        category: filtersValue.filterMetricCategory,
        fromDate: filtersValue.filterFromUntilDate.fromDate,
        untilDate: filtersValue.filterFromUntilDate.untilDate,
      });
    } catch (err) {
      const status = handleStatusMsg(err, 'error');

      enqueueSnackbar(status.message, {
        variant: 'error',
      });
    } finally {
      const endLoadings = handleLoadings('initiateMetricCompanies', [...loadings], 'end');
      setLoadings([...endLoadings]);
    }
  };

  const onApplyFilters = (values) => {
    initiateMetricCompaniesApi(values);
    setFilters(values);
  };

  useEffect(() => {
    initiateMetricCompaniesApi(filters);
  }, [location]);

  /* search */
  const {
    searchResult,
    keyword,
    keywordArray,
    setKeyword,
    isTyping,
    onChange,
    resetSearch,
  } = useSearch({
    originalList: lists,
    keyObjectProperty: ['company', 'name'],
  });

  return (
    <div className={styles.container}>
      <div className={styles.filter}>
        <FilterDropdown
          showFeatureTypeSection={false}
          showArchivedSection={false}
          showTeamSection={false}
          showFromUntilDateSection
          onApplyFilters={onApplyFilters}
          loadingsOnApplyFilters={loadings}
          waitOnApplyFilters="initiateMetricCompanies"
          initialFromDate={filters.filterFromUntilDate.fromDate}
          initialUntilDate={filters.filterFromUntilDate.untilDate}
          showTrialSection
          initialTrial={filters.filterTrial}
          showMetricCategorySection
          initialMetricCategory={filters.filterMetricCategory}
          buttonSizeVariant="sm"
        />
      </div>
      <div className={styles.search}>
        <InputSearchGeneral
          onChange={onChange}
          value={keyword}
          placeholder="Search company name..."
          onClickCloseIcon={resetSearch}
          size="sm"
          autoFocus={false}
        />
      </div>
      <DisplayDividerMain />
      <div id="metricCompaniesList" className={styles.list}>
        {isFirstLoad && <CompanyListSkeleton />}
        {!isFirstLoad && (
        <InfinityScroll
          scrollableTarget="metricCompaniesList"
          dataLength={lists?.length || 0}
          hasMore={checkIfListNeedToLoad()}
          next={handleLoadMoreLists}
          emptyMessage="No items here yet..."
        >
          <div className={styles.listItems}>
            {searchResult.map((item, index) => {
              const isSameDate = handleCompareDate(
                searchResult,
                item,
                (index - 1),
                'metricDate',
              );
              if (!isSameDate) {
                return (
                  <>
                    <DateSeparator date={item.metricDate} />
                    <div className={styles.item}>
                      <CompanyItem
                        item={item}
                        baseUrl={location.pathname}
                      />
                    </div>
                  </>
                );
              }
              return (
                <div className={styles.item}>
                  <CompanyItem
                    item={item}
                    baseUrl={location.pathname}
                  />
                </div>
              );
            })}
          </div>
        </InfinityScroll>
        )}
      </div>
    </div>
  );
};

CompanyList.propTypes = {
  handleSetHeaderText: PropTypes.func.isRequired,
};

export default memo(CompanyList);
