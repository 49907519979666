import React, { memo, useState } from 'react';
import PropTypes from 'prop-types';
import { Box, Skeleton } from '@mui/material';
import styles from './Body.module.css';

const EditBlastPostPageSkeletonBody = () => (
  <Box className={styles.container}>
    <Box className={styles.content}>
      <Skeleton
        animation="wave"
        variant="text"
        width={200}
      />
      <Skeleton
        animation="wave"
        variant="text"
      />
      <Skeleton
        animation="wave"
        variant="text"
      />
      <Skeleton
        animation="wave"
        variant="text"
      />
      <Box className={styles.image}>
        <Skeleton
          animation="wave"
          variant="rectangular"
          width={200}
          height={200}
        />
      </Box>
      <Skeleton
        animation="wave"
        variant="text"
      />
      <Skeleton
        animation="wave"
        variant="text"
      />
    </Box>
    <Box className={styles.options}>
      <Skeleton
        animation="wave"
        variant="text"
        width={120}
      />
      <Skeleton
        animation="wave"
        variant="text"
      />
      <Skeleton
        animation="wave"
        variant="text"
      />
      <Skeleton
        animation="wave"
        variant="text"
        width={120}
      />
      <Skeleton
        animation="wave"
        variant="text"
      />
    </Box>
    <Box className={styles.action}>
      <Box className={styles.cancelButton}>
        <Skeleton
          animation="wave"
          variant="rectangular"
          height={30}
          width={80}
        />
      </Box>
      <Box>
        <Skeleton
          animation="wave"
          variant="rectangular"
          height={30}
          width={80}
        />
      </Box>
    </Box>
  </Box>
);

export default memo(EditBlastPostPageSkeletonBody);
