import React, {
  useState, useContext, useEffect, useCallback, memo, createRef,
} from 'react';
import { useParams } from 'react-router-dom';
import { differenceWith, isEqual, lastIndexOf } from 'lodash';
import { useSnackbar } from 'notistack';
import Spinner from 'react-bootstrap/Spinner';
import PropTypes from 'prop-types';
import { GlobalContext, initialState } from '../../../../contexts/GlobalStateProvider';
import Message from '../../../PrivateChatPage/MainChatSection/FetchMessages/Message/Message';
import styles from './FetchGroupMessages.module.css';
import { ChatActions } from '../../../../actions';
import {
  useFileViewer,
} from '../../../../hooks/FileHooks';
import handleStatusMsg from '../../../../utilities/handleStatusMsg';
import { handleCompareDate, handleCheckToday } from '../../../../utilities/checkSameDay';
import DateSeparator from '../../../CommentsSectionContainer/DateSeparator/DateSeparator';
import { useGroupMessageSocket, useSearchAutoScrollMessage } from '../../../../hooks/GroupMessageHooks';
import UnreadMessagesSeparator from '../../../PrivateChatPage/MainChatSection/FetchMessages/UnreadMessagesSeparator/UnreadMessagesSeparator';
import InfinityScroll from '../../../../components/UI/InfinityScroll/InfinityScroll';
import { FeedbackLoadingBlock } from '../../../../components/UI_V2/Feedback/Loading';
import FileViewerDialog from '../../../../components/FileViewerDialog/FileViewerDialog';
import Color from '../../../../themes/colors';
import { reverseArray } from '../../../../utilities/arrayUtil';

const FetchGroupMessages = ({
  isNeedCheckLoadMore, checkLengthChatToLimit,
  loadingUpload, progressUpload, listFilesBeingUploaded,
}) => {
  const [{ user, currentGroupChat, previousGroupChat }, dispatch] = useContext(GlobalContext);
  const params = useParams();
  const [messagesFiles, setMessagesFiles] = useState([]);
  const { enqueueSnackbar } = useSnackbar();
  const [isErrorLoadMore, setStatusErrorLoadMore] = useState(false);

  const { companyId, teamId } = params;

  const [messageRef, lastMessageRef, searchMessageId, goToMessage] = useSearchAutoScrollMessage({
    list: messagesFiles,
    keyQueryProperty: 'messageId',
  });

  useGroupMessageSocket({
    groupChatId: params?.groupChatId,
    userId: user?._id,
  }, dispatch);

  const {
    openFileViewer,
    fileViewerObj,
    handleOpenAndSetFileViewer,
    handleCloseFileViewer,
  } = useFileViewer();

  const sortingCurrentGroupChat = useCallback(
    () => ChatActions.sortingChats(currentGroupChat?.groupChats), [currentGroupChat],
  );
  const checkIfChatNeedToLoad = useCallback(() => {
    if (!isNeedCheckLoadMore || isErrorLoadMore) return false;

    return checkLengthChatToLimit(previousGroupChat);
  }, [isNeedCheckLoadMore, previousGroupChat, isErrorLoadMore]);

  const resetPrevGroupChat = useCallback(() => {
    ChatActions.setPreviousGroupChat({
      previousGroupChat: initialState.previousGroupChat,
    }, dispatch);
    ChatActions.setCurrentGroupChat({
      currentGroupChat: initialState.previousGroupChat,
    }, dispatch);
  }, []);

  useEffect(() => () => resetPrevGroupChat(), []);

  useEffect(() => {
    if (currentGroupChat.groupChats === undefined) return;

    const joinedData = sortingCurrentGroupChat();

    const prevArray = [...messagesFiles];
    const updatedItems = differenceWith(joinedData, prevArray, isEqual);

    if (updatedItems.length > 0) {
      setMessagesFiles([...joinedData]);
    }
  }, [currentGroupChat]);

  const handleLoadMoreMessages = async () => {
    try {
      const result = await ChatActions.loadMoreGroupChats({
        groupChatId: params.groupChatId,
        currentGroupChat,
        companyId,
        teamId,
      }, dispatch);
    } catch (error) {
      const status = handleStatusMsg(error, 'error');

      if (status?.message) {
        enqueueSnackbar(status.message, {
          variant: 'error',
        });
      }

      setStatusErrorLoadMore(true);
    }
  };

  return (
    <div id="list-groupChat-messages-section" className={styles.container}>
      <InfinityScroll
        scrollableTarget="list-groupChat-messages-section"
        inverse
        dataLength={messagesFiles?.length}
        next={handleLoadMoreMessages}
        hasMore={checkIfChatNeedToLoad()}
        initialScrollY={0}
        // loader={<div className={styles.infiniteScroll}>
        // <Spinner animation="border" size="lg" variant="warning" /></div>}
        emptyMessage={(
          <>
            <div className={styles.infiniteScroll}>
              {messagesFiles.length === 0
                ? <p>No messages here yet...</p>
                : <p>That&apos;s all, folks! 🥳</p>}
            </div>
          </>
            )}
        style={{ display: 'flex', flexDirection: 'column-reverse' }}
      >
        {messagesFiles !== undefined
          ? (
            <>
              <FeedbackLoadingBlock
                loadingUpload={loadingUpload}
                progressUpload={progressUpload}
                listFilesBeingUploaded={reverseArray(listFilesBeingUploaded)}
                backgroundColorItem={Color.white}
                heightItem="50px"
              />
              {messagesFiles.map((messageFile, index) => {
                if (!messageFile) return null;
                const isSameDate = handleCompareDate(messagesFiles, messageFile, (index + 1), 'createdAt');
                const isToday = handleCheckToday(messageFile);

                const isLastIndex = index === (messagesFiles.length - 1);
                const isMessageMatchQuery = searchMessageId === messageFile._id;

                let usedRef;

                if (isLastIndex) {
                  usedRef = lastMessageRef;
                }

                if (isMessageMatchQuery) {
                  usedRef = messageRef;
                  goToMessage();
                }

                if (!isSameDate) {
                  return (
                    <>
                      <Message
                        messageFile={messageFile}
                        owner={messageFile?.creator?._id === user?._id ? 'self' : 'other'}
                        isToday={isToday}
                        category="group-chat"
                        ref={usedRef}
                        handleOpenAndSetFileViewer={handleOpenAndSetFileViewer}
                      />
                      <DateSeparator date={messageFile?.createdAt} />
                      {/* {index === currentChatUnreadMessagesCounter - 1
                  ? <UnreadMessagesSeparator /> : null} */}
                    </>
                  );
                }
                return (
                  <>
                    <Message
                      messageFile={messageFile}
                      owner={messageFile?.creator?._id === user?._id ? 'self' : 'other'}
                      isToday={isToday}
                      category="group-chat"
                      ref={usedRef}
                      handleOpenAndSetFileViewer={handleOpenAndSetFileViewer}
                    />
                    {/* {index === currentChatUnreadMessagesCounter - 1
                ? <UnreadMessagesSeparator /> : null} */}
                  </>
                );
              })}
            </>
          )
          : null}
      </InfinityScroll>
      <FileViewerDialog
        open={openFileViewer}
        onClose={handleCloseFileViewer}
        file={fileViewerObj}
      />
    </div>
  );
};

FetchGroupMessages.propTypes = {
  isNeedCheckLoadMore: PropTypes.bool.isRequired,
  checkLengthChatToLimit: PropTypes.func.isRequired,
  loadingUpload: PropTypes.bool.isRequired,
  listFilesBeingUploaded: PropTypes.array.isRequired,
  progressUpload: PropTypes.array.isRequired,
};

export default memo(FetchGroupMessages);
