import React, {
  useState, useEffect, memo,
} from 'react';
import { useParams } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import Form from 'react-bootstrap/Form';
import CloseIcon from '@mui/icons-material/Close';
import AddIcon from '@mui/icons-material/Add';
import Button from '../../../components/UI/Button/Button';
import styles from './CreateListButton.module.css';
import handleStatusMsg from '../../../utilities/handleStatusMsg';
import handleLoadings from '../../../utilities/handleLoadings';
import { ListActions } from '../../../actions';

const CreateListButton = () => {
  const { enqueueSnackbar } = useSnackbar();
  const [loadings, setLoadings] = useState([]);
  const [isCreateList, setIsCreateList] = useState(false);
  const [newList, setNewList] = useState({
    name: '',
    desc: '',
  });
  const [postList, setPostList] = useState();

  const params = useParams();
  const { companyId, teamId, boardId } = params;

  const handleCancel = () => {
    setIsCreateList(false);
  };

  useEffect(() => {
    const postNewList = async () => {
      // POST API
      if (postList !== undefined) {
        try {
          const startLoadings = handleLoadings('createList', [...loadings], 'start');
          setLoadings([...startLoadings]);
          const body = { selector: { boardId }, data: { name: postList.name } };
          const result = await ListActions.createList({ companyId, body, teamId, });

          const status = handleStatusMsg(result, 'success');

          enqueueSnackbar(status.message, {
            variant: 'success',
          });

          handleCancel();
        } catch (err) {
          const status = handleStatusMsg(err, 'error');

          enqueueSnackbar(status.message, {
            variant: 'error',
          });
        } finally {
          const endLoadings = handleLoadings('createList', [...loadings], 'end');
          setLoadings([...endLoadings]);
        }
      }
    };
    postNewList();
  }, [postList]);

  const handleCreateList = () => {
    setIsCreateList(true);
  };

  const handleChanged = (event) => {
    const { value } = event.target;

    setNewList((prevValue) => ({
      ...prevValue,
      name: value,
    }
    ));
  };

  const clickPostList = () => {
    if (newList === undefined || newList.name === undefined || newList.name.length < 1) {
      enqueueSnackbar('List name cannot be empty', {
        variant: 'error',
      });
      return;
    }
    setPostList(newList);
  };

  return (
    <>

      {isCreateList

        ? (
          <div className={styles.AddCommentContainer}>
            <div className={styles.inputComment}>
              <Form onSubmit={(event) => {
                clickPostList();
                event.preventDefault();
              }}
              >
                <div>
                  <Form.Control autoFocus as="input" onChange={(event) => handleChanged(event)} name="name" placeholder="Ketik nama list..." />
                </div>
                <div className={styles.actionSection}>
                  <div>
                    <Button handleClick={clickPostList} variant="success" size="sm" wait="createList" loadings={loadings}>Buat</Button>
                    <CloseIcon className={styles.icon} onClick={handleCancel} />
                  </div>
                </div>
              </Form>
            </div>
          </div>
        )
        : (
          <Button flex="flexStart" className={styles.addListButton} handleClick={handleCreateList} variant="outline-dark" size="sm" block>
            <div className={styles.buttonBottom}>
              <AddIcon />
              <a>Buat List</a>
            </div>
          </Button>
        )}
    </>
  );
};

export default memo(CreateListButton);
