import React, { memo, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import DownloadIcon from '@mui/icons-material/Download';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { useMediaQuery } from '@mui/material';
import SortIcon from '@mui/icons-material/Sort';
import styles from './SortButton.module.css';
import Color from '../../themes/colors';
import { InputButtonMain } from '../UI_V2/Input/Button';
import SortModal from './SortModal/SortModal';
import { useDelayShowHideHandler } from '../../hooks/HelperHooks';
import {
  sortByAlphabet, sortByAlphabetReverse, sortByDateNewestToOldest, sortByDateOldestToNewest,
} from '../../utilities/arrayUtil';
import { getStoreTeamSortType, setStoreTeamSortType } from '../../utilities/localStorage';

const SortButton = ({
  originalList,
  handleChangeOriginalListToNewList,
  alphabetKeyObj,
  title,
  companyId,
  userId,
}) => {
  const initialSortType = getStoreTeamSortType(
    { companyId, userId },
  );
  const [showModal, handleShowModal, handleHideModal] = useDelayShowHideHandler(false);
  const [sortType, setSortType] = useState(initialSortType?.teamSortType || 'alphabetAsc');

  useEffect(() => {
    setSortType(initialSortType?.teamSortType || 'alphabetAsc');
  }, [companyId]);

  const handleChangeSortType = (value) => {
    setSortType(value);
    setStoreTeamSortType({
      userId,
      companyId,
      teamSortType: { teamSortType: value },
    });
  };

  useEffect(() => {
    let newList = [];
    switch (sortType) {
      case 'alphabetAsc':
        newList = sortByAlphabet(originalList, alphabetKeyObj);
        break;
      case 'alphabetDesc':
        newList = sortByAlphabetReverse(originalList, alphabetKeyObj);
        break;
      case 'createdAsc':
        newList = sortByDateNewestToOldest(originalList, 'createdAt');
        break;
      case 'createdDesc':
        newList = sortByDateOldestToNewest(originalList, 'createdAt');
        break;
      case 'updatedAsc':
        newList = sortByDateNewestToOldest(originalList, 'updatedAt');
        break;
      case 'updatedDesc':
        newList = sortByDateOldestToNewest(originalList, 'updatedAt');
        break;

      default:
        break;
    }
    handleChangeOriginalListToNewList(newList);
  }, [sortType]);

  return (
    <>
      <div className={styles.container} onClick={handleShowModal}>
        <SortIcon />
      </div>
      {showModal && (
      <SortModal
        open={showModal}
        onClose={handleHideModal}
        onSelectSortType={handleChangeSortType}
        initialSortType={sortType}
        title={title}
      />
      )}
    </>
  );
};

SortButton.propTypes = {
  originalList: PropTypes.array,
  handleChangeOriginalListToNewList: PropTypes.func,
  alphabetKeyObj: PropTypes.oneOf(['name', 'title']),
  title: PropTypes.string,
  companyId: PropTypes.string,
  userId: PropTypes.string,
};

SortButton.defaultProps = {
  originalList: [],
  handleChangeOriginalListToNewList: () => null,
  alphabetKeyObj: 'name',
  title: 'Tim',
  companyId: null,
  userId: null,
};

export default memo(SortButton);
