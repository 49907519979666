import apiUtil, { getNextPage } from '../utilities/apiUtil';
import { ErrorException } from '../utilities/handleError';
import { mergeObjectListAndRemoveDuplicate } from '../utilities/arrayUtil';

async function initiateListData({
  page = 1,
  limit = 10,
  setCurrentListData,
  setPreviousListData,
  initialStatePreviousListData,
  initiateListDataFunc,
  filters,
}) {
  try {
    const result = await initiateListDataFunc({
      filters, page, limit,
    });

    setCurrentListData(result?.data);
    setPreviousListData(initialStatePreviousListData);

    return result;
  } catch (error) {
    throw new ErrorException(error);
  }
}

async function loadMoreListData({
  currentListData,
  limit = 10,
  setCurrentListData,
  setPreviousListData,
  filters,
  loadMoreListDataFunc,
}) {
  try {
    const page = getNextPage({
      data: currentListData.data,
      limitPerPage: limit,
    });

    const result = await loadMoreListDataFunc({
      filters, page, limit, currentListData,
    });

    const mergedDataListData = mergeObjectListAndRemoveDuplicate({
      currentObjectList: currentListData,
      nextObjectList: result?.data,
      keyObject: 'data',
    });

    const mergedListData = {
      ...result?.data,
      data: mergedDataListData?.data,
    };

    setPreviousListData(result?.data);
    setCurrentListData(mergedListData);

    return result;
  } catch (error) {
    throw new ErrorException(error);
  }
}

export {
  initiateListData,
  loadMoreListData,
};
