import React, {
  memo, useContext, useEffect, useState,
} from 'react';
import PropTypes from 'prop-types';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { useMediaQuery } from '@mui/material';
import { useParams } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import styles from './ListTabs.module.css';
import Color from '../../../../themes/colors';
import { pageProperties, pageType } from '../../../../constants/PageConstants';
import Tab from './Tab/Tab';
import { NavHeaderConstants } from '../../../../constants';
import { GlobalContext } from '../../../../contexts/GlobalStateProvider';
import handleStatusMsg from '../../../../utilities/handleStatusMsg';
import handleLoadings from '../../../../utilities/handleLoadings';
import { TeamActions } from '../../../../actions';
import LinkNoDecor from '../../../LinkNoDecor/LinkNoDecor';

const ListTabs = ({
  overviewOptionId,
}) => {
  const [{ currentTeam, user }] = useContext(GlobalContext);
  const isMobile = useMediaQuery('(max-width:720px)');
  const [id, setId] = useState({});
  const [menus, setMenus] = useState([]);
  const { enqueueSnackbar } = useSnackbar();
  const [loadings, setLoadings] = useState([]);
  const params = useParams();
  const { companyId, teamId } = params;

  useEffect(() => {
    if (currentTeam?.blast
      && currentTeam?.boards?.[0]
      && currentTeam?.checkIn
      && currentTeam?.schedule
      && currentTeam?.groupChat
      && currentTeam?.bucket
    ) return;

    const fetchOverviewIds = async () => {
      try {
        const startLoadings = handleLoadings('overviewOption', [...loadings], 'start');
        setLoadings([...startLoadings]);
        const result = await TeamActions.getCurrentTeamOverviewId({ teamId, companyId });
        setId(result);
      } catch (error) {
        const status = handleStatusMsg(error, 'error');

        enqueueSnackbar(status.message, {
          variant: 'error',
        });
      } finally {
        const endLoadings = handleLoadings('overviewOption', [...loadings], 'end');
        setLoadings([...endLoadings]);
      }
    };
    fetchOverviewIds();
  }, [currentTeam]);

  useEffect(() => {
    const newMenus = NavHeaderConstants.menus.map((menu) => {
      let urlId = '';
      let firstUrl = `/companies/${companyId}/teams/${teamId}`;
      let customMenuUrl;
      switch (menu._id) {
        case 'teams':
          urlId = currentTeam?._id;
          firstUrl = `/companies/${companyId}`;
          break;
        case 'blasts':
          urlId = currentTeam?.blast?._id || currentTeam?.blast || id?.blast;
          break;
        case 'boards':
          urlId = `${currentTeam?.boards?.[0]?._id || currentTeam?.boards?.[0] || id?.boards?.[0]}/kanban/all`;
          break;
        case 'boardsList':
          customMenuUrl = 'boards';
          urlId = `${currentTeam?.boards?.[0]?._id || currentTeam?.boards?.[0] || id?.boards?.[0]}/list/all`;
          break;
        case 'boardsCalendar':
          customMenuUrl = 'boards';
          urlId = `${currentTeam?.boards?.[0]?._id || currentTeam?.boards?.[0] || id?.boards?.[0]}/calendar/all`;
          break;
        case 'check-ins':
          urlId = currentTeam?.checkIn?._id || currentTeam?.checkIn || id?.checkIn;
          break;
        case 'schedules':
          urlId = currentTeam?.schedule?._id || currentTeam?.schedule || id?.schedule;
          break;
        case 'group-chats':
          urlId = currentTeam?.groupChat?._id || currentTeam?.groupChat || id?.groupChat;
          break;
        case 'buckets':
          urlId = currentTeam?.bucket?._id || currentTeam?.bucket || id?.bucket;
          break;
        case 'reports':
          urlId = `members/${user?._id}`;
          break;
        default:
          break;
      }

      const url = `${firstUrl}/${customMenuUrl || menu._id}/${urlId}`;
      const newMenu = {
        ...menu,
        url,
      };

      return newMenu;
    });

    setMenus(newMenus);
  }, [currentTeam, id]);
  return (
    <div className={styles.container}>
      {menus.map((menu) => (
        <LinkNoDecor to={menu.url}>
          <div className={styles.tab}>
            <Tab isActive={overviewOptionId === menu._id} text={menu.title} icon={menu.icon} />
          </div>
        </LinkNoDecor>
      ))}
    </div>
  );
};

ListTabs.propTypes = {
  overviewOptionId: PropTypes.string,
};

ListTabs.defaultProps = {
  overviewOptionId: undefined,
};

export default memo(ListTabs);
