/* eslint-disable consistent-return */
import { useCallback } from 'react';
import { CheerActions, DocActions } from '../actions';
import { CheerConstants, DocConstants } from '../constants';
import {
  connectSocketBucket, connectSocketDoc,
} from '../services/socket';
import { useSocketHooks } from './SocketHooks';

function useDocSocket({ bucketId, docId, userId }, dispatch) {
  const callbackDoc = useCallback((doc) => {
    if (doc?._id !== docId) return;
    DocActions.incomingDoc({
      doc,
    }, dispatch);
  }, []);

  const listenSocket = (socket, params) => {
    socket
      .on(`doc-update-${params?.bucketId}`, callbackDoc)
      .on(`doc-archive-${params?.bucketId}`, callbackDoc);
  };
  const removeListener = (socket, params) => {
    socket
      .off(`doc-update-${params?.bucketId}`)
      .off(`doc-archive-${params?.bucketId}`);
    socket.disconnect();
  };

  useSocketHooks({
    params: { bucketId, userId },
    connectSocket: connectSocketBucket,
    listenSocket,
    removeListener,
  });
}

function useDocCheerNCommentSocket({ docId, userId }, dispatch) {
  const callbackNewCheer = useCallback((cheer) => {
    CheerActions.incomingCheerFromPrimaryParent({
      cheer,
      typeAction: CheerConstants.typeCallback.NEW,
      primaryParentAction: ({ updateStateParent }) => DocActions.dispatchUpdateBucketDoc(
        { updateBucketDoc: updateStateParent },
        dispatch,
      ),
      keyProperty: 'cheers',
    });
  }, []);
  const callbackDeleteCheer = useCallback((cheer) => {
    CheerActions.incomingCheerFromPrimaryParent({
      cheer,
      typeAction: CheerConstants.typeCallback.DELETE,
      primaryParentAction: ({ updateStateParent }) => DocActions.dispatchUpdateBucketDoc(
        { updateBucketDoc: updateStateParent },
        dispatch,
      ),
      keyProperty: 'cheers',
    });
  }, []);

  const callbackNewComment = useCallback((comment) => {
    DocActions.incomingDocComment({
      comment,
      typeAction: DocConstants.typeCallback.NEW,
    }, dispatch);
  }, []);
  const callbackUpdateComment = useCallback((comment) => {
    DocActions.incomingDocComment({
      comment,
      typeAction: DocConstants.typeCallback.EDIT,
    }, dispatch);
  }, []);
  const callbackDeleteComment = useCallback((comment) => {
    DocActions.incomingDocComment({
      comment,
      typeAction: DocConstants.typeCallback.DELETE,
    }, dispatch);
  }, []);

  const callbackNewCheerComment = useCallback((cheer) => {
    CheerActions.incomingCheerFromSecondaryParent({
      cheer,
      typeAction: CheerConstants.typeCallback.NEW,
      secondaryParentAction: DocActions.incomingDocComment,
      typeActionEditSecondaryParent: DocConstants.typeCallback.EDIT,
    }, dispatch);
  }, []);
  const callbackDeleteCheerComment = useCallback((cheer) => {
    CheerActions.incomingCheerFromSecondaryParent({
      cheer,
      typeAction: CheerConstants.typeCallback.DELETE,
      secondaryParentAction: DocActions.incomingDocComment,
      typeActionEditSecondaryParent: DocConstants.typeCallback.EDIT,
    }, dispatch);
  }, []);

  const listenSocket = (socket, params) => {
    socket
      .on('cheer-new', callbackNewCheer)
      .on('cheer-delete', callbackDeleteCheer)
      .on(`docsComment-new-${params?.docId}`, callbackNewComment)
      .on(`docsComment-update-${params?.docId}`, callbackUpdateComment)
      .on(`docsComment-delete-${params?.docId}`, callbackDeleteComment)
      .on('cheerComment-new', callbackNewCheerComment)
      .on('cheerComment-delete', callbackDeleteCheerComment);
  };
  const removeListener = (socket, params) => {
    socket
      .off('cheer-new')
      .off('cheer-delete')
      .off(`docsComment-new-${params?.docId}`)
      .off(`docsComment-update-${params?.docId}`)
      .off(`docsComment-delete-${params?.docId}`)
      .off('cheerComment-new')
      .off('cheerComment-delete');
    socket.disconnect();
  };

  useSocketHooks({
    params: { docId, userId },
    connectSocket: connectSocketDoc,
    listenSocket,
    removeListener,
  });
}

export {
  useDocSocket,
  useDocCheerNCommentSocket,
};
