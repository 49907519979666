import React, { memo, useState } from 'react';
import PropTypes from 'prop-types';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import styles from './ActivityByModule.module.css';
import Color from '../../themes/colors';
import HeaderActivity from './HeaderActivity/HeaderActivity';
import ListActivityByModule from './ListActivityByModule/ListActivityByModule';
import { serviceTypeRef } from '../../constants/ActivityConstants';

const ActivityByModule = ({
  variant,
  handleHideActivities,
}) => (
  <div className={styles.container}>
    <div className={styles.header}>
      <HeaderActivity handleHideActivities={handleHideActivities} />
    </div>
    <div className={styles.list}>
      <ListActivityByModule variant={variant} />
    </div>
  </div>
);

ActivityByModule.propTypes = {
  variant: PropTypes.oneOf([
    serviceTypeRef.card,
    serviceTypeRef.post,
    serviceTypeRef.question,
    serviceTypeRef.event,
    serviceTypeRef.recurringOccurrence,
    serviceTypeRef.bucket,
    serviceTypeRef.doc,
    serviceTypeRef.file,
  ]),
  handleHideActivities: PropTypes.func.isRequired,
};

ActivityByModule.defaultProps = {
  variant: serviceTypeRef.post,
};

export default memo(ActivityByModule);
