import React, { useCallback, memo } from 'react';
import LockIconMaterialUI from '@mui/icons-material/Lock';
import Tooltip from '@mui/material/Tooltip';
import { isEqual } from 'lodash';
import PropTypes from 'prop-types';
import { checkIfDataPrivate } from '../../../actions/UserActions';

const PrivateIcon = ({ data, size }) => {
  const showIcon = useCallback(() => checkIfDataPrivate(data), [data]);

  return showIcon()
    && (
    <Tooltip title="Hanya pembuat dan anggota/penerima saja yang bisa lihat">
      <LockIconMaterialUI fontSize={size} />
    </Tooltip>
    );
};

PrivateIcon.propTypes = {
  data: PropTypes.object.isRequired,
  size: PropTypes.object,
};

PrivateIcon.defaultProps = {
  size: 'medium',
};

const isComponentDataEqual = (prevProps, nextProps) => {
  const { data, size } = nextProps;
  return isEqual(prevProps.data, data)
  && isEqual(prevProps.size, size);
};

export default memo(PrivateIcon, isComponentDataEqual);
