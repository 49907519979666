import React, { memo, useState } from 'react';
import DoneAllIcon from '@mui/icons-material/DoneAll';
import CheckIcon from '@mui/icons-material/Check';
import PropTypes from 'prop-types';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { Tooltip } from '@mui/material';
import styles from './MessageSent.module.css';

const featureTextStyles = {
  message: 'Pesan',
  comment: 'Komentar',
};

const sentStatusTextStyles = {
  sending: 'masih dikirim',
  readPart: 'masih dikirim ke sebagian penerima',
  readAll: 'sudah terkirim ke semua.',
};

const colorSentIconStyles = {
  sending: styles.sending,
  readPart: styles.readPart,
  readAll: styles.readAll,
};

const DisplayIconMessageSent = ({
  featureType,
  sentStatus,
}) => {
  const isSending = sentStatus === 'sending';
  const isSentPart = sentStatus === 'readPart';
  const isSentAll = sentStatus === 'readAll';
  return (
    <Tooltip title={`${featureTextStyles[featureType]} ${sentStatusTextStyles[sentStatus]}`}>
      <div className={`${styles.container} ${colorSentIconStyles[sentStatus]}`}>
        {isSending && <CheckIcon />}
        {(isSentAll || isSentPart) && <DoneAllIcon />}
      </div>
    </Tooltip>
  );
};

DisplayIconMessageSent.propTypes = {
  featureType: PropTypes.oneOf(['message', 'comment']),
  sentStatus: PropTypes.oneOf(['sending', 'readPart', 'readAll']),
};

DisplayIconMessageSent.defaultProps = {
  featureType: 'message',
  sentStatus: 'readAll',
};

export default memo(DisplayIconMessageSent);
