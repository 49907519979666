import { useEffect, useState } from 'react';
import OneSignal from 'react-onesignal';

const useOneSignalPushNotif = ({
  companyId,
  userId,
}) => {
  const [oneSignalInitialized, setOneSignalInitialized] = useState(false);
  const [oneSignalPrompted, setOneSignalPrompted] = useState(false);
  const [oneSignalLoggedIn, setOneSignalLoggedIn] = useState(false);

  useEffect(() => {
    if (!companyId) return;
    if (oneSignalPrompted) return;
    if (!oneSignalInitialized) return;
    if (!oneSignalLoggedIn) return;

    const promptPushOneSignal = async () => {
      try {
        await OneSignal.Slidedown.promptPush();

        const idPush = OneSignal.User.PushSubscription.id;
        if (idPush) {
          await OneSignal.User.PushSubscription.optIn();
        }
        setOneSignalPrompted(true);
      } catch (error) {
        console.log(error);
      }
    };

    promptPushOneSignal();
  }, [oneSignalLoggedIn, companyId]);

  useEffect(() => {
    if (!oneSignalInitialized) return;
    const loginAndPromptPushOneSignal = async () => {
      try {
        await OneSignal.login(userId);
        setOneSignalLoggedIn(true);
      } catch (error) {
        console.log(error);
      }
    };

    loginAndPromptPushOneSignal();
  }, [oneSignalInitialized]);

  useEffect(() => {
    if (!userId) return;
    if (oneSignalInitialized) return;

    const initOneSignal = async () => {
      try {
        // Onesignal initialization
        const options = {
          appId: process.env.REACT_APP_PUSH_NOTIF_APP_ID,
          allowLocalhostAsSecureOrigin: process.env.REACT_APP_ENV === 'development',
          promptOptions: {
            type: 'push',
            text: {
              actionMessage: 'Yuk aktifkan push notif Cicle untuk mendapatkan update terbaru secara realtime',
              acceptButton: 'Aktifkan',
              cancelButton: 'Batal',
            },
          },
          welcomeNotification: {
            message: 'Terima kasih sudah mengaktifkan push notif ya!',
          },
        };

        await OneSignal.init(options);
        // OneSignal.Debug.setLogLevel('trace');
        setOneSignalInitialized(true);
      } catch (error) {
        console.log(error);
      }
    };

    initOneSignal();
  }, [userId]);
};

export {
  useOneSignalPushNotif,
};
