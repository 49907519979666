import React, { useState, useEffect, memo } from 'react';
import Backdrop from '@mui/material/Backdrop';
import PropTypes from 'prop-types';
import { FeedbackSpinnerGeneral } from '../../Spinner';
import Color from '../../../../../themes/colors';
import { isListEqual } from '../../../../../utilities/arrayUtil';

const FeedbackLoadingMain = ({
  wait,
  loadings,
  loadingComponent,
  children,
  useSpinner,
  spinnerSize,
  spinnerVariant,
  spinnerValue,
  spinnerColor,
  spinnerThickness,
}) => {
  const [isLoading, setIsLoading] = useState(true);
  const [localLoadings, setLocalLoadings] = useState([]);

  useEffect(() => {
    if (!wait || !loadings) return;
    if (isListEqual(loadings, localLoadings)) return;
    const checkWait = loadings.filter((loading) => loading === wait);
    setLocalLoadings(loadings);

    if (checkWait.length > 0) {
      setIsLoading(true);
      return;
    }

    setIsLoading(false);
  }, [loadings]);
  return (
    <>
      {isLoading && useSpinner
        && (
          <Backdrop open style={{ backgroundColor: 'rgba(0, 0, 0, 0)' }}>
            <FeedbackSpinnerGeneral
              variant={spinnerVariant}
              size={spinnerSize}
              color={spinnerColor}
              thickness={spinnerThickness}
              value={spinnerValue}
            />
          </Backdrop>
        )}

      {isLoading && !useSpinner && <>{loadingComponent}</>}

      {!isLoading && <>{children}</>}
    </>
  );
};

FeedbackLoadingMain.propTypes = {
  loadings: PropTypes.array,
  children: PropTypes.node.isRequired,
  wait: PropTypes.string.isRequired,
  spinnerSize: PropTypes.string,
  spinnerVariant: PropTypes.string,
  spinnerValue: PropTypes.string,
  spinnerColor: PropTypes.string,
  spinnerThickness: PropTypes.string,
  useSpinner: PropTypes.bool,
  loadingComponent: PropTypes.node,
};

FeedbackLoadingMain.defaultProps = {
  spinnerSize: 24,
  spinnerVariant: 'indeterminate',
  spinnerValue: 0,
  spinnerColor: Color.white,
  spinnerThickness: 6,
  useSpinner: false,
  loadings: [],
  loadingComponent: null,
};

export default memo(FeedbackLoadingMain);
