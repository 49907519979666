import React, {
  memo, useCallback, useEffect, useState,
} from 'react';
import PropTypes from 'prop-types';
import { useSnackbar } from 'notistack';
import { useParams, useHistory, useLocation } from 'react-router-dom';
import styles from './ListDataSection.module.css';
import { DisplayTextBody } from '../../../UI_V2/Display/Text';
import Color from '../../../../themes/colors';
import InfinityScroll from '../../../UI/InfinityScroll/InfinityScroll';
import handleStatusMsg from '../../../../utilities/handleStatusMsg';
import { ArchivedItemsActions } from '../../../../actions';
import { useInfinityScrollHooks } from '../../../../hooks/InfinityScrollHooks';
import ArchivedItem from './ArchivedItem/ArchivedItem';
import ArchivedItemsBarSkeleton from '../Skeleton/Skeleton';
import FilterBar from '../../FilterBar/FilterBar';
import handleLoadings from '../../../../utilities/handleLoadings';

const ListDataSection = ({
  featureType,
  showFilters,
  handleHideFilters,
  initiateListDataFunc,
  loadMoreListDataFunc,
  unarchiveDataFunc,
  handleSetIsFilterActive,
  topPositionVariant
}) => {
  /* Initiate list data */
  const initialStatePreviousListData = {
    data: new Array(10),
  };
  const initialStateCurrentListData = {
    data: [],
  };
  const [previousListData, setPreviousListData] = useState(initialStatePreviousListData);
  const [currentListData, setCurrentListData] = useState(initialStateCurrentListData);
  const [isFirstLoad, setIsFirstLoad] = useState(true);
  const [filters, setFilters] = useState({
    filterTeams: [],
    filterSubscribers: [],
    filterLabels: [],
    filterTitle: '',
    filterDueDate: null,
  });
  const [loadings, setLoadings] = useState([]);

  const params = useParams();
  const history = useHistory();
  const location = useLocation();
  const { companyId, teamId } = params;

  const { enqueueSnackbar } = useSnackbar();
  const getMoreLists = async () => {
    const result = await ArchivedItemsActions.loadMoreListData({
      currentListData,
      setCurrentListData,
      setPreviousListData,
      loadMoreListDataFunc,
      filters,
    });

    return result;
  };

  const resetPreviousLists = useCallback(() => {
    setPreviousListData(initialStatePreviousListData);
    setCurrentListData(initialStateCurrentListData);
  }, []);

  const {
    lists,
    handleLoadMoreLists,
    checkIfListNeedToLoad,
    setCheckLoadMore,
    isListsLengthAtTheLimitOrAbove,
  } = useInfinityScrollHooks({
    currentObjectWithKeyProperty: currentListData,
    previousLists: previousListData?.data,
    resetPreviousLists,
    getMoreLists,
    keyProperty: 'data',
    limitList: 10,
  });

  const initiateListDataApi = async (filtersValue = filters) => {
    try {
      const startLoadings = handleLoadings('initiateArchivedItems', [...loadings], 'start');
      setLoadings([...startLoadings]);

      const result = await ArchivedItemsActions.initiateListData({
        setCurrentListData,
        setPreviousListData,
        initialStatePreviousListData,
        initiateListDataFunc,
        filters: filtersValue,
      });

      if (isListsLengthAtTheLimitOrAbove(result?.data?.data)) {
        setCheckLoadMore(true);
      } else {
        setCheckLoadMore(false);
      }

      if (isFirstLoad) {
        setIsFirstLoad(false);
      }

      if (showFilters) {
        handleHideFilters();
      }
    } catch (err) {
      const status = handleStatusMsg(err, 'error');

      enqueueSnackbar(status.message, {
        variant: 'error',
      });
    } finally {
      const endLoadings = handleLoadings('initiateArchivedItems', [...loadings], 'end');
      setLoadings([...endLoadings]);
    }
  };

  useEffect(() => {
    initiateListDataApi();
  }, [location]);

  const onApplyFilters = (filtersValue) => {
    setFilters(filtersValue);
    initiateListDataApi(filtersValue);
  };

  useEffect(() => {
    const {
      filterDueDate,
      filterLabels,
      filterSubscribers,
      filterTeams,
      filterTitle,
    } = filters;
    const isSearchFilterActive = filterTitle.length > 0;
    const isTeamsFilterActive = filterTeams.length > 0;
    const isLabelsFilterActive = filterLabels.length > 0;
    const isMembersFilterActive = filterSubscribers.length > 0;
    const isDueDateFilterActive = filterDueDate;

    let isActive = false;
    if (isSearchFilterActive) isActive = true;
    if (isTeamsFilterActive) isActive = true;
    if (isLabelsFilterActive) isActive = true;
    if (isMembersFilterActive) isActive = true;
    if (isDueDateFilterActive) isActive = true;

    handleSetIsFilterActive(isActive);
  }, [filters]);

  return (
    <>
      <div className={styles.container}>
        {isFirstLoad && <ArchivedItemsBarSkeleton />}
        {!isFirstLoad && (
        <InfinityScroll
          scrollableTarget="archivedItemsList"
          dataLength={lists?.length || 0}
          hasMore={checkIfListNeedToLoad()}
          next={handleLoadMoreLists}
          emptyMessage="No items here yet..."
        >
          {lists.map((item, index) => (
            <ArchivedItem
              index={index}
              featureType={featureType}
              item={item}
              unarchiveDataFunc={unarchiveDataFunc}
              companyId={companyId}
              teamId={teamId}
              initiateListDataApi={initiateListDataApi}
            />
          ))}
        </InfinityScroll>
        )}
      </div>
      {showFilters
      && (
      <FilterBar
        showLabelSection={false}
        showMemberSection={false}
        showDueDateSection={false}
        showTeamSection={false}
        onClose={handleHideFilters}
        onApplyFilters={onApplyFilters}
        loadingsOnApplyFilters={loadings}
        waitOnApplyFilters="initiateArchivedItems"
        initialSearch={filters.filterTitle}
        // disableCloseIcon
        showBackIconOnClose
        barTitle="Filter Archived"
        topPositionVariant={topPositionVariant}
      />
      )}
    </>
  );
};

ListDataSection.propTypes = {
  featureType: PropTypes.string.isRequired,
  showFilters: PropTypes.bool.isRequired,
  handleHideFilters: PropTypes.func.isRequired,
  initiateListDataFunc: PropTypes.func.isRequired,
  loadMoreListDataFunc: PropTypes.func.isRequired,
  unarchiveDataFunc: PropTypes.func.isRequired,
  handleSetIsFilterActive: PropTypes.func.isRequired,
  topPositionVariant: PropTypes.string.isRequired,
};

export default memo(ListDataSection);
