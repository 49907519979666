import React, {
  memo, useState, useEffect, useContext, useRef, useCallback,
} from 'react';
import PropTypes from 'prop-types';
import { Box, useMediaQuery } from '@mui/material';
import { GlobalContext } from '../../../contexts/GlobalStateProvider';
import JumpTo from './JumpTo/JumpTo';
import { useEventListener } from '../../../hooks/HelperHooks';
import SearchJumpToResultsSkeleton from './Skeleton/Skeleton';
import SearchResults from './SearchResults/SearchResults';
import { SearchJumpToActions } from '../../../actions';
import { SurfacePaperFloating } from '../../../components/UI_V2/Surface/Paper';

const containerStyles = {
  // padding: '1rem 1.5rem',
  paddingTop: '1rem',
  position: 'relative',
};

const ShowResults = ({
  listSearch, listTeams, listRecentViewPages, onClose, isTyping,
  // keyword,
  keywordArray, params,
  debouncedKeyword,
  isOnSearchBar,
}) => {
  const [{
    user, currentCompany, teams,
  }, dispatch] = useContext(GlobalContext);
  const ref = useRef();

  useEffect(() => {
    if (!user?._id || !currentCompany?._id) return;
    SearchJumpToActions.initiateSearchList({ teams, currentCompany, userId: user?._id }, dispatch);
  }, []);

  // const initialState = { selectedIndex: 0 };
  // function reducer(state, action) {
  //   switch (action.type) {
  //     case 'arrowUp':
  //       return {
  //         selectedIndex:
  //         state.selectedIndex !== 0 ? state.selectedIndex - 1 : listSearch.length - 1,
  //       };
  //     case 'arrowDown':
  //       return {
  //         selectedIndex:
  //         state.selectedIndex !== listSearch.length - 1 ? state.selectedIndex + 1 : 0,
  //       };
  //     case 'select':
  //       return { selectedIndex: action.payload };
  //     default:
  //       throw new Error();
  //   }
  // }
  // const [state, dispatch] = useReducer(reducer, initialState);

  // const handleKeyPressed = useCallback(({ ctrlKey, key }) => {
  //   const arrowUpPressed = key === 'ArrowUp';
  //   const arrowDownPressed = key === 'ArrowDown';
  //   const escapePressed = key === 'Escape';
  //   if (arrowUpPressed) {
  //     dispatch({ type: 'arrowUp' });
  //   }
  //   if (arrowDownPressed) {
  //     dispatch({ type: 'arrowDown' });
  //   }
  //   if (escapePressed) onClose();
  // }, []);

  // useEventListener('keydown', (event) => handleKeyPressed(event), ref?.current);

  let customStyle = {
    top: '5vh',
    left: '-9.7vw',
    height: '72vh',
  };

  const isTablet = useMediaQuery('(max-width:1024px)');
  const isMobile = useMediaQuery('(max-width:720px)');

  const sizeTablet = {
    width: '75vw',
    // height: '75vh',
    left: '-15.7vw',
  };

  const sizeMobile = {
    width: '84vw',
    // height: '80vh',
    left: '-6.7vw',
  };

  if (isTablet) {
    customStyle = {
      ...customStyle,
      ...sizeTablet,
    };
  }

  if (isMobile) {
    customStyle = {
      ...customStyle,
      ...sizeMobile,
    };
  }

  return (
  // <SurfacePaperFloating
  //   onOutsideClick={onClose}
  //   size="xl"
  //   customStyle={customStyle}
  //   disabled={isOnSearchBar}
  // >
    <Box
      ref={ref}
      sx={{
        ...containerStyles,
      }}
    >
      {debouncedKeyword.length < 1
      && (
      <JumpTo
        listTeams={listTeams}
        listRecentViewPages={listRecentViewPages}
        // selectedIndex={state.selectedIndex}
        onClose={onClose}
        params={params}
        isTyping={isTyping}
      />
      )}
      {/* {isTyping && <SearchJumpToResultsSkeleton />} */}
      {debouncedKeyword.length > 0
        && (
        <SearchResults
          params={params}
          keywordArray={keywordArray}
          // keyword={keyword}
          debouncedKeyword={debouncedKeyword}
          onClose={onClose}
          list={listSearch}
          isTyping={isTyping}
        />
        )}
    </Box>
  // </SurfacePaperFloating>
  );
};

ShowResults.propTypes = {
  onClose: PropTypes.func.isRequired,
  listSearch: PropTypes.array.isRequired,
  listTeams: PropTypes.array.isRequired,
  listRecentViewPages: PropTypes.array.isRequired,
  isTyping: PropTypes.bool.isRequired,
  // keyword: PropTypes.string.isRequired,
  keywordArray: PropTypes.array.isRequired,
  params: PropTypes.object,
  debouncedKeyword: PropTypes.string.isRequired,
  isOnSearchBar: PropTypes.bool.isRequired,
};

ShowResults.defaultProps = {
  params: { companyId: null, teamId: null },
};

export default memo(ShowResults);
