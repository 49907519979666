import React from 'react';
import CloseIcon from '@mui/icons-material/Close';
import PropTypes from 'prop-types';
import BoxPopOver from '../../../components/UI/BoxPopOver/BoxPopOver';
import SeparatorLine from '../../../components/UI/SeparatorLine/SeparatorLine';
import styles from './PublishChoice.module.css';
import Button from '../../../components/UI/Button/Button';

const PublishChoice = ({
  handlePublishSingle,
  handlePublishThisAndFollowing,
  cancel,
  loadings,
  waitSingle,
  waitThisFollowing,
}) => (
  <BoxPopOver left customStyle={{ top: '-150px' }}>
    <div className={styles.headerSection}>
      <h1>
        Ini adalah jadwal berulang...
      </h1>
      <CloseIcon onClick={cancel} />
    </div>
    <SeparatorLine />
    <div className={styles.bodySection}>

      {/* <p className={styles.description}>Do you want
      to change all of the future events too?</p> */}
      <p className={styles.description}>Apakah kamu mau ubah semua jadwal kedepannya?</p>
      <Button handleClick={handlePublishSingle} wait={waitSingle} loadings={loadings} size="sm" variant="success" block>
        {/* No, change this event only */}
        Ngga, ubah jadwal ini aja
      </Button>
      <Button handleClick={handlePublishThisAndFollowing} wait={waitThisFollowing} loadings={loadings} size="sm" variant="outline-success" block>
        {/* Yes, change this and future events */}
        Ya, ubah ini dan semua jadwal kedepan.
      </Button>
    </div>
  </BoxPopOver>
);

PublishChoice.propTypes = {
  handlePublishSingle: PropTypes.func.isRequired,
  handlePublishThisAndFollowing: PropTypes.func.isRequired,
  cancel: PropTypes.func.isRequired,
  loadings: PropTypes.array.isRequired,
  waitSingle: PropTypes.string.isRequired,
  waitThisFollowing: PropTypes.string.isRequired,
};

export default PublishChoice;
