import React, { memo, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import CloseIcon from '@mui/icons-material/Close';
import { useMediaQuery } from '@mui/material';
import styles from './Drawer.module.css';
import { DisplayTextBody } from '../../../Display/Text';
import { DisplayDividerMain } from '../../../Display/Divider';
import LoadingItem from './LoadingItem/LoadingItem';

const FeedbackLoadingDrawer = ({
  loadingUpload,
  progressUpload,
  listFilesBeingUploaded,
  backgroundColorItem,
  heightItem,
}) => {
  const isMobile = useMediaQuery('(max-width:720px)');
  const [open, setOpen] = useState(false);

  useEffect(() => {
    if (loadingUpload) setOpen(true);
  }, [loadingUpload]);
  return (
    <>
      {open && (
      <div className={styles.container}>
        <div className={styles.header}>
          <DisplayTextBody
            decoration="bold"
          >
            {!loadingUpload ? `Sukses mengunggah ${listFilesBeingUploaded.length} berkas` : `Mengunggah ${listFilesBeingUploaded.length} berkas`}
          </DisplayTextBody>
          <div className={styles.iconClose} onClick={() => setOpen(false)}>
            <CloseIcon />
          </div>
        </div>
        <DisplayDividerMain marginTop="0.2rem" marginBottom="0.2rem" />
        <div className={styles.body}>
          {listFilesBeingUploaded.map((file) => {
            const isProgressUploadFinish = progressUpload[file._id] === 100;
            const isNotYetUploaded = progressUpload[file._id] === undefined;

            return (
              <LoadingItem
                file={file}
                isProgressUploadFinish={isProgressUploadFinish}
                isNotYetUploaded={isNotYetUploaded}
                progressUpload={progressUpload}
                backgroundColor={backgroundColorItem}
                height={heightItem}
              />
            );
          })}
        </div>
      </div>
      )}
    </>
  );
};

FeedbackLoadingDrawer.propTypes = {
  loadingUpload: PropTypes.bool,
  progressUpload: PropTypes.object,
  listFilesBeingUploaded: PropTypes.array,
  backgroundColorItem: PropTypes.string,
  heightItem: PropTypes.string,
};

FeedbackLoadingDrawer.defaultProps = {
  loadingUpload: false,
  progressUpload: {},
  listFilesBeingUploaded: [],
  backgroundColorItem: undefined,
  heightItem: undefined,
};

export default memo(FeedbackLoadingDrawer);
