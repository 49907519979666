import React, {
  memo, useContext, useEffect, useRef, useState,
} from 'react';
import PropTypes from 'prop-types';
import SortIcon from '@mui/icons-material/Sort';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { Skeleton, useMediaQuery } from '@mui/material';
import { enqueueSnackbar } from 'notistack';
import styles from './CardListItemMembers.module.css';
import Color from '../../../../themes/colors';
import { DisplayTextBody } from '../../../UI_V2/Display/Text';
import CardListColumnBox from '../../CardListColumnBox/CardListColumnBox';
import ShowLimitMembers from '../../../ShowLimitMembers/ShowLimitMembers';
import { useDelayShowHideHandler } from '../../../../hooks/HelperHooks';
import { GlobalContext } from '../../../../contexts/GlobalStateProvider';
import handleStatusMsg from '../../../../utilities/handleStatusMsg';
import { TeamActions } from '../../../../actions';
import ManageMultipleMembersContainer from '../../../../pages/ManageMultipleMembersContainer/ManageMultipleMembersContainer';

const CardListItemMembers = ({
  item,
  widthPercentage,
  variantBorder,
  setSelected,
  triggerScrollIntoView,
}) => {
  const isMobile = useMediaQuery('(max-width:720px)');
  const [{ currentTeam }, dispatch] = useContext(GlobalContext);
  const [isLoadingTeamMembers, setIsLoadingTeamMembers] = useState(false);

  const [
    showManageCardMembers,
    handleShowManageCardMembers,
    handleHideManageCardMembers,
  ] = useDelayShowHideHandler();

  const initiateTeamMembers = async () => {
    try {
      const result = await TeamActions.initiateTeam(
        {
          companyId: item.company,
          teamId: item.team._id,
          currentTeam,
        },
        dispatch,
      );

      await TeamActions.initiateTeamMembers({
        companyId: item.company,
        teamId: item.team._id,
        currentTeam: result.data ? result.data.team : result,
      }, dispatch);
    } catch (error) {
      const status = handleStatusMsg(error, 'error');

      enqueueSnackbar(status.message, {
        variant: 'error',
      });
    } finally {
      setIsLoadingTeamMembers(false);
      handleShowManageCardMembers();
    }
  };

  const checkShouldGetTeamMembersOrNot = () => {
    setIsLoadingTeamMembers(true);
  };

  useEffect(() => {
    if (!isLoadingTeamMembers) return;
    setSelected(item._id);
    initiateTeamMembers();
  }, [isLoadingTeamMembers]);

  useEffect(() => {
    if (!showManageCardMembers) return;

    triggerScrollIntoView();
  }, [showManageCardMembers]);
  return (
    <CardListColumnBox
      variantBorder={variantBorder}
      widthPercentage={widthPercentage}
      variantCursor="pointer"
      onClick={showManageCardMembers ? () => null : checkShouldGetTeamMembersOrNot}
    >
      <div className={styles.container}>
        {!isLoadingTeamMembers && (
        <ShowLimitMembers
          members={item.members}
          limit={isMobile ? 1 : 4}
          noPlusButton
          companyId={item.company}
          disableMemberOnlinePhoto
        />
        )}
        {isLoadingTeamMembers && <Skeleton width={80} />}
        {showManageCardMembers && (
        <ManageMultipleMembersContainer
          card={item}
          type="card"
          cancel={handleHideManageCardMembers}
          position="topLeft"
        />
        )}
      </div>
    </CardListColumnBox>
  );
};

CardListItemMembers.propTypes = {
  item: PropTypes.object,
  widthPercentage: PropTypes.number,
  variantBorder: PropTypes.string.isRequired,
  setSelected: PropTypes.func,
  triggerScrollIntoView: PropTypes.func,
};

CardListItemMembers.defaultProps = {
  item: {},
  widthPercentage: 100,
  setSelected: () => null,
  triggerScrollIntoView: () => null,
};

export default memo(CardListItemMembers);
